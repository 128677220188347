import { type Component, type ComponentOptions, createApp } from "vue";
import { assert } from "@utils/assertion";
import { setupGaContext } from "@utils/vue-migration/common/gaContext/gaContext";
import { getResourceConstraints } from "@generated/api/roleResourceConstraintControllerApi";
import { parseGaContextDataSync } from "@generated/model/gaContextDataSync";
import { createRouter, createWebHistory } from "vue-router";
import { generatedRoutes } from "@newgenerated/routes/designRoutes";
import { getFeatures } from "@generated/api/featureControllerApi";
import { getGaDesignTranslations } from "@/common/i18nUtils";

type Module = (rootElement: Element) => void;

const modules: { callback: Module; selector: string }[] = [];
const widgets = new Map<string, Component>();
const alreadyInitializedElements = new Set<Element>();
const initFunctions = new Set<() => void>();

export function registerModule(selector: string, callback: Module): void {
  modules.push({ selector, callback });
}

export function updateDom(rootElement: Document | Element): void {
  modules.forEach((module) => {
    rootElement.querySelectorAll(module.selector).forEach((domElement) => {
      if (!alreadyInitializedElements.has(domElement)) {
        alreadyInitializedElements.add(domElement);
        module.callback(domElement);
      }
    });
  });
}

export function gaInitGeneral(rootElement: Document | Element): void {
  /* eslint-disable-next-line */
  (window as unknown as any).$(rootElement).gaInitGeneral();
}

document.addEventListener("ga.dom.updated", function (event) {
  if (event.target instanceof Element) {
    updateDom(event.target);
  } else {
    updateDom(document);
  }
});

export function registerVueWidgetForJsp(name: string, component: Component): void {
  widgets.set(name, component);
}

export function registerGlobalInitFunction(fn: () => void): void {
  initFunctions.add(fn);
}

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/:lang?",
      component: { template: "<router-view />" },
      children: [...generatedRoutes],
    },
  ],
});

// Our scripts are being loaded with the `defer` keyword, which means the document will already be parsed when this runs.
// Maybe there is a cleaner and simpler solution to make sure all modules get registered first and then run once.
addEventListener("DOMContentLoaded", async () => {
  /* eslint-disable-next-line */
  if ((globalThis as any).IS_PROSPECT) {
    return;
  }

  /* eslint-disable-next-line */
  const gaContextDataSync = parseGaContextDataSync((globalThis as any).gaContext);
  await setupGaContext(gaContextDataSync, {
    loadTranslations: getGaDesignTranslations,
    roleResourceConstraints: getResourceConstraints,
    features: getFeatures,
  });

  initFunctions.forEach((fn) => fn());
  updateDom(document);
  if (widgets.size > 0) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).vue = {
      mountWidget: (name: string, selector: string, opt: ComponentOptions = {}): void => {
        const component = widgets.get(name);
        assert(component !== undefined, `Component '${name}' has not been registered`);
        if (name === "GaDesignSpa") {
          createApp(component, opt).use(router).mount(selector);
        } else {
          createApp(component, opt).mount(selector);
        }
        //TODO setupSessionKeepalive
      },
    };

    document.dispatchEvent(new CustomEvent("ga.design.ready", { bubbles: true }));
  }
});
