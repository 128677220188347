// @ts-ignore
import { hasProp, assert } from "@utils/assertion";
// @ts-ignore
import { isArray } from "@utils/arrayUtils";
// @ts-ignore
import { parseJsonWithNumbersAsBigint, renderJsonWithNumbersAsBigint } from "@utils/json";
// @ts-ignore
import { DateTime } from "luxon";
// @ts-ignore
import { type ReadonlyUint8Array } from "@utils/arrayUtils";
import { type Account } from "./schema";
import { type ActionableActionbarProps } from "./schema";
import { type ActionableActivityReport } from "./schema";
import { type ActionableBulkLoadForm } from "./schema";
import { type ActionableId } from "./schema";
import { type ActionableListPortletView } from "./schema";
import { type ActionableMetadata } from "./schema";
import { type ActionableMiniView } from "./schema";
import { type ActionableReflectSectionProps } from "./schema";
import { type ActionableSetEntry } from "./schema";
import { type Activity } from "./schema";
import { type ActivityDefinition } from "./schema";
import { type Actor } from "./schema";
import { type AdminSidebarWidgetProps } from "./schema";
import { type AdminToolLink } from "./schema";
import { type AdminToolLinkGroup } from "./schema";
import { type AdminToolLinkTargetType } from "./schema";
import { type AlipayPaymentView } from "./schema";
import { type AndroidPaymentView } from "./schema";
import { type AreaOfExpertise } from "./schema";
import { type AskGetabstractAnalyticsEventVariant } from "./schema";
import { type AskGetabstractAnalyticsFeedbackEventForm } from "./schema";
import { type AskGetabstractAnswer } from "./schema";
import { type AskGetabstractAnswerContextDoc } from "./schema";
import { type AskGetabstractAnswerStatus } from "./schema";
import { type AskGetabstractPortletView } from "./schema";
import { type AskGetabstractProperties } from "./schema";
import { type AskGetabstractQuestion } from "./schema";
import { type AskGetabstractQuestionAnswers } from "./schema";
import { type AuthorAdminListFilters } from "./schema";
import { type AuthorAdminListView } from "./schema";
import { type AuthorEditView } from "./schema";
import { type B2BStatsReportPageProps } from "./schema";
import { type B2BStatsReportSaveResponse } from "./schema";
import { type B2CRenewalEmails } from "./schema";
import { type B2CSubscription } from "./schema";
import { type BookmarkResponse } from "./schema";
import { type CareerLevel } from "./schema";
import { type CareerLocalizationForm } from "./schema";
import { type ChannelAdminSearchForm } from "./schema";
import { type ChannelAdminTagBasedProps } from "./schema";
import { type ChannelCoverEditForm } from "./schema";
import { type ChannelEditForm } from "./schema";
import { type ChannelEditView } from "./schema";
import { type ChannelList } from "./schema";
import { type ChannelLocalizationEditForm } from "./schema";
import { type ChannelLocalizationView } from "./schema";
import { type ChannelManager } from "./schema";
import { type ChannelMetadata } from "./schema";
import { type ChannelRelationForm } from "./schema";
import { type ChannelRelationView } from "./schema";
import { type ChannelSaveResponse } from "./schema";
import { type ChannelTextLocalization } from "./schema";
import { type ConnectorCompletionExecutionLogListView } from "./schema";
import { type ConnectorCompletionFilters } from "./schema";
import { type ConnectorContentExecutionListView } from "./schema";
import { type ConnectorContentExecutionLogDetailView } from "./schema";
import { type ConnectorContentExecutionLogListView } from "./schema";
import { type ConnectorExecutionLogMetadata } from "./schema";
import { type ConnectorExecutionType } from "./schema";
import { type ConnectorExecutionView } from "./schema";
import { type ConnectorForm } from "./schema";
import { type ConnectorGrantType } from "./schema";
import { type ConnectorListView } from "./schema";
import { type ConnectorProduct } from "./schema";
import { type ConnectorProviderMetadata } from "./schema";
import { type ConnectorRealTimeCompletionInfo } from "./schema";
import { type ConnectorSettingsForm } from "./schema";
import { type ConnectorSettingsView } from "./schema";
import { type ConnectorSyncEntry } from "./schema";
import { type ConnectorSyncForm } from "./schema";
import { type ConnectorType } from "./schema";
import { type ConnectorUserIdentifier } from "./schema";
import { type ConnectorView } from "./schema";
import { type ContentCardDemoProps } from "./schema";
import { type ContentId } from "./schema";
import { type ContentIdList } from "./schema";
import { type ContentItemType } from "./schema";
import { type ContentIxStatus } from "./schema";
import { type ContentIxStatusActionable } from "./schema";
import { type ContentIxStatusList } from "./schema";
import { type ContentIxStatusMiab } from "./schema";
import { type ContentIxStatusSummary } from "./schema";
import { type ContentLibrary } from "./schema";
import { type ContentMetadata } from "./schema";
import { type ContentMetadataActionable } from "./schema";
import { type ContentMetadataCommon } from "./schema";
import { type ContentMetadataLgxp } from "./schema";
import { type ContentMetadataMiab } from "./schema";
import { type ContentMetadataSummary } from "./schema";
import { type CorporateLicences } from "./schema";
import { type CorporationLecacyForm } from "./schema";
import { type CorporationPortalAdminView } from "./schema";
import { type Country } from "./schema";
import { type CountryRestrictionType } from "./schema";
import { type CreditCardPaymentView } from "./schema";
import { type CreditCardType } from "./schema";
import { type CustomPageAdminSearchForm } from "./schema";
import { type CustomPageCopyRequest } from "./schema";
import { type CustomPageEditForm } from "./schema";
import { type CustomPageEditListView } from "./schema";
import { type CustomPageEditListViewArray } from "./schema";
import { type CustomPageEditView } from "./schema";
import { type CustomPageFilters } from "./schema";
import { type CustomPageHeaderLayout } from "./schema";
import { type CustomPageHighlightsForm } from "./schema";
import { type CustomPageListView } from "./schema";
import { type CustomPageLocalizationEditForm } from "./schema";
import { type CustomPageLocalizationEditView } from "./schema";
import { type CustomPageView } from "./schema";
import { type CustomerAdminAccountDetailForm } from "./schema";
import { type CustomerAdminB2BPortalsForm } from "./schema";
import { type CustomerAdminB2BPortalsForms } from "./schema";
import { type CustomerAdminDetailAddress } from "./schema";
import { type CustomerAdminDetailForm } from "./schema";
import { type CustomerAdminDetailRole } from "./schema";
import { type CustomerAdminDetailWidgetProps } from "./schema";
import { type CustomerAdminFreeAbstractCoupon } from "./schema";
import { type CustomerAdminFreeAbstractCoupons } from "./schema";
import { type CustomerAdminMobileAppForm } from "./schema";
import { type CustomerAdminSubscriptionsForm } from "./schema";
import { type CustomerCommentForm } from "./schema";
import { type CustomerCommentForms } from "./schema";
import { type CustomerCommentSaveRequest } from "./schema";
import { type CustomerReportView } from "./schema";
import { type CustomerView } from "./schema";
import { type DateFormattingDemoProps } from "./schema";
import { type DateRange } from "./schema";
import { type DemoFormFile } from "./schema";
import { type DisplayMonetaryAmount } from "./schema";
import { type DownloadType } from "./schema";
import { type DropdownEntry } from "./schema";
import { type EmailCategoryFilters } from "./schema";
import { type EmailCategoryFollowUpType } from "./schema";
import { type EmailCategoryForm } from "./schema";
import { type EmailCategoryFormDetails } from "./schema";
import { type EmailCategoryManualSendForm } from "./schema";
import { type EmailCategoryTypeDefault } from "./schema";
import { type EmailCategoryTypeEducation } from "./schema";
import { type EmailCategoryTypeFollowUp } from "./schema";
import { type EmailCategoryTypeManual } from "./schema";
import { type EmailCategoryTypeOnboarding } from "./schema";
import { type EmailCategoryTypeRenewal } from "./schema";
import { type EmailDefaultCategoryAdminView } from "./schema";
import { type EmailEducationCategoryAdminView } from "./schema";
import { type EmailFollowUpCategoryAdminView } from "./schema";
import { type EmailManualCategoryAdminView } from "./schema";
import { type EmailOnboardingCategoryAdminView } from "./schema";
import { type EmailPoolAdminView } from "./schema";
import { type EmailPoolForm } from "./schema";
import { type EmailRenewalCategoryAdminView } from "./schema";
import { type EmailRepositoryAdminView } from "./schema";
import { type EmailRepositoryType } from "./schema";
import { type EmailTemplateCategoryInfo } from "./schema";
import { type EmailTemplateEditView } from "./schema";
import { type EmailTemplateForm } from "./schema";
import { type EmailTemplateTestForm } from "./schema";
import { type EmailTemplateTestFormat } from "./schema";
import { type EmailTemplateTestMethod } from "./schema";
import { type EmailTemplateTestResult } from "./schema";
import { type ExportReportListView } from "./schema";
import { type FeatureType } from "./schema";
import { type FilteredLearningItemAdminView } from "./schema";
import { type FormFilter } from "./schema";
import { type FormFilterName } from "./schema";
import { type FormFilterOption } from "./schema";
import { type FormFilterPriority } from "./schema";
import { type FormFilterType } from "./schema";
import { type FrontendProject } from "./schema";
import { type FullSearchProps } from "./schema";
import { type FullSearchResults } from "./schema";
import { type GaContextAsp } from "./schema";
import { type GaContextCustomer } from "./schema";
import { type GaContextDataSync } from "./schema";
import { type GaContextFlags } from "./schema";
import { type Gender } from "./schema";
import { type GiftCodeStatus } from "./schema";
import { type GiftDonorSubscription } from "./schema";
import { type GiftReceiverSubscription } from "./schema";
import { type GiftSubscription } from "./schema";
import { type Invoice } from "./schema";
import { type InvoicePaymentView } from "./schema";
import { type IosPaymentView } from "./schema";
import { type ItemLayout } from "./schema";
import { type KindChannel } from "./schema";
import { type KindClassification } from "./schema";
import { type KindDynamicBoolean } from "./schema";
import { type KindDynamicNumber } from "./schema";
import { type KindDynamicString } from "./schema";
import { type KindDynamicVoid } from "./schema";
import { type KindKeyQuality } from "./schema";
import { type KindPrimarySkill } from "./schema";
import { type KindSkill } from "./schema";
import { type Language } from "./schema";
import { type LeadCreationResponse } from "./schema";
import { type LeadForm } from "./schema";
import { type LeadFormCompanySize } from "./schema";
import { type LeadFormInquiry } from "./schema";
import { type LeadFormProps } from "./schema";
import { type LeadSource } from "./schema";
import { type LearningCalendarView } from "./schema";
import { type LearningItemAdminActionableView } from "./schema";
import { type LearningItemAdminFilter } from "./schema";
import { type LearningItemAdminMetadataView } from "./schema";
import { type LearningItemAdminProps } from "./schema";
import { type LearningItemAdminSummaryView } from "./schema";
import { type LearningItemAdminView } from "./schema";
import { type LearningItemType } from "./schema";
import { type LearningItemTypeActionable } from "./schema";
import { type LearningItemTypeSummary } from "./schema";
import { type LgxpId } from "./schema";
import { type LsvPaymentView } from "./schema";
import { type MIABAnalyticsFeedbackEventForm } from "./schema";
import { type MIABContent } from "./schema";
import { type MIABContentData } from "./schema";
import { type MIABCustomQuestionsForm } from "./schema";
import { type MIABCustomQuestionsProps } from "./schema";
import { type MIABCustomQuestionsResponse } from "./schema";
import { type MIABOverviewPageProps } from "./schema";
import { type MIABPageProps } from "./schema";
import { type MIABPopularSummariesResult } from "./schema";
import { type MIABPrepareStepAiResponse } from "./schema";
import { type MIABQuestionMetadata } from "./schema";
import { type MIABQuestionsAiResponse } from "./schema";
import { type MIABSummaryForm } from "./schema";
import { type MIABUserContext } from "./schema";
import { type MIABView } from "./schema";
import { type MiabId } from "./schema";
import { type MilesPaymentView } from "./schema";
import { type MobileAppLoginPreferenceListView } from "./schema";
import { type MobileDevice } from "./schema";
import { type MonetaryAmount } from "./schema";
import { type MyListPortletView } from "./schema";
import { type MyListView } from "./schema";
import { type NavigationDirection } from "./schema";
import { type NewB2BReportForm } from "./schema";
import { type OAuth2Token } from "./schema";
import { type Oauth2Flow } from "./schema";
import { type PageInfo } from "./schema";
import { type PageRequest } from "./schema";
import { type PageViewMeta } from "./schema";
import { type PagedActionableMetadata } from "./schema";
import { type PagedAuthorAdminListView } from "./schema";
import { type PagedConnectorCompletionExecutionLogListView } from "./schema";
import { type PagedConnectorContentExecutionListView } from "./schema";
import { type PagedConnectorContentExecutionLogListView } from "./schema";
import { type PagedCustomPageEditListView } from "./schema";
import { type PagedCustomPageListView } from "./schema";
import { type PagedEmailDefaultCategoryAdminView } from "./schema";
import { type PagedEmailEducationCategoryAdminView } from "./schema";
import { type PagedEmailFollowUpCategoryAdminView } from "./schema";
import { type PagedEmailManualCategoryAdminView } from "./schema";
import { type PagedEmailOnboardingCategoryAdminView } from "./schema";
import { type PagedEmailRenewalCategoryAdminView } from "./schema";
import { type PagedExportReportListView } from "./schema";
import { type PagedReportListView } from "./schema";
import { type PagedSummaryListView } from "./schema";
import { type PayPalPaymentView } from "./schema";
import { type PaymentType } from "./schema";
import { type Person } from "./schema";
import { type PortalType } from "./schema";
import { type PortletContainerAdminEditForm } from "./schema";
import { type PortletContainerAdminEditView } from "./schema";
import { type PortletContainerStatus } from "./schema";
import { type PortletLayout } from "./schema";
import { type PortletMetadata } from "./schema";
import { type PortletType } from "./schema";
import { type ProductAdminView } from "./schema";
import { type PromoBoxConfigEditView } from "./schema";
import { type PromoBoxConfigForm } from "./schema";
import { type PromoBoxConfigItemForm } from "./schema";
import { type PromoBoxConfigPreview } from "./schema";
import { type PromoBoxConfigPreviewFilters } from "./schema";
import { type PromoBoxEditProps } from "./schema";
import { type PromoBoxEditView } from "./schema";
import { type PromoBoxLibraryView } from "./schema";
import { type PromoBoxSaveRequest } from "./schema";
import { type PromoBoxTag } from "./schema";
import { type PromoBoxView } from "./schema";
import { type PushFrequency } from "./schema";
import { type ReferencedItemEdit } from "./schema";
import { type ReferencedItemSaveRequest } from "./schema";
import { type ReferencedLearningItem } from "./schema";
import { type ReferencedLearningItemList } from "./schema";
import { type RenewSubscriptionForm } from "./schema";
import { type ReportFrequency } from "./schema";
import { type ReportListView } from "./schema";
import { type ReportSaveRequest } from "./schema";
import { type ReportType } from "./schema";
import { type ResendConfirmationEmailForm } from "./schema";
import { type Result } from "./schema";
import { type RightsProbability } from "./schema";
import { type RightsState } from "./schema";
import { type RoleResource } from "./schema";
import { type RoleResourceConstraint } from "./schema";
import { type SearchFiltersForm } from "./schema";
import { type ShareEvent } from "./schema";
import { type SkillPromptAnswer } from "./schema";
import { type SkillTaxonomy } from "./schema";
import { type StudentIdVerificationProvider } from "./schema";
import { type StudentVerificationStatus } from "./schema";
import { type StudentVerificationView } from "./schema";
import { type SubscriptionAdminForm } from "./schema";
import { type SubscriptionAdminView } from "./schema";
import { type SubscriptionCreditForm } from "./schema";
import { type SubscriptionCreditView } from "./schema";
import { type SubscriptionPayment } from "./schema";
import { type SubscriptionRenewInfoView } from "./schema";
import { type SubscriptionRenewSource } from "./schema";
import { type SubscriptionStatus } from "./schema";
import { type SubscriptionTransaction } from "./schema";
import { type SummaryActivityReportView } from "./schema";
import { type SummaryBulkLoadForm } from "./schema";
import { type SummaryCardDemoProps } from "./schema";
import { type SummaryContent } from "./schema";
import { type SummaryCoverInformation } from "./schema";
import { type SummaryDocumentType } from "./schema";
import { type SummaryId } from "./schema";
import { type SummaryListView } from "./schema";
import { type SummaryMetadata } from "./schema";
import { type SummaryOutputFormat } from "./schema";
import { type SummarySearchFilters } from "./schema";
import { type SummarySourceType } from "./schema";
import { type Tag } from "./schema";
import { type TagAdminFilter } from "./schema";
import { type TagArray } from "./schema";
import { type TagImport } from "./schema";
import { type TagKind } from "./schema";
import { type TagKindChannel } from "./schema";
import { type TagKindClassification } from "./schema";
import { type TagKindDynamicBoolean } from "./schema";
import { type TagKindDynamicNumber } from "./schema";
import { type TagKindDynamicString } from "./schema";
import { type TagKindDynamicVoid } from "./schema";
import { type TagKindKeyQuality } from "./schema";
import { type TagKindPrimarySkill } from "./schema";
import { type TagKindSkill } from "./schema";
import { type TagSourceType } from "./schema";
import { type TagValidationResponse } from "./schema";
import { type TaggedItem } from "./schema";
import { type TeamSubscription } from "./schema";
import { type ToastState } from "./schema";
import { type ToastVariant } from "./schema";
import { type TransferProtocol } from "./schema";
import { type Translations } from "./schema";
import { type UserContext } from "./schema";
import { type UserReportView } from "./schema";
import { type ValidationErrorReasons } from "./schema";
import { type Verb } from "./schema";
import { type VerifaiCreatorPageProps } from "./schema";
import { type VerifaiNavLink } from "./schema";
import { type VerifaiNavigationProps } from "./schema";
import { type VueDemoProps } from "./schema";
import { type WarmupV2EditForm } from "./schema";
import { type WarmupV2RelayType } from "./schema";
import { type WarmupV2SsoRequest } from "./schema";
import { type WarmupV2Submission } from "./schema";
import { type WarmupV2ValidatedForm } from "./schema";
import { type WarmupV2ValidationResult } from "./schema";
import { type WarmupV2WidgetProps } from "./schema";
import { type WishlistManagementData } from "./schema";
import { type WishlistManagementEditorUpdate } from "./schema";
import { type WishlistManagementItem } from "./schema";
import { type WishlistManagementItemList } from "./schema";
import { type WishlistManagementLanguageUpdate } from "./schema";
import { type XApiActorId } from "./schema";
import { type XApiStatement } from "./schema";
// Account
export function serialize_Account(obj: Account): string {
  const serialized = ((value: { homePage: string; name: string }): unknown => {
    return {
      homePage: ((value: string): unknown => {
        return value;
      })(value["homePage"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_Account(str: string): Account | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          homePage: string;
          name: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "homePage")) {
      return { success: false };
    }
    const parsed_homePage = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["homePage"]);
    })();
    if (!parsed_homePage.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    return { success: true, value: { homePage: parsed_homePage.value, name: parsed_name.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ActionableActionbarProps
export function serialize_ActionableActionbarProps(obj: ActionableActionbarProps): string {
  const serialized = ((value: {
    actionableName: string;
    actionableSet: ActionableSetEntry[];
    bookmarked: boolean;
    canAccessCourse: boolean;
    contentMetadata: ContentMetadataActionable;
    freeActionableActive: boolean;
    language: Language;
    showCertificate: boolean;
    userCustomerId: number | null;
    userPortalCanSubscribe: boolean;
    userPortalCorporateId: number | null;
    userPortalNeedsPrivacyPolicy: boolean;
    userPortalSmb: boolean;
    userPortalUsername: string | null;
  }): unknown => {
    return {
      actionableName: ((value: string): unknown => {
        return value;
      })(value["actionableName"]),
      actionableSet: ((value: ActionableSetEntry[]): unknown => {
        return value.map((value: { actionableId: bigint; active: boolean; language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"; name: string; title: string }): unknown => {
          return {
            actionableId: ((value: bigint): unknown => {
              return value;
            })(value["actionableId"]),
            active: ((value: boolean): unknown => {
              return value;
            })(value["active"]),
            language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
              return value;
            })(value["language"]),
            name: ((value: string): unknown => {
              return value;
            })(value["name"]),
            title: ((value: string): unknown => {
              return value;
            })(value["title"]),
          };
        });
      })(value["actionableSet"]),
      bookmarked: ((value: boolean): unknown => {
        return value;
      })(value["bookmarked"]),
      canAccessCourse: ((value: boolean): unknown => {
        return value;
      })(value["canAccessCourse"]),
      contentMetadata: ((value: { common: ContentMetadataCommon; contentId: ActionableId; contentType: "ACTIONABLE" }): unknown => {
        return {
          common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
            return {
              audioActive: ((value: boolean): unknown => {
                return value;
              })(value["audioActive"]),
              coverUri: ((value: string): unknown => {
                return value;
              })(value["coverUri"]),
              description: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["description"]),
              inactive: ((value: boolean): unknown => {
                return value;
              })(value["inactive"]),
              language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                return value;
              })(value["language"]),
              leadIn: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["leadIn"]),
              link: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["link"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
            };
          })(value["common"]),
          contentId: ((value: { contentType: "ACTIONABLE"; id: bigint }): unknown => {
            return {
              contentType: ((value: "ACTIONABLE"): unknown => {
                return value;
              })(value["contentType"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
            };
          })(value["contentId"]),
          contentType: ((value: "ACTIONABLE"): unknown => {
            return value;
          })(value["contentType"]),
        };
      })(value["contentMetadata"]),
      freeActionableActive: ((value: boolean): unknown => {
        return value;
      })(value["freeActionableActive"]),
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      showCertificate: ((value: boolean): unknown => {
        return value;
      })(value["showCertificate"]),
      userCustomerId: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["userCustomerId"]),
      userPortalCanSubscribe: ((value: boolean): unknown => {
        return value;
      })(value["userPortalCanSubscribe"]),
      userPortalCorporateId: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["userPortalCorporateId"]),
      userPortalNeedsPrivacyPolicy: ((value: boolean): unknown => {
        return value;
      })(value["userPortalNeedsPrivacyPolicy"]),
      userPortalSmb: ((value: boolean): unknown => {
        return value;
      })(value["userPortalSmb"]),
      userPortalUsername: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["userPortalUsername"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ActionableActionbarProps(str: string): ActionableActionbarProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          actionableName: string;
          actionableSet: ActionableSetEntry[];
          bookmarked: boolean;
          canAccessCourse: boolean;
          contentMetadata: ContentMetadataActionable;
          freeActionableActive: boolean;
          language: Language;
          showCertificate: boolean;
          userCustomerId: number | null;
          userPortalCanSubscribe: boolean;
          userPortalCorporateId: number | null;
          userPortalNeedsPrivacyPolicy: boolean;
          userPortalSmb: boolean;
          userPortalUsername: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "actionableName")) {
      return { success: false };
    }
    const parsed_actionableName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["actionableName"]);
    })();
    if (!parsed_actionableName.success) {
      return { success: false };
    }
    if (!hasProp(value, "actionableSet")) {
      return { success: false };
    }
    const parsed_actionableSet = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ActionableSetEntry[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ActionableSetEntry } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  actionableId: bigint;
                  active: boolean;
                  language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
                  name: string;
                  title: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "actionableId")) {
              return { success: false };
            }
            const parsed_actionableId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["actionableId"]);
            })();
            if (!parsed_actionableId.success) {
              return { success: false };
            }
            if (!hasProp(value, "active")) {
              return { success: false };
            }
            const parsed_active = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["active"]);
            })();
            if (!parsed_active.success) {
              return { success: false };
            }
            if (!hasProp(value, "language")) {
              return { success: false };
            }
            const parsed_language = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "en":
                    return { success: true, value: "en" };
                  case "de":
                    return { success: true, value: "de" };
                  case "es":
                    return { success: true, value: "es" };
                  case "ru":
                    return { success: true, value: "ru" };
                  case "zh":
                    return { success: true, value: "zh" };
                  case "pt":
                    return { success: true, value: "pt" };
                  case "fr":
                    return { success: true, value: "fr" };
                  default:
                    return { success: false };
                }
              })(value["language"]);
            })();
            if (!parsed_language.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            return { success: true, value: { actionableId: parsed_actionableId.value, active: parsed_active.value, language: parsed_language.value, name: parsed_name.value, title: parsed_title.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["actionableSet"]);
    })();
    if (!parsed_actionableSet.success) {
      return { success: false };
    }
    if (!hasProp(value, "bookmarked")) {
      return { success: false };
    }
    const parsed_bookmarked = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["bookmarked"]);
    })();
    if (!parsed_bookmarked.success) {
      return { success: false };
    }
    if (!hasProp(value, "canAccessCourse")) {
      return { success: false };
    }
    const parsed_canAccessCourse = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["canAccessCourse"]);
    })();
    if (!parsed_canAccessCourse.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentMetadata")) {
      return { success: false };
    }
    const parsed_contentMetadata = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataActionable } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                common: ContentMetadataCommon;
                contentId: ActionableId;
                contentType: "ACTIONABLE";
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "common")) {
            return { success: false };
          }
          const parsed_common = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      audioActive: boolean;
                      coverUri: string;
                      description: string | null;
                      inactive: boolean;
                      language: Language;
                      leadIn: string | null;
                      link: string | null;
                      title: string;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "audioActive")) {
                  return { success: false };
                }
                const parsed_audioActive = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                    if (typeof value !== "boolean") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["audioActive"]);
                })();
                if (!parsed_audioActive.success) {
                  return { success: false };
                }
                if (!hasProp(value, "coverUri")) {
                  return { success: false };
                }
                const parsed_coverUri = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["coverUri"]);
                })();
                if (!parsed_coverUri.success) {
                  return { success: false };
                }
                if (!hasProp(value, "description")) {
                  return { success: false };
                }
                const parsed_description = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["description"]);
                })();
                if (!parsed_description.success) {
                  return { success: false };
                }
                if (!hasProp(value, "inactive")) {
                  return { success: false };
                }
                const parsed_inactive = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                    if (typeof value !== "boolean") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["inactive"]);
                })();
                if (!parsed_inactive.success) {
                  return { success: false };
                }
                if (!hasProp(value, "language")) {
                  return { success: false };
                }
                const parsed_language = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: Language } => {
                    return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      switch (value) {
                        case "en":
                          return { success: true, value: "en" };
                        case "de":
                          return { success: true, value: "de" };
                        case "es":
                          return { success: true, value: "es" };
                        case "ru":
                          return { success: true, value: "ru" };
                        case "zh":
                          return { success: true, value: "zh" };
                        case "pt":
                          return { success: true, value: "pt" };
                        case "fr":
                          return { success: true, value: "fr" };
                        default:
                          return { success: false };
                      }
                    })(value);
                  })(value["language"]);
                })();
                if (!parsed_language.success) {
                  return { success: false };
                }
                if (!hasProp(value, "leadIn")) {
                  return { success: false };
                }
                const parsed_leadIn = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["leadIn"]);
                })();
                if (!parsed_leadIn.success) {
                  return { success: false };
                }
                if (!hasProp(value, "link")) {
                  return { success: false };
                }
                const parsed_link = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["link"]);
                })();
                if (!parsed_link.success) {
                  return { success: false };
                }
                if (!hasProp(value, "title")) {
                  return { success: false };
                }
                const parsed_title = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["title"]);
                })();
                if (!parsed_title.success) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: {
                    audioActive: parsed_audioActive.value,
                    coverUri: parsed_coverUri.value,
                    description: parsed_description.value,
                    inactive: parsed_inactive.value,
                    language: parsed_language.value,
                    leadIn: parsed_leadIn.value,
                    link: parsed_link.value,
                    title: parsed_title.value,
                  },
                };
              })(value);
            })(value["common"]);
          })();
          if (!parsed_common.success) {
            return { success: false };
          }
          if (!hasProp(value, "contentId")) {
            return { success: false };
          }
          const parsed_contentId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ActionableId } => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      contentType: "ACTIONABLE";
                      id: bigint;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "contentType")) {
                  return { success: false };
                }
                const parsed_contentType = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    switch (value) {
                      case "ACTIONABLE":
                        return { success: true, value: "ACTIONABLE" };
                      default:
                        return { success: false };
                    }
                  })(value["contentType"]);
                })();
                if (!parsed_contentType.success) {
                  return { success: false };
                }
                if (!hasProp(value, "id")) {
                  return { success: false };
                }
                const parsed_id = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                    if (typeof value === "number") {
                      return { success: true, value: BigInt(value) };
                    }
                    if (typeof value === "bigint") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["id"]);
                })();
                if (!parsed_id.success) {
                  return { success: false };
                }
                return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
              })(value);
            })(value["contentId"]);
          })();
          if (!parsed_contentId.success) {
            return { success: false };
          }
          if (!hasProp(value, "contentType")) {
            return { success: false };
          }
          const parsed_contentType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              switch (value) {
                case "ACTIONABLE":
                  return { success: true, value: "ACTIONABLE" };
                default:
                  return { success: false };
              }
            })(value["contentType"]);
          })();
          if (!parsed_contentType.success) {
            return { success: false };
          }
          return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value, contentType: parsed_contentType.value } };
        })(value);
      })(value["contentMetadata"]);
    })();
    if (!parsed_contentMetadata.success) {
      return { success: false };
    }
    if (!hasProp(value, "freeActionableActive")) {
      return { success: false };
    }
    const parsed_freeActionableActive = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["freeActionableActive"]);
    })();
    if (!parsed_freeActionableActive.success) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language } => {
        return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "en":
              return { success: true, value: "en" };
            case "de":
              return { success: true, value: "de" };
            case "es":
              return { success: true, value: "es" };
            case "ru":
              return { success: true, value: "ru" };
            case "zh":
              return { success: true, value: "zh" };
            case "pt":
              return { success: true, value: "pt" };
            case "fr":
              return { success: true, value: "fr" };
            default:
              return { success: false };
          }
        })(value);
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "showCertificate")) {
      return { success: false };
    }
    const parsed_showCertificate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["showCertificate"]);
    })();
    if (!parsed_showCertificate.success) {
      return { success: false };
    }
    if (!hasProp(value, "userCustomerId")) {
      return { success: false };
    }
    const parsed_userCustomerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["userCustomerId"]);
    })();
    if (!parsed_userCustomerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "userPortalCanSubscribe")) {
      return { success: false };
    }
    const parsed_userPortalCanSubscribe = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["userPortalCanSubscribe"]);
    })();
    if (!parsed_userPortalCanSubscribe.success) {
      return { success: false };
    }
    if (!hasProp(value, "userPortalCorporateId")) {
      return { success: false };
    }
    const parsed_userPortalCorporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["userPortalCorporateId"]);
    })();
    if (!parsed_userPortalCorporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "userPortalNeedsPrivacyPolicy")) {
      return { success: false };
    }
    const parsed_userPortalNeedsPrivacyPolicy = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["userPortalNeedsPrivacyPolicy"]);
    })();
    if (!parsed_userPortalNeedsPrivacyPolicy.success) {
      return { success: false };
    }
    if (!hasProp(value, "userPortalSmb")) {
      return { success: false };
    }
    const parsed_userPortalSmb = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["userPortalSmb"]);
    })();
    if (!parsed_userPortalSmb.success) {
      return { success: false };
    }
    if (!hasProp(value, "userPortalUsername")) {
      return { success: false };
    }
    const parsed_userPortalUsername = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["userPortalUsername"]);
    })();
    if (!parsed_userPortalUsername.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        actionableName: parsed_actionableName.value,
        actionableSet: parsed_actionableSet.value,
        bookmarked: parsed_bookmarked.value,
        canAccessCourse: parsed_canAccessCourse.value,
        contentMetadata: parsed_contentMetadata.value,
        freeActionableActive: parsed_freeActionableActive.value,
        language: parsed_language.value,
        showCertificate: parsed_showCertificate.value,
        userCustomerId: parsed_userCustomerId.value,
        userPortalCanSubscribe: parsed_userPortalCanSubscribe.value,
        userPortalCorporateId: parsed_userPortalCorporateId.value,
        userPortalNeedsPrivacyPolicy: parsed_userPortalNeedsPrivacyPolicy.value,
        userPortalSmb: parsed_userPortalSmb.value,
        userPortalUsername: parsed_userPortalUsername.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ActionableActivityReport
export function serialize_ActionableActivityReport(obj: ActionableActivityReport): string {
  const serialized = ((value: { actionable: ActionableMetadata; customer: CustomerReportView | null; timeStamp: DateTime<true> }): unknown => {
    return {
      actionable: ((value: {
        actionableId: bigint;
        activatedAt: DateTime<true> | null;
        active: boolean;
        coverUri: string;
        description: string;
        language: Language;
        modifiedAt: DateTime<true>;
        name: string;
        tags: Tag[];
        title: string;
      }): unknown => {
        return {
          actionableId: ((value: bigint): unknown => {
            return value;
          })(value["actionableId"]),
          activatedAt: ((value: DateTime<true> | null): unknown => {
            if (value === null) {
              return null;
            }
            return value.toMillis();
          })(value["activatedAt"]),
          active: ((value: boolean): unknown => {
            return value;
          })(value["active"]),
          coverUri: ((value: string): unknown => {
            return value;
          })(value["coverUri"]),
          description: ((value: string): unknown => {
            return value;
          })(value["description"]),
          language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
            return value;
          })(value["language"]),
          modifiedAt: ((value: DateTime<true>): unknown => {
            return value.toMillis();
          })(value["modifiedAt"]),
          name: ((value: string): unknown => {
            return value;
          })(value["name"]),
          tags: ((value: Tag[]): unknown => {
            return value.map(
              (
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                  | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
              ): unknown => {
                switch (value.discriminator) {
                  case "dynamicString": {
                    return {
                      discriminator: "dynamicString",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicString"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicBoolean": {
                    return {
                      discriminator: "dynamicBoolean",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicBoolean"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: boolean): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicNumber": {
                    return {
                      discriminator: "dynamicNumber",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicNumber"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: number): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicVoid": {
                    return {
                      discriminator: "dynamicVoid",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicVoid"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                    };
                  }
                  case "channel": {
                    return {
                      discriminator: "channel",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                        return {
                          discriminator: ((value: "channel"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "channel"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "primary-skill": {
                    return {
                      discriminator: "primary-skill",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                        return {
                          discriminator: ((value: "primary-skill"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "primary-skill"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "skill": {
                    return {
                      discriminator: "skill",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                        return {
                          discriminator: ((value: "skill"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "skill"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "key-quality": {
                    return {
                      discriminator: "key-quality",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                        return {
                          discriminator: ((value: "key-quality"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "key-quality"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "classification": {
                    return {
                      discriminator: "classification",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                        return {
                          discriminator: ((value: "classification"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "classification"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                }
              },
            );
          })(value["tags"]),
          title: ((value: string): unknown => {
            return value;
          })(value["title"]),
        };
      })(value["actionable"]),
      customer: ((
        value: {
          country: string | null;
          customerId: number;
          email: string | null;
          endDate: { year: number; month: number; day: number };
          externalId: string | null;
          extraField1: string | null;
          extraField2: string | null;
          extraField3: string | null;
          firstName: string | null;
          language: Language;
          lastName: string | null;
          signupDate: { year: number; month: number; day: number };
          status: string;
          title: number | null;
          town: string | null;
          userGroups: string[];
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          country: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["country"]),
          customerId: ((value: number): unknown => {
            return value;
          })(value["customerId"]),
          email: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["email"]),
          endDate: ((value: { year: number; month: number; day: number }): unknown => {
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["endDate"]),
          externalId: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["externalId"]),
          extraField1: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["extraField1"]),
          extraField2: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["extraField2"]),
          extraField3: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["extraField3"]),
          firstName: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["firstName"]),
          language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
            return value;
          })(value["language"]),
          lastName: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["lastName"]),
          signupDate: ((value: { year: number; month: number; day: number }): unknown => {
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["signupDate"]),
          status: ((value: string): unknown => {
            return value;
          })(value["status"]),
          title: ((value: number | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["title"]),
          town: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["town"]),
          userGroups: ((value: string[]): unknown => {
            return value.map((value: string): unknown => {
              return value;
            });
          })(value["userGroups"]),
        };
      })(value["customer"]),
      timeStamp: ((value: DateTime<true>): unknown => {
        return value.toISO();
      })(value["timeStamp"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ActionableActivityReport(str: string): ActionableActivityReport | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          actionable: ActionableMetadata;
          customer: CustomerReportView | null;
          timeStamp: DateTime<true>;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "actionable")) {
      return { success: false };
    }
    const parsed_actionable = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ActionableMetadata } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                actionableId: bigint;
                activatedAt: DateTime<true> | null;
                active: boolean;
                coverUri: string;
                description: string;
                language: Language;
                modifiedAt: DateTime<true>;
                name: string;
                tags: Tag[];
                title: string;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "actionableId")) {
            return { success: false };
          }
          const parsed_actionableId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
              if (typeof value === "number") {
                return { success: true, value: BigInt(value) };
              }
              if (typeof value === "bigint") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["actionableId"]);
          })();
          if (!parsed_actionableId.success) {
            return { success: false };
          }
          if (!hasProp(value, "activatedAt")) {
            return { success: false };
          }
          const parsed_activatedAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "number") {
                return { success: false };
              }
              const parsed = DateTime.fromMillis(value);
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: parsed };
            })(value["activatedAt"]);
          })();
          if (!parsed_activatedAt.success) {
            return { success: false };
          }
          if (!hasProp(value, "active")) {
            return { success: false };
          }
          const parsed_active = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["active"]);
          })();
          if (!parsed_active.success) {
            return { success: false };
          }
          if (!hasProp(value, "coverUri")) {
            return { success: false };
          }
          const parsed_coverUri = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["coverUri"]);
          })();
          if (!parsed_coverUri.success) {
            return { success: false };
          }
          if (!hasProp(value, "description")) {
            return { success: false };
          }
          const parsed_description = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["description"]);
          })();
          if (!parsed_description.success) {
            return { success: false };
          }
          if (!hasProp(value, "language")) {
            return { success: false };
          }
          const parsed_language = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Language } => {
              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "en":
                    return { success: true, value: "en" };
                  case "de":
                    return { success: true, value: "de" };
                  case "es":
                    return { success: true, value: "es" };
                  case "ru":
                    return { success: true, value: "ru" };
                  case "zh":
                    return { success: true, value: "zh" };
                  case "pt":
                    return { success: true, value: "pt" };
                  case "fr":
                    return { success: true, value: "fr" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["language"]);
          })();
          if (!parsed_language.success) {
            return { success: false };
          }
          if (!hasProp(value, "modifiedAt")) {
            return { success: false };
          }
          const parsed_modifiedAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
              if (typeof value !== "number") {
                return { success: false };
              }
              const parsed = DateTime.fromMillis(value);
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: parsed };
            })(value["modifiedAt"]);
          })();
          if (!parsed_modifiedAt.success) {
            return { success: false };
          }
          if (!hasProp(value, "name")) {
            return { success: false };
          }
          const parsed_name = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["name"]);
          })();
          if (!parsed_name.success) {
            return { success: false };
          }
          if (!hasProp(value, "tags")) {
            return { success: false };
          }
          const parsed_tags = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                        | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                        | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                        | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                        | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                        | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                        | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                        | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                        | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                    } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value:
                          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                          | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                          | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                      } => {
                    if (value == null) {
                      return { success: false };
                    }
                    if (typeof value != "object") {
                      return { success: false };
                    }
                    if (!hasProp(value, "discriminator")) {
                      return { success: false };
                    }
                    if (typeof value["discriminator"] != "string") {
                      return { success: false };
                    }
                    const discriminator = value["discriminator"];
                    switch (discriminator) {
                      case "dynamicString": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicString;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicString;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicString":
                                          return { success: true, value: "dynamicString" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                      }
                      case "dynamicBoolean": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicBoolean;
                                value: boolean;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicBoolean;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicBoolean":
                                          return { success: true, value: "dynamicBoolean" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                      }
                      case "dynamicNumber": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicNumber;
                                value: number;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicNumber;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicNumber":
                                          return { success: true, value: "dynamicNumber" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: number } => {
                              if (typeof value === "number") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                      }
                      case "dynamicVoid": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicVoid;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicVoid;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicVoid":
                                          return { success: true, value: "dynamicVoid" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                      }
                      case "channel": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindChannel;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindChannel;
                                      id: bigint;
                                      kind: KindChannel;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "channel":
                                          return { success: true, value: "channel" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "channel":
                                          return { success: true, value: "channel" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                      }
                      case "primary-skill": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindPrimarySkill;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindPrimarySkill;
                                      id: bigint;
                                      kind: KindPrimarySkill;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "primary-skill":
                                          return { success: true, value: "primary-skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "primary-skill":
                                          return { success: true, value: "primary-skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                      }
                      case "skill": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindSkill;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindSkill;
                                      id: bigint;
                                      kind: KindSkill;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "skill":
                                          return { success: true, value: "skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "skill":
                                          return { success: true, value: "skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                      }
                      case "key-quality": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindKeyQuality;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindKeyQuality;
                                      id: bigint;
                                      kind: KindKeyQuality;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "key-quality":
                                          return { success: true, value: "key-quality" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "key-quality":
                                          return { success: true, value: "key-quality" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                      }
                      case "classification": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindClassification;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindClassification;
                                      id: bigint;
                                      kind: KindClassification;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "classification":
                                          return { success: true, value: "classification" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "classification":
                                          return { success: true, value: "classification" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                      }
                      default:
                        return { success: false };
                    }
                  })(value);
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["tags"]);
          })();
          if (!parsed_tags.success) {
            return { success: false };
          }
          if (!hasProp(value, "title")) {
            return { success: false };
          }
          const parsed_title = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["title"]);
          })();
          if (!parsed_title.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              actionableId: parsed_actionableId.value,
              activatedAt: parsed_activatedAt.value,
              active: parsed_active.value,
              coverUri: parsed_coverUri.value,
              description: parsed_description.value,
              language: parsed_language.value,
              modifiedAt: parsed_modifiedAt.value,
              name: parsed_name.value,
              tags: parsed_tags.value,
              title: parsed_title.value,
            },
          };
        })(value);
      })(value["actionable"]);
    })();
    if (!parsed_actionable.success) {
      return { success: false };
    }
    if (!hasProp(value, "customer")) {
      return { success: false };
    }
    const parsed_customer = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CustomerReportView | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                country: string | null;
                customerId: number;
                email: string | null;
                endDate: { year: number; month: number; day: number };
                externalId: string | null;
                extraField1: string | null;
                extraField2: string | null;
                extraField3: string | null;
                firstName: string | null;
                language: Language;
                lastName: string | null;
                signupDate: { year: number; month: number; day: number };
                status: string;
                title: number | null;
                town: string | null;
                userGroups: string[];
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "country")) {
            return { success: false };
          }
          const parsed_country = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["country"]);
          })();
          if (!parsed_country.success) {
            return { success: false };
          }
          if (!hasProp(value, "customerId")) {
            return { success: false };
          }
          const parsed_customerId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["customerId"]);
          })();
          if (!parsed_customerId.success) {
            return { success: false };
          }
          if (!hasProp(value, "email")) {
            return { success: false };
          }
          const parsed_email = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["email"]);
          })();
          if (!parsed_email.success) {
            return { success: false };
          }
          if (!hasProp(value, "endDate")) {
            return { success: false };
          }
          const parsed_endDate = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["endDate"]);
          })();
          if (!parsed_endDate.success) {
            return { success: false };
          }
          if (!hasProp(value, "externalId")) {
            return { success: false };
          }
          const parsed_externalId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["externalId"]);
          })();
          if (!parsed_externalId.success) {
            return { success: false };
          }
          if (!hasProp(value, "extraField1")) {
            return { success: false };
          }
          const parsed_extraField1 = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["extraField1"]);
          })();
          if (!parsed_extraField1.success) {
            return { success: false };
          }
          if (!hasProp(value, "extraField2")) {
            return { success: false };
          }
          const parsed_extraField2 = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["extraField2"]);
          })();
          if (!parsed_extraField2.success) {
            return { success: false };
          }
          if (!hasProp(value, "extraField3")) {
            return { success: false };
          }
          const parsed_extraField3 = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["extraField3"]);
          })();
          if (!parsed_extraField3.success) {
            return { success: false };
          }
          if (!hasProp(value, "firstName")) {
            return { success: false };
          }
          const parsed_firstName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["firstName"]);
          })();
          if (!parsed_firstName.success) {
            return { success: false };
          }
          if (!hasProp(value, "language")) {
            return { success: false };
          }
          const parsed_language = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Language } => {
              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "en":
                    return { success: true, value: "en" };
                  case "de":
                    return { success: true, value: "de" };
                  case "es":
                    return { success: true, value: "es" };
                  case "ru":
                    return { success: true, value: "ru" };
                  case "zh":
                    return { success: true, value: "zh" };
                  case "pt":
                    return { success: true, value: "pt" };
                  case "fr":
                    return { success: true, value: "fr" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["language"]);
          })();
          if (!parsed_language.success) {
            return { success: false };
          }
          if (!hasProp(value, "lastName")) {
            return { success: false };
          }
          const parsed_lastName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lastName"]);
          })();
          if (!parsed_lastName.success) {
            return { success: false };
          }
          if (!hasProp(value, "signupDate")) {
            return { success: false };
          }
          const parsed_signupDate = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["signupDate"]);
          })();
          if (!parsed_signupDate.success) {
            return { success: false };
          }
          if (!hasProp(value, "status")) {
            return { success: false };
          }
          const parsed_status = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["status"]);
          })();
          if (!parsed_status.success) {
            return { success: false };
          }
          if (!hasProp(value, "title")) {
            return { success: false };
          }
          const parsed_title = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["title"]);
          })();
          if (!parsed_title.success) {
            return { success: false };
          }
          if (!hasProp(value, "town")) {
            return { success: false };
          }
          const parsed_town = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["town"]);
          })();
          if (!parsed_town.success) {
            return { success: false };
          }
          if (!hasProp(value, "userGroups")) {
            return { success: false };
          }
          const parsed_userGroups = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["userGroups"]);
          })();
          if (!parsed_userGroups.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              country: parsed_country.value,
              customerId: parsed_customerId.value,
              email: parsed_email.value,
              endDate: parsed_endDate.value,
              externalId: parsed_externalId.value,
              extraField1: parsed_extraField1.value,
              extraField2: parsed_extraField2.value,
              extraField3: parsed_extraField3.value,
              firstName: parsed_firstName.value,
              language: parsed_language.value,
              lastName: parsed_lastName.value,
              signupDate: parsed_signupDate.value,
              status: parsed_status.value,
              title: parsed_title.value,
              town: parsed_town.value,
              userGroups: parsed_userGroups.value,
            },
          };
        })(value);
      })(value["customer"]);
    })();
    if (!parsed_customer.success) {
      return { success: false };
    }
    if (!hasProp(value, "timeStamp")) {
      return { success: false };
    }
    const parsed_timeStamp = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["timeStamp"]);
    })();
    if (!parsed_timeStamp.success) {
      return { success: false };
    }
    return { success: true, value: { actionable: parsed_actionable.value, customer: parsed_customer.value, timeStamp: parsed_timeStamp.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ActionableBulkLoadForm
export function serialize_ActionableBulkLoadForm(obj: ActionableBulkLoadForm): string {
  const serialized = ((value: { actionableIds: bigint[]; corporateId: number | null; includeInactive: boolean }): unknown => {
    return {
      actionableIds: ((value: bigint[]): unknown => {
        return value.map((value: bigint): unknown => {
          return value;
        });
      })(value["actionableIds"]),
      corporateId: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["corporateId"]),
      includeInactive: ((value: boolean): unknown => {
        return value;
      })(value["includeInactive"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ActionableBulkLoadForm(str: string): ActionableBulkLoadForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          actionableIds: bigint[];
          corporateId: number | null;
          includeInactive: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "actionableIds")) {
      return { success: false };
    }
    const parsed_actionableIds = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: bigint } => {
          if (typeof value === "number") {
            return { success: true, value: BigInt(value) };
          }
          if (typeof value === "bigint") {
            return { success: true, value: value };
          }
          return { success: false };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["actionableIds"]);
    })();
    if (!parsed_actionableIds.success) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "includeInactive")) {
      return { success: false };
    }
    const parsed_includeInactive = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["includeInactive"]);
    })();
    if (!parsed_includeInactive.success) {
      return { success: false };
    }
    return { success: true, value: { actionableIds: parsed_actionableIds.value, corporateId: parsed_corporateId.value, includeInactive: parsed_includeInactive.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ActionableId
export function serialize_ActionableId(obj: ActionableId): string {
  const serialized = ((value: { contentType: "ACTIONABLE"; id: bigint }): unknown => {
    return {
      contentType: ((value: "ACTIONABLE"): unknown => {
        return value;
      })(value["contentType"]),
      id: ((value: bigint): unknown => {
        return value;
      })(value["id"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ActionableId(str: string): ActionableId | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          contentType: "ACTIONABLE";
          id: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "contentType")) {
      return { success: false };
    }
    const parsed_contentType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "ACTIONABLE":
            return { success: true, value: "ACTIONABLE" };
          default:
            return { success: false };
        }
      })(value["contentType"]);
    })();
    if (!parsed_contentType.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ActionableListPortletView
export function serialize_ActionableListPortletView(obj: ActionableListPortletView): string {
  const serialized = ((value: { items: ActionableMetadata[]; metadata: PortletMetadata; page: number; pageSize: number; totalCount: bigint; viewAllUri: string | null }): unknown => {
    return {
      items: ((value: ActionableMetadata[]): unknown => {
        return value.map(
          (value: { actionableId: bigint; activatedAt: DateTime<true> | null; active: boolean; coverUri: string; description: string; language: Language; modifiedAt: DateTime<true>; name: string; tags: Tag[]; title: string }): unknown => {
            return {
              actionableId: ((value: bigint): unknown => {
                return value;
              })(value["actionableId"]),
              activatedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["activatedAt"]),
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              coverUri: ((value: string): unknown => {
                return value;
              })(value["coverUri"]),
              description: ((value: string): unknown => {
                return value;
              })(value["description"]),
              language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                return value;
              })(value["language"]),
              modifiedAt: ((value: DateTime<true>): unknown => {
                return value.toMillis();
              })(value["modifiedAt"]),
              name: ((value: string): unknown => {
                return value;
              })(value["name"]),
              tags: ((value: Tag[]): unknown => {
                return value.map(
                  (
                    value:
                      | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                      | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                      | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                      | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                      | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                      | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                      | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                      | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                      | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
                  ): unknown => {
                    switch (value.discriminator) {
                      case "dynamicString": {
                        return {
                          discriminator: "dynamicString",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicString"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicBoolean": {
                        return {
                          discriminator: "dynamicBoolean",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicBoolean"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: boolean): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicNumber": {
                        return {
                          discriminator: "dynamicNumber",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicNumber"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: number): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicVoid": {
                        return {
                          discriminator: "dynamicVoid",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicVoid"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                        };
                      }
                      case "channel": {
                        return {
                          discriminator: "channel",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                            return {
                              discriminator: ((value: "channel"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "channel"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "primary-skill": {
                        return {
                          discriminator: "primary-skill",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                            return {
                              discriminator: ((value: "primary-skill"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "primary-skill"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "skill": {
                        return {
                          discriminator: "skill",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                            return {
                              discriminator: ((value: "skill"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "skill"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "key-quality": {
                        return {
                          discriminator: "key-quality",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                            return {
                              discriminator: ((value: "key-quality"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "key-quality"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "classification": {
                        return {
                          discriminator: "classification",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                            return {
                              discriminator: ((value: "classification"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "classification"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                    }
                  },
                );
              })(value["tags"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
            };
          },
        );
      })(value["items"]),
      metadata: ((value: {
        custom: boolean;
        defaultFilterAllLanguages: boolean;
        embedSortingFiltering: boolean;
        hideTitle: boolean;
        itemCount: number;
        itemLayout: ItemLayout;
        name: string;
        portletId: number;
        portletLayout: PortletLayout;
        subType: string | null;
        subtitle: string;
        title: string;
        type: PortletType;
      }): unknown => {
        return {
          custom: ((value: boolean): unknown => {
            return value;
          })(value["custom"]),
          defaultFilterAllLanguages: ((value: boolean): unknown => {
            return value;
          })(value["defaultFilterAllLanguages"]),
          embedSortingFiltering: ((value: boolean): unknown => {
            return value;
          })(value["embedSortingFiltering"]),
          hideTitle: ((value: boolean): unknown => {
            return value;
          })(value["hideTitle"]),
          itemCount: ((value: number): unknown => {
            return value;
          })(value["itemCount"]),
          itemLayout: ((value: "MINI" | "REGULAR"): unknown => {
            return value;
          })(value["itemLayout"]),
          name: ((value: string): unknown => {
            return value;
          })(value["name"]),
          portletId: ((value: number): unknown => {
            return value;
          })(value["portletId"]),
          portletLayout: ((value: "GRID" | "CAROUSEL"): unknown => {
            return value;
          })(value["portletLayout"]),
          subType: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["subType"]),
          subtitle: ((value: string): unknown => {
            return value;
          })(value["subtitle"]),
          title: ((value: string): unknown => {
            return value;
          })(value["title"]),
          type: ((
            value:
              | "IMAGETEXT"
              | "SUMMARYLIST"
              | "TRENDINGCHANNEL"
              | "FEATUREDCHANNEL"
              | "FOLLOWINGCHANNEL"
              | "MYLIST"
              | "PRIMARYTOPICCHANNEL"
              | "CHANNELLIST"
              | "ANNOTATIONLIST"
              | "LGXPLIST"
              | "SKETCHNOTESLGXP"
              | "ACTIONABLELIST"
              | "ACTIONABLELISTCUSTOM"
              | "MYACTIONABLES"
              | "FREESUMMARY"
              | "SUMMARYLISTCUSTOM"
              | "CUSTOMPAGELIST"
              | "PROMOBOXCONFIG"
              | "MYMIABS"
              | "ASKGETABSTRACT"
              | "CUSTOMIZEDMIABS",
          ): unknown => {
            return value;
          })(value["type"]),
        };
      })(value["metadata"]),
      page: ((value: number): unknown => {
        return value;
      })(value["page"]),
      pageSize: ((value: number): unknown => {
        return value;
      })(value["pageSize"]),
      totalCount: ((value: bigint): unknown => {
        return value;
      })(value["totalCount"]),
      viewAllUri: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["viewAllUri"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ActionableListPortletView(str: string): ActionableListPortletView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: ActionableMetadata[];
          metadata: PortletMetadata;
          page: number;
          pageSize: number;
          totalCount: bigint;
          viewAllUri: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ActionableMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ActionableMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  actionableId: bigint;
                  activatedAt: DateTime<true> | null;
                  active: boolean;
                  coverUri: string;
                  description: string;
                  language: Language;
                  modifiedAt: DateTime<true>;
                  name: string;
                  tags: Tag[];
                  title: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "actionableId")) {
              return { success: false };
            }
            const parsed_actionableId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["actionableId"]);
            })();
            if (!parsed_actionableId.success) {
              return { success: false };
            }
            if (!hasProp(value, "activatedAt")) {
              return { success: false };
            }
            const parsed_activatedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["activatedAt"]);
            })();
            if (!parsed_activatedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "active")) {
              return { success: false };
            }
            const parsed_active = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["active"]);
            })();
            if (!parsed_active.success) {
              return { success: false };
            }
            if (!hasProp(value, "coverUri")) {
              return { success: false };
            }
            const parsed_coverUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["coverUri"]);
            })();
            if (!parsed_coverUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "description")) {
              return { success: false };
            }
            const parsed_description = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["description"]);
            })();
            if (!parsed_description.success) {
              return { success: false };
            }
            if (!hasProp(value, "language")) {
              return { success: false };
            }
            const parsed_language = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Language } => {
                return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "en":
                      return { success: true, value: "en" };
                    case "de":
                      return { success: true, value: "de" };
                    case "es":
                      return { success: true, value: "es" };
                    case "ru":
                      return { success: true, value: "ru" };
                    case "zh":
                      return { success: true, value: "zh" };
                    case "pt":
                      return { success: true, value: "pt" };
                    case "fr":
                      return { success: true, value: "fr" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["language"]);
            })();
            if (!parsed_language.success) {
              return { success: false };
            }
            if (!hasProp(value, "modifiedAt")) {
              return { success: false };
            }
            const parsed_modifiedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["modifiedAt"]);
            })();
            if (!parsed_modifiedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "tags")) {
              return { success: false };
            }
            const parsed_tags = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value:
                          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                          | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                          | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                      } => {
                    return ((
                      value: unknown,
                    ):
                      | { success: false }
                      | {
                          success: true;
                          value:
                            | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                            | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                            | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                            | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                            | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                            | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                            | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                            | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                            | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                        } => {
                      if (value == null) {
                        return { success: false };
                      }
                      if (typeof value != "object") {
                        return { success: false };
                      }
                      if (!hasProp(value, "discriminator")) {
                        return { success: false };
                      }
                      if (typeof value["discriminator"] != "string") {
                        return { success: false };
                      }
                      const discriminator = value["discriminator"];
                      switch (discriminator) {
                        case "dynamicString": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicString;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicString;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicString":
                                            return { success: true, value: "dynamicString" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                        }
                        case "dynamicBoolean": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicBoolean;
                                  value: boolean;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicBoolean;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicBoolean":
                                            return { success: true, value: "dynamicBoolean" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                        }
                        case "dynamicNumber": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicNumber;
                                  value: number;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicNumber;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicNumber":
                                            return { success: true, value: "dynamicNumber" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: number } => {
                                if (typeof value === "number") {
                                  return { success: true, value: value };
                                }
                                return { success: false };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                        }
                        case "dynamicVoid": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicVoid;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicVoid;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicVoid":
                                            return { success: true, value: "dynamicVoid" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                        }
                        case "channel": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindChannel;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindChannel;
                                        id: bigint;
                                        kind: KindChannel;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "channel":
                                            return { success: true, value: "channel" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "channel":
                                            return { success: true, value: "channel" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                        }
                        case "primary-skill": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindPrimarySkill;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindPrimarySkill;
                                        id: bigint;
                                        kind: KindPrimarySkill;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "primary-skill":
                                            return { success: true, value: "primary-skill" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "primary-skill":
                                            return { success: true, value: "primary-skill" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                        }
                        case "skill": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindSkill;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindSkill;
                                        id: bigint;
                                        kind: KindSkill;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "skill":
                                            return { success: true, value: "skill" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "skill":
                                            return { success: true, value: "skill" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                        }
                        case "key-quality": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindKeyQuality;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindKeyQuality;
                                        id: bigint;
                                        kind: KindKeyQuality;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "key-quality":
                                            return { success: true, value: "key-quality" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "key-quality":
                                            return { success: true, value: "key-quality" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                        }
                        case "classification": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindClassification;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindClassification;
                                        id: bigint;
                                        kind: KindClassification;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "classification":
                                            return { success: true, value: "classification" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "classification":
                                            return { success: true, value: "classification" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                        }
                        default:
                          return { success: false };
                      }
                    })(value);
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["tags"]);
            })();
            if (!parsed_tags.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                actionableId: parsed_actionableId.value,
                activatedAt: parsed_activatedAt.value,
                active: parsed_active.value,
                coverUri: parsed_coverUri.value,
                description: parsed_description.value,
                language: parsed_language.value,
                modifiedAt: parsed_modifiedAt.value,
                name: parsed_name.value,
                tags: parsed_tags.value,
                title: parsed_title.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "metadata")) {
      return { success: false };
    }
    const parsed_metadata = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PortletMetadata } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                custom: boolean;
                defaultFilterAllLanguages: boolean;
                embedSortingFiltering: boolean;
                hideTitle: boolean;
                itemCount: number;
                itemLayout: ItemLayout;
                name: string;
                portletId: number;
                portletLayout: PortletLayout;
                subType: string | null;
                subtitle: string;
                title: string;
                type: PortletType;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "custom")) {
            return { success: false };
          }
          const parsed_custom = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["custom"]);
          })();
          if (!parsed_custom.success) {
            return { success: false };
          }
          if (!hasProp(value, "defaultFilterAllLanguages")) {
            return { success: false };
          }
          const parsed_defaultFilterAllLanguages = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["defaultFilterAllLanguages"]);
          })();
          if (!parsed_defaultFilterAllLanguages.success) {
            return { success: false };
          }
          if (!hasProp(value, "embedSortingFiltering")) {
            return { success: false };
          }
          const parsed_embedSortingFiltering = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["embedSortingFiltering"]);
          })();
          if (!parsed_embedSortingFiltering.success) {
            return { success: false };
          }
          if (!hasProp(value, "hideTitle")) {
            return { success: false };
          }
          const parsed_hideTitle = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["hideTitle"]);
          })();
          if (!parsed_hideTitle.success) {
            return { success: false };
          }
          if (!hasProp(value, "itemCount")) {
            return { success: false };
          }
          const parsed_itemCount = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["itemCount"]);
          })();
          if (!parsed_itemCount.success) {
            return { success: false };
          }
          if (!hasProp(value, "itemLayout")) {
            return { success: false };
          }
          const parsed_itemLayout = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ItemLayout } => {
              return ((value: unknown): { success: false } | { success: true; value: "MINI" | "REGULAR" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "MINI":
                    return { success: true, value: "MINI" };
                  case "REGULAR":
                    return { success: true, value: "REGULAR" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["itemLayout"]);
          })();
          if (!parsed_itemLayout.success) {
            return { success: false };
          }
          if (!hasProp(value, "name")) {
            return { success: false };
          }
          const parsed_name = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["name"]);
          })();
          if (!parsed_name.success) {
            return { success: false };
          }
          if (!hasProp(value, "portletId")) {
            return { success: false };
          }
          const parsed_portletId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["portletId"]);
          })();
          if (!parsed_portletId.success) {
            return { success: false };
          }
          if (!hasProp(value, "portletLayout")) {
            return { success: false };
          }
          const parsed_portletLayout = (() => {
            return ((value: unknown): { success: false } | { success: true; value: PortletLayout } => {
              return ((value: unknown): { success: false } | { success: true; value: "GRID" | "CAROUSEL" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "GRID":
                    return { success: true, value: "GRID" };
                  case "CAROUSEL":
                    return { success: true, value: "CAROUSEL" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["portletLayout"]);
          })();
          if (!parsed_portletLayout.success) {
            return { success: false };
          }
          if (!hasProp(value, "subType")) {
            return { success: false };
          }
          const parsed_subType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["subType"]);
          })();
          if (!parsed_subType.success) {
            return { success: false };
          }
          if (!hasProp(value, "subtitle")) {
            return { success: false };
          }
          const parsed_subtitle = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["subtitle"]);
          })();
          if (!parsed_subtitle.success) {
            return { success: false };
          }
          if (!hasProp(value, "title")) {
            return { success: false };
          }
          const parsed_title = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["title"]);
          })();
          if (!parsed_title.success) {
            return { success: false };
          }
          if (!hasProp(value, "type")) {
            return { success: false };
          }
          const parsed_type = (() => {
            return ((value: unknown): { success: false } | { success: true; value: PortletType } => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value:
                      | "IMAGETEXT"
                      | "SUMMARYLIST"
                      | "TRENDINGCHANNEL"
                      | "FEATUREDCHANNEL"
                      | "FOLLOWINGCHANNEL"
                      | "MYLIST"
                      | "PRIMARYTOPICCHANNEL"
                      | "CHANNELLIST"
                      | "ANNOTATIONLIST"
                      | "LGXPLIST"
                      | "SKETCHNOTESLGXP"
                      | "ACTIONABLELIST"
                      | "ACTIONABLELISTCUSTOM"
                      | "MYACTIONABLES"
                      | "FREESUMMARY"
                      | "SUMMARYLISTCUSTOM"
                      | "CUSTOMPAGELIST"
                      | "PROMOBOXCONFIG"
                      | "MYMIABS"
                      | "ASKGETABSTRACT"
                      | "CUSTOMIZEDMIABS";
                  } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "IMAGETEXT":
                    return { success: true, value: "IMAGETEXT" };
                  case "SUMMARYLIST":
                    return { success: true, value: "SUMMARYLIST" };
                  case "TRENDINGCHANNEL":
                    return { success: true, value: "TRENDINGCHANNEL" };
                  case "FEATUREDCHANNEL":
                    return { success: true, value: "FEATUREDCHANNEL" };
                  case "FOLLOWINGCHANNEL":
                    return { success: true, value: "FOLLOWINGCHANNEL" };
                  case "MYLIST":
                    return { success: true, value: "MYLIST" };
                  case "PRIMARYTOPICCHANNEL":
                    return { success: true, value: "PRIMARYTOPICCHANNEL" };
                  case "CHANNELLIST":
                    return { success: true, value: "CHANNELLIST" };
                  case "ANNOTATIONLIST":
                    return { success: true, value: "ANNOTATIONLIST" };
                  case "LGXPLIST":
                    return { success: true, value: "LGXPLIST" };
                  case "SKETCHNOTESLGXP":
                    return { success: true, value: "SKETCHNOTESLGXP" };
                  case "ACTIONABLELIST":
                    return { success: true, value: "ACTIONABLELIST" };
                  case "ACTIONABLELISTCUSTOM":
                    return { success: true, value: "ACTIONABLELISTCUSTOM" };
                  case "MYACTIONABLES":
                    return { success: true, value: "MYACTIONABLES" };
                  case "FREESUMMARY":
                    return { success: true, value: "FREESUMMARY" };
                  case "SUMMARYLISTCUSTOM":
                    return { success: true, value: "SUMMARYLISTCUSTOM" };
                  case "CUSTOMPAGELIST":
                    return { success: true, value: "CUSTOMPAGELIST" };
                  case "PROMOBOXCONFIG":
                    return { success: true, value: "PROMOBOXCONFIG" };
                  case "MYMIABS":
                    return { success: true, value: "MYMIABS" };
                  case "ASKGETABSTRACT":
                    return { success: true, value: "ASKGETABSTRACT" };
                  case "CUSTOMIZEDMIABS":
                    return { success: true, value: "CUSTOMIZEDMIABS" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["type"]);
          })();
          if (!parsed_type.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              custom: parsed_custom.value,
              defaultFilterAllLanguages: parsed_defaultFilterAllLanguages.value,
              embedSortingFiltering: parsed_embedSortingFiltering.value,
              hideTitle: parsed_hideTitle.value,
              itemCount: parsed_itemCount.value,
              itemLayout: parsed_itemLayout.value,
              name: parsed_name.value,
              portletId: parsed_portletId.value,
              portletLayout: parsed_portletLayout.value,
              subType: parsed_subType.value,
              subtitle: parsed_subtitle.value,
              title: parsed_title.value,
              type: parsed_type.value,
            },
          };
        })(value);
      })(value["metadata"]);
    })();
    if (!parsed_metadata.success) {
      return { success: false };
    }
    if (!hasProp(value, "page")) {
      return { success: false };
    }
    const parsed_page = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["page"]);
    })();
    if (!parsed_page.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageSize")) {
      return { success: false };
    }
    const parsed_pageSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageSize"]);
    })();
    if (!parsed_pageSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    if (!hasProp(value, "viewAllUri")) {
      return { success: false };
    }
    const parsed_viewAllUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["viewAllUri"]);
    })();
    if (!parsed_viewAllUri.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, metadata: parsed_metadata.value, page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value, viewAllUri: parsed_viewAllUri.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ActionableMetadata
export function serialize_ActionableMetadata(obj: ActionableMetadata): string {
  const serialized = ((value: {
    actionableId: bigint;
    activatedAt: DateTime<true> | null;
    active: boolean;
    coverUri: string;
    description: string;
    language: Language;
    modifiedAt: DateTime<true>;
    name: string;
    tags: Tag[];
    title: string;
  }): unknown => {
    return {
      actionableId: ((value: bigint): unknown => {
        return value;
      })(value["actionableId"]),
      activatedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["activatedAt"]),
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      coverUri: ((value: string): unknown => {
        return value;
      })(value["coverUri"]),
      description: ((value: string): unknown => {
        return value;
      })(value["description"]),
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      modifiedAt: ((value: DateTime<true>): unknown => {
        return value.toMillis();
      })(value["modifiedAt"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      tags: ((value: Tag[]): unknown => {
        return value.map(
          (
            value:
              | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
              | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
              | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
              | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
              | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
              | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
              | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
              | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
              | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
          ): unknown => {
            switch (value.discriminator) {
              case "dynamicString": {
                return {
                  discriminator: "dynamicString",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicString"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicBoolean": {
                return {
                  discriminator: "dynamicBoolean",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicBoolean"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: boolean): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicNumber": {
                return {
                  discriminator: "dynamicNumber",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicNumber"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: number): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicVoid": {
                return {
                  discriminator: "dynamicVoid",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicVoid"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                };
              }
              case "channel": {
                return {
                  discriminator: "channel",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                    return {
                      discriminator: ((value: "channel"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "channel"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "primary-skill": {
                return {
                  discriminator: "primary-skill",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                    return {
                      discriminator: ((value: "primary-skill"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "primary-skill"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "skill": {
                return {
                  discriminator: "skill",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                    return {
                      discriminator: ((value: "skill"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "skill"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "key-quality": {
                return {
                  discriminator: "key-quality",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                    return {
                      discriminator: ((value: "key-quality"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "key-quality"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "classification": {
                return {
                  discriminator: "classification",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                    return {
                      discriminator: ((value: "classification"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "classification"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
            }
          },
        );
      })(value["tags"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ActionableMetadata(str: string): ActionableMetadata | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          actionableId: bigint;
          activatedAt: DateTime<true> | null;
          active: boolean;
          coverUri: string;
          description: string;
          language: Language;
          modifiedAt: DateTime<true>;
          name: string;
          tags: Tag[];
          title: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "actionableId")) {
      return { success: false };
    }
    const parsed_actionableId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["actionableId"]);
    })();
    if (!parsed_actionableId.success) {
      return { success: false };
    }
    if (!hasProp(value, "activatedAt")) {
      return { success: false };
    }
    const parsed_activatedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["activatedAt"]);
    })();
    if (!parsed_activatedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "coverUri")) {
      return { success: false };
    }
    const parsed_coverUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["coverUri"]);
    })();
    if (!parsed_coverUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "description")) {
      return { success: false };
    }
    const parsed_description = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["description"]);
    })();
    if (!parsed_description.success) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language } => {
        return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "en":
              return { success: true, value: "en" };
            case "de":
              return { success: true, value: "de" };
            case "es":
              return { success: true, value: "es" };
            case "ru":
              return { success: true, value: "ru" };
            case "zh":
              return { success: true, value: "zh" };
            case "pt":
              return { success: true, value: "pt" };
            case "fr":
              return { success: true, value: "fr" };
            default:
              return { success: false };
          }
        })(value);
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "modifiedAt")) {
      return { success: false };
    }
    const parsed_modifiedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["modifiedAt"]);
    })();
    if (!parsed_modifiedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "tags")) {
      return { success: false };
    }
    const parsed_tags = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                  | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                    | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                    | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                    | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                    | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                    | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                    | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                    | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                    | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "discriminator")) {
                return { success: false };
              }
              if (typeof value["discriminator"] != "string") {
                return { success: false };
              }
              const discriminator = value["discriminator"];
              switch (discriminator) {
                case "dynamicString": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicString;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicString;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicString":
                                    return { success: true, value: "dynamicString" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                }
                case "dynamicBoolean": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicBoolean;
                          value: boolean;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicBoolean;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicBoolean":
                                    return { success: true, value: "dynamicBoolean" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                }
                case "dynamicNumber": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicNumber;
                          value: number;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicNumber;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicNumber":
                                    return { success: true, value: "dynamicNumber" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                }
                case "dynamicVoid": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicVoid;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicVoid;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicVoid":
                                    return { success: true, value: "dynamicVoid" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                }
                case "channel": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindChannel;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindChannel;
                                id: bigint;
                                kind: KindChannel;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                              return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "channel":
                                    return { success: true, value: "channel" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                              return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "channel":
                                    return { success: true, value: "channel" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                }
                case "primary-skill": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindPrimarySkill;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindPrimarySkill;
                                id: bigint;
                                kind: KindPrimarySkill;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "primary-skill":
                                    return { success: true, value: "primary-skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "primary-skill":
                                    return { success: true, value: "primary-skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                }
                case "skill": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindSkill;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindSkill;
                                id: bigint;
                                kind: KindSkill;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "skill":
                                    return { success: true, value: "skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "skill":
                                    return { success: true, value: "skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                }
                case "key-quality": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindKeyQuality;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindKeyQuality;
                                id: bigint;
                                kind: KindKeyQuality;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                              return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "key-quality":
                                    return { success: true, value: "key-quality" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                              return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "key-quality":
                                    return { success: true, value: "key-quality" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                }
                case "classification": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindClassification;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindClassification;
                                id: bigint;
                                kind: KindClassification;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                              return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "classification":
                                    return { success: true, value: "classification" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                              return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "classification":
                                    return { success: true, value: "classification" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["tags"]);
    })();
    if (!parsed_tags.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        actionableId: parsed_actionableId.value,
        activatedAt: parsed_activatedAt.value,
        active: parsed_active.value,
        coverUri: parsed_coverUri.value,
        description: parsed_description.value,
        language: parsed_language.value,
        modifiedAt: parsed_modifiedAt.value,
        name: parsed_name.value,
        tags: parsed_tags.value,
        title: parsed_title.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ActionableMiniView
export function serialize_ActionableMiniView(obj: ActionableMiniView): string {
  const serialized = ((value: { actionableId: bigint; coverUri: string; name: string; title: string }): unknown => {
    return {
      actionableId: ((value: bigint): unknown => {
        return value;
      })(value["actionableId"]),
      coverUri: ((value: string): unknown => {
        return value;
      })(value["coverUri"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ActionableMiniView(str: string): ActionableMiniView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          actionableId: bigint;
          coverUri: string;
          name: string;
          title: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "actionableId")) {
      return { success: false };
    }
    const parsed_actionableId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["actionableId"]);
    })();
    if (!parsed_actionableId.success) {
      return { success: false };
    }
    if (!hasProp(value, "coverUri")) {
      return { success: false };
    }
    const parsed_coverUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["coverUri"]);
    })();
    if (!parsed_coverUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return { success: true, value: { actionableId: parsed_actionableId.value, coverUri: parsed_coverUri.value, name: parsed_name.value, title: parsed_title.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ActionableReflectSectionProps
export function serialize_ActionableReflectSectionProps(obj: ActionableReflectSectionProps): string {
  const serialized = ((value: { actionableId: bigint; freeActionableActive: boolean; isMapp: boolean }): unknown => {
    return {
      actionableId: ((value: bigint): unknown => {
        return value;
      })(value["actionableId"]),
      freeActionableActive: ((value: boolean): unknown => {
        return value;
      })(value["freeActionableActive"]),
      isMapp: ((value: boolean): unknown => {
        return value;
      })(value["isMapp"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ActionableReflectSectionProps(str: string): ActionableReflectSectionProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          actionableId: bigint;
          freeActionableActive: boolean;
          isMapp: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "actionableId")) {
      return { success: false };
    }
    const parsed_actionableId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["actionableId"]);
    })();
    if (!parsed_actionableId.success) {
      return { success: false };
    }
    if (!hasProp(value, "freeActionableActive")) {
      return { success: false };
    }
    const parsed_freeActionableActive = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["freeActionableActive"]);
    })();
    if (!parsed_freeActionableActive.success) {
      return { success: false };
    }
    if (!hasProp(value, "isMapp")) {
      return { success: false };
    }
    const parsed_isMapp = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isMapp"]);
    })();
    if (!parsed_isMapp.success) {
      return { success: false };
    }
    return { success: true, value: { actionableId: parsed_actionableId.value, freeActionableActive: parsed_freeActionableActive.value, isMapp: parsed_isMapp.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ActionableSetEntry
export function serialize_ActionableSetEntry(obj: ActionableSetEntry): string {
  const serialized = ((value: { actionableId: bigint; active: boolean; language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"; name: string; title: string }): unknown => {
    return {
      actionableId: ((value: bigint): unknown => {
        return value;
      })(value["actionableId"]),
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ActionableSetEntry(str: string): ActionableSetEntry | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          actionableId: bigint;
          active: boolean;
          language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
          name: string;
          title: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "actionableId")) {
      return { success: false };
    }
    const parsed_actionableId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["actionableId"]);
    })();
    if (!parsed_actionableId.success) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "en":
            return { success: true, value: "en" };
          case "de":
            return { success: true, value: "de" };
          case "es":
            return { success: true, value: "es" };
          case "ru":
            return { success: true, value: "ru" };
          case "zh":
            return { success: true, value: "zh" };
          case "pt":
            return { success: true, value: "pt" };
          case "fr":
            return { success: true, value: "fr" };
          default:
            return { success: false };
        }
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return { success: true, value: { actionableId: parsed_actionableId.value, active: parsed_active.value, language: parsed_language.value, name: parsed_name.value, title: parsed_title.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// Activity
export function serialize_Activity(obj: Activity): string {
  const serialized = ((value: { definition: ActivityDefinition; id: string; objectType: string }): unknown => {
    return {
      definition: ((value: { description: { additionalProperties: Record<string, string> }; moreInfo: string; name: { additionalProperties: Record<string, string> }; type: string }): unknown => {
        return {
          description: ((value: { additionalProperties: Record<string, string> }): unknown => {
            const unordered_obj: Record<string, unknown> = {
              ...Object.fromEntries(
                Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                  return [
                    propertyKey,
                    ((value: string): unknown => {
                      return value;
                    })(propertyValue),
                  ];
                }),
              ),
            };
            return Object.keys(unordered_obj)
              .sort()
              .reduce(
                (obj, key) => {
                  obj[key] = unordered_obj[key];
                  return obj;
                },
                {} as Record<string, unknown>,
              );
          })(value["description"]),
          moreInfo: ((value: string): unknown => {
            return value;
          })(value["moreInfo"]),
          name: ((value: { additionalProperties: Record<string, string> }): unknown => {
            const unordered_obj: Record<string, unknown> = {
              ...Object.fromEntries(
                Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                  return [
                    propertyKey,
                    ((value: string): unknown => {
                      return value;
                    })(propertyValue),
                  ];
                }),
              ),
            };
            return Object.keys(unordered_obj)
              .sort()
              .reduce(
                (obj, key) => {
                  obj[key] = unordered_obj[key];
                  return obj;
                },
                {} as Record<string, unknown>,
              );
          })(value["name"]),
          type: ((value: string): unknown => {
            return value;
          })(value["type"]),
        };
      })(value["definition"]),
      id: ((value: string): unknown => {
        return value;
      })(value["id"]),
      objectType: ((value: string): unknown => {
        return value;
      })(value["objectType"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_Activity(str: string): Activity | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          definition: ActivityDefinition;
          id: string;
          objectType: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "definition")) {
      return { success: false };
    }
    const parsed_definition = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ActivityDefinition } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                description: { additionalProperties: Record<string, string> };
                moreInfo: string;
                name: { additionalProperties: Record<string, string> };
                type: string;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "description")) {
            return { success: false };
          }
          const parsed_description = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
              if (typeof value !== "object") {
                return { success: false };
              }
              if (value === null) {
                return { success: false };
              }
              const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
                const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
                  return [
                    key,
                    ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value),
                  ];
                });
                if (parsed.find(([_, value]) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: Object.fromEntries(
                    parsed.map(([key, value]) => {
                      assert(value.success);
                      return [key, value.value];
                    }),
                  ),
                };
              })();
              if (!parsed_additionalProperties.success) {
                return { success: false };
              }
              return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
            })(value["description"]);
          })();
          if (!parsed_description.success) {
            return { success: false };
          }
          if (!hasProp(value, "moreInfo")) {
            return { success: false };
          }
          const parsed_moreInfo = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["moreInfo"]);
          })();
          if (!parsed_moreInfo.success) {
            return { success: false };
          }
          if (!hasProp(value, "name")) {
            return { success: false };
          }
          const parsed_name = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
              if (typeof value !== "object") {
                return { success: false };
              }
              if (value === null) {
                return { success: false };
              }
              const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
                const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
                  return [
                    key,
                    ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value),
                  ];
                });
                if (parsed.find(([_, value]) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: Object.fromEntries(
                    parsed.map(([key, value]) => {
                      assert(value.success);
                      return [key, value.value];
                    }),
                  ),
                };
              })();
              if (!parsed_additionalProperties.success) {
                return { success: false };
              }
              return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
            })(value["name"]);
          })();
          if (!parsed_name.success) {
            return { success: false };
          }
          if (!hasProp(value, "type")) {
            return { success: false };
          }
          const parsed_type = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["type"]);
          })();
          if (!parsed_type.success) {
            return { success: false };
          }
          return { success: true, value: { description: parsed_description.value, moreInfo: parsed_moreInfo.value, name: parsed_name.value, type: parsed_type.value } };
        })(value);
      })(value["definition"]);
    })();
    if (!parsed_definition.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    if (!hasProp(value, "objectType")) {
      return { success: false };
    }
    const parsed_objectType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["objectType"]);
    })();
    if (!parsed_objectType.success) {
      return { success: false };
    }
    return { success: true, value: { definition: parsed_definition.value, id: parsed_id.value, objectType: parsed_objectType.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ActivityDefinition
export function serialize_ActivityDefinition(obj: ActivityDefinition): string {
  const serialized = ((value: { description: { additionalProperties: Record<string, string> }; moreInfo: string; name: { additionalProperties: Record<string, string> }; type: string }): unknown => {
    return {
      description: ((value: { additionalProperties: Record<string, string> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: string): unknown => {
                  return value;
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["description"]),
      moreInfo: ((value: string): unknown => {
        return value;
      })(value["moreInfo"]),
      name: ((value: { additionalProperties: Record<string, string> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: string): unknown => {
                  return value;
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["name"]),
      type: ((value: string): unknown => {
        return value;
      })(value["type"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ActivityDefinition(str: string): ActivityDefinition | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          description: { additionalProperties: Record<string, string> };
          moreInfo: string;
          name: { additionalProperties: Record<string, string> };
          type: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "description")) {
      return { success: false };
    }
    const parsed_description = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["description"]);
    })();
    if (!parsed_description.success) {
      return { success: false };
    }
    if (!hasProp(value, "moreInfo")) {
      return { success: false };
    }
    const parsed_moreInfo = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["moreInfo"]);
    })();
    if (!parsed_moreInfo.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "type")) {
      return { success: false };
    }
    const parsed_type = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["type"]);
    })();
    if (!parsed_type.success) {
      return { success: false };
    }
    return { success: true, value: { description: parsed_description.value, moreInfo: parsed_moreInfo.value, name: parsed_name.value, type: parsed_type.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// Actor
export function serialize_Actor(obj: Actor): string {
  const serialized = ((value: { account: Account | null; mbox: string | null; name: string; objectType: string; openid: string | null }): unknown => {
    return {
      account: ((
        value: {
          homePage: string;
          name: string;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          homePage: ((value: string): unknown => {
            return value;
          })(value["homePage"]),
          name: ((value: string): unknown => {
            return value;
          })(value["name"]),
        };
      })(value["account"]),
      mbox: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["mbox"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      objectType: ((value: string): unknown => {
        return value;
      })(value["objectType"]),
      openid: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["openid"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_Actor(str: string): Actor | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          account: Account | null;
          mbox: string | null;
          name: string;
          objectType: string;
          openid: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "account")) {
      return { success: false };
    }
    const parsed_account = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Account | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                homePage: string;
                name: string;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "homePage")) {
            return { success: false };
          }
          const parsed_homePage = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["homePage"]);
          })();
          if (!parsed_homePage.success) {
            return { success: false };
          }
          if (!hasProp(value, "name")) {
            return { success: false };
          }
          const parsed_name = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["name"]);
          })();
          if (!parsed_name.success) {
            return { success: false };
          }
          return { success: true, value: { homePage: parsed_homePage.value, name: parsed_name.value } };
        })(value);
      })(value["account"]);
    })();
    if (!parsed_account.success) {
      return { success: false };
    }
    if (!hasProp(value, "mbox")) {
      return { success: false };
    }
    const parsed_mbox = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["mbox"]);
    })();
    if (!parsed_mbox.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "objectType")) {
      return { success: false };
    }
    const parsed_objectType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["objectType"]);
    })();
    if (!parsed_objectType.success) {
      return { success: false };
    }
    if (!hasProp(value, "openid")) {
      return { success: false };
    }
    const parsed_openid = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["openid"]);
    })();
    if (!parsed_openid.success) {
      return { success: false };
    }
    return { success: true, value: { account: parsed_account.value, mbox: parsed_mbox.value, name: parsed_name.value, objectType: parsed_objectType.value, openid: parsed_openid.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AdminSidebarWidgetProps
export function serialize_AdminSidebarWidgetProps(obj: AdminSidebarWidgetProps): string {
  const serialized = ((value: { navigationItems: AdminToolLinkGroup[] }): unknown => {
    return {
      navigationItems: ((value: AdminToolLinkGroup[]): unknown => {
        return value.map((value: { ico: string | null; links: AdminToolLink[]; name: string | null }): unknown => {
          return {
            ico: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["ico"]),
            links: ((value: AdminToolLink[]): unknown => {
              return value.map(
                (value: { description: string | null; hidden: boolean; ico: string | null; linkText: string | null; tabName: string | null; tabTitle: string | null; targetType: AdminToolLinkTargetType; url: string | null }): unknown => {
                  return {
                    description: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["description"]),
                    hidden: ((value: boolean): unknown => {
                      return value;
                    })(value["hidden"]),
                    ico: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["ico"]),
                    linkText: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["linkText"]),
                    tabName: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["tabName"]),
                    tabTitle: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["tabTitle"]),
                    targetType: ((value: "SELF" | "BLANK" | "ROUTER"): unknown => {
                      return value;
                    })(value["targetType"]),
                    url: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["url"]),
                  };
                },
              );
            })(value["links"]),
            name: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["name"]),
          };
        });
      })(value["navigationItems"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AdminSidebarWidgetProps(str: string): AdminSidebarWidgetProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          navigationItems: AdminToolLinkGroup[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "navigationItems")) {
      return { success: false };
    }
    const parsed_navigationItems = (() => {
      return ((value: unknown): { success: false } | { success: true; value: AdminToolLinkGroup[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: AdminToolLinkGroup } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  ico: string | null;
                  links: AdminToolLink[];
                  name: string | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "ico")) {
              return { success: false };
            }
            const parsed_ico = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["ico"]);
            })();
            if (!parsed_ico.success) {
              return { success: false };
            }
            if (!hasProp(value, "links")) {
              return { success: false };
            }
            const parsed_links = (() => {
              return ((value: unknown): { success: false } | { success: true; value: AdminToolLink[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: AdminToolLink } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          description: string | null;
                          hidden: boolean;
                          ico: string | null;
                          linkText: string | null;
                          tabName: string | null;
                          tabTitle: string | null;
                          targetType: AdminToolLinkTargetType;
                          url: string | null;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "description")) {
                      return { success: false };
                    }
                    const parsed_description = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["description"]);
                    })();
                    if (!parsed_description.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "hidden")) {
                      return { success: false };
                    }
                    const parsed_hidden = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["hidden"]);
                    })();
                    if (!parsed_hidden.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "ico")) {
                      return { success: false };
                    }
                    const parsed_ico = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["ico"]);
                    })();
                    if (!parsed_ico.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "linkText")) {
                      return { success: false };
                    }
                    const parsed_linkText = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["linkText"]);
                    })();
                    if (!parsed_linkText.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tabName")) {
                      return { success: false };
                    }
                    const parsed_tabName = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["tabName"]);
                    })();
                    if (!parsed_tabName.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tabTitle")) {
                      return { success: false };
                    }
                    const parsed_tabTitle = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["tabTitle"]);
                    })();
                    if (!parsed_tabTitle.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "targetType")) {
                      return { success: false };
                    }
                    const parsed_targetType = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: AdminToolLinkTargetType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "SELF" | "BLANK" | "ROUTER" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "SELF":
                              return { success: true, value: "SELF" };
                            case "BLANK":
                              return { success: true, value: "BLANK" };
                            case "ROUTER":
                              return { success: true, value: "ROUTER" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["targetType"]);
                    })();
                    if (!parsed_targetType.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "url")) {
                      return { success: false };
                    }
                    const parsed_url = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["url"]);
                    })();
                    if (!parsed_url.success) {
                      return { success: false };
                    }
                    return {
                      success: true,
                      value: {
                        description: parsed_description.value,
                        hidden: parsed_hidden.value,
                        ico: parsed_ico.value,
                        linkText: parsed_linkText.value,
                        tabName: parsed_tabName.value,
                        tabTitle: parsed_tabTitle.value,
                        targetType: parsed_targetType.value,
                        url: parsed_url.value,
                      },
                    };
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["links"]);
            })();
            if (!parsed_links.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            return { success: true, value: { ico: parsed_ico.value, links: parsed_links.value, name: parsed_name.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["navigationItems"]);
    })();
    if (!parsed_navigationItems.success) {
      return { success: false };
    }
    return { success: true, value: { navigationItems: parsed_navigationItems.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AdminToolLink
export function serialize_AdminToolLink(obj: AdminToolLink): string {
  const serialized = ((value: {
    description: string | null;
    hidden: boolean;
    ico: string | null;
    linkText: string | null;
    tabName: string | null;
    tabTitle: string | null;
    targetType: AdminToolLinkTargetType;
    url: string | null;
  }): unknown => {
    return {
      description: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["description"]),
      hidden: ((value: boolean): unknown => {
        return value;
      })(value["hidden"]),
      ico: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["ico"]),
      linkText: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["linkText"]),
      tabName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["tabName"]),
      tabTitle: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["tabTitle"]),
      targetType: ((value: "SELF" | "BLANK" | "ROUTER"): unknown => {
        return value;
      })(value["targetType"]),
      url: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["url"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AdminToolLink(str: string): AdminToolLink | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          description: string | null;
          hidden: boolean;
          ico: string | null;
          linkText: string | null;
          tabName: string | null;
          tabTitle: string | null;
          targetType: AdminToolLinkTargetType;
          url: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "description")) {
      return { success: false };
    }
    const parsed_description = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["description"]);
    })();
    if (!parsed_description.success) {
      return { success: false };
    }
    if (!hasProp(value, "hidden")) {
      return { success: false };
    }
    const parsed_hidden = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["hidden"]);
    })();
    if (!parsed_hidden.success) {
      return { success: false };
    }
    if (!hasProp(value, "ico")) {
      return { success: false };
    }
    const parsed_ico = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ico"]);
    })();
    if (!parsed_ico.success) {
      return { success: false };
    }
    if (!hasProp(value, "linkText")) {
      return { success: false };
    }
    const parsed_linkText = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["linkText"]);
    })();
    if (!parsed_linkText.success) {
      return { success: false };
    }
    if (!hasProp(value, "tabName")) {
      return { success: false };
    }
    const parsed_tabName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["tabName"]);
    })();
    if (!parsed_tabName.success) {
      return { success: false };
    }
    if (!hasProp(value, "tabTitle")) {
      return { success: false };
    }
    const parsed_tabTitle = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["tabTitle"]);
    })();
    if (!parsed_tabTitle.success) {
      return { success: false };
    }
    if (!hasProp(value, "targetType")) {
      return { success: false };
    }
    const parsed_targetType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: AdminToolLinkTargetType } => {
        return ((value: unknown): { success: false } | { success: true; value: "SELF" | "BLANK" | "ROUTER" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "SELF":
              return { success: true, value: "SELF" };
            case "BLANK":
              return { success: true, value: "BLANK" };
            case "ROUTER":
              return { success: true, value: "ROUTER" };
            default:
              return { success: false };
          }
        })(value);
      })(value["targetType"]);
    })();
    if (!parsed_targetType.success) {
      return { success: false };
    }
    if (!hasProp(value, "url")) {
      return { success: false };
    }
    const parsed_url = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["url"]);
    })();
    if (!parsed_url.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        description: parsed_description.value,
        hidden: parsed_hidden.value,
        ico: parsed_ico.value,
        linkText: parsed_linkText.value,
        tabName: parsed_tabName.value,
        tabTitle: parsed_tabTitle.value,
        targetType: parsed_targetType.value,
        url: parsed_url.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AdminToolLinkGroup
export function serialize_AdminToolLinkGroup(obj: AdminToolLinkGroup): string {
  const serialized = ((value: { ico: string | null; links: AdminToolLink[]; name: string | null }): unknown => {
    return {
      ico: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["ico"]),
      links: ((value: AdminToolLink[]): unknown => {
        return value.map(
          (value: { description: string | null; hidden: boolean; ico: string | null; linkText: string | null; tabName: string | null; tabTitle: string | null; targetType: AdminToolLinkTargetType; url: string | null }): unknown => {
            return {
              description: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["description"]),
              hidden: ((value: boolean): unknown => {
                return value;
              })(value["hidden"]),
              ico: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["ico"]),
              linkText: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["linkText"]),
              tabName: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["tabName"]),
              tabTitle: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["tabTitle"]),
              targetType: ((value: "SELF" | "BLANK" | "ROUTER"): unknown => {
                return value;
              })(value["targetType"]),
              url: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["url"]),
            };
          },
        );
      })(value["links"]),
      name: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["name"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AdminToolLinkGroup(str: string): AdminToolLinkGroup | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          ico: string | null;
          links: AdminToolLink[];
          name: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "ico")) {
      return { success: false };
    }
    const parsed_ico = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ico"]);
    })();
    if (!parsed_ico.success) {
      return { success: false };
    }
    if (!hasProp(value, "links")) {
      return { success: false };
    }
    const parsed_links = (() => {
      return ((value: unknown): { success: false } | { success: true; value: AdminToolLink[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: AdminToolLink } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  description: string | null;
                  hidden: boolean;
                  ico: string | null;
                  linkText: string | null;
                  tabName: string | null;
                  tabTitle: string | null;
                  targetType: AdminToolLinkTargetType;
                  url: string | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "description")) {
              return { success: false };
            }
            const parsed_description = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["description"]);
            })();
            if (!parsed_description.success) {
              return { success: false };
            }
            if (!hasProp(value, "hidden")) {
              return { success: false };
            }
            const parsed_hidden = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["hidden"]);
            })();
            if (!parsed_hidden.success) {
              return { success: false };
            }
            if (!hasProp(value, "ico")) {
              return { success: false };
            }
            const parsed_ico = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["ico"]);
            })();
            if (!parsed_ico.success) {
              return { success: false };
            }
            if (!hasProp(value, "linkText")) {
              return { success: false };
            }
            const parsed_linkText = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["linkText"]);
            })();
            if (!parsed_linkText.success) {
              return { success: false };
            }
            if (!hasProp(value, "tabName")) {
              return { success: false };
            }
            const parsed_tabName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["tabName"]);
            })();
            if (!parsed_tabName.success) {
              return { success: false };
            }
            if (!hasProp(value, "tabTitle")) {
              return { success: false };
            }
            const parsed_tabTitle = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["tabTitle"]);
            })();
            if (!parsed_tabTitle.success) {
              return { success: false };
            }
            if (!hasProp(value, "targetType")) {
              return { success: false };
            }
            const parsed_targetType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: AdminToolLinkTargetType } => {
                return ((value: unknown): { success: false } | { success: true; value: "SELF" | "BLANK" | "ROUTER" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "SELF":
                      return { success: true, value: "SELF" };
                    case "BLANK":
                      return { success: true, value: "BLANK" };
                    case "ROUTER":
                      return { success: true, value: "ROUTER" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["targetType"]);
            })();
            if (!parsed_targetType.success) {
              return { success: false };
            }
            if (!hasProp(value, "url")) {
              return { success: false };
            }
            const parsed_url = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["url"]);
            })();
            if (!parsed_url.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                description: parsed_description.value,
                hidden: parsed_hidden.value,
                ico: parsed_ico.value,
                linkText: parsed_linkText.value,
                tabName: parsed_tabName.value,
                tabTitle: parsed_tabTitle.value,
                targetType: parsed_targetType.value,
                url: parsed_url.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["links"]);
    })();
    if (!parsed_links.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    return { success: true, value: { ico: parsed_ico.value, links: parsed_links.value, name: parsed_name.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AdminToolLinkTargetType
export function serialize_AdminToolLinkTargetType(obj: AdminToolLinkTargetType): string {
  const serialized = ((value: "SELF" | "BLANK" | "ROUTER"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AdminToolLinkTargetType(str: string): AdminToolLinkTargetType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "SELF" | "BLANK" | "ROUTER" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "SELF":
        return { success: true, value: "SELF" };
      case "BLANK":
        return { success: true, value: "BLANK" };
      case "ROUTER":
        return { success: true, value: "ROUTER" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AlipayPaymentView
export function serialize_AlipayPaymentView(obj: AlipayPaymentView): string {
  const serialized = ((value: { outTradeNo: string | null; tradeNo: string | null; transactionId: number }): unknown => {
    return {
      outTradeNo: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["outTradeNo"]),
      tradeNo: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["tradeNo"]),
      transactionId: ((value: number): unknown => {
        return value;
      })(value["transactionId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AlipayPaymentView(str: string): AlipayPaymentView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          outTradeNo: string | null;
          tradeNo: string | null;
          transactionId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "outTradeNo")) {
      return { success: false };
    }
    const parsed_outTradeNo = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["outTradeNo"]);
    })();
    if (!parsed_outTradeNo.success) {
      return { success: false };
    }
    if (!hasProp(value, "tradeNo")) {
      return { success: false };
    }
    const parsed_tradeNo = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["tradeNo"]);
    })();
    if (!parsed_tradeNo.success) {
      return { success: false };
    }
    if (!hasProp(value, "transactionId")) {
      return { success: false };
    }
    const parsed_transactionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["transactionId"]);
    })();
    if (!parsed_transactionId.success) {
      return { success: false };
    }
    return { success: true, value: { outTradeNo: parsed_outTradeNo.value, tradeNo: parsed_tradeNo.value, transactionId: parsed_transactionId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AndroidPaymentView
export function serialize_AndroidPaymentView(obj: AndroidPaymentView): string {
  const serialized = ((value: {
    appStoreProductId: string | null;
    autoRenewing: boolean;
    countryCode: string | null;
    orderId: string | null;
    paymentState: number;
    purchaseState: number;
    testPurchase: boolean;
    transactionId: number;
  }): unknown => {
    return {
      appStoreProductId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["appStoreProductId"]),
      autoRenewing: ((value: boolean): unknown => {
        return value;
      })(value["autoRenewing"]),
      countryCode: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["countryCode"]),
      orderId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["orderId"]),
      paymentState: ((value: number): unknown => {
        return value;
      })(value["paymentState"]),
      purchaseState: ((value: number): unknown => {
        return value;
      })(value["purchaseState"]),
      testPurchase: ((value: boolean): unknown => {
        return value;
      })(value["testPurchase"]),
      transactionId: ((value: number): unknown => {
        return value;
      })(value["transactionId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AndroidPaymentView(str: string): AndroidPaymentView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          appStoreProductId: string | null;
          autoRenewing: boolean;
          countryCode: string | null;
          orderId: string | null;
          paymentState: number;
          purchaseState: number;
          testPurchase: boolean;
          transactionId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "appStoreProductId")) {
      return { success: false };
    }
    const parsed_appStoreProductId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["appStoreProductId"]);
    })();
    if (!parsed_appStoreProductId.success) {
      return { success: false };
    }
    if (!hasProp(value, "autoRenewing")) {
      return { success: false };
    }
    const parsed_autoRenewing = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["autoRenewing"]);
    })();
    if (!parsed_autoRenewing.success) {
      return { success: false };
    }
    if (!hasProp(value, "countryCode")) {
      return { success: false };
    }
    const parsed_countryCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["countryCode"]);
    })();
    if (!parsed_countryCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "orderId")) {
      return { success: false };
    }
    const parsed_orderId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["orderId"]);
    })();
    if (!parsed_orderId.success) {
      return { success: false };
    }
    if (!hasProp(value, "paymentState")) {
      return { success: false };
    }
    const parsed_paymentState = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["paymentState"]);
    })();
    if (!parsed_paymentState.success) {
      return { success: false };
    }
    if (!hasProp(value, "purchaseState")) {
      return { success: false };
    }
    const parsed_purchaseState = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["purchaseState"]);
    })();
    if (!parsed_purchaseState.success) {
      return { success: false };
    }
    if (!hasProp(value, "testPurchase")) {
      return { success: false };
    }
    const parsed_testPurchase = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["testPurchase"]);
    })();
    if (!parsed_testPurchase.success) {
      return { success: false };
    }
    if (!hasProp(value, "transactionId")) {
      return { success: false };
    }
    const parsed_transactionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["transactionId"]);
    })();
    if (!parsed_transactionId.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        appStoreProductId: parsed_appStoreProductId.value,
        autoRenewing: parsed_autoRenewing.value,
        countryCode: parsed_countryCode.value,
        orderId: parsed_orderId.value,
        paymentState: parsed_paymentState.value,
        purchaseState: parsed_purchaseState.value,
        testPurchase: parsed_testPurchase.value,
        transactionId: parsed_transactionId.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AreaOfExpertise
export function serialize_AreaOfExpertise(obj: AreaOfExpertise): string {
  const serialized = ((
    value:
      | "ACCOUNTING_AND_FINANCE"
      | "ADMINISTRATION"
      | "BUSINESS_DEVELOPMENT"
      | "COMMUNICATIONS"
      | "CUSTOMER_SERVICE"
      | "HUMAN_RESOURCES"
      | "INFORMATION_TECHNOLOGY"
      | "LEGAL"
      | "MARKETING"
      | "OPERATIONS"
      | "PRODUCTION"
      | "SALES"
      | "SUPPLY_MANAGEMENT"
      | "OTHER"
      | "UNDEFINED",
  ): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AreaOfExpertise(str: string): AreaOfExpertise | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value:
          | "ACCOUNTING_AND_FINANCE"
          | "ADMINISTRATION"
          | "BUSINESS_DEVELOPMENT"
          | "COMMUNICATIONS"
          | "CUSTOMER_SERVICE"
          | "HUMAN_RESOURCES"
          | "INFORMATION_TECHNOLOGY"
          | "LEGAL"
          | "MARKETING"
          | "OPERATIONS"
          | "PRODUCTION"
          | "SALES"
          | "SUPPLY_MANAGEMENT"
          | "OTHER"
          | "UNDEFINED";
      } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "ACCOUNTING_AND_FINANCE":
        return { success: true, value: "ACCOUNTING_AND_FINANCE" };
      case "ADMINISTRATION":
        return { success: true, value: "ADMINISTRATION" };
      case "BUSINESS_DEVELOPMENT":
        return { success: true, value: "BUSINESS_DEVELOPMENT" };
      case "COMMUNICATIONS":
        return { success: true, value: "COMMUNICATIONS" };
      case "CUSTOMER_SERVICE":
        return { success: true, value: "CUSTOMER_SERVICE" };
      case "HUMAN_RESOURCES":
        return { success: true, value: "HUMAN_RESOURCES" };
      case "INFORMATION_TECHNOLOGY":
        return { success: true, value: "INFORMATION_TECHNOLOGY" };
      case "LEGAL":
        return { success: true, value: "LEGAL" };
      case "MARKETING":
        return { success: true, value: "MARKETING" };
      case "OPERATIONS":
        return { success: true, value: "OPERATIONS" };
      case "PRODUCTION":
        return { success: true, value: "PRODUCTION" };
      case "SALES":
        return { success: true, value: "SALES" };
      case "SUPPLY_MANAGEMENT":
        return { success: true, value: "SUPPLY_MANAGEMENT" };
      case "OTHER":
        return { success: true, value: "OTHER" };
      case "UNDEFINED":
        return { success: true, value: "UNDEFINED" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AskGetabstractAnalyticsEventVariant
export function serialize_AskGetabstractAnalyticsEventVariant(obj: AskGetabstractAnalyticsEventVariant): string {
  const serialized = ((value: "default" | "qa_triggered_from_search" | "qa_demo"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AskGetabstractAnalyticsEventVariant(str: string): AskGetabstractAnalyticsEventVariant | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "default" | "qa_triggered_from_search" | "qa_demo" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "default":
        return { success: true, value: "default" };
      case "qa_triggered_from_search":
        return { success: true, value: "qa_triggered_from_search" };
      case "qa_demo":
        return { success: true, value: "qa_demo" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AskGetabstractAnalyticsFeedbackEventForm
export function serialize_AskGetabstractAnalyticsFeedbackEventForm(obj: AskGetabstractAnalyticsFeedbackEventForm): string {
  const serialized = ((value: { isPositive: boolean; questionUuid: string }): unknown => {
    return {
      isPositive: ((value: boolean): unknown => {
        return value;
      })(value["isPositive"]),
      questionUuid: ((value: string): unknown => {
        return value;
      })(value["questionUuid"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AskGetabstractAnalyticsFeedbackEventForm(str: string): AskGetabstractAnalyticsFeedbackEventForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          isPositive: boolean;
          questionUuid: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "isPositive")) {
      return { success: false };
    }
    const parsed_isPositive = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isPositive"]);
    })();
    if (!parsed_isPositive.success) {
      return { success: false };
    }
    if (!hasProp(value, "questionUuid")) {
      return { success: false };
    }
    const parsed_questionUuid = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["questionUuid"]);
    })();
    if (!parsed_questionUuid.success) {
      return { success: false };
    }
    return { success: true, value: { isPositive: parsed_isPositive.value, questionUuid: parsed_questionUuid.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AskGetabstractAnswer
export function serialize_AskGetabstractAnswer(obj: AskGetabstractAnswer): string {
  const serialized = ((value: {
    answer: string;
    contextDocs: AskGetabstractAnswerContextDoc[];
    detectedLanguageLabel: string | null;
    previousQuestionUuid: string | null;
    question: string;
    questionUuid: string;
    relatedActionables: ActionableMiniView[];
    relatedQuestions: string[];
    status: AskGetabstractAnswerStatus;
  }): unknown => {
    return {
      answer: ((value: string): unknown => {
        return value;
      })(value["answer"]),
      contextDocs: ((value: AskGetabstractAnswerContextDoc[]): unknown => {
        return value.map(
          (value: {
            bookmarked: boolean;
            dataId: number;
            rating: number | null;
            referenceNumber: number;
            snippet: string;
            sourceTypeString: string;
            title: string;
            urls: {
              cover: string;
              list: string;
              reference: string;
            };
          }): unknown => {
            return {
              bookmarked: ((value: boolean): unknown => {
                return value;
              })(value["bookmarked"]),
              dataId: ((value: number): unknown => {
                return value;
              })(value["dataId"]),
              rating: ((value: number | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["rating"]),
              referenceNumber: ((value: number): unknown => {
                return value;
              })(value["referenceNumber"]),
              snippet: ((value: string): unknown => {
                return value;
              })(value["snippet"]),
              sourceTypeString: ((value: string): unknown => {
                return value;
              })(value["sourceTypeString"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
              urls: ((value: { cover: string; list: string; reference: string }): unknown => {
                return {
                  cover: ((value: string): unknown => {
                    return value;
                  })(value["cover"]),
                  list: ((value: string): unknown => {
                    return value;
                  })(value["list"]),
                  reference: ((value: string): unknown => {
                    return value;
                  })(value["reference"]),
                };
              })(value["urls"]),
            };
          },
        );
      })(value["contextDocs"]),
      detectedLanguageLabel: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["detectedLanguageLabel"]),
      previousQuestionUuid: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["previousQuestionUuid"]),
      question: ((value: string): unknown => {
        return value;
      })(value["question"]),
      questionUuid: ((value: string): unknown => {
        return value;
      })(value["questionUuid"]),
      relatedActionables: ((value: ActionableMiniView[]): unknown => {
        return value.map((value: { actionableId: bigint; coverUri: string; name: string; title: string }): unknown => {
          return {
            actionableId: ((value: bigint): unknown => {
              return value;
            })(value["actionableId"]),
            coverUri: ((value: string): unknown => {
              return value;
            })(value["coverUri"]),
            name: ((value: string): unknown => {
              return value;
            })(value["name"]),
            title: ((value: string): unknown => {
              return value;
            })(value["title"]),
          };
        });
      })(value["relatedActionables"]),
      relatedQuestions: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["relatedQuestions"]),
      status: ((value: "PROCESSING" | "FINISHED" | "NO_DOCS_FROM_VECTOR_DB" | "NO_DOCS_AFTER_FILTER" | "NO_REFERENCE_IN_ANSWER" | "GENERAL_ERROR" | "TIMEOUT"): unknown => {
        return value;
      })(value["status"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AskGetabstractAnswer(str: string): AskGetabstractAnswer | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          answer: string;
          contextDocs: AskGetabstractAnswerContextDoc[];
          detectedLanguageLabel: string | null;
          previousQuestionUuid: string | null;
          question: string;
          questionUuid: string;
          relatedActionables: ActionableMiniView[];
          relatedQuestions: string[];
          status: AskGetabstractAnswerStatus;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "answer")) {
      return { success: false };
    }
    const parsed_answer = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["answer"]);
    })();
    if (!parsed_answer.success) {
      return { success: false };
    }
    if (!hasProp(value, "contextDocs")) {
      return { success: false };
    }
    const parsed_contextDocs = (() => {
      return ((value: unknown): { success: false } | { success: true; value: AskGetabstractAnswerContextDoc[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: AskGetabstractAnswerContextDoc } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  bookmarked: boolean;
                  dataId: number;
                  rating: number | null;
                  referenceNumber: number;
                  snippet: string;
                  sourceTypeString: string;
                  title: string;
                  urls: {
                    cover: string;
                    list: string;
                    reference: string;
                  };
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "bookmarked")) {
              return { success: false };
            }
            const parsed_bookmarked = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["bookmarked"]);
            })();
            if (!parsed_bookmarked.success) {
              return { success: false };
            }
            if (!hasProp(value, "dataId")) {
              return { success: false };
            }
            const parsed_dataId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["dataId"]);
            })();
            if (!parsed_dataId.success) {
              return { success: false };
            }
            if (!hasProp(value, "rating")) {
              return { success: false };
            }
            const parsed_rating = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["rating"]);
            })();
            if (!parsed_rating.success) {
              return { success: false };
            }
            if (!hasProp(value, "referenceNumber")) {
              return { success: false };
            }
            const parsed_referenceNumber = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["referenceNumber"]);
            })();
            if (!parsed_referenceNumber.success) {
              return { success: false };
            }
            if (!hasProp(value, "snippet")) {
              return { success: false };
            }
            const parsed_snippet = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["snippet"]);
            })();
            if (!parsed_snippet.success) {
              return { success: false };
            }
            if (!hasProp(value, "sourceTypeString")) {
              return { success: false };
            }
            const parsed_sourceTypeString = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["sourceTypeString"]);
            })();
            if (!parsed_sourceTypeString.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            if (!hasProp(value, "urls")) {
              return { success: false };
            }
            const parsed_urls = (() => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      cover: string;
                      list: string;
                      reference: string;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "cover")) {
                  return { success: false };
                }
                const parsed_cover = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["cover"]);
                })();
                if (!parsed_cover.success) {
                  return { success: false };
                }
                if (!hasProp(value, "list")) {
                  return { success: false };
                }
                const parsed_list = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["list"]);
                })();
                if (!parsed_list.success) {
                  return { success: false };
                }
                if (!hasProp(value, "reference")) {
                  return { success: false };
                }
                const parsed_reference = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["reference"]);
                })();
                if (!parsed_reference.success) {
                  return { success: false };
                }
                return { success: true, value: { cover: parsed_cover.value, list: parsed_list.value, reference: parsed_reference.value } };
              })(value["urls"]);
            })();
            if (!parsed_urls.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                bookmarked: parsed_bookmarked.value,
                dataId: parsed_dataId.value,
                rating: parsed_rating.value,
                referenceNumber: parsed_referenceNumber.value,
                snippet: parsed_snippet.value,
                sourceTypeString: parsed_sourceTypeString.value,
                title: parsed_title.value,
                urls: parsed_urls.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["contextDocs"]);
    })();
    if (!parsed_contextDocs.success) {
      return { success: false };
    }
    if (!hasProp(value, "detectedLanguageLabel")) {
      return { success: false };
    }
    const parsed_detectedLanguageLabel = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["detectedLanguageLabel"]);
    })();
    if (!parsed_detectedLanguageLabel.success) {
      return { success: false };
    }
    if (!hasProp(value, "previousQuestionUuid")) {
      return { success: false };
    }
    const parsed_previousQuestionUuid = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["previousQuestionUuid"]);
    })();
    if (!parsed_previousQuestionUuid.success) {
      return { success: false };
    }
    if (!hasProp(value, "question")) {
      return { success: false };
    }
    const parsed_question = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["question"]);
    })();
    if (!parsed_question.success) {
      return { success: false };
    }
    if (!hasProp(value, "questionUuid")) {
      return { success: false };
    }
    const parsed_questionUuid = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["questionUuid"]);
    })();
    if (!parsed_questionUuid.success) {
      return { success: false };
    }
    if (!hasProp(value, "relatedActionables")) {
      return { success: false };
    }
    const parsed_relatedActionables = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ActionableMiniView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ActionableMiniView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  actionableId: bigint;
                  coverUri: string;
                  name: string;
                  title: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "actionableId")) {
              return { success: false };
            }
            const parsed_actionableId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["actionableId"]);
            })();
            if (!parsed_actionableId.success) {
              return { success: false };
            }
            if (!hasProp(value, "coverUri")) {
              return { success: false };
            }
            const parsed_coverUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["coverUri"]);
            })();
            if (!parsed_coverUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            return { success: true, value: { actionableId: parsed_actionableId.value, coverUri: parsed_coverUri.value, name: parsed_name.value, title: parsed_title.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["relatedActionables"]);
    })();
    if (!parsed_relatedActionables.success) {
      return { success: false };
    }
    if (!hasProp(value, "relatedQuestions")) {
      return { success: false };
    }
    const parsed_relatedQuestions = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["relatedQuestions"]);
    })();
    if (!parsed_relatedQuestions.success) {
      return { success: false };
    }
    if (!hasProp(value, "status")) {
      return { success: false };
    }
    const parsed_status = (() => {
      return ((value: unknown): { success: false } | { success: true; value: AskGetabstractAnswerStatus } => {
        return ((value: unknown): { success: false } | { success: true; value: "PROCESSING" | "FINISHED" | "NO_DOCS_FROM_VECTOR_DB" | "NO_DOCS_AFTER_FILTER" | "NO_REFERENCE_IN_ANSWER" | "GENERAL_ERROR" | "TIMEOUT" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "PROCESSING":
              return { success: true, value: "PROCESSING" };
            case "FINISHED":
              return { success: true, value: "FINISHED" };
            case "NO_DOCS_FROM_VECTOR_DB":
              return { success: true, value: "NO_DOCS_FROM_VECTOR_DB" };
            case "NO_DOCS_AFTER_FILTER":
              return { success: true, value: "NO_DOCS_AFTER_FILTER" };
            case "NO_REFERENCE_IN_ANSWER":
              return { success: true, value: "NO_REFERENCE_IN_ANSWER" };
            case "GENERAL_ERROR":
              return { success: true, value: "GENERAL_ERROR" };
            case "TIMEOUT":
              return { success: true, value: "TIMEOUT" };
            default:
              return { success: false };
          }
        })(value);
      })(value["status"]);
    })();
    if (!parsed_status.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        answer: parsed_answer.value,
        contextDocs: parsed_contextDocs.value,
        detectedLanguageLabel: parsed_detectedLanguageLabel.value,
        previousQuestionUuid: parsed_previousQuestionUuid.value,
        question: parsed_question.value,
        questionUuid: parsed_questionUuid.value,
        relatedActionables: parsed_relatedActionables.value,
        relatedQuestions: parsed_relatedQuestions.value,
        status: parsed_status.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AskGetabstractAnswerContextDoc
export function serialize_AskGetabstractAnswerContextDoc(obj: AskGetabstractAnswerContextDoc): string {
  const serialized = ((value: {
    bookmarked: boolean;
    dataId: number;
    rating: number | null;
    referenceNumber: number;
    snippet: string;
    sourceTypeString: string;
    title: string;
    urls: {
      cover: string;
      list: string;
      reference: string;
    };
  }): unknown => {
    return {
      bookmarked: ((value: boolean): unknown => {
        return value;
      })(value["bookmarked"]),
      dataId: ((value: number): unknown => {
        return value;
      })(value["dataId"]),
      rating: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["rating"]),
      referenceNumber: ((value: number): unknown => {
        return value;
      })(value["referenceNumber"]),
      snippet: ((value: string): unknown => {
        return value;
      })(value["snippet"]),
      sourceTypeString: ((value: string): unknown => {
        return value;
      })(value["sourceTypeString"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
      urls: ((value: { cover: string; list: string; reference: string }): unknown => {
        return {
          cover: ((value: string): unknown => {
            return value;
          })(value["cover"]),
          list: ((value: string): unknown => {
            return value;
          })(value["list"]),
          reference: ((value: string): unknown => {
            return value;
          })(value["reference"]),
        };
      })(value["urls"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AskGetabstractAnswerContextDoc(str: string): AskGetabstractAnswerContextDoc | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          bookmarked: boolean;
          dataId: number;
          rating: number | null;
          referenceNumber: number;
          snippet: string;
          sourceTypeString: string;
          title: string;
          urls: {
            cover: string;
            list: string;
            reference: string;
          };
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "bookmarked")) {
      return { success: false };
    }
    const parsed_bookmarked = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["bookmarked"]);
    })();
    if (!parsed_bookmarked.success) {
      return { success: false };
    }
    if (!hasProp(value, "dataId")) {
      return { success: false };
    }
    const parsed_dataId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["dataId"]);
    })();
    if (!parsed_dataId.success) {
      return { success: false };
    }
    if (!hasProp(value, "rating")) {
      return { success: false };
    }
    const parsed_rating = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["rating"]);
    })();
    if (!parsed_rating.success) {
      return { success: false };
    }
    if (!hasProp(value, "referenceNumber")) {
      return { success: false };
    }
    const parsed_referenceNumber = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["referenceNumber"]);
    })();
    if (!parsed_referenceNumber.success) {
      return { success: false };
    }
    if (!hasProp(value, "snippet")) {
      return { success: false };
    }
    const parsed_snippet = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["snippet"]);
    })();
    if (!parsed_snippet.success) {
      return { success: false };
    }
    if (!hasProp(value, "sourceTypeString")) {
      return { success: false };
    }
    const parsed_sourceTypeString = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["sourceTypeString"]);
    })();
    if (!parsed_sourceTypeString.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    if (!hasProp(value, "urls")) {
      return { success: false };
    }
    const parsed_urls = (() => {
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value: {
              cover: string;
              list: string;
              reference: string;
            };
          } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        if (!hasProp(value, "cover")) {
          return { success: false };
        }
        const parsed_cover = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["cover"]);
        })();
        if (!parsed_cover.success) {
          return { success: false };
        }
        if (!hasProp(value, "list")) {
          return { success: false };
        }
        const parsed_list = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["list"]);
        })();
        if (!parsed_list.success) {
          return { success: false };
        }
        if (!hasProp(value, "reference")) {
          return { success: false };
        }
        const parsed_reference = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["reference"]);
        })();
        if (!parsed_reference.success) {
          return { success: false };
        }
        return { success: true, value: { cover: parsed_cover.value, list: parsed_list.value, reference: parsed_reference.value } };
      })(value["urls"]);
    })();
    if (!parsed_urls.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        bookmarked: parsed_bookmarked.value,
        dataId: parsed_dataId.value,
        rating: parsed_rating.value,
        referenceNumber: parsed_referenceNumber.value,
        snippet: parsed_snippet.value,
        sourceTypeString: parsed_sourceTypeString.value,
        title: parsed_title.value,
        urls: parsed_urls.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AskGetabstractAnswerStatus
export function serialize_AskGetabstractAnswerStatus(obj: AskGetabstractAnswerStatus): string {
  const serialized = ((value: "PROCESSING" | "FINISHED" | "NO_DOCS_FROM_VECTOR_DB" | "NO_DOCS_AFTER_FILTER" | "NO_REFERENCE_IN_ANSWER" | "GENERAL_ERROR" | "TIMEOUT"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AskGetabstractAnswerStatus(str: string): AskGetabstractAnswerStatus | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "PROCESSING" | "FINISHED" | "NO_DOCS_FROM_VECTOR_DB" | "NO_DOCS_AFTER_FILTER" | "NO_REFERENCE_IN_ANSWER" | "GENERAL_ERROR" | "TIMEOUT" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "PROCESSING":
        return { success: true, value: "PROCESSING" };
      case "FINISHED":
        return { success: true, value: "FINISHED" };
      case "NO_DOCS_FROM_VECTOR_DB":
        return { success: true, value: "NO_DOCS_FROM_VECTOR_DB" };
      case "NO_DOCS_AFTER_FILTER":
        return { success: true, value: "NO_DOCS_AFTER_FILTER" };
      case "NO_REFERENCE_IN_ANSWER":
        return { success: true, value: "NO_REFERENCE_IN_ANSWER" };
      case "GENERAL_ERROR":
        return { success: true, value: "GENERAL_ERROR" };
      case "TIMEOUT":
        return { success: true, value: "TIMEOUT" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AskGetabstractPortletView
export function serialize_AskGetabstractPortletView(obj: AskGetabstractPortletView): string {
  const serialized = ((value: { items: string[]; metadata: PortletMetadata }): unknown => {
    return {
      items: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["items"]),
      metadata: ((value: {
        custom: boolean;
        defaultFilterAllLanguages: boolean;
        embedSortingFiltering: boolean;
        hideTitle: boolean;
        itemCount: number;
        itemLayout: ItemLayout;
        name: string;
        portletId: number;
        portletLayout: PortletLayout;
        subType: string | null;
        subtitle: string;
        title: string;
        type: PortletType;
      }): unknown => {
        return {
          custom: ((value: boolean): unknown => {
            return value;
          })(value["custom"]),
          defaultFilterAllLanguages: ((value: boolean): unknown => {
            return value;
          })(value["defaultFilterAllLanguages"]),
          embedSortingFiltering: ((value: boolean): unknown => {
            return value;
          })(value["embedSortingFiltering"]),
          hideTitle: ((value: boolean): unknown => {
            return value;
          })(value["hideTitle"]),
          itemCount: ((value: number): unknown => {
            return value;
          })(value["itemCount"]),
          itemLayout: ((value: "MINI" | "REGULAR"): unknown => {
            return value;
          })(value["itemLayout"]),
          name: ((value: string): unknown => {
            return value;
          })(value["name"]),
          portletId: ((value: number): unknown => {
            return value;
          })(value["portletId"]),
          portletLayout: ((value: "GRID" | "CAROUSEL"): unknown => {
            return value;
          })(value["portletLayout"]),
          subType: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["subType"]),
          subtitle: ((value: string): unknown => {
            return value;
          })(value["subtitle"]),
          title: ((value: string): unknown => {
            return value;
          })(value["title"]),
          type: ((
            value:
              | "IMAGETEXT"
              | "SUMMARYLIST"
              | "TRENDINGCHANNEL"
              | "FEATUREDCHANNEL"
              | "FOLLOWINGCHANNEL"
              | "MYLIST"
              | "PRIMARYTOPICCHANNEL"
              | "CHANNELLIST"
              | "ANNOTATIONLIST"
              | "LGXPLIST"
              | "SKETCHNOTESLGXP"
              | "ACTIONABLELIST"
              | "ACTIONABLELISTCUSTOM"
              | "MYACTIONABLES"
              | "FREESUMMARY"
              | "SUMMARYLISTCUSTOM"
              | "CUSTOMPAGELIST"
              | "PROMOBOXCONFIG"
              | "MYMIABS"
              | "ASKGETABSTRACT"
              | "CUSTOMIZEDMIABS",
          ): unknown => {
            return value;
          })(value["type"]),
        };
      })(value["metadata"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AskGetabstractPortletView(str: string): AskGetabstractPortletView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: string[];
          metadata: PortletMetadata;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "metadata")) {
      return { success: false };
    }
    const parsed_metadata = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PortletMetadata } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                custom: boolean;
                defaultFilterAllLanguages: boolean;
                embedSortingFiltering: boolean;
                hideTitle: boolean;
                itemCount: number;
                itemLayout: ItemLayout;
                name: string;
                portletId: number;
                portletLayout: PortletLayout;
                subType: string | null;
                subtitle: string;
                title: string;
                type: PortletType;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "custom")) {
            return { success: false };
          }
          const parsed_custom = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["custom"]);
          })();
          if (!parsed_custom.success) {
            return { success: false };
          }
          if (!hasProp(value, "defaultFilterAllLanguages")) {
            return { success: false };
          }
          const parsed_defaultFilterAllLanguages = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["defaultFilterAllLanguages"]);
          })();
          if (!parsed_defaultFilterAllLanguages.success) {
            return { success: false };
          }
          if (!hasProp(value, "embedSortingFiltering")) {
            return { success: false };
          }
          const parsed_embedSortingFiltering = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["embedSortingFiltering"]);
          })();
          if (!parsed_embedSortingFiltering.success) {
            return { success: false };
          }
          if (!hasProp(value, "hideTitle")) {
            return { success: false };
          }
          const parsed_hideTitle = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["hideTitle"]);
          })();
          if (!parsed_hideTitle.success) {
            return { success: false };
          }
          if (!hasProp(value, "itemCount")) {
            return { success: false };
          }
          const parsed_itemCount = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["itemCount"]);
          })();
          if (!parsed_itemCount.success) {
            return { success: false };
          }
          if (!hasProp(value, "itemLayout")) {
            return { success: false };
          }
          const parsed_itemLayout = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ItemLayout } => {
              return ((value: unknown): { success: false } | { success: true; value: "MINI" | "REGULAR" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "MINI":
                    return { success: true, value: "MINI" };
                  case "REGULAR":
                    return { success: true, value: "REGULAR" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["itemLayout"]);
          })();
          if (!parsed_itemLayout.success) {
            return { success: false };
          }
          if (!hasProp(value, "name")) {
            return { success: false };
          }
          const parsed_name = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["name"]);
          })();
          if (!parsed_name.success) {
            return { success: false };
          }
          if (!hasProp(value, "portletId")) {
            return { success: false };
          }
          const parsed_portletId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["portletId"]);
          })();
          if (!parsed_portletId.success) {
            return { success: false };
          }
          if (!hasProp(value, "portletLayout")) {
            return { success: false };
          }
          const parsed_portletLayout = (() => {
            return ((value: unknown): { success: false } | { success: true; value: PortletLayout } => {
              return ((value: unknown): { success: false } | { success: true; value: "GRID" | "CAROUSEL" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "GRID":
                    return { success: true, value: "GRID" };
                  case "CAROUSEL":
                    return { success: true, value: "CAROUSEL" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["portletLayout"]);
          })();
          if (!parsed_portletLayout.success) {
            return { success: false };
          }
          if (!hasProp(value, "subType")) {
            return { success: false };
          }
          const parsed_subType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["subType"]);
          })();
          if (!parsed_subType.success) {
            return { success: false };
          }
          if (!hasProp(value, "subtitle")) {
            return { success: false };
          }
          const parsed_subtitle = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["subtitle"]);
          })();
          if (!parsed_subtitle.success) {
            return { success: false };
          }
          if (!hasProp(value, "title")) {
            return { success: false };
          }
          const parsed_title = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["title"]);
          })();
          if (!parsed_title.success) {
            return { success: false };
          }
          if (!hasProp(value, "type")) {
            return { success: false };
          }
          const parsed_type = (() => {
            return ((value: unknown): { success: false } | { success: true; value: PortletType } => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value:
                      | "IMAGETEXT"
                      | "SUMMARYLIST"
                      | "TRENDINGCHANNEL"
                      | "FEATUREDCHANNEL"
                      | "FOLLOWINGCHANNEL"
                      | "MYLIST"
                      | "PRIMARYTOPICCHANNEL"
                      | "CHANNELLIST"
                      | "ANNOTATIONLIST"
                      | "LGXPLIST"
                      | "SKETCHNOTESLGXP"
                      | "ACTIONABLELIST"
                      | "ACTIONABLELISTCUSTOM"
                      | "MYACTIONABLES"
                      | "FREESUMMARY"
                      | "SUMMARYLISTCUSTOM"
                      | "CUSTOMPAGELIST"
                      | "PROMOBOXCONFIG"
                      | "MYMIABS"
                      | "ASKGETABSTRACT"
                      | "CUSTOMIZEDMIABS";
                  } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "IMAGETEXT":
                    return { success: true, value: "IMAGETEXT" };
                  case "SUMMARYLIST":
                    return { success: true, value: "SUMMARYLIST" };
                  case "TRENDINGCHANNEL":
                    return { success: true, value: "TRENDINGCHANNEL" };
                  case "FEATUREDCHANNEL":
                    return { success: true, value: "FEATUREDCHANNEL" };
                  case "FOLLOWINGCHANNEL":
                    return { success: true, value: "FOLLOWINGCHANNEL" };
                  case "MYLIST":
                    return { success: true, value: "MYLIST" };
                  case "PRIMARYTOPICCHANNEL":
                    return { success: true, value: "PRIMARYTOPICCHANNEL" };
                  case "CHANNELLIST":
                    return { success: true, value: "CHANNELLIST" };
                  case "ANNOTATIONLIST":
                    return { success: true, value: "ANNOTATIONLIST" };
                  case "LGXPLIST":
                    return { success: true, value: "LGXPLIST" };
                  case "SKETCHNOTESLGXP":
                    return { success: true, value: "SKETCHNOTESLGXP" };
                  case "ACTIONABLELIST":
                    return { success: true, value: "ACTIONABLELIST" };
                  case "ACTIONABLELISTCUSTOM":
                    return { success: true, value: "ACTIONABLELISTCUSTOM" };
                  case "MYACTIONABLES":
                    return { success: true, value: "MYACTIONABLES" };
                  case "FREESUMMARY":
                    return { success: true, value: "FREESUMMARY" };
                  case "SUMMARYLISTCUSTOM":
                    return { success: true, value: "SUMMARYLISTCUSTOM" };
                  case "CUSTOMPAGELIST":
                    return { success: true, value: "CUSTOMPAGELIST" };
                  case "PROMOBOXCONFIG":
                    return { success: true, value: "PROMOBOXCONFIG" };
                  case "MYMIABS":
                    return { success: true, value: "MYMIABS" };
                  case "ASKGETABSTRACT":
                    return { success: true, value: "ASKGETABSTRACT" };
                  case "CUSTOMIZEDMIABS":
                    return { success: true, value: "CUSTOMIZEDMIABS" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["type"]);
          })();
          if (!parsed_type.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              custom: parsed_custom.value,
              defaultFilterAllLanguages: parsed_defaultFilterAllLanguages.value,
              embedSortingFiltering: parsed_embedSortingFiltering.value,
              hideTitle: parsed_hideTitle.value,
              itemCount: parsed_itemCount.value,
              itemLayout: parsed_itemLayout.value,
              name: parsed_name.value,
              portletId: parsed_portletId.value,
              portletLayout: parsed_portletLayout.value,
              subType: parsed_subType.value,
              subtitle: parsed_subtitle.value,
              title: parsed_title.value,
              type: parsed_type.value,
            },
          };
        })(value);
      })(value["metadata"]);
    })();
    if (!parsed_metadata.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, metadata: parsed_metadata.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AskGetabstractProperties
export function serialize_AskGetabstractProperties(obj: AskGetabstractProperties): string {
  const serialized = ((value: { isTrendingQuestion: boolean; language: Language; question: string | null; questionUuid: string | null; showAiDataProtectionDisclaimer: boolean }): unknown => {
    return {
      isTrendingQuestion: ((value: boolean): unknown => {
        return value;
      })(value["isTrendingQuestion"]),
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      question: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["question"]),
      questionUuid: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["questionUuid"]),
      showAiDataProtectionDisclaimer: ((value: boolean): unknown => {
        return value;
      })(value["showAiDataProtectionDisclaimer"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AskGetabstractProperties(str: string): AskGetabstractProperties | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          isTrendingQuestion: boolean;
          language: Language;
          question: string | null;
          questionUuid: string | null;
          showAiDataProtectionDisclaimer: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "isTrendingQuestion")) {
      return { success: false };
    }
    const parsed_isTrendingQuestion = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isTrendingQuestion"]);
    })();
    if (!parsed_isTrendingQuestion.success) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language } => {
        return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "en":
              return { success: true, value: "en" };
            case "de":
              return { success: true, value: "de" };
            case "es":
              return { success: true, value: "es" };
            case "ru":
              return { success: true, value: "ru" };
            case "zh":
              return { success: true, value: "zh" };
            case "pt":
              return { success: true, value: "pt" };
            case "fr":
              return { success: true, value: "fr" };
            default:
              return { success: false };
          }
        })(value);
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "question")) {
      return { success: false };
    }
    const parsed_question = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["question"]);
    })();
    if (!parsed_question.success) {
      return { success: false };
    }
    if (!hasProp(value, "questionUuid")) {
      return { success: false };
    }
    const parsed_questionUuid = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["questionUuid"]);
    })();
    if (!parsed_questionUuid.success) {
      return { success: false };
    }
    if (!hasProp(value, "showAiDataProtectionDisclaimer")) {
      return { success: false };
    }
    const parsed_showAiDataProtectionDisclaimer = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["showAiDataProtectionDisclaimer"]);
    })();
    if (!parsed_showAiDataProtectionDisclaimer.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        isTrendingQuestion: parsed_isTrendingQuestion.value,
        language: parsed_language.value,
        question: parsed_question.value,
        questionUuid: parsed_questionUuid.value,
        showAiDataProtectionDisclaimer: parsed_showAiDataProtectionDisclaimer.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AskGetabstractQuestion
export function serialize_AskGetabstractQuestion(obj: AskGetabstractQuestion): string {
  const serialized = ((value: {
    analyticsEventVariant: AskGetabstractAnalyticsEventVariant;
    blacklistQuestions: string[];
    previousQuestionUuid: string | null;
    question: string;
    questionUuid: string;
    relatedQuestionStep: number;
    trendingQuestion: boolean;
  }): unknown => {
    return {
      analyticsEventVariant: ((value: "default" | "qa_triggered_from_search" | "qa_demo"): unknown => {
        return value;
      })(value["analyticsEventVariant"]),
      blacklistQuestions: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["blacklistQuestions"]),
      previousQuestionUuid: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["previousQuestionUuid"]),
      question: ((value: string): unknown => {
        return value;
      })(value["question"]),
      questionUuid: ((value: string): unknown => {
        return value;
      })(value["questionUuid"]),
      relatedQuestionStep: ((value: number): unknown => {
        return value;
      })(value["relatedQuestionStep"]),
      trendingQuestion: ((value: boolean): unknown => {
        return value;
      })(value["trendingQuestion"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AskGetabstractQuestion(str: string): AskGetabstractQuestion | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          analyticsEventVariant: AskGetabstractAnalyticsEventVariant;
          blacklistQuestions: string[];
          previousQuestionUuid: string | null;
          question: string;
          questionUuid: string;
          relatedQuestionStep: number;
          trendingQuestion: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "analyticsEventVariant")) {
      return { success: false };
    }
    const parsed_analyticsEventVariant = (() => {
      return ((value: unknown): { success: false } | { success: true; value: AskGetabstractAnalyticsEventVariant } => {
        return ((value: unknown): { success: false } | { success: true; value: "default" | "qa_triggered_from_search" | "qa_demo" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "default":
              return { success: true, value: "default" };
            case "qa_triggered_from_search":
              return { success: true, value: "qa_triggered_from_search" };
            case "qa_demo":
              return { success: true, value: "qa_demo" };
            default:
              return { success: false };
          }
        })(value);
      })(value["analyticsEventVariant"]);
    })();
    if (!parsed_analyticsEventVariant.success) {
      return { success: false };
    }
    if (!hasProp(value, "blacklistQuestions")) {
      return { success: false };
    }
    const parsed_blacklistQuestions = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["blacklistQuestions"]);
    })();
    if (!parsed_blacklistQuestions.success) {
      return { success: false };
    }
    if (!hasProp(value, "previousQuestionUuid")) {
      return { success: false };
    }
    const parsed_previousQuestionUuid = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["previousQuestionUuid"]);
    })();
    if (!parsed_previousQuestionUuid.success) {
      return { success: false };
    }
    if (!hasProp(value, "question")) {
      return { success: false };
    }
    const parsed_question = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["question"]);
    })();
    if (!parsed_question.success) {
      return { success: false };
    }
    if (!hasProp(value, "questionUuid")) {
      return { success: false };
    }
    const parsed_questionUuid = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["questionUuid"]);
    })();
    if (!parsed_questionUuid.success) {
      return { success: false };
    }
    if (!hasProp(value, "relatedQuestionStep")) {
      return { success: false };
    }
    const parsed_relatedQuestionStep = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["relatedQuestionStep"]);
    })();
    if (!parsed_relatedQuestionStep.success) {
      return { success: false };
    }
    if (!hasProp(value, "trendingQuestion")) {
      return { success: false };
    }
    const parsed_trendingQuestion = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["trendingQuestion"]);
    })();
    if (!parsed_trendingQuestion.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        analyticsEventVariant: parsed_analyticsEventVariant.value,
        blacklistQuestions: parsed_blacklistQuestions.value,
        previousQuestionUuid: parsed_previousQuestionUuid.value,
        question: parsed_question.value,
        questionUuid: parsed_questionUuid.value,
        relatedQuestionStep: parsed_relatedQuestionStep.value,
        trendingQuestion: parsed_trendingQuestion.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AskGetabstractQuestionAnswers
export function serialize_AskGetabstractQuestionAnswers(obj: AskGetabstractQuestionAnswers): string {
  const serialized = ((value: { current: AskGetabstractAnswer; history: AskGetabstractAnswer[] }): unknown => {
    return {
      current: ((value: {
        answer: string;
        contextDocs: AskGetabstractAnswerContextDoc[];
        detectedLanguageLabel: string | null;
        previousQuestionUuid: string | null;
        question: string;
        questionUuid: string;
        relatedActionables: ActionableMiniView[];
        relatedQuestions: string[];
        status: AskGetabstractAnswerStatus;
      }): unknown => {
        return {
          answer: ((value: string): unknown => {
            return value;
          })(value["answer"]),
          contextDocs: ((value: AskGetabstractAnswerContextDoc[]): unknown => {
            return value.map(
              (value: {
                bookmarked: boolean;
                dataId: number;
                rating: number | null;
                referenceNumber: number;
                snippet: string;
                sourceTypeString: string;
                title: string;
                urls: {
                  cover: string;
                  list: string;
                  reference: string;
                };
              }): unknown => {
                return {
                  bookmarked: ((value: boolean): unknown => {
                    return value;
                  })(value["bookmarked"]),
                  dataId: ((value: number): unknown => {
                    return value;
                  })(value["dataId"]),
                  rating: ((value: number | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["rating"]),
                  referenceNumber: ((value: number): unknown => {
                    return value;
                  })(value["referenceNumber"]),
                  snippet: ((value: string): unknown => {
                    return value;
                  })(value["snippet"]),
                  sourceTypeString: ((value: string): unknown => {
                    return value;
                  })(value["sourceTypeString"]),
                  title: ((value: string): unknown => {
                    return value;
                  })(value["title"]),
                  urls: ((value: { cover: string; list: string; reference: string }): unknown => {
                    return {
                      cover: ((value: string): unknown => {
                        return value;
                      })(value["cover"]),
                      list: ((value: string): unknown => {
                        return value;
                      })(value["list"]),
                      reference: ((value: string): unknown => {
                        return value;
                      })(value["reference"]),
                    };
                  })(value["urls"]),
                };
              },
            );
          })(value["contextDocs"]),
          detectedLanguageLabel: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["detectedLanguageLabel"]),
          previousQuestionUuid: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["previousQuestionUuid"]),
          question: ((value: string): unknown => {
            return value;
          })(value["question"]),
          questionUuid: ((value: string): unknown => {
            return value;
          })(value["questionUuid"]),
          relatedActionables: ((value: ActionableMiniView[]): unknown => {
            return value.map((value: { actionableId: bigint; coverUri: string; name: string; title: string }): unknown => {
              return {
                actionableId: ((value: bigint): unknown => {
                  return value;
                })(value["actionableId"]),
                coverUri: ((value: string): unknown => {
                  return value;
                })(value["coverUri"]),
                name: ((value: string): unknown => {
                  return value;
                })(value["name"]),
                title: ((value: string): unknown => {
                  return value;
                })(value["title"]),
              };
            });
          })(value["relatedActionables"]),
          relatedQuestions: ((value: string[]): unknown => {
            return value.map((value: string): unknown => {
              return value;
            });
          })(value["relatedQuestions"]),
          status: ((value: "PROCESSING" | "FINISHED" | "NO_DOCS_FROM_VECTOR_DB" | "NO_DOCS_AFTER_FILTER" | "NO_REFERENCE_IN_ANSWER" | "GENERAL_ERROR" | "TIMEOUT"): unknown => {
            return value;
          })(value["status"]),
        };
      })(value["current"]),
      history: ((value: AskGetabstractAnswer[]): unknown => {
        return value.map(
          (value: {
            answer: string;
            contextDocs: AskGetabstractAnswerContextDoc[];
            detectedLanguageLabel: string | null;
            previousQuestionUuid: string | null;
            question: string;
            questionUuid: string;
            relatedActionables: ActionableMiniView[];
            relatedQuestions: string[];
            status: AskGetabstractAnswerStatus;
          }): unknown => {
            return {
              answer: ((value: string): unknown => {
                return value;
              })(value["answer"]),
              contextDocs: ((value: AskGetabstractAnswerContextDoc[]): unknown => {
                return value.map(
                  (value: {
                    bookmarked: boolean;
                    dataId: number;
                    rating: number | null;
                    referenceNumber: number;
                    snippet: string;
                    sourceTypeString: string;
                    title: string;
                    urls: {
                      cover: string;
                      list: string;
                      reference: string;
                    };
                  }): unknown => {
                    return {
                      bookmarked: ((value: boolean): unknown => {
                        return value;
                      })(value["bookmarked"]),
                      dataId: ((value: number): unknown => {
                        return value;
                      })(value["dataId"]),
                      rating: ((value: number | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["rating"]),
                      referenceNumber: ((value: number): unknown => {
                        return value;
                      })(value["referenceNumber"]),
                      snippet: ((value: string): unknown => {
                        return value;
                      })(value["snippet"]),
                      sourceTypeString: ((value: string): unknown => {
                        return value;
                      })(value["sourceTypeString"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                      urls: ((value: { cover: string; list: string; reference: string }): unknown => {
                        return {
                          cover: ((value: string): unknown => {
                            return value;
                          })(value["cover"]),
                          list: ((value: string): unknown => {
                            return value;
                          })(value["list"]),
                          reference: ((value: string): unknown => {
                            return value;
                          })(value["reference"]),
                        };
                      })(value["urls"]),
                    };
                  },
                );
              })(value["contextDocs"]),
              detectedLanguageLabel: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["detectedLanguageLabel"]),
              previousQuestionUuid: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["previousQuestionUuid"]),
              question: ((value: string): unknown => {
                return value;
              })(value["question"]),
              questionUuid: ((value: string): unknown => {
                return value;
              })(value["questionUuid"]),
              relatedActionables: ((value: ActionableMiniView[]): unknown => {
                return value.map((value: { actionableId: bigint; coverUri: string; name: string; title: string }): unknown => {
                  return {
                    actionableId: ((value: bigint): unknown => {
                      return value;
                    })(value["actionableId"]),
                    coverUri: ((value: string): unknown => {
                      return value;
                    })(value["coverUri"]),
                    name: ((value: string): unknown => {
                      return value;
                    })(value["name"]),
                    title: ((value: string): unknown => {
                      return value;
                    })(value["title"]),
                  };
                });
              })(value["relatedActionables"]),
              relatedQuestions: ((value: string[]): unknown => {
                return value.map((value: string): unknown => {
                  return value;
                });
              })(value["relatedQuestions"]),
              status: ((value: "PROCESSING" | "FINISHED" | "NO_DOCS_FROM_VECTOR_DB" | "NO_DOCS_AFTER_FILTER" | "NO_REFERENCE_IN_ANSWER" | "GENERAL_ERROR" | "TIMEOUT"): unknown => {
                return value;
              })(value["status"]),
            };
          },
        );
      })(value["history"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AskGetabstractQuestionAnswers(str: string): AskGetabstractQuestionAnswers | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          current: AskGetabstractAnswer;
          history: AskGetabstractAnswer[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "current")) {
      return { success: false };
    }
    const parsed_current = (() => {
      return ((value: unknown): { success: false } | { success: true; value: AskGetabstractAnswer } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                answer: string;
                contextDocs: AskGetabstractAnswerContextDoc[];
                detectedLanguageLabel: string | null;
                previousQuestionUuid: string | null;
                question: string;
                questionUuid: string;
                relatedActionables: ActionableMiniView[];
                relatedQuestions: string[];
                status: AskGetabstractAnswerStatus;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "answer")) {
            return { success: false };
          }
          const parsed_answer = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["answer"]);
          })();
          if (!parsed_answer.success) {
            return { success: false };
          }
          if (!hasProp(value, "contextDocs")) {
            return { success: false };
          }
          const parsed_contextDocs = (() => {
            return ((value: unknown): { success: false } | { success: true; value: AskGetabstractAnswerContextDoc[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: AskGetabstractAnswerContextDoc } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        bookmarked: boolean;
                        dataId: number;
                        rating: number | null;
                        referenceNumber: number;
                        snippet: string;
                        sourceTypeString: string;
                        title: string;
                        urls: {
                          cover: string;
                          list: string;
                          reference: string;
                        };
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "bookmarked")) {
                    return { success: false };
                  }
                  const parsed_bookmarked = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["bookmarked"]);
                  })();
                  if (!parsed_bookmarked.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "dataId")) {
                    return { success: false };
                  }
                  const parsed_dataId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["dataId"]);
                  })();
                  if (!parsed_dataId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "rating")) {
                    return { success: false };
                  }
                  const parsed_rating = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["rating"]);
                  })();
                  if (!parsed_rating.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "referenceNumber")) {
                    return { success: false };
                  }
                  const parsed_referenceNumber = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["referenceNumber"]);
                  })();
                  if (!parsed_referenceNumber.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "snippet")) {
                    return { success: false };
                  }
                  const parsed_snippet = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["snippet"]);
                  })();
                  if (!parsed_snippet.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "sourceTypeString")) {
                    return { success: false };
                  }
                  const parsed_sourceTypeString = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["sourceTypeString"]);
                  })();
                  if (!parsed_sourceTypeString.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "title")) {
                    return { success: false };
                  }
                  const parsed_title = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["title"]);
                  })();
                  if (!parsed_title.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "urls")) {
                    return { success: false };
                  }
                  const parsed_urls = (() => {
                    return ((
                      value: unknown,
                    ):
                      | { success: false }
                      | {
                          success: true;
                          value: {
                            cover: string;
                            list: string;
                            reference: string;
                          };
                        } => {
                      if (typeof value !== "object") {
                        return { success: false };
                      }
                      if (value === null) {
                        return { success: false };
                      }
                      if (!hasProp(value, "cover")) {
                        return { success: false };
                      }
                      const parsed_cover = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: string } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          return { success: true, value: value };
                        })(value["cover"]);
                      })();
                      if (!parsed_cover.success) {
                        return { success: false };
                      }
                      if (!hasProp(value, "list")) {
                        return { success: false };
                      }
                      const parsed_list = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: string } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          return { success: true, value: value };
                        })(value["list"]);
                      })();
                      if (!parsed_list.success) {
                        return { success: false };
                      }
                      if (!hasProp(value, "reference")) {
                        return { success: false };
                      }
                      const parsed_reference = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: string } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          return { success: true, value: value };
                        })(value["reference"]);
                      })();
                      if (!parsed_reference.success) {
                        return { success: false };
                      }
                      return { success: true, value: { cover: parsed_cover.value, list: parsed_list.value, reference: parsed_reference.value } };
                    })(value["urls"]);
                  })();
                  if (!parsed_urls.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      bookmarked: parsed_bookmarked.value,
                      dataId: parsed_dataId.value,
                      rating: parsed_rating.value,
                      referenceNumber: parsed_referenceNumber.value,
                      snippet: parsed_snippet.value,
                      sourceTypeString: parsed_sourceTypeString.value,
                      title: parsed_title.value,
                      urls: parsed_urls.value,
                    },
                  };
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["contextDocs"]);
          })();
          if (!parsed_contextDocs.success) {
            return { success: false };
          }
          if (!hasProp(value, "detectedLanguageLabel")) {
            return { success: false };
          }
          const parsed_detectedLanguageLabel = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["detectedLanguageLabel"]);
          })();
          if (!parsed_detectedLanguageLabel.success) {
            return { success: false };
          }
          if (!hasProp(value, "previousQuestionUuid")) {
            return { success: false };
          }
          const parsed_previousQuestionUuid = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["previousQuestionUuid"]);
          })();
          if (!parsed_previousQuestionUuid.success) {
            return { success: false };
          }
          if (!hasProp(value, "question")) {
            return { success: false };
          }
          const parsed_question = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["question"]);
          })();
          if (!parsed_question.success) {
            return { success: false };
          }
          if (!hasProp(value, "questionUuid")) {
            return { success: false };
          }
          const parsed_questionUuid = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["questionUuid"]);
          })();
          if (!parsed_questionUuid.success) {
            return { success: false };
          }
          if (!hasProp(value, "relatedActionables")) {
            return { success: false };
          }
          const parsed_relatedActionables = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ActionableMiniView[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: ActionableMiniView } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        actionableId: bigint;
                        coverUri: string;
                        name: string;
                        title: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "actionableId")) {
                    return { success: false };
                  }
                  const parsed_actionableId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["actionableId"]);
                  })();
                  if (!parsed_actionableId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "coverUri")) {
                    return { success: false };
                  }
                  const parsed_coverUri = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["coverUri"]);
                  })();
                  if (!parsed_coverUri.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "name")) {
                    return { success: false };
                  }
                  const parsed_name = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["name"]);
                  })();
                  if (!parsed_name.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "title")) {
                    return { success: false };
                  }
                  const parsed_title = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["title"]);
                  })();
                  if (!parsed_title.success) {
                    return { success: false };
                  }
                  return { success: true, value: { actionableId: parsed_actionableId.value, coverUri: parsed_coverUri.value, name: parsed_name.value, title: parsed_title.value } };
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["relatedActionables"]);
          })();
          if (!parsed_relatedActionables.success) {
            return { success: false };
          }
          if (!hasProp(value, "relatedQuestions")) {
            return { success: false };
          }
          const parsed_relatedQuestions = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["relatedQuestions"]);
          })();
          if (!parsed_relatedQuestions.success) {
            return { success: false };
          }
          if (!hasProp(value, "status")) {
            return { success: false };
          }
          const parsed_status = (() => {
            return ((value: unknown): { success: false } | { success: true; value: AskGetabstractAnswerStatus } => {
              return ((value: unknown): { success: false } | { success: true; value: "PROCESSING" | "FINISHED" | "NO_DOCS_FROM_VECTOR_DB" | "NO_DOCS_AFTER_FILTER" | "NO_REFERENCE_IN_ANSWER" | "GENERAL_ERROR" | "TIMEOUT" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "PROCESSING":
                    return { success: true, value: "PROCESSING" };
                  case "FINISHED":
                    return { success: true, value: "FINISHED" };
                  case "NO_DOCS_FROM_VECTOR_DB":
                    return { success: true, value: "NO_DOCS_FROM_VECTOR_DB" };
                  case "NO_DOCS_AFTER_FILTER":
                    return { success: true, value: "NO_DOCS_AFTER_FILTER" };
                  case "NO_REFERENCE_IN_ANSWER":
                    return { success: true, value: "NO_REFERENCE_IN_ANSWER" };
                  case "GENERAL_ERROR":
                    return { success: true, value: "GENERAL_ERROR" };
                  case "TIMEOUT":
                    return { success: true, value: "TIMEOUT" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["status"]);
          })();
          if (!parsed_status.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              answer: parsed_answer.value,
              contextDocs: parsed_contextDocs.value,
              detectedLanguageLabel: parsed_detectedLanguageLabel.value,
              previousQuestionUuid: parsed_previousQuestionUuid.value,
              question: parsed_question.value,
              questionUuid: parsed_questionUuid.value,
              relatedActionables: parsed_relatedActionables.value,
              relatedQuestions: parsed_relatedQuestions.value,
              status: parsed_status.value,
            },
          };
        })(value);
      })(value["current"]);
    })();
    if (!parsed_current.success) {
      return { success: false };
    }
    if (!hasProp(value, "history")) {
      return { success: false };
    }
    const parsed_history = (() => {
      return ((value: unknown): { success: false } | { success: true; value: AskGetabstractAnswer[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: AskGetabstractAnswer } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  answer: string;
                  contextDocs: AskGetabstractAnswerContextDoc[];
                  detectedLanguageLabel: string | null;
                  previousQuestionUuid: string | null;
                  question: string;
                  questionUuid: string;
                  relatedActionables: ActionableMiniView[];
                  relatedQuestions: string[];
                  status: AskGetabstractAnswerStatus;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "answer")) {
              return { success: false };
            }
            const parsed_answer = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["answer"]);
            })();
            if (!parsed_answer.success) {
              return { success: false };
            }
            if (!hasProp(value, "contextDocs")) {
              return { success: false };
            }
            const parsed_contextDocs = (() => {
              return ((value: unknown): { success: false } | { success: true; value: AskGetabstractAnswerContextDoc[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: AskGetabstractAnswerContextDoc } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          bookmarked: boolean;
                          dataId: number;
                          rating: number | null;
                          referenceNumber: number;
                          snippet: string;
                          sourceTypeString: string;
                          title: string;
                          urls: {
                            cover: string;
                            list: string;
                            reference: string;
                          };
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "bookmarked")) {
                      return { success: false };
                    }
                    const parsed_bookmarked = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["bookmarked"]);
                    })();
                    if (!parsed_bookmarked.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "dataId")) {
                      return { success: false };
                    }
                    const parsed_dataId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["dataId"]);
                    })();
                    if (!parsed_dataId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "rating")) {
                      return { success: false };
                    }
                    const parsed_rating = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["rating"]);
                    })();
                    if (!parsed_rating.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "referenceNumber")) {
                      return { success: false };
                    }
                    const parsed_referenceNumber = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["referenceNumber"]);
                    })();
                    if (!parsed_referenceNumber.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "snippet")) {
                      return { success: false };
                    }
                    const parsed_snippet = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["snippet"]);
                    })();
                    if (!parsed_snippet.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "sourceTypeString")) {
                      return { success: false };
                    }
                    const parsed_sourceTypeString = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["sourceTypeString"]);
                    })();
                    if (!parsed_sourceTypeString.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "title")) {
                      return { success: false };
                    }
                    const parsed_title = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["title"]);
                    })();
                    if (!parsed_title.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "urls")) {
                      return { success: false };
                    }
                    const parsed_urls = (() => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              cover: string;
                              list: string;
                              reference: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "cover")) {
                          return { success: false };
                        }
                        const parsed_cover = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["cover"]);
                        })();
                        if (!parsed_cover.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "list")) {
                          return { success: false };
                        }
                        const parsed_list = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["list"]);
                        })();
                        if (!parsed_list.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "reference")) {
                          return { success: false };
                        }
                        const parsed_reference = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["reference"]);
                        })();
                        if (!parsed_reference.success) {
                          return { success: false };
                        }
                        return { success: true, value: { cover: parsed_cover.value, list: parsed_list.value, reference: parsed_reference.value } };
                      })(value["urls"]);
                    })();
                    if (!parsed_urls.success) {
                      return { success: false };
                    }
                    return {
                      success: true,
                      value: {
                        bookmarked: parsed_bookmarked.value,
                        dataId: parsed_dataId.value,
                        rating: parsed_rating.value,
                        referenceNumber: parsed_referenceNumber.value,
                        snippet: parsed_snippet.value,
                        sourceTypeString: parsed_sourceTypeString.value,
                        title: parsed_title.value,
                        urls: parsed_urls.value,
                      },
                    };
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["contextDocs"]);
            })();
            if (!parsed_contextDocs.success) {
              return { success: false };
            }
            if (!hasProp(value, "detectedLanguageLabel")) {
              return { success: false };
            }
            const parsed_detectedLanguageLabel = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["detectedLanguageLabel"]);
            })();
            if (!parsed_detectedLanguageLabel.success) {
              return { success: false };
            }
            if (!hasProp(value, "previousQuestionUuid")) {
              return { success: false };
            }
            const parsed_previousQuestionUuid = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["previousQuestionUuid"]);
            })();
            if (!parsed_previousQuestionUuid.success) {
              return { success: false };
            }
            if (!hasProp(value, "question")) {
              return { success: false };
            }
            const parsed_question = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["question"]);
            })();
            if (!parsed_question.success) {
              return { success: false };
            }
            if (!hasProp(value, "questionUuid")) {
              return { success: false };
            }
            const parsed_questionUuid = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["questionUuid"]);
            })();
            if (!parsed_questionUuid.success) {
              return { success: false };
            }
            if (!hasProp(value, "relatedActionables")) {
              return { success: false };
            }
            const parsed_relatedActionables = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ActionableMiniView[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: ActionableMiniView } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          actionableId: bigint;
                          coverUri: string;
                          name: string;
                          title: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "actionableId")) {
                      return { success: false };
                    }
                    const parsed_actionableId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["actionableId"]);
                    })();
                    if (!parsed_actionableId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "coverUri")) {
                      return { success: false };
                    }
                    const parsed_coverUri = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["coverUri"]);
                    })();
                    if (!parsed_coverUri.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "name")) {
                      return { success: false };
                    }
                    const parsed_name = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["name"]);
                    })();
                    if (!parsed_name.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "title")) {
                      return { success: false };
                    }
                    const parsed_title = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["title"]);
                    })();
                    if (!parsed_title.success) {
                      return { success: false };
                    }
                    return { success: true, value: { actionableId: parsed_actionableId.value, coverUri: parsed_coverUri.value, name: parsed_name.value, title: parsed_title.value } };
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["relatedActionables"]);
            })();
            if (!parsed_relatedActionables.success) {
              return { success: false };
            }
            if (!hasProp(value, "relatedQuestions")) {
              return { success: false };
            }
            const parsed_relatedQuestions = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["relatedQuestions"]);
            })();
            if (!parsed_relatedQuestions.success) {
              return { success: false };
            }
            if (!hasProp(value, "status")) {
              return { success: false };
            }
            const parsed_status = (() => {
              return ((value: unknown): { success: false } | { success: true; value: AskGetabstractAnswerStatus } => {
                return ((value: unknown): { success: false } | { success: true; value: "PROCESSING" | "FINISHED" | "NO_DOCS_FROM_VECTOR_DB" | "NO_DOCS_AFTER_FILTER" | "NO_REFERENCE_IN_ANSWER" | "GENERAL_ERROR" | "TIMEOUT" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "PROCESSING":
                      return { success: true, value: "PROCESSING" };
                    case "FINISHED":
                      return { success: true, value: "FINISHED" };
                    case "NO_DOCS_FROM_VECTOR_DB":
                      return { success: true, value: "NO_DOCS_FROM_VECTOR_DB" };
                    case "NO_DOCS_AFTER_FILTER":
                      return { success: true, value: "NO_DOCS_AFTER_FILTER" };
                    case "NO_REFERENCE_IN_ANSWER":
                      return { success: true, value: "NO_REFERENCE_IN_ANSWER" };
                    case "GENERAL_ERROR":
                      return { success: true, value: "GENERAL_ERROR" };
                    case "TIMEOUT":
                      return { success: true, value: "TIMEOUT" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["status"]);
            })();
            if (!parsed_status.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                answer: parsed_answer.value,
                contextDocs: parsed_contextDocs.value,
                detectedLanguageLabel: parsed_detectedLanguageLabel.value,
                previousQuestionUuid: parsed_previousQuestionUuid.value,
                question: parsed_question.value,
                questionUuid: parsed_questionUuid.value,
                relatedActionables: parsed_relatedActionables.value,
                relatedQuestions: parsed_relatedQuestions.value,
                status: parsed_status.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["history"]);
    })();
    if (!parsed_history.success) {
      return { success: false };
    }
    return { success: true, value: { current: parsed_current.value, history: parsed_history.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AuthorAdminListFilters
export function serialize_AuthorAdminListFilters(obj: AuthorAdminListFilters): string {
  const serialized = ((value: { authorId: string | null; channels: string | null; firstName: string | null; gender: string | null; lastName: string | null; middleName: string | null }): unknown => {
    return {
      authorId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["authorId"]),
      channels: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["channels"]),
      firstName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["firstName"]),
      gender: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["gender"]),
      lastName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lastName"]),
      middleName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["middleName"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AuthorAdminListFilters(str: string): AuthorAdminListFilters | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          authorId: string | null;
          channels: string | null;
          firstName: string | null;
          gender: string | null;
          lastName: string | null;
          middleName: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "authorId")) {
      return { success: false };
    }
    const parsed_authorId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["authorId"]);
    })();
    if (!parsed_authorId.success) {
      return { success: false };
    }
    if (!hasProp(value, "channels")) {
      return { success: false };
    }
    const parsed_channels = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["channels"]);
    })();
    if (!parsed_channels.success) {
      return { success: false };
    }
    if (!hasProp(value, "firstName")) {
      return { success: false };
    }
    const parsed_firstName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["firstName"]);
    })();
    if (!parsed_firstName.success) {
      return { success: false };
    }
    if (!hasProp(value, "gender")) {
      return { success: false };
    }
    const parsed_gender = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["gender"]);
    })();
    if (!parsed_gender.success) {
      return { success: false };
    }
    if (!hasProp(value, "lastName")) {
      return { success: false };
    }
    const parsed_lastName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lastName"]);
    })();
    if (!parsed_lastName.success) {
      return { success: false };
    }
    if (!hasProp(value, "middleName")) {
      return { success: false };
    }
    const parsed_middleName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["middleName"]);
    })();
    if (!parsed_middleName.success) {
      return { success: false };
    }
    return { success: true, value: { authorId: parsed_authorId.value, channels: parsed_channels.value, firstName: parsed_firstName.value, gender: parsed_gender.value, lastName: parsed_lastName.value, middleName: parsed_middleName.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AuthorAdminListView
export function serialize_AuthorAdminListView(obj: AuthorAdminListView): string {
  const serialized = ((value: { authorId: bigint; channels: string[]; firstName: string; gender: Gender; lastName: string | null; middleName: string | null }): unknown => {
    return {
      authorId: ((value: bigint): unknown => {
        return value;
      })(value["authorId"]),
      channels: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["channels"]),
      firstName: ((value: string): unknown => {
        return value;
      })(value["firstName"]),
      gender: ((value: "FEMALE" | "MALE" | "UNKNOWN" | "CUSTOM"): unknown => {
        return value;
      })(value["gender"]),
      lastName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lastName"]),
      middleName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["middleName"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AuthorAdminListView(str: string): AuthorAdminListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          authorId: bigint;
          channels: string[];
          firstName: string;
          gender: Gender;
          lastName: string | null;
          middleName: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "authorId")) {
      return { success: false };
    }
    const parsed_authorId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["authorId"]);
    })();
    if (!parsed_authorId.success) {
      return { success: false };
    }
    if (!hasProp(value, "channels")) {
      return { success: false };
    }
    const parsed_channels = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["channels"]);
    })();
    if (!parsed_channels.success) {
      return { success: false };
    }
    if (!hasProp(value, "firstName")) {
      return { success: false };
    }
    const parsed_firstName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["firstName"]);
    })();
    if (!parsed_firstName.success) {
      return { success: false };
    }
    if (!hasProp(value, "gender")) {
      return { success: false };
    }
    const parsed_gender = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Gender } => {
        return ((value: unknown): { success: false } | { success: true; value: "FEMALE" | "MALE" | "UNKNOWN" | "CUSTOM" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "FEMALE":
              return { success: true, value: "FEMALE" };
            case "MALE":
              return { success: true, value: "MALE" };
            case "UNKNOWN":
              return { success: true, value: "UNKNOWN" };
            case "CUSTOM":
              return { success: true, value: "CUSTOM" };
            default:
              return { success: false };
          }
        })(value);
      })(value["gender"]);
    })();
    if (!parsed_gender.success) {
      return { success: false };
    }
    if (!hasProp(value, "lastName")) {
      return { success: false };
    }
    const parsed_lastName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lastName"]);
    })();
    if (!parsed_lastName.success) {
      return { success: false };
    }
    if (!hasProp(value, "middleName")) {
      return { success: false };
    }
    const parsed_middleName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["middleName"]);
    })();
    if (!parsed_middleName.success) {
      return { success: false };
    }
    return { success: true, value: { authorId: parsed_authorId.value, channels: parsed_channels.value, firstName: parsed_firstName.value, gender: parsed_gender.value, lastName: parsed_lastName.value, middleName: parsed_middleName.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// AuthorEditView
export function serialize_AuthorEditView(obj: AuthorEditView): string {
  const serialized = ((value: { authorId: bigint | null; firstName: string; gender: Gender; ignoringWarning: boolean; lastName: string | null; middleName: string | null }): unknown => {
    return {
      authorId: ((value: bigint | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["authorId"]),
      firstName: ((value: string): unknown => {
        return value;
      })(value["firstName"]),
      gender: ((value: "FEMALE" | "MALE" | "UNKNOWN" | "CUSTOM"): unknown => {
        return value;
      })(value["gender"]),
      ignoringWarning: ((value: boolean): unknown => {
        return value;
      })(value["ignoringWarning"]),
      lastName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lastName"]),
      middleName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["middleName"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_AuthorEditView(str: string): AuthorEditView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          authorId: bigint | null;
          firstName: string;
          gender: Gender;
          ignoringWarning: boolean;
          lastName: string | null;
          middleName: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "authorId")) {
      return { success: false };
    }
    const parsed_authorId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["authorId"]);
    })();
    if (!parsed_authorId.success) {
      return { success: false };
    }
    if (!hasProp(value, "firstName")) {
      return { success: false };
    }
    const parsed_firstName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["firstName"]);
    })();
    if (!parsed_firstName.success) {
      return { success: false };
    }
    if (!hasProp(value, "gender")) {
      return { success: false };
    }
    const parsed_gender = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Gender } => {
        return ((value: unknown): { success: false } | { success: true; value: "FEMALE" | "MALE" | "UNKNOWN" | "CUSTOM" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "FEMALE":
              return { success: true, value: "FEMALE" };
            case "MALE":
              return { success: true, value: "MALE" };
            case "UNKNOWN":
              return { success: true, value: "UNKNOWN" };
            case "CUSTOM":
              return { success: true, value: "CUSTOM" };
            default:
              return { success: false };
          }
        })(value);
      })(value["gender"]);
    })();
    if (!parsed_gender.success) {
      return { success: false };
    }
    if (!hasProp(value, "ignoringWarning")) {
      return { success: false };
    }
    const parsed_ignoringWarning = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ignoringWarning"]);
    })();
    if (!parsed_ignoringWarning.success) {
      return { success: false };
    }
    if (!hasProp(value, "lastName")) {
      return { success: false };
    }
    const parsed_lastName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lastName"]);
    })();
    if (!parsed_lastName.success) {
      return { success: false };
    }
    if (!hasProp(value, "middleName")) {
      return { success: false };
    }
    const parsed_middleName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["middleName"]);
    })();
    if (!parsed_middleName.success) {
      return { success: false };
    }
    return {
      success: true,
      value: { authorId: parsed_authorId.value, firstName: parsed_firstName.value, gender: parsed_gender.value, ignoringWarning: parsed_ignoringWarning.value, lastName: parsed_lastName.value, middleName: parsed_middleName.value },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// B2BStatsReportPageProps
export function serialize_B2BStatsReportPageProps(obj: B2BStatsReportPageProps): string {
  const serialized = ((value: { b2bReportForm: NewB2BReportForm; corporateId: number; hasExtendedAccess: boolean; reportTypes: DropdownEntry[] }): unknown => {
    return {
      b2bReportForm: ((value: {
        active: boolean;
        activeUsersOnly: boolean;
        b2bCustomReport: boolean;
        ccRecipients: string;
        corporateId: number;
        customerId: number;
        dayOfWeek: number;
        downloadType: DownloadType;
        emailAdministrator: string | null;
        frequency: ReportFrequency;
        fromDate: { year: number; month: number; day: number } | null;
        ftpFolder: string;
        ftpHost: string;
        ftpPassphrase: string;
        ftpPassword: string;
        ftpPort: number;
        ftpRemoteFileName: string;
        ftpUserName: string;
        prefixFileName: boolean;
        privateKey: string | null;
        privateKeyContent: string;
        privateKeyEnabled: boolean;
        reportId: number;
        reportName: string;
        reportType: ReportType;
        specialReport: boolean;
        targetExcel: boolean;
        toDate: { year: number; month: number; day: number } | null;
        transferProtocol: TransferProtocol;
        zipFile: boolean;
      }): unknown => {
        return {
          active: ((value: boolean): unknown => {
            return value;
          })(value["active"]),
          activeUsersOnly: ((value: boolean): unknown => {
            return value;
          })(value["activeUsersOnly"]),
          b2bCustomReport: ((value: boolean): unknown => {
            return value;
          })(value["b2bCustomReport"]),
          ccRecipients: ((value: string): unknown => {
            return value;
          })(value["ccRecipients"]),
          corporateId: ((value: number): unknown => {
            return value;
          })(value["corporateId"]),
          customerId: ((value: number): unknown => {
            return value;
          })(value["customerId"]),
          dayOfWeek: ((value: number): unknown => {
            return value;
          })(value["dayOfWeek"]),
          downloadType: ((value: "PUSH" | "PULL" | "BOTH"): unknown => {
            return value;
          })(value["downloadType"]),
          emailAdministrator: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["emailAdministrator"]),
          frequency: ((value: "ONE_TIME" | "DAILY" | "WEEKLY" | "MONTHLY"): unknown => {
            return value;
          })(value["frequency"]),
          fromDate: ((value: { year: number; month: number; day: number } | null): unknown => {
            if (value === null) {
              return null;
            }
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["fromDate"]),
          ftpFolder: ((value: string): unknown => {
            return value;
          })(value["ftpFolder"]),
          ftpHost: ((value: string): unknown => {
            return value;
          })(value["ftpHost"]),
          ftpPassphrase: ((value: string): unknown => {
            return value;
          })(value["ftpPassphrase"]),
          ftpPassword: ((value: string): unknown => {
            return value;
          })(value["ftpPassword"]),
          ftpPort: ((value: number): unknown => {
            return value;
          })(value["ftpPort"]),
          ftpRemoteFileName: ((value: string): unknown => {
            return value;
          })(value["ftpRemoteFileName"]),
          ftpUserName: ((value: string): unknown => {
            return value;
          })(value["ftpUserName"]),
          prefixFileName: ((value: boolean): unknown => {
            return value;
          })(value["prefixFileName"]),
          privateKey: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["privateKey"]),
          privateKeyContent: ((value: string): unknown => {
            return value;
          })(value["privateKeyContent"]),
          privateKeyEnabled: ((value: boolean): unknown => {
            return value;
          })(value["privateKeyEnabled"]),
          reportId: ((value: number): unknown => {
            return value;
          })(value["reportId"]),
          reportName: ((value: string): unknown => {
            return value;
          })(value["reportName"]),
          reportType: ((value: "USERS" | "ACTIVITY" | "EXTENDED_USERS_EXPORT" | "ACTIONABLES_ACTIVITY"): unknown => {
            return value;
          })(value["reportType"]),
          specialReport: ((value: boolean): unknown => {
            return value;
          })(value["specialReport"]),
          targetExcel: ((value: boolean): unknown => {
            return value;
          })(value["targetExcel"]),
          toDate: ((value: { year: number; month: number; day: number } | null): unknown => {
            if (value === null) {
              return null;
            }
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["toDate"]),
          transferProtocol: ((value: "FTP" | "SFTP" | "FTPS"): unknown => {
            return value;
          })(value["transferProtocol"]),
          zipFile: ((value: boolean): unknown => {
            return value;
          })(value["zipFile"]),
        };
      })(value["b2bReportForm"]),
      corporateId: ((value: number): unknown => {
        return value;
      })(value["corporateId"]),
      hasExtendedAccess: ((value: boolean): unknown => {
        return value;
      })(value["hasExtendedAccess"]),
      reportTypes: ((value: DropdownEntry[]): unknown => {
        return value.map((value: { text: string; value: string | null }): unknown => {
          return {
            text: ((value: string): unknown => {
              return value;
            })(value["text"]),
            value: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["value"]),
          };
        });
      })(value["reportTypes"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_B2BStatsReportPageProps(str: string): B2BStatsReportPageProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          b2bReportForm: NewB2BReportForm;
          corporateId: number;
          hasExtendedAccess: boolean;
          reportTypes: DropdownEntry[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "b2bReportForm")) {
      return { success: false };
    }
    const parsed_b2bReportForm = (() => {
      return ((value: unknown): { success: false } | { success: true; value: NewB2BReportForm } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                active: boolean;
                activeUsersOnly: boolean;
                b2bCustomReport: boolean;
                ccRecipients: string;
                corporateId: number;
                customerId: number;
                dayOfWeek: number;
                downloadType: DownloadType;
                emailAdministrator: string | null;
                frequency: ReportFrequency;
                fromDate: { year: number; month: number; day: number } | null;
                ftpFolder: string;
                ftpHost: string;
                ftpPassphrase: string;
                ftpPassword: string;
                ftpPort: number;
                ftpRemoteFileName: string;
                ftpUserName: string;
                prefixFileName: boolean;
                privateKey: string | null;
                privateKeyContent: string;
                privateKeyEnabled: boolean;
                reportId: number;
                reportName: string;
                reportType: ReportType;
                specialReport: boolean;
                targetExcel: boolean;
                toDate: { year: number; month: number; day: number } | null;
                transferProtocol: TransferProtocol;
                zipFile: boolean;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "active")) {
            return { success: false };
          }
          const parsed_active = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["active"]);
          })();
          if (!parsed_active.success) {
            return { success: false };
          }
          if (!hasProp(value, "activeUsersOnly")) {
            return { success: false };
          }
          const parsed_activeUsersOnly = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["activeUsersOnly"]);
          })();
          if (!parsed_activeUsersOnly.success) {
            return { success: false };
          }
          if (!hasProp(value, "b2bCustomReport")) {
            return { success: false };
          }
          const parsed_b2bCustomReport = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["b2bCustomReport"]);
          })();
          if (!parsed_b2bCustomReport.success) {
            return { success: false };
          }
          if (!hasProp(value, "ccRecipients")) {
            return { success: false };
          }
          const parsed_ccRecipients = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["ccRecipients"]);
          })();
          if (!parsed_ccRecipients.success) {
            return { success: false };
          }
          if (!hasProp(value, "corporateId")) {
            return { success: false };
          }
          const parsed_corporateId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["corporateId"]);
          })();
          if (!parsed_corporateId.success) {
            return { success: false };
          }
          if (!hasProp(value, "customerId")) {
            return { success: false };
          }
          const parsed_customerId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["customerId"]);
          })();
          if (!parsed_customerId.success) {
            return { success: false };
          }
          if (!hasProp(value, "dayOfWeek")) {
            return { success: false };
          }
          const parsed_dayOfWeek = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["dayOfWeek"]);
          })();
          if (!parsed_dayOfWeek.success) {
            return { success: false };
          }
          if (!hasProp(value, "downloadType")) {
            return { success: false };
          }
          const parsed_downloadType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: DownloadType } => {
              return ((value: unknown): { success: false } | { success: true; value: "PUSH" | "PULL" | "BOTH" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "PUSH":
                    return { success: true, value: "PUSH" };
                  case "PULL":
                    return { success: true, value: "PULL" };
                  case "BOTH":
                    return { success: true, value: "BOTH" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["downloadType"]);
          })();
          if (!parsed_downloadType.success) {
            return { success: false };
          }
          if (!hasProp(value, "emailAdministrator")) {
            return { success: false };
          }
          const parsed_emailAdministrator = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["emailAdministrator"]);
          })();
          if (!parsed_emailAdministrator.success) {
            return { success: false };
          }
          if (!hasProp(value, "frequency")) {
            return { success: false };
          }
          const parsed_frequency = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ReportFrequency } => {
              return ((value: unknown): { success: false } | { success: true; value: "ONE_TIME" | "DAILY" | "WEEKLY" | "MONTHLY" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "ONE_TIME":
                    return { success: true, value: "ONE_TIME" };
                  case "DAILY":
                    return { success: true, value: "DAILY" };
                  case "WEEKLY":
                    return { success: true, value: "WEEKLY" };
                  case "MONTHLY":
                    return { success: true, value: "MONTHLY" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["frequency"]);
          })();
          if (!parsed_frequency.success) {
            return { success: false };
          }
          if (!hasProp(value, "fromDate")) {
            return { success: false };
          }
          const parsed_fromDate = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["fromDate"]);
          })();
          if (!parsed_fromDate.success) {
            return { success: false };
          }
          if (!hasProp(value, "ftpFolder")) {
            return { success: false };
          }
          const parsed_ftpFolder = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["ftpFolder"]);
          })();
          if (!parsed_ftpFolder.success) {
            return { success: false };
          }
          if (!hasProp(value, "ftpHost")) {
            return { success: false };
          }
          const parsed_ftpHost = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["ftpHost"]);
          })();
          if (!parsed_ftpHost.success) {
            return { success: false };
          }
          if (!hasProp(value, "ftpPassphrase")) {
            return { success: false };
          }
          const parsed_ftpPassphrase = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["ftpPassphrase"]);
          })();
          if (!parsed_ftpPassphrase.success) {
            return { success: false };
          }
          if (!hasProp(value, "ftpPassword")) {
            return { success: false };
          }
          const parsed_ftpPassword = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["ftpPassword"]);
          })();
          if (!parsed_ftpPassword.success) {
            return { success: false };
          }
          if (!hasProp(value, "ftpPort")) {
            return { success: false };
          }
          const parsed_ftpPort = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["ftpPort"]);
          })();
          if (!parsed_ftpPort.success) {
            return { success: false };
          }
          if (!hasProp(value, "ftpRemoteFileName")) {
            return { success: false };
          }
          const parsed_ftpRemoteFileName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["ftpRemoteFileName"]);
          })();
          if (!parsed_ftpRemoteFileName.success) {
            return { success: false };
          }
          if (!hasProp(value, "ftpUserName")) {
            return { success: false };
          }
          const parsed_ftpUserName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["ftpUserName"]);
          })();
          if (!parsed_ftpUserName.success) {
            return { success: false };
          }
          if (!hasProp(value, "prefixFileName")) {
            return { success: false };
          }
          const parsed_prefixFileName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["prefixFileName"]);
          })();
          if (!parsed_prefixFileName.success) {
            return { success: false };
          }
          if (!hasProp(value, "privateKey")) {
            return { success: false };
          }
          const parsed_privateKey = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["privateKey"]);
          })();
          if (!parsed_privateKey.success) {
            return { success: false };
          }
          if (!hasProp(value, "privateKeyContent")) {
            return { success: false };
          }
          const parsed_privateKeyContent = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["privateKeyContent"]);
          })();
          if (!parsed_privateKeyContent.success) {
            return { success: false };
          }
          if (!hasProp(value, "privateKeyEnabled")) {
            return { success: false };
          }
          const parsed_privateKeyEnabled = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["privateKeyEnabled"]);
          })();
          if (!parsed_privateKeyEnabled.success) {
            return { success: false };
          }
          if (!hasProp(value, "reportId")) {
            return { success: false };
          }
          const parsed_reportId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["reportId"]);
          })();
          if (!parsed_reportId.success) {
            return { success: false };
          }
          if (!hasProp(value, "reportName")) {
            return { success: false };
          }
          const parsed_reportName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["reportName"]);
          })();
          if (!parsed_reportName.success) {
            return { success: false };
          }
          if (!hasProp(value, "reportType")) {
            return { success: false };
          }
          const parsed_reportType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ReportType } => {
              return ((value: unknown): { success: false } | { success: true; value: "USERS" | "ACTIVITY" | "EXTENDED_USERS_EXPORT" | "ACTIONABLES_ACTIVITY" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "USERS":
                    return { success: true, value: "USERS" };
                  case "ACTIVITY":
                    return { success: true, value: "ACTIVITY" };
                  case "EXTENDED_USERS_EXPORT":
                    return { success: true, value: "EXTENDED_USERS_EXPORT" };
                  case "ACTIONABLES_ACTIVITY":
                    return { success: true, value: "ACTIONABLES_ACTIVITY" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["reportType"]);
          })();
          if (!parsed_reportType.success) {
            return { success: false };
          }
          if (!hasProp(value, "specialReport")) {
            return { success: false };
          }
          const parsed_specialReport = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["specialReport"]);
          })();
          if (!parsed_specialReport.success) {
            return { success: false };
          }
          if (!hasProp(value, "targetExcel")) {
            return { success: false };
          }
          const parsed_targetExcel = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["targetExcel"]);
          })();
          if (!parsed_targetExcel.success) {
            return { success: false };
          }
          if (!hasProp(value, "toDate")) {
            return { success: false };
          }
          const parsed_toDate = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["toDate"]);
          })();
          if (!parsed_toDate.success) {
            return { success: false };
          }
          if (!hasProp(value, "transferProtocol")) {
            return { success: false };
          }
          const parsed_transferProtocol = (() => {
            return ((value: unknown): { success: false } | { success: true; value: TransferProtocol } => {
              return ((value: unknown): { success: false } | { success: true; value: "FTP" | "SFTP" | "FTPS" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "FTP":
                    return { success: true, value: "FTP" };
                  case "SFTP":
                    return { success: true, value: "SFTP" };
                  case "FTPS":
                    return { success: true, value: "FTPS" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["transferProtocol"]);
          })();
          if (!parsed_transferProtocol.success) {
            return { success: false };
          }
          if (!hasProp(value, "zipFile")) {
            return { success: false };
          }
          const parsed_zipFile = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["zipFile"]);
          })();
          if (!parsed_zipFile.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              active: parsed_active.value,
              activeUsersOnly: parsed_activeUsersOnly.value,
              b2bCustomReport: parsed_b2bCustomReport.value,
              ccRecipients: parsed_ccRecipients.value,
              corporateId: parsed_corporateId.value,
              customerId: parsed_customerId.value,
              dayOfWeek: parsed_dayOfWeek.value,
              downloadType: parsed_downloadType.value,
              emailAdministrator: parsed_emailAdministrator.value,
              frequency: parsed_frequency.value,
              fromDate: parsed_fromDate.value,
              ftpFolder: parsed_ftpFolder.value,
              ftpHost: parsed_ftpHost.value,
              ftpPassphrase: parsed_ftpPassphrase.value,
              ftpPassword: parsed_ftpPassword.value,
              ftpPort: parsed_ftpPort.value,
              ftpRemoteFileName: parsed_ftpRemoteFileName.value,
              ftpUserName: parsed_ftpUserName.value,
              prefixFileName: parsed_prefixFileName.value,
              privateKey: parsed_privateKey.value,
              privateKeyContent: parsed_privateKeyContent.value,
              privateKeyEnabled: parsed_privateKeyEnabled.value,
              reportId: parsed_reportId.value,
              reportName: parsed_reportName.value,
              reportType: parsed_reportType.value,
              specialReport: parsed_specialReport.value,
              targetExcel: parsed_targetExcel.value,
              toDate: parsed_toDate.value,
              transferProtocol: parsed_transferProtocol.value,
              zipFile: parsed_zipFile.value,
            },
          };
        })(value);
      })(value["b2bReportForm"]);
    })();
    if (!parsed_b2bReportForm.success) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "hasExtendedAccess")) {
      return { success: false };
    }
    const parsed_hasExtendedAccess = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["hasExtendedAccess"]);
    })();
    if (!parsed_hasExtendedAccess.success) {
      return { success: false };
    }
    if (!hasProp(value, "reportTypes")) {
      return { success: false };
    }
    const parsed_reportTypes = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DropdownEntry[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: DropdownEntry } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  text: string;
                  value: string | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "text")) {
              return { success: false };
            }
            const parsed_text = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["text"]);
            })();
            if (!parsed_text.success) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { text: parsed_text.value, value: parsed_value.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["reportTypes"]);
    })();
    if (!parsed_reportTypes.success) {
      return { success: false };
    }
    return { success: true, value: { b2bReportForm: parsed_b2bReportForm.value, corporateId: parsed_corporateId.value, hasExtendedAccess: parsed_hasExtendedAccess.value, reportTypes: parsed_reportTypes.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// B2BStatsReportSaveResponse
export function serialize_B2BStatsReportSaveResponse(obj: B2BStatsReportSaveResponse): string {
  const serialized = ((value: { kind: "VALIDATION_ERROR"; validationErrorReasons: ValidationErrorReasons } | { kind: "SUCCESS"; reportId: number }): unknown => {
    switch (value.kind) {
      case "VALIDATION_ERROR": {
        return {
          kind: "VALIDATION_ERROR",
          validationErrorReasons: ((value: { fields: { additionalProperties: Record<string, string[]> }; general: string[] }): unknown => {
            return {
              fields: ((value: { additionalProperties: Record<string, string[]> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string[]): unknown => {
                          return value.map((value: string): unknown => {
                            return value;
                          });
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["fields"]),
              general: ((value: string[]): unknown => {
                return value.map((value: string): unknown => {
                  return value;
                });
              })(value["general"]),
            };
          })(value["validationErrorReasons"]),
        };
      }
      case "SUCCESS": {
        return {
          kind: "SUCCESS",
          reportId: ((value: number): unknown => {
            return value;
          })(value["reportId"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_B2BStatsReportSaveResponse(str: string): B2BStatsReportSaveResponse | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: { kind: "VALIDATION_ERROR"; validationErrorReasons: ValidationErrorReasons } | { kind: "SUCCESS"; reportId: number } } => {
    return ((value: unknown): { success: false } | { success: true; value: { kind: "VALIDATION_ERROR"; validationErrorReasons: ValidationErrorReasons } | { kind: "SUCCESS"; reportId: number } } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "kind")) {
        return { success: false };
      }
      if (typeof value["kind"] != "string") {
        return { success: false };
      }
      const discriminator = value["kind"];
      switch (discriminator) {
        case "VALIDATION_ERROR": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  validationErrorReasons: ValidationErrorReasons;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "validationErrorReasons")) {
              return { success: false };
            }
            const parsed_validationErrorReasons = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ValidationErrorReasons } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        fields: { additionalProperties: Record<string, string[]> };
                        general: string[];
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "fields")) {
                    return { success: false };
                  }
                  const parsed_fields = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string[]> } } => {
                      if (typeof value !== "object") {
                        return { success: false };
                      }
                      if (value === null) {
                        return { success: false };
                      }
                      const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string[]> } => {
                        const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string[] }] => {
                          return [
                            key,
                            ((value: unknown): { success: false } | { success: true; value: string[] } => {
                              if (!isArray(value)) {
                                return { success: false };
                              }
                              const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              });
                              if (parsed.find((value) => !value.success) !== undefined) {
                                return { success: false };
                              }
                              return {
                                success: true,
                                value: parsed.map((entry) => {
                                  assert(entry.success == true);
                                  return entry.value;
                                }),
                              };
                            })(value),
                          ];
                        });
                        if (parsed.find(([_, value]) => !value.success) !== undefined) {
                          return { success: false };
                        }
                        return {
                          success: true,
                          value: Object.fromEntries(
                            parsed.map(([key, value]) => {
                              assert(value.success);
                              return [key, value.value];
                            }),
                          ),
                        };
                      })();
                      if (!parsed_additionalProperties.success) {
                        return { success: false };
                      }
                      return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
                    })(value["fields"]);
                  })();
                  if (!parsed_fields.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "general")) {
                    return { success: false };
                  }
                  const parsed_general = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                      if (!isArray(value)) {
                        return { success: false };
                      }
                      const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      });
                      if (parsed.find((value) => !value.success) !== undefined) {
                        return { success: false };
                      }
                      return {
                        success: true,
                        value: parsed.map((entry) => {
                          assert(entry.success == true);
                          return entry.value;
                        }),
                      };
                    })(value["general"]);
                  })();
                  if (!parsed_general.success) {
                    return { success: false };
                  }
                  return { success: true, value: { fields: parsed_fields.value, general: parsed_general.value } };
                })(value);
              })(value["validationErrorReasons"]);
            })();
            if (!parsed_validationErrorReasons.success) {
              return { success: false };
            }
            return { success: true, value: { validationErrorReasons: parsed_validationErrorReasons.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "VALIDATION_ERROR" as const, ...parsedVariant.value } };
        }
        case "SUCCESS": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  reportId: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "reportId")) {
              return { success: false };
            }
            const parsed_reportId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["reportId"]);
            })();
            if (!parsed_reportId.success) {
              return { success: false };
            }
            return { success: true, value: { reportId: parsed_reportId.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "SUCCESS" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// B2CRenewalEmails
export function serialize_B2CRenewalEmails(obj: B2CRenewalEmails): string {
  const serialized = ((value: { name: string; sendDate: DateTime<true>; type: string }): unknown => {
    return {
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      sendDate: ((value: DateTime<true>): unknown => {
        return value.toMillis();
      })(value["sendDate"]),
      type: ((value: string): unknown => {
        return value;
      })(value["type"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_B2CRenewalEmails(str: string): B2CRenewalEmails | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          name: string;
          sendDate: DateTime<true>;
          type: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "sendDate")) {
      return { success: false };
    }
    const parsed_sendDate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["sendDate"]);
    })();
    if (!parsed_sendDate.success) {
      return { success: false };
    }
    if (!hasProp(value, "type")) {
      return { success: false };
    }
    const parsed_type = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["type"]);
    })();
    if (!parsed_type.success) {
      return { success: false };
    }
    return { success: true, value: { name: parsed_name.value, sendDate: parsed_sendDate.value, type: parsed_type.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// B2CSubscription
export function serialize_B2CSubscription(obj: B2CSubscription): string {
  const serialized = ((value: {
    actionStatus: string | null;
    customerId: number;
    editSubscriptionUrl: string;
    endDate: { year: number; month: number; day: number };
    giftSubscription: GiftSubscription | null;
    isCredited: boolean;
    isExpired: boolean;
    isLoss: boolean;
    isMobileSubscription: boolean;
    isPaid: boolean;
    isTeamSubscription: boolean;
    manualRenewalUrl: string;
    numberOfTransactions: number;
    product: string;
    productId: number;
    quantity: number;
    randomKey: string | null;
    startDate: { year: number; month: number; day: number };
    studentVerification: StudentVerificationView | null;
    subscriptionId: number;
    subscriptionPayment: SubscriptionPayment;
    subscriptionStatus: SubscriptionStatus;
    teamSubscription: TeamSubscription | null;
  }): unknown => {
    return {
      actionStatus: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["actionStatus"]),
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
      editSubscriptionUrl: ((value: string): unknown => {
        return value;
      })(value["editSubscriptionUrl"]),
      endDate: ((value: { year: number; month: number; day: number }): unknown => {
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["endDate"]),
      giftSubscription: ((
        value: {
          donorSubscription: GiftDonorSubscription | null;
          giftReceiverSubscription: GiftReceiverSubscription | null;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          donorSubscription: ((
            value: {
              donorCustomerId: number;
              donorSubscriptionId: number;
            } | null,
          ): unknown => {
            if (value === null) {
              return null;
            }
            return {
              donorCustomerId: ((value: number): unknown => {
                return value;
              })(value["donorCustomerId"]),
              donorSubscriptionId: ((value: number): unknown => {
                return value;
              })(value["donorSubscriptionId"]),
            };
          })(value["donorSubscription"]),
          giftReceiverSubscription: ((
            value: {
              receiverCustomerId: number;
              receiverSubscriptionId: number;
            } | null,
          ): unknown => {
            if (value === null) {
              return null;
            }
            return {
              receiverCustomerId: ((value: number): unknown => {
                return value;
              })(value["receiverCustomerId"]),
              receiverSubscriptionId: ((value: number): unknown => {
                return value;
              })(value["receiverSubscriptionId"]),
            };
          })(value["giftReceiverSubscription"]),
        };
      })(value["giftSubscription"]),
      isCredited: ((value: boolean): unknown => {
        return value;
      })(value["isCredited"]),
      isExpired: ((value: boolean): unknown => {
        return value;
      })(value["isExpired"]),
      isLoss: ((value: boolean): unknown => {
        return value;
      })(value["isLoss"]),
      isMobileSubscription: ((value: boolean): unknown => {
        return value;
      })(value["isMobileSubscription"]),
      isPaid: ((value: boolean): unknown => {
        return value;
      })(value["isPaid"]),
      isTeamSubscription: ((value: boolean): unknown => {
        return value;
      })(value["isTeamSubscription"]),
      manualRenewalUrl: ((value: string): unknown => {
        return value;
      })(value["manualRenewalUrl"]),
      numberOfTransactions: ((value: number): unknown => {
        return value;
      })(value["numberOfTransactions"]),
      product: ((value: string): unknown => {
        return value;
      })(value["product"]),
      productId: ((value: number): unknown => {
        return value;
      })(value["productId"]),
      quantity: ((value: number): unknown => {
        return value;
      })(value["quantity"]),
      randomKey: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["randomKey"]),
      startDate: ((value: { year: number; month: number; day: number }): unknown => {
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["startDate"]),
      studentVerification: ((
        value: {
          countryOfStudy: string;
          createdAt: DateTime<true>;
          fieldOfStudy: string | null;
          proof: string | null;
          provider: StudentIdVerificationProvider;
          status: StudentVerificationStatus;
          subscriptionId: number | null;
          verifiedAt: DateTime<true> | null;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          countryOfStudy: ((value: string): unknown => {
            return value;
          })(value["countryOfStudy"]),
          createdAt: ((value: DateTime<true>): unknown => {
            return value.toMillis();
          })(value["createdAt"]),
          fieldOfStudy: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["fieldOfStudy"]),
          proof: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["proof"]),
          provider: ((value: "UNIDAYS" | "UNIDAYS_PERK" | "SHEERID" | "MANUAL" | "UNKNOWN"): unknown => {
            return value;
          })(value["provider"]),
          status: ((value: "VERIFIED" | "EXPIRED" | "FAILED" | "IN_PROGRESS"): unknown => {
            return value;
          })(value["status"]),
          subscriptionId: ((value: number | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["subscriptionId"]),
          verifiedAt: ((value: DateTime<true> | null): unknown => {
            if (value === null) {
              return null;
            }
            return value.toMillis();
          })(value["verifiedAt"]),
        };
      })(value["studentVerification"]),
      subscriptionId: ((value: number): unknown => {
        return value;
      })(value["subscriptionId"]),
      subscriptionPayment: ((value: { balance: MonetaryAmount; isMobilePayment: boolean; paymentType: string | null }): unknown => {
        return {
          balance: ((value: { amount: number; currencyCode: string; displayValue: string }): unknown => {
            return {
              amount: ((value: number): unknown => {
                return value;
              })(value["amount"]),
              currencyCode: ((value: string): unknown => {
                return value;
              })(value["currencyCode"]),
              displayValue: ((value: string): unknown => {
                return value;
              })(value["displayValue"]),
            };
          })(value["balance"]),
          isMobilePayment: ((value: boolean): unknown => {
            return value;
          })(value["isMobilePayment"]),
          paymentType: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["paymentType"]),
        };
      })(value["subscriptionPayment"]),
      subscriptionStatus: ((value: "ACTIVE" | "STOP" | "CANCEL" | "RENEWED" | "RENEWALFAILED"): unknown => {
        return value;
      })(value["subscriptionStatus"]),
      teamSubscription: ((
        value: {
          teamPortalCorporateId: number;
          teamPortalUrl: string;
          teamPortalUsername: string;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          teamPortalCorporateId: ((value: number): unknown => {
            return value;
          })(value["teamPortalCorporateId"]),
          teamPortalUrl: ((value: string): unknown => {
            return value;
          })(value["teamPortalUrl"]),
          teamPortalUsername: ((value: string): unknown => {
            return value;
          })(value["teamPortalUsername"]),
        };
      })(value["teamSubscription"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_B2CSubscription(str: string): B2CSubscription | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          actionStatus: string | null;
          customerId: number;
          editSubscriptionUrl: string;
          endDate: { year: number; month: number; day: number };
          giftSubscription: GiftSubscription | null;
          isCredited: boolean;
          isExpired: boolean;
          isLoss: boolean;
          isMobileSubscription: boolean;
          isPaid: boolean;
          isTeamSubscription: boolean;
          manualRenewalUrl: string;
          numberOfTransactions: number;
          product: string;
          productId: number;
          quantity: number;
          randomKey: string | null;
          startDate: { year: number; month: number; day: number };
          studentVerification: StudentVerificationView | null;
          subscriptionId: number;
          subscriptionPayment: SubscriptionPayment;
          subscriptionStatus: SubscriptionStatus;
          teamSubscription: TeamSubscription | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "actionStatus")) {
      return { success: false };
    }
    const parsed_actionStatus = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["actionStatus"]);
    })();
    if (!parsed_actionStatus.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "editSubscriptionUrl")) {
      return { success: false };
    }
    const parsed_editSubscriptionUrl = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["editSubscriptionUrl"]);
    })();
    if (!parsed_editSubscriptionUrl.success) {
      return { success: false };
    }
    if (!hasProp(value, "endDate")) {
      return { success: false };
    }
    const parsed_endDate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["endDate"]);
    })();
    if (!parsed_endDate.success) {
      return { success: false };
    }
    if (!hasProp(value, "giftSubscription")) {
      return { success: false };
    }
    const parsed_giftSubscription = (() => {
      return ((value: unknown): { success: false } | { success: true; value: GiftSubscription | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                donorSubscription: GiftDonorSubscription | null;
                giftReceiverSubscription: GiftReceiverSubscription | null;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "donorSubscription")) {
            return { success: false };
          }
          const parsed_donorSubscription = (() => {
            return ((value: unknown): { success: false } | { success: true; value: GiftDonorSubscription | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      donorCustomerId: number;
                      donorSubscriptionId: number;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "donorCustomerId")) {
                  return { success: false };
                }
                const parsed_donorCustomerId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: number } => {
                    if (typeof value === "number") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["donorCustomerId"]);
                })();
                if (!parsed_donorCustomerId.success) {
                  return { success: false };
                }
                if (!hasProp(value, "donorSubscriptionId")) {
                  return { success: false };
                }
                const parsed_donorSubscriptionId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: number } => {
                    if (typeof value === "number") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["donorSubscriptionId"]);
                })();
                if (!parsed_donorSubscriptionId.success) {
                  return { success: false };
                }
                return { success: true, value: { donorCustomerId: parsed_donorCustomerId.value, donorSubscriptionId: parsed_donorSubscriptionId.value } };
              })(value);
            })(value["donorSubscription"]);
          })();
          if (!parsed_donorSubscription.success) {
            return { success: false };
          }
          if (!hasProp(value, "giftReceiverSubscription")) {
            return { success: false };
          }
          const parsed_giftReceiverSubscription = (() => {
            return ((value: unknown): { success: false } | { success: true; value: GiftReceiverSubscription | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      receiverCustomerId: number;
                      receiverSubscriptionId: number;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "receiverCustomerId")) {
                  return { success: false };
                }
                const parsed_receiverCustomerId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: number } => {
                    if (typeof value === "number") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["receiverCustomerId"]);
                })();
                if (!parsed_receiverCustomerId.success) {
                  return { success: false };
                }
                if (!hasProp(value, "receiverSubscriptionId")) {
                  return { success: false };
                }
                const parsed_receiverSubscriptionId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: number } => {
                    if (typeof value === "number") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["receiverSubscriptionId"]);
                })();
                if (!parsed_receiverSubscriptionId.success) {
                  return { success: false };
                }
                return { success: true, value: { receiverCustomerId: parsed_receiverCustomerId.value, receiverSubscriptionId: parsed_receiverSubscriptionId.value } };
              })(value);
            })(value["giftReceiverSubscription"]);
          })();
          if (!parsed_giftReceiverSubscription.success) {
            return { success: false };
          }
          return { success: true, value: { donorSubscription: parsed_donorSubscription.value, giftReceiverSubscription: parsed_giftReceiverSubscription.value } };
        })(value);
      })(value["giftSubscription"]);
    })();
    if (!parsed_giftSubscription.success) {
      return { success: false };
    }
    if (!hasProp(value, "isCredited")) {
      return { success: false };
    }
    const parsed_isCredited = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isCredited"]);
    })();
    if (!parsed_isCredited.success) {
      return { success: false };
    }
    if (!hasProp(value, "isExpired")) {
      return { success: false };
    }
    const parsed_isExpired = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isExpired"]);
    })();
    if (!parsed_isExpired.success) {
      return { success: false };
    }
    if (!hasProp(value, "isLoss")) {
      return { success: false };
    }
    const parsed_isLoss = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isLoss"]);
    })();
    if (!parsed_isLoss.success) {
      return { success: false };
    }
    if (!hasProp(value, "isMobileSubscription")) {
      return { success: false };
    }
    const parsed_isMobileSubscription = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isMobileSubscription"]);
    })();
    if (!parsed_isMobileSubscription.success) {
      return { success: false };
    }
    if (!hasProp(value, "isPaid")) {
      return { success: false };
    }
    const parsed_isPaid = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isPaid"]);
    })();
    if (!parsed_isPaid.success) {
      return { success: false };
    }
    if (!hasProp(value, "isTeamSubscription")) {
      return { success: false };
    }
    const parsed_isTeamSubscription = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isTeamSubscription"]);
    })();
    if (!parsed_isTeamSubscription.success) {
      return { success: false };
    }
    if (!hasProp(value, "manualRenewalUrl")) {
      return { success: false };
    }
    const parsed_manualRenewalUrl = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["manualRenewalUrl"]);
    })();
    if (!parsed_manualRenewalUrl.success) {
      return { success: false };
    }
    if (!hasProp(value, "numberOfTransactions")) {
      return { success: false };
    }
    const parsed_numberOfTransactions = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["numberOfTransactions"]);
    })();
    if (!parsed_numberOfTransactions.success) {
      return { success: false };
    }
    if (!hasProp(value, "product")) {
      return { success: false };
    }
    const parsed_product = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["product"]);
    })();
    if (!parsed_product.success) {
      return { success: false };
    }
    if (!hasProp(value, "productId")) {
      return { success: false };
    }
    const parsed_productId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["productId"]);
    })();
    if (!parsed_productId.success) {
      return { success: false };
    }
    if (!hasProp(value, "quantity")) {
      return { success: false };
    }
    const parsed_quantity = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["quantity"]);
    })();
    if (!parsed_quantity.success) {
      return { success: false };
    }
    if (!hasProp(value, "randomKey")) {
      return { success: false };
    }
    const parsed_randomKey = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["randomKey"]);
    })();
    if (!parsed_randomKey.success) {
      return { success: false };
    }
    if (!hasProp(value, "startDate")) {
      return { success: false };
    }
    const parsed_startDate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["startDate"]);
    })();
    if (!parsed_startDate.success) {
      return { success: false };
    }
    if (!hasProp(value, "studentVerification")) {
      return { success: false };
    }
    const parsed_studentVerification = (() => {
      return ((value: unknown): { success: false } | { success: true; value: StudentVerificationView | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                countryOfStudy: string;
                createdAt: DateTime<true>;
                fieldOfStudy: string | null;
                proof: string | null;
                provider: StudentIdVerificationProvider;
                status: StudentVerificationStatus;
                subscriptionId: number | null;
                verifiedAt: DateTime<true> | null;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "countryOfStudy")) {
            return { success: false };
          }
          const parsed_countryOfStudy = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["countryOfStudy"]);
          })();
          if (!parsed_countryOfStudy.success) {
            return { success: false };
          }
          if (!hasProp(value, "createdAt")) {
            return { success: false };
          }
          const parsed_createdAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
              if (typeof value !== "number") {
                return { success: false };
              }
              const parsed = DateTime.fromMillis(value);
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: parsed };
            })(value["createdAt"]);
          })();
          if (!parsed_createdAt.success) {
            return { success: false };
          }
          if (!hasProp(value, "fieldOfStudy")) {
            return { success: false };
          }
          const parsed_fieldOfStudy = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["fieldOfStudy"]);
          })();
          if (!parsed_fieldOfStudy.success) {
            return { success: false };
          }
          if (!hasProp(value, "proof")) {
            return { success: false };
          }
          const parsed_proof = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["proof"]);
          })();
          if (!parsed_proof.success) {
            return { success: false };
          }
          if (!hasProp(value, "provider")) {
            return { success: false };
          }
          const parsed_provider = (() => {
            return ((value: unknown): { success: false } | { success: true; value: StudentIdVerificationProvider } => {
              return ((value: unknown): { success: false } | { success: true; value: "UNIDAYS" | "UNIDAYS_PERK" | "SHEERID" | "MANUAL" | "UNKNOWN" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "UNIDAYS":
                    return { success: true, value: "UNIDAYS" };
                  case "UNIDAYS_PERK":
                    return { success: true, value: "UNIDAYS_PERK" };
                  case "SHEERID":
                    return { success: true, value: "SHEERID" };
                  case "MANUAL":
                    return { success: true, value: "MANUAL" };
                  case "UNKNOWN":
                    return { success: true, value: "UNKNOWN" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["provider"]);
          })();
          if (!parsed_provider.success) {
            return { success: false };
          }
          if (!hasProp(value, "status")) {
            return { success: false };
          }
          const parsed_status = (() => {
            return ((value: unknown): { success: false } | { success: true; value: StudentVerificationStatus } => {
              return ((value: unknown): { success: false } | { success: true; value: "VERIFIED" | "EXPIRED" | "FAILED" | "IN_PROGRESS" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "VERIFIED":
                    return { success: true, value: "VERIFIED" };
                  case "EXPIRED":
                    return { success: true, value: "EXPIRED" };
                  case "FAILED":
                    return { success: true, value: "FAILED" };
                  case "IN_PROGRESS":
                    return { success: true, value: "IN_PROGRESS" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["status"]);
          })();
          if (!parsed_status.success) {
            return { success: false };
          }
          if (!hasProp(value, "subscriptionId")) {
            return { success: false };
          }
          const parsed_subscriptionId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["subscriptionId"]);
          })();
          if (!parsed_subscriptionId.success) {
            return { success: false };
          }
          if (!hasProp(value, "verifiedAt")) {
            return { success: false };
          }
          const parsed_verifiedAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "number") {
                return { success: false };
              }
              const parsed = DateTime.fromMillis(value);
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: parsed };
            })(value["verifiedAt"]);
          })();
          if (!parsed_verifiedAt.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              countryOfStudy: parsed_countryOfStudy.value,
              createdAt: parsed_createdAt.value,
              fieldOfStudy: parsed_fieldOfStudy.value,
              proof: parsed_proof.value,
              provider: parsed_provider.value,
              status: parsed_status.value,
              subscriptionId: parsed_subscriptionId.value,
              verifiedAt: parsed_verifiedAt.value,
            },
          };
        })(value);
      })(value["studentVerification"]);
    })();
    if (!parsed_studentVerification.success) {
      return { success: false };
    }
    if (!hasProp(value, "subscriptionId")) {
      return { success: false };
    }
    const parsed_subscriptionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["subscriptionId"]);
    })();
    if (!parsed_subscriptionId.success) {
      return { success: false };
    }
    if (!hasProp(value, "subscriptionPayment")) {
      return { success: false };
    }
    const parsed_subscriptionPayment = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SubscriptionPayment } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                balance: MonetaryAmount;
                isMobilePayment: boolean;
                paymentType: string | null;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "balance")) {
            return { success: false };
          }
          const parsed_balance = (() => {
            return ((value: unknown): { success: false } | { success: true; value: MonetaryAmount } => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      amount: number;
                      currencyCode: string;
                      displayValue: string;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "amount")) {
                  return { success: false };
                }
                const parsed_amount = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: number } => {
                    if (typeof value === "number") {
                      return { success: true, value: value };
                    }
                    if (typeof value === "bigint") {
                      return { success: true, value: Number(value) };
                    }
                    return { success: false };
                  })(value["amount"]);
                })();
                if (!parsed_amount.success) {
                  return { success: false };
                }
                if (!hasProp(value, "currencyCode")) {
                  return { success: false };
                }
                const parsed_currencyCode = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["currencyCode"]);
                })();
                if (!parsed_currencyCode.success) {
                  return { success: false };
                }
                if (!hasProp(value, "displayValue")) {
                  return { success: false };
                }
                const parsed_displayValue = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["displayValue"]);
                })();
                if (!parsed_displayValue.success) {
                  return { success: false };
                }
                return { success: true, value: { amount: parsed_amount.value, currencyCode: parsed_currencyCode.value, displayValue: parsed_displayValue.value } };
              })(value);
            })(value["balance"]);
          })();
          if (!parsed_balance.success) {
            return { success: false };
          }
          if (!hasProp(value, "isMobilePayment")) {
            return { success: false };
          }
          const parsed_isMobilePayment = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["isMobilePayment"]);
          })();
          if (!parsed_isMobilePayment.success) {
            return { success: false };
          }
          if (!hasProp(value, "paymentType")) {
            return { success: false };
          }
          const parsed_paymentType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["paymentType"]);
          })();
          if (!parsed_paymentType.success) {
            return { success: false };
          }
          return { success: true, value: { balance: parsed_balance.value, isMobilePayment: parsed_isMobilePayment.value, paymentType: parsed_paymentType.value } };
        })(value);
      })(value["subscriptionPayment"]);
    })();
    if (!parsed_subscriptionPayment.success) {
      return { success: false };
    }
    if (!hasProp(value, "subscriptionStatus")) {
      return { success: false };
    }
    const parsed_subscriptionStatus = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SubscriptionStatus } => {
        return ((value: unknown): { success: false } | { success: true; value: "ACTIVE" | "STOP" | "CANCEL" | "RENEWED" | "RENEWALFAILED" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "ACTIVE":
              return { success: true, value: "ACTIVE" };
            case "STOP":
              return { success: true, value: "STOP" };
            case "CANCEL":
              return { success: true, value: "CANCEL" };
            case "RENEWED":
              return { success: true, value: "RENEWED" };
            case "RENEWALFAILED":
              return { success: true, value: "RENEWALFAILED" };
            default:
              return { success: false };
          }
        })(value);
      })(value["subscriptionStatus"]);
    })();
    if (!parsed_subscriptionStatus.success) {
      return { success: false };
    }
    if (!hasProp(value, "teamSubscription")) {
      return { success: false };
    }
    const parsed_teamSubscription = (() => {
      return ((value: unknown): { success: false } | { success: true; value: TeamSubscription | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                teamPortalCorporateId: number;
                teamPortalUrl: string;
                teamPortalUsername: string;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "teamPortalCorporateId")) {
            return { success: false };
          }
          const parsed_teamPortalCorporateId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["teamPortalCorporateId"]);
          })();
          if (!parsed_teamPortalCorporateId.success) {
            return { success: false };
          }
          if (!hasProp(value, "teamPortalUrl")) {
            return { success: false };
          }
          const parsed_teamPortalUrl = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["teamPortalUrl"]);
          })();
          if (!parsed_teamPortalUrl.success) {
            return { success: false };
          }
          if (!hasProp(value, "teamPortalUsername")) {
            return { success: false };
          }
          const parsed_teamPortalUsername = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["teamPortalUsername"]);
          })();
          if (!parsed_teamPortalUsername.success) {
            return { success: false };
          }
          return { success: true, value: { teamPortalCorporateId: parsed_teamPortalCorporateId.value, teamPortalUrl: parsed_teamPortalUrl.value, teamPortalUsername: parsed_teamPortalUsername.value } };
        })(value);
      })(value["teamSubscription"]);
    })();
    if (!parsed_teamSubscription.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        actionStatus: parsed_actionStatus.value,
        customerId: parsed_customerId.value,
        editSubscriptionUrl: parsed_editSubscriptionUrl.value,
        endDate: parsed_endDate.value,
        giftSubscription: parsed_giftSubscription.value,
        isCredited: parsed_isCredited.value,
        isExpired: parsed_isExpired.value,
        isLoss: parsed_isLoss.value,
        isMobileSubscription: parsed_isMobileSubscription.value,
        isPaid: parsed_isPaid.value,
        isTeamSubscription: parsed_isTeamSubscription.value,
        manualRenewalUrl: parsed_manualRenewalUrl.value,
        numberOfTransactions: parsed_numberOfTransactions.value,
        product: parsed_product.value,
        productId: parsed_productId.value,
        quantity: parsed_quantity.value,
        randomKey: parsed_randomKey.value,
        startDate: parsed_startDate.value,
        studentVerification: parsed_studentVerification.value,
        subscriptionId: parsed_subscriptionId.value,
        subscriptionPayment: parsed_subscriptionPayment.value,
        subscriptionStatus: parsed_subscriptionStatus.value,
        teamSubscription: parsed_teamSubscription.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// BookmarkResponse
export function serialize_BookmarkResponse(obj: BookmarkResponse): string {
  const serialized = ((value: { bookmarked: boolean }): unknown => {
    return {
      bookmarked: ((value: boolean): unknown => {
        return value;
      })(value["bookmarked"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_BookmarkResponse(str: string): BookmarkResponse | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          bookmarked: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "bookmarked")) {
      return { success: false };
    }
    const parsed_bookmarked = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["bookmarked"]);
    })();
    if (!parsed_bookmarked.success) {
      return { success: false };
    }
    return { success: true, value: { bookmarked: parsed_bookmarked.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CareerLevel
export function serialize_CareerLevel(obj: CareerLevel): string {
  const serialized = ((
    value:
      | "OWNER_EXECUTIVE_C_LEVEL"
      | "SENIOR_MANAGEMENT"
      | "MIDDLE_MANAGEMENT"
      | "JUNIOR_MANAGEMENT"
      | "ADMINISTRATIVE_STAFF"
      | "EMPLOYEE_WITHOUT_MANAGEMENT_OR_LEADERSHIP_RESPONSIBILITY"
      | "STUDENT_OR_TRAINEE"
      | "TEACHER_OR_ACADEMIC"
      | "NOT_EMPLOYED"
      | "RETIRED"
      | "OTHER"
      | "UNDEFINED",
  ): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CareerLevel(str: string): CareerLevel | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value:
          | "OWNER_EXECUTIVE_C_LEVEL"
          | "SENIOR_MANAGEMENT"
          | "MIDDLE_MANAGEMENT"
          | "JUNIOR_MANAGEMENT"
          | "ADMINISTRATIVE_STAFF"
          | "EMPLOYEE_WITHOUT_MANAGEMENT_OR_LEADERSHIP_RESPONSIBILITY"
          | "STUDENT_OR_TRAINEE"
          | "TEACHER_OR_ACADEMIC"
          | "NOT_EMPLOYED"
          | "RETIRED"
          | "OTHER"
          | "UNDEFINED";
      } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "OWNER_EXECUTIVE_C_LEVEL":
        return { success: true, value: "OWNER_EXECUTIVE_C_LEVEL" };
      case "SENIOR_MANAGEMENT":
        return { success: true, value: "SENIOR_MANAGEMENT" };
      case "MIDDLE_MANAGEMENT":
        return { success: true, value: "MIDDLE_MANAGEMENT" };
      case "JUNIOR_MANAGEMENT":
        return { success: true, value: "JUNIOR_MANAGEMENT" };
      case "ADMINISTRATIVE_STAFF":
        return { success: true, value: "ADMINISTRATIVE_STAFF" };
      case "EMPLOYEE_WITHOUT_MANAGEMENT_OR_LEADERSHIP_RESPONSIBILITY":
        return { success: true, value: "EMPLOYEE_WITHOUT_MANAGEMENT_OR_LEADERSHIP_RESPONSIBILITY" };
      case "STUDENT_OR_TRAINEE":
        return { success: true, value: "STUDENT_OR_TRAINEE" };
      case "TEACHER_OR_ACADEMIC":
        return { success: true, value: "TEACHER_OR_ACADEMIC" };
      case "NOT_EMPLOYED":
        return { success: true, value: "NOT_EMPLOYED" };
      case "RETIRED":
        return { success: true, value: "RETIRED" };
      case "OTHER":
        return { success: true, value: "OTHER" };
      case "UNDEFINED":
        return { success: true, value: "UNDEFINED" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CareerLocalizationForm
export function serialize_CareerLocalizationForm(obj: CareerLocalizationForm): string {
  const serialized = ((value: { benefits: string; content: string; location: string; title: string }): unknown => {
    return {
      benefits: ((value: string): unknown => {
        return value;
      })(value["benefits"]),
      content: ((value: string): unknown => {
        return value;
      })(value["content"]),
      location: ((value: string): unknown => {
        return value;
      })(value["location"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CareerLocalizationForm(str: string): CareerLocalizationForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          benefits: string;
          content: string;
          location: string;
          title: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "benefits")) {
      return { success: false };
    }
    const parsed_benefits = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["benefits"]);
    })();
    if (!parsed_benefits.success) {
      return { success: false };
    }
    if (!hasProp(value, "content")) {
      return { success: false };
    }
    const parsed_content = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["content"]);
    })();
    if (!parsed_content.success) {
      return { success: false };
    }
    if (!hasProp(value, "location")) {
      return { success: false };
    }
    const parsed_location = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["location"]);
    })();
    if (!parsed_location.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return { success: true, value: { benefits: parsed_benefits.value, content: parsed_content.value, location: parsed_location.value, title: parsed_title.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ChannelAdminSearchForm
export function serialize_ChannelAdminSearchForm(obj: ChannelAdminSearchForm): string {
  const serialized = ((value: { corporateId: number | null; includeInactive: boolean | null; includeTemporary: boolean | null; query: string }): unknown => {
    return {
      corporateId: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["corporateId"]),
      includeInactive: ((value: boolean | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["includeInactive"]),
      includeTemporary: ((value: boolean | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["includeTemporary"]),
      query: ((value: string): unknown => {
        return value;
      })(value["query"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ChannelAdminSearchForm(str: string): ChannelAdminSearchForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          corporateId: number | null;
          includeInactive: boolean | null;
          includeTemporary: boolean | null;
          query: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "includeInactive")) {
      return { success: false };
    }
    const parsed_includeInactive = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["includeInactive"]);
    })();
    if (!parsed_includeInactive.success) {
      return { success: false };
    }
    if (!hasProp(value, "includeTemporary")) {
      return { success: false };
    }
    const parsed_includeTemporary = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["includeTemporary"]);
    })();
    if (!parsed_includeTemporary.success) {
      return { success: false };
    }
    if (!hasProp(value, "query")) {
      return { success: false };
    }
    const parsed_query = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["query"]);
    })();
    if (!parsed_query.success) {
      return { success: false };
    }
    return { success: true, value: { corporateId: parsed_corporateId.value, includeInactive: parsed_includeInactive.value, includeTemporary: parsed_includeTemporary.value, query: parsed_query.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ChannelAdminTagBasedProps
export function serialize_ChannelAdminTagBasedProps(obj: ChannelAdminTagBasedProps): string {
  const serialized = ((value: { channelId: bigint; managerSelection: ChannelManager[] }): unknown => {
    return {
      channelId: ((value: bigint): unknown => {
        return value;
      })(value["channelId"]),
      managerSelection: ((value: ChannelManager[]): unknown => {
        return value.map((value: { id: number; name: string }): unknown => {
          return {
            id: ((value: number): unknown => {
              return value;
            })(value["id"]),
            name: ((value: string): unknown => {
              return value;
            })(value["name"]),
          };
        });
      })(value["managerSelection"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ChannelAdminTagBasedProps(str: string): ChannelAdminTagBasedProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          channelId: bigint;
          managerSelection: ChannelManager[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "channelId")) {
      return { success: false };
    }
    const parsed_channelId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["channelId"]);
    })();
    if (!parsed_channelId.success) {
      return { success: false };
    }
    if (!hasProp(value, "managerSelection")) {
      return { success: false };
    }
    const parsed_managerSelection = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ChannelManager[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ChannelManager } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: number;
                  name: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value, name: parsed_name.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["managerSelection"]);
    })();
    if (!parsed_managerSelection.success) {
      return { success: false };
    }
    return { success: true, value: { channelId: parsed_channelId.value, managerSelection: parsed_managerSelection.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ChannelCoverEditForm
export function serialize_ChannelCoverEditForm(obj: ChannelCoverEditForm): string {
  const serialized = ((value: { kind: "default" } | { kind: "update"; base64Image: string } | { kind: "existing"; url: string }): unknown => {
    switch (value.kind) {
      case "default": {
        return { kind: "default" };
      }
      case "update": {
        return {
          kind: "update",
          base64Image: ((value: string): unknown => {
            return value;
          })(value["base64Image"]),
        };
      }
      case "existing": {
        return {
          kind: "existing",
          url: ((value: string): unknown => {
            return value;
          })(value["url"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ChannelCoverEditForm(str: string): ChannelCoverEditForm | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: { kind: "default" } | { kind: "update"; base64Image: string } | { kind: "existing"; url: string } } => {
    return ((value: unknown): { success: false } | { success: true; value: { kind: "default" } | { kind: "update"; base64Image: string } | { kind: "existing"; url: string } } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "kind")) {
        return { success: false };
      }
      if (typeof value["kind"] != "string") {
        return { success: false };
      }
      const discriminator = value["kind"];
      switch (discriminator) {
        case "default": {
          const parsedVariant = ((value: unknown): { success: false } | { success: true; value: Record<string, never> } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            return { success: true, value: {} };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "default" as const, ...parsedVariant.value } };
        }
        case "update": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  base64Image: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "base64Image")) {
              return { success: false };
            }
            const parsed_base64Image = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["base64Image"]);
            })();
            if (!parsed_base64Image.success) {
              return { success: false };
            }
            return { success: true, value: { base64Image: parsed_base64Image.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "update" as const, ...parsedVariant.value } };
        }
        case "existing": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  url: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "url")) {
              return { success: false };
            }
            const parsed_url = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["url"]);
            })();
            if (!parsed_url.success) {
              return { success: false };
            }
            return { success: true, value: { url: parsed_url.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "existing" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ChannelEditForm
export function serialize_ChannelEditForm(obj: ChannelEditForm): string {
  const serialized = ((value: {
    active: boolean;
    channelId: bigint;
    channelLocalization: { additionalProperties: Record<string, ChannelLocalizationEditForm> };
    childChannels: ChannelRelationForm[];
    cover: ChannelCoverEditForm;
    definition: string | null;
    name: string;
    notes: string | null;
    primarySortOrder: number | null;
    tag: Tag;
  }): unknown => {
    return {
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      channelId: ((value: bigint): unknown => {
        return value;
      })(value["channelId"]),
      channelLocalization: ((value: { additionalProperties: Record<string, ChannelLocalizationEditForm> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: { active: boolean; description: string | null; manager: number | null; synonym: string | null; title: string | null }): unknown => {
                  return {
                    active: ((value: boolean): unknown => {
                      return value;
                    })(value["active"]),
                    description: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["description"]),
                    manager: ((value: number | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["manager"]),
                    synonym: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["synonym"]),
                    title: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["title"]),
                  };
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["channelLocalization"]),
      childChannels: ((value: ChannelRelationForm[]): unknown => {
        return value.map((value: { channelId: bigint; sortOrder: number | null; title: string }): unknown => {
          return {
            channelId: ((value: bigint): unknown => {
              return value;
            })(value["channelId"]),
            sortOrder: ((value: number | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["sortOrder"]),
            title: ((value: string): unknown => {
              return value;
            })(value["title"]),
          };
        });
      })(value["childChannels"]),
      cover: ((value: { kind: "default" } | { kind: "update"; base64Image: string } | { kind: "existing"; url: string }): unknown => {
        switch (value.kind) {
          case "default": {
            return { kind: "default" };
          }
          case "update": {
            return {
              kind: "update",
              base64Image: ((value: string): unknown => {
                return value;
              })(value["base64Image"]),
            };
          }
          case "existing": {
            return {
              kind: "existing",
              url: ((value: string): unknown => {
                return value;
              })(value["url"]),
            };
          }
        }
      })(value["cover"]),
      definition: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["definition"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      notes: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["notes"]),
      primarySortOrder: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["primarySortOrder"]),
      tag: ((
        value:
          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
          | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
          | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
      ): unknown => {
        switch (value.discriminator) {
          case "dynamicString": {
            return {
              discriminator: "dynamicString",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                return {
                  discriminator: ((value: "dynamicString"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "dynamicBoolean": {
            return {
              discriminator: "dynamicBoolean",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                return {
                  discriminator: ((value: "dynamicBoolean"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: boolean): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "dynamicNumber": {
            return {
              discriminator: "dynamicNumber",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                return {
                  discriminator: ((value: "dynamicNumber"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: number): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "dynamicVoid": {
            return {
              discriminator: "dynamicVoid",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                return {
                  discriminator: ((value: "dynamicVoid"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
            };
          }
          case "channel": {
            return {
              discriminator: "channel",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                return {
                  discriminator: ((value: "channel"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "channel"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "primary-skill": {
            return {
              discriminator: "primary-skill",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                return {
                  discriminator: ((value: "primary-skill"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "primary-skill"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "skill": {
            return {
              discriminator: "skill",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                return {
                  discriminator: ((value: "skill"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "skill"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "key-quality": {
            return {
              discriminator: "key-quality",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                return {
                  discriminator: ((value: "key-quality"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "key-quality"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "classification": {
            return {
              discriminator: "classification",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                return {
                  discriminator: ((value: "classification"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "classification"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
        }
      })(value["tag"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ChannelEditForm(str: string): ChannelEditForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          active: boolean;
          channelId: bigint;
          channelLocalization: { additionalProperties: Record<string, ChannelLocalizationEditForm> };
          childChannels: ChannelRelationForm[];
          cover: ChannelCoverEditForm;
          definition: string | null;
          name: string;
          notes: string | null;
          primarySortOrder: number | null;
          tag: Tag;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "channelId")) {
      return { success: false };
    }
    const parsed_channelId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["channelId"]);
    })();
    if (!parsed_channelId.success) {
      return { success: false };
    }
    if (!hasProp(value, "channelLocalization")) {
      return { success: false };
    }
    const parsed_channelLocalization = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, ChannelLocalizationEditForm> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, ChannelLocalizationEditForm> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: ChannelLocalizationEditForm }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: ChannelLocalizationEditForm } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        active: boolean;
                        description: string | null;
                        manager: number | null;
                        synonym: string | null;
                        title: string | null;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "active")) {
                    return { success: false };
                  }
                  const parsed_active = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["active"]);
                  })();
                  if (!parsed_active.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "description")) {
                    return { success: false };
                  }
                  const parsed_description = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["description"]);
                  })();
                  if (!parsed_description.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "manager")) {
                    return { success: false };
                  }
                  const parsed_manager = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["manager"]);
                  })();
                  if (!parsed_manager.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "synonym")) {
                    return { success: false };
                  }
                  const parsed_synonym = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["synonym"]);
                  })();
                  if (!parsed_synonym.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "title")) {
                    return { success: false };
                  }
                  const parsed_title = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["title"]);
                  })();
                  if (!parsed_title.success) {
                    return { success: false };
                  }
                  return { success: true, value: { active: parsed_active.value, description: parsed_description.value, manager: parsed_manager.value, synonym: parsed_synonym.value, title: parsed_title.value } };
                })(value);
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["channelLocalization"]);
    })();
    if (!parsed_channelLocalization.success) {
      return { success: false };
    }
    if (!hasProp(value, "childChannels")) {
      return { success: false };
    }
    const parsed_childChannels = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ChannelRelationForm[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ChannelRelationForm } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  channelId: bigint;
                  sortOrder: number | null;
                  title: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "channelId")) {
              return { success: false };
            }
            const parsed_channelId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["channelId"]);
            })();
            if (!parsed_channelId.success) {
              return { success: false };
            }
            if (!hasProp(value, "sortOrder")) {
              return { success: false };
            }
            const parsed_sortOrder = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["sortOrder"]);
            })();
            if (!parsed_sortOrder.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            return { success: true, value: { channelId: parsed_channelId.value, sortOrder: parsed_sortOrder.value, title: parsed_title.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["childChannels"]);
    })();
    if (!parsed_childChannels.success) {
      return { success: false };
    }
    if (!hasProp(value, "cover")) {
      return { success: false };
    }
    const parsed_cover = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ChannelCoverEditForm } => {
        return ((value: unknown): { success: false } | { success: true; value: { kind: "default" } | { kind: "update"; base64Image: string } | { kind: "existing"; url: string } } => {
          return ((value: unknown): { success: false } | { success: true; value: { kind: "default" } | { kind: "update"; base64Image: string } | { kind: "existing"; url: string } } => {
            if (value == null) {
              return { success: false };
            }
            if (typeof value != "object") {
              return { success: false };
            }
            if (!hasProp(value, "kind")) {
              return { success: false };
            }
            if (typeof value["kind"] != "string") {
              return { success: false };
            }
            const discriminator = value["kind"];
            switch (discriminator) {
              case "default": {
                const parsedVariant = ((value: unknown): { success: false } | { success: true; value: Record<string, never> } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  return { success: true, value: {} };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { kind: "default" as const, ...parsedVariant.value } };
              }
              case "update": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        base64Image: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "base64Image")) {
                    return { success: false };
                  }
                  const parsed_base64Image = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["base64Image"]);
                  })();
                  if (!parsed_base64Image.success) {
                    return { success: false };
                  }
                  return { success: true, value: { base64Image: parsed_base64Image.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { kind: "update" as const, ...parsedVariant.value } };
              }
              case "existing": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        url: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "url")) {
                    return { success: false };
                  }
                  const parsed_url = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["url"]);
                  })();
                  if (!parsed_url.success) {
                    return { success: false };
                  }
                  return { success: true, value: { url: parsed_url.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { kind: "existing" as const, ...parsedVariant.value } };
              }
              default:
                return { success: false };
            }
          })(value);
        })(value);
      })(value["cover"]);
    })();
    if (!parsed_cover.success) {
      return { success: false };
    }
    if (!hasProp(value, "definition")) {
      return { success: false };
    }
    const parsed_definition = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["definition"]);
    })();
    if (!parsed_definition.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "notes")) {
      return { success: false };
    }
    const parsed_notes = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["notes"]);
    })();
    if (!parsed_notes.success) {
      return { success: false };
    }
    if (!hasProp(value, "primarySortOrder")) {
      return { success: false };
    }
    const parsed_primarySortOrder = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["primarySortOrder"]);
    })();
    if (!parsed_primarySortOrder.success) {
      return { success: false };
    }
    if (!hasProp(value, "tag")) {
      return { success: false };
    }
    const parsed_tag = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Tag } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value:
                | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
            } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                  | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
              } => {
            if (value == null) {
              return { success: false };
            }
            if (typeof value != "object") {
              return { success: false };
            }
            if (!hasProp(value, "discriminator")) {
              return { success: false };
            }
            if (typeof value["discriminator"] != "string") {
              return { success: false };
            }
            const discriminator = value["discriminator"];
            switch (discriminator) {
              case "dynamicString": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindDynamicString;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindDynamicString;
                              id: bigint;
                              kind: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                            return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "dynamicString":
                                  return { success: true, value: "dynamicString" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
              }
              case "dynamicBoolean": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindDynamicBoolean;
                        value: boolean;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindDynamicBoolean;
                              id: bigint;
                              kind: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                            return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "dynamicBoolean":
                                  return { success: true, value: "dynamicBoolean" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
              }
              case "dynamicNumber": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindDynamicNumber;
                        value: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindDynamicNumber;
                              id: bigint;
                              kind: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                            return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "dynamicNumber":
                                  return { success: true, value: "dynamicNumber" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
              }
              case "dynamicVoid": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindDynamicVoid;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindDynamicVoid;
                              id: bigint;
                              kind: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                            return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "dynamicVoid":
                                  return { success: true, value: "dynamicVoid" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
              }
              case "channel": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindChannel;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindChannel;
                              id: bigint;
                              kind: KindChannel;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                            return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "channel":
                                  return { success: true, value: "channel" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                            return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "channel":
                                  return { success: true, value: "channel" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
              }
              case "primary-skill": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindPrimarySkill;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindPrimarySkill;
                              id: bigint;
                              kind: KindPrimarySkill;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                            return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "primary-skill":
                                  return { success: true, value: "primary-skill" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                            return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "primary-skill":
                                  return { success: true, value: "primary-skill" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
              }
              case "skill": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindSkill;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindSkill;
                              id: bigint;
                              kind: KindSkill;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                            return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "skill":
                                  return { success: true, value: "skill" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                            return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "skill":
                                  return { success: true, value: "skill" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
              }
              case "key-quality": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindKeyQuality;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindKeyQuality;
                              id: bigint;
                              kind: KindKeyQuality;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                            return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "key-quality":
                                  return { success: true, value: "key-quality" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                            return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "key-quality":
                                  return { success: true, value: "key-quality" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
              }
              case "classification": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindClassification;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindClassification;
                              id: bigint;
                              kind: KindClassification;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                            return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "classification":
                                  return { success: true, value: "classification" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                            return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "classification":
                                  return { success: true, value: "classification" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
              }
              default:
                return { success: false };
            }
          })(value);
        })(value);
      })(value["tag"]);
    })();
    if (!parsed_tag.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        active: parsed_active.value,
        channelId: parsed_channelId.value,
        channelLocalization: parsed_channelLocalization.value,
        childChannels: parsed_childChannels.value,
        cover: parsed_cover.value,
        definition: parsed_definition.value,
        name: parsed_name.value,
        notes: parsed_notes.value,
        primarySortOrder: parsed_primarySortOrder.value,
        tag: parsed_tag.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ChannelEditView
export function serialize_ChannelEditView(obj: ChannelEditView): string {
  const serialized = ((value: {
    active: boolean;
    channelId: bigint;
    channelLocalization: { additionalProperties: Record<string, ChannelLocalizationView> };
    childChannels: ChannelRelationView[];
    cover: string | null;
    definition: string | null;
    name: string;
    notes: string | null;
    primarySortOrder: number | null;
    tag: Tag;
  }): unknown => {
    return {
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      channelId: ((value: bigint): unknown => {
        return value;
      })(value["channelId"]),
      channelLocalization: ((value: { additionalProperties: Record<string, ChannelLocalizationView> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: { active: boolean; description: string | null; manager: number | null; synonym: string | null; title: string | null }): unknown => {
                  return {
                    active: ((value: boolean): unknown => {
                      return value;
                    })(value["active"]),
                    description: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["description"]),
                    manager: ((value: number | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["manager"]),
                    synonym: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["synonym"]),
                    title: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["title"]),
                  };
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["channelLocalization"]),
      childChannels: ((value: ChannelRelationView[]): unknown => {
        return value.map((value: { channelId: bigint; sortOrder: number | null; title: string }): unknown => {
          return {
            channelId: ((value: bigint): unknown => {
              return value;
            })(value["channelId"]),
            sortOrder: ((value: number | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["sortOrder"]),
            title: ((value: string): unknown => {
              return value;
            })(value["title"]),
          };
        });
      })(value["childChannels"]),
      cover: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["cover"]),
      definition: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["definition"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      notes: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["notes"]),
      primarySortOrder: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["primarySortOrder"]),
      tag: ((
        value:
          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
          | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
          | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
      ): unknown => {
        switch (value.discriminator) {
          case "dynamicString": {
            return {
              discriminator: "dynamicString",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                return {
                  discriminator: ((value: "dynamicString"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "dynamicBoolean": {
            return {
              discriminator: "dynamicBoolean",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                return {
                  discriminator: ((value: "dynamicBoolean"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: boolean): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "dynamicNumber": {
            return {
              discriminator: "dynamicNumber",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                return {
                  discriminator: ((value: "dynamicNumber"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: number): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "dynamicVoid": {
            return {
              discriminator: "dynamicVoid",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                return {
                  discriminator: ((value: "dynamicVoid"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
            };
          }
          case "channel": {
            return {
              discriminator: "channel",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                return {
                  discriminator: ((value: "channel"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "channel"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "primary-skill": {
            return {
              discriminator: "primary-skill",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                return {
                  discriminator: ((value: "primary-skill"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "primary-skill"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "skill": {
            return {
              discriminator: "skill",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                return {
                  discriminator: ((value: "skill"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "skill"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "key-quality": {
            return {
              discriminator: "key-quality",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                return {
                  discriminator: ((value: "key-quality"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "key-quality"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "classification": {
            return {
              discriminator: "classification",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                return {
                  discriminator: ((value: "classification"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "classification"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
        }
      })(value["tag"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ChannelEditView(str: string): ChannelEditView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          active: boolean;
          channelId: bigint;
          channelLocalization: { additionalProperties: Record<string, ChannelLocalizationView> };
          childChannels: ChannelRelationView[];
          cover: string | null;
          definition: string | null;
          name: string;
          notes: string | null;
          primarySortOrder: number | null;
          tag: Tag;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "channelId")) {
      return { success: false };
    }
    const parsed_channelId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["channelId"]);
    })();
    if (!parsed_channelId.success) {
      return { success: false };
    }
    if (!hasProp(value, "channelLocalization")) {
      return { success: false };
    }
    const parsed_channelLocalization = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, ChannelLocalizationView> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, ChannelLocalizationView> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: ChannelLocalizationView }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: ChannelLocalizationView } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        active: boolean;
                        description: string | null;
                        manager: number | null;
                        synonym: string | null;
                        title: string | null;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "active")) {
                    return { success: false };
                  }
                  const parsed_active = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["active"]);
                  })();
                  if (!parsed_active.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "description")) {
                    return { success: false };
                  }
                  const parsed_description = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["description"]);
                  })();
                  if (!parsed_description.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "manager")) {
                    return { success: false };
                  }
                  const parsed_manager = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["manager"]);
                  })();
                  if (!parsed_manager.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "synonym")) {
                    return { success: false };
                  }
                  const parsed_synonym = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["synonym"]);
                  })();
                  if (!parsed_synonym.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "title")) {
                    return { success: false };
                  }
                  const parsed_title = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["title"]);
                  })();
                  if (!parsed_title.success) {
                    return { success: false };
                  }
                  return { success: true, value: { active: parsed_active.value, description: parsed_description.value, manager: parsed_manager.value, synonym: parsed_synonym.value, title: parsed_title.value } };
                })(value);
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["channelLocalization"]);
    })();
    if (!parsed_channelLocalization.success) {
      return { success: false };
    }
    if (!hasProp(value, "childChannels")) {
      return { success: false };
    }
    const parsed_childChannels = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ChannelRelationView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ChannelRelationView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  channelId: bigint;
                  sortOrder: number | null;
                  title: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "channelId")) {
              return { success: false };
            }
            const parsed_channelId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["channelId"]);
            })();
            if (!parsed_channelId.success) {
              return { success: false };
            }
            if (!hasProp(value, "sortOrder")) {
              return { success: false };
            }
            const parsed_sortOrder = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["sortOrder"]);
            })();
            if (!parsed_sortOrder.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            return { success: true, value: { channelId: parsed_channelId.value, sortOrder: parsed_sortOrder.value, title: parsed_title.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["childChannels"]);
    })();
    if (!parsed_childChannels.success) {
      return { success: false };
    }
    if (!hasProp(value, "cover")) {
      return { success: false };
    }
    const parsed_cover = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["cover"]);
    })();
    if (!parsed_cover.success) {
      return { success: false };
    }
    if (!hasProp(value, "definition")) {
      return { success: false };
    }
    const parsed_definition = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["definition"]);
    })();
    if (!parsed_definition.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "notes")) {
      return { success: false };
    }
    const parsed_notes = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["notes"]);
    })();
    if (!parsed_notes.success) {
      return { success: false };
    }
    if (!hasProp(value, "primarySortOrder")) {
      return { success: false };
    }
    const parsed_primarySortOrder = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["primarySortOrder"]);
    })();
    if (!parsed_primarySortOrder.success) {
      return { success: false };
    }
    if (!hasProp(value, "tag")) {
      return { success: false };
    }
    const parsed_tag = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Tag } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value:
                | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
            } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                  | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
              } => {
            if (value == null) {
              return { success: false };
            }
            if (typeof value != "object") {
              return { success: false };
            }
            if (!hasProp(value, "discriminator")) {
              return { success: false };
            }
            if (typeof value["discriminator"] != "string") {
              return { success: false };
            }
            const discriminator = value["discriminator"];
            switch (discriminator) {
              case "dynamicString": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindDynamicString;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindDynamicString;
                              id: bigint;
                              kind: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                            return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "dynamicString":
                                  return { success: true, value: "dynamicString" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
              }
              case "dynamicBoolean": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindDynamicBoolean;
                        value: boolean;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindDynamicBoolean;
                              id: bigint;
                              kind: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                            return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "dynamicBoolean":
                                  return { success: true, value: "dynamicBoolean" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
              }
              case "dynamicNumber": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindDynamicNumber;
                        value: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindDynamicNumber;
                              id: bigint;
                              kind: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                            return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "dynamicNumber":
                                  return { success: true, value: "dynamicNumber" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
              }
              case "dynamicVoid": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindDynamicVoid;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindDynamicVoid;
                              id: bigint;
                              kind: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                            return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "dynamicVoid":
                                  return { success: true, value: "dynamicVoid" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
              }
              case "channel": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindChannel;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindChannel;
                              id: bigint;
                              kind: KindChannel;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                            return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "channel":
                                  return { success: true, value: "channel" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                            return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "channel":
                                  return { success: true, value: "channel" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
              }
              case "primary-skill": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindPrimarySkill;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindPrimarySkill;
                              id: bigint;
                              kind: KindPrimarySkill;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                            return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "primary-skill":
                                  return { success: true, value: "primary-skill" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                            return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "primary-skill":
                                  return { success: true, value: "primary-skill" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
              }
              case "skill": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindSkill;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindSkill;
                              id: bigint;
                              kind: KindSkill;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                            return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "skill":
                                  return { success: true, value: "skill" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                            return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "skill":
                                  return { success: true, value: "skill" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
              }
              case "key-quality": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindKeyQuality;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindKeyQuality;
                              id: bigint;
                              kind: KindKeyQuality;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                            return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "key-quality":
                                  return { success: true, value: "key-quality" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                            return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "key-quality":
                                  return { success: true, value: "key-quality" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
              }
              case "classification": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindClassification;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindClassification;
                              id: bigint;
                              kind: KindClassification;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                            return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "classification":
                                  return { success: true, value: "classification" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                            return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "classification":
                                  return { success: true, value: "classification" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
              }
              default:
                return { success: false };
            }
          })(value);
        })(value);
      })(value["tag"]);
    })();
    if (!parsed_tag.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        active: parsed_active.value,
        channelId: parsed_channelId.value,
        channelLocalization: parsed_channelLocalization.value,
        childChannels: parsed_childChannels.value,
        cover: parsed_cover.value,
        definition: parsed_definition.value,
        name: parsed_name.value,
        notes: parsed_notes.value,
        primarySortOrder: parsed_primarySortOrder.value,
        tag: parsed_tag.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ChannelList
export function serialize_ChannelList(obj: ChannelList): string {
  const serialized = ((value: { channels: ChannelMetadata[] }): unknown => {
    return {
      channels: ((value: ChannelMetadata[]): unknown => {
        return value.map(
          (value: {
            activationTime: DateTime<true> | null;
            active: boolean;
            channelId: bigint;
            channelLocalization: { additionalProperties: Record<string, ChannelTextLocalization> };
            coverUri: string | null;
            definition: string | null;
            descriptionHtml: string | null;
            modificationTime: DateTime<true> | null;
            name: string;
            synonyms: string | null;
            tag: Tag;
            title: string | null;
            visibleLanguages: Language[];
          }): unknown => {
            return {
              activationTime: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["activationTime"]),
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              channelId: ((value: bigint): unknown => {
                return value;
              })(value["channelId"]),
              channelLocalization: ((value: { additionalProperties: Record<string, ChannelTextLocalization> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: { description: string | null; synonym: string | null; title: string | null }): unknown => {
                          return {
                            description: ((value: string | null): unknown => {
                              if (value === null) {
                                return null;
                              }
                              return value;
                            })(value["description"]),
                            synonym: ((value: string | null): unknown => {
                              if (value === null) {
                                return null;
                              }
                              return value;
                            })(value["synonym"]),
                            title: ((value: string | null): unknown => {
                              if (value === null) {
                                return null;
                              }
                              return value;
                            })(value["title"]),
                          };
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["channelLocalization"]),
              coverUri: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["coverUri"]),
              definition: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["definition"]),
              descriptionHtml: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["descriptionHtml"]),
              modificationTime: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["modificationTime"]),
              name: ((value: string): unknown => {
                return value;
              })(value["name"]),
              synonyms: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["synonyms"]),
              tag: ((
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                  | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
              ): unknown => {
                switch (value.discriminator) {
                  case "dynamicString": {
                    return {
                      discriminator: "dynamicString",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicString"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicBoolean": {
                    return {
                      discriminator: "dynamicBoolean",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicBoolean"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: boolean): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicNumber": {
                    return {
                      discriminator: "dynamicNumber",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicNumber"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: number): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicVoid": {
                    return {
                      discriminator: "dynamicVoid",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicVoid"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                    };
                  }
                  case "channel": {
                    return {
                      discriminator: "channel",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                        return {
                          discriminator: ((value: "channel"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "channel"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "primary-skill": {
                    return {
                      discriminator: "primary-skill",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                        return {
                          discriminator: ((value: "primary-skill"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "primary-skill"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "skill": {
                    return {
                      discriminator: "skill",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                        return {
                          discriminator: ((value: "skill"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "skill"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "key-quality": {
                    return {
                      discriminator: "key-quality",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                        return {
                          discriminator: ((value: "key-quality"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "key-quality"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "classification": {
                    return {
                      discriminator: "classification",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                        return {
                          discriminator: ((value: "classification"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "classification"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                }
              })(value["tag"]),
              title: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["title"]),
              visibleLanguages: ((value: Language[]): unknown => {
                return value.map((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                  return value;
                });
              })(value["visibleLanguages"]),
            };
          },
        );
      })(value["channels"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ChannelList(str: string): ChannelList | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          channels: ChannelMetadata[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "channels")) {
      return { success: false };
    }
    const parsed_channels = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ChannelMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ChannelMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  activationTime: DateTime<true> | null;
                  active: boolean;
                  channelId: bigint;
                  channelLocalization: { additionalProperties: Record<string, ChannelTextLocalization> };
                  coverUri: string | null;
                  definition: string | null;
                  descriptionHtml: string | null;
                  modificationTime: DateTime<true> | null;
                  name: string;
                  synonyms: string | null;
                  tag: Tag;
                  title: string | null;
                  visibleLanguages: Language[];
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "activationTime")) {
              return { success: false };
            }
            const parsed_activationTime = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["activationTime"]);
            })();
            if (!parsed_activationTime.success) {
              return { success: false };
            }
            if (!hasProp(value, "active")) {
              return { success: false };
            }
            const parsed_active = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["active"]);
            })();
            if (!parsed_active.success) {
              return { success: false };
            }
            if (!hasProp(value, "channelId")) {
              return { success: false };
            }
            const parsed_channelId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["channelId"]);
            })();
            if (!parsed_channelId.success) {
              return { success: false };
            }
            if (!hasProp(value, "channelLocalization")) {
              return { success: false };
            }
            const parsed_channelLocalization = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, ChannelTextLocalization> } } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, ChannelTextLocalization> } => {
                  const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: ChannelTextLocalization }] => {
                    return [
                      key,
                      ((value: unknown): { success: false } | { success: true; value: ChannelTextLocalization } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                description: string | null;
                                synonym: string | null;
                                title: string | null;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "synonym")) {
                            return { success: false };
                          }
                          const parsed_synonym = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["synonym"]);
                          })();
                          if (!parsed_synonym.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return { success: true, value: { description: parsed_description.value, synonym: parsed_synonym.value, title: parsed_title.value } };
                        })(value);
                      })(value),
                    ];
                  });
                  if (parsed.find(([_, value]) => !value.success) !== undefined) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: Object.fromEntries(
                      parsed.map(([key, value]) => {
                        assert(value.success);
                        return [key, value.value];
                      }),
                    ),
                  };
                })();
                if (!parsed_additionalProperties.success) {
                  return { success: false };
                }
                return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
              })(value["channelLocalization"]);
            })();
            if (!parsed_channelLocalization.success) {
              return { success: false };
            }
            if (!hasProp(value, "coverUri")) {
              return { success: false };
            }
            const parsed_coverUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["coverUri"]);
            })();
            if (!parsed_coverUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "definition")) {
              return { success: false };
            }
            const parsed_definition = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["definition"]);
            })();
            if (!parsed_definition.success) {
              return { success: false };
            }
            if (!hasProp(value, "descriptionHtml")) {
              return { success: false };
            }
            const parsed_descriptionHtml = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["descriptionHtml"]);
            })();
            if (!parsed_descriptionHtml.success) {
              return { success: false };
            }
            if (!hasProp(value, "modificationTime")) {
              return { success: false };
            }
            const parsed_modificationTime = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["modificationTime"]);
            })();
            if (!parsed_modificationTime.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "synonyms")) {
              return { success: false };
            }
            const parsed_synonyms = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["synonyms"]);
            })();
            if (!parsed_synonyms.success) {
              return { success: false };
            }
            if (!hasProp(value, "tag")) {
              return { success: false };
            }
            const parsed_tag = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Tag } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                        | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                        | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                        | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                        | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                        | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                        | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                        | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                        | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                    } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value:
                          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                          | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                          | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                      } => {
                    if (value == null) {
                      return { success: false };
                    }
                    if (typeof value != "object") {
                      return { success: false };
                    }
                    if (!hasProp(value, "discriminator")) {
                      return { success: false };
                    }
                    if (typeof value["discriminator"] != "string") {
                      return { success: false };
                    }
                    const discriminator = value["discriminator"];
                    switch (discriminator) {
                      case "dynamicString": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicString;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicString;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicString":
                                          return { success: true, value: "dynamicString" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                      }
                      case "dynamicBoolean": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicBoolean;
                                value: boolean;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicBoolean;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicBoolean":
                                          return { success: true, value: "dynamicBoolean" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                      }
                      case "dynamicNumber": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicNumber;
                                value: number;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicNumber;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicNumber":
                                          return { success: true, value: "dynamicNumber" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: number } => {
                              if (typeof value === "number") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                      }
                      case "dynamicVoid": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicVoid;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicVoid;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicVoid":
                                          return { success: true, value: "dynamicVoid" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                      }
                      case "channel": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindChannel;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindChannel;
                                      id: bigint;
                                      kind: KindChannel;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "channel":
                                          return { success: true, value: "channel" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "channel":
                                          return { success: true, value: "channel" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                      }
                      case "primary-skill": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindPrimarySkill;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindPrimarySkill;
                                      id: bigint;
                                      kind: KindPrimarySkill;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "primary-skill":
                                          return { success: true, value: "primary-skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "primary-skill":
                                          return { success: true, value: "primary-skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                      }
                      case "skill": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindSkill;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindSkill;
                                      id: bigint;
                                      kind: KindSkill;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "skill":
                                          return { success: true, value: "skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "skill":
                                          return { success: true, value: "skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                      }
                      case "key-quality": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindKeyQuality;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindKeyQuality;
                                      id: bigint;
                                      kind: KindKeyQuality;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "key-quality":
                                          return { success: true, value: "key-quality" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "key-quality":
                                          return { success: true, value: "key-quality" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                      }
                      case "classification": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindClassification;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindClassification;
                                      id: bigint;
                                      kind: KindClassification;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "classification":
                                          return { success: true, value: "classification" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "classification":
                                          return { success: true, value: "classification" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                      }
                      default:
                        return { success: false };
                    }
                  })(value);
                })(value);
              })(value["tag"]);
            })();
            if (!parsed_tag.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            if (!hasProp(value, "visibleLanguages")) {
              return { success: false };
            }
            const parsed_visibleLanguages = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Language[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: Language } => {
                  return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    switch (value) {
                      case "en":
                        return { success: true, value: "en" };
                      case "de":
                        return { success: true, value: "de" };
                      case "es":
                        return { success: true, value: "es" };
                      case "ru":
                        return { success: true, value: "ru" };
                      case "zh":
                        return { success: true, value: "zh" };
                      case "pt":
                        return { success: true, value: "pt" };
                      case "fr":
                        return { success: true, value: "fr" };
                      default:
                        return { success: false };
                    }
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["visibleLanguages"]);
            })();
            if (!parsed_visibleLanguages.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                activationTime: parsed_activationTime.value,
                active: parsed_active.value,
                channelId: parsed_channelId.value,
                channelLocalization: parsed_channelLocalization.value,
                coverUri: parsed_coverUri.value,
                definition: parsed_definition.value,
                descriptionHtml: parsed_descriptionHtml.value,
                modificationTime: parsed_modificationTime.value,
                name: parsed_name.value,
                synonyms: parsed_synonyms.value,
                tag: parsed_tag.value,
                title: parsed_title.value,
                visibleLanguages: parsed_visibleLanguages.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["channels"]);
    })();
    if (!parsed_channels.success) {
      return { success: false };
    }
    return { success: true, value: { channels: parsed_channels.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ChannelLocalizationEditForm
export function serialize_ChannelLocalizationEditForm(obj: ChannelLocalizationEditForm): string {
  const serialized = ((value: { active: boolean; description: string | null; manager: number | null; synonym: string | null; title: string | null }): unknown => {
    return {
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      description: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["description"]),
      manager: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["manager"]),
      synonym: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["synonym"]),
      title: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ChannelLocalizationEditForm(str: string): ChannelLocalizationEditForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          active: boolean;
          description: string | null;
          manager: number | null;
          synonym: string | null;
          title: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "description")) {
      return { success: false };
    }
    const parsed_description = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["description"]);
    })();
    if (!parsed_description.success) {
      return { success: false };
    }
    if (!hasProp(value, "manager")) {
      return { success: false };
    }
    const parsed_manager = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["manager"]);
    })();
    if (!parsed_manager.success) {
      return { success: false };
    }
    if (!hasProp(value, "synonym")) {
      return { success: false };
    }
    const parsed_synonym = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["synonym"]);
    })();
    if (!parsed_synonym.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return { success: true, value: { active: parsed_active.value, description: parsed_description.value, manager: parsed_manager.value, synonym: parsed_synonym.value, title: parsed_title.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ChannelLocalizationView
export function serialize_ChannelLocalizationView(obj: ChannelLocalizationView): string {
  const serialized = ((value: { active: boolean; description: string | null; manager: number | null; synonym: string | null; title: string | null }): unknown => {
    return {
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      description: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["description"]),
      manager: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["manager"]),
      synonym: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["synonym"]),
      title: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ChannelLocalizationView(str: string): ChannelLocalizationView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          active: boolean;
          description: string | null;
          manager: number | null;
          synonym: string | null;
          title: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "description")) {
      return { success: false };
    }
    const parsed_description = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["description"]);
    })();
    if (!parsed_description.success) {
      return { success: false };
    }
    if (!hasProp(value, "manager")) {
      return { success: false };
    }
    const parsed_manager = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["manager"]);
    })();
    if (!parsed_manager.success) {
      return { success: false };
    }
    if (!hasProp(value, "synonym")) {
      return { success: false };
    }
    const parsed_synonym = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["synonym"]);
    })();
    if (!parsed_synonym.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return { success: true, value: { active: parsed_active.value, description: parsed_description.value, manager: parsed_manager.value, synonym: parsed_synonym.value, title: parsed_title.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ChannelManager
export function serialize_ChannelManager(obj: ChannelManager): string {
  const serialized = ((value: { id: number; name: string }): unknown => {
    return {
      id: ((value: number): unknown => {
        return value;
      })(value["id"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ChannelManager(str: string): ChannelManager | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          id: number;
          name: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    return { success: true, value: { id: parsed_id.value, name: parsed_name.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ChannelMetadata
export function serialize_ChannelMetadata(obj: ChannelMetadata): string {
  const serialized = ((value: {
    activationTime: DateTime<true> | null;
    active: boolean;
    channelId: bigint;
    channelLocalization: { additionalProperties: Record<string, ChannelTextLocalization> };
    coverUri: string | null;
    definition: string | null;
    descriptionHtml: string | null;
    modificationTime: DateTime<true> | null;
    name: string;
    synonyms: string | null;
    tag: Tag;
    title: string | null;
    visibleLanguages: Language[];
  }): unknown => {
    return {
      activationTime: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["activationTime"]),
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      channelId: ((value: bigint): unknown => {
        return value;
      })(value["channelId"]),
      channelLocalization: ((value: { additionalProperties: Record<string, ChannelTextLocalization> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: { description: string | null; synonym: string | null; title: string | null }): unknown => {
                  return {
                    description: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["description"]),
                    synonym: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["synonym"]),
                    title: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["title"]),
                  };
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["channelLocalization"]),
      coverUri: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["coverUri"]),
      definition: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["definition"]),
      descriptionHtml: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["descriptionHtml"]),
      modificationTime: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["modificationTime"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      synonyms: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["synonyms"]),
      tag: ((
        value:
          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
          | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
          | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
      ): unknown => {
        switch (value.discriminator) {
          case "dynamicString": {
            return {
              discriminator: "dynamicString",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                return {
                  discriminator: ((value: "dynamicString"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "dynamicBoolean": {
            return {
              discriminator: "dynamicBoolean",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                return {
                  discriminator: ((value: "dynamicBoolean"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: boolean): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "dynamicNumber": {
            return {
              discriminator: "dynamicNumber",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                return {
                  discriminator: ((value: "dynamicNumber"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: number): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "dynamicVoid": {
            return {
              discriminator: "dynamicVoid",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                return {
                  discriminator: ((value: "dynamicVoid"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
            };
          }
          case "channel": {
            return {
              discriminator: "channel",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                return {
                  discriminator: ((value: "channel"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "channel"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "primary-skill": {
            return {
              discriminator: "primary-skill",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                return {
                  discriminator: ((value: "primary-skill"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "primary-skill"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "skill": {
            return {
              discriminator: "skill",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                return {
                  discriminator: ((value: "skill"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "skill"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "key-quality": {
            return {
              discriminator: "key-quality",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                return {
                  discriminator: ((value: "key-quality"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "key-quality"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "classification": {
            return {
              discriminator: "classification",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                return {
                  discriminator: ((value: "classification"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "classification"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
        }
      })(value["tag"]),
      title: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["title"]),
      visibleLanguages: ((value: Language[]): unknown => {
        return value.map((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
          return value;
        });
      })(value["visibleLanguages"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ChannelMetadata(str: string): ChannelMetadata | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          activationTime: DateTime<true> | null;
          active: boolean;
          channelId: bigint;
          channelLocalization: { additionalProperties: Record<string, ChannelTextLocalization> };
          coverUri: string | null;
          definition: string | null;
          descriptionHtml: string | null;
          modificationTime: DateTime<true> | null;
          name: string;
          synonyms: string | null;
          tag: Tag;
          title: string | null;
          visibleLanguages: Language[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "activationTime")) {
      return { success: false };
    }
    const parsed_activationTime = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["activationTime"]);
    })();
    if (!parsed_activationTime.success) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "channelId")) {
      return { success: false };
    }
    const parsed_channelId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["channelId"]);
    })();
    if (!parsed_channelId.success) {
      return { success: false };
    }
    if (!hasProp(value, "channelLocalization")) {
      return { success: false };
    }
    const parsed_channelLocalization = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, ChannelTextLocalization> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, ChannelTextLocalization> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: ChannelTextLocalization }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: ChannelTextLocalization } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        description: string | null;
                        synonym: string | null;
                        title: string | null;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "description")) {
                    return { success: false };
                  }
                  const parsed_description = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["description"]);
                  })();
                  if (!parsed_description.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "synonym")) {
                    return { success: false };
                  }
                  const parsed_synonym = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["synonym"]);
                  })();
                  if (!parsed_synonym.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "title")) {
                    return { success: false };
                  }
                  const parsed_title = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["title"]);
                  })();
                  if (!parsed_title.success) {
                    return { success: false };
                  }
                  return { success: true, value: { description: parsed_description.value, synonym: parsed_synonym.value, title: parsed_title.value } };
                })(value);
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["channelLocalization"]);
    })();
    if (!parsed_channelLocalization.success) {
      return { success: false };
    }
    if (!hasProp(value, "coverUri")) {
      return { success: false };
    }
    const parsed_coverUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["coverUri"]);
    })();
    if (!parsed_coverUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "definition")) {
      return { success: false };
    }
    const parsed_definition = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["definition"]);
    })();
    if (!parsed_definition.success) {
      return { success: false };
    }
    if (!hasProp(value, "descriptionHtml")) {
      return { success: false };
    }
    const parsed_descriptionHtml = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["descriptionHtml"]);
    })();
    if (!parsed_descriptionHtml.success) {
      return { success: false };
    }
    if (!hasProp(value, "modificationTime")) {
      return { success: false };
    }
    const parsed_modificationTime = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["modificationTime"]);
    })();
    if (!parsed_modificationTime.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "synonyms")) {
      return { success: false };
    }
    const parsed_synonyms = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["synonyms"]);
    })();
    if (!parsed_synonyms.success) {
      return { success: false };
    }
    if (!hasProp(value, "tag")) {
      return { success: false };
    }
    const parsed_tag = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Tag } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value:
                | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
            } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                  | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
              } => {
            if (value == null) {
              return { success: false };
            }
            if (typeof value != "object") {
              return { success: false };
            }
            if (!hasProp(value, "discriminator")) {
              return { success: false };
            }
            if (typeof value["discriminator"] != "string") {
              return { success: false };
            }
            const discriminator = value["discriminator"];
            switch (discriminator) {
              case "dynamicString": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindDynamicString;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindDynamicString;
                              id: bigint;
                              kind: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                            return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "dynamicString":
                                  return { success: true, value: "dynamicString" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
              }
              case "dynamicBoolean": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindDynamicBoolean;
                        value: boolean;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindDynamicBoolean;
                              id: bigint;
                              kind: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                            return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "dynamicBoolean":
                                  return { success: true, value: "dynamicBoolean" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
              }
              case "dynamicNumber": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindDynamicNumber;
                        value: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindDynamicNumber;
                              id: bigint;
                              kind: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                            return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "dynamicNumber":
                                  return { success: true, value: "dynamicNumber" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
              }
              case "dynamicVoid": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindDynamicVoid;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindDynamicVoid;
                              id: bigint;
                              kind: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                            return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "dynamicVoid":
                                  return { success: true, value: "dynamicVoid" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
              }
              case "channel": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindChannel;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindChannel;
                              id: bigint;
                              kind: KindChannel;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                            return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "channel":
                                  return { success: true, value: "channel" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                            return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "channel":
                                  return { success: true, value: "channel" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
              }
              case "primary-skill": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindPrimarySkill;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindPrimarySkill;
                              id: bigint;
                              kind: KindPrimarySkill;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                            return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "primary-skill":
                                  return { success: true, value: "primary-skill" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                            return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "primary-skill":
                                  return { success: true, value: "primary-skill" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
              }
              case "skill": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindSkill;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindSkill;
                              id: bigint;
                              kind: KindSkill;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                            return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "skill":
                                  return { success: true, value: "skill" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                            return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "skill":
                                  return { success: true, value: "skill" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
              }
              case "key-quality": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindKeyQuality;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindKeyQuality;
                              id: bigint;
                              kind: KindKeyQuality;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                            return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "key-quality":
                                  return { success: true, value: "key-quality" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                            return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "key-quality":
                                  return { success: true, value: "key-quality" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
              }
              case "classification": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindClassification;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindClassification;
                              id: bigint;
                              kind: KindClassification;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                            return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "classification":
                                  return { success: true, value: "classification" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                            return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "classification":
                                  return { success: true, value: "classification" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
              }
              default:
                return { success: false };
            }
          })(value);
        })(value);
      })(value["tag"]);
    })();
    if (!parsed_tag.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    if (!hasProp(value, "visibleLanguages")) {
      return { success: false };
    }
    const parsed_visibleLanguages = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Language } => {
          return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            switch (value) {
              case "en":
                return { success: true, value: "en" };
              case "de":
                return { success: true, value: "de" };
              case "es":
                return { success: true, value: "es" };
              case "ru":
                return { success: true, value: "ru" };
              case "zh":
                return { success: true, value: "zh" };
              case "pt":
                return { success: true, value: "pt" };
              case "fr":
                return { success: true, value: "fr" };
              default:
                return { success: false };
            }
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["visibleLanguages"]);
    })();
    if (!parsed_visibleLanguages.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        activationTime: parsed_activationTime.value,
        active: parsed_active.value,
        channelId: parsed_channelId.value,
        channelLocalization: parsed_channelLocalization.value,
        coverUri: parsed_coverUri.value,
        definition: parsed_definition.value,
        descriptionHtml: parsed_descriptionHtml.value,
        modificationTime: parsed_modificationTime.value,
        name: parsed_name.value,
        synonyms: parsed_synonyms.value,
        tag: parsed_tag.value,
        title: parsed_title.value,
        visibleLanguages: parsed_visibleLanguages.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ChannelRelationForm
export function serialize_ChannelRelationForm(obj: ChannelRelationForm): string {
  const serialized = ((value: { channelId: bigint; sortOrder: number | null; title: string }): unknown => {
    return {
      channelId: ((value: bigint): unknown => {
        return value;
      })(value["channelId"]),
      sortOrder: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["sortOrder"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ChannelRelationForm(str: string): ChannelRelationForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          channelId: bigint;
          sortOrder: number | null;
          title: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "channelId")) {
      return { success: false };
    }
    const parsed_channelId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["channelId"]);
    })();
    if (!parsed_channelId.success) {
      return { success: false };
    }
    if (!hasProp(value, "sortOrder")) {
      return { success: false };
    }
    const parsed_sortOrder = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["sortOrder"]);
    })();
    if (!parsed_sortOrder.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return { success: true, value: { channelId: parsed_channelId.value, sortOrder: parsed_sortOrder.value, title: parsed_title.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ChannelRelationView
export function serialize_ChannelRelationView(obj: ChannelRelationView): string {
  const serialized = ((value: { channelId: bigint; sortOrder: number | null; title: string }): unknown => {
    return {
      channelId: ((value: bigint): unknown => {
        return value;
      })(value["channelId"]),
      sortOrder: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["sortOrder"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ChannelRelationView(str: string): ChannelRelationView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          channelId: bigint;
          sortOrder: number | null;
          title: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "channelId")) {
      return { success: false };
    }
    const parsed_channelId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["channelId"]);
    })();
    if (!parsed_channelId.success) {
      return { success: false };
    }
    if (!hasProp(value, "sortOrder")) {
      return { success: false };
    }
    const parsed_sortOrder = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["sortOrder"]);
    })();
    if (!parsed_sortOrder.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return { success: true, value: { channelId: parsed_channelId.value, sortOrder: parsed_sortOrder.value, title: parsed_title.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ChannelSaveResponse
export function serialize_ChannelSaveResponse(obj: ChannelSaveResponse): string {
  const serialized = ((value: { kind: "VALIDATION_ERROR"; validationErrorReasons: ValidationErrorReasons } | { kind: "SUCCESS"; channelId: bigint }): unknown => {
    switch (value.kind) {
      case "VALIDATION_ERROR": {
        return {
          kind: "VALIDATION_ERROR",
          validationErrorReasons: ((value: { fields: { additionalProperties: Record<string, string[]> }; general: string[] }): unknown => {
            return {
              fields: ((value: { additionalProperties: Record<string, string[]> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string[]): unknown => {
                          return value.map((value: string): unknown => {
                            return value;
                          });
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["fields"]),
              general: ((value: string[]): unknown => {
                return value.map((value: string): unknown => {
                  return value;
                });
              })(value["general"]),
            };
          })(value["validationErrorReasons"]),
        };
      }
      case "SUCCESS": {
        return {
          kind: "SUCCESS",
          channelId: ((value: bigint): unknown => {
            return value;
          })(value["channelId"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ChannelSaveResponse(str: string): ChannelSaveResponse | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: { kind: "VALIDATION_ERROR"; validationErrorReasons: ValidationErrorReasons } | { kind: "SUCCESS"; channelId: bigint } } => {
    return ((value: unknown): { success: false } | { success: true; value: { kind: "VALIDATION_ERROR"; validationErrorReasons: ValidationErrorReasons } | { kind: "SUCCESS"; channelId: bigint } } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "kind")) {
        return { success: false };
      }
      if (typeof value["kind"] != "string") {
        return { success: false };
      }
      const discriminator = value["kind"];
      switch (discriminator) {
        case "VALIDATION_ERROR": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  validationErrorReasons: ValidationErrorReasons;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "validationErrorReasons")) {
              return { success: false };
            }
            const parsed_validationErrorReasons = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ValidationErrorReasons } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        fields: { additionalProperties: Record<string, string[]> };
                        general: string[];
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "fields")) {
                    return { success: false };
                  }
                  const parsed_fields = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string[]> } } => {
                      if (typeof value !== "object") {
                        return { success: false };
                      }
                      if (value === null) {
                        return { success: false };
                      }
                      const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string[]> } => {
                        const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string[] }] => {
                          return [
                            key,
                            ((value: unknown): { success: false } | { success: true; value: string[] } => {
                              if (!isArray(value)) {
                                return { success: false };
                              }
                              const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              });
                              if (parsed.find((value) => !value.success) !== undefined) {
                                return { success: false };
                              }
                              return {
                                success: true,
                                value: parsed.map((entry) => {
                                  assert(entry.success == true);
                                  return entry.value;
                                }),
                              };
                            })(value),
                          ];
                        });
                        if (parsed.find(([_, value]) => !value.success) !== undefined) {
                          return { success: false };
                        }
                        return {
                          success: true,
                          value: Object.fromEntries(
                            parsed.map(([key, value]) => {
                              assert(value.success);
                              return [key, value.value];
                            }),
                          ),
                        };
                      })();
                      if (!parsed_additionalProperties.success) {
                        return { success: false };
                      }
                      return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
                    })(value["fields"]);
                  })();
                  if (!parsed_fields.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "general")) {
                    return { success: false };
                  }
                  const parsed_general = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                      if (!isArray(value)) {
                        return { success: false };
                      }
                      const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      });
                      if (parsed.find((value) => !value.success) !== undefined) {
                        return { success: false };
                      }
                      return {
                        success: true,
                        value: parsed.map((entry) => {
                          assert(entry.success == true);
                          return entry.value;
                        }),
                      };
                    })(value["general"]);
                  })();
                  if (!parsed_general.success) {
                    return { success: false };
                  }
                  return { success: true, value: { fields: parsed_fields.value, general: parsed_general.value } };
                })(value);
              })(value["validationErrorReasons"]);
            })();
            if (!parsed_validationErrorReasons.success) {
              return { success: false };
            }
            return { success: true, value: { validationErrorReasons: parsed_validationErrorReasons.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "VALIDATION_ERROR" as const, ...parsedVariant.value } };
        }
        case "SUCCESS": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  channelId: bigint;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "channelId")) {
              return { success: false };
            }
            const parsed_channelId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["channelId"]);
            })();
            if (!parsed_channelId.success) {
              return { success: false };
            }
            return { success: true, value: { channelId: parsed_channelId.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "SUCCESS" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ChannelTextLocalization
export function serialize_ChannelTextLocalization(obj: ChannelTextLocalization): string {
  const serialized = ((value: { description: string | null; synonym: string | null; title: string | null }): unknown => {
    return {
      description: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["description"]),
      synonym: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["synonym"]),
      title: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ChannelTextLocalization(str: string): ChannelTextLocalization | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          description: string | null;
          synonym: string | null;
          title: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "description")) {
      return { success: false };
    }
    const parsed_description = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["description"]);
    })();
    if (!parsed_description.success) {
      return { success: false };
    }
    if (!hasProp(value, "synonym")) {
      return { success: false };
    }
    const parsed_synonym = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["synonym"]);
    })();
    if (!parsed_synonym.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return { success: true, value: { description: parsed_description.value, synonym: parsed_synonym.value, title: parsed_title.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorCompletionExecutionLogListView
export function serialize_ConnectorCompletionExecutionLogListView(obj: ConnectorCompletionExecutionLogListView): string {
  const serialized = ((value: {
    completedAt: DateTime<true> | null;
    connectorExecutionLogId: bigint;
    contentId: string;
    method: string;
    request: string;
    response: string;
    statusCode: number;
    syncedAt: DateTime<true>;
    userId: string;
  }): unknown => {
    return {
      completedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toISO();
      })(value["completedAt"]),
      connectorExecutionLogId: ((value: bigint): unknown => {
        return value;
      })(value["connectorExecutionLogId"]),
      contentId: ((value: string): unknown => {
        return value;
      })(value["contentId"]),
      method: ((value: string): unknown => {
        return value;
      })(value["method"]),
      request: ((value: string): unknown => {
        return value;
      })(value["request"]),
      response: ((value: string): unknown => {
        return value;
      })(value["response"]),
      statusCode: ((value: number): unknown => {
        return value;
      })(value["statusCode"]),
      syncedAt: ((value: DateTime<true>): unknown => {
        return value.toISO();
      })(value["syncedAt"]),
      userId: ((value: string): unknown => {
        return value;
      })(value["userId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorCompletionExecutionLogListView(str: string): ConnectorCompletionExecutionLogListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          completedAt: DateTime<true> | null;
          connectorExecutionLogId: bigint;
          contentId: string;
          method: string;
          request: string;
          response: string;
          statusCode: number;
          syncedAt: DateTime<true>;
          userId: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "completedAt")) {
      return { success: false };
    }
    const parsed_completedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["completedAt"]);
    })();
    if (!parsed_completedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "connectorExecutionLogId")) {
      return { success: false };
    }
    const parsed_connectorExecutionLogId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["connectorExecutionLogId"]);
    })();
    if (!parsed_connectorExecutionLogId.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentId")) {
      return { success: false };
    }
    const parsed_contentId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["contentId"]);
    })();
    if (!parsed_contentId.success) {
      return { success: false };
    }
    if (!hasProp(value, "method")) {
      return { success: false };
    }
    const parsed_method = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["method"]);
    })();
    if (!parsed_method.success) {
      return { success: false };
    }
    if (!hasProp(value, "request")) {
      return { success: false };
    }
    const parsed_request = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["request"]);
    })();
    if (!parsed_request.success) {
      return { success: false };
    }
    if (!hasProp(value, "response")) {
      return { success: false };
    }
    const parsed_response = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["response"]);
    })();
    if (!parsed_response.success) {
      return { success: false };
    }
    if (!hasProp(value, "statusCode")) {
      return { success: false };
    }
    const parsed_statusCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["statusCode"]);
    })();
    if (!parsed_statusCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "syncedAt")) {
      return { success: false };
    }
    const parsed_syncedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["syncedAt"]);
    })();
    if (!parsed_syncedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "userId")) {
      return { success: false };
    }
    const parsed_userId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["userId"]);
    })();
    if (!parsed_userId.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        completedAt: parsed_completedAt.value,
        connectorExecutionLogId: parsed_connectorExecutionLogId.value,
        contentId: parsed_contentId.value,
        method: parsed_method.value,
        request: parsed_request.value,
        response: parsed_response.value,
        statusCode: parsed_statusCode.value,
        syncedAt: parsed_syncedAt.value,
        userId: parsed_userId.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorCompletionFilters
export function serialize_ConnectorCompletionFilters(obj: ConnectorCompletionFilters): string {
  const serialized = ((value: { timeFrame: "LAST_24_HOURS" | "LAST_WEEK" | "LAST_2_WEEKS" | "LAST_MONTH" | "LAST_3_MONTHS"; userId: string | null }): unknown => {
    return {
      timeFrame: ((value: "LAST_24_HOURS" | "LAST_WEEK" | "LAST_2_WEEKS" | "LAST_MONTH" | "LAST_3_MONTHS"): unknown => {
        return value;
      })(value["timeFrame"]),
      userId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["userId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorCompletionFilters(str: string): ConnectorCompletionFilters | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          timeFrame: "LAST_24_HOURS" | "LAST_WEEK" | "LAST_2_WEEKS" | "LAST_MONTH" | "LAST_3_MONTHS";
          userId: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "timeFrame")) {
      return { success: false };
    }
    const parsed_timeFrame = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "LAST_24_HOURS" | "LAST_WEEK" | "LAST_2_WEEKS" | "LAST_MONTH" | "LAST_3_MONTHS" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "LAST_24_HOURS":
            return { success: true, value: "LAST_24_HOURS" };
          case "LAST_WEEK":
            return { success: true, value: "LAST_WEEK" };
          case "LAST_2_WEEKS":
            return { success: true, value: "LAST_2_WEEKS" };
          case "LAST_MONTH":
            return { success: true, value: "LAST_MONTH" };
          case "LAST_3_MONTHS":
            return { success: true, value: "LAST_3_MONTHS" };
          default:
            return { success: false };
        }
      })(value["timeFrame"]);
    })();
    if (!parsed_timeFrame.success) {
      return { success: false };
    }
    if (!hasProp(value, "userId")) {
      return { success: false };
    }
    const parsed_userId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["userId"]);
    })();
    if (!parsed_userId.success) {
      return { success: false };
    }
    return { success: true, value: { timeFrame: parsed_timeFrame.value, userId: parsed_userId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorContentExecutionListView
export function serialize_ConnectorContentExecutionListView(obj: ConnectorContentExecutionListView): string {
  const serialized = ((value: {
    connectorExecutionId: bigint;
    connectorName: string;
    description: string;
    detail: ConnectorContentExecutionLogDetailView;
    provider: ConnectorProviderMetadata;
    syncedAt: DateTime<true> | null;
    type: "WEB" | "BATCH";
  }): unknown => {
    return {
      connectorExecutionId: ((value: bigint): unknown => {
        return value;
      })(value["connectorExecutionId"]),
      connectorName: ((value: string): unknown => {
        return value;
      })(value["connectorName"]),
      description: ((value: string): unknown => {
        return value;
      })(value["description"]),
      detail: ((value: { failedExportedItems: number; successfullyExportedItems: number }): unknown => {
        return {
          failedExportedItems: ((value: number): unknown => {
            return value;
          })(value["failedExportedItems"]),
          successfullyExportedItems: ((value: number): unknown => {
            return value;
          })(value["successfullyExportedItems"]),
        };
      })(value["detail"]),
      provider: ((value: { imageUri: string; name: string; type: ConnectorType }): unknown => {
        return {
          imageUri: ((value: string): unknown => {
            return value;
          })(value["imageUri"]),
          name: ((value: string): unknown => {
            return value;
          })(value["name"]),
          type: ((value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "DOCEBO_CATALOG" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" | "VIVA_LEARNING" | "ORACLE"): unknown => {
            return value;
          })(value["type"]),
        };
      })(value["provider"]),
      syncedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toISO();
      })(value["syncedAt"]),
      type: ((value: "WEB" | "BATCH"): unknown => {
        return value;
      })(value["type"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorContentExecutionListView(str: string): ConnectorContentExecutionListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          connectorExecutionId: bigint;
          connectorName: string;
          description: string;
          detail: ConnectorContentExecutionLogDetailView;
          provider: ConnectorProviderMetadata;
          syncedAt: DateTime<true> | null;
          type: "WEB" | "BATCH";
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "connectorExecutionId")) {
      return { success: false };
    }
    const parsed_connectorExecutionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["connectorExecutionId"]);
    })();
    if (!parsed_connectorExecutionId.success) {
      return { success: false };
    }
    if (!hasProp(value, "connectorName")) {
      return { success: false };
    }
    const parsed_connectorName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["connectorName"]);
    })();
    if (!parsed_connectorName.success) {
      return { success: false };
    }
    if (!hasProp(value, "description")) {
      return { success: false };
    }
    const parsed_description = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["description"]);
    })();
    if (!parsed_description.success) {
      return { success: false };
    }
    if (!hasProp(value, "detail")) {
      return { success: false };
    }
    const parsed_detail = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorContentExecutionLogDetailView } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                failedExportedItems: number;
                successfullyExportedItems: number;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "failedExportedItems")) {
            return { success: false };
          }
          const parsed_failedExportedItems = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["failedExportedItems"]);
          })();
          if (!parsed_failedExportedItems.success) {
            return { success: false };
          }
          if (!hasProp(value, "successfullyExportedItems")) {
            return { success: false };
          }
          const parsed_successfullyExportedItems = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["successfullyExportedItems"]);
          })();
          if (!parsed_successfullyExportedItems.success) {
            return { success: false };
          }
          return { success: true, value: { failedExportedItems: parsed_failedExportedItems.value, successfullyExportedItems: parsed_successfullyExportedItems.value } };
        })(value);
      })(value["detail"]);
    })();
    if (!parsed_detail.success) {
      return { success: false };
    }
    if (!hasProp(value, "provider")) {
      return { success: false };
    }
    const parsed_provider = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorProviderMetadata } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                imageUri: string;
                name: string;
                type: ConnectorType;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "imageUri")) {
            return { success: false };
          }
          const parsed_imageUri = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              try {
                new URL(value);
              } catch (ex) {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["imageUri"]);
          })();
          if (!parsed_imageUri.success) {
            return { success: false };
          }
          if (!hasProp(value, "name")) {
            return { success: false };
          }
          const parsed_name = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["name"]);
          })();
          if (!parsed_name.success) {
            return { success: false };
          }
          if (!hasProp(value, "type")) {
            return { success: false };
          }
          const parsed_type = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ConnectorType } => {
              return ((value: unknown): { success: false } | { success: true; value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "DOCEBO_CATALOG" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" | "VIVA_LEARNING" | "ORACLE" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "SUCCESS_FACTORS":
                    return { success: true, value: "SUCCESS_FACTORS" };
                  case "DEGREED":
                    return { success: true, value: "DEGREED" };
                  case "DOCEBO":
                    return { success: true, value: "DOCEBO" };
                  case "DOCEBO_CATALOG":
                    return { success: true, value: "DOCEBO_CATALOG" };
                  case "CSOD":
                    return { success: true, value: "CSOD" };
                  case "SUM_TOTAL":
                    return { success: true, value: "SUM_TOTAL" };
                  case "EDCAST":
                    return { success: true, value: "EDCAST" };
                  case "WORKDAY":
                    return { success: true, value: "WORKDAY" };
                  case "VIVA_LEARNING":
                    return { success: true, value: "VIVA_LEARNING" };
                  case "ORACLE":
                    return { success: true, value: "ORACLE" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["type"]);
          })();
          if (!parsed_type.success) {
            return { success: false };
          }
          return { success: true, value: { imageUri: parsed_imageUri.value, name: parsed_name.value, type: parsed_type.value } };
        })(value);
      })(value["provider"]);
    })();
    if (!parsed_provider.success) {
      return { success: false };
    }
    if (!hasProp(value, "syncedAt")) {
      return { success: false };
    }
    const parsed_syncedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["syncedAt"]);
    })();
    if (!parsed_syncedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "type")) {
      return { success: false };
    }
    const parsed_type = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "WEB" | "BATCH" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "WEB":
            return { success: true, value: "WEB" };
          case "BATCH":
            return { success: true, value: "BATCH" };
          default:
            return { success: false };
        }
      })(value["type"]);
    })();
    if (!parsed_type.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        connectorExecutionId: parsed_connectorExecutionId.value,
        connectorName: parsed_connectorName.value,
        description: parsed_description.value,
        detail: parsed_detail.value,
        provider: parsed_provider.value,
        syncedAt: parsed_syncedAt.value,
        type: parsed_type.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorContentExecutionLogDetailView
export function serialize_ConnectorContentExecutionLogDetailView(obj: ConnectorContentExecutionLogDetailView): string {
  const serialized = ((value: { failedExportedItems: number; successfullyExportedItems: number }): unknown => {
    return {
      failedExportedItems: ((value: number): unknown => {
        return value;
      })(value["failedExportedItems"]),
      successfullyExportedItems: ((value: number): unknown => {
        return value;
      })(value["successfullyExportedItems"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorContentExecutionLogDetailView(str: string): ConnectorContentExecutionLogDetailView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          failedExportedItems: number;
          successfullyExportedItems: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "failedExportedItems")) {
      return { success: false };
    }
    const parsed_failedExportedItems = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["failedExportedItems"]);
    })();
    if (!parsed_failedExportedItems.success) {
      return { success: false };
    }
    if (!hasProp(value, "successfullyExportedItems")) {
      return { success: false };
    }
    const parsed_successfullyExportedItems = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["successfullyExportedItems"]);
    })();
    if (!parsed_successfullyExportedItems.success) {
      return { success: false };
    }
    return { success: true, value: { failedExportedItems: parsed_failedExportedItems.value, successfullyExportedItems: parsed_successfullyExportedItems.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorContentExecutionLogListView
export function serialize_ConnectorContentExecutionLogListView(obj: ConnectorContentExecutionLogListView): string {
  const serialized = ((value: { connectorExecutionLogId: bigint; contentId: string; method: string; request: string; response: string; statusCode: number; syncedAt: DateTime<true> }): unknown => {
    return {
      connectorExecutionLogId: ((value: bigint): unknown => {
        return value;
      })(value["connectorExecutionLogId"]),
      contentId: ((value: string): unknown => {
        return value;
      })(value["contentId"]),
      method: ((value: string): unknown => {
        return value;
      })(value["method"]),
      request: ((value: string): unknown => {
        return value;
      })(value["request"]),
      response: ((value: string): unknown => {
        return value;
      })(value["response"]),
      statusCode: ((value: number): unknown => {
        return value;
      })(value["statusCode"]),
      syncedAt: ((value: DateTime<true>): unknown => {
        return value.toISO();
      })(value["syncedAt"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorContentExecutionLogListView(str: string): ConnectorContentExecutionLogListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          connectorExecutionLogId: bigint;
          contentId: string;
          method: string;
          request: string;
          response: string;
          statusCode: number;
          syncedAt: DateTime<true>;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "connectorExecutionLogId")) {
      return { success: false };
    }
    const parsed_connectorExecutionLogId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["connectorExecutionLogId"]);
    })();
    if (!parsed_connectorExecutionLogId.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentId")) {
      return { success: false };
    }
    const parsed_contentId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["contentId"]);
    })();
    if (!parsed_contentId.success) {
      return { success: false };
    }
    if (!hasProp(value, "method")) {
      return { success: false };
    }
    const parsed_method = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["method"]);
    })();
    if (!parsed_method.success) {
      return { success: false };
    }
    if (!hasProp(value, "request")) {
      return { success: false };
    }
    const parsed_request = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["request"]);
    })();
    if (!parsed_request.success) {
      return { success: false };
    }
    if (!hasProp(value, "response")) {
      return { success: false };
    }
    const parsed_response = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["response"]);
    })();
    if (!parsed_response.success) {
      return { success: false };
    }
    if (!hasProp(value, "statusCode")) {
      return { success: false };
    }
    const parsed_statusCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["statusCode"]);
    })();
    if (!parsed_statusCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "syncedAt")) {
      return { success: false };
    }
    const parsed_syncedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["syncedAt"]);
    })();
    if (!parsed_syncedAt.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        connectorExecutionLogId: parsed_connectorExecutionLogId.value,
        contentId: parsed_contentId.value,
        method: parsed_method.value,
        request: parsed_request.value,
        response: parsed_response.value,
        statusCode: parsed_statusCode.value,
        syncedAt: parsed_syncedAt.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorExecutionLogMetadata
export function serialize_ConnectorExecutionLogMetadata(obj: ConnectorExecutionLogMetadata): string {
  const serialized = ((value: { connectorExecutionLogId: bigint; contentId: string; method: string; request: string; response: string; statusCode: number; syncedAt: DateTime<true> }): unknown => {
    return {
      connectorExecutionLogId: ((value: bigint): unknown => {
        return value;
      })(value["connectorExecutionLogId"]),
      contentId: ((value: string): unknown => {
        return value;
      })(value["contentId"]),
      method: ((value: string): unknown => {
        return value;
      })(value["method"]),
      request: ((value: string): unknown => {
        return value;
      })(value["request"]),
      response: ((value: string): unknown => {
        return value;
      })(value["response"]),
      statusCode: ((value: number): unknown => {
        return value;
      })(value["statusCode"]),
      syncedAt: ((value: DateTime<true>): unknown => {
        return value.toISO();
      })(value["syncedAt"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorExecutionLogMetadata(str: string): ConnectorExecutionLogMetadata | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          connectorExecutionLogId: bigint;
          contentId: string;
          method: string;
          request: string;
          response: string;
          statusCode: number;
          syncedAt: DateTime<true>;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "connectorExecutionLogId")) {
      return { success: false };
    }
    const parsed_connectorExecutionLogId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["connectorExecutionLogId"]);
    })();
    if (!parsed_connectorExecutionLogId.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentId")) {
      return { success: false };
    }
    const parsed_contentId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["contentId"]);
    })();
    if (!parsed_contentId.success) {
      return { success: false };
    }
    if (!hasProp(value, "method")) {
      return { success: false };
    }
    const parsed_method = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["method"]);
    })();
    if (!parsed_method.success) {
      return { success: false };
    }
    if (!hasProp(value, "request")) {
      return { success: false };
    }
    const parsed_request = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["request"]);
    })();
    if (!parsed_request.success) {
      return { success: false };
    }
    if (!hasProp(value, "response")) {
      return { success: false };
    }
    const parsed_response = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["response"]);
    })();
    if (!parsed_response.success) {
      return { success: false };
    }
    if (!hasProp(value, "statusCode")) {
      return { success: false };
    }
    const parsed_statusCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["statusCode"]);
    })();
    if (!parsed_statusCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "syncedAt")) {
      return { success: false };
    }
    const parsed_syncedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["syncedAt"]);
    })();
    if (!parsed_syncedAt.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        connectorExecutionLogId: parsed_connectorExecutionLogId.value,
        contentId: parsed_contentId.value,
        method: parsed_method.value,
        request: parsed_request.value,
        response: parsed_response.value,
        statusCode: parsed_statusCode.value,
        syncedAt: parsed_syncedAt.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorExecutionType
export function serialize_ConnectorExecutionType(obj: ConnectorExecutionType): string {
  const serialized = ((value: "WEB" | "BATCH"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorExecutionType(str: string): ConnectorExecutionType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "WEB" | "BATCH" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "WEB":
        return { success: true, value: "WEB" };
      case "BATCH":
        return { success: true, value: "BATCH" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorExecutionView
export function serialize_ConnectorExecutionView(obj: ConnectorExecutionView): string {
  const serialized = ((value: { connectorExecutionId: bigint; executedAt: DateTime<true>; executionType: ConnectorExecutionType; logsCount: number; provider: ConnectorProviderMetadata }): unknown => {
    return {
      connectorExecutionId: ((value: bigint): unknown => {
        return value;
      })(value["connectorExecutionId"]),
      executedAt: ((value: DateTime<true>): unknown => {
        return value.toISO();
      })(value["executedAt"]),
      executionType: ((value: "WEB" | "BATCH"): unknown => {
        return value;
      })(value["executionType"]),
      logsCount: ((value: number): unknown => {
        return value;
      })(value["logsCount"]),
      provider: ((value: { imageUri: string; name: string; type: ConnectorType }): unknown => {
        return {
          imageUri: ((value: string): unknown => {
            return value;
          })(value["imageUri"]),
          name: ((value: string): unknown => {
            return value;
          })(value["name"]),
          type: ((value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "DOCEBO_CATALOG" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" | "VIVA_LEARNING" | "ORACLE"): unknown => {
            return value;
          })(value["type"]),
        };
      })(value["provider"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorExecutionView(str: string): ConnectorExecutionView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          connectorExecutionId: bigint;
          executedAt: DateTime<true>;
          executionType: ConnectorExecutionType;
          logsCount: number;
          provider: ConnectorProviderMetadata;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "connectorExecutionId")) {
      return { success: false };
    }
    const parsed_connectorExecutionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["connectorExecutionId"]);
    })();
    if (!parsed_connectorExecutionId.success) {
      return { success: false };
    }
    if (!hasProp(value, "executedAt")) {
      return { success: false };
    }
    const parsed_executedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["executedAt"]);
    })();
    if (!parsed_executedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "executionType")) {
      return { success: false };
    }
    const parsed_executionType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorExecutionType } => {
        return ((value: unknown): { success: false } | { success: true; value: "WEB" | "BATCH" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "WEB":
              return { success: true, value: "WEB" };
            case "BATCH":
              return { success: true, value: "BATCH" };
            default:
              return { success: false };
          }
        })(value);
      })(value["executionType"]);
    })();
    if (!parsed_executionType.success) {
      return { success: false };
    }
    if (!hasProp(value, "logsCount")) {
      return { success: false };
    }
    const parsed_logsCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["logsCount"]);
    })();
    if (!parsed_logsCount.success) {
      return { success: false };
    }
    if (!hasProp(value, "provider")) {
      return { success: false };
    }
    const parsed_provider = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorProviderMetadata } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                imageUri: string;
                name: string;
                type: ConnectorType;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "imageUri")) {
            return { success: false };
          }
          const parsed_imageUri = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              try {
                new URL(value);
              } catch (ex) {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["imageUri"]);
          })();
          if (!parsed_imageUri.success) {
            return { success: false };
          }
          if (!hasProp(value, "name")) {
            return { success: false };
          }
          const parsed_name = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["name"]);
          })();
          if (!parsed_name.success) {
            return { success: false };
          }
          if (!hasProp(value, "type")) {
            return { success: false };
          }
          const parsed_type = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ConnectorType } => {
              return ((value: unknown): { success: false } | { success: true; value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "DOCEBO_CATALOG" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" | "VIVA_LEARNING" | "ORACLE" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "SUCCESS_FACTORS":
                    return { success: true, value: "SUCCESS_FACTORS" };
                  case "DEGREED":
                    return { success: true, value: "DEGREED" };
                  case "DOCEBO":
                    return { success: true, value: "DOCEBO" };
                  case "DOCEBO_CATALOG":
                    return { success: true, value: "DOCEBO_CATALOG" };
                  case "CSOD":
                    return { success: true, value: "CSOD" };
                  case "SUM_TOTAL":
                    return { success: true, value: "SUM_TOTAL" };
                  case "EDCAST":
                    return { success: true, value: "EDCAST" };
                  case "WORKDAY":
                    return { success: true, value: "WORKDAY" };
                  case "VIVA_LEARNING":
                    return { success: true, value: "VIVA_LEARNING" };
                  case "ORACLE":
                    return { success: true, value: "ORACLE" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["type"]);
          })();
          if (!parsed_type.success) {
            return { success: false };
          }
          return { success: true, value: { imageUri: parsed_imageUri.value, name: parsed_name.value, type: parsed_type.value } };
        })(value);
      })(value["provider"]);
    })();
    if (!parsed_provider.success) {
      return { success: false };
    }
    return {
      success: true,
      value: { connectorExecutionId: parsed_connectorExecutionId.value, executedAt: parsed_executedAt.value, executionType: parsed_executionType.value, logsCount: parsed_logsCount.value, provider: parsed_provider.value },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorForm
export function serialize_ConnectorForm(obj: ConnectorForm): string {
  const serialized = ((value: { active: boolean; languages: Language[]; name: string; products: ConnectorProduct[]; settingsForm: ConnectorSettingsForm; syncCompletions: boolean; syncContent: boolean; type: ConnectorType }): unknown => {
    return {
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      languages: ((value: Language[]): unknown => {
        return value.map((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
          return value;
        });
      })(value["languages"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      products: ((value: ConnectorProduct[]): unknown => {
        return value.map((value: "BUSINESS_BOOKS" | "ARTICLES_AND_REPORTS" | "VIDEOS_AND_PODCASTS" | "ACTIONABLES"): unknown => {
          return value;
        });
      })(value["products"]),
      settingsForm: ((value: {
        accountId: string | null;
        apiKey: string | null;
        clientId: string;
        clientSecret: string;
        doceboCatalogCode: string | null;
        doceboCategoryCode: string | null;
        doceboChannelId: bigint | null;
        grantType: ("CLIENT_CREDENTIALS" | "PASSWORD") | null;
        lmsApiUri: string | null;
        lmsBaseUri: string;
        lmsTokenUri: string | null;
        oauth2Flow: Oauth2Flow;
        password: string | null;
        scope: string | null;
        sfCompanyId: string | null;
        userId: string | null;
        userIdentifier: ConnectorUserIdentifier;
        username: string | null;
        workdayCategoryFallback: string | null;
        xapiActorId: XApiActorId;
      }): unknown => {
        return {
          accountId: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["accountId"]),
          apiKey: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["apiKey"]),
          clientId: ((value: string): unknown => {
            return value;
          })(value["clientId"]),
          clientSecret: ((value: string): unknown => {
            return value;
          })(value["clientSecret"]),
          doceboCatalogCode: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["doceboCatalogCode"]),
          doceboCategoryCode: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["doceboCategoryCode"]),
          doceboChannelId: ((value: bigint | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["doceboChannelId"]),
          grantType: ((value: ("CLIENT_CREDENTIALS" | "PASSWORD") | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["grantType"]),
          lmsApiUri: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["lmsApiUri"]),
          lmsBaseUri: ((value: string): unknown => {
            return value;
          })(value["lmsBaseUri"]),
          lmsTokenUri: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["lmsTokenUri"]),
          oauth2Flow: ((value: "BASIC" | "OAUTH2"): unknown => {
            return value;
          })(value["oauth2Flow"]),
          password: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["password"]),
          scope: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["scope"]),
          sfCompanyId: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["sfCompanyId"]),
          userId: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["userId"]),
          userIdentifier: ((value: "NAME_ID" | "EMAIL" | "EXTRA_FIELD_1" | "EXTRA_FIELD_2" | "EXTRA_FIELD_3"): unknown => {
            return value;
          })(value["userIdentifier"]),
          username: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["username"]),
          workdayCategoryFallback: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["workdayCategoryFallback"]),
          xapiActorId: ((value: "MBOX" | "ACCOUNT" | "OPENID"): unknown => {
            return value;
          })(value["xapiActorId"]),
        };
      })(value["settingsForm"]),
      syncCompletions: ((value: boolean): unknown => {
        return value;
      })(value["syncCompletions"]),
      syncContent: ((value: boolean): unknown => {
        return value;
      })(value["syncContent"]),
      type: ((value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "DOCEBO_CATALOG" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" | "VIVA_LEARNING" | "ORACLE"): unknown => {
        return value;
      })(value["type"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorForm(str: string): ConnectorForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          active: boolean;
          languages: Language[];
          name: string;
          products: ConnectorProduct[];
          settingsForm: ConnectorSettingsForm;
          syncCompletions: boolean;
          syncContent: boolean;
          type: ConnectorType;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "languages")) {
      return { success: false };
    }
    const parsed_languages = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Language } => {
          return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            switch (value) {
              case "en":
                return { success: true, value: "en" };
              case "de":
                return { success: true, value: "de" };
              case "es":
                return { success: true, value: "es" };
              case "ru":
                return { success: true, value: "ru" };
              case "zh":
                return { success: true, value: "zh" };
              case "pt":
                return { success: true, value: "pt" };
              case "fr":
                return { success: true, value: "fr" };
              default:
                return { success: false };
            }
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["languages"]);
    })();
    if (!parsed_languages.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "products")) {
      return { success: false };
    }
    const parsed_products = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorProduct[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ConnectorProduct } => {
          return ((value: unknown): { success: false } | { success: true; value: "BUSINESS_BOOKS" | "ARTICLES_AND_REPORTS" | "VIDEOS_AND_PODCASTS" | "ACTIONABLES" } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            switch (value) {
              case "BUSINESS_BOOKS":
                return { success: true, value: "BUSINESS_BOOKS" };
              case "ARTICLES_AND_REPORTS":
                return { success: true, value: "ARTICLES_AND_REPORTS" };
              case "VIDEOS_AND_PODCASTS":
                return { success: true, value: "VIDEOS_AND_PODCASTS" };
              case "ACTIONABLES":
                return { success: true, value: "ACTIONABLES" };
              default:
                return { success: false };
            }
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["products"]);
    })();
    if (!parsed_products.success) {
      return { success: false };
    }
    if (!hasProp(value, "settingsForm")) {
      return { success: false };
    }
    const parsed_settingsForm = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorSettingsForm } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                accountId: string | null;
                apiKey: string | null;
                clientId: string;
                clientSecret: string;
                doceboCatalogCode: string | null;
                doceboCategoryCode: string | null;
                doceboChannelId: bigint | null;
                grantType: ("CLIENT_CREDENTIALS" | "PASSWORD") | null;
                lmsApiUri: string | null;
                lmsBaseUri: string;
                lmsTokenUri: string | null;
                oauth2Flow: Oauth2Flow;
                password: string | null;
                scope: string | null;
                sfCompanyId: string | null;
                userId: string | null;
                userIdentifier: ConnectorUserIdentifier;
                username: string | null;
                workdayCategoryFallback: string | null;
                xapiActorId: XApiActorId;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "accountId")) {
            return { success: false };
          }
          const parsed_accountId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["accountId"]);
          })();
          if (!parsed_accountId.success) {
            return { success: false };
          }
          if (!hasProp(value, "apiKey")) {
            return { success: false };
          }
          const parsed_apiKey = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["apiKey"]);
          })();
          if (!parsed_apiKey.success) {
            return { success: false };
          }
          if (!hasProp(value, "clientId")) {
            return { success: false };
          }
          const parsed_clientId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["clientId"]);
          })();
          if (!parsed_clientId.success) {
            return { success: false };
          }
          if (!hasProp(value, "clientSecret")) {
            return { success: false };
          }
          const parsed_clientSecret = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["clientSecret"]);
          })();
          if (!parsed_clientSecret.success) {
            return { success: false };
          }
          if (!hasProp(value, "doceboCatalogCode")) {
            return { success: false };
          }
          const parsed_doceboCatalogCode = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["doceboCatalogCode"]);
          })();
          if (!parsed_doceboCatalogCode.success) {
            return { success: false };
          }
          if (!hasProp(value, "doceboCategoryCode")) {
            return { success: false };
          }
          const parsed_doceboCategoryCode = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["doceboCategoryCode"]);
          })();
          if (!parsed_doceboCategoryCode.success) {
            return { success: false };
          }
          if (!hasProp(value, "doceboChannelId")) {
            return { success: false };
          }
          const parsed_doceboChannelId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: bigint | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value === "number") {
                return { success: true, value: BigInt(value) };
              }
              if (typeof value === "bigint") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["doceboChannelId"]);
          })();
          if (!parsed_doceboChannelId.success) {
            return { success: false };
          }
          if (!hasProp(value, "grantType")) {
            return { success: false };
          }
          const parsed_grantType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ("CLIENT_CREDENTIALS" | "PASSWORD") | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              switch (value) {
                case "CLIENT_CREDENTIALS":
                  return { success: true, value: "CLIENT_CREDENTIALS" };
                case "PASSWORD":
                  return { success: true, value: "PASSWORD" };
                default:
                  return { success: false };
              }
            })(value["grantType"]);
          })();
          if (!parsed_grantType.success) {
            return { success: false };
          }
          if (!hasProp(value, "lmsApiUri")) {
            return { success: false };
          }
          const parsed_lmsApiUri = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lmsApiUri"]);
          })();
          if (!parsed_lmsApiUri.success) {
            return { success: false };
          }
          if (!hasProp(value, "lmsBaseUri")) {
            return { success: false };
          }
          const parsed_lmsBaseUri = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lmsBaseUri"]);
          })();
          if (!parsed_lmsBaseUri.success) {
            return { success: false };
          }
          if (!hasProp(value, "lmsTokenUri")) {
            return { success: false };
          }
          const parsed_lmsTokenUri = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lmsTokenUri"]);
          })();
          if (!parsed_lmsTokenUri.success) {
            return { success: false };
          }
          if (!hasProp(value, "oauth2Flow")) {
            return { success: false };
          }
          const parsed_oauth2Flow = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Oauth2Flow } => {
              return ((value: unknown): { success: false } | { success: true; value: "BASIC" | "OAUTH2" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "BASIC":
                    return { success: true, value: "BASIC" };
                  case "OAUTH2":
                    return { success: true, value: "OAUTH2" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["oauth2Flow"]);
          })();
          if (!parsed_oauth2Flow.success) {
            return { success: false };
          }
          if (!hasProp(value, "password")) {
            return { success: false };
          }
          const parsed_password = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["password"]);
          })();
          if (!parsed_password.success) {
            return { success: false };
          }
          if (!hasProp(value, "scope")) {
            return { success: false };
          }
          const parsed_scope = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["scope"]);
          })();
          if (!parsed_scope.success) {
            return { success: false };
          }
          if (!hasProp(value, "sfCompanyId")) {
            return { success: false };
          }
          const parsed_sfCompanyId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["sfCompanyId"]);
          })();
          if (!parsed_sfCompanyId.success) {
            return { success: false };
          }
          if (!hasProp(value, "userId")) {
            return { success: false };
          }
          const parsed_userId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["userId"]);
          })();
          if (!parsed_userId.success) {
            return { success: false };
          }
          if (!hasProp(value, "userIdentifier")) {
            return { success: false };
          }
          const parsed_userIdentifier = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ConnectorUserIdentifier } => {
              return ((value: unknown): { success: false } | { success: true; value: "NAME_ID" | "EMAIL" | "EXTRA_FIELD_1" | "EXTRA_FIELD_2" | "EXTRA_FIELD_3" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "NAME_ID":
                    return { success: true, value: "NAME_ID" };
                  case "EMAIL":
                    return { success: true, value: "EMAIL" };
                  case "EXTRA_FIELD_1":
                    return { success: true, value: "EXTRA_FIELD_1" };
                  case "EXTRA_FIELD_2":
                    return { success: true, value: "EXTRA_FIELD_2" };
                  case "EXTRA_FIELD_3":
                    return { success: true, value: "EXTRA_FIELD_3" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["userIdentifier"]);
          })();
          if (!parsed_userIdentifier.success) {
            return { success: false };
          }
          if (!hasProp(value, "username")) {
            return { success: false };
          }
          const parsed_username = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["username"]);
          })();
          if (!parsed_username.success) {
            return { success: false };
          }
          if (!hasProp(value, "workdayCategoryFallback")) {
            return { success: false };
          }
          const parsed_workdayCategoryFallback = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["workdayCategoryFallback"]);
          })();
          if (!parsed_workdayCategoryFallback.success) {
            return { success: false };
          }
          if (!hasProp(value, "xapiActorId")) {
            return { success: false };
          }
          const parsed_xapiActorId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: XApiActorId } => {
              return ((value: unknown): { success: false } | { success: true; value: "MBOX" | "ACCOUNT" | "OPENID" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "MBOX":
                    return { success: true, value: "MBOX" };
                  case "ACCOUNT":
                    return { success: true, value: "ACCOUNT" };
                  case "OPENID":
                    return { success: true, value: "OPENID" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["xapiActorId"]);
          })();
          if (!parsed_xapiActorId.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              accountId: parsed_accountId.value,
              apiKey: parsed_apiKey.value,
              clientId: parsed_clientId.value,
              clientSecret: parsed_clientSecret.value,
              doceboCatalogCode: parsed_doceboCatalogCode.value,
              doceboCategoryCode: parsed_doceboCategoryCode.value,
              doceboChannelId: parsed_doceboChannelId.value,
              grantType: parsed_grantType.value,
              lmsApiUri: parsed_lmsApiUri.value,
              lmsBaseUri: parsed_lmsBaseUri.value,
              lmsTokenUri: parsed_lmsTokenUri.value,
              oauth2Flow: parsed_oauth2Flow.value,
              password: parsed_password.value,
              scope: parsed_scope.value,
              sfCompanyId: parsed_sfCompanyId.value,
              userId: parsed_userId.value,
              userIdentifier: parsed_userIdentifier.value,
              username: parsed_username.value,
              workdayCategoryFallback: parsed_workdayCategoryFallback.value,
              xapiActorId: parsed_xapiActorId.value,
            },
          };
        })(value);
      })(value["settingsForm"]);
    })();
    if (!parsed_settingsForm.success) {
      return { success: false };
    }
    if (!hasProp(value, "syncCompletions")) {
      return { success: false };
    }
    const parsed_syncCompletions = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["syncCompletions"]);
    })();
    if (!parsed_syncCompletions.success) {
      return { success: false };
    }
    if (!hasProp(value, "syncContent")) {
      return { success: false };
    }
    const parsed_syncContent = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["syncContent"]);
    })();
    if (!parsed_syncContent.success) {
      return { success: false };
    }
    if (!hasProp(value, "type")) {
      return { success: false };
    }
    const parsed_type = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorType } => {
        return ((value: unknown): { success: false } | { success: true; value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "DOCEBO_CATALOG" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" | "VIVA_LEARNING" | "ORACLE" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "SUCCESS_FACTORS":
              return { success: true, value: "SUCCESS_FACTORS" };
            case "DEGREED":
              return { success: true, value: "DEGREED" };
            case "DOCEBO":
              return { success: true, value: "DOCEBO" };
            case "DOCEBO_CATALOG":
              return { success: true, value: "DOCEBO_CATALOG" };
            case "CSOD":
              return { success: true, value: "CSOD" };
            case "SUM_TOTAL":
              return { success: true, value: "SUM_TOTAL" };
            case "EDCAST":
              return { success: true, value: "EDCAST" };
            case "WORKDAY":
              return { success: true, value: "WORKDAY" };
            case "VIVA_LEARNING":
              return { success: true, value: "VIVA_LEARNING" };
            case "ORACLE":
              return { success: true, value: "ORACLE" };
            default:
              return { success: false };
          }
        })(value);
      })(value["type"]);
    })();
    if (!parsed_type.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        active: parsed_active.value,
        languages: parsed_languages.value,
        name: parsed_name.value,
        products: parsed_products.value,
        settingsForm: parsed_settingsForm.value,
        syncCompletions: parsed_syncCompletions.value,
        syncContent: parsed_syncContent.value,
        type: parsed_type.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorGrantType
export function serialize_ConnectorGrantType(obj: ConnectorGrantType): string {
  const serialized = ((value: "CLIENT_CREDENTIALS" | "PASSWORD"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorGrantType(str: string): ConnectorGrantType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "CLIENT_CREDENTIALS" | "PASSWORD" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "CLIENT_CREDENTIALS":
        return { success: true, value: "CLIENT_CREDENTIALS" };
      case "PASSWORD":
        return { success: true, value: "PASSWORD" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorListView
export function serialize_ConnectorListView(obj: ConnectorListView): string {
  const serialized = ((value: { active: boolean; connectorId: bigint; name: string; provider: ConnectorProviderMetadata; syncedAt: DateTime<true> | null }): unknown => {
    return {
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      connectorId: ((value: bigint): unknown => {
        return value;
      })(value["connectorId"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      provider: ((value: { imageUri: string; name: string; type: ConnectorType }): unknown => {
        return {
          imageUri: ((value: string): unknown => {
            return value;
          })(value["imageUri"]),
          name: ((value: string): unknown => {
            return value;
          })(value["name"]),
          type: ((value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "DOCEBO_CATALOG" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" | "VIVA_LEARNING" | "ORACLE"): unknown => {
            return value;
          })(value["type"]),
        };
      })(value["provider"]),
      syncedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toISO();
      })(value["syncedAt"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorListView(str: string): ConnectorListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          active: boolean;
          connectorId: bigint;
          name: string;
          provider: ConnectorProviderMetadata;
          syncedAt: DateTime<true> | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "connectorId")) {
      return { success: false };
    }
    const parsed_connectorId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["connectorId"]);
    })();
    if (!parsed_connectorId.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "provider")) {
      return { success: false };
    }
    const parsed_provider = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorProviderMetadata } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                imageUri: string;
                name: string;
                type: ConnectorType;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "imageUri")) {
            return { success: false };
          }
          const parsed_imageUri = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              try {
                new URL(value);
              } catch (ex) {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["imageUri"]);
          })();
          if (!parsed_imageUri.success) {
            return { success: false };
          }
          if (!hasProp(value, "name")) {
            return { success: false };
          }
          const parsed_name = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["name"]);
          })();
          if (!parsed_name.success) {
            return { success: false };
          }
          if (!hasProp(value, "type")) {
            return { success: false };
          }
          const parsed_type = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ConnectorType } => {
              return ((value: unknown): { success: false } | { success: true; value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "DOCEBO_CATALOG" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" | "VIVA_LEARNING" | "ORACLE" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "SUCCESS_FACTORS":
                    return { success: true, value: "SUCCESS_FACTORS" };
                  case "DEGREED":
                    return { success: true, value: "DEGREED" };
                  case "DOCEBO":
                    return { success: true, value: "DOCEBO" };
                  case "DOCEBO_CATALOG":
                    return { success: true, value: "DOCEBO_CATALOG" };
                  case "CSOD":
                    return { success: true, value: "CSOD" };
                  case "SUM_TOTAL":
                    return { success: true, value: "SUM_TOTAL" };
                  case "EDCAST":
                    return { success: true, value: "EDCAST" };
                  case "WORKDAY":
                    return { success: true, value: "WORKDAY" };
                  case "VIVA_LEARNING":
                    return { success: true, value: "VIVA_LEARNING" };
                  case "ORACLE":
                    return { success: true, value: "ORACLE" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["type"]);
          })();
          if (!parsed_type.success) {
            return { success: false };
          }
          return { success: true, value: { imageUri: parsed_imageUri.value, name: parsed_name.value, type: parsed_type.value } };
        })(value);
      })(value["provider"]);
    })();
    if (!parsed_provider.success) {
      return { success: false };
    }
    if (!hasProp(value, "syncedAt")) {
      return { success: false };
    }
    const parsed_syncedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["syncedAt"]);
    })();
    if (!parsed_syncedAt.success) {
      return { success: false };
    }
    return { success: true, value: { active: parsed_active.value, connectorId: parsed_connectorId.value, name: parsed_name.value, provider: parsed_provider.value, syncedAt: parsed_syncedAt.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorProduct
export function serialize_ConnectorProduct(obj: ConnectorProduct): string {
  const serialized = ((value: "BUSINESS_BOOKS" | "ARTICLES_AND_REPORTS" | "VIDEOS_AND_PODCASTS" | "ACTIONABLES"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorProduct(str: string): ConnectorProduct | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "BUSINESS_BOOKS" | "ARTICLES_AND_REPORTS" | "VIDEOS_AND_PODCASTS" | "ACTIONABLES" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "BUSINESS_BOOKS":
        return { success: true, value: "BUSINESS_BOOKS" };
      case "ARTICLES_AND_REPORTS":
        return { success: true, value: "ARTICLES_AND_REPORTS" };
      case "VIDEOS_AND_PODCASTS":
        return { success: true, value: "VIDEOS_AND_PODCASTS" };
      case "ACTIONABLES":
        return { success: true, value: "ACTIONABLES" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorProviderMetadata
export function serialize_ConnectorProviderMetadata(obj: ConnectorProviderMetadata): string {
  const serialized = ((value: { imageUri: string; name: string; type: ConnectorType }): unknown => {
    return {
      imageUri: ((value: string): unknown => {
        return value;
      })(value["imageUri"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      type: ((value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "DOCEBO_CATALOG" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" | "VIVA_LEARNING" | "ORACLE"): unknown => {
        return value;
      })(value["type"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorProviderMetadata(str: string): ConnectorProviderMetadata | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          imageUri: string;
          name: string;
          type: ConnectorType;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "imageUri")) {
      return { success: false };
    }
    const parsed_imageUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        try {
          new URL(value);
        } catch (ex) {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["imageUri"]);
    })();
    if (!parsed_imageUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "type")) {
      return { success: false };
    }
    const parsed_type = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorType } => {
        return ((value: unknown): { success: false } | { success: true; value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "DOCEBO_CATALOG" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" | "VIVA_LEARNING" | "ORACLE" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "SUCCESS_FACTORS":
              return { success: true, value: "SUCCESS_FACTORS" };
            case "DEGREED":
              return { success: true, value: "DEGREED" };
            case "DOCEBO":
              return { success: true, value: "DOCEBO" };
            case "DOCEBO_CATALOG":
              return { success: true, value: "DOCEBO_CATALOG" };
            case "CSOD":
              return { success: true, value: "CSOD" };
            case "SUM_TOTAL":
              return { success: true, value: "SUM_TOTAL" };
            case "EDCAST":
              return { success: true, value: "EDCAST" };
            case "WORKDAY":
              return { success: true, value: "WORKDAY" };
            case "VIVA_LEARNING":
              return { success: true, value: "VIVA_LEARNING" };
            case "ORACLE":
              return { success: true, value: "ORACLE" };
            default:
              return { success: false };
          }
        })(value);
      })(value["type"]);
    })();
    if (!parsed_type.success) {
      return { success: false };
    }
    return { success: true, value: { imageUri: parsed_imageUri.value, name: parsed_name.value, type: parsed_type.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorRealTimeCompletionInfo
export function serialize_ConnectorRealTimeCompletionInfo(obj: ConnectorRealTimeCompletionInfo): string {
  const serialized = ((value: { completedAt: DateTime<true>; contentId: string; corporateId: number; customerId: number; duration: bigint; progress: number }): unknown => {
    return {
      completedAt: ((value: DateTime<true>): unknown => {
        return value.toISO();
      })(value["completedAt"]),
      contentId: ((value: string): unknown => {
        return value;
      })(value["contentId"]),
      corporateId: ((value: number): unknown => {
        return value;
      })(value["corporateId"]),
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
      duration: ((value: bigint): unknown => {
        return value;
      })(value["duration"]),
      progress: ((value: number): unknown => {
        return value;
      })(value["progress"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorRealTimeCompletionInfo(str: string): ConnectorRealTimeCompletionInfo | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          completedAt: DateTime<true>;
          contentId: string;
          corporateId: number;
          customerId: number;
          duration: bigint;
          progress: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "completedAt")) {
      return { success: false };
    }
    const parsed_completedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["completedAt"]);
    })();
    if (!parsed_completedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentId")) {
      return { success: false };
    }
    const parsed_contentId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["contentId"]);
    })();
    if (!parsed_contentId.success) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "duration")) {
      return { success: false };
    }
    const parsed_duration = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["duration"]);
    })();
    if (!parsed_duration.success) {
      return { success: false };
    }
    if (!hasProp(value, "progress")) {
      return { success: false };
    }
    const parsed_progress = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["progress"]);
    })();
    if (!parsed_progress.success) {
      return { success: false };
    }
    return {
      success: true,
      value: { completedAt: parsed_completedAt.value, contentId: parsed_contentId.value, corporateId: parsed_corporateId.value, customerId: parsed_customerId.value, duration: parsed_duration.value, progress: parsed_progress.value },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorSettingsForm
export function serialize_ConnectorSettingsForm(obj: ConnectorSettingsForm): string {
  const serialized = ((value: {
    accountId: string | null;
    apiKey: string | null;
    clientId: string;
    clientSecret: string;
    doceboCatalogCode: string | null;
    doceboCategoryCode: string | null;
    doceboChannelId: bigint | null;
    grantType: ("CLIENT_CREDENTIALS" | "PASSWORD") | null;
    lmsApiUri: string | null;
    lmsBaseUri: string;
    lmsTokenUri: string | null;
    oauth2Flow: Oauth2Flow;
    password: string | null;
    scope: string | null;
    sfCompanyId: string | null;
    userId: string | null;
    userIdentifier: ConnectorUserIdentifier;
    username: string | null;
    workdayCategoryFallback: string | null;
    xapiActorId: XApiActorId;
  }): unknown => {
    return {
      accountId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["accountId"]),
      apiKey: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["apiKey"]),
      clientId: ((value: string): unknown => {
        return value;
      })(value["clientId"]),
      clientSecret: ((value: string): unknown => {
        return value;
      })(value["clientSecret"]),
      doceboCatalogCode: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["doceboCatalogCode"]),
      doceboCategoryCode: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["doceboCategoryCode"]),
      doceboChannelId: ((value: bigint | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["doceboChannelId"]),
      grantType: ((value: ("CLIENT_CREDENTIALS" | "PASSWORD") | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["grantType"]),
      lmsApiUri: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lmsApiUri"]),
      lmsBaseUri: ((value: string): unknown => {
        return value;
      })(value["lmsBaseUri"]),
      lmsTokenUri: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lmsTokenUri"]),
      oauth2Flow: ((value: "BASIC" | "OAUTH2"): unknown => {
        return value;
      })(value["oauth2Flow"]),
      password: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["password"]),
      scope: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["scope"]),
      sfCompanyId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["sfCompanyId"]),
      userId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["userId"]),
      userIdentifier: ((value: "NAME_ID" | "EMAIL" | "EXTRA_FIELD_1" | "EXTRA_FIELD_2" | "EXTRA_FIELD_3"): unknown => {
        return value;
      })(value["userIdentifier"]),
      username: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["username"]),
      workdayCategoryFallback: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["workdayCategoryFallback"]),
      xapiActorId: ((value: "MBOX" | "ACCOUNT" | "OPENID"): unknown => {
        return value;
      })(value["xapiActorId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorSettingsForm(str: string): ConnectorSettingsForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          accountId: string | null;
          apiKey: string | null;
          clientId: string;
          clientSecret: string;
          doceboCatalogCode: string | null;
          doceboCategoryCode: string | null;
          doceboChannelId: bigint | null;
          grantType: ("CLIENT_CREDENTIALS" | "PASSWORD") | null;
          lmsApiUri: string | null;
          lmsBaseUri: string;
          lmsTokenUri: string | null;
          oauth2Flow: Oauth2Flow;
          password: string | null;
          scope: string | null;
          sfCompanyId: string | null;
          userId: string | null;
          userIdentifier: ConnectorUserIdentifier;
          username: string | null;
          workdayCategoryFallback: string | null;
          xapiActorId: XApiActorId;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "accountId")) {
      return { success: false };
    }
    const parsed_accountId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["accountId"]);
    })();
    if (!parsed_accountId.success) {
      return { success: false };
    }
    if (!hasProp(value, "apiKey")) {
      return { success: false };
    }
    const parsed_apiKey = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["apiKey"]);
    })();
    if (!parsed_apiKey.success) {
      return { success: false };
    }
    if (!hasProp(value, "clientId")) {
      return { success: false };
    }
    const parsed_clientId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["clientId"]);
    })();
    if (!parsed_clientId.success) {
      return { success: false };
    }
    if (!hasProp(value, "clientSecret")) {
      return { success: false };
    }
    const parsed_clientSecret = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["clientSecret"]);
    })();
    if (!parsed_clientSecret.success) {
      return { success: false };
    }
    if (!hasProp(value, "doceboCatalogCode")) {
      return { success: false };
    }
    const parsed_doceboCatalogCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["doceboCatalogCode"]);
    })();
    if (!parsed_doceboCatalogCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "doceboCategoryCode")) {
      return { success: false };
    }
    const parsed_doceboCategoryCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["doceboCategoryCode"]);
    })();
    if (!parsed_doceboCategoryCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "doceboChannelId")) {
      return { success: false };
    }
    const parsed_doceboChannelId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["doceboChannelId"]);
    })();
    if (!parsed_doceboChannelId.success) {
      return { success: false };
    }
    if (!hasProp(value, "grantType")) {
      return { success: false };
    }
    const parsed_grantType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ("CLIENT_CREDENTIALS" | "PASSWORD") | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "CLIENT_CREDENTIALS":
            return { success: true, value: "CLIENT_CREDENTIALS" };
          case "PASSWORD":
            return { success: true, value: "PASSWORD" };
          default:
            return { success: false };
        }
      })(value["grantType"]);
    })();
    if (!parsed_grantType.success) {
      return { success: false };
    }
    if (!hasProp(value, "lmsApiUri")) {
      return { success: false };
    }
    const parsed_lmsApiUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lmsApiUri"]);
    })();
    if (!parsed_lmsApiUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "lmsBaseUri")) {
      return { success: false };
    }
    const parsed_lmsBaseUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lmsBaseUri"]);
    })();
    if (!parsed_lmsBaseUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "lmsTokenUri")) {
      return { success: false };
    }
    const parsed_lmsTokenUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lmsTokenUri"]);
    })();
    if (!parsed_lmsTokenUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "oauth2Flow")) {
      return { success: false };
    }
    const parsed_oauth2Flow = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Oauth2Flow } => {
        return ((value: unknown): { success: false } | { success: true; value: "BASIC" | "OAUTH2" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "BASIC":
              return { success: true, value: "BASIC" };
            case "OAUTH2":
              return { success: true, value: "OAUTH2" };
            default:
              return { success: false };
          }
        })(value);
      })(value["oauth2Flow"]);
    })();
    if (!parsed_oauth2Flow.success) {
      return { success: false };
    }
    if (!hasProp(value, "password")) {
      return { success: false };
    }
    const parsed_password = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["password"]);
    })();
    if (!parsed_password.success) {
      return { success: false };
    }
    if (!hasProp(value, "scope")) {
      return { success: false };
    }
    const parsed_scope = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["scope"]);
    })();
    if (!parsed_scope.success) {
      return { success: false };
    }
    if (!hasProp(value, "sfCompanyId")) {
      return { success: false };
    }
    const parsed_sfCompanyId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["sfCompanyId"]);
    })();
    if (!parsed_sfCompanyId.success) {
      return { success: false };
    }
    if (!hasProp(value, "userId")) {
      return { success: false };
    }
    const parsed_userId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["userId"]);
    })();
    if (!parsed_userId.success) {
      return { success: false };
    }
    if (!hasProp(value, "userIdentifier")) {
      return { success: false };
    }
    const parsed_userIdentifier = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorUserIdentifier } => {
        return ((value: unknown): { success: false } | { success: true; value: "NAME_ID" | "EMAIL" | "EXTRA_FIELD_1" | "EXTRA_FIELD_2" | "EXTRA_FIELD_3" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "NAME_ID":
              return { success: true, value: "NAME_ID" };
            case "EMAIL":
              return { success: true, value: "EMAIL" };
            case "EXTRA_FIELD_1":
              return { success: true, value: "EXTRA_FIELD_1" };
            case "EXTRA_FIELD_2":
              return { success: true, value: "EXTRA_FIELD_2" };
            case "EXTRA_FIELD_3":
              return { success: true, value: "EXTRA_FIELD_3" };
            default:
              return { success: false };
          }
        })(value);
      })(value["userIdentifier"]);
    })();
    if (!parsed_userIdentifier.success) {
      return { success: false };
    }
    if (!hasProp(value, "username")) {
      return { success: false };
    }
    const parsed_username = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["username"]);
    })();
    if (!parsed_username.success) {
      return { success: false };
    }
    if (!hasProp(value, "workdayCategoryFallback")) {
      return { success: false };
    }
    const parsed_workdayCategoryFallback = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["workdayCategoryFallback"]);
    })();
    if (!parsed_workdayCategoryFallback.success) {
      return { success: false };
    }
    if (!hasProp(value, "xapiActorId")) {
      return { success: false };
    }
    const parsed_xapiActorId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: XApiActorId } => {
        return ((value: unknown): { success: false } | { success: true; value: "MBOX" | "ACCOUNT" | "OPENID" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "MBOX":
              return { success: true, value: "MBOX" };
            case "ACCOUNT":
              return { success: true, value: "ACCOUNT" };
            case "OPENID":
              return { success: true, value: "OPENID" };
            default:
              return { success: false };
          }
        })(value);
      })(value["xapiActorId"]);
    })();
    if (!parsed_xapiActorId.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        accountId: parsed_accountId.value,
        apiKey: parsed_apiKey.value,
        clientId: parsed_clientId.value,
        clientSecret: parsed_clientSecret.value,
        doceboCatalogCode: parsed_doceboCatalogCode.value,
        doceboCategoryCode: parsed_doceboCategoryCode.value,
        doceboChannelId: parsed_doceboChannelId.value,
        grantType: parsed_grantType.value,
        lmsApiUri: parsed_lmsApiUri.value,
        lmsBaseUri: parsed_lmsBaseUri.value,
        lmsTokenUri: parsed_lmsTokenUri.value,
        oauth2Flow: parsed_oauth2Flow.value,
        password: parsed_password.value,
        scope: parsed_scope.value,
        sfCompanyId: parsed_sfCompanyId.value,
        userId: parsed_userId.value,
        userIdentifier: parsed_userIdentifier.value,
        username: parsed_username.value,
        workdayCategoryFallback: parsed_workdayCategoryFallback.value,
        xapiActorId: parsed_xapiActorId.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorSettingsView
export function serialize_ConnectorSettingsView(obj: ConnectorSettingsView): string {
  const serialized = ((value: {
    accountId: string | null;
    apiKey: string | null;
    clientId: string;
    clientSecret: string;
    doceboCatalogCode: string | null;
    doceboCategoryCode: string | null;
    doceboChannelId: bigint | null;
    grantType: ConnectorGrantType;
    lmsApiUri: string | null;
    lmsBaseUri: string;
    lmsTokenUri: string | null;
    oauth2Flow: Oauth2Flow;
    password: string | null;
    scope: string | null;
    sfCompanyId: string | null;
    userId: string | null;
    userIdentifier: ConnectorUserIdentifier;
    username: string | null;
    workdayCategoryFallback: string | null;
    xapiActorId: XApiActorId;
  }): unknown => {
    return {
      accountId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["accountId"]),
      apiKey: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["apiKey"]),
      clientId: ((value: string): unknown => {
        return value;
      })(value["clientId"]),
      clientSecret: ((value: string): unknown => {
        return value;
      })(value["clientSecret"]),
      doceboCatalogCode: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["doceboCatalogCode"]),
      doceboCategoryCode: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["doceboCategoryCode"]),
      doceboChannelId: ((value: bigint | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["doceboChannelId"]),
      grantType: ((value: "CLIENT_CREDENTIALS" | "PASSWORD"): unknown => {
        return value;
      })(value["grantType"]),
      lmsApiUri: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lmsApiUri"]),
      lmsBaseUri: ((value: string): unknown => {
        return value;
      })(value["lmsBaseUri"]),
      lmsTokenUri: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lmsTokenUri"]),
      oauth2Flow: ((value: "BASIC" | "OAUTH2"): unknown => {
        return value;
      })(value["oauth2Flow"]),
      password: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["password"]),
      scope: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["scope"]),
      sfCompanyId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["sfCompanyId"]),
      userId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["userId"]),
      userIdentifier: ((value: "NAME_ID" | "EMAIL" | "EXTRA_FIELD_1" | "EXTRA_FIELD_2" | "EXTRA_FIELD_3"): unknown => {
        return value;
      })(value["userIdentifier"]),
      username: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["username"]),
      workdayCategoryFallback: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["workdayCategoryFallback"]),
      xapiActorId: ((value: "MBOX" | "ACCOUNT" | "OPENID"): unknown => {
        return value;
      })(value["xapiActorId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorSettingsView(str: string): ConnectorSettingsView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          accountId: string | null;
          apiKey: string | null;
          clientId: string;
          clientSecret: string;
          doceboCatalogCode: string | null;
          doceboCategoryCode: string | null;
          doceboChannelId: bigint | null;
          grantType: ConnectorGrantType;
          lmsApiUri: string | null;
          lmsBaseUri: string;
          lmsTokenUri: string | null;
          oauth2Flow: Oauth2Flow;
          password: string | null;
          scope: string | null;
          sfCompanyId: string | null;
          userId: string | null;
          userIdentifier: ConnectorUserIdentifier;
          username: string | null;
          workdayCategoryFallback: string | null;
          xapiActorId: XApiActorId;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "accountId")) {
      return { success: false };
    }
    const parsed_accountId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["accountId"]);
    })();
    if (!parsed_accountId.success) {
      return { success: false };
    }
    if (!hasProp(value, "apiKey")) {
      return { success: false };
    }
    const parsed_apiKey = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["apiKey"]);
    })();
    if (!parsed_apiKey.success) {
      return { success: false };
    }
    if (!hasProp(value, "clientId")) {
      return { success: false };
    }
    const parsed_clientId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["clientId"]);
    })();
    if (!parsed_clientId.success) {
      return { success: false };
    }
    if (!hasProp(value, "clientSecret")) {
      return { success: false };
    }
    const parsed_clientSecret = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["clientSecret"]);
    })();
    if (!parsed_clientSecret.success) {
      return { success: false };
    }
    if (!hasProp(value, "doceboCatalogCode")) {
      return { success: false };
    }
    const parsed_doceboCatalogCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["doceboCatalogCode"]);
    })();
    if (!parsed_doceboCatalogCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "doceboCategoryCode")) {
      return { success: false };
    }
    const parsed_doceboCategoryCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["doceboCategoryCode"]);
    })();
    if (!parsed_doceboCategoryCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "doceboChannelId")) {
      return { success: false };
    }
    const parsed_doceboChannelId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["doceboChannelId"]);
    })();
    if (!parsed_doceboChannelId.success) {
      return { success: false };
    }
    if (!hasProp(value, "grantType")) {
      return { success: false };
    }
    const parsed_grantType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorGrantType } => {
        return ((value: unknown): { success: false } | { success: true; value: "CLIENT_CREDENTIALS" | "PASSWORD" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "CLIENT_CREDENTIALS":
              return { success: true, value: "CLIENT_CREDENTIALS" };
            case "PASSWORD":
              return { success: true, value: "PASSWORD" };
            default:
              return { success: false };
          }
        })(value);
      })(value["grantType"]);
    })();
    if (!parsed_grantType.success) {
      return { success: false };
    }
    if (!hasProp(value, "lmsApiUri")) {
      return { success: false };
    }
    const parsed_lmsApiUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lmsApiUri"]);
    })();
    if (!parsed_lmsApiUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "lmsBaseUri")) {
      return { success: false };
    }
    const parsed_lmsBaseUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lmsBaseUri"]);
    })();
    if (!parsed_lmsBaseUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "lmsTokenUri")) {
      return { success: false };
    }
    const parsed_lmsTokenUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lmsTokenUri"]);
    })();
    if (!parsed_lmsTokenUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "oauth2Flow")) {
      return { success: false };
    }
    const parsed_oauth2Flow = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Oauth2Flow } => {
        return ((value: unknown): { success: false } | { success: true; value: "BASIC" | "OAUTH2" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "BASIC":
              return { success: true, value: "BASIC" };
            case "OAUTH2":
              return { success: true, value: "OAUTH2" };
            default:
              return { success: false };
          }
        })(value);
      })(value["oauth2Flow"]);
    })();
    if (!parsed_oauth2Flow.success) {
      return { success: false };
    }
    if (!hasProp(value, "password")) {
      return { success: false };
    }
    const parsed_password = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["password"]);
    })();
    if (!parsed_password.success) {
      return { success: false };
    }
    if (!hasProp(value, "scope")) {
      return { success: false };
    }
    const parsed_scope = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["scope"]);
    })();
    if (!parsed_scope.success) {
      return { success: false };
    }
    if (!hasProp(value, "sfCompanyId")) {
      return { success: false };
    }
    const parsed_sfCompanyId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["sfCompanyId"]);
    })();
    if (!parsed_sfCompanyId.success) {
      return { success: false };
    }
    if (!hasProp(value, "userId")) {
      return { success: false };
    }
    const parsed_userId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["userId"]);
    })();
    if (!parsed_userId.success) {
      return { success: false };
    }
    if (!hasProp(value, "userIdentifier")) {
      return { success: false };
    }
    const parsed_userIdentifier = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorUserIdentifier } => {
        return ((value: unknown): { success: false } | { success: true; value: "NAME_ID" | "EMAIL" | "EXTRA_FIELD_1" | "EXTRA_FIELD_2" | "EXTRA_FIELD_3" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "NAME_ID":
              return { success: true, value: "NAME_ID" };
            case "EMAIL":
              return { success: true, value: "EMAIL" };
            case "EXTRA_FIELD_1":
              return { success: true, value: "EXTRA_FIELD_1" };
            case "EXTRA_FIELD_2":
              return { success: true, value: "EXTRA_FIELD_2" };
            case "EXTRA_FIELD_3":
              return { success: true, value: "EXTRA_FIELD_3" };
            default:
              return { success: false };
          }
        })(value);
      })(value["userIdentifier"]);
    })();
    if (!parsed_userIdentifier.success) {
      return { success: false };
    }
    if (!hasProp(value, "username")) {
      return { success: false };
    }
    const parsed_username = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["username"]);
    })();
    if (!parsed_username.success) {
      return { success: false };
    }
    if (!hasProp(value, "workdayCategoryFallback")) {
      return { success: false };
    }
    const parsed_workdayCategoryFallback = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["workdayCategoryFallback"]);
    })();
    if (!parsed_workdayCategoryFallback.success) {
      return { success: false };
    }
    if (!hasProp(value, "xapiActorId")) {
      return { success: false };
    }
    const parsed_xapiActorId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: XApiActorId } => {
        return ((value: unknown): { success: false } | { success: true; value: "MBOX" | "ACCOUNT" | "OPENID" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "MBOX":
              return { success: true, value: "MBOX" };
            case "ACCOUNT":
              return { success: true, value: "ACCOUNT" };
            case "OPENID":
              return { success: true, value: "OPENID" };
            default:
              return { success: false };
          }
        })(value);
      })(value["xapiActorId"]);
    })();
    if (!parsed_xapiActorId.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        accountId: parsed_accountId.value,
        apiKey: parsed_apiKey.value,
        clientId: parsed_clientId.value,
        clientSecret: parsed_clientSecret.value,
        doceboCatalogCode: parsed_doceboCatalogCode.value,
        doceboCategoryCode: parsed_doceboCategoryCode.value,
        doceboChannelId: parsed_doceboChannelId.value,
        grantType: parsed_grantType.value,
        lmsApiUri: parsed_lmsApiUri.value,
        lmsBaseUri: parsed_lmsBaseUri.value,
        lmsTokenUri: parsed_lmsTokenUri.value,
        oauth2Flow: parsed_oauth2Flow.value,
        password: parsed_password.value,
        scope: parsed_scope.value,
        sfCompanyId: parsed_sfCompanyId.value,
        userId: parsed_userId.value,
        userIdentifier: parsed_userIdentifier.value,
        username: parsed_username.value,
        workdayCategoryFallback: parsed_workdayCategoryFallback.value,
        xapiActorId: parsed_xapiActorId.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorSyncEntry
export function serialize_ConnectorSyncEntry(obj: ConnectorSyncEntry): string {
  const serialized = ((value: { connectorId: bigint; corporateId: number; type: ConnectorType }): unknown => {
    return {
      connectorId: ((value: bigint): unknown => {
        return value;
      })(value["connectorId"]),
      corporateId: ((value: number): unknown => {
        return value;
      })(value["corporateId"]),
      type: ((value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "DOCEBO_CATALOG" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" | "VIVA_LEARNING" | "ORACLE"): unknown => {
        return value;
      })(value["type"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorSyncEntry(str: string): ConnectorSyncEntry | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          connectorId: bigint;
          corporateId: number;
          type: ConnectorType;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "connectorId")) {
      return { success: false };
    }
    const parsed_connectorId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["connectorId"]);
    })();
    if (!parsed_connectorId.success) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "type")) {
      return { success: false };
    }
    const parsed_type = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorType } => {
        return ((value: unknown): { success: false } | { success: true; value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "DOCEBO_CATALOG" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" | "VIVA_LEARNING" | "ORACLE" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "SUCCESS_FACTORS":
              return { success: true, value: "SUCCESS_FACTORS" };
            case "DEGREED":
              return { success: true, value: "DEGREED" };
            case "DOCEBO":
              return { success: true, value: "DOCEBO" };
            case "DOCEBO_CATALOG":
              return { success: true, value: "DOCEBO_CATALOG" };
            case "CSOD":
              return { success: true, value: "CSOD" };
            case "SUM_TOTAL":
              return { success: true, value: "SUM_TOTAL" };
            case "EDCAST":
              return { success: true, value: "EDCAST" };
            case "WORKDAY":
              return { success: true, value: "WORKDAY" };
            case "VIVA_LEARNING":
              return { success: true, value: "VIVA_LEARNING" };
            case "ORACLE":
              return { success: true, value: "ORACLE" };
            default:
              return { success: false };
          }
        })(value);
      })(value["type"]);
    })();
    if (!parsed_type.success) {
      return { success: false };
    }
    return { success: true, value: { connectorId: parsed_connectorId.value, corporateId: parsed_corporateId.value, type: parsed_type.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorSyncForm
export function serialize_ConnectorSyncForm(obj: ConnectorSyncForm): string {
  const serialized = ((value: { actionableIds: bigint[]; dataIds: number[] }): unknown => {
    return {
      actionableIds: ((value: bigint[]): unknown => {
        return value.map((value: bigint): unknown => {
          return value;
        });
      })(value["actionableIds"]),
      dataIds: ((value: number[]): unknown => {
        return value.map((value: number): unknown => {
          return value;
        });
      })(value["dataIds"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorSyncForm(str: string): ConnectorSyncForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          actionableIds: bigint[];
          dataIds: number[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "actionableIds")) {
      return { success: false };
    }
    const parsed_actionableIds = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: bigint } => {
          if (typeof value === "number") {
            return { success: true, value: BigInt(value) };
          }
          if (typeof value === "bigint") {
            return { success: true, value: value };
          }
          return { success: false };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["actionableIds"]);
    })();
    if (!parsed_actionableIds.success) {
      return { success: false };
    }
    if (!hasProp(value, "dataIds")) {
      return { success: false };
    }
    const parsed_dataIds = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
          if (typeof value === "number") {
            return { success: true, value: value };
          }
          return { success: false };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["dataIds"]);
    })();
    if (!parsed_dataIds.success) {
      return { success: false };
    }
    return { success: true, value: { actionableIds: parsed_actionableIds.value, dataIds: parsed_dataIds.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorType
export function serialize_ConnectorType(obj: ConnectorType): string {
  const serialized = ((value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "DOCEBO_CATALOG" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" | "VIVA_LEARNING" | "ORACLE"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorType(str: string): ConnectorType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "DOCEBO_CATALOG" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" | "VIVA_LEARNING" | "ORACLE" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "SUCCESS_FACTORS":
        return { success: true, value: "SUCCESS_FACTORS" };
      case "DEGREED":
        return { success: true, value: "DEGREED" };
      case "DOCEBO":
        return { success: true, value: "DOCEBO" };
      case "DOCEBO_CATALOG":
        return { success: true, value: "DOCEBO_CATALOG" };
      case "CSOD":
        return { success: true, value: "CSOD" };
      case "SUM_TOTAL":
        return { success: true, value: "SUM_TOTAL" };
      case "EDCAST":
        return { success: true, value: "EDCAST" };
      case "WORKDAY":
        return { success: true, value: "WORKDAY" };
      case "VIVA_LEARNING":
        return { success: true, value: "VIVA_LEARNING" };
      case "ORACLE":
        return { success: true, value: "ORACLE" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorUserIdentifier
export function serialize_ConnectorUserIdentifier(obj: ConnectorUserIdentifier): string {
  const serialized = ((value: "NAME_ID" | "EMAIL" | "EXTRA_FIELD_1" | "EXTRA_FIELD_2" | "EXTRA_FIELD_3"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorUserIdentifier(str: string): ConnectorUserIdentifier | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "NAME_ID" | "EMAIL" | "EXTRA_FIELD_1" | "EXTRA_FIELD_2" | "EXTRA_FIELD_3" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "NAME_ID":
        return { success: true, value: "NAME_ID" };
      case "EMAIL":
        return { success: true, value: "EMAIL" };
      case "EXTRA_FIELD_1":
        return { success: true, value: "EXTRA_FIELD_1" };
      case "EXTRA_FIELD_2":
        return { success: true, value: "EXTRA_FIELD_2" };
      case "EXTRA_FIELD_3":
        return { success: true, value: "EXTRA_FIELD_3" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ConnectorView
export function serialize_ConnectorView(obj: ConnectorView): string {
  const serialized = ((value: {
    connectorId: bigint;
    languages: Language[];
    name: string;
    products: ConnectorProduct[];
    settings: ConnectorSettingsView;
    syncCompletions: boolean;
    syncContent: boolean;
    syncStartedCompletions: boolean;
    type: ConnectorType;
  }): unknown => {
    return {
      connectorId: ((value: bigint): unknown => {
        return value;
      })(value["connectorId"]),
      languages: ((value: Language[]): unknown => {
        return value.map((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
          return value;
        });
      })(value["languages"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      products: ((value: ConnectorProduct[]): unknown => {
        return value.map((value: "BUSINESS_BOOKS" | "ARTICLES_AND_REPORTS" | "VIDEOS_AND_PODCASTS" | "ACTIONABLES"): unknown => {
          return value;
        });
      })(value["products"]),
      settings: ((value: {
        accountId: string | null;
        apiKey: string | null;
        clientId: string;
        clientSecret: string;
        doceboCatalogCode: string | null;
        doceboCategoryCode: string | null;
        doceboChannelId: bigint | null;
        grantType: ConnectorGrantType;
        lmsApiUri: string | null;
        lmsBaseUri: string;
        lmsTokenUri: string | null;
        oauth2Flow: Oauth2Flow;
        password: string | null;
        scope: string | null;
        sfCompanyId: string | null;
        userId: string | null;
        userIdentifier: ConnectorUserIdentifier;
        username: string | null;
        workdayCategoryFallback: string | null;
        xapiActorId: XApiActorId;
      }): unknown => {
        return {
          accountId: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["accountId"]),
          apiKey: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["apiKey"]),
          clientId: ((value: string): unknown => {
            return value;
          })(value["clientId"]),
          clientSecret: ((value: string): unknown => {
            return value;
          })(value["clientSecret"]),
          doceboCatalogCode: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["doceboCatalogCode"]),
          doceboCategoryCode: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["doceboCategoryCode"]),
          doceboChannelId: ((value: bigint | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["doceboChannelId"]),
          grantType: ((value: "CLIENT_CREDENTIALS" | "PASSWORD"): unknown => {
            return value;
          })(value["grantType"]),
          lmsApiUri: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["lmsApiUri"]),
          lmsBaseUri: ((value: string): unknown => {
            return value;
          })(value["lmsBaseUri"]),
          lmsTokenUri: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["lmsTokenUri"]),
          oauth2Flow: ((value: "BASIC" | "OAUTH2"): unknown => {
            return value;
          })(value["oauth2Flow"]),
          password: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["password"]),
          scope: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["scope"]),
          sfCompanyId: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["sfCompanyId"]),
          userId: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["userId"]),
          userIdentifier: ((value: "NAME_ID" | "EMAIL" | "EXTRA_FIELD_1" | "EXTRA_FIELD_2" | "EXTRA_FIELD_3"): unknown => {
            return value;
          })(value["userIdentifier"]),
          username: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["username"]),
          workdayCategoryFallback: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["workdayCategoryFallback"]),
          xapiActorId: ((value: "MBOX" | "ACCOUNT" | "OPENID"): unknown => {
            return value;
          })(value["xapiActorId"]),
        };
      })(value["settings"]),
      syncCompletions: ((value: boolean): unknown => {
        return value;
      })(value["syncCompletions"]),
      syncContent: ((value: boolean): unknown => {
        return value;
      })(value["syncContent"]),
      syncStartedCompletions: ((value: boolean): unknown => {
        return value;
      })(value["syncStartedCompletions"]),
      type: ((value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "DOCEBO_CATALOG" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" | "VIVA_LEARNING" | "ORACLE"): unknown => {
        return value;
      })(value["type"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ConnectorView(str: string): ConnectorView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          connectorId: bigint;
          languages: Language[];
          name: string;
          products: ConnectorProduct[];
          settings: ConnectorSettingsView;
          syncCompletions: boolean;
          syncContent: boolean;
          syncStartedCompletions: boolean;
          type: ConnectorType;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "connectorId")) {
      return { success: false };
    }
    const parsed_connectorId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["connectorId"]);
    })();
    if (!parsed_connectorId.success) {
      return { success: false };
    }
    if (!hasProp(value, "languages")) {
      return { success: false };
    }
    const parsed_languages = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Language } => {
          return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            switch (value) {
              case "en":
                return { success: true, value: "en" };
              case "de":
                return { success: true, value: "de" };
              case "es":
                return { success: true, value: "es" };
              case "ru":
                return { success: true, value: "ru" };
              case "zh":
                return { success: true, value: "zh" };
              case "pt":
                return { success: true, value: "pt" };
              case "fr":
                return { success: true, value: "fr" };
              default:
                return { success: false };
            }
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["languages"]);
    })();
    if (!parsed_languages.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "products")) {
      return { success: false };
    }
    const parsed_products = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorProduct[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ConnectorProduct } => {
          return ((value: unknown): { success: false } | { success: true; value: "BUSINESS_BOOKS" | "ARTICLES_AND_REPORTS" | "VIDEOS_AND_PODCASTS" | "ACTIONABLES" } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            switch (value) {
              case "BUSINESS_BOOKS":
                return { success: true, value: "BUSINESS_BOOKS" };
              case "ARTICLES_AND_REPORTS":
                return { success: true, value: "ARTICLES_AND_REPORTS" };
              case "VIDEOS_AND_PODCASTS":
                return { success: true, value: "VIDEOS_AND_PODCASTS" };
              case "ACTIONABLES":
                return { success: true, value: "ACTIONABLES" };
              default:
                return { success: false };
            }
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["products"]);
    })();
    if (!parsed_products.success) {
      return { success: false };
    }
    if (!hasProp(value, "settings")) {
      return { success: false };
    }
    const parsed_settings = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorSettingsView } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                accountId: string | null;
                apiKey: string | null;
                clientId: string;
                clientSecret: string;
                doceboCatalogCode: string | null;
                doceboCategoryCode: string | null;
                doceboChannelId: bigint | null;
                grantType: ConnectorGrantType;
                lmsApiUri: string | null;
                lmsBaseUri: string;
                lmsTokenUri: string | null;
                oauth2Flow: Oauth2Flow;
                password: string | null;
                scope: string | null;
                sfCompanyId: string | null;
                userId: string | null;
                userIdentifier: ConnectorUserIdentifier;
                username: string | null;
                workdayCategoryFallback: string | null;
                xapiActorId: XApiActorId;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "accountId")) {
            return { success: false };
          }
          const parsed_accountId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["accountId"]);
          })();
          if (!parsed_accountId.success) {
            return { success: false };
          }
          if (!hasProp(value, "apiKey")) {
            return { success: false };
          }
          const parsed_apiKey = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["apiKey"]);
          })();
          if (!parsed_apiKey.success) {
            return { success: false };
          }
          if (!hasProp(value, "clientId")) {
            return { success: false };
          }
          const parsed_clientId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["clientId"]);
          })();
          if (!parsed_clientId.success) {
            return { success: false };
          }
          if (!hasProp(value, "clientSecret")) {
            return { success: false };
          }
          const parsed_clientSecret = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["clientSecret"]);
          })();
          if (!parsed_clientSecret.success) {
            return { success: false };
          }
          if (!hasProp(value, "doceboCatalogCode")) {
            return { success: false };
          }
          const parsed_doceboCatalogCode = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["doceboCatalogCode"]);
          })();
          if (!parsed_doceboCatalogCode.success) {
            return { success: false };
          }
          if (!hasProp(value, "doceboCategoryCode")) {
            return { success: false };
          }
          const parsed_doceboCategoryCode = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["doceboCategoryCode"]);
          })();
          if (!parsed_doceboCategoryCode.success) {
            return { success: false };
          }
          if (!hasProp(value, "doceboChannelId")) {
            return { success: false };
          }
          const parsed_doceboChannelId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: bigint | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value === "number") {
                return { success: true, value: BigInt(value) };
              }
              if (typeof value === "bigint") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["doceboChannelId"]);
          })();
          if (!parsed_doceboChannelId.success) {
            return { success: false };
          }
          if (!hasProp(value, "grantType")) {
            return { success: false };
          }
          const parsed_grantType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ConnectorGrantType } => {
              return ((value: unknown): { success: false } | { success: true; value: "CLIENT_CREDENTIALS" | "PASSWORD" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "CLIENT_CREDENTIALS":
                    return { success: true, value: "CLIENT_CREDENTIALS" };
                  case "PASSWORD":
                    return { success: true, value: "PASSWORD" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["grantType"]);
          })();
          if (!parsed_grantType.success) {
            return { success: false };
          }
          if (!hasProp(value, "lmsApiUri")) {
            return { success: false };
          }
          const parsed_lmsApiUri = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lmsApiUri"]);
          })();
          if (!parsed_lmsApiUri.success) {
            return { success: false };
          }
          if (!hasProp(value, "lmsBaseUri")) {
            return { success: false };
          }
          const parsed_lmsBaseUri = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lmsBaseUri"]);
          })();
          if (!parsed_lmsBaseUri.success) {
            return { success: false };
          }
          if (!hasProp(value, "lmsTokenUri")) {
            return { success: false };
          }
          const parsed_lmsTokenUri = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lmsTokenUri"]);
          })();
          if (!parsed_lmsTokenUri.success) {
            return { success: false };
          }
          if (!hasProp(value, "oauth2Flow")) {
            return { success: false };
          }
          const parsed_oauth2Flow = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Oauth2Flow } => {
              return ((value: unknown): { success: false } | { success: true; value: "BASIC" | "OAUTH2" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "BASIC":
                    return { success: true, value: "BASIC" };
                  case "OAUTH2":
                    return { success: true, value: "OAUTH2" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["oauth2Flow"]);
          })();
          if (!parsed_oauth2Flow.success) {
            return { success: false };
          }
          if (!hasProp(value, "password")) {
            return { success: false };
          }
          const parsed_password = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["password"]);
          })();
          if (!parsed_password.success) {
            return { success: false };
          }
          if (!hasProp(value, "scope")) {
            return { success: false };
          }
          const parsed_scope = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["scope"]);
          })();
          if (!parsed_scope.success) {
            return { success: false };
          }
          if (!hasProp(value, "sfCompanyId")) {
            return { success: false };
          }
          const parsed_sfCompanyId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["sfCompanyId"]);
          })();
          if (!parsed_sfCompanyId.success) {
            return { success: false };
          }
          if (!hasProp(value, "userId")) {
            return { success: false };
          }
          const parsed_userId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["userId"]);
          })();
          if (!parsed_userId.success) {
            return { success: false };
          }
          if (!hasProp(value, "userIdentifier")) {
            return { success: false };
          }
          const parsed_userIdentifier = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ConnectorUserIdentifier } => {
              return ((value: unknown): { success: false } | { success: true; value: "NAME_ID" | "EMAIL" | "EXTRA_FIELD_1" | "EXTRA_FIELD_2" | "EXTRA_FIELD_3" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "NAME_ID":
                    return { success: true, value: "NAME_ID" };
                  case "EMAIL":
                    return { success: true, value: "EMAIL" };
                  case "EXTRA_FIELD_1":
                    return { success: true, value: "EXTRA_FIELD_1" };
                  case "EXTRA_FIELD_2":
                    return { success: true, value: "EXTRA_FIELD_2" };
                  case "EXTRA_FIELD_3":
                    return { success: true, value: "EXTRA_FIELD_3" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["userIdentifier"]);
          })();
          if (!parsed_userIdentifier.success) {
            return { success: false };
          }
          if (!hasProp(value, "username")) {
            return { success: false };
          }
          const parsed_username = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["username"]);
          })();
          if (!parsed_username.success) {
            return { success: false };
          }
          if (!hasProp(value, "workdayCategoryFallback")) {
            return { success: false };
          }
          const parsed_workdayCategoryFallback = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["workdayCategoryFallback"]);
          })();
          if (!parsed_workdayCategoryFallback.success) {
            return { success: false };
          }
          if (!hasProp(value, "xapiActorId")) {
            return { success: false };
          }
          const parsed_xapiActorId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: XApiActorId } => {
              return ((value: unknown): { success: false } | { success: true; value: "MBOX" | "ACCOUNT" | "OPENID" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "MBOX":
                    return { success: true, value: "MBOX" };
                  case "ACCOUNT":
                    return { success: true, value: "ACCOUNT" };
                  case "OPENID":
                    return { success: true, value: "OPENID" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["xapiActorId"]);
          })();
          if (!parsed_xapiActorId.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              accountId: parsed_accountId.value,
              apiKey: parsed_apiKey.value,
              clientId: parsed_clientId.value,
              clientSecret: parsed_clientSecret.value,
              doceboCatalogCode: parsed_doceboCatalogCode.value,
              doceboCategoryCode: parsed_doceboCategoryCode.value,
              doceboChannelId: parsed_doceboChannelId.value,
              grantType: parsed_grantType.value,
              lmsApiUri: parsed_lmsApiUri.value,
              lmsBaseUri: parsed_lmsBaseUri.value,
              lmsTokenUri: parsed_lmsTokenUri.value,
              oauth2Flow: parsed_oauth2Flow.value,
              password: parsed_password.value,
              scope: parsed_scope.value,
              sfCompanyId: parsed_sfCompanyId.value,
              userId: parsed_userId.value,
              userIdentifier: parsed_userIdentifier.value,
              username: parsed_username.value,
              workdayCategoryFallback: parsed_workdayCategoryFallback.value,
              xapiActorId: parsed_xapiActorId.value,
            },
          };
        })(value);
      })(value["settings"]);
    })();
    if (!parsed_settings.success) {
      return { success: false };
    }
    if (!hasProp(value, "syncCompletions")) {
      return { success: false };
    }
    const parsed_syncCompletions = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["syncCompletions"]);
    })();
    if (!parsed_syncCompletions.success) {
      return { success: false };
    }
    if (!hasProp(value, "syncContent")) {
      return { success: false };
    }
    const parsed_syncContent = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["syncContent"]);
    })();
    if (!parsed_syncContent.success) {
      return { success: false };
    }
    if (!hasProp(value, "syncStartedCompletions")) {
      return { success: false };
    }
    const parsed_syncStartedCompletions = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["syncStartedCompletions"]);
    })();
    if (!parsed_syncStartedCompletions.success) {
      return { success: false };
    }
    if (!hasProp(value, "type")) {
      return { success: false };
    }
    const parsed_type = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorType } => {
        return ((value: unknown): { success: false } | { success: true; value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "DOCEBO_CATALOG" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" | "VIVA_LEARNING" | "ORACLE" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "SUCCESS_FACTORS":
              return { success: true, value: "SUCCESS_FACTORS" };
            case "DEGREED":
              return { success: true, value: "DEGREED" };
            case "DOCEBO":
              return { success: true, value: "DOCEBO" };
            case "DOCEBO_CATALOG":
              return { success: true, value: "DOCEBO_CATALOG" };
            case "CSOD":
              return { success: true, value: "CSOD" };
            case "SUM_TOTAL":
              return { success: true, value: "SUM_TOTAL" };
            case "EDCAST":
              return { success: true, value: "EDCAST" };
            case "WORKDAY":
              return { success: true, value: "WORKDAY" };
            case "VIVA_LEARNING":
              return { success: true, value: "VIVA_LEARNING" };
            case "ORACLE":
              return { success: true, value: "ORACLE" };
            default:
              return { success: false };
          }
        })(value);
      })(value["type"]);
    })();
    if (!parsed_type.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        connectorId: parsed_connectorId.value,
        languages: parsed_languages.value,
        name: parsed_name.value,
        products: parsed_products.value,
        settings: parsed_settings.value,
        syncCompletions: parsed_syncCompletions.value,
        syncContent: parsed_syncContent.value,
        syncStartedCompletions: parsed_syncStartedCompletions.value,
        type: parsed_type.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ContentCardDemoProps
export function serialize_ContentCardDemoProps(obj: ContentCardDemoProps): string {
  const serialized = ((value: { actionables: ContentMetadata[]; miabs: ContentMetadata[]; sketchnotes: ContentMetadata[]; summaries: ContentMetadata[] }): unknown => {
    return {
      actionables: ((value: ContentMetadata[]): unknown => {
        return value.map(
          (
            value:
              | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
              | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
              | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
              | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number },
          ): unknown => {
            switch (value.contentType) {
              case "SUMMARY": {
                return {
                  contentType: "SUMMARY",
                  backgroundColor: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["backgroundColor"]),
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "SUMMARY"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "SUMMARY"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                  documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
                    return value;
                  })(value["documentType"]),
                  meetingInABoxAvailable: ((value: boolean): unknown => {
                    return value;
                  })(value["meetingInABoxAvailable"]),
                  sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
                    return value;
                  })(value["sourceType"]),
                };
              }
              case "ACTIONABLE": {
                return {
                  contentType: "ACTIONABLE",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "ACTIONABLE"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "ACTIONABLE"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                };
              }
              case "SKETCHNOTE": {
                return {
                  contentType: "SKETCHNOTE",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "SKETCHNOTE"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "SKETCHNOTE"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                };
              }
              case "MIAB": {
                return {
                  contentType: "MIAB",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "MIAB"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "MIAB"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                  customerId: ((value: number | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["customerId"]),
                  dataId: ((value: number): unknown => {
                    return value;
                  })(value["dataId"]),
                };
              }
            }
          },
        );
      })(value["actionables"]),
      miabs: ((value: ContentMetadata[]): unknown => {
        return value.map(
          (
            value:
              | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
              | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
              | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
              | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number },
          ): unknown => {
            switch (value.contentType) {
              case "SUMMARY": {
                return {
                  contentType: "SUMMARY",
                  backgroundColor: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["backgroundColor"]),
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "SUMMARY"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "SUMMARY"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                  documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
                    return value;
                  })(value["documentType"]),
                  meetingInABoxAvailable: ((value: boolean): unknown => {
                    return value;
                  })(value["meetingInABoxAvailable"]),
                  sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
                    return value;
                  })(value["sourceType"]),
                };
              }
              case "ACTIONABLE": {
                return {
                  contentType: "ACTIONABLE",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "ACTIONABLE"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "ACTIONABLE"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                };
              }
              case "SKETCHNOTE": {
                return {
                  contentType: "SKETCHNOTE",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "SKETCHNOTE"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "SKETCHNOTE"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                };
              }
              case "MIAB": {
                return {
                  contentType: "MIAB",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "MIAB"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "MIAB"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                  customerId: ((value: number | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["customerId"]),
                  dataId: ((value: number): unknown => {
                    return value;
                  })(value["dataId"]),
                };
              }
            }
          },
        );
      })(value["miabs"]),
      sketchnotes: ((value: ContentMetadata[]): unknown => {
        return value.map(
          (
            value:
              | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
              | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
              | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
              | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number },
          ): unknown => {
            switch (value.contentType) {
              case "SUMMARY": {
                return {
                  contentType: "SUMMARY",
                  backgroundColor: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["backgroundColor"]),
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "SUMMARY"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "SUMMARY"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                  documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
                    return value;
                  })(value["documentType"]),
                  meetingInABoxAvailable: ((value: boolean): unknown => {
                    return value;
                  })(value["meetingInABoxAvailable"]),
                  sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
                    return value;
                  })(value["sourceType"]),
                };
              }
              case "ACTIONABLE": {
                return {
                  contentType: "ACTIONABLE",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "ACTIONABLE"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "ACTIONABLE"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                };
              }
              case "SKETCHNOTE": {
                return {
                  contentType: "SKETCHNOTE",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "SKETCHNOTE"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "SKETCHNOTE"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                };
              }
              case "MIAB": {
                return {
                  contentType: "MIAB",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "MIAB"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "MIAB"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                  customerId: ((value: number | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["customerId"]),
                  dataId: ((value: number): unknown => {
                    return value;
                  })(value["dataId"]),
                };
              }
            }
          },
        );
      })(value["sketchnotes"]),
      summaries: ((value: ContentMetadata[]): unknown => {
        return value.map(
          (
            value:
              | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
              | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
              | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
              | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number },
          ): unknown => {
            switch (value.contentType) {
              case "SUMMARY": {
                return {
                  contentType: "SUMMARY",
                  backgroundColor: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["backgroundColor"]),
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "SUMMARY"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "SUMMARY"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                  documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
                    return value;
                  })(value["documentType"]),
                  meetingInABoxAvailable: ((value: boolean): unknown => {
                    return value;
                  })(value["meetingInABoxAvailable"]),
                  sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
                    return value;
                  })(value["sourceType"]),
                };
              }
              case "ACTIONABLE": {
                return {
                  contentType: "ACTIONABLE",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "ACTIONABLE"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "ACTIONABLE"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                };
              }
              case "SKETCHNOTE": {
                return {
                  contentType: "SKETCHNOTE",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "SKETCHNOTE"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "SKETCHNOTE"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                };
              }
              case "MIAB": {
                return {
                  contentType: "MIAB",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "MIAB"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "MIAB"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                  customerId: ((value: number | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["customerId"]),
                  dataId: ((value: number): unknown => {
                    return value;
                  })(value["dataId"]),
                };
              }
            }
          },
        );
      })(value["summaries"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ContentCardDemoProps(str: string): ContentCardDemoProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          actionables: ContentMetadata[];
          miabs: ContentMetadata[];
          sketchnotes: ContentMetadata[];
          summaries: ContentMetadata[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "actionables")) {
      return { success: false };
    }
    const parsed_actionables = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ContentMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ContentMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
                  | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
                  | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
                  | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
                    | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
                    | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
                    | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "contentType")) {
                return { success: false };
              }
              if (typeof value["contentType"] != "string") {
                return { success: false };
              }
              const discriminator = value["contentType"];
              switch (discriminator) {
                case "SUMMARY": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          backgroundColor: string | null;
                          common: ContentMetadataCommon;
                          contentId: SummaryId;
                          documentType: SummaryDocumentType;
                          meetingInABoxAvailable: boolean;
                          sourceType: SummarySourceType;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "backgroundColor")) {
                      return { success: false };
                    }
                    const parsed_backgroundColor = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["backgroundColor"]);
                    })();
                    if (!parsed_backgroundColor.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummaryId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "SUMMARY";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "SUMMARY":
                                  return { success: true, value: "SUMMARY" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "documentType")) {
                      return { success: false };
                    }
                    const parsed_documentType = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "ABSTRACT":
                              return { success: true, value: "ABSTRACT" };
                            case "REVIEW":
                              return { success: true, value: "REVIEW" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["documentType"]);
                    })();
                    if (!parsed_documentType.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "meetingInABoxAvailable")) {
                      return { success: false };
                    }
                    const parsed_meetingInABoxAvailable = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["meetingInABoxAvailable"]);
                    })();
                    if (!parsed_meetingInABoxAvailable.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "sourceType")) {
                      return { success: false };
                    }
                    const parsed_sourceType = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "BOOK":
                              return { success: true, value: "BOOK" };
                            case "VIDEO":
                              return { success: true, value: "VIDEO" };
                            case "ARTICLE":
                              return { success: true, value: "ARTICLE" };
                            case "CHAPTER":
                              return { success: true, value: "CHAPTER" };
                            case "REPORT":
                              return { success: true, value: "REPORT" };
                            case "PODCAST":
                              return { success: true, value: "PODCAST" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["sourceType"]);
                    })();
                    if (!parsed_sourceType.success) {
                      return { success: false };
                    }
                    return {
                      success: true,
                      value: {
                        backgroundColor: parsed_backgroundColor.value,
                        common: parsed_common.value,
                        contentId: parsed_contentId.value,
                        documentType: parsed_documentType.value,
                        meetingInABoxAvailable: parsed_meetingInABoxAvailable.value,
                        sourceType: parsed_sourceType.value,
                      },
                    };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "SUMMARY" as const, ...parsedVariant.value } };
                }
                case "ACTIONABLE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: ActionableId;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ActionableId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "ACTIONABLE";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "ACTIONABLE":
                                  return { success: true, value: "ACTIONABLE" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "ACTIONABLE" as const, ...parsedVariant.value } };
                }
                case "SKETCHNOTE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: LgxpId;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: LgxpId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "SKETCHNOTE";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "SKETCHNOTE" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "SKETCHNOTE":
                                  return { success: true, value: "SKETCHNOTE" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "SKETCHNOTE" as const, ...parsedVariant.value } };
                }
                case "MIAB": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: MiabId;
                          customerId: number | null;
                          dataId: number;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: MiabId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "MIAB";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "MIAB" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "MIAB":
                                  return { success: true, value: "MIAB" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "customerId")) {
                      return { success: false };
                    }
                    const parsed_customerId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["customerId"]);
                    })();
                    if (!parsed_customerId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "dataId")) {
                      return { success: false };
                    }
                    const parsed_dataId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["dataId"]);
                    })();
                    if (!parsed_dataId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value, customerId: parsed_customerId.value, dataId: parsed_dataId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "MIAB" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["actionables"]);
    })();
    if (!parsed_actionables.success) {
      return { success: false };
    }
    if (!hasProp(value, "miabs")) {
      return { success: false };
    }
    const parsed_miabs = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ContentMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ContentMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
                  | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
                  | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
                  | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
                    | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
                    | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
                    | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "contentType")) {
                return { success: false };
              }
              if (typeof value["contentType"] != "string") {
                return { success: false };
              }
              const discriminator = value["contentType"];
              switch (discriminator) {
                case "SUMMARY": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          backgroundColor: string | null;
                          common: ContentMetadataCommon;
                          contentId: SummaryId;
                          documentType: SummaryDocumentType;
                          meetingInABoxAvailable: boolean;
                          sourceType: SummarySourceType;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "backgroundColor")) {
                      return { success: false };
                    }
                    const parsed_backgroundColor = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["backgroundColor"]);
                    })();
                    if (!parsed_backgroundColor.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummaryId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "SUMMARY";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "SUMMARY":
                                  return { success: true, value: "SUMMARY" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "documentType")) {
                      return { success: false };
                    }
                    const parsed_documentType = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "ABSTRACT":
                              return { success: true, value: "ABSTRACT" };
                            case "REVIEW":
                              return { success: true, value: "REVIEW" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["documentType"]);
                    })();
                    if (!parsed_documentType.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "meetingInABoxAvailable")) {
                      return { success: false };
                    }
                    const parsed_meetingInABoxAvailable = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["meetingInABoxAvailable"]);
                    })();
                    if (!parsed_meetingInABoxAvailable.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "sourceType")) {
                      return { success: false };
                    }
                    const parsed_sourceType = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "BOOK":
                              return { success: true, value: "BOOK" };
                            case "VIDEO":
                              return { success: true, value: "VIDEO" };
                            case "ARTICLE":
                              return { success: true, value: "ARTICLE" };
                            case "CHAPTER":
                              return { success: true, value: "CHAPTER" };
                            case "REPORT":
                              return { success: true, value: "REPORT" };
                            case "PODCAST":
                              return { success: true, value: "PODCAST" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["sourceType"]);
                    })();
                    if (!parsed_sourceType.success) {
                      return { success: false };
                    }
                    return {
                      success: true,
                      value: {
                        backgroundColor: parsed_backgroundColor.value,
                        common: parsed_common.value,
                        contentId: parsed_contentId.value,
                        documentType: parsed_documentType.value,
                        meetingInABoxAvailable: parsed_meetingInABoxAvailable.value,
                        sourceType: parsed_sourceType.value,
                      },
                    };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "SUMMARY" as const, ...parsedVariant.value } };
                }
                case "ACTIONABLE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: ActionableId;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ActionableId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "ACTIONABLE";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "ACTIONABLE":
                                  return { success: true, value: "ACTIONABLE" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "ACTIONABLE" as const, ...parsedVariant.value } };
                }
                case "SKETCHNOTE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: LgxpId;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: LgxpId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "SKETCHNOTE";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "SKETCHNOTE" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "SKETCHNOTE":
                                  return { success: true, value: "SKETCHNOTE" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "SKETCHNOTE" as const, ...parsedVariant.value } };
                }
                case "MIAB": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: MiabId;
                          customerId: number | null;
                          dataId: number;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: MiabId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "MIAB";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "MIAB" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "MIAB":
                                  return { success: true, value: "MIAB" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "customerId")) {
                      return { success: false };
                    }
                    const parsed_customerId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["customerId"]);
                    })();
                    if (!parsed_customerId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "dataId")) {
                      return { success: false };
                    }
                    const parsed_dataId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["dataId"]);
                    })();
                    if (!parsed_dataId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value, customerId: parsed_customerId.value, dataId: parsed_dataId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "MIAB" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["miabs"]);
    })();
    if (!parsed_miabs.success) {
      return { success: false };
    }
    if (!hasProp(value, "sketchnotes")) {
      return { success: false };
    }
    const parsed_sketchnotes = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ContentMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ContentMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
                  | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
                  | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
                  | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
                    | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
                    | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
                    | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "contentType")) {
                return { success: false };
              }
              if (typeof value["contentType"] != "string") {
                return { success: false };
              }
              const discriminator = value["contentType"];
              switch (discriminator) {
                case "SUMMARY": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          backgroundColor: string | null;
                          common: ContentMetadataCommon;
                          contentId: SummaryId;
                          documentType: SummaryDocumentType;
                          meetingInABoxAvailable: boolean;
                          sourceType: SummarySourceType;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "backgroundColor")) {
                      return { success: false };
                    }
                    const parsed_backgroundColor = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["backgroundColor"]);
                    })();
                    if (!parsed_backgroundColor.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummaryId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "SUMMARY";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "SUMMARY":
                                  return { success: true, value: "SUMMARY" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "documentType")) {
                      return { success: false };
                    }
                    const parsed_documentType = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "ABSTRACT":
                              return { success: true, value: "ABSTRACT" };
                            case "REVIEW":
                              return { success: true, value: "REVIEW" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["documentType"]);
                    })();
                    if (!parsed_documentType.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "meetingInABoxAvailable")) {
                      return { success: false };
                    }
                    const parsed_meetingInABoxAvailable = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["meetingInABoxAvailable"]);
                    })();
                    if (!parsed_meetingInABoxAvailable.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "sourceType")) {
                      return { success: false };
                    }
                    const parsed_sourceType = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "BOOK":
                              return { success: true, value: "BOOK" };
                            case "VIDEO":
                              return { success: true, value: "VIDEO" };
                            case "ARTICLE":
                              return { success: true, value: "ARTICLE" };
                            case "CHAPTER":
                              return { success: true, value: "CHAPTER" };
                            case "REPORT":
                              return { success: true, value: "REPORT" };
                            case "PODCAST":
                              return { success: true, value: "PODCAST" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["sourceType"]);
                    })();
                    if (!parsed_sourceType.success) {
                      return { success: false };
                    }
                    return {
                      success: true,
                      value: {
                        backgroundColor: parsed_backgroundColor.value,
                        common: parsed_common.value,
                        contentId: parsed_contentId.value,
                        documentType: parsed_documentType.value,
                        meetingInABoxAvailable: parsed_meetingInABoxAvailable.value,
                        sourceType: parsed_sourceType.value,
                      },
                    };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "SUMMARY" as const, ...parsedVariant.value } };
                }
                case "ACTIONABLE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: ActionableId;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ActionableId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "ACTIONABLE";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "ACTIONABLE":
                                  return { success: true, value: "ACTIONABLE" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "ACTIONABLE" as const, ...parsedVariant.value } };
                }
                case "SKETCHNOTE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: LgxpId;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: LgxpId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "SKETCHNOTE";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "SKETCHNOTE" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "SKETCHNOTE":
                                  return { success: true, value: "SKETCHNOTE" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "SKETCHNOTE" as const, ...parsedVariant.value } };
                }
                case "MIAB": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: MiabId;
                          customerId: number | null;
                          dataId: number;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: MiabId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "MIAB";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "MIAB" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "MIAB":
                                  return { success: true, value: "MIAB" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "customerId")) {
                      return { success: false };
                    }
                    const parsed_customerId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["customerId"]);
                    })();
                    if (!parsed_customerId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "dataId")) {
                      return { success: false };
                    }
                    const parsed_dataId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["dataId"]);
                    })();
                    if (!parsed_dataId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value, customerId: parsed_customerId.value, dataId: parsed_dataId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "MIAB" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["sketchnotes"]);
    })();
    if (!parsed_sketchnotes.success) {
      return { success: false };
    }
    if (!hasProp(value, "summaries")) {
      return { success: false };
    }
    const parsed_summaries = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ContentMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ContentMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
                  | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
                  | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
                  | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
                    | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
                    | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
                    | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "contentType")) {
                return { success: false };
              }
              if (typeof value["contentType"] != "string") {
                return { success: false };
              }
              const discriminator = value["contentType"];
              switch (discriminator) {
                case "SUMMARY": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          backgroundColor: string | null;
                          common: ContentMetadataCommon;
                          contentId: SummaryId;
                          documentType: SummaryDocumentType;
                          meetingInABoxAvailable: boolean;
                          sourceType: SummarySourceType;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "backgroundColor")) {
                      return { success: false };
                    }
                    const parsed_backgroundColor = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["backgroundColor"]);
                    })();
                    if (!parsed_backgroundColor.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummaryId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "SUMMARY";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "SUMMARY":
                                  return { success: true, value: "SUMMARY" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "documentType")) {
                      return { success: false };
                    }
                    const parsed_documentType = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "ABSTRACT":
                              return { success: true, value: "ABSTRACT" };
                            case "REVIEW":
                              return { success: true, value: "REVIEW" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["documentType"]);
                    })();
                    if (!parsed_documentType.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "meetingInABoxAvailable")) {
                      return { success: false };
                    }
                    const parsed_meetingInABoxAvailable = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["meetingInABoxAvailable"]);
                    })();
                    if (!parsed_meetingInABoxAvailable.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "sourceType")) {
                      return { success: false };
                    }
                    const parsed_sourceType = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "BOOK":
                              return { success: true, value: "BOOK" };
                            case "VIDEO":
                              return { success: true, value: "VIDEO" };
                            case "ARTICLE":
                              return { success: true, value: "ARTICLE" };
                            case "CHAPTER":
                              return { success: true, value: "CHAPTER" };
                            case "REPORT":
                              return { success: true, value: "REPORT" };
                            case "PODCAST":
                              return { success: true, value: "PODCAST" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["sourceType"]);
                    })();
                    if (!parsed_sourceType.success) {
                      return { success: false };
                    }
                    return {
                      success: true,
                      value: {
                        backgroundColor: parsed_backgroundColor.value,
                        common: parsed_common.value,
                        contentId: parsed_contentId.value,
                        documentType: parsed_documentType.value,
                        meetingInABoxAvailable: parsed_meetingInABoxAvailable.value,
                        sourceType: parsed_sourceType.value,
                      },
                    };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "SUMMARY" as const, ...parsedVariant.value } };
                }
                case "ACTIONABLE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: ActionableId;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ActionableId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "ACTIONABLE";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "ACTIONABLE":
                                  return { success: true, value: "ACTIONABLE" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "ACTIONABLE" as const, ...parsedVariant.value } };
                }
                case "SKETCHNOTE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: LgxpId;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: LgxpId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "SKETCHNOTE";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "SKETCHNOTE" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "SKETCHNOTE":
                                  return { success: true, value: "SKETCHNOTE" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "SKETCHNOTE" as const, ...parsedVariant.value } };
                }
                case "MIAB": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: MiabId;
                          customerId: number | null;
                          dataId: number;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: MiabId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "MIAB";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "MIAB" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "MIAB":
                                  return { success: true, value: "MIAB" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "customerId")) {
                      return { success: false };
                    }
                    const parsed_customerId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["customerId"]);
                    })();
                    if (!parsed_customerId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "dataId")) {
                      return { success: false };
                    }
                    const parsed_dataId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["dataId"]);
                    })();
                    if (!parsed_dataId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value, customerId: parsed_customerId.value, dataId: parsed_dataId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "MIAB" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["summaries"]);
    })();
    if (!parsed_summaries.success) {
      return { success: false };
    }
    return { success: true, value: { actionables: parsed_actionables.value, miabs: parsed_miabs.value, sketchnotes: parsed_sketchnotes.value, summaries: parsed_summaries.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ContentId
export function serialize_ContentId(obj: ContentId): string {
  const serialized = ((value: { contentType: "SUMMARY"; id: bigint } | { contentType: "ACTIONABLE"; id: bigint } | { contentType: "SKETCHNOTE"; id: bigint } | { contentType: "MIAB"; id: bigint }): unknown => {
    switch (value.contentType) {
      case "SUMMARY": {
        return {
          contentType: "SUMMARY",
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
        };
      }
      case "ACTIONABLE": {
        return {
          contentType: "ACTIONABLE",
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
        };
      }
      case "SKETCHNOTE": {
        return {
          contentType: "SKETCHNOTE",
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
        };
      }
      case "MIAB": {
        return {
          contentType: "MIAB",
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ContentId(str: string): ContentId | null {
  const parsed = ((
    value: unknown,
  ): { success: false } | { success: true; value: { contentType: "SUMMARY"; id: bigint } | { contentType: "ACTIONABLE"; id: bigint } | { contentType: "SKETCHNOTE"; id: bigint } | { contentType: "MIAB"; id: bigint } } => {
    return ((
      value: unknown,
    ): { success: false } | { success: true; value: { contentType: "SUMMARY"; id: bigint } | { contentType: "ACTIONABLE"; id: bigint } | { contentType: "SKETCHNOTE"; id: bigint } | { contentType: "MIAB"; id: bigint } } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "contentType")) {
        return { success: false };
      }
      if (typeof value["contentType"] != "string") {
        return { success: false };
      }
      const discriminator = value["contentType"];
      switch (discriminator) {
        case "SUMMARY": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: bigint;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { contentType: "SUMMARY" as const, ...parsedVariant.value } };
        }
        case "ACTIONABLE": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: bigint;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { contentType: "ACTIONABLE" as const, ...parsedVariant.value } };
        }
        case "SKETCHNOTE": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: bigint;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { contentType: "SKETCHNOTE" as const, ...parsedVariant.value } };
        }
        case "MIAB": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: bigint;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { contentType: "MIAB" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ContentIdList
export function serialize_ContentIdList(obj: ContentIdList): string {
  const serialized = ((value: { items: ContentId[] }): unknown => {
    return {
      items: ((value: ContentId[]): unknown => {
        return value.map((value: { contentType: "SUMMARY"; id: bigint } | { contentType: "ACTIONABLE"; id: bigint } | { contentType: "SKETCHNOTE"; id: bigint } | { contentType: "MIAB"; id: bigint }): unknown => {
          switch (value.contentType) {
            case "SUMMARY": {
              return {
                contentType: "SUMMARY",
                id: ((value: bigint): unknown => {
                  return value;
                })(value["id"]),
              };
            }
            case "ACTIONABLE": {
              return {
                contentType: "ACTIONABLE",
                id: ((value: bigint): unknown => {
                  return value;
                })(value["id"]),
              };
            }
            case "SKETCHNOTE": {
              return {
                contentType: "SKETCHNOTE",
                id: ((value: bigint): unknown => {
                  return value;
                })(value["id"]),
              };
            }
            case "MIAB": {
              return {
                contentType: "MIAB",
                id: ((value: bigint): unknown => {
                  return value;
                })(value["id"]),
              };
            }
          }
        });
      })(value["items"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ContentIdList(str: string): ContentIdList | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: ContentId[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ContentId[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ContentId } => {
          return ((
            value: unknown,
          ): { success: false } | { success: true; value: { contentType: "SUMMARY"; id: bigint } | { contentType: "ACTIONABLE"; id: bigint } | { contentType: "SKETCHNOTE"; id: bigint } | { contentType: "MIAB"; id: bigint } } => {
            return ((
              value: unknown,
            ): { success: false } | { success: true; value: { contentType: "SUMMARY"; id: bigint } | { contentType: "ACTIONABLE"; id: bigint } | { contentType: "SKETCHNOTE"; id: bigint } | { contentType: "MIAB"; id: bigint } } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "contentType")) {
                return { success: false };
              }
              if (typeof value["contentType"] != "string") {
                return { success: false };
              }
              const discriminator = value["contentType"];
              switch (discriminator) {
                case "SUMMARY": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "SUMMARY" as const, ...parsedVariant.value } };
                }
                case "ACTIONABLE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "ACTIONABLE" as const, ...parsedVariant.value } };
                }
                case "SKETCHNOTE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "SKETCHNOTE" as const, ...parsedVariant.value } };
                }
                case "MIAB": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "MIAB" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ContentItemType
export function serialize_ContentItemType(obj: ContentItemType): string {
  const serialized = ((value: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE" | "MIAB"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ContentItemType(str: string): ContentItemType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE" | "MIAB" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "UNKNOWN":
        return { success: true, value: "UNKNOWN" };
      case "SUMMARY":
        return { success: true, value: "SUMMARY" };
      case "CATEGORY":
        return { success: true, value: "CATEGORY" };
      case "CHANNEL":
        return { success: true, value: "CHANNEL" };
      case "LGXP":
        return { success: true, value: "LGXP" };
      case "ACTIONABLE":
        return { success: true, value: "ACTIONABLE" };
      case "MYLIST":
        return { success: true, value: "MYLIST" };
      case "CUSTOMPAGE":
        return { success: true, value: "CUSTOMPAGE" };
      case "MIAB":
        return { success: true, value: "MIAB" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ContentIxStatus
export function serialize_ContentIxStatus(obj: ContentIxStatus): string {
  const serialized = ((
    value:
      | { contentType: "SUMMARY"; bookmarkedAt: DateTime<true> | null; consumedAt: DateTime<true> | null; contentId: SummaryId; customerId: number; likedAt: DateTime<true> | null; readingProgress: number }
      | { contentType: "MIAB"; bookmarkedAt: DateTime<true> | null; contentId: MiabId; customerId: number; feedback: boolean | null; feedbackAt: DateTime<true> | null; modifiedAt: DateTime<true> }
      | { contentType: "ACTIONABLE"; bookmarkedAt: DateTime<true> | null; contentId: ActionableId; customerId: number },
  ): unknown => {
    switch (value.contentType) {
      case "SUMMARY": {
        return {
          contentType: "SUMMARY",
          bookmarkedAt: ((value: DateTime<true> | null): unknown => {
            if (value === null) {
              return null;
            }
            return value.toMillis();
          })(value["bookmarkedAt"]),
          consumedAt: ((value: DateTime<true> | null): unknown => {
            if (value === null) {
              return null;
            }
            return value.toMillis();
          })(value["consumedAt"]),
          contentId: ((value: { contentType: "SUMMARY"; id: bigint }): unknown => {
            return {
              contentType: ((value: "SUMMARY"): unknown => {
                return value;
              })(value["contentType"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
            };
          })(value["contentId"]),
          customerId: ((value: number): unknown => {
            return value;
          })(value["customerId"]),
          likedAt: ((value: DateTime<true> | null): unknown => {
            if (value === null) {
              return null;
            }
            return value.toMillis();
          })(value["likedAt"]),
          readingProgress: ((value: number): unknown => {
            return value;
          })(value["readingProgress"]),
        };
      }
      case "MIAB": {
        return {
          contentType: "MIAB",
          bookmarkedAt: ((value: DateTime<true> | null): unknown => {
            if (value === null) {
              return null;
            }
            return value.toMillis();
          })(value["bookmarkedAt"]),
          contentId: ((value: { contentType: "MIAB"; id: bigint }): unknown => {
            return {
              contentType: ((value: "MIAB"): unknown => {
                return value;
              })(value["contentType"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
            };
          })(value["contentId"]),
          customerId: ((value: number): unknown => {
            return value;
          })(value["customerId"]),
          feedback: ((value: boolean | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["feedback"]),
          feedbackAt: ((value: DateTime<true> | null): unknown => {
            if (value === null) {
              return null;
            }
            return value.toMillis();
          })(value["feedbackAt"]),
          modifiedAt: ((value: DateTime<true>): unknown => {
            return value.toMillis();
          })(value["modifiedAt"]),
        };
      }
      case "ACTIONABLE": {
        return {
          contentType: "ACTIONABLE",
          bookmarkedAt: ((value: DateTime<true> | null): unknown => {
            if (value === null) {
              return null;
            }
            return value.toMillis();
          })(value["bookmarkedAt"]),
          contentId: ((value: { contentType: "ACTIONABLE"; id: bigint }): unknown => {
            return {
              contentType: ((value: "ACTIONABLE"): unknown => {
                return value;
              })(value["contentType"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
            };
          })(value["contentId"]),
          customerId: ((value: number): unknown => {
            return value;
          })(value["customerId"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ContentIxStatus(str: string): ContentIxStatus | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value:
          | { contentType: "SUMMARY"; bookmarkedAt: DateTime<true> | null; consumedAt: DateTime<true> | null; contentId: SummaryId; customerId: number; likedAt: DateTime<true> | null; readingProgress: number }
          | { contentType: "MIAB"; bookmarkedAt: DateTime<true> | null; contentId: MiabId; customerId: number; feedback: boolean | null; feedbackAt: DateTime<true> | null; modifiedAt: DateTime<true> }
          | { contentType: "ACTIONABLE"; bookmarkedAt: DateTime<true> | null; contentId: ActionableId; customerId: number };
      } => {
    return ((
      value: unknown,
    ):
      | { success: false }
      | {
          success: true;
          value:
            | { contentType: "SUMMARY"; bookmarkedAt: DateTime<true> | null; consumedAt: DateTime<true> | null; contentId: SummaryId; customerId: number; likedAt: DateTime<true> | null; readingProgress: number }
            | { contentType: "MIAB"; bookmarkedAt: DateTime<true> | null; contentId: MiabId; customerId: number; feedback: boolean | null; feedbackAt: DateTime<true> | null; modifiedAt: DateTime<true> }
            | { contentType: "ACTIONABLE"; bookmarkedAt: DateTime<true> | null; contentId: ActionableId; customerId: number };
        } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "contentType")) {
        return { success: false };
      }
      if (typeof value["contentType"] != "string") {
        return { success: false };
      }
      const discriminator = value["contentType"];
      switch (discriminator) {
        case "SUMMARY": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  bookmarkedAt: DateTime<true> | null;
                  consumedAt: DateTime<true> | null;
                  contentId: SummaryId;
                  customerId: number;
                  likedAt: DateTime<true> | null;
                  readingProgress: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "bookmarkedAt")) {
              return { success: false };
            }
            const parsed_bookmarkedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["bookmarkedAt"]);
            })();
            if (!parsed_bookmarkedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "consumedAt")) {
              return { success: false };
            }
            const parsed_consumedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["consumedAt"]);
            })();
            if (!parsed_consumedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "contentId")) {
              return { success: false };
            }
            const parsed_contentId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: SummaryId } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        contentType: "SUMMARY";
                        id: bigint;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "contentType")) {
                    return { success: false };
                  }
                  const parsed_contentType = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      switch (value) {
                        case "SUMMARY":
                          return { success: true, value: "SUMMARY" };
                        default:
                          return { success: false };
                      }
                    })(value["contentType"]);
                  })();
                  if (!parsed_contentType.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                })(value);
              })(value["contentId"]);
            })();
            if (!parsed_contentId.success) {
              return { success: false };
            }
            if (!hasProp(value, "customerId")) {
              return { success: false };
            }
            const parsed_customerId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["customerId"]);
            })();
            if (!parsed_customerId.success) {
              return { success: false };
            }
            if (!hasProp(value, "likedAt")) {
              return { success: false };
            }
            const parsed_likedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["likedAt"]);
            })();
            if (!parsed_likedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "readingProgress")) {
              return { success: false };
            }
            const parsed_readingProgress = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: Number(value) };
                }
                return { success: false };
              })(value["readingProgress"]);
            })();
            if (!parsed_readingProgress.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                bookmarkedAt: parsed_bookmarkedAt.value,
                consumedAt: parsed_consumedAt.value,
                contentId: parsed_contentId.value,
                customerId: parsed_customerId.value,
                likedAt: parsed_likedAt.value,
                readingProgress: parsed_readingProgress.value,
              },
            };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { contentType: "SUMMARY" as const, ...parsedVariant.value } };
        }
        case "MIAB": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  bookmarkedAt: DateTime<true> | null;
                  contentId: MiabId;
                  customerId: number;
                  feedback: boolean | null;
                  feedbackAt: DateTime<true> | null;
                  modifiedAt: DateTime<true>;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "bookmarkedAt")) {
              return { success: false };
            }
            const parsed_bookmarkedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["bookmarkedAt"]);
            })();
            if (!parsed_bookmarkedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "contentId")) {
              return { success: false };
            }
            const parsed_contentId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: MiabId } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        contentType: "MIAB";
                        id: bigint;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "contentType")) {
                    return { success: false };
                  }
                  const parsed_contentType = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: "MIAB" } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      switch (value) {
                        case "MIAB":
                          return { success: true, value: "MIAB" };
                        default:
                          return { success: false };
                      }
                    })(value["contentType"]);
                  })();
                  if (!parsed_contentType.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                })(value);
              })(value["contentId"]);
            })();
            if (!parsed_contentId.success) {
              return { success: false };
            }
            if (!hasProp(value, "customerId")) {
              return { success: false };
            }
            const parsed_customerId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["customerId"]);
            })();
            if (!parsed_customerId.success) {
              return { success: false };
            }
            if (!hasProp(value, "feedback")) {
              return { success: false };
            }
            const parsed_feedback = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["feedback"]);
            })();
            if (!parsed_feedback.success) {
              return { success: false };
            }
            if (!hasProp(value, "feedbackAt")) {
              return { success: false };
            }
            const parsed_feedbackAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["feedbackAt"]);
            })();
            if (!parsed_feedbackAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "modifiedAt")) {
              return { success: false };
            }
            const parsed_modifiedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["modifiedAt"]);
            })();
            if (!parsed_modifiedAt.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                bookmarkedAt: parsed_bookmarkedAt.value,
                contentId: parsed_contentId.value,
                customerId: parsed_customerId.value,
                feedback: parsed_feedback.value,
                feedbackAt: parsed_feedbackAt.value,
                modifiedAt: parsed_modifiedAt.value,
              },
            };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { contentType: "MIAB" as const, ...parsedVariant.value } };
        }
        case "ACTIONABLE": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  bookmarkedAt: DateTime<true> | null;
                  contentId: ActionableId;
                  customerId: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "bookmarkedAt")) {
              return { success: false };
            }
            const parsed_bookmarkedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["bookmarkedAt"]);
            })();
            if (!parsed_bookmarkedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "contentId")) {
              return { success: false };
            }
            const parsed_contentId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ActionableId } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        contentType: "ACTIONABLE";
                        id: bigint;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "contentType")) {
                    return { success: false };
                  }
                  const parsed_contentType = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      switch (value) {
                        case "ACTIONABLE":
                          return { success: true, value: "ACTIONABLE" };
                        default:
                          return { success: false };
                      }
                    })(value["contentType"]);
                  })();
                  if (!parsed_contentType.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                })(value);
              })(value["contentId"]);
            })();
            if (!parsed_contentId.success) {
              return { success: false };
            }
            if (!hasProp(value, "customerId")) {
              return { success: false };
            }
            const parsed_customerId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["customerId"]);
            })();
            if (!parsed_customerId.success) {
              return { success: false };
            }
            return { success: true, value: { bookmarkedAt: parsed_bookmarkedAt.value, contentId: parsed_contentId.value, customerId: parsed_customerId.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { contentType: "ACTIONABLE" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ContentIxStatusActionable
export function serialize_ContentIxStatusActionable(obj: ContentIxStatusActionable): string {
  const serialized = ((value: { bookmarkedAt: DateTime<true> | null; contentId: ActionableId; contentType: "ACTIONABLE"; customerId: number }): unknown => {
    return {
      bookmarkedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["bookmarkedAt"]),
      contentId: ((value: { contentType: "ACTIONABLE"; id: bigint }): unknown => {
        return {
          contentType: ((value: "ACTIONABLE"): unknown => {
            return value;
          })(value["contentType"]),
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
        };
      })(value["contentId"]),
      contentType: ((value: "ACTIONABLE"): unknown => {
        return value;
      })(value["contentType"]),
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ContentIxStatusActionable(str: string): ContentIxStatusActionable | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          bookmarkedAt: DateTime<true> | null;
          contentId: ActionableId;
          contentType: "ACTIONABLE";
          customerId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "bookmarkedAt")) {
      return { success: false };
    }
    const parsed_bookmarkedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["bookmarkedAt"]);
    })();
    if (!parsed_bookmarkedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentId")) {
      return { success: false };
    }
    const parsed_contentId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ActionableId } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                contentType: "ACTIONABLE";
                id: bigint;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "contentType")) {
            return { success: false };
          }
          const parsed_contentType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              switch (value) {
                case "ACTIONABLE":
                  return { success: true, value: "ACTIONABLE" };
                default:
                  return { success: false };
              }
            })(value["contentType"]);
          })();
          if (!parsed_contentType.success) {
            return { success: false };
          }
          if (!hasProp(value, "id")) {
            return { success: false };
          }
          const parsed_id = (() => {
            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
              if (typeof value === "number") {
                return { success: true, value: BigInt(value) };
              }
              if (typeof value === "bigint") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["id"]);
          })();
          if (!parsed_id.success) {
            return { success: false };
          }
          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
        })(value);
      })(value["contentId"]);
    })();
    if (!parsed_contentId.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentType")) {
      return { success: false };
    }
    const parsed_contentType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "ACTIONABLE":
            return { success: true, value: "ACTIONABLE" };
          default:
            return { success: false };
        }
      })(value["contentType"]);
    })();
    if (!parsed_contentType.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    return { success: true, value: { bookmarkedAt: parsed_bookmarkedAt.value, contentId: parsed_contentId.value, contentType: parsed_contentType.value, customerId: parsed_customerId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ContentIxStatusList
export function serialize_ContentIxStatusList(obj: ContentIxStatusList): string {
  const serialized = ((value: { items: ContentIxStatus[] }): unknown => {
    return {
      items: ((value: ContentIxStatus[]): unknown => {
        return value.map(
          (
            value:
              | { contentType: "SUMMARY"; bookmarkedAt: DateTime<true> | null; consumedAt: DateTime<true> | null; contentId: SummaryId; customerId: number; likedAt: DateTime<true> | null; readingProgress: number }
              | { contentType: "MIAB"; bookmarkedAt: DateTime<true> | null; contentId: MiabId; customerId: number; feedback: boolean | null; feedbackAt: DateTime<true> | null; modifiedAt: DateTime<true> }
              | { contentType: "ACTIONABLE"; bookmarkedAt: DateTime<true> | null; contentId: ActionableId; customerId: number },
          ): unknown => {
            switch (value.contentType) {
              case "SUMMARY": {
                return {
                  contentType: "SUMMARY",
                  bookmarkedAt: ((value: DateTime<true> | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value.toMillis();
                  })(value["bookmarkedAt"]),
                  consumedAt: ((value: DateTime<true> | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value.toMillis();
                  })(value["consumedAt"]),
                  contentId: ((value: { contentType: "SUMMARY"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "SUMMARY"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                  customerId: ((value: number): unknown => {
                    return value;
                  })(value["customerId"]),
                  likedAt: ((value: DateTime<true> | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value.toMillis();
                  })(value["likedAt"]),
                  readingProgress: ((value: number): unknown => {
                    return value;
                  })(value["readingProgress"]),
                };
              }
              case "MIAB": {
                return {
                  contentType: "MIAB",
                  bookmarkedAt: ((value: DateTime<true> | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value.toMillis();
                  })(value["bookmarkedAt"]),
                  contentId: ((value: { contentType: "MIAB"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "MIAB"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                  customerId: ((value: number): unknown => {
                    return value;
                  })(value["customerId"]),
                  feedback: ((value: boolean | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["feedback"]),
                  feedbackAt: ((value: DateTime<true> | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value.toMillis();
                  })(value["feedbackAt"]),
                  modifiedAt: ((value: DateTime<true>): unknown => {
                    return value.toMillis();
                  })(value["modifiedAt"]),
                };
              }
              case "ACTIONABLE": {
                return {
                  contentType: "ACTIONABLE",
                  bookmarkedAt: ((value: DateTime<true> | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value.toMillis();
                  })(value["bookmarkedAt"]),
                  contentId: ((value: { contentType: "ACTIONABLE"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "ACTIONABLE"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                  customerId: ((value: number): unknown => {
                    return value;
                  })(value["customerId"]),
                };
              }
            }
          },
        );
      })(value["items"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ContentIxStatusList(str: string): ContentIxStatusList | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: ContentIxStatus[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ContentIxStatus[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ContentIxStatus } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { contentType: "SUMMARY"; bookmarkedAt: DateTime<true> | null; consumedAt: DateTime<true> | null; contentId: SummaryId; customerId: number; likedAt: DateTime<true> | null; readingProgress: number }
                  | { contentType: "MIAB"; bookmarkedAt: DateTime<true> | null; contentId: MiabId; customerId: number; feedback: boolean | null; feedbackAt: DateTime<true> | null; modifiedAt: DateTime<true> }
                  | { contentType: "ACTIONABLE"; bookmarkedAt: DateTime<true> | null; contentId: ActionableId; customerId: number };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { contentType: "SUMMARY"; bookmarkedAt: DateTime<true> | null; consumedAt: DateTime<true> | null; contentId: SummaryId; customerId: number; likedAt: DateTime<true> | null; readingProgress: number }
                    | { contentType: "MIAB"; bookmarkedAt: DateTime<true> | null; contentId: MiabId; customerId: number; feedback: boolean | null; feedbackAt: DateTime<true> | null; modifiedAt: DateTime<true> }
                    | { contentType: "ACTIONABLE"; bookmarkedAt: DateTime<true> | null; contentId: ActionableId; customerId: number };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "contentType")) {
                return { success: false };
              }
              if (typeof value["contentType"] != "string") {
                return { success: false };
              }
              const discriminator = value["contentType"];
              switch (discriminator) {
                case "SUMMARY": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          bookmarkedAt: DateTime<true> | null;
                          consumedAt: DateTime<true> | null;
                          contentId: SummaryId;
                          customerId: number;
                          likedAt: DateTime<true> | null;
                          readingProgress: number;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "bookmarkedAt")) {
                      return { success: false };
                    }
                    const parsed_bookmarkedAt = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "number") {
                          return { success: false };
                        }
                        const parsed = DateTime.fromMillis(value);
                        if (!parsed.isValid) {
                          return { success: false };
                        }
                        return { success: true, value: parsed };
                      })(value["bookmarkedAt"]);
                    })();
                    if (!parsed_bookmarkedAt.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "consumedAt")) {
                      return { success: false };
                    }
                    const parsed_consumedAt = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "number") {
                          return { success: false };
                        }
                        const parsed = DateTime.fromMillis(value);
                        if (!parsed.isValid) {
                          return { success: false };
                        }
                        return { success: true, value: parsed };
                      })(value["consumedAt"]);
                    })();
                    if (!parsed_consumedAt.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummaryId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "SUMMARY";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "SUMMARY":
                                  return { success: true, value: "SUMMARY" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "customerId")) {
                      return { success: false };
                    }
                    const parsed_customerId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["customerId"]);
                    })();
                    if (!parsed_customerId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "likedAt")) {
                      return { success: false };
                    }
                    const parsed_likedAt = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "number") {
                          return { success: false };
                        }
                        const parsed = DateTime.fromMillis(value);
                        if (!parsed.isValid) {
                          return { success: false };
                        }
                        return { success: true, value: parsed };
                      })(value["likedAt"]);
                    })();
                    if (!parsed_likedAt.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "readingProgress")) {
                      return { success: false };
                    }
                    const parsed_readingProgress = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: Number(value) };
                        }
                        return { success: false };
                      })(value["readingProgress"]);
                    })();
                    if (!parsed_readingProgress.success) {
                      return { success: false };
                    }
                    return {
                      success: true,
                      value: {
                        bookmarkedAt: parsed_bookmarkedAt.value,
                        consumedAt: parsed_consumedAt.value,
                        contentId: parsed_contentId.value,
                        customerId: parsed_customerId.value,
                        likedAt: parsed_likedAt.value,
                        readingProgress: parsed_readingProgress.value,
                      },
                    };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "SUMMARY" as const, ...parsedVariant.value } };
                }
                case "MIAB": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          bookmarkedAt: DateTime<true> | null;
                          contentId: MiabId;
                          customerId: number;
                          feedback: boolean | null;
                          feedbackAt: DateTime<true> | null;
                          modifiedAt: DateTime<true>;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "bookmarkedAt")) {
                      return { success: false };
                    }
                    const parsed_bookmarkedAt = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "number") {
                          return { success: false };
                        }
                        const parsed = DateTime.fromMillis(value);
                        if (!parsed.isValid) {
                          return { success: false };
                        }
                        return { success: true, value: parsed };
                      })(value["bookmarkedAt"]);
                    })();
                    if (!parsed_bookmarkedAt.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: MiabId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "MIAB";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "MIAB" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "MIAB":
                                  return { success: true, value: "MIAB" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "customerId")) {
                      return { success: false };
                    }
                    const parsed_customerId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["customerId"]);
                    })();
                    if (!parsed_customerId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "feedback")) {
                      return { success: false };
                    }
                    const parsed_feedback = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["feedback"]);
                    })();
                    if (!parsed_feedback.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "feedbackAt")) {
                      return { success: false };
                    }
                    const parsed_feedbackAt = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "number") {
                          return { success: false };
                        }
                        const parsed = DateTime.fromMillis(value);
                        if (!parsed.isValid) {
                          return { success: false };
                        }
                        return { success: true, value: parsed };
                      })(value["feedbackAt"]);
                    })();
                    if (!parsed_feedbackAt.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "modifiedAt")) {
                      return { success: false };
                    }
                    const parsed_modifiedAt = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                        if (typeof value !== "number") {
                          return { success: false };
                        }
                        const parsed = DateTime.fromMillis(value);
                        if (!parsed.isValid) {
                          return { success: false };
                        }
                        return { success: true, value: parsed };
                      })(value["modifiedAt"]);
                    })();
                    if (!parsed_modifiedAt.success) {
                      return { success: false };
                    }
                    return {
                      success: true,
                      value: {
                        bookmarkedAt: parsed_bookmarkedAt.value,
                        contentId: parsed_contentId.value,
                        customerId: parsed_customerId.value,
                        feedback: parsed_feedback.value,
                        feedbackAt: parsed_feedbackAt.value,
                        modifiedAt: parsed_modifiedAt.value,
                      },
                    };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "MIAB" as const, ...parsedVariant.value } };
                }
                case "ACTIONABLE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          bookmarkedAt: DateTime<true> | null;
                          contentId: ActionableId;
                          customerId: number;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "bookmarkedAt")) {
                      return { success: false };
                    }
                    const parsed_bookmarkedAt = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "number") {
                          return { success: false };
                        }
                        const parsed = DateTime.fromMillis(value);
                        if (!parsed.isValid) {
                          return { success: false };
                        }
                        return { success: true, value: parsed };
                      })(value["bookmarkedAt"]);
                    })();
                    if (!parsed_bookmarkedAt.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ActionableId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "ACTIONABLE";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "ACTIONABLE":
                                  return { success: true, value: "ACTIONABLE" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "customerId")) {
                      return { success: false };
                    }
                    const parsed_customerId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["customerId"]);
                    })();
                    if (!parsed_customerId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { bookmarkedAt: parsed_bookmarkedAt.value, contentId: parsed_contentId.value, customerId: parsed_customerId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "ACTIONABLE" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ContentIxStatusMiab
export function serialize_ContentIxStatusMiab(obj: ContentIxStatusMiab): string {
  const serialized = ((value: { bookmarkedAt: DateTime<true> | null; contentId: MiabId; contentType: "MIAB"; customerId: number; feedback: boolean | null; feedbackAt: DateTime<true> | null; modifiedAt: DateTime<true> }): unknown => {
    return {
      bookmarkedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["bookmarkedAt"]),
      contentId: ((value: { contentType: "MIAB"; id: bigint }): unknown => {
        return {
          contentType: ((value: "MIAB"): unknown => {
            return value;
          })(value["contentType"]),
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
        };
      })(value["contentId"]),
      contentType: ((value: "MIAB"): unknown => {
        return value;
      })(value["contentType"]),
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
      feedback: ((value: boolean | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["feedback"]),
      feedbackAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["feedbackAt"]),
      modifiedAt: ((value: DateTime<true>): unknown => {
        return value.toMillis();
      })(value["modifiedAt"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ContentIxStatusMiab(str: string): ContentIxStatusMiab | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          bookmarkedAt: DateTime<true> | null;
          contentId: MiabId;
          contentType: "MIAB";
          customerId: number;
          feedback: boolean | null;
          feedbackAt: DateTime<true> | null;
          modifiedAt: DateTime<true>;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "bookmarkedAt")) {
      return { success: false };
    }
    const parsed_bookmarkedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["bookmarkedAt"]);
    })();
    if (!parsed_bookmarkedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentId")) {
      return { success: false };
    }
    const parsed_contentId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: MiabId } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                contentType: "MIAB";
                id: bigint;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "contentType")) {
            return { success: false };
          }
          const parsed_contentType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: "MIAB" } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              switch (value) {
                case "MIAB":
                  return { success: true, value: "MIAB" };
                default:
                  return { success: false };
              }
            })(value["contentType"]);
          })();
          if (!parsed_contentType.success) {
            return { success: false };
          }
          if (!hasProp(value, "id")) {
            return { success: false };
          }
          const parsed_id = (() => {
            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
              if (typeof value === "number") {
                return { success: true, value: BigInt(value) };
              }
              if (typeof value === "bigint") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["id"]);
          })();
          if (!parsed_id.success) {
            return { success: false };
          }
          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
        })(value);
      })(value["contentId"]);
    })();
    if (!parsed_contentId.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentType")) {
      return { success: false };
    }
    const parsed_contentType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "MIAB" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "MIAB":
            return { success: true, value: "MIAB" };
          default:
            return { success: false };
        }
      })(value["contentType"]);
    })();
    if (!parsed_contentType.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "feedback")) {
      return { success: false };
    }
    const parsed_feedback = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["feedback"]);
    })();
    if (!parsed_feedback.success) {
      return { success: false };
    }
    if (!hasProp(value, "feedbackAt")) {
      return { success: false };
    }
    const parsed_feedbackAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["feedbackAt"]);
    })();
    if (!parsed_feedbackAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "modifiedAt")) {
      return { success: false };
    }
    const parsed_modifiedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["modifiedAt"]);
    })();
    if (!parsed_modifiedAt.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        bookmarkedAt: parsed_bookmarkedAt.value,
        contentId: parsed_contentId.value,
        contentType: parsed_contentType.value,
        customerId: parsed_customerId.value,
        feedback: parsed_feedback.value,
        feedbackAt: parsed_feedbackAt.value,
        modifiedAt: parsed_modifiedAt.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ContentIxStatusSummary
export function serialize_ContentIxStatusSummary(obj: ContentIxStatusSummary): string {
  const serialized = ((value: {
    bookmarkedAt: DateTime<true> | null;
    consumedAt: DateTime<true> | null;
    contentId: SummaryId;
    contentType: "SUMMARY";
    customerId: number;
    likedAt: DateTime<true> | null;
    readingProgress: number;
  }): unknown => {
    return {
      bookmarkedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["bookmarkedAt"]),
      consumedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["consumedAt"]),
      contentId: ((value: { contentType: "SUMMARY"; id: bigint }): unknown => {
        return {
          contentType: ((value: "SUMMARY"): unknown => {
            return value;
          })(value["contentType"]),
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
        };
      })(value["contentId"]),
      contentType: ((value: "SUMMARY"): unknown => {
        return value;
      })(value["contentType"]),
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
      likedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["likedAt"]),
      readingProgress: ((value: number): unknown => {
        return value;
      })(value["readingProgress"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ContentIxStatusSummary(str: string): ContentIxStatusSummary | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          bookmarkedAt: DateTime<true> | null;
          consumedAt: DateTime<true> | null;
          contentId: SummaryId;
          contentType: "SUMMARY";
          customerId: number;
          likedAt: DateTime<true> | null;
          readingProgress: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "bookmarkedAt")) {
      return { success: false };
    }
    const parsed_bookmarkedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["bookmarkedAt"]);
    })();
    if (!parsed_bookmarkedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "consumedAt")) {
      return { success: false };
    }
    const parsed_consumedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["consumedAt"]);
    })();
    if (!parsed_consumedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentId")) {
      return { success: false };
    }
    const parsed_contentId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SummaryId } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                contentType: "SUMMARY";
                id: bigint;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "contentType")) {
            return { success: false };
          }
          const parsed_contentType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              switch (value) {
                case "SUMMARY":
                  return { success: true, value: "SUMMARY" };
                default:
                  return { success: false };
              }
            })(value["contentType"]);
          })();
          if (!parsed_contentType.success) {
            return { success: false };
          }
          if (!hasProp(value, "id")) {
            return { success: false };
          }
          const parsed_id = (() => {
            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
              if (typeof value === "number") {
                return { success: true, value: BigInt(value) };
              }
              if (typeof value === "bigint") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["id"]);
          })();
          if (!parsed_id.success) {
            return { success: false };
          }
          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
        })(value);
      })(value["contentId"]);
    })();
    if (!parsed_contentId.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentType")) {
      return { success: false };
    }
    const parsed_contentType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "SUMMARY":
            return { success: true, value: "SUMMARY" };
          default:
            return { success: false };
        }
      })(value["contentType"]);
    })();
    if (!parsed_contentType.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "likedAt")) {
      return { success: false };
    }
    const parsed_likedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["likedAt"]);
    })();
    if (!parsed_likedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "readingProgress")) {
      return { success: false };
    }
    const parsed_readingProgress = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        if (typeof value === "bigint") {
          return { success: true, value: Number(value) };
        }
        return { success: false };
      })(value["readingProgress"]);
    })();
    if (!parsed_readingProgress.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        bookmarkedAt: parsed_bookmarkedAt.value,
        consumedAt: parsed_consumedAt.value,
        contentId: parsed_contentId.value,
        contentType: parsed_contentType.value,
        customerId: parsed_customerId.value,
        likedAt: parsed_likedAt.value,
        readingProgress: parsed_readingProgress.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ContentLibrary
export function serialize_ContentLibrary(obj: ContentLibrary): string {
  const serialized = ((value: "BUSINESS_BOOKS" | "CLASSICS_BOOKS" | "ARTICLES_AND_REPORTS" | "VIDEOS_AND_PODCASTS"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ContentLibrary(str: string): ContentLibrary | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "BUSINESS_BOOKS" | "CLASSICS_BOOKS" | "ARTICLES_AND_REPORTS" | "VIDEOS_AND_PODCASTS" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "BUSINESS_BOOKS":
        return { success: true, value: "BUSINESS_BOOKS" };
      case "CLASSICS_BOOKS":
        return { success: true, value: "CLASSICS_BOOKS" };
      case "ARTICLES_AND_REPORTS":
        return { success: true, value: "ARTICLES_AND_REPORTS" };
      case "VIDEOS_AND_PODCASTS":
        return { success: true, value: "VIDEOS_AND_PODCASTS" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ContentMetadata
export function serialize_ContentMetadata(obj: ContentMetadata): string {
  const serialized = ((
    value:
      | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
      | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
      | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
      | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number },
  ): unknown => {
    switch (value.contentType) {
      case "SUMMARY": {
        return {
          contentType: "SUMMARY",
          backgroundColor: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["backgroundColor"]),
          common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
            return {
              audioActive: ((value: boolean): unknown => {
                return value;
              })(value["audioActive"]),
              coverUri: ((value: string): unknown => {
                return value;
              })(value["coverUri"]),
              description: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["description"]),
              inactive: ((value: boolean): unknown => {
                return value;
              })(value["inactive"]),
              language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                return value;
              })(value["language"]),
              leadIn: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["leadIn"]),
              link: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["link"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
            };
          })(value["common"]),
          contentId: ((value: { contentType: "SUMMARY"; id: bigint }): unknown => {
            return {
              contentType: ((value: "SUMMARY"): unknown => {
                return value;
              })(value["contentType"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
            };
          })(value["contentId"]),
          documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
            return value;
          })(value["documentType"]),
          meetingInABoxAvailable: ((value: boolean): unknown => {
            return value;
          })(value["meetingInABoxAvailable"]),
          sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
            return value;
          })(value["sourceType"]),
        };
      }
      case "ACTIONABLE": {
        return {
          contentType: "ACTIONABLE",
          common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
            return {
              audioActive: ((value: boolean): unknown => {
                return value;
              })(value["audioActive"]),
              coverUri: ((value: string): unknown => {
                return value;
              })(value["coverUri"]),
              description: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["description"]),
              inactive: ((value: boolean): unknown => {
                return value;
              })(value["inactive"]),
              language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                return value;
              })(value["language"]),
              leadIn: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["leadIn"]),
              link: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["link"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
            };
          })(value["common"]),
          contentId: ((value: { contentType: "ACTIONABLE"; id: bigint }): unknown => {
            return {
              contentType: ((value: "ACTIONABLE"): unknown => {
                return value;
              })(value["contentType"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
            };
          })(value["contentId"]),
        };
      }
      case "SKETCHNOTE": {
        return {
          contentType: "SKETCHNOTE",
          common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
            return {
              audioActive: ((value: boolean): unknown => {
                return value;
              })(value["audioActive"]),
              coverUri: ((value: string): unknown => {
                return value;
              })(value["coverUri"]),
              description: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["description"]),
              inactive: ((value: boolean): unknown => {
                return value;
              })(value["inactive"]),
              language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                return value;
              })(value["language"]),
              leadIn: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["leadIn"]),
              link: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["link"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
            };
          })(value["common"]),
          contentId: ((value: { contentType: "SKETCHNOTE"; id: bigint }): unknown => {
            return {
              contentType: ((value: "SKETCHNOTE"): unknown => {
                return value;
              })(value["contentType"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
            };
          })(value["contentId"]),
        };
      }
      case "MIAB": {
        return {
          contentType: "MIAB",
          common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
            return {
              audioActive: ((value: boolean): unknown => {
                return value;
              })(value["audioActive"]),
              coverUri: ((value: string): unknown => {
                return value;
              })(value["coverUri"]),
              description: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["description"]),
              inactive: ((value: boolean): unknown => {
                return value;
              })(value["inactive"]),
              language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                return value;
              })(value["language"]),
              leadIn: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["leadIn"]),
              link: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["link"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
            };
          })(value["common"]),
          contentId: ((value: { contentType: "MIAB"; id: bigint }): unknown => {
            return {
              contentType: ((value: "MIAB"): unknown => {
                return value;
              })(value["contentType"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
            };
          })(value["contentId"]),
          customerId: ((value: number | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["customerId"]),
          dataId: ((value: number): unknown => {
            return value;
          })(value["dataId"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ContentMetadata(str: string): ContentMetadata | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value:
          | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
          | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
          | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
          | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number };
      } => {
    return ((
      value: unknown,
    ):
      | { success: false }
      | {
          success: true;
          value:
            | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
            | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
            | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
            | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number };
        } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "contentType")) {
        return { success: false };
      }
      if (typeof value["contentType"] != "string") {
        return { success: false };
      }
      const discriminator = value["contentType"];
      switch (discriminator) {
        case "SUMMARY": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  backgroundColor: string | null;
                  common: ContentMetadataCommon;
                  contentId: SummaryId;
                  documentType: SummaryDocumentType;
                  meetingInABoxAvailable: boolean;
                  sourceType: SummarySourceType;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "backgroundColor")) {
              return { success: false };
            }
            const parsed_backgroundColor = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["backgroundColor"]);
            })();
            if (!parsed_backgroundColor.success) {
              return { success: false };
            }
            if (!hasProp(value, "common")) {
              return { success: false };
            }
            const parsed_common = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        audioActive: boolean;
                        coverUri: string;
                        description: string | null;
                        inactive: boolean;
                        language: Language;
                        leadIn: string | null;
                        link: string | null;
                        title: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "audioActive")) {
                    return { success: false };
                  }
                  const parsed_audioActive = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["audioActive"]);
                  })();
                  if (!parsed_audioActive.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "coverUri")) {
                    return { success: false };
                  }
                  const parsed_coverUri = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["coverUri"]);
                  })();
                  if (!parsed_coverUri.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "description")) {
                    return { success: false };
                  }
                  const parsed_description = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["description"]);
                  })();
                  if (!parsed_description.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "inactive")) {
                    return { success: false };
                  }
                  const parsed_inactive = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["inactive"]);
                  })();
                  if (!parsed_inactive.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "language")) {
                    return { success: false };
                  }
                  const parsed_language = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: Language } => {
                      return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "en":
                            return { success: true, value: "en" };
                          case "de":
                            return { success: true, value: "de" };
                          case "es":
                            return { success: true, value: "es" };
                          case "ru":
                            return { success: true, value: "ru" };
                          case "zh":
                            return { success: true, value: "zh" };
                          case "pt":
                            return { success: true, value: "pt" };
                          case "fr":
                            return { success: true, value: "fr" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["language"]);
                  })();
                  if (!parsed_language.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "leadIn")) {
                    return { success: false };
                  }
                  const parsed_leadIn = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["leadIn"]);
                  })();
                  if (!parsed_leadIn.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "link")) {
                    return { success: false };
                  }
                  const parsed_link = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["link"]);
                  })();
                  if (!parsed_link.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "title")) {
                    return { success: false };
                  }
                  const parsed_title = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["title"]);
                  })();
                  if (!parsed_title.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      audioActive: parsed_audioActive.value,
                      coverUri: parsed_coverUri.value,
                      description: parsed_description.value,
                      inactive: parsed_inactive.value,
                      language: parsed_language.value,
                      leadIn: parsed_leadIn.value,
                      link: parsed_link.value,
                      title: parsed_title.value,
                    },
                  };
                })(value);
              })(value["common"]);
            })();
            if (!parsed_common.success) {
              return { success: false };
            }
            if (!hasProp(value, "contentId")) {
              return { success: false };
            }
            const parsed_contentId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: SummaryId } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        contentType: "SUMMARY";
                        id: bigint;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "contentType")) {
                    return { success: false };
                  }
                  const parsed_contentType = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      switch (value) {
                        case "SUMMARY":
                          return { success: true, value: "SUMMARY" };
                        default:
                          return { success: false };
                      }
                    })(value["contentType"]);
                  })();
                  if (!parsed_contentType.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                })(value);
              })(value["contentId"]);
            })();
            if (!parsed_contentId.success) {
              return { success: false };
            }
            if (!hasProp(value, "documentType")) {
              return { success: false };
            }
            const parsed_documentType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
                return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "ABSTRACT":
                      return { success: true, value: "ABSTRACT" };
                    case "REVIEW":
                      return { success: true, value: "REVIEW" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["documentType"]);
            })();
            if (!parsed_documentType.success) {
              return { success: false };
            }
            if (!hasProp(value, "meetingInABoxAvailable")) {
              return { success: false };
            }
            const parsed_meetingInABoxAvailable = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["meetingInABoxAvailable"]);
            })();
            if (!parsed_meetingInABoxAvailable.success) {
              return { success: false };
            }
            if (!hasProp(value, "sourceType")) {
              return { success: false };
            }
            const parsed_sourceType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
                return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "BOOK":
                      return { success: true, value: "BOOK" };
                    case "VIDEO":
                      return { success: true, value: "VIDEO" };
                    case "ARTICLE":
                      return { success: true, value: "ARTICLE" };
                    case "CHAPTER":
                      return { success: true, value: "CHAPTER" };
                    case "REPORT":
                      return { success: true, value: "REPORT" };
                    case "PODCAST":
                      return { success: true, value: "PODCAST" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["sourceType"]);
            })();
            if (!parsed_sourceType.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                backgroundColor: parsed_backgroundColor.value,
                common: parsed_common.value,
                contentId: parsed_contentId.value,
                documentType: parsed_documentType.value,
                meetingInABoxAvailable: parsed_meetingInABoxAvailable.value,
                sourceType: parsed_sourceType.value,
              },
            };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { contentType: "SUMMARY" as const, ...parsedVariant.value } };
        }
        case "ACTIONABLE": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  common: ContentMetadataCommon;
                  contentId: ActionableId;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "common")) {
              return { success: false };
            }
            const parsed_common = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        audioActive: boolean;
                        coverUri: string;
                        description: string | null;
                        inactive: boolean;
                        language: Language;
                        leadIn: string | null;
                        link: string | null;
                        title: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "audioActive")) {
                    return { success: false };
                  }
                  const parsed_audioActive = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["audioActive"]);
                  })();
                  if (!parsed_audioActive.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "coverUri")) {
                    return { success: false };
                  }
                  const parsed_coverUri = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["coverUri"]);
                  })();
                  if (!parsed_coverUri.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "description")) {
                    return { success: false };
                  }
                  const parsed_description = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["description"]);
                  })();
                  if (!parsed_description.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "inactive")) {
                    return { success: false };
                  }
                  const parsed_inactive = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["inactive"]);
                  })();
                  if (!parsed_inactive.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "language")) {
                    return { success: false };
                  }
                  const parsed_language = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: Language } => {
                      return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "en":
                            return { success: true, value: "en" };
                          case "de":
                            return { success: true, value: "de" };
                          case "es":
                            return { success: true, value: "es" };
                          case "ru":
                            return { success: true, value: "ru" };
                          case "zh":
                            return { success: true, value: "zh" };
                          case "pt":
                            return { success: true, value: "pt" };
                          case "fr":
                            return { success: true, value: "fr" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["language"]);
                  })();
                  if (!parsed_language.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "leadIn")) {
                    return { success: false };
                  }
                  const parsed_leadIn = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["leadIn"]);
                  })();
                  if (!parsed_leadIn.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "link")) {
                    return { success: false };
                  }
                  const parsed_link = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["link"]);
                  })();
                  if (!parsed_link.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "title")) {
                    return { success: false };
                  }
                  const parsed_title = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["title"]);
                  })();
                  if (!parsed_title.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      audioActive: parsed_audioActive.value,
                      coverUri: parsed_coverUri.value,
                      description: parsed_description.value,
                      inactive: parsed_inactive.value,
                      language: parsed_language.value,
                      leadIn: parsed_leadIn.value,
                      link: parsed_link.value,
                      title: parsed_title.value,
                    },
                  };
                })(value);
              })(value["common"]);
            })();
            if (!parsed_common.success) {
              return { success: false };
            }
            if (!hasProp(value, "contentId")) {
              return { success: false };
            }
            const parsed_contentId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ActionableId } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        contentType: "ACTIONABLE";
                        id: bigint;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "contentType")) {
                    return { success: false };
                  }
                  const parsed_contentType = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      switch (value) {
                        case "ACTIONABLE":
                          return { success: true, value: "ACTIONABLE" };
                        default:
                          return { success: false };
                      }
                    })(value["contentType"]);
                  })();
                  if (!parsed_contentType.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                })(value);
              })(value["contentId"]);
            })();
            if (!parsed_contentId.success) {
              return { success: false };
            }
            return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { contentType: "ACTIONABLE" as const, ...parsedVariant.value } };
        }
        case "SKETCHNOTE": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  common: ContentMetadataCommon;
                  contentId: LgxpId;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "common")) {
              return { success: false };
            }
            const parsed_common = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        audioActive: boolean;
                        coverUri: string;
                        description: string | null;
                        inactive: boolean;
                        language: Language;
                        leadIn: string | null;
                        link: string | null;
                        title: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "audioActive")) {
                    return { success: false };
                  }
                  const parsed_audioActive = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["audioActive"]);
                  })();
                  if (!parsed_audioActive.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "coverUri")) {
                    return { success: false };
                  }
                  const parsed_coverUri = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["coverUri"]);
                  })();
                  if (!parsed_coverUri.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "description")) {
                    return { success: false };
                  }
                  const parsed_description = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["description"]);
                  })();
                  if (!parsed_description.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "inactive")) {
                    return { success: false };
                  }
                  const parsed_inactive = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["inactive"]);
                  })();
                  if (!parsed_inactive.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "language")) {
                    return { success: false };
                  }
                  const parsed_language = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: Language } => {
                      return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "en":
                            return { success: true, value: "en" };
                          case "de":
                            return { success: true, value: "de" };
                          case "es":
                            return { success: true, value: "es" };
                          case "ru":
                            return { success: true, value: "ru" };
                          case "zh":
                            return { success: true, value: "zh" };
                          case "pt":
                            return { success: true, value: "pt" };
                          case "fr":
                            return { success: true, value: "fr" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["language"]);
                  })();
                  if (!parsed_language.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "leadIn")) {
                    return { success: false };
                  }
                  const parsed_leadIn = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["leadIn"]);
                  })();
                  if (!parsed_leadIn.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "link")) {
                    return { success: false };
                  }
                  const parsed_link = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["link"]);
                  })();
                  if (!parsed_link.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "title")) {
                    return { success: false };
                  }
                  const parsed_title = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["title"]);
                  })();
                  if (!parsed_title.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      audioActive: parsed_audioActive.value,
                      coverUri: parsed_coverUri.value,
                      description: parsed_description.value,
                      inactive: parsed_inactive.value,
                      language: parsed_language.value,
                      leadIn: parsed_leadIn.value,
                      link: parsed_link.value,
                      title: parsed_title.value,
                    },
                  };
                })(value);
              })(value["common"]);
            })();
            if (!parsed_common.success) {
              return { success: false };
            }
            if (!hasProp(value, "contentId")) {
              return { success: false };
            }
            const parsed_contentId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: LgxpId } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        contentType: "SKETCHNOTE";
                        id: bigint;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "contentType")) {
                    return { success: false };
                  }
                  const parsed_contentType = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: "SKETCHNOTE" } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      switch (value) {
                        case "SKETCHNOTE":
                          return { success: true, value: "SKETCHNOTE" };
                        default:
                          return { success: false };
                      }
                    })(value["contentType"]);
                  })();
                  if (!parsed_contentType.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                })(value);
              })(value["contentId"]);
            })();
            if (!parsed_contentId.success) {
              return { success: false };
            }
            return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { contentType: "SKETCHNOTE" as const, ...parsedVariant.value } };
        }
        case "MIAB": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  common: ContentMetadataCommon;
                  contentId: MiabId;
                  customerId: number | null;
                  dataId: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "common")) {
              return { success: false };
            }
            const parsed_common = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        audioActive: boolean;
                        coverUri: string;
                        description: string | null;
                        inactive: boolean;
                        language: Language;
                        leadIn: string | null;
                        link: string | null;
                        title: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "audioActive")) {
                    return { success: false };
                  }
                  const parsed_audioActive = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["audioActive"]);
                  })();
                  if (!parsed_audioActive.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "coverUri")) {
                    return { success: false };
                  }
                  const parsed_coverUri = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["coverUri"]);
                  })();
                  if (!parsed_coverUri.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "description")) {
                    return { success: false };
                  }
                  const parsed_description = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["description"]);
                  })();
                  if (!parsed_description.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "inactive")) {
                    return { success: false };
                  }
                  const parsed_inactive = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["inactive"]);
                  })();
                  if (!parsed_inactive.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "language")) {
                    return { success: false };
                  }
                  const parsed_language = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: Language } => {
                      return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "en":
                            return { success: true, value: "en" };
                          case "de":
                            return { success: true, value: "de" };
                          case "es":
                            return { success: true, value: "es" };
                          case "ru":
                            return { success: true, value: "ru" };
                          case "zh":
                            return { success: true, value: "zh" };
                          case "pt":
                            return { success: true, value: "pt" };
                          case "fr":
                            return { success: true, value: "fr" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["language"]);
                  })();
                  if (!parsed_language.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "leadIn")) {
                    return { success: false };
                  }
                  const parsed_leadIn = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["leadIn"]);
                  })();
                  if (!parsed_leadIn.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "link")) {
                    return { success: false };
                  }
                  const parsed_link = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["link"]);
                  })();
                  if (!parsed_link.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "title")) {
                    return { success: false };
                  }
                  const parsed_title = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["title"]);
                  })();
                  if (!parsed_title.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      audioActive: parsed_audioActive.value,
                      coverUri: parsed_coverUri.value,
                      description: parsed_description.value,
                      inactive: parsed_inactive.value,
                      language: parsed_language.value,
                      leadIn: parsed_leadIn.value,
                      link: parsed_link.value,
                      title: parsed_title.value,
                    },
                  };
                })(value);
              })(value["common"]);
            })();
            if (!parsed_common.success) {
              return { success: false };
            }
            if (!hasProp(value, "contentId")) {
              return { success: false };
            }
            const parsed_contentId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: MiabId } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        contentType: "MIAB";
                        id: bigint;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "contentType")) {
                    return { success: false };
                  }
                  const parsed_contentType = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: "MIAB" } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      switch (value) {
                        case "MIAB":
                          return { success: true, value: "MIAB" };
                        default:
                          return { success: false };
                      }
                    })(value["contentType"]);
                  })();
                  if (!parsed_contentType.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                })(value);
              })(value["contentId"]);
            })();
            if (!parsed_contentId.success) {
              return { success: false };
            }
            if (!hasProp(value, "customerId")) {
              return { success: false };
            }
            const parsed_customerId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["customerId"]);
            })();
            if (!parsed_customerId.success) {
              return { success: false };
            }
            if (!hasProp(value, "dataId")) {
              return { success: false };
            }
            const parsed_dataId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["dataId"]);
            })();
            if (!parsed_dataId.success) {
              return { success: false };
            }
            return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value, customerId: parsed_customerId.value, dataId: parsed_dataId.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { contentType: "MIAB" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ContentMetadataActionable
export function serialize_ContentMetadataActionable(obj: ContentMetadataActionable): string {
  const serialized = ((value: { common: ContentMetadataCommon; contentId: ActionableId; contentType: "ACTIONABLE" }): unknown => {
    return {
      common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
        return {
          audioActive: ((value: boolean): unknown => {
            return value;
          })(value["audioActive"]),
          coverUri: ((value: string): unknown => {
            return value;
          })(value["coverUri"]),
          description: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["description"]),
          inactive: ((value: boolean): unknown => {
            return value;
          })(value["inactive"]),
          language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
            return value;
          })(value["language"]),
          leadIn: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["leadIn"]),
          link: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["link"]),
          title: ((value: string): unknown => {
            return value;
          })(value["title"]),
        };
      })(value["common"]),
      contentId: ((value: { contentType: "ACTIONABLE"; id: bigint }): unknown => {
        return {
          contentType: ((value: "ACTIONABLE"): unknown => {
            return value;
          })(value["contentType"]),
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
        };
      })(value["contentId"]),
      contentType: ((value: "ACTIONABLE"): unknown => {
        return value;
      })(value["contentType"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ContentMetadataActionable(str: string): ContentMetadataActionable | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          common: ContentMetadataCommon;
          contentId: ActionableId;
          contentType: "ACTIONABLE";
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "common")) {
      return { success: false };
    }
    const parsed_common = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                audioActive: boolean;
                coverUri: string;
                description: string | null;
                inactive: boolean;
                language: Language;
                leadIn: string | null;
                link: string | null;
                title: string;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "audioActive")) {
            return { success: false };
          }
          const parsed_audioActive = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["audioActive"]);
          })();
          if (!parsed_audioActive.success) {
            return { success: false };
          }
          if (!hasProp(value, "coverUri")) {
            return { success: false };
          }
          const parsed_coverUri = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["coverUri"]);
          })();
          if (!parsed_coverUri.success) {
            return { success: false };
          }
          if (!hasProp(value, "description")) {
            return { success: false };
          }
          const parsed_description = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["description"]);
          })();
          if (!parsed_description.success) {
            return { success: false };
          }
          if (!hasProp(value, "inactive")) {
            return { success: false };
          }
          const parsed_inactive = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["inactive"]);
          })();
          if (!parsed_inactive.success) {
            return { success: false };
          }
          if (!hasProp(value, "language")) {
            return { success: false };
          }
          const parsed_language = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Language } => {
              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "en":
                    return { success: true, value: "en" };
                  case "de":
                    return { success: true, value: "de" };
                  case "es":
                    return { success: true, value: "es" };
                  case "ru":
                    return { success: true, value: "ru" };
                  case "zh":
                    return { success: true, value: "zh" };
                  case "pt":
                    return { success: true, value: "pt" };
                  case "fr":
                    return { success: true, value: "fr" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["language"]);
          })();
          if (!parsed_language.success) {
            return { success: false };
          }
          if (!hasProp(value, "leadIn")) {
            return { success: false };
          }
          const parsed_leadIn = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["leadIn"]);
          })();
          if (!parsed_leadIn.success) {
            return { success: false };
          }
          if (!hasProp(value, "link")) {
            return { success: false };
          }
          const parsed_link = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["link"]);
          })();
          if (!parsed_link.success) {
            return { success: false };
          }
          if (!hasProp(value, "title")) {
            return { success: false };
          }
          const parsed_title = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["title"]);
          })();
          if (!parsed_title.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              audioActive: parsed_audioActive.value,
              coverUri: parsed_coverUri.value,
              description: parsed_description.value,
              inactive: parsed_inactive.value,
              language: parsed_language.value,
              leadIn: parsed_leadIn.value,
              link: parsed_link.value,
              title: parsed_title.value,
            },
          };
        })(value);
      })(value["common"]);
    })();
    if (!parsed_common.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentId")) {
      return { success: false };
    }
    const parsed_contentId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ActionableId } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                contentType: "ACTIONABLE";
                id: bigint;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "contentType")) {
            return { success: false };
          }
          const parsed_contentType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              switch (value) {
                case "ACTIONABLE":
                  return { success: true, value: "ACTIONABLE" };
                default:
                  return { success: false };
              }
            })(value["contentType"]);
          })();
          if (!parsed_contentType.success) {
            return { success: false };
          }
          if (!hasProp(value, "id")) {
            return { success: false };
          }
          const parsed_id = (() => {
            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
              if (typeof value === "number") {
                return { success: true, value: BigInt(value) };
              }
              if (typeof value === "bigint") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["id"]);
          })();
          if (!parsed_id.success) {
            return { success: false };
          }
          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
        })(value);
      })(value["contentId"]);
    })();
    if (!parsed_contentId.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentType")) {
      return { success: false };
    }
    const parsed_contentType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "ACTIONABLE":
            return { success: true, value: "ACTIONABLE" };
          default:
            return { success: false };
        }
      })(value["contentType"]);
    })();
    if (!parsed_contentType.success) {
      return { success: false };
    }
    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value, contentType: parsed_contentType.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ContentMetadataCommon
export function serialize_ContentMetadataCommon(obj: ContentMetadataCommon): string {
  const serialized = ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
    return {
      audioActive: ((value: boolean): unknown => {
        return value;
      })(value["audioActive"]),
      coverUri: ((value: string): unknown => {
        return value;
      })(value["coverUri"]),
      description: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["description"]),
      inactive: ((value: boolean): unknown => {
        return value;
      })(value["inactive"]),
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      leadIn: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["leadIn"]),
      link: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["link"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ContentMetadataCommon(str: string): ContentMetadataCommon | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          audioActive: boolean;
          coverUri: string;
          description: string | null;
          inactive: boolean;
          language: Language;
          leadIn: string | null;
          link: string | null;
          title: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "audioActive")) {
      return { success: false };
    }
    const parsed_audioActive = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["audioActive"]);
    })();
    if (!parsed_audioActive.success) {
      return { success: false };
    }
    if (!hasProp(value, "coverUri")) {
      return { success: false };
    }
    const parsed_coverUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["coverUri"]);
    })();
    if (!parsed_coverUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "description")) {
      return { success: false };
    }
    const parsed_description = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["description"]);
    })();
    if (!parsed_description.success) {
      return { success: false };
    }
    if (!hasProp(value, "inactive")) {
      return { success: false };
    }
    const parsed_inactive = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["inactive"]);
    })();
    if (!parsed_inactive.success) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language } => {
        return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "en":
              return { success: true, value: "en" };
            case "de":
              return { success: true, value: "de" };
            case "es":
              return { success: true, value: "es" };
            case "ru":
              return { success: true, value: "ru" };
            case "zh":
              return { success: true, value: "zh" };
            case "pt":
              return { success: true, value: "pt" };
            case "fr":
              return { success: true, value: "fr" };
            default:
              return { success: false };
          }
        })(value);
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "leadIn")) {
      return { success: false };
    }
    const parsed_leadIn = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["leadIn"]);
    })();
    if (!parsed_leadIn.success) {
      return { success: false };
    }
    if (!hasProp(value, "link")) {
      return { success: false };
    }
    const parsed_link = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["link"]);
    })();
    if (!parsed_link.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        audioActive: parsed_audioActive.value,
        coverUri: parsed_coverUri.value,
        description: parsed_description.value,
        inactive: parsed_inactive.value,
        language: parsed_language.value,
        leadIn: parsed_leadIn.value,
        link: parsed_link.value,
        title: parsed_title.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ContentMetadataLgxp
export function serialize_ContentMetadataLgxp(obj: ContentMetadataLgxp): string {
  const serialized = ((value: { common: ContentMetadataCommon; contentId: LgxpId; contentType: "SKETCHNOTE" }): unknown => {
    return {
      common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
        return {
          audioActive: ((value: boolean): unknown => {
            return value;
          })(value["audioActive"]),
          coverUri: ((value: string): unknown => {
            return value;
          })(value["coverUri"]),
          description: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["description"]),
          inactive: ((value: boolean): unknown => {
            return value;
          })(value["inactive"]),
          language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
            return value;
          })(value["language"]),
          leadIn: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["leadIn"]),
          link: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["link"]),
          title: ((value: string): unknown => {
            return value;
          })(value["title"]),
        };
      })(value["common"]),
      contentId: ((value: { contentType: "SKETCHNOTE"; id: bigint }): unknown => {
        return {
          contentType: ((value: "SKETCHNOTE"): unknown => {
            return value;
          })(value["contentType"]),
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
        };
      })(value["contentId"]),
      contentType: ((value: "SKETCHNOTE"): unknown => {
        return value;
      })(value["contentType"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ContentMetadataLgxp(str: string): ContentMetadataLgxp | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          common: ContentMetadataCommon;
          contentId: LgxpId;
          contentType: "SKETCHNOTE";
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "common")) {
      return { success: false };
    }
    const parsed_common = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                audioActive: boolean;
                coverUri: string;
                description: string | null;
                inactive: boolean;
                language: Language;
                leadIn: string | null;
                link: string | null;
                title: string;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "audioActive")) {
            return { success: false };
          }
          const parsed_audioActive = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["audioActive"]);
          })();
          if (!parsed_audioActive.success) {
            return { success: false };
          }
          if (!hasProp(value, "coverUri")) {
            return { success: false };
          }
          const parsed_coverUri = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["coverUri"]);
          })();
          if (!parsed_coverUri.success) {
            return { success: false };
          }
          if (!hasProp(value, "description")) {
            return { success: false };
          }
          const parsed_description = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["description"]);
          })();
          if (!parsed_description.success) {
            return { success: false };
          }
          if (!hasProp(value, "inactive")) {
            return { success: false };
          }
          const parsed_inactive = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["inactive"]);
          })();
          if (!parsed_inactive.success) {
            return { success: false };
          }
          if (!hasProp(value, "language")) {
            return { success: false };
          }
          const parsed_language = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Language } => {
              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "en":
                    return { success: true, value: "en" };
                  case "de":
                    return { success: true, value: "de" };
                  case "es":
                    return { success: true, value: "es" };
                  case "ru":
                    return { success: true, value: "ru" };
                  case "zh":
                    return { success: true, value: "zh" };
                  case "pt":
                    return { success: true, value: "pt" };
                  case "fr":
                    return { success: true, value: "fr" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["language"]);
          })();
          if (!parsed_language.success) {
            return { success: false };
          }
          if (!hasProp(value, "leadIn")) {
            return { success: false };
          }
          const parsed_leadIn = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["leadIn"]);
          })();
          if (!parsed_leadIn.success) {
            return { success: false };
          }
          if (!hasProp(value, "link")) {
            return { success: false };
          }
          const parsed_link = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["link"]);
          })();
          if (!parsed_link.success) {
            return { success: false };
          }
          if (!hasProp(value, "title")) {
            return { success: false };
          }
          const parsed_title = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["title"]);
          })();
          if (!parsed_title.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              audioActive: parsed_audioActive.value,
              coverUri: parsed_coverUri.value,
              description: parsed_description.value,
              inactive: parsed_inactive.value,
              language: parsed_language.value,
              leadIn: parsed_leadIn.value,
              link: parsed_link.value,
              title: parsed_title.value,
            },
          };
        })(value);
      })(value["common"]);
    })();
    if (!parsed_common.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentId")) {
      return { success: false };
    }
    const parsed_contentId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: LgxpId } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                contentType: "SKETCHNOTE";
                id: bigint;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "contentType")) {
            return { success: false };
          }
          const parsed_contentType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: "SKETCHNOTE" } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              switch (value) {
                case "SKETCHNOTE":
                  return { success: true, value: "SKETCHNOTE" };
                default:
                  return { success: false };
              }
            })(value["contentType"]);
          })();
          if (!parsed_contentType.success) {
            return { success: false };
          }
          if (!hasProp(value, "id")) {
            return { success: false };
          }
          const parsed_id = (() => {
            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
              if (typeof value === "number") {
                return { success: true, value: BigInt(value) };
              }
              if (typeof value === "bigint") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["id"]);
          })();
          if (!parsed_id.success) {
            return { success: false };
          }
          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
        })(value);
      })(value["contentId"]);
    })();
    if (!parsed_contentId.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentType")) {
      return { success: false };
    }
    const parsed_contentType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "SKETCHNOTE" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "SKETCHNOTE":
            return { success: true, value: "SKETCHNOTE" };
          default:
            return { success: false };
        }
      })(value["contentType"]);
    })();
    if (!parsed_contentType.success) {
      return { success: false };
    }
    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value, contentType: parsed_contentType.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ContentMetadataMiab
export function serialize_ContentMetadataMiab(obj: ContentMetadataMiab): string {
  const serialized = ((value: { common: ContentMetadataCommon; contentId: MiabId; contentType: "MIAB"; customerId: number | null; dataId: number }): unknown => {
    return {
      common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
        return {
          audioActive: ((value: boolean): unknown => {
            return value;
          })(value["audioActive"]),
          coverUri: ((value: string): unknown => {
            return value;
          })(value["coverUri"]),
          description: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["description"]),
          inactive: ((value: boolean): unknown => {
            return value;
          })(value["inactive"]),
          language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
            return value;
          })(value["language"]),
          leadIn: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["leadIn"]),
          link: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["link"]),
          title: ((value: string): unknown => {
            return value;
          })(value["title"]),
        };
      })(value["common"]),
      contentId: ((value: { contentType: "MIAB"; id: bigint }): unknown => {
        return {
          contentType: ((value: "MIAB"): unknown => {
            return value;
          })(value["contentType"]),
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
        };
      })(value["contentId"]),
      contentType: ((value: "MIAB"): unknown => {
        return value;
      })(value["contentType"]),
      customerId: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["customerId"]),
      dataId: ((value: number): unknown => {
        return value;
      })(value["dataId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ContentMetadataMiab(str: string): ContentMetadataMiab | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          common: ContentMetadataCommon;
          contentId: MiabId;
          contentType: "MIAB";
          customerId: number | null;
          dataId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "common")) {
      return { success: false };
    }
    const parsed_common = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                audioActive: boolean;
                coverUri: string;
                description: string | null;
                inactive: boolean;
                language: Language;
                leadIn: string | null;
                link: string | null;
                title: string;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "audioActive")) {
            return { success: false };
          }
          const parsed_audioActive = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["audioActive"]);
          })();
          if (!parsed_audioActive.success) {
            return { success: false };
          }
          if (!hasProp(value, "coverUri")) {
            return { success: false };
          }
          const parsed_coverUri = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["coverUri"]);
          })();
          if (!parsed_coverUri.success) {
            return { success: false };
          }
          if (!hasProp(value, "description")) {
            return { success: false };
          }
          const parsed_description = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["description"]);
          })();
          if (!parsed_description.success) {
            return { success: false };
          }
          if (!hasProp(value, "inactive")) {
            return { success: false };
          }
          const parsed_inactive = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["inactive"]);
          })();
          if (!parsed_inactive.success) {
            return { success: false };
          }
          if (!hasProp(value, "language")) {
            return { success: false };
          }
          const parsed_language = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Language } => {
              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "en":
                    return { success: true, value: "en" };
                  case "de":
                    return { success: true, value: "de" };
                  case "es":
                    return { success: true, value: "es" };
                  case "ru":
                    return { success: true, value: "ru" };
                  case "zh":
                    return { success: true, value: "zh" };
                  case "pt":
                    return { success: true, value: "pt" };
                  case "fr":
                    return { success: true, value: "fr" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["language"]);
          })();
          if (!parsed_language.success) {
            return { success: false };
          }
          if (!hasProp(value, "leadIn")) {
            return { success: false };
          }
          const parsed_leadIn = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["leadIn"]);
          })();
          if (!parsed_leadIn.success) {
            return { success: false };
          }
          if (!hasProp(value, "link")) {
            return { success: false };
          }
          const parsed_link = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["link"]);
          })();
          if (!parsed_link.success) {
            return { success: false };
          }
          if (!hasProp(value, "title")) {
            return { success: false };
          }
          const parsed_title = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["title"]);
          })();
          if (!parsed_title.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              audioActive: parsed_audioActive.value,
              coverUri: parsed_coverUri.value,
              description: parsed_description.value,
              inactive: parsed_inactive.value,
              language: parsed_language.value,
              leadIn: parsed_leadIn.value,
              link: parsed_link.value,
              title: parsed_title.value,
            },
          };
        })(value);
      })(value["common"]);
    })();
    if (!parsed_common.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentId")) {
      return { success: false };
    }
    const parsed_contentId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: MiabId } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                contentType: "MIAB";
                id: bigint;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "contentType")) {
            return { success: false };
          }
          const parsed_contentType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: "MIAB" } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              switch (value) {
                case "MIAB":
                  return { success: true, value: "MIAB" };
                default:
                  return { success: false };
              }
            })(value["contentType"]);
          })();
          if (!parsed_contentType.success) {
            return { success: false };
          }
          if (!hasProp(value, "id")) {
            return { success: false };
          }
          const parsed_id = (() => {
            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
              if (typeof value === "number") {
                return { success: true, value: BigInt(value) };
              }
              if (typeof value === "bigint") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["id"]);
          })();
          if (!parsed_id.success) {
            return { success: false };
          }
          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
        })(value);
      })(value["contentId"]);
    })();
    if (!parsed_contentId.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentType")) {
      return { success: false };
    }
    const parsed_contentType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "MIAB" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "MIAB":
            return { success: true, value: "MIAB" };
          default:
            return { success: false };
        }
      })(value["contentType"]);
    })();
    if (!parsed_contentType.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "dataId")) {
      return { success: false };
    }
    const parsed_dataId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["dataId"]);
    })();
    if (!parsed_dataId.success) {
      return { success: false };
    }
    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value, contentType: parsed_contentType.value, customerId: parsed_customerId.value, dataId: parsed_dataId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ContentMetadataSummary
export function serialize_ContentMetadataSummary(obj: ContentMetadataSummary): string {
  const serialized = ((value: {
    backgroundColor: string | null;
    common: ContentMetadataCommon;
    contentId: SummaryId;
    contentType: "SUMMARY";
    documentType: SummaryDocumentType;
    meetingInABoxAvailable: boolean;
    sourceType: SummarySourceType;
  }): unknown => {
    return {
      backgroundColor: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["backgroundColor"]),
      common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
        return {
          audioActive: ((value: boolean): unknown => {
            return value;
          })(value["audioActive"]),
          coverUri: ((value: string): unknown => {
            return value;
          })(value["coverUri"]),
          description: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["description"]),
          inactive: ((value: boolean): unknown => {
            return value;
          })(value["inactive"]),
          language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
            return value;
          })(value["language"]),
          leadIn: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["leadIn"]),
          link: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["link"]),
          title: ((value: string): unknown => {
            return value;
          })(value["title"]),
        };
      })(value["common"]),
      contentId: ((value: { contentType: "SUMMARY"; id: bigint }): unknown => {
        return {
          contentType: ((value: "SUMMARY"): unknown => {
            return value;
          })(value["contentType"]),
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
        };
      })(value["contentId"]),
      contentType: ((value: "SUMMARY"): unknown => {
        return value;
      })(value["contentType"]),
      documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
        return value;
      })(value["documentType"]),
      meetingInABoxAvailable: ((value: boolean): unknown => {
        return value;
      })(value["meetingInABoxAvailable"]),
      sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
        return value;
      })(value["sourceType"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ContentMetadataSummary(str: string): ContentMetadataSummary | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          backgroundColor: string | null;
          common: ContentMetadataCommon;
          contentId: SummaryId;
          contentType: "SUMMARY";
          documentType: SummaryDocumentType;
          meetingInABoxAvailable: boolean;
          sourceType: SummarySourceType;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "backgroundColor")) {
      return { success: false };
    }
    const parsed_backgroundColor = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["backgroundColor"]);
    })();
    if (!parsed_backgroundColor.success) {
      return { success: false };
    }
    if (!hasProp(value, "common")) {
      return { success: false };
    }
    const parsed_common = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                audioActive: boolean;
                coverUri: string;
                description: string | null;
                inactive: boolean;
                language: Language;
                leadIn: string | null;
                link: string | null;
                title: string;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "audioActive")) {
            return { success: false };
          }
          const parsed_audioActive = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["audioActive"]);
          })();
          if (!parsed_audioActive.success) {
            return { success: false };
          }
          if (!hasProp(value, "coverUri")) {
            return { success: false };
          }
          const parsed_coverUri = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["coverUri"]);
          })();
          if (!parsed_coverUri.success) {
            return { success: false };
          }
          if (!hasProp(value, "description")) {
            return { success: false };
          }
          const parsed_description = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["description"]);
          })();
          if (!parsed_description.success) {
            return { success: false };
          }
          if (!hasProp(value, "inactive")) {
            return { success: false };
          }
          const parsed_inactive = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["inactive"]);
          })();
          if (!parsed_inactive.success) {
            return { success: false };
          }
          if (!hasProp(value, "language")) {
            return { success: false };
          }
          const parsed_language = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Language } => {
              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "en":
                    return { success: true, value: "en" };
                  case "de":
                    return { success: true, value: "de" };
                  case "es":
                    return { success: true, value: "es" };
                  case "ru":
                    return { success: true, value: "ru" };
                  case "zh":
                    return { success: true, value: "zh" };
                  case "pt":
                    return { success: true, value: "pt" };
                  case "fr":
                    return { success: true, value: "fr" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["language"]);
          })();
          if (!parsed_language.success) {
            return { success: false };
          }
          if (!hasProp(value, "leadIn")) {
            return { success: false };
          }
          const parsed_leadIn = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["leadIn"]);
          })();
          if (!parsed_leadIn.success) {
            return { success: false };
          }
          if (!hasProp(value, "link")) {
            return { success: false };
          }
          const parsed_link = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["link"]);
          })();
          if (!parsed_link.success) {
            return { success: false };
          }
          if (!hasProp(value, "title")) {
            return { success: false };
          }
          const parsed_title = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["title"]);
          })();
          if (!parsed_title.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              audioActive: parsed_audioActive.value,
              coverUri: parsed_coverUri.value,
              description: parsed_description.value,
              inactive: parsed_inactive.value,
              language: parsed_language.value,
              leadIn: parsed_leadIn.value,
              link: parsed_link.value,
              title: parsed_title.value,
            },
          };
        })(value);
      })(value["common"]);
    })();
    if (!parsed_common.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentId")) {
      return { success: false };
    }
    const parsed_contentId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SummaryId } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                contentType: "SUMMARY";
                id: bigint;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "contentType")) {
            return { success: false };
          }
          const parsed_contentType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              switch (value) {
                case "SUMMARY":
                  return { success: true, value: "SUMMARY" };
                default:
                  return { success: false };
              }
            })(value["contentType"]);
          })();
          if (!parsed_contentType.success) {
            return { success: false };
          }
          if (!hasProp(value, "id")) {
            return { success: false };
          }
          const parsed_id = (() => {
            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
              if (typeof value === "number") {
                return { success: true, value: BigInt(value) };
              }
              if (typeof value === "bigint") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["id"]);
          })();
          if (!parsed_id.success) {
            return { success: false };
          }
          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
        })(value);
      })(value["contentId"]);
    })();
    if (!parsed_contentId.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentType")) {
      return { success: false };
    }
    const parsed_contentType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "SUMMARY":
            return { success: true, value: "SUMMARY" };
          default:
            return { success: false };
        }
      })(value["contentType"]);
    })();
    if (!parsed_contentType.success) {
      return { success: false };
    }
    if (!hasProp(value, "documentType")) {
      return { success: false };
    }
    const parsed_documentType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
        return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "ABSTRACT":
              return { success: true, value: "ABSTRACT" };
            case "REVIEW":
              return { success: true, value: "REVIEW" };
            default:
              return { success: false };
          }
        })(value);
      })(value["documentType"]);
    })();
    if (!parsed_documentType.success) {
      return { success: false };
    }
    if (!hasProp(value, "meetingInABoxAvailable")) {
      return { success: false };
    }
    const parsed_meetingInABoxAvailable = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["meetingInABoxAvailable"]);
    })();
    if (!parsed_meetingInABoxAvailable.success) {
      return { success: false };
    }
    if (!hasProp(value, "sourceType")) {
      return { success: false };
    }
    const parsed_sourceType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
        return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "BOOK":
              return { success: true, value: "BOOK" };
            case "VIDEO":
              return { success: true, value: "VIDEO" };
            case "ARTICLE":
              return { success: true, value: "ARTICLE" };
            case "CHAPTER":
              return { success: true, value: "CHAPTER" };
            case "REPORT":
              return { success: true, value: "REPORT" };
            case "PODCAST":
              return { success: true, value: "PODCAST" };
            default:
              return { success: false };
          }
        })(value);
      })(value["sourceType"]);
    })();
    if (!parsed_sourceType.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        backgroundColor: parsed_backgroundColor.value,
        common: parsed_common.value,
        contentId: parsed_contentId.value,
        contentType: parsed_contentType.value,
        documentType: parsed_documentType.value,
        meetingInABoxAvailable: parsed_meetingInABoxAvailable.value,
        sourceType: parsed_sourceType.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CorporateLicences
export function serialize_CorporateLicences(obj: CorporateLicences): string {
  const serialized = ((value: { consumedLicences: number; limitedLicences: boolean; numberLicences: number; unidentifiedUsers: number }): unknown => {
    return {
      consumedLicences: ((value: number): unknown => {
        return value;
      })(value["consumedLicences"]),
      limitedLicences: ((value: boolean): unknown => {
        return value;
      })(value["limitedLicences"]),
      numberLicences: ((value: number): unknown => {
        return value;
      })(value["numberLicences"]),
      unidentifiedUsers: ((value: number): unknown => {
        return value;
      })(value["unidentifiedUsers"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CorporateLicences(str: string): CorporateLicences | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          consumedLicences: number;
          limitedLicences: boolean;
          numberLicences: number;
          unidentifiedUsers: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "consumedLicences")) {
      return { success: false };
    }
    const parsed_consumedLicences = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["consumedLicences"]);
    })();
    if (!parsed_consumedLicences.success) {
      return { success: false };
    }
    if (!hasProp(value, "limitedLicences")) {
      return { success: false };
    }
    const parsed_limitedLicences = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["limitedLicences"]);
    })();
    if (!parsed_limitedLicences.success) {
      return { success: false };
    }
    if (!hasProp(value, "numberLicences")) {
      return { success: false };
    }
    const parsed_numberLicences = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["numberLicences"]);
    })();
    if (!parsed_numberLicences.success) {
      return { success: false };
    }
    if (!hasProp(value, "unidentifiedUsers")) {
      return { success: false };
    }
    const parsed_unidentifiedUsers = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["unidentifiedUsers"]);
    })();
    if (!parsed_unidentifiedUsers.success) {
      return { success: false };
    }
    return { success: true, value: { consumedLicences: parsed_consumedLicences.value, limitedLicences: parsed_limitedLicences.value, numberLicences: parsed_numberLicences.value, unidentifiedUsers: parsed_unidentifiedUsers.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CorporationLecacyForm
export function serialize_CorporationLecacyForm(obj: CorporationLecacyForm): string {
  const serialized = ((value: { info: string }): unknown => {
    return {
      info: ((value: string): unknown => {
        return value;
      })(value["info"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CorporationLecacyForm(str: string): CorporationLecacyForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          info: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "info")) {
      return { success: false };
    }
    const parsed_info = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["info"]);
    })();
    if (!parsed_info.success) {
      return { success: false };
    }
    return { success: true, value: { info: parsed_info.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CorporationPortalAdminView
export function serialize_CorporationPortalAdminView(obj: CorporationPortalAdminView): string {
  const serialized = ((value: { active: boolean; corporateId: number; corporation: string; lcResponsible: CustomerView | null; logoUrl: string | null; portalUrl: string; signupUrl: string | null; username: string }): unknown => {
    return {
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      corporateId: ((value: number): unknown => {
        return value;
      })(value["corporateId"]),
      corporation: ((value: string): unknown => {
        return value;
      })(value["corporation"]),
      lcResponsible: ((
        value: {
          customerId: number;
          email: string;
          fullName: string;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          customerId: ((value: number): unknown => {
            return value;
          })(value["customerId"]),
          email: ((value: string): unknown => {
            return value;
          })(value["email"]),
          fullName: ((value: string): unknown => {
            return value;
          })(value["fullName"]),
        };
      })(value["lcResponsible"]),
      logoUrl: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["logoUrl"]),
      portalUrl: ((value: string): unknown => {
        return value;
      })(value["portalUrl"]),
      signupUrl: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["signupUrl"]),
      username: ((value: string): unknown => {
        return value;
      })(value["username"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CorporationPortalAdminView(str: string): CorporationPortalAdminView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          active: boolean;
          corporateId: number;
          corporation: string;
          lcResponsible: CustomerView | null;
          logoUrl: string | null;
          portalUrl: string;
          signupUrl: string | null;
          username: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "corporation")) {
      return { success: false };
    }
    const parsed_corporation = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["corporation"]);
    })();
    if (!parsed_corporation.success) {
      return { success: false };
    }
    if (!hasProp(value, "lcResponsible")) {
      return { success: false };
    }
    const parsed_lcResponsible = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CustomerView | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                customerId: number;
                email: string;
                fullName: string;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "customerId")) {
            return { success: false };
          }
          const parsed_customerId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["customerId"]);
          })();
          if (!parsed_customerId.success) {
            return { success: false };
          }
          if (!hasProp(value, "email")) {
            return { success: false };
          }
          const parsed_email = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["email"]);
          })();
          if (!parsed_email.success) {
            return { success: false };
          }
          if (!hasProp(value, "fullName")) {
            return { success: false };
          }
          const parsed_fullName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["fullName"]);
          })();
          if (!parsed_fullName.success) {
            return { success: false };
          }
          return { success: true, value: { customerId: parsed_customerId.value, email: parsed_email.value, fullName: parsed_fullName.value } };
        })(value);
      })(value["lcResponsible"]);
    })();
    if (!parsed_lcResponsible.success) {
      return { success: false };
    }
    if (!hasProp(value, "logoUrl")) {
      return { success: false };
    }
    const parsed_logoUrl = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["logoUrl"]);
    })();
    if (!parsed_logoUrl.success) {
      return { success: false };
    }
    if (!hasProp(value, "portalUrl")) {
      return { success: false };
    }
    const parsed_portalUrl = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["portalUrl"]);
    })();
    if (!parsed_portalUrl.success) {
      return { success: false };
    }
    if (!hasProp(value, "signupUrl")) {
      return { success: false };
    }
    const parsed_signupUrl = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["signupUrl"]);
    })();
    if (!parsed_signupUrl.success) {
      return { success: false };
    }
    if (!hasProp(value, "username")) {
      return { success: false };
    }
    const parsed_username = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["username"]);
    })();
    if (!parsed_username.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        active: parsed_active.value,
        corporateId: parsed_corporateId.value,
        corporation: parsed_corporation.value,
        lcResponsible: parsed_lcResponsible.value,
        logoUrl: parsed_logoUrl.value,
        portalUrl: parsed_portalUrl.value,
        signupUrl: parsed_signupUrl.value,
        username: parsed_username.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// Country
export function serialize_Country(obj: Country): string {
  const serialized = ((value: {
    countryCode: string;
    currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
    names: {
      de: string;
      en: string;
      es: string;
      fr: string;
      pt: string;
      ru: string;
      zh: string;
    };
    phonePrefix: string;
    region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
  }): unknown => {
    return {
      countryCode: ((value: string): unknown => {
        return value;
      })(value["countryCode"]),
      currency: ((value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY"): unknown => {
        return value;
      })(value["currency"]),
      names: ((value: { de: string; en: string; es: string; fr: string; pt: string; ru: string; zh: string }): unknown => {
        return {
          de: ((value: string): unknown => {
            return value;
          })(value["de"]),
          en: ((value: string): unknown => {
            return value;
          })(value["en"]),
          es: ((value: string): unknown => {
            return value;
          })(value["es"]),
          fr: ((value: string): unknown => {
            return value;
          })(value["fr"]),
          pt: ((value: string): unknown => {
            return value;
          })(value["pt"]),
          ru: ((value: string): unknown => {
            return value;
          })(value["ru"]),
          zh: ((value: string): unknown => {
            return value;
          })(value["zh"]),
        };
      })(value["names"]),
      phonePrefix: ((value: string): unknown => {
        return value;
      })(value["phonePrefix"]),
      region: ((value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA"): unknown => {
        return value;
      })(value["region"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_Country(str: string): Country | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          countryCode: string;
          currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
          names: {
            de: string;
            en: string;
            es: string;
            fr: string;
            pt: string;
            ru: string;
            zh: string;
          };
          phonePrefix: string;
          region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "countryCode")) {
      return { success: false };
    }
    const parsed_countryCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["countryCode"]);
    })();
    if (!parsed_countryCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "currency")) {
      return { success: false };
    }
    const parsed_currency = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "CHF":
            return { success: true, value: "CHF" };
          case "CNY":
            return { success: true, value: "CNY" };
          case "USD":
            return { success: true, value: "USD" };
          case "EUR":
            return { success: true, value: "EUR" };
          case "GBP":
            return { success: true, value: "GBP" };
          case "INR":
            return { success: true, value: "INR" };
          case "JPY":
            return { success: true, value: "JPY" };
          default:
            return { success: false };
        }
      })(value["currency"]);
    })();
    if (!parsed_currency.success) {
      return { success: false };
    }
    if (!hasProp(value, "names")) {
      return { success: false };
    }
    const parsed_names = (() => {
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value: {
              de: string;
              en: string;
              es: string;
              fr: string;
              pt: string;
              ru: string;
              zh: string;
            };
          } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        if (!hasProp(value, "de")) {
          return { success: false };
        }
        const parsed_de = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["de"]);
        })();
        if (!parsed_de.success) {
          return { success: false };
        }
        if (!hasProp(value, "en")) {
          return { success: false };
        }
        const parsed_en = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["en"]);
        })();
        if (!parsed_en.success) {
          return { success: false };
        }
        if (!hasProp(value, "es")) {
          return { success: false };
        }
        const parsed_es = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["es"]);
        })();
        if (!parsed_es.success) {
          return { success: false };
        }
        if (!hasProp(value, "fr")) {
          return { success: false };
        }
        const parsed_fr = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["fr"]);
        })();
        if (!parsed_fr.success) {
          return { success: false };
        }
        if (!hasProp(value, "pt")) {
          return { success: false };
        }
        const parsed_pt = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["pt"]);
        })();
        if (!parsed_pt.success) {
          return { success: false };
        }
        if (!hasProp(value, "ru")) {
          return { success: false };
        }
        const parsed_ru = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["ru"]);
        })();
        if (!parsed_ru.success) {
          return { success: false };
        }
        if (!hasProp(value, "zh")) {
          return { success: false };
        }
        const parsed_zh = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["zh"]);
        })();
        if (!parsed_zh.success) {
          return { success: false };
        }
        return { success: true, value: { de: parsed_de.value, en: parsed_en.value, es: parsed_es.value, fr: parsed_fr.value, pt: parsed_pt.value, ru: parsed_ru.value, zh: parsed_zh.value } };
      })(value["names"]);
    })();
    if (!parsed_names.success) {
      return { success: false };
    }
    if (!hasProp(value, "phonePrefix")) {
      return { success: false };
    }
    const parsed_phonePrefix = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["phonePrefix"]);
    })();
    if (!parsed_phonePrefix.success) {
      return { success: false };
    }
    if (!hasProp(value, "region")) {
      return { success: false };
    }
    const parsed_region = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "SOUTHAMERICA":
            return { success: true, value: "SOUTHAMERICA" };
          case "EUROPE":
            return { success: true, value: "EUROPE" };
          case "OCEANIA":
            return { success: true, value: "OCEANIA" };
          case "NORTHAMERICA":
            return { success: true, value: "NORTHAMERICA" };
          case "AFRICA":
            return { success: true, value: "AFRICA" };
          case "ANTARCTICA":
            return { success: true, value: "ANTARCTICA" };
          case "ASIA":
            return { success: true, value: "ASIA" };
          default:
            return { success: false };
        }
      })(value["region"]);
    })();
    if (!parsed_region.success) {
      return { success: false };
    }
    return { success: true, value: { countryCode: parsed_countryCode.value, currency: parsed_currency.value, names: parsed_names.value, phonePrefix: parsed_phonePrefix.value, region: parsed_region.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CountryRestrictionType
export function serialize_CountryRestrictionType(obj: CountryRestrictionType): string {
  const serialized = ((value: "INCLUDED" | "EXCLUDED" | "NO_RESTRICTION"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CountryRestrictionType(str: string): CountryRestrictionType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "INCLUDED" | "EXCLUDED" | "NO_RESTRICTION" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "INCLUDED":
        return { success: true, value: "INCLUDED" };
      case "EXCLUDED":
        return { success: true, value: "EXCLUDED" };
      case "NO_RESTRICTION":
        return { success: true, value: "NO_RESTRICTION" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CreditCardPaymentView
export function serialize_CreditCardPaymentView(obj: CreditCardPaymentView): string {
  const serialized = ((value: {
    acqAuthorizationCode: string | null;
    authorizationCode: string | null;
    batchRefNumber: string | null;
    creditCardAlias: string | null;
    creditCardNumber: string;
    creditCardType: CreditCardType;
    datatransMerchantId: string | null;
    expirationMonth: number;
    expirationYear: number;
    gatewayProvider: string;
    nameOnCard: string | null;
    refNumber: string | null;
    transactionDateTime: DateTime<true>;
    transactionId: number;
  }): unknown => {
    return {
      acqAuthorizationCode: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["acqAuthorizationCode"]),
      authorizationCode: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["authorizationCode"]),
      batchRefNumber: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["batchRefNumber"]),
      creditCardAlias: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["creditCardAlias"]),
      creditCardNumber: ((value: string): unknown => {
        return value;
      })(value["creditCardNumber"]),
      creditCardType: ((value: "VISA" | "MASTERCARD" | "AMEXCO"): unknown => {
        return value;
      })(value["creditCardType"]),
      datatransMerchantId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["datatransMerchantId"]),
      expirationMonth: ((value: number): unknown => {
        return value;
      })(value["expirationMonth"]),
      expirationYear: ((value: number): unknown => {
        return value;
      })(value["expirationYear"]),
      gatewayProvider: ((value: string): unknown => {
        return value;
      })(value["gatewayProvider"]),
      nameOnCard: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["nameOnCard"]),
      refNumber: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["refNumber"]),
      transactionDateTime: ((value: DateTime<true>): unknown => {
        return value.toMillis();
      })(value["transactionDateTime"]),
      transactionId: ((value: number): unknown => {
        return value;
      })(value["transactionId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CreditCardPaymentView(str: string): CreditCardPaymentView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          acqAuthorizationCode: string | null;
          authorizationCode: string | null;
          batchRefNumber: string | null;
          creditCardAlias: string | null;
          creditCardNumber: string;
          creditCardType: CreditCardType;
          datatransMerchantId: string | null;
          expirationMonth: number;
          expirationYear: number;
          gatewayProvider: string;
          nameOnCard: string | null;
          refNumber: string | null;
          transactionDateTime: DateTime<true>;
          transactionId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "acqAuthorizationCode")) {
      return { success: false };
    }
    const parsed_acqAuthorizationCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["acqAuthorizationCode"]);
    })();
    if (!parsed_acqAuthorizationCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "authorizationCode")) {
      return { success: false };
    }
    const parsed_authorizationCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["authorizationCode"]);
    })();
    if (!parsed_authorizationCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "batchRefNumber")) {
      return { success: false };
    }
    const parsed_batchRefNumber = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["batchRefNumber"]);
    })();
    if (!parsed_batchRefNumber.success) {
      return { success: false };
    }
    if (!hasProp(value, "creditCardAlias")) {
      return { success: false };
    }
    const parsed_creditCardAlias = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["creditCardAlias"]);
    })();
    if (!parsed_creditCardAlias.success) {
      return { success: false };
    }
    if (!hasProp(value, "creditCardNumber")) {
      return { success: false };
    }
    const parsed_creditCardNumber = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["creditCardNumber"]);
    })();
    if (!parsed_creditCardNumber.success) {
      return { success: false };
    }
    if (!hasProp(value, "creditCardType")) {
      return { success: false };
    }
    const parsed_creditCardType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CreditCardType } => {
        return ((value: unknown): { success: false } | { success: true; value: "VISA" | "MASTERCARD" | "AMEXCO" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "VISA":
              return { success: true, value: "VISA" };
            case "MASTERCARD":
              return { success: true, value: "MASTERCARD" };
            case "AMEXCO":
              return { success: true, value: "AMEXCO" };
            default:
              return { success: false };
          }
        })(value);
      })(value["creditCardType"]);
    })();
    if (!parsed_creditCardType.success) {
      return { success: false };
    }
    if (!hasProp(value, "datatransMerchantId")) {
      return { success: false };
    }
    const parsed_datatransMerchantId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["datatransMerchantId"]);
    })();
    if (!parsed_datatransMerchantId.success) {
      return { success: false };
    }
    if (!hasProp(value, "expirationMonth")) {
      return { success: false };
    }
    const parsed_expirationMonth = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["expirationMonth"]);
    })();
    if (!parsed_expirationMonth.success) {
      return { success: false };
    }
    if (!hasProp(value, "expirationYear")) {
      return { success: false };
    }
    const parsed_expirationYear = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["expirationYear"]);
    })();
    if (!parsed_expirationYear.success) {
      return { success: false };
    }
    if (!hasProp(value, "gatewayProvider")) {
      return { success: false };
    }
    const parsed_gatewayProvider = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["gatewayProvider"]);
    })();
    if (!parsed_gatewayProvider.success) {
      return { success: false };
    }
    if (!hasProp(value, "nameOnCard")) {
      return { success: false };
    }
    const parsed_nameOnCard = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["nameOnCard"]);
    })();
    if (!parsed_nameOnCard.success) {
      return { success: false };
    }
    if (!hasProp(value, "refNumber")) {
      return { success: false };
    }
    const parsed_refNumber = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["refNumber"]);
    })();
    if (!parsed_refNumber.success) {
      return { success: false };
    }
    if (!hasProp(value, "transactionDateTime")) {
      return { success: false };
    }
    const parsed_transactionDateTime = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["transactionDateTime"]);
    })();
    if (!parsed_transactionDateTime.success) {
      return { success: false };
    }
    if (!hasProp(value, "transactionId")) {
      return { success: false };
    }
    const parsed_transactionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["transactionId"]);
    })();
    if (!parsed_transactionId.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        acqAuthorizationCode: parsed_acqAuthorizationCode.value,
        authorizationCode: parsed_authorizationCode.value,
        batchRefNumber: parsed_batchRefNumber.value,
        creditCardAlias: parsed_creditCardAlias.value,
        creditCardNumber: parsed_creditCardNumber.value,
        creditCardType: parsed_creditCardType.value,
        datatransMerchantId: parsed_datatransMerchantId.value,
        expirationMonth: parsed_expirationMonth.value,
        expirationYear: parsed_expirationYear.value,
        gatewayProvider: parsed_gatewayProvider.value,
        nameOnCard: parsed_nameOnCard.value,
        refNumber: parsed_refNumber.value,
        transactionDateTime: parsed_transactionDateTime.value,
        transactionId: parsed_transactionId.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CreditCardType
export function serialize_CreditCardType(obj: CreditCardType): string {
  const serialized = ((value: "VISA" | "MASTERCARD" | "AMEXCO"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CreditCardType(str: string): CreditCardType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "VISA" | "MASTERCARD" | "AMEXCO" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "VISA":
        return { success: true, value: "VISA" };
      case "MASTERCARD":
        return { success: true, value: "MASTERCARD" };
      case "AMEXCO":
        return { success: true, value: "AMEXCO" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageAdminSearchForm
export function serialize_CustomPageAdminSearchForm(obj: CustomPageAdminSearchForm): string {
  const serialized = ((value: { includeInactive: boolean; onlyHighlighted: boolean; searchTerm: string }): unknown => {
    return {
      includeInactive: ((value: boolean): unknown => {
        return value;
      })(value["includeInactive"]),
      onlyHighlighted: ((value: boolean): unknown => {
        return value;
      })(value["onlyHighlighted"]),
      searchTerm: ((value: string): unknown => {
        return value;
      })(value["searchTerm"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageAdminSearchForm(str: string): CustomPageAdminSearchForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          includeInactive: boolean;
          onlyHighlighted: boolean;
          searchTerm: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "includeInactive")) {
      return { success: false };
    }
    const parsed_includeInactive = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["includeInactive"]);
    })();
    if (!parsed_includeInactive.success) {
      return { success: false };
    }
    if (!hasProp(value, "onlyHighlighted")) {
      return { success: false };
    }
    const parsed_onlyHighlighted = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["onlyHighlighted"]);
    })();
    if (!parsed_onlyHighlighted.success) {
      return { success: false };
    }
    if (!hasProp(value, "searchTerm")) {
      return { success: false };
    }
    const parsed_searchTerm = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["searchTerm"]);
    })();
    if (!parsed_searchTerm.success) {
      return { success: false };
    }
    return { success: true, value: { includeInactive: parsed_includeInactive.value, onlyHighlighted: parsed_onlyHighlighted.value, searchTerm: parsed_searchTerm.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageCopyRequest
export function serialize_CustomPageCopyRequest(obj: CustomPageCopyRequest): string {
  const serialized = ((value: { toCorporateId: number }): unknown => {
    return {
      toCorporateId: ((value: number): unknown => {
        return value;
      })(value["toCorporateId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageCopyRequest(str: string): CustomPageCopyRequest | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          toCorporateId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "toCorporateId")) {
      return { success: false };
    }
    const parsed_toCorporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["toCorporateId"]);
    })();
    if (!parsed_toCorporateId.success) {
      return { success: false };
    }
    return { success: true, value: { toCorporateId: parsed_toCorporateId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageEditForm
export function serialize_CustomPageEditForm(obj: CustomPageEditForm): string {
  const serialized = ((value: {
    active: boolean;
    customPageLocalization: { additionalProperties: Record<string, CustomPageLocalizationEditForm> };
    defaultCover: boolean;
    headerLayout: CustomPageHeaderLayout;
    portletIds: number[];
  }): unknown => {
    return {
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      customPageLocalization: ((value: { additionalProperties: Record<string, CustomPageLocalizationEditForm> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: { description: string | null; title: string }): unknown => {
                  return {
                    description: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["description"]),
                    title: ((value: string): unknown => {
                      return value;
                    })(value["title"]),
                  };
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["customPageLocalization"]),
      defaultCover: ((value: boolean): unknown => {
        return value;
      })(value["defaultCover"]),
      headerLayout: ((value: "NONE" | "DEFAULT"): unknown => {
        return value;
      })(value["headerLayout"]),
      portletIds: ((value: number[]): unknown => {
        return value.map((value: number): unknown => {
          return value;
        });
      })(value["portletIds"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageEditForm(str: string): CustomPageEditForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          active: boolean;
          customPageLocalization: { additionalProperties: Record<string, CustomPageLocalizationEditForm> };
          defaultCover: boolean;
          headerLayout: CustomPageHeaderLayout;
          portletIds: number[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "customPageLocalization")) {
      return { success: false };
    }
    const parsed_customPageLocalization = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, CustomPageLocalizationEditForm> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, CustomPageLocalizationEditForm> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: CustomPageLocalizationEditForm }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: CustomPageLocalizationEditForm } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        description: string | null;
                        title: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "description")) {
                    return { success: false };
                  }
                  const parsed_description = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["description"]);
                  })();
                  if (!parsed_description.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "title")) {
                    return { success: false };
                  }
                  const parsed_title = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["title"]);
                  })();
                  if (!parsed_title.success) {
                    return { success: false };
                  }
                  return { success: true, value: { description: parsed_description.value, title: parsed_title.value } };
                })(value);
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["customPageLocalization"]);
    })();
    if (!parsed_customPageLocalization.success) {
      return { success: false };
    }
    if (!hasProp(value, "defaultCover")) {
      return { success: false };
    }
    const parsed_defaultCover = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["defaultCover"]);
    })();
    if (!parsed_defaultCover.success) {
      return { success: false };
    }
    if (!hasProp(value, "headerLayout")) {
      return { success: false };
    }
    const parsed_headerLayout = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CustomPageHeaderLayout } => {
        return ((value: unknown): { success: false } | { success: true; value: "NONE" | "DEFAULT" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "NONE":
              return { success: true, value: "NONE" };
            case "DEFAULT":
              return { success: true, value: "DEFAULT" };
            default:
              return { success: false };
          }
        })(value);
      })(value["headerLayout"]);
    })();
    if (!parsed_headerLayout.success) {
      return { success: false };
    }
    if (!hasProp(value, "portletIds")) {
      return { success: false };
    }
    const parsed_portletIds = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
          if (typeof value === "number") {
            return { success: true, value: value };
          }
          return { success: false };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["portletIds"]);
    })();
    if (!parsed_portletIds.success) {
      return { success: false };
    }
    return {
      success: true,
      value: { active: parsed_active.value, customPageLocalization: parsed_customPageLocalization.value, defaultCover: parsed_defaultCover.value, headerLayout: parsed_headerLayout.value, portletIds: parsed_portletIds.value },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageEditListView
export function serialize_CustomPageEditListView(obj: CustomPageEditListView): string {
  const serialized = ((value: {
    active: boolean;
    corporateId: number;
    corporation: string;
    customPageId: bigint;
    highlightPriority: number | null;
    modifiedAt: DateTime<true>;
    titles: { additionalProperties: Record<string, string> };
  }): unknown => {
    return {
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      corporateId: ((value: number): unknown => {
        return value;
      })(value["corporateId"]),
      corporation: ((value: string): unknown => {
        return value;
      })(value["corporation"]),
      customPageId: ((value: bigint): unknown => {
        return value;
      })(value["customPageId"]),
      highlightPriority: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["highlightPriority"]),
      modifiedAt: ((value: DateTime<true>): unknown => {
        return value.toISO();
      })(value["modifiedAt"]),
      titles: ((value: { additionalProperties: Record<string, string> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: string): unknown => {
                  return value;
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["titles"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageEditListView(str: string): CustomPageEditListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          active: boolean;
          corporateId: number;
          corporation: string;
          customPageId: bigint;
          highlightPriority: number | null;
          modifiedAt: DateTime<true>;
          titles: { additionalProperties: Record<string, string> };
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "corporation")) {
      return { success: false };
    }
    const parsed_corporation = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["corporation"]);
    })();
    if (!parsed_corporation.success) {
      return { success: false };
    }
    if (!hasProp(value, "customPageId")) {
      return { success: false };
    }
    const parsed_customPageId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customPageId"]);
    })();
    if (!parsed_customPageId.success) {
      return { success: false };
    }
    if (!hasProp(value, "highlightPriority")) {
      return { success: false };
    }
    const parsed_highlightPriority = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["highlightPriority"]);
    })();
    if (!parsed_highlightPriority.success) {
      return { success: false };
    }
    if (!hasProp(value, "modifiedAt")) {
      return { success: false };
    }
    const parsed_modifiedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["modifiedAt"]);
    })();
    if (!parsed_modifiedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "titles")) {
      return { success: false };
    }
    const parsed_titles = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["titles"]);
    })();
    if (!parsed_titles.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        active: parsed_active.value,
        corporateId: parsed_corporateId.value,
        corporation: parsed_corporation.value,
        customPageId: parsed_customPageId.value,
        highlightPriority: parsed_highlightPriority.value,
        modifiedAt: parsed_modifiedAt.value,
        titles: parsed_titles.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageEditListViewArray
export function serialize_CustomPageEditListViewArray(obj: CustomPageEditListViewArray): string {
  const serialized = ((value: { arr: CustomPageEditListView[] }): unknown => {
    return {
      arr: ((value: CustomPageEditListView[]): unknown => {
        return value.map(
          (value: { active: boolean; corporateId: number; corporation: string; customPageId: bigint; highlightPriority: number | null; modifiedAt: DateTime<true>; titles: { additionalProperties: Record<string, string> } }): unknown => {
            return {
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              corporateId: ((value: number): unknown => {
                return value;
              })(value["corporateId"]),
              corporation: ((value: string): unknown => {
                return value;
              })(value["corporation"]),
              customPageId: ((value: bigint): unknown => {
                return value;
              })(value["customPageId"]),
              highlightPriority: ((value: number | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["highlightPriority"]),
              modifiedAt: ((value: DateTime<true>): unknown => {
                return value.toISO();
              })(value["modifiedAt"]),
              titles: ((value: { additionalProperties: Record<string, string> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string): unknown => {
                          return value;
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["titles"]),
            };
          },
        );
      })(value["arr"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageEditListViewArray(str: string): CustomPageEditListViewArray | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          arr: CustomPageEditListView[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "arr")) {
      return { success: false };
    }
    const parsed_arr = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CustomPageEditListView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: CustomPageEditListView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  active: boolean;
                  corporateId: number;
                  corporation: string;
                  customPageId: bigint;
                  highlightPriority: number | null;
                  modifiedAt: DateTime<true>;
                  titles: { additionalProperties: Record<string, string> };
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "active")) {
              return { success: false };
            }
            const parsed_active = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["active"]);
            })();
            if (!parsed_active.success) {
              return { success: false };
            }
            if (!hasProp(value, "corporateId")) {
              return { success: false };
            }
            const parsed_corporateId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["corporateId"]);
            })();
            if (!parsed_corporateId.success) {
              return { success: false };
            }
            if (!hasProp(value, "corporation")) {
              return { success: false };
            }
            const parsed_corporation = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["corporation"]);
            })();
            if (!parsed_corporation.success) {
              return { success: false };
            }
            if (!hasProp(value, "customPageId")) {
              return { success: false };
            }
            const parsed_customPageId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["customPageId"]);
            })();
            if (!parsed_customPageId.success) {
              return { success: false };
            }
            if (!hasProp(value, "highlightPriority")) {
              return { success: false };
            }
            const parsed_highlightPriority = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["highlightPriority"]);
            })();
            if (!parsed_highlightPriority.success) {
              return { success: false };
            }
            if (!hasProp(value, "modifiedAt")) {
              return { success: false };
            }
            const parsed_modifiedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["modifiedAt"]);
            })();
            if (!parsed_modifiedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "titles")) {
              return { success: false };
            }
            const parsed_titles = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
                  const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
                    return [
                      key,
                      ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value),
                    ];
                  });
                  if (parsed.find(([_, value]) => !value.success) !== undefined) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: Object.fromEntries(
                      parsed.map(([key, value]) => {
                        assert(value.success);
                        return [key, value.value];
                      }),
                    ),
                  };
                })();
                if (!parsed_additionalProperties.success) {
                  return { success: false };
                }
                return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
              })(value["titles"]);
            })();
            if (!parsed_titles.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                active: parsed_active.value,
                corporateId: parsed_corporateId.value,
                corporation: parsed_corporation.value,
                customPageId: parsed_customPageId.value,
                highlightPriority: parsed_highlightPriority.value,
                modifiedAt: parsed_modifiedAt.value,
                titles: parsed_titles.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["arr"]);
    })();
    if (!parsed_arr.success) {
      return { success: false };
    }
    return { success: true, value: { arr: parsed_arr.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageEditView
export function serialize_CustomPageEditView(obj: CustomPageEditView): string {
  const serialized = ((value: {
    active: boolean;
    corporateId: number;
    coverUrl: string;
    customPageId: bigint;
    customPageLocalization: { additionalProperties: Record<string, CustomPageLocalizationEditView> };
    headerLayout: CustomPageHeaderLayout;
    portlets: PortletMetadata[];
  }): unknown => {
    return {
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      corporateId: ((value: number): unknown => {
        return value;
      })(value["corporateId"]),
      coverUrl: ((value: string): unknown => {
        return value;
      })(value["coverUrl"]),
      customPageId: ((value: bigint): unknown => {
        return value;
      })(value["customPageId"]),
      customPageLocalization: ((value: { additionalProperties: Record<string, CustomPageLocalizationEditView> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: { description: string | null; title: string }): unknown => {
                  return {
                    description: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["description"]),
                    title: ((value: string): unknown => {
                      return value;
                    })(value["title"]),
                  };
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["customPageLocalization"]),
      headerLayout: ((value: "NONE" | "DEFAULT"): unknown => {
        return value;
      })(value["headerLayout"]),
      portlets: ((value: PortletMetadata[]): unknown => {
        return value.map(
          (value: {
            custom: boolean;
            defaultFilterAllLanguages: boolean;
            embedSortingFiltering: boolean;
            hideTitle: boolean;
            itemCount: number;
            itemLayout: ItemLayout;
            name: string;
            portletId: number;
            portletLayout: PortletLayout;
            subType: string | null;
            subtitle: string;
            title: string;
            type: PortletType;
          }): unknown => {
            return {
              custom: ((value: boolean): unknown => {
                return value;
              })(value["custom"]),
              defaultFilterAllLanguages: ((value: boolean): unknown => {
                return value;
              })(value["defaultFilterAllLanguages"]),
              embedSortingFiltering: ((value: boolean): unknown => {
                return value;
              })(value["embedSortingFiltering"]),
              hideTitle: ((value: boolean): unknown => {
                return value;
              })(value["hideTitle"]),
              itemCount: ((value: number): unknown => {
                return value;
              })(value["itemCount"]),
              itemLayout: ((value: "MINI" | "REGULAR"): unknown => {
                return value;
              })(value["itemLayout"]),
              name: ((value: string): unknown => {
                return value;
              })(value["name"]),
              portletId: ((value: number): unknown => {
                return value;
              })(value["portletId"]),
              portletLayout: ((value: "GRID" | "CAROUSEL"): unknown => {
                return value;
              })(value["portletLayout"]),
              subType: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["subType"]),
              subtitle: ((value: string): unknown => {
                return value;
              })(value["subtitle"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
              type: ((
                value:
                  | "IMAGETEXT"
                  | "SUMMARYLIST"
                  | "TRENDINGCHANNEL"
                  | "FEATUREDCHANNEL"
                  | "FOLLOWINGCHANNEL"
                  | "MYLIST"
                  | "PRIMARYTOPICCHANNEL"
                  | "CHANNELLIST"
                  | "ANNOTATIONLIST"
                  | "LGXPLIST"
                  | "SKETCHNOTESLGXP"
                  | "ACTIONABLELIST"
                  | "ACTIONABLELISTCUSTOM"
                  | "MYACTIONABLES"
                  | "FREESUMMARY"
                  | "SUMMARYLISTCUSTOM"
                  | "CUSTOMPAGELIST"
                  | "PROMOBOXCONFIG"
                  | "MYMIABS"
                  | "ASKGETABSTRACT"
                  | "CUSTOMIZEDMIABS",
              ): unknown => {
                return value;
              })(value["type"]),
            };
          },
        );
      })(value["portlets"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageEditView(str: string): CustomPageEditView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          active: boolean;
          corporateId: number;
          coverUrl: string;
          customPageId: bigint;
          customPageLocalization: { additionalProperties: Record<string, CustomPageLocalizationEditView> };
          headerLayout: CustomPageHeaderLayout;
          portlets: PortletMetadata[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "coverUrl")) {
      return { success: false };
    }
    const parsed_coverUrl = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["coverUrl"]);
    })();
    if (!parsed_coverUrl.success) {
      return { success: false };
    }
    if (!hasProp(value, "customPageId")) {
      return { success: false };
    }
    const parsed_customPageId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customPageId"]);
    })();
    if (!parsed_customPageId.success) {
      return { success: false };
    }
    if (!hasProp(value, "customPageLocalization")) {
      return { success: false };
    }
    const parsed_customPageLocalization = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, CustomPageLocalizationEditView> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, CustomPageLocalizationEditView> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: CustomPageLocalizationEditView }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: CustomPageLocalizationEditView } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        description: string | null;
                        title: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "description")) {
                    return { success: false };
                  }
                  const parsed_description = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["description"]);
                  })();
                  if (!parsed_description.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "title")) {
                    return { success: false };
                  }
                  const parsed_title = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["title"]);
                  })();
                  if (!parsed_title.success) {
                    return { success: false };
                  }
                  return { success: true, value: { description: parsed_description.value, title: parsed_title.value } };
                })(value);
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["customPageLocalization"]);
    })();
    if (!parsed_customPageLocalization.success) {
      return { success: false };
    }
    if (!hasProp(value, "headerLayout")) {
      return { success: false };
    }
    const parsed_headerLayout = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CustomPageHeaderLayout } => {
        return ((value: unknown): { success: false } | { success: true; value: "NONE" | "DEFAULT" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "NONE":
              return { success: true, value: "NONE" };
            case "DEFAULT":
              return { success: true, value: "DEFAULT" };
            default:
              return { success: false };
          }
        })(value);
      })(value["headerLayout"]);
    })();
    if (!parsed_headerLayout.success) {
      return { success: false };
    }
    if (!hasProp(value, "portlets")) {
      return { success: false };
    }
    const parsed_portlets = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PortletMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: PortletMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  custom: boolean;
                  defaultFilterAllLanguages: boolean;
                  embedSortingFiltering: boolean;
                  hideTitle: boolean;
                  itemCount: number;
                  itemLayout: ItemLayout;
                  name: string;
                  portletId: number;
                  portletLayout: PortletLayout;
                  subType: string | null;
                  subtitle: string;
                  title: string;
                  type: PortletType;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "custom")) {
              return { success: false };
            }
            const parsed_custom = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["custom"]);
            })();
            if (!parsed_custom.success) {
              return { success: false };
            }
            if (!hasProp(value, "defaultFilterAllLanguages")) {
              return { success: false };
            }
            const parsed_defaultFilterAllLanguages = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["defaultFilterAllLanguages"]);
            })();
            if (!parsed_defaultFilterAllLanguages.success) {
              return { success: false };
            }
            if (!hasProp(value, "embedSortingFiltering")) {
              return { success: false };
            }
            const parsed_embedSortingFiltering = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["embedSortingFiltering"]);
            })();
            if (!parsed_embedSortingFiltering.success) {
              return { success: false };
            }
            if (!hasProp(value, "hideTitle")) {
              return { success: false };
            }
            const parsed_hideTitle = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["hideTitle"]);
            })();
            if (!parsed_hideTitle.success) {
              return { success: false };
            }
            if (!hasProp(value, "itemCount")) {
              return { success: false };
            }
            const parsed_itemCount = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["itemCount"]);
            })();
            if (!parsed_itemCount.success) {
              return { success: false };
            }
            if (!hasProp(value, "itemLayout")) {
              return { success: false };
            }
            const parsed_itemLayout = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ItemLayout } => {
                return ((value: unknown): { success: false } | { success: true; value: "MINI" | "REGULAR" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "MINI":
                      return { success: true, value: "MINI" };
                    case "REGULAR":
                      return { success: true, value: "REGULAR" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["itemLayout"]);
            })();
            if (!parsed_itemLayout.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "portletId")) {
              return { success: false };
            }
            const parsed_portletId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["portletId"]);
            })();
            if (!parsed_portletId.success) {
              return { success: false };
            }
            if (!hasProp(value, "portletLayout")) {
              return { success: false };
            }
            const parsed_portletLayout = (() => {
              return ((value: unknown): { success: false } | { success: true; value: PortletLayout } => {
                return ((value: unknown): { success: false } | { success: true; value: "GRID" | "CAROUSEL" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "GRID":
                      return { success: true, value: "GRID" };
                    case "CAROUSEL":
                      return { success: true, value: "CAROUSEL" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["portletLayout"]);
            })();
            if (!parsed_portletLayout.success) {
              return { success: false };
            }
            if (!hasProp(value, "subType")) {
              return { success: false };
            }
            const parsed_subType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["subType"]);
            })();
            if (!parsed_subType.success) {
              return { success: false };
            }
            if (!hasProp(value, "subtitle")) {
              return { success: false };
            }
            const parsed_subtitle = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["subtitle"]);
            })();
            if (!parsed_subtitle.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            if (!hasProp(value, "type")) {
              return { success: false };
            }
            const parsed_type = (() => {
              return ((value: unknown): { success: false } | { success: true; value: PortletType } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | "IMAGETEXT"
                        | "SUMMARYLIST"
                        | "TRENDINGCHANNEL"
                        | "FEATUREDCHANNEL"
                        | "FOLLOWINGCHANNEL"
                        | "MYLIST"
                        | "PRIMARYTOPICCHANNEL"
                        | "CHANNELLIST"
                        | "ANNOTATIONLIST"
                        | "LGXPLIST"
                        | "SKETCHNOTESLGXP"
                        | "ACTIONABLELIST"
                        | "ACTIONABLELISTCUSTOM"
                        | "MYACTIONABLES"
                        | "FREESUMMARY"
                        | "SUMMARYLISTCUSTOM"
                        | "CUSTOMPAGELIST"
                        | "PROMOBOXCONFIG"
                        | "MYMIABS"
                        | "ASKGETABSTRACT"
                        | "CUSTOMIZEDMIABS";
                    } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "IMAGETEXT":
                      return { success: true, value: "IMAGETEXT" };
                    case "SUMMARYLIST":
                      return { success: true, value: "SUMMARYLIST" };
                    case "TRENDINGCHANNEL":
                      return { success: true, value: "TRENDINGCHANNEL" };
                    case "FEATUREDCHANNEL":
                      return { success: true, value: "FEATUREDCHANNEL" };
                    case "FOLLOWINGCHANNEL":
                      return { success: true, value: "FOLLOWINGCHANNEL" };
                    case "MYLIST":
                      return { success: true, value: "MYLIST" };
                    case "PRIMARYTOPICCHANNEL":
                      return { success: true, value: "PRIMARYTOPICCHANNEL" };
                    case "CHANNELLIST":
                      return { success: true, value: "CHANNELLIST" };
                    case "ANNOTATIONLIST":
                      return { success: true, value: "ANNOTATIONLIST" };
                    case "LGXPLIST":
                      return { success: true, value: "LGXPLIST" };
                    case "SKETCHNOTESLGXP":
                      return { success: true, value: "SKETCHNOTESLGXP" };
                    case "ACTIONABLELIST":
                      return { success: true, value: "ACTIONABLELIST" };
                    case "ACTIONABLELISTCUSTOM":
                      return { success: true, value: "ACTIONABLELISTCUSTOM" };
                    case "MYACTIONABLES":
                      return { success: true, value: "MYACTIONABLES" };
                    case "FREESUMMARY":
                      return { success: true, value: "FREESUMMARY" };
                    case "SUMMARYLISTCUSTOM":
                      return { success: true, value: "SUMMARYLISTCUSTOM" };
                    case "CUSTOMPAGELIST":
                      return { success: true, value: "CUSTOMPAGELIST" };
                    case "PROMOBOXCONFIG":
                      return { success: true, value: "PROMOBOXCONFIG" };
                    case "MYMIABS":
                      return { success: true, value: "MYMIABS" };
                    case "ASKGETABSTRACT":
                      return { success: true, value: "ASKGETABSTRACT" };
                    case "CUSTOMIZEDMIABS":
                      return { success: true, value: "CUSTOMIZEDMIABS" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["type"]);
            })();
            if (!parsed_type.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                custom: parsed_custom.value,
                defaultFilterAllLanguages: parsed_defaultFilterAllLanguages.value,
                embedSortingFiltering: parsed_embedSortingFiltering.value,
                hideTitle: parsed_hideTitle.value,
                itemCount: parsed_itemCount.value,
                itemLayout: parsed_itemLayout.value,
                name: parsed_name.value,
                portletId: parsed_portletId.value,
                portletLayout: parsed_portletLayout.value,
                subType: parsed_subType.value,
                subtitle: parsed_subtitle.value,
                title: parsed_title.value,
                type: parsed_type.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["portlets"]);
    })();
    if (!parsed_portlets.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        active: parsed_active.value,
        corporateId: parsed_corporateId.value,
        coverUrl: parsed_coverUrl.value,
        customPageId: parsed_customPageId.value,
        customPageLocalization: parsed_customPageLocalization.value,
        headerLayout: parsed_headerLayout.value,
        portlets: parsed_portlets.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageFilters
export function serialize_CustomPageFilters(obj: CustomPageFilters): string {
  const serialized = ((value: { includeInactive: boolean; onlyHighlighted: boolean }): unknown => {
    return {
      includeInactive: ((value: boolean): unknown => {
        return value;
      })(value["includeInactive"]),
      onlyHighlighted: ((value: boolean): unknown => {
        return value;
      })(value["onlyHighlighted"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageFilters(str: string): CustomPageFilters | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          includeInactive: boolean;
          onlyHighlighted: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "includeInactive")) {
      return { success: false };
    }
    const parsed_includeInactive = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["includeInactive"]);
    })();
    if (!parsed_includeInactive.success) {
      return { success: false };
    }
    if (!hasProp(value, "onlyHighlighted")) {
      return { success: false };
    }
    const parsed_onlyHighlighted = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["onlyHighlighted"]);
    })();
    if (!parsed_onlyHighlighted.success) {
      return { success: false };
    }
    return { success: true, value: { includeInactive: parsed_includeInactive.value, onlyHighlighted: parsed_onlyHighlighted.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageHeaderLayout
export function serialize_CustomPageHeaderLayout(obj: CustomPageHeaderLayout): string {
  const serialized = ((value: "NONE" | "DEFAULT"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageHeaderLayout(str: string): CustomPageHeaderLayout | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "NONE" | "DEFAULT" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "NONE":
        return { success: true, value: "NONE" };
      case "DEFAULT":
        return { success: true, value: "DEFAULT" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageHighlightsForm
export function serialize_CustomPageHighlightsForm(obj: CustomPageHighlightsForm): string {
  const serialized = ((value: { customPageIds: bigint[] }): unknown => {
    return {
      customPageIds: ((value: bigint[]): unknown => {
        return value.map((value: bigint): unknown => {
          return value;
        });
      })(value["customPageIds"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageHighlightsForm(str: string): CustomPageHighlightsForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          customPageIds: bigint[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "customPageIds")) {
      return { success: false };
    }
    const parsed_customPageIds = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: bigint } => {
          if (typeof value === "number") {
            return { success: true, value: BigInt(value) };
          }
          if (typeof value === "bigint") {
            return { success: true, value: value };
          }
          return { success: false };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["customPageIds"]);
    })();
    if (!parsed_customPageIds.success) {
      return { success: false };
    }
    return { success: true, value: { customPageIds: parsed_customPageIds.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageListView
export function serialize_CustomPageListView(obj: CustomPageListView): string {
  const serialized = ((value: { corporateId: number; coverUri: string; customPageId: bigint; description: string | null; title: string }): unknown => {
    return {
      corporateId: ((value: number): unknown => {
        return value;
      })(value["corporateId"]),
      coverUri: ((value: string): unknown => {
        return value;
      })(value["coverUri"]),
      customPageId: ((value: bigint): unknown => {
        return value;
      })(value["customPageId"]),
      description: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["description"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageListView(str: string): CustomPageListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          corporateId: number;
          coverUri: string;
          customPageId: bigint;
          description: string | null;
          title: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "coverUri")) {
      return { success: false };
    }
    const parsed_coverUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["coverUri"]);
    })();
    if (!parsed_coverUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "customPageId")) {
      return { success: false };
    }
    const parsed_customPageId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customPageId"]);
    })();
    if (!parsed_customPageId.success) {
      return { success: false };
    }
    if (!hasProp(value, "description")) {
      return { success: false };
    }
    const parsed_description = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["description"]);
    })();
    if (!parsed_description.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return { success: true, value: { corporateId: parsed_corporateId.value, coverUri: parsed_coverUri.value, customPageId: parsed_customPageId.value, description: parsed_description.value, title: parsed_title.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageLocalizationEditForm
export function serialize_CustomPageLocalizationEditForm(obj: CustomPageLocalizationEditForm): string {
  const serialized = ((value: { description: string | null; title: string }): unknown => {
    return {
      description: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["description"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageLocalizationEditForm(str: string): CustomPageLocalizationEditForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          description: string | null;
          title: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "description")) {
      return { success: false };
    }
    const parsed_description = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["description"]);
    })();
    if (!parsed_description.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return { success: true, value: { description: parsed_description.value, title: parsed_title.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageLocalizationEditView
export function serialize_CustomPageLocalizationEditView(obj: CustomPageLocalizationEditView): string {
  const serialized = ((value: { description: string | null; title: string }): unknown => {
    return {
      description: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["description"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageLocalizationEditView(str: string): CustomPageLocalizationEditView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          description: string | null;
          title: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "description")) {
      return { success: false };
    }
    const parsed_description = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["description"]);
    })();
    if (!parsed_description.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return { success: true, value: { description: parsed_description.value, title: parsed_title.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomPageView
export function serialize_CustomPageView(obj: CustomPageView): string {
  const serialized = ((value: { coverUrl: string; customPageId: bigint; description: string | null; headerLayout: CustomPageHeaderLayout; portlets: PortletMetadata[]; title: string }): unknown => {
    return {
      coverUrl: ((value: string): unknown => {
        return value;
      })(value["coverUrl"]),
      customPageId: ((value: bigint): unknown => {
        return value;
      })(value["customPageId"]),
      description: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["description"]),
      headerLayout: ((value: "NONE" | "DEFAULT"): unknown => {
        return value;
      })(value["headerLayout"]),
      portlets: ((value: PortletMetadata[]): unknown => {
        return value.map(
          (value: {
            custom: boolean;
            defaultFilterAllLanguages: boolean;
            embedSortingFiltering: boolean;
            hideTitle: boolean;
            itemCount: number;
            itemLayout: ItemLayout;
            name: string;
            portletId: number;
            portletLayout: PortletLayout;
            subType: string | null;
            subtitle: string;
            title: string;
            type: PortletType;
          }): unknown => {
            return {
              custom: ((value: boolean): unknown => {
                return value;
              })(value["custom"]),
              defaultFilterAllLanguages: ((value: boolean): unknown => {
                return value;
              })(value["defaultFilterAllLanguages"]),
              embedSortingFiltering: ((value: boolean): unknown => {
                return value;
              })(value["embedSortingFiltering"]),
              hideTitle: ((value: boolean): unknown => {
                return value;
              })(value["hideTitle"]),
              itemCount: ((value: number): unknown => {
                return value;
              })(value["itemCount"]),
              itemLayout: ((value: "MINI" | "REGULAR"): unknown => {
                return value;
              })(value["itemLayout"]),
              name: ((value: string): unknown => {
                return value;
              })(value["name"]),
              portletId: ((value: number): unknown => {
                return value;
              })(value["portletId"]),
              portletLayout: ((value: "GRID" | "CAROUSEL"): unknown => {
                return value;
              })(value["portletLayout"]),
              subType: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["subType"]),
              subtitle: ((value: string): unknown => {
                return value;
              })(value["subtitle"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
              type: ((
                value:
                  | "IMAGETEXT"
                  | "SUMMARYLIST"
                  | "TRENDINGCHANNEL"
                  | "FEATUREDCHANNEL"
                  | "FOLLOWINGCHANNEL"
                  | "MYLIST"
                  | "PRIMARYTOPICCHANNEL"
                  | "CHANNELLIST"
                  | "ANNOTATIONLIST"
                  | "LGXPLIST"
                  | "SKETCHNOTESLGXP"
                  | "ACTIONABLELIST"
                  | "ACTIONABLELISTCUSTOM"
                  | "MYACTIONABLES"
                  | "FREESUMMARY"
                  | "SUMMARYLISTCUSTOM"
                  | "CUSTOMPAGELIST"
                  | "PROMOBOXCONFIG"
                  | "MYMIABS"
                  | "ASKGETABSTRACT"
                  | "CUSTOMIZEDMIABS",
              ): unknown => {
                return value;
              })(value["type"]),
            };
          },
        );
      })(value["portlets"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomPageView(str: string): CustomPageView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          coverUrl: string;
          customPageId: bigint;
          description: string | null;
          headerLayout: CustomPageHeaderLayout;
          portlets: PortletMetadata[];
          title: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "coverUrl")) {
      return { success: false };
    }
    const parsed_coverUrl = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["coverUrl"]);
    })();
    if (!parsed_coverUrl.success) {
      return { success: false };
    }
    if (!hasProp(value, "customPageId")) {
      return { success: false };
    }
    const parsed_customPageId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customPageId"]);
    })();
    if (!parsed_customPageId.success) {
      return { success: false };
    }
    if (!hasProp(value, "description")) {
      return { success: false };
    }
    const parsed_description = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["description"]);
    })();
    if (!parsed_description.success) {
      return { success: false };
    }
    if (!hasProp(value, "headerLayout")) {
      return { success: false };
    }
    const parsed_headerLayout = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CustomPageHeaderLayout } => {
        return ((value: unknown): { success: false } | { success: true; value: "NONE" | "DEFAULT" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "NONE":
              return { success: true, value: "NONE" };
            case "DEFAULT":
              return { success: true, value: "DEFAULT" };
            default:
              return { success: false };
          }
        })(value);
      })(value["headerLayout"]);
    })();
    if (!parsed_headerLayout.success) {
      return { success: false };
    }
    if (!hasProp(value, "portlets")) {
      return { success: false };
    }
    const parsed_portlets = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PortletMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: PortletMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  custom: boolean;
                  defaultFilterAllLanguages: boolean;
                  embedSortingFiltering: boolean;
                  hideTitle: boolean;
                  itemCount: number;
                  itemLayout: ItemLayout;
                  name: string;
                  portletId: number;
                  portletLayout: PortletLayout;
                  subType: string | null;
                  subtitle: string;
                  title: string;
                  type: PortletType;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "custom")) {
              return { success: false };
            }
            const parsed_custom = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["custom"]);
            })();
            if (!parsed_custom.success) {
              return { success: false };
            }
            if (!hasProp(value, "defaultFilterAllLanguages")) {
              return { success: false };
            }
            const parsed_defaultFilterAllLanguages = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["defaultFilterAllLanguages"]);
            })();
            if (!parsed_defaultFilterAllLanguages.success) {
              return { success: false };
            }
            if (!hasProp(value, "embedSortingFiltering")) {
              return { success: false };
            }
            const parsed_embedSortingFiltering = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["embedSortingFiltering"]);
            })();
            if (!parsed_embedSortingFiltering.success) {
              return { success: false };
            }
            if (!hasProp(value, "hideTitle")) {
              return { success: false };
            }
            const parsed_hideTitle = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["hideTitle"]);
            })();
            if (!parsed_hideTitle.success) {
              return { success: false };
            }
            if (!hasProp(value, "itemCount")) {
              return { success: false };
            }
            const parsed_itemCount = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["itemCount"]);
            })();
            if (!parsed_itemCount.success) {
              return { success: false };
            }
            if (!hasProp(value, "itemLayout")) {
              return { success: false };
            }
            const parsed_itemLayout = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ItemLayout } => {
                return ((value: unknown): { success: false } | { success: true; value: "MINI" | "REGULAR" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "MINI":
                      return { success: true, value: "MINI" };
                    case "REGULAR":
                      return { success: true, value: "REGULAR" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["itemLayout"]);
            })();
            if (!parsed_itemLayout.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "portletId")) {
              return { success: false };
            }
            const parsed_portletId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["portletId"]);
            })();
            if (!parsed_portletId.success) {
              return { success: false };
            }
            if (!hasProp(value, "portletLayout")) {
              return { success: false };
            }
            const parsed_portletLayout = (() => {
              return ((value: unknown): { success: false } | { success: true; value: PortletLayout } => {
                return ((value: unknown): { success: false } | { success: true; value: "GRID" | "CAROUSEL" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "GRID":
                      return { success: true, value: "GRID" };
                    case "CAROUSEL":
                      return { success: true, value: "CAROUSEL" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["portletLayout"]);
            })();
            if (!parsed_portletLayout.success) {
              return { success: false };
            }
            if (!hasProp(value, "subType")) {
              return { success: false };
            }
            const parsed_subType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["subType"]);
            })();
            if (!parsed_subType.success) {
              return { success: false };
            }
            if (!hasProp(value, "subtitle")) {
              return { success: false };
            }
            const parsed_subtitle = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["subtitle"]);
            })();
            if (!parsed_subtitle.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            if (!hasProp(value, "type")) {
              return { success: false };
            }
            const parsed_type = (() => {
              return ((value: unknown): { success: false } | { success: true; value: PortletType } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | "IMAGETEXT"
                        | "SUMMARYLIST"
                        | "TRENDINGCHANNEL"
                        | "FEATUREDCHANNEL"
                        | "FOLLOWINGCHANNEL"
                        | "MYLIST"
                        | "PRIMARYTOPICCHANNEL"
                        | "CHANNELLIST"
                        | "ANNOTATIONLIST"
                        | "LGXPLIST"
                        | "SKETCHNOTESLGXP"
                        | "ACTIONABLELIST"
                        | "ACTIONABLELISTCUSTOM"
                        | "MYACTIONABLES"
                        | "FREESUMMARY"
                        | "SUMMARYLISTCUSTOM"
                        | "CUSTOMPAGELIST"
                        | "PROMOBOXCONFIG"
                        | "MYMIABS"
                        | "ASKGETABSTRACT"
                        | "CUSTOMIZEDMIABS";
                    } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "IMAGETEXT":
                      return { success: true, value: "IMAGETEXT" };
                    case "SUMMARYLIST":
                      return { success: true, value: "SUMMARYLIST" };
                    case "TRENDINGCHANNEL":
                      return { success: true, value: "TRENDINGCHANNEL" };
                    case "FEATUREDCHANNEL":
                      return { success: true, value: "FEATUREDCHANNEL" };
                    case "FOLLOWINGCHANNEL":
                      return { success: true, value: "FOLLOWINGCHANNEL" };
                    case "MYLIST":
                      return { success: true, value: "MYLIST" };
                    case "PRIMARYTOPICCHANNEL":
                      return { success: true, value: "PRIMARYTOPICCHANNEL" };
                    case "CHANNELLIST":
                      return { success: true, value: "CHANNELLIST" };
                    case "ANNOTATIONLIST":
                      return { success: true, value: "ANNOTATIONLIST" };
                    case "LGXPLIST":
                      return { success: true, value: "LGXPLIST" };
                    case "SKETCHNOTESLGXP":
                      return { success: true, value: "SKETCHNOTESLGXP" };
                    case "ACTIONABLELIST":
                      return { success: true, value: "ACTIONABLELIST" };
                    case "ACTIONABLELISTCUSTOM":
                      return { success: true, value: "ACTIONABLELISTCUSTOM" };
                    case "MYACTIONABLES":
                      return { success: true, value: "MYACTIONABLES" };
                    case "FREESUMMARY":
                      return { success: true, value: "FREESUMMARY" };
                    case "SUMMARYLISTCUSTOM":
                      return { success: true, value: "SUMMARYLISTCUSTOM" };
                    case "CUSTOMPAGELIST":
                      return { success: true, value: "CUSTOMPAGELIST" };
                    case "PROMOBOXCONFIG":
                      return { success: true, value: "PROMOBOXCONFIG" };
                    case "MYMIABS":
                      return { success: true, value: "MYMIABS" };
                    case "ASKGETABSTRACT":
                      return { success: true, value: "ASKGETABSTRACT" };
                    case "CUSTOMIZEDMIABS":
                      return { success: true, value: "CUSTOMIZEDMIABS" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["type"]);
            })();
            if (!parsed_type.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                custom: parsed_custom.value,
                defaultFilterAllLanguages: parsed_defaultFilterAllLanguages.value,
                embedSortingFiltering: parsed_embedSortingFiltering.value,
                hideTitle: parsed_hideTitle.value,
                itemCount: parsed_itemCount.value,
                itemLayout: parsed_itemLayout.value,
                name: parsed_name.value,
                portletId: parsed_portletId.value,
                portletLayout: parsed_portletLayout.value,
                subType: parsed_subType.value,
                subtitle: parsed_subtitle.value,
                title: parsed_title.value,
                type: parsed_type.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["portlets"]);
    })();
    if (!parsed_portlets.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return {
      success: true,
      value: { coverUrl: parsed_coverUrl.value, customPageId: parsed_customPageId.value, description: parsed_description.value, headerLayout: parsed_headerLayout.value, portlets: parsed_portlets.value, title: parsed_title.value },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomerAdminAccountDetailForm
export function serialize_CustomerAdminAccountDetailForm(obj: CustomerAdminAccountDetailForm): string {
  const serialized = ((value: {
    acceptedTerms: DateTime<true> | null;
    customerId: number;
    isTestAccount: boolean;
    lastMobileUsage: DateTime<true> | null;
    lastWebUsage: DateTime<true> | null;
    lockedOutTime: bigint | null;
    loginAsCustomerUri: string;
    roles: CustomerAdminDetailRole[];
    totalWebUsages: number | null;
  }): unknown => {
    return {
      acceptedTerms: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["acceptedTerms"]),
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
      isTestAccount: ((value: boolean): unknown => {
        return value;
      })(value["isTestAccount"]),
      lastMobileUsage: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["lastMobileUsage"]),
      lastWebUsage: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["lastWebUsage"]),
      lockedOutTime: ((value: bigint | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lockedOutTime"]),
      loginAsCustomerUri: ((value: string): unknown => {
        return value;
      })(value["loginAsCustomerUri"]),
      roles: ((value: CustomerAdminDetailRole[]): unknown => {
        return value.map((value: { corporation: string; customerEmail: string; isCorporationGroup: boolean; language: string; roleName: string }): unknown => {
          return {
            corporation: ((value: string): unknown => {
              return value;
            })(value["corporation"]),
            customerEmail: ((value: string): unknown => {
              return value;
            })(value["customerEmail"]),
            isCorporationGroup: ((value: boolean): unknown => {
              return value;
            })(value["isCorporationGroup"]),
            language: ((value: string): unknown => {
              return value;
            })(value["language"]),
            roleName: ((value: string): unknown => {
              return value;
            })(value["roleName"]),
          };
        });
      })(value["roles"]),
      totalWebUsages: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["totalWebUsages"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomerAdminAccountDetailForm(str: string): CustomerAdminAccountDetailForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          acceptedTerms: DateTime<true> | null;
          customerId: number;
          isTestAccount: boolean;
          lastMobileUsage: DateTime<true> | null;
          lastWebUsage: DateTime<true> | null;
          lockedOutTime: bigint | null;
          loginAsCustomerUri: string;
          roles: CustomerAdminDetailRole[];
          totalWebUsages: number | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "acceptedTerms")) {
      return { success: false };
    }
    const parsed_acceptedTerms = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["acceptedTerms"]);
    })();
    if (!parsed_acceptedTerms.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "isTestAccount")) {
      return { success: false };
    }
    const parsed_isTestAccount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isTestAccount"]);
    })();
    if (!parsed_isTestAccount.success) {
      return { success: false };
    }
    if (!hasProp(value, "lastMobileUsage")) {
      return { success: false };
    }
    const parsed_lastMobileUsage = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["lastMobileUsage"]);
    })();
    if (!parsed_lastMobileUsage.success) {
      return { success: false };
    }
    if (!hasProp(value, "lastWebUsage")) {
      return { success: false };
    }
    const parsed_lastWebUsage = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["lastWebUsage"]);
    })();
    if (!parsed_lastWebUsage.success) {
      return { success: false };
    }
    if (!hasProp(value, "lockedOutTime")) {
      return { success: false };
    }
    const parsed_lockedOutTime = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["lockedOutTime"]);
    })();
    if (!parsed_lockedOutTime.success) {
      return { success: false };
    }
    if (!hasProp(value, "loginAsCustomerUri")) {
      return { success: false };
    }
    const parsed_loginAsCustomerUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["loginAsCustomerUri"]);
    })();
    if (!parsed_loginAsCustomerUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "roles")) {
      return { success: false };
    }
    const parsed_roles = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CustomerAdminDetailRole[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: CustomerAdminDetailRole } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  corporation: string;
                  customerEmail: string;
                  isCorporationGroup: boolean;
                  language: string;
                  roleName: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "corporation")) {
              return { success: false };
            }
            const parsed_corporation = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["corporation"]);
            })();
            if (!parsed_corporation.success) {
              return { success: false };
            }
            if (!hasProp(value, "customerEmail")) {
              return { success: false };
            }
            const parsed_customerEmail = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["customerEmail"]);
            })();
            if (!parsed_customerEmail.success) {
              return { success: false };
            }
            if (!hasProp(value, "isCorporationGroup")) {
              return { success: false };
            }
            const parsed_isCorporationGroup = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["isCorporationGroup"]);
            })();
            if (!parsed_isCorporationGroup.success) {
              return { success: false };
            }
            if (!hasProp(value, "language")) {
              return { success: false };
            }
            const parsed_language = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["language"]);
            })();
            if (!parsed_language.success) {
              return { success: false };
            }
            if (!hasProp(value, "roleName")) {
              return { success: false };
            }
            const parsed_roleName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["roleName"]);
            })();
            if (!parsed_roleName.success) {
              return { success: false };
            }
            return {
              success: true,
              value: { corporation: parsed_corporation.value, customerEmail: parsed_customerEmail.value, isCorporationGroup: parsed_isCorporationGroup.value, language: parsed_language.value, roleName: parsed_roleName.value },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["roles"]);
    })();
    if (!parsed_roles.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalWebUsages")) {
      return { success: false };
    }
    const parsed_totalWebUsages = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalWebUsages"]);
    })();
    if (!parsed_totalWebUsages.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        acceptedTerms: parsed_acceptedTerms.value,
        customerId: parsed_customerId.value,
        isTestAccount: parsed_isTestAccount.value,
        lastMobileUsage: parsed_lastMobileUsage.value,
        lastWebUsage: parsed_lastWebUsage.value,
        lockedOutTime: parsed_lockedOutTime.value,
        loginAsCustomerUri: parsed_loginAsCustomerUri.value,
        roles: parsed_roles.value,
        totalWebUsages: parsed_totalWebUsages.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomerAdminB2BPortalsForm
export function serialize_CustomerAdminB2BPortalsForm(obj: CustomerAdminB2BPortalsForm): string {
  const serialized = ((value: {
    corporateId: number;
    corporation: string;
    corporationIsActive: boolean;
    customerIsActive: boolean;
    endDate: { year: number; month: number; day: number } | null;
    isExpired: boolean;
    responsibleCsm: string | null;
    responsibleSales: string | null;
    startDate: { year: number; month: number; day: number } | null;
    type: string | null;
  }): unknown => {
    return {
      corporateId: ((value: number): unknown => {
        return value;
      })(value["corporateId"]),
      corporation: ((value: string): unknown => {
        return value;
      })(value["corporation"]),
      corporationIsActive: ((value: boolean): unknown => {
        return value;
      })(value["corporationIsActive"]),
      customerIsActive: ((value: boolean): unknown => {
        return value;
      })(value["customerIsActive"]),
      endDate: ((value: { year: number; month: number; day: number } | null): unknown => {
        if (value === null) {
          return null;
        }
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["endDate"]),
      isExpired: ((value: boolean): unknown => {
        return value;
      })(value["isExpired"]),
      responsibleCsm: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["responsibleCsm"]),
      responsibleSales: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["responsibleSales"]),
      startDate: ((value: { year: number; month: number; day: number } | null): unknown => {
        if (value === null) {
          return null;
        }
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["startDate"]),
      type: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["type"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomerAdminB2BPortalsForm(str: string): CustomerAdminB2BPortalsForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          corporateId: number;
          corporation: string;
          corporationIsActive: boolean;
          customerIsActive: boolean;
          endDate: { year: number; month: number; day: number } | null;
          isExpired: boolean;
          responsibleCsm: string | null;
          responsibleSales: string | null;
          startDate: { year: number; month: number; day: number } | null;
          type: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "corporation")) {
      return { success: false };
    }
    const parsed_corporation = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["corporation"]);
    })();
    if (!parsed_corporation.success) {
      return { success: false };
    }
    if (!hasProp(value, "corporationIsActive")) {
      return { success: false };
    }
    const parsed_corporationIsActive = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["corporationIsActive"]);
    })();
    if (!parsed_corporationIsActive.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerIsActive")) {
      return { success: false };
    }
    const parsed_customerIsActive = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["customerIsActive"]);
    })();
    if (!parsed_customerIsActive.success) {
      return { success: false };
    }
    if (!hasProp(value, "endDate")) {
      return { success: false };
    }
    const parsed_endDate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["endDate"]);
    })();
    if (!parsed_endDate.success) {
      return { success: false };
    }
    if (!hasProp(value, "isExpired")) {
      return { success: false };
    }
    const parsed_isExpired = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isExpired"]);
    })();
    if (!parsed_isExpired.success) {
      return { success: false };
    }
    if (!hasProp(value, "responsibleCsm")) {
      return { success: false };
    }
    const parsed_responsibleCsm = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["responsibleCsm"]);
    })();
    if (!parsed_responsibleCsm.success) {
      return { success: false };
    }
    if (!hasProp(value, "responsibleSales")) {
      return { success: false };
    }
    const parsed_responsibleSales = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["responsibleSales"]);
    })();
    if (!parsed_responsibleSales.success) {
      return { success: false };
    }
    if (!hasProp(value, "startDate")) {
      return { success: false };
    }
    const parsed_startDate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["startDate"]);
    })();
    if (!parsed_startDate.success) {
      return { success: false };
    }
    if (!hasProp(value, "type")) {
      return { success: false };
    }
    const parsed_type = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["type"]);
    })();
    if (!parsed_type.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        corporateId: parsed_corporateId.value,
        corporation: parsed_corporation.value,
        corporationIsActive: parsed_corporationIsActive.value,
        customerIsActive: parsed_customerIsActive.value,
        endDate: parsed_endDate.value,
        isExpired: parsed_isExpired.value,
        responsibleCsm: parsed_responsibleCsm.value,
        responsibleSales: parsed_responsibleSales.value,
        startDate: parsed_startDate.value,
        type: parsed_type.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomerAdminB2BPortalsForms
export function serialize_CustomerAdminB2BPortalsForms(obj: CustomerAdminB2BPortalsForms): string {
  const serialized = ((value: { forms: CustomerAdminB2BPortalsForm[] }): unknown => {
    return {
      forms: ((value: CustomerAdminB2BPortalsForm[]): unknown => {
        return value.map(
          (value: {
            corporateId: number;
            corporation: string;
            corporationIsActive: boolean;
            customerIsActive: boolean;
            endDate: { year: number; month: number; day: number } | null;
            isExpired: boolean;
            responsibleCsm: string | null;
            responsibleSales: string | null;
            startDate: { year: number; month: number; day: number } | null;
            type: string | null;
          }): unknown => {
            return {
              corporateId: ((value: number): unknown => {
                return value;
              })(value["corporateId"]),
              corporation: ((value: string): unknown => {
                return value;
              })(value["corporation"]),
              corporationIsActive: ((value: boolean): unknown => {
                return value;
              })(value["corporationIsActive"]),
              customerIsActive: ((value: boolean): unknown => {
                return value;
              })(value["customerIsActive"]),
              endDate: ((value: { year: number; month: number; day: number } | null): unknown => {
                if (value === null) {
                  return null;
                }
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["endDate"]),
              isExpired: ((value: boolean): unknown => {
                return value;
              })(value["isExpired"]),
              responsibleCsm: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["responsibleCsm"]),
              responsibleSales: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["responsibleSales"]),
              startDate: ((value: { year: number; month: number; day: number } | null): unknown => {
                if (value === null) {
                  return null;
                }
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["startDate"]),
              type: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["type"]),
            };
          },
        );
      })(value["forms"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomerAdminB2BPortalsForms(str: string): CustomerAdminB2BPortalsForms | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          forms: CustomerAdminB2BPortalsForm[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "forms")) {
      return { success: false };
    }
    const parsed_forms = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CustomerAdminB2BPortalsForm[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: CustomerAdminB2BPortalsForm } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  corporateId: number;
                  corporation: string;
                  corporationIsActive: boolean;
                  customerIsActive: boolean;
                  endDate: { year: number; month: number; day: number } | null;
                  isExpired: boolean;
                  responsibleCsm: string | null;
                  responsibleSales: string | null;
                  startDate: { year: number; month: number; day: number } | null;
                  type: string | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "corporateId")) {
              return { success: false };
            }
            const parsed_corporateId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["corporateId"]);
            })();
            if (!parsed_corporateId.success) {
              return { success: false };
            }
            if (!hasProp(value, "corporation")) {
              return { success: false };
            }
            const parsed_corporation = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["corporation"]);
            })();
            if (!parsed_corporation.success) {
              return { success: false };
            }
            if (!hasProp(value, "corporationIsActive")) {
              return { success: false };
            }
            const parsed_corporationIsActive = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["corporationIsActive"]);
            })();
            if (!parsed_corporationIsActive.success) {
              return { success: false };
            }
            if (!hasProp(value, "customerIsActive")) {
              return { success: false };
            }
            const parsed_customerIsActive = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["customerIsActive"]);
            })();
            if (!parsed_customerIsActive.success) {
              return { success: false };
            }
            if (!hasProp(value, "endDate")) {
              return { success: false };
            }
            const parsed_endDate = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
              })(value["endDate"]);
            })();
            if (!parsed_endDate.success) {
              return { success: false };
            }
            if (!hasProp(value, "isExpired")) {
              return { success: false };
            }
            const parsed_isExpired = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["isExpired"]);
            })();
            if (!parsed_isExpired.success) {
              return { success: false };
            }
            if (!hasProp(value, "responsibleCsm")) {
              return { success: false };
            }
            const parsed_responsibleCsm = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["responsibleCsm"]);
            })();
            if (!parsed_responsibleCsm.success) {
              return { success: false };
            }
            if (!hasProp(value, "responsibleSales")) {
              return { success: false };
            }
            const parsed_responsibleSales = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["responsibleSales"]);
            })();
            if (!parsed_responsibleSales.success) {
              return { success: false };
            }
            if (!hasProp(value, "startDate")) {
              return { success: false };
            }
            const parsed_startDate = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
              })(value["startDate"]);
            })();
            if (!parsed_startDate.success) {
              return { success: false };
            }
            if (!hasProp(value, "type")) {
              return { success: false };
            }
            const parsed_type = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["type"]);
            })();
            if (!parsed_type.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                corporateId: parsed_corporateId.value,
                corporation: parsed_corporation.value,
                corporationIsActive: parsed_corporationIsActive.value,
                customerIsActive: parsed_customerIsActive.value,
                endDate: parsed_endDate.value,
                isExpired: parsed_isExpired.value,
                responsibleCsm: parsed_responsibleCsm.value,
                responsibleSales: parsed_responsibleSales.value,
                startDate: parsed_startDate.value,
                type: parsed_type.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["forms"]);
    })();
    if (!parsed_forms.success) {
      return { success: false };
    }
    return { success: true, value: { forms: parsed_forms.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomerAdminDetailAddress
export function serialize_CustomerAdminDetailAddress(obj: CustomerAdminDetailAddress): string {
  const serialized = ((value: {
    address1: string | null;
    address2: string | null;
    addressId: number;
    company: string | null;
    countryCode: string | null;
    department: string | null;
    firstName: string | null;
    lastName: string | null;
    phone: string | null;
    position: string | null;
    state: string | null;
    title: number;
    town: string | null;
    vat: string | null;
    zipCode: string | null;
  }): unknown => {
    return {
      address1: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["address1"]),
      address2: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["address2"]),
      addressId: ((value: number): unknown => {
        return value;
      })(value["addressId"]),
      company: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["company"]),
      countryCode: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["countryCode"]),
      department: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["department"]),
      firstName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["firstName"]),
      lastName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lastName"]),
      phone: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["phone"]),
      position: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["position"]),
      state: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["state"]),
      title: ((value: number): unknown => {
        return value;
      })(value["title"]),
      town: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["town"]),
      vat: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["vat"]),
      zipCode: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["zipCode"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomerAdminDetailAddress(str: string): CustomerAdminDetailAddress | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          address1: string | null;
          address2: string | null;
          addressId: number;
          company: string | null;
          countryCode: string | null;
          department: string | null;
          firstName: string | null;
          lastName: string | null;
          phone: string | null;
          position: string | null;
          state: string | null;
          title: number;
          town: string | null;
          vat: string | null;
          zipCode: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "address1")) {
      return { success: false };
    }
    const parsed_address1 = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["address1"]);
    })();
    if (!parsed_address1.success) {
      return { success: false };
    }
    if (!hasProp(value, "address2")) {
      return { success: false };
    }
    const parsed_address2 = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["address2"]);
    })();
    if (!parsed_address2.success) {
      return { success: false };
    }
    if (!hasProp(value, "addressId")) {
      return { success: false };
    }
    const parsed_addressId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["addressId"]);
    })();
    if (!parsed_addressId.success) {
      return { success: false };
    }
    if (!hasProp(value, "company")) {
      return { success: false };
    }
    const parsed_company = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["company"]);
    })();
    if (!parsed_company.success) {
      return { success: false };
    }
    if (!hasProp(value, "countryCode")) {
      return { success: false };
    }
    const parsed_countryCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["countryCode"]);
    })();
    if (!parsed_countryCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "department")) {
      return { success: false };
    }
    const parsed_department = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["department"]);
    })();
    if (!parsed_department.success) {
      return { success: false };
    }
    if (!hasProp(value, "firstName")) {
      return { success: false };
    }
    const parsed_firstName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["firstName"]);
    })();
    if (!parsed_firstName.success) {
      return { success: false };
    }
    if (!hasProp(value, "lastName")) {
      return { success: false };
    }
    const parsed_lastName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lastName"]);
    })();
    if (!parsed_lastName.success) {
      return { success: false };
    }
    if (!hasProp(value, "phone")) {
      return { success: false };
    }
    const parsed_phone = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["phone"]);
    })();
    if (!parsed_phone.success) {
      return { success: false };
    }
    if (!hasProp(value, "position")) {
      return { success: false };
    }
    const parsed_position = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["position"]);
    })();
    if (!parsed_position.success) {
      return { success: false };
    }
    if (!hasProp(value, "state")) {
      return { success: false };
    }
    const parsed_state = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["state"]);
    })();
    if (!parsed_state.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    if (!hasProp(value, "town")) {
      return { success: false };
    }
    const parsed_town = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["town"]);
    })();
    if (!parsed_town.success) {
      return { success: false };
    }
    if (!hasProp(value, "vat")) {
      return { success: false };
    }
    const parsed_vat = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["vat"]);
    })();
    if (!parsed_vat.success) {
      return { success: false };
    }
    if (!hasProp(value, "zipCode")) {
      return { success: false };
    }
    const parsed_zipCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["zipCode"]);
    })();
    if (!parsed_zipCode.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        address1: parsed_address1.value,
        address2: parsed_address2.value,
        addressId: parsed_addressId.value,
        company: parsed_company.value,
        countryCode: parsed_countryCode.value,
        department: parsed_department.value,
        firstName: parsed_firstName.value,
        lastName: parsed_lastName.value,
        phone: parsed_phone.value,
        position: parsed_position.value,
        state: parsed_state.value,
        title: parsed_title.value,
        town: parsed_town.value,
        vat: parsed_vat.value,
        zipCode: parsed_zipCode.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomerAdminDetailForm
export function serialize_CustomerAdminDetailForm(obj: CustomerAdminDetailForm): string {
  const serialized = ((value: {
    areaOfExpertise: AreaOfExpertise | null;
    batchEmails: boolean;
    billingAddress: CustomerAdminDetailAddress;
    careerLevel: CareerLevel | null;
    changePassword: boolean;
    commentNotification: boolean;
    customGender: string | null;
    customerId: number;
    education: boolean;
    email: string;
    gender: Gender | null;
    htmlEmail: boolean;
    invoiceByPost: boolean;
    language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
    mailAddress: CustomerAdminDetailAddress;
    password: string;
    pushFrequency: PushFrequency;
    receiveNewsletters: boolean;
    unlimitedAccess: boolean;
    yearOfBirth: number | null;
  }): unknown => {
    return {
      areaOfExpertise: ((
        value:
          | (
              | "ACCOUNTING_AND_FINANCE"
              | "ADMINISTRATION"
              | "BUSINESS_DEVELOPMENT"
              | "COMMUNICATIONS"
              | "CUSTOMER_SERVICE"
              | "HUMAN_RESOURCES"
              | "INFORMATION_TECHNOLOGY"
              | "LEGAL"
              | "MARKETING"
              | "OPERATIONS"
              | "PRODUCTION"
              | "SALES"
              | "SUPPLY_MANAGEMENT"
              | "OTHER"
              | "UNDEFINED"
            )
          | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["areaOfExpertise"]),
      batchEmails: ((value: boolean): unknown => {
        return value;
      })(value["batchEmails"]),
      billingAddress: ((value: {
        address1: string | null;
        address2: string | null;
        addressId: number;
        company: string | null;
        countryCode: string | null;
        department: string | null;
        firstName: string | null;
        lastName: string | null;
        phone: string | null;
        position: string | null;
        state: string | null;
        title: number;
        town: string | null;
        vat: string | null;
        zipCode: string | null;
      }): unknown => {
        return {
          address1: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["address1"]),
          address2: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["address2"]),
          addressId: ((value: number): unknown => {
            return value;
          })(value["addressId"]),
          company: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["company"]),
          countryCode: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["countryCode"]),
          department: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["department"]),
          firstName: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["firstName"]),
          lastName: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["lastName"]),
          phone: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["phone"]),
          position: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["position"]),
          state: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["state"]),
          title: ((value: number): unknown => {
            return value;
          })(value["title"]),
          town: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["town"]),
          vat: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["vat"]),
          zipCode: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["zipCode"]),
        };
      })(value["billingAddress"]),
      careerLevel: ((
        value:
          | (
              | "OWNER_EXECUTIVE_C_LEVEL"
              | "SENIOR_MANAGEMENT"
              | "MIDDLE_MANAGEMENT"
              | "JUNIOR_MANAGEMENT"
              | "ADMINISTRATIVE_STAFF"
              | "EMPLOYEE_WITHOUT_MANAGEMENT_OR_LEADERSHIP_RESPONSIBILITY"
              | "STUDENT_OR_TRAINEE"
              | "TEACHER_OR_ACADEMIC"
              | "NOT_EMPLOYED"
              | "RETIRED"
              | "OTHER"
              | "UNDEFINED"
            )
          | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["careerLevel"]),
      changePassword: ((value: boolean): unknown => {
        return value;
      })(value["changePassword"]),
      commentNotification: ((value: boolean): unknown => {
        return value;
      })(value["commentNotification"]),
      customGender: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["customGender"]),
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
      education: ((value: boolean): unknown => {
        return value;
      })(value["education"]),
      email: ((value: string): unknown => {
        return value;
      })(value["email"]),
      gender: ((value: ("FEMALE" | "MALE" | "UNKNOWN" | "CUSTOM") | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["gender"]),
      htmlEmail: ((value: boolean): unknown => {
        return value;
      })(value["htmlEmail"]),
      invoiceByPost: ((value: boolean): unknown => {
        return value;
      })(value["invoiceByPost"]),
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      mailAddress: ((value: {
        address1: string | null;
        address2: string | null;
        addressId: number;
        company: string | null;
        countryCode: string | null;
        department: string | null;
        firstName: string | null;
        lastName: string | null;
        phone: string | null;
        position: string | null;
        state: string | null;
        title: number;
        town: string | null;
        vat: string | null;
        zipCode: string | null;
      }): unknown => {
        return {
          address1: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["address1"]),
          address2: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["address2"]),
          addressId: ((value: number): unknown => {
            return value;
          })(value["addressId"]),
          company: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["company"]),
          countryCode: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["countryCode"]),
          department: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["department"]),
          firstName: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["firstName"]),
          lastName: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["lastName"]),
          phone: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["phone"]),
          position: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["position"]),
          state: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["state"]),
          title: ((value: number): unknown => {
            return value;
          })(value["title"]),
          town: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["town"]),
          vat: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["vat"]),
          zipCode: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["zipCode"]),
        };
      })(value["mailAddress"]),
      password: ((value: string): unknown => {
        return value;
      })(value["password"]),
      pushFrequency: ((value: "NONE" | "DAILY" | "ONCE_A_WEEK" | "TWICE_A_MONTH" | "ONCE_A_MONTH"): unknown => {
        return value;
      })(value["pushFrequency"]),
      receiveNewsletters: ((value: boolean): unknown => {
        return value;
      })(value["receiveNewsletters"]),
      unlimitedAccess: ((value: boolean): unknown => {
        return value;
      })(value["unlimitedAccess"]),
      yearOfBirth: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["yearOfBirth"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomerAdminDetailForm(str: string): CustomerAdminDetailForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          areaOfExpertise: AreaOfExpertise | null;
          batchEmails: boolean;
          billingAddress: CustomerAdminDetailAddress;
          careerLevel: CareerLevel | null;
          changePassword: boolean;
          commentNotification: boolean;
          customGender: string | null;
          customerId: number;
          education: boolean;
          email: string;
          gender: Gender | null;
          htmlEmail: boolean;
          invoiceByPost: boolean;
          language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
          mailAddress: CustomerAdminDetailAddress;
          password: string;
          pushFrequency: PushFrequency;
          receiveNewsletters: boolean;
          unlimitedAccess: boolean;
          yearOfBirth: number | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "areaOfExpertise")) {
      return { success: false };
    }
    const parsed_areaOfExpertise = (() => {
      return ((value: unknown): { success: false } | { success: true; value: AreaOfExpertise | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value:
                | "ACCOUNTING_AND_FINANCE"
                | "ADMINISTRATION"
                | "BUSINESS_DEVELOPMENT"
                | "COMMUNICATIONS"
                | "CUSTOMER_SERVICE"
                | "HUMAN_RESOURCES"
                | "INFORMATION_TECHNOLOGY"
                | "LEGAL"
                | "MARKETING"
                | "OPERATIONS"
                | "PRODUCTION"
                | "SALES"
                | "SUPPLY_MANAGEMENT"
                | "OTHER"
                | "UNDEFINED";
            } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "ACCOUNTING_AND_FINANCE":
              return { success: true, value: "ACCOUNTING_AND_FINANCE" };
            case "ADMINISTRATION":
              return { success: true, value: "ADMINISTRATION" };
            case "BUSINESS_DEVELOPMENT":
              return { success: true, value: "BUSINESS_DEVELOPMENT" };
            case "COMMUNICATIONS":
              return { success: true, value: "COMMUNICATIONS" };
            case "CUSTOMER_SERVICE":
              return { success: true, value: "CUSTOMER_SERVICE" };
            case "HUMAN_RESOURCES":
              return { success: true, value: "HUMAN_RESOURCES" };
            case "INFORMATION_TECHNOLOGY":
              return { success: true, value: "INFORMATION_TECHNOLOGY" };
            case "LEGAL":
              return { success: true, value: "LEGAL" };
            case "MARKETING":
              return { success: true, value: "MARKETING" };
            case "OPERATIONS":
              return { success: true, value: "OPERATIONS" };
            case "PRODUCTION":
              return { success: true, value: "PRODUCTION" };
            case "SALES":
              return { success: true, value: "SALES" };
            case "SUPPLY_MANAGEMENT":
              return { success: true, value: "SUPPLY_MANAGEMENT" };
            case "OTHER":
              return { success: true, value: "OTHER" };
            case "UNDEFINED":
              return { success: true, value: "UNDEFINED" };
            default:
              return { success: false };
          }
        })(value);
      })(value["areaOfExpertise"]);
    })();
    if (!parsed_areaOfExpertise.success) {
      return { success: false };
    }
    if (!hasProp(value, "batchEmails")) {
      return { success: false };
    }
    const parsed_batchEmails = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["batchEmails"]);
    })();
    if (!parsed_batchEmails.success) {
      return { success: false };
    }
    if (!hasProp(value, "billingAddress")) {
      return { success: false };
    }
    const parsed_billingAddress = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CustomerAdminDetailAddress } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                address1: string | null;
                address2: string | null;
                addressId: number;
                company: string | null;
                countryCode: string | null;
                department: string | null;
                firstName: string | null;
                lastName: string | null;
                phone: string | null;
                position: string | null;
                state: string | null;
                title: number;
                town: string | null;
                vat: string | null;
                zipCode: string | null;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "address1")) {
            return { success: false };
          }
          const parsed_address1 = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["address1"]);
          })();
          if (!parsed_address1.success) {
            return { success: false };
          }
          if (!hasProp(value, "address2")) {
            return { success: false };
          }
          const parsed_address2 = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["address2"]);
          })();
          if (!parsed_address2.success) {
            return { success: false };
          }
          if (!hasProp(value, "addressId")) {
            return { success: false };
          }
          const parsed_addressId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["addressId"]);
          })();
          if (!parsed_addressId.success) {
            return { success: false };
          }
          if (!hasProp(value, "company")) {
            return { success: false };
          }
          const parsed_company = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["company"]);
          })();
          if (!parsed_company.success) {
            return { success: false };
          }
          if (!hasProp(value, "countryCode")) {
            return { success: false };
          }
          const parsed_countryCode = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["countryCode"]);
          })();
          if (!parsed_countryCode.success) {
            return { success: false };
          }
          if (!hasProp(value, "department")) {
            return { success: false };
          }
          const parsed_department = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["department"]);
          })();
          if (!parsed_department.success) {
            return { success: false };
          }
          if (!hasProp(value, "firstName")) {
            return { success: false };
          }
          const parsed_firstName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["firstName"]);
          })();
          if (!parsed_firstName.success) {
            return { success: false };
          }
          if (!hasProp(value, "lastName")) {
            return { success: false };
          }
          const parsed_lastName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lastName"]);
          })();
          if (!parsed_lastName.success) {
            return { success: false };
          }
          if (!hasProp(value, "phone")) {
            return { success: false };
          }
          const parsed_phone = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["phone"]);
          })();
          if (!parsed_phone.success) {
            return { success: false };
          }
          if (!hasProp(value, "position")) {
            return { success: false };
          }
          const parsed_position = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["position"]);
          })();
          if (!parsed_position.success) {
            return { success: false };
          }
          if (!hasProp(value, "state")) {
            return { success: false };
          }
          const parsed_state = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["state"]);
          })();
          if (!parsed_state.success) {
            return { success: false };
          }
          if (!hasProp(value, "title")) {
            return { success: false };
          }
          const parsed_title = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["title"]);
          })();
          if (!parsed_title.success) {
            return { success: false };
          }
          if (!hasProp(value, "town")) {
            return { success: false };
          }
          const parsed_town = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["town"]);
          })();
          if (!parsed_town.success) {
            return { success: false };
          }
          if (!hasProp(value, "vat")) {
            return { success: false };
          }
          const parsed_vat = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["vat"]);
          })();
          if (!parsed_vat.success) {
            return { success: false };
          }
          if (!hasProp(value, "zipCode")) {
            return { success: false };
          }
          const parsed_zipCode = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["zipCode"]);
          })();
          if (!parsed_zipCode.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              address1: parsed_address1.value,
              address2: parsed_address2.value,
              addressId: parsed_addressId.value,
              company: parsed_company.value,
              countryCode: parsed_countryCode.value,
              department: parsed_department.value,
              firstName: parsed_firstName.value,
              lastName: parsed_lastName.value,
              phone: parsed_phone.value,
              position: parsed_position.value,
              state: parsed_state.value,
              title: parsed_title.value,
              town: parsed_town.value,
              vat: parsed_vat.value,
              zipCode: parsed_zipCode.value,
            },
          };
        })(value);
      })(value["billingAddress"]);
    })();
    if (!parsed_billingAddress.success) {
      return { success: false };
    }
    if (!hasProp(value, "careerLevel")) {
      return { success: false };
    }
    const parsed_careerLevel = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CareerLevel | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value:
                | "OWNER_EXECUTIVE_C_LEVEL"
                | "SENIOR_MANAGEMENT"
                | "MIDDLE_MANAGEMENT"
                | "JUNIOR_MANAGEMENT"
                | "ADMINISTRATIVE_STAFF"
                | "EMPLOYEE_WITHOUT_MANAGEMENT_OR_LEADERSHIP_RESPONSIBILITY"
                | "STUDENT_OR_TRAINEE"
                | "TEACHER_OR_ACADEMIC"
                | "NOT_EMPLOYED"
                | "RETIRED"
                | "OTHER"
                | "UNDEFINED";
            } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "OWNER_EXECUTIVE_C_LEVEL":
              return { success: true, value: "OWNER_EXECUTIVE_C_LEVEL" };
            case "SENIOR_MANAGEMENT":
              return { success: true, value: "SENIOR_MANAGEMENT" };
            case "MIDDLE_MANAGEMENT":
              return { success: true, value: "MIDDLE_MANAGEMENT" };
            case "JUNIOR_MANAGEMENT":
              return { success: true, value: "JUNIOR_MANAGEMENT" };
            case "ADMINISTRATIVE_STAFF":
              return { success: true, value: "ADMINISTRATIVE_STAFF" };
            case "EMPLOYEE_WITHOUT_MANAGEMENT_OR_LEADERSHIP_RESPONSIBILITY":
              return { success: true, value: "EMPLOYEE_WITHOUT_MANAGEMENT_OR_LEADERSHIP_RESPONSIBILITY" };
            case "STUDENT_OR_TRAINEE":
              return { success: true, value: "STUDENT_OR_TRAINEE" };
            case "TEACHER_OR_ACADEMIC":
              return { success: true, value: "TEACHER_OR_ACADEMIC" };
            case "NOT_EMPLOYED":
              return { success: true, value: "NOT_EMPLOYED" };
            case "RETIRED":
              return { success: true, value: "RETIRED" };
            case "OTHER":
              return { success: true, value: "OTHER" };
            case "UNDEFINED":
              return { success: true, value: "UNDEFINED" };
            default:
              return { success: false };
          }
        })(value);
      })(value["careerLevel"]);
    })();
    if (!parsed_careerLevel.success) {
      return { success: false };
    }
    if (!hasProp(value, "changePassword")) {
      return { success: false };
    }
    const parsed_changePassword = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["changePassword"]);
    })();
    if (!parsed_changePassword.success) {
      return { success: false };
    }
    if (!hasProp(value, "commentNotification")) {
      return { success: false };
    }
    const parsed_commentNotification = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["commentNotification"]);
    })();
    if (!parsed_commentNotification.success) {
      return { success: false };
    }
    if (!hasProp(value, "customGender")) {
      return { success: false };
    }
    const parsed_customGender = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["customGender"]);
    })();
    if (!parsed_customGender.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "education")) {
      return { success: false };
    }
    const parsed_education = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["education"]);
    })();
    if (!parsed_education.success) {
      return { success: false };
    }
    if (!hasProp(value, "email")) {
      return { success: false };
    }
    const parsed_email = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["email"]);
    })();
    if (!parsed_email.success) {
      return { success: false };
    }
    if (!hasProp(value, "gender")) {
      return { success: false };
    }
    const parsed_gender = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Gender | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((value: unknown): { success: false } | { success: true; value: "FEMALE" | "MALE" | "UNKNOWN" | "CUSTOM" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "FEMALE":
              return { success: true, value: "FEMALE" };
            case "MALE":
              return { success: true, value: "MALE" };
            case "UNKNOWN":
              return { success: true, value: "UNKNOWN" };
            case "CUSTOM":
              return { success: true, value: "CUSTOM" };
            default:
              return { success: false };
          }
        })(value);
      })(value["gender"]);
    })();
    if (!parsed_gender.success) {
      return { success: false };
    }
    if (!hasProp(value, "htmlEmail")) {
      return { success: false };
    }
    const parsed_htmlEmail = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["htmlEmail"]);
    })();
    if (!parsed_htmlEmail.success) {
      return { success: false };
    }
    if (!hasProp(value, "invoiceByPost")) {
      return { success: false };
    }
    const parsed_invoiceByPost = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["invoiceByPost"]);
    })();
    if (!parsed_invoiceByPost.success) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "en":
            return { success: true, value: "en" };
          case "de":
            return { success: true, value: "de" };
          case "es":
            return { success: true, value: "es" };
          case "ru":
            return { success: true, value: "ru" };
          case "zh":
            return { success: true, value: "zh" };
          case "pt":
            return { success: true, value: "pt" };
          case "fr":
            return { success: true, value: "fr" };
          default:
            return { success: false };
        }
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "mailAddress")) {
      return { success: false };
    }
    const parsed_mailAddress = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CustomerAdminDetailAddress } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                address1: string | null;
                address2: string | null;
                addressId: number;
                company: string | null;
                countryCode: string | null;
                department: string | null;
                firstName: string | null;
                lastName: string | null;
                phone: string | null;
                position: string | null;
                state: string | null;
                title: number;
                town: string | null;
                vat: string | null;
                zipCode: string | null;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "address1")) {
            return { success: false };
          }
          const parsed_address1 = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["address1"]);
          })();
          if (!parsed_address1.success) {
            return { success: false };
          }
          if (!hasProp(value, "address2")) {
            return { success: false };
          }
          const parsed_address2 = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["address2"]);
          })();
          if (!parsed_address2.success) {
            return { success: false };
          }
          if (!hasProp(value, "addressId")) {
            return { success: false };
          }
          const parsed_addressId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["addressId"]);
          })();
          if (!parsed_addressId.success) {
            return { success: false };
          }
          if (!hasProp(value, "company")) {
            return { success: false };
          }
          const parsed_company = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["company"]);
          })();
          if (!parsed_company.success) {
            return { success: false };
          }
          if (!hasProp(value, "countryCode")) {
            return { success: false };
          }
          const parsed_countryCode = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["countryCode"]);
          })();
          if (!parsed_countryCode.success) {
            return { success: false };
          }
          if (!hasProp(value, "department")) {
            return { success: false };
          }
          const parsed_department = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["department"]);
          })();
          if (!parsed_department.success) {
            return { success: false };
          }
          if (!hasProp(value, "firstName")) {
            return { success: false };
          }
          const parsed_firstName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["firstName"]);
          })();
          if (!parsed_firstName.success) {
            return { success: false };
          }
          if (!hasProp(value, "lastName")) {
            return { success: false };
          }
          const parsed_lastName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lastName"]);
          })();
          if (!parsed_lastName.success) {
            return { success: false };
          }
          if (!hasProp(value, "phone")) {
            return { success: false };
          }
          const parsed_phone = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["phone"]);
          })();
          if (!parsed_phone.success) {
            return { success: false };
          }
          if (!hasProp(value, "position")) {
            return { success: false };
          }
          const parsed_position = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["position"]);
          })();
          if (!parsed_position.success) {
            return { success: false };
          }
          if (!hasProp(value, "state")) {
            return { success: false };
          }
          const parsed_state = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["state"]);
          })();
          if (!parsed_state.success) {
            return { success: false };
          }
          if (!hasProp(value, "title")) {
            return { success: false };
          }
          const parsed_title = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["title"]);
          })();
          if (!parsed_title.success) {
            return { success: false };
          }
          if (!hasProp(value, "town")) {
            return { success: false };
          }
          const parsed_town = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["town"]);
          })();
          if (!parsed_town.success) {
            return { success: false };
          }
          if (!hasProp(value, "vat")) {
            return { success: false };
          }
          const parsed_vat = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["vat"]);
          })();
          if (!parsed_vat.success) {
            return { success: false };
          }
          if (!hasProp(value, "zipCode")) {
            return { success: false };
          }
          const parsed_zipCode = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["zipCode"]);
          })();
          if (!parsed_zipCode.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              address1: parsed_address1.value,
              address2: parsed_address2.value,
              addressId: parsed_addressId.value,
              company: parsed_company.value,
              countryCode: parsed_countryCode.value,
              department: parsed_department.value,
              firstName: parsed_firstName.value,
              lastName: parsed_lastName.value,
              phone: parsed_phone.value,
              position: parsed_position.value,
              state: parsed_state.value,
              title: parsed_title.value,
              town: parsed_town.value,
              vat: parsed_vat.value,
              zipCode: parsed_zipCode.value,
            },
          };
        })(value);
      })(value["mailAddress"]);
    })();
    if (!parsed_mailAddress.success) {
      return { success: false };
    }
    if (!hasProp(value, "password")) {
      return { success: false };
    }
    const parsed_password = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["password"]);
    })();
    if (!parsed_password.success) {
      return { success: false };
    }
    if (!hasProp(value, "pushFrequency")) {
      return { success: false };
    }
    const parsed_pushFrequency = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PushFrequency } => {
        return ((value: unknown): { success: false } | { success: true; value: "NONE" | "DAILY" | "ONCE_A_WEEK" | "TWICE_A_MONTH" | "ONCE_A_MONTH" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "NONE":
              return { success: true, value: "NONE" };
            case "DAILY":
              return { success: true, value: "DAILY" };
            case "ONCE_A_WEEK":
              return { success: true, value: "ONCE_A_WEEK" };
            case "TWICE_A_MONTH":
              return { success: true, value: "TWICE_A_MONTH" };
            case "ONCE_A_MONTH":
              return { success: true, value: "ONCE_A_MONTH" };
            default:
              return { success: false };
          }
        })(value);
      })(value["pushFrequency"]);
    })();
    if (!parsed_pushFrequency.success) {
      return { success: false };
    }
    if (!hasProp(value, "receiveNewsletters")) {
      return { success: false };
    }
    const parsed_receiveNewsletters = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["receiveNewsletters"]);
    })();
    if (!parsed_receiveNewsletters.success) {
      return { success: false };
    }
    if (!hasProp(value, "unlimitedAccess")) {
      return { success: false };
    }
    const parsed_unlimitedAccess = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["unlimitedAccess"]);
    })();
    if (!parsed_unlimitedAccess.success) {
      return { success: false };
    }
    if (!hasProp(value, "yearOfBirth")) {
      return { success: false };
    }
    const parsed_yearOfBirth = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["yearOfBirth"]);
    })();
    if (!parsed_yearOfBirth.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        areaOfExpertise: parsed_areaOfExpertise.value,
        batchEmails: parsed_batchEmails.value,
        billingAddress: parsed_billingAddress.value,
        careerLevel: parsed_careerLevel.value,
        changePassword: parsed_changePassword.value,
        commentNotification: parsed_commentNotification.value,
        customGender: parsed_customGender.value,
        customerId: parsed_customerId.value,
        education: parsed_education.value,
        email: parsed_email.value,
        gender: parsed_gender.value,
        htmlEmail: parsed_htmlEmail.value,
        invoiceByPost: parsed_invoiceByPost.value,
        language: parsed_language.value,
        mailAddress: parsed_mailAddress.value,
        password: parsed_password.value,
        pushFrequency: parsed_pushFrequency.value,
        receiveNewsletters: parsed_receiveNewsletters.value,
        unlimitedAccess: parsed_unlimitedAccess.value,
        yearOfBirth: parsed_yearOfBirth.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomerAdminDetailRole
export function serialize_CustomerAdminDetailRole(obj: CustomerAdminDetailRole): string {
  const serialized = ((value: { corporation: string; customerEmail: string; isCorporationGroup: boolean; language: string; roleName: string }): unknown => {
    return {
      corporation: ((value: string): unknown => {
        return value;
      })(value["corporation"]),
      customerEmail: ((value: string): unknown => {
        return value;
      })(value["customerEmail"]),
      isCorporationGroup: ((value: boolean): unknown => {
        return value;
      })(value["isCorporationGroup"]),
      language: ((value: string): unknown => {
        return value;
      })(value["language"]),
      roleName: ((value: string): unknown => {
        return value;
      })(value["roleName"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomerAdminDetailRole(str: string): CustomerAdminDetailRole | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          corporation: string;
          customerEmail: string;
          isCorporationGroup: boolean;
          language: string;
          roleName: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "corporation")) {
      return { success: false };
    }
    const parsed_corporation = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["corporation"]);
    })();
    if (!parsed_corporation.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerEmail")) {
      return { success: false };
    }
    const parsed_customerEmail = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["customerEmail"]);
    })();
    if (!parsed_customerEmail.success) {
      return { success: false };
    }
    if (!hasProp(value, "isCorporationGroup")) {
      return { success: false };
    }
    const parsed_isCorporationGroup = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isCorporationGroup"]);
    })();
    if (!parsed_isCorporationGroup.success) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "roleName")) {
      return { success: false };
    }
    const parsed_roleName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["roleName"]);
    })();
    if (!parsed_roleName.success) {
      return { success: false };
    }
    return {
      success: true,
      value: { corporation: parsed_corporation.value, customerEmail: parsed_customerEmail.value, isCorporationGroup: parsed_isCorporationGroup.value, language: parsed_language.value, roleName: parsed_roleName.value },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomerAdminDetailWidgetProps
export function serialize_CustomerAdminDetailWidgetProps(obj: CustomerAdminDetailWidgetProps): string {
  const serialized = ((value: { customerId: number }): unknown => {
    return {
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomerAdminDetailWidgetProps(str: string): CustomerAdminDetailWidgetProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          customerId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    return { success: true, value: { customerId: parsed_customerId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomerAdminFreeAbstractCoupon
export function serialize_CustomerAdminFreeAbstractCoupon(obj: CustomerAdminFreeAbstractCoupon): string {
  const serialized = ((value: {
    affiliateCorporateId: number;
    affiliateCorporation: string;
    affiliateUserName: string;
    couponBalance: number;
    couponRegistationBalance: number;
    registrationDate: { year: number; month: number; day: number };
    summaryGroupId: number;
  }): unknown => {
    return {
      affiliateCorporateId: ((value: number): unknown => {
        return value;
      })(value["affiliateCorporateId"]),
      affiliateCorporation: ((value: string): unknown => {
        return value;
      })(value["affiliateCorporation"]),
      affiliateUserName: ((value: string): unknown => {
        return value;
      })(value["affiliateUserName"]),
      couponBalance: ((value: number): unknown => {
        return value;
      })(value["couponBalance"]),
      couponRegistationBalance: ((value: number): unknown => {
        return value;
      })(value["couponRegistationBalance"]),
      registrationDate: ((value: { year: number; month: number; day: number }): unknown => {
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["registrationDate"]),
      summaryGroupId: ((value: number): unknown => {
        return value;
      })(value["summaryGroupId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomerAdminFreeAbstractCoupon(str: string): CustomerAdminFreeAbstractCoupon | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          affiliateCorporateId: number;
          affiliateCorporation: string;
          affiliateUserName: string;
          couponBalance: number;
          couponRegistationBalance: number;
          registrationDate: { year: number; month: number; day: number };
          summaryGroupId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "affiliateCorporateId")) {
      return { success: false };
    }
    const parsed_affiliateCorporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["affiliateCorporateId"]);
    })();
    if (!parsed_affiliateCorporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "affiliateCorporation")) {
      return { success: false };
    }
    const parsed_affiliateCorporation = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["affiliateCorporation"]);
    })();
    if (!parsed_affiliateCorporation.success) {
      return { success: false };
    }
    if (!hasProp(value, "affiliateUserName")) {
      return { success: false };
    }
    const parsed_affiliateUserName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["affiliateUserName"]);
    })();
    if (!parsed_affiliateUserName.success) {
      return { success: false };
    }
    if (!hasProp(value, "couponBalance")) {
      return { success: false };
    }
    const parsed_couponBalance = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["couponBalance"]);
    })();
    if (!parsed_couponBalance.success) {
      return { success: false };
    }
    if (!hasProp(value, "couponRegistationBalance")) {
      return { success: false };
    }
    const parsed_couponRegistationBalance = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["couponRegistationBalance"]);
    })();
    if (!parsed_couponRegistationBalance.success) {
      return { success: false };
    }
    if (!hasProp(value, "registrationDate")) {
      return { success: false };
    }
    const parsed_registrationDate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["registrationDate"]);
    })();
    if (!parsed_registrationDate.success) {
      return { success: false };
    }
    if (!hasProp(value, "summaryGroupId")) {
      return { success: false };
    }
    const parsed_summaryGroupId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["summaryGroupId"]);
    })();
    if (!parsed_summaryGroupId.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        affiliateCorporateId: parsed_affiliateCorporateId.value,
        affiliateCorporation: parsed_affiliateCorporation.value,
        affiliateUserName: parsed_affiliateUserName.value,
        couponBalance: parsed_couponBalance.value,
        couponRegistationBalance: parsed_couponRegistationBalance.value,
        registrationDate: parsed_registrationDate.value,
        summaryGroupId: parsed_summaryGroupId.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomerAdminFreeAbstractCoupons
export function serialize_CustomerAdminFreeAbstractCoupons(obj: CustomerAdminFreeAbstractCoupons): string {
  const serialized = ((value: { coupons: CustomerAdminFreeAbstractCoupon[] }): unknown => {
    return {
      coupons: ((value: CustomerAdminFreeAbstractCoupon[]): unknown => {
        return value.map(
          (value: {
            affiliateCorporateId: number;
            affiliateCorporation: string;
            affiliateUserName: string;
            couponBalance: number;
            couponRegistationBalance: number;
            registrationDate: { year: number; month: number; day: number };
            summaryGroupId: number;
          }): unknown => {
            return {
              affiliateCorporateId: ((value: number): unknown => {
                return value;
              })(value["affiliateCorporateId"]),
              affiliateCorporation: ((value: string): unknown => {
                return value;
              })(value["affiliateCorporation"]),
              affiliateUserName: ((value: string): unknown => {
                return value;
              })(value["affiliateUserName"]),
              couponBalance: ((value: number): unknown => {
                return value;
              })(value["couponBalance"]),
              couponRegistationBalance: ((value: number): unknown => {
                return value;
              })(value["couponRegistationBalance"]),
              registrationDate: ((value: { year: number; month: number; day: number }): unknown => {
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["registrationDate"]),
              summaryGroupId: ((value: number): unknown => {
                return value;
              })(value["summaryGroupId"]),
            };
          },
        );
      })(value["coupons"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomerAdminFreeAbstractCoupons(str: string): CustomerAdminFreeAbstractCoupons | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          coupons: CustomerAdminFreeAbstractCoupon[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "coupons")) {
      return { success: false };
    }
    const parsed_coupons = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CustomerAdminFreeAbstractCoupon[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: CustomerAdminFreeAbstractCoupon } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  affiliateCorporateId: number;
                  affiliateCorporation: string;
                  affiliateUserName: string;
                  couponBalance: number;
                  couponRegistationBalance: number;
                  registrationDate: { year: number; month: number; day: number };
                  summaryGroupId: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "affiliateCorporateId")) {
              return { success: false };
            }
            const parsed_affiliateCorporateId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["affiliateCorporateId"]);
            })();
            if (!parsed_affiliateCorporateId.success) {
              return { success: false };
            }
            if (!hasProp(value, "affiliateCorporation")) {
              return { success: false };
            }
            const parsed_affiliateCorporation = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["affiliateCorporation"]);
            })();
            if (!parsed_affiliateCorporation.success) {
              return { success: false };
            }
            if (!hasProp(value, "affiliateUserName")) {
              return { success: false };
            }
            const parsed_affiliateUserName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["affiliateUserName"]);
            })();
            if (!parsed_affiliateUserName.success) {
              return { success: false };
            }
            if (!hasProp(value, "couponBalance")) {
              return { success: false };
            }
            const parsed_couponBalance = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["couponBalance"]);
            })();
            if (!parsed_couponBalance.success) {
              return { success: false };
            }
            if (!hasProp(value, "couponRegistationBalance")) {
              return { success: false };
            }
            const parsed_couponRegistationBalance = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["couponRegistationBalance"]);
            })();
            if (!parsed_couponRegistationBalance.success) {
              return { success: false };
            }
            if (!hasProp(value, "registrationDate")) {
              return { success: false };
            }
            const parsed_registrationDate = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
              })(value["registrationDate"]);
            })();
            if (!parsed_registrationDate.success) {
              return { success: false };
            }
            if (!hasProp(value, "summaryGroupId")) {
              return { success: false };
            }
            const parsed_summaryGroupId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["summaryGroupId"]);
            })();
            if (!parsed_summaryGroupId.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                affiliateCorporateId: parsed_affiliateCorporateId.value,
                affiliateCorporation: parsed_affiliateCorporation.value,
                affiliateUserName: parsed_affiliateUserName.value,
                couponBalance: parsed_couponBalance.value,
                couponRegistationBalance: parsed_couponRegistationBalance.value,
                registrationDate: parsed_registrationDate.value,
                summaryGroupId: parsed_summaryGroupId.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["coupons"]);
    })();
    if (!parsed_coupons.success) {
      return { success: false };
    }
    return { success: true, value: { coupons: parsed_coupons.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomerAdminMobileAppForm
export function serialize_CustomerAdminMobileAppForm(obj: CustomerAdminMobileAppForm): string {
  const serialized = ((value: { loginId: number; loginPreferences: MobileAppLoginPreferenceListView[]; mobileDevices: MobileDevice[]; oAuth2Tokens: OAuth2Token[]; showLoginPreferences: boolean }): unknown => {
    return {
      loginId: ((value: number): unknown => {
        return value;
      })(value["loginId"]),
      loginPreferences: ((value: MobileAppLoginPreferenceListView[]): unknown => {
        return value.map((value: { corporateId: number; corporationName: string }): unknown => {
          return {
            corporateId: ((value: number): unknown => {
              return value;
            })(value["corporateId"]),
            corporationName: ((value: string): unknown => {
              return value;
            })(value["corporationName"]),
          };
        });
      })(value["loginPreferences"]),
      mobileDevices: ((value: MobileDevice[]): unknown => {
        return value.map(
          (value: {
            corporateId: number;
            customerId: number;
            deviceId: string;
            deviceType: string;
            firstUsedAt: DateTime<true>;
            language: Language;
            lastPushAt: DateTime<true> | null;
            lastUsedAt: DateTime<true>;
            osType: string | null;
            pushFrequency: PushFrequency;
            pushToken: string | null;
            version: string;
          }): unknown => {
            return {
              corporateId: ((value: number): unknown => {
                return value;
              })(value["corporateId"]),
              customerId: ((value: number): unknown => {
                return value;
              })(value["customerId"]),
              deviceId: ((value: string): unknown => {
                return value;
              })(value["deviceId"]),
              deviceType: ((value: string): unknown => {
                return value;
              })(value["deviceType"]),
              firstUsedAt: ((value: DateTime<true>): unknown => {
                return value.toMillis();
              })(value["firstUsedAt"]),
              language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                return value;
              })(value["language"]),
              lastPushAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["lastPushAt"]),
              lastUsedAt: ((value: DateTime<true>): unknown => {
                return value.toMillis();
              })(value["lastUsedAt"]),
              osType: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["osType"]),
              pushFrequency: ((value: "NONE" | "DAILY" | "ONCE_A_WEEK" | "TWICE_A_MONTH" | "ONCE_A_MONTH"): unknown => {
                return value;
              })(value["pushFrequency"]),
              pushToken: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["pushToken"]),
              version: ((value: string): unknown => {
                return value;
              })(value["version"]),
            };
          },
        );
      })(value["mobileDevices"]),
      oAuth2Tokens: ((value: OAuth2Token[]): unknown => {
        return value.map(
          (value: {
            accessTokenExpiredAt: DateTime<true> | null;
            accessTokenValue: string | null;
            corporateId: number;
            isExpired: boolean;
            refreshTokenExpiredAt: DateTime<true> | null;
            refreshTokenValue: string | null;
            tokenType: string | null;
          }): unknown => {
            return {
              accessTokenExpiredAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["accessTokenExpiredAt"]),
              accessTokenValue: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["accessTokenValue"]),
              corporateId: ((value: number): unknown => {
                return value;
              })(value["corporateId"]),
              isExpired: ((value: boolean): unknown => {
                return value;
              })(value["isExpired"]),
              refreshTokenExpiredAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["refreshTokenExpiredAt"]),
              refreshTokenValue: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["refreshTokenValue"]),
              tokenType: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["tokenType"]),
            };
          },
        );
      })(value["oAuth2Tokens"]),
      showLoginPreferences: ((value: boolean): unknown => {
        return value;
      })(value["showLoginPreferences"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomerAdminMobileAppForm(str: string): CustomerAdminMobileAppForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          loginId: number;
          loginPreferences: MobileAppLoginPreferenceListView[];
          mobileDevices: MobileDevice[];
          oAuth2Tokens: OAuth2Token[];
          showLoginPreferences: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "loginId")) {
      return { success: false };
    }
    const parsed_loginId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["loginId"]);
    })();
    if (!parsed_loginId.success) {
      return { success: false };
    }
    if (!hasProp(value, "loginPreferences")) {
      return { success: false };
    }
    const parsed_loginPreferences = (() => {
      return ((value: unknown): { success: false } | { success: true; value: MobileAppLoginPreferenceListView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: MobileAppLoginPreferenceListView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  corporateId: number;
                  corporationName: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "corporateId")) {
              return { success: false };
            }
            const parsed_corporateId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["corporateId"]);
            })();
            if (!parsed_corporateId.success) {
              return { success: false };
            }
            if (!hasProp(value, "corporationName")) {
              return { success: false };
            }
            const parsed_corporationName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["corporationName"]);
            })();
            if (!parsed_corporationName.success) {
              return { success: false };
            }
            return { success: true, value: { corporateId: parsed_corporateId.value, corporationName: parsed_corporationName.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["loginPreferences"]);
    })();
    if (!parsed_loginPreferences.success) {
      return { success: false };
    }
    if (!hasProp(value, "mobileDevices")) {
      return { success: false };
    }
    const parsed_mobileDevices = (() => {
      return ((value: unknown): { success: false } | { success: true; value: MobileDevice[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: MobileDevice } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  corporateId: number;
                  customerId: number;
                  deviceId: string;
                  deviceType: string;
                  firstUsedAt: DateTime<true>;
                  language: Language;
                  lastPushAt: DateTime<true> | null;
                  lastUsedAt: DateTime<true>;
                  osType: string | null;
                  pushFrequency: PushFrequency;
                  pushToken: string | null;
                  version: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "corporateId")) {
              return { success: false };
            }
            const parsed_corporateId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["corporateId"]);
            })();
            if (!parsed_corporateId.success) {
              return { success: false };
            }
            if (!hasProp(value, "customerId")) {
              return { success: false };
            }
            const parsed_customerId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["customerId"]);
            })();
            if (!parsed_customerId.success) {
              return { success: false };
            }
            if (!hasProp(value, "deviceId")) {
              return { success: false };
            }
            const parsed_deviceId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["deviceId"]);
            })();
            if (!parsed_deviceId.success) {
              return { success: false };
            }
            if (!hasProp(value, "deviceType")) {
              return { success: false };
            }
            const parsed_deviceType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["deviceType"]);
            })();
            if (!parsed_deviceType.success) {
              return { success: false };
            }
            if (!hasProp(value, "firstUsedAt")) {
              return { success: false };
            }
            const parsed_firstUsedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["firstUsedAt"]);
            })();
            if (!parsed_firstUsedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "language")) {
              return { success: false };
            }
            const parsed_language = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Language } => {
                return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "en":
                      return { success: true, value: "en" };
                    case "de":
                      return { success: true, value: "de" };
                    case "es":
                      return { success: true, value: "es" };
                    case "ru":
                      return { success: true, value: "ru" };
                    case "zh":
                      return { success: true, value: "zh" };
                    case "pt":
                      return { success: true, value: "pt" };
                    case "fr":
                      return { success: true, value: "fr" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["language"]);
            })();
            if (!parsed_language.success) {
              return { success: false };
            }
            if (!hasProp(value, "lastPushAt")) {
              return { success: false };
            }
            const parsed_lastPushAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["lastPushAt"]);
            })();
            if (!parsed_lastPushAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "lastUsedAt")) {
              return { success: false };
            }
            const parsed_lastUsedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["lastUsedAt"]);
            })();
            if (!parsed_lastUsedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "osType")) {
              return { success: false };
            }
            const parsed_osType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["osType"]);
            })();
            if (!parsed_osType.success) {
              return { success: false };
            }
            if (!hasProp(value, "pushFrequency")) {
              return { success: false };
            }
            const parsed_pushFrequency = (() => {
              return ((value: unknown): { success: false } | { success: true; value: PushFrequency } => {
                return ((value: unknown): { success: false } | { success: true; value: "NONE" | "DAILY" | "ONCE_A_WEEK" | "TWICE_A_MONTH" | "ONCE_A_MONTH" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "NONE":
                      return { success: true, value: "NONE" };
                    case "DAILY":
                      return { success: true, value: "DAILY" };
                    case "ONCE_A_WEEK":
                      return { success: true, value: "ONCE_A_WEEK" };
                    case "TWICE_A_MONTH":
                      return { success: true, value: "TWICE_A_MONTH" };
                    case "ONCE_A_MONTH":
                      return { success: true, value: "ONCE_A_MONTH" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["pushFrequency"]);
            })();
            if (!parsed_pushFrequency.success) {
              return { success: false };
            }
            if (!hasProp(value, "pushToken")) {
              return { success: false };
            }
            const parsed_pushToken = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["pushToken"]);
            })();
            if (!parsed_pushToken.success) {
              return { success: false };
            }
            if (!hasProp(value, "version")) {
              return { success: false };
            }
            const parsed_version = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["version"]);
            })();
            if (!parsed_version.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                corporateId: parsed_corporateId.value,
                customerId: parsed_customerId.value,
                deviceId: parsed_deviceId.value,
                deviceType: parsed_deviceType.value,
                firstUsedAt: parsed_firstUsedAt.value,
                language: parsed_language.value,
                lastPushAt: parsed_lastPushAt.value,
                lastUsedAt: parsed_lastUsedAt.value,
                osType: parsed_osType.value,
                pushFrequency: parsed_pushFrequency.value,
                pushToken: parsed_pushToken.value,
                version: parsed_version.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["mobileDevices"]);
    })();
    if (!parsed_mobileDevices.success) {
      return { success: false };
    }
    if (!hasProp(value, "oAuth2Tokens")) {
      return { success: false };
    }
    const parsed_oAuth2Tokens = (() => {
      return ((value: unknown): { success: false } | { success: true; value: OAuth2Token[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: OAuth2Token } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  accessTokenExpiredAt: DateTime<true> | null;
                  accessTokenValue: string | null;
                  corporateId: number;
                  isExpired: boolean;
                  refreshTokenExpiredAt: DateTime<true> | null;
                  refreshTokenValue: string | null;
                  tokenType: string | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "accessTokenExpiredAt")) {
              return { success: false };
            }
            const parsed_accessTokenExpiredAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["accessTokenExpiredAt"]);
            })();
            if (!parsed_accessTokenExpiredAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "accessTokenValue")) {
              return { success: false };
            }
            const parsed_accessTokenValue = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["accessTokenValue"]);
            })();
            if (!parsed_accessTokenValue.success) {
              return { success: false };
            }
            if (!hasProp(value, "corporateId")) {
              return { success: false };
            }
            const parsed_corporateId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["corporateId"]);
            })();
            if (!parsed_corporateId.success) {
              return { success: false };
            }
            if (!hasProp(value, "isExpired")) {
              return { success: false };
            }
            const parsed_isExpired = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["isExpired"]);
            })();
            if (!parsed_isExpired.success) {
              return { success: false };
            }
            if (!hasProp(value, "refreshTokenExpiredAt")) {
              return { success: false };
            }
            const parsed_refreshTokenExpiredAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["refreshTokenExpiredAt"]);
            })();
            if (!parsed_refreshTokenExpiredAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "refreshTokenValue")) {
              return { success: false };
            }
            const parsed_refreshTokenValue = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["refreshTokenValue"]);
            })();
            if (!parsed_refreshTokenValue.success) {
              return { success: false };
            }
            if (!hasProp(value, "tokenType")) {
              return { success: false };
            }
            const parsed_tokenType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["tokenType"]);
            })();
            if (!parsed_tokenType.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                accessTokenExpiredAt: parsed_accessTokenExpiredAt.value,
                accessTokenValue: parsed_accessTokenValue.value,
                corporateId: parsed_corporateId.value,
                isExpired: parsed_isExpired.value,
                refreshTokenExpiredAt: parsed_refreshTokenExpiredAt.value,
                refreshTokenValue: parsed_refreshTokenValue.value,
                tokenType: parsed_tokenType.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["oAuth2Tokens"]);
    })();
    if (!parsed_oAuth2Tokens.success) {
      return { success: false };
    }
    if (!hasProp(value, "showLoginPreferences")) {
      return { success: false };
    }
    const parsed_showLoginPreferences = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["showLoginPreferences"]);
    })();
    if (!parsed_showLoginPreferences.success) {
      return { success: false };
    }
    return {
      success: true,
      value: { loginId: parsed_loginId.value, loginPreferences: parsed_loginPreferences.value, mobileDevices: parsed_mobileDevices.value, oAuth2Tokens: parsed_oAuth2Tokens.value, showLoginPreferences: parsed_showLoginPreferences.value },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomerAdminSubscriptionsForm
export function serialize_CustomerAdminSubscriptionsForm(obj: CustomerAdminSubscriptionsForm): string {
  const serialized = ((value: {
    customerId: number;
    customerLanguageId: number;
    customerName: string;
    customerRandomCode: string;
    emailHistory: B2CRenewalEmails[];
    hasStudentSubscription: boolean;
    studentPreverification: StudentVerificationView | null;
    subscriptions: B2CSubscription[];
  }): unknown => {
    return {
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
      customerLanguageId: ((value: number): unknown => {
        return value;
      })(value["customerLanguageId"]),
      customerName: ((value: string): unknown => {
        return value;
      })(value["customerName"]),
      customerRandomCode: ((value: string): unknown => {
        return value;
      })(value["customerRandomCode"]),
      emailHistory: ((value: B2CRenewalEmails[]): unknown => {
        return value.map((value: { name: string; sendDate: DateTime<true>; type: string }): unknown => {
          return {
            name: ((value: string): unknown => {
              return value;
            })(value["name"]),
            sendDate: ((value: DateTime<true>): unknown => {
              return value.toMillis();
            })(value["sendDate"]),
            type: ((value: string): unknown => {
              return value;
            })(value["type"]),
          };
        });
      })(value["emailHistory"]),
      hasStudentSubscription: ((value: boolean): unknown => {
        return value;
      })(value["hasStudentSubscription"]),
      studentPreverification: ((
        value: {
          countryOfStudy: string;
          createdAt: DateTime<true>;
          fieldOfStudy: string | null;
          proof: string | null;
          provider: StudentIdVerificationProvider;
          status: StudentVerificationStatus;
          subscriptionId: number | null;
          verifiedAt: DateTime<true> | null;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          countryOfStudy: ((value: string): unknown => {
            return value;
          })(value["countryOfStudy"]),
          createdAt: ((value: DateTime<true>): unknown => {
            return value.toMillis();
          })(value["createdAt"]),
          fieldOfStudy: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["fieldOfStudy"]),
          proof: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["proof"]),
          provider: ((value: "UNIDAYS" | "UNIDAYS_PERK" | "SHEERID" | "MANUAL" | "UNKNOWN"): unknown => {
            return value;
          })(value["provider"]),
          status: ((value: "VERIFIED" | "EXPIRED" | "FAILED" | "IN_PROGRESS"): unknown => {
            return value;
          })(value["status"]),
          subscriptionId: ((value: number | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["subscriptionId"]),
          verifiedAt: ((value: DateTime<true> | null): unknown => {
            if (value === null) {
              return null;
            }
            return value.toMillis();
          })(value["verifiedAt"]),
        };
      })(value["studentPreverification"]),
      subscriptions: ((value: B2CSubscription[]): unknown => {
        return value.map(
          (value: {
            actionStatus: string | null;
            customerId: number;
            editSubscriptionUrl: string;
            endDate: { year: number; month: number; day: number };
            giftSubscription: GiftSubscription | null;
            isCredited: boolean;
            isExpired: boolean;
            isLoss: boolean;
            isMobileSubscription: boolean;
            isPaid: boolean;
            isTeamSubscription: boolean;
            manualRenewalUrl: string;
            numberOfTransactions: number;
            product: string;
            productId: number;
            quantity: number;
            randomKey: string | null;
            startDate: { year: number; month: number; day: number };
            studentVerification: StudentVerificationView | null;
            subscriptionId: number;
            subscriptionPayment: SubscriptionPayment;
            subscriptionStatus: SubscriptionStatus;
            teamSubscription: TeamSubscription | null;
          }): unknown => {
            return {
              actionStatus: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["actionStatus"]),
              customerId: ((value: number): unknown => {
                return value;
              })(value["customerId"]),
              editSubscriptionUrl: ((value: string): unknown => {
                return value;
              })(value["editSubscriptionUrl"]),
              endDate: ((value: { year: number; month: number; day: number }): unknown => {
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["endDate"]),
              giftSubscription: ((
                value: {
                  donorSubscription: GiftDonorSubscription | null;
                  giftReceiverSubscription: GiftReceiverSubscription | null;
                } | null,
              ): unknown => {
                if (value === null) {
                  return null;
                }
                return {
                  donorSubscription: ((
                    value: {
                      donorCustomerId: number;
                      donorSubscriptionId: number;
                    } | null,
                  ): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return {
                      donorCustomerId: ((value: number): unknown => {
                        return value;
                      })(value["donorCustomerId"]),
                      donorSubscriptionId: ((value: number): unknown => {
                        return value;
                      })(value["donorSubscriptionId"]),
                    };
                  })(value["donorSubscription"]),
                  giftReceiverSubscription: ((
                    value: {
                      receiverCustomerId: number;
                      receiverSubscriptionId: number;
                    } | null,
                  ): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return {
                      receiverCustomerId: ((value: number): unknown => {
                        return value;
                      })(value["receiverCustomerId"]),
                      receiverSubscriptionId: ((value: number): unknown => {
                        return value;
                      })(value["receiverSubscriptionId"]),
                    };
                  })(value["giftReceiverSubscription"]),
                };
              })(value["giftSubscription"]),
              isCredited: ((value: boolean): unknown => {
                return value;
              })(value["isCredited"]),
              isExpired: ((value: boolean): unknown => {
                return value;
              })(value["isExpired"]),
              isLoss: ((value: boolean): unknown => {
                return value;
              })(value["isLoss"]),
              isMobileSubscription: ((value: boolean): unknown => {
                return value;
              })(value["isMobileSubscription"]),
              isPaid: ((value: boolean): unknown => {
                return value;
              })(value["isPaid"]),
              isTeamSubscription: ((value: boolean): unknown => {
                return value;
              })(value["isTeamSubscription"]),
              manualRenewalUrl: ((value: string): unknown => {
                return value;
              })(value["manualRenewalUrl"]),
              numberOfTransactions: ((value: number): unknown => {
                return value;
              })(value["numberOfTransactions"]),
              product: ((value: string): unknown => {
                return value;
              })(value["product"]),
              productId: ((value: number): unknown => {
                return value;
              })(value["productId"]),
              quantity: ((value: number): unknown => {
                return value;
              })(value["quantity"]),
              randomKey: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["randomKey"]),
              startDate: ((value: { year: number; month: number; day: number }): unknown => {
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["startDate"]),
              studentVerification: ((
                value: {
                  countryOfStudy: string;
                  createdAt: DateTime<true>;
                  fieldOfStudy: string | null;
                  proof: string | null;
                  provider: StudentIdVerificationProvider;
                  status: StudentVerificationStatus;
                  subscriptionId: number | null;
                  verifiedAt: DateTime<true> | null;
                } | null,
              ): unknown => {
                if (value === null) {
                  return null;
                }
                return {
                  countryOfStudy: ((value: string): unknown => {
                    return value;
                  })(value["countryOfStudy"]),
                  createdAt: ((value: DateTime<true>): unknown => {
                    return value.toMillis();
                  })(value["createdAt"]),
                  fieldOfStudy: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["fieldOfStudy"]),
                  proof: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["proof"]),
                  provider: ((value: "UNIDAYS" | "UNIDAYS_PERK" | "SHEERID" | "MANUAL" | "UNKNOWN"): unknown => {
                    return value;
                  })(value["provider"]),
                  status: ((value: "VERIFIED" | "EXPIRED" | "FAILED" | "IN_PROGRESS"): unknown => {
                    return value;
                  })(value["status"]),
                  subscriptionId: ((value: number | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["subscriptionId"]),
                  verifiedAt: ((value: DateTime<true> | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value.toMillis();
                  })(value["verifiedAt"]),
                };
              })(value["studentVerification"]),
              subscriptionId: ((value: number): unknown => {
                return value;
              })(value["subscriptionId"]),
              subscriptionPayment: ((value: { balance: MonetaryAmount; isMobilePayment: boolean; paymentType: string | null }): unknown => {
                return {
                  balance: ((value: { amount: number; currencyCode: string; displayValue: string }): unknown => {
                    return {
                      amount: ((value: number): unknown => {
                        return value;
                      })(value["amount"]),
                      currencyCode: ((value: string): unknown => {
                        return value;
                      })(value["currencyCode"]),
                      displayValue: ((value: string): unknown => {
                        return value;
                      })(value["displayValue"]),
                    };
                  })(value["balance"]),
                  isMobilePayment: ((value: boolean): unknown => {
                    return value;
                  })(value["isMobilePayment"]),
                  paymentType: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["paymentType"]),
                };
              })(value["subscriptionPayment"]),
              subscriptionStatus: ((value: "ACTIVE" | "STOP" | "CANCEL" | "RENEWED" | "RENEWALFAILED"): unknown => {
                return value;
              })(value["subscriptionStatus"]),
              teamSubscription: ((
                value: {
                  teamPortalCorporateId: number;
                  teamPortalUrl: string;
                  teamPortalUsername: string;
                } | null,
              ): unknown => {
                if (value === null) {
                  return null;
                }
                return {
                  teamPortalCorporateId: ((value: number): unknown => {
                    return value;
                  })(value["teamPortalCorporateId"]),
                  teamPortalUrl: ((value: string): unknown => {
                    return value;
                  })(value["teamPortalUrl"]),
                  teamPortalUsername: ((value: string): unknown => {
                    return value;
                  })(value["teamPortalUsername"]),
                };
              })(value["teamSubscription"]),
            };
          },
        );
      })(value["subscriptions"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomerAdminSubscriptionsForm(str: string): CustomerAdminSubscriptionsForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          customerId: number;
          customerLanguageId: number;
          customerName: string;
          customerRandomCode: string;
          emailHistory: B2CRenewalEmails[];
          hasStudentSubscription: boolean;
          studentPreverification: StudentVerificationView | null;
          subscriptions: B2CSubscription[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerLanguageId")) {
      return { success: false };
    }
    const parsed_customerLanguageId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerLanguageId"]);
    })();
    if (!parsed_customerLanguageId.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerName")) {
      return { success: false };
    }
    const parsed_customerName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["customerName"]);
    })();
    if (!parsed_customerName.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerRandomCode")) {
      return { success: false };
    }
    const parsed_customerRandomCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["customerRandomCode"]);
    })();
    if (!parsed_customerRandomCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "emailHistory")) {
      return { success: false };
    }
    const parsed_emailHistory = (() => {
      return ((value: unknown): { success: false } | { success: true; value: B2CRenewalEmails[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: B2CRenewalEmails } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  name: string;
                  sendDate: DateTime<true>;
                  type: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "sendDate")) {
              return { success: false };
            }
            const parsed_sendDate = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["sendDate"]);
            })();
            if (!parsed_sendDate.success) {
              return { success: false };
            }
            if (!hasProp(value, "type")) {
              return { success: false };
            }
            const parsed_type = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["type"]);
            })();
            if (!parsed_type.success) {
              return { success: false };
            }
            return { success: true, value: { name: parsed_name.value, sendDate: parsed_sendDate.value, type: parsed_type.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["emailHistory"]);
    })();
    if (!parsed_emailHistory.success) {
      return { success: false };
    }
    if (!hasProp(value, "hasStudentSubscription")) {
      return { success: false };
    }
    const parsed_hasStudentSubscription = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["hasStudentSubscription"]);
    })();
    if (!parsed_hasStudentSubscription.success) {
      return { success: false };
    }
    if (!hasProp(value, "studentPreverification")) {
      return { success: false };
    }
    const parsed_studentPreverification = (() => {
      return ((value: unknown): { success: false } | { success: true; value: StudentVerificationView | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                countryOfStudy: string;
                createdAt: DateTime<true>;
                fieldOfStudy: string | null;
                proof: string | null;
                provider: StudentIdVerificationProvider;
                status: StudentVerificationStatus;
                subscriptionId: number | null;
                verifiedAt: DateTime<true> | null;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "countryOfStudy")) {
            return { success: false };
          }
          const parsed_countryOfStudy = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["countryOfStudy"]);
          })();
          if (!parsed_countryOfStudy.success) {
            return { success: false };
          }
          if (!hasProp(value, "createdAt")) {
            return { success: false };
          }
          const parsed_createdAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
              if (typeof value !== "number") {
                return { success: false };
              }
              const parsed = DateTime.fromMillis(value);
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: parsed };
            })(value["createdAt"]);
          })();
          if (!parsed_createdAt.success) {
            return { success: false };
          }
          if (!hasProp(value, "fieldOfStudy")) {
            return { success: false };
          }
          const parsed_fieldOfStudy = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["fieldOfStudy"]);
          })();
          if (!parsed_fieldOfStudy.success) {
            return { success: false };
          }
          if (!hasProp(value, "proof")) {
            return { success: false };
          }
          const parsed_proof = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["proof"]);
          })();
          if (!parsed_proof.success) {
            return { success: false };
          }
          if (!hasProp(value, "provider")) {
            return { success: false };
          }
          const parsed_provider = (() => {
            return ((value: unknown): { success: false } | { success: true; value: StudentIdVerificationProvider } => {
              return ((value: unknown): { success: false } | { success: true; value: "UNIDAYS" | "UNIDAYS_PERK" | "SHEERID" | "MANUAL" | "UNKNOWN" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "UNIDAYS":
                    return { success: true, value: "UNIDAYS" };
                  case "UNIDAYS_PERK":
                    return { success: true, value: "UNIDAYS_PERK" };
                  case "SHEERID":
                    return { success: true, value: "SHEERID" };
                  case "MANUAL":
                    return { success: true, value: "MANUAL" };
                  case "UNKNOWN":
                    return { success: true, value: "UNKNOWN" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["provider"]);
          })();
          if (!parsed_provider.success) {
            return { success: false };
          }
          if (!hasProp(value, "status")) {
            return { success: false };
          }
          const parsed_status = (() => {
            return ((value: unknown): { success: false } | { success: true; value: StudentVerificationStatus } => {
              return ((value: unknown): { success: false } | { success: true; value: "VERIFIED" | "EXPIRED" | "FAILED" | "IN_PROGRESS" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "VERIFIED":
                    return { success: true, value: "VERIFIED" };
                  case "EXPIRED":
                    return { success: true, value: "EXPIRED" };
                  case "FAILED":
                    return { success: true, value: "FAILED" };
                  case "IN_PROGRESS":
                    return { success: true, value: "IN_PROGRESS" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["status"]);
          })();
          if (!parsed_status.success) {
            return { success: false };
          }
          if (!hasProp(value, "subscriptionId")) {
            return { success: false };
          }
          const parsed_subscriptionId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["subscriptionId"]);
          })();
          if (!parsed_subscriptionId.success) {
            return { success: false };
          }
          if (!hasProp(value, "verifiedAt")) {
            return { success: false };
          }
          const parsed_verifiedAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "number") {
                return { success: false };
              }
              const parsed = DateTime.fromMillis(value);
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: parsed };
            })(value["verifiedAt"]);
          })();
          if (!parsed_verifiedAt.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              countryOfStudy: parsed_countryOfStudy.value,
              createdAt: parsed_createdAt.value,
              fieldOfStudy: parsed_fieldOfStudy.value,
              proof: parsed_proof.value,
              provider: parsed_provider.value,
              status: parsed_status.value,
              subscriptionId: parsed_subscriptionId.value,
              verifiedAt: parsed_verifiedAt.value,
            },
          };
        })(value);
      })(value["studentPreverification"]);
    })();
    if (!parsed_studentPreverification.success) {
      return { success: false };
    }
    if (!hasProp(value, "subscriptions")) {
      return { success: false };
    }
    const parsed_subscriptions = (() => {
      return ((value: unknown): { success: false } | { success: true; value: B2CSubscription[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: B2CSubscription } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  actionStatus: string | null;
                  customerId: number;
                  editSubscriptionUrl: string;
                  endDate: { year: number; month: number; day: number };
                  giftSubscription: GiftSubscription | null;
                  isCredited: boolean;
                  isExpired: boolean;
                  isLoss: boolean;
                  isMobileSubscription: boolean;
                  isPaid: boolean;
                  isTeamSubscription: boolean;
                  manualRenewalUrl: string;
                  numberOfTransactions: number;
                  product: string;
                  productId: number;
                  quantity: number;
                  randomKey: string | null;
                  startDate: { year: number; month: number; day: number };
                  studentVerification: StudentVerificationView | null;
                  subscriptionId: number;
                  subscriptionPayment: SubscriptionPayment;
                  subscriptionStatus: SubscriptionStatus;
                  teamSubscription: TeamSubscription | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "actionStatus")) {
              return { success: false };
            }
            const parsed_actionStatus = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["actionStatus"]);
            })();
            if (!parsed_actionStatus.success) {
              return { success: false };
            }
            if (!hasProp(value, "customerId")) {
              return { success: false };
            }
            const parsed_customerId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["customerId"]);
            })();
            if (!parsed_customerId.success) {
              return { success: false };
            }
            if (!hasProp(value, "editSubscriptionUrl")) {
              return { success: false };
            }
            const parsed_editSubscriptionUrl = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["editSubscriptionUrl"]);
            })();
            if (!parsed_editSubscriptionUrl.success) {
              return { success: false };
            }
            if (!hasProp(value, "endDate")) {
              return { success: false };
            }
            const parsed_endDate = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
              })(value["endDate"]);
            })();
            if (!parsed_endDate.success) {
              return { success: false };
            }
            if (!hasProp(value, "giftSubscription")) {
              return { success: false };
            }
            const parsed_giftSubscription = (() => {
              return ((value: unknown): { success: false } | { success: true; value: GiftSubscription | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        donorSubscription: GiftDonorSubscription | null;
                        giftReceiverSubscription: GiftReceiverSubscription | null;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "donorSubscription")) {
                    return { success: false };
                  }
                  const parsed_donorSubscription = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: GiftDonorSubscription | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              donorCustomerId: number;
                              donorSubscriptionId: number;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "donorCustomerId")) {
                          return { success: false };
                        }
                        const parsed_donorCustomerId = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: number } => {
                            if (typeof value === "number") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["donorCustomerId"]);
                        })();
                        if (!parsed_donorCustomerId.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "donorSubscriptionId")) {
                          return { success: false };
                        }
                        const parsed_donorSubscriptionId = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: number } => {
                            if (typeof value === "number") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["donorSubscriptionId"]);
                        })();
                        if (!parsed_donorSubscriptionId.success) {
                          return { success: false };
                        }
                        return { success: true, value: { donorCustomerId: parsed_donorCustomerId.value, donorSubscriptionId: parsed_donorSubscriptionId.value } };
                      })(value);
                    })(value["donorSubscription"]);
                  })();
                  if (!parsed_donorSubscription.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "giftReceiverSubscription")) {
                    return { success: false };
                  }
                  const parsed_giftReceiverSubscription = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: GiftReceiverSubscription | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              receiverCustomerId: number;
                              receiverSubscriptionId: number;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "receiverCustomerId")) {
                          return { success: false };
                        }
                        const parsed_receiverCustomerId = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: number } => {
                            if (typeof value === "number") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["receiverCustomerId"]);
                        })();
                        if (!parsed_receiverCustomerId.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "receiverSubscriptionId")) {
                          return { success: false };
                        }
                        const parsed_receiverSubscriptionId = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: number } => {
                            if (typeof value === "number") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["receiverSubscriptionId"]);
                        })();
                        if (!parsed_receiverSubscriptionId.success) {
                          return { success: false };
                        }
                        return { success: true, value: { receiverCustomerId: parsed_receiverCustomerId.value, receiverSubscriptionId: parsed_receiverSubscriptionId.value } };
                      })(value);
                    })(value["giftReceiverSubscription"]);
                  })();
                  if (!parsed_giftReceiverSubscription.success) {
                    return { success: false };
                  }
                  return { success: true, value: { donorSubscription: parsed_donorSubscription.value, giftReceiverSubscription: parsed_giftReceiverSubscription.value } };
                })(value);
              })(value["giftSubscription"]);
            })();
            if (!parsed_giftSubscription.success) {
              return { success: false };
            }
            if (!hasProp(value, "isCredited")) {
              return { success: false };
            }
            const parsed_isCredited = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["isCredited"]);
            })();
            if (!parsed_isCredited.success) {
              return { success: false };
            }
            if (!hasProp(value, "isExpired")) {
              return { success: false };
            }
            const parsed_isExpired = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["isExpired"]);
            })();
            if (!parsed_isExpired.success) {
              return { success: false };
            }
            if (!hasProp(value, "isLoss")) {
              return { success: false };
            }
            const parsed_isLoss = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["isLoss"]);
            })();
            if (!parsed_isLoss.success) {
              return { success: false };
            }
            if (!hasProp(value, "isMobileSubscription")) {
              return { success: false };
            }
            const parsed_isMobileSubscription = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["isMobileSubscription"]);
            })();
            if (!parsed_isMobileSubscription.success) {
              return { success: false };
            }
            if (!hasProp(value, "isPaid")) {
              return { success: false };
            }
            const parsed_isPaid = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["isPaid"]);
            })();
            if (!parsed_isPaid.success) {
              return { success: false };
            }
            if (!hasProp(value, "isTeamSubscription")) {
              return { success: false };
            }
            const parsed_isTeamSubscription = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["isTeamSubscription"]);
            })();
            if (!parsed_isTeamSubscription.success) {
              return { success: false };
            }
            if (!hasProp(value, "manualRenewalUrl")) {
              return { success: false };
            }
            const parsed_manualRenewalUrl = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["manualRenewalUrl"]);
            })();
            if (!parsed_manualRenewalUrl.success) {
              return { success: false };
            }
            if (!hasProp(value, "numberOfTransactions")) {
              return { success: false };
            }
            const parsed_numberOfTransactions = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["numberOfTransactions"]);
            })();
            if (!parsed_numberOfTransactions.success) {
              return { success: false };
            }
            if (!hasProp(value, "product")) {
              return { success: false };
            }
            const parsed_product = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["product"]);
            })();
            if (!parsed_product.success) {
              return { success: false };
            }
            if (!hasProp(value, "productId")) {
              return { success: false };
            }
            const parsed_productId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["productId"]);
            })();
            if (!parsed_productId.success) {
              return { success: false };
            }
            if (!hasProp(value, "quantity")) {
              return { success: false };
            }
            const parsed_quantity = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["quantity"]);
            })();
            if (!parsed_quantity.success) {
              return { success: false };
            }
            if (!hasProp(value, "randomKey")) {
              return { success: false };
            }
            const parsed_randomKey = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["randomKey"]);
            })();
            if (!parsed_randomKey.success) {
              return { success: false };
            }
            if (!hasProp(value, "startDate")) {
              return { success: false };
            }
            const parsed_startDate = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
              })(value["startDate"]);
            })();
            if (!parsed_startDate.success) {
              return { success: false };
            }
            if (!hasProp(value, "studentVerification")) {
              return { success: false };
            }
            const parsed_studentVerification = (() => {
              return ((value: unknown): { success: false } | { success: true; value: StudentVerificationView | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        countryOfStudy: string;
                        createdAt: DateTime<true>;
                        fieldOfStudy: string | null;
                        proof: string | null;
                        provider: StudentIdVerificationProvider;
                        status: StudentVerificationStatus;
                        subscriptionId: number | null;
                        verifiedAt: DateTime<true> | null;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "countryOfStudy")) {
                    return { success: false };
                  }
                  const parsed_countryOfStudy = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["countryOfStudy"]);
                  })();
                  if (!parsed_countryOfStudy.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "createdAt")) {
                    return { success: false };
                  }
                  const parsed_createdAt = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                      if (typeof value !== "number") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromMillis(value);
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: parsed };
                    })(value["createdAt"]);
                  })();
                  if (!parsed_createdAt.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "fieldOfStudy")) {
                    return { success: false };
                  }
                  const parsed_fieldOfStudy = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["fieldOfStudy"]);
                  })();
                  if (!parsed_fieldOfStudy.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "proof")) {
                    return { success: false };
                  }
                  const parsed_proof = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["proof"]);
                  })();
                  if (!parsed_proof.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "provider")) {
                    return { success: false };
                  }
                  const parsed_provider = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: StudentIdVerificationProvider } => {
                      return ((value: unknown): { success: false } | { success: true; value: "UNIDAYS" | "UNIDAYS_PERK" | "SHEERID" | "MANUAL" | "UNKNOWN" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "UNIDAYS":
                            return { success: true, value: "UNIDAYS" };
                          case "UNIDAYS_PERK":
                            return { success: true, value: "UNIDAYS_PERK" };
                          case "SHEERID":
                            return { success: true, value: "SHEERID" };
                          case "MANUAL":
                            return { success: true, value: "MANUAL" };
                          case "UNKNOWN":
                            return { success: true, value: "UNKNOWN" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["provider"]);
                  })();
                  if (!parsed_provider.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "status")) {
                    return { success: false };
                  }
                  const parsed_status = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: StudentVerificationStatus } => {
                      return ((value: unknown): { success: false } | { success: true; value: "VERIFIED" | "EXPIRED" | "FAILED" | "IN_PROGRESS" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "VERIFIED":
                            return { success: true, value: "VERIFIED" };
                          case "EXPIRED":
                            return { success: true, value: "EXPIRED" };
                          case "FAILED":
                            return { success: true, value: "FAILED" };
                          case "IN_PROGRESS":
                            return { success: true, value: "IN_PROGRESS" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["status"]);
                  })();
                  if (!parsed_status.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "subscriptionId")) {
                    return { success: false };
                  }
                  const parsed_subscriptionId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["subscriptionId"]);
                  })();
                  if (!parsed_subscriptionId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "verifiedAt")) {
                    return { success: false };
                  }
                  const parsed_verifiedAt = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "number") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromMillis(value);
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: parsed };
                    })(value["verifiedAt"]);
                  })();
                  if (!parsed_verifiedAt.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      countryOfStudy: parsed_countryOfStudy.value,
                      createdAt: parsed_createdAt.value,
                      fieldOfStudy: parsed_fieldOfStudy.value,
                      proof: parsed_proof.value,
                      provider: parsed_provider.value,
                      status: parsed_status.value,
                      subscriptionId: parsed_subscriptionId.value,
                      verifiedAt: parsed_verifiedAt.value,
                    },
                  };
                })(value);
              })(value["studentVerification"]);
            })();
            if (!parsed_studentVerification.success) {
              return { success: false };
            }
            if (!hasProp(value, "subscriptionId")) {
              return { success: false };
            }
            const parsed_subscriptionId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["subscriptionId"]);
            })();
            if (!parsed_subscriptionId.success) {
              return { success: false };
            }
            if (!hasProp(value, "subscriptionPayment")) {
              return { success: false };
            }
            const parsed_subscriptionPayment = (() => {
              return ((value: unknown): { success: false } | { success: true; value: SubscriptionPayment } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        balance: MonetaryAmount;
                        isMobilePayment: boolean;
                        paymentType: string | null;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "balance")) {
                    return { success: false };
                  }
                  const parsed_balance = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: MonetaryAmount } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              amount: number;
                              currencyCode: string;
                              displayValue: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "amount")) {
                          return { success: false };
                        }
                        const parsed_amount = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: number } => {
                            if (typeof value === "number") {
                              return { success: true, value: value };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: Number(value) };
                            }
                            return { success: false };
                          })(value["amount"]);
                        })();
                        if (!parsed_amount.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "currencyCode")) {
                          return { success: false };
                        }
                        const parsed_currencyCode = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["currencyCode"]);
                        })();
                        if (!parsed_currencyCode.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "displayValue")) {
                          return { success: false };
                        }
                        const parsed_displayValue = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["displayValue"]);
                        })();
                        if (!parsed_displayValue.success) {
                          return { success: false };
                        }
                        return { success: true, value: { amount: parsed_amount.value, currencyCode: parsed_currencyCode.value, displayValue: parsed_displayValue.value } };
                      })(value);
                    })(value["balance"]);
                  })();
                  if (!parsed_balance.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "isMobilePayment")) {
                    return { success: false };
                  }
                  const parsed_isMobilePayment = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["isMobilePayment"]);
                  })();
                  if (!parsed_isMobilePayment.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "paymentType")) {
                    return { success: false };
                  }
                  const parsed_paymentType = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["paymentType"]);
                  })();
                  if (!parsed_paymentType.success) {
                    return { success: false };
                  }
                  return { success: true, value: { balance: parsed_balance.value, isMobilePayment: parsed_isMobilePayment.value, paymentType: parsed_paymentType.value } };
                })(value);
              })(value["subscriptionPayment"]);
            })();
            if (!parsed_subscriptionPayment.success) {
              return { success: false };
            }
            if (!hasProp(value, "subscriptionStatus")) {
              return { success: false };
            }
            const parsed_subscriptionStatus = (() => {
              return ((value: unknown): { success: false } | { success: true; value: SubscriptionStatus } => {
                return ((value: unknown): { success: false } | { success: true; value: "ACTIVE" | "STOP" | "CANCEL" | "RENEWED" | "RENEWALFAILED" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "ACTIVE":
                      return { success: true, value: "ACTIVE" };
                    case "STOP":
                      return { success: true, value: "STOP" };
                    case "CANCEL":
                      return { success: true, value: "CANCEL" };
                    case "RENEWED":
                      return { success: true, value: "RENEWED" };
                    case "RENEWALFAILED":
                      return { success: true, value: "RENEWALFAILED" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["subscriptionStatus"]);
            })();
            if (!parsed_subscriptionStatus.success) {
              return { success: false };
            }
            if (!hasProp(value, "teamSubscription")) {
              return { success: false };
            }
            const parsed_teamSubscription = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TeamSubscription | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        teamPortalCorporateId: number;
                        teamPortalUrl: string;
                        teamPortalUsername: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "teamPortalCorporateId")) {
                    return { success: false };
                  }
                  const parsed_teamPortalCorporateId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["teamPortalCorporateId"]);
                  })();
                  if (!parsed_teamPortalCorporateId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "teamPortalUrl")) {
                    return { success: false };
                  }
                  const parsed_teamPortalUrl = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["teamPortalUrl"]);
                  })();
                  if (!parsed_teamPortalUrl.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "teamPortalUsername")) {
                    return { success: false };
                  }
                  const parsed_teamPortalUsername = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["teamPortalUsername"]);
                  })();
                  if (!parsed_teamPortalUsername.success) {
                    return { success: false };
                  }
                  return { success: true, value: { teamPortalCorporateId: parsed_teamPortalCorporateId.value, teamPortalUrl: parsed_teamPortalUrl.value, teamPortalUsername: parsed_teamPortalUsername.value } };
                })(value);
              })(value["teamSubscription"]);
            })();
            if (!parsed_teamSubscription.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                actionStatus: parsed_actionStatus.value,
                customerId: parsed_customerId.value,
                editSubscriptionUrl: parsed_editSubscriptionUrl.value,
                endDate: parsed_endDate.value,
                giftSubscription: parsed_giftSubscription.value,
                isCredited: parsed_isCredited.value,
                isExpired: parsed_isExpired.value,
                isLoss: parsed_isLoss.value,
                isMobileSubscription: parsed_isMobileSubscription.value,
                isPaid: parsed_isPaid.value,
                isTeamSubscription: parsed_isTeamSubscription.value,
                manualRenewalUrl: parsed_manualRenewalUrl.value,
                numberOfTransactions: parsed_numberOfTransactions.value,
                product: parsed_product.value,
                productId: parsed_productId.value,
                quantity: parsed_quantity.value,
                randomKey: parsed_randomKey.value,
                startDate: parsed_startDate.value,
                studentVerification: parsed_studentVerification.value,
                subscriptionId: parsed_subscriptionId.value,
                subscriptionPayment: parsed_subscriptionPayment.value,
                subscriptionStatus: parsed_subscriptionStatus.value,
                teamSubscription: parsed_teamSubscription.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["subscriptions"]);
    })();
    if (!parsed_subscriptions.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        customerId: parsed_customerId.value,
        customerLanguageId: parsed_customerLanguageId.value,
        customerName: parsed_customerName.value,
        customerRandomCode: parsed_customerRandomCode.value,
        emailHistory: parsed_emailHistory.value,
        hasStudentSubscription: parsed_hasStudentSubscription.value,
        studentPreverification: parsed_studentPreverification.value,
        subscriptions: parsed_subscriptions.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomerCommentForm
export function serialize_CustomerCommentForm(obj: CustomerCommentForm): string {
  const serialized = ((value: { comment: string; commentId: number; customerId: number; dateEntered: DateTime<true>; isMutable: boolean | null; operator: string | null }): unknown => {
    return {
      comment: ((value: string): unknown => {
        return value;
      })(value["comment"]),
      commentId: ((value: number): unknown => {
        return value;
      })(value["commentId"]),
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
      dateEntered: ((value: DateTime<true>): unknown => {
        return value.toMillis();
      })(value["dateEntered"]),
      isMutable: ((value: boolean | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["isMutable"]),
      operator: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["operator"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomerCommentForm(str: string): CustomerCommentForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          comment: string;
          commentId: number;
          customerId: number;
          dateEntered: DateTime<true>;
          isMutable: boolean | null;
          operator: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "comment")) {
      return { success: false };
    }
    const parsed_comment = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["comment"]);
    })();
    if (!parsed_comment.success) {
      return { success: false };
    }
    if (!hasProp(value, "commentId")) {
      return { success: false };
    }
    const parsed_commentId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["commentId"]);
    })();
    if (!parsed_commentId.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "dateEntered")) {
      return { success: false };
    }
    const parsed_dateEntered = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["dateEntered"]);
    })();
    if (!parsed_dateEntered.success) {
      return { success: false };
    }
    if (!hasProp(value, "isMutable")) {
      return { success: false };
    }
    const parsed_isMutable = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isMutable"]);
    })();
    if (!parsed_isMutable.success) {
      return { success: false };
    }
    if (!hasProp(value, "operator")) {
      return { success: false };
    }
    const parsed_operator = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["operator"]);
    })();
    if (!parsed_operator.success) {
      return { success: false };
    }
    return {
      success: true,
      value: { comment: parsed_comment.value, commentId: parsed_commentId.value, customerId: parsed_customerId.value, dateEntered: parsed_dateEntered.value, isMutable: parsed_isMutable.value, operator: parsed_operator.value },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomerCommentForms
export function serialize_CustomerCommentForms(obj: CustomerCommentForms): string {
  const serialized = ((value: { items: CustomerCommentForm[] }): unknown => {
    return {
      items: ((value: CustomerCommentForm[]): unknown => {
        return value.map((value: { comment: string; commentId: number; customerId: number; dateEntered: DateTime<true>; isMutable: boolean | null; operator: string | null }): unknown => {
          return {
            comment: ((value: string): unknown => {
              return value;
            })(value["comment"]),
            commentId: ((value: number): unknown => {
              return value;
            })(value["commentId"]),
            customerId: ((value: number): unknown => {
              return value;
            })(value["customerId"]),
            dateEntered: ((value: DateTime<true>): unknown => {
              return value.toMillis();
            })(value["dateEntered"]),
            isMutable: ((value: boolean | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["isMutable"]),
            operator: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["operator"]),
          };
        });
      })(value["items"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomerCommentForms(str: string): CustomerCommentForms | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: CustomerCommentForm[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CustomerCommentForm[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: CustomerCommentForm } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  comment: string;
                  commentId: number;
                  customerId: number;
                  dateEntered: DateTime<true>;
                  isMutable: boolean | null;
                  operator: string | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "comment")) {
              return { success: false };
            }
            const parsed_comment = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["comment"]);
            })();
            if (!parsed_comment.success) {
              return { success: false };
            }
            if (!hasProp(value, "commentId")) {
              return { success: false };
            }
            const parsed_commentId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["commentId"]);
            })();
            if (!parsed_commentId.success) {
              return { success: false };
            }
            if (!hasProp(value, "customerId")) {
              return { success: false };
            }
            const parsed_customerId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["customerId"]);
            })();
            if (!parsed_customerId.success) {
              return { success: false };
            }
            if (!hasProp(value, "dateEntered")) {
              return { success: false };
            }
            const parsed_dateEntered = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["dateEntered"]);
            })();
            if (!parsed_dateEntered.success) {
              return { success: false };
            }
            if (!hasProp(value, "isMutable")) {
              return { success: false };
            }
            const parsed_isMutable = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["isMutable"]);
            })();
            if (!parsed_isMutable.success) {
              return { success: false };
            }
            if (!hasProp(value, "operator")) {
              return { success: false };
            }
            const parsed_operator = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["operator"]);
            })();
            if (!parsed_operator.success) {
              return { success: false };
            }
            return {
              success: true,
              value: { comment: parsed_comment.value, commentId: parsed_commentId.value, customerId: parsed_customerId.value, dateEntered: parsed_dateEntered.value, isMutable: parsed_isMutable.value, operator: parsed_operator.value },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomerCommentSaveRequest
export function serialize_CustomerCommentSaveRequest(obj: CustomerCommentSaveRequest): string {
  const serialized = ((value: { comment: string }): unknown => {
    return {
      comment: ((value: string): unknown => {
        return value;
      })(value["comment"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomerCommentSaveRequest(str: string): CustomerCommentSaveRequest | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          comment: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "comment")) {
      return { success: false };
    }
    const parsed_comment = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["comment"]);
    })();
    if (!parsed_comment.success) {
      return { success: false };
    }
    return { success: true, value: { comment: parsed_comment.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomerReportView
export function serialize_CustomerReportView(obj: CustomerReportView): string {
  const serialized = ((value: {
    country: string | null;
    customerId: number;
    email: string | null;
    endDate: { year: number; month: number; day: number };
    externalId: string | null;
    extraField1: string | null;
    extraField2: string | null;
    extraField3: string | null;
    firstName: string | null;
    language: Language;
    lastName: string | null;
    signupDate: { year: number; month: number; day: number };
    status: string;
    title: number | null;
    town: string | null;
    userGroups: string[];
  }): unknown => {
    return {
      country: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["country"]),
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
      email: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["email"]),
      endDate: ((value: { year: number; month: number; day: number }): unknown => {
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["endDate"]),
      externalId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["externalId"]),
      extraField1: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["extraField1"]),
      extraField2: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["extraField2"]),
      extraField3: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["extraField3"]),
      firstName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["firstName"]),
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      lastName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lastName"]),
      signupDate: ((value: { year: number; month: number; day: number }): unknown => {
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["signupDate"]),
      status: ((value: string): unknown => {
        return value;
      })(value["status"]),
      title: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["title"]),
      town: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["town"]),
      userGroups: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["userGroups"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomerReportView(str: string): CustomerReportView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          country: string | null;
          customerId: number;
          email: string | null;
          endDate: { year: number; month: number; day: number };
          externalId: string | null;
          extraField1: string | null;
          extraField2: string | null;
          extraField3: string | null;
          firstName: string | null;
          language: Language;
          lastName: string | null;
          signupDate: { year: number; month: number; day: number };
          status: string;
          title: number | null;
          town: string | null;
          userGroups: string[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "country")) {
      return { success: false };
    }
    const parsed_country = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["country"]);
    })();
    if (!parsed_country.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "email")) {
      return { success: false };
    }
    const parsed_email = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["email"]);
    })();
    if (!parsed_email.success) {
      return { success: false };
    }
    if (!hasProp(value, "endDate")) {
      return { success: false };
    }
    const parsed_endDate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["endDate"]);
    })();
    if (!parsed_endDate.success) {
      return { success: false };
    }
    if (!hasProp(value, "externalId")) {
      return { success: false };
    }
    const parsed_externalId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["externalId"]);
    })();
    if (!parsed_externalId.success) {
      return { success: false };
    }
    if (!hasProp(value, "extraField1")) {
      return { success: false };
    }
    const parsed_extraField1 = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["extraField1"]);
    })();
    if (!parsed_extraField1.success) {
      return { success: false };
    }
    if (!hasProp(value, "extraField2")) {
      return { success: false };
    }
    const parsed_extraField2 = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["extraField2"]);
    })();
    if (!parsed_extraField2.success) {
      return { success: false };
    }
    if (!hasProp(value, "extraField3")) {
      return { success: false };
    }
    const parsed_extraField3 = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["extraField3"]);
    })();
    if (!parsed_extraField3.success) {
      return { success: false };
    }
    if (!hasProp(value, "firstName")) {
      return { success: false };
    }
    const parsed_firstName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["firstName"]);
    })();
    if (!parsed_firstName.success) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language } => {
        return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "en":
              return { success: true, value: "en" };
            case "de":
              return { success: true, value: "de" };
            case "es":
              return { success: true, value: "es" };
            case "ru":
              return { success: true, value: "ru" };
            case "zh":
              return { success: true, value: "zh" };
            case "pt":
              return { success: true, value: "pt" };
            case "fr":
              return { success: true, value: "fr" };
            default:
              return { success: false };
          }
        })(value);
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "lastName")) {
      return { success: false };
    }
    const parsed_lastName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lastName"]);
    })();
    if (!parsed_lastName.success) {
      return { success: false };
    }
    if (!hasProp(value, "signupDate")) {
      return { success: false };
    }
    const parsed_signupDate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["signupDate"]);
    })();
    if (!parsed_signupDate.success) {
      return { success: false };
    }
    if (!hasProp(value, "status")) {
      return { success: false };
    }
    const parsed_status = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["status"]);
    })();
    if (!parsed_status.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    if (!hasProp(value, "town")) {
      return { success: false };
    }
    const parsed_town = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["town"]);
    })();
    if (!parsed_town.success) {
      return { success: false };
    }
    if (!hasProp(value, "userGroups")) {
      return { success: false };
    }
    const parsed_userGroups = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["userGroups"]);
    })();
    if (!parsed_userGroups.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        country: parsed_country.value,
        customerId: parsed_customerId.value,
        email: parsed_email.value,
        endDate: parsed_endDate.value,
        externalId: parsed_externalId.value,
        extraField1: parsed_extraField1.value,
        extraField2: parsed_extraField2.value,
        extraField3: parsed_extraField3.value,
        firstName: parsed_firstName.value,
        language: parsed_language.value,
        lastName: parsed_lastName.value,
        signupDate: parsed_signupDate.value,
        status: parsed_status.value,
        title: parsed_title.value,
        town: parsed_town.value,
        userGroups: parsed_userGroups.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// CustomerView
export function serialize_CustomerView(obj: CustomerView): string {
  const serialized = ((value: { customerId: number; email: string; fullName: string }): unknown => {
    return {
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
      email: ((value: string): unknown => {
        return value;
      })(value["email"]),
      fullName: ((value: string): unknown => {
        return value;
      })(value["fullName"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_CustomerView(str: string): CustomerView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          customerId: number;
          email: string;
          fullName: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "email")) {
      return { success: false };
    }
    const parsed_email = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["email"]);
    })();
    if (!parsed_email.success) {
      return { success: false };
    }
    if (!hasProp(value, "fullName")) {
      return { success: false };
    }
    const parsed_fullName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["fullName"]);
    })();
    if (!parsed_fullName.success) {
      return { success: false };
    }
    return { success: true, value: { customerId: parsed_customerId.value, email: parsed_email.value, fullName: parsed_fullName.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// DateFormattingDemoProps
export function serialize_DateFormattingDemoProps(obj: DateFormattingDemoProps): string {
  const serialized = ((value: {
    values: {
      label: string;
      value: string;
    }[];
  }): unknown => {
    return {
      values: ((
        value: {
          label: string;
          value: string;
        }[],
      ): unknown => {
        return value.map((value: { label: string; value: string }): unknown => {
          return {
            label: ((value: string): unknown => {
              return value;
            })(value["label"]),
            value: ((value: string): unknown => {
              return value;
            })(value["value"]),
          };
        });
      })(value["values"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_DateFormattingDemoProps(str: string): DateFormattingDemoProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          values: {
            label: string;
            value: string;
          }[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "values")) {
      return { success: false };
    }
    const parsed_values = (() => {
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value: {
              label: string;
              value: string;
            }[];
          } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map(
          (
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  label: string;
                  value: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "label")) {
              return { success: false };
            }
            const parsed_label = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["label"]);
            })();
            if (!parsed_label.success) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { label: parsed_label.value, value: parsed_value.value } };
          },
        );
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["values"]);
    })();
    if (!parsed_values.success) {
      return { success: false };
    }
    return { success: true, value: { values: parsed_values.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// DateRange
export function serialize_DateRange(obj: DateRange): string {
  const serialized = ((value: { from: { year: number; month: number; day: number }; to: { year: number; month: number; day: number } }): unknown => {
    return {
      from: ((value: { year: number; month: number; day: number }): unknown => {
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["from"]),
      to: ((value: { year: number; month: number; day: number }): unknown => {
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["to"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_DateRange(str: string): DateRange | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          from: { year: number; month: number; day: number };
          to: { year: number; month: number; day: number };
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "from")) {
      return { success: false };
    }
    const parsed_from = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["from"]);
    })();
    if (!parsed_from.success) {
      return { success: false };
    }
    if (!hasProp(value, "to")) {
      return { success: false };
    }
    const parsed_to = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["to"]);
    })();
    if (!parsed_to.success) {
      return { success: false };
    }
    return { success: true, value: { from: parsed_from.value, to: parsed_to.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// DemoFormFile
export function serialize_DemoFormFile(obj: DemoFormFile): string {
  const serialized = ((value: { data: ReadonlyUint8Array }): unknown => {
    return {
      data: ((value: ReadonlyUint8Array): unknown => {
        return btoa(String.fromCodePoint(...value));
      })(value["data"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_DemoFormFile(str: string): DemoFormFile | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          data: ReadonlyUint8Array;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "data")) {
      return { success: false };
    }
    const parsed_data = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ReadonlyUint8Array } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const binary = (() => {
          try {
            const binaryString = atob(value);
            const arr = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
              arr[i] = binaryString.charCodeAt(i);
            }
            return arr;
          } catch (ex) {
            return null;
          }
        })();
        if (binary === null) {
          return { success: false };
        }
        return { success: true, value: binary };
      })(value["data"]);
    })();
    if (!parsed_data.success) {
      return { success: false };
    }
    return { success: true, value: { data: parsed_data.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// DisplayMonetaryAmount
export function serialize_DisplayMonetaryAmount(obj: DisplayMonetaryAmount): string {
  const serialized = ((value: { amount: number; currencyCode: string; displayValue: string }): unknown => {
    return {
      amount: ((value: number): unknown => {
        return value;
      })(value["amount"]),
      currencyCode: ((value: string): unknown => {
        return value;
      })(value["currencyCode"]),
      displayValue: ((value: string): unknown => {
        return value;
      })(value["displayValue"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_DisplayMonetaryAmount(str: string): DisplayMonetaryAmount | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          amount: number;
          currencyCode: string;
          displayValue: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "amount")) {
      return { success: false };
    }
    const parsed_amount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        if (typeof value === "bigint") {
          return { success: true, value: Number(value) };
        }
        return { success: false };
      })(value["amount"]);
    })();
    if (!parsed_amount.success) {
      return { success: false };
    }
    if (!hasProp(value, "currencyCode")) {
      return { success: false };
    }
    const parsed_currencyCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["currencyCode"]);
    })();
    if (!parsed_currencyCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "displayValue")) {
      return { success: false };
    }
    const parsed_displayValue = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["displayValue"]);
    })();
    if (!parsed_displayValue.success) {
      return { success: false };
    }
    return { success: true, value: { amount: parsed_amount.value, currencyCode: parsed_currencyCode.value, displayValue: parsed_displayValue.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// DownloadType
export function serialize_DownloadType(obj: DownloadType): string {
  const serialized = ((value: "PUSH" | "PULL" | "BOTH"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_DownloadType(str: string): DownloadType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "PUSH" | "PULL" | "BOTH" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "PUSH":
        return { success: true, value: "PUSH" };
      case "PULL":
        return { success: true, value: "PULL" };
      case "BOTH":
        return { success: true, value: "BOTH" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// DropdownEntry
export function serialize_DropdownEntry(obj: DropdownEntry): string {
  const serialized = ((value: { text: string; value: string | null }): unknown => {
    return {
      text: ((value: string): unknown => {
        return value;
      })(value["text"]),
      value: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["value"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_DropdownEntry(str: string): DropdownEntry | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          text: string;
          value: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "text")) {
      return { success: false };
    }
    const parsed_text = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["text"]);
    })();
    if (!parsed_text.success) {
      return { success: false };
    }
    if (!hasProp(value, "value")) {
      return { success: false };
    }
    const parsed_value = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["value"]);
    })();
    if (!parsed_value.success) {
      return { success: false };
    }
    return { success: true, value: { text: parsed_text.value, value: parsed_value.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailCategoryFilters
export function serialize_EmailCategoryFilters(obj: EmailCategoryFilters): string {
  const serialized = ((value: { categoryId: number | null; categoryName: string | null; followUpType: EmailCategoryFollowUpType }): unknown => {
    return {
      categoryId: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["categoryId"]),
      categoryName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["categoryName"]),
      followUpType: ((value: "STANDARD" | "INTERVAL" | "DATEBASED"): unknown => {
        return value;
      })(value["followUpType"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailCategoryFilters(str: string): EmailCategoryFilters | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          categoryId: number | null;
          categoryName: string | null;
          followUpType: EmailCategoryFollowUpType;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "categoryId")) {
      return { success: false };
    }
    const parsed_categoryId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["categoryId"]);
    })();
    if (!parsed_categoryId.success) {
      return { success: false };
    }
    if (!hasProp(value, "categoryName")) {
      return { success: false };
    }
    const parsed_categoryName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["categoryName"]);
    })();
    if (!parsed_categoryName.success) {
      return { success: false };
    }
    if (!hasProp(value, "followUpType")) {
      return { success: false };
    }
    const parsed_followUpType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: EmailCategoryFollowUpType } => {
        return ((value: unknown): { success: false } | { success: true; value: "STANDARD" | "INTERVAL" | "DATEBASED" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "STANDARD":
              return { success: true, value: "STANDARD" };
            case "INTERVAL":
              return { success: true, value: "INTERVAL" };
            case "DATEBASED":
              return { success: true, value: "DATEBASED" };
            default:
              return { success: false };
          }
        })(value);
      })(value["followUpType"]);
    })();
    if (!parsed_followUpType.success) {
      return { success: false };
    }
    return { success: true, value: { categoryId: parsed_categoryId.value, categoryName: parsed_categoryName.value, followUpType: parsed_followUpType.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailCategoryFollowUpType
export function serialize_EmailCategoryFollowUpType(obj: EmailCategoryFollowUpType): string {
  const serialized = ((value: "STANDARD" | "INTERVAL" | "DATEBASED"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailCategoryFollowUpType(str: string): EmailCategoryFollowUpType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "STANDARD" | "INTERVAL" | "DATEBASED" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "STANDARD":
        return { success: true, value: "STANDARD" };
      case "INTERVAL":
        return { success: true, value: "INTERVAL" };
      case "DATEBASED":
        return { success: true, value: "DATEBASED" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailCategoryForm
export function serialize_EmailCategoryForm(obj: EmailCategoryForm): string {
  const serialized = ((value: { description: string | null; details: EmailCategoryFormDetails; name: string; repositoryId: number; sendCountriesOnly: string[]; sendMatchingLanguageOnly: boolean }): unknown => {
    return {
      description: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["description"]),
      details: ((
        value:
          | { repositoryType: "DEFAULT" }
          | { repositoryType: "RENEWAL"; active: boolean; maxDaysAfterExpiration: number; minDaysAfterExpiration: number; productIds: number[] }
          | { repositoryType: "EDUCATION"; active: boolean; productIds: number[]; welcomeEmail: boolean }
          | { repositoryType: "ONBOARDING"; active: boolean; maxDaysAfterSignup: number; minDaysAfterSignup: number }
          | {
              repositoryType: "FOLLOWUP";
              active: boolean;
              corporateIds: number[];
              emailingPools: string[];
              followupType: EmailCategoryFollowUpType;
              interval: number;
              maxDaysAfterExpiration: number;
              minDaysAfterExpiration: number;
              sendToAll: boolean;
              startDate: { year: number; month: number; day: number } | null;
            }
          | { repositoryType: "MANUAL"; corporateIds: number[] },
      ): unknown => {
        switch (value.repositoryType) {
          case "DEFAULT": {
            return { repositoryType: "DEFAULT" };
          }
          case "RENEWAL": {
            return {
              repositoryType: "RENEWAL",
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              maxDaysAfterExpiration: ((value: number): unknown => {
                return value;
              })(value["maxDaysAfterExpiration"]),
              minDaysAfterExpiration: ((value: number): unknown => {
                return value;
              })(value["minDaysAfterExpiration"]),
              productIds: ((value: number[]): unknown => {
                return value.map((value: number): unknown => {
                  return value;
                });
              })(value["productIds"]),
            };
          }
          case "EDUCATION": {
            return {
              repositoryType: "EDUCATION",
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              productIds: ((value: number[]): unknown => {
                return value.map((value: number): unknown => {
                  return value;
                });
              })(value["productIds"]),
              welcomeEmail: ((value: boolean): unknown => {
                return value;
              })(value["welcomeEmail"]),
            };
          }
          case "ONBOARDING": {
            return {
              repositoryType: "ONBOARDING",
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              maxDaysAfterSignup: ((value: number): unknown => {
                return value;
              })(value["maxDaysAfterSignup"]),
              minDaysAfterSignup: ((value: number): unknown => {
                return value;
              })(value["minDaysAfterSignup"]),
            };
          }
          case "FOLLOWUP": {
            return {
              repositoryType: "FOLLOWUP",
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              corporateIds: ((value: number[]): unknown => {
                return value.map((value: number): unknown => {
                  return value;
                });
              })(value["corporateIds"]),
              emailingPools: ((value: string[]): unknown => {
                return value.map((value: string): unknown => {
                  return value;
                });
              })(value["emailingPools"]),
              followupType: ((value: "STANDARD" | "INTERVAL" | "DATEBASED"): unknown => {
                return value;
              })(value["followupType"]),
              interval: ((value: number): unknown => {
                return value;
              })(value["interval"]),
              maxDaysAfterExpiration: ((value: number): unknown => {
                return value;
              })(value["maxDaysAfterExpiration"]),
              minDaysAfterExpiration: ((value: number): unknown => {
                return value;
              })(value["minDaysAfterExpiration"]),
              sendToAll: ((value: boolean): unknown => {
                return value;
              })(value["sendToAll"]),
              startDate: ((value: { year: number; month: number; day: number } | null): unknown => {
                if (value === null) {
                  return null;
                }
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["startDate"]),
            };
          }
          case "MANUAL": {
            return {
              repositoryType: "MANUAL",
              corporateIds: ((value: number[]): unknown => {
                return value.map((value: number): unknown => {
                  return value;
                });
              })(value["corporateIds"]),
            };
          }
        }
      })(value["details"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      repositoryId: ((value: number): unknown => {
        return value;
      })(value["repositoryId"]),
      sendCountriesOnly: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["sendCountriesOnly"]),
      sendMatchingLanguageOnly: ((value: boolean): unknown => {
        return value;
      })(value["sendMatchingLanguageOnly"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailCategoryForm(str: string): EmailCategoryForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          description: string | null;
          details: EmailCategoryFormDetails;
          name: string;
          repositoryId: number;
          sendCountriesOnly: string[];
          sendMatchingLanguageOnly: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "description")) {
      return { success: false };
    }
    const parsed_description = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["description"]);
    })();
    if (!parsed_description.success) {
      return { success: false };
    }
    if (!hasProp(value, "details")) {
      return { success: false };
    }
    const parsed_details = (() => {
      return ((value: unknown): { success: false } | { success: true; value: EmailCategoryFormDetails } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value:
                | { repositoryType: "DEFAULT" }
                | { repositoryType: "RENEWAL"; active: boolean; maxDaysAfterExpiration: number; minDaysAfterExpiration: number; productIds: number[] }
                | { repositoryType: "EDUCATION"; active: boolean; productIds: number[]; welcomeEmail: boolean }
                | { repositoryType: "ONBOARDING"; active: boolean; maxDaysAfterSignup: number; minDaysAfterSignup: number }
                | {
                    repositoryType: "FOLLOWUP";
                    active: boolean;
                    corporateIds: number[];
                    emailingPools: string[];
                    followupType: EmailCategoryFollowUpType;
                    interval: number;
                    maxDaysAfterExpiration: number;
                    minDaysAfterExpiration: number;
                    sendToAll: boolean;
                    startDate: { year: number; month: number; day: number } | null;
                  }
                | { repositoryType: "MANUAL"; corporateIds: number[] };
            } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { repositoryType: "DEFAULT" }
                  | { repositoryType: "RENEWAL"; active: boolean; maxDaysAfterExpiration: number; minDaysAfterExpiration: number; productIds: number[] }
                  | { repositoryType: "EDUCATION"; active: boolean; productIds: number[]; welcomeEmail: boolean }
                  | { repositoryType: "ONBOARDING"; active: boolean; maxDaysAfterSignup: number; minDaysAfterSignup: number }
                  | {
                      repositoryType: "FOLLOWUP";
                      active: boolean;
                      corporateIds: number[];
                      emailingPools: string[];
                      followupType: EmailCategoryFollowUpType;
                      interval: number;
                      maxDaysAfterExpiration: number;
                      minDaysAfterExpiration: number;
                      sendToAll: boolean;
                      startDate: { year: number; month: number; day: number } | null;
                    }
                  | { repositoryType: "MANUAL"; corporateIds: number[] };
              } => {
            if (value == null) {
              return { success: false };
            }
            if (typeof value != "object") {
              return { success: false };
            }
            if (!hasProp(value, "repositoryType")) {
              return { success: false };
            }
            if (typeof value["repositoryType"] != "string") {
              return { success: false };
            }
            const discriminator = value["repositoryType"];
            switch (discriminator) {
              case "DEFAULT": {
                const parsedVariant = ((value: unknown): { success: false } | { success: true; value: Record<string, never> } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  return { success: true, value: {} };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { repositoryType: "DEFAULT" as const, ...parsedVariant.value } };
              }
              case "RENEWAL": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        active: boolean;
                        maxDaysAfterExpiration: number;
                        minDaysAfterExpiration: number;
                        productIds: number[];
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "active")) {
                    return { success: false };
                  }
                  const parsed_active = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["active"]);
                  })();
                  if (!parsed_active.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "maxDaysAfterExpiration")) {
                    return { success: false };
                  }
                  const parsed_maxDaysAfterExpiration = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["maxDaysAfterExpiration"]);
                  })();
                  if (!parsed_maxDaysAfterExpiration.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "minDaysAfterExpiration")) {
                    return { success: false };
                  }
                  const parsed_minDaysAfterExpiration = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["minDaysAfterExpiration"]);
                  })();
                  if (!parsed_minDaysAfterExpiration.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "productIds")) {
                    return { success: false };
                  }
                  const parsed_productIds = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number[] } => {
                      if (!isArray(value)) {
                        return { success: false };
                      }
                      const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      });
                      if (parsed.find((value) => !value.success) !== undefined) {
                        return { success: false };
                      }
                      return {
                        success: true,
                        value: parsed.map((entry) => {
                          assert(entry.success == true);
                          return entry.value;
                        }),
                      };
                    })(value["productIds"]);
                  })();
                  if (!parsed_productIds.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: { active: parsed_active.value, maxDaysAfterExpiration: parsed_maxDaysAfterExpiration.value, minDaysAfterExpiration: parsed_minDaysAfterExpiration.value, productIds: parsed_productIds.value },
                  };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { repositoryType: "RENEWAL" as const, ...parsedVariant.value } };
              }
              case "EDUCATION": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        active: boolean;
                        productIds: number[];
                        welcomeEmail: boolean;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "active")) {
                    return { success: false };
                  }
                  const parsed_active = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["active"]);
                  })();
                  if (!parsed_active.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "productIds")) {
                    return { success: false };
                  }
                  const parsed_productIds = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number[] } => {
                      if (!isArray(value)) {
                        return { success: false };
                      }
                      const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      });
                      if (parsed.find((value) => !value.success) !== undefined) {
                        return { success: false };
                      }
                      return {
                        success: true,
                        value: parsed.map((entry) => {
                          assert(entry.success == true);
                          return entry.value;
                        }),
                      };
                    })(value["productIds"]);
                  })();
                  if (!parsed_productIds.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "welcomeEmail")) {
                    return { success: false };
                  }
                  const parsed_welcomeEmail = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["welcomeEmail"]);
                  })();
                  if (!parsed_welcomeEmail.success) {
                    return { success: false };
                  }
                  return { success: true, value: { active: parsed_active.value, productIds: parsed_productIds.value, welcomeEmail: parsed_welcomeEmail.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { repositoryType: "EDUCATION" as const, ...parsedVariant.value } };
              }
              case "ONBOARDING": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        active: boolean;
                        maxDaysAfterSignup: number;
                        minDaysAfterSignup: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "active")) {
                    return { success: false };
                  }
                  const parsed_active = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["active"]);
                  })();
                  if (!parsed_active.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "maxDaysAfterSignup")) {
                    return { success: false };
                  }
                  const parsed_maxDaysAfterSignup = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["maxDaysAfterSignup"]);
                  })();
                  if (!parsed_maxDaysAfterSignup.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "minDaysAfterSignup")) {
                    return { success: false };
                  }
                  const parsed_minDaysAfterSignup = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["minDaysAfterSignup"]);
                  })();
                  if (!parsed_minDaysAfterSignup.success) {
                    return { success: false };
                  }
                  return { success: true, value: { active: parsed_active.value, maxDaysAfterSignup: parsed_maxDaysAfterSignup.value, minDaysAfterSignup: parsed_minDaysAfterSignup.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { repositoryType: "ONBOARDING" as const, ...parsedVariant.value } };
              }
              case "FOLLOWUP": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        active: boolean;
                        corporateIds: number[];
                        emailingPools: string[];
                        followupType: EmailCategoryFollowUpType;
                        interval: number;
                        maxDaysAfterExpiration: number;
                        minDaysAfterExpiration: number;
                        sendToAll: boolean;
                        startDate: { year: number; month: number; day: number } | null;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "active")) {
                    return { success: false };
                  }
                  const parsed_active = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["active"]);
                  })();
                  if (!parsed_active.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "corporateIds")) {
                    return { success: false };
                  }
                  const parsed_corporateIds = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number[] } => {
                      if (!isArray(value)) {
                        return { success: false };
                      }
                      const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      });
                      if (parsed.find((value) => !value.success) !== undefined) {
                        return { success: false };
                      }
                      return {
                        success: true,
                        value: parsed.map((entry) => {
                          assert(entry.success == true);
                          return entry.value;
                        }),
                      };
                    })(value["corporateIds"]);
                  })();
                  if (!parsed_corporateIds.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "emailingPools")) {
                    return { success: false };
                  }
                  const parsed_emailingPools = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                      if (!isArray(value)) {
                        return { success: false };
                      }
                      const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      });
                      if (parsed.find((value) => !value.success) !== undefined) {
                        return { success: false };
                      }
                      return {
                        success: true,
                        value: parsed.map((entry) => {
                          assert(entry.success == true);
                          return entry.value;
                        }),
                      };
                    })(value["emailingPools"]);
                  })();
                  if (!parsed_emailingPools.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "followupType")) {
                    return { success: false };
                  }
                  const parsed_followupType = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: EmailCategoryFollowUpType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "STANDARD" | "INTERVAL" | "DATEBASED" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "STANDARD":
                            return { success: true, value: "STANDARD" };
                          case "INTERVAL":
                            return { success: true, value: "INTERVAL" };
                          case "DATEBASED":
                            return { success: true, value: "DATEBASED" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["followupType"]);
                  })();
                  if (!parsed_followupType.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "interval")) {
                    return { success: false };
                  }
                  const parsed_interval = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["interval"]);
                  })();
                  if (!parsed_interval.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "maxDaysAfterExpiration")) {
                    return { success: false };
                  }
                  const parsed_maxDaysAfterExpiration = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["maxDaysAfterExpiration"]);
                  })();
                  if (!parsed_maxDaysAfterExpiration.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "minDaysAfterExpiration")) {
                    return { success: false };
                  }
                  const parsed_minDaysAfterExpiration = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["minDaysAfterExpiration"]);
                  })();
                  if (!parsed_minDaysAfterExpiration.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "sendToAll")) {
                    return { success: false };
                  }
                  const parsed_sendToAll = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["sendToAll"]);
                  })();
                  if (!parsed_sendToAll.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "startDate")) {
                    return { success: false };
                  }
                  const parsed_startDate = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                    })(value["startDate"]);
                  })();
                  if (!parsed_startDate.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      active: parsed_active.value,
                      corporateIds: parsed_corporateIds.value,
                      emailingPools: parsed_emailingPools.value,
                      followupType: parsed_followupType.value,
                      interval: parsed_interval.value,
                      maxDaysAfterExpiration: parsed_maxDaysAfterExpiration.value,
                      minDaysAfterExpiration: parsed_minDaysAfterExpiration.value,
                      sendToAll: parsed_sendToAll.value,
                      startDate: parsed_startDate.value,
                    },
                  };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { repositoryType: "FOLLOWUP" as const, ...parsedVariant.value } };
              }
              case "MANUAL": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        corporateIds: number[];
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "corporateIds")) {
                    return { success: false };
                  }
                  const parsed_corporateIds = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number[] } => {
                      if (!isArray(value)) {
                        return { success: false };
                      }
                      const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      });
                      if (parsed.find((value) => !value.success) !== undefined) {
                        return { success: false };
                      }
                      return {
                        success: true,
                        value: parsed.map((entry) => {
                          assert(entry.success == true);
                          return entry.value;
                        }),
                      };
                    })(value["corporateIds"]);
                  })();
                  if (!parsed_corporateIds.success) {
                    return { success: false };
                  }
                  return { success: true, value: { corporateIds: parsed_corporateIds.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { repositoryType: "MANUAL" as const, ...parsedVariant.value } };
              }
              default:
                return { success: false };
            }
          })(value);
        })(value);
      })(value["details"]);
    })();
    if (!parsed_details.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "repositoryId")) {
      return { success: false };
    }
    const parsed_repositoryId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["repositoryId"]);
    })();
    if (!parsed_repositoryId.success) {
      return { success: false };
    }
    if (!hasProp(value, "sendCountriesOnly")) {
      return { success: false };
    }
    const parsed_sendCountriesOnly = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["sendCountriesOnly"]);
    })();
    if (!parsed_sendCountriesOnly.success) {
      return { success: false };
    }
    if (!hasProp(value, "sendMatchingLanguageOnly")) {
      return { success: false };
    }
    const parsed_sendMatchingLanguageOnly = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["sendMatchingLanguageOnly"]);
    })();
    if (!parsed_sendMatchingLanguageOnly.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        description: parsed_description.value,
        details: parsed_details.value,
        name: parsed_name.value,
        repositoryId: parsed_repositoryId.value,
        sendCountriesOnly: parsed_sendCountriesOnly.value,
        sendMatchingLanguageOnly: parsed_sendMatchingLanguageOnly.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailCategoryFormDetails
export function serialize_EmailCategoryFormDetails(obj: EmailCategoryFormDetails): string {
  const serialized = ((
    value:
      | { repositoryType: "DEFAULT" }
      | { repositoryType: "RENEWAL"; active: boolean; maxDaysAfterExpiration: number; minDaysAfterExpiration: number; productIds: number[] }
      | { repositoryType: "EDUCATION"; active: boolean; productIds: number[]; welcomeEmail: boolean }
      | { repositoryType: "ONBOARDING"; active: boolean; maxDaysAfterSignup: number; minDaysAfterSignup: number }
      | {
          repositoryType: "FOLLOWUP";
          active: boolean;
          corporateIds: number[];
          emailingPools: string[];
          followupType: EmailCategoryFollowUpType;
          interval: number;
          maxDaysAfterExpiration: number;
          minDaysAfterExpiration: number;
          sendToAll: boolean;
          startDate: { year: number; month: number; day: number } | null;
        }
      | { repositoryType: "MANUAL"; corporateIds: number[] },
  ): unknown => {
    switch (value.repositoryType) {
      case "DEFAULT": {
        return { repositoryType: "DEFAULT" };
      }
      case "RENEWAL": {
        return {
          repositoryType: "RENEWAL",
          active: ((value: boolean): unknown => {
            return value;
          })(value["active"]),
          maxDaysAfterExpiration: ((value: number): unknown => {
            return value;
          })(value["maxDaysAfterExpiration"]),
          minDaysAfterExpiration: ((value: number): unknown => {
            return value;
          })(value["minDaysAfterExpiration"]),
          productIds: ((value: number[]): unknown => {
            return value.map((value: number): unknown => {
              return value;
            });
          })(value["productIds"]),
        };
      }
      case "EDUCATION": {
        return {
          repositoryType: "EDUCATION",
          active: ((value: boolean): unknown => {
            return value;
          })(value["active"]),
          productIds: ((value: number[]): unknown => {
            return value.map((value: number): unknown => {
              return value;
            });
          })(value["productIds"]),
          welcomeEmail: ((value: boolean): unknown => {
            return value;
          })(value["welcomeEmail"]),
        };
      }
      case "ONBOARDING": {
        return {
          repositoryType: "ONBOARDING",
          active: ((value: boolean): unknown => {
            return value;
          })(value["active"]),
          maxDaysAfterSignup: ((value: number): unknown => {
            return value;
          })(value["maxDaysAfterSignup"]),
          minDaysAfterSignup: ((value: number): unknown => {
            return value;
          })(value["minDaysAfterSignup"]),
        };
      }
      case "FOLLOWUP": {
        return {
          repositoryType: "FOLLOWUP",
          active: ((value: boolean): unknown => {
            return value;
          })(value["active"]),
          corporateIds: ((value: number[]): unknown => {
            return value.map((value: number): unknown => {
              return value;
            });
          })(value["corporateIds"]),
          emailingPools: ((value: string[]): unknown => {
            return value.map((value: string): unknown => {
              return value;
            });
          })(value["emailingPools"]),
          followupType: ((value: "STANDARD" | "INTERVAL" | "DATEBASED"): unknown => {
            return value;
          })(value["followupType"]),
          interval: ((value: number): unknown => {
            return value;
          })(value["interval"]),
          maxDaysAfterExpiration: ((value: number): unknown => {
            return value;
          })(value["maxDaysAfterExpiration"]),
          minDaysAfterExpiration: ((value: number): unknown => {
            return value;
          })(value["minDaysAfterExpiration"]),
          sendToAll: ((value: boolean): unknown => {
            return value;
          })(value["sendToAll"]),
          startDate: ((value: { year: number; month: number; day: number } | null): unknown => {
            if (value === null) {
              return null;
            }
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["startDate"]),
        };
      }
      case "MANUAL": {
        return {
          repositoryType: "MANUAL",
          corporateIds: ((value: number[]): unknown => {
            return value.map((value: number): unknown => {
              return value;
            });
          })(value["corporateIds"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailCategoryFormDetails(str: string): EmailCategoryFormDetails | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value:
          | { repositoryType: "DEFAULT" }
          | { repositoryType: "RENEWAL"; active: boolean; maxDaysAfterExpiration: number; minDaysAfterExpiration: number; productIds: number[] }
          | { repositoryType: "EDUCATION"; active: boolean; productIds: number[]; welcomeEmail: boolean }
          | { repositoryType: "ONBOARDING"; active: boolean; maxDaysAfterSignup: number; minDaysAfterSignup: number }
          | {
              repositoryType: "FOLLOWUP";
              active: boolean;
              corporateIds: number[];
              emailingPools: string[];
              followupType: EmailCategoryFollowUpType;
              interval: number;
              maxDaysAfterExpiration: number;
              minDaysAfterExpiration: number;
              sendToAll: boolean;
              startDate: { year: number; month: number; day: number } | null;
            }
          | { repositoryType: "MANUAL"; corporateIds: number[] };
      } => {
    return ((
      value: unknown,
    ):
      | { success: false }
      | {
          success: true;
          value:
            | { repositoryType: "DEFAULT" }
            | { repositoryType: "RENEWAL"; active: boolean; maxDaysAfterExpiration: number; minDaysAfterExpiration: number; productIds: number[] }
            | { repositoryType: "EDUCATION"; active: boolean; productIds: number[]; welcomeEmail: boolean }
            | { repositoryType: "ONBOARDING"; active: boolean; maxDaysAfterSignup: number; minDaysAfterSignup: number }
            | {
                repositoryType: "FOLLOWUP";
                active: boolean;
                corporateIds: number[];
                emailingPools: string[];
                followupType: EmailCategoryFollowUpType;
                interval: number;
                maxDaysAfterExpiration: number;
                minDaysAfterExpiration: number;
                sendToAll: boolean;
                startDate: { year: number; month: number; day: number } | null;
              }
            | { repositoryType: "MANUAL"; corporateIds: number[] };
        } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "repositoryType")) {
        return { success: false };
      }
      if (typeof value["repositoryType"] != "string") {
        return { success: false };
      }
      const discriminator = value["repositoryType"];
      switch (discriminator) {
        case "DEFAULT": {
          const parsedVariant = ((value: unknown): { success: false } | { success: true; value: Record<string, never> } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            return { success: true, value: {} };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { repositoryType: "DEFAULT" as const, ...parsedVariant.value } };
        }
        case "RENEWAL": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  active: boolean;
                  maxDaysAfterExpiration: number;
                  minDaysAfterExpiration: number;
                  productIds: number[];
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "active")) {
              return { success: false };
            }
            const parsed_active = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["active"]);
            })();
            if (!parsed_active.success) {
              return { success: false };
            }
            if (!hasProp(value, "maxDaysAfterExpiration")) {
              return { success: false };
            }
            const parsed_maxDaysAfterExpiration = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["maxDaysAfterExpiration"]);
            })();
            if (!parsed_maxDaysAfterExpiration.success) {
              return { success: false };
            }
            if (!hasProp(value, "minDaysAfterExpiration")) {
              return { success: false };
            }
            const parsed_minDaysAfterExpiration = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["minDaysAfterExpiration"]);
            })();
            if (!parsed_minDaysAfterExpiration.success) {
              return { success: false };
            }
            if (!hasProp(value, "productIds")) {
              return { success: false };
            }
            const parsed_productIds = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
                  if (typeof value === "number") {
                    return { success: true, value: value };
                  }
                  return { success: false };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["productIds"]);
            })();
            if (!parsed_productIds.success) {
              return { success: false };
            }
            return { success: true, value: { active: parsed_active.value, maxDaysAfterExpiration: parsed_maxDaysAfterExpiration.value, minDaysAfterExpiration: parsed_minDaysAfterExpiration.value, productIds: parsed_productIds.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { repositoryType: "RENEWAL" as const, ...parsedVariant.value } };
        }
        case "EDUCATION": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  active: boolean;
                  productIds: number[];
                  welcomeEmail: boolean;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "active")) {
              return { success: false };
            }
            const parsed_active = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["active"]);
            })();
            if (!parsed_active.success) {
              return { success: false };
            }
            if (!hasProp(value, "productIds")) {
              return { success: false };
            }
            const parsed_productIds = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
                  if (typeof value === "number") {
                    return { success: true, value: value };
                  }
                  return { success: false };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["productIds"]);
            })();
            if (!parsed_productIds.success) {
              return { success: false };
            }
            if (!hasProp(value, "welcomeEmail")) {
              return { success: false };
            }
            const parsed_welcomeEmail = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["welcomeEmail"]);
            })();
            if (!parsed_welcomeEmail.success) {
              return { success: false };
            }
            return { success: true, value: { active: parsed_active.value, productIds: parsed_productIds.value, welcomeEmail: parsed_welcomeEmail.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { repositoryType: "EDUCATION" as const, ...parsedVariant.value } };
        }
        case "ONBOARDING": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  active: boolean;
                  maxDaysAfterSignup: number;
                  minDaysAfterSignup: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "active")) {
              return { success: false };
            }
            const parsed_active = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["active"]);
            })();
            if (!parsed_active.success) {
              return { success: false };
            }
            if (!hasProp(value, "maxDaysAfterSignup")) {
              return { success: false };
            }
            const parsed_maxDaysAfterSignup = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["maxDaysAfterSignup"]);
            })();
            if (!parsed_maxDaysAfterSignup.success) {
              return { success: false };
            }
            if (!hasProp(value, "minDaysAfterSignup")) {
              return { success: false };
            }
            const parsed_minDaysAfterSignup = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["minDaysAfterSignup"]);
            })();
            if (!parsed_minDaysAfterSignup.success) {
              return { success: false };
            }
            return { success: true, value: { active: parsed_active.value, maxDaysAfterSignup: parsed_maxDaysAfterSignup.value, minDaysAfterSignup: parsed_minDaysAfterSignup.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { repositoryType: "ONBOARDING" as const, ...parsedVariant.value } };
        }
        case "FOLLOWUP": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  active: boolean;
                  corporateIds: number[];
                  emailingPools: string[];
                  followupType: EmailCategoryFollowUpType;
                  interval: number;
                  maxDaysAfterExpiration: number;
                  minDaysAfterExpiration: number;
                  sendToAll: boolean;
                  startDate: { year: number; month: number; day: number } | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "active")) {
              return { success: false };
            }
            const parsed_active = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["active"]);
            })();
            if (!parsed_active.success) {
              return { success: false };
            }
            if (!hasProp(value, "corporateIds")) {
              return { success: false };
            }
            const parsed_corporateIds = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
                  if (typeof value === "number") {
                    return { success: true, value: value };
                  }
                  return { success: false };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["corporateIds"]);
            })();
            if (!parsed_corporateIds.success) {
              return { success: false };
            }
            if (!hasProp(value, "emailingPools")) {
              return { success: false };
            }
            const parsed_emailingPools = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["emailingPools"]);
            })();
            if (!parsed_emailingPools.success) {
              return { success: false };
            }
            if (!hasProp(value, "followupType")) {
              return { success: false };
            }
            const parsed_followupType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: EmailCategoryFollowUpType } => {
                return ((value: unknown): { success: false } | { success: true; value: "STANDARD" | "INTERVAL" | "DATEBASED" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "STANDARD":
                      return { success: true, value: "STANDARD" };
                    case "INTERVAL":
                      return { success: true, value: "INTERVAL" };
                    case "DATEBASED":
                      return { success: true, value: "DATEBASED" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["followupType"]);
            })();
            if (!parsed_followupType.success) {
              return { success: false };
            }
            if (!hasProp(value, "interval")) {
              return { success: false };
            }
            const parsed_interval = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["interval"]);
            })();
            if (!parsed_interval.success) {
              return { success: false };
            }
            if (!hasProp(value, "maxDaysAfterExpiration")) {
              return { success: false };
            }
            const parsed_maxDaysAfterExpiration = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["maxDaysAfterExpiration"]);
            })();
            if (!parsed_maxDaysAfterExpiration.success) {
              return { success: false };
            }
            if (!hasProp(value, "minDaysAfterExpiration")) {
              return { success: false };
            }
            const parsed_minDaysAfterExpiration = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["minDaysAfterExpiration"]);
            })();
            if (!parsed_minDaysAfterExpiration.success) {
              return { success: false };
            }
            if (!hasProp(value, "sendToAll")) {
              return { success: false };
            }
            const parsed_sendToAll = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["sendToAll"]);
            })();
            if (!parsed_sendToAll.success) {
              return { success: false };
            }
            if (!hasProp(value, "startDate")) {
              return { success: false };
            }
            const parsed_startDate = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
              })(value["startDate"]);
            })();
            if (!parsed_startDate.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                active: parsed_active.value,
                corporateIds: parsed_corporateIds.value,
                emailingPools: parsed_emailingPools.value,
                followupType: parsed_followupType.value,
                interval: parsed_interval.value,
                maxDaysAfterExpiration: parsed_maxDaysAfterExpiration.value,
                minDaysAfterExpiration: parsed_minDaysAfterExpiration.value,
                sendToAll: parsed_sendToAll.value,
                startDate: parsed_startDate.value,
              },
            };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { repositoryType: "FOLLOWUP" as const, ...parsedVariant.value } };
        }
        case "MANUAL": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  corporateIds: number[];
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "corporateIds")) {
              return { success: false };
            }
            const parsed_corporateIds = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
                  if (typeof value === "number") {
                    return { success: true, value: value };
                  }
                  return { success: false };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["corporateIds"]);
            })();
            if (!parsed_corporateIds.success) {
              return { success: false };
            }
            return { success: true, value: { corporateIds: parsed_corporateIds.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { repositoryType: "MANUAL" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailCategoryManualSendForm
export function serialize_EmailCategoryManualSendForm(obj: EmailCategoryManualSendForm): string {
  const serialized = ((value: { executionDate: { year: number; month: number; day: number } | null; notifyEmail: string }): unknown => {
    return {
      executionDate: ((value: { year: number; month: number; day: number } | null): unknown => {
        if (value === null) {
          return null;
        }
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["executionDate"]),
      notifyEmail: ((value: string): unknown => {
        return value;
      })(value["notifyEmail"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailCategoryManualSendForm(str: string): EmailCategoryManualSendForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          executionDate: { year: number; month: number; day: number } | null;
          notifyEmail: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "executionDate")) {
      return { success: false };
    }
    const parsed_executionDate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["executionDate"]);
    })();
    if (!parsed_executionDate.success) {
      return { success: false };
    }
    if (!hasProp(value, "notifyEmail")) {
      return { success: false };
    }
    const parsed_notifyEmail = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["notifyEmail"]);
    })();
    if (!parsed_notifyEmail.success) {
      return { success: false };
    }
    return { success: true, value: { executionDate: parsed_executionDate.value, notifyEmail: parsed_notifyEmail.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailCategoryTypeDefault
export function serialize_EmailCategoryTypeDefault(obj: EmailCategoryTypeDefault): string {
  const serialized = ((value: "DEFAULT"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailCategoryTypeDefault(str: string): EmailCategoryTypeDefault | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "DEFAULT" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "DEFAULT":
        return { success: true, value: "DEFAULT" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailCategoryTypeEducation
export function serialize_EmailCategoryTypeEducation(obj: EmailCategoryTypeEducation): string {
  const serialized = ((value: "EDUCATION"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailCategoryTypeEducation(str: string): EmailCategoryTypeEducation | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "EDUCATION" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "EDUCATION":
        return { success: true, value: "EDUCATION" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailCategoryTypeFollowUp
export function serialize_EmailCategoryTypeFollowUp(obj: EmailCategoryTypeFollowUp): string {
  const serialized = ((value: "FOLLOWUP"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailCategoryTypeFollowUp(str: string): EmailCategoryTypeFollowUp | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "FOLLOWUP" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "FOLLOWUP":
        return { success: true, value: "FOLLOWUP" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailCategoryTypeManual
export function serialize_EmailCategoryTypeManual(obj: EmailCategoryTypeManual): string {
  const serialized = ((value: "MANUAL"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailCategoryTypeManual(str: string): EmailCategoryTypeManual | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "MANUAL" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "MANUAL":
        return { success: true, value: "MANUAL" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailCategoryTypeOnboarding
export function serialize_EmailCategoryTypeOnboarding(obj: EmailCategoryTypeOnboarding): string {
  const serialized = ((value: "ONBOARDING"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailCategoryTypeOnboarding(str: string): EmailCategoryTypeOnboarding | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "ONBOARDING" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "ONBOARDING":
        return { success: true, value: "ONBOARDING" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailCategoryTypeRenewal
export function serialize_EmailCategoryTypeRenewal(obj: EmailCategoryTypeRenewal): string {
  const serialized = ((value: "RENEWAL"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailCategoryTypeRenewal(str: string): EmailCategoryTypeRenewal | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "RENEWAL" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "RENEWAL":
        return { success: true, value: "RENEWAL" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailDefaultCategoryAdminView
export function serialize_EmailDefaultCategoryAdminView(obj: EmailDefaultCategoryAdminView): string {
  const serialized = ((value: { categoryId: number; name: string; repositoryId: number }): unknown => {
    return {
      categoryId: ((value: number): unknown => {
        return value;
      })(value["categoryId"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      repositoryId: ((value: number): unknown => {
        return value;
      })(value["repositoryId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailDefaultCategoryAdminView(str: string): EmailDefaultCategoryAdminView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          categoryId: number;
          name: string;
          repositoryId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "categoryId")) {
      return { success: false };
    }
    const parsed_categoryId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["categoryId"]);
    })();
    if (!parsed_categoryId.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "repositoryId")) {
      return { success: false };
    }
    const parsed_repositoryId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["repositoryId"]);
    })();
    if (!parsed_repositoryId.success) {
      return { success: false };
    }
    return { success: true, value: { categoryId: parsed_categoryId.value, name: parsed_name.value, repositoryId: parsed_repositoryId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailEducationCategoryAdminView
export function serialize_EmailEducationCategoryAdminView(obj: EmailEducationCategoryAdminView): string {
  const serialized = ((value: { active: boolean; categoryId: number; name: string; productIds: number[]; repositoryId: number }): unknown => {
    return {
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      categoryId: ((value: number): unknown => {
        return value;
      })(value["categoryId"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      productIds: ((value: number[]): unknown => {
        return value.map((value: number): unknown => {
          return value;
        });
      })(value["productIds"]),
      repositoryId: ((value: number): unknown => {
        return value;
      })(value["repositoryId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailEducationCategoryAdminView(str: string): EmailEducationCategoryAdminView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          active: boolean;
          categoryId: number;
          name: string;
          productIds: number[];
          repositoryId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "categoryId")) {
      return { success: false };
    }
    const parsed_categoryId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["categoryId"]);
    })();
    if (!parsed_categoryId.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "productIds")) {
      return { success: false };
    }
    const parsed_productIds = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
          if (typeof value === "number") {
            return { success: true, value: value };
          }
          return { success: false };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["productIds"]);
    })();
    if (!parsed_productIds.success) {
      return { success: false };
    }
    if (!hasProp(value, "repositoryId")) {
      return { success: false };
    }
    const parsed_repositoryId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["repositoryId"]);
    })();
    if (!parsed_repositoryId.success) {
      return { success: false };
    }
    return { success: true, value: { active: parsed_active.value, categoryId: parsed_categoryId.value, name: parsed_name.value, productIds: parsed_productIds.value, repositoryId: parsed_repositoryId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailFollowUpCategoryAdminView
export function serialize_EmailFollowUpCategoryAdminView(obj: EmailFollowUpCategoryAdminView): string {
  const serialized = ((value: {
    active: boolean;
    categoryId: number;
    corporateIds: string[];
    interval: number;
    maxDaysAfterExpiration: number;
    minDaysAfterExpiration: number;
    name: string;
    repositoryId: number;
    startDate: { year: number; month: number; day: number } | null;
  }): unknown => {
    return {
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      categoryId: ((value: number): unknown => {
        return value;
      })(value["categoryId"]),
      corporateIds: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["corporateIds"]),
      interval: ((value: number): unknown => {
        return value;
      })(value["interval"]),
      maxDaysAfterExpiration: ((value: number): unknown => {
        return value;
      })(value["maxDaysAfterExpiration"]),
      minDaysAfterExpiration: ((value: number): unknown => {
        return value;
      })(value["minDaysAfterExpiration"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      repositoryId: ((value: number): unknown => {
        return value;
      })(value["repositoryId"]),
      startDate: ((value: { year: number; month: number; day: number } | null): unknown => {
        if (value === null) {
          return null;
        }
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["startDate"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailFollowUpCategoryAdminView(str: string): EmailFollowUpCategoryAdminView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          active: boolean;
          categoryId: number;
          corporateIds: string[];
          interval: number;
          maxDaysAfterExpiration: number;
          minDaysAfterExpiration: number;
          name: string;
          repositoryId: number;
          startDate: { year: number; month: number; day: number } | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "categoryId")) {
      return { success: false };
    }
    const parsed_categoryId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["categoryId"]);
    })();
    if (!parsed_categoryId.success) {
      return { success: false };
    }
    if (!hasProp(value, "corporateIds")) {
      return { success: false };
    }
    const parsed_corporateIds = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["corporateIds"]);
    })();
    if (!parsed_corporateIds.success) {
      return { success: false };
    }
    if (!hasProp(value, "interval")) {
      return { success: false };
    }
    const parsed_interval = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["interval"]);
    })();
    if (!parsed_interval.success) {
      return { success: false };
    }
    if (!hasProp(value, "maxDaysAfterExpiration")) {
      return { success: false };
    }
    const parsed_maxDaysAfterExpiration = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["maxDaysAfterExpiration"]);
    })();
    if (!parsed_maxDaysAfterExpiration.success) {
      return { success: false };
    }
    if (!hasProp(value, "minDaysAfterExpiration")) {
      return { success: false };
    }
    const parsed_minDaysAfterExpiration = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["minDaysAfterExpiration"]);
    })();
    if (!parsed_minDaysAfterExpiration.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "repositoryId")) {
      return { success: false };
    }
    const parsed_repositoryId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["repositoryId"]);
    })();
    if (!parsed_repositoryId.success) {
      return { success: false };
    }
    if (!hasProp(value, "startDate")) {
      return { success: false };
    }
    const parsed_startDate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["startDate"]);
    })();
    if (!parsed_startDate.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        active: parsed_active.value,
        categoryId: parsed_categoryId.value,
        corporateIds: parsed_corporateIds.value,
        interval: parsed_interval.value,
        maxDaysAfterExpiration: parsed_maxDaysAfterExpiration.value,
        minDaysAfterExpiration: parsed_minDaysAfterExpiration.value,
        name: parsed_name.value,
        repositoryId: parsed_repositoryId.value,
        startDate: parsed_startDate.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailManualCategoryAdminView
export function serialize_EmailManualCategoryAdminView(obj: EmailManualCategoryAdminView): string {
  const serialized = ((value: { categoryId: number; corporateIds: string[]; name: string; repositoryId: number }): unknown => {
    return {
      categoryId: ((value: number): unknown => {
        return value;
      })(value["categoryId"]),
      corporateIds: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["corporateIds"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      repositoryId: ((value: number): unknown => {
        return value;
      })(value["repositoryId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailManualCategoryAdminView(str: string): EmailManualCategoryAdminView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          categoryId: number;
          corporateIds: string[];
          name: string;
          repositoryId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "categoryId")) {
      return { success: false };
    }
    const parsed_categoryId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["categoryId"]);
    })();
    if (!parsed_categoryId.success) {
      return { success: false };
    }
    if (!hasProp(value, "corporateIds")) {
      return { success: false };
    }
    const parsed_corporateIds = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["corporateIds"]);
    })();
    if (!parsed_corporateIds.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "repositoryId")) {
      return { success: false };
    }
    const parsed_repositoryId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["repositoryId"]);
    })();
    if (!parsed_repositoryId.success) {
      return { success: false };
    }
    return { success: true, value: { categoryId: parsed_categoryId.value, corporateIds: parsed_corporateIds.value, name: parsed_name.value, repositoryId: parsed_repositoryId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailOnboardingCategoryAdminView
export function serialize_EmailOnboardingCategoryAdminView(obj: EmailOnboardingCategoryAdminView): string {
  const serialized = ((value: { active: boolean; categoryId: number; maxDaysAfterSignup: number; minDaysAfterSignup: number; name: string; repositoryId: number }): unknown => {
    return {
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      categoryId: ((value: number): unknown => {
        return value;
      })(value["categoryId"]),
      maxDaysAfterSignup: ((value: number): unknown => {
        return value;
      })(value["maxDaysAfterSignup"]),
      minDaysAfterSignup: ((value: number): unknown => {
        return value;
      })(value["minDaysAfterSignup"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      repositoryId: ((value: number): unknown => {
        return value;
      })(value["repositoryId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailOnboardingCategoryAdminView(str: string): EmailOnboardingCategoryAdminView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          active: boolean;
          categoryId: number;
          maxDaysAfterSignup: number;
          minDaysAfterSignup: number;
          name: string;
          repositoryId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "categoryId")) {
      return { success: false };
    }
    const parsed_categoryId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["categoryId"]);
    })();
    if (!parsed_categoryId.success) {
      return { success: false };
    }
    if (!hasProp(value, "maxDaysAfterSignup")) {
      return { success: false };
    }
    const parsed_maxDaysAfterSignup = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["maxDaysAfterSignup"]);
    })();
    if (!parsed_maxDaysAfterSignup.success) {
      return { success: false };
    }
    if (!hasProp(value, "minDaysAfterSignup")) {
      return { success: false };
    }
    const parsed_minDaysAfterSignup = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["minDaysAfterSignup"]);
    })();
    if (!parsed_minDaysAfterSignup.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "repositoryId")) {
      return { success: false };
    }
    const parsed_repositoryId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["repositoryId"]);
    })();
    if (!parsed_repositoryId.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        active: parsed_active.value,
        categoryId: parsed_categoryId.value,
        maxDaysAfterSignup: parsed_maxDaysAfterSignup.value,
        minDaysAfterSignup: parsed_minDaysAfterSignup.value,
        name: parsed_name.value,
        repositoryId: parsed_repositoryId.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailPoolAdminView
export function serialize_EmailPoolAdminView(obj: EmailPoolAdminView): string {
  const serialized = ((value: { corporateIds: number[]; name: string }): unknown => {
    return {
      corporateIds: ((value: number[]): unknown => {
        return value.map((value: number): unknown => {
          return value;
        });
      })(value["corporateIds"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailPoolAdminView(str: string): EmailPoolAdminView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          corporateIds: number[];
          name: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "corporateIds")) {
      return { success: false };
    }
    const parsed_corporateIds = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
          if (typeof value === "number") {
            return { success: true, value: value };
          }
          return { success: false };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["corporateIds"]);
    })();
    if (!parsed_corporateIds.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    return { success: true, value: { corporateIds: parsed_corporateIds.value, name: parsed_name.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailPoolForm
export function serialize_EmailPoolForm(obj: EmailPoolForm): string {
  const serialized = ((value: { corporateIds: number[] }): unknown => {
    return {
      corporateIds: ((value: number[]): unknown => {
        return value.map((value: number): unknown => {
          return value;
        });
      })(value["corporateIds"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailPoolForm(str: string): EmailPoolForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          corporateIds: number[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "corporateIds")) {
      return { success: false };
    }
    const parsed_corporateIds = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
          if (typeof value === "number") {
            return { success: true, value: value };
          }
          return { success: false };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["corporateIds"]);
    })();
    if (!parsed_corporateIds.success) {
      return { success: false };
    }
    return { success: true, value: { corporateIds: parsed_corporateIds.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailRenewalCategoryAdminView
export function serialize_EmailRenewalCategoryAdminView(obj: EmailRenewalCategoryAdminView): string {
  const serialized = ((value: { active: boolean; categoryId: number; maxDaysAfterExpiration: number; minDaysAfterExpiration: number; name: string; productIds: number[]; repositoryId: number }): unknown => {
    return {
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      categoryId: ((value: number): unknown => {
        return value;
      })(value["categoryId"]),
      maxDaysAfterExpiration: ((value: number): unknown => {
        return value;
      })(value["maxDaysAfterExpiration"]),
      minDaysAfterExpiration: ((value: number): unknown => {
        return value;
      })(value["minDaysAfterExpiration"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      productIds: ((value: number[]): unknown => {
        return value.map((value: number): unknown => {
          return value;
        });
      })(value["productIds"]),
      repositoryId: ((value: number): unknown => {
        return value;
      })(value["repositoryId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailRenewalCategoryAdminView(str: string): EmailRenewalCategoryAdminView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          active: boolean;
          categoryId: number;
          maxDaysAfterExpiration: number;
          minDaysAfterExpiration: number;
          name: string;
          productIds: number[];
          repositoryId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "categoryId")) {
      return { success: false };
    }
    const parsed_categoryId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["categoryId"]);
    })();
    if (!parsed_categoryId.success) {
      return { success: false };
    }
    if (!hasProp(value, "maxDaysAfterExpiration")) {
      return { success: false };
    }
    const parsed_maxDaysAfterExpiration = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["maxDaysAfterExpiration"]);
    })();
    if (!parsed_maxDaysAfterExpiration.success) {
      return { success: false };
    }
    if (!hasProp(value, "minDaysAfterExpiration")) {
      return { success: false };
    }
    const parsed_minDaysAfterExpiration = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["minDaysAfterExpiration"]);
    })();
    if (!parsed_minDaysAfterExpiration.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "productIds")) {
      return { success: false };
    }
    const parsed_productIds = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
          if (typeof value === "number") {
            return { success: true, value: value };
          }
          return { success: false };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["productIds"]);
    })();
    if (!parsed_productIds.success) {
      return { success: false };
    }
    if (!hasProp(value, "repositoryId")) {
      return { success: false };
    }
    const parsed_repositoryId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["repositoryId"]);
    })();
    if (!parsed_repositoryId.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        active: parsed_active.value,
        categoryId: parsed_categoryId.value,
        maxDaysAfterExpiration: parsed_maxDaysAfterExpiration.value,
        minDaysAfterExpiration: parsed_minDaysAfterExpiration.value,
        name: parsed_name.value,
        productIds: parsed_productIds.value,
        repositoryId: parsed_repositoryId.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailRepositoryAdminView
export function serialize_EmailRepositoryAdminView(obj: EmailRepositoryAdminView): string {
  const serialized = ((value: { comment: string | null; name: string; repositoryId: number; type: EmailRepositoryType }): unknown => {
    return {
      comment: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["comment"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      repositoryId: ((value: number): unknown => {
        return value;
      })(value["repositoryId"]),
      type: ((value: "DEFAULT" | "FOLLOWUP" | "RENEWAL" | "EDUCATION" | "ONBOARDING" | "MANUAL"): unknown => {
        return value;
      })(value["type"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailRepositoryAdminView(str: string): EmailRepositoryAdminView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          comment: string | null;
          name: string;
          repositoryId: number;
          type: EmailRepositoryType;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "comment")) {
      return { success: false };
    }
    const parsed_comment = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["comment"]);
    })();
    if (!parsed_comment.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "repositoryId")) {
      return { success: false };
    }
    const parsed_repositoryId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["repositoryId"]);
    })();
    if (!parsed_repositoryId.success) {
      return { success: false };
    }
    if (!hasProp(value, "type")) {
      return { success: false };
    }
    const parsed_type = (() => {
      return ((value: unknown): { success: false } | { success: true; value: EmailRepositoryType } => {
        return ((value: unknown): { success: false } | { success: true; value: "DEFAULT" | "FOLLOWUP" | "RENEWAL" | "EDUCATION" | "ONBOARDING" | "MANUAL" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "DEFAULT":
              return { success: true, value: "DEFAULT" };
            case "FOLLOWUP":
              return { success: true, value: "FOLLOWUP" };
            case "RENEWAL":
              return { success: true, value: "RENEWAL" };
            case "EDUCATION":
              return { success: true, value: "EDUCATION" };
            case "ONBOARDING":
              return { success: true, value: "ONBOARDING" };
            case "MANUAL":
              return { success: true, value: "MANUAL" };
            default:
              return { success: false };
          }
        })(value);
      })(value["type"]);
    })();
    if (!parsed_type.success) {
      return { success: false };
    }
    return { success: true, value: { comment: parsed_comment.value, name: parsed_name.value, repositoryId: parsed_repositoryId.value, type: parsed_type.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailRepositoryType
export function serialize_EmailRepositoryType(obj: EmailRepositoryType): string {
  const serialized = ((value: "DEFAULT" | "FOLLOWUP" | "RENEWAL" | "EDUCATION" | "ONBOARDING" | "MANUAL"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailRepositoryType(str: string): EmailRepositoryType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "DEFAULT" | "FOLLOWUP" | "RENEWAL" | "EDUCATION" | "ONBOARDING" | "MANUAL" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "DEFAULT":
        return { success: true, value: "DEFAULT" };
      case "FOLLOWUP":
        return { success: true, value: "FOLLOWUP" };
      case "RENEWAL":
        return { success: true, value: "RENEWAL" };
      case "EDUCATION":
        return { success: true, value: "EDUCATION" };
      case "ONBOARDING":
        return { success: true, value: "ONBOARDING" };
      case "MANUAL":
        return { success: true, value: "MANUAL" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailTemplateCategoryInfo
export function serialize_EmailTemplateCategoryInfo(obj: EmailTemplateCategoryInfo): string {
  const serialized = ((value: {
    alternativeRecipientDefault: string;
    categoryName: string;
    defaultSender: string;
    footerOptions: DropdownEntry[];
    repositoryId: number;
    repositoryName: string;
    runningBatchProcess: boolean;
    templates: EmailTemplateEditView[];
  }): unknown => {
    return {
      alternativeRecipientDefault: ((value: string): unknown => {
        return value;
      })(value["alternativeRecipientDefault"]),
      categoryName: ((value: string): unknown => {
        return value;
      })(value["categoryName"]),
      defaultSender: ((value: string): unknown => {
        return value;
      })(value["defaultSender"]),
      footerOptions: ((value: DropdownEntry[]): unknown => {
        return value.map((value: { text: string; value: string | null }): unknown => {
          return {
            text: ((value: string): unknown => {
              return value;
            })(value["text"]),
            value: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["value"]),
          };
        });
      })(value["footerOptions"]),
      repositoryId: ((value: number): unknown => {
        return value;
      })(value["repositoryId"]),
      repositoryName: ((value: string): unknown => {
        return value;
      })(value["repositoryName"]),
      runningBatchProcess: ((value: boolean): unknown => {
        return value;
      })(value["runningBatchProcess"]),
      templates: ((value: EmailTemplateEditView[]): unknown => {
        return value.map(
          (value: {
            analyticsCampaign: string | null;
            attachmentPath: string | null;
            contentHtml: string | null;
            contentPlain: string | null;
            contentSidebar: string | null;
            emailFrameId: number | null;
            emailTitle: string | null;
            footerSnippet: string | null;
            language: Language;
            linkTemplateCategoryId: number | null;
            linkTemplateId: number | null;
            sender: string | null;
            subject: string;
            templateId: number;
            templateLanguage: "SNIPPETS" | "VELOCITY";
          }): unknown => {
            return {
              analyticsCampaign: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["analyticsCampaign"]),
              attachmentPath: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["attachmentPath"]),
              contentHtml: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["contentHtml"]),
              contentPlain: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["contentPlain"]),
              contentSidebar: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["contentSidebar"]),
              emailFrameId: ((value: number | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["emailFrameId"]),
              emailTitle: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["emailTitle"]),
              footerSnippet: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["footerSnippet"]),
              language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                return value;
              })(value["language"]),
              linkTemplateCategoryId: ((value: number | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["linkTemplateCategoryId"]),
              linkTemplateId: ((value: number | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["linkTemplateId"]),
              sender: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["sender"]),
              subject: ((value: string): unknown => {
                return value;
              })(value["subject"]),
              templateId: ((value: number): unknown => {
                return value;
              })(value["templateId"]),
              templateLanguage: ((value: "SNIPPETS" | "VELOCITY"): unknown => {
                return value;
              })(value["templateLanguage"]),
            };
          },
        );
      })(value["templates"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailTemplateCategoryInfo(str: string): EmailTemplateCategoryInfo | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          alternativeRecipientDefault: string;
          categoryName: string;
          defaultSender: string;
          footerOptions: DropdownEntry[];
          repositoryId: number;
          repositoryName: string;
          runningBatchProcess: boolean;
          templates: EmailTemplateEditView[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "alternativeRecipientDefault")) {
      return { success: false };
    }
    const parsed_alternativeRecipientDefault = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["alternativeRecipientDefault"]);
    })();
    if (!parsed_alternativeRecipientDefault.success) {
      return { success: false };
    }
    if (!hasProp(value, "categoryName")) {
      return { success: false };
    }
    const parsed_categoryName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["categoryName"]);
    })();
    if (!parsed_categoryName.success) {
      return { success: false };
    }
    if (!hasProp(value, "defaultSender")) {
      return { success: false };
    }
    const parsed_defaultSender = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["defaultSender"]);
    })();
    if (!parsed_defaultSender.success) {
      return { success: false };
    }
    if (!hasProp(value, "footerOptions")) {
      return { success: false };
    }
    const parsed_footerOptions = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DropdownEntry[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: DropdownEntry } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  text: string;
                  value: string | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "text")) {
              return { success: false };
            }
            const parsed_text = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["text"]);
            })();
            if (!parsed_text.success) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { text: parsed_text.value, value: parsed_value.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["footerOptions"]);
    })();
    if (!parsed_footerOptions.success) {
      return { success: false };
    }
    if (!hasProp(value, "repositoryId")) {
      return { success: false };
    }
    const parsed_repositoryId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["repositoryId"]);
    })();
    if (!parsed_repositoryId.success) {
      return { success: false };
    }
    if (!hasProp(value, "repositoryName")) {
      return { success: false };
    }
    const parsed_repositoryName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["repositoryName"]);
    })();
    if (!parsed_repositoryName.success) {
      return { success: false };
    }
    if (!hasProp(value, "runningBatchProcess")) {
      return { success: false };
    }
    const parsed_runningBatchProcess = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["runningBatchProcess"]);
    })();
    if (!parsed_runningBatchProcess.success) {
      return { success: false };
    }
    if (!hasProp(value, "templates")) {
      return { success: false };
    }
    const parsed_templates = (() => {
      return ((value: unknown): { success: false } | { success: true; value: EmailTemplateEditView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: EmailTemplateEditView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  analyticsCampaign: string | null;
                  attachmentPath: string | null;
                  contentHtml: string | null;
                  contentPlain: string | null;
                  contentSidebar: string | null;
                  emailFrameId: number | null;
                  emailTitle: string | null;
                  footerSnippet: string | null;
                  language: Language;
                  linkTemplateCategoryId: number | null;
                  linkTemplateId: number | null;
                  sender: string | null;
                  subject: string;
                  templateId: number;
                  templateLanguage: "SNIPPETS" | "VELOCITY";
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "analyticsCampaign")) {
              return { success: false };
            }
            const parsed_analyticsCampaign = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["analyticsCampaign"]);
            })();
            if (!parsed_analyticsCampaign.success) {
              return { success: false };
            }
            if (!hasProp(value, "attachmentPath")) {
              return { success: false };
            }
            const parsed_attachmentPath = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["attachmentPath"]);
            })();
            if (!parsed_attachmentPath.success) {
              return { success: false };
            }
            if (!hasProp(value, "contentHtml")) {
              return { success: false };
            }
            const parsed_contentHtml = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["contentHtml"]);
            })();
            if (!parsed_contentHtml.success) {
              return { success: false };
            }
            if (!hasProp(value, "contentPlain")) {
              return { success: false };
            }
            const parsed_contentPlain = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["contentPlain"]);
            })();
            if (!parsed_contentPlain.success) {
              return { success: false };
            }
            if (!hasProp(value, "contentSidebar")) {
              return { success: false };
            }
            const parsed_contentSidebar = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["contentSidebar"]);
            })();
            if (!parsed_contentSidebar.success) {
              return { success: false };
            }
            if (!hasProp(value, "emailFrameId")) {
              return { success: false };
            }
            const parsed_emailFrameId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["emailFrameId"]);
            })();
            if (!parsed_emailFrameId.success) {
              return { success: false };
            }
            if (!hasProp(value, "emailTitle")) {
              return { success: false };
            }
            const parsed_emailTitle = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["emailTitle"]);
            })();
            if (!parsed_emailTitle.success) {
              return { success: false };
            }
            if (!hasProp(value, "footerSnippet")) {
              return { success: false };
            }
            const parsed_footerSnippet = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["footerSnippet"]);
            })();
            if (!parsed_footerSnippet.success) {
              return { success: false };
            }
            if (!hasProp(value, "language")) {
              return { success: false };
            }
            const parsed_language = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Language } => {
                return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "en":
                      return { success: true, value: "en" };
                    case "de":
                      return { success: true, value: "de" };
                    case "es":
                      return { success: true, value: "es" };
                    case "ru":
                      return { success: true, value: "ru" };
                    case "zh":
                      return { success: true, value: "zh" };
                    case "pt":
                      return { success: true, value: "pt" };
                    case "fr":
                      return { success: true, value: "fr" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["language"]);
            })();
            if (!parsed_language.success) {
              return { success: false };
            }
            if (!hasProp(value, "linkTemplateCategoryId")) {
              return { success: false };
            }
            const parsed_linkTemplateCategoryId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["linkTemplateCategoryId"]);
            })();
            if (!parsed_linkTemplateCategoryId.success) {
              return { success: false };
            }
            if (!hasProp(value, "linkTemplateId")) {
              return { success: false };
            }
            const parsed_linkTemplateId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["linkTemplateId"]);
            })();
            if (!parsed_linkTemplateId.success) {
              return { success: false };
            }
            if (!hasProp(value, "sender")) {
              return { success: false };
            }
            const parsed_sender = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["sender"]);
            })();
            if (!parsed_sender.success) {
              return { success: false };
            }
            if (!hasProp(value, "subject")) {
              return { success: false };
            }
            const parsed_subject = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["subject"]);
            })();
            if (!parsed_subject.success) {
              return { success: false };
            }
            if (!hasProp(value, "templateId")) {
              return { success: false };
            }
            const parsed_templateId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["templateId"]);
            })();
            if (!parsed_templateId.success) {
              return { success: false };
            }
            if (!hasProp(value, "templateLanguage")) {
              return { success: false };
            }
            const parsed_templateLanguage = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "SNIPPETS" | "VELOCITY" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "SNIPPETS":
                    return { success: true, value: "SNIPPETS" };
                  case "VELOCITY":
                    return { success: true, value: "VELOCITY" };
                  default:
                    return { success: false };
                }
              })(value["templateLanguage"]);
            })();
            if (!parsed_templateLanguage.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                analyticsCampaign: parsed_analyticsCampaign.value,
                attachmentPath: parsed_attachmentPath.value,
                contentHtml: parsed_contentHtml.value,
                contentPlain: parsed_contentPlain.value,
                contentSidebar: parsed_contentSidebar.value,
                emailFrameId: parsed_emailFrameId.value,
                emailTitle: parsed_emailTitle.value,
                footerSnippet: parsed_footerSnippet.value,
                language: parsed_language.value,
                linkTemplateCategoryId: parsed_linkTemplateCategoryId.value,
                linkTemplateId: parsed_linkTemplateId.value,
                sender: parsed_sender.value,
                subject: parsed_subject.value,
                templateId: parsed_templateId.value,
                templateLanguage: parsed_templateLanguage.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["templates"]);
    })();
    if (!parsed_templates.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        alternativeRecipientDefault: parsed_alternativeRecipientDefault.value,
        categoryName: parsed_categoryName.value,
        defaultSender: parsed_defaultSender.value,
        footerOptions: parsed_footerOptions.value,
        repositoryId: parsed_repositoryId.value,
        repositoryName: parsed_repositoryName.value,
        runningBatchProcess: parsed_runningBatchProcess.value,
        templates: parsed_templates.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailTemplateEditView
export function serialize_EmailTemplateEditView(obj: EmailTemplateEditView): string {
  const serialized = ((value: {
    analyticsCampaign: string | null;
    attachmentPath: string | null;
    contentHtml: string | null;
    contentPlain: string | null;
    contentSidebar: string | null;
    emailFrameId: number | null;
    emailTitle: string | null;
    footerSnippet: string | null;
    language: Language;
    linkTemplateCategoryId: number | null;
    linkTemplateId: number | null;
    sender: string | null;
    subject: string;
    templateId: number;
    templateLanguage: "SNIPPETS" | "VELOCITY";
  }): unknown => {
    return {
      analyticsCampaign: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["analyticsCampaign"]),
      attachmentPath: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["attachmentPath"]),
      contentHtml: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["contentHtml"]),
      contentPlain: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["contentPlain"]),
      contentSidebar: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["contentSidebar"]),
      emailFrameId: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["emailFrameId"]),
      emailTitle: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["emailTitle"]),
      footerSnippet: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["footerSnippet"]),
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      linkTemplateCategoryId: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["linkTemplateCategoryId"]),
      linkTemplateId: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["linkTemplateId"]),
      sender: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["sender"]),
      subject: ((value: string): unknown => {
        return value;
      })(value["subject"]),
      templateId: ((value: number): unknown => {
        return value;
      })(value["templateId"]),
      templateLanguage: ((value: "SNIPPETS" | "VELOCITY"): unknown => {
        return value;
      })(value["templateLanguage"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailTemplateEditView(str: string): EmailTemplateEditView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          analyticsCampaign: string | null;
          attachmentPath: string | null;
          contentHtml: string | null;
          contentPlain: string | null;
          contentSidebar: string | null;
          emailFrameId: number | null;
          emailTitle: string | null;
          footerSnippet: string | null;
          language: Language;
          linkTemplateCategoryId: number | null;
          linkTemplateId: number | null;
          sender: string | null;
          subject: string;
          templateId: number;
          templateLanguage: "SNIPPETS" | "VELOCITY";
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "analyticsCampaign")) {
      return { success: false };
    }
    const parsed_analyticsCampaign = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["analyticsCampaign"]);
    })();
    if (!parsed_analyticsCampaign.success) {
      return { success: false };
    }
    if (!hasProp(value, "attachmentPath")) {
      return { success: false };
    }
    const parsed_attachmentPath = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["attachmentPath"]);
    })();
    if (!parsed_attachmentPath.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentHtml")) {
      return { success: false };
    }
    const parsed_contentHtml = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["contentHtml"]);
    })();
    if (!parsed_contentHtml.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentPlain")) {
      return { success: false };
    }
    const parsed_contentPlain = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["contentPlain"]);
    })();
    if (!parsed_contentPlain.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentSidebar")) {
      return { success: false };
    }
    const parsed_contentSidebar = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["contentSidebar"]);
    })();
    if (!parsed_contentSidebar.success) {
      return { success: false };
    }
    if (!hasProp(value, "emailFrameId")) {
      return { success: false };
    }
    const parsed_emailFrameId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["emailFrameId"]);
    })();
    if (!parsed_emailFrameId.success) {
      return { success: false };
    }
    if (!hasProp(value, "emailTitle")) {
      return { success: false };
    }
    const parsed_emailTitle = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["emailTitle"]);
    })();
    if (!parsed_emailTitle.success) {
      return { success: false };
    }
    if (!hasProp(value, "footerSnippet")) {
      return { success: false };
    }
    const parsed_footerSnippet = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["footerSnippet"]);
    })();
    if (!parsed_footerSnippet.success) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language } => {
        return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "en":
              return { success: true, value: "en" };
            case "de":
              return { success: true, value: "de" };
            case "es":
              return { success: true, value: "es" };
            case "ru":
              return { success: true, value: "ru" };
            case "zh":
              return { success: true, value: "zh" };
            case "pt":
              return { success: true, value: "pt" };
            case "fr":
              return { success: true, value: "fr" };
            default:
              return { success: false };
          }
        })(value);
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "linkTemplateCategoryId")) {
      return { success: false };
    }
    const parsed_linkTemplateCategoryId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["linkTemplateCategoryId"]);
    })();
    if (!parsed_linkTemplateCategoryId.success) {
      return { success: false };
    }
    if (!hasProp(value, "linkTemplateId")) {
      return { success: false };
    }
    const parsed_linkTemplateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["linkTemplateId"]);
    })();
    if (!parsed_linkTemplateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "sender")) {
      return { success: false };
    }
    const parsed_sender = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["sender"]);
    })();
    if (!parsed_sender.success) {
      return { success: false };
    }
    if (!hasProp(value, "subject")) {
      return { success: false };
    }
    const parsed_subject = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["subject"]);
    })();
    if (!parsed_subject.success) {
      return { success: false };
    }
    if (!hasProp(value, "templateId")) {
      return { success: false };
    }
    const parsed_templateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["templateId"]);
    })();
    if (!parsed_templateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "templateLanguage")) {
      return { success: false };
    }
    const parsed_templateLanguage = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "SNIPPETS" | "VELOCITY" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "SNIPPETS":
            return { success: true, value: "SNIPPETS" };
          case "VELOCITY":
            return { success: true, value: "VELOCITY" };
          default:
            return { success: false };
        }
      })(value["templateLanguage"]);
    })();
    if (!parsed_templateLanguage.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        analyticsCampaign: parsed_analyticsCampaign.value,
        attachmentPath: parsed_attachmentPath.value,
        contentHtml: parsed_contentHtml.value,
        contentPlain: parsed_contentPlain.value,
        contentSidebar: parsed_contentSidebar.value,
        emailFrameId: parsed_emailFrameId.value,
        emailTitle: parsed_emailTitle.value,
        footerSnippet: parsed_footerSnippet.value,
        language: parsed_language.value,
        linkTemplateCategoryId: parsed_linkTemplateCategoryId.value,
        linkTemplateId: parsed_linkTemplateId.value,
        sender: parsed_sender.value,
        subject: parsed_subject.value,
        templateId: parsed_templateId.value,
        templateLanguage: parsed_templateLanguage.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailTemplateForm
export function serialize_EmailTemplateForm(obj: EmailTemplateForm): string {
  const serialized = ((value: {
    analyticsCampaign: string | null;
    attachmentPath: string | null;
    contentHtml: string | null;
    contentPlain: string | null;
    contentSidebar: string | null;
    emailFrameId: number | null;
    emailTitle: string | null;
    footerSnippet: string | null;
    language: Language;
    linkTemplateId: number | null;
    sender: string | null;
    subject: string;
    templateLanguage: "SNIPPETS" | "VELOCITY";
  }): unknown => {
    return {
      analyticsCampaign: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["analyticsCampaign"]),
      attachmentPath: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["attachmentPath"]),
      contentHtml: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["contentHtml"]),
      contentPlain: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["contentPlain"]),
      contentSidebar: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["contentSidebar"]),
      emailFrameId: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["emailFrameId"]),
      emailTitle: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["emailTitle"]),
      footerSnippet: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["footerSnippet"]),
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      linkTemplateId: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["linkTemplateId"]),
      sender: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["sender"]),
      subject: ((value: string): unknown => {
        return value;
      })(value["subject"]),
      templateLanguage: ((value: "SNIPPETS" | "VELOCITY"): unknown => {
        return value;
      })(value["templateLanguage"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailTemplateForm(str: string): EmailTemplateForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          analyticsCampaign: string | null;
          attachmentPath: string | null;
          contentHtml: string | null;
          contentPlain: string | null;
          contentSidebar: string | null;
          emailFrameId: number | null;
          emailTitle: string | null;
          footerSnippet: string | null;
          language: Language;
          linkTemplateId: number | null;
          sender: string | null;
          subject: string;
          templateLanguage: "SNIPPETS" | "VELOCITY";
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "analyticsCampaign")) {
      return { success: false };
    }
    const parsed_analyticsCampaign = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["analyticsCampaign"]);
    })();
    if (!parsed_analyticsCampaign.success) {
      return { success: false };
    }
    if (!hasProp(value, "attachmentPath")) {
      return { success: false };
    }
    const parsed_attachmentPath = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["attachmentPath"]);
    })();
    if (!parsed_attachmentPath.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentHtml")) {
      return { success: false };
    }
    const parsed_contentHtml = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["contentHtml"]);
    })();
    if (!parsed_contentHtml.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentPlain")) {
      return { success: false };
    }
    const parsed_contentPlain = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["contentPlain"]);
    })();
    if (!parsed_contentPlain.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentSidebar")) {
      return { success: false };
    }
    const parsed_contentSidebar = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["contentSidebar"]);
    })();
    if (!parsed_contentSidebar.success) {
      return { success: false };
    }
    if (!hasProp(value, "emailFrameId")) {
      return { success: false };
    }
    const parsed_emailFrameId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["emailFrameId"]);
    })();
    if (!parsed_emailFrameId.success) {
      return { success: false };
    }
    if (!hasProp(value, "emailTitle")) {
      return { success: false };
    }
    const parsed_emailTitle = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["emailTitle"]);
    })();
    if (!parsed_emailTitle.success) {
      return { success: false };
    }
    if (!hasProp(value, "footerSnippet")) {
      return { success: false };
    }
    const parsed_footerSnippet = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["footerSnippet"]);
    })();
    if (!parsed_footerSnippet.success) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language } => {
        return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "en":
              return { success: true, value: "en" };
            case "de":
              return { success: true, value: "de" };
            case "es":
              return { success: true, value: "es" };
            case "ru":
              return { success: true, value: "ru" };
            case "zh":
              return { success: true, value: "zh" };
            case "pt":
              return { success: true, value: "pt" };
            case "fr":
              return { success: true, value: "fr" };
            default:
              return { success: false };
          }
        })(value);
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "linkTemplateId")) {
      return { success: false };
    }
    const parsed_linkTemplateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["linkTemplateId"]);
    })();
    if (!parsed_linkTemplateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "sender")) {
      return { success: false };
    }
    const parsed_sender = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["sender"]);
    })();
    if (!parsed_sender.success) {
      return { success: false };
    }
    if (!hasProp(value, "subject")) {
      return { success: false };
    }
    const parsed_subject = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["subject"]);
    })();
    if (!parsed_subject.success) {
      return { success: false };
    }
    if (!hasProp(value, "templateLanguage")) {
      return { success: false };
    }
    const parsed_templateLanguage = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "SNIPPETS" | "VELOCITY" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "SNIPPETS":
            return { success: true, value: "SNIPPETS" };
          case "VELOCITY":
            return { success: true, value: "VELOCITY" };
          default:
            return { success: false };
        }
      })(value["templateLanguage"]);
    })();
    if (!parsed_templateLanguage.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        analyticsCampaign: parsed_analyticsCampaign.value,
        attachmentPath: parsed_attachmentPath.value,
        contentHtml: parsed_contentHtml.value,
        contentPlain: parsed_contentPlain.value,
        contentSidebar: parsed_contentSidebar.value,
        emailFrameId: parsed_emailFrameId.value,
        emailTitle: parsed_emailTitle.value,
        footerSnippet: parsed_footerSnippet.value,
        language: parsed_language.value,
        linkTemplateId: parsed_linkTemplateId.value,
        sender: parsed_sender.value,
        subject: parsed_subject.value,
        templateLanguage: parsed_templateLanguage.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailTemplateTestForm
export function serialize_EmailTemplateTestForm(obj: EmailTemplateTestForm): string {
  const serialized = ((value: {
    alternativeRecipient: string | null;
    corporateId: number | null;
    customerId: number;
    dataIds: number[];
    format: EmailTemplateTestFormat;
    method: EmailTemplateTestMethod;
    subscriptionId: number | null;
  }): unknown => {
    return {
      alternativeRecipient: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["alternativeRecipient"]),
      corporateId: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["corporateId"]),
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
      dataIds: ((value: number[]): unknown => {
        return value.map((value: number): unknown => {
          return value;
        });
      })(value["dataIds"]),
      format: ((value: "HTML" | "PLAINTEXT"): unknown => {
        return value;
      })(value["format"]),
      method: ((value: "BROWSER" | "EMAIL"): unknown => {
        return value;
      })(value["method"]),
      subscriptionId: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["subscriptionId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailTemplateTestForm(str: string): EmailTemplateTestForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          alternativeRecipient: string | null;
          corporateId: number | null;
          customerId: number;
          dataIds: number[];
          format: EmailTemplateTestFormat;
          method: EmailTemplateTestMethod;
          subscriptionId: number | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "alternativeRecipient")) {
      return { success: false };
    }
    const parsed_alternativeRecipient = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["alternativeRecipient"]);
    })();
    if (!parsed_alternativeRecipient.success) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "dataIds")) {
      return { success: false };
    }
    const parsed_dataIds = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
          if (typeof value === "number") {
            return { success: true, value: value };
          }
          return { success: false };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["dataIds"]);
    })();
    if (!parsed_dataIds.success) {
      return { success: false };
    }
    if (!hasProp(value, "format")) {
      return { success: false };
    }
    const parsed_format = (() => {
      return ((value: unknown): { success: false } | { success: true; value: EmailTemplateTestFormat } => {
        return ((value: unknown): { success: false } | { success: true; value: "HTML" | "PLAINTEXT" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "HTML":
              return { success: true, value: "HTML" };
            case "PLAINTEXT":
              return { success: true, value: "PLAINTEXT" };
            default:
              return { success: false };
          }
        })(value);
      })(value["format"]);
    })();
    if (!parsed_format.success) {
      return { success: false };
    }
    if (!hasProp(value, "method")) {
      return { success: false };
    }
    const parsed_method = (() => {
      return ((value: unknown): { success: false } | { success: true; value: EmailTemplateTestMethod } => {
        return ((value: unknown): { success: false } | { success: true; value: "BROWSER" | "EMAIL" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "BROWSER":
              return { success: true, value: "BROWSER" };
            case "EMAIL":
              return { success: true, value: "EMAIL" };
            default:
              return { success: false };
          }
        })(value);
      })(value["method"]);
    })();
    if (!parsed_method.success) {
      return { success: false };
    }
    if (!hasProp(value, "subscriptionId")) {
      return { success: false };
    }
    const parsed_subscriptionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["subscriptionId"]);
    })();
    if (!parsed_subscriptionId.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        alternativeRecipient: parsed_alternativeRecipient.value,
        corporateId: parsed_corporateId.value,
        customerId: parsed_customerId.value,
        dataIds: parsed_dataIds.value,
        format: parsed_format.value,
        method: parsed_method.value,
        subscriptionId: parsed_subscriptionId.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailTemplateTestFormat
export function serialize_EmailTemplateTestFormat(obj: EmailTemplateTestFormat): string {
  const serialized = ((value: "HTML" | "PLAINTEXT"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailTemplateTestFormat(str: string): EmailTemplateTestFormat | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "HTML" | "PLAINTEXT" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "HTML":
        return { success: true, value: "HTML" };
      case "PLAINTEXT":
        return { success: true, value: "PLAINTEXT" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailTemplateTestMethod
export function serialize_EmailTemplateTestMethod(obj: EmailTemplateTestMethod): string {
  const serialized = ((value: "BROWSER" | "EMAIL"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailTemplateTestMethod(str: string): EmailTemplateTestMethod | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "BROWSER" | "EMAIL" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "BROWSER":
        return { success: true, value: "BROWSER" };
      case "EMAIL":
        return { success: true, value: "EMAIL" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// EmailTemplateTestResult
export function serialize_EmailTemplateTestResult(obj: EmailTemplateTestResult): string {
  const serialized = ((value: { format: EmailTemplateTestFormat; method: EmailTemplateTestMethod; resultBody: string | null; success: boolean }): unknown => {
    return {
      format: ((value: "HTML" | "PLAINTEXT"): unknown => {
        return value;
      })(value["format"]),
      method: ((value: "BROWSER" | "EMAIL"): unknown => {
        return value;
      })(value["method"]),
      resultBody: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["resultBody"]),
      success: ((value: boolean): unknown => {
        return value;
      })(value["success"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_EmailTemplateTestResult(str: string): EmailTemplateTestResult | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          format: EmailTemplateTestFormat;
          method: EmailTemplateTestMethod;
          resultBody: string | null;
          success: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "format")) {
      return { success: false };
    }
    const parsed_format = (() => {
      return ((value: unknown): { success: false } | { success: true; value: EmailTemplateTestFormat } => {
        return ((value: unknown): { success: false } | { success: true; value: "HTML" | "PLAINTEXT" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "HTML":
              return { success: true, value: "HTML" };
            case "PLAINTEXT":
              return { success: true, value: "PLAINTEXT" };
            default:
              return { success: false };
          }
        })(value);
      })(value["format"]);
    })();
    if (!parsed_format.success) {
      return { success: false };
    }
    if (!hasProp(value, "method")) {
      return { success: false };
    }
    const parsed_method = (() => {
      return ((value: unknown): { success: false } | { success: true; value: EmailTemplateTestMethod } => {
        return ((value: unknown): { success: false } | { success: true; value: "BROWSER" | "EMAIL" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "BROWSER":
              return { success: true, value: "BROWSER" };
            case "EMAIL":
              return { success: true, value: "EMAIL" };
            default:
              return { success: false };
          }
        })(value);
      })(value["method"]);
    })();
    if (!parsed_method.success) {
      return { success: false };
    }
    if (!hasProp(value, "resultBody")) {
      return { success: false };
    }
    const parsed_resultBody = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["resultBody"]);
    })();
    if (!parsed_resultBody.success) {
      return { success: false };
    }
    if (!hasProp(value, "success")) {
      return { success: false };
    }
    const parsed_success = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["success"]);
    })();
    if (!parsed_success.success) {
      return { success: false };
    }
    return { success: true, value: { format: parsed_format.value, method: parsed_method.value, resultBody: parsed_resultBody.value, success: parsed_success.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ExportReportListView
export function serialize_ExportReportListView(obj: ExportReportListView): string {
  const serialized = ((value: { downloadLink: string; exportDate: { year: number; month: number; day: number }; exportTaskId: number; fileName: string; hash: string; reportName: string | null; reportType: ReportType | null }): unknown => {
    return {
      downloadLink: ((value: string): unknown => {
        return value;
      })(value["downloadLink"]),
      exportDate: ((value: { year: number; month: number; day: number }): unknown => {
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["exportDate"]),
      exportTaskId: ((value: number): unknown => {
        return value;
      })(value["exportTaskId"]),
      fileName: ((value: string): unknown => {
        return value;
      })(value["fileName"]),
      hash: ((value: string): unknown => {
        return value;
      })(value["hash"]),
      reportName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["reportName"]),
      reportType: ((value: ("USERS" | "ACTIVITY" | "EXTENDED_USERS_EXPORT" | "ACTIONABLES_ACTIVITY") | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["reportType"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ExportReportListView(str: string): ExportReportListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          downloadLink: string;
          exportDate: { year: number; month: number; day: number };
          exportTaskId: number;
          fileName: string;
          hash: string;
          reportName: string | null;
          reportType: ReportType | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "downloadLink")) {
      return { success: false };
    }
    const parsed_downloadLink = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["downloadLink"]);
    })();
    if (!parsed_downloadLink.success) {
      return { success: false };
    }
    if (!hasProp(value, "exportDate")) {
      return { success: false };
    }
    const parsed_exportDate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["exportDate"]);
    })();
    if (!parsed_exportDate.success) {
      return { success: false };
    }
    if (!hasProp(value, "exportTaskId")) {
      return { success: false };
    }
    const parsed_exportTaskId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["exportTaskId"]);
    })();
    if (!parsed_exportTaskId.success) {
      return { success: false };
    }
    if (!hasProp(value, "fileName")) {
      return { success: false };
    }
    const parsed_fileName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["fileName"]);
    })();
    if (!parsed_fileName.success) {
      return { success: false };
    }
    if (!hasProp(value, "hash")) {
      return { success: false };
    }
    const parsed_hash = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["hash"]);
    })();
    if (!parsed_hash.success) {
      return { success: false };
    }
    if (!hasProp(value, "reportName")) {
      return { success: false };
    }
    const parsed_reportName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["reportName"]);
    })();
    if (!parsed_reportName.success) {
      return { success: false };
    }
    if (!hasProp(value, "reportType")) {
      return { success: false };
    }
    const parsed_reportType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ReportType | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((value: unknown): { success: false } | { success: true; value: "USERS" | "ACTIVITY" | "EXTENDED_USERS_EXPORT" | "ACTIONABLES_ACTIVITY" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "USERS":
              return { success: true, value: "USERS" };
            case "ACTIVITY":
              return { success: true, value: "ACTIVITY" };
            case "EXTENDED_USERS_EXPORT":
              return { success: true, value: "EXTENDED_USERS_EXPORT" };
            case "ACTIONABLES_ACTIVITY":
              return { success: true, value: "ACTIONABLES_ACTIVITY" };
            default:
              return { success: false };
          }
        })(value);
      })(value["reportType"]);
    })();
    if (!parsed_reportType.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        downloadLink: parsed_downloadLink.value,
        exportDate: parsed_exportDate.value,
        exportTaskId: parsed_exportTaskId.value,
        fileName: parsed_fileName.value,
        hash: parsed_hash.value,
        reportName: parsed_reportName.value,
        reportType: parsed_reportType.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// FeatureType
export function serialize_FeatureType(obj: FeatureType): string {
  const serialized = ((value: "ACTIONABLE" | "GET_ABSTRACT_AI" | "MIAB"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_FeatureType(str: string): FeatureType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" | "GET_ABSTRACT_AI" | "MIAB" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "ACTIONABLE":
        return { success: true, value: "ACTIONABLE" };
      case "GET_ABSTRACT_AI":
        return { success: true, value: "GET_ABSTRACT_AI" };
      case "MIAB":
        return { success: true, value: "MIAB" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// FilteredLearningItemAdminView
export function serialize_FilteredLearningItemAdminView(obj: FilteredLearningItemAdminView): string {
  const serialized = ((value: { items: LearningItemAdminView[]; totalCount: number }): unknown => {
    return {
      items: ((value: LearningItemAdminView[]): unknown => {
        return value.map(
          (
            value:
              | { learningItemType: "ACTIONABLE"; description: string; metadata: LearningItemAdminMetadataView }
              | { learningItemType: "SUMMARY"; audioActive: boolean; documentType: SummaryDocumentType; metadata: LearningItemAdminMetadataView; publisher: string | null; sourceType: SummarySourceType },
          ): unknown => {
            switch (value.learningItemType) {
              case "ACTIONABLE": {
                return {
                  learningItemType: "ACTIONABLE",
                  description: ((value: string): unknown => {
                    return value;
                  })(value["description"]),
                  metadata: ((value: {
                    activatedAt: { year: number; month: number; day: number } | null;
                    active: boolean;
                    createdAt: { year: number; month: number; day: number };
                    deactivatedAt: { year: number; month: number; day: number } | null;
                    id: bigint;
                    language: Language;
                    manager: string | null;
                    tags: Tag[];
                    title: string;
                  }): unknown => {
                    return {
                      activatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
                      })(value["activatedAt"]),
                      active: ((value: boolean): unknown => {
                        return value;
                      })(value["active"]),
                      createdAt: ((value: { year: number; month: number; day: number }): unknown => {
                        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
                      })(value["createdAt"]),
                      deactivatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
                      })(value["deactivatedAt"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      manager: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["manager"]),
                      tags: ((value: Tag[]): unknown => {
                        return value.map(
                          (
                            value:
                              | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                              | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                              | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                              | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                              | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                              | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                              | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                              | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                              | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
                          ): unknown => {
                            switch (value.discriminator) {
                              case "dynamicString": {
                                return {
                                  discriminator: "dynamicString",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                                    return {
                                      discriminator: ((value: "dynamicString"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: string): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: string): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                              case "dynamicBoolean": {
                                return {
                                  discriminator: "dynamicBoolean",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                                    return {
                                      discriminator: ((value: "dynamicBoolean"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: string): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: boolean): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                              case "dynamicNumber": {
                                return {
                                  discriminator: "dynamicNumber",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                                    return {
                                      discriminator: ((value: "dynamicNumber"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: string): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: number): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                              case "dynamicVoid": {
                                return {
                                  discriminator: "dynamicVoid",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                                    return {
                                      discriminator: ((value: "dynamicVoid"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: string): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                };
                              }
                              case "channel": {
                                return {
                                  discriminator: "channel",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                                    return {
                                      discriminator: ((value: "channel"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: "channel"): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: string): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                              case "primary-skill": {
                                return {
                                  discriminator: "primary-skill",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                                    return {
                                      discriminator: ((value: "primary-skill"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: "primary-skill"): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: string): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                              case "skill": {
                                return {
                                  discriminator: "skill",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                                    return {
                                      discriminator: ((value: "skill"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: "skill"): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: string): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                              case "key-quality": {
                                return {
                                  discriminator: "key-quality",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                                    return {
                                      discriminator: ((value: "key-quality"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: "key-quality"): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: string): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                              case "classification": {
                                return {
                                  discriminator: "classification",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                                    return {
                                      discriminator: ((value: "classification"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: "classification"): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: string): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                            }
                          },
                        );
                      })(value["tags"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["metadata"]),
                };
              }
              case "SUMMARY": {
                return {
                  learningItemType: "SUMMARY",
                  audioActive: ((value: boolean): unknown => {
                    return value;
                  })(value["audioActive"]),
                  documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
                    return value;
                  })(value["documentType"]),
                  metadata: ((value: {
                    activatedAt: { year: number; month: number; day: number } | null;
                    active: boolean;
                    createdAt: { year: number; month: number; day: number };
                    deactivatedAt: { year: number; month: number; day: number } | null;
                    id: bigint;
                    language: Language;
                    manager: string | null;
                    tags: Tag[];
                    title: string;
                  }): unknown => {
                    return {
                      activatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
                      })(value["activatedAt"]),
                      active: ((value: boolean): unknown => {
                        return value;
                      })(value["active"]),
                      createdAt: ((value: { year: number; month: number; day: number }): unknown => {
                        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
                      })(value["createdAt"]),
                      deactivatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
                      })(value["deactivatedAt"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      manager: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["manager"]),
                      tags: ((value: Tag[]): unknown => {
                        return value.map(
                          (
                            value:
                              | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                              | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                              | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                              | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                              | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                              | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                              | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                              | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                              | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
                          ): unknown => {
                            switch (value.discriminator) {
                              case "dynamicString": {
                                return {
                                  discriminator: "dynamicString",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                                    return {
                                      discriminator: ((value: "dynamicString"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: string): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: string): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                              case "dynamicBoolean": {
                                return {
                                  discriminator: "dynamicBoolean",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                                    return {
                                      discriminator: ((value: "dynamicBoolean"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: string): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: boolean): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                              case "dynamicNumber": {
                                return {
                                  discriminator: "dynamicNumber",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                                    return {
                                      discriminator: ((value: "dynamicNumber"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: string): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: number): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                              case "dynamicVoid": {
                                return {
                                  discriminator: "dynamicVoid",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                                    return {
                                      discriminator: ((value: "dynamicVoid"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: string): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                };
                              }
                              case "channel": {
                                return {
                                  discriminator: "channel",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                                    return {
                                      discriminator: ((value: "channel"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: "channel"): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: string): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                              case "primary-skill": {
                                return {
                                  discriminator: "primary-skill",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                                    return {
                                      discriminator: ((value: "primary-skill"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: "primary-skill"): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: string): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                              case "skill": {
                                return {
                                  discriminator: "skill",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                                    return {
                                      discriminator: ((value: "skill"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: "skill"): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: string): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                              case "key-quality": {
                                return {
                                  discriminator: "key-quality",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                                    return {
                                      discriminator: ((value: "key-quality"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: "key-quality"): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: string): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                              case "classification": {
                                return {
                                  discriminator: "classification",
                                  exclude: ((value: boolean): unknown => {
                                    return value;
                                  })(value["exclude"]),
                                  source: ((value: "USER" | "AI"): unknown => {
                                    return value;
                                  })(value["source"]),
                                  tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                                    return {
                                      discriminator: ((value: "classification"): unknown => {
                                        return value;
                                      })(value["discriminator"]),
                                      id: ((value: bigint): unknown => {
                                        return value;
                                      })(value["id"]),
                                      kind: ((value: "classification"): unknown => {
                                        return value;
                                      })(value["kind"]),
                                    };
                                  })(value["tagKind"]),
                                  value: ((value: string): unknown => {
                                    return value;
                                  })(value["value"]),
                                };
                              }
                            }
                          },
                        );
                      })(value["tags"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["metadata"]),
                  publisher: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["publisher"]),
                  sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
                    return value;
                  })(value["sourceType"]),
                };
              }
            }
          },
        );
      })(value["items"]),
      totalCount: ((value: number): unknown => {
        return value;
      })(value["totalCount"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_FilteredLearningItemAdminView(str: string): FilteredLearningItemAdminView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: LearningItemAdminView[];
          totalCount: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: LearningItemAdminView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: LearningItemAdminView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { learningItemType: "ACTIONABLE"; description: string; metadata: LearningItemAdminMetadataView }
                  | { learningItemType: "SUMMARY"; audioActive: boolean; documentType: SummaryDocumentType; metadata: LearningItemAdminMetadataView; publisher: string | null; sourceType: SummarySourceType };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { learningItemType: "ACTIONABLE"; description: string; metadata: LearningItemAdminMetadataView }
                    | { learningItemType: "SUMMARY"; audioActive: boolean; documentType: SummaryDocumentType; metadata: LearningItemAdminMetadataView; publisher: string | null; sourceType: SummarySourceType };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "learningItemType")) {
                return { success: false };
              }
              if (typeof value["learningItemType"] != "string") {
                return { success: false };
              }
              const discriminator = value["learningItemType"];
              switch (discriminator) {
                case "ACTIONABLE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          description: string;
                          metadata: LearningItemAdminMetadataView;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "description")) {
                      return { success: false };
                    }
                    const parsed_description = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["description"]);
                    })();
                    if (!parsed_description.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "metadata")) {
                      return { success: false };
                    }
                    const parsed_metadata = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: LearningItemAdminMetadataView } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                activatedAt: { year: number; month: number; day: number } | null;
                                active: boolean;
                                createdAt: { year: number; month: number; day: number };
                                deactivatedAt: { year: number; month: number; day: number } | null;
                                id: bigint;
                                language: Language;
                                manager: string | null;
                                tags: Tag[];
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "activatedAt")) {
                            return { success: false };
                          }
                          const parsed_activatedAt = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                              if (!parsed.isValid) {
                                return { success: false };
                              }
                              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                            })(value["activatedAt"]);
                          })();
                          if (!parsed_activatedAt.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "active")) {
                            return { success: false };
                          }
                          const parsed_active = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["active"]);
                          })();
                          if (!parsed_active.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "createdAt")) {
                            return { success: false };
                          }
                          const parsed_createdAt = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                              if (!parsed.isValid) {
                                return { success: false };
                              }
                              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                            })(value["createdAt"]);
                          })();
                          if (!parsed_createdAt.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "deactivatedAt")) {
                            return { success: false };
                          }
                          const parsed_deactivatedAt = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                              if (!parsed.isValid) {
                                return { success: false };
                              }
                              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                            })(value["deactivatedAt"]);
                          })();
                          if (!parsed_deactivatedAt.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "manager")) {
                            return { success: false };
                          }
                          const parsed_manager = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["manager"]);
                          })();
                          if (!parsed_manager.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tags")) {
                            return { success: false };
                          }
                          const parsed_tags = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
                              if (!isArray(value)) {
                                return { success: false };
                              }
                              const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value:
                                        | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                                        | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                                        | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                                        | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                                        | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                                        | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                                        | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                                        | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                                        | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                                    } => {
                                  return ((
                                    value: unknown,
                                  ):
                                    | { success: false }
                                    | {
                                        success: true;
                                        value:
                                          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                                          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                                          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                                          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                                          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                                          | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                                          | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                                          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                                          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                                      } => {
                                    if (value == null) {
                                      return { success: false };
                                    }
                                    if (typeof value != "object") {
                                      return { success: false };
                                    }
                                    if (!hasProp(value, "discriminator")) {
                                      return { success: false };
                                    }
                                    if (typeof value["discriminator"] != "string") {
                                      return { success: false };
                                    }
                                    const discriminator = value["discriminator"];
                                    switch (discriminator) {
                                      case "dynamicString": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindDynamicString;
                                                value: string;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindDynamicString;
                                                      id: bigint;
                                                      kind: string;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "dynamicString":
                                                          return { success: true, value: "dynamicString" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    return { success: true, value: value };
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                                      }
                                      case "dynamicBoolean": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindDynamicBoolean;
                                                value: boolean;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindDynamicBoolean;
                                                      id: bigint;
                                                      kind: string;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "dynamicBoolean":
                                                          return { success: true, value: "dynamicBoolean" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    return { success: true, value: value };
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                                      }
                                      case "dynamicNumber": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindDynamicNumber;
                                                value: number;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindDynamicNumber;
                                                      id: bigint;
                                                      kind: string;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "dynamicNumber":
                                                          return { success: true, value: "dynamicNumber" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    return { success: true, value: value };
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: number } => {
                                              if (typeof value === "number") {
                                                return { success: true, value: value };
                                              }
                                              return { success: false };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                                      }
                                      case "dynamicVoid": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindDynamicVoid;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindDynamicVoid;
                                                      id: bigint;
                                                      kind: string;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "dynamicVoid":
                                                          return { success: true, value: "dynamicVoid" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    return { success: true, value: value };
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                                      }
                                      case "channel": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindChannel;
                                                value: string;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindChannel;
                                                      id: bigint;
                                                      kind: KindChannel;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "channel":
                                                          return { success: true, value: "channel" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "channel":
                                                          return { success: true, value: "channel" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                                      }
                                      case "primary-skill": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindPrimarySkill;
                                                value: string;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindPrimarySkill;
                                                      id: bigint;
                                                      kind: KindPrimarySkill;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "primary-skill":
                                                          return { success: true, value: "primary-skill" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "primary-skill":
                                                          return { success: true, value: "primary-skill" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                                      }
                                      case "skill": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindSkill;
                                                value: string;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindSkill;
                                                      id: bigint;
                                                      kind: KindSkill;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "skill":
                                                          return { success: true, value: "skill" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "skill":
                                                          return { success: true, value: "skill" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                                      }
                                      case "key-quality": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindKeyQuality;
                                                value: string;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindKeyQuality;
                                                      id: bigint;
                                                      kind: KindKeyQuality;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "key-quality":
                                                          return { success: true, value: "key-quality" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "key-quality":
                                                          return { success: true, value: "key-quality" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                                      }
                                      case "classification": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindClassification;
                                                value: string;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindClassification;
                                                      id: bigint;
                                                      kind: KindClassification;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "classification":
                                                          return { success: true, value: "classification" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "classification":
                                                          return { success: true, value: "classification" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                                      }
                                      default:
                                        return { success: false };
                                    }
                                  })(value);
                                })(value);
                              });
                              if (parsed.find((value) => !value.success) !== undefined) {
                                return { success: false };
                              }
                              return {
                                success: true,
                                value: parsed.map((entry) => {
                                  assert(entry.success == true);
                                  return entry.value;
                                }),
                              };
                            })(value["tags"]);
                          })();
                          if (!parsed_tags.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              activatedAt: parsed_activatedAt.value,
                              active: parsed_active.value,
                              createdAt: parsed_createdAt.value,
                              deactivatedAt: parsed_deactivatedAt.value,
                              id: parsed_id.value,
                              language: parsed_language.value,
                              manager: parsed_manager.value,
                              tags: parsed_tags.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["metadata"]);
                    })();
                    if (!parsed_metadata.success) {
                      return { success: false };
                    }
                    return { success: true, value: { description: parsed_description.value, metadata: parsed_metadata.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { learningItemType: "ACTIONABLE" as const, ...parsedVariant.value } };
                }
                case "SUMMARY": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          audioActive: boolean;
                          documentType: SummaryDocumentType;
                          metadata: LearningItemAdminMetadataView;
                          publisher: string | null;
                          sourceType: SummarySourceType;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "audioActive")) {
                      return { success: false };
                    }
                    const parsed_audioActive = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["audioActive"]);
                    })();
                    if (!parsed_audioActive.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "documentType")) {
                      return { success: false };
                    }
                    const parsed_documentType = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "ABSTRACT":
                              return { success: true, value: "ABSTRACT" };
                            case "REVIEW":
                              return { success: true, value: "REVIEW" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["documentType"]);
                    })();
                    if (!parsed_documentType.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "metadata")) {
                      return { success: false };
                    }
                    const parsed_metadata = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: LearningItemAdminMetadataView } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                activatedAt: { year: number; month: number; day: number } | null;
                                active: boolean;
                                createdAt: { year: number; month: number; day: number };
                                deactivatedAt: { year: number; month: number; day: number } | null;
                                id: bigint;
                                language: Language;
                                manager: string | null;
                                tags: Tag[];
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "activatedAt")) {
                            return { success: false };
                          }
                          const parsed_activatedAt = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                              if (!parsed.isValid) {
                                return { success: false };
                              }
                              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                            })(value["activatedAt"]);
                          })();
                          if (!parsed_activatedAt.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "active")) {
                            return { success: false };
                          }
                          const parsed_active = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["active"]);
                          })();
                          if (!parsed_active.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "createdAt")) {
                            return { success: false };
                          }
                          const parsed_createdAt = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                              if (!parsed.isValid) {
                                return { success: false };
                              }
                              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                            })(value["createdAt"]);
                          })();
                          if (!parsed_createdAt.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "deactivatedAt")) {
                            return { success: false };
                          }
                          const parsed_deactivatedAt = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                              if (!parsed.isValid) {
                                return { success: false };
                              }
                              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                            })(value["deactivatedAt"]);
                          })();
                          if (!parsed_deactivatedAt.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "manager")) {
                            return { success: false };
                          }
                          const parsed_manager = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["manager"]);
                          })();
                          if (!parsed_manager.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tags")) {
                            return { success: false };
                          }
                          const parsed_tags = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
                              if (!isArray(value)) {
                                return { success: false };
                              }
                              const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value:
                                        | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                                        | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                                        | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                                        | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                                        | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                                        | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                                        | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                                        | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                                        | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                                    } => {
                                  return ((
                                    value: unknown,
                                  ):
                                    | { success: false }
                                    | {
                                        success: true;
                                        value:
                                          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                                          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                                          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                                          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                                          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                                          | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                                          | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                                          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                                          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                                      } => {
                                    if (value == null) {
                                      return { success: false };
                                    }
                                    if (typeof value != "object") {
                                      return { success: false };
                                    }
                                    if (!hasProp(value, "discriminator")) {
                                      return { success: false };
                                    }
                                    if (typeof value["discriminator"] != "string") {
                                      return { success: false };
                                    }
                                    const discriminator = value["discriminator"];
                                    switch (discriminator) {
                                      case "dynamicString": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindDynamicString;
                                                value: string;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindDynamicString;
                                                      id: bigint;
                                                      kind: string;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "dynamicString":
                                                          return { success: true, value: "dynamicString" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    return { success: true, value: value };
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                                      }
                                      case "dynamicBoolean": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindDynamicBoolean;
                                                value: boolean;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindDynamicBoolean;
                                                      id: bigint;
                                                      kind: string;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "dynamicBoolean":
                                                          return { success: true, value: "dynamicBoolean" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    return { success: true, value: value };
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                                      }
                                      case "dynamicNumber": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindDynamicNumber;
                                                value: number;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindDynamicNumber;
                                                      id: bigint;
                                                      kind: string;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "dynamicNumber":
                                                          return { success: true, value: "dynamicNumber" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    return { success: true, value: value };
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: number } => {
                                              if (typeof value === "number") {
                                                return { success: true, value: value };
                                              }
                                              return { success: false };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                                      }
                                      case "dynamicVoid": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindDynamicVoid;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindDynamicVoid;
                                                      id: bigint;
                                                      kind: string;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "dynamicVoid":
                                                          return { success: true, value: "dynamicVoid" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    return { success: true, value: value };
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                                      }
                                      case "channel": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindChannel;
                                                value: string;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindChannel;
                                                      id: bigint;
                                                      kind: KindChannel;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "channel":
                                                          return { success: true, value: "channel" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "channel":
                                                          return { success: true, value: "channel" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                                      }
                                      case "primary-skill": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindPrimarySkill;
                                                value: string;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindPrimarySkill;
                                                      id: bigint;
                                                      kind: KindPrimarySkill;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "primary-skill":
                                                          return { success: true, value: "primary-skill" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "primary-skill":
                                                          return { success: true, value: "primary-skill" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                                      }
                                      case "skill": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindSkill;
                                                value: string;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindSkill;
                                                      id: bigint;
                                                      kind: KindSkill;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "skill":
                                                          return { success: true, value: "skill" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "skill":
                                                          return { success: true, value: "skill" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                                      }
                                      case "key-quality": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindKeyQuality;
                                                value: string;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindKeyQuality;
                                                      id: bigint;
                                                      kind: KindKeyQuality;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "key-quality":
                                                          return { success: true, value: "key-quality" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "key-quality":
                                                          return { success: true, value: "key-quality" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                                      }
                                      case "classification": {
                                        const parsedVariant = ((
                                          value: unknown,
                                        ):
                                          | { success: false }
                                          | {
                                              success: true;
                                              value: {
                                                exclude: boolean;
                                                source: TagSourceType;
                                                tagKind: TagKindClassification;
                                                value: string;
                                              };
                                            } => {
                                          if (typeof value !== "object") {
                                            return { success: false };
                                          }
                                          if (value === null) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "exclude")) {
                                            return { success: false };
                                          }
                                          const parsed_exclude = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                              if (typeof value !== "boolean") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["exclude"]);
                                          })();
                                          if (!parsed_exclude.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "source")) {
                                            return { success: false };
                                          }
                                          const parsed_source = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                                if (typeof value !== "string") {
                                                  return { success: false };
                                                }
                                                switch (value) {
                                                  case "USER":
                                                    return { success: true, value: "USER" };
                                                  case "AI":
                                                    return { success: true, value: "AI" };
                                                  default:
                                                    return { success: false };
                                                }
                                              })(value);
                                            })(value["source"]);
                                          })();
                                          if (!parsed_source.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "tagKind")) {
                                            return { success: false };
                                          }
                                          const parsed_tagKind = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                                              return ((
                                                value: unknown,
                                              ):
                                                | { success: false }
                                                | {
                                                    success: true;
                                                    value: {
                                                      discriminator: KindClassification;
                                                      id: bigint;
                                                      kind: KindClassification;
                                                    };
                                                  } => {
                                                if (typeof value !== "object") {
                                                  return { success: false };
                                                }
                                                if (value === null) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "discriminator")) {
                                                  return { success: false };
                                                }
                                                const parsed_discriminator = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "classification":
                                                          return { success: true, value: "classification" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["discriminator"]);
                                                })();
                                                if (!parsed_discriminator.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "id")) {
                                                  return { success: false };
                                                }
                                                const parsed_id = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                    if (typeof value === "number") {
                                                      return { success: true, value: BigInt(value) };
                                                    }
                                                    if (typeof value === "bigint") {
                                                      return { success: true, value: value };
                                                    }
                                                    return { success: false };
                                                  })(value["id"]);
                                                })();
                                                if (!parsed_id.success) {
                                                  return { success: false };
                                                }
                                                if (!hasProp(value, "kind")) {
                                                  return { success: false };
                                                }
                                                const parsed_kind = (() => {
                                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                                      if (typeof value !== "string") {
                                                        return { success: false };
                                                      }
                                                      switch (value) {
                                                        case "classification":
                                                          return { success: true, value: "classification" };
                                                        default:
                                                          return { success: false };
                                                      }
                                                    })(value);
                                                  })(value["kind"]);
                                                })();
                                                if (!parsed_kind.success) {
                                                  return { success: false };
                                                }
                                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                              })(value);
                                            })(value["tagKind"]);
                                          })();
                                          if (!parsed_tagKind.success) {
                                            return { success: false };
                                          }
                                          if (!hasProp(value, "value")) {
                                            return { success: false };
                                          }
                                          const parsed_value = (() => {
                                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              return { success: true, value: value };
                                            })(value["value"]);
                                          })();
                                          if (!parsed_value.success) {
                                            return { success: false };
                                          }
                                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                        })(value);
                                        if (!parsedVariant.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                                      }
                                      default:
                                        return { success: false };
                                    }
                                  })(value);
                                })(value);
                              });
                              if (parsed.find((value) => !value.success) !== undefined) {
                                return { success: false };
                              }
                              return {
                                success: true,
                                value: parsed.map((entry) => {
                                  assert(entry.success == true);
                                  return entry.value;
                                }),
                              };
                            })(value["tags"]);
                          })();
                          if (!parsed_tags.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              activatedAt: parsed_activatedAt.value,
                              active: parsed_active.value,
                              createdAt: parsed_createdAt.value,
                              deactivatedAt: parsed_deactivatedAt.value,
                              id: parsed_id.value,
                              language: parsed_language.value,
                              manager: parsed_manager.value,
                              tags: parsed_tags.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["metadata"]);
                    })();
                    if (!parsed_metadata.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "publisher")) {
                      return { success: false };
                    }
                    const parsed_publisher = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["publisher"]);
                    })();
                    if (!parsed_publisher.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "sourceType")) {
                      return { success: false };
                    }
                    const parsed_sourceType = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "BOOK":
                              return { success: true, value: "BOOK" };
                            case "VIDEO":
                              return { success: true, value: "VIDEO" };
                            case "ARTICLE":
                              return { success: true, value: "ARTICLE" };
                            case "CHAPTER":
                              return { success: true, value: "CHAPTER" };
                            case "REPORT":
                              return { success: true, value: "REPORT" };
                            case "PODCAST":
                              return { success: true, value: "PODCAST" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["sourceType"]);
                    })();
                    if (!parsed_sourceType.success) {
                      return { success: false };
                    }
                    return {
                      success: true,
                      value: { audioActive: parsed_audioActive.value, documentType: parsed_documentType.value, metadata: parsed_metadata.value, publisher: parsed_publisher.value, sourceType: parsed_sourceType.value },
                    };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { learningItemType: "SUMMARY" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, totalCount: parsed_totalCount.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// FormFilter
export function serialize_FormFilter(obj: FormFilter): string {
  const serialized = ((value: { activeValues: string[]; identifier: FormFilterName; label: string; name: string; options: FormFilterOption[]; priority: FormFilterPriority; type: FormFilterType }): unknown => {
    return {
      activeValues: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["activeValues"]),
      identifier: ((value: "CHANNEL" | "SOURCE" | "QUALITY" | "RATING" | "AUDIO" | "LANGUAGE" | "PUBLICATION_DATE" | "MIAB"): unknown => {
        return value;
      })(value["identifier"]),
      label: ((value: string): unknown => {
        return value;
      })(value["label"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      options: ((value: FormFilterOption[]): unknown => {
        return value.map((value: { label: string; shortLabel: string | null; value: string }): unknown => {
          return {
            label: ((value: string): unknown => {
              return value;
            })(value["label"]),
            shortLabel: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["shortLabel"]),
            value: ((value: string): unknown => {
              return value;
            })(value["value"]),
          };
        });
      })(value["options"]),
      priority: ((value: "PRIMARY" | "SECONDARY"): unknown => {
        return value;
      })(value["priority"]),
      type: ((value: "MULTICHECKBOX" | "CHECKBOX" | "MULTISELECT" | "RANGE" | "RANGE_MIN"): unknown => {
        return value;
      })(value["type"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_FormFilter(str: string): FormFilter | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          activeValues: string[];
          identifier: FormFilterName;
          label: string;
          name: string;
          options: FormFilterOption[];
          priority: FormFilterPriority;
          type: FormFilterType;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "activeValues")) {
      return { success: false };
    }
    const parsed_activeValues = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["activeValues"]);
    })();
    if (!parsed_activeValues.success) {
      return { success: false };
    }
    if (!hasProp(value, "identifier")) {
      return { success: false };
    }
    const parsed_identifier = (() => {
      return ((value: unknown): { success: false } | { success: true; value: FormFilterName } => {
        return ((value: unknown): { success: false } | { success: true; value: "CHANNEL" | "SOURCE" | "QUALITY" | "RATING" | "AUDIO" | "LANGUAGE" | "PUBLICATION_DATE" | "MIAB" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "CHANNEL":
              return { success: true, value: "CHANNEL" };
            case "SOURCE":
              return { success: true, value: "SOURCE" };
            case "QUALITY":
              return { success: true, value: "QUALITY" };
            case "RATING":
              return { success: true, value: "RATING" };
            case "AUDIO":
              return { success: true, value: "AUDIO" };
            case "LANGUAGE":
              return { success: true, value: "LANGUAGE" };
            case "PUBLICATION_DATE":
              return { success: true, value: "PUBLICATION_DATE" };
            case "MIAB":
              return { success: true, value: "MIAB" };
            default:
              return { success: false };
          }
        })(value);
      })(value["identifier"]);
    })();
    if (!parsed_identifier.success) {
      return { success: false };
    }
    if (!hasProp(value, "label")) {
      return { success: false };
    }
    const parsed_label = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["label"]);
    })();
    if (!parsed_label.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "options")) {
      return { success: false };
    }
    const parsed_options = (() => {
      return ((value: unknown): { success: false } | { success: true; value: FormFilterOption[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: FormFilterOption } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  label: string;
                  shortLabel: string | null;
                  value: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "label")) {
              return { success: false };
            }
            const parsed_label = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["label"]);
            })();
            if (!parsed_label.success) {
              return { success: false };
            }
            if (!hasProp(value, "shortLabel")) {
              return { success: false };
            }
            const parsed_shortLabel = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["shortLabel"]);
            })();
            if (!parsed_shortLabel.success) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { label: parsed_label.value, shortLabel: parsed_shortLabel.value, value: parsed_value.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["options"]);
    })();
    if (!parsed_options.success) {
      return { success: false };
    }
    if (!hasProp(value, "priority")) {
      return { success: false };
    }
    const parsed_priority = (() => {
      return ((value: unknown): { success: false } | { success: true; value: FormFilterPriority } => {
        return ((value: unknown): { success: false } | { success: true; value: "PRIMARY" | "SECONDARY" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "PRIMARY":
              return { success: true, value: "PRIMARY" };
            case "SECONDARY":
              return { success: true, value: "SECONDARY" };
            default:
              return { success: false };
          }
        })(value);
      })(value["priority"]);
    })();
    if (!parsed_priority.success) {
      return { success: false };
    }
    if (!hasProp(value, "type")) {
      return { success: false };
    }
    const parsed_type = (() => {
      return ((value: unknown): { success: false } | { success: true; value: FormFilterType } => {
        return ((value: unknown): { success: false } | { success: true; value: "MULTICHECKBOX" | "CHECKBOX" | "MULTISELECT" | "RANGE" | "RANGE_MIN" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "MULTICHECKBOX":
              return { success: true, value: "MULTICHECKBOX" };
            case "CHECKBOX":
              return { success: true, value: "CHECKBOX" };
            case "MULTISELECT":
              return { success: true, value: "MULTISELECT" };
            case "RANGE":
              return { success: true, value: "RANGE" };
            case "RANGE_MIN":
              return { success: true, value: "RANGE_MIN" };
            default:
              return { success: false };
          }
        })(value);
      })(value["type"]);
    })();
    if (!parsed_type.success) {
      return { success: false };
    }
    return {
      success: true,
      value: { activeValues: parsed_activeValues.value, identifier: parsed_identifier.value, label: parsed_label.value, name: parsed_name.value, options: parsed_options.value, priority: parsed_priority.value, type: parsed_type.value },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// FormFilterName
export function serialize_FormFilterName(obj: FormFilterName): string {
  const serialized = ((value: "CHANNEL" | "SOURCE" | "QUALITY" | "RATING" | "AUDIO" | "LANGUAGE" | "PUBLICATION_DATE" | "MIAB"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_FormFilterName(str: string): FormFilterName | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "CHANNEL" | "SOURCE" | "QUALITY" | "RATING" | "AUDIO" | "LANGUAGE" | "PUBLICATION_DATE" | "MIAB" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "CHANNEL":
        return { success: true, value: "CHANNEL" };
      case "SOURCE":
        return { success: true, value: "SOURCE" };
      case "QUALITY":
        return { success: true, value: "QUALITY" };
      case "RATING":
        return { success: true, value: "RATING" };
      case "AUDIO":
        return { success: true, value: "AUDIO" };
      case "LANGUAGE":
        return { success: true, value: "LANGUAGE" };
      case "PUBLICATION_DATE":
        return { success: true, value: "PUBLICATION_DATE" };
      case "MIAB":
        return { success: true, value: "MIAB" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// FormFilterOption
export function serialize_FormFilterOption(obj: FormFilterOption): string {
  const serialized = ((value: { label: string; shortLabel: string | null; value: string }): unknown => {
    return {
      label: ((value: string): unknown => {
        return value;
      })(value["label"]),
      shortLabel: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["shortLabel"]),
      value: ((value: string): unknown => {
        return value;
      })(value["value"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_FormFilterOption(str: string): FormFilterOption | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          label: string;
          shortLabel: string | null;
          value: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "label")) {
      return { success: false };
    }
    const parsed_label = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["label"]);
    })();
    if (!parsed_label.success) {
      return { success: false };
    }
    if (!hasProp(value, "shortLabel")) {
      return { success: false };
    }
    const parsed_shortLabel = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["shortLabel"]);
    })();
    if (!parsed_shortLabel.success) {
      return { success: false };
    }
    if (!hasProp(value, "value")) {
      return { success: false };
    }
    const parsed_value = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["value"]);
    })();
    if (!parsed_value.success) {
      return { success: false };
    }
    return { success: true, value: { label: parsed_label.value, shortLabel: parsed_shortLabel.value, value: parsed_value.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// FormFilterPriority
export function serialize_FormFilterPriority(obj: FormFilterPriority): string {
  const serialized = ((value: "PRIMARY" | "SECONDARY"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_FormFilterPriority(str: string): FormFilterPriority | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "PRIMARY" | "SECONDARY" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "PRIMARY":
        return { success: true, value: "PRIMARY" };
      case "SECONDARY":
        return { success: true, value: "SECONDARY" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// FormFilterType
export function serialize_FormFilterType(obj: FormFilterType): string {
  const serialized = ((value: "MULTICHECKBOX" | "CHECKBOX" | "MULTISELECT" | "RANGE" | "RANGE_MIN"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_FormFilterType(str: string): FormFilterType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "MULTICHECKBOX" | "CHECKBOX" | "MULTISELECT" | "RANGE" | "RANGE_MIN" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "MULTICHECKBOX":
        return { success: true, value: "MULTICHECKBOX" };
      case "CHECKBOX":
        return { success: true, value: "CHECKBOX" };
      case "MULTISELECT":
        return { success: true, value: "MULTISELECT" };
      case "RANGE":
        return { success: true, value: "RANGE" };
      case "RANGE_MIN":
        return { success: true, value: "RANGE_MIN" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// FrontendProject
export function serialize_FrontendProject(obj: FrontendProject): string {
  const serialized = ((value: "VUE" | "DESIGN"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_FrontendProject(str: string): FrontendProject | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "VUE" | "DESIGN" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "VUE":
        return { success: true, value: "VUE" };
      case "DESIGN":
        return { success: true, value: "DESIGN" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// FullSearchProps
export function serialize_FullSearchProps(obj: FullSearchProps): string {
  const serialized = ((value: { beta: boolean; hasCorporatePlus: boolean; initialActionableFormFilters: FormFilter[]; initialSummaryFormFilters: FormFilter[]; query: string | null }): unknown => {
    return {
      beta: ((value: boolean): unknown => {
        return value;
      })(value["beta"]),
      hasCorporatePlus: ((value: boolean): unknown => {
        return value;
      })(value["hasCorporatePlus"]),
      initialActionableFormFilters: ((value: FormFilter[]): unknown => {
        return value.map((value: { activeValues: string[]; identifier: FormFilterName; label: string; name: string; options: FormFilterOption[]; priority: FormFilterPriority; type: FormFilterType }): unknown => {
          return {
            activeValues: ((value: string[]): unknown => {
              return value.map((value: string): unknown => {
                return value;
              });
            })(value["activeValues"]),
            identifier: ((value: "CHANNEL" | "SOURCE" | "QUALITY" | "RATING" | "AUDIO" | "LANGUAGE" | "PUBLICATION_DATE" | "MIAB"): unknown => {
              return value;
            })(value["identifier"]),
            label: ((value: string): unknown => {
              return value;
            })(value["label"]),
            name: ((value: string): unknown => {
              return value;
            })(value["name"]),
            options: ((value: FormFilterOption[]): unknown => {
              return value.map((value: { label: string; shortLabel: string | null; value: string }): unknown => {
                return {
                  label: ((value: string): unknown => {
                    return value;
                  })(value["label"]),
                  shortLabel: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["shortLabel"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              });
            })(value["options"]),
            priority: ((value: "PRIMARY" | "SECONDARY"): unknown => {
              return value;
            })(value["priority"]),
            type: ((value: "MULTICHECKBOX" | "CHECKBOX" | "MULTISELECT" | "RANGE" | "RANGE_MIN"): unknown => {
              return value;
            })(value["type"]),
          };
        });
      })(value["initialActionableFormFilters"]),
      initialSummaryFormFilters: ((value: FormFilter[]): unknown => {
        return value.map((value: { activeValues: string[]; identifier: FormFilterName; label: string; name: string; options: FormFilterOption[]; priority: FormFilterPriority; type: FormFilterType }): unknown => {
          return {
            activeValues: ((value: string[]): unknown => {
              return value.map((value: string): unknown => {
                return value;
              });
            })(value["activeValues"]),
            identifier: ((value: "CHANNEL" | "SOURCE" | "QUALITY" | "RATING" | "AUDIO" | "LANGUAGE" | "PUBLICATION_DATE" | "MIAB"): unknown => {
              return value;
            })(value["identifier"]),
            label: ((value: string): unknown => {
              return value;
            })(value["label"]),
            name: ((value: string): unknown => {
              return value;
            })(value["name"]),
            options: ((value: FormFilterOption[]): unknown => {
              return value.map((value: { label: string; shortLabel: string | null; value: string }): unknown => {
                return {
                  label: ((value: string): unknown => {
                    return value;
                  })(value["label"]),
                  shortLabel: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["shortLabel"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              });
            })(value["options"]),
            priority: ((value: "PRIMARY" | "SECONDARY"): unknown => {
              return value;
            })(value["priority"]),
            type: ((value: "MULTICHECKBOX" | "CHECKBOX" | "MULTISELECT" | "RANGE" | "RANGE_MIN"): unknown => {
              return value;
            })(value["type"]),
          };
        });
      })(value["initialSummaryFormFilters"]),
      query: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["query"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_FullSearchProps(str: string): FullSearchProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          beta: boolean;
          hasCorporatePlus: boolean;
          initialActionableFormFilters: FormFilter[];
          initialSummaryFormFilters: FormFilter[];
          query: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "beta")) {
      return { success: false };
    }
    const parsed_beta = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["beta"]);
    })();
    if (!parsed_beta.success) {
      return { success: false };
    }
    if (!hasProp(value, "hasCorporatePlus")) {
      return { success: false };
    }
    const parsed_hasCorporatePlus = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["hasCorporatePlus"]);
    })();
    if (!parsed_hasCorporatePlus.success) {
      return { success: false };
    }
    if (!hasProp(value, "initialActionableFormFilters")) {
      return { success: false };
    }
    const parsed_initialActionableFormFilters = (() => {
      return ((value: unknown): { success: false } | { success: true; value: FormFilter[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: FormFilter } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  activeValues: string[];
                  identifier: FormFilterName;
                  label: string;
                  name: string;
                  options: FormFilterOption[];
                  priority: FormFilterPriority;
                  type: FormFilterType;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "activeValues")) {
              return { success: false };
            }
            const parsed_activeValues = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["activeValues"]);
            })();
            if (!parsed_activeValues.success) {
              return { success: false };
            }
            if (!hasProp(value, "identifier")) {
              return { success: false };
            }
            const parsed_identifier = (() => {
              return ((value: unknown): { success: false } | { success: true; value: FormFilterName } => {
                return ((value: unknown): { success: false } | { success: true; value: "CHANNEL" | "SOURCE" | "QUALITY" | "RATING" | "AUDIO" | "LANGUAGE" | "PUBLICATION_DATE" | "MIAB" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "CHANNEL":
                      return { success: true, value: "CHANNEL" };
                    case "SOURCE":
                      return { success: true, value: "SOURCE" };
                    case "QUALITY":
                      return { success: true, value: "QUALITY" };
                    case "RATING":
                      return { success: true, value: "RATING" };
                    case "AUDIO":
                      return { success: true, value: "AUDIO" };
                    case "LANGUAGE":
                      return { success: true, value: "LANGUAGE" };
                    case "PUBLICATION_DATE":
                      return { success: true, value: "PUBLICATION_DATE" };
                    case "MIAB":
                      return { success: true, value: "MIAB" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["identifier"]);
            })();
            if (!parsed_identifier.success) {
              return { success: false };
            }
            if (!hasProp(value, "label")) {
              return { success: false };
            }
            const parsed_label = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["label"]);
            })();
            if (!parsed_label.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "options")) {
              return { success: false };
            }
            const parsed_options = (() => {
              return ((value: unknown): { success: false } | { success: true; value: FormFilterOption[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: FormFilterOption } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          label: string;
                          shortLabel: string | null;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "label")) {
                      return { success: false };
                    }
                    const parsed_label = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["label"]);
                    })();
                    if (!parsed_label.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "shortLabel")) {
                      return { success: false };
                    }
                    const parsed_shortLabel = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["shortLabel"]);
                    })();
                    if (!parsed_shortLabel.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { label: parsed_label.value, shortLabel: parsed_shortLabel.value, value: parsed_value.value } };
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["options"]);
            })();
            if (!parsed_options.success) {
              return { success: false };
            }
            if (!hasProp(value, "priority")) {
              return { success: false };
            }
            const parsed_priority = (() => {
              return ((value: unknown): { success: false } | { success: true; value: FormFilterPriority } => {
                return ((value: unknown): { success: false } | { success: true; value: "PRIMARY" | "SECONDARY" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "PRIMARY":
                      return { success: true, value: "PRIMARY" };
                    case "SECONDARY":
                      return { success: true, value: "SECONDARY" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["priority"]);
            })();
            if (!parsed_priority.success) {
              return { success: false };
            }
            if (!hasProp(value, "type")) {
              return { success: false };
            }
            const parsed_type = (() => {
              return ((value: unknown): { success: false } | { success: true; value: FormFilterType } => {
                return ((value: unknown): { success: false } | { success: true; value: "MULTICHECKBOX" | "CHECKBOX" | "MULTISELECT" | "RANGE" | "RANGE_MIN" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "MULTICHECKBOX":
                      return { success: true, value: "MULTICHECKBOX" };
                    case "CHECKBOX":
                      return { success: true, value: "CHECKBOX" };
                    case "MULTISELECT":
                      return { success: true, value: "MULTISELECT" };
                    case "RANGE":
                      return { success: true, value: "RANGE" };
                    case "RANGE_MIN":
                      return { success: true, value: "RANGE_MIN" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["type"]);
            })();
            if (!parsed_type.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                activeValues: parsed_activeValues.value,
                identifier: parsed_identifier.value,
                label: parsed_label.value,
                name: parsed_name.value,
                options: parsed_options.value,
                priority: parsed_priority.value,
                type: parsed_type.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["initialActionableFormFilters"]);
    })();
    if (!parsed_initialActionableFormFilters.success) {
      return { success: false };
    }
    if (!hasProp(value, "initialSummaryFormFilters")) {
      return { success: false };
    }
    const parsed_initialSummaryFormFilters = (() => {
      return ((value: unknown): { success: false } | { success: true; value: FormFilter[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: FormFilter } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  activeValues: string[];
                  identifier: FormFilterName;
                  label: string;
                  name: string;
                  options: FormFilterOption[];
                  priority: FormFilterPriority;
                  type: FormFilterType;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "activeValues")) {
              return { success: false };
            }
            const parsed_activeValues = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["activeValues"]);
            })();
            if (!parsed_activeValues.success) {
              return { success: false };
            }
            if (!hasProp(value, "identifier")) {
              return { success: false };
            }
            const parsed_identifier = (() => {
              return ((value: unknown): { success: false } | { success: true; value: FormFilterName } => {
                return ((value: unknown): { success: false } | { success: true; value: "CHANNEL" | "SOURCE" | "QUALITY" | "RATING" | "AUDIO" | "LANGUAGE" | "PUBLICATION_DATE" | "MIAB" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "CHANNEL":
                      return { success: true, value: "CHANNEL" };
                    case "SOURCE":
                      return { success: true, value: "SOURCE" };
                    case "QUALITY":
                      return { success: true, value: "QUALITY" };
                    case "RATING":
                      return { success: true, value: "RATING" };
                    case "AUDIO":
                      return { success: true, value: "AUDIO" };
                    case "LANGUAGE":
                      return { success: true, value: "LANGUAGE" };
                    case "PUBLICATION_DATE":
                      return { success: true, value: "PUBLICATION_DATE" };
                    case "MIAB":
                      return { success: true, value: "MIAB" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["identifier"]);
            })();
            if (!parsed_identifier.success) {
              return { success: false };
            }
            if (!hasProp(value, "label")) {
              return { success: false };
            }
            const parsed_label = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["label"]);
            })();
            if (!parsed_label.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "options")) {
              return { success: false };
            }
            const parsed_options = (() => {
              return ((value: unknown): { success: false } | { success: true; value: FormFilterOption[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: FormFilterOption } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          label: string;
                          shortLabel: string | null;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "label")) {
                      return { success: false };
                    }
                    const parsed_label = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["label"]);
                    })();
                    if (!parsed_label.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "shortLabel")) {
                      return { success: false };
                    }
                    const parsed_shortLabel = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["shortLabel"]);
                    })();
                    if (!parsed_shortLabel.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { label: parsed_label.value, shortLabel: parsed_shortLabel.value, value: parsed_value.value } };
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["options"]);
            })();
            if (!parsed_options.success) {
              return { success: false };
            }
            if (!hasProp(value, "priority")) {
              return { success: false };
            }
            const parsed_priority = (() => {
              return ((value: unknown): { success: false } | { success: true; value: FormFilterPriority } => {
                return ((value: unknown): { success: false } | { success: true; value: "PRIMARY" | "SECONDARY" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "PRIMARY":
                      return { success: true, value: "PRIMARY" };
                    case "SECONDARY":
                      return { success: true, value: "SECONDARY" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["priority"]);
            })();
            if (!parsed_priority.success) {
              return { success: false };
            }
            if (!hasProp(value, "type")) {
              return { success: false };
            }
            const parsed_type = (() => {
              return ((value: unknown): { success: false } | { success: true; value: FormFilterType } => {
                return ((value: unknown): { success: false } | { success: true; value: "MULTICHECKBOX" | "CHECKBOX" | "MULTISELECT" | "RANGE" | "RANGE_MIN" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "MULTICHECKBOX":
                      return { success: true, value: "MULTICHECKBOX" };
                    case "CHECKBOX":
                      return { success: true, value: "CHECKBOX" };
                    case "MULTISELECT":
                      return { success: true, value: "MULTISELECT" };
                    case "RANGE":
                      return { success: true, value: "RANGE" };
                    case "RANGE_MIN":
                      return { success: true, value: "RANGE_MIN" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["type"]);
            })();
            if (!parsed_type.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                activeValues: parsed_activeValues.value,
                identifier: parsed_identifier.value,
                label: parsed_label.value,
                name: parsed_name.value,
                options: parsed_options.value,
                priority: parsed_priority.value,
                type: parsed_type.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["initialSummaryFormFilters"]);
    })();
    if (!parsed_initialSummaryFormFilters.success) {
      return { success: false };
    }
    if (!hasProp(value, "query")) {
      return { success: false };
    }
    const parsed_query = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["query"]);
    })();
    if (!parsed_query.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        beta: parsed_beta.value,
        hasCorporatePlus: parsed_hasCorporatePlus.value,
        initialActionableFormFilters: parsed_initialActionableFormFilters.value,
        initialSummaryFormFilters: parsed_initialSummaryFormFilters.value,
        query: parsed_query.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// FullSearchResults
export function serialize_FullSearchResults(obj: FullSearchResults): string {
  const serialized = ((value: {
    actionableFormFilters: FormFilter[];
    actionables: ContentMetadata[];
    channelPaging: {
      page: number;
      totalCount: bigint;
    };
    channels: ChannelMetadata[];
    customPages: CustomPageListView[];
    downloadSourceParams: { additionalProperties: Record<string, string> };
    summaries: ContentMetadata[];
    summariesPaging: {
      page: number;
      totalCount: bigint;
    };
    summaryFormFilters: FormFilter[];
  }): unknown => {
    return {
      actionableFormFilters: ((value: FormFilter[]): unknown => {
        return value.map((value: { activeValues: string[]; identifier: FormFilterName; label: string; name: string; options: FormFilterOption[]; priority: FormFilterPriority; type: FormFilterType }): unknown => {
          return {
            activeValues: ((value: string[]): unknown => {
              return value.map((value: string): unknown => {
                return value;
              });
            })(value["activeValues"]),
            identifier: ((value: "CHANNEL" | "SOURCE" | "QUALITY" | "RATING" | "AUDIO" | "LANGUAGE" | "PUBLICATION_DATE" | "MIAB"): unknown => {
              return value;
            })(value["identifier"]),
            label: ((value: string): unknown => {
              return value;
            })(value["label"]),
            name: ((value: string): unknown => {
              return value;
            })(value["name"]),
            options: ((value: FormFilterOption[]): unknown => {
              return value.map((value: { label: string; shortLabel: string | null; value: string }): unknown => {
                return {
                  label: ((value: string): unknown => {
                    return value;
                  })(value["label"]),
                  shortLabel: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["shortLabel"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              });
            })(value["options"]),
            priority: ((value: "PRIMARY" | "SECONDARY"): unknown => {
              return value;
            })(value["priority"]),
            type: ((value: "MULTICHECKBOX" | "CHECKBOX" | "MULTISELECT" | "RANGE" | "RANGE_MIN"): unknown => {
              return value;
            })(value["type"]),
          };
        });
      })(value["actionableFormFilters"]),
      actionables: ((value: ContentMetadata[]): unknown => {
        return value.map(
          (
            value:
              | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
              | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
              | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
              | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number },
          ): unknown => {
            switch (value.contentType) {
              case "SUMMARY": {
                return {
                  contentType: "SUMMARY",
                  backgroundColor: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["backgroundColor"]),
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "SUMMARY"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "SUMMARY"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                  documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
                    return value;
                  })(value["documentType"]),
                  meetingInABoxAvailable: ((value: boolean): unknown => {
                    return value;
                  })(value["meetingInABoxAvailable"]),
                  sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
                    return value;
                  })(value["sourceType"]),
                };
              }
              case "ACTIONABLE": {
                return {
                  contentType: "ACTIONABLE",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "ACTIONABLE"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "ACTIONABLE"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                };
              }
              case "SKETCHNOTE": {
                return {
                  contentType: "SKETCHNOTE",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "SKETCHNOTE"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "SKETCHNOTE"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                };
              }
              case "MIAB": {
                return {
                  contentType: "MIAB",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "MIAB"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "MIAB"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                  customerId: ((value: number | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["customerId"]),
                  dataId: ((value: number): unknown => {
                    return value;
                  })(value["dataId"]),
                };
              }
            }
          },
        );
      })(value["actionables"]),
      channelPaging: ((value: { page: number; totalCount: bigint }): unknown => {
        return {
          page: ((value: number): unknown => {
            return value;
          })(value["page"]),
          totalCount: ((value: bigint): unknown => {
            return value;
          })(value["totalCount"]),
        };
      })(value["channelPaging"]),
      channels: ((value: ChannelMetadata[]): unknown => {
        return value.map(
          (value: {
            activationTime: DateTime<true> | null;
            active: boolean;
            channelId: bigint;
            channelLocalization: { additionalProperties: Record<string, ChannelTextLocalization> };
            coverUri: string | null;
            definition: string | null;
            descriptionHtml: string | null;
            modificationTime: DateTime<true> | null;
            name: string;
            synonyms: string | null;
            tag: Tag;
            title: string | null;
            visibleLanguages: Language[];
          }): unknown => {
            return {
              activationTime: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["activationTime"]),
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              channelId: ((value: bigint): unknown => {
                return value;
              })(value["channelId"]),
              channelLocalization: ((value: { additionalProperties: Record<string, ChannelTextLocalization> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: { description: string | null; synonym: string | null; title: string | null }): unknown => {
                          return {
                            description: ((value: string | null): unknown => {
                              if (value === null) {
                                return null;
                              }
                              return value;
                            })(value["description"]),
                            synonym: ((value: string | null): unknown => {
                              if (value === null) {
                                return null;
                              }
                              return value;
                            })(value["synonym"]),
                            title: ((value: string | null): unknown => {
                              if (value === null) {
                                return null;
                              }
                              return value;
                            })(value["title"]),
                          };
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["channelLocalization"]),
              coverUri: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["coverUri"]),
              definition: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["definition"]),
              descriptionHtml: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["descriptionHtml"]),
              modificationTime: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["modificationTime"]),
              name: ((value: string): unknown => {
                return value;
              })(value["name"]),
              synonyms: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["synonyms"]),
              tag: ((
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                  | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
              ): unknown => {
                switch (value.discriminator) {
                  case "dynamicString": {
                    return {
                      discriminator: "dynamicString",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicString"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicBoolean": {
                    return {
                      discriminator: "dynamicBoolean",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicBoolean"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: boolean): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicNumber": {
                    return {
                      discriminator: "dynamicNumber",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicNumber"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: number): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicVoid": {
                    return {
                      discriminator: "dynamicVoid",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicVoid"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                    };
                  }
                  case "channel": {
                    return {
                      discriminator: "channel",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                        return {
                          discriminator: ((value: "channel"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "channel"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "primary-skill": {
                    return {
                      discriminator: "primary-skill",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                        return {
                          discriminator: ((value: "primary-skill"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "primary-skill"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "skill": {
                    return {
                      discriminator: "skill",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                        return {
                          discriminator: ((value: "skill"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "skill"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "key-quality": {
                    return {
                      discriminator: "key-quality",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                        return {
                          discriminator: ((value: "key-quality"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "key-quality"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "classification": {
                    return {
                      discriminator: "classification",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                        return {
                          discriminator: ((value: "classification"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "classification"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                }
              })(value["tag"]),
              title: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["title"]),
              visibleLanguages: ((value: Language[]): unknown => {
                return value.map((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                  return value;
                });
              })(value["visibleLanguages"]),
            };
          },
        );
      })(value["channels"]),
      customPages: ((value: CustomPageListView[]): unknown => {
        return value.map((value: { corporateId: number; coverUri: string; customPageId: bigint; description: string | null; title: string }): unknown => {
          return {
            corporateId: ((value: number): unknown => {
              return value;
            })(value["corporateId"]),
            coverUri: ((value: string): unknown => {
              return value;
            })(value["coverUri"]),
            customPageId: ((value: bigint): unknown => {
              return value;
            })(value["customPageId"]),
            description: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["description"]),
            title: ((value: string): unknown => {
              return value;
            })(value["title"]),
          };
        });
      })(value["customPages"]),
      downloadSourceParams: ((value: { additionalProperties: Record<string, string> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: string): unknown => {
                  return value;
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["downloadSourceParams"]),
      summaries: ((value: ContentMetadata[]): unknown => {
        return value.map(
          (
            value:
              | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
              | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
              | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
              | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number },
          ): unknown => {
            switch (value.contentType) {
              case "SUMMARY": {
                return {
                  contentType: "SUMMARY",
                  backgroundColor: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["backgroundColor"]),
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "SUMMARY"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "SUMMARY"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                  documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
                    return value;
                  })(value["documentType"]),
                  meetingInABoxAvailable: ((value: boolean): unknown => {
                    return value;
                  })(value["meetingInABoxAvailable"]),
                  sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
                    return value;
                  })(value["sourceType"]),
                };
              }
              case "ACTIONABLE": {
                return {
                  contentType: "ACTIONABLE",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "ACTIONABLE"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "ACTIONABLE"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                };
              }
              case "SKETCHNOTE": {
                return {
                  contentType: "SKETCHNOTE",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "SKETCHNOTE"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "SKETCHNOTE"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                };
              }
              case "MIAB": {
                return {
                  contentType: "MIAB",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "MIAB"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "MIAB"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                  customerId: ((value: number | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["customerId"]),
                  dataId: ((value: number): unknown => {
                    return value;
                  })(value["dataId"]),
                };
              }
            }
          },
        );
      })(value["summaries"]),
      summariesPaging: ((value: { page: number; totalCount: bigint }): unknown => {
        return {
          page: ((value: number): unknown => {
            return value;
          })(value["page"]),
          totalCount: ((value: bigint): unknown => {
            return value;
          })(value["totalCount"]),
        };
      })(value["summariesPaging"]),
      summaryFormFilters: ((value: FormFilter[]): unknown => {
        return value.map((value: { activeValues: string[]; identifier: FormFilterName; label: string; name: string; options: FormFilterOption[]; priority: FormFilterPriority; type: FormFilterType }): unknown => {
          return {
            activeValues: ((value: string[]): unknown => {
              return value.map((value: string): unknown => {
                return value;
              });
            })(value["activeValues"]),
            identifier: ((value: "CHANNEL" | "SOURCE" | "QUALITY" | "RATING" | "AUDIO" | "LANGUAGE" | "PUBLICATION_DATE" | "MIAB"): unknown => {
              return value;
            })(value["identifier"]),
            label: ((value: string): unknown => {
              return value;
            })(value["label"]),
            name: ((value: string): unknown => {
              return value;
            })(value["name"]),
            options: ((value: FormFilterOption[]): unknown => {
              return value.map((value: { label: string; shortLabel: string | null; value: string }): unknown => {
                return {
                  label: ((value: string): unknown => {
                    return value;
                  })(value["label"]),
                  shortLabel: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["shortLabel"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              });
            })(value["options"]),
            priority: ((value: "PRIMARY" | "SECONDARY"): unknown => {
              return value;
            })(value["priority"]),
            type: ((value: "MULTICHECKBOX" | "CHECKBOX" | "MULTISELECT" | "RANGE" | "RANGE_MIN"): unknown => {
              return value;
            })(value["type"]),
          };
        });
      })(value["summaryFormFilters"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_FullSearchResults(str: string): FullSearchResults | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          actionableFormFilters: FormFilter[];
          actionables: ContentMetadata[];
          channelPaging: {
            page: number;
            totalCount: bigint;
          };
          channels: ChannelMetadata[];
          customPages: CustomPageListView[];
          downloadSourceParams: { additionalProperties: Record<string, string> };
          summaries: ContentMetadata[];
          summariesPaging: {
            page: number;
            totalCount: bigint;
          };
          summaryFormFilters: FormFilter[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "actionableFormFilters")) {
      return { success: false };
    }
    const parsed_actionableFormFilters = (() => {
      return ((value: unknown): { success: false } | { success: true; value: FormFilter[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: FormFilter } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  activeValues: string[];
                  identifier: FormFilterName;
                  label: string;
                  name: string;
                  options: FormFilterOption[];
                  priority: FormFilterPriority;
                  type: FormFilterType;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "activeValues")) {
              return { success: false };
            }
            const parsed_activeValues = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["activeValues"]);
            })();
            if (!parsed_activeValues.success) {
              return { success: false };
            }
            if (!hasProp(value, "identifier")) {
              return { success: false };
            }
            const parsed_identifier = (() => {
              return ((value: unknown): { success: false } | { success: true; value: FormFilterName } => {
                return ((value: unknown): { success: false } | { success: true; value: "CHANNEL" | "SOURCE" | "QUALITY" | "RATING" | "AUDIO" | "LANGUAGE" | "PUBLICATION_DATE" | "MIAB" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "CHANNEL":
                      return { success: true, value: "CHANNEL" };
                    case "SOURCE":
                      return { success: true, value: "SOURCE" };
                    case "QUALITY":
                      return { success: true, value: "QUALITY" };
                    case "RATING":
                      return { success: true, value: "RATING" };
                    case "AUDIO":
                      return { success: true, value: "AUDIO" };
                    case "LANGUAGE":
                      return { success: true, value: "LANGUAGE" };
                    case "PUBLICATION_DATE":
                      return { success: true, value: "PUBLICATION_DATE" };
                    case "MIAB":
                      return { success: true, value: "MIAB" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["identifier"]);
            })();
            if (!parsed_identifier.success) {
              return { success: false };
            }
            if (!hasProp(value, "label")) {
              return { success: false };
            }
            const parsed_label = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["label"]);
            })();
            if (!parsed_label.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "options")) {
              return { success: false };
            }
            const parsed_options = (() => {
              return ((value: unknown): { success: false } | { success: true; value: FormFilterOption[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: FormFilterOption } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          label: string;
                          shortLabel: string | null;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "label")) {
                      return { success: false };
                    }
                    const parsed_label = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["label"]);
                    })();
                    if (!parsed_label.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "shortLabel")) {
                      return { success: false };
                    }
                    const parsed_shortLabel = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["shortLabel"]);
                    })();
                    if (!parsed_shortLabel.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { label: parsed_label.value, shortLabel: parsed_shortLabel.value, value: parsed_value.value } };
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["options"]);
            })();
            if (!parsed_options.success) {
              return { success: false };
            }
            if (!hasProp(value, "priority")) {
              return { success: false };
            }
            const parsed_priority = (() => {
              return ((value: unknown): { success: false } | { success: true; value: FormFilterPriority } => {
                return ((value: unknown): { success: false } | { success: true; value: "PRIMARY" | "SECONDARY" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "PRIMARY":
                      return { success: true, value: "PRIMARY" };
                    case "SECONDARY":
                      return { success: true, value: "SECONDARY" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["priority"]);
            })();
            if (!parsed_priority.success) {
              return { success: false };
            }
            if (!hasProp(value, "type")) {
              return { success: false };
            }
            const parsed_type = (() => {
              return ((value: unknown): { success: false } | { success: true; value: FormFilterType } => {
                return ((value: unknown): { success: false } | { success: true; value: "MULTICHECKBOX" | "CHECKBOX" | "MULTISELECT" | "RANGE" | "RANGE_MIN" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "MULTICHECKBOX":
                      return { success: true, value: "MULTICHECKBOX" };
                    case "CHECKBOX":
                      return { success: true, value: "CHECKBOX" };
                    case "MULTISELECT":
                      return { success: true, value: "MULTISELECT" };
                    case "RANGE":
                      return { success: true, value: "RANGE" };
                    case "RANGE_MIN":
                      return { success: true, value: "RANGE_MIN" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["type"]);
            })();
            if (!parsed_type.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                activeValues: parsed_activeValues.value,
                identifier: parsed_identifier.value,
                label: parsed_label.value,
                name: parsed_name.value,
                options: parsed_options.value,
                priority: parsed_priority.value,
                type: parsed_type.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["actionableFormFilters"]);
    })();
    if (!parsed_actionableFormFilters.success) {
      return { success: false };
    }
    if (!hasProp(value, "actionables")) {
      return { success: false };
    }
    const parsed_actionables = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ContentMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ContentMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
                  | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
                  | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
                  | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
                    | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
                    | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
                    | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "contentType")) {
                return { success: false };
              }
              if (typeof value["contentType"] != "string") {
                return { success: false };
              }
              const discriminator = value["contentType"];
              switch (discriminator) {
                case "SUMMARY": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          backgroundColor: string | null;
                          common: ContentMetadataCommon;
                          contentId: SummaryId;
                          documentType: SummaryDocumentType;
                          meetingInABoxAvailable: boolean;
                          sourceType: SummarySourceType;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "backgroundColor")) {
                      return { success: false };
                    }
                    const parsed_backgroundColor = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["backgroundColor"]);
                    })();
                    if (!parsed_backgroundColor.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummaryId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "SUMMARY";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "SUMMARY":
                                  return { success: true, value: "SUMMARY" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "documentType")) {
                      return { success: false };
                    }
                    const parsed_documentType = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "ABSTRACT":
                              return { success: true, value: "ABSTRACT" };
                            case "REVIEW":
                              return { success: true, value: "REVIEW" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["documentType"]);
                    })();
                    if (!parsed_documentType.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "meetingInABoxAvailable")) {
                      return { success: false };
                    }
                    const parsed_meetingInABoxAvailable = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["meetingInABoxAvailable"]);
                    })();
                    if (!parsed_meetingInABoxAvailable.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "sourceType")) {
                      return { success: false };
                    }
                    const parsed_sourceType = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "BOOK":
                              return { success: true, value: "BOOK" };
                            case "VIDEO":
                              return { success: true, value: "VIDEO" };
                            case "ARTICLE":
                              return { success: true, value: "ARTICLE" };
                            case "CHAPTER":
                              return { success: true, value: "CHAPTER" };
                            case "REPORT":
                              return { success: true, value: "REPORT" };
                            case "PODCAST":
                              return { success: true, value: "PODCAST" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["sourceType"]);
                    })();
                    if (!parsed_sourceType.success) {
                      return { success: false };
                    }
                    return {
                      success: true,
                      value: {
                        backgroundColor: parsed_backgroundColor.value,
                        common: parsed_common.value,
                        contentId: parsed_contentId.value,
                        documentType: parsed_documentType.value,
                        meetingInABoxAvailable: parsed_meetingInABoxAvailable.value,
                        sourceType: parsed_sourceType.value,
                      },
                    };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "SUMMARY" as const, ...parsedVariant.value } };
                }
                case "ACTIONABLE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: ActionableId;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ActionableId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "ACTIONABLE";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "ACTIONABLE":
                                  return { success: true, value: "ACTIONABLE" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "ACTIONABLE" as const, ...parsedVariant.value } };
                }
                case "SKETCHNOTE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: LgxpId;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: LgxpId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "SKETCHNOTE";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "SKETCHNOTE" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "SKETCHNOTE":
                                  return { success: true, value: "SKETCHNOTE" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "SKETCHNOTE" as const, ...parsedVariant.value } };
                }
                case "MIAB": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: MiabId;
                          customerId: number | null;
                          dataId: number;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: MiabId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "MIAB";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "MIAB" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "MIAB":
                                  return { success: true, value: "MIAB" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "customerId")) {
                      return { success: false };
                    }
                    const parsed_customerId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["customerId"]);
                    })();
                    if (!parsed_customerId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "dataId")) {
                      return { success: false };
                    }
                    const parsed_dataId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["dataId"]);
                    })();
                    if (!parsed_dataId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value, customerId: parsed_customerId.value, dataId: parsed_dataId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "MIAB" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["actionables"]);
    })();
    if (!parsed_actionables.success) {
      return { success: false };
    }
    if (!hasProp(value, "channelPaging")) {
      return { success: false };
    }
    const parsed_channelPaging = (() => {
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value: {
              page: number;
              totalCount: bigint;
            };
          } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        if (!hasProp(value, "page")) {
          return { success: false };
        }
        const parsed_page = (() => {
          return ((value: unknown): { success: false } | { success: true; value: number } => {
            if (typeof value === "number") {
              return { success: true, value: value };
            }
            return { success: false };
          })(value["page"]);
        })();
        if (!parsed_page.success) {
          return { success: false };
        }
        if (!hasProp(value, "totalCount")) {
          return { success: false };
        }
        const parsed_totalCount = (() => {
          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
            if (typeof value === "number") {
              return { success: true, value: BigInt(value) };
            }
            if (typeof value === "bigint") {
              return { success: true, value: value };
            }
            return { success: false };
          })(value["totalCount"]);
        })();
        if (!parsed_totalCount.success) {
          return { success: false };
        }
        return { success: true, value: { page: parsed_page.value, totalCount: parsed_totalCount.value } };
      })(value["channelPaging"]);
    })();
    if (!parsed_channelPaging.success) {
      return { success: false };
    }
    if (!hasProp(value, "channels")) {
      return { success: false };
    }
    const parsed_channels = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ChannelMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ChannelMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  activationTime: DateTime<true> | null;
                  active: boolean;
                  channelId: bigint;
                  channelLocalization: { additionalProperties: Record<string, ChannelTextLocalization> };
                  coverUri: string | null;
                  definition: string | null;
                  descriptionHtml: string | null;
                  modificationTime: DateTime<true> | null;
                  name: string;
                  synonyms: string | null;
                  tag: Tag;
                  title: string | null;
                  visibleLanguages: Language[];
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "activationTime")) {
              return { success: false };
            }
            const parsed_activationTime = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["activationTime"]);
            })();
            if (!parsed_activationTime.success) {
              return { success: false };
            }
            if (!hasProp(value, "active")) {
              return { success: false };
            }
            const parsed_active = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["active"]);
            })();
            if (!parsed_active.success) {
              return { success: false };
            }
            if (!hasProp(value, "channelId")) {
              return { success: false };
            }
            const parsed_channelId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["channelId"]);
            })();
            if (!parsed_channelId.success) {
              return { success: false };
            }
            if (!hasProp(value, "channelLocalization")) {
              return { success: false };
            }
            const parsed_channelLocalization = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, ChannelTextLocalization> } } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, ChannelTextLocalization> } => {
                  const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: ChannelTextLocalization }] => {
                    return [
                      key,
                      ((value: unknown): { success: false } | { success: true; value: ChannelTextLocalization } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                description: string | null;
                                synonym: string | null;
                                title: string | null;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "synonym")) {
                            return { success: false };
                          }
                          const parsed_synonym = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["synonym"]);
                          })();
                          if (!parsed_synonym.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return { success: true, value: { description: parsed_description.value, synonym: parsed_synonym.value, title: parsed_title.value } };
                        })(value);
                      })(value),
                    ];
                  });
                  if (parsed.find(([_, value]) => !value.success) !== undefined) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: Object.fromEntries(
                      parsed.map(([key, value]) => {
                        assert(value.success);
                        return [key, value.value];
                      }),
                    ),
                  };
                })();
                if (!parsed_additionalProperties.success) {
                  return { success: false };
                }
                return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
              })(value["channelLocalization"]);
            })();
            if (!parsed_channelLocalization.success) {
              return { success: false };
            }
            if (!hasProp(value, "coverUri")) {
              return { success: false };
            }
            const parsed_coverUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["coverUri"]);
            })();
            if (!parsed_coverUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "definition")) {
              return { success: false };
            }
            const parsed_definition = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["definition"]);
            })();
            if (!parsed_definition.success) {
              return { success: false };
            }
            if (!hasProp(value, "descriptionHtml")) {
              return { success: false };
            }
            const parsed_descriptionHtml = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["descriptionHtml"]);
            })();
            if (!parsed_descriptionHtml.success) {
              return { success: false };
            }
            if (!hasProp(value, "modificationTime")) {
              return { success: false };
            }
            const parsed_modificationTime = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["modificationTime"]);
            })();
            if (!parsed_modificationTime.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "synonyms")) {
              return { success: false };
            }
            const parsed_synonyms = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["synonyms"]);
            })();
            if (!parsed_synonyms.success) {
              return { success: false };
            }
            if (!hasProp(value, "tag")) {
              return { success: false };
            }
            const parsed_tag = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Tag } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                        | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                        | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                        | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                        | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                        | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                        | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                        | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                        | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                    } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value:
                          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                          | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                          | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                      } => {
                    if (value == null) {
                      return { success: false };
                    }
                    if (typeof value != "object") {
                      return { success: false };
                    }
                    if (!hasProp(value, "discriminator")) {
                      return { success: false };
                    }
                    if (typeof value["discriminator"] != "string") {
                      return { success: false };
                    }
                    const discriminator = value["discriminator"];
                    switch (discriminator) {
                      case "dynamicString": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicString;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicString;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicString":
                                          return { success: true, value: "dynamicString" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                      }
                      case "dynamicBoolean": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicBoolean;
                                value: boolean;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicBoolean;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicBoolean":
                                          return { success: true, value: "dynamicBoolean" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                      }
                      case "dynamicNumber": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicNumber;
                                value: number;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicNumber;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicNumber":
                                          return { success: true, value: "dynamicNumber" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: number } => {
                              if (typeof value === "number") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                      }
                      case "dynamicVoid": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicVoid;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicVoid;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicVoid":
                                          return { success: true, value: "dynamicVoid" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                      }
                      case "channel": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindChannel;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindChannel;
                                      id: bigint;
                                      kind: KindChannel;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "channel":
                                          return { success: true, value: "channel" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "channel":
                                          return { success: true, value: "channel" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                      }
                      case "primary-skill": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindPrimarySkill;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindPrimarySkill;
                                      id: bigint;
                                      kind: KindPrimarySkill;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "primary-skill":
                                          return { success: true, value: "primary-skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "primary-skill":
                                          return { success: true, value: "primary-skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                      }
                      case "skill": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindSkill;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindSkill;
                                      id: bigint;
                                      kind: KindSkill;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "skill":
                                          return { success: true, value: "skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "skill":
                                          return { success: true, value: "skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                      }
                      case "key-quality": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindKeyQuality;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindKeyQuality;
                                      id: bigint;
                                      kind: KindKeyQuality;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "key-quality":
                                          return { success: true, value: "key-quality" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "key-quality":
                                          return { success: true, value: "key-quality" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                      }
                      case "classification": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindClassification;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindClassification;
                                      id: bigint;
                                      kind: KindClassification;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "classification":
                                          return { success: true, value: "classification" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "classification":
                                          return { success: true, value: "classification" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                      }
                      default:
                        return { success: false };
                    }
                  })(value);
                })(value);
              })(value["tag"]);
            })();
            if (!parsed_tag.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            if (!hasProp(value, "visibleLanguages")) {
              return { success: false };
            }
            const parsed_visibleLanguages = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Language[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: Language } => {
                  return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    switch (value) {
                      case "en":
                        return { success: true, value: "en" };
                      case "de":
                        return { success: true, value: "de" };
                      case "es":
                        return { success: true, value: "es" };
                      case "ru":
                        return { success: true, value: "ru" };
                      case "zh":
                        return { success: true, value: "zh" };
                      case "pt":
                        return { success: true, value: "pt" };
                      case "fr":
                        return { success: true, value: "fr" };
                      default:
                        return { success: false };
                    }
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["visibleLanguages"]);
            })();
            if (!parsed_visibleLanguages.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                activationTime: parsed_activationTime.value,
                active: parsed_active.value,
                channelId: parsed_channelId.value,
                channelLocalization: parsed_channelLocalization.value,
                coverUri: parsed_coverUri.value,
                definition: parsed_definition.value,
                descriptionHtml: parsed_descriptionHtml.value,
                modificationTime: parsed_modificationTime.value,
                name: parsed_name.value,
                synonyms: parsed_synonyms.value,
                tag: parsed_tag.value,
                title: parsed_title.value,
                visibleLanguages: parsed_visibleLanguages.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["channels"]);
    })();
    if (!parsed_channels.success) {
      return { success: false };
    }
    if (!hasProp(value, "customPages")) {
      return { success: false };
    }
    const parsed_customPages = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CustomPageListView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: CustomPageListView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  corporateId: number;
                  coverUri: string;
                  customPageId: bigint;
                  description: string | null;
                  title: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "corporateId")) {
              return { success: false };
            }
            const parsed_corporateId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["corporateId"]);
            })();
            if (!parsed_corporateId.success) {
              return { success: false };
            }
            if (!hasProp(value, "coverUri")) {
              return { success: false };
            }
            const parsed_coverUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["coverUri"]);
            })();
            if (!parsed_coverUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "customPageId")) {
              return { success: false };
            }
            const parsed_customPageId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["customPageId"]);
            })();
            if (!parsed_customPageId.success) {
              return { success: false };
            }
            if (!hasProp(value, "description")) {
              return { success: false };
            }
            const parsed_description = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["description"]);
            })();
            if (!parsed_description.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            return { success: true, value: { corporateId: parsed_corporateId.value, coverUri: parsed_coverUri.value, customPageId: parsed_customPageId.value, description: parsed_description.value, title: parsed_title.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["customPages"]);
    })();
    if (!parsed_customPages.success) {
      return { success: false };
    }
    if (!hasProp(value, "downloadSourceParams")) {
      return { success: false };
    }
    const parsed_downloadSourceParams = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["downloadSourceParams"]);
    })();
    if (!parsed_downloadSourceParams.success) {
      return { success: false };
    }
    if (!hasProp(value, "summaries")) {
      return { success: false };
    }
    const parsed_summaries = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ContentMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ContentMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
                  | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
                  | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
                  | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
                    | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
                    | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
                    | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "contentType")) {
                return { success: false };
              }
              if (typeof value["contentType"] != "string") {
                return { success: false };
              }
              const discriminator = value["contentType"];
              switch (discriminator) {
                case "SUMMARY": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          backgroundColor: string | null;
                          common: ContentMetadataCommon;
                          contentId: SummaryId;
                          documentType: SummaryDocumentType;
                          meetingInABoxAvailable: boolean;
                          sourceType: SummarySourceType;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "backgroundColor")) {
                      return { success: false };
                    }
                    const parsed_backgroundColor = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["backgroundColor"]);
                    })();
                    if (!parsed_backgroundColor.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummaryId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "SUMMARY";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "SUMMARY":
                                  return { success: true, value: "SUMMARY" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "documentType")) {
                      return { success: false };
                    }
                    const parsed_documentType = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "ABSTRACT":
                              return { success: true, value: "ABSTRACT" };
                            case "REVIEW":
                              return { success: true, value: "REVIEW" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["documentType"]);
                    })();
                    if (!parsed_documentType.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "meetingInABoxAvailable")) {
                      return { success: false };
                    }
                    const parsed_meetingInABoxAvailable = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["meetingInABoxAvailable"]);
                    })();
                    if (!parsed_meetingInABoxAvailable.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "sourceType")) {
                      return { success: false };
                    }
                    const parsed_sourceType = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "BOOK":
                              return { success: true, value: "BOOK" };
                            case "VIDEO":
                              return { success: true, value: "VIDEO" };
                            case "ARTICLE":
                              return { success: true, value: "ARTICLE" };
                            case "CHAPTER":
                              return { success: true, value: "CHAPTER" };
                            case "REPORT":
                              return { success: true, value: "REPORT" };
                            case "PODCAST":
                              return { success: true, value: "PODCAST" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["sourceType"]);
                    })();
                    if (!parsed_sourceType.success) {
                      return { success: false };
                    }
                    return {
                      success: true,
                      value: {
                        backgroundColor: parsed_backgroundColor.value,
                        common: parsed_common.value,
                        contentId: parsed_contentId.value,
                        documentType: parsed_documentType.value,
                        meetingInABoxAvailable: parsed_meetingInABoxAvailable.value,
                        sourceType: parsed_sourceType.value,
                      },
                    };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "SUMMARY" as const, ...parsedVariant.value } };
                }
                case "ACTIONABLE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: ActionableId;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ActionableId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "ACTIONABLE";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "ACTIONABLE":
                                  return { success: true, value: "ACTIONABLE" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "ACTIONABLE" as const, ...parsedVariant.value } };
                }
                case "SKETCHNOTE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: LgxpId;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: LgxpId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "SKETCHNOTE";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "SKETCHNOTE" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "SKETCHNOTE":
                                  return { success: true, value: "SKETCHNOTE" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "SKETCHNOTE" as const, ...parsedVariant.value } };
                }
                case "MIAB": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: MiabId;
                          customerId: number | null;
                          dataId: number;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: MiabId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "MIAB";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "MIAB" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "MIAB":
                                  return { success: true, value: "MIAB" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "customerId")) {
                      return { success: false };
                    }
                    const parsed_customerId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["customerId"]);
                    })();
                    if (!parsed_customerId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "dataId")) {
                      return { success: false };
                    }
                    const parsed_dataId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["dataId"]);
                    })();
                    if (!parsed_dataId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value, customerId: parsed_customerId.value, dataId: parsed_dataId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "MIAB" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["summaries"]);
    })();
    if (!parsed_summaries.success) {
      return { success: false };
    }
    if (!hasProp(value, "summariesPaging")) {
      return { success: false };
    }
    const parsed_summariesPaging = (() => {
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value: {
              page: number;
              totalCount: bigint;
            };
          } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        if (!hasProp(value, "page")) {
          return { success: false };
        }
        const parsed_page = (() => {
          return ((value: unknown): { success: false } | { success: true; value: number } => {
            if (typeof value === "number") {
              return { success: true, value: value };
            }
            return { success: false };
          })(value["page"]);
        })();
        if (!parsed_page.success) {
          return { success: false };
        }
        if (!hasProp(value, "totalCount")) {
          return { success: false };
        }
        const parsed_totalCount = (() => {
          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
            if (typeof value === "number") {
              return { success: true, value: BigInt(value) };
            }
            if (typeof value === "bigint") {
              return { success: true, value: value };
            }
            return { success: false };
          })(value["totalCount"]);
        })();
        if (!parsed_totalCount.success) {
          return { success: false };
        }
        return { success: true, value: { page: parsed_page.value, totalCount: parsed_totalCount.value } };
      })(value["summariesPaging"]);
    })();
    if (!parsed_summariesPaging.success) {
      return { success: false };
    }
    if (!hasProp(value, "summaryFormFilters")) {
      return { success: false };
    }
    const parsed_summaryFormFilters = (() => {
      return ((value: unknown): { success: false } | { success: true; value: FormFilter[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: FormFilter } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  activeValues: string[];
                  identifier: FormFilterName;
                  label: string;
                  name: string;
                  options: FormFilterOption[];
                  priority: FormFilterPriority;
                  type: FormFilterType;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "activeValues")) {
              return { success: false };
            }
            const parsed_activeValues = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["activeValues"]);
            })();
            if (!parsed_activeValues.success) {
              return { success: false };
            }
            if (!hasProp(value, "identifier")) {
              return { success: false };
            }
            const parsed_identifier = (() => {
              return ((value: unknown): { success: false } | { success: true; value: FormFilterName } => {
                return ((value: unknown): { success: false } | { success: true; value: "CHANNEL" | "SOURCE" | "QUALITY" | "RATING" | "AUDIO" | "LANGUAGE" | "PUBLICATION_DATE" | "MIAB" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "CHANNEL":
                      return { success: true, value: "CHANNEL" };
                    case "SOURCE":
                      return { success: true, value: "SOURCE" };
                    case "QUALITY":
                      return { success: true, value: "QUALITY" };
                    case "RATING":
                      return { success: true, value: "RATING" };
                    case "AUDIO":
                      return { success: true, value: "AUDIO" };
                    case "LANGUAGE":
                      return { success: true, value: "LANGUAGE" };
                    case "PUBLICATION_DATE":
                      return { success: true, value: "PUBLICATION_DATE" };
                    case "MIAB":
                      return { success: true, value: "MIAB" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["identifier"]);
            })();
            if (!parsed_identifier.success) {
              return { success: false };
            }
            if (!hasProp(value, "label")) {
              return { success: false };
            }
            const parsed_label = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["label"]);
            })();
            if (!parsed_label.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "options")) {
              return { success: false };
            }
            const parsed_options = (() => {
              return ((value: unknown): { success: false } | { success: true; value: FormFilterOption[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: FormFilterOption } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          label: string;
                          shortLabel: string | null;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "label")) {
                      return { success: false };
                    }
                    const parsed_label = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["label"]);
                    })();
                    if (!parsed_label.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "shortLabel")) {
                      return { success: false };
                    }
                    const parsed_shortLabel = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["shortLabel"]);
                    })();
                    if (!parsed_shortLabel.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { label: parsed_label.value, shortLabel: parsed_shortLabel.value, value: parsed_value.value } };
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["options"]);
            })();
            if (!parsed_options.success) {
              return { success: false };
            }
            if (!hasProp(value, "priority")) {
              return { success: false };
            }
            const parsed_priority = (() => {
              return ((value: unknown): { success: false } | { success: true; value: FormFilterPriority } => {
                return ((value: unknown): { success: false } | { success: true; value: "PRIMARY" | "SECONDARY" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "PRIMARY":
                      return { success: true, value: "PRIMARY" };
                    case "SECONDARY":
                      return { success: true, value: "SECONDARY" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["priority"]);
            })();
            if (!parsed_priority.success) {
              return { success: false };
            }
            if (!hasProp(value, "type")) {
              return { success: false };
            }
            const parsed_type = (() => {
              return ((value: unknown): { success: false } | { success: true; value: FormFilterType } => {
                return ((value: unknown): { success: false } | { success: true; value: "MULTICHECKBOX" | "CHECKBOX" | "MULTISELECT" | "RANGE" | "RANGE_MIN" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "MULTICHECKBOX":
                      return { success: true, value: "MULTICHECKBOX" };
                    case "CHECKBOX":
                      return { success: true, value: "CHECKBOX" };
                    case "MULTISELECT":
                      return { success: true, value: "MULTISELECT" };
                    case "RANGE":
                      return { success: true, value: "RANGE" };
                    case "RANGE_MIN":
                      return { success: true, value: "RANGE_MIN" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["type"]);
            })();
            if (!parsed_type.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                activeValues: parsed_activeValues.value,
                identifier: parsed_identifier.value,
                label: parsed_label.value,
                name: parsed_name.value,
                options: parsed_options.value,
                priority: parsed_priority.value,
                type: parsed_type.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["summaryFormFilters"]);
    })();
    if (!parsed_summaryFormFilters.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        actionableFormFilters: parsed_actionableFormFilters.value,
        actionables: parsed_actionables.value,
        channelPaging: parsed_channelPaging.value,
        channels: parsed_channels.value,
        customPages: parsed_customPages.value,
        downloadSourceParams: parsed_downloadSourceParams.value,
        summaries: parsed_summaries.value,
        summariesPaging: parsed_summariesPaging.value,
        summaryFormFilters: parsed_summaryFormFilters.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// GaContextAsp
export function serialize_GaContextAsp(obj: GaContextAsp): string {
  const serialized = ((value: { corporateId: number; username: string }): unknown => {
    return {
      corporateId: ((value: number): unknown => {
        return value;
      })(value["corporateId"]),
      username: ((value: string): unknown => {
        return value;
      })(value["username"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_GaContextAsp(str: string): GaContextAsp | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          corporateId: number;
          username: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "username")) {
      return { success: false };
    }
    const parsed_username = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["username"]);
    })();
    if (!parsed_username.success) {
      return { success: false };
    }
    return { success: true, value: { corporateId: parsed_corporateId.value, username: parsed_username.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// GaContextCustomer
export function serialize_GaContextCustomer(obj: GaContextCustomer): string {
  const serialized = ((value: { customerId: number }): unknown => {
    return {
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_GaContextCustomer(str: string): GaContextCustomer | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          customerId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    return { success: true, value: { customerId: parsed_customerId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// GaContextDataSync
export function serialize_GaContextDataSync(obj: GaContextDataSync): string {
  const serialized = ((value: { asp: GaContextAsp | null; currentLanguage: Language; customer: GaContextCustomer | null; flags: GaContextFlags }): unknown => {
    return {
      asp: ((
        value: {
          corporateId: number;
          username: string;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          corporateId: ((value: number): unknown => {
            return value;
          })(value["corporateId"]),
          username: ((value: string): unknown => {
            return value;
          })(value["username"]),
        };
      })(value["asp"]),
      currentLanguage: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["currentLanguage"]),
      customer: ((
        value: {
          customerId: number;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          customerId: ((value: number): unknown => {
            return value;
          })(value["customerId"]),
        };
      })(value["customer"]),
      flags: ((value: { beta: boolean; loggedIn: boolean }): unknown => {
        return {
          beta: ((value: boolean): unknown => {
            return value;
          })(value["beta"]),
          loggedIn: ((value: boolean): unknown => {
            return value;
          })(value["loggedIn"]),
        };
      })(value["flags"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_GaContextDataSync(str: string): GaContextDataSync | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          asp: GaContextAsp | null;
          currentLanguage: Language;
          customer: GaContextCustomer | null;
          flags: GaContextFlags;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "asp")) {
      return { success: false };
    }
    const parsed_asp = (() => {
      return ((value: unknown): { success: false } | { success: true; value: GaContextAsp | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                corporateId: number;
                username: string;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "corporateId")) {
            return { success: false };
          }
          const parsed_corporateId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["corporateId"]);
          })();
          if (!parsed_corporateId.success) {
            return { success: false };
          }
          if (!hasProp(value, "username")) {
            return { success: false };
          }
          const parsed_username = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["username"]);
          })();
          if (!parsed_username.success) {
            return { success: false };
          }
          return { success: true, value: { corporateId: parsed_corporateId.value, username: parsed_username.value } };
        })(value);
      })(value["asp"]);
    })();
    if (!parsed_asp.success) {
      return { success: false };
    }
    if (!hasProp(value, "currentLanguage")) {
      return { success: false };
    }
    const parsed_currentLanguage = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language } => {
        return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "en":
              return { success: true, value: "en" };
            case "de":
              return { success: true, value: "de" };
            case "es":
              return { success: true, value: "es" };
            case "ru":
              return { success: true, value: "ru" };
            case "zh":
              return { success: true, value: "zh" };
            case "pt":
              return { success: true, value: "pt" };
            case "fr":
              return { success: true, value: "fr" };
            default:
              return { success: false };
          }
        })(value);
      })(value["currentLanguage"]);
    })();
    if (!parsed_currentLanguage.success) {
      return { success: false };
    }
    if (!hasProp(value, "customer")) {
      return { success: false };
    }
    const parsed_customer = (() => {
      return ((value: unknown): { success: false } | { success: true; value: GaContextCustomer | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                customerId: number;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "customerId")) {
            return { success: false };
          }
          const parsed_customerId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["customerId"]);
          })();
          if (!parsed_customerId.success) {
            return { success: false };
          }
          return { success: true, value: { customerId: parsed_customerId.value } };
        })(value);
      })(value["customer"]);
    })();
    if (!parsed_customer.success) {
      return { success: false };
    }
    if (!hasProp(value, "flags")) {
      return { success: false };
    }
    const parsed_flags = (() => {
      return ((value: unknown): { success: false } | { success: true; value: GaContextFlags } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                beta: boolean;
                loggedIn: boolean;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "beta")) {
            return { success: false };
          }
          const parsed_beta = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["beta"]);
          })();
          if (!parsed_beta.success) {
            return { success: false };
          }
          if (!hasProp(value, "loggedIn")) {
            return { success: false };
          }
          const parsed_loggedIn = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["loggedIn"]);
          })();
          if (!parsed_loggedIn.success) {
            return { success: false };
          }
          return { success: true, value: { beta: parsed_beta.value, loggedIn: parsed_loggedIn.value } };
        })(value);
      })(value["flags"]);
    })();
    if (!parsed_flags.success) {
      return { success: false };
    }
    return { success: true, value: { asp: parsed_asp.value, currentLanguage: parsed_currentLanguage.value, customer: parsed_customer.value, flags: parsed_flags.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// GaContextFlags
export function serialize_GaContextFlags(obj: GaContextFlags): string {
  const serialized = ((value: { beta: boolean; loggedIn: boolean }): unknown => {
    return {
      beta: ((value: boolean): unknown => {
        return value;
      })(value["beta"]),
      loggedIn: ((value: boolean): unknown => {
        return value;
      })(value["loggedIn"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_GaContextFlags(str: string): GaContextFlags | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          beta: boolean;
          loggedIn: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "beta")) {
      return { success: false };
    }
    const parsed_beta = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["beta"]);
    })();
    if (!parsed_beta.success) {
      return { success: false };
    }
    if (!hasProp(value, "loggedIn")) {
      return { success: false };
    }
    const parsed_loggedIn = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["loggedIn"]);
    })();
    if (!parsed_loggedIn.success) {
      return { success: false };
    }
    return { success: true, value: { beta: parsed_beta.value, loggedIn: parsed_loggedIn.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// Gender
export function serialize_Gender(obj: Gender): string {
  const serialized = ((value: "FEMALE" | "MALE" | "UNKNOWN" | "CUSTOM"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_Gender(str: string): Gender | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "FEMALE" | "MALE" | "UNKNOWN" | "CUSTOM" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "FEMALE":
        return { success: true, value: "FEMALE" };
      case "MALE":
        return { success: true, value: "MALE" };
      case "UNKNOWN":
        return { success: true, value: "UNKNOWN" };
      case "CUSTOM":
        return { success: true, value: "CUSTOM" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// GiftCodeStatus
export function serialize_GiftCodeStatus(obj: GiftCodeStatus): string {
  const serialized = ((value: "AVAILABLE" | "UNAVAILABLE"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_GiftCodeStatus(str: string): GiftCodeStatus | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "AVAILABLE" | "UNAVAILABLE" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "AVAILABLE":
        return { success: true, value: "AVAILABLE" };
      case "UNAVAILABLE":
        return { success: true, value: "UNAVAILABLE" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// GiftDonorSubscription
export function serialize_GiftDonorSubscription(obj: GiftDonorSubscription): string {
  const serialized = ((value: { donorCustomerId: number; donorSubscriptionId: number }): unknown => {
    return {
      donorCustomerId: ((value: number): unknown => {
        return value;
      })(value["donorCustomerId"]),
      donorSubscriptionId: ((value: number): unknown => {
        return value;
      })(value["donorSubscriptionId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_GiftDonorSubscription(str: string): GiftDonorSubscription | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          donorCustomerId: number;
          donorSubscriptionId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "donorCustomerId")) {
      return { success: false };
    }
    const parsed_donorCustomerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["donorCustomerId"]);
    })();
    if (!parsed_donorCustomerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "donorSubscriptionId")) {
      return { success: false };
    }
    const parsed_donorSubscriptionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["donorSubscriptionId"]);
    })();
    if (!parsed_donorSubscriptionId.success) {
      return { success: false };
    }
    return { success: true, value: { donorCustomerId: parsed_donorCustomerId.value, donorSubscriptionId: parsed_donorSubscriptionId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// GiftReceiverSubscription
export function serialize_GiftReceiverSubscription(obj: GiftReceiverSubscription): string {
  const serialized = ((value: { receiverCustomerId: number; receiverSubscriptionId: number }): unknown => {
    return {
      receiverCustomerId: ((value: number): unknown => {
        return value;
      })(value["receiverCustomerId"]),
      receiverSubscriptionId: ((value: number): unknown => {
        return value;
      })(value["receiverSubscriptionId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_GiftReceiverSubscription(str: string): GiftReceiverSubscription | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          receiverCustomerId: number;
          receiverSubscriptionId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "receiverCustomerId")) {
      return { success: false };
    }
    const parsed_receiverCustomerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["receiverCustomerId"]);
    })();
    if (!parsed_receiverCustomerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "receiverSubscriptionId")) {
      return { success: false };
    }
    const parsed_receiverSubscriptionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["receiverSubscriptionId"]);
    })();
    if (!parsed_receiverSubscriptionId.success) {
      return { success: false };
    }
    return { success: true, value: { receiverCustomerId: parsed_receiverCustomerId.value, receiverSubscriptionId: parsed_receiverSubscriptionId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// GiftSubscription
export function serialize_GiftSubscription(obj: GiftSubscription): string {
  const serialized = ((value: { donorSubscription: GiftDonorSubscription | null; giftReceiverSubscription: GiftReceiverSubscription | null }): unknown => {
    return {
      donorSubscription: ((
        value: {
          donorCustomerId: number;
          donorSubscriptionId: number;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          donorCustomerId: ((value: number): unknown => {
            return value;
          })(value["donorCustomerId"]),
          donorSubscriptionId: ((value: number): unknown => {
            return value;
          })(value["donorSubscriptionId"]),
        };
      })(value["donorSubscription"]),
      giftReceiverSubscription: ((
        value: {
          receiverCustomerId: number;
          receiverSubscriptionId: number;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          receiverCustomerId: ((value: number): unknown => {
            return value;
          })(value["receiverCustomerId"]),
          receiverSubscriptionId: ((value: number): unknown => {
            return value;
          })(value["receiverSubscriptionId"]),
        };
      })(value["giftReceiverSubscription"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_GiftSubscription(str: string): GiftSubscription | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          donorSubscription: GiftDonorSubscription | null;
          giftReceiverSubscription: GiftReceiverSubscription | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "donorSubscription")) {
      return { success: false };
    }
    const parsed_donorSubscription = (() => {
      return ((value: unknown): { success: false } | { success: true; value: GiftDonorSubscription | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                donorCustomerId: number;
                donorSubscriptionId: number;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "donorCustomerId")) {
            return { success: false };
          }
          const parsed_donorCustomerId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["donorCustomerId"]);
          })();
          if (!parsed_donorCustomerId.success) {
            return { success: false };
          }
          if (!hasProp(value, "donorSubscriptionId")) {
            return { success: false };
          }
          const parsed_donorSubscriptionId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["donorSubscriptionId"]);
          })();
          if (!parsed_donorSubscriptionId.success) {
            return { success: false };
          }
          return { success: true, value: { donorCustomerId: parsed_donorCustomerId.value, donorSubscriptionId: parsed_donorSubscriptionId.value } };
        })(value);
      })(value["donorSubscription"]);
    })();
    if (!parsed_donorSubscription.success) {
      return { success: false };
    }
    if (!hasProp(value, "giftReceiverSubscription")) {
      return { success: false };
    }
    const parsed_giftReceiverSubscription = (() => {
      return ((value: unknown): { success: false } | { success: true; value: GiftReceiverSubscription | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                receiverCustomerId: number;
                receiverSubscriptionId: number;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "receiverCustomerId")) {
            return { success: false };
          }
          const parsed_receiverCustomerId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["receiverCustomerId"]);
          })();
          if (!parsed_receiverCustomerId.success) {
            return { success: false };
          }
          if (!hasProp(value, "receiverSubscriptionId")) {
            return { success: false };
          }
          const parsed_receiverSubscriptionId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["receiverSubscriptionId"]);
          })();
          if (!parsed_receiverSubscriptionId.success) {
            return { success: false };
          }
          return { success: true, value: { receiverCustomerId: parsed_receiverCustomerId.value, receiverSubscriptionId: parsed_receiverSubscriptionId.value } };
        })(value);
      })(value["giftReceiverSubscription"]);
    })();
    if (!parsed_giftReceiverSubscription.success) {
      return { success: false };
    }
    return { success: true, value: { donorSubscription: parsed_donorSubscription.value, giftReceiverSubscription: parsed_giftReceiverSubscription.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// Invoice
export function serialize_Invoice(obj: Invoice): string {
  const serialized = ((value: { generatedAt: DateTime<true>; invoiceId: number; invoiceLevel: number; invoiceUri: string | null; pdfCounter: number; transactionId: number }): unknown => {
    return {
      generatedAt: ((value: DateTime<true>): unknown => {
        return value.toMillis();
      })(value["generatedAt"]),
      invoiceId: ((value: number): unknown => {
        return value;
      })(value["invoiceId"]),
      invoiceLevel: ((value: number): unknown => {
        return value;
      })(value["invoiceLevel"]),
      invoiceUri: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["invoiceUri"]),
      pdfCounter: ((value: number): unknown => {
        return value;
      })(value["pdfCounter"]),
      transactionId: ((value: number): unknown => {
        return value;
      })(value["transactionId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_Invoice(str: string): Invoice | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          generatedAt: DateTime<true>;
          invoiceId: number;
          invoiceLevel: number;
          invoiceUri: string | null;
          pdfCounter: number;
          transactionId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "generatedAt")) {
      return { success: false };
    }
    const parsed_generatedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["generatedAt"]);
    })();
    if (!parsed_generatedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "invoiceId")) {
      return { success: false };
    }
    const parsed_invoiceId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["invoiceId"]);
    })();
    if (!parsed_invoiceId.success) {
      return { success: false };
    }
    if (!hasProp(value, "invoiceLevel")) {
      return { success: false };
    }
    const parsed_invoiceLevel = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["invoiceLevel"]);
    })();
    if (!parsed_invoiceLevel.success) {
      return { success: false };
    }
    if (!hasProp(value, "invoiceUri")) {
      return { success: false };
    }
    const parsed_invoiceUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["invoiceUri"]);
    })();
    if (!parsed_invoiceUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "pdfCounter")) {
      return { success: false };
    }
    const parsed_pdfCounter = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pdfCounter"]);
    })();
    if (!parsed_pdfCounter.success) {
      return { success: false };
    }
    if (!hasProp(value, "transactionId")) {
      return { success: false };
    }
    const parsed_transactionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["transactionId"]);
    })();
    if (!parsed_transactionId.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        generatedAt: parsed_generatedAt.value,
        invoiceId: parsed_invoiceId.value,
        invoiceLevel: parsed_invoiceLevel.value,
        invoiceUri: parsed_invoiceUri.value,
        pdfCounter: parsed_pdfCounter.value,
        transactionId: parsed_transactionId.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// InvoicePaymentView
export function serialize_InvoicePaymentView(obj: InvoicePaymentView): string {
  const serialized = ((value: { paidAt: DateTime<true> | null; reference: string | null; transactionId: number }): unknown => {
    return {
      paidAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["paidAt"]),
      reference: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["reference"]),
      transactionId: ((value: number): unknown => {
        return value;
      })(value["transactionId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_InvoicePaymentView(str: string): InvoicePaymentView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          paidAt: DateTime<true> | null;
          reference: string | null;
          transactionId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "paidAt")) {
      return { success: false };
    }
    const parsed_paidAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["paidAt"]);
    })();
    if (!parsed_paidAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "reference")) {
      return { success: false };
    }
    const parsed_reference = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["reference"]);
    })();
    if (!parsed_reference.success) {
      return { success: false };
    }
    if (!hasProp(value, "transactionId")) {
      return { success: false };
    }
    const parsed_transactionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["transactionId"]);
    })();
    if (!parsed_transactionId.success) {
      return { success: false };
    }
    return { success: true, value: { paidAt: parsed_paidAt.value, reference: parsed_reference.value, transactionId: parsed_transactionId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// IosPaymentView
export function serialize_IosPaymentView(obj: IosPaymentView): string {
  const serialized = ((value: {
    appStoreProductId: string;
    autoRenewProductId: string | null;
    autoRenewing: boolean;
    expirationIntent: number;
    itunesTransactionId: string;
    originalItunesTransactionId: string | null;
    testPurchase: boolean;
    transactionId: number;
    webOrderLineItemId: bigint;
  }): unknown => {
    return {
      appStoreProductId: ((value: string): unknown => {
        return value;
      })(value["appStoreProductId"]),
      autoRenewProductId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["autoRenewProductId"]),
      autoRenewing: ((value: boolean): unknown => {
        return value;
      })(value["autoRenewing"]),
      expirationIntent: ((value: number): unknown => {
        return value;
      })(value["expirationIntent"]),
      itunesTransactionId: ((value: string): unknown => {
        return value;
      })(value["itunesTransactionId"]),
      originalItunesTransactionId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["originalItunesTransactionId"]),
      testPurchase: ((value: boolean): unknown => {
        return value;
      })(value["testPurchase"]),
      transactionId: ((value: number): unknown => {
        return value;
      })(value["transactionId"]),
      webOrderLineItemId: ((value: bigint): unknown => {
        return value;
      })(value["webOrderLineItemId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_IosPaymentView(str: string): IosPaymentView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          appStoreProductId: string;
          autoRenewProductId: string | null;
          autoRenewing: boolean;
          expirationIntent: number;
          itunesTransactionId: string;
          originalItunesTransactionId: string | null;
          testPurchase: boolean;
          transactionId: number;
          webOrderLineItemId: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "appStoreProductId")) {
      return { success: false };
    }
    const parsed_appStoreProductId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["appStoreProductId"]);
    })();
    if (!parsed_appStoreProductId.success) {
      return { success: false };
    }
    if (!hasProp(value, "autoRenewProductId")) {
      return { success: false };
    }
    const parsed_autoRenewProductId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["autoRenewProductId"]);
    })();
    if (!parsed_autoRenewProductId.success) {
      return { success: false };
    }
    if (!hasProp(value, "autoRenewing")) {
      return { success: false };
    }
    const parsed_autoRenewing = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["autoRenewing"]);
    })();
    if (!parsed_autoRenewing.success) {
      return { success: false };
    }
    if (!hasProp(value, "expirationIntent")) {
      return { success: false };
    }
    const parsed_expirationIntent = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["expirationIntent"]);
    })();
    if (!parsed_expirationIntent.success) {
      return { success: false };
    }
    if (!hasProp(value, "itunesTransactionId")) {
      return { success: false };
    }
    const parsed_itunesTransactionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["itunesTransactionId"]);
    })();
    if (!parsed_itunesTransactionId.success) {
      return { success: false };
    }
    if (!hasProp(value, "originalItunesTransactionId")) {
      return { success: false };
    }
    const parsed_originalItunesTransactionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["originalItunesTransactionId"]);
    })();
    if (!parsed_originalItunesTransactionId.success) {
      return { success: false };
    }
    if (!hasProp(value, "testPurchase")) {
      return { success: false };
    }
    const parsed_testPurchase = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["testPurchase"]);
    })();
    if (!parsed_testPurchase.success) {
      return { success: false };
    }
    if (!hasProp(value, "transactionId")) {
      return { success: false };
    }
    const parsed_transactionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["transactionId"]);
    })();
    if (!parsed_transactionId.success) {
      return { success: false };
    }
    if (!hasProp(value, "webOrderLineItemId")) {
      return { success: false };
    }
    const parsed_webOrderLineItemId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["webOrderLineItemId"]);
    })();
    if (!parsed_webOrderLineItemId.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        appStoreProductId: parsed_appStoreProductId.value,
        autoRenewProductId: parsed_autoRenewProductId.value,
        autoRenewing: parsed_autoRenewing.value,
        expirationIntent: parsed_expirationIntent.value,
        itunesTransactionId: parsed_itunesTransactionId.value,
        originalItunesTransactionId: parsed_originalItunesTransactionId.value,
        testPurchase: parsed_testPurchase.value,
        transactionId: parsed_transactionId.value,
        webOrderLineItemId: parsed_webOrderLineItemId.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ItemLayout
export function serialize_ItemLayout(obj: ItemLayout): string {
  const serialized = ((value: "MINI" | "REGULAR"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ItemLayout(str: string): ItemLayout | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "MINI" | "REGULAR" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "MINI":
        return { success: true, value: "MINI" };
      case "REGULAR":
        return { success: true, value: "REGULAR" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// KindChannel
export function serialize_KindChannel(obj: KindChannel): string {
  const serialized = ((value: "channel"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_KindChannel(str: string): KindChannel | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "channel" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "channel":
        return { success: true, value: "channel" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// KindClassification
export function serialize_KindClassification(obj: KindClassification): string {
  const serialized = ((value: "classification"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_KindClassification(str: string): KindClassification | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "classification" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "classification":
        return { success: true, value: "classification" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// KindDynamicBoolean
export function serialize_KindDynamicBoolean(obj: KindDynamicBoolean): string {
  const serialized = ((value: "dynamicBoolean"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_KindDynamicBoolean(str: string): KindDynamicBoolean | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "dynamicBoolean":
        return { success: true, value: "dynamicBoolean" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// KindDynamicNumber
export function serialize_KindDynamicNumber(obj: KindDynamicNumber): string {
  const serialized = ((value: "dynamicNumber"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_KindDynamicNumber(str: string): KindDynamicNumber | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "dynamicNumber":
        return { success: true, value: "dynamicNumber" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// KindDynamicString
export function serialize_KindDynamicString(obj: KindDynamicString): string {
  const serialized = ((value: "dynamicString"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_KindDynamicString(str: string): KindDynamicString | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "dynamicString":
        return { success: true, value: "dynamicString" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// KindDynamicVoid
export function serialize_KindDynamicVoid(obj: KindDynamicVoid): string {
  const serialized = ((value: "dynamicVoid"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_KindDynamicVoid(str: string): KindDynamicVoid | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "dynamicVoid":
        return { success: true, value: "dynamicVoid" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// KindKeyQuality
export function serialize_KindKeyQuality(obj: KindKeyQuality): string {
  const serialized = ((value: "key-quality"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_KindKeyQuality(str: string): KindKeyQuality | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "key-quality":
        return { success: true, value: "key-quality" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// KindPrimarySkill
export function serialize_KindPrimarySkill(obj: KindPrimarySkill): string {
  const serialized = ((value: "primary-skill"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_KindPrimarySkill(str: string): KindPrimarySkill | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "primary-skill":
        return { success: true, value: "primary-skill" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// KindSkill
export function serialize_KindSkill(obj: KindSkill): string {
  const serialized = ((value: "skill"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_KindSkill(str: string): KindSkill | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "skill" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "skill":
        return { success: true, value: "skill" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// Language
export function serialize_Language(obj: Language): string {
  const serialized = ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_Language(str: string): Language | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "en":
        return { success: true, value: "en" };
      case "de":
        return { success: true, value: "de" };
      case "es":
        return { success: true, value: "es" };
      case "ru":
        return { success: true, value: "ru" };
      case "zh":
        return { success: true, value: "zh" };
      case "pt":
        return { success: true, value: "pt" };
      case "fr":
        return { success: true, value: "fr" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LeadCreationResponse
export function serialize_LeadCreationResponse(obj: LeadCreationResponse): string {
  const serialized = ((value: { kind: "ERROR"; validationErrorReasons: ValidationErrorReasons } | { kind: "MEETING"; calendlyUrl: string } | { kind: "SUCCESS" }): unknown => {
    switch (value.kind) {
      case "ERROR": {
        return {
          kind: "ERROR",
          validationErrorReasons: ((value: { fields: { additionalProperties: Record<string, string[]> }; general: string[] }): unknown => {
            return {
              fields: ((value: { additionalProperties: Record<string, string[]> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string[]): unknown => {
                          return value.map((value: string): unknown => {
                            return value;
                          });
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["fields"]),
              general: ((value: string[]): unknown => {
                return value.map((value: string): unknown => {
                  return value;
                });
              })(value["general"]),
            };
          })(value["validationErrorReasons"]),
        };
      }
      case "MEETING": {
        return {
          kind: "MEETING",
          calendlyUrl: ((value: string): unknown => {
            return value;
          })(value["calendlyUrl"]),
        };
      }
      case "SUCCESS": {
        return { kind: "SUCCESS" };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LeadCreationResponse(str: string): LeadCreationResponse | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: { kind: "ERROR"; validationErrorReasons: ValidationErrorReasons } | { kind: "MEETING"; calendlyUrl: string } | { kind: "SUCCESS" } } => {
    return ((value: unknown): { success: false } | { success: true; value: { kind: "ERROR"; validationErrorReasons: ValidationErrorReasons } | { kind: "MEETING"; calendlyUrl: string } | { kind: "SUCCESS" } } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "kind")) {
        return { success: false };
      }
      if (typeof value["kind"] != "string") {
        return { success: false };
      }
      const discriminator = value["kind"];
      switch (discriminator) {
        case "ERROR": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  validationErrorReasons: ValidationErrorReasons;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "validationErrorReasons")) {
              return { success: false };
            }
            const parsed_validationErrorReasons = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ValidationErrorReasons } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        fields: { additionalProperties: Record<string, string[]> };
                        general: string[];
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "fields")) {
                    return { success: false };
                  }
                  const parsed_fields = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string[]> } } => {
                      if (typeof value !== "object") {
                        return { success: false };
                      }
                      if (value === null) {
                        return { success: false };
                      }
                      const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string[]> } => {
                        const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string[] }] => {
                          return [
                            key,
                            ((value: unknown): { success: false } | { success: true; value: string[] } => {
                              if (!isArray(value)) {
                                return { success: false };
                              }
                              const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              });
                              if (parsed.find((value) => !value.success) !== undefined) {
                                return { success: false };
                              }
                              return {
                                success: true,
                                value: parsed.map((entry) => {
                                  assert(entry.success == true);
                                  return entry.value;
                                }),
                              };
                            })(value),
                          ];
                        });
                        if (parsed.find(([_, value]) => !value.success) !== undefined) {
                          return { success: false };
                        }
                        return {
                          success: true,
                          value: Object.fromEntries(
                            parsed.map(([key, value]) => {
                              assert(value.success);
                              return [key, value.value];
                            }),
                          ),
                        };
                      })();
                      if (!parsed_additionalProperties.success) {
                        return { success: false };
                      }
                      return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
                    })(value["fields"]);
                  })();
                  if (!parsed_fields.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "general")) {
                    return { success: false };
                  }
                  const parsed_general = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                      if (!isArray(value)) {
                        return { success: false };
                      }
                      const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      });
                      if (parsed.find((value) => !value.success) !== undefined) {
                        return { success: false };
                      }
                      return {
                        success: true,
                        value: parsed.map((entry) => {
                          assert(entry.success == true);
                          return entry.value;
                        }),
                      };
                    })(value["general"]);
                  })();
                  if (!parsed_general.success) {
                    return { success: false };
                  }
                  return { success: true, value: { fields: parsed_fields.value, general: parsed_general.value } };
                })(value);
              })(value["validationErrorReasons"]);
            })();
            if (!parsed_validationErrorReasons.success) {
              return { success: false };
            }
            return { success: true, value: { validationErrorReasons: parsed_validationErrorReasons.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "ERROR" as const, ...parsedVariant.value } };
        }
        case "MEETING": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  calendlyUrl: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "calendlyUrl")) {
              return { success: false };
            }
            const parsed_calendlyUrl = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["calendlyUrl"]);
            })();
            if (!parsed_calendlyUrl.success) {
              return { success: false };
            }
            return { success: true, value: { calendlyUrl: parsed_calendlyUrl.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "MEETING" as const, ...parsedVariant.value } };
        }
        case "SUCCESS": {
          const parsedVariant = ((value: unknown): { success: false } | { success: true; value: Record<string, never> } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            return { success: true, value: {} };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "SUCCESS" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LeadForm
export function serialize_LeadForm(obj: LeadForm): string {
  const serialized = ((value: {
    comment: string;
    company: string;
    companySize: LeadFormCompanySize;
    country: string;
    email: string;
    firstName: string;
    function: string;
    gRecaptchaResponse: string;
    howDidYouFindUs: string;
    inquiry: LeadFormInquiry;
    lastName: string;
    leadSource: LeadSource;
    phone: string;
    utmCampaign: string;
    utmContent: string;
    utmId: string;
    utmMedium: string;
    utmSource: string;
    utmTerm: string;
  }): unknown => {
    return {
      comment: ((value: string): unknown => {
        return value;
      })(value["comment"]),
      company: ((value: string): unknown => {
        return value;
      })(value["company"]),
      companySize: ((value: "PLEASE_CHOOSE" | "1_10" | "11_100" | "101_500" | "501_1000" | "1001_5000" | "5000PLUS"): unknown => {
        return value;
      })(value["companySize"]),
      country: ((value: string): unknown => {
        return value;
      })(value["country"]),
      email: ((value: string): unknown => {
        return value;
      })(value["email"]),
      firstName: ((value: string): unknown => {
        return value;
      })(value["firstName"]),
      function: ((value: string): unknown => {
        return value;
      })(value["function"]),
      gRecaptchaResponse: ((value: string): unknown => {
        return value;
      })(value["gRecaptchaResponse"]),
      howDidYouFindUs: ((value: string): unknown => {
        return value;
      })(value["howDidYouFindUs"]),
      inquiry: ((value: "PLEASE_CHOOSE" | "FOR_ENTERPRISE" | "FOR_INDIVIDUALS" | "FOR_TEAMS"): unknown => {
        return value;
      })(value["inquiry"]),
      lastName: ((value: string): unknown => {
        return value;
      })(value["lastName"]),
      leadSource: ((
        value:
          | "RENEWAL_FAILED"
          | "CANCELATION"
          | "CONTACTFORM"
          | "MANUAL"
          | "TRIAL_REQUEST"
          | "WHITE_PAPER_DOWNLOAD"
          | "FREE_ABSTRACTS"
          | "PURCHASE_FAILED"
          | "PURCHASE_FAILED_OLD"
          | "ACTIONABLE_LANDING_PAGE"
          | "CUSTOMER_STORIES_LANDING_PAGE"
          | "SUPPORT_FORM"
          | "LEADERSFUTURE_LANDING_PAGE"
          | "TEAMDEVELOPMENT_LANDING_PAGE"
          | "EMPLOYEEENGAGEMENT_LANDING_PAGE"
          | "EMPLOYEETRAINING_LANDING_PAGE"
          | "CORPORATELEARNING_LANDING_PAGE"
          | "LEARNINGDEVELOPMENT_LANDING_PAGE"
          | "MICROLEARNING_LANDING_PAGE"
          | "SKILLSOFT_LANDING_PAGE"
          | "STROER_LANDING_PAGE"
          | "LMS_LANDINGPAGE"
          | "MICROLEARNING_LANDINGPAGE"
          | "ENTERPRISE_PRODUCTS"
          | "SOLUTIONS_PAGE"
          | "SOLUTIONS_UPSKILLING"
          | "SOLUTIONS_DEI"
          | "SOLUTIONS_HEALTH_WELLBEING"
          | "SOLUTIONS_LEARNING_PLATFORM_STRATEGY"
          | "SOLUTIONS_TALENT_DEVELOPMENT"
          | "SOLUTIONS_COLLABORATIVE_LEARNING"
          | "SOLUTIONS_LEARNING_RESOURCE_LIBRARY"
          | "SOLUTIONS_EFFECTIVE_LEADERS"
          | "WELCOME_PAGE"
          | "BUSINESS_BOOK_SUMMARY_LANDINGPAGE",
      ): unknown => {
        return value;
      })(value["leadSource"]),
      phone: ((value: string): unknown => {
        return value;
      })(value["phone"]),
      utmCampaign: ((value: string): unknown => {
        return value;
      })(value["utmCampaign"]),
      utmContent: ((value: string): unknown => {
        return value;
      })(value["utmContent"]),
      utmId: ((value: string): unknown => {
        return value;
      })(value["utmId"]),
      utmMedium: ((value: string): unknown => {
        return value;
      })(value["utmMedium"]),
      utmSource: ((value: string): unknown => {
        return value;
      })(value["utmSource"]),
      utmTerm: ((value: string): unknown => {
        return value;
      })(value["utmTerm"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LeadForm(str: string): LeadForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          comment: string;
          company: string;
          companySize: LeadFormCompanySize;
          country: string;
          email: string;
          firstName: string;
          function: string;
          gRecaptchaResponse: string;
          howDidYouFindUs: string;
          inquiry: LeadFormInquiry;
          lastName: string;
          leadSource: LeadSource;
          phone: string;
          utmCampaign: string;
          utmContent: string;
          utmId: string;
          utmMedium: string;
          utmSource: string;
          utmTerm: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "comment")) {
      return { success: false };
    }
    const parsed_comment = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["comment"]);
    })();
    if (!parsed_comment.success) {
      return { success: false };
    }
    if (!hasProp(value, "company")) {
      return { success: false };
    }
    const parsed_company = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["company"]);
    })();
    if (!parsed_company.success) {
      return { success: false };
    }
    if (!hasProp(value, "companySize")) {
      return { success: false };
    }
    const parsed_companySize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: LeadFormCompanySize } => {
        return ((value: unknown): { success: false } | { success: true; value: "PLEASE_CHOOSE" | "1_10" | "11_100" | "101_500" | "501_1000" | "1001_5000" | "5000PLUS" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "PLEASE_CHOOSE":
              return { success: true, value: "PLEASE_CHOOSE" };
            case "1_10":
              return { success: true, value: "1_10" };
            case "11_100":
              return { success: true, value: "11_100" };
            case "101_500":
              return { success: true, value: "101_500" };
            case "501_1000":
              return { success: true, value: "501_1000" };
            case "1001_5000":
              return { success: true, value: "1001_5000" };
            case "5000PLUS":
              return { success: true, value: "5000PLUS" };
            default:
              return { success: false };
          }
        })(value);
      })(value["companySize"]);
    })();
    if (!parsed_companySize.success) {
      return { success: false };
    }
    if (!hasProp(value, "country")) {
      return { success: false };
    }
    const parsed_country = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["country"]);
    })();
    if (!parsed_country.success) {
      return { success: false };
    }
    if (!hasProp(value, "email")) {
      return { success: false };
    }
    const parsed_email = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["email"]);
    })();
    if (!parsed_email.success) {
      return { success: false };
    }
    if (!hasProp(value, "firstName")) {
      return { success: false };
    }
    const parsed_firstName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["firstName"]);
    })();
    if (!parsed_firstName.success) {
      return { success: false };
    }
    if (!hasProp(value, "function")) {
      return { success: false };
    }
    const parsed_function = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["function"]);
    })();
    if (!parsed_function.success) {
      return { success: false };
    }
    if (!hasProp(value, "gRecaptchaResponse")) {
      return { success: false };
    }
    const parsed_gRecaptchaResponse = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["gRecaptchaResponse"]);
    })();
    if (!parsed_gRecaptchaResponse.success) {
      return { success: false };
    }
    if (!hasProp(value, "howDidYouFindUs")) {
      return { success: false };
    }
    const parsed_howDidYouFindUs = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["howDidYouFindUs"]);
    })();
    if (!parsed_howDidYouFindUs.success) {
      return { success: false };
    }
    if (!hasProp(value, "inquiry")) {
      return { success: false };
    }
    const parsed_inquiry = (() => {
      return ((value: unknown): { success: false } | { success: true; value: LeadFormInquiry } => {
        return ((value: unknown): { success: false } | { success: true; value: "PLEASE_CHOOSE" | "FOR_ENTERPRISE" | "FOR_INDIVIDUALS" | "FOR_TEAMS" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "PLEASE_CHOOSE":
              return { success: true, value: "PLEASE_CHOOSE" };
            case "FOR_ENTERPRISE":
              return { success: true, value: "FOR_ENTERPRISE" };
            case "FOR_INDIVIDUALS":
              return { success: true, value: "FOR_INDIVIDUALS" };
            case "FOR_TEAMS":
              return { success: true, value: "FOR_TEAMS" };
            default:
              return { success: false };
          }
        })(value);
      })(value["inquiry"]);
    })();
    if (!parsed_inquiry.success) {
      return { success: false };
    }
    if (!hasProp(value, "lastName")) {
      return { success: false };
    }
    const parsed_lastName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lastName"]);
    })();
    if (!parsed_lastName.success) {
      return { success: false };
    }
    if (!hasProp(value, "leadSource")) {
      return { success: false };
    }
    const parsed_leadSource = (() => {
      return ((value: unknown): { success: false } | { success: true; value: LeadSource } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value:
                | "RENEWAL_FAILED"
                | "CANCELATION"
                | "CONTACTFORM"
                | "MANUAL"
                | "TRIAL_REQUEST"
                | "WHITE_PAPER_DOWNLOAD"
                | "FREE_ABSTRACTS"
                | "PURCHASE_FAILED"
                | "PURCHASE_FAILED_OLD"
                | "ACTIONABLE_LANDING_PAGE"
                | "CUSTOMER_STORIES_LANDING_PAGE"
                | "SUPPORT_FORM"
                | "LEADERSFUTURE_LANDING_PAGE"
                | "TEAMDEVELOPMENT_LANDING_PAGE"
                | "EMPLOYEEENGAGEMENT_LANDING_PAGE"
                | "EMPLOYEETRAINING_LANDING_PAGE"
                | "CORPORATELEARNING_LANDING_PAGE"
                | "LEARNINGDEVELOPMENT_LANDING_PAGE"
                | "MICROLEARNING_LANDING_PAGE"
                | "SKILLSOFT_LANDING_PAGE"
                | "STROER_LANDING_PAGE"
                | "LMS_LANDINGPAGE"
                | "MICROLEARNING_LANDINGPAGE"
                | "ENTERPRISE_PRODUCTS"
                | "SOLUTIONS_PAGE"
                | "SOLUTIONS_UPSKILLING"
                | "SOLUTIONS_DEI"
                | "SOLUTIONS_HEALTH_WELLBEING"
                | "SOLUTIONS_LEARNING_PLATFORM_STRATEGY"
                | "SOLUTIONS_TALENT_DEVELOPMENT"
                | "SOLUTIONS_COLLABORATIVE_LEARNING"
                | "SOLUTIONS_LEARNING_RESOURCE_LIBRARY"
                | "SOLUTIONS_EFFECTIVE_LEADERS"
                | "WELCOME_PAGE"
                | "BUSINESS_BOOK_SUMMARY_LANDINGPAGE";
            } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "RENEWAL_FAILED":
              return { success: true, value: "RENEWAL_FAILED" };
            case "CANCELATION":
              return { success: true, value: "CANCELATION" };
            case "CONTACTFORM":
              return { success: true, value: "CONTACTFORM" };
            case "MANUAL":
              return { success: true, value: "MANUAL" };
            case "TRIAL_REQUEST":
              return { success: true, value: "TRIAL_REQUEST" };
            case "WHITE_PAPER_DOWNLOAD":
              return { success: true, value: "WHITE_PAPER_DOWNLOAD" };
            case "FREE_ABSTRACTS":
              return { success: true, value: "FREE_ABSTRACTS" };
            case "PURCHASE_FAILED":
              return { success: true, value: "PURCHASE_FAILED" };
            case "PURCHASE_FAILED_OLD":
              return { success: true, value: "PURCHASE_FAILED_OLD" };
            case "ACTIONABLE_LANDING_PAGE":
              return { success: true, value: "ACTIONABLE_LANDING_PAGE" };
            case "CUSTOMER_STORIES_LANDING_PAGE":
              return { success: true, value: "CUSTOMER_STORIES_LANDING_PAGE" };
            case "SUPPORT_FORM":
              return { success: true, value: "SUPPORT_FORM" };
            case "LEADERSFUTURE_LANDING_PAGE":
              return { success: true, value: "LEADERSFUTURE_LANDING_PAGE" };
            case "TEAMDEVELOPMENT_LANDING_PAGE":
              return { success: true, value: "TEAMDEVELOPMENT_LANDING_PAGE" };
            case "EMPLOYEEENGAGEMENT_LANDING_PAGE":
              return { success: true, value: "EMPLOYEEENGAGEMENT_LANDING_PAGE" };
            case "EMPLOYEETRAINING_LANDING_PAGE":
              return { success: true, value: "EMPLOYEETRAINING_LANDING_PAGE" };
            case "CORPORATELEARNING_LANDING_PAGE":
              return { success: true, value: "CORPORATELEARNING_LANDING_PAGE" };
            case "LEARNINGDEVELOPMENT_LANDING_PAGE":
              return { success: true, value: "LEARNINGDEVELOPMENT_LANDING_PAGE" };
            case "MICROLEARNING_LANDING_PAGE":
              return { success: true, value: "MICROLEARNING_LANDING_PAGE" };
            case "SKILLSOFT_LANDING_PAGE":
              return { success: true, value: "SKILLSOFT_LANDING_PAGE" };
            case "STROER_LANDING_PAGE":
              return { success: true, value: "STROER_LANDING_PAGE" };
            case "LMS_LANDINGPAGE":
              return { success: true, value: "LMS_LANDINGPAGE" };
            case "MICROLEARNING_LANDINGPAGE":
              return { success: true, value: "MICROLEARNING_LANDINGPAGE" };
            case "ENTERPRISE_PRODUCTS":
              return { success: true, value: "ENTERPRISE_PRODUCTS" };
            case "SOLUTIONS_PAGE":
              return { success: true, value: "SOLUTIONS_PAGE" };
            case "SOLUTIONS_UPSKILLING":
              return { success: true, value: "SOLUTIONS_UPSKILLING" };
            case "SOLUTIONS_DEI":
              return { success: true, value: "SOLUTIONS_DEI" };
            case "SOLUTIONS_HEALTH_WELLBEING":
              return { success: true, value: "SOLUTIONS_HEALTH_WELLBEING" };
            case "SOLUTIONS_LEARNING_PLATFORM_STRATEGY":
              return { success: true, value: "SOLUTIONS_LEARNING_PLATFORM_STRATEGY" };
            case "SOLUTIONS_TALENT_DEVELOPMENT":
              return { success: true, value: "SOLUTIONS_TALENT_DEVELOPMENT" };
            case "SOLUTIONS_COLLABORATIVE_LEARNING":
              return { success: true, value: "SOLUTIONS_COLLABORATIVE_LEARNING" };
            case "SOLUTIONS_LEARNING_RESOURCE_LIBRARY":
              return { success: true, value: "SOLUTIONS_LEARNING_RESOURCE_LIBRARY" };
            case "SOLUTIONS_EFFECTIVE_LEADERS":
              return { success: true, value: "SOLUTIONS_EFFECTIVE_LEADERS" };
            case "WELCOME_PAGE":
              return { success: true, value: "WELCOME_PAGE" };
            case "BUSINESS_BOOK_SUMMARY_LANDINGPAGE":
              return { success: true, value: "BUSINESS_BOOK_SUMMARY_LANDINGPAGE" };
            default:
              return { success: false };
          }
        })(value);
      })(value["leadSource"]);
    })();
    if (!parsed_leadSource.success) {
      return { success: false };
    }
    if (!hasProp(value, "phone")) {
      return { success: false };
    }
    const parsed_phone = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["phone"]);
    })();
    if (!parsed_phone.success) {
      return { success: false };
    }
    if (!hasProp(value, "utmCampaign")) {
      return { success: false };
    }
    const parsed_utmCampaign = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["utmCampaign"]);
    })();
    if (!parsed_utmCampaign.success) {
      return { success: false };
    }
    if (!hasProp(value, "utmContent")) {
      return { success: false };
    }
    const parsed_utmContent = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["utmContent"]);
    })();
    if (!parsed_utmContent.success) {
      return { success: false };
    }
    if (!hasProp(value, "utmId")) {
      return { success: false };
    }
    const parsed_utmId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["utmId"]);
    })();
    if (!parsed_utmId.success) {
      return { success: false };
    }
    if (!hasProp(value, "utmMedium")) {
      return { success: false };
    }
    const parsed_utmMedium = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["utmMedium"]);
    })();
    if (!parsed_utmMedium.success) {
      return { success: false };
    }
    if (!hasProp(value, "utmSource")) {
      return { success: false };
    }
    const parsed_utmSource = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["utmSource"]);
    })();
    if (!parsed_utmSource.success) {
      return { success: false };
    }
    if (!hasProp(value, "utmTerm")) {
      return { success: false };
    }
    const parsed_utmTerm = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["utmTerm"]);
    })();
    if (!parsed_utmTerm.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        comment: parsed_comment.value,
        company: parsed_company.value,
        companySize: parsed_companySize.value,
        country: parsed_country.value,
        email: parsed_email.value,
        firstName: parsed_firstName.value,
        function: parsed_function.value,
        gRecaptchaResponse: parsed_gRecaptchaResponse.value,
        howDidYouFindUs: parsed_howDidYouFindUs.value,
        inquiry: parsed_inquiry.value,
        lastName: parsed_lastName.value,
        leadSource: parsed_leadSource.value,
        phone: parsed_phone.value,
        utmCampaign: parsed_utmCampaign.value,
        utmContent: parsed_utmContent.value,
        utmId: parsed_utmId.value,
        utmMedium: parsed_utmMedium.value,
        utmSource: parsed_utmSource.value,
        utmTerm: parsed_utmTerm.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LeadFormCompanySize
export function serialize_LeadFormCompanySize(obj: LeadFormCompanySize): string {
  const serialized = ((value: "PLEASE_CHOOSE" | "1_10" | "11_100" | "101_500" | "501_1000" | "1001_5000" | "5000PLUS"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LeadFormCompanySize(str: string): LeadFormCompanySize | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "PLEASE_CHOOSE" | "1_10" | "11_100" | "101_500" | "501_1000" | "1001_5000" | "5000PLUS" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "PLEASE_CHOOSE":
        return { success: true, value: "PLEASE_CHOOSE" };
      case "1_10":
        return { success: true, value: "1_10" };
      case "11_100":
        return { success: true, value: "11_100" };
      case "101_500":
        return { success: true, value: "101_500" };
      case "501_1000":
        return { success: true, value: "501_1000" };
      case "1001_5000":
        return { success: true, value: "1001_5000" };
      case "5000PLUS":
        return { success: true, value: "5000PLUS" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LeadFormInquiry
export function serialize_LeadFormInquiry(obj: LeadFormInquiry): string {
  const serialized = ((value: "PLEASE_CHOOSE" | "FOR_ENTERPRISE" | "FOR_INDIVIDUALS" | "FOR_TEAMS"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LeadFormInquiry(str: string): LeadFormInquiry | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "PLEASE_CHOOSE" | "FOR_ENTERPRISE" | "FOR_INDIVIDUALS" | "FOR_TEAMS" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "PLEASE_CHOOSE":
        return { success: true, value: "PLEASE_CHOOSE" };
      case "FOR_ENTERPRISE":
        return { success: true, value: "FOR_ENTERPRISE" };
      case "FOR_INDIVIDUALS":
        return { success: true, value: "FOR_INDIVIDUALS" };
      case "FOR_TEAMS":
        return { success: true, value: "FOR_TEAMS" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LeadFormProps
export function serialize_LeadFormProps(obj: LeadFormProps): string {
  const serialized = ((value: { countries: Country[]; country: Country; fullWidth: boolean; lead: string | null; leadSource: LeadSource; title: string | null }): unknown => {
    return {
      countries: ((value: Country[]): unknown => {
        return value.map(
          (value: {
            countryCode: string;
            currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
            names: {
              de: string;
              en: string;
              es: string;
              fr: string;
              pt: string;
              ru: string;
              zh: string;
            };
            phonePrefix: string;
            region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
          }): unknown => {
            return {
              countryCode: ((value: string): unknown => {
                return value;
              })(value["countryCode"]),
              currency: ((value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY"): unknown => {
                return value;
              })(value["currency"]),
              names: ((value: { de: string; en: string; es: string; fr: string; pt: string; ru: string; zh: string }): unknown => {
                return {
                  de: ((value: string): unknown => {
                    return value;
                  })(value["de"]),
                  en: ((value: string): unknown => {
                    return value;
                  })(value["en"]),
                  es: ((value: string): unknown => {
                    return value;
                  })(value["es"]),
                  fr: ((value: string): unknown => {
                    return value;
                  })(value["fr"]),
                  pt: ((value: string): unknown => {
                    return value;
                  })(value["pt"]),
                  ru: ((value: string): unknown => {
                    return value;
                  })(value["ru"]),
                  zh: ((value: string): unknown => {
                    return value;
                  })(value["zh"]),
                };
              })(value["names"]),
              phonePrefix: ((value: string): unknown => {
                return value;
              })(value["phonePrefix"]),
              region: ((value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA"): unknown => {
                return value;
              })(value["region"]),
            };
          },
        );
      })(value["countries"]),
      country: ((value: {
        countryCode: string;
        currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
        names: {
          de: string;
          en: string;
          es: string;
          fr: string;
          pt: string;
          ru: string;
          zh: string;
        };
        phonePrefix: string;
        region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
      }): unknown => {
        return {
          countryCode: ((value: string): unknown => {
            return value;
          })(value["countryCode"]),
          currency: ((value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY"): unknown => {
            return value;
          })(value["currency"]),
          names: ((value: { de: string; en: string; es: string; fr: string; pt: string; ru: string; zh: string }): unknown => {
            return {
              de: ((value: string): unknown => {
                return value;
              })(value["de"]),
              en: ((value: string): unknown => {
                return value;
              })(value["en"]),
              es: ((value: string): unknown => {
                return value;
              })(value["es"]),
              fr: ((value: string): unknown => {
                return value;
              })(value["fr"]),
              pt: ((value: string): unknown => {
                return value;
              })(value["pt"]),
              ru: ((value: string): unknown => {
                return value;
              })(value["ru"]),
              zh: ((value: string): unknown => {
                return value;
              })(value["zh"]),
            };
          })(value["names"]),
          phonePrefix: ((value: string): unknown => {
            return value;
          })(value["phonePrefix"]),
          region: ((value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA"): unknown => {
            return value;
          })(value["region"]),
        };
      })(value["country"]),
      fullWidth: ((value: boolean): unknown => {
        return value;
      })(value["fullWidth"]),
      lead: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lead"]),
      leadSource: ((
        value:
          | "RENEWAL_FAILED"
          | "CANCELATION"
          | "CONTACTFORM"
          | "MANUAL"
          | "TRIAL_REQUEST"
          | "WHITE_PAPER_DOWNLOAD"
          | "FREE_ABSTRACTS"
          | "PURCHASE_FAILED"
          | "PURCHASE_FAILED_OLD"
          | "ACTIONABLE_LANDING_PAGE"
          | "CUSTOMER_STORIES_LANDING_PAGE"
          | "SUPPORT_FORM"
          | "LEADERSFUTURE_LANDING_PAGE"
          | "TEAMDEVELOPMENT_LANDING_PAGE"
          | "EMPLOYEEENGAGEMENT_LANDING_PAGE"
          | "EMPLOYEETRAINING_LANDING_PAGE"
          | "CORPORATELEARNING_LANDING_PAGE"
          | "LEARNINGDEVELOPMENT_LANDING_PAGE"
          | "MICROLEARNING_LANDING_PAGE"
          | "SKILLSOFT_LANDING_PAGE"
          | "STROER_LANDING_PAGE"
          | "LMS_LANDINGPAGE"
          | "MICROLEARNING_LANDINGPAGE"
          | "ENTERPRISE_PRODUCTS"
          | "SOLUTIONS_PAGE"
          | "SOLUTIONS_UPSKILLING"
          | "SOLUTIONS_DEI"
          | "SOLUTIONS_HEALTH_WELLBEING"
          | "SOLUTIONS_LEARNING_PLATFORM_STRATEGY"
          | "SOLUTIONS_TALENT_DEVELOPMENT"
          | "SOLUTIONS_COLLABORATIVE_LEARNING"
          | "SOLUTIONS_LEARNING_RESOURCE_LIBRARY"
          | "SOLUTIONS_EFFECTIVE_LEADERS"
          | "WELCOME_PAGE"
          | "BUSINESS_BOOK_SUMMARY_LANDINGPAGE",
      ): unknown => {
        return value;
      })(value["leadSource"]),
      title: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LeadFormProps(str: string): LeadFormProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          countries: Country[];
          country: Country;
          fullWidth: boolean;
          lead: string | null;
          leadSource: LeadSource;
          title: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "countries")) {
      return { success: false };
    }
    const parsed_countries = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Country[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Country } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  countryCode: string;
                  currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                  names: {
                    de: string;
                    en: string;
                    es: string;
                    fr: string;
                    pt: string;
                    ru: string;
                    zh: string;
                  };
                  phonePrefix: string;
                  region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "countryCode")) {
              return { success: false };
            }
            const parsed_countryCode = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["countryCode"]);
            })();
            if (!parsed_countryCode.success) {
              return { success: false };
            }
            if (!hasProp(value, "currency")) {
              return { success: false };
            }
            const parsed_currency = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "CHF":
                    return { success: true, value: "CHF" };
                  case "CNY":
                    return { success: true, value: "CNY" };
                  case "USD":
                    return { success: true, value: "USD" };
                  case "EUR":
                    return { success: true, value: "EUR" };
                  case "GBP":
                    return { success: true, value: "GBP" };
                  case "INR":
                    return { success: true, value: "INR" };
                  case "JPY":
                    return { success: true, value: "JPY" };
                  default:
                    return { success: false };
                }
              })(value["currency"]);
            })();
            if (!parsed_currency.success) {
              return { success: false };
            }
            if (!hasProp(value, "names")) {
              return { success: false };
            }
            const parsed_names = (() => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      de: string;
                      en: string;
                      es: string;
                      fr: string;
                      pt: string;
                      ru: string;
                      zh: string;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "de")) {
                  return { success: false };
                }
                const parsed_de = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["de"]);
                })();
                if (!parsed_de.success) {
                  return { success: false };
                }
                if (!hasProp(value, "en")) {
                  return { success: false };
                }
                const parsed_en = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["en"]);
                })();
                if (!parsed_en.success) {
                  return { success: false };
                }
                if (!hasProp(value, "es")) {
                  return { success: false };
                }
                const parsed_es = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["es"]);
                })();
                if (!parsed_es.success) {
                  return { success: false };
                }
                if (!hasProp(value, "fr")) {
                  return { success: false };
                }
                const parsed_fr = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["fr"]);
                })();
                if (!parsed_fr.success) {
                  return { success: false };
                }
                if (!hasProp(value, "pt")) {
                  return { success: false };
                }
                const parsed_pt = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["pt"]);
                })();
                if (!parsed_pt.success) {
                  return { success: false };
                }
                if (!hasProp(value, "ru")) {
                  return { success: false };
                }
                const parsed_ru = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["ru"]);
                })();
                if (!parsed_ru.success) {
                  return { success: false };
                }
                if (!hasProp(value, "zh")) {
                  return { success: false };
                }
                const parsed_zh = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["zh"]);
                })();
                if (!parsed_zh.success) {
                  return { success: false };
                }
                return { success: true, value: { de: parsed_de.value, en: parsed_en.value, es: parsed_es.value, fr: parsed_fr.value, pt: parsed_pt.value, ru: parsed_ru.value, zh: parsed_zh.value } };
              })(value["names"]);
            })();
            if (!parsed_names.success) {
              return { success: false };
            }
            if (!hasProp(value, "phonePrefix")) {
              return { success: false };
            }
            const parsed_phonePrefix = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["phonePrefix"]);
            })();
            if (!parsed_phonePrefix.success) {
              return { success: false };
            }
            if (!hasProp(value, "region")) {
              return { success: false };
            }
            const parsed_region = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "SOUTHAMERICA":
                    return { success: true, value: "SOUTHAMERICA" };
                  case "EUROPE":
                    return { success: true, value: "EUROPE" };
                  case "OCEANIA":
                    return { success: true, value: "OCEANIA" };
                  case "NORTHAMERICA":
                    return { success: true, value: "NORTHAMERICA" };
                  case "AFRICA":
                    return { success: true, value: "AFRICA" };
                  case "ANTARCTICA":
                    return { success: true, value: "ANTARCTICA" };
                  case "ASIA":
                    return { success: true, value: "ASIA" };
                  default:
                    return { success: false };
                }
              })(value["region"]);
            })();
            if (!parsed_region.success) {
              return { success: false };
            }
            return { success: true, value: { countryCode: parsed_countryCode.value, currency: parsed_currency.value, names: parsed_names.value, phonePrefix: parsed_phonePrefix.value, region: parsed_region.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["countries"]);
    })();
    if (!parsed_countries.success) {
      return { success: false };
    }
    if (!hasProp(value, "country")) {
      return { success: false };
    }
    const parsed_country = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Country } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                countryCode: string;
                currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                names: {
                  de: string;
                  en: string;
                  es: string;
                  fr: string;
                  pt: string;
                  ru: string;
                  zh: string;
                };
                phonePrefix: string;
                region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "countryCode")) {
            return { success: false };
          }
          const parsed_countryCode = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["countryCode"]);
          })();
          if (!parsed_countryCode.success) {
            return { success: false };
          }
          if (!hasProp(value, "currency")) {
            return { success: false };
          }
          const parsed_currency = (() => {
            return ((value: unknown): { success: false } | { success: true; value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY" } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              switch (value) {
                case "CHF":
                  return { success: true, value: "CHF" };
                case "CNY":
                  return { success: true, value: "CNY" };
                case "USD":
                  return { success: true, value: "USD" };
                case "EUR":
                  return { success: true, value: "EUR" };
                case "GBP":
                  return { success: true, value: "GBP" };
                case "INR":
                  return { success: true, value: "INR" };
                case "JPY":
                  return { success: true, value: "JPY" };
                default:
                  return { success: false };
              }
            })(value["currency"]);
          })();
          if (!parsed_currency.success) {
            return { success: false };
          }
          if (!hasProp(value, "names")) {
            return { success: false };
          }
          const parsed_names = (() => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value: {
                    de: string;
                    en: string;
                    es: string;
                    fr: string;
                    pt: string;
                    ru: string;
                    zh: string;
                  };
                } => {
              if (typeof value !== "object") {
                return { success: false };
              }
              if (value === null) {
                return { success: false };
              }
              if (!hasProp(value, "de")) {
                return { success: false };
              }
              const parsed_de = (() => {
                return ((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                })(value["de"]);
              })();
              if (!parsed_de.success) {
                return { success: false };
              }
              if (!hasProp(value, "en")) {
                return { success: false };
              }
              const parsed_en = (() => {
                return ((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                })(value["en"]);
              })();
              if (!parsed_en.success) {
                return { success: false };
              }
              if (!hasProp(value, "es")) {
                return { success: false };
              }
              const parsed_es = (() => {
                return ((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                })(value["es"]);
              })();
              if (!parsed_es.success) {
                return { success: false };
              }
              if (!hasProp(value, "fr")) {
                return { success: false };
              }
              const parsed_fr = (() => {
                return ((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                })(value["fr"]);
              })();
              if (!parsed_fr.success) {
                return { success: false };
              }
              if (!hasProp(value, "pt")) {
                return { success: false };
              }
              const parsed_pt = (() => {
                return ((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                })(value["pt"]);
              })();
              if (!parsed_pt.success) {
                return { success: false };
              }
              if (!hasProp(value, "ru")) {
                return { success: false };
              }
              const parsed_ru = (() => {
                return ((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                })(value["ru"]);
              })();
              if (!parsed_ru.success) {
                return { success: false };
              }
              if (!hasProp(value, "zh")) {
                return { success: false };
              }
              const parsed_zh = (() => {
                return ((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                })(value["zh"]);
              })();
              if (!parsed_zh.success) {
                return { success: false };
              }
              return { success: true, value: { de: parsed_de.value, en: parsed_en.value, es: parsed_es.value, fr: parsed_fr.value, pt: parsed_pt.value, ru: parsed_ru.value, zh: parsed_zh.value } };
            })(value["names"]);
          })();
          if (!parsed_names.success) {
            return { success: false };
          }
          if (!hasProp(value, "phonePrefix")) {
            return { success: false };
          }
          const parsed_phonePrefix = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["phonePrefix"]);
          })();
          if (!parsed_phonePrefix.success) {
            return { success: false };
          }
          if (!hasProp(value, "region")) {
            return { success: false };
          }
          const parsed_region = (() => {
            return ((value: unknown): { success: false } | { success: true; value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA" } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              switch (value) {
                case "SOUTHAMERICA":
                  return { success: true, value: "SOUTHAMERICA" };
                case "EUROPE":
                  return { success: true, value: "EUROPE" };
                case "OCEANIA":
                  return { success: true, value: "OCEANIA" };
                case "NORTHAMERICA":
                  return { success: true, value: "NORTHAMERICA" };
                case "AFRICA":
                  return { success: true, value: "AFRICA" };
                case "ANTARCTICA":
                  return { success: true, value: "ANTARCTICA" };
                case "ASIA":
                  return { success: true, value: "ASIA" };
                default:
                  return { success: false };
              }
            })(value["region"]);
          })();
          if (!parsed_region.success) {
            return { success: false };
          }
          return { success: true, value: { countryCode: parsed_countryCode.value, currency: parsed_currency.value, names: parsed_names.value, phonePrefix: parsed_phonePrefix.value, region: parsed_region.value } };
        })(value);
      })(value["country"]);
    })();
    if (!parsed_country.success) {
      return { success: false };
    }
    if (!hasProp(value, "fullWidth")) {
      return { success: false };
    }
    const parsed_fullWidth = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["fullWidth"]);
    })();
    if (!parsed_fullWidth.success) {
      return { success: false };
    }
    if (!hasProp(value, "lead")) {
      return { success: false };
    }
    const parsed_lead = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lead"]);
    })();
    if (!parsed_lead.success) {
      return { success: false };
    }
    if (!hasProp(value, "leadSource")) {
      return { success: false };
    }
    const parsed_leadSource = (() => {
      return ((value: unknown): { success: false } | { success: true; value: LeadSource } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value:
                | "RENEWAL_FAILED"
                | "CANCELATION"
                | "CONTACTFORM"
                | "MANUAL"
                | "TRIAL_REQUEST"
                | "WHITE_PAPER_DOWNLOAD"
                | "FREE_ABSTRACTS"
                | "PURCHASE_FAILED"
                | "PURCHASE_FAILED_OLD"
                | "ACTIONABLE_LANDING_PAGE"
                | "CUSTOMER_STORIES_LANDING_PAGE"
                | "SUPPORT_FORM"
                | "LEADERSFUTURE_LANDING_PAGE"
                | "TEAMDEVELOPMENT_LANDING_PAGE"
                | "EMPLOYEEENGAGEMENT_LANDING_PAGE"
                | "EMPLOYEETRAINING_LANDING_PAGE"
                | "CORPORATELEARNING_LANDING_PAGE"
                | "LEARNINGDEVELOPMENT_LANDING_PAGE"
                | "MICROLEARNING_LANDING_PAGE"
                | "SKILLSOFT_LANDING_PAGE"
                | "STROER_LANDING_PAGE"
                | "LMS_LANDINGPAGE"
                | "MICROLEARNING_LANDINGPAGE"
                | "ENTERPRISE_PRODUCTS"
                | "SOLUTIONS_PAGE"
                | "SOLUTIONS_UPSKILLING"
                | "SOLUTIONS_DEI"
                | "SOLUTIONS_HEALTH_WELLBEING"
                | "SOLUTIONS_LEARNING_PLATFORM_STRATEGY"
                | "SOLUTIONS_TALENT_DEVELOPMENT"
                | "SOLUTIONS_COLLABORATIVE_LEARNING"
                | "SOLUTIONS_LEARNING_RESOURCE_LIBRARY"
                | "SOLUTIONS_EFFECTIVE_LEADERS"
                | "WELCOME_PAGE"
                | "BUSINESS_BOOK_SUMMARY_LANDINGPAGE";
            } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "RENEWAL_FAILED":
              return { success: true, value: "RENEWAL_FAILED" };
            case "CANCELATION":
              return { success: true, value: "CANCELATION" };
            case "CONTACTFORM":
              return { success: true, value: "CONTACTFORM" };
            case "MANUAL":
              return { success: true, value: "MANUAL" };
            case "TRIAL_REQUEST":
              return { success: true, value: "TRIAL_REQUEST" };
            case "WHITE_PAPER_DOWNLOAD":
              return { success: true, value: "WHITE_PAPER_DOWNLOAD" };
            case "FREE_ABSTRACTS":
              return { success: true, value: "FREE_ABSTRACTS" };
            case "PURCHASE_FAILED":
              return { success: true, value: "PURCHASE_FAILED" };
            case "PURCHASE_FAILED_OLD":
              return { success: true, value: "PURCHASE_FAILED_OLD" };
            case "ACTIONABLE_LANDING_PAGE":
              return { success: true, value: "ACTIONABLE_LANDING_PAGE" };
            case "CUSTOMER_STORIES_LANDING_PAGE":
              return { success: true, value: "CUSTOMER_STORIES_LANDING_PAGE" };
            case "SUPPORT_FORM":
              return { success: true, value: "SUPPORT_FORM" };
            case "LEADERSFUTURE_LANDING_PAGE":
              return { success: true, value: "LEADERSFUTURE_LANDING_PAGE" };
            case "TEAMDEVELOPMENT_LANDING_PAGE":
              return { success: true, value: "TEAMDEVELOPMENT_LANDING_PAGE" };
            case "EMPLOYEEENGAGEMENT_LANDING_PAGE":
              return { success: true, value: "EMPLOYEEENGAGEMENT_LANDING_PAGE" };
            case "EMPLOYEETRAINING_LANDING_PAGE":
              return { success: true, value: "EMPLOYEETRAINING_LANDING_PAGE" };
            case "CORPORATELEARNING_LANDING_PAGE":
              return { success: true, value: "CORPORATELEARNING_LANDING_PAGE" };
            case "LEARNINGDEVELOPMENT_LANDING_PAGE":
              return { success: true, value: "LEARNINGDEVELOPMENT_LANDING_PAGE" };
            case "MICROLEARNING_LANDING_PAGE":
              return { success: true, value: "MICROLEARNING_LANDING_PAGE" };
            case "SKILLSOFT_LANDING_PAGE":
              return { success: true, value: "SKILLSOFT_LANDING_PAGE" };
            case "STROER_LANDING_PAGE":
              return { success: true, value: "STROER_LANDING_PAGE" };
            case "LMS_LANDINGPAGE":
              return { success: true, value: "LMS_LANDINGPAGE" };
            case "MICROLEARNING_LANDINGPAGE":
              return { success: true, value: "MICROLEARNING_LANDINGPAGE" };
            case "ENTERPRISE_PRODUCTS":
              return { success: true, value: "ENTERPRISE_PRODUCTS" };
            case "SOLUTIONS_PAGE":
              return { success: true, value: "SOLUTIONS_PAGE" };
            case "SOLUTIONS_UPSKILLING":
              return { success: true, value: "SOLUTIONS_UPSKILLING" };
            case "SOLUTIONS_DEI":
              return { success: true, value: "SOLUTIONS_DEI" };
            case "SOLUTIONS_HEALTH_WELLBEING":
              return { success: true, value: "SOLUTIONS_HEALTH_WELLBEING" };
            case "SOLUTIONS_LEARNING_PLATFORM_STRATEGY":
              return { success: true, value: "SOLUTIONS_LEARNING_PLATFORM_STRATEGY" };
            case "SOLUTIONS_TALENT_DEVELOPMENT":
              return { success: true, value: "SOLUTIONS_TALENT_DEVELOPMENT" };
            case "SOLUTIONS_COLLABORATIVE_LEARNING":
              return { success: true, value: "SOLUTIONS_COLLABORATIVE_LEARNING" };
            case "SOLUTIONS_LEARNING_RESOURCE_LIBRARY":
              return { success: true, value: "SOLUTIONS_LEARNING_RESOURCE_LIBRARY" };
            case "SOLUTIONS_EFFECTIVE_LEADERS":
              return { success: true, value: "SOLUTIONS_EFFECTIVE_LEADERS" };
            case "WELCOME_PAGE":
              return { success: true, value: "WELCOME_PAGE" };
            case "BUSINESS_BOOK_SUMMARY_LANDINGPAGE":
              return { success: true, value: "BUSINESS_BOOK_SUMMARY_LANDINGPAGE" };
            default:
              return { success: false };
          }
        })(value);
      })(value["leadSource"]);
    })();
    if (!parsed_leadSource.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return { success: true, value: { countries: parsed_countries.value, country: parsed_country.value, fullWidth: parsed_fullWidth.value, lead: parsed_lead.value, leadSource: parsed_leadSource.value, title: parsed_title.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LeadSource
export function serialize_LeadSource(obj: LeadSource): string {
  const serialized = ((
    value:
      | "RENEWAL_FAILED"
      | "CANCELATION"
      | "CONTACTFORM"
      | "MANUAL"
      | "TRIAL_REQUEST"
      | "WHITE_PAPER_DOWNLOAD"
      | "FREE_ABSTRACTS"
      | "PURCHASE_FAILED"
      | "PURCHASE_FAILED_OLD"
      | "ACTIONABLE_LANDING_PAGE"
      | "CUSTOMER_STORIES_LANDING_PAGE"
      | "SUPPORT_FORM"
      | "LEADERSFUTURE_LANDING_PAGE"
      | "TEAMDEVELOPMENT_LANDING_PAGE"
      | "EMPLOYEEENGAGEMENT_LANDING_PAGE"
      | "EMPLOYEETRAINING_LANDING_PAGE"
      | "CORPORATELEARNING_LANDING_PAGE"
      | "LEARNINGDEVELOPMENT_LANDING_PAGE"
      | "MICROLEARNING_LANDING_PAGE"
      | "SKILLSOFT_LANDING_PAGE"
      | "STROER_LANDING_PAGE"
      | "LMS_LANDINGPAGE"
      | "MICROLEARNING_LANDINGPAGE"
      | "ENTERPRISE_PRODUCTS"
      | "SOLUTIONS_PAGE"
      | "SOLUTIONS_UPSKILLING"
      | "SOLUTIONS_DEI"
      | "SOLUTIONS_HEALTH_WELLBEING"
      | "SOLUTIONS_LEARNING_PLATFORM_STRATEGY"
      | "SOLUTIONS_TALENT_DEVELOPMENT"
      | "SOLUTIONS_COLLABORATIVE_LEARNING"
      | "SOLUTIONS_LEARNING_RESOURCE_LIBRARY"
      | "SOLUTIONS_EFFECTIVE_LEADERS"
      | "WELCOME_PAGE"
      | "BUSINESS_BOOK_SUMMARY_LANDINGPAGE",
  ): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LeadSource(str: string): LeadSource | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value:
          | "RENEWAL_FAILED"
          | "CANCELATION"
          | "CONTACTFORM"
          | "MANUAL"
          | "TRIAL_REQUEST"
          | "WHITE_PAPER_DOWNLOAD"
          | "FREE_ABSTRACTS"
          | "PURCHASE_FAILED"
          | "PURCHASE_FAILED_OLD"
          | "ACTIONABLE_LANDING_PAGE"
          | "CUSTOMER_STORIES_LANDING_PAGE"
          | "SUPPORT_FORM"
          | "LEADERSFUTURE_LANDING_PAGE"
          | "TEAMDEVELOPMENT_LANDING_PAGE"
          | "EMPLOYEEENGAGEMENT_LANDING_PAGE"
          | "EMPLOYEETRAINING_LANDING_PAGE"
          | "CORPORATELEARNING_LANDING_PAGE"
          | "LEARNINGDEVELOPMENT_LANDING_PAGE"
          | "MICROLEARNING_LANDING_PAGE"
          | "SKILLSOFT_LANDING_PAGE"
          | "STROER_LANDING_PAGE"
          | "LMS_LANDINGPAGE"
          | "MICROLEARNING_LANDINGPAGE"
          | "ENTERPRISE_PRODUCTS"
          | "SOLUTIONS_PAGE"
          | "SOLUTIONS_UPSKILLING"
          | "SOLUTIONS_DEI"
          | "SOLUTIONS_HEALTH_WELLBEING"
          | "SOLUTIONS_LEARNING_PLATFORM_STRATEGY"
          | "SOLUTIONS_TALENT_DEVELOPMENT"
          | "SOLUTIONS_COLLABORATIVE_LEARNING"
          | "SOLUTIONS_LEARNING_RESOURCE_LIBRARY"
          | "SOLUTIONS_EFFECTIVE_LEADERS"
          | "WELCOME_PAGE"
          | "BUSINESS_BOOK_SUMMARY_LANDINGPAGE";
      } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "RENEWAL_FAILED":
        return { success: true, value: "RENEWAL_FAILED" };
      case "CANCELATION":
        return { success: true, value: "CANCELATION" };
      case "CONTACTFORM":
        return { success: true, value: "CONTACTFORM" };
      case "MANUAL":
        return { success: true, value: "MANUAL" };
      case "TRIAL_REQUEST":
        return { success: true, value: "TRIAL_REQUEST" };
      case "WHITE_PAPER_DOWNLOAD":
        return { success: true, value: "WHITE_PAPER_DOWNLOAD" };
      case "FREE_ABSTRACTS":
        return { success: true, value: "FREE_ABSTRACTS" };
      case "PURCHASE_FAILED":
        return { success: true, value: "PURCHASE_FAILED" };
      case "PURCHASE_FAILED_OLD":
        return { success: true, value: "PURCHASE_FAILED_OLD" };
      case "ACTIONABLE_LANDING_PAGE":
        return { success: true, value: "ACTIONABLE_LANDING_PAGE" };
      case "CUSTOMER_STORIES_LANDING_PAGE":
        return { success: true, value: "CUSTOMER_STORIES_LANDING_PAGE" };
      case "SUPPORT_FORM":
        return { success: true, value: "SUPPORT_FORM" };
      case "LEADERSFUTURE_LANDING_PAGE":
        return { success: true, value: "LEADERSFUTURE_LANDING_PAGE" };
      case "TEAMDEVELOPMENT_LANDING_PAGE":
        return { success: true, value: "TEAMDEVELOPMENT_LANDING_PAGE" };
      case "EMPLOYEEENGAGEMENT_LANDING_PAGE":
        return { success: true, value: "EMPLOYEEENGAGEMENT_LANDING_PAGE" };
      case "EMPLOYEETRAINING_LANDING_PAGE":
        return { success: true, value: "EMPLOYEETRAINING_LANDING_PAGE" };
      case "CORPORATELEARNING_LANDING_PAGE":
        return { success: true, value: "CORPORATELEARNING_LANDING_PAGE" };
      case "LEARNINGDEVELOPMENT_LANDING_PAGE":
        return { success: true, value: "LEARNINGDEVELOPMENT_LANDING_PAGE" };
      case "MICROLEARNING_LANDING_PAGE":
        return { success: true, value: "MICROLEARNING_LANDING_PAGE" };
      case "SKILLSOFT_LANDING_PAGE":
        return { success: true, value: "SKILLSOFT_LANDING_PAGE" };
      case "STROER_LANDING_PAGE":
        return { success: true, value: "STROER_LANDING_PAGE" };
      case "LMS_LANDINGPAGE":
        return { success: true, value: "LMS_LANDINGPAGE" };
      case "MICROLEARNING_LANDINGPAGE":
        return { success: true, value: "MICROLEARNING_LANDINGPAGE" };
      case "ENTERPRISE_PRODUCTS":
        return { success: true, value: "ENTERPRISE_PRODUCTS" };
      case "SOLUTIONS_PAGE":
        return { success: true, value: "SOLUTIONS_PAGE" };
      case "SOLUTIONS_UPSKILLING":
        return { success: true, value: "SOLUTIONS_UPSKILLING" };
      case "SOLUTIONS_DEI":
        return { success: true, value: "SOLUTIONS_DEI" };
      case "SOLUTIONS_HEALTH_WELLBEING":
        return { success: true, value: "SOLUTIONS_HEALTH_WELLBEING" };
      case "SOLUTIONS_LEARNING_PLATFORM_STRATEGY":
        return { success: true, value: "SOLUTIONS_LEARNING_PLATFORM_STRATEGY" };
      case "SOLUTIONS_TALENT_DEVELOPMENT":
        return { success: true, value: "SOLUTIONS_TALENT_DEVELOPMENT" };
      case "SOLUTIONS_COLLABORATIVE_LEARNING":
        return { success: true, value: "SOLUTIONS_COLLABORATIVE_LEARNING" };
      case "SOLUTIONS_LEARNING_RESOURCE_LIBRARY":
        return { success: true, value: "SOLUTIONS_LEARNING_RESOURCE_LIBRARY" };
      case "SOLUTIONS_EFFECTIVE_LEADERS":
        return { success: true, value: "SOLUTIONS_EFFECTIVE_LEADERS" };
      case "WELCOME_PAGE":
        return { success: true, value: "WELCOME_PAGE" };
      case "BUSINESS_BOOK_SUMMARY_LANDINGPAGE":
        return { success: true, value: "BUSINESS_BOOK_SUMMARY_LANDINGPAGE" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LearningCalendarView
export function serialize_LearningCalendarView(obj: LearningCalendarView): string {
  const serialized = ((value: {
    bannerImgUri: string;
    doors: any[];
    endDate: { year: number; month: number; day: number } | null;
    expiresAt: { year: number; month: number; day: number } | null;
    learningCalendarId: bigint;
    name: string;
    startDate: { year: number; month: number; day: number };
  }): unknown => {
    return {
      bannerImgUri: ((value: string): unknown => {
        return value;
      })(value["bannerImgUri"]),
      doors: ((value: any[]): unknown => {
        return value.map((value: any): unknown => {
          return value;
        });
      })(value["doors"]),
      endDate: ((value: { year: number; month: number; day: number } | null): unknown => {
        if (value === null) {
          return null;
        }
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["endDate"]),
      expiresAt: ((value: { year: number; month: number; day: number } | null): unknown => {
        if (value === null) {
          return null;
        }
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["expiresAt"]),
      learningCalendarId: ((value: bigint): unknown => {
        return value;
      })(value["learningCalendarId"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      startDate: ((value: { year: number; month: number; day: number }): unknown => {
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["startDate"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LearningCalendarView(str: string): LearningCalendarView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          bannerImgUri: string;
          doors: any[];
          endDate: { year: number; month: number; day: number } | null;
          expiresAt: { year: number; month: number; day: number } | null;
          learningCalendarId: bigint;
          name: string;
          startDate: { year: number; month: number; day: number };
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "bannerImgUri")) {
      return { success: false };
    }
    const parsed_bannerImgUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["bannerImgUri"]);
    })();
    if (!parsed_bannerImgUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "doors")) {
      return { success: false };
    }
    const parsed_doors = (() => {
      return ((value: unknown): { success: false } | { success: true; value: any[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: any } => {
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["doors"]);
    })();
    if (!parsed_doors.success) {
      return { success: false };
    }
    if (!hasProp(value, "endDate")) {
      return { success: false };
    }
    const parsed_endDate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["endDate"]);
    })();
    if (!parsed_endDate.success) {
      return { success: false };
    }
    if (!hasProp(value, "expiresAt")) {
      return { success: false };
    }
    const parsed_expiresAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["expiresAt"]);
    })();
    if (!parsed_expiresAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "learningCalendarId")) {
      return { success: false };
    }
    const parsed_learningCalendarId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["learningCalendarId"]);
    })();
    if (!parsed_learningCalendarId.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "startDate")) {
      return { success: false };
    }
    const parsed_startDate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["startDate"]);
    })();
    if (!parsed_startDate.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        bannerImgUri: parsed_bannerImgUri.value,
        doors: parsed_doors.value,
        endDate: parsed_endDate.value,
        expiresAt: parsed_expiresAt.value,
        learningCalendarId: parsed_learningCalendarId.value,
        name: parsed_name.value,
        startDate: parsed_startDate.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LearningItemAdminActionableView
export function serialize_LearningItemAdminActionableView(obj: LearningItemAdminActionableView): string {
  const serialized = ((value: { description: string; learningItemType: LearningItemTypeActionable; metadata: LearningItemAdminMetadataView }): unknown => {
    return {
      description: ((value: string): unknown => {
        return value;
      })(value["description"]),
      learningItemType: ((value: "ACTIONABLE"): unknown => {
        return value;
      })(value["learningItemType"]),
      metadata: ((value: {
        activatedAt: { year: number; month: number; day: number } | null;
        active: boolean;
        createdAt: { year: number; month: number; day: number };
        deactivatedAt: { year: number; month: number; day: number } | null;
        id: bigint;
        language: Language;
        manager: string | null;
        tags: Tag[];
        title: string;
      }): unknown => {
        return {
          activatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
            if (value === null) {
              return null;
            }
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["activatedAt"]),
          active: ((value: boolean): unknown => {
            return value;
          })(value["active"]),
          createdAt: ((value: { year: number; month: number; day: number }): unknown => {
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["createdAt"]),
          deactivatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
            if (value === null) {
              return null;
            }
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["deactivatedAt"]),
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
          language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
            return value;
          })(value["language"]),
          manager: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["manager"]),
          tags: ((value: Tag[]): unknown => {
            return value.map(
              (
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                  | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
              ): unknown => {
                switch (value.discriminator) {
                  case "dynamicString": {
                    return {
                      discriminator: "dynamicString",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicString"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicBoolean": {
                    return {
                      discriminator: "dynamicBoolean",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicBoolean"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: boolean): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicNumber": {
                    return {
                      discriminator: "dynamicNumber",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicNumber"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: number): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicVoid": {
                    return {
                      discriminator: "dynamicVoid",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicVoid"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                    };
                  }
                  case "channel": {
                    return {
                      discriminator: "channel",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                        return {
                          discriminator: ((value: "channel"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "channel"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "primary-skill": {
                    return {
                      discriminator: "primary-skill",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                        return {
                          discriminator: ((value: "primary-skill"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "primary-skill"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "skill": {
                    return {
                      discriminator: "skill",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                        return {
                          discriminator: ((value: "skill"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "skill"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "key-quality": {
                    return {
                      discriminator: "key-quality",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                        return {
                          discriminator: ((value: "key-quality"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "key-quality"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "classification": {
                    return {
                      discriminator: "classification",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                        return {
                          discriminator: ((value: "classification"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "classification"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                }
              },
            );
          })(value["tags"]),
          title: ((value: string): unknown => {
            return value;
          })(value["title"]),
        };
      })(value["metadata"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LearningItemAdminActionableView(str: string): LearningItemAdminActionableView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          description: string;
          learningItemType: LearningItemTypeActionable;
          metadata: LearningItemAdminMetadataView;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "description")) {
      return { success: false };
    }
    const parsed_description = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["description"]);
    })();
    if (!parsed_description.success) {
      return { success: false };
    }
    if (!hasProp(value, "learningItemType")) {
      return { success: false };
    }
    const parsed_learningItemType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: LearningItemTypeActionable } => {
        return ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "ACTIONABLE":
              return { success: true, value: "ACTIONABLE" };
            default:
              return { success: false };
          }
        })(value);
      })(value["learningItemType"]);
    })();
    if (!parsed_learningItemType.success) {
      return { success: false };
    }
    if (!hasProp(value, "metadata")) {
      return { success: false };
    }
    const parsed_metadata = (() => {
      return ((value: unknown): { success: false } | { success: true; value: LearningItemAdminMetadataView } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                activatedAt: { year: number; month: number; day: number } | null;
                active: boolean;
                createdAt: { year: number; month: number; day: number };
                deactivatedAt: { year: number; month: number; day: number } | null;
                id: bigint;
                language: Language;
                manager: string | null;
                tags: Tag[];
                title: string;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "activatedAt")) {
            return { success: false };
          }
          const parsed_activatedAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["activatedAt"]);
          })();
          if (!parsed_activatedAt.success) {
            return { success: false };
          }
          if (!hasProp(value, "active")) {
            return { success: false };
          }
          const parsed_active = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["active"]);
          })();
          if (!parsed_active.success) {
            return { success: false };
          }
          if (!hasProp(value, "createdAt")) {
            return { success: false };
          }
          const parsed_createdAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["createdAt"]);
          })();
          if (!parsed_createdAt.success) {
            return { success: false };
          }
          if (!hasProp(value, "deactivatedAt")) {
            return { success: false };
          }
          const parsed_deactivatedAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["deactivatedAt"]);
          })();
          if (!parsed_deactivatedAt.success) {
            return { success: false };
          }
          if (!hasProp(value, "id")) {
            return { success: false };
          }
          const parsed_id = (() => {
            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
              if (typeof value === "number") {
                return { success: true, value: BigInt(value) };
              }
              if (typeof value === "bigint") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["id"]);
          })();
          if (!parsed_id.success) {
            return { success: false };
          }
          if (!hasProp(value, "language")) {
            return { success: false };
          }
          const parsed_language = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Language } => {
              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "en":
                    return { success: true, value: "en" };
                  case "de":
                    return { success: true, value: "de" };
                  case "es":
                    return { success: true, value: "es" };
                  case "ru":
                    return { success: true, value: "ru" };
                  case "zh":
                    return { success: true, value: "zh" };
                  case "pt":
                    return { success: true, value: "pt" };
                  case "fr":
                    return { success: true, value: "fr" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["language"]);
          })();
          if (!parsed_language.success) {
            return { success: false };
          }
          if (!hasProp(value, "manager")) {
            return { success: false };
          }
          const parsed_manager = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["manager"]);
          })();
          if (!parsed_manager.success) {
            return { success: false };
          }
          if (!hasProp(value, "tags")) {
            return { success: false };
          }
          const parsed_tags = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                        | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                        | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                        | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                        | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                        | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                        | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                        | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                        | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                    } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value:
                          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                          | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                          | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                      } => {
                    if (value == null) {
                      return { success: false };
                    }
                    if (typeof value != "object") {
                      return { success: false };
                    }
                    if (!hasProp(value, "discriminator")) {
                      return { success: false };
                    }
                    if (typeof value["discriminator"] != "string") {
                      return { success: false };
                    }
                    const discriminator = value["discriminator"];
                    switch (discriminator) {
                      case "dynamicString": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicString;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicString;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicString":
                                          return { success: true, value: "dynamicString" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                      }
                      case "dynamicBoolean": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicBoolean;
                                value: boolean;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicBoolean;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicBoolean":
                                          return { success: true, value: "dynamicBoolean" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                      }
                      case "dynamicNumber": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicNumber;
                                value: number;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicNumber;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicNumber":
                                          return { success: true, value: "dynamicNumber" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: number } => {
                              if (typeof value === "number") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                      }
                      case "dynamicVoid": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicVoid;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicVoid;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicVoid":
                                          return { success: true, value: "dynamicVoid" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                      }
                      case "channel": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindChannel;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindChannel;
                                      id: bigint;
                                      kind: KindChannel;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "channel":
                                          return { success: true, value: "channel" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "channel":
                                          return { success: true, value: "channel" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                      }
                      case "primary-skill": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindPrimarySkill;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindPrimarySkill;
                                      id: bigint;
                                      kind: KindPrimarySkill;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "primary-skill":
                                          return { success: true, value: "primary-skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "primary-skill":
                                          return { success: true, value: "primary-skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                      }
                      case "skill": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindSkill;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindSkill;
                                      id: bigint;
                                      kind: KindSkill;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "skill":
                                          return { success: true, value: "skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "skill":
                                          return { success: true, value: "skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                      }
                      case "key-quality": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindKeyQuality;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindKeyQuality;
                                      id: bigint;
                                      kind: KindKeyQuality;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "key-quality":
                                          return { success: true, value: "key-quality" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "key-quality":
                                          return { success: true, value: "key-quality" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                      }
                      case "classification": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindClassification;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindClassification;
                                      id: bigint;
                                      kind: KindClassification;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "classification":
                                          return { success: true, value: "classification" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "classification":
                                          return { success: true, value: "classification" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                      }
                      default:
                        return { success: false };
                    }
                  })(value);
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["tags"]);
          })();
          if (!parsed_tags.success) {
            return { success: false };
          }
          if (!hasProp(value, "title")) {
            return { success: false };
          }
          const parsed_title = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["title"]);
          })();
          if (!parsed_title.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              activatedAt: parsed_activatedAt.value,
              active: parsed_active.value,
              createdAt: parsed_createdAt.value,
              deactivatedAt: parsed_deactivatedAt.value,
              id: parsed_id.value,
              language: parsed_language.value,
              manager: parsed_manager.value,
              tags: parsed_tags.value,
              title: parsed_title.value,
            },
          };
        })(value);
      })(value["metadata"]);
    })();
    if (!parsed_metadata.success) {
      return { success: false };
    }
    return { success: true, value: { description: parsed_description.value, learningItemType: parsed_learningItemType.value, metadata: parsed_metadata.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LearningItemAdminFilter
export function serialize_LearningItemAdminFilter(obj: LearningItemAdminFilter): string {
  const serialized = ((value: {
    activatedAtRange: DateRange | null;
    active: boolean | null;
    audio: boolean | null;
    createdAtRange: DateRange | null;
    documentTypes: SummaryDocumentType[];
    ids: number[];
    itemTypes: LearningItemType[];
    languages: Language[];
    manager: string | null;
    missingTagKinds: TagKind[];
    publisher: string | null;
    resultSize: number;
    sourceTypes: SummarySourceType[];
    tagCount: number | null;
    tagKinds: TagKind[];
    tags: Tag[];
    title: string | null;
  }): unknown => {
    return {
      activatedAtRange: ((
        value: {
          from: { year: number; month: number; day: number };
          to: { year: number; month: number; day: number };
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          from: ((value: { year: number; month: number; day: number }): unknown => {
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["from"]),
          to: ((value: { year: number; month: number; day: number }): unknown => {
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["to"]),
        };
      })(value["activatedAtRange"]),
      active: ((value: boolean | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["active"]),
      audio: ((value: boolean | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["audio"]),
      createdAtRange: ((
        value: {
          from: { year: number; month: number; day: number };
          to: { year: number; month: number; day: number };
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          from: ((value: { year: number; month: number; day: number }): unknown => {
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["from"]),
          to: ((value: { year: number; month: number; day: number }): unknown => {
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["to"]),
        };
      })(value["createdAtRange"]),
      documentTypes: ((value: SummaryDocumentType[]): unknown => {
        return value.map((value: "ABSTRACT" | "REVIEW"): unknown => {
          return value;
        });
      })(value["documentTypes"]),
      ids: ((value: number[]): unknown => {
        return value.map((value: number): unknown => {
          return value;
        });
      })(value["ids"]),
      itemTypes: ((value: LearningItemType[]): unknown => {
        return value.map((value: "SUMMARY" | "ACTIONABLE"): unknown => {
          return value;
        });
      })(value["itemTypes"]),
      languages: ((value: Language[]): unknown => {
        return value.map((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
          return value;
        });
      })(value["languages"]),
      manager: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["manager"]),
      missingTagKinds: ((value: TagKind[]): unknown => {
        return value.map(
          (
            value:
              | { discriminator: "classification"; id: bigint; kind: KindClassification }
              | { discriminator: "channel"; id: bigint; kind: KindChannel }
              | { discriminator: "primary-skill"; id: bigint; kind: KindPrimarySkill }
              | { discriminator: "skill"; id: bigint; kind: KindSkill }
              | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
              | { discriminator: "dynamicVoid"; id: bigint; kind: string }
              | { discriminator: "dynamicString"; id: bigint; kind: string }
              | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
              | { discriminator: "dynamicNumber"; id: bigint; kind: string },
          ): unknown => {
            switch (value.discriminator) {
              case "classification": {
                return {
                  discriminator: "classification",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "classification"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
              case "channel": {
                return {
                  discriminator: "channel",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "channel"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
              case "primary-skill": {
                return {
                  discriminator: "primary-skill",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "primary-skill"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
              case "skill": {
                return {
                  discriminator: "skill",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "skill"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
              case "key-quality": {
                return {
                  discriminator: "key-quality",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "key-quality"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
              case "dynamicVoid": {
                return {
                  discriminator: "dynamicVoid",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
              case "dynamicString": {
                return {
                  discriminator: "dynamicString",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
              case "dynamicBoolean": {
                return {
                  discriminator: "dynamicBoolean",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
              case "dynamicNumber": {
                return {
                  discriminator: "dynamicNumber",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
            }
          },
        );
      })(value["missingTagKinds"]),
      publisher: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["publisher"]),
      resultSize: ((value: number): unknown => {
        return value;
      })(value["resultSize"]),
      sourceTypes: ((value: SummarySourceType[]): unknown => {
        return value.map((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
          return value;
        });
      })(value["sourceTypes"]),
      tagCount: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["tagCount"]),
      tagKinds: ((value: TagKind[]): unknown => {
        return value.map(
          (
            value:
              | { discriminator: "classification"; id: bigint; kind: KindClassification }
              | { discriminator: "channel"; id: bigint; kind: KindChannel }
              | { discriminator: "primary-skill"; id: bigint; kind: KindPrimarySkill }
              | { discriminator: "skill"; id: bigint; kind: KindSkill }
              | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
              | { discriminator: "dynamicVoid"; id: bigint; kind: string }
              | { discriminator: "dynamicString"; id: bigint; kind: string }
              | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
              | { discriminator: "dynamicNumber"; id: bigint; kind: string },
          ): unknown => {
            switch (value.discriminator) {
              case "classification": {
                return {
                  discriminator: "classification",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "classification"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
              case "channel": {
                return {
                  discriminator: "channel",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "channel"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
              case "primary-skill": {
                return {
                  discriminator: "primary-skill",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "primary-skill"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
              case "skill": {
                return {
                  discriminator: "skill",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "skill"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
              case "key-quality": {
                return {
                  discriminator: "key-quality",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "key-quality"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
              case "dynamicVoid": {
                return {
                  discriminator: "dynamicVoid",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
              case "dynamicString": {
                return {
                  discriminator: "dynamicString",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
              case "dynamicBoolean": {
                return {
                  discriminator: "dynamicBoolean",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
              case "dynamicNumber": {
                return {
                  discriminator: "dynamicNumber",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
            }
          },
        );
      })(value["tagKinds"]),
      tags: ((value: Tag[]): unknown => {
        return value.map(
          (
            value:
              | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
              | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
              | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
              | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
              | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
              | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
              | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
              | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
              | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
          ): unknown => {
            switch (value.discriminator) {
              case "dynamicString": {
                return {
                  discriminator: "dynamicString",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicString"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicBoolean": {
                return {
                  discriminator: "dynamicBoolean",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicBoolean"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: boolean): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicNumber": {
                return {
                  discriminator: "dynamicNumber",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicNumber"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: number): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicVoid": {
                return {
                  discriminator: "dynamicVoid",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicVoid"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                };
              }
              case "channel": {
                return {
                  discriminator: "channel",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                    return {
                      discriminator: ((value: "channel"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "channel"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "primary-skill": {
                return {
                  discriminator: "primary-skill",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                    return {
                      discriminator: ((value: "primary-skill"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "primary-skill"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "skill": {
                return {
                  discriminator: "skill",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                    return {
                      discriminator: ((value: "skill"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "skill"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "key-quality": {
                return {
                  discriminator: "key-quality",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                    return {
                      discriminator: ((value: "key-quality"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "key-quality"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "classification": {
                return {
                  discriminator: "classification",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                    return {
                      discriminator: ((value: "classification"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "classification"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
            }
          },
        );
      })(value["tags"]),
      title: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LearningItemAdminFilter(str: string): LearningItemAdminFilter | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          activatedAtRange: DateRange | null;
          active: boolean | null;
          audio: boolean | null;
          createdAtRange: DateRange | null;
          documentTypes: SummaryDocumentType[];
          ids: number[];
          itemTypes: LearningItemType[];
          languages: Language[];
          manager: string | null;
          missingTagKinds: TagKind[];
          publisher: string | null;
          resultSize: number;
          sourceTypes: SummarySourceType[];
          tagCount: number | null;
          tagKinds: TagKind[];
          tags: Tag[];
          title: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "activatedAtRange")) {
      return { success: false };
    }
    const parsed_activatedAtRange = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateRange | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                from: { year: number; month: number; day: number };
                to: { year: number; month: number; day: number };
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "from")) {
            return { success: false };
          }
          const parsed_from = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["from"]);
          })();
          if (!parsed_from.success) {
            return { success: false };
          }
          if (!hasProp(value, "to")) {
            return { success: false };
          }
          const parsed_to = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["to"]);
          })();
          if (!parsed_to.success) {
            return { success: false };
          }
          return { success: true, value: { from: parsed_from.value, to: parsed_to.value } };
        })(value);
      })(value["activatedAtRange"]);
    })();
    if (!parsed_activatedAtRange.success) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "audio")) {
      return { success: false };
    }
    const parsed_audio = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["audio"]);
    })();
    if (!parsed_audio.success) {
      return { success: false };
    }
    if (!hasProp(value, "createdAtRange")) {
      return { success: false };
    }
    const parsed_createdAtRange = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateRange | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                from: { year: number; month: number; day: number };
                to: { year: number; month: number; day: number };
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "from")) {
            return { success: false };
          }
          const parsed_from = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["from"]);
          })();
          if (!parsed_from.success) {
            return { success: false };
          }
          if (!hasProp(value, "to")) {
            return { success: false };
          }
          const parsed_to = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["to"]);
          })();
          if (!parsed_to.success) {
            return { success: false };
          }
          return { success: true, value: { from: parsed_from.value, to: parsed_to.value } };
        })(value);
      })(value["createdAtRange"]);
    })();
    if (!parsed_createdAtRange.success) {
      return { success: false };
    }
    if (!hasProp(value, "documentTypes")) {
      return { success: false };
    }
    const parsed_documentTypes = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
          return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            switch (value) {
              case "ABSTRACT":
                return { success: true, value: "ABSTRACT" };
              case "REVIEW":
                return { success: true, value: "REVIEW" };
              default:
                return { success: false };
            }
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["documentTypes"]);
    })();
    if (!parsed_documentTypes.success) {
      return { success: false };
    }
    if (!hasProp(value, "ids")) {
      return { success: false };
    }
    const parsed_ids = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
          if (typeof value === "number") {
            return { success: true, value: value };
          }
          return { success: false };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["ids"]);
    })();
    if (!parsed_ids.success) {
      return { success: false };
    }
    if (!hasProp(value, "itemTypes")) {
      return { success: false };
    }
    const parsed_itemTypes = (() => {
      return ((value: unknown): { success: false } | { success: true; value: LearningItemType[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: LearningItemType } => {
          return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" | "ACTIONABLE" } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            switch (value) {
              case "SUMMARY":
                return { success: true, value: "SUMMARY" };
              case "ACTIONABLE":
                return { success: true, value: "ACTIONABLE" };
              default:
                return { success: false };
            }
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["itemTypes"]);
    })();
    if (!parsed_itemTypes.success) {
      return { success: false };
    }
    if (!hasProp(value, "languages")) {
      return { success: false };
    }
    const parsed_languages = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Language } => {
          return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            switch (value) {
              case "en":
                return { success: true, value: "en" };
              case "de":
                return { success: true, value: "de" };
              case "es":
                return { success: true, value: "es" };
              case "ru":
                return { success: true, value: "ru" };
              case "zh":
                return { success: true, value: "zh" };
              case "pt":
                return { success: true, value: "pt" };
              case "fr":
                return { success: true, value: "fr" };
              default:
                return { success: false };
            }
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["languages"]);
    })();
    if (!parsed_languages.success) {
      return { success: false };
    }
    if (!hasProp(value, "manager")) {
      return { success: false };
    }
    const parsed_manager = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["manager"]);
    })();
    if (!parsed_manager.success) {
      return { success: false };
    }
    if (!hasProp(value, "missingTagKinds")) {
      return { success: false };
    }
    const parsed_missingTagKinds = (() => {
      return ((value: unknown): { success: false } | { success: true; value: TagKind[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: TagKind } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { discriminator: "classification"; id: bigint; kind: KindClassification }
                  | { discriminator: "channel"; id: bigint; kind: KindChannel }
                  | { discriminator: "primary-skill"; id: bigint; kind: KindPrimarySkill }
                  | { discriminator: "skill"; id: bigint; kind: KindSkill }
                  | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
                  | { discriminator: "dynamicVoid"; id: bigint; kind: string }
                  | { discriminator: "dynamicString"; id: bigint; kind: string }
                  | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
                  | { discriminator: "dynamicNumber"; id: bigint; kind: string };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { discriminator: "classification"; id: bigint; kind: KindClassification }
                    | { discriminator: "channel"; id: bigint; kind: KindChannel }
                    | { discriminator: "primary-skill"; id: bigint; kind: KindPrimarySkill }
                    | { discriminator: "skill"; id: bigint; kind: KindSkill }
                    | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
                    | { discriminator: "dynamicVoid"; id: bigint; kind: string }
                    | { discriminator: "dynamicString"; id: bigint; kind: string }
                    | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
                    | { discriminator: "dynamicNumber"; id: bigint; kind: string };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "discriminator")) {
                return { success: false };
              }
              if (typeof value["discriminator"] != "string") {
                return { success: false };
              }
              const discriminator = value["discriminator"];
              switch (discriminator) {
                case "classification": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: KindClassification;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                        return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "classification":
                              return { success: true, value: "classification" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                }
                case "channel": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: KindChannel;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                        return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "channel":
                              return { success: true, value: "channel" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                }
                case "primary-skill": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: KindPrimarySkill;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                        return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "primary-skill":
                              return { success: true, value: "primary-skill" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                }
                case "skill": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: KindSkill;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                        return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "skill":
                              return { success: true, value: "skill" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                }
                case "key-quality": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: KindKeyQuality;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                        return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "key-quality":
                              return { success: true, value: "key-quality" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                }
                case "dynamicVoid": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                }
                case "dynamicString": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                }
                case "dynamicBoolean": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                }
                case "dynamicNumber": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["missingTagKinds"]);
    })();
    if (!parsed_missingTagKinds.success) {
      return { success: false };
    }
    if (!hasProp(value, "publisher")) {
      return { success: false };
    }
    const parsed_publisher = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["publisher"]);
    })();
    if (!parsed_publisher.success) {
      return { success: false };
    }
    if (!hasProp(value, "resultSize")) {
      return { success: false };
    }
    const parsed_resultSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["resultSize"]);
    })();
    if (!parsed_resultSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "sourceTypes")) {
      return { success: false };
    }
    const parsed_sourceTypes = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SummarySourceType[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
          return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            switch (value) {
              case "BOOK":
                return { success: true, value: "BOOK" };
              case "VIDEO":
                return { success: true, value: "VIDEO" };
              case "ARTICLE":
                return { success: true, value: "ARTICLE" };
              case "CHAPTER":
                return { success: true, value: "CHAPTER" };
              case "REPORT":
                return { success: true, value: "REPORT" };
              case "PODCAST":
                return { success: true, value: "PODCAST" };
              default:
                return { success: false };
            }
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["sourceTypes"]);
    })();
    if (!parsed_sourceTypes.success) {
      return { success: false };
    }
    if (!hasProp(value, "tagCount")) {
      return { success: false };
    }
    const parsed_tagCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["tagCount"]);
    })();
    if (!parsed_tagCount.success) {
      return { success: false };
    }
    if (!hasProp(value, "tagKinds")) {
      return { success: false };
    }
    const parsed_tagKinds = (() => {
      return ((value: unknown): { success: false } | { success: true; value: TagKind[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: TagKind } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { discriminator: "classification"; id: bigint; kind: KindClassification }
                  | { discriminator: "channel"; id: bigint; kind: KindChannel }
                  | { discriminator: "primary-skill"; id: bigint; kind: KindPrimarySkill }
                  | { discriminator: "skill"; id: bigint; kind: KindSkill }
                  | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
                  | { discriminator: "dynamicVoid"; id: bigint; kind: string }
                  | { discriminator: "dynamicString"; id: bigint; kind: string }
                  | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
                  | { discriminator: "dynamicNumber"; id: bigint; kind: string };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { discriminator: "classification"; id: bigint; kind: KindClassification }
                    | { discriminator: "channel"; id: bigint; kind: KindChannel }
                    | { discriminator: "primary-skill"; id: bigint; kind: KindPrimarySkill }
                    | { discriminator: "skill"; id: bigint; kind: KindSkill }
                    | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
                    | { discriminator: "dynamicVoid"; id: bigint; kind: string }
                    | { discriminator: "dynamicString"; id: bigint; kind: string }
                    | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
                    | { discriminator: "dynamicNumber"; id: bigint; kind: string };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "discriminator")) {
                return { success: false };
              }
              if (typeof value["discriminator"] != "string") {
                return { success: false };
              }
              const discriminator = value["discriminator"];
              switch (discriminator) {
                case "classification": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: KindClassification;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                        return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "classification":
                              return { success: true, value: "classification" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                }
                case "channel": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: KindChannel;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                        return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "channel":
                              return { success: true, value: "channel" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                }
                case "primary-skill": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: KindPrimarySkill;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                        return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "primary-skill":
                              return { success: true, value: "primary-skill" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                }
                case "skill": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: KindSkill;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                        return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "skill":
                              return { success: true, value: "skill" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                }
                case "key-quality": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: KindKeyQuality;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                        return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "key-quality":
                              return { success: true, value: "key-quality" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                }
                case "dynamicVoid": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                }
                case "dynamicString": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                }
                case "dynamicBoolean": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                }
                case "dynamicNumber": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["tagKinds"]);
    })();
    if (!parsed_tagKinds.success) {
      return { success: false };
    }
    if (!hasProp(value, "tags")) {
      return { success: false };
    }
    const parsed_tags = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                  | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                    | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                    | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                    | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                    | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                    | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                    | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                    | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                    | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "discriminator")) {
                return { success: false };
              }
              if (typeof value["discriminator"] != "string") {
                return { success: false };
              }
              const discriminator = value["discriminator"];
              switch (discriminator) {
                case "dynamicString": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicString;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicString;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicString":
                                    return { success: true, value: "dynamicString" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                }
                case "dynamicBoolean": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicBoolean;
                          value: boolean;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicBoolean;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicBoolean":
                                    return { success: true, value: "dynamicBoolean" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                }
                case "dynamicNumber": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicNumber;
                          value: number;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicNumber;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicNumber":
                                    return { success: true, value: "dynamicNumber" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                }
                case "dynamicVoid": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicVoid;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicVoid;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicVoid":
                                    return { success: true, value: "dynamicVoid" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                }
                case "channel": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindChannel;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindChannel;
                                id: bigint;
                                kind: KindChannel;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                              return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "channel":
                                    return { success: true, value: "channel" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                              return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "channel":
                                    return { success: true, value: "channel" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                }
                case "primary-skill": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindPrimarySkill;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindPrimarySkill;
                                id: bigint;
                                kind: KindPrimarySkill;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "primary-skill":
                                    return { success: true, value: "primary-skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "primary-skill":
                                    return { success: true, value: "primary-skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                }
                case "skill": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindSkill;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindSkill;
                                id: bigint;
                                kind: KindSkill;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "skill":
                                    return { success: true, value: "skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "skill":
                                    return { success: true, value: "skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                }
                case "key-quality": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindKeyQuality;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindKeyQuality;
                                id: bigint;
                                kind: KindKeyQuality;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                              return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "key-quality":
                                    return { success: true, value: "key-quality" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                              return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "key-quality":
                                    return { success: true, value: "key-quality" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                }
                case "classification": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindClassification;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindClassification;
                                id: bigint;
                                kind: KindClassification;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                              return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "classification":
                                    return { success: true, value: "classification" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                              return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "classification":
                                    return { success: true, value: "classification" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["tags"]);
    })();
    if (!parsed_tags.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        activatedAtRange: parsed_activatedAtRange.value,
        active: parsed_active.value,
        audio: parsed_audio.value,
        createdAtRange: parsed_createdAtRange.value,
        documentTypes: parsed_documentTypes.value,
        ids: parsed_ids.value,
        itemTypes: parsed_itemTypes.value,
        languages: parsed_languages.value,
        manager: parsed_manager.value,
        missingTagKinds: parsed_missingTagKinds.value,
        publisher: parsed_publisher.value,
        resultSize: parsed_resultSize.value,
        sourceTypes: parsed_sourceTypes.value,
        tagCount: parsed_tagCount.value,
        tagKinds: parsed_tagKinds.value,
        tags: parsed_tags.value,
        title: parsed_title.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LearningItemAdminMetadataView
export function serialize_LearningItemAdminMetadataView(obj: LearningItemAdminMetadataView): string {
  const serialized = ((value: {
    activatedAt: { year: number; month: number; day: number } | null;
    active: boolean;
    createdAt: { year: number; month: number; day: number };
    deactivatedAt: { year: number; month: number; day: number } | null;
    id: bigint;
    language: Language;
    manager: string | null;
    tags: Tag[];
    title: string;
  }): unknown => {
    return {
      activatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
        if (value === null) {
          return null;
        }
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["activatedAt"]),
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      createdAt: ((value: { year: number; month: number; day: number }): unknown => {
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["createdAt"]),
      deactivatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
        if (value === null) {
          return null;
        }
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["deactivatedAt"]),
      id: ((value: bigint): unknown => {
        return value;
      })(value["id"]),
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      manager: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["manager"]),
      tags: ((value: Tag[]): unknown => {
        return value.map(
          (
            value:
              | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
              | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
              | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
              | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
              | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
              | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
              | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
              | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
              | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
          ): unknown => {
            switch (value.discriminator) {
              case "dynamicString": {
                return {
                  discriminator: "dynamicString",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicString"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicBoolean": {
                return {
                  discriminator: "dynamicBoolean",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicBoolean"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: boolean): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicNumber": {
                return {
                  discriminator: "dynamicNumber",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicNumber"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: number): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicVoid": {
                return {
                  discriminator: "dynamicVoid",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicVoid"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                };
              }
              case "channel": {
                return {
                  discriminator: "channel",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                    return {
                      discriminator: ((value: "channel"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "channel"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "primary-skill": {
                return {
                  discriminator: "primary-skill",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                    return {
                      discriminator: ((value: "primary-skill"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "primary-skill"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "skill": {
                return {
                  discriminator: "skill",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                    return {
                      discriminator: ((value: "skill"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "skill"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "key-quality": {
                return {
                  discriminator: "key-quality",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                    return {
                      discriminator: ((value: "key-quality"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "key-quality"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "classification": {
                return {
                  discriminator: "classification",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                    return {
                      discriminator: ((value: "classification"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "classification"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
            }
          },
        );
      })(value["tags"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LearningItemAdminMetadataView(str: string): LearningItemAdminMetadataView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          activatedAt: { year: number; month: number; day: number } | null;
          active: boolean;
          createdAt: { year: number; month: number; day: number };
          deactivatedAt: { year: number; month: number; day: number } | null;
          id: bigint;
          language: Language;
          manager: string | null;
          tags: Tag[];
          title: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "activatedAt")) {
      return { success: false };
    }
    const parsed_activatedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["activatedAt"]);
    })();
    if (!parsed_activatedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "createdAt")) {
      return { success: false };
    }
    const parsed_createdAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["createdAt"]);
    })();
    if (!parsed_createdAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "deactivatedAt")) {
      return { success: false };
    }
    const parsed_deactivatedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["deactivatedAt"]);
    })();
    if (!parsed_deactivatedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language } => {
        return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "en":
              return { success: true, value: "en" };
            case "de":
              return { success: true, value: "de" };
            case "es":
              return { success: true, value: "es" };
            case "ru":
              return { success: true, value: "ru" };
            case "zh":
              return { success: true, value: "zh" };
            case "pt":
              return { success: true, value: "pt" };
            case "fr":
              return { success: true, value: "fr" };
            default:
              return { success: false };
          }
        })(value);
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "manager")) {
      return { success: false };
    }
    const parsed_manager = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["manager"]);
    })();
    if (!parsed_manager.success) {
      return { success: false };
    }
    if (!hasProp(value, "tags")) {
      return { success: false };
    }
    const parsed_tags = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                  | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                    | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                    | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                    | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                    | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                    | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                    | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                    | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                    | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "discriminator")) {
                return { success: false };
              }
              if (typeof value["discriminator"] != "string") {
                return { success: false };
              }
              const discriminator = value["discriminator"];
              switch (discriminator) {
                case "dynamicString": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicString;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicString;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicString":
                                    return { success: true, value: "dynamicString" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                }
                case "dynamicBoolean": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicBoolean;
                          value: boolean;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicBoolean;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicBoolean":
                                    return { success: true, value: "dynamicBoolean" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                }
                case "dynamicNumber": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicNumber;
                          value: number;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicNumber;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicNumber":
                                    return { success: true, value: "dynamicNumber" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                }
                case "dynamicVoid": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicVoid;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicVoid;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicVoid":
                                    return { success: true, value: "dynamicVoid" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                }
                case "channel": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindChannel;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindChannel;
                                id: bigint;
                                kind: KindChannel;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                              return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "channel":
                                    return { success: true, value: "channel" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                              return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "channel":
                                    return { success: true, value: "channel" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                }
                case "primary-skill": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindPrimarySkill;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindPrimarySkill;
                                id: bigint;
                                kind: KindPrimarySkill;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "primary-skill":
                                    return { success: true, value: "primary-skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "primary-skill":
                                    return { success: true, value: "primary-skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                }
                case "skill": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindSkill;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindSkill;
                                id: bigint;
                                kind: KindSkill;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "skill":
                                    return { success: true, value: "skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "skill":
                                    return { success: true, value: "skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                }
                case "key-quality": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindKeyQuality;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindKeyQuality;
                                id: bigint;
                                kind: KindKeyQuality;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                              return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "key-quality":
                                    return { success: true, value: "key-quality" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                              return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "key-quality":
                                    return { success: true, value: "key-quality" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                }
                case "classification": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindClassification;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindClassification;
                                id: bigint;
                                kind: KindClassification;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                              return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "classification":
                                    return { success: true, value: "classification" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                              return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "classification":
                                    return { success: true, value: "classification" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["tags"]);
    })();
    if (!parsed_tags.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        activatedAt: parsed_activatedAt.value,
        active: parsed_active.value,
        createdAt: parsed_createdAt.value,
        deactivatedAt: parsed_deactivatedAt.value,
        id: parsed_id.value,
        language: parsed_language.value,
        manager: parsed_manager.value,
        tags: parsed_tags.value,
        title: parsed_title.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LearningItemAdminProps
export function serialize_LearningItemAdminProps(obj: LearningItemAdminProps): string {
  const serialized = ((value: { filter: LearningItemAdminFilter; views: ("READONLY" | "LEARNING_ITEM_VIEW" | "TAG_VIEW" | "CHANNEL_VIEW")[] }): unknown => {
    return {
      filter: ((value: {
        activatedAtRange: DateRange | null;
        active: boolean | null;
        audio: boolean | null;
        createdAtRange: DateRange | null;
        documentTypes: SummaryDocumentType[];
        ids: number[];
        itemTypes: LearningItemType[];
        languages: Language[];
        manager: string | null;
        missingTagKinds: TagKind[];
        publisher: string | null;
        resultSize: number;
        sourceTypes: SummarySourceType[];
        tagCount: number | null;
        tagKinds: TagKind[];
        tags: Tag[];
        title: string | null;
      }): unknown => {
        return {
          activatedAtRange: ((
            value: {
              from: { year: number; month: number; day: number };
              to: { year: number; month: number; day: number };
            } | null,
          ): unknown => {
            if (value === null) {
              return null;
            }
            return {
              from: ((value: { year: number; month: number; day: number }): unknown => {
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["from"]),
              to: ((value: { year: number; month: number; day: number }): unknown => {
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["to"]),
            };
          })(value["activatedAtRange"]),
          active: ((value: boolean | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["active"]),
          audio: ((value: boolean | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["audio"]),
          createdAtRange: ((
            value: {
              from: { year: number; month: number; day: number };
              to: { year: number; month: number; day: number };
            } | null,
          ): unknown => {
            if (value === null) {
              return null;
            }
            return {
              from: ((value: { year: number; month: number; day: number }): unknown => {
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["from"]),
              to: ((value: { year: number; month: number; day: number }): unknown => {
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["to"]),
            };
          })(value["createdAtRange"]),
          documentTypes: ((value: SummaryDocumentType[]): unknown => {
            return value.map((value: "ABSTRACT" | "REVIEW"): unknown => {
              return value;
            });
          })(value["documentTypes"]),
          ids: ((value: number[]): unknown => {
            return value.map((value: number): unknown => {
              return value;
            });
          })(value["ids"]),
          itemTypes: ((value: LearningItemType[]): unknown => {
            return value.map((value: "SUMMARY" | "ACTIONABLE"): unknown => {
              return value;
            });
          })(value["itemTypes"]),
          languages: ((value: Language[]): unknown => {
            return value.map((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
              return value;
            });
          })(value["languages"]),
          manager: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["manager"]),
          missingTagKinds: ((value: TagKind[]): unknown => {
            return value.map(
              (
                value:
                  | { discriminator: "classification"; id: bigint; kind: KindClassification }
                  | { discriminator: "channel"; id: bigint; kind: KindChannel }
                  | { discriminator: "primary-skill"; id: bigint; kind: KindPrimarySkill }
                  | { discriminator: "skill"; id: bigint; kind: KindSkill }
                  | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
                  | { discriminator: "dynamicVoid"; id: bigint; kind: string }
                  | { discriminator: "dynamicString"; id: bigint; kind: string }
                  | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
                  | { discriminator: "dynamicNumber"; id: bigint; kind: string },
              ): unknown => {
                switch (value.discriminator) {
                  case "classification": {
                    return {
                      discriminator: "classification",
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "classification"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  }
                  case "channel": {
                    return {
                      discriminator: "channel",
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "channel"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  }
                  case "primary-skill": {
                    return {
                      discriminator: "primary-skill",
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "primary-skill"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  }
                  case "skill": {
                    return {
                      discriminator: "skill",
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "skill"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  }
                  case "key-quality": {
                    return {
                      discriminator: "key-quality",
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "key-quality"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  }
                  case "dynamicVoid": {
                    return {
                      discriminator: "dynamicVoid",
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  }
                  case "dynamicString": {
                    return {
                      discriminator: "dynamicString",
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  }
                  case "dynamicBoolean": {
                    return {
                      discriminator: "dynamicBoolean",
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  }
                  case "dynamicNumber": {
                    return {
                      discriminator: "dynamicNumber",
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  }
                }
              },
            );
          })(value["missingTagKinds"]),
          publisher: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["publisher"]),
          resultSize: ((value: number): unknown => {
            return value;
          })(value["resultSize"]),
          sourceTypes: ((value: SummarySourceType[]): unknown => {
            return value.map((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
              return value;
            });
          })(value["sourceTypes"]),
          tagCount: ((value: number | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["tagCount"]),
          tagKinds: ((value: TagKind[]): unknown => {
            return value.map(
              (
                value:
                  | { discriminator: "classification"; id: bigint; kind: KindClassification }
                  | { discriminator: "channel"; id: bigint; kind: KindChannel }
                  | { discriminator: "primary-skill"; id: bigint; kind: KindPrimarySkill }
                  | { discriminator: "skill"; id: bigint; kind: KindSkill }
                  | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
                  | { discriminator: "dynamicVoid"; id: bigint; kind: string }
                  | { discriminator: "dynamicString"; id: bigint; kind: string }
                  | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
                  | { discriminator: "dynamicNumber"; id: bigint; kind: string },
              ): unknown => {
                switch (value.discriminator) {
                  case "classification": {
                    return {
                      discriminator: "classification",
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "classification"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  }
                  case "channel": {
                    return {
                      discriminator: "channel",
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "channel"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  }
                  case "primary-skill": {
                    return {
                      discriminator: "primary-skill",
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "primary-skill"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  }
                  case "skill": {
                    return {
                      discriminator: "skill",
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "skill"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  }
                  case "key-quality": {
                    return {
                      discriminator: "key-quality",
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "key-quality"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  }
                  case "dynamicVoid": {
                    return {
                      discriminator: "dynamicVoid",
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  }
                  case "dynamicString": {
                    return {
                      discriminator: "dynamicString",
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  }
                  case "dynamicBoolean": {
                    return {
                      discriminator: "dynamicBoolean",
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  }
                  case "dynamicNumber": {
                    return {
                      discriminator: "dynamicNumber",
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  }
                }
              },
            );
          })(value["tagKinds"]),
          tags: ((value: Tag[]): unknown => {
            return value.map(
              (
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                  | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
              ): unknown => {
                switch (value.discriminator) {
                  case "dynamicString": {
                    return {
                      discriminator: "dynamicString",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicString"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicBoolean": {
                    return {
                      discriminator: "dynamicBoolean",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicBoolean"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: boolean): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicNumber": {
                    return {
                      discriminator: "dynamicNumber",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicNumber"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: number): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicVoid": {
                    return {
                      discriminator: "dynamicVoid",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicVoid"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                    };
                  }
                  case "channel": {
                    return {
                      discriminator: "channel",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                        return {
                          discriminator: ((value: "channel"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "channel"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "primary-skill": {
                    return {
                      discriminator: "primary-skill",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                        return {
                          discriminator: ((value: "primary-skill"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "primary-skill"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "skill": {
                    return {
                      discriminator: "skill",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                        return {
                          discriminator: ((value: "skill"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "skill"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "key-quality": {
                    return {
                      discriminator: "key-quality",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                        return {
                          discriminator: ((value: "key-quality"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "key-quality"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "classification": {
                    return {
                      discriminator: "classification",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                        return {
                          discriminator: ((value: "classification"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "classification"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                }
              },
            );
          })(value["tags"]),
          title: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["title"]),
        };
      })(value["filter"]),
      views: ((value: ("READONLY" | "LEARNING_ITEM_VIEW" | "TAG_VIEW" | "CHANNEL_VIEW")[]): unknown => {
        return value.map((value: "READONLY" | "LEARNING_ITEM_VIEW" | "TAG_VIEW" | "CHANNEL_VIEW"): unknown => {
          return value;
        });
      })(value["views"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LearningItemAdminProps(str: string): LearningItemAdminProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          filter: LearningItemAdminFilter;
          views: ("READONLY" | "LEARNING_ITEM_VIEW" | "TAG_VIEW" | "CHANNEL_VIEW")[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "filter")) {
      return { success: false };
    }
    const parsed_filter = (() => {
      return ((value: unknown): { success: false } | { success: true; value: LearningItemAdminFilter } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                activatedAtRange: DateRange | null;
                active: boolean | null;
                audio: boolean | null;
                createdAtRange: DateRange | null;
                documentTypes: SummaryDocumentType[];
                ids: number[];
                itemTypes: LearningItemType[];
                languages: Language[];
                manager: string | null;
                missingTagKinds: TagKind[];
                publisher: string | null;
                resultSize: number;
                sourceTypes: SummarySourceType[];
                tagCount: number | null;
                tagKinds: TagKind[];
                tags: Tag[];
                title: string | null;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "activatedAtRange")) {
            return { success: false };
          }
          const parsed_activatedAtRange = (() => {
            return ((value: unknown): { success: false } | { success: true; value: DateRange | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      from: { year: number; month: number; day: number };
                      to: { year: number; month: number; day: number };
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "from")) {
                  return { success: false };
                }
                const parsed_from = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                    if (!parsed.isValid) {
                      return { success: false };
                    }
                    return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                  })(value["from"]);
                })();
                if (!parsed_from.success) {
                  return { success: false };
                }
                if (!hasProp(value, "to")) {
                  return { success: false };
                }
                const parsed_to = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                    if (!parsed.isValid) {
                      return { success: false };
                    }
                    return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                  })(value["to"]);
                })();
                if (!parsed_to.success) {
                  return { success: false };
                }
                return { success: true, value: { from: parsed_from.value, to: parsed_to.value } };
              })(value);
            })(value["activatedAtRange"]);
          })();
          if (!parsed_activatedAtRange.success) {
            return { success: false };
          }
          if (!hasProp(value, "active")) {
            return { success: false };
          }
          const parsed_active = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["active"]);
          })();
          if (!parsed_active.success) {
            return { success: false };
          }
          if (!hasProp(value, "audio")) {
            return { success: false };
          }
          const parsed_audio = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["audio"]);
          })();
          if (!parsed_audio.success) {
            return { success: false };
          }
          if (!hasProp(value, "createdAtRange")) {
            return { success: false };
          }
          const parsed_createdAtRange = (() => {
            return ((value: unknown): { success: false } | { success: true; value: DateRange | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      from: { year: number; month: number; day: number };
                      to: { year: number; month: number; day: number };
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "from")) {
                  return { success: false };
                }
                const parsed_from = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                    if (!parsed.isValid) {
                      return { success: false };
                    }
                    return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                  })(value["from"]);
                })();
                if (!parsed_from.success) {
                  return { success: false };
                }
                if (!hasProp(value, "to")) {
                  return { success: false };
                }
                const parsed_to = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                    if (!parsed.isValid) {
                      return { success: false };
                    }
                    return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                  })(value["to"]);
                })();
                if (!parsed_to.success) {
                  return { success: false };
                }
                return { success: true, value: { from: parsed_from.value, to: parsed_to.value } };
              })(value);
            })(value["createdAtRange"]);
          })();
          if (!parsed_createdAtRange.success) {
            return { success: false };
          }
          if (!hasProp(value, "documentTypes")) {
            return { success: false };
          }
          const parsed_documentTypes = (() => {
            return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
                return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "ABSTRACT":
                      return { success: true, value: "ABSTRACT" };
                    case "REVIEW":
                      return { success: true, value: "REVIEW" };
                    default:
                      return { success: false };
                  }
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["documentTypes"]);
          })();
          if (!parsed_documentTypes.success) {
            return { success: false };
          }
          if (!hasProp(value, "ids")) {
            return { success: false };
          }
          const parsed_ids = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["ids"]);
          })();
          if (!parsed_ids.success) {
            return { success: false };
          }
          if (!hasProp(value, "itemTypes")) {
            return { success: false };
          }
          const parsed_itemTypes = (() => {
            return ((value: unknown): { success: false } | { success: true; value: LearningItemType[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: LearningItemType } => {
                return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" | "ACTIONABLE" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "SUMMARY":
                      return { success: true, value: "SUMMARY" };
                    case "ACTIONABLE":
                      return { success: true, value: "ACTIONABLE" };
                    default:
                      return { success: false };
                  }
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["itemTypes"]);
          })();
          if (!parsed_itemTypes.success) {
            return { success: false };
          }
          if (!hasProp(value, "languages")) {
            return { success: false };
          }
          const parsed_languages = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Language[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: Language } => {
                return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "en":
                      return { success: true, value: "en" };
                    case "de":
                      return { success: true, value: "de" };
                    case "es":
                      return { success: true, value: "es" };
                    case "ru":
                      return { success: true, value: "ru" };
                    case "zh":
                      return { success: true, value: "zh" };
                    case "pt":
                      return { success: true, value: "pt" };
                    case "fr":
                      return { success: true, value: "fr" };
                    default:
                      return { success: false };
                  }
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["languages"]);
          })();
          if (!parsed_languages.success) {
            return { success: false };
          }
          if (!hasProp(value, "manager")) {
            return { success: false };
          }
          const parsed_manager = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["manager"]);
          })();
          if (!parsed_manager.success) {
            return { success: false };
          }
          if (!hasProp(value, "missingTagKinds")) {
            return { success: false };
          }
          const parsed_missingTagKinds = (() => {
            return ((value: unknown): { success: false } | { success: true; value: TagKind[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: TagKind } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | { discriminator: "classification"; id: bigint; kind: KindClassification }
                        | { discriminator: "channel"; id: bigint; kind: KindChannel }
                        | { discriminator: "primary-skill"; id: bigint; kind: KindPrimarySkill }
                        | { discriminator: "skill"; id: bigint; kind: KindSkill }
                        | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
                        | { discriminator: "dynamicVoid"; id: bigint; kind: string }
                        | { discriminator: "dynamicString"; id: bigint; kind: string }
                        | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
                        | { discriminator: "dynamicNumber"; id: bigint; kind: string };
                    } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value:
                          | { discriminator: "classification"; id: bigint; kind: KindClassification }
                          | { discriminator: "channel"; id: bigint; kind: KindChannel }
                          | { discriminator: "primary-skill"; id: bigint; kind: KindPrimarySkill }
                          | { discriminator: "skill"; id: bigint; kind: KindSkill }
                          | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
                          | { discriminator: "dynamicVoid"; id: bigint; kind: string }
                          | { discriminator: "dynamicString"; id: bigint; kind: string }
                          | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
                          | { discriminator: "dynamicNumber"; id: bigint; kind: string };
                      } => {
                    if (value == null) {
                      return { success: false };
                    }
                    if (typeof value != "object") {
                      return { success: false };
                    }
                    if (!hasProp(value, "discriminator")) {
                      return { success: false };
                    }
                    if (typeof value["discriminator"] != "string") {
                      return { success: false };
                    }
                    const discriminator = value["discriminator"];
                    switch (discriminator) {
                      case "classification": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                id: bigint;
                                kind: KindClassification;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                              return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "classification":
                                    return { success: true, value: "classification" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                      }
                      case "channel": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                id: bigint;
                                kind: KindChannel;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                              return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "channel":
                                    return { success: true, value: "channel" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                      }
                      case "primary-skill": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                id: bigint;
                                kind: KindPrimarySkill;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "primary-skill":
                                    return { success: true, value: "primary-skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                      }
                      case "skill": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                id: bigint;
                                kind: KindSkill;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "skill":
                                    return { success: true, value: "skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                      }
                      case "key-quality": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                id: bigint;
                                kind: KindKeyQuality;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                              return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "key-quality":
                                    return { success: true, value: "key-quality" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                      }
                      case "dynamicVoid": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                      }
                      case "dynamicString": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                      }
                      case "dynamicBoolean": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                      }
                      case "dynamicNumber": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                      }
                      default:
                        return { success: false };
                    }
                  })(value);
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["missingTagKinds"]);
          })();
          if (!parsed_missingTagKinds.success) {
            return { success: false };
          }
          if (!hasProp(value, "publisher")) {
            return { success: false };
          }
          const parsed_publisher = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["publisher"]);
          })();
          if (!parsed_publisher.success) {
            return { success: false };
          }
          if (!hasProp(value, "resultSize")) {
            return { success: false };
          }
          const parsed_resultSize = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["resultSize"]);
          })();
          if (!parsed_resultSize.success) {
            return { success: false };
          }
          if (!hasProp(value, "sourceTypes")) {
            return { success: false };
          }
          const parsed_sourceTypes = (() => {
            return ((value: unknown): { success: false } | { success: true; value: SummarySourceType[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
                return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "BOOK":
                      return { success: true, value: "BOOK" };
                    case "VIDEO":
                      return { success: true, value: "VIDEO" };
                    case "ARTICLE":
                      return { success: true, value: "ARTICLE" };
                    case "CHAPTER":
                      return { success: true, value: "CHAPTER" };
                    case "REPORT":
                      return { success: true, value: "REPORT" };
                    case "PODCAST":
                      return { success: true, value: "PODCAST" };
                    default:
                      return { success: false };
                  }
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["sourceTypes"]);
          })();
          if (!parsed_sourceTypes.success) {
            return { success: false };
          }
          if (!hasProp(value, "tagCount")) {
            return { success: false };
          }
          const parsed_tagCount = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["tagCount"]);
          })();
          if (!parsed_tagCount.success) {
            return { success: false };
          }
          if (!hasProp(value, "tagKinds")) {
            return { success: false };
          }
          const parsed_tagKinds = (() => {
            return ((value: unknown): { success: false } | { success: true; value: TagKind[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: TagKind } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | { discriminator: "classification"; id: bigint; kind: KindClassification }
                        | { discriminator: "channel"; id: bigint; kind: KindChannel }
                        | { discriminator: "primary-skill"; id: bigint; kind: KindPrimarySkill }
                        | { discriminator: "skill"; id: bigint; kind: KindSkill }
                        | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
                        | { discriminator: "dynamicVoid"; id: bigint; kind: string }
                        | { discriminator: "dynamicString"; id: bigint; kind: string }
                        | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
                        | { discriminator: "dynamicNumber"; id: bigint; kind: string };
                    } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value:
                          | { discriminator: "classification"; id: bigint; kind: KindClassification }
                          | { discriminator: "channel"; id: bigint; kind: KindChannel }
                          | { discriminator: "primary-skill"; id: bigint; kind: KindPrimarySkill }
                          | { discriminator: "skill"; id: bigint; kind: KindSkill }
                          | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
                          | { discriminator: "dynamicVoid"; id: bigint; kind: string }
                          | { discriminator: "dynamicString"; id: bigint; kind: string }
                          | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
                          | { discriminator: "dynamicNumber"; id: bigint; kind: string };
                      } => {
                    if (value == null) {
                      return { success: false };
                    }
                    if (typeof value != "object") {
                      return { success: false };
                    }
                    if (!hasProp(value, "discriminator")) {
                      return { success: false };
                    }
                    if (typeof value["discriminator"] != "string") {
                      return { success: false };
                    }
                    const discriminator = value["discriminator"];
                    switch (discriminator) {
                      case "classification": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                id: bigint;
                                kind: KindClassification;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                              return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "classification":
                                    return { success: true, value: "classification" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                      }
                      case "channel": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                id: bigint;
                                kind: KindChannel;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                              return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "channel":
                                    return { success: true, value: "channel" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                      }
                      case "primary-skill": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                id: bigint;
                                kind: KindPrimarySkill;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "primary-skill":
                                    return { success: true, value: "primary-skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                      }
                      case "skill": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                id: bigint;
                                kind: KindSkill;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "skill":
                                    return { success: true, value: "skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                      }
                      case "key-quality": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                id: bigint;
                                kind: KindKeyQuality;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                              return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "key-quality":
                                    return { success: true, value: "key-quality" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                      }
                      case "dynamicVoid": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                      }
                      case "dynamicString": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                      }
                      case "dynamicBoolean": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                      }
                      case "dynamicNumber": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                      }
                      default:
                        return { success: false };
                    }
                  })(value);
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["tagKinds"]);
          })();
          if (!parsed_tagKinds.success) {
            return { success: false };
          }
          if (!hasProp(value, "tags")) {
            return { success: false };
          }
          const parsed_tags = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                        | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                        | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                        | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                        | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                        | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                        | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                        | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                        | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                    } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value:
                          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                          | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                          | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                      } => {
                    if (value == null) {
                      return { success: false };
                    }
                    if (typeof value != "object") {
                      return { success: false };
                    }
                    if (!hasProp(value, "discriminator")) {
                      return { success: false };
                    }
                    if (typeof value["discriminator"] != "string") {
                      return { success: false };
                    }
                    const discriminator = value["discriminator"];
                    switch (discriminator) {
                      case "dynamicString": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicString;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicString;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicString":
                                          return { success: true, value: "dynamicString" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                      }
                      case "dynamicBoolean": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicBoolean;
                                value: boolean;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicBoolean;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicBoolean":
                                          return { success: true, value: "dynamicBoolean" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                      }
                      case "dynamicNumber": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicNumber;
                                value: number;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicNumber;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicNumber":
                                          return { success: true, value: "dynamicNumber" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: number } => {
                              if (typeof value === "number") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                      }
                      case "dynamicVoid": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicVoid;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicVoid;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicVoid":
                                          return { success: true, value: "dynamicVoid" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                      }
                      case "channel": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindChannel;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindChannel;
                                      id: bigint;
                                      kind: KindChannel;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "channel":
                                          return { success: true, value: "channel" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "channel":
                                          return { success: true, value: "channel" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                      }
                      case "primary-skill": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindPrimarySkill;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindPrimarySkill;
                                      id: bigint;
                                      kind: KindPrimarySkill;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "primary-skill":
                                          return { success: true, value: "primary-skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "primary-skill":
                                          return { success: true, value: "primary-skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                      }
                      case "skill": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindSkill;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindSkill;
                                      id: bigint;
                                      kind: KindSkill;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "skill":
                                          return { success: true, value: "skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "skill":
                                          return { success: true, value: "skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                      }
                      case "key-quality": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindKeyQuality;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindKeyQuality;
                                      id: bigint;
                                      kind: KindKeyQuality;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "key-quality":
                                          return { success: true, value: "key-quality" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "key-quality":
                                          return { success: true, value: "key-quality" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                      }
                      case "classification": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindClassification;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindClassification;
                                      id: bigint;
                                      kind: KindClassification;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "classification":
                                          return { success: true, value: "classification" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "classification":
                                          return { success: true, value: "classification" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                      }
                      default:
                        return { success: false };
                    }
                  })(value);
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["tags"]);
          })();
          if (!parsed_tags.success) {
            return { success: false };
          }
          if (!hasProp(value, "title")) {
            return { success: false };
          }
          const parsed_title = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["title"]);
          })();
          if (!parsed_title.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              activatedAtRange: parsed_activatedAtRange.value,
              active: parsed_active.value,
              audio: parsed_audio.value,
              createdAtRange: parsed_createdAtRange.value,
              documentTypes: parsed_documentTypes.value,
              ids: parsed_ids.value,
              itemTypes: parsed_itemTypes.value,
              languages: parsed_languages.value,
              manager: parsed_manager.value,
              missingTagKinds: parsed_missingTagKinds.value,
              publisher: parsed_publisher.value,
              resultSize: parsed_resultSize.value,
              sourceTypes: parsed_sourceTypes.value,
              tagCount: parsed_tagCount.value,
              tagKinds: parsed_tagKinds.value,
              tags: parsed_tags.value,
              title: parsed_title.value,
            },
          };
        })(value);
      })(value["filter"]);
    })();
    if (!parsed_filter.success) {
      return { success: false };
    }
    if (!hasProp(value, "views")) {
      return { success: false };
    }
    const parsed_views = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ("READONLY" | "LEARNING_ITEM_VIEW" | "TAG_VIEW" | "CHANNEL_VIEW")[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: "READONLY" | "LEARNING_ITEM_VIEW" | "TAG_VIEW" | "CHANNEL_VIEW" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "READONLY":
              return { success: true, value: "READONLY" };
            case "LEARNING_ITEM_VIEW":
              return { success: true, value: "LEARNING_ITEM_VIEW" };
            case "TAG_VIEW":
              return { success: true, value: "TAG_VIEW" };
            case "CHANNEL_VIEW":
              return { success: true, value: "CHANNEL_VIEW" };
            default:
              return { success: false };
          }
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["views"]);
    })();
    if (!parsed_views.success) {
      return { success: false };
    }
    return { success: true, value: { filter: parsed_filter.value, views: parsed_views.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LearningItemAdminSummaryView
export function serialize_LearningItemAdminSummaryView(obj: LearningItemAdminSummaryView): string {
  const serialized = ((value: {
    audioActive: boolean;
    documentType: SummaryDocumentType;
    learningItemType: LearningItemTypeSummary;
    metadata: LearningItemAdminMetadataView;
    publisher: string | null;
    sourceType: SummarySourceType;
  }): unknown => {
    return {
      audioActive: ((value: boolean): unknown => {
        return value;
      })(value["audioActive"]),
      documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
        return value;
      })(value["documentType"]),
      learningItemType: ((value: "SUMMARY"): unknown => {
        return value;
      })(value["learningItemType"]),
      metadata: ((value: {
        activatedAt: { year: number; month: number; day: number } | null;
        active: boolean;
        createdAt: { year: number; month: number; day: number };
        deactivatedAt: { year: number; month: number; day: number } | null;
        id: bigint;
        language: Language;
        manager: string | null;
        tags: Tag[];
        title: string;
      }): unknown => {
        return {
          activatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
            if (value === null) {
              return null;
            }
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["activatedAt"]),
          active: ((value: boolean): unknown => {
            return value;
          })(value["active"]),
          createdAt: ((value: { year: number; month: number; day: number }): unknown => {
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["createdAt"]),
          deactivatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
            if (value === null) {
              return null;
            }
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["deactivatedAt"]),
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
          language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
            return value;
          })(value["language"]),
          manager: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["manager"]),
          tags: ((value: Tag[]): unknown => {
            return value.map(
              (
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                  | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
              ): unknown => {
                switch (value.discriminator) {
                  case "dynamicString": {
                    return {
                      discriminator: "dynamicString",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicString"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicBoolean": {
                    return {
                      discriminator: "dynamicBoolean",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicBoolean"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: boolean): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicNumber": {
                    return {
                      discriminator: "dynamicNumber",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicNumber"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: number): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicVoid": {
                    return {
                      discriminator: "dynamicVoid",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicVoid"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                    };
                  }
                  case "channel": {
                    return {
                      discriminator: "channel",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                        return {
                          discriminator: ((value: "channel"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "channel"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "primary-skill": {
                    return {
                      discriminator: "primary-skill",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                        return {
                          discriminator: ((value: "primary-skill"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "primary-skill"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "skill": {
                    return {
                      discriminator: "skill",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                        return {
                          discriminator: ((value: "skill"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "skill"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "key-quality": {
                    return {
                      discriminator: "key-quality",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                        return {
                          discriminator: ((value: "key-quality"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "key-quality"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "classification": {
                    return {
                      discriminator: "classification",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                        return {
                          discriminator: ((value: "classification"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "classification"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                }
              },
            );
          })(value["tags"]),
          title: ((value: string): unknown => {
            return value;
          })(value["title"]),
        };
      })(value["metadata"]),
      publisher: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["publisher"]),
      sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
        return value;
      })(value["sourceType"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LearningItemAdminSummaryView(str: string): LearningItemAdminSummaryView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          audioActive: boolean;
          documentType: SummaryDocumentType;
          learningItemType: LearningItemTypeSummary;
          metadata: LearningItemAdminMetadataView;
          publisher: string | null;
          sourceType: SummarySourceType;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "audioActive")) {
      return { success: false };
    }
    const parsed_audioActive = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["audioActive"]);
    })();
    if (!parsed_audioActive.success) {
      return { success: false };
    }
    if (!hasProp(value, "documentType")) {
      return { success: false };
    }
    const parsed_documentType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
        return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "ABSTRACT":
              return { success: true, value: "ABSTRACT" };
            case "REVIEW":
              return { success: true, value: "REVIEW" };
            default:
              return { success: false };
          }
        })(value);
      })(value["documentType"]);
    })();
    if (!parsed_documentType.success) {
      return { success: false };
    }
    if (!hasProp(value, "learningItemType")) {
      return { success: false };
    }
    const parsed_learningItemType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: LearningItemTypeSummary } => {
        return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "SUMMARY":
              return { success: true, value: "SUMMARY" };
            default:
              return { success: false };
          }
        })(value);
      })(value["learningItemType"]);
    })();
    if (!parsed_learningItemType.success) {
      return { success: false };
    }
    if (!hasProp(value, "metadata")) {
      return { success: false };
    }
    const parsed_metadata = (() => {
      return ((value: unknown): { success: false } | { success: true; value: LearningItemAdminMetadataView } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                activatedAt: { year: number; month: number; day: number } | null;
                active: boolean;
                createdAt: { year: number; month: number; day: number };
                deactivatedAt: { year: number; month: number; day: number } | null;
                id: bigint;
                language: Language;
                manager: string | null;
                tags: Tag[];
                title: string;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "activatedAt")) {
            return { success: false };
          }
          const parsed_activatedAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["activatedAt"]);
          })();
          if (!parsed_activatedAt.success) {
            return { success: false };
          }
          if (!hasProp(value, "active")) {
            return { success: false };
          }
          const parsed_active = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["active"]);
          })();
          if (!parsed_active.success) {
            return { success: false };
          }
          if (!hasProp(value, "createdAt")) {
            return { success: false };
          }
          const parsed_createdAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["createdAt"]);
          })();
          if (!parsed_createdAt.success) {
            return { success: false };
          }
          if (!hasProp(value, "deactivatedAt")) {
            return { success: false };
          }
          const parsed_deactivatedAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["deactivatedAt"]);
          })();
          if (!parsed_deactivatedAt.success) {
            return { success: false };
          }
          if (!hasProp(value, "id")) {
            return { success: false };
          }
          const parsed_id = (() => {
            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
              if (typeof value === "number") {
                return { success: true, value: BigInt(value) };
              }
              if (typeof value === "bigint") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["id"]);
          })();
          if (!parsed_id.success) {
            return { success: false };
          }
          if (!hasProp(value, "language")) {
            return { success: false };
          }
          const parsed_language = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Language } => {
              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "en":
                    return { success: true, value: "en" };
                  case "de":
                    return { success: true, value: "de" };
                  case "es":
                    return { success: true, value: "es" };
                  case "ru":
                    return { success: true, value: "ru" };
                  case "zh":
                    return { success: true, value: "zh" };
                  case "pt":
                    return { success: true, value: "pt" };
                  case "fr":
                    return { success: true, value: "fr" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["language"]);
          })();
          if (!parsed_language.success) {
            return { success: false };
          }
          if (!hasProp(value, "manager")) {
            return { success: false };
          }
          const parsed_manager = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["manager"]);
          })();
          if (!parsed_manager.success) {
            return { success: false };
          }
          if (!hasProp(value, "tags")) {
            return { success: false };
          }
          const parsed_tags = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                        | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                        | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                        | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                        | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                        | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                        | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                        | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                        | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                    } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value:
                          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                          | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                          | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                      } => {
                    if (value == null) {
                      return { success: false };
                    }
                    if (typeof value != "object") {
                      return { success: false };
                    }
                    if (!hasProp(value, "discriminator")) {
                      return { success: false };
                    }
                    if (typeof value["discriminator"] != "string") {
                      return { success: false };
                    }
                    const discriminator = value["discriminator"];
                    switch (discriminator) {
                      case "dynamicString": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicString;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicString;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicString":
                                          return { success: true, value: "dynamicString" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                      }
                      case "dynamicBoolean": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicBoolean;
                                value: boolean;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicBoolean;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicBoolean":
                                          return { success: true, value: "dynamicBoolean" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                      }
                      case "dynamicNumber": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicNumber;
                                value: number;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicNumber;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicNumber":
                                          return { success: true, value: "dynamicNumber" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: number } => {
                              if (typeof value === "number") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                      }
                      case "dynamicVoid": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicVoid;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicVoid;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicVoid":
                                          return { success: true, value: "dynamicVoid" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                      }
                      case "channel": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindChannel;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindChannel;
                                      id: bigint;
                                      kind: KindChannel;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "channel":
                                          return { success: true, value: "channel" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "channel":
                                          return { success: true, value: "channel" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                      }
                      case "primary-skill": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindPrimarySkill;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindPrimarySkill;
                                      id: bigint;
                                      kind: KindPrimarySkill;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "primary-skill":
                                          return { success: true, value: "primary-skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "primary-skill":
                                          return { success: true, value: "primary-skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                      }
                      case "skill": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindSkill;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindSkill;
                                      id: bigint;
                                      kind: KindSkill;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "skill":
                                          return { success: true, value: "skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "skill":
                                          return { success: true, value: "skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                      }
                      case "key-quality": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindKeyQuality;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindKeyQuality;
                                      id: bigint;
                                      kind: KindKeyQuality;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "key-quality":
                                          return { success: true, value: "key-quality" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "key-quality":
                                          return { success: true, value: "key-quality" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                      }
                      case "classification": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindClassification;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindClassification;
                                      id: bigint;
                                      kind: KindClassification;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "classification":
                                          return { success: true, value: "classification" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "classification":
                                          return { success: true, value: "classification" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                      }
                      default:
                        return { success: false };
                    }
                  })(value);
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["tags"]);
          })();
          if (!parsed_tags.success) {
            return { success: false };
          }
          if (!hasProp(value, "title")) {
            return { success: false };
          }
          const parsed_title = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["title"]);
          })();
          if (!parsed_title.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              activatedAt: parsed_activatedAt.value,
              active: parsed_active.value,
              createdAt: parsed_createdAt.value,
              deactivatedAt: parsed_deactivatedAt.value,
              id: parsed_id.value,
              language: parsed_language.value,
              manager: parsed_manager.value,
              tags: parsed_tags.value,
              title: parsed_title.value,
            },
          };
        })(value);
      })(value["metadata"]);
    })();
    if (!parsed_metadata.success) {
      return { success: false };
    }
    if (!hasProp(value, "publisher")) {
      return { success: false };
    }
    const parsed_publisher = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["publisher"]);
    })();
    if (!parsed_publisher.success) {
      return { success: false };
    }
    if (!hasProp(value, "sourceType")) {
      return { success: false };
    }
    const parsed_sourceType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
        return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "BOOK":
              return { success: true, value: "BOOK" };
            case "VIDEO":
              return { success: true, value: "VIDEO" };
            case "ARTICLE":
              return { success: true, value: "ARTICLE" };
            case "CHAPTER":
              return { success: true, value: "CHAPTER" };
            case "REPORT":
              return { success: true, value: "REPORT" };
            case "PODCAST":
              return { success: true, value: "PODCAST" };
            default:
              return { success: false };
          }
        })(value);
      })(value["sourceType"]);
    })();
    if (!parsed_sourceType.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        audioActive: parsed_audioActive.value,
        documentType: parsed_documentType.value,
        learningItemType: parsed_learningItemType.value,
        metadata: parsed_metadata.value,
        publisher: parsed_publisher.value,
        sourceType: parsed_sourceType.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LearningItemAdminView
export function serialize_LearningItemAdminView(obj: LearningItemAdminView): string {
  const serialized = ((
    value:
      | { learningItemType: "ACTIONABLE"; description: string; metadata: LearningItemAdminMetadataView }
      | { learningItemType: "SUMMARY"; audioActive: boolean; documentType: SummaryDocumentType; metadata: LearningItemAdminMetadataView; publisher: string | null; sourceType: SummarySourceType },
  ): unknown => {
    switch (value.learningItemType) {
      case "ACTIONABLE": {
        return {
          learningItemType: "ACTIONABLE",
          description: ((value: string): unknown => {
            return value;
          })(value["description"]),
          metadata: ((value: {
            activatedAt: { year: number; month: number; day: number } | null;
            active: boolean;
            createdAt: { year: number; month: number; day: number };
            deactivatedAt: { year: number; month: number; day: number } | null;
            id: bigint;
            language: Language;
            manager: string | null;
            tags: Tag[];
            title: string;
          }): unknown => {
            return {
              activatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
                if (value === null) {
                  return null;
                }
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["activatedAt"]),
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              createdAt: ((value: { year: number; month: number; day: number }): unknown => {
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["createdAt"]),
              deactivatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
                if (value === null) {
                  return null;
                }
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["deactivatedAt"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                return value;
              })(value["language"]),
              manager: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["manager"]),
              tags: ((value: Tag[]): unknown => {
                return value.map(
                  (
                    value:
                      | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                      | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                      | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                      | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                      | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                      | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                      | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                      | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                      | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
                  ): unknown => {
                    switch (value.discriminator) {
                      case "dynamicString": {
                        return {
                          discriminator: "dynamicString",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicString"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicBoolean": {
                        return {
                          discriminator: "dynamicBoolean",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicBoolean"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: boolean): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicNumber": {
                        return {
                          discriminator: "dynamicNumber",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicNumber"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: number): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicVoid": {
                        return {
                          discriminator: "dynamicVoid",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicVoid"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                        };
                      }
                      case "channel": {
                        return {
                          discriminator: "channel",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                            return {
                              discriminator: ((value: "channel"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "channel"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "primary-skill": {
                        return {
                          discriminator: "primary-skill",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                            return {
                              discriminator: ((value: "primary-skill"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "primary-skill"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "skill": {
                        return {
                          discriminator: "skill",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                            return {
                              discriminator: ((value: "skill"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "skill"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "key-quality": {
                        return {
                          discriminator: "key-quality",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                            return {
                              discriminator: ((value: "key-quality"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "key-quality"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "classification": {
                        return {
                          discriminator: "classification",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                            return {
                              discriminator: ((value: "classification"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "classification"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                    }
                  },
                );
              })(value["tags"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
            };
          })(value["metadata"]),
        };
      }
      case "SUMMARY": {
        return {
          learningItemType: "SUMMARY",
          audioActive: ((value: boolean): unknown => {
            return value;
          })(value["audioActive"]),
          documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
            return value;
          })(value["documentType"]),
          metadata: ((value: {
            activatedAt: { year: number; month: number; day: number } | null;
            active: boolean;
            createdAt: { year: number; month: number; day: number };
            deactivatedAt: { year: number; month: number; day: number } | null;
            id: bigint;
            language: Language;
            manager: string | null;
            tags: Tag[];
            title: string;
          }): unknown => {
            return {
              activatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
                if (value === null) {
                  return null;
                }
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["activatedAt"]),
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              createdAt: ((value: { year: number; month: number; day: number }): unknown => {
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["createdAt"]),
              deactivatedAt: ((value: { year: number; month: number; day: number } | null): unknown => {
                if (value === null) {
                  return null;
                }
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["deactivatedAt"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                return value;
              })(value["language"]),
              manager: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["manager"]),
              tags: ((value: Tag[]): unknown => {
                return value.map(
                  (
                    value:
                      | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                      | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                      | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                      | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                      | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                      | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                      | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                      | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                      | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
                  ): unknown => {
                    switch (value.discriminator) {
                      case "dynamicString": {
                        return {
                          discriminator: "dynamicString",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicString"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicBoolean": {
                        return {
                          discriminator: "dynamicBoolean",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicBoolean"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: boolean): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicNumber": {
                        return {
                          discriminator: "dynamicNumber",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicNumber"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: number): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicVoid": {
                        return {
                          discriminator: "dynamicVoid",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicVoid"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                        };
                      }
                      case "channel": {
                        return {
                          discriminator: "channel",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                            return {
                              discriminator: ((value: "channel"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "channel"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "primary-skill": {
                        return {
                          discriminator: "primary-skill",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                            return {
                              discriminator: ((value: "primary-skill"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "primary-skill"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "skill": {
                        return {
                          discriminator: "skill",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                            return {
                              discriminator: ((value: "skill"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "skill"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "key-quality": {
                        return {
                          discriminator: "key-quality",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                            return {
                              discriminator: ((value: "key-quality"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "key-quality"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "classification": {
                        return {
                          discriminator: "classification",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                            return {
                              discriminator: ((value: "classification"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "classification"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                    }
                  },
                );
              })(value["tags"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
            };
          })(value["metadata"]),
          publisher: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["publisher"]),
          sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
            return value;
          })(value["sourceType"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LearningItemAdminView(str: string): LearningItemAdminView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value:
          | { learningItemType: "ACTIONABLE"; description: string; metadata: LearningItemAdminMetadataView }
          | { learningItemType: "SUMMARY"; audioActive: boolean; documentType: SummaryDocumentType; metadata: LearningItemAdminMetadataView; publisher: string | null; sourceType: SummarySourceType };
      } => {
    return ((
      value: unknown,
    ):
      | { success: false }
      | {
          success: true;
          value:
            | { learningItemType: "ACTIONABLE"; description: string; metadata: LearningItemAdminMetadataView }
            | { learningItemType: "SUMMARY"; audioActive: boolean; documentType: SummaryDocumentType; metadata: LearningItemAdminMetadataView; publisher: string | null; sourceType: SummarySourceType };
        } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "learningItemType")) {
        return { success: false };
      }
      if (typeof value["learningItemType"] != "string") {
        return { success: false };
      }
      const discriminator = value["learningItemType"];
      switch (discriminator) {
        case "ACTIONABLE": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  description: string;
                  metadata: LearningItemAdminMetadataView;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "description")) {
              return { success: false };
            }
            const parsed_description = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["description"]);
            })();
            if (!parsed_description.success) {
              return { success: false };
            }
            if (!hasProp(value, "metadata")) {
              return { success: false };
            }
            const parsed_metadata = (() => {
              return ((value: unknown): { success: false } | { success: true; value: LearningItemAdminMetadataView } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        activatedAt: { year: number; month: number; day: number } | null;
                        active: boolean;
                        createdAt: { year: number; month: number; day: number };
                        deactivatedAt: { year: number; month: number; day: number } | null;
                        id: bigint;
                        language: Language;
                        manager: string | null;
                        tags: Tag[];
                        title: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "activatedAt")) {
                    return { success: false };
                  }
                  const parsed_activatedAt = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                    })(value["activatedAt"]);
                  })();
                  if (!parsed_activatedAt.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "active")) {
                    return { success: false };
                  }
                  const parsed_active = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["active"]);
                  })();
                  if (!parsed_active.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "createdAt")) {
                    return { success: false };
                  }
                  const parsed_createdAt = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                    })(value["createdAt"]);
                  })();
                  if (!parsed_createdAt.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "deactivatedAt")) {
                    return { success: false };
                  }
                  const parsed_deactivatedAt = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                    })(value["deactivatedAt"]);
                  })();
                  if (!parsed_deactivatedAt.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "language")) {
                    return { success: false };
                  }
                  const parsed_language = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: Language } => {
                      return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "en":
                            return { success: true, value: "en" };
                          case "de":
                            return { success: true, value: "de" };
                          case "es":
                            return { success: true, value: "es" };
                          case "ru":
                            return { success: true, value: "ru" };
                          case "zh":
                            return { success: true, value: "zh" };
                          case "pt":
                            return { success: true, value: "pt" };
                          case "fr":
                            return { success: true, value: "fr" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["language"]);
                  })();
                  if (!parsed_language.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "manager")) {
                    return { success: false };
                  }
                  const parsed_manager = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["manager"]);
                  })();
                  if (!parsed_manager.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tags")) {
                    return { success: false };
                  }
                  const parsed_tags = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
                      if (!isArray(value)) {
                        return { success: false };
                      }
                      const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value:
                                | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                                | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                                | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                                | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                                | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                                | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                                | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                                | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                                | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                            } => {
                          return ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value:
                                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                                  | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                                  | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                              } => {
                            if (value == null) {
                              return { success: false };
                            }
                            if (typeof value != "object") {
                              return { success: false };
                            }
                            if (!hasProp(value, "discriminator")) {
                              return { success: false };
                            }
                            if (typeof value["discriminator"] != "string") {
                              return { success: false };
                            }
                            const discriminator = value["discriminator"];
                            switch (discriminator) {
                              case "dynamicString": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindDynamicString;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindDynamicString;
                                              id: bigint;
                                              kind: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "dynamicString":
                                                  return { success: true, value: "dynamicString" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                              }
                              case "dynamicBoolean": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindDynamicBoolean;
                                        value: boolean;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindDynamicBoolean;
                                              id: bigint;
                                              kind: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "dynamicBoolean":
                                                  return { success: true, value: "dynamicBoolean" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                              }
                              case "dynamicNumber": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindDynamicNumber;
                                        value: number;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindDynamicNumber;
                                              id: bigint;
                                              kind: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "dynamicNumber":
                                                  return { success: true, value: "dynamicNumber" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                              }
                              case "dynamicVoid": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindDynamicVoid;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindDynamicVoid;
                                              id: bigint;
                                              kind: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "dynamicVoid":
                                                  return { success: true, value: "dynamicVoid" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                              }
                              case "channel": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindChannel;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindChannel;
                                              id: bigint;
                                              kind: KindChannel;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "channel":
                                                  return { success: true, value: "channel" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "channel":
                                                  return { success: true, value: "channel" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                              }
                              case "primary-skill": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindPrimarySkill;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindPrimarySkill;
                                              id: bigint;
                                              kind: KindPrimarySkill;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "primary-skill":
                                                  return { success: true, value: "primary-skill" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "primary-skill":
                                                  return { success: true, value: "primary-skill" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                              }
                              case "skill": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindSkill;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindSkill;
                                              id: bigint;
                                              kind: KindSkill;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "skill":
                                                  return { success: true, value: "skill" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "skill":
                                                  return { success: true, value: "skill" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                              }
                              case "key-quality": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindKeyQuality;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindKeyQuality;
                                              id: bigint;
                                              kind: KindKeyQuality;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "key-quality":
                                                  return { success: true, value: "key-quality" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "key-quality":
                                                  return { success: true, value: "key-quality" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                              }
                              case "classification": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindClassification;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindClassification;
                                              id: bigint;
                                              kind: KindClassification;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "classification":
                                                  return { success: true, value: "classification" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "classification":
                                                  return { success: true, value: "classification" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                              }
                              default:
                                return { success: false };
                            }
                          })(value);
                        })(value);
                      });
                      if (parsed.find((value) => !value.success) !== undefined) {
                        return { success: false };
                      }
                      return {
                        success: true,
                        value: parsed.map((entry) => {
                          assert(entry.success == true);
                          return entry.value;
                        }),
                      };
                    })(value["tags"]);
                  })();
                  if (!parsed_tags.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "title")) {
                    return { success: false };
                  }
                  const parsed_title = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["title"]);
                  })();
                  if (!parsed_title.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      activatedAt: parsed_activatedAt.value,
                      active: parsed_active.value,
                      createdAt: parsed_createdAt.value,
                      deactivatedAt: parsed_deactivatedAt.value,
                      id: parsed_id.value,
                      language: parsed_language.value,
                      manager: parsed_manager.value,
                      tags: parsed_tags.value,
                      title: parsed_title.value,
                    },
                  };
                })(value);
              })(value["metadata"]);
            })();
            if (!parsed_metadata.success) {
              return { success: false };
            }
            return { success: true, value: { description: parsed_description.value, metadata: parsed_metadata.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { learningItemType: "ACTIONABLE" as const, ...parsedVariant.value } };
        }
        case "SUMMARY": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  audioActive: boolean;
                  documentType: SummaryDocumentType;
                  metadata: LearningItemAdminMetadataView;
                  publisher: string | null;
                  sourceType: SummarySourceType;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "audioActive")) {
              return { success: false };
            }
            const parsed_audioActive = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["audioActive"]);
            })();
            if (!parsed_audioActive.success) {
              return { success: false };
            }
            if (!hasProp(value, "documentType")) {
              return { success: false };
            }
            const parsed_documentType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
                return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "ABSTRACT":
                      return { success: true, value: "ABSTRACT" };
                    case "REVIEW":
                      return { success: true, value: "REVIEW" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["documentType"]);
            })();
            if (!parsed_documentType.success) {
              return { success: false };
            }
            if (!hasProp(value, "metadata")) {
              return { success: false };
            }
            const parsed_metadata = (() => {
              return ((value: unknown): { success: false } | { success: true; value: LearningItemAdminMetadataView } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        activatedAt: { year: number; month: number; day: number } | null;
                        active: boolean;
                        createdAt: { year: number; month: number; day: number };
                        deactivatedAt: { year: number; month: number; day: number } | null;
                        id: bigint;
                        language: Language;
                        manager: string | null;
                        tags: Tag[];
                        title: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "activatedAt")) {
                    return { success: false };
                  }
                  const parsed_activatedAt = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                    })(value["activatedAt"]);
                  })();
                  if (!parsed_activatedAt.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "active")) {
                    return { success: false };
                  }
                  const parsed_active = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["active"]);
                  })();
                  if (!parsed_active.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "createdAt")) {
                    return { success: false };
                  }
                  const parsed_createdAt = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                    })(value["createdAt"]);
                  })();
                  if (!parsed_createdAt.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "deactivatedAt")) {
                    return { success: false };
                  }
                  const parsed_deactivatedAt = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
                    })(value["deactivatedAt"]);
                  })();
                  if (!parsed_deactivatedAt.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "language")) {
                    return { success: false };
                  }
                  const parsed_language = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: Language } => {
                      return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "en":
                            return { success: true, value: "en" };
                          case "de":
                            return { success: true, value: "de" };
                          case "es":
                            return { success: true, value: "es" };
                          case "ru":
                            return { success: true, value: "ru" };
                          case "zh":
                            return { success: true, value: "zh" };
                          case "pt":
                            return { success: true, value: "pt" };
                          case "fr":
                            return { success: true, value: "fr" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["language"]);
                  })();
                  if (!parsed_language.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "manager")) {
                    return { success: false };
                  }
                  const parsed_manager = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["manager"]);
                  })();
                  if (!parsed_manager.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tags")) {
                    return { success: false };
                  }
                  const parsed_tags = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
                      if (!isArray(value)) {
                        return { success: false };
                      }
                      const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value:
                                | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                                | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                                | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                                | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                                | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                                | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                                | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                                | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                                | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                            } => {
                          return ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value:
                                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                                  | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                                  | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                              } => {
                            if (value == null) {
                              return { success: false };
                            }
                            if (typeof value != "object") {
                              return { success: false };
                            }
                            if (!hasProp(value, "discriminator")) {
                              return { success: false };
                            }
                            if (typeof value["discriminator"] != "string") {
                              return { success: false };
                            }
                            const discriminator = value["discriminator"];
                            switch (discriminator) {
                              case "dynamicString": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindDynamicString;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindDynamicString;
                                              id: bigint;
                                              kind: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "dynamicString":
                                                  return { success: true, value: "dynamicString" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                              }
                              case "dynamicBoolean": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindDynamicBoolean;
                                        value: boolean;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindDynamicBoolean;
                                              id: bigint;
                                              kind: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "dynamicBoolean":
                                                  return { success: true, value: "dynamicBoolean" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                              }
                              case "dynamicNumber": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindDynamicNumber;
                                        value: number;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindDynamicNumber;
                                              id: bigint;
                                              kind: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "dynamicNumber":
                                                  return { success: true, value: "dynamicNumber" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                              }
                              case "dynamicVoid": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindDynamicVoid;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindDynamicVoid;
                                              id: bigint;
                                              kind: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "dynamicVoid":
                                                  return { success: true, value: "dynamicVoid" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                              }
                              case "channel": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindChannel;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindChannel;
                                              id: bigint;
                                              kind: KindChannel;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "channel":
                                                  return { success: true, value: "channel" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "channel":
                                                  return { success: true, value: "channel" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                              }
                              case "primary-skill": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindPrimarySkill;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindPrimarySkill;
                                              id: bigint;
                                              kind: KindPrimarySkill;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "primary-skill":
                                                  return { success: true, value: "primary-skill" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "primary-skill":
                                                  return { success: true, value: "primary-skill" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                              }
                              case "skill": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindSkill;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindSkill;
                                              id: bigint;
                                              kind: KindSkill;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "skill":
                                                  return { success: true, value: "skill" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "skill":
                                                  return { success: true, value: "skill" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                              }
                              case "key-quality": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindKeyQuality;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindKeyQuality;
                                              id: bigint;
                                              kind: KindKeyQuality;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "key-quality":
                                                  return { success: true, value: "key-quality" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "key-quality":
                                                  return { success: true, value: "key-quality" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                              }
                              case "classification": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindClassification;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindClassification;
                                              id: bigint;
                                              kind: KindClassification;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "classification":
                                                  return { success: true, value: "classification" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "classification":
                                                  return { success: true, value: "classification" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                              }
                              default:
                                return { success: false };
                            }
                          })(value);
                        })(value);
                      });
                      if (parsed.find((value) => !value.success) !== undefined) {
                        return { success: false };
                      }
                      return {
                        success: true,
                        value: parsed.map((entry) => {
                          assert(entry.success == true);
                          return entry.value;
                        }),
                      };
                    })(value["tags"]);
                  })();
                  if (!parsed_tags.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "title")) {
                    return { success: false };
                  }
                  const parsed_title = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["title"]);
                  })();
                  if (!parsed_title.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      activatedAt: parsed_activatedAt.value,
                      active: parsed_active.value,
                      createdAt: parsed_createdAt.value,
                      deactivatedAt: parsed_deactivatedAt.value,
                      id: parsed_id.value,
                      language: parsed_language.value,
                      manager: parsed_manager.value,
                      tags: parsed_tags.value,
                      title: parsed_title.value,
                    },
                  };
                })(value);
              })(value["metadata"]);
            })();
            if (!parsed_metadata.success) {
              return { success: false };
            }
            if (!hasProp(value, "publisher")) {
              return { success: false };
            }
            const parsed_publisher = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["publisher"]);
            })();
            if (!parsed_publisher.success) {
              return { success: false };
            }
            if (!hasProp(value, "sourceType")) {
              return { success: false };
            }
            const parsed_sourceType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
                return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "BOOK":
                      return { success: true, value: "BOOK" };
                    case "VIDEO":
                      return { success: true, value: "VIDEO" };
                    case "ARTICLE":
                      return { success: true, value: "ARTICLE" };
                    case "CHAPTER":
                      return { success: true, value: "CHAPTER" };
                    case "REPORT":
                      return { success: true, value: "REPORT" };
                    case "PODCAST":
                      return { success: true, value: "PODCAST" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["sourceType"]);
            })();
            if (!parsed_sourceType.success) {
              return { success: false };
            }
            return { success: true, value: { audioActive: parsed_audioActive.value, documentType: parsed_documentType.value, metadata: parsed_metadata.value, publisher: parsed_publisher.value, sourceType: parsed_sourceType.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { learningItemType: "SUMMARY" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LearningItemType
export function serialize_LearningItemType(obj: LearningItemType): string {
  const serialized = ((value: "SUMMARY" | "ACTIONABLE"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LearningItemType(str: string): LearningItemType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "SUMMARY" | "ACTIONABLE" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "SUMMARY":
        return { success: true, value: "SUMMARY" };
      case "ACTIONABLE":
        return { success: true, value: "ACTIONABLE" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LearningItemTypeActionable
export function serialize_LearningItemTypeActionable(obj: LearningItemTypeActionable): string {
  const serialized = ((value: "ACTIONABLE"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LearningItemTypeActionable(str: string): LearningItemTypeActionable | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "ACTIONABLE":
        return { success: true, value: "ACTIONABLE" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LearningItemTypeSummary
export function serialize_LearningItemTypeSummary(obj: LearningItemTypeSummary): string {
  const serialized = ((value: "SUMMARY"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LearningItemTypeSummary(str: string): LearningItemTypeSummary | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "SUMMARY" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "SUMMARY":
        return { success: true, value: "SUMMARY" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LgxpId
export function serialize_LgxpId(obj: LgxpId): string {
  const serialized = ((value: { contentType: "SKETCHNOTE"; id: bigint }): unknown => {
    return {
      contentType: ((value: "SKETCHNOTE"): unknown => {
        return value;
      })(value["contentType"]),
      id: ((value: bigint): unknown => {
        return value;
      })(value["id"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LgxpId(str: string): LgxpId | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          contentType: "SKETCHNOTE";
          id: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "contentType")) {
      return { success: false };
    }
    const parsed_contentType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "SKETCHNOTE" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "SKETCHNOTE":
            return { success: true, value: "SKETCHNOTE" };
          default:
            return { success: false };
        }
      })(value["contentType"]);
    })();
    if (!parsed_contentType.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// LsvPaymentView
export function serialize_LsvPaymentView(obj: LsvPaymentView): string {
  const serialized = ((value: {
    accountNumber: string | null;
    acqAuthorizationCode: string | null;
    authorizationCode: string | null;
    bankIban: string | null;
    bankName: string | null;
    bankTown: string | null;
    clearingNumber: string | null;
    firstName: string;
    lastName: string;
    merchantId: string | null;
    refNumber: string | null;
    transactionId: number;
  }): unknown => {
    return {
      accountNumber: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["accountNumber"]),
      acqAuthorizationCode: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["acqAuthorizationCode"]),
      authorizationCode: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["authorizationCode"]),
      bankIban: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["bankIban"]),
      bankName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["bankName"]),
      bankTown: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["bankTown"]),
      clearingNumber: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["clearingNumber"]),
      firstName: ((value: string): unknown => {
        return value;
      })(value["firstName"]),
      lastName: ((value: string): unknown => {
        return value;
      })(value["lastName"]),
      merchantId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["merchantId"]),
      refNumber: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["refNumber"]),
      transactionId: ((value: number): unknown => {
        return value;
      })(value["transactionId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_LsvPaymentView(str: string): LsvPaymentView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          accountNumber: string | null;
          acqAuthorizationCode: string | null;
          authorizationCode: string | null;
          bankIban: string | null;
          bankName: string | null;
          bankTown: string | null;
          clearingNumber: string | null;
          firstName: string;
          lastName: string;
          merchantId: string | null;
          refNumber: string | null;
          transactionId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "accountNumber")) {
      return { success: false };
    }
    const parsed_accountNumber = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["accountNumber"]);
    })();
    if (!parsed_accountNumber.success) {
      return { success: false };
    }
    if (!hasProp(value, "acqAuthorizationCode")) {
      return { success: false };
    }
    const parsed_acqAuthorizationCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["acqAuthorizationCode"]);
    })();
    if (!parsed_acqAuthorizationCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "authorizationCode")) {
      return { success: false };
    }
    const parsed_authorizationCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["authorizationCode"]);
    })();
    if (!parsed_authorizationCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "bankIban")) {
      return { success: false };
    }
    const parsed_bankIban = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["bankIban"]);
    })();
    if (!parsed_bankIban.success) {
      return { success: false };
    }
    if (!hasProp(value, "bankName")) {
      return { success: false };
    }
    const parsed_bankName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["bankName"]);
    })();
    if (!parsed_bankName.success) {
      return { success: false };
    }
    if (!hasProp(value, "bankTown")) {
      return { success: false };
    }
    const parsed_bankTown = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["bankTown"]);
    })();
    if (!parsed_bankTown.success) {
      return { success: false };
    }
    if (!hasProp(value, "clearingNumber")) {
      return { success: false };
    }
    const parsed_clearingNumber = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["clearingNumber"]);
    })();
    if (!parsed_clearingNumber.success) {
      return { success: false };
    }
    if (!hasProp(value, "firstName")) {
      return { success: false };
    }
    const parsed_firstName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["firstName"]);
    })();
    if (!parsed_firstName.success) {
      return { success: false };
    }
    if (!hasProp(value, "lastName")) {
      return { success: false };
    }
    const parsed_lastName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lastName"]);
    })();
    if (!parsed_lastName.success) {
      return { success: false };
    }
    if (!hasProp(value, "merchantId")) {
      return { success: false };
    }
    const parsed_merchantId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["merchantId"]);
    })();
    if (!parsed_merchantId.success) {
      return { success: false };
    }
    if (!hasProp(value, "refNumber")) {
      return { success: false };
    }
    const parsed_refNumber = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["refNumber"]);
    })();
    if (!parsed_refNumber.success) {
      return { success: false };
    }
    if (!hasProp(value, "transactionId")) {
      return { success: false };
    }
    const parsed_transactionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["transactionId"]);
    })();
    if (!parsed_transactionId.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        accountNumber: parsed_accountNumber.value,
        acqAuthorizationCode: parsed_acqAuthorizationCode.value,
        authorizationCode: parsed_authorizationCode.value,
        bankIban: parsed_bankIban.value,
        bankName: parsed_bankName.value,
        bankTown: parsed_bankTown.value,
        clearingNumber: parsed_clearingNumber.value,
        firstName: parsed_firstName.value,
        lastName: parsed_lastName.value,
        merchantId: parsed_merchantId.value,
        refNumber: parsed_refNumber.value,
        transactionId: parsed_transactionId.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// MIABAnalyticsFeedbackEventForm
export function serialize_MIABAnalyticsFeedbackEventForm(obj: MIABAnalyticsFeedbackEventForm): string {
  const serialized = ((value: { isPositive: boolean; miabId: bigint }): unknown => {
    return {
      isPositive: ((value: boolean): unknown => {
        return value;
      })(value["isPositive"]),
      miabId: ((value: bigint): unknown => {
        return value;
      })(value["miabId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_MIABAnalyticsFeedbackEventForm(str: string): MIABAnalyticsFeedbackEventForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          isPositive: boolean;
          miabId: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "isPositive")) {
      return { success: false };
    }
    const parsed_isPositive = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isPositive"]);
    })();
    if (!parsed_isPositive.success) {
      return { success: false };
    }
    if (!hasProp(value, "miabId")) {
      return { success: false };
    }
    const parsed_miabId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["miabId"]);
    })();
    if (!parsed_miabId.success) {
      return { success: false };
    }
    return { success: true, value: { isPositive: parsed_isPositive.value, miabId: parsed_miabId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// MIABContent
export function serialize_MIABContent(obj: MIABContent): string {
  const serialized = ((value: { data: MIABContentData; miabId: bigint }): unknown => {
    return {
      data: ((value: { createdAt: DateTime<true>; customerId: number | null; keyInsights: string[]; questions: MIABQuestionMetadata[]; summaryId: number }): unknown => {
        return {
          createdAt: ((value: DateTime<true>): unknown => {
            return value.toMillis();
          })(value["createdAt"]),
          customerId: ((value: number | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["customerId"]),
          keyInsights: ((value: string[]): unknown => {
            return value.map((value: string): unknown => {
              return value;
            });
          })(value["keyInsights"]),
          questions: ((value: MIABQuestionMetadata[]): unknown => {
            return value.map((value: { createdAt: DateTime<true> | null; question: string; sortOrder: number; title: string }): unknown => {
              return {
                createdAt: ((value: DateTime<true> | null): unknown => {
                  if (value === null) {
                    return null;
                  }
                  return value.toMillis();
                })(value["createdAt"]),
                question: ((value: string): unknown => {
                  return value;
                })(value["question"]),
                sortOrder: ((value: number): unknown => {
                  return value;
                })(value["sortOrder"]),
                title: ((value: string): unknown => {
                  return value;
                })(value["title"]),
              };
            });
          })(value["questions"]),
          summaryId: ((value: number): unknown => {
            return value;
          })(value["summaryId"]),
        };
      })(value["data"]),
      miabId: ((value: bigint): unknown => {
        return value;
      })(value["miabId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_MIABContent(str: string): MIABContent | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          data: MIABContentData;
          miabId: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "data")) {
      return { success: false };
    }
    const parsed_data = (() => {
      return ((value: unknown): { success: false } | { success: true; value: MIABContentData } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                createdAt: DateTime<true>;
                customerId: number | null;
                keyInsights: string[];
                questions: MIABQuestionMetadata[];
                summaryId: number;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "createdAt")) {
            return { success: false };
          }
          const parsed_createdAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
              if (typeof value !== "number") {
                return { success: false };
              }
              const parsed = DateTime.fromMillis(value);
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: parsed };
            })(value["createdAt"]);
          })();
          if (!parsed_createdAt.success) {
            return { success: false };
          }
          if (!hasProp(value, "customerId")) {
            return { success: false };
          }
          const parsed_customerId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["customerId"]);
          })();
          if (!parsed_customerId.success) {
            return { success: false };
          }
          if (!hasProp(value, "keyInsights")) {
            return { success: false };
          }
          const parsed_keyInsights = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["keyInsights"]);
          })();
          if (!parsed_keyInsights.success) {
            return { success: false };
          }
          if (!hasProp(value, "questions")) {
            return { success: false };
          }
          const parsed_questions = (() => {
            return ((value: unknown): { success: false } | { success: true; value: MIABQuestionMetadata[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: MIABQuestionMetadata } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        createdAt: DateTime<true> | null;
                        question: string;
                        sortOrder: number;
                        title: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "createdAt")) {
                    return { success: false };
                  }
                  const parsed_createdAt = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "number") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromMillis(value);
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: parsed };
                    })(value["createdAt"]);
                  })();
                  if (!parsed_createdAt.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "question")) {
                    return { success: false };
                  }
                  const parsed_question = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["question"]);
                  })();
                  if (!parsed_question.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "sortOrder")) {
                    return { success: false };
                  }
                  const parsed_sortOrder = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["sortOrder"]);
                  })();
                  if (!parsed_sortOrder.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "title")) {
                    return { success: false };
                  }
                  const parsed_title = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["title"]);
                  })();
                  if (!parsed_title.success) {
                    return { success: false };
                  }
                  return { success: true, value: { createdAt: parsed_createdAt.value, question: parsed_question.value, sortOrder: parsed_sortOrder.value, title: parsed_title.value } };
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["questions"]);
          })();
          if (!parsed_questions.success) {
            return { success: false };
          }
          if (!hasProp(value, "summaryId")) {
            return { success: false };
          }
          const parsed_summaryId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["summaryId"]);
          })();
          if (!parsed_summaryId.success) {
            return { success: false };
          }
          return { success: true, value: { createdAt: parsed_createdAt.value, customerId: parsed_customerId.value, keyInsights: parsed_keyInsights.value, questions: parsed_questions.value, summaryId: parsed_summaryId.value } };
        })(value);
      })(value["data"]);
    })();
    if (!parsed_data.success) {
      return { success: false };
    }
    if (!hasProp(value, "miabId")) {
      return { success: false };
    }
    const parsed_miabId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["miabId"]);
    })();
    if (!parsed_miabId.success) {
      return { success: false };
    }
    return { success: true, value: { data: parsed_data.value, miabId: parsed_miabId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// MIABContentData
export function serialize_MIABContentData(obj: MIABContentData): string {
  const serialized = ((value: { createdAt: DateTime<true>; customerId: number | null; keyInsights: string[]; questions: MIABQuestionMetadata[]; summaryId: number }): unknown => {
    return {
      createdAt: ((value: DateTime<true>): unknown => {
        return value.toMillis();
      })(value["createdAt"]),
      customerId: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["customerId"]),
      keyInsights: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["keyInsights"]),
      questions: ((value: MIABQuestionMetadata[]): unknown => {
        return value.map((value: { createdAt: DateTime<true> | null; question: string; sortOrder: number; title: string }): unknown => {
          return {
            createdAt: ((value: DateTime<true> | null): unknown => {
              if (value === null) {
                return null;
              }
              return value.toMillis();
            })(value["createdAt"]),
            question: ((value: string): unknown => {
              return value;
            })(value["question"]),
            sortOrder: ((value: number): unknown => {
              return value;
            })(value["sortOrder"]),
            title: ((value: string): unknown => {
              return value;
            })(value["title"]),
          };
        });
      })(value["questions"]),
      summaryId: ((value: number): unknown => {
        return value;
      })(value["summaryId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_MIABContentData(str: string): MIABContentData | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          createdAt: DateTime<true>;
          customerId: number | null;
          keyInsights: string[];
          questions: MIABQuestionMetadata[];
          summaryId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "createdAt")) {
      return { success: false };
    }
    const parsed_createdAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["createdAt"]);
    })();
    if (!parsed_createdAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "keyInsights")) {
      return { success: false };
    }
    const parsed_keyInsights = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["keyInsights"]);
    })();
    if (!parsed_keyInsights.success) {
      return { success: false };
    }
    if (!hasProp(value, "questions")) {
      return { success: false };
    }
    const parsed_questions = (() => {
      return ((value: unknown): { success: false } | { success: true; value: MIABQuestionMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: MIABQuestionMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  createdAt: DateTime<true> | null;
                  question: string;
                  sortOrder: number;
                  title: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "createdAt")) {
              return { success: false };
            }
            const parsed_createdAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["createdAt"]);
            })();
            if (!parsed_createdAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "question")) {
              return { success: false };
            }
            const parsed_question = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["question"]);
            })();
            if (!parsed_question.success) {
              return { success: false };
            }
            if (!hasProp(value, "sortOrder")) {
              return { success: false };
            }
            const parsed_sortOrder = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["sortOrder"]);
            })();
            if (!parsed_sortOrder.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            return { success: true, value: { createdAt: parsed_createdAt.value, question: parsed_question.value, sortOrder: parsed_sortOrder.value, title: parsed_title.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["questions"]);
    })();
    if (!parsed_questions.success) {
      return { success: false };
    }
    if (!hasProp(value, "summaryId")) {
      return { success: false };
    }
    const parsed_summaryId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["summaryId"]);
    })();
    if (!parsed_summaryId.success) {
      return { success: false };
    }
    return { success: true, value: { createdAt: parsed_createdAt.value, customerId: parsed_customerId.value, keyInsights: parsed_keyInsights.value, questions: parsed_questions.value, summaryId: parsed_summaryId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// MIABCustomQuestionsForm
export function serialize_MIABCustomQuestionsForm(obj: MIABCustomQuestionsForm): string {
  const serialized = ((value: { dataId: number; questions: MIABQuestionMetadata[] }): unknown => {
    return {
      dataId: ((value: number): unknown => {
        return value;
      })(value["dataId"]),
      questions: ((value: MIABQuestionMetadata[]): unknown => {
        return value.map((value: { createdAt: DateTime<true> | null; question: string; sortOrder: number; title: string }): unknown => {
          return {
            createdAt: ((value: DateTime<true> | null): unknown => {
              if (value === null) {
                return null;
              }
              return value.toMillis();
            })(value["createdAt"]),
            question: ((value: string): unknown => {
              return value;
            })(value["question"]),
            sortOrder: ((value: number): unknown => {
              return value;
            })(value["sortOrder"]),
            title: ((value: string): unknown => {
              return value;
            })(value["title"]),
          };
        });
      })(value["questions"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_MIABCustomQuestionsForm(str: string): MIABCustomQuestionsForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          dataId: number;
          questions: MIABQuestionMetadata[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "dataId")) {
      return { success: false };
    }
    const parsed_dataId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["dataId"]);
    })();
    if (!parsed_dataId.success) {
      return { success: false };
    }
    if (!hasProp(value, "questions")) {
      return { success: false };
    }
    const parsed_questions = (() => {
      return ((value: unknown): { success: false } | { success: true; value: MIABQuestionMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: MIABQuestionMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  createdAt: DateTime<true> | null;
                  question: string;
                  sortOrder: number;
                  title: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "createdAt")) {
              return { success: false };
            }
            const parsed_createdAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["createdAt"]);
            })();
            if (!parsed_createdAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "question")) {
              return { success: false };
            }
            const parsed_question = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["question"]);
            })();
            if (!parsed_question.success) {
              return { success: false };
            }
            if (!hasProp(value, "sortOrder")) {
              return { success: false };
            }
            const parsed_sortOrder = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["sortOrder"]);
            })();
            if (!parsed_sortOrder.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            return { success: true, value: { createdAt: parsed_createdAt.value, question: parsed_question.value, sortOrder: parsed_sortOrder.value, title: parsed_title.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["questions"]);
    })();
    if (!parsed_questions.success) {
      return { success: false };
    }
    return { success: true, value: { dataId: parsed_dataId.value, questions: parsed_questions.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// MIABCustomQuestionsProps
export function serialize_MIABCustomQuestionsProps(obj: MIABCustomQuestionsProps): string {
  const serialized = ((value: { createdAt: DateTime<true>; createdBy: string; customQuestions: MIABQuestionMetadata[]; customerId: number; hash: string }): unknown => {
    return {
      createdAt: ((value: DateTime<true>): unknown => {
        return value.toMillis();
      })(value["createdAt"]),
      createdBy: ((value: string): unknown => {
        return value;
      })(value["createdBy"]),
      customQuestions: ((value: MIABQuestionMetadata[]): unknown => {
        return value.map((value: { createdAt: DateTime<true> | null; question: string; sortOrder: number; title: string }): unknown => {
          return {
            createdAt: ((value: DateTime<true> | null): unknown => {
              if (value === null) {
                return null;
              }
              return value.toMillis();
            })(value["createdAt"]),
            question: ((value: string): unknown => {
              return value;
            })(value["question"]),
            sortOrder: ((value: number): unknown => {
              return value;
            })(value["sortOrder"]),
            title: ((value: string): unknown => {
              return value;
            })(value["title"]),
          };
        });
      })(value["customQuestions"]),
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
      hash: ((value: string): unknown => {
        return value;
      })(value["hash"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_MIABCustomQuestionsProps(str: string): MIABCustomQuestionsProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          createdAt: DateTime<true>;
          createdBy: string;
          customQuestions: MIABQuestionMetadata[];
          customerId: number;
          hash: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "createdAt")) {
      return { success: false };
    }
    const parsed_createdAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["createdAt"]);
    })();
    if (!parsed_createdAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "createdBy")) {
      return { success: false };
    }
    const parsed_createdBy = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["createdBy"]);
    })();
    if (!parsed_createdBy.success) {
      return { success: false };
    }
    if (!hasProp(value, "customQuestions")) {
      return { success: false };
    }
    const parsed_customQuestions = (() => {
      return ((value: unknown): { success: false } | { success: true; value: MIABQuestionMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: MIABQuestionMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  createdAt: DateTime<true> | null;
                  question: string;
                  sortOrder: number;
                  title: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "createdAt")) {
              return { success: false };
            }
            const parsed_createdAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["createdAt"]);
            })();
            if (!parsed_createdAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "question")) {
              return { success: false };
            }
            const parsed_question = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["question"]);
            })();
            if (!parsed_question.success) {
              return { success: false };
            }
            if (!hasProp(value, "sortOrder")) {
              return { success: false };
            }
            const parsed_sortOrder = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["sortOrder"]);
            })();
            if (!parsed_sortOrder.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            return { success: true, value: { createdAt: parsed_createdAt.value, question: parsed_question.value, sortOrder: parsed_sortOrder.value, title: parsed_title.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["customQuestions"]);
    })();
    if (!parsed_customQuestions.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "hash")) {
      return { success: false };
    }
    const parsed_hash = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["hash"]);
    })();
    if (!parsed_hash.success) {
      return { success: false };
    }
    return { success: true, value: { createdAt: parsed_createdAt.value, createdBy: parsed_createdBy.value, customQuestions: parsed_customQuestions.value, customerId: parsed_customerId.value, hash: parsed_hash.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// MIABCustomQuestionsResponse
export function serialize_MIABCustomQuestionsResponse(obj: MIABCustomQuestionsResponse): string {
  const serialized = ((value: { kind: "VALIDATION_ERROR"; validationErrorReasons: ValidationErrorReasons } | { kind: "CREATED"; hash: string; miabId: bigint } | { kind: "UPDATED"; miabId: bigint }): unknown => {
    switch (value.kind) {
      case "VALIDATION_ERROR": {
        return {
          kind: "VALIDATION_ERROR",
          validationErrorReasons: ((value: { fields: { additionalProperties: Record<string, string[]> }; general: string[] }): unknown => {
            return {
              fields: ((value: { additionalProperties: Record<string, string[]> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string[]): unknown => {
                          return value.map((value: string): unknown => {
                            return value;
                          });
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["fields"]),
              general: ((value: string[]): unknown => {
                return value.map((value: string): unknown => {
                  return value;
                });
              })(value["general"]),
            };
          })(value["validationErrorReasons"]),
        };
      }
      case "CREATED": {
        return {
          kind: "CREATED",
          hash: ((value: string): unknown => {
            return value;
          })(value["hash"]),
          miabId: ((value: bigint): unknown => {
            return value;
          })(value["miabId"]),
        };
      }
      case "UPDATED": {
        return {
          kind: "UPDATED",
          miabId: ((value: bigint): unknown => {
            return value;
          })(value["miabId"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_MIABCustomQuestionsResponse(str: string): MIABCustomQuestionsResponse | null {
  const parsed = ((
    value: unknown,
  ): { success: false } | { success: true; value: { kind: "VALIDATION_ERROR"; validationErrorReasons: ValidationErrorReasons } | { kind: "CREATED"; hash: string; miabId: bigint } | { kind: "UPDATED"; miabId: bigint } } => {
    return ((
      value: unknown,
    ): { success: false } | { success: true; value: { kind: "VALIDATION_ERROR"; validationErrorReasons: ValidationErrorReasons } | { kind: "CREATED"; hash: string; miabId: bigint } | { kind: "UPDATED"; miabId: bigint } } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "kind")) {
        return { success: false };
      }
      if (typeof value["kind"] != "string") {
        return { success: false };
      }
      const discriminator = value["kind"];
      switch (discriminator) {
        case "VALIDATION_ERROR": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  validationErrorReasons: ValidationErrorReasons;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "validationErrorReasons")) {
              return { success: false };
            }
            const parsed_validationErrorReasons = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ValidationErrorReasons } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        fields: { additionalProperties: Record<string, string[]> };
                        general: string[];
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "fields")) {
                    return { success: false };
                  }
                  const parsed_fields = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string[]> } } => {
                      if (typeof value !== "object") {
                        return { success: false };
                      }
                      if (value === null) {
                        return { success: false };
                      }
                      const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string[]> } => {
                        const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string[] }] => {
                          return [
                            key,
                            ((value: unknown): { success: false } | { success: true; value: string[] } => {
                              if (!isArray(value)) {
                                return { success: false };
                              }
                              const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              });
                              if (parsed.find((value) => !value.success) !== undefined) {
                                return { success: false };
                              }
                              return {
                                success: true,
                                value: parsed.map((entry) => {
                                  assert(entry.success == true);
                                  return entry.value;
                                }),
                              };
                            })(value),
                          ];
                        });
                        if (parsed.find(([_, value]) => !value.success) !== undefined) {
                          return { success: false };
                        }
                        return {
                          success: true,
                          value: Object.fromEntries(
                            parsed.map(([key, value]) => {
                              assert(value.success);
                              return [key, value.value];
                            }),
                          ),
                        };
                      })();
                      if (!parsed_additionalProperties.success) {
                        return { success: false };
                      }
                      return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
                    })(value["fields"]);
                  })();
                  if (!parsed_fields.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "general")) {
                    return { success: false };
                  }
                  const parsed_general = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                      if (!isArray(value)) {
                        return { success: false };
                      }
                      const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      });
                      if (parsed.find((value) => !value.success) !== undefined) {
                        return { success: false };
                      }
                      return {
                        success: true,
                        value: parsed.map((entry) => {
                          assert(entry.success == true);
                          return entry.value;
                        }),
                      };
                    })(value["general"]);
                  })();
                  if (!parsed_general.success) {
                    return { success: false };
                  }
                  return { success: true, value: { fields: parsed_fields.value, general: parsed_general.value } };
                })(value);
              })(value["validationErrorReasons"]);
            })();
            if (!parsed_validationErrorReasons.success) {
              return { success: false };
            }
            return { success: true, value: { validationErrorReasons: parsed_validationErrorReasons.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "VALIDATION_ERROR" as const, ...parsedVariant.value } };
        }
        case "CREATED": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  hash: string;
                  miabId: bigint;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "hash")) {
              return { success: false };
            }
            const parsed_hash = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["hash"]);
            })();
            if (!parsed_hash.success) {
              return { success: false };
            }
            if (!hasProp(value, "miabId")) {
              return { success: false };
            }
            const parsed_miabId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["miabId"]);
            })();
            if (!parsed_miabId.success) {
              return { success: false };
            }
            return { success: true, value: { hash: parsed_hash.value, miabId: parsed_miabId.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "CREATED" as const, ...parsedVariant.value } };
        }
        case "UPDATED": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  miabId: bigint;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "miabId")) {
              return { success: false };
            }
            const parsed_miabId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["miabId"]);
            })();
            if (!parsed_miabId.success) {
              return { success: false };
            }
            return { success: true, value: { miabId: parsed_miabId.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "UPDATED" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// MIABOverviewPageProps
export function serialize_MIABOverviewPageProps(obj: MIABOverviewPageProps): string {
  const serialized = ((value: { channels: ChannelMetadata[] }): unknown => {
    return {
      channels: ((value: ChannelMetadata[]): unknown => {
        return value.map(
          (value: {
            activationTime: DateTime<true> | null;
            active: boolean;
            channelId: bigint;
            channelLocalization: { additionalProperties: Record<string, ChannelTextLocalization> };
            coverUri: string | null;
            definition: string | null;
            descriptionHtml: string | null;
            modificationTime: DateTime<true> | null;
            name: string;
            synonyms: string | null;
            tag: Tag;
            title: string | null;
            visibleLanguages: Language[];
          }): unknown => {
            return {
              activationTime: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["activationTime"]),
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              channelId: ((value: bigint): unknown => {
                return value;
              })(value["channelId"]),
              channelLocalization: ((value: { additionalProperties: Record<string, ChannelTextLocalization> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: { description: string | null; synonym: string | null; title: string | null }): unknown => {
                          return {
                            description: ((value: string | null): unknown => {
                              if (value === null) {
                                return null;
                              }
                              return value;
                            })(value["description"]),
                            synonym: ((value: string | null): unknown => {
                              if (value === null) {
                                return null;
                              }
                              return value;
                            })(value["synonym"]),
                            title: ((value: string | null): unknown => {
                              if (value === null) {
                                return null;
                              }
                              return value;
                            })(value["title"]),
                          };
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["channelLocalization"]),
              coverUri: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["coverUri"]),
              definition: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["definition"]),
              descriptionHtml: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["descriptionHtml"]),
              modificationTime: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["modificationTime"]),
              name: ((value: string): unknown => {
                return value;
              })(value["name"]),
              synonyms: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["synonyms"]),
              tag: ((
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                  | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
              ): unknown => {
                switch (value.discriminator) {
                  case "dynamicString": {
                    return {
                      discriminator: "dynamicString",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicString"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicBoolean": {
                    return {
                      discriminator: "dynamicBoolean",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicBoolean"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: boolean): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicNumber": {
                    return {
                      discriminator: "dynamicNumber",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicNumber"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: number): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicVoid": {
                    return {
                      discriminator: "dynamicVoid",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicVoid"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                    };
                  }
                  case "channel": {
                    return {
                      discriminator: "channel",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                        return {
                          discriminator: ((value: "channel"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "channel"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "primary-skill": {
                    return {
                      discriminator: "primary-skill",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                        return {
                          discriminator: ((value: "primary-skill"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "primary-skill"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "skill": {
                    return {
                      discriminator: "skill",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                        return {
                          discriminator: ((value: "skill"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "skill"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "key-quality": {
                    return {
                      discriminator: "key-quality",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                        return {
                          discriminator: ((value: "key-quality"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "key-quality"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "classification": {
                    return {
                      discriminator: "classification",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                        return {
                          discriminator: ((value: "classification"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "classification"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                }
              })(value["tag"]),
              title: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["title"]),
              visibleLanguages: ((value: Language[]): unknown => {
                return value.map((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                  return value;
                });
              })(value["visibleLanguages"]),
            };
          },
        );
      })(value["channels"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_MIABOverviewPageProps(str: string): MIABOverviewPageProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          channels: ChannelMetadata[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "channels")) {
      return { success: false };
    }
    const parsed_channels = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ChannelMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ChannelMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  activationTime: DateTime<true> | null;
                  active: boolean;
                  channelId: bigint;
                  channelLocalization: { additionalProperties: Record<string, ChannelTextLocalization> };
                  coverUri: string | null;
                  definition: string | null;
                  descriptionHtml: string | null;
                  modificationTime: DateTime<true> | null;
                  name: string;
                  synonyms: string | null;
                  tag: Tag;
                  title: string | null;
                  visibleLanguages: Language[];
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "activationTime")) {
              return { success: false };
            }
            const parsed_activationTime = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["activationTime"]);
            })();
            if (!parsed_activationTime.success) {
              return { success: false };
            }
            if (!hasProp(value, "active")) {
              return { success: false };
            }
            const parsed_active = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["active"]);
            })();
            if (!parsed_active.success) {
              return { success: false };
            }
            if (!hasProp(value, "channelId")) {
              return { success: false };
            }
            const parsed_channelId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["channelId"]);
            })();
            if (!parsed_channelId.success) {
              return { success: false };
            }
            if (!hasProp(value, "channelLocalization")) {
              return { success: false };
            }
            const parsed_channelLocalization = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, ChannelTextLocalization> } } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, ChannelTextLocalization> } => {
                  const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: ChannelTextLocalization }] => {
                    return [
                      key,
                      ((value: unknown): { success: false } | { success: true; value: ChannelTextLocalization } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                description: string | null;
                                synonym: string | null;
                                title: string | null;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "synonym")) {
                            return { success: false };
                          }
                          const parsed_synonym = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["synonym"]);
                          })();
                          if (!parsed_synonym.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return { success: true, value: { description: parsed_description.value, synonym: parsed_synonym.value, title: parsed_title.value } };
                        })(value);
                      })(value),
                    ];
                  });
                  if (parsed.find(([_, value]) => !value.success) !== undefined) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: Object.fromEntries(
                      parsed.map(([key, value]) => {
                        assert(value.success);
                        return [key, value.value];
                      }),
                    ),
                  };
                })();
                if (!parsed_additionalProperties.success) {
                  return { success: false };
                }
                return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
              })(value["channelLocalization"]);
            })();
            if (!parsed_channelLocalization.success) {
              return { success: false };
            }
            if (!hasProp(value, "coverUri")) {
              return { success: false };
            }
            const parsed_coverUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["coverUri"]);
            })();
            if (!parsed_coverUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "definition")) {
              return { success: false };
            }
            const parsed_definition = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["definition"]);
            })();
            if (!parsed_definition.success) {
              return { success: false };
            }
            if (!hasProp(value, "descriptionHtml")) {
              return { success: false };
            }
            const parsed_descriptionHtml = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["descriptionHtml"]);
            })();
            if (!parsed_descriptionHtml.success) {
              return { success: false };
            }
            if (!hasProp(value, "modificationTime")) {
              return { success: false };
            }
            const parsed_modificationTime = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["modificationTime"]);
            })();
            if (!parsed_modificationTime.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "synonyms")) {
              return { success: false };
            }
            const parsed_synonyms = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["synonyms"]);
            })();
            if (!parsed_synonyms.success) {
              return { success: false };
            }
            if (!hasProp(value, "tag")) {
              return { success: false };
            }
            const parsed_tag = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Tag } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                        | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                        | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                        | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                        | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                        | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                        | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                        | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                        | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                    } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value:
                          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                          | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                          | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                      } => {
                    if (value == null) {
                      return { success: false };
                    }
                    if (typeof value != "object") {
                      return { success: false };
                    }
                    if (!hasProp(value, "discriminator")) {
                      return { success: false };
                    }
                    if (typeof value["discriminator"] != "string") {
                      return { success: false };
                    }
                    const discriminator = value["discriminator"];
                    switch (discriminator) {
                      case "dynamicString": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicString;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicString;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicString":
                                          return { success: true, value: "dynamicString" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                      }
                      case "dynamicBoolean": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicBoolean;
                                value: boolean;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicBoolean;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicBoolean":
                                          return { success: true, value: "dynamicBoolean" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                      }
                      case "dynamicNumber": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicNumber;
                                value: number;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicNumber;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicNumber":
                                          return { success: true, value: "dynamicNumber" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: number } => {
                              if (typeof value === "number") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                      }
                      case "dynamicVoid": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicVoid;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicVoid;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicVoid":
                                          return { success: true, value: "dynamicVoid" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                      }
                      case "channel": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindChannel;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindChannel;
                                      id: bigint;
                                      kind: KindChannel;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "channel":
                                          return { success: true, value: "channel" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "channel":
                                          return { success: true, value: "channel" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                      }
                      case "primary-skill": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindPrimarySkill;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindPrimarySkill;
                                      id: bigint;
                                      kind: KindPrimarySkill;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "primary-skill":
                                          return { success: true, value: "primary-skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "primary-skill":
                                          return { success: true, value: "primary-skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                      }
                      case "skill": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindSkill;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindSkill;
                                      id: bigint;
                                      kind: KindSkill;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "skill":
                                          return { success: true, value: "skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "skill":
                                          return { success: true, value: "skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                      }
                      case "key-quality": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindKeyQuality;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindKeyQuality;
                                      id: bigint;
                                      kind: KindKeyQuality;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "key-quality":
                                          return { success: true, value: "key-quality" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "key-quality":
                                          return { success: true, value: "key-quality" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                      }
                      case "classification": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindClassification;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindClassification;
                                      id: bigint;
                                      kind: KindClassification;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "classification":
                                          return { success: true, value: "classification" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "classification":
                                          return { success: true, value: "classification" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                      }
                      default:
                        return { success: false };
                    }
                  })(value);
                })(value);
              })(value["tag"]);
            })();
            if (!parsed_tag.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            if (!hasProp(value, "visibleLanguages")) {
              return { success: false };
            }
            const parsed_visibleLanguages = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Language[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: Language } => {
                  return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    switch (value) {
                      case "en":
                        return { success: true, value: "en" };
                      case "de":
                        return { success: true, value: "de" };
                      case "es":
                        return { success: true, value: "es" };
                      case "ru":
                        return { success: true, value: "ru" };
                      case "zh":
                        return { success: true, value: "zh" };
                      case "pt":
                        return { success: true, value: "pt" };
                      case "fr":
                        return { success: true, value: "fr" };
                      default:
                        return { success: false };
                    }
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["visibleLanguages"]);
            })();
            if (!parsed_visibleLanguages.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                activationTime: parsed_activationTime.value,
                active: parsed_active.value,
                channelId: parsed_channelId.value,
                channelLocalization: parsed_channelLocalization.value,
                coverUri: parsed_coverUri.value,
                definition: parsed_definition.value,
                descriptionHtml: parsed_descriptionHtml.value,
                modificationTime: parsed_modificationTime.value,
                name: parsed_name.value,
                synonyms: parsed_synonyms.value,
                tag: parsed_tag.value,
                title: parsed_title.value,
                visibleLanguages: parsed_visibleLanguages.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["channels"]);
    })();
    if (!parsed_channels.success) {
      return { success: false };
    }
    return { success: true, value: { channels: parsed_channels.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// MIABPageProps
export function serialize_MIABPageProps(obj: MIABPageProps): string {
  const serialized = ((value: {
    bookmarked: boolean;
    feedback: boolean | null;
    linkedMiab: ContentMetadataMiab | null;
    miab: MIABView;
    openEdit: boolean;
    regionalRestriction: {
      countriesByRegion: { additionalProperties: Record<string, string[]> };
      restrictionType: CountryRestrictionType;
    };
    similarMiabs: ContentMetadata[];
    userPortal: string | null;
  }): unknown => {
    return {
      bookmarked: ((value: boolean): unknown => {
        return value;
      })(value["bookmarked"]),
      feedback: ((value: boolean | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["feedback"]),
      linkedMiab: ((
        value: {
          common: ContentMetadataCommon;
          contentId: MiabId;
          contentType: "MIAB";
          customerId: number | null;
          dataId: number;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
            return {
              audioActive: ((value: boolean): unknown => {
                return value;
              })(value["audioActive"]),
              coverUri: ((value: string): unknown => {
                return value;
              })(value["coverUri"]),
              description: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["description"]),
              inactive: ((value: boolean): unknown => {
                return value;
              })(value["inactive"]),
              language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                return value;
              })(value["language"]),
              leadIn: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["leadIn"]),
              link: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["link"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
            };
          })(value["common"]),
          contentId: ((value: { contentType: "MIAB"; id: bigint }): unknown => {
            return {
              contentType: ((value: "MIAB"): unknown => {
                return value;
              })(value["contentType"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
            };
          })(value["contentId"]),
          contentType: ((value: "MIAB"): unknown => {
            return value;
          })(value["contentType"]),
          customerId: ((value: number | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["customerId"]),
          dataId: ((value: number): unknown => {
            return value;
          })(value["dataId"]),
        };
      })(value["linkedMiab"]),
      miab: ((value: { content: MIABContent; metadata: ContentMetadata; userContext: MIABUserContext | null }): unknown => {
        return {
          content: ((value: { data: MIABContentData; miabId: bigint }): unknown => {
            return {
              data: ((value: { createdAt: DateTime<true>; customerId: number | null; keyInsights: string[]; questions: MIABQuestionMetadata[]; summaryId: number }): unknown => {
                return {
                  createdAt: ((value: DateTime<true>): unknown => {
                    return value.toMillis();
                  })(value["createdAt"]),
                  customerId: ((value: number | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["customerId"]),
                  keyInsights: ((value: string[]): unknown => {
                    return value.map((value: string): unknown => {
                      return value;
                    });
                  })(value["keyInsights"]),
                  questions: ((value: MIABQuestionMetadata[]): unknown => {
                    return value.map((value: { createdAt: DateTime<true> | null; question: string; sortOrder: number; title: string }): unknown => {
                      return {
                        createdAt: ((value: DateTime<true> | null): unknown => {
                          if (value === null) {
                            return null;
                          }
                          return value.toMillis();
                        })(value["createdAt"]),
                        question: ((value: string): unknown => {
                          return value;
                        })(value["question"]),
                        sortOrder: ((value: number): unknown => {
                          return value;
                        })(value["sortOrder"]),
                        title: ((value: string): unknown => {
                          return value;
                        })(value["title"]),
                      };
                    });
                  })(value["questions"]),
                  summaryId: ((value: number): unknown => {
                    return value;
                  })(value["summaryId"]),
                };
              })(value["data"]),
              miabId: ((value: bigint): unknown => {
                return value;
              })(value["miabId"]),
            };
          })(value["content"]),
          metadata: ((
            value:
              | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
              | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
              | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
              | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number },
          ): unknown => {
            switch (value.contentType) {
              case "SUMMARY": {
                return {
                  contentType: "SUMMARY",
                  backgroundColor: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["backgroundColor"]),
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "SUMMARY"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "SUMMARY"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                  documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
                    return value;
                  })(value["documentType"]),
                  meetingInABoxAvailable: ((value: boolean): unknown => {
                    return value;
                  })(value["meetingInABoxAvailable"]),
                  sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
                    return value;
                  })(value["sourceType"]),
                };
              }
              case "ACTIONABLE": {
                return {
                  contentType: "ACTIONABLE",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "ACTIONABLE"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "ACTIONABLE"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                };
              }
              case "SKETCHNOTE": {
                return {
                  contentType: "SKETCHNOTE",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "SKETCHNOTE"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "SKETCHNOTE"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                };
              }
              case "MIAB": {
                return {
                  contentType: "MIAB",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "MIAB"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "MIAB"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                  customerId: ((value: number | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["customerId"]),
                  dataId: ((value: number): unknown => {
                    return value;
                  })(value["dataId"]),
                };
              }
            }
          })(value["metadata"]),
          userContext: ((
            value: {
              customerId: number;
              hash: string;
              username: string;
            } | null,
          ): unknown => {
            if (value === null) {
              return null;
            }
            return {
              customerId: ((value: number): unknown => {
                return value;
              })(value["customerId"]),
              hash: ((value: string): unknown => {
                return value;
              })(value["hash"]),
              username: ((value: string): unknown => {
                return value;
              })(value["username"]),
            };
          })(value["userContext"]),
        };
      })(value["miab"]),
      openEdit: ((value: boolean): unknown => {
        return value;
      })(value["openEdit"]),
      regionalRestriction: ((value: { countriesByRegion: { additionalProperties: Record<string, string[]> }; restrictionType: CountryRestrictionType }): unknown => {
        return {
          countriesByRegion: ((value: { additionalProperties: Record<string, string[]> }): unknown => {
            const unordered_obj: Record<string, unknown> = {
              ...Object.fromEntries(
                Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                  return [
                    propertyKey,
                    ((value: string[]): unknown => {
                      return value.map((value: string): unknown => {
                        return value;
                      });
                    })(propertyValue),
                  ];
                }),
              ),
            };
            return Object.keys(unordered_obj)
              .sort()
              .reduce(
                (obj, key) => {
                  obj[key] = unordered_obj[key];
                  return obj;
                },
                {} as Record<string, unknown>,
              );
          })(value["countriesByRegion"]),
          restrictionType: ((value: "INCLUDED" | "EXCLUDED" | "NO_RESTRICTION"): unknown => {
            return value;
          })(value["restrictionType"]),
        };
      })(value["regionalRestriction"]),
      similarMiabs: ((value: ContentMetadata[]): unknown => {
        return value.map(
          (
            value:
              | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
              | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
              | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
              | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number },
          ): unknown => {
            switch (value.contentType) {
              case "SUMMARY": {
                return {
                  contentType: "SUMMARY",
                  backgroundColor: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["backgroundColor"]),
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "SUMMARY"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "SUMMARY"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                  documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
                    return value;
                  })(value["documentType"]),
                  meetingInABoxAvailable: ((value: boolean): unknown => {
                    return value;
                  })(value["meetingInABoxAvailable"]),
                  sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
                    return value;
                  })(value["sourceType"]),
                };
              }
              case "ACTIONABLE": {
                return {
                  contentType: "ACTIONABLE",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "ACTIONABLE"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "ACTIONABLE"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                };
              }
              case "SKETCHNOTE": {
                return {
                  contentType: "SKETCHNOTE",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "SKETCHNOTE"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "SKETCHNOTE"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                };
              }
              case "MIAB": {
                return {
                  contentType: "MIAB",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "MIAB"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "MIAB"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                  customerId: ((value: number | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["customerId"]),
                  dataId: ((value: number): unknown => {
                    return value;
                  })(value["dataId"]),
                };
              }
            }
          },
        );
      })(value["similarMiabs"]),
      userPortal: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["userPortal"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_MIABPageProps(str: string): MIABPageProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          bookmarked: boolean;
          feedback: boolean | null;
          linkedMiab: ContentMetadataMiab | null;
          miab: MIABView;
          openEdit: boolean;
          regionalRestriction: {
            countriesByRegion: { additionalProperties: Record<string, string[]> };
            restrictionType: CountryRestrictionType;
          };
          similarMiabs: ContentMetadata[];
          userPortal: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "bookmarked")) {
      return { success: false };
    }
    const parsed_bookmarked = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["bookmarked"]);
    })();
    if (!parsed_bookmarked.success) {
      return { success: false };
    }
    if (!hasProp(value, "feedback")) {
      return { success: false };
    }
    const parsed_feedback = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["feedback"]);
    })();
    if (!parsed_feedback.success) {
      return { success: false };
    }
    if (!hasProp(value, "linkedMiab")) {
      return { success: false };
    }
    const parsed_linkedMiab = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataMiab | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                common: ContentMetadataCommon;
                contentId: MiabId;
                contentType: "MIAB";
                customerId: number | null;
                dataId: number;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "common")) {
            return { success: false };
          }
          const parsed_common = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      audioActive: boolean;
                      coverUri: string;
                      description: string | null;
                      inactive: boolean;
                      language: Language;
                      leadIn: string | null;
                      link: string | null;
                      title: string;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "audioActive")) {
                  return { success: false };
                }
                const parsed_audioActive = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                    if (typeof value !== "boolean") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["audioActive"]);
                })();
                if (!parsed_audioActive.success) {
                  return { success: false };
                }
                if (!hasProp(value, "coverUri")) {
                  return { success: false };
                }
                const parsed_coverUri = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["coverUri"]);
                })();
                if (!parsed_coverUri.success) {
                  return { success: false };
                }
                if (!hasProp(value, "description")) {
                  return { success: false };
                }
                const parsed_description = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["description"]);
                })();
                if (!parsed_description.success) {
                  return { success: false };
                }
                if (!hasProp(value, "inactive")) {
                  return { success: false };
                }
                const parsed_inactive = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                    if (typeof value !== "boolean") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["inactive"]);
                })();
                if (!parsed_inactive.success) {
                  return { success: false };
                }
                if (!hasProp(value, "language")) {
                  return { success: false };
                }
                const parsed_language = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: Language } => {
                    return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      switch (value) {
                        case "en":
                          return { success: true, value: "en" };
                        case "de":
                          return { success: true, value: "de" };
                        case "es":
                          return { success: true, value: "es" };
                        case "ru":
                          return { success: true, value: "ru" };
                        case "zh":
                          return { success: true, value: "zh" };
                        case "pt":
                          return { success: true, value: "pt" };
                        case "fr":
                          return { success: true, value: "fr" };
                        default:
                          return { success: false };
                      }
                    })(value);
                  })(value["language"]);
                })();
                if (!parsed_language.success) {
                  return { success: false };
                }
                if (!hasProp(value, "leadIn")) {
                  return { success: false };
                }
                const parsed_leadIn = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["leadIn"]);
                })();
                if (!parsed_leadIn.success) {
                  return { success: false };
                }
                if (!hasProp(value, "link")) {
                  return { success: false };
                }
                const parsed_link = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["link"]);
                })();
                if (!parsed_link.success) {
                  return { success: false };
                }
                if (!hasProp(value, "title")) {
                  return { success: false };
                }
                const parsed_title = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["title"]);
                })();
                if (!parsed_title.success) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: {
                    audioActive: parsed_audioActive.value,
                    coverUri: parsed_coverUri.value,
                    description: parsed_description.value,
                    inactive: parsed_inactive.value,
                    language: parsed_language.value,
                    leadIn: parsed_leadIn.value,
                    link: parsed_link.value,
                    title: parsed_title.value,
                  },
                };
              })(value);
            })(value["common"]);
          })();
          if (!parsed_common.success) {
            return { success: false };
          }
          if (!hasProp(value, "contentId")) {
            return { success: false };
          }
          const parsed_contentId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: MiabId } => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      contentType: "MIAB";
                      id: bigint;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "contentType")) {
                  return { success: false };
                }
                const parsed_contentType = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: "MIAB" } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    switch (value) {
                      case "MIAB":
                        return { success: true, value: "MIAB" };
                      default:
                        return { success: false };
                    }
                  })(value["contentType"]);
                })();
                if (!parsed_contentType.success) {
                  return { success: false };
                }
                if (!hasProp(value, "id")) {
                  return { success: false };
                }
                const parsed_id = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                    if (typeof value === "number") {
                      return { success: true, value: BigInt(value) };
                    }
                    if (typeof value === "bigint") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["id"]);
                })();
                if (!parsed_id.success) {
                  return { success: false };
                }
                return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
              })(value);
            })(value["contentId"]);
          })();
          if (!parsed_contentId.success) {
            return { success: false };
          }
          if (!hasProp(value, "contentType")) {
            return { success: false };
          }
          const parsed_contentType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: "MIAB" } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              switch (value) {
                case "MIAB":
                  return { success: true, value: "MIAB" };
                default:
                  return { success: false };
              }
            })(value["contentType"]);
          })();
          if (!parsed_contentType.success) {
            return { success: false };
          }
          if (!hasProp(value, "customerId")) {
            return { success: false };
          }
          const parsed_customerId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["customerId"]);
          })();
          if (!parsed_customerId.success) {
            return { success: false };
          }
          if (!hasProp(value, "dataId")) {
            return { success: false };
          }
          const parsed_dataId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["dataId"]);
          })();
          if (!parsed_dataId.success) {
            return { success: false };
          }
          return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value, contentType: parsed_contentType.value, customerId: parsed_customerId.value, dataId: parsed_dataId.value } };
        })(value);
      })(value["linkedMiab"]);
    })();
    if (!parsed_linkedMiab.success) {
      return { success: false };
    }
    if (!hasProp(value, "miab")) {
      return { success: false };
    }
    const parsed_miab = (() => {
      return ((value: unknown): { success: false } | { success: true; value: MIABView } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                content: MIABContent;
                metadata: ContentMetadata;
                userContext: MIABUserContext | null;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "content")) {
            return { success: false };
          }
          const parsed_content = (() => {
            return ((value: unknown): { success: false } | { success: true; value: MIABContent } => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      data: MIABContentData;
                      miabId: bigint;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "data")) {
                  return { success: false };
                }
                const parsed_data = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: MIABContentData } => {
                    return ((
                      value: unknown,
                    ):
                      | { success: false }
                      | {
                          success: true;
                          value: {
                            createdAt: DateTime<true>;
                            customerId: number | null;
                            keyInsights: string[];
                            questions: MIABQuestionMetadata[];
                            summaryId: number;
                          };
                        } => {
                      if (typeof value !== "object") {
                        return { success: false };
                      }
                      if (value === null) {
                        return { success: false };
                      }
                      if (!hasProp(value, "createdAt")) {
                        return { success: false };
                      }
                      const parsed_createdAt = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                          if (typeof value !== "number") {
                            return { success: false };
                          }
                          const parsed = DateTime.fromMillis(value);
                          if (!parsed.isValid) {
                            return { success: false };
                          }
                          return { success: true, value: parsed };
                        })(value["createdAt"]);
                      })();
                      if (!parsed_createdAt.success) {
                        return { success: false };
                      }
                      if (!hasProp(value, "customerId")) {
                        return { success: false };
                      }
                      const parsed_customerId = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                          if (value === null) {
                            return { success: true, value: null };
                          }
                          if (typeof value === "number") {
                            return { success: true, value: value };
                          }
                          return { success: false };
                        })(value["customerId"]);
                      })();
                      if (!parsed_customerId.success) {
                        return { success: false };
                      }
                      if (!hasProp(value, "keyInsights")) {
                        return { success: false };
                      }
                      const parsed_keyInsights = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                          if (!isArray(value)) {
                            return { success: false };
                          }
                          const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          });
                          if (parsed.find((value) => !value.success) !== undefined) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: parsed.map((entry) => {
                              assert(entry.success == true);
                              return entry.value;
                            }),
                          };
                        })(value["keyInsights"]);
                      })();
                      if (!parsed_keyInsights.success) {
                        return { success: false };
                      }
                      if (!hasProp(value, "questions")) {
                        return { success: false };
                      }
                      const parsed_questions = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: MIABQuestionMetadata[] } => {
                          if (!isArray(value)) {
                            return { success: false };
                          }
                          const parsed = value.map((value: unknown): { success: false } | { success: true; value: MIABQuestionMetadata } => {
                            return ((
                              value: unknown,
                            ):
                              | { success: false }
                              | {
                                  success: true;
                                  value: {
                                    createdAt: DateTime<true> | null;
                                    question: string;
                                    sortOrder: number;
                                    title: string;
                                  };
                                } => {
                              if (typeof value !== "object") {
                                return { success: false };
                              }
                              if (value === null) {
                                return { success: false };
                              }
                              if (!hasProp(value, "createdAt")) {
                                return { success: false };
                              }
                              const parsed_createdAt = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                                  if (value === null) {
                                    return { success: true, value: null };
                                  }
                                  if (typeof value !== "number") {
                                    return { success: false };
                                  }
                                  const parsed = DateTime.fromMillis(value);
                                  if (!parsed.isValid) {
                                    return { success: false };
                                  }
                                  return { success: true, value: parsed };
                                })(value["createdAt"]);
                              })();
                              if (!parsed_createdAt.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "question")) {
                                return { success: false };
                              }
                              const parsed_question = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["question"]);
                              })();
                              if (!parsed_question.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "sortOrder")) {
                                return { success: false };
                              }
                              const parsed_sortOrder = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: number } => {
                                  if (typeof value === "number") {
                                    return { success: true, value: value };
                                  }
                                  return { success: false };
                                })(value["sortOrder"]);
                              })();
                              if (!parsed_sortOrder.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "title")) {
                                return { success: false };
                              }
                              const parsed_title = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["title"]);
                              })();
                              if (!parsed_title.success) {
                                return { success: false };
                              }
                              return { success: true, value: { createdAt: parsed_createdAt.value, question: parsed_question.value, sortOrder: parsed_sortOrder.value, title: parsed_title.value } };
                            })(value);
                          });
                          if (parsed.find((value) => !value.success) !== undefined) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: parsed.map((entry) => {
                              assert(entry.success == true);
                              return entry.value;
                            }),
                          };
                        })(value["questions"]);
                      })();
                      if (!parsed_questions.success) {
                        return { success: false };
                      }
                      if (!hasProp(value, "summaryId")) {
                        return { success: false };
                      }
                      const parsed_summaryId = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: number } => {
                          if (typeof value === "number") {
                            return { success: true, value: value };
                          }
                          return { success: false };
                        })(value["summaryId"]);
                      })();
                      if (!parsed_summaryId.success) {
                        return { success: false };
                      }
                      return { success: true, value: { createdAt: parsed_createdAt.value, customerId: parsed_customerId.value, keyInsights: parsed_keyInsights.value, questions: parsed_questions.value, summaryId: parsed_summaryId.value } };
                    })(value);
                  })(value["data"]);
                })();
                if (!parsed_data.success) {
                  return { success: false };
                }
                if (!hasProp(value, "miabId")) {
                  return { success: false };
                }
                const parsed_miabId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                    if (typeof value === "number") {
                      return { success: true, value: BigInt(value) };
                    }
                    if (typeof value === "bigint") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["miabId"]);
                })();
                if (!parsed_miabId.success) {
                  return { success: false };
                }
                return { success: true, value: { data: parsed_data.value, miabId: parsed_miabId.value } };
              })(value);
            })(value["content"]);
          })();
          if (!parsed_content.success) {
            return { success: false };
          }
          if (!hasProp(value, "metadata")) {
            return { success: false };
          }
          const parsed_metadata = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ContentMetadata } => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value:
                      | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
                      | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
                      | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
                      | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number };
                  } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
                        | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
                        | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
                        | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number };
                    } => {
                  if (value == null) {
                    return { success: false };
                  }
                  if (typeof value != "object") {
                    return { success: false };
                  }
                  if (!hasProp(value, "contentType")) {
                    return { success: false };
                  }
                  if (typeof value["contentType"] != "string") {
                    return { success: false };
                  }
                  const discriminator = value["contentType"];
                  switch (discriminator) {
                    case "SUMMARY": {
                      const parsedVariant = ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              backgroundColor: string | null;
                              common: ContentMetadataCommon;
                              contentId: SummaryId;
                              documentType: SummaryDocumentType;
                              meetingInABoxAvailable: boolean;
                              sourceType: SummarySourceType;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "backgroundColor")) {
                          return { success: false };
                        }
                        const parsed_backgroundColor = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["backgroundColor"]);
                        })();
                        if (!parsed_backgroundColor.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "common")) {
                          return { success: false };
                        }
                        const parsed_common = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                            return ((
                              value: unknown,
                            ):
                              | { success: false }
                              | {
                                  success: true;
                                  value: {
                                    audioActive: boolean;
                                    coverUri: string;
                                    description: string | null;
                                    inactive: boolean;
                                    language: Language;
                                    leadIn: string | null;
                                    link: string | null;
                                    title: string;
                                  };
                                } => {
                              if (typeof value !== "object") {
                                return { success: false };
                              }
                              if (value === null) {
                                return { success: false };
                              }
                              if (!hasProp(value, "audioActive")) {
                                return { success: false };
                              }
                              const parsed_audioActive = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                  if (typeof value !== "boolean") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["audioActive"]);
                              })();
                              if (!parsed_audioActive.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "coverUri")) {
                                return { success: false };
                              }
                              const parsed_coverUri = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["coverUri"]);
                              })();
                              if (!parsed_coverUri.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "description")) {
                                return { success: false };
                              }
                              const parsed_description = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                                  if (value === null) {
                                    return { success: true, value: null };
                                  }
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["description"]);
                              })();
                              if (!parsed_description.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "inactive")) {
                                return { success: false };
                              }
                              const parsed_inactive = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                  if (typeof value !== "boolean") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["inactive"]);
                              })();
                              if (!parsed_inactive.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "language")) {
                                return { success: false };
                              }
                              const parsed_language = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: Language } => {
                                  return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    switch (value) {
                                      case "en":
                                        return { success: true, value: "en" };
                                      case "de":
                                        return { success: true, value: "de" };
                                      case "es":
                                        return { success: true, value: "es" };
                                      case "ru":
                                        return { success: true, value: "ru" };
                                      case "zh":
                                        return { success: true, value: "zh" };
                                      case "pt":
                                        return { success: true, value: "pt" };
                                      case "fr":
                                        return { success: true, value: "fr" };
                                      default:
                                        return { success: false };
                                    }
                                  })(value);
                                })(value["language"]);
                              })();
                              if (!parsed_language.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "leadIn")) {
                                return { success: false };
                              }
                              const parsed_leadIn = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                                  if (value === null) {
                                    return { success: true, value: null };
                                  }
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["leadIn"]);
                              })();
                              if (!parsed_leadIn.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "link")) {
                                return { success: false };
                              }
                              const parsed_link = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                                  if (value === null) {
                                    return { success: true, value: null };
                                  }
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["link"]);
                              })();
                              if (!parsed_link.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "title")) {
                                return { success: false };
                              }
                              const parsed_title = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["title"]);
                              })();
                              if (!parsed_title.success) {
                                return { success: false };
                              }
                              return {
                                success: true,
                                value: {
                                  audioActive: parsed_audioActive.value,
                                  coverUri: parsed_coverUri.value,
                                  description: parsed_description.value,
                                  inactive: parsed_inactive.value,
                                  language: parsed_language.value,
                                  leadIn: parsed_leadIn.value,
                                  link: parsed_link.value,
                                  title: parsed_title.value,
                                },
                              };
                            })(value);
                          })(value["common"]);
                        })();
                        if (!parsed_common.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "contentId")) {
                          return { success: false };
                        }
                        const parsed_contentId = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: SummaryId } => {
                            return ((
                              value: unknown,
                            ):
                              | { success: false }
                              | {
                                  success: true;
                                  value: {
                                    contentType: "SUMMARY";
                                    id: bigint;
                                  };
                                } => {
                              if (typeof value !== "object") {
                                return { success: false };
                              }
                              if (value === null) {
                                return { success: false };
                              }
                              if (!hasProp(value, "contentType")) {
                                return { success: false };
                              }
                              const parsed_contentType = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "SUMMARY":
                                      return { success: true, value: "SUMMARY" };
                                    default:
                                      return { success: false };
                                  }
                                })(value["contentType"]);
                              })();
                              if (!parsed_contentType.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "id")) {
                                return { success: false };
                              }
                              const parsed_id = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                  if (typeof value === "number") {
                                    return { success: true, value: BigInt(value) };
                                  }
                                  if (typeof value === "bigint") {
                                    return { success: true, value: value };
                                  }
                                  return { success: false };
                                })(value["id"]);
                              })();
                              if (!parsed_id.success) {
                                return { success: false };
                              }
                              return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                            })(value);
                          })(value["contentId"]);
                        })();
                        if (!parsed_contentId.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "documentType")) {
                          return { success: false };
                        }
                        const parsed_documentType = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
                            return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "ABSTRACT":
                                  return { success: true, value: "ABSTRACT" };
                                case "REVIEW":
                                  return { success: true, value: "REVIEW" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["documentType"]);
                        })();
                        if (!parsed_documentType.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "meetingInABoxAvailable")) {
                          return { success: false };
                        }
                        const parsed_meetingInABoxAvailable = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                            if (typeof value !== "boolean") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["meetingInABoxAvailable"]);
                        })();
                        if (!parsed_meetingInABoxAvailable.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "sourceType")) {
                          return { success: false };
                        }
                        const parsed_sourceType = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
                            return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "BOOK":
                                  return { success: true, value: "BOOK" };
                                case "VIDEO":
                                  return { success: true, value: "VIDEO" };
                                case "ARTICLE":
                                  return { success: true, value: "ARTICLE" };
                                case "CHAPTER":
                                  return { success: true, value: "CHAPTER" };
                                case "REPORT":
                                  return { success: true, value: "REPORT" };
                                case "PODCAST":
                                  return { success: true, value: "PODCAST" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["sourceType"]);
                        })();
                        if (!parsed_sourceType.success) {
                          return { success: false };
                        }
                        return {
                          success: true,
                          value: {
                            backgroundColor: parsed_backgroundColor.value,
                            common: parsed_common.value,
                            contentId: parsed_contentId.value,
                            documentType: parsed_documentType.value,
                            meetingInABoxAvailable: parsed_meetingInABoxAvailable.value,
                            sourceType: parsed_sourceType.value,
                          },
                        };
                      })(value);
                      if (!parsedVariant.success) {
                        return { success: false };
                      }
                      return { success: true, value: { contentType: "SUMMARY" as const, ...parsedVariant.value } };
                    }
                    case "ACTIONABLE": {
                      const parsedVariant = ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              common: ContentMetadataCommon;
                              contentId: ActionableId;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "common")) {
                          return { success: false };
                        }
                        const parsed_common = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                            return ((
                              value: unknown,
                            ):
                              | { success: false }
                              | {
                                  success: true;
                                  value: {
                                    audioActive: boolean;
                                    coverUri: string;
                                    description: string | null;
                                    inactive: boolean;
                                    language: Language;
                                    leadIn: string | null;
                                    link: string | null;
                                    title: string;
                                  };
                                } => {
                              if (typeof value !== "object") {
                                return { success: false };
                              }
                              if (value === null) {
                                return { success: false };
                              }
                              if (!hasProp(value, "audioActive")) {
                                return { success: false };
                              }
                              const parsed_audioActive = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                  if (typeof value !== "boolean") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["audioActive"]);
                              })();
                              if (!parsed_audioActive.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "coverUri")) {
                                return { success: false };
                              }
                              const parsed_coverUri = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["coverUri"]);
                              })();
                              if (!parsed_coverUri.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "description")) {
                                return { success: false };
                              }
                              const parsed_description = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                                  if (value === null) {
                                    return { success: true, value: null };
                                  }
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["description"]);
                              })();
                              if (!parsed_description.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "inactive")) {
                                return { success: false };
                              }
                              const parsed_inactive = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                  if (typeof value !== "boolean") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["inactive"]);
                              })();
                              if (!parsed_inactive.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "language")) {
                                return { success: false };
                              }
                              const parsed_language = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: Language } => {
                                  return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    switch (value) {
                                      case "en":
                                        return { success: true, value: "en" };
                                      case "de":
                                        return { success: true, value: "de" };
                                      case "es":
                                        return { success: true, value: "es" };
                                      case "ru":
                                        return { success: true, value: "ru" };
                                      case "zh":
                                        return { success: true, value: "zh" };
                                      case "pt":
                                        return { success: true, value: "pt" };
                                      case "fr":
                                        return { success: true, value: "fr" };
                                      default:
                                        return { success: false };
                                    }
                                  })(value);
                                })(value["language"]);
                              })();
                              if (!parsed_language.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "leadIn")) {
                                return { success: false };
                              }
                              const parsed_leadIn = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                                  if (value === null) {
                                    return { success: true, value: null };
                                  }
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["leadIn"]);
                              })();
                              if (!parsed_leadIn.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "link")) {
                                return { success: false };
                              }
                              const parsed_link = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                                  if (value === null) {
                                    return { success: true, value: null };
                                  }
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["link"]);
                              })();
                              if (!parsed_link.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "title")) {
                                return { success: false };
                              }
                              const parsed_title = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["title"]);
                              })();
                              if (!parsed_title.success) {
                                return { success: false };
                              }
                              return {
                                success: true,
                                value: {
                                  audioActive: parsed_audioActive.value,
                                  coverUri: parsed_coverUri.value,
                                  description: parsed_description.value,
                                  inactive: parsed_inactive.value,
                                  language: parsed_language.value,
                                  leadIn: parsed_leadIn.value,
                                  link: parsed_link.value,
                                  title: parsed_title.value,
                                },
                              };
                            })(value);
                          })(value["common"]);
                        })();
                        if (!parsed_common.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "contentId")) {
                          return { success: false };
                        }
                        const parsed_contentId = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: ActionableId } => {
                            return ((
                              value: unknown,
                            ):
                              | { success: false }
                              | {
                                  success: true;
                                  value: {
                                    contentType: "ACTIONABLE";
                                    id: bigint;
                                  };
                                } => {
                              if (typeof value !== "object") {
                                return { success: false };
                              }
                              if (value === null) {
                                return { success: false };
                              }
                              if (!hasProp(value, "contentType")) {
                                return { success: false };
                              }
                              const parsed_contentType = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "ACTIONABLE":
                                      return { success: true, value: "ACTIONABLE" };
                                    default:
                                      return { success: false };
                                  }
                                })(value["contentType"]);
                              })();
                              if (!parsed_contentType.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "id")) {
                                return { success: false };
                              }
                              const parsed_id = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                  if (typeof value === "number") {
                                    return { success: true, value: BigInt(value) };
                                  }
                                  if (typeof value === "bigint") {
                                    return { success: true, value: value };
                                  }
                                  return { success: false };
                                })(value["id"]);
                              })();
                              if (!parsed_id.success) {
                                return { success: false };
                              }
                              return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                            })(value);
                          })(value["contentId"]);
                        })();
                        if (!parsed_contentId.success) {
                          return { success: false };
                        }
                        return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value } };
                      })(value);
                      if (!parsedVariant.success) {
                        return { success: false };
                      }
                      return { success: true, value: { contentType: "ACTIONABLE" as const, ...parsedVariant.value } };
                    }
                    case "SKETCHNOTE": {
                      const parsedVariant = ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              common: ContentMetadataCommon;
                              contentId: LgxpId;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "common")) {
                          return { success: false };
                        }
                        const parsed_common = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                            return ((
                              value: unknown,
                            ):
                              | { success: false }
                              | {
                                  success: true;
                                  value: {
                                    audioActive: boolean;
                                    coverUri: string;
                                    description: string | null;
                                    inactive: boolean;
                                    language: Language;
                                    leadIn: string | null;
                                    link: string | null;
                                    title: string;
                                  };
                                } => {
                              if (typeof value !== "object") {
                                return { success: false };
                              }
                              if (value === null) {
                                return { success: false };
                              }
                              if (!hasProp(value, "audioActive")) {
                                return { success: false };
                              }
                              const parsed_audioActive = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                  if (typeof value !== "boolean") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["audioActive"]);
                              })();
                              if (!parsed_audioActive.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "coverUri")) {
                                return { success: false };
                              }
                              const parsed_coverUri = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["coverUri"]);
                              })();
                              if (!parsed_coverUri.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "description")) {
                                return { success: false };
                              }
                              const parsed_description = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                                  if (value === null) {
                                    return { success: true, value: null };
                                  }
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["description"]);
                              })();
                              if (!parsed_description.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "inactive")) {
                                return { success: false };
                              }
                              const parsed_inactive = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                  if (typeof value !== "boolean") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["inactive"]);
                              })();
                              if (!parsed_inactive.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "language")) {
                                return { success: false };
                              }
                              const parsed_language = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: Language } => {
                                  return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    switch (value) {
                                      case "en":
                                        return { success: true, value: "en" };
                                      case "de":
                                        return { success: true, value: "de" };
                                      case "es":
                                        return { success: true, value: "es" };
                                      case "ru":
                                        return { success: true, value: "ru" };
                                      case "zh":
                                        return { success: true, value: "zh" };
                                      case "pt":
                                        return { success: true, value: "pt" };
                                      case "fr":
                                        return { success: true, value: "fr" };
                                      default:
                                        return { success: false };
                                    }
                                  })(value);
                                })(value["language"]);
                              })();
                              if (!parsed_language.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "leadIn")) {
                                return { success: false };
                              }
                              const parsed_leadIn = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                                  if (value === null) {
                                    return { success: true, value: null };
                                  }
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["leadIn"]);
                              })();
                              if (!parsed_leadIn.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "link")) {
                                return { success: false };
                              }
                              const parsed_link = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                                  if (value === null) {
                                    return { success: true, value: null };
                                  }
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["link"]);
                              })();
                              if (!parsed_link.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "title")) {
                                return { success: false };
                              }
                              const parsed_title = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["title"]);
                              })();
                              if (!parsed_title.success) {
                                return { success: false };
                              }
                              return {
                                success: true,
                                value: {
                                  audioActive: parsed_audioActive.value,
                                  coverUri: parsed_coverUri.value,
                                  description: parsed_description.value,
                                  inactive: parsed_inactive.value,
                                  language: parsed_language.value,
                                  leadIn: parsed_leadIn.value,
                                  link: parsed_link.value,
                                  title: parsed_title.value,
                                },
                              };
                            })(value);
                          })(value["common"]);
                        })();
                        if (!parsed_common.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "contentId")) {
                          return { success: false };
                        }
                        const parsed_contentId = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: LgxpId } => {
                            return ((
                              value: unknown,
                            ):
                              | { success: false }
                              | {
                                  success: true;
                                  value: {
                                    contentType: "SKETCHNOTE";
                                    id: bigint;
                                  };
                                } => {
                              if (typeof value !== "object") {
                                return { success: false };
                              }
                              if (value === null) {
                                return { success: false };
                              }
                              if (!hasProp(value, "contentType")) {
                                return { success: false };
                              }
                              const parsed_contentType = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: "SKETCHNOTE" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "SKETCHNOTE":
                                      return { success: true, value: "SKETCHNOTE" };
                                    default:
                                      return { success: false };
                                  }
                                })(value["contentType"]);
                              })();
                              if (!parsed_contentType.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "id")) {
                                return { success: false };
                              }
                              const parsed_id = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                  if (typeof value === "number") {
                                    return { success: true, value: BigInt(value) };
                                  }
                                  if (typeof value === "bigint") {
                                    return { success: true, value: value };
                                  }
                                  return { success: false };
                                })(value["id"]);
                              })();
                              if (!parsed_id.success) {
                                return { success: false };
                              }
                              return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                            })(value);
                          })(value["contentId"]);
                        })();
                        if (!parsed_contentId.success) {
                          return { success: false };
                        }
                        return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value } };
                      })(value);
                      if (!parsedVariant.success) {
                        return { success: false };
                      }
                      return { success: true, value: { contentType: "SKETCHNOTE" as const, ...parsedVariant.value } };
                    }
                    case "MIAB": {
                      const parsedVariant = ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              common: ContentMetadataCommon;
                              contentId: MiabId;
                              customerId: number | null;
                              dataId: number;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "common")) {
                          return { success: false };
                        }
                        const parsed_common = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                            return ((
                              value: unknown,
                            ):
                              | { success: false }
                              | {
                                  success: true;
                                  value: {
                                    audioActive: boolean;
                                    coverUri: string;
                                    description: string | null;
                                    inactive: boolean;
                                    language: Language;
                                    leadIn: string | null;
                                    link: string | null;
                                    title: string;
                                  };
                                } => {
                              if (typeof value !== "object") {
                                return { success: false };
                              }
                              if (value === null) {
                                return { success: false };
                              }
                              if (!hasProp(value, "audioActive")) {
                                return { success: false };
                              }
                              const parsed_audioActive = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                  if (typeof value !== "boolean") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["audioActive"]);
                              })();
                              if (!parsed_audioActive.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "coverUri")) {
                                return { success: false };
                              }
                              const parsed_coverUri = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["coverUri"]);
                              })();
                              if (!parsed_coverUri.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "description")) {
                                return { success: false };
                              }
                              const parsed_description = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                                  if (value === null) {
                                    return { success: true, value: null };
                                  }
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["description"]);
                              })();
                              if (!parsed_description.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "inactive")) {
                                return { success: false };
                              }
                              const parsed_inactive = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                  if (typeof value !== "boolean") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["inactive"]);
                              })();
                              if (!parsed_inactive.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "language")) {
                                return { success: false };
                              }
                              const parsed_language = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: Language } => {
                                  return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    switch (value) {
                                      case "en":
                                        return { success: true, value: "en" };
                                      case "de":
                                        return { success: true, value: "de" };
                                      case "es":
                                        return { success: true, value: "es" };
                                      case "ru":
                                        return { success: true, value: "ru" };
                                      case "zh":
                                        return { success: true, value: "zh" };
                                      case "pt":
                                        return { success: true, value: "pt" };
                                      case "fr":
                                        return { success: true, value: "fr" };
                                      default:
                                        return { success: false };
                                    }
                                  })(value);
                                })(value["language"]);
                              })();
                              if (!parsed_language.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "leadIn")) {
                                return { success: false };
                              }
                              const parsed_leadIn = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                                  if (value === null) {
                                    return { success: true, value: null };
                                  }
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["leadIn"]);
                              })();
                              if (!parsed_leadIn.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "link")) {
                                return { success: false };
                              }
                              const parsed_link = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                                  if (value === null) {
                                    return { success: true, value: null };
                                  }
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["link"]);
                              })();
                              if (!parsed_link.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "title")) {
                                return { success: false };
                              }
                              const parsed_title = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["title"]);
                              })();
                              if (!parsed_title.success) {
                                return { success: false };
                              }
                              return {
                                success: true,
                                value: {
                                  audioActive: parsed_audioActive.value,
                                  coverUri: parsed_coverUri.value,
                                  description: parsed_description.value,
                                  inactive: parsed_inactive.value,
                                  language: parsed_language.value,
                                  leadIn: parsed_leadIn.value,
                                  link: parsed_link.value,
                                  title: parsed_title.value,
                                },
                              };
                            })(value);
                          })(value["common"]);
                        })();
                        if (!parsed_common.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "contentId")) {
                          return { success: false };
                        }
                        const parsed_contentId = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: MiabId } => {
                            return ((
                              value: unknown,
                            ):
                              | { success: false }
                              | {
                                  success: true;
                                  value: {
                                    contentType: "MIAB";
                                    id: bigint;
                                  };
                                } => {
                              if (typeof value !== "object") {
                                return { success: false };
                              }
                              if (value === null) {
                                return { success: false };
                              }
                              if (!hasProp(value, "contentType")) {
                                return { success: false };
                              }
                              const parsed_contentType = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: "MIAB" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "MIAB":
                                      return { success: true, value: "MIAB" };
                                    default:
                                      return { success: false };
                                  }
                                })(value["contentType"]);
                              })();
                              if (!parsed_contentType.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "id")) {
                                return { success: false };
                              }
                              const parsed_id = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                  if (typeof value === "number") {
                                    return { success: true, value: BigInt(value) };
                                  }
                                  if (typeof value === "bigint") {
                                    return { success: true, value: value };
                                  }
                                  return { success: false };
                                })(value["id"]);
                              })();
                              if (!parsed_id.success) {
                                return { success: false };
                              }
                              return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                            })(value);
                          })(value["contentId"]);
                        })();
                        if (!parsed_contentId.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "customerId")) {
                          return { success: false };
                        }
                        const parsed_customerId = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value === "number") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["customerId"]);
                        })();
                        if (!parsed_customerId.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "dataId")) {
                          return { success: false };
                        }
                        const parsed_dataId = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: number } => {
                            if (typeof value === "number") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["dataId"]);
                        })();
                        if (!parsed_dataId.success) {
                          return { success: false };
                        }
                        return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value, customerId: parsed_customerId.value, dataId: parsed_dataId.value } };
                      })(value);
                      if (!parsedVariant.success) {
                        return { success: false };
                      }
                      return { success: true, value: { contentType: "MIAB" as const, ...parsedVariant.value } };
                    }
                    default:
                      return { success: false };
                  }
                })(value);
              })(value);
            })(value["metadata"]);
          })();
          if (!parsed_metadata.success) {
            return { success: false };
          }
          if (!hasProp(value, "userContext")) {
            return { success: false };
          }
          const parsed_userContext = (() => {
            return ((value: unknown): { success: false } | { success: true; value: MIABUserContext | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      customerId: number;
                      hash: string;
                      username: string;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "customerId")) {
                  return { success: false };
                }
                const parsed_customerId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: number } => {
                    if (typeof value === "number") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["customerId"]);
                })();
                if (!parsed_customerId.success) {
                  return { success: false };
                }
                if (!hasProp(value, "hash")) {
                  return { success: false };
                }
                const parsed_hash = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["hash"]);
                })();
                if (!parsed_hash.success) {
                  return { success: false };
                }
                if (!hasProp(value, "username")) {
                  return { success: false };
                }
                const parsed_username = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["username"]);
                })();
                if (!parsed_username.success) {
                  return { success: false };
                }
                return { success: true, value: { customerId: parsed_customerId.value, hash: parsed_hash.value, username: parsed_username.value } };
              })(value);
            })(value["userContext"]);
          })();
          if (!parsed_userContext.success) {
            return { success: false };
          }
          return { success: true, value: { content: parsed_content.value, metadata: parsed_metadata.value, userContext: parsed_userContext.value } };
        })(value);
      })(value["miab"]);
    })();
    if (!parsed_miab.success) {
      return { success: false };
    }
    if (!hasProp(value, "openEdit")) {
      return { success: false };
    }
    const parsed_openEdit = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["openEdit"]);
    })();
    if (!parsed_openEdit.success) {
      return { success: false };
    }
    if (!hasProp(value, "regionalRestriction")) {
      return { success: false };
    }
    const parsed_regionalRestriction = (() => {
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value: {
              countriesByRegion: { additionalProperties: Record<string, string[]> };
              restrictionType: CountryRestrictionType;
            };
          } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        if (!hasProp(value, "countriesByRegion")) {
          return { success: false };
        }
        const parsed_countriesByRegion = (() => {
          return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string[]> } } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string[]> } => {
              const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string[] }] => {
                return [
                  key,
                  ((value: unknown): { success: false } | { success: true; value: string[] } => {
                    if (!isArray(value)) {
                      return { success: false };
                    }
                    const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    });
                    if (parsed.find((value) => !value.success) !== undefined) {
                      return { success: false };
                    }
                    return {
                      success: true,
                      value: parsed.map((entry) => {
                        assert(entry.success == true);
                        return entry.value;
                      }),
                    };
                  })(value),
                ];
              });
              if (parsed.find(([_, value]) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: Object.fromEntries(
                  parsed.map(([key, value]) => {
                    assert(value.success);
                    return [key, value.value];
                  }),
                ),
              };
            })();
            if (!parsed_additionalProperties.success) {
              return { success: false };
            }
            return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
          })(value["countriesByRegion"]);
        })();
        if (!parsed_countriesByRegion.success) {
          return { success: false };
        }
        if (!hasProp(value, "restrictionType")) {
          return { success: false };
        }
        const parsed_restrictionType = (() => {
          return ((value: unknown): { success: false } | { success: true; value: CountryRestrictionType } => {
            return ((value: unknown): { success: false } | { success: true; value: "INCLUDED" | "EXCLUDED" | "NO_RESTRICTION" } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              switch (value) {
                case "INCLUDED":
                  return { success: true, value: "INCLUDED" };
                case "EXCLUDED":
                  return { success: true, value: "EXCLUDED" };
                case "NO_RESTRICTION":
                  return { success: true, value: "NO_RESTRICTION" };
                default:
                  return { success: false };
              }
            })(value);
          })(value["restrictionType"]);
        })();
        if (!parsed_restrictionType.success) {
          return { success: false };
        }
        return { success: true, value: { countriesByRegion: parsed_countriesByRegion.value, restrictionType: parsed_restrictionType.value } };
      })(value["regionalRestriction"]);
    })();
    if (!parsed_regionalRestriction.success) {
      return { success: false };
    }
    if (!hasProp(value, "similarMiabs")) {
      return { success: false };
    }
    const parsed_similarMiabs = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ContentMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ContentMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
                  | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
                  | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
                  | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
                    | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
                    | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
                    | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "contentType")) {
                return { success: false };
              }
              if (typeof value["contentType"] != "string") {
                return { success: false };
              }
              const discriminator = value["contentType"];
              switch (discriminator) {
                case "SUMMARY": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          backgroundColor: string | null;
                          common: ContentMetadataCommon;
                          contentId: SummaryId;
                          documentType: SummaryDocumentType;
                          meetingInABoxAvailable: boolean;
                          sourceType: SummarySourceType;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "backgroundColor")) {
                      return { success: false };
                    }
                    const parsed_backgroundColor = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["backgroundColor"]);
                    })();
                    if (!parsed_backgroundColor.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummaryId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "SUMMARY";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "SUMMARY":
                                  return { success: true, value: "SUMMARY" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "documentType")) {
                      return { success: false };
                    }
                    const parsed_documentType = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "ABSTRACT":
                              return { success: true, value: "ABSTRACT" };
                            case "REVIEW":
                              return { success: true, value: "REVIEW" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["documentType"]);
                    })();
                    if (!parsed_documentType.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "meetingInABoxAvailable")) {
                      return { success: false };
                    }
                    const parsed_meetingInABoxAvailable = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["meetingInABoxAvailable"]);
                    })();
                    if (!parsed_meetingInABoxAvailable.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "sourceType")) {
                      return { success: false };
                    }
                    const parsed_sourceType = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "BOOK":
                              return { success: true, value: "BOOK" };
                            case "VIDEO":
                              return { success: true, value: "VIDEO" };
                            case "ARTICLE":
                              return { success: true, value: "ARTICLE" };
                            case "CHAPTER":
                              return { success: true, value: "CHAPTER" };
                            case "REPORT":
                              return { success: true, value: "REPORT" };
                            case "PODCAST":
                              return { success: true, value: "PODCAST" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["sourceType"]);
                    })();
                    if (!parsed_sourceType.success) {
                      return { success: false };
                    }
                    return {
                      success: true,
                      value: {
                        backgroundColor: parsed_backgroundColor.value,
                        common: parsed_common.value,
                        contentId: parsed_contentId.value,
                        documentType: parsed_documentType.value,
                        meetingInABoxAvailable: parsed_meetingInABoxAvailable.value,
                        sourceType: parsed_sourceType.value,
                      },
                    };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "SUMMARY" as const, ...parsedVariant.value } };
                }
                case "ACTIONABLE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: ActionableId;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ActionableId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "ACTIONABLE";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "ACTIONABLE":
                                  return { success: true, value: "ACTIONABLE" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "ACTIONABLE" as const, ...parsedVariant.value } };
                }
                case "SKETCHNOTE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: LgxpId;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: LgxpId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "SKETCHNOTE";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "SKETCHNOTE" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "SKETCHNOTE":
                                  return { success: true, value: "SKETCHNOTE" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "SKETCHNOTE" as const, ...parsedVariant.value } };
                }
                case "MIAB": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: MiabId;
                          customerId: number | null;
                          dataId: number;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: MiabId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "MIAB";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "MIAB" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "MIAB":
                                  return { success: true, value: "MIAB" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "customerId")) {
                      return { success: false };
                    }
                    const parsed_customerId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["customerId"]);
                    })();
                    if (!parsed_customerId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "dataId")) {
                      return { success: false };
                    }
                    const parsed_dataId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["dataId"]);
                    })();
                    if (!parsed_dataId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value, customerId: parsed_customerId.value, dataId: parsed_dataId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "MIAB" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["similarMiabs"]);
    })();
    if (!parsed_similarMiabs.success) {
      return { success: false };
    }
    if (!hasProp(value, "userPortal")) {
      return { success: false };
    }
    const parsed_userPortal = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["userPortal"]);
    })();
    if (!parsed_userPortal.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        bookmarked: parsed_bookmarked.value,
        feedback: parsed_feedback.value,
        linkedMiab: parsed_linkedMiab.value,
        miab: parsed_miab.value,
        openEdit: parsed_openEdit.value,
        regionalRestriction: parsed_regionalRestriction.value,
        similarMiabs: parsed_similarMiabs.value,
        userPortal: parsed_userPortal.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// MIABPopularSummariesResult
export function serialize_MIABPopularSummariesResult(obj: MIABPopularSummariesResult): string {
  const serialized = ((value: { miabsEnglish: ContentMetadata[]; miabsNative: ContentMetadata[]; pagingEnglish: PageInfo; pagingNative: PageInfo }): unknown => {
    return {
      miabsEnglish: ((value: ContentMetadata[]): unknown => {
        return value.map(
          (
            value:
              | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
              | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
              | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
              | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number },
          ): unknown => {
            switch (value.contentType) {
              case "SUMMARY": {
                return {
                  contentType: "SUMMARY",
                  backgroundColor: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["backgroundColor"]),
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "SUMMARY"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "SUMMARY"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                  documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
                    return value;
                  })(value["documentType"]),
                  meetingInABoxAvailable: ((value: boolean): unknown => {
                    return value;
                  })(value["meetingInABoxAvailable"]),
                  sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
                    return value;
                  })(value["sourceType"]),
                };
              }
              case "ACTIONABLE": {
                return {
                  contentType: "ACTIONABLE",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "ACTIONABLE"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "ACTIONABLE"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                };
              }
              case "SKETCHNOTE": {
                return {
                  contentType: "SKETCHNOTE",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "SKETCHNOTE"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "SKETCHNOTE"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                };
              }
              case "MIAB": {
                return {
                  contentType: "MIAB",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "MIAB"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "MIAB"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                  customerId: ((value: number | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["customerId"]),
                  dataId: ((value: number): unknown => {
                    return value;
                  })(value["dataId"]),
                };
              }
            }
          },
        );
      })(value["miabsEnglish"]),
      miabsNative: ((value: ContentMetadata[]): unknown => {
        return value.map(
          (
            value:
              | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
              | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
              | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
              | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number },
          ): unknown => {
            switch (value.contentType) {
              case "SUMMARY": {
                return {
                  contentType: "SUMMARY",
                  backgroundColor: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["backgroundColor"]),
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "SUMMARY"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "SUMMARY"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                  documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
                    return value;
                  })(value["documentType"]),
                  meetingInABoxAvailable: ((value: boolean): unknown => {
                    return value;
                  })(value["meetingInABoxAvailable"]),
                  sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
                    return value;
                  })(value["sourceType"]),
                };
              }
              case "ACTIONABLE": {
                return {
                  contentType: "ACTIONABLE",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "ACTIONABLE"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "ACTIONABLE"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                };
              }
              case "SKETCHNOTE": {
                return {
                  contentType: "SKETCHNOTE",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "SKETCHNOTE"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "SKETCHNOTE"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                };
              }
              case "MIAB": {
                return {
                  contentType: "MIAB",
                  common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                    return {
                      audioActive: ((value: boolean): unknown => {
                        return value;
                      })(value["audioActive"]),
                      coverUri: ((value: string): unknown => {
                        return value;
                      })(value["coverUri"]),
                      description: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["description"]),
                      inactive: ((value: boolean): unknown => {
                        return value;
                      })(value["inactive"]),
                      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                        return value;
                      })(value["language"]),
                      leadIn: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["leadIn"]),
                      link: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["link"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                    };
                  })(value["common"]),
                  contentId: ((value: { contentType: "MIAB"; id: bigint }): unknown => {
                    return {
                      contentType: ((value: "MIAB"): unknown => {
                        return value;
                      })(value["contentType"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                    };
                  })(value["contentId"]),
                  customerId: ((value: number | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["customerId"]),
                  dataId: ((value: number): unknown => {
                    return value;
                  })(value["dataId"]),
                };
              }
            }
          },
        );
      })(value["miabsNative"]),
      pagingEnglish: ((value: { page: number; pageSize: number; totalCount: number }): unknown => {
        return {
          page: ((value: number): unknown => {
            return value;
          })(value["page"]),
          pageSize: ((value: number): unknown => {
            return value;
          })(value["pageSize"]),
          totalCount: ((value: number): unknown => {
            return value;
          })(value["totalCount"]),
        };
      })(value["pagingEnglish"]),
      pagingNative: ((value: { page: number; pageSize: number; totalCount: number }): unknown => {
        return {
          page: ((value: number): unknown => {
            return value;
          })(value["page"]),
          pageSize: ((value: number): unknown => {
            return value;
          })(value["pageSize"]),
          totalCount: ((value: number): unknown => {
            return value;
          })(value["totalCount"]),
        };
      })(value["pagingNative"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_MIABPopularSummariesResult(str: string): MIABPopularSummariesResult | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          miabsEnglish: ContentMetadata[];
          miabsNative: ContentMetadata[];
          pagingEnglish: PageInfo;
          pagingNative: PageInfo;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "miabsEnglish")) {
      return { success: false };
    }
    const parsed_miabsEnglish = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ContentMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ContentMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
                  | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
                  | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
                  | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
                    | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
                    | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
                    | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "contentType")) {
                return { success: false };
              }
              if (typeof value["contentType"] != "string") {
                return { success: false };
              }
              const discriminator = value["contentType"];
              switch (discriminator) {
                case "SUMMARY": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          backgroundColor: string | null;
                          common: ContentMetadataCommon;
                          contentId: SummaryId;
                          documentType: SummaryDocumentType;
                          meetingInABoxAvailable: boolean;
                          sourceType: SummarySourceType;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "backgroundColor")) {
                      return { success: false };
                    }
                    const parsed_backgroundColor = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["backgroundColor"]);
                    })();
                    if (!parsed_backgroundColor.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummaryId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "SUMMARY";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "SUMMARY":
                                  return { success: true, value: "SUMMARY" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "documentType")) {
                      return { success: false };
                    }
                    const parsed_documentType = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "ABSTRACT":
                              return { success: true, value: "ABSTRACT" };
                            case "REVIEW":
                              return { success: true, value: "REVIEW" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["documentType"]);
                    })();
                    if (!parsed_documentType.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "meetingInABoxAvailable")) {
                      return { success: false };
                    }
                    const parsed_meetingInABoxAvailable = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["meetingInABoxAvailable"]);
                    })();
                    if (!parsed_meetingInABoxAvailable.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "sourceType")) {
                      return { success: false };
                    }
                    const parsed_sourceType = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "BOOK":
                              return { success: true, value: "BOOK" };
                            case "VIDEO":
                              return { success: true, value: "VIDEO" };
                            case "ARTICLE":
                              return { success: true, value: "ARTICLE" };
                            case "CHAPTER":
                              return { success: true, value: "CHAPTER" };
                            case "REPORT":
                              return { success: true, value: "REPORT" };
                            case "PODCAST":
                              return { success: true, value: "PODCAST" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["sourceType"]);
                    })();
                    if (!parsed_sourceType.success) {
                      return { success: false };
                    }
                    return {
                      success: true,
                      value: {
                        backgroundColor: parsed_backgroundColor.value,
                        common: parsed_common.value,
                        contentId: parsed_contentId.value,
                        documentType: parsed_documentType.value,
                        meetingInABoxAvailable: parsed_meetingInABoxAvailable.value,
                        sourceType: parsed_sourceType.value,
                      },
                    };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "SUMMARY" as const, ...parsedVariant.value } };
                }
                case "ACTIONABLE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: ActionableId;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ActionableId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "ACTIONABLE";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "ACTIONABLE":
                                  return { success: true, value: "ACTIONABLE" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "ACTIONABLE" as const, ...parsedVariant.value } };
                }
                case "SKETCHNOTE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: LgxpId;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: LgxpId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "SKETCHNOTE";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "SKETCHNOTE" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "SKETCHNOTE":
                                  return { success: true, value: "SKETCHNOTE" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "SKETCHNOTE" as const, ...parsedVariant.value } };
                }
                case "MIAB": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: MiabId;
                          customerId: number | null;
                          dataId: number;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: MiabId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "MIAB";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "MIAB" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "MIAB":
                                  return { success: true, value: "MIAB" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "customerId")) {
                      return { success: false };
                    }
                    const parsed_customerId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["customerId"]);
                    })();
                    if (!parsed_customerId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "dataId")) {
                      return { success: false };
                    }
                    const parsed_dataId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["dataId"]);
                    })();
                    if (!parsed_dataId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value, customerId: parsed_customerId.value, dataId: parsed_dataId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "MIAB" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["miabsEnglish"]);
    })();
    if (!parsed_miabsEnglish.success) {
      return { success: false };
    }
    if (!hasProp(value, "miabsNative")) {
      return { success: false };
    }
    const parsed_miabsNative = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ContentMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ContentMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
                  | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
                  | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
                  | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
                    | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
                    | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
                    | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "contentType")) {
                return { success: false };
              }
              if (typeof value["contentType"] != "string") {
                return { success: false };
              }
              const discriminator = value["contentType"];
              switch (discriminator) {
                case "SUMMARY": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          backgroundColor: string | null;
                          common: ContentMetadataCommon;
                          contentId: SummaryId;
                          documentType: SummaryDocumentType;
                          meetingInABoxAvailable: boolean;
                          sourceType: SummarySourceType;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "backgroundColor")) {
                      return { success: false };
                    }
                    const parsed_backgroundColor = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["backgroundColor"]);
                    })();
                    if (!parsed_backgroundColor.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummaryId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "SUMMARY";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "SUMMARY":
                                  return { success: true, value: "SUMMARY" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "documentType")) {
                      return { success: false };
                    }
                    const parsed_documentType = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "ABSTRACT":
                              return { success: true, value: "ABSTRACT" };
                            case "REVIEW":
                              return { success: true, value: "REVIEW" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["documentType"]);
                    })();
                    if (!parsed_documentType.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "meetingInABoxAvailable")) {
                      return { success: false };
                    }
                    const parsed_meetingInABoxAvailable = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["meetingInABoxAvailable"]);
                    })();
                    if (!parsed_meetingInABoxAvailable.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "sourceType")) {
                      return { success: false };
                    }
                    const parsed_sourceType = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "BOOK":
                              return { success: true, value: "BOOK" };
                            case "VIDEO":
                              return { success: true, value: "VIDEO" };
                            case "ARTICLE":
                              return { success: true, value: "ARTICLE" };
                            case "CHAPTER":
                              return { success: true, value: "CHAPTER" };
                            case "REPORT":
                              return { success: true, value: "REPORT" };
                            case "PODCAST":
                              return { success: true, value: "PODCAST" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["sourceType"]);
                    })();
                    if (!parsed_sourceType.success) {
                      return { success: false };
                    }
                    return {
                      success: true,
                      value: {
                        backgroundColor: parsed_backgroundColor.value,
                        common: parsed_common.value,
                        contentId: parsed_contentId.value,
                        documentType: parsed_documentType.value,
                        meetingInABoxAvailable: parsed_meetingInABoxAvailable.value,
                        sourceType: parsed_sourceType.value,
                      },
                    };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "SUMMARY" as const, ...parsedVariant.value } };
                }
                case "ACTIONABLE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: ActionableId;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ActionableId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "ACTIONABLE";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "ACTIONABLE":
                                  return { success: true, value: "ACTIONABLE" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "ACTIONABLE" as const, ...parsedVariant.value } };
                }
                case "SKETCHNOTE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: LgxpId;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: LgxpId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "SKETCHNOTE";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "SKETCHNOTE" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "SKETCHNOTE":
                                  return { success: true, value: "SKETCHNOTE" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "SKETCHNOTE" as const, ...parsedVariant.value } };
                }
                case "MIAB": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          common: ContentMetadataCommon;
                          contentId: MiabId;
                          customerId: number | null;
                          dataId: number;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "common")) {
                      return { success: false };
                    }
                    const parsed_common = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                audioActive: boolean;
                                coverUri: string;
                                description: string | null;
                                inactive: boolean;
                                language: Language;
                                leadIn: string | null;
                                link: string | null;
                                title: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "audioActive")) {
                            return { success: false };
                          }
                          const parsed_audioActive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["audioActive"]);
                          })();
                          if (!parsed_audioActive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "coverUri")) {
                            return { success: false };
                          }
                          const parsed_coverUri = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["coverUri"]);
                          })();
                          if (!parsed_coverUri.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "description")) {
                            return { success: false };
                          }
                          const parsed_description = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["description"]);
                          })();
                          if (!parsed_description.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "inactive")) {
                            return { success: false };
                          }
                          const parsed_inactive = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["inactive"]);
                          })();
                          if (!parsed_inactive.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "language")) {
                            return { success: false };
                          }
                          const parsed_language = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: Language } => {
                              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "en":
                                    return { success: true, value: "en" };
                                  case "de":
                                    return { success: true, value: "de" };
                                  case "es":
                                    return { success: true, value: "es" };
                                  case "ru":
                                    return { success: true, value: "ru" };
                                  case "zh":
                                    return { success: true, value: "zh" };
                                  case "pt":
                                    return { success: true, value: "pt" };
                                  case "fr":
                                    return { success: true, value: "fr" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["language"]);
                          })();
                          if (!parsed_language.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "leadIn")) {
                            return { success: false };
                          }
                          const parsed_leadIn = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["leadIn"]);
                          })();
                          if (!parsed_leadIn.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "link")) {
                            return { success: false };
                          }
                          const parsed_link = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["link"]);
                          })();
                          if (!parsed_link.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "title")) {
                            return { success: false };
                          }
                          const parsed_title = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["title"]);
                          })();
                          if (!parsed_title.success) {
                            return { success: false };
                          }
                          return {
                            success: true,
                            value: {
                              audioActive: parsed_audioActive.value,
                              coverUri: parsed_coverUri.value,
                              description: parsed_description.value,
                              inactive: parsed_inactive.value,
                              language: parsed_language.value,
                              leadIn: parsed_leadIn.value,
                              link: parsed_link.value,
                              title: parsed_title.value,
                            },
                          };
                        })(value);
                      })(value["common"]);
                    })();
                    if (!parsed_common.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "contentId")) {
                      return { success: false };
                    }
                    const parsed_contentId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: MiabId } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                contentType: "MIAB";
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "contentType")) {
                            return { success: false };
                          }
                          const parsed_contentType = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "MIAB" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "MIAB":
                                  return { success: true, value: "MIAB" };
                                default:
                                  return { success: false };
                              }
                            })(value["contentType"]);
                          })();
                          if (!parsed_contentType.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                        })(value);
                      })(value["contentId"]);
                    })();
                    if (!parsed_contentId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "customerId")) {
                      return { success: false };
                    }
                    const parsed_customerId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["customerId"]);
                    })();
                    if (!parsed_customerId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "dataId")) {
                      return { success: false };
                    }
                    const parsed_dataId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["dataId"]);
                    })();
                    if (!parsed_dataId.success) {
                      return { success: false };
                    }
                    return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value, customerId: parsed_customerId.value, dataId: parsed_dataId.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { contentType: "MIAB" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["miabsNative"]);
    })();
    if (!parsed_miabsNative.success) {
      return { success: false };
    }
    if (!hasProp(value, "pagingEnglish")) {
      return { success: false };
    }
    const parsed_pagingEnglish = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PageInfo } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                page: number;
                pageSize: number;
                totalCount: number;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "page")) {
            return { success: false };
          }
          const parsed_page = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["page"]);
          })();
          if (!parsed_page.success) {
            return { success: false };
          }
          if (!hasProp(value, "pageSize")) {
            return { success: false };
          }
          const parsed_pageSize = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["pageSize"]);
          })();
          if (!parsed_pageSize.success) {
            return { success: false };
          }
          if (!hasProp(value, "totalCount")) {
            return { success: false };
          }
          const parsed_totalCount = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["totalCount"]);
          })();
          if (!parsed_totalCount.success) {
            return { success: false };
          }
          return { success: true, value: { page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
        })(value);
      })(value["pagingEnglish"]);
    })();
    if (!parsed_pagingEnglish.success) {
      return { success: false };
    }
    if (!hasProp(value, "pagingNative")) {
      return { success: false };
    }
    const parsed_pagingNative = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PageInfo } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                page: number;
                pageSize: number;
                totalCount: number;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "page")) {
            return { success: false };
          }
          const parsed_page = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["page"]);
          })();
          if (!parsed_page.success) {
            return { success: false };
          }
          if (!hasProp(value, "pageSize")) {
            return { success: false };
          }
          const parsed_pageSize = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["pageSize"]);
          })();
          if (!parsed_pageSize.success) {
            return { success: false };
          }
          if (!hasProp(value, "totalCount")) {
            return { success: false };
          }
          const parsed_totalCount = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["totalCount"]);
          })();
          if (!parsed_totalCount.success) {
            return { success: false };
          }
          return { success: true, value: { page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
        })(value);
      })(value["pagingNative"]);
    })();
    if (!parsed_pagingNative.success) {
      return { success: false };
    }
    return { success: true, value: { miabsEnglish: parsed_miabsEnglish.value, miabsNative: parsed_miabsNative.value, pagingEnglish: parsed_pagingEnglish.value, pagingNative: parsed_pagingNative.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// MIABPrepareStepAiResponse
export function serialize_MIABPrepareStepAiResponse(obj: MIABPrepareStepAiResponse): string {
  const serialized = ((value: { point1: string; point2: string; point3: string; point4: string }): unknown => {
    return {
      point1: ((value: string): unknown => {
        return value;
      })(value["point1"]),
      point2: ((value: string): unknown => {
        return value;
      })(value["point2"]),
      point3: ((value: string): unknown => {
        return value;
      })(value["point3"]),
      point4: ((value: string): unknown => {
        return value;
      })(value["point4"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_MIABPrepareStepAiResponse(str: string): MIABPrepareStepAiResponse | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          point1: string;
          point2: string;
          point3: string;
          point4: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "point1")) {
      return { success: false };
    }
    const parsed_point1 = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["point1"]);
    })();
    if (!parsed_point1.success) {
      return { success: false };
    }
    if (!hasProp(value, "point2")) {
      return { success: false };
    }
    const parsed_point2 = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["point2"]);
    })();
    if (!parsed_point2.success) {
      return { success: false };
    }
    if (!hasProp(value, "point3")) {
      return { success: false };
    }
    const parsed_point3 = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["point3"]);
    })();
    if (!parsed_point3.success) {
      return { success: false };
    }
    if (!hasProp(value, "point4")) {
      return { success: false };
    }
    const parsed_point4 = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["point4"]);
    })();
    if (!parsed_point4.success) {
      return { success: false };
    }
    return { success: true, value: { point1: parsed_point1.value, point2: parsed_point2.value, point3: parsed_point3.value, point4: parsed_point4.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// MIABQuestionMetadata
export function serialize_MIABQuestionMetadata(obj: MIABQuestionMetadata): string {
  const serialized = ((value: { createdAt: DateTime<true> | null; question: string; sortOrder: number; title: string }): unknown => {
    return {
      createdAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["createdAt"]),
      question: ((value: string): unknown => {
        return value;
      })(value["question"]),
      sortOrder: ((value: number): unknown => {
        return value;
      })(value["sortOrder"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_MIABQuestionMetadata(str: string): MIABQuestionMetadata | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          createdAt: DateTime<true> | null;
          question: string;
          sortOrder: number;
          title: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "createdAt")) {
      return { success: false };
    }
    const parsed_createdAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["createdAt"]);
    })();
    if (!parsed_createdAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "question")) {
      return { success: false };
    }
    const parsed_question = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["question"]);
    })();
    if (!parsed_question.success) {
      return { success: false };
    }
    if (!hasProp(value, "sortOrder")) {
      return { success: false };
    }
    const parsed_sortOrder = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["sortOrder"]);
    })();
    if (!parsed_sortOrder.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return { success: true, value: { createdAt: parsed_createdAt.value, question: parsed_question.value, sortOrder: parsed_sortOrder.value, title: parsed_title.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// MIABQuestionsAiResponse
export function serialize_MIABQuestionsAiResponse(obj: MIABQuestionsAiResponse): string {
  const serialized = ((value: {
    question1: {
      question: string;
      title: string;
    };
    question2: {
      question: string;
      title: string;
    };
    question3: {
      question: string;
      title: string;
    };
    question4: {
      question: string;
      title: string;
    };
    question5: {
      question: string;
      title: string;
    };
  }): unknown => {
    return {
      question1: ((value: { question: string; title: string }): unknown => {
        return {
          question: ((value: string): unknown => {
            return value;
          })(value["question"]),
          title: ((value: string): unknown => {
            return value;
          })(value["title"]),
        };
      })(value["question1"]),
      question2: ((value: { question: string; title: string }): unknown => {
        return {
          question: ((value: string): unknown => {
            return value;
          })(value["question"]),
          title: ((value: string): unknown => {
            return value;
          })(value["title"]),
        };
      })(value["question2"]),
      question3: ((value: { question: string; title: string }): unknown => {
        return {
          question: ((value: string): unknown => {
            return value;
          })(value["question"]),
          title: ((value: string): unknown => {
            return value;
          })(value["title"]),
        };
      })(value["question3"]),
      question4: ((value: { question: string; title: string }): unknown => {
        return {
          question: ((value: string): unknown => {
            return value;
          })(value["question"]),
          title: ((value: string): unknown => {
            return value;
          })(value["title"]),
        };
      })(value["question4"]),
      question5: ((value: { question: string; title: string }): unknown => {
        return {
          question: ((value: string): unknown => {
            return value;
          })(value["question"]),
          title: ((value: string): unknown => {
            return value;
          })(value["title"]),
        };
      })(value["question5"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_MIABQuestionsAiResponse(str: string): MIABQuestionsAiResponse | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          question1: {
            question: string;
            title: string;
          };
          question2: {
            question: string;
            title: string;
          };
          question3: {
            question: string;
            title: string;
          };
          question4: {
            question: string;
            title: string;
          };
          question5: {
            question: string;
            title: string;
          };
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "question1")) {
      return { success: false };
    }
    const parsed_question1 = (() => {
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value: {
              question: string;
              title: string;
            };
          } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        if (!hasProp(value, "question")) {
          return { success: false };
        }
        const parsed_question = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["question"]);
        })();
        if (!parsed_question.success) {
          return { success: false };
        }
        if (!hasProp(value, "title")) {
          return { success: false };
        }
        const parsed_title = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["title"]);
        })();
        if (!parsed_title.success) {
          return { success: false };
        }
        return { success: true, value: { question: parsed_question.value, title: parsed_title.value } };
      })(value["question1"]);
    })();
    if (!parsed_question1.success) {
      return { success: false };
    }
    if (!hasProp(value, "question2")) {
      return { success: false };
    }
    const parsed_question2 = (() => {
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value: {
              question: string;
              title: string;
            };
          } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        if (!hasProp(value, "question")) {
          return { success: false };
        }
        const parsed_question = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["question"]);
        })();
        if (!parsed_question.success) {
          return { success: false };
        }
        if (!hasProp(value, "title")) {
          return { success: false };
        }
        const parsed_title = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["title"]);
        })();
        if (!parsed_title.success) {
          return { success: false };
        }
        return { success: true, value: { question: parsed_question.value, title: parsed_title.value } };
      })(value["question2"]);
    })();
    if (!parsed_question2.success) {
      return { success: false };
    }
    if (!hasProp(value, "question3")) {
      return { success: false };
    }
    const parsed_question3 = (() => {
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value: {
              question: string;
              title: string;
            };
          } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        if (!hasProp(value, "question")) {
          return { success: false };
        }
        const parsed_question = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["question"]);
        })();
        if (!parsed_question.success) {
          return { success: false };
        }
        if (!hasProp(value, "title")) {
          return { success: false };
        }
        const parsed_title = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["title"]);
        })();
        if (!parsed_title.success) {
          return { success: false };
        }
        return { success: true, value: { question: parsed_question.value, title: parsed_title.value } };
      })(value["question3"]);
    })();
    if (!parsed_question3.success) {
      return { success: false };
    }
    if (!hasProp(value, "question4")) {
      return { success: false };
    }
    const parsed_question4 = (() => {
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value: {
              question: string;
              title: string;
            };
          } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        if (!hasProp(value, "question")) {
          return { success: false };
        }
        const parsed_question = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["question"]);
        })();
        if (!parsed_question.success) {
          return { success: false };
        }
        if (!hasProp(value, "title")) {
          return { success: false };
        }
        const parsed_title = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["title"]);
        })();
        if (!parsed_title.success) {
          return { success: false };
        }
        return { success: true, value: { question: parsed_question.value, title: parsed_title.value } };
      })(value["question4"]);
    })();
    if (!parsed_question4.success) {
      return { success: false };
    }
    if (!hasProp(value, "question5")) {
      return { success: false };
    }
    const parsed_question5 = (() => {
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value: {
              question: string;
              title: string;
            };
          } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        if (!hasProp(value, "question")) {
          return { success: false };
        }
        const parsed_question = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["question"]);
        })();
        if (!parsed_question.success) {
          return { success: false };
        }
        if (!hasProp(value, "title")) {
          return { success: false };
        }
        const parsed_title = (() => {
          return ((value: unknown): { success: false } | { success: true; value: string } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            return { success: true, value: value };
          })(value["title"]);
        })();
        if (!parsed_title.success) {
          return { success: false };
        }
        return { success: true, value: { question: parsed_question.value, title: parsed_title.value } };
      })(value["question5"]);
    })();
    if (!parsed_question5.success) {
      return { success: false };
    }
    return { success: true, value: { question1: parsed_question1.value, question2: parsed_question2.value, question3: parsed_question3.value, question4: parsed_question4.value, question5: parsed_question5.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// MIABSummaryForm
export function serialize_MIABSummaryForm(obj: MIABSummaryForm): string {
  const serialized = ((value: { pageEnglish: number; pageNative: number; selectedChannel: bigint | null }): unknown => {
    return {
      pageEnglish: ((value: number): unknown => {
        return value;
      })(value["pageEnglish"]),
      pageNative: ((value: number): unknown => {
        return value;
      })(value["pageNative"]),
      selectedChannel: ((value: bigint | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["selectedChannel"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_MIABSummaryForm(str: string): MIABSummaryForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          pageEnglish: number;
          pageNative: number;
          selectedChannel: bigint | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "pageEnglish")) {
      return { success: false };
    }
    const parsed_pageEnglish = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageEnglish"]);
    })();
    if (!parsed_pageEnglish.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageNative")) {
      return { success: false };
    }
    const parsed_pageNative = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageNative"]);
    })();
    if (!parsed_pageNative.success) {
      return { success: false };
    }
    if (!hasProp(value, "selectedChannel")) {
      return { success: false };
    }
    const parsed_selectedChannel = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["selectedChannel"]);
    })();
    if (!parsed_selectedChannel.success) {
      return { success: false };
    }
    return { success: true, value: { pageEnglish: parsed_pageEnglish.value, pageNative: parsed_pageNative.value, selectedChannel: parsed_selectedChannel.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// MIABUserContext
export function serialize_MIABUserContext(obj: MIABUserContext): string {
  const serialized = ((value: { customerId: number; hash: string; username: string }): unknown => {
    return {
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
      hash: ((value: string): unknown => {
        return value;
      })(value["hash"]),
      username: ((value: string): unknown => {
        return value;
      })(value["username"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_MIABUserContext(str: string): MIABUserContext | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          customerId: number;
          hash: string;
          username: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "hash")) {
      return { success: false };
    }
    const parsed_hash = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["hash"]);
    })();
    if (!parsed_hash.success) {
      return { success: false };
    }
    if (!hasProp(value, "username")) {
      return { success: false };
    }
    const parsed_username = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["username"]);
    })();
    if (!parsed_username.success) {
      return { success: false };
    }
    return { success: true, value: { customerId: parsed_customerId.value, hash: parsed_hash.value, username: parsed_username.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// MIABView
export function serialize_MIABView(obj: MIABView): string {
  const serialized = ((value: { content: MIABContent; metadata: ContentMetadata; userContext: MIABUserContext | null }): unknown => {
    return {
      content: ((value: { data: MIABContentData; miabId: bigint }): unknown => {
        return {
          data: ((value: { createdAt: DateTime<true>; customerId: number | null; keyInsights: string[]; questions: MIABQuestionMetadata[]; summaryId: number }): unknown => {
            return {
              createdAt: ((value: DateTime<true>): unknown => {
                return value.toMillis();
              })(value["createdAt"]),
              customerId: ((value: number | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["customerId"]),
              keyInsights: ((value: string[]): unknown => {
                return value.map((value: string): unknown => {
                  return value;
                });
              })(value["keyInsights"]),
              questions: ((value: MIABQuestionMetadata[]): unknown => {
                return value.map((value: { createdAt: DateTime<true> | null; question: string; sortOrder: number; title: string }): unknown => {
                  return {
                    createdAt: ((value: DateTime<true> | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value.toMillis();
                    })(value["createdAt"]),
                    question: ((value: string): unknown => {
                      return value;
                    })(value["question"]),
                    sortOrder: ((value: number): unknown => {
                      return value;
                    })(value["sortOrder"]),
                    title: ((value: string): unknown => {
                      return value;
                    })(value["title"]),
                  };
                });
              })(value["questions"]),
              summaryId: ((value: number): unknown => {
                return value;
              })(value["summaryId"]),
            };
          })(value["data"]),
          miabId: ((value: bigint): unknown => {
            return value;
          })(value["miabId"]),
        };
      })(value["content"]),
      metadata: ((
        value:
          | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
          | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
          | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
          | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number },
      ): unknown => {
        switch (value.contentType) {
          case "SUMMARY": {
            return {
              contentType: "SUMMARY",
              backgroundColor: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["backgroundColor"]),
              common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                return {
                  audioActive: ((value: boolean): unknown => {
                    return value;
                  })(value["audioActive"]),
                  coverUri: ((value: string): unknown => {
                    return value;
                  })(value["coverUri"]),
                  description: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["description"]),
                  inactive: ((value: boolean): unknown => {
                    return value;
                  })(value["inactive"]),
                  language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                    return value;
                  })(value["language"]),
                  leadIn: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["leadIn"]),
                  link: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["link"]),
                  title: ((value: string): unknown => {
                    return value;
                  })(value["title"]),
                };
              })(value["common"]),
              contentId: ((value: { contentType: "SUMMARY"; id: bigint }): unknown => {
                return {
                  contentType: ((value: "SUMMARY"): unknown => {
                    return value;
                  })(value["contentType"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                };
              })(value["contentId"]),
              documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
                return value;
              })(value["documentType"]),
              meetingInABoxAvailable: ((value: boolean): unknown => {
                return value;
              })(value["meetingInABoxAvailable"]),
              sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
                return value;
              })(value["sourceType"]),
            };
          }
          case "ACTIONABLE": {
            return {
              contentType: "ACTIONABLE",
              common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                return {
                  audioActive: ((value: boolean): unknown => {
                    return value;
                  })(value["audioActive"]),
                  coverUri: ((value: string): unknown => {
                    return value;
                  })(value["coverUri"]),
                  description: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["description"]),
                  inactive: ((value: boolean): unknown => {
                    return value;
                  })(value["inactive"]),
                  language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                    return value;
                  })(value["language"]),
                  leadIn: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["leadIn"]),
                  link: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["link"]),
                  title: ((value: string): unknown => {
                    return value;
                  })(value["title"]),
                };
              })(value["common"]),
              contentId: ((value: { contentType: "ACTIONABLE"; id: bigint }): unknown => {
                return {
                  contentType: ((value: "ACTIONABLE"): unknown => {
                    return value;
                  })(value["contentType"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                };
              })(value["contentId"]),
            };
          }
          case "SKETCHNOTE": {
            return {
              contentType: "SKETCHNOTE",
              common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                return {
                  audioActive: ((value: boolean): unknown => {
                    return value;
                  })(value["audioActive"]),
                  coverUri: ((value: string): unknown => {
                    return value;
                  })(value["coverUri"]),
                  description: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["description"]),
                  inactive: ((value: boolean): unknown => {
                    return value;
                  })(value["inactive"]),
                  language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                    return value;
                  })(value["language"]),
                  leadIn: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["leadIn"]),
                  link: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["link"]),
                  title: ((value: string): unknown => {
                    return value;
                  })(value["title"]),
                };
              })(value["common"]),
              contentId: ((value: { contentType: "SKETCHNOTE"; id: bigint }): unknown => {
                return {
                  contentType: ((value: "SKETCHNOTE"): unknown => {
                    return value;
                  })(value["contentType"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                };
              })(value["contentId"]),
            };
          }
          case "MIAB": {
            return {
              contentType: "MIAB",
              common: ((value: { audioActive: boolean; coverUri: string; description: string | null; inactive: boolean; language: Language; leadIn: string | null; link: string | null; title: string }): unknown => {
                return {
                  audioActive: ((value: boolean): unknown => {
                    return value;
                  })(value["audioActive"]),
                  coverUri: ((value: string): unknown => {
                    return value;
                  })(value["coverUri"]),
                  description: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["description"]),
                  inactive: ((value: boolean): unknown => {
                    return value;
                  })(value["inactive"]),
                  language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                    return value;
                  })(value["language"]),
                  leadIn: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["leadIn"]),
                  link: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["link"]),
                  title: ((value: string): unknown => {
                    return value;
                  })(value["title"]),
                };
              })(value["common"]),
              contentId: ((value: { contentType: "MIAB"; id: bigint }): unknown => {
                return {
                  contentType: ((value: "MIAB"): unknown => {
                    return value;
                  })(value["contentType"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                };
              })(value["contentId"]),
              customerId: ((value: number | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["customerId"]),
              dataId: ((value: number): unknown => {
                return value;
              })(value["dataId"]),
            };
          }
        }
      })(value["metadata"]),
      userContext: ((
        value: {
          customerId: number;
          hash: string;
          username: string;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          customerId: ((value: number): unknown => {
            return value;
          })(value["customerId"]),
          hash: ((value: string): unknown => {
            return value;
          })(value["hash"]),
          username: ((value: string): unknown => {
            return value;
          })(value["username"]),
        };
      })(value["userContext"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_MIABView(str: string): MIABView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          content: MIABContent;
          metadata: ContentMetadata;
          userContext: MIABUserContext | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "content")) {
      return { success: false };
    }
    const parsed_content = (() => {
      return ((value: unknown): { success: false } | { success: true; value: MIABContent } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                data: MIABContentData;
                miabId: bigint;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "data")) {
            return { success: false };
          }
          const parsed_data = (() => {
            return ((value: unknown): { success: false } | { success: true; value: MIABContentData } => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      createdAt: DateTime<true>;
                      customerId: number | null;
                      keyInsights: string[];
                      questions: MIABQuestionMetadata[];
                      summaryId: number;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "createdAt")) {
                  return { success: false };
                }
                const parsed_createdAt = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                    if (typeof value !== "number") {
                      return { success: false };
                    }
                    const parsed = DateTime.fromMillis(value);
                    if (!parsed.isValid) {
                      return { success: false };
                    }
                    return { success: true, value: parsed };
                  })(value["createdAt"]);
                })();
                if (!parsed_createdAt.success) {
                  return { success: false };
                }
                if (!hasProp(value, "customerId")) {
                  return { success: false };
                }
                const parsed_customerId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value === "number") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["customerId"]);
                })();
                if (!parsed_customerId.success) {
                  return { success: false };
                }
                if (!hasProp(value, "keyInsights")) {
                  return { success: false };
                }
                const parsed_keyInsights = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                    if (!isArray(value)) {
                      return { success: false };
                    }
                    const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    });
                    if (parsed.find((value) => !value.success) !== undefined) {
                      return { success: false };
                    }
                    return {
                      success: true,
                      value: parsed.map((entry) => {
                        assert(entry.success == true);
                        return entry.value;
                      }),
                    };
                  })(value["keyInsights"]);
                })();
                if (!parsed_keyInsights.success) {
                  return { success: false };
                }
                if (!hasProp(value, "questions")) {
                  return { success: false };
                }
                const parsed_questions = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: MIABQuestionMetadata[] } => {
                    if (!isArray(value)) {
                      return { success: false };
                    }
                    const parsed = value.map((value: unknown): { success: false } | { success: true; value: MIABQuestionMetadata } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              createdAt: DateTime<true> | null;
                              question: string;
                              sortOrder: number;
                              title: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "createdAt")) {
                          return { success: false };
                        }
                        const parsed_createdAt = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "number") {
                              return { success: false };
                            }
                            const parsed = DateTime.fromMillis(value);
                            if (!parsed.isValid) {
                              return { success: false };
                            }
                            return { success: true, value: parsed };
                          })(value["createdAt"]);
                        })();
                        if (!parsed_createdAt.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "question")) {
                          return { success: false };
                        }
                        const parsed_question = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["question"]);
                        })();
                        if (!parsed_question.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "sortOrder")) {
                          return { success: false };
                        }
                        const parsed_sortOrder = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: number } => {
                            if (typeof value === "number") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["sortOrder"]);
                        })();
                        if (!parsed_sortOrder.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "title")) {
                          return { success: false };
                        }
                        const parsed_title = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["title"]);
                        })();
                        if (!parsed_title.success) {
                          return { success: false };
                        }
                        return { success: true, value: { createdAt: parsed_createdAt.value, question: parsed_question.value, sortOrder: parsed_sortOrder.value, title: parsed_title.value } };
                      })(value);
                    });
                    if (parsed.find((value) => !value.success) !== undefined) {
                      return { success: false };
                    }
                    return {
                      success: true,
                      value: parsed.map((entry) => {
                        assert(entry.success == true);
                        return entry.value;
                      }),
                    };
                  })(value["questions"]);
                })();
                if (!parsed_questions.success) {
                  return { success: false };
                }
                if (!hasProp(value, "summaryId")) {
                  return { success: false };
                }
                const parsed_summaryId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: number } => {
                    if (typeof value === "number") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["summaryId"]);
                })();
                if (!parsed_summaryId.success) {
                  return { success: false };
                }
                return { success: true, value: { createdAt: parsed_createdAt.value, customerId: parsed_customerId.value, keyInsights: parsed_keyInsights.value, questions: parsed_questions.value, summaryId: parsed_summaryId.value } };
              })(value);
            })(value["data"]);
          })();
          if (!parsed_data.success) {
            return { success: false };
          }
          if (!hasProp(value, "miabId")) {
            return { success: false };
          }
          const parsed_miabId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
              if (typeof value === "number") {
                return { success: true, value: BigInt(value) };
              }
              if (typeof value === "bigint") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["miabId"]);
          })();
          if (!parsed_miabId.success) {
            return { success: false };
          }
          return { success: true, value: { data: parsed_data.value, miabId: parsed_miabId.value } };
        })(value);
      })(value["content"]);
    })();
    if (!parsed_content.success) {
      return { success: false };
    }
    if (!hasProp(value, "metadata")) {
      return { success: false };
    }
    const parsed_metadata = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ContentMetadata } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value:
                | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
                | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
                | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
                | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number };
            } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { contentType: "SUMMARY"; backgroundColor: string | null; common: ContentMetadataCommon; contentId: SummaryId; documentType: SummaryDocumentType; meetingInABoxAvailable: boolean; sourceType: SummarySourceType }
                  | { contentType: "ACTIONABLE"; common: ContentMetadataCommon; contentId: ActionableId }
                  | { contentType: "SKETCHNOTE"; common: ContentMetadataCommon; contentId: LgxpId }
                  | { contentType: "MIAB"; common: ContentMetadataCommon; contentId: MiabId; customerId: number | null; dataId: number };
              } => {
            if (value == null) {
              return { success: false };
            }
            if (typeof value != "object") {
              return { success: false };
            }
            if (!hasProp(value, "contentType")) {
              return { success: false };
            }
            if (typeof value["contentType"] != "string") {
              return { success: false };
            }
            const discriminator = value["contentType"];
            switch (discriminator) {
              case "SUMMARY": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        backgroundColor: string | null;
                        common: ContentMetadataCommon;
                        contentId: SummaryId;
                        documentType: SummaryDocumentType;
                        meetingInABoxAvailable: boolean;
                        sourceType: SummarySourceType;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "backgroundColor")) {
                    return { success: false };
                  }
                  const parsed_backgroundColor = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["backgroundColor"]);
                  })();
                  if (!parsed_backgroundColor.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "common")) {
                    return { success: false };
                  }
                  const parsed_common = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              audioActive: boolean;
                              coverUri: string;
                              description: string | null;
                              inactive: boolean;
                              language: Language;
                              leadIn: string | null;
                              link: string | null;
                              title: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "audioActive")) {
                          return { success: false };
                        }
                        const parsed_audioActive = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                            if (typeof value !== "boolean") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["audioActive"]);
                        })();
                        if (!parsed_audioActive.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "coverUri")) {
                          return { success: false };
                        }
                        const parsed_coverUri = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["coverUri"]);
                        })();
                        if (!parsed_coverUri.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "description")) {
                          return { success: false };
                        }
                        const parsed_description = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["description"]);
                        })();
                        if (!parsed_description.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "inactive")) {
                          return { success: false };
                        }
                        const parsed_inactive = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                            if (typeof value !== "boolean") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["inactive"]);
                        })();
                        if (!parsed_inactive.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "language")) {
                          return { success: false };
                        }
                        const parsed_language = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: Language } => {
                            return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "en":
                                  return { success: true, value: "en" };
                                case "de":
                                  return { success: true, value: "de" };
                                case "es":
                                  return { success: true, value: "es" };
                                case "ru":
                                  return { success: true, value: "ru" };
                                case "zh":
                                  return { success: true, value: "zh" };
                                case "pt":
                                  return { success: true, value: "pt" };
                                case "fr":
                                  return { success: true, value: "fr" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["language"]);
                        })();
                        if (!parsed_language.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "leadIn")) {
                          return { success: false };
                        }
                        const parsed_leadIn = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["leadIn"]);
                        })();
                        if (!parsed_leadIn.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "link")) {
                          return { success: false };
                        }
                        const parsed_link = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["link"]);
                        })();
                        if (!parsed_link.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "title")) {
                          return { success: false };
                        }
                        const parsed_title = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["title"]);
                        })();
                        if (!parsed_title.success) {
                          return { success: false };
                        }
                        return {
                          success: true,
                          value: {
                            audioActive: parsed_audioActive.value,
                            coverUri: parsed_coverUri.value,
                            description: parsed_description.value,
                            inactive: parsed_inactive.value,
                            language: parsed_language.value,
                            leadIn: parsed_leadIn.value,
                            link: parsed_link.value,
                            title: parsed_title.value,
                          },
                        };
                      })(value);
                    })(value["common"]);
                  })();
                  if (!parsed_common.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "contentId")) {
                    return { success: false };
                  }
                  const parsed_contentId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: SummaryId } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              contentType: "SUMMARY";
                              id: bigint;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "contentType")) {
                          return { success: false };
                        }
                        const parsed_contentType = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            switch (value) {
                              case "SUMMARY":
                                return { success: true, value: "SUMMARY" };
                              default:
                                return { success: false };
                            }
                          })(value["contentType"]);
                        })();
                        if (!parsed_contentType.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                      })(value);
                    })(value["contentId"]);
                  })();
                  if (!parsed_contentId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "documentType")) {
                    return { success: false };
                  }
                  const parsed_documentType = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "ABSTRACT":
                            return { success: true, value: "ABSTRACT" };
                          case "REVIEW":
                            return { success: true, value: "REVIEW" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["documentType"]);
                  })();
                  if (!parsed_documentType.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "meetingInABoxAvailable")) {
                    return { success: false };
                  }
                  const parsed_meetingInABoxAvailable = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["meetingInABoxAvailable"]);
                  })();
                  if (!parsed_meetingInABoxAvailable.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "sourceType")) {
                    return { success: false };
                  }
                  const parsed_sourceType = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "BOOK":
                            return { success: true, value: "BOOK" };
                          case "VIDEO":
                            return { success: true, value: "VIDEO" };
                          case "ARTICLE":
                            return { success: true, value: "ARTICLE" };
                          case "CHAPTER":
                            return { success: true, value: "CHAPTER" };
                          case "REPORT":
                            return { success: true, value: "REPORT" };
                          case "PODCAST":
                            return { success: true, value: "PODCAST" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["sourceType"]);
                  })();
                  if (!parsed_sourceType.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      backgroundColor: parsed_backgroundColor.value,
                      common: parsed_common.value,
                      contentId: parsed_contentId.value,
                      documentType: parsed_documentType.value,
                      meetingInABoxAvailable: parsed_meetingInABoxAvailable.value,
                      sourceType: parsed_sourceType.value,
                    },
                  };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { contentType: "SUMMARY" as const, ...parsedVariant.value } };
              }
              case "ACTIONABLE": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        common: ContentMetadataCommon;
                        contentId: ActionableId;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "common")) {
                    return { success: false };
                  }
                  const parsed_common = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              audioActive: boolean;
                              coverUri: string;
                              description: string | null;
                              inactive: boolean;
                              language: Language;
                              leadIn: string | null;
                              link: string | null;
                              title: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "audioActive")) {
                          return { success: false };
                        }
                        const parsed_audioActive = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                            if (typeof value !== "boolean") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["audioActive"]);
                        })();
                        if (!parsed_audioActive.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "coverUri")) {
                          return { success: false };
                        }
                        const parsed_coverUri = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["coverUri"]);
                        })();
                        if (!parsed_coverUri.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "description")) {
                          return { success: false };
                        }
                        const parsed_description = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["description"]);
                        })();
                        if (!parsed_description.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "inactive")) {
                          return { success: false };
                        }
                        const parsed_inactive = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                            if (typeof value !== "boolean") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["inactive"]);
                        })();
                        if (!parsed_inactive.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "language")) {
                          return { success: false };
                        }
                        const parsed_language = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: Language } => {
                            return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "en":
                                  return { success: true, value: "en" };
                                case "de":
                                  return { success: true, value: "de" };
                                case "es":
                                  return { success: true, value: "es" };
                                case "ru":
                                  return { success: true, value: "ru" };
                                case "zh":
                                  return { success: true, value: "zh" };
                                case "pt":
                                  return { success: true, value: "pt" };
                                case "fr":
                                  return { success: true, value: "fr" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["language"]);
                        })();
                        if (!parsed_language.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "leadIn")) {
                          return { success: false };
                        }
                        const parsed_leadIn = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["leadIn"]);
                        })();
                        if (!parsed_leadIn.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "link")) {
                          return { success: false };
                        }
                        const parsed_link = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["link"]);
                        })();
                        if (!parsed_link.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "title")) {
                          return { success: false };
                        }
                        const parsed_title = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["title"]);
                        })();
                        if (!parsed_title.success) {
                          return { success: false };
                        }
                        return {
                          success: true,
                          value: {
                            audioActive: parsed_audioActive.value,
                            coverUri: parsed_coverUri.value,
                            description: parsed_description.value,
                            inactive: parsed_inactive.value,
                            language: parsed_language.value,
                            leadIn: parsed_leadIn.value,
                            link: parsed_link.value,
                            title: parsed_title.value,
                          },
                        };
                      })(value);
                    })(value["common"]);
                  })();
                  if (!parsed_common.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "contentId")) {
                    return { success: false };
                  }
                  const parsed_contentId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: ActionableId } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              contentType: "ACTIONABLE";
                              id: bigint;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "contentType")) {
                          return { success: false };
                        }
                        const parsed_contentType = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: "ACTIONABLE" } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            switch (value) {
                              case "ACTIONABLE":
                                return { success: true, value: "ACTIONABLE" };
                              default:
                                return { success: false };
                            }
                          })(value["contentType"]);
                        })();
                        if (!parsed_contentType.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                      })(value);
                    })(value["contentId"]);
                  })();
                  if (!parsed_contentId.success) {
                    return { success: false };
                  }
                  return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { contentType: "ACTIONABLE" as const, ...parsedVariant.value } };
              }
              case "SKETCHNOTE": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        common: ContentMetadataCommon;
                        contentId: LgxpId;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "common")) {
                    return { success: false };
                  }
                  const parsed_common = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              audioActive: boolean;
                              coverUri: string;
                              description: string | null;
                              inactive: boolean;
                              language: Language;
                              leadIn: string | null;
                              link: string | null;
                              title: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "audioActive")) {
                          return { success: false };
                        }
                        const parsed_audioActive = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                            if (typeof value !== "boolean") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["audioActive"]);
                        })();
                        if (!parsed_audioActive.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "coverUri")) {
                          return { success: false };
                        }
                        const parsed_coverUri = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["coverUri"]);
                        })();
                        if (!parsed_coverUri.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "description")) {
                          return { success: false };
                        }
                        const parsed_description = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["description"]);
                        })();
                        if (!parsed_description.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "inactive")) {
                          return { success: false };
                        }
                        const parsed_inactive = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                            if (typeof value !== "boolean") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["inactive"]);
                        })();
                        if (!parsed_inactive.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "language")) {
                          return { success: false };
                        }
                        const parsed_language = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: Language } => {
                            return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "en":
                                  return { success: true, value: "en" };
                                case "de":
                                  return { success: true, value: "de" };
                                case "es":
                                  return { success: true, value: "es" };
                                case "ru":
                                  return { success: true, value: "ru" };
                                case "zh":
                                  return { success: true, value: "zh" };
                                case "pt":
                                  return { success: true, value: "pt" };
                                case "fr":
                                  return { success: true, value: "fr" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["language"]);
                        })();
                        if (!parsed_language.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "leadIn")) {
                          return { success: false };
                        }
                        const parsed_leadIn = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["leadIn"]);
                        })();
                        if (!parsed_leadIn.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "link")) {
                          return { success: false };
                        }
                        const parsed_link = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["link"]);
                        })();
                        if (!parsed_link.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "title")) {
                          return { success: false };
                        }
                        const parsed_title = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["title"]);
                        })();
                        if (!parsed_title.success) {
                          return { success: false };
                        }
                        return {
                          success: true,
                          value: {
                            audioActive: parsed_audioActive.value,
                            coverUri: parsed_coverUri.value,
                            description: parsed_description.value,
                            inactive: parsed_inactive.value,
                            language: parsed_language.value,
                            leadIn: parsed_leadIn.value,
                            link: parsed_link.value,
                            title: parsed_title.value,
                          },
                        };
                      })(value);
                    })(value["common"]);
                  })();
                  if (!parsed_common.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "contentId")) {
                    return { success: false };
                  }
                  const parsed_contentId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: LgxpId } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              contentType: "SKETCHNOTE";
                              id: bigint;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "contentType")) {
                          return { success: false };
                        }
                        const parsed_contentType = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: "SKETCHNOTE" } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            switch (value) {
                              case "SKETCHNOTE":
                                return { success: true, value: "SKETCHNOTE" };
                              default:
                                return { success: false };
                            }
                          })(value["contentType"]);
                        })();
                        if (!parsed_contentType.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                      })(value);
                    })(value["contentId"]);
                  })();
                  if (!parsed_contentId.success) {
                    return { success: false };
                  }
                  return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { contentType: "SKETCHNOTE" as const, ...parsedVariant.value } };
              }
              case "MIAB": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        common: ContentMetadataCommon;
                        contentId: MiabId;
                        customerId: number | null;
                        dataId: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "common")) {
                    return { success: false };
                  }
                  const parsed_common = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: ContentMetadataCommon } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              audioActive: boolean;
                              coverUri: string;
                              description: string | null;
                              inactive: boolean;
                              language: Language;
                              leadIn: string | null;
                              link: string | null;
                              title: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "audioActive")) {
                          return { success: false };
                        }
                        const parsed_audioActive = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                            if (typeof value !== "boolean") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["audioActive"]);
                        })();
                        if (!parsed_audioActive.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "coverUri")) {
                          return { success: false };
                        }
                        const parsed_coverUri = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["coverUri"]);
                        })();
                        if (!parsed_coverUri.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "description")) {
                          return { success: false };
                        }
                        const parsed_description = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["description"]);
                        })();
                        if (!parsed_description.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "inactive")) {
                          return { success: false };
                        }
                        const parsed_inactive = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                            if (typeof value !== "boolean") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["inactive"]);
                        })();
                        if (!parsed_inactive.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "language")) {
                          return { success: false };
                        }
                        const parsed_language = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: Language } => {
                            return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "en":
                                  return { success: true, value: "en" };
                                case "de":
                                  return { success: true, value: "de" };
                                case "es":
                                  return { success: true, value: "es" };
                                case "ru":
                                  return { success: true, value: "ru" };
                                case "zh":
                                  return { success: true, value: "zh" };
                                case "pt":
                                  return { success: true, value: "pt" };
                                case "fr":
                                  return { success: true, value: "fr" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["language"]);
                        })();
                        if (!parsed_language.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "leadIn")) {
                          return { success: false };
                        }
                        const parsed_leadIn = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["leadIn"]);
                        })();
                        if (!parsed_leadIn.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "link")) {
                          return { success: false };
                        }
                        const parsed_link = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["link"]);
                        })();
                        if (!parsed_link.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "title")) {
                          return { success: false };
                        }
                        const parsed_title = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["title"]);
                        })();
                        if (!parsed_title.success) {
                          return { success: false };
                        }
                        return {
                          success: true,
                          value: {
                            audioActive: parsed_audioActive.value,
                            coverUri: parsed_coverUri.value,
                            description: parsed_description.value,
                            inactive: parsed_inactive.value,
                            language: parsed_language.value,
                            leadIn: parsed_leadIn.value,
                            link: parsed_link.value,
                            title: parsed_title.value,
                          },
                        };
                      })(value);
                    })(value["common"]);
                  })();
                  if (!parsed_common.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "contentId")) {
                    return { success: false };
                  }
                  const parsed_contentId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: MiabId } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              contentType: "MIAB";
                              id: bigint;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "contentType")) {
                          return { success: false };
                        }
                        const parsed_contentType = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: "MIAB" } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            switch (value) {
                              case "MIAB":
                                return { success: true, value: "MIAB" };
                              default:
                                return { success: false };
                            }
                          })(value["contentType"]);
                        })();
                        if (!parsed_contentType.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
                      })(value);
                    })(value["contentId"]);
                  })();
                  if (!parsed_contentId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "customerId")) {
                    return { success: false };
                  }
                  const parsed_customerId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["customerId"]);
                  })();
                  if (!parsed_customerId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "dataId")) {
                    return { success: false };
                  }
                  const parsed_dataId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["dataId"]);
                  })();
                  if (!parsed_dataId.success) {
                    return { success: false };
                  }
                  return { success: true, value: { common: parsed_common.value, contentId: parsed_contentId.value, customerId: parsed_customerId.value, dataId: parsed_dataId.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { contentType: "MIAB" as const, ...parsedVariant.value } };
              }
              default:
                return { success: false };
            }
          })(value);
        })(value);
      })(value["metadata"]);
    })();
    if (!parsed_metadata.success) {
      return { success: false };
    }
    if (!hasProp(value, "userContext")) {
      return { success: false };
    }
    const parsed_userContext = (() => {
      return ((value: unknown): { success: false } | { success: true; value: MIABUserContext | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                customerId: number;
                hash: string;
                username: string;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "customerId")) {
            return { success: false };
          }
          const parsed_customerId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["customerId"]);
          })();
          if (!parsed_customerId.success) {
            return { success: false };
          }
          if (!hasProp(value, "hash")) {
            return { success: false };
          }
          const parsed_hash = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["hash"]);
          })();
          if (!parsed_hash.success) {
            return { success: false };
          }
          if (!hasProp(value, "username")) {
            return { success: false };
          }
          const parsed_username = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["username"]);
          })();
          if (!parsed_username.success) {
            return { success: false };
          }
          return { success: true, value: { customerId: parsed_customerId.value, hash: parsed_hash.value, username: parsed_username.value } };
        })(value);
      })(value["userContext"]);
    })();
    if (!parsed_userContext.success) {
      return { success: false };
    }
    return { success: true, value: { content: parsed_content.value, metadata: parsed_metadata.value, userContext: parsed_userContext.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// MiabId
export function serialize_MiabId(obj: MiabId): string {
  const serialized = ((value: { contentType: "MIAB"; id: bigint }): unknown => {
    return {
      contentType: ((value: "MIAB"): unknown => {
        return value;
      })(value["contentType"]),
      id: ((value: bigint): unknown => {
        return value;
      })(value["id"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_MiabId(str: string): MiabId | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          contentType: "MIAB";
          id: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "contentType")) {
      return { success: false };
    }
    const parsed_contentType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "MIAB" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "MIAB":
            return { success: true, value: "MIAB" };
          default:
            return { success: false };
        }
      })(value["contentType"]);
    })();
    if (!parsed_contentType.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// MilesPaymentView
export function serialize_MilesPaymentView(obj: MilesPaymentView): string {
  const serialized = ((value: { cardNumber: string | null; milesTransactionId: number | null; pin: string | null; transactionId: number }): unknown => {
    return {
      cardNumber: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["cardNumber"]),
      milesTransactionId: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["milesTransactionId"]),
      pin: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["pin"]),
      transactionId: ((value: number): unknown => {
        return value;
      })(value["transactionId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_MilesPaymentView(str: string): MilesPaymentView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          cardNumber: string | null;
          milesTransactionId: number | null;
          pin: string | null;
          transactionId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "cardNumber")) {
      return { success: false };
    }
    const parsed_cardNumber = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["cardNumber"]);
    })();
    if (!parsed_cardNumber.success) {
      return { success: false };
    }
    if (!hasProp(value, "milesTransactionId")) {
      return { success: false };
    }
    const parsed_milesTransactionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["milesTransactionId"]);
    })();
    if (!parsed_milesTransactionId.success) {
      return { success: false };
    }
    if (!hasProp(value, "pin")) {
      return { success: false };
    }
    const parsed_pin = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["pin"]);
    })();
    if (!parsed_pin.success) {
      return { success: false };
    }
    if (!hasProp(value, "transactionId")) {
      return { success: false };
    }
    const parsed_transactionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["transactionId"]);
    })();
    if (!parsed_transactionId.success) {
      return { success: false };
    }
    return { success: true, value: { cardNumber: parsed_cardNumber.value, milesTransactionId: parsed_milesTransactionId.value, pin: parsed_pin.value, transactionId: parsed_transactionId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// MobileAppLoginPreferenceListView
export function serialize_MobileAppLoginPreferenceListView(obj: MobileAppLoginPreferenceListView): string {
  const serialized = ((value: { corporateId: number; corporationName: string }): unknown => {
    return {
      corporateId: ((value: number): unknown => {
        return value;
      })(value["corporateId"]),
      corporationName: ((value: string): unknown => {
        return value;
      })(value["corporationName"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_MobileAppLoginPreferenceListView(str: string): MobileAppLoginPreferenceListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          corporateId: number;
          corporationName: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "corporationName")) {
      return { success: false };
    }
    const parsed_corporationName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["corporationName"]);
    })();
    if (!parsed_corporationName.success) {
      return { success: false };
    }
    return { success: true, value: { corporateId: parsed_corporateId.value, corporationName: parsed_corporationName.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// MobileDevice
export function serialize_MobileDevice(obj: MobileDevice): string {
  const serialized = ((value: {
    corporateId: number;
    customerId: number;
    deviceId: string;
    deviceType: string;
    firstUsedAt: DateTime<true>;
    language: Language;
    lastPushAt: DateTime<true> | null;
    lastUsedAt: DateTime<true>;
    osType: string | null;
    pushFrequency: PushFrequency;
    pushToken: string | null;
    version: string;
  }): unknown => {
    return {
      corporateId: ((value: number): unknown => {
        return value;
      })(value["corporateId"]),
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
      deviceId: ((value: string): unknown => {
        return value;
      })(value["deviceId"]),
      deviceType: ((value: string): unknown => {
        return value;
      })(value["deviceType"]),
      firstUsedAt: ((value: DateTime<true>): unknown => {
        return value.toMillis();
      })(value["firstUsedAt"]),
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      lastPushAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["lastPushAt"]),
      lastUsedAt: ((value: DateTime<true>): unknown => {
        return value.toMillis();
      })(value["lastUsedAt"]),
      osType: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["osType"]),
      pushFrequency: ((value: "NONE" | "DAILY" | "ONCE_A_WEEK" | "TWICE_A_MONTH" | "ONCE_A_MONTH"): unknown => {
        return value;
      })(value["pushFrequency"]),
      pushToken: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["pushToken"]),
      version: ((value: string): unknown => {
        return value;
      })(value["version"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_MobileDevice(str: string): MobileDevice | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          corporateId: number;
          customerId: number;
          deviceId: string;
          deviceType: string;
          firstUsedAt: DateTime<true>;
          language: Language;
          lastPushAt: DateTime<true> | null;
          lastUsedAt: DateTime<true>;
          osType: string | null;
          pushFrequency: PushFrequency;
          pushToken: string | null;
          version: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "deviceId")) {
      return { success: false };
    }
    const parsed_deviceId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["deviceId"]);
    })();
    if (!parsed_deviceId.success) {
      return { success: false };
    }
    if (!hasProp(value, "deviceType")) {
      return { success: false };
    }
    const parsed_deviceType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["deviceType"]);
    })();
    if (!parsed_deviceType.success) {
      return { success: false };
    }
    if (!hasProp(value, "firstUsedAt")) {
      return { success: false };
    }
    const parsed_firstUsedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["firstUsedAt"]);
    })();
    if (!parsed_firstUsedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language } => {
        return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "en":
              return { success: true, value: "en" };
            case "de":
              return { success: true, value: "de" };
            case "es":
              return { success: true, value: "es" };
            case "ru":
              return { success: true, value: "ru" };
            case "zh":
              return { success: true, value: "zh" };
            case "pt":
              return { success: true, value: "pt" };
            case "fr":
              return { success: true, value: "fr" };
            default:
              return { success: false };
          }
        })(value);
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "lastPushAt")) {
      return { success: false };
    }
    const parsed_lastPushAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["lastPushAt"]);
    })();
    if (!parsed_lastPushAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "lastUsedAt")) {
      return { success: false };
    }
    const parsed_lastUsedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["lastUsedAt"]);
    })();
    if (!parsed_lastUsedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "osType")) {
      return { success: false };
    }
    const parsed_osType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["osType"]);
    })();
    if (!parsed_osType.success) {
      return { success: false };
    }
    if (!hasProp(value, "pushFrequency")) {
      return { success: false };
    }
    const parsed_pushFrequency = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PushFrequency } => {
        return ((value: unknown): { success: false } | { success: true; value: "NONE" | "DAILY" | "ONCE_A_WEEK" | "TWICE_A_MONTH" | "ONCE_A_MONTH" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "NONE":
              return { success: true, value: "NONE" };
            case "DAILY":
              return { success: true, value: "DAILY" };
            case "ONCE_A_WEEK":
              return { success: true, value: "ONCE_A_WEEK" };
            case "TWICE_A_MONTH":
              return { success: true, value: "TWICE_A_MONTH" };
            case "ONCE_A_MONTH":
              return { success: true, value: "ONCE_A_MONTH" };
            default:
              return { success: false };
          }
        })(value);
      })(value["pushFrequency"]);
    })();
    if (!parsed_pushFrequency.success) {
      return { success: false };
    }
    if (!hasProp(value, "pushToken")) {
      return { success: false };
    }
    const parsed_pushToken = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["pushToken"]);
    })();
    if (!parsed_pushToken.success) {
      return { success: false };
    }
    if (!hasProp(value, "version")) {
      return { success: false };
    }
    const parsed_version = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["version"]);
    })();
    if (!parsed_version.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        corporateId: parsed_corporateId.value,
        customerId: parsed_customerId.value,
        deviceId: parsed_deviceId.value,
        deviceType: parsed_deviceType.value,
        firstUsedAt: parsed_firstUsedAt.value,
        language: parsed_language.value,
        lastPushAt: parsed_lastPushAt.value,
        lastUsedAt: parsed_lastUsedAt.value,
        osType: parsed_osType.value,
        pushFrequency: parsed_pushFrequency.value,
        pushToken: parsed_pushToken.value,
        version: parsed_version.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// MonetaryAmount
export function serialize_MonetaryAmount(obj: MonetaryAmount): string {
  const serialized = ((value: { amount: number; currencyCode: string; displayValue: string }): unknown => {
    return {
      amount: ((value: number): unknown => {
        return value;
      })(value["amount"]),
      currencyCode: ((value: string): unknown => {
        return value;
      })(value["currencyCode"]),
      displayValue: ((value: string): unknown => {
        return value;
      })(value["displayValue"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_MonetaryAmount(str: string): MonetaryAmount | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          amount: number;
          currencyCode: string;
          displayValue: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "amount")) {
      return { success: false };
    }
    const parsed_amount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        if (typeof value === "bigint") {
          return { success: true, value: Number(value) };
        }
        return { success: false };
      })(value["amount"]);
    })();
    if (!parsed_amount.success) {
      return { success: false };
    }
    if (!hasProp(value, "currencyCode")) {
      return { success: false };
    }
    const parsed_currencyCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["currencyCode"]);
    })();
    if (!parsed_currencyCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "displayValue")) {
      return { success: false };
    }
    const parsed_displayValue = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["displayValue"]);
    })();
    if (!parsed_displayValue.success) {
      return { success: false };
    }
    return { success: true, value: { amount: parsed_amount.value, currencyCode: parsed_currencyCode.value, displayValue: parsed_displayValue.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// MyListPortletView
export function serialize_MyListPortletView(obj: MyListPortletView): string {
  const serialized = ((value: { items: MyListView[]; metadata: PortletMetadata; page: number; pageSize: number; totalCount: bigint; viewAllUri: string | null }): unknown => {
    return {
      items: ((value: MyListView[]): unknown => {
        return value.map((value: { coverUri: string; key: string; myListId: bigint; name: string }): unknown => {
          return {
            coverUri: ((value: string): unknown => {
              return value;
            })(value["coverUri"]),
            key: ((value: string): unknown => {
              return value;
            })(value["key"]),
            myListId: ((value: bigint): unknown => {
              return value;
            })(value["myListId"]),
            name: ((value: string): unknown => {
              return value;
            })(value["name"]),
          };
        });
      })(value["items"]),
      metadata: ((value: {
        custom: boolean;
        defaultFilterAllLanguages: boolean;
        embedSortingFiltering: boolean;
        hideTitle: boolean;
        itemCount: number;
        itemLayout: ItemLayout;
        name: string;
        portletId: number;
        portletLayout: PortletLayout;
        subType: string | null;
        subtitle: string;
        title: string;
        type: PortletType;
      }): unknown => {
        return {
          custom: ((value: boolean): unknown => {
            return value;
          })(value["custom"]),
          defaultFilterAllLanguages: ((value: boolean): unknown => {
            return value;
          })(value["defaultFilterAllLanguages"]),
          embedSortingFiltering: ((value: boolean): unknown => {
            return value;
          })(value["embedSortingFiltering"]),
          hideTitle: ((value: boolean): unknown => {
            return value;
          })(value["hideTitle"]),
          itemCount: ((value: number): unknown => {
            return value;
          })(value["itemCount"]),
          itemLayout: ((value: "MINI" | "REGULAR"): unknown => {
            return value;
          })(value["itemLayout"]),
          name: ((value: string): unknown => {
            return value;
          })(value["name"]),
          portletId: ((value: number): unknown => {
            return value;
          })(value["portletId"]),
          portletLayout: ((value: "GRID" | "CAROUSEL"): unknown => {
            return value;
          })(value["portletLayout"]),
          subType: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["subType"]),
          subtitle: ((value: string): unknown => {
            return value;
          })(value["subtitle"]),
          title: ((value: string): unknown => {
            return value;
          })(value["title"]),
          type: ((
            value:
              | "IMAGETEXT"
              | "SUMMARYLIST"
              | "TRENDINGCHANNEL"
              | "FEATUREDCHANNEL"
              | "FOLLOWINGCHANNEL"
              | "MYLIST"
              | "PRIMARYTOPICCHANNEL"
              | "CHANNELLIST"
              | "ANNOTATIONLIST"
              | "LGXPLIST"
              | "SKETCHNOTESLGXP"
              | "ACTIONABLELIST"
              | "ACTIONABLELISTCUSTOM"
              | "MYACTIONABLES"
              | "FREESUMMARY"
              | "SUMMARYLISTCUSTOM"
              | "CUSTOMPAGELIST"
              | "PROMOBOXCONFIG"
              | "MYMIABS"
              | "ASKGETABSTRACT"
              | "CUSTOMIZEDMIABS",
          ): unknown => {
            return value;
          })(value["type"]),
        };
      })(value["metadata"]),
      page: ((value: number): unknown => {
        return value;
      })(value["page"]),
      pageSize: ((value: number): unknown => {
        return value;
      })(value["pageSize"]),
      totalCount: ((value: bigint): unknown => {
        return value;
      })(value["totalCount"]),
      viewAllUri: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["viewAllUri"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_MyListPortletView(str: string): MyListPortletView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: MyListView[];
          metadata: PortletMetadata;
          page: number;
          pageSize: number;
          totalCount: bigint;
          viewAllUri: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: MyListView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: MyListView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  coverUri: string;
                  key: string;
                  myListId: bigint;
                  name: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "coverUri")) {
              return { success: false };
            }
            const parsed_coverUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["coverUri"]);
            })();
            if (!parsed_coverUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "key")) {
              return { success: false };
            }
            const parsed_key = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["key"]);
            })();
            if (!parsed_key.success) {
              return { success: false };
            }
            if (!hasProp(value, "myListId")) {
              return { success: false };
            }
            const parsed_myListId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["myListId"]);
            })();
            if (!parsed_myListId.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            return { success: true, value: { coverUri: parsed_coverUri.value, key: parsed_key.value, myListId: parsed_myListId.value, name: parsed_name.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "metadata")) {
      return { success: false };
    }
    const parsed_metadata = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PortletMetadata } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                custom: boolean;
                defaultFilterAllLanguages: boolean;
                embedSortingFiltering: boolean;
                hideTitle: boolean;
                itemCount: number;
                itemLayout: ItemLayout;
                name: string;
                portletId: number;
                portletLayout: PortletLayout;
                subType: string | null;
                subtitle: string;
                title: string;
                type: PortletType;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "custom")) {
            return { success: false };
          }
          const parsed_custom = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["custom"]);
          })();
          if (!parsed_custom.success) {
            return { success: false };
          }
          if (!hasProp(value, "defaultFilterAllLanguages")) {
            return { success: false };
          }
          const parsed_defaultFilterAllLanguages = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["defaultFilterAllLanguages"]);
          })();
          if (!parsed_defaultFilterAllLanguages.success) {
            return { success: false };
          }
          if (!hasProp(value, "embedSortingFiltering")) {
            return { success: false };
          }
          const parsed_embedSortingFiltering = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["embedSortingFiltering"]);
          })();
          if (!parsed_embedSortingFiltering.success) {
            return { success: false };
          }
          if (!hasProp(value, "hideTitle")) {
            return { success: false };
          }
          const parsed_hideTitle = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["hideTitle"]);
          })();
          if (!parsed_hideTitle.success) {
            return { success: false };
          }
          if (!hasProp(value, "itemCount")) {
            return { success: false };
          }
          const parsed_itemCount = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["itemCount"]);
          })();
          if (!parsed_itemCount.success) {
            return { success: false };
          }
          if (!hasProp(value, "itemLayout")) {
            return { success: false };
          }
          const parsed_itemLayout = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ItemLayout } => {
              return ((value: unknown): { success: false } | { success: true; value: "MINI" | "REGULAR" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "MINI":
                    return { success: true, value: "MINI" };
                  case "REGULAR":
                    return { success: true, value: "REGULAR" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["itemLayout"]);
          })();
          if (!parsed_itemLayout.success) {
            return { success: false };
          }
          if (!hasProp(value, "name")) {
            return { success: false };
          }
          const parsed_name = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["name"]);
          })();
          if (!parsed_name.success) {
            return { success: false };
          }
          if (!hasProp(value, "portletId")) {
            return { success: false };
          }
          const parsed_portletId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["portletId"]);
          })();
          if (!parsed_portletId.success) {
            return { success: false };
          }
          if (!hasProp(value, "portletLayout")) {
            return { success: false };
          }
          const parsed_portletLayout = (() => {
            return ((value: unknown): { success: false } | { success: true; value: PortletLayout } => {
              return ((value: unknown): { success: false } | { success: true; value: "GRID" | "CAROUSEL" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "GRID":
                    return { success: true, value: "GRID" };
                  case "CAROUSEL":
                    return { success: true, value: "CAROUSEL" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["portletLayout"]);
          })();
          if (!parsed_portletLayout.success) {
            return { success: false };
          }
          if (!hasProp(value, "subType")) {
            return { success: false };
          }
          const parsed_subType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["subType"]);
          })();
          if (!parsed_subType.success) {
            return { success: false };
          }
          if (!hasProp(value, "subtitle")) {
            return { success: false };
          }
          const parsed_subtitle = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["subtitle"]);
          })();
          if (!parsed_subtitle.success) {
            return { success: false };
          }
          if (!hasProp(value, "title")) {
            return { success: false };
          }
          const parsed_title = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["title"]);
          })();
          if (!parsed_title.success) {
            return { success: false };
          }
          if (!hasProp(value, "type")) {
            return { success: false };
          }
          const parsed_type = (() => {
            return ((value: unknown): { success: false } | { success: true; value: PortletType } => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value:
                      | "IMAGETEXT"
                      | "SUMMARYLIST"
                      | "TRENDINGCHANNEL"
                      | "FEATUREDCHANNEL"
                      | "FOLLOWINGCHANNEL"
                      | "MYLIST"
                      | "PRIMARYTOPICCHANNEL"
                      | "CHANNELLIST"
                      | "ANNOTATIONLIST"
                      | "LGXPLIST"
                      | "SKETCHNOTESLGXP"
                      | "ACTIONABLELIST"
                      | "ACTIONABLELISTCUSTOM"
                      | "MYACTIONABLES"
                      | "FREESUMMARY"
                      | "SUMMARYLISTCUSTOM"
                      | "CUSTOMPAGELIST"
                      | "PROMOBOXCONFIG"
                      | "MYMIABS"
                      | "ASKGETABSTRACT"
                      | "CUSTOMIZEDMIABS";
                  } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "IMAGETEXT":
                    return { success: true, value: "IMAGETEXT" };
                  case "SUMMARYLIST":
                    return { success: true, value: "SUMMARYLIST" };
                  case "TRENDINGCHANNEL":
                    return { success: true, value: "TRENDINGCHANNEL" };
                  case "FEATUREDCHANNEL":
                    return { success: true, value: "FEATUREDCHANNEL" };
                  case "FOLLOWINGCHANNEL":
                    return { success: true, value: "FOLLOWINGCHANNEL" };
                  case "MYLIST":
                    return { success: true, value: "MYLIST" };
                  case "PRIMARYTOPICCHANNEL":
                    return { success: true, value: "PRIMARYTOPICCHANNEL" };
                  case "CHANNELLIST":
                    return { success: true, value: "CHANNELLIST" };
                  case "ANNOTATIONLIST":
                    return { success: true, value: "ANNOTATIONLIST" };
                  case "LGXPLIST":
                    return { success: true, value: "LGXPLIST" };
                  case "SKETCHNOTESLGXP":
                    return { success: true, value: "SKETCHNOTESLGXP" };
                  case "ACTIONABLELIST":
                    return { success: true, value: "ACTIONABLELIST" };
                  case "ACTIONABLELISTCUSTOM":
                    return { success: true, value: "ACTIONABLELISTCUSTOM" };
                  case "MYACTIONABLES":
                    return { success: true, value: "MYACTIONABLES" };
                  case "FREESUMMARY":
                    return { success: true, value: "FREESUMMARY" };
                  case "SUMMARYLISTCUSTOM":
                    return { success: true, value: "SUMMARYLISTCUSTOM" };
                  case "CUSTOMPAGELIST":
                    return { success: true, value: "CUSTOMPAGELIST" };
                  case "PROMOBOXCONFIG":
                    return { success: true, value: "PROMOBOXCONFIG" };
                  case "MYMIABS":
                    return { success: true, value: "MYMIABS" };
                  case "ASKGETABSTRACT":
                    return { success: true, value: "ASKGETABSTRACT" };
                  case "CUSTOMIZEDMIABS":
                    return { success: true, value: "CUSTOMIZEDMIABS" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["type"]);
          })();
          if (!parsed_type.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              custom: parsed_custom.value,
              defaultFilterAllLanguages: parsed_defaultFilterAllLanguages.value,
              embedSortingFiltering: parsed_embedSortingFiltering.value,
              hideTitle: parsed_hideTitle.value,
              itemCount: parsed_itemCount.value,
              itemLayout: parsed_itemLayout.value,
              name: parsed_name.value,
              portletId: parsed_portletId.value,
              portletLayout: parsed_portletLayout.value,
              subType: parsed_subType.value,
              subtitle: parsed_subtitle.value,
              title: parsed_title.value,
              type: parsed_type.value,
            },
          };
        })(value);
      })(value["metadata"]);
    })();
    if (!parsed_metadata.success) {
      return { success: false };
    }
    if (!hasProp(value, "page")) {
      return { success: false };
    }
    const parsed_page = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["page"]);
    })();
    if (!parsed_page.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageSize")) {
      return { success: false };
    }
    const parsed_pageSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageSize"]);
    })();
    if (!parsed_pageSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    if (!hasProp(value, "viewAllUri")) {
      return { success: false };
    }
    const parsed_viewAllUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["viewAllUri"]);
    })();
    if (!parsed_viewAllUri.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, metadata: parsed_metadata.value, page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value, viewAllUri: parsed_viewAllUri.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// MyListView
export function serialize_MyListView(obj: MyListView): string {
  const serialized = ((value: { coverUri: string; key: string; myListId: bigint; name: string }): unknown => {
    return {
      coverUri: ((value: string): unknown => {
        return value;
      })(value["coverUri"]),
      key: ((value: string): unknown => {
        return value;
      })(value["key"]),
      myListId: ((value: bigint): unknown => {
        return value;
      })(value["myListId"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_MyListView(str: string): MyListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          coverUri: string;
          key: string;
          myListId: bigint;
          name: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "coverUri")) {
      return { success: false };
    }
    const parsed_coverUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["coverUri"]);
    })();
    if (!parsed_coverUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "key")) {
      return { success: false };
    }
    const parsed_key = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["key"]);
    })();
    if (!parsed_key.success) {
      return { success: false };
    }
    if (!hasProp(value, "myListId")) {
      return { success: false };
    }
    const parsed_myListId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["myListId"]);
    })();
    if (!parsed_myListId.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    return { success: true, value: { coverUri: parsed_coverUri.value, key: parsed_key.value, myListId: parsed_myListId.value, name: parsed_name.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// NavigationDirection
export function serialize_NavigationDirection(obj: NavigationDirection): string {
  const serialized = ((value: "NEXT" | "PREVIOUS"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_NavigationDirection(str: string): NavigationDirection | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "NEXT" | "PREVIOUS" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "NEXT":
        return { success: true, value: "NEXT" };
      case "PREVIOUS":
        return { success: true, value: "PREVIOUS" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// NewB2BReportForm
export function serialize_NewB2BReportForm(obj: NewB2BReportForm): string {
  const serialized = ((value: {
    active: boolean;
    activeUsersOnly: boolean;
    b2bCustomReport: boolean;
    ccRecipients: string;
    corporateId: number;
    customerId: number;
    dayOfWeek: number;
    downloadType: DownloadType;
    emailAdministrator: string | null;
    frequency: ReportFrequency;
    fromDate: { year: number; month: number; day: number } | null;
    ftpFolder: string;
    ftpHost: string;
    ftpPassphrase: string;
    ftpPassword: string;
    ftpPort: number;
    ftpRemoteFileName: string;
    ftpUserName: string;
    prefixFileName: boolean;
    privateKey: string | null;
    privateKeyContent: string;
    privateKeyEnabled: boolean;
    reportId: number;
    reportName: string;
    reportType: ReportType;
    specialReport: boolean;
    targetExcel: boolean;
    toDate: { year: number; month: number; day: number } | null;
    transferProtocol: TransferProtocol;
    zipFile: boolean;
  }): unknown => {
    return {
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      activeUsersOnly: ((value: boolean): unknown => {
        return value;
      })(value["activeUsersOnly"]),
      b2bCustomReport: ((value: boolean): unknown => {
        return value;
      })(value["b2bCustomReport"]),
      ccRecipients: ((value: string): unknown => {
        return value;
      })(value["ccRecipients"]),
      corporateId: ((value: number): unknown => {
        return value;
      })(value["corporateId"]),
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
      dayOfWeek: ((value: number): unknown => {
        return value;
      })(value["dayOfWeek"]),
      downloadType: ((value: "PUSH" | "PULL" | "BOTH"): unknown => {
        return value;
      })(value["downloadType"]),
      emailAdministrator: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["emailAdministrator"]),
      frequency: ((value: "ONE_TIME" | "DAILY" | "WEEKLY" | "MONTHLY"): unknown => {
        return value;
      })(value["frequency"]),
      fromDate: ((value: { year: number; month: number; day: number } | null): unknown => {
        if (value === null) {
          return null;
        }
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["fromDate"]),
      ftpFolder: ((value: string): unknown => {
        return value;
      })(value["ftpFolder"]),
      ftpHost: ((value: string): unknown => {
        return value;
      })(value["ftpHost"]),
      ftpPassphrase: ((value: string): unknown => {
        return value;
      })(value["ftpPassphrase"]),
      ftpPassword: ((value: string): unknown => {
        return value;
      })(value["ftpPassword"]),
      ftpPort: ((value: number): unknown => {
        return value;
      })(value["ftpPort"]),
      ftpRemoteFileName: ((value: string): unknown => {
        return value;
      })(value["ftpRemoteFileName"]),
      ftpUserName: ((value: string): unknown => {
        return value;
      })(value["ftpUserName"]),
      prefixFileName: ((value: boolean): unknown => {
        return value;
      })(value["prefixFileName"]),
      privateKey: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["privateKey"]),
      privateKeyContent: ((value: string): unknown => {
        return value;
      })(value["privateKeyContent"]),
      privateKeyEnabled: ((value: boolean): unknown => {
        return value;
      })(value["privateKeyEnabled"]),
      reportId: ((value: number): unknown => {
        return value;
      })(value["reportId"]),
      reportName: ((value: string): unknown => {
        return value;
      })(value["reportName"]),
      reportType: ((value: "USERS" | "ACTIVITY" | "EXTENDED_USERS_EXPORT" | "ACTIONABLES_ACTIVITY"): unknown => {
        return value;
      })(value["reportType"]),
      specialReport: ((value: boolean): unknown => {
        return value;
      })(value["specialReport"]),
      targetExcel: ((value: boolean): unknown => {
        return value;
      })(value["targetExcel"]),
      toDate: ((value: { year: number; month: number; day: number } | null): unknown => {
        if (value === null) {
          return null;
        }
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["toDate"]),
      transferProtocol: ((value: "FTP" | "SFTP" | "FTPS"): unknown => {
        return value;
      })(value["transferProtocol"]),
      zipFile: ((value: boolean): unknown => {
        return value;
      })(value["zipFile"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_NewB2BReportForm(str: string): NewB2BReportForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          active: boolean;
          activeUsersOnly: boolean;
          b2bCustomReport: boolean;
          ccRecipients: string;
          corporateId: number;
          customerId: number;
          dayOfWeek: number;
          downloadType: DownloadType;
          emailAdministrator: string | null;
          frequency: ReportFrequency;
          fromDate: { year: number; month: number; day: number } | null;
          ftpFolder: string;
          ftpHost: string;
          ftpPassphrase: string;
          ftpPassword: string;
          ftpPort: number;
          ftpRemoteFileName: string;
          ftpUserName: string;
          prefixFileName: boolean;
          privateKey: string | null;
          privateKeyContent: string;
          privateKeyEnabled: boolean;
          reportId: number;
          reportName: string;
          reportType: ReportType;
          specialReport: boolean;
          targetExcel: boolean;
          toDate: { year: number; month: number; day: number } | null;
          transferProtocol: TransferProtocol;
          zipFile: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "activeUsersOnly")) {
      return { success: false };
    }
    const parsed_activeUsersOnly = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["activeUsersOnly"]);
    })();
    if (!parsed_activeUsersOnly.success) {
      return { success: false };
    }
    if (!hasProp(value, "b2bCustomReport")) {
      return { success: false };
    }
    const parsed_b2bCustomReport = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["b2bCustomReport"]);
    })();
    if (!parsed_b2bCustomReport.success) {
      return { success: false };
    }
    if (!hasProp(value, "ccRecipients")) {
      return { success: false };
    }
    const parsed_ccRecipients = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ccRecipients"]);
    })();
    if (!parsed_ccRecipients.success) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "dayOfWeek")) {
      return { success: false };
    }
    const parsed_dayOfWeek = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["dayOfWeek"]);
    })();
    if (!parsed_dayOfWeek.success) {
      return { success: false };
    }
    if (!hasProp(value, "downloadType")) {
      return { success: false };
    }
    const parsed_downloadType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DownloadType } => {
        return ((value: unknown): { success: false } | { success: true; value: "PUSH" | "PULL" | "BOTH" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "PUSH":
              return { success: true, value: "PUSH" };
            case "PULL":
              return { success: true, value: "PULL" };
            case "BOTH":
              return { success: true, value: "BOTH" };
            default:
              return { success: false };
          }
        })(value);
      })(value["downloadType"]);
    })();
    if (!parsed_downloadType.success) {
      return { success: false };
    }
    if (!hasProp(value, "emailAdministrator")) {
      return { success: false };
    }
    const parsed_emailAdministrator = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["emailAdministrator"]);
    })();
    if (!parsed_emailAdministrator.success) {
      return { success: false };
    }
    if (!hasProp(value, "frequency")) {
      return { success: false };
    }
    const parsed_frequency = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ReportFrequency } => {
        return ((value: unknown): { success: false } | { success: true; value: "ONE_TIME" | "DAILY" | "WEEKLY" | "MONTHLY" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "ONE_TIME":
              return { success: true, value: "ONE_TIME" };
            case "DAILY":
              return { success: true, value: "DAILY" };
            case "WEEKLY":
              return { success: true, value: "WEEKLY" };
            case "MONTHLY":
              return { success: true, value: "MONTHLY" };
            default:
              return { success: false };
          }
        })(value);
      })(value["frequency"]);
    })();
    if (!parsed_frequency.success) {
      return { success: false };
    }
    if (!hasProp(value, "fromDate")) {
      return { success: false };
    }
    const parsed_fromDate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["fromDate"]);
    })();
    if (!parsed_fromDate.success) {
      return { success: false };
    }
    if (!hasProp(value, "ftpFolder")) {
      return { success: false };
    }
    const parsed_ftpFolder = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ftpFolder"]);
    })();
    if (!parsed_ftpFolder.success) {
      return { success: false };
    }
    if (!hasProp(value, "ftpHost")) {
      return { success: false };
    }
    const parsed_ftpHost = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ftpHost"]);
    })();
    if (!parsed_ftpHost.success) {
      return { success: false };
    }
    if (!hasProp(value, "ftpPassphrase")) {
      return { success: false };
    }
    const parsed_ftpPassphrase = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ftpPassphrase"]);
    })();
    if (!parsed_ftpPassphrase.success) {
      return { success: false };
    }
    if (!hasProp(value, "ftpPassword")) {
      return { success: false };
    }
    const parsed_ftpPassword = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ftpPassword"]);
    })();
    if (!parsed_ftpPassword.success) {
      return { success: false };
    }
    if (!hasProp(value, "ftpPort")) {
      return { success: false };
    }
    const parsed_ftpPort = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["ftpPort"]);
    })();
    if (!parsed_ftpPort.success) {
      return { success: false };
    }
    if (!hasProp(value, "ftpRemoteFileName")) {
      return { success: false };
    }
    const parsed_ftpRemoteFileName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ftpRemoteFileName"]);
    })();
    if (!parsed_ftpRemoteFileName.success) {
      return { success: false };
    }
    if (!hasProp(value, "ftpUserName")) {
      return { success: false };
    }
    const parsed_ftpUserName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ftpUserName"]);
    })();
    if (!parsed_ftpUserName.success) {
      return { success: false };
    }
    if (!hasProp(value, "prefixFileName")) {
      return { success: false };
    }
    const parsed_prefixFileName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["prefixFileName"]);
    })();
    if (!parsed_prefixFileName.success) {
      return { success: false };
    }
    if (!hasProp(value, "privateKey")) {
      return { success: false };
    }
    const parsed_privateKey = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["privateKey"]);
    })();
    if (!parsed_privateKey.success) {
      return { success: false };
    }
    if (!hasProp(value, "privateKeyContent")) {
      return { success: false };
    }
    const parsed_privateKeyContent = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["privateKeyContent"]);
    })();
    if (!parsed_privateKeyContent.success) {
      return { success: false };
    }
    if (!hasProp(value, "privateKeyEnabled")) {
      return { success: false };
    }
    const parsed_privateKeyEnabled = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["privateKeyEnabled"]);
    })();
    if (!parsed_privateKeyEnabled.success) {
      return { success: false };
    }
    if (!hasProp(value, "reportId")) {
      return { success: false };
    }
    const parsed_reportId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["reportId"]);
    })();
    if (!parsed_reportId.success) {
      return { success: false };
    }
    if (!hasProp(value, "reportName")) {
      return { success: false };
    }
    const parsed_reportName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["reportName"]);
    })();
    if (!parsed_reportName.success) {
      return { success: false };
    }
    if (!hasProp(value, "reportType")) {
      return { success: false };
    }
    const parsed_reportType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ReportType } => {
        return ((value: unknown): { success: false } | { success: true; value: "USERS" | "ACTIVITY" | "EXTENDED_USERS_EXPORT" | "ACTIONABLES_ACTIVITY" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "USERS":
              return { success: true, value: "USERS" };
            case "ACTIVITY":
              return { success: true, value: "ACTIVITY" };
            case "EXTENDED_USERS_EXPORT":
              return { success: true, value: "EXTENDED_USERS_EXPORT" };
            case "ACTIONABLES_ACTIVITY":
              return { success: true, value: "ACTIONABLES_ACTIVITY" };
            default:
              return { success: false };
          }
        })(value);
      })(value["reportType"]);
    })();
    if (!parsed_reportType.success) {
      return { success: false };
    }
    if (!hasProp(value, "specialReport")) {
      return { success: false };
    }
    const parsed_specialReport = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["specialReport"]);
    })();
    if (!parsed_specialReport.success) {
      return { success: false };
    }
    if (!hasProp(value, "targetExcel")) {
      return { success: false };
    }
    const parsed_targetExcel = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["targetExcel"]);
    })();
    if (!parsed_targetExcel.success) {
      return { success: false };
    }
    if (!hasProp(value, "toDate")) {
      return { success: false };
    }
    const parsed_toDate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["toDate"]);
    })();
    if (!parsed_toDate.success) {
      return { success: false };
    }
    if (!hasProp(value, "transferProtocol")) {
      return { success: false };
    }
    const parsed_transferProtocol = (() => {
      return ((value: unknown): { success: false } | { success: true; value: TransferProtocol } => {
        return ((value: unknown): { success: false } | { success: true; value: "FTP" | "SFTP" | "FTPS" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "FTP":
              return { success: true, value: "FTP" };
            case "SFTP":
              return { success: true, value: "SFTP" };
            case "FTPS":
              return { success: true, value: "FTPS" };
            default:
              return { success: false };
          }
        })(value);
      })(value["transferProtocol"]);
    })();
    if (!parsed_transferProtocol.success) {
      return { success: false };
    }
    if (!hasProp(value, "zipFile")) {
      return { success: false };
    }
    const parsed_zipFile = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["zipFile"]);
    })();
    if (!parsed_zipFile.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        active: parsed_active.value,
        activeUsersOnly: parsed_activeUsersOnly.value,
        b2bCustomReport: parsed_b2bCustomReport.value,
        ccRecipients: parsed_ccRecipients.value,
        corporateId: parsed_corporateId.value,
        customerId: parsed_customerId.value,
        dayOfWeek: parsed_dayOfWeek.value,
        downloadType: parsed_downloadType.value,
        emailAdministrator: parsed_emailAdministrator.value,
        frequency: parsed_frequency.value,
        fromDate: parsed_fromDate.value,
        ftpFolder: parsed_ftpFolder.value,
        ftpHost: parsed_ftpHost.value,
        ftpPassphrase: parsed_ftpPassphrase.value,
        ftpPassword: parsed_ftpPassword.value,
        ftpPort: parsed_ftpPort.value,
        ftpRemoteFileName: parsed_ftpRemoteFileName.value,
        ftpUserName: parsed_ftpUserName.value,
        prefixFileName: parsed_prefixFileName.value,
        privateKey: parsed_privateKey.value,
        privateKeyContent: parsed_privateKeyContent.value,
        privateKeyEnabled: parsed_privateKeyEnabled.value,
        reportId: parsed_reportId.value,
        reportName: parsed_reportName.value,
        reportType: parsed_reportType.value,
        specialReport: parsed_specialReport.value,
        targetExcel: parsed_targetExcel.value,
        toDate: parsed_toDate.value,
        transferProtocol: parsed_transferProtocol.value,
        zipFile: parsed_zipFile.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// OAuth2Token
export function serialize_OAuth2Token(obj: OAuth2Token): string {
  const serialized = ((value: {
    accessTokenExpiredAt: DateTime<true> | null;
    accessTokenValue: string | null;
    corporateId: number;
    isExpired: boolean;
    refreshTokenExpiredAt: DateTime<true> | null;
    refreshTokenValue: string | null;
    tokenType: string | null;
  }): unknown => {
    return {
      accessTokenExpiredAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["accessTokenExpiredAt"]),
      accessTokenValue: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["accessTokenValue"]),
      corporateId: ((value: number): unknown => {
        return value;
      })(value["corporateId"]),
      isExpired: ((value: boolean): unknown => {
        return value;
      })(value["isExpired"]),
      refreshTokenExpiredAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["refreshTokenExpiredAt"]),
      refreshTokenValue: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["refreshTokenValue"]),
      tokenType: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["tokenType"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_OAuth2Token(str: string): OAuth2Token | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          accessTokenExpiredAt: DateTime<true> | null;
          accessTokenValue: string | null;
          corporateId: number;
          isExpired: boolean;
          refreshTokenExpiredAt: DateTime<true> | null;
          refreshTokenValue: string | null;
          tokenType: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "accessTokenExpiredAt")) {
      return { success: false };
    }
    const parsed_accessTokenExpiredAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["accessTokenExpiredAt"]);
    })();
    if (!parsed_accessTokenExpiredAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "accessTokenValue")) {
      return { success: false };
    }
    const parsed_accessTokenValue = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["accessTokenValue"]);
    })();
    if (!parsed_accessTokenValue.success) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "isExpired")) {
      return { success: false };
    }
    const parsed_isExpired = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isExpired"]);
    })();
    if (!parsed_isExpired.success) {
      return { success: false };
    }
    if (!hasProp(value, "refreshTokenExpiredAt")) {
      return { success: false };
    }
    const parsed_refreshTokenExpiredAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["refreshTokenExpiredAt"]);
    })();
    if (!parsed_refreshTokenExpiredAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "refreshTokenValue")) {
      return { success: false };
    }
    const parsed_refreshTokenValue = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["refreshTokenValue"]);
    })();
    if (!parsed_refreshTokenValue.success) {
      return { success: false };
    }
    if (!hasProp(value, "tokenType")) {
      return { success: false };
    }
    const parsed_tokenType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["tokenType"]);
    })();
    if (!parsed_tokenType.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        accessTokenExpiredAt: parsed_accessTokenExpiredAt.value,
        accessTokenValue: parsed_accessTokenValue.value,
        corporateId: parsed_corporateId.value,
        isExpired: parsed_isExpired.value,
        refreshTokenExpiredAt: parsed_refreshTokenExpiredAt.value,
        refreshTokenValue: parsed_refreshTokenValue.value,
        tokenType: parsed_tokenType.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// Oauth2Flow
export function serialize_Oauth2Flow(obj: Oauth2Flow): string {
  const serialized = ((value: "BASIC" | "OAUTH2"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_Oauth2Flow(str: string): Oauth2Flow | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "BASIC" | "OAUTH2" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "BASIC":
        return { success: true, value: "BASIC" };
      case "OAUTH2":
        return { success: true, value: "OAUTH2" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PageInfo
export function serialize_PageInfo(obj: PageInfo): string {
  const serialized = ((value: { page: number; pageSize: number; totalCount: number }): unknown => {
    return {
      page: ((value: number): unknown => {
        return value;
      })(value["page"]),
      pageSize: ((value: number): unknown => {
        return value;
      })(value["pageSize"]),
      totalCount: ((value: number): unknown => {
        return value;
      })(value["totalCount"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PageInfo(str: string): PageInfo | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          page: number;
          pageSize: number;
          totalCount: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "page")) {
      return { success: false };
    }
    const parsed_page = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["page"]);
    })();
    if (!parsed_page.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageSize")) {
      return { success: false };
    }
    const parsed_pageSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageSize"]);
    })();
    if (!parsed_pageSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    return { success: true, value: { page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PageRequest
export function serialize_PageRequest(obj: PageRequest): string {
  const serialized = ((value: { page: number; psize: number; sortBy: string | null; sortDesc: boolean }): unknown => {
    return {
      page: ((value: number): unknown => {
        return value;
      })(value["page"]),
      psize: ((value: number): unknown => {
        return value;
      })(value["psize"]),
      sortBy: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["sortBy"]),
      sortDesc: ((value: boolean): unknown => {
        return value;
      })(value["sortDesc"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PageRequest(str: string): PageRequest | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          page: number;
          psize: number;
          sortBy: string | null;
          sortDesc: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "page")) {
      return { success: false };
    }
    const parsed_page = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["page"]);
    })();
    if (!parsed_page.success) {
      return { success: false };
    }
    if (!hasProp(value, "psize")) {
      return { success: false };
    }
    const parsed_psize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["psize"]);
    })();
    if (!parsed_psize.success) {
      return { success: false };
    }
    if (!hasProp(value, "sortBy")) {
      return { success: false };
    }
    const parsed_sortBy = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["sortBy"]);
    })();
    if (!parsed_sortBy.success) {
      return { success: false };
    }
    if (!hasProp(value, "sortDesc")) {
      return { success: false };
    }
    const parsed_sortDesc = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["sortDesc"]);
    })();
    if (!parsed_sortDesc.success) {
      return { success: false };
    }
    return { success: true, value: { page: parsed_page.value, psize: parsed_psize.value, sortBy: parsed_sortBy.value, sortDesc: parsed_sortDesc.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PageViewMeta
export function serialize_PageViewMeta(obj: PageViewMeta): string {
  const serialized = ((value: { ci: bigint | null; ct: ContentItemType; orr: string; p: string; r: string | null }): unknown => {
    return {
      ci: ((value: bigint | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["ci"]),
      ct: ((value: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE" | "MIAB"): unknown => {
        return value;
      })(value["ct"]),
      orr: ((value: string): unknown => {
        return value;
      })(value["orr"]),
      p: ((value: string): unknown => {
        return value;
      })(value["p"]),
      r: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["r"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PageViewMeta(str: string): PageViewMeta | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          ci: bigint | null;
          ct: ContentItemType;
          orr: string;
          p: string;
          r: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "ci")) {
      return { success: false };
    }
    const parsed_ci = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["ci"]);
    })();
    if (!parsed_ci.success) {
      return { success: false };
    }
    if (!hasProp(value, "ct")) {
      return { success: false };
    }
    const parsed_ct = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ContentItemType } => {
        return ((value: unknown): { success: false } | { success: true; value: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE" | "MIAB" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "UNKNOWN":
              return { success: true, value: "UNKNOWN" };
            case "SUMMARY":
              return { success: true, value: "SUMMARY" };
            case "CATEGORY":
              return { success: true, value: "CATEGORY" };
            case "CHANNEL":
              return { success: true, value: "CHANNEL" };
            case "LGXP":
              return { success: true, value: "LGXP" };
            case "ACTIONABLE":
              return { success: true, value: "ACTIONABLE" };
            case "MYLIST":
              return { success: true, value: "MYLIST" };
            case "CUSTOMPAGE":
              return { success: true, value: "CUSTOMPAGE" };
            case "MIAB":
              return { success: true, value: "MIAB" };
            default:
              return { success: false };
          }
        })(value);
      })(value["ct"]);
    })();
    if (!parsed_ct.success) {
      return { success: false };
    }
    if (!hasProp(value, "orr")) {
      return { success: false };
    }
    const parsed_orr = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["orr"]);
    })();
    if (!parsed_orr.success) {
      return { success: false };
    }
    if (!hasProp(value, "p")) {
      return { success: false };
    }
    const parsed_p = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["p"]);
    })();
    if (!parsed_p.success) {
      return { success: false };
    }
    if (!hasProp(value, "r")) {
      return { success: false };
    }
    const parsed_r = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["r"]);
    })();
    if (!parsed_r.success) {
      return { success: false };
    }
    return { success: true, value: { ci: parsed_ci.value, ct: parsed_ct.value, orr: parsed_orr.value, p: parsed_p.value, r: parsed_r.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PagedActionableMetadata
export function serialize_PagedActionableMetadata(obj: PagedActionableMetadata): string {
  const serialized = ((value: { items: ActionableMetadata[]; page: number; pageSize: number; totalCount: bigint }): unknown => {
    return {
      items: ((value: ActionableMetadata[]): unknown => {
        return value.map(
          (value: { actionableId: bigint; activatedAt: DateTime<true> | null; active: boolean; coverUri: string; description: string; language: Language; modifiedAt: DateTime<true>; name: string; tags: Tag[]; title: string }): unknown => {
            return {
              actionableId: ((value: bigint): unknown => {
                return value;
              })(value["actionableId"]),
              activatedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["activatedAt"]),
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              coverUri: ((value: string): unknown => {
                return value;
              })(value["coverUri"]),
              description: ((value: string): unknown => {
                return value;
              })(value["description"]),
              language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                return value;
              })(value["language"]),
              modifiedAt: ((value: DateTime<true>): unknown => {
                return value.toMillis();
              })(value["modifiedAt"]),
              name: ((value: string): unknown => {
                return value;
              })(value["name"]),
              tags: ((value: Tag[]): unknown => {
                return value.map(
                  (
                    value:
                      | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                      | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                      | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                      | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                      | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                      | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                      | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                      | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                      | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
                  ): unknown => {
                    switch (value.discriminator) {
                      case "dynamicString": {
                        return {
                          discriminator: "dynamicString",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicString"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicBoolean": {
                        return {
                          discriminator: "dynamicBoolean",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicBoolean"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: boolean): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicNumber": {
                        return {
                          discriminator: "dynamicNumber",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicNumber"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: number): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicVoid": {
                        return {
                          discriminator: "dynamicVoid",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicVoid"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                        };
                      }
                      case "channel": {
                        return {
                          discriminator: "channel",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                            return {
                              discriminator: ((value: "channel"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "channel"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "primary-skill": {
                        return {
                          discriminator: "primary-skill",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                            return {
                              discriminator: ((value: "primary-skill"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "primary-skill"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "skill": {
                        return {
                          discriminator: "skill",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                            return {
                              discriminator: ((value: "skill"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "skill"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "key-quality": {
                        return {
                          discriminator: "key-quality",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                            return {
                              discriminator: ((value: "key-quality"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "key-quality"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "classification": {
                        return {
                          discriminator: "classification",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                            return {
                              discriminator: ((value: "classification"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "classification"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                    }
                  },
                );
              })(value["tags"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
            };
          },
        );
      })(value["items"]),
      page: ((value: number): unknown => {
        return value;
      })(value["page"]),
      pageSize: ((value: number): unknown => {
        return value;
      })(value["pageSize"]),
      totalCount: ((value: bigint): unknown => {
        return value;
      })(value["totalCount"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PagedActionableMetadata(str: string): PagedActionableMetadata | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: ActionableMetadata[];
          page: number;
          pageSize: number;
          totalCount: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ActionableMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ActionableMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  actionableId: bigint;
                  activatedAt: DateTime<true> | null;
                  active: boolean;
                  coverUri: string;
                  description: string;
                  language: Language;
                  modifiedAt: DateTime<true>;
                  name: string;
                  tags: Tag[];
                  title: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "actionableId")) {
              return { success: false };
            }
            const parsed_actionableId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["actionableId"]);
            })();
            if (!parsed_actionableId.success) {
              return { success: false };
            }
            if (!hasProp(value, "activatedAt")) {
              return { success: false };
            }
            const parsed_activatedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["activatedAt"]);
            })();
            if (!parsed_activatedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "active")) {
              return { success: false };
            }
            const parsed_active = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["active"]);
            })();
            if (!parsed_active.success) {
              return { success: false };
            }
            if (!hasProp(value, "coverUri")) {
              return { success: false };
            }
            const parsed_coverUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["coverUri"]);
            })();
            if (!parsed_coverUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "description")) {
              return { success: false };
            }
            const parsed_description = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["description"]);
            })();
            if (!parsed_description.success) {
              return { success: false };
            }
            if (!hasProp(value, "language")) {
              return { success: false };
            }
            const parsed_language = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Language } => {
                return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "en":
                      return { success: true, value: "en" };
                    case "de":
                      return { success: true, value: "de" };
                    case "es":
                      return { success: true, value: "es" };
                    case "ru":
                      return { success: true, value: "ru" };
                    case "zh":
                      return { success: true, value: "zh" };
                    case "pt":
                      return { success: true, value: "pt" };
                    case "fr":
                      return { success: true, value: "fr" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["language"]);
            })();
            if (!parsed_language.success) {
              return { success: false };
            }
            if (!hasProp(value, "modifiedAt")) {
              return { success: false };
            }
            const parsed_modifiedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["modifiedAt"]);
            })();
            if (!parsed_modifiedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "tags")) {
              return { success: false };
            }
            const parsed_tags = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value:
                          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                          | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                          | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                      } => {
                    return ((
                      value: unknown,
                    ):
                      | { success: false }
                      | {
                          success: true;
                          value:
                            | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                            | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                            | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                            | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                            | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                            | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                            | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                            | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                            | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                        } => {
                      if (value == null) {
                        return { success: false };
                      }
                      if (typeof value != "object") {
                        return { success: false };
                      }
                      if (!hasProp(value, "discriminator")) {
                        return { success: false };
                      }
                      if (typeof value["discriminator"] != "string") {
                        return { success: false };
                      }
                      const discriminator = value["discriminator"];
                      switch (discriminator) {
                        case "dynamicString": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicString;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicString;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicString":
                                            return { success: true, value: "dynamicString" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                        }
                        case "dynamicBoolean": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicBoolean;
                                  value: boolean;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicBoolean;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicBoolean":
                                            return { success: true, value: "dynamicBoolean" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                        }
                        case "dynamicNumber": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicNumber;
                                  value: number;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicNumber;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicNumber":
                                            return { success: true, value: "dynamicNumber" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: number } => {
                                if (typeof value === "number") {
                                  return { success: true, value: value };
                                }
                                return { success: false };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                        }
                        case "dynamicVoid": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicVoid;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicVoid;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicVoid":
                                            return { success: true, value: "dynamicVoid" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                        }
                        case "channel": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindChannel;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindChannel;
                                        id: bigint;
                                        kind: KindChannel;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "channel":
                                            return { success: true, value: "channel" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "channel":
                                            return { success: true, value: "channel" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                        }
                        case "primary-skill": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindPrimarySkill;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindPrimarySkill;
                                        id: bigint;
                                        kind: KindPrimarySkill;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "primary-skill":
                                            return { success: true, value: "primary-skill" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "primary-skill":
                                            return { success: true, value: "primary-skill" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                        }
                        case "skill": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindSkill;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindSkill;
                                        id: bigint;
                                        kind: KindSkill;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "skill":
                                            return { success: true, value: "skill" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "skill":
                                            return { success: true, value: "skill" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                        }
                        case "key-quality": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindKeyQuality;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindKeyQuality;
                                        id: bigint;
                                        kind: KindKeyQuality;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "key-quality":
                                            return { success: true, value: "key-quality" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "key-quality":
                                            return { success: true, value: "key-quality" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                        }
                        case "classification": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindClassification;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindClassification;
                                        id: bigint;
                                        kind: KindClassification;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "classification":
                                            return { success: true, value: "classification" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "classification":
                                            return { success: true, value: "classification" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                        }
                        default:
                          return { success: false };
                      }
                    })(value);
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["tags"]);
            })();
            if (!parsed_tags.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                actionableId: parsed_actionableId.value,
                activatedAt: parsed_activatedAt.value,
                active: parsed_active.value,
                coverUri: parsed_coverUri.value,
                description: parsed_description.value,
                language: parsed_language.value,
                modifiedAt: parsed_modifiedAt.value,
                name: parsed_name.value,
                tags: parsed_tags.value,
                title: parsed_title.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "page")) {
      return { success: false };
    }
    const parsed_page = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["page"]);
    })();
    if (!parsed_page.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageSize")) {
      return { success: false };
    }
    const parsed_pageSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageSize"]);
    })();
    if (!parsed_pageSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PagedAuthorAdminListView
export function serialize_PagedAuthorAdminListView(obj: PagedAuthorAdminListView): string {
  const serialized = ((value: { items: AuthorAdminListView[]; page: number; pageSize: number; totalCount: bigint }): unknown => {
    return {
      items: ((value: AuthorAdminListView[]): unknown => {
        return value.map((value: { authorId: bigint; channels: string[]; firstName: string; gender: Gender; lastName: string | null; middleName: string | null }): unknown => {
          return {
            authorId: ((value: bigint): unknown => {
              return value;
            })(value["authorId"]),
            channels: ((value: string[]): unknown => {
              return value.map((value: string): unknown => {
                return value;
              });
            })(value["channels"]),
            firstName: ((value: string): unknown => {
              return value;
            })(value["firstName"]),
            gender: ((value: "FEMALE" | "MALE" | "UNKNOWN" | "CUSTOM"): unknown => {
              return value;
            })(value["gender"]),
            lastName: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["lastName"]),
            middleName: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["middleName"]),
          };
        });
      })(value["items"]),
      page: ((value: number): unknown => {
        return value;
      })(value["page"]),
      pageSize: ((value: number): unknown => {
        return value;
      })(value["pageSize"]),
      totalCount: ((value: bigint): unknown => {
        return value;
      })(value["totalCount"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PagedAuthorAdminListView(str: string): PagedAuthorAdminListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: AuthorAdminListView[];
          page: number;
          pageSize: number;
          totalCount: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: AuthorAdminListView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: AuthorAdminListView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  authorId: bigint;
                  channels: string[];
                  firstName: string;
                  gender: Gender;
                  lastName: string | null;
                  middleName: string | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "authorId")) {
              return { success: false };
            }
            const parsed_authorId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["authorId"]);
            })();
            if (!parsed_authorId.success) {
              return { success: false };
            }
            if (!hasProp(value, "channels")) {
              return { success: false };
            }
            const parsed_channels = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["channels"]);
            })();
            if (!parsed_channels.success) {
              return { success: false };
            }
            if (!hasProp(value, "firstName")) {
              return { success: false };
            }
            const parsed_firstName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["firstName"]);
            })();
            if (!parsed_firstName.success) {
              return { success: false };
            }
            if (!hasProp(value, "gender")) {
              return { success: false };
            }
            const parsed_gender = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Gender } => {
                return ((value: unknown): { success: false } | { success: true; value: "FEMALE" | "MALE" | "UNKNOWN" | "CUSTOM" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "FEMALE":
                      return { success: true, value: "FEMALE" };
                    case "MALE":
                      return { success: true, value: "MALE" };
                    case "UNKNOWN":
                      return { success: true, value: "UNKNOWN" };
                    case "CUSTOM":
                      return { success: true, value: "CUSTOM" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["gender"]);
            })();
            if (!parsed_gender.success) {
              return { success: false };
            }
            if (!hasProp(value, "lastName")) {
              return { success: false };
            }
            const parsed_lastName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["lastName"]);
            })();
            if (!parsed_lastName.success) {
              return { success: false };
            }
            if (!hasProp(value, "middleName")) {
              return { success: false };
            }
            const parsed_middleName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["middleName"]);
            })();
            if (!parsed_middleName.success) {
              return { success: false };
            }
            return {
              success: true,
              value: { authorId: parsed_authorId.value, channels: parsed_channels.value, firstName: parsed_firstName.value, gender: parsed_gender.value, lastName: parsed_lastName.value, middleName: parsed_middleName.value },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "page")) {
      return { success: false };
    }
    const parsed_page = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["page"]);
    })();
    if (!parsed_page.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageSize")) {
      return { success: false };
    }
    const parsed_pageSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageSize"]);
    })();
    if (!parsed_pageSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PagedConnectorCompletionExecutionLogListView
export function serialize_PagedConnectorCompletionExecutionLogListView(obj: PagedConnectorCompletionExecutionLogListView): string {
  const serialized = ((value: { items: ConnectorCompletionExecutionLogListView[]; page: number; pageSize: number; totalCount: bigint }): unknown => {
    return {
      items: ((value: ConnectorCompletionExecutionLogListView[]): unknown => {
        return value.map(
          (value: { completedAt: DateTime<true> | null; connectorExecutionLogId: bigint; contentId: string; method: string; request: string; response: string; statusCode: number; syncedAt: DateTime<true>; userId: string }): unknown => {
            return {
              completedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toISO();
              })(value["completedAt"]),
              connectorExecutionLogId: ((value: bigint): unknown => {
                return value;
              })(value["connectorExecutionLogId"]),
              contentId: ((value: string): unknown => {
                return value;
              })(value["contentId"]),
              method: ((value: string): unknown => {
                return value;
              })(value["method"]),
              request: ((value: string): unknown => {
                return value;
              })(value["request"]),
              response: ((value: string): unknown => {
                return value;
              })(value["response"]),
              statusCode: ((value: number): unknown => {
                return value;
              })(value["statusCode"]),
              syncedAt: ((value: DateTime<true>): unknown => {
                return value.toISO();
              })(value["syncedAt"]),
              userId: ((value: string): unknown => {
                return value;
              })(value["userId"]),
            };
          },
        );
      })(value["items"]),
      page: ((value: number): unknown => {
        return value;
      })(value["page"]),
      pageSize: ((value: number): unknown => {
        return value;
      })(value["pageSize"]),
      totalCount: ((value: bigint): unknown => {
        return value;
      })(value["totalCount"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PagedConnectorCompletionExecutionLogListView(str: string): PagedConnectorCompletionExecutionLogListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: ConnectorCompletionExecutionLogListView[];
          page: number;
          pageSize: number;
          totalCount: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorCompletionExecutionLogListView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ConnectorCompletionExecutionLogListView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  completedAt: DateTime<true> | null;
                  connectorExecutionLogId: bigint;
                  contentId: string;
                  method: string;
                  request: string;
                  response: string;
                  statusCode: number;
                  syncedAt: DateTime<true>;
                  userId: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "completedAt")) {
              return { success: false };
            }
            const parsed_completedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["completedAt"]);
            })();
            if (!parsed_completedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "connectorExecutionLogId")) {
              return { success: false };
            }
            const parsed_connectorExecutionLogId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["connectorExecutionLogId"]);
            })();
            if (!parsed_connectorExecutionLogId.success) {
              return { success: false };
            }
            if (!hasProp(value, "contentId")) {
              return { success: false };
            }
            const parsed_contentId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["contentId"]);
            })();
            if (!parsed_contentId.success) {
              return { success: false };
            }
            if (!hasProp(value, "method")) {
              return { success: false };
            }
            const parsed_method = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["method"]);
            })();
            if (!parsed_method.success) {
              return { success: false };
            }
            if (!hasProp(value, "request")) {
              return { success: false };
            }
            const parsed_request = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["request"]);
            })();
            if (!parsed_request.success) {
              return { success: false };
            }
            if (!hasProp(value, "response")) {
              return { success: false };
            }
            const parsed_response = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["response"]);
            })();
            if (!parsed_response.success) {
              return { success: false };
            }
            if (!hasProp(value, "statusCode")) {
              return { success: false };
            }
            const parsed_statusCode = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["statusCode"]);
            })();
            if (!parsed_statusCode.success) {
              return { success: false };
            }
            if (!hasProp(value, "syncedAt")) {
              return { success: false };
            }
            const parsed_syncedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["syncedAt"]);
            })();
            if (!parsed_syncedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "userId")) {
              return { success: false };
            }
            const parsed_userId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["userId"]);
            })();
            if (!parsed_userId.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                completedAt: parsed_completedAt.value,
                connectorExecutionLogId: parsed_connectorExecutionLogId.value,
                contentId: parsed_contentId.value,
                method: parsed_method.value,
                request: parsed_request.value,
                response: parsed_response.value,
                statusCode: parsed_statusCode.value,
                syncedAt: parsed_syncedAt.value,
                userId: parsed_userId.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "page")) {
      return { success: false };
    }
    const parsed_page = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["page"]);
    })();
    if (!parsed_page.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageSize")) {
      return { success: false };
    }
    const parsed_pageSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageSize"]);
    })();
    if (!parsed_pageSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PagedConnectorContentExecutionListView
export function serialize_PagedConnectorContentExecutionListView(obj: PagedConnectorContentExecutionListView): string {
  const serialized = ((value: { items: ConnectorContentExecutionListView[]; page: number; pageSize: number; totalCount: bigint }): unknown => {
    return {
      items: ((value: ConnectorContentExecutionListView[]): unknown => {
        return value.map(
          (value: {
            connectorExecutionId: bigint;
            connectorName: string;
            description: string;
            detail: ConnectorContentExecutionLogDetailView;
            provider: ConnectorProviderMetadata;
            syncedAt: DateTime<true> | null;
            type: "WEB" | "BATCH";
          }): unknown => {
            return {
              connectorExecutionId: ((value: bigint): unknown => {
                return value;
              })(value["connectorExecutionId"]),
              connectorName: ((value: string): unknown => {
                return value;
              })(value["connectorName"]),
              description: ((value: string): unknown => {
                return value;
              })(value["description"]),
              detail: ((value: { failedExportedItems: number; successfullyExportedItems: number }): unknown => {
                return {
                  failedExportedItems: ((value: number): unknown => {
                    return value;
                  })(value["failedExportedItems"]),
                  successfullyExportedItems: ((value: number): unknown => {
                    return value;
                  })(value["successfullyExportedItems"]),
                };
              })(value["detail"]),
              provider: ((value: { imageUri: string; name: string; type: ConnectorType }): unknown => {
                return {
                  imageUri: ((value: string): unknown => {
                    return value;
                  })(value["imageUri"]),
                  name: ((value: string): unknown => {
                    return value;
                  })(value["name"]),
                  type: ((value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "DOCEBO_CATALOG" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" | "VIVA_LEARNING" | "ORACLE"): unknown => {
                    return value;
                  })(value["type"]),
                };
              })(value["provider"]),
              syncedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toISO();
              })(value["syncedAt"]),
              type: ((value: "WEB" | "BATCH"): unknown => {
                return value;
              })(value["type"]),
            };
          },
        );
      })(value["items"]),
      page: ((value: number): unknown => {
        return value;
      })(value["page"]),
      pageSize: ((value: number): unknown => {
        return value;
      })(value["pageSize"]),
      totalCount: ((value: bigint): unknown => {
        return value;
      })(value["totalCount"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PagedConnectorContentExecutionListView(str: string): PagedConnectorContentExecutionListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: ConnectorContentExecutionListView[];
          page: number;
          pageSize: number;
          totalCount: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorContentExecutionListView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ConnectorContentExecutionListView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  connectorExecutionId: bigint;
                  connectorName: string;
                  description: string;
                  detail: ConnectorContentExecutionLogDetailView;
                  provider: ConnectorProviderMetadata;
                  syncedAt: DateTime<true> | null;
                  type: "WEB" | "BATCH";
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "connectorExecutionId")) {
              return { success: false };
            }
            const parsed_connectorExecutionId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["connectorExecutionId"]);
            })();
            if (!parsed_connectorExecutionId.success) {
              return { success: false };
            }
            if (!hasProp(value, "connectorName")) {
              return { success: false };
            }
            const parsed_connectorName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["connectorName"]);
            })();
            if (!parsed_connectorName.success) {
              return { success: false };
            }
            if (!hasProp(value, "description")) {
              return { success: false };
            }
            const parsed_description = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["description"]);
            })();
            if (!parsed_description.success) {
              return { success: false };
            }
            if (!hasProp(value, "detail")) {
              return { success: false };
            }
            const parsed_detail = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ConnectorContentExecutionLogDetailView } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        failedExportedItems: number;
                        successfullyExportedItems: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "failedExportedItems")) {
                    return { success: false };
                  }
                  const parsed_failedExportedItems = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["failedExportedItems"]);
                  })();
                  if (!parsed_failedExportedItems.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "successfullyExportedItems")) {
                    return { success: false };
                  }
                  const parsed_successfullyExportedItems = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["successfullyExportedItems"]);
                  })();
                  if (!parsed_successfullyExportedItems.success) {
                    return { success: false };
                  }
                  return { success: true, value: { failedExportedItems: parsed_failedExportedItems.value, successfullyExportedItems: parsed_successfullyExportedItems.value } };
                })(value);
              })(value["detail"]);
            })();
            if (!parsed_detail.success) {
              return { success: false };
            }
            if (!hasProp(value, "provider")) {
              return { success: false };
            }
            const parsed_provider = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ConnectorProviderMetadata } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        imageUri: string;
                        name: string;
                        type: ConnectorType;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "imageUri")) {
                    return { success: false };
                  }
                  const parsed_imageUri = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      try {
                        new URL(value);
                      } catch (ex) {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["imageUri"]);
                  })();
                  if (!parsed_imageUri.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "name")) {
                    return { success: false };
                  }
                  const parsed_name = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["name"]);
                  })();
                  if (!parsed_name.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "type")) {
                    return { success: false };
                  }
                  const parsed_type = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: ConnectorType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "SUCCESS_FACTORS" | "DEGREED" | "DOCEBO" | "DOCEBO_CATALOG" | "CSOD" | "SUM_TOTAL" | "EDCAST" | "WORKDAY" | "VIVA_LEARNING" | "ORACLE" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "SUCCESS_FACTORS":
                            return { success: true, value: "SUCCESS_FACTORS" };
                          case "DEGREED":
                            return { success: true, value: "DEGREED" };
                          case "DOCEBO":
                            return { success: true, value: "DOCEBO" };
                          case "DOCEBO_CATALOG":
                            return { success: true, value: "DOCEBO_CATALOG" };
                          case "CSOD":
                            return { success: true, value: "CSOD" };
                          case "SUM_TOTAL":
                            return { success: true, value: "SUM_TOTAL" };
                          case "EDCAST":
                            return { success: true, value: "EDCAST" };
                          case "WORKDAY":
                            return { success: true, value: "WORKDAY" };
                          case "VIVA_LEARNING":
                            return { success: true, value: "VIVA_LEARNING" };
                          case "ORACLE":
                            return { success: true, value: "ORACLE" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["type"]);
                  })();
                  if (!parsed_type.success) {
                    return { success: false };
                  }
                  return { success: true, value: { imageUri: parsed_imageUri.value, name: parsed_name.value, type: parsed_type.value } };
                })(value);
              })(value["provider"]);
            })();
            if (!parsed_provider.success) {
              return { success: false };
            }
            if (!hasProp(value, "syncedAt")) {
              return { success: false };
            }
            const parsed_syncedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["syncedAt"]);
            })();
            if (!parsed_syncedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "type")) {
              return { success: false };
            }
            const parsed_type = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "WEB" | "BATCH" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "WEB":
                    return { success: true, value: "WEB" };
                  case "BATCH":
                    return { success: true, value: "BATCH" };
                  default:
                    return { success: false };
                }
              })(value["type"]);
            })();
            if (!parsed_type.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                connectorExecutionId: parsed_connectorExecutionId.value,
                connectorName: parsed_connectorName.value,
                description: parsed_description.value,
                detail: parsed_detail.value,
                provider: parsed_provider.value,
                syncedAt: parsed_syncedAt.value,
                type: parsed_type.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "page")) {
      return { success: false };
    }
    const parsed_page = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["page"]);
    })();
    if (!parsed_page.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageSize")) {
      return { success: false };
    }
    const parsed_pageSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageSize"]);
    })();
    if (!parsed_pageSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PagedConnectorContentExecutionLogListView
export function serialize_PagedConnectorContentExecutionLogListView(obj: PagedConnectorContentExecutionLogListView): string {
  const serialized = ((value: { items: ConnectorContentExecutionLogListView[]; page: number; pageSize: number; totalCount: bigint }): unknown => {
    return {
      items: ((value: ConnectorContentExecutionLogListView[]): unknown => {
        return value.map((value: { connectorExecutionLogId: bigint; contentId: string; method: string; request: string; response: string; statusCode: number; syncedAt: DateTime<true> }): unknown => {
          return {
            connectorExecutionLogId: ((value: bigint): unknown => {
              return value;
            })(value["connectorExecutionLogId"]),
            contentId: ((value: string): unknown => {
              return value;
            })(value["contentId"]),
            method: ((value: string): unknown => {
              return value;
            })(value["method"]),
            request: ((value: string): unknown => {
              return value;
            })(value["request"]),
            response: ((value: string): unknown => {
              return value;
            })(value["response"]),
            statusCode: ((value: number): unknown => {
              return value;
            })(value["statusCode"]),
            syncedAt: ((value: DateTime<true>): unknown => {
              return value.toISO();
            })(value["syncedAt"]),
          };
        });
      })(value["items"]),
      page: ((value: number): unknown => {
        return value;
      })(value["page"]),
      pageSize: ((value: number): unknown => {
        return value;
      })(value["pageSize"]),
      totalCount: ((value: bigint): unknown => {
        return value;
      })(value["totalCount"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PagedConnectorContentExecutionLogListView(str: string): PagedConnectorContentExecutionLogListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: ConnectorContentExecutionLogListView[];
          page: number;
          pageSize: number;
          totalCount: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ConnectorContentExecutionLogListView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ConnectorContentExecutionLogListView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  connectorExecutionLogId: bigint;
                  contentId: string;
                  method: string;
                  request: string;
                  response: string;
                  statusCode: number;
                  syncedAt: DateTime<true>;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "connectorExecutionLogId")) {
              return { success: false };
            }
            const parsed_connectorExecutionLogId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["connectorExecutionLogId"]);
            })();
            if (!parsed_connectorExecutionLogId.success) {
              return { success: false };
            }
            if (!hasProp(value, "contentId")) {
              return { success: false };
            }
            const parsed_contentId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["contentId"]);
            })();
            if (!parsed_contentId.success) {
              return { success: false };
            }
            if (!hasProp(value, "method")) {
              return { success: false };
            }
            const parsed_method = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["method"]);
            })();
            if (!parsed_method.success) {
              return { success: false };
            }
            if (!hasProp(value, "request")) {
              return { success: false };
            }
            const parsed_request = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["request"]);
            })();
            if (!parsed_request.success) {
              return { success: false };
            }
            if (!hasProp(value, "response")) {
              return { success: false };
            }
            const parsed_response = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["response"]);
            })();
            if (!parsed_response.success) {
              return { success: false };
            }
            if (!hasProp(value, "statusCode")) {
              return { success: false };
            }
            const parsed_statusCode = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["statusCode"]);
            })();
            if (!parsed_statusCode.success) {
              return { success: false };
            }
            if (!hasProp(value, "syncedAt")) {
              return { success: false };
            }
            const parsed_syncedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["syncedAt"]);
            })();
            if (!parsed_syncedAt.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                connectorExecutionLogId: parsed_connectorExecutionLogId.value,
                contentId: parsed_contentId.value,
                method: parsed_method.value,
                request: parsed_request.value,
                response: parsed_response.value,
                statusCode: parsed_statusCode.value,
                syncedAt: parsed_syncedAt.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "page")) {
      return { success: false };
    }
    const parsed_page = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["page"]);
    })();
    if (!parsed_page.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageSize")) {
      return { success: false };
    }
    const parsed_pageSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageSize"]);
    })();
    if (!parsed_pageSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PagedCustomPageEditListView
export function serialize_PagedCustomPageEditListView(obj: PagedCustomPageEditListView): string {
  const serialized = ((value: { items: CustomPageEditListView[]; pageInfo: PageInfo }): unknown => {
    return {
      items: ((value: CustomPageEditListView[]): unknown => {
        return value.map(
          (value: { active: boolean; corporateId: number; corporation: string; customPageId: bigint; highlightPriority: number | null; modifiedAt: DateTime<true>; titles: { additionalProperties: Record<string, string> } }): unknown => {
            return {
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              corporateId: ((value: number): unknown => {
                return value;
              })(value["corporateId"]),
              corporation: ((value: string): unknown => {
                return value;
              })(value["corporation"]),
              customPageId: ((value: bigint): unknown => {
                return value;
              })(value["customPageId"]),
              highlightPriority: ((value: number | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["highlightPriority"]),
              modifiedAt: ((value: DateTime<true>): unknown => {
                return value.toISO();
              })(value["modifiedAt"]),
              titles: ((value: { additionalProperties: Record<string, string> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string): unknown => {
                          return value;
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["titles"]),
            };
          },
        );
      })(value["items"]),
      pageInfo: ((value: { page: number; pageSize: number; totalCount: number }): unknown => {
        return {
          page: ((value: number): unknown => {
            return value;
          })(value["page"]),
          pageSize: ((value: number): unknown => {
            return value;
          })(value["pageSize"]),
          totalCount: ((value: number): unknown => {
            return value;
          })(value["totalCount"]),
        };
      })(value["pageInfo"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PagedCustomPageEditListView(str: string): PagedCustomPageEditListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: CustomPageEditListView[];
          pageInfo: PageInfo;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CustomPageEditListView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: CustomPageEditListView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  active: boolean;
                  corporateId: number;
                  corporation: string;
                  customPageId: bigint;
                  highlightPriority: number | null;
                  modifiedAt: DateTime<true>;
                  titles: { additionalProperties: Record<string, string> };
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "active")) {
              return { success: false };
            }
            const parsed_active = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["active"]);
            })();
            if (!parsed_active.success) {
              return { success: false };
            }
            if (!hasProp(value, "corporateId")) {
              return { success: false };
            }
            const parsed_corporateId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["corporateId"]);
            })();
            if (!parsed_corporateId.success) {
              return { success: false };
            }
            if (!hasProp(value, "corporation")) {
              return { success: false };
            }
            const parsed_corporation = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["corporation"]);
            })();
            if (!parsed_corporation.success) {
              return { success: false };
            }
            if (!hasProp(value, "customPageId")) {
              return { success: false };
            }
            const parsed_customPageId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["customPageId"]);
            })();
            if (!parsed_customPageId.success) {
              return { success: false };
            }
            if (!hasProp(value, "highlightPriority")) {
              return { success: false };
            }
            const parsed_highlightPriority = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["highlightPriority"]);
            })();
            if (!parsed_highlightPriority.success) {
              return { success: false };
            }
            if (!hasProp(value, "modifiedAt")) {
              return { success: false };
            }
            const parsed_modifiedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["modifiedAt"]);
            })();
            if (!parsed_modifiedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "titles")) {
              return { success: false };
            }
            const parsed_titles = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
                  const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
                    return [
                      key,
                      ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value),
                    ];
                  });
                  if (parsed.find(([_, value]) => !value.success) !== undefined) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: Object.fromEntries(
                      parsed.map(([key, value]) => {
                        assert(value.success);
                        return [key, value.value];
                      }),
                    ),
                  };
                })();
                if (!parsed_additionalProperties.success) {
                  return { success: false };
                }
                return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
              })(value["titles"]);
            })();
            if (!parsed_titles.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                active: parsed_active.value,
                corporateId: parsed_corporateId.value,
                corporation: parsed_corporation.value,
                customPageId: parsed_customPageId.value,
                highlightPriority: parsed_highlightPriority.value,
                modifiedAt: parsed_modifiedAt.value,
                titles: parsed_titles.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageInfo")) {
      return { success: false };
    }
    const parsed_pageInfo = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PageInfo } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                page: number;
                pageSize: number;
                totalCount: number;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "page")) {
            return { success: false };
          }
          const parsed_page = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["page"]);
          })();
          if (!parsed_page.success) {
            return { success: false };
          }
          if (!hasProp(value, "pageSize")) {
            return { success: false };
          }
          const parsed_pageSize = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["pageSize"]);
          })();
          if (!parsed_pageSize.success) {
            return { success: false };
          }
          if (!hasProp(value, "totalCount")) {
            return { success: false };
          }
          const parsed_totalCount = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["totalCount"]);
          })();
          if (!parsed_totalCount.success) {
            return { success: false };
          }
          return { success: true, value: { page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
        })(value);
      })(value["pageInfo"]);
    })();
    if (!parsed_pageInfo.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, pageInfo: parsed_pageInfo.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PagedCustomPageListView
export function serialize_PagedCustomPageListView(obj: PagedCustomPageListView): string {
  const serialized = ((value: { items: CustomPageListView[]; pageInfo: PageInfo }): unknown => {
    return {
      items: ((value: CustomPageListView[]): unknown => {
        return value.map((value: { corporateId: number; coverUri: string; customPageId: bigint; description: string | null; title: string }): unknown => {
          return {
            corporateId: ((value: number): unknown => {
              return value;
            })(value["corporateId"]),
            coverUri: ((value: string): unknown => {
              return value;
            })(value["coverUri"]),
            customPageId: ((value: bigint): unknown => {
              return value;
            })(value["customPageId"]),
            description: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["description"]),
            title: ((value: string): unknown => {
              return value;
            })(value["title"]),
          };
        });
      })(value["items"]),
      pageInfo: ((value: { page: number; pageSize: number; totalCount: number }): unknown => {
        return {
          page: ((value: number): unknown => {
            return value;
          })(value["page"]),
          pageSize: ((value: number): unknown => {
            return value;
          })(value["pageSize"]),
          totalCount: ((value: number): unknown => {
            return value;
          })(value["totalCount"]),
        };
      })(value["pageInfo"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PagedCustomPageListView(str: string): PagedCustomPageListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: CustomPageListView[];
          pageInfo: PageInfo;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CustomPageListView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: CustomPageListView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  corporateId: number;
                  coverUri: string;
                  customPageId: bigint;
                  description: string | null;
                  title: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "corporateId")) {
              return { success: false };
            }
            const parsed_corporateId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["corporateId"]);
            })();
            if (!parsed_corporateId.success) {
              return { success: false };
            }
            if (!hasProp(value, "coverUri")) {
              return { success: false };
            }
            const parsed_coverUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["coverUri"]);
            })();
            if (!parsed_coverUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "customPageId")) {
              return { success: false };
            }
            const parsed_customPageId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["customPageId"]);
            })();
            if (!parsed_customPageId.success) {
              return { success: false };
            }
            if (!hasProp(value, "description")) {
              return { success: false };
            }
            const parsed_description = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["description"]);
            })();
            if (!parsed_description.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            return { success: true, value: { corporateId: parsed_corporateId.value, coverUri: parsed_coverUri.value, customPageId: parsed_customPageId.value, description: parsed_description.value, title: parsed_title.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageInfo")) {
      return { success: false };
    }
    const parsed_pageInfo = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PageInfo } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                page: number;
                pageSize: number;
                totalCount: number;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "page")) {
            return { success: false };
          }
          const parsed_page = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["page"]);
          })();
          if (!parsed_page.success) {
            return { success: false };
          }
          if (!hasProp(value, "pageSize")) {
            return { success: false };
          }
          const parsed_pageSize = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["pageSize"]);
          })();
          if (!parsed_pageSize.success) {
            return { success: false };
          }
          if (!hasProp(value, "totalCount")) {
            return { success: false };
          }
          const parsed_totalCount = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["totalCount"]);
          })();
          if (!parsed_totalCount.success) {
            return { success: false };
          }
          return { success: true, value: { page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
        })(value);
      })(value["pageInfo"]);
    })();
    if (!parsed_pageInfo.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, pageInfo: parsed_pageInfo.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PagedEmailDefaultCategoryAdminView
export function serialize_PagedEmailDefaultCategoryAdminView(obj: PagedEmailDefaultCategoryAdminView): string {
  const serialized = ((value: { items: EmailDefaultCategoryAdminView[]; page: number; pageSize: number; totalCount: bigint }): unknown => {
    return {
      items: ((value: EmailDefaultCategoryAdminView[]): unknown => {
        return value.map((value: { categoryId: number; name: string; repositoryId: number }): unknown => {
          return {
            categoryId: ((value: number): unknown => {
              return value;
            })(value["categoryId"]),
            name: ((value: string): unknown => {
              return value;
            })(value["name"]),
            repositoryId: ((value: number): unknown => {
              return value;
            })(value["repositoryId"]),
          };
        });
      })(value["items"]),
      page: ((value: number): unknown => {
        return value;
      })(value["page"]),
      pageSize: ((value: number): unknown => {
        return value;
      })(value["pageSize"]),
      totalCount: ((value: bigint): unknown => {
        return value;
      })(value["totalCount"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PagedEmailDefaultCategoryAdminView(str: string): PagedEmailDefaultCategoryAdminView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: EmailDefaultCategoryAdminView[];
          page: number;
          pageSize: number;
          totalCount: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: EmailDefaultCategoryAdminView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: EmailDefaultCategoryAdminView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  categoryId: number;
                  name: string;
                  repositoryId: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "categoryId")) {
              return { success: false };
            }
            const parsed_categoryId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["categoryId"]);
            })();
            if (!parsed_categoryId.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "repositoryId")) {
              return { success: false };
            }
            const parsed_repositoryId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["repositoryId"]);
            })();
            if (!parsed_repositoryId.success) {
              return { success: false };
            }
            return { success: true, value: { categoryId: parsed_categoryId.value, name: parsed_name.value, repositoryId: parsed_repositoryId.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "page")) {
      return { success: false };
    }
    const parsed_page = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["page"]);
    })();
    if (!parsed_page.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageSize")) {
      return { success: false };
    }
    const parsed_pageSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageSize"]);
    })();
    if (!parsed_pageSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PagedEmailEducationCategoryAdminView
export function serialize_PagedEmailEducationCategoryAdminView(obj: PagedEmailEducationCategoryAdminView): string {
  const serialized = ((value: { items: EmailEducationCategoryAdminView[]; page: number; pageSize: number; totalCount: bigint }): unknown => {
    return {
      items: ((value: EmailEducationCategoryAdminView[]): unknown => {
        return value.map((value: { active: boolean; categoryId: number; name: string; productIds: number[]; repositoryId: number }): unknown => {
          return {
            active: ((value: boolean): unknown => {
              return value;
            })(value["active"]),
            categoryId: ((value: number): unknown => {
              return value;
            })(value["categoryId"]),
            name: ((value: string): unknown => {
              return value;
            })(value["name"]),
            productIds: ((value: number[]): unknown => {
              return value.map((value: number): unknown => {
                return value;
              });
            })(value["productIds"]),
            repositoryId: ((value: number): unknown => {
              return value;
            })(value["repositoryId"]),
          };
        });
      })(value["items"]),
      page: ((value: number): unknown => {
        return value;
      })(value["page"]),
      pageSize: ((value: number): unknown => {
        return value;
      })(value["pageSize"]),
      totalCount: ((value: bigint): unknown => {
        return value;
      })(value["totalCount"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PagedEmailEducationCategoryAdminView(str: string): PagedEmailEducationCategoryAdminView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: EmailEducationCategoryAdminView[];
          page: number;
          pageSize: number;
          totalCount: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: EmailEducationCategoryAdminView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: EmailEducationCategoryAdminView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  active: boolean;
                  categoryId: number;
                  name: string;
                  productIds: number[];
                  repositoryId: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "active")) {
              return { success: false };
            }
            const parsed_active = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["active"]);
            })();
            if (!parsed_active.success) {
              return { success: false };
            }
            if (!hasProp(value, "categoryId")) {
              return { success: false };
            }
            const parsed_categoryId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["categoryId"]);
            })();
            if (!parsed_categoryId.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "productIds")) {
              return { success: false };
            }
            const parsed_productIds = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
                  if (typeof value === "number") {
                    return { success: true, value: value };
                  }
                  return { success: false };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["productIds"]);
            })();
            if (!parsed_productIds.success) {
              return { success: false };
            }
            if (!hasProp(value, "repositoryId")) {
              return { success: false };
            }
            const parsed_repositoryId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["repositoryId"]);
            })();
            if (!parsed_repositoryId.success) {
              return { success: false };
            }
            return { success: true, value: { active: parsed_active.value, categoryId: parsed_categoryId.value, name: parsed_name.value, productIds: parsed_productIds.value, repositoryId: parsed_repositoryId.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "page")) {
      return { success: false };
    }
    const parsed_page = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["page"]);
    })();
    if (!parsed_page.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageSize")) {
      return { success: false };
    }
    const parsed_pageSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageSize"]);
    })();
    if (!parsed_pageSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PagedEmailFollowUpCategoryAdminView
export function serialize_PagedEmailFollowUpCategoryAdminView(obj: PagedEmailFollowUpCategoryAdminView): string {
  const serialized = ((value: { items: EmailFollowUpCategoryAdminView[]; page: number; pageSize: number; totalCount: bigint }): unknown => {
    return {
      items: ((value: EmailFollowUpCategoryAdminView[]): unknown => {
        return value.map(
          (value: {
            active: boolean;
            categoryId: number;
            corporateIds: string[];
            interval: number;
            maxDaysAfterExpiration: number;
            minDaysAfterExpiration: number;
            name: string;
            repositoryId: number;
            startDate: { year: number; month: number; day: number } | null;
          }): unknown => {
            return {
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              categoryId: ((value: number): unknown => {
                return value;
              })(value["categoryId"]),
              corporateIds: ((value: string[]): unknown => {
                return value.map((value: string): unknown => {
                  return value;
                });
              })(value["corporateIds"]),
              interval: ((value: number): unknown => {
                return value;
              })(value["interval"]),
              maxDaysAfterExpiration: ((value: number): unknown => {
                return value;
              })(value["maxDaysAfterExpiration"]),
              minDaysAfterExpiration: ((value: number): unknown => {
                return value;
              })(value["minDaysAfterExpiration"]),
              name: ((value: string): unknown => {
                return value;
              })(value["name"]),
              repositoryId: ((value: number): unknown => {
                return value;
              })(value["repositoryId"]),
              startDate: ((value: { year: number; month: number; day: number } | null): unknown => {
                if (value === null) {
                  return null;
                }
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["startDate"]),
            };
          },
        );
      })(value["items"]),
      page: ((value: number): unknown => {
        return value;
      })(value["page"]),
      pageSize: ((value: number): unknown => {
        return value;
      })(value["pageSize"]),
      totalCount: ((value: bigint): unknown => {
        return value;
      })(value["totalCount"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PagedEmailFollowUpCategoryAdminView(str: string): PagedEmailFollowUpCategoryAdminView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: EmailFollowUpCategoryAdminView[];
          page: number;
          pageSize: number;
          totalCount: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: EmailFollowUpCategoryAdminView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: EmailFollowUpCategoryAdminView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  active: boolean;
                  categoryId: number;
                  corporateIds: string[];
                  interval: number;
                  maxDaysAfterExpiration: number;
                  minDaysAfterExpiration: number;
                  name: string;
                  repositoryId: number;
                  startDate: { year: number; month: number; day: number } | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "active")) {
              return { success: false };
            }
            const parsed_active = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["active"]);
            })();
            if (!parsed_active.success) {
              return { success: false };
            }
            if (!hasProp(value, "categoryId")) {
              return { success: false };
            }
            const parsed_categoryId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["categoryId"]);
            })();
            if (!parsed_categoryId.success) {
              return { success: false };
            }
            if (!hasProp(value, "corporateIds")) {
              return { success: false };
            }
            const parsed_corporateIds = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["corporateIds"]);
            })();
            if (!parsed_corporateIds.success) {
              return { success: false };
            }
            if (!hasProp(value, "interval")) {
              return { success: false };
            }
            const parsed_interval = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["interval"]);
            })();
            if (!parsed_interval.success) {
              return { success: false };
            }
            if (!hasProp(value, "maxDaysAfterExpiration")) {
              return { success: false };
            }
            const parsed_maxDaysAfterExpiration = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["maxDaysAfterExpiration"]);
            })();
            if (!parsed_maxDaysAfterExpiration.success) {
              return { success: false };
            }
            if (!hasProp(value, "minDaysAfterExpiration")) {
              return { success: false };
            }
            const parsed_minDaysAfterExpiration = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["minDaysAfterExpiration"]);
            })();
            if (!parsed_minDaysAfterExpiration.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "repositoryId")) {
              return { success: false };
            }
            const parsed_repositoryId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["repositoryId"]);
            })();
            if (!parsed_repositoryId.success) {
              return { success: false };
            }
            if (!hasProp(value, "startDate")) {
              return { success: false };
            }
            const parsed_startDate = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
              })(value["startDate"]);
            })();
            if (!parsed_startDate.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                active: parsed_active.value,
                categoryId: parsed_categoryId.value,
                corporateIds: parsed_corporateIds.value,
                interval: parsed_interval.value,
                maxDaysAfterExpiration: parsed_maxDaysAfterExpiration.value,
                minDaysAfterExpiration: parsed_minDaysAfterExpiration.value,
                name: parsed_name.value,
                repositoryId: parsed_repositoryId.value,
                startDate: parsed_startDate.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "page")) {
      return { success: false };
    }
    const parsed_page = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["page"]);
    })();
    if (!parsed_page.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageSize")) {
      return { success: false };
    }
    const parsed_pageSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageSize"]);
    })();
    if (!parsed_pageSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PagedEmailManualCategoryAdminView
export function serialize_PagedEmailManualCategoryAdminView(obj: PagedEmailManualCategoryAdminView): string {
  const serialized = ((value: { items: EmailManualCategoryAdminView[]; page: number; pageSize: number; totalCount: bigint }): unknown => {
    return {
      items: ((value: EmailManualCategoryAdminView[]): unknown => {
        return value.map((value: { categoryId: number; corporateIds: string[]; name: string; repositoryId: number }): unknown => {
          return {
            categoryId: ((value: number): unknown => {
              return value;
            })(value["categoryId"]),
            corporateIds: ((value: string[]): unknown => {
              return value.map((value: string): unknown => {
                return value;
              });
            })(value["corporateIds"]),
            name: ((value: string): unknown => {
              return value;
            })(value["name"]),
            repositoryId: ((value: number): unknown => {
              return value;
            })(value["repositoryId"]),
          };
        });
      })(value["items"]),
      page: ((value: number): unknown => {
        return value;
      })(value["page"]),
      pageSize: ((value: number): unknown => {
        return value;
      })(value["pageSize"]),
      totalCount: ((value: bigint): unknown => {
        return value;
      })(value["totalCount"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PagedEmailManualCategoryAdminView(str: string): PagedEmailManualCategoryAdminView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: EmailManualCategoryAdminView[];
          page: number;
          pageSize: number;
          totalCount: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: EmailManualCategoryAdminView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: EmailManualCategoryAdminView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  categoryId: number;
                  corporateIds: string[];
                  name: string;
                  repositoryId: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "categoryId")) {
              return { success: false };
            }
            const parsed_categoryId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["categoryId"]);
            })();
            if (!parsed_categoryId.success) {
              return { success: false };
            }
            if (!hasProp(value, "corporateIds")) {
              return { success: false };
            }
            const parsed_corporateIds = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["corporateIds"]);
            })();
            if (!parsed_corporateIds.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "repositoryId")) {
              return { success: false };
            }
            const parsed_repositoryId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["repositoryId"]);
            })();
            if (!parsed_repositoryId.success) {
              return { success: false };
            }
            return { success: true, value: { categoryId: parsed_categoryId.value, corporateIds: parsed_corporateIds.value, name: parsed_name.value, repositoryId: parsed_repositoryId.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "page")) {
      return { success: false };
    }
    const parsed_page = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["page"]);
    })();
    if (!parsed_page.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageSize")) {
      return { success: false };
    }
    const parsed_pageSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageSize"]);
    })();
    if (!parsed_pageSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PagedEmailOnboardingCategoryAdminView
export function serialize_PagedEmailOnboardingCategoryAdminView(obj: PagedEmailOnboardingCategoryAdminView): string {
  const serialized = ((value: { items: EmailOnboardingCategoryAdminView[]; page: number; pageSize: number; totalCount: bigint }): unknown => {
    return {
      items: ((value: EmailOnboardingCategoryAdminView[]): unknown => {
        return value.map((value: { active: boolean; categoryId: number; maxDaysAfterSignup: number; minDaysAfterSignup: number; name: string; repositoryId: number }): unknown => {
          return {
            active: ((value: boolean): unknown => {
              return value;
            })(value["active"]),
            categoryId: ((value: number): unknown => {
              return value;
            })(value["categoryId"]),
            maxDaysAfterSignup: ((value: number): unknown => {
              return value;
            })(value["maxDaysAfterSignup"]),
            minDaysAfterSignup: ((value: number): unknown => {
              return value;
            })(value["minDaysAfterSignup"]),
            name: ((value: string): unknown => {
              return value;
            })(value["name"]),
            repositoryId: ((value: number): unknown => {
              return value;
            })(value["repositoryId"]),
          };
        });
      })(value["items"]),
      page: ((value: number): unknown => {
        return value;
      })(value["page"]),
      pageSize: ((value: number): unknown => {
        return value;
      })(value["pageSize"]),
      totalCount: ((value: bigint): unknown => {
        return value;
      })(value["totalCount"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PagedEmailOnboardingCategoryAdminView(str: string): PagedEmailOnboardingCategoryAdminView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: EmailOnboardingCategoryAdminView[];
          page: number;
          pageSize: number;
          totalCount: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: EmailOnboardingCategoryAdminView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: EmailOnboardingCategoryAdminView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  active: boolean;
                  categoryId: number;
                  maxDaysAfterSignup: number;
                  minDaysAfterSignup: number;
                  name: string;
                  repositoryId: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "active")) {
              return { success: false };
            }
            const parsed_active = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["active"]);
            })();
            if (!parsed_active.success) {
              return { success: false };
            }
            if (!hasProp(value, "categoryId")) {
              return { success: false };
            }
            const parsed_categoryId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["categoryId"]);
            })();
            if (!parsed_categoryId.success) {
              return { success: false };
            }
            if (!hasProp(value, "maxDaysAfterSignup")) {
              return { success: false };
            }
            const parsed_maxDaysAfterSignup = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["maxDaysAfterSignup"]);
            })();
            if (!parsed_maxDaysAfterSignup.success) {
              return { success: false };
            }
            if (!hasProp(value, "minDaysAfterSignup")) {
              return { success: false };
            }
            const parsed_minDaysAfterSignup = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["minDaysAfterSignup"]);
            })();
            if (!parsed_minDaysAfterSignup.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "repositoryId")) {
              return { success: false };
            }
            const parsed_repositoryId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["repositoryId"]);
            })();
            if (!parsed_repositoryId.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                active: parsed_active.value,
                categoryId: parsed_categoryId.value,
                maxDaysAfterSignup: parsed_maxDaysAfterSignup.value,
                minDaysAfterSignup: parsed_minDaysAfterSignup.value,
                name: parsed_name.value,
                repositoryId: parsed_repositoryId.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "page")) {
      return { success: false };
    }
    const parsed_page = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["page"]);
    })();
    if (!parsed_page.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageSize")) {
      return { success: false };
    }
    const parsed_pageSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageSize"]);
    })();
    if (!parsed_pageSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PagedEmailRenewalCategoryAdminView
export function serialize_PagedEmailRenewalCategoryAdminView(obj: PagedEmailRenewalCategoryAdminView): string {
  const serialized = ((value: { items: EmailRenewalCategoryAdminView[]; page: number; pageSize: number; totalCount: bigint }): unknown => {
    return {
      items: ((value: EmailRenewalCategoryAdminView[]): unknown => {
        return value.map((value: { active: boolean; categoryId: number; maxDaysAfterExpiration: number; minDaysAfterExpiration: number; name: string; productIds: number[]; repositoryId: number }): unknown => {
          return {
            active: ((value: boolean): unknown => {
              return value;
            })(value["active"]),
            categoryId: ((value: number): unknown => {
              return value;
            })(value["categoryId"]),
            maxDaysAfterExpiration: ((value: number): unknown => {
              return value;
            })(value["maxDaysAfterExpiration"]),
            minDaysAfterExpiration: ((value: number): unknown => {
              return value;
            })(value["minDaysAfterExpiration"]),
            name: ((value: string): unknown => {
              return value;
            })(value["name"]),
            productIds: ((value: number[]): unknown => {
              return value.map((value: number): unknown => {
                return value;
              });
            })(value["productIds"]),
            repositoryId: ((value: number): unknown => {
              return value;
            })(value["repositoryId"]),
          };
        });
      })(value["items"]),
      page: ((value: number): unknown => {
        return value;
      })(value["page"]),
      pageSize: ((value: number): unknown => {
        return value;
      })(value["pageSize"]),
      totalCount: ((value: bigint): unknown => {
        return value;
      })(value["totalCount"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PagedEmailRenewalCategoryAdminView(str: string): PagedEmailRenewalCategoryAdminView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: EmailRenewalCategoryAdminView[];
          page: number;
          pageSize: number;
          totalCount: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: EmailRenewalCategoryAdminView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: EmailRenewalCategoryAdminView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  active: boolean;
                  categoryId: number;
                  maxDaysAfterExpiration: number;
                  minDaysAfterExpiration: number;
                  name: string;
                  productIds: number[];
                  repositoryId: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "active")) {
              return { success: false };
            }
            const parsed_active = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["active"]);
            })();
            if (!parsed_active.success) {
              return { success: false };
            }
            if (!hasProp(value, "categoryId")) {
              return { success: false };
            }
            const parsed_categoryId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["categoryId"]);
            })();
            if (!parsed_categoryId.success) {
              return { success: false };
            }
            if (!hasProp(value, "maxDaysAfterExpiration")) {
              return { success: false };
            }
            const parsed_maxDaysAfterExpiration = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["maxDaysAfterExpiration"]);
            })();
            if (!parsed_maxDaysAfterExpiration.success) {
              return { success: false };
            }
            if (!hasProp(value, "minDaysAfterExpiration")) {
              return { success: false };
            }
            const parsed_minDaysAfterExpiration = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["minDaysAfterExpiration"]);
            })();
            if (!parsed_minDaysAfterExpiration.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "productIds")) {
              return { success: false };
            }
            const parsed_productIds = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
                  if (typeof value === "number") {
                    return { success: true, value: value };
                  }
                  return { success: false };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["productIds"]);
            })();
            if (!parsed_productIds.success) {
              return { success: false };
            }
            if (!hasProp(value, "repositoryId")) {
              return { success: false };
            }
            const parsed_repositoryId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["repositoryId"]);
            })();
            if (!parsed_repositoryId.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                active: parsed_active.value,
                categoryId: parsed_categoryId.value,
                maxDaysAfterExpiration: parsed_maxDaysAfterExpiration.value,
                minDaysAfterExpiration: parsed_minDaysAfterExpiration.value,
                name: parsed_name.value,
                productIds: parsed_productIds.value,
                repositoryId: parsed_repositoryId.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "page")) {
      return { success: false };
    }
    const parsed_page = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["page"]);
    })();
    if (!parsed_page.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageSize")) {
      return { success: false };
    }
    const parsed_pageSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageSize"]);
    })();
    if (!parsed_pageSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PagedExportReportListView
export function serialize_PagedExportReportListView(obj: PagedExportReportListView): string {
  const serialized = ((value: { items: ExportReportListView[]; pageInfo: PageInfo }): unknown => {
    return {
      items: ((value: ExportReportListView[]): unknown => {
        return value.map(
          (value: { downloadLink: string; exportDate: { year: number; month: number; day: number }; exportTaskId: number; fileName: string; hash: string; reportName: string | null; reportType: ReportType | null }): unknown => {
            return {
              downloadLink: ((value: string): unknown => {
                return value;
              })(value["downloadLink"]),
              exportDate: ((value: { year: number; month: number; day: number }): unknown => {
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["exportDate"]),
              exportTaskId: ((value: number): unknown => {
                return value;
              })(value["exportTaskId"]),
              fileName: ((value: string): unknown => {
                return value;
              })(value["fileName"]),
              hash: ((value: string): unknown => {
                return value;
              })(value["hash"]),
              reportName: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["reportName"]),
              reportType: ((value: ("USERS" | "ACTIVITY" | "EXTENDED_USERS_EXPORT" | "ACTIONABLES_ACTIVITY") | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["reportType"]),
            };
          },
        );
      })(value["items"]),
      pageInfo: ((value: { page: number; pageSize: number; totalCount: number }): unknown => {
        return {
          page: ((value: number): unknown => {
            return value;
          })(value["page"]),
          pageSize: ((value: number): unknown => {
            return value;
          })(value["pageSize"]),
          totalCount: ((value: number): unknown => {
            return value;
          })(value["totalCount"]),
        };
      })(value["pageInfo"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PagedExportReportListView(str: string): PagedExportReportListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: ExportReportListView[];
          pageInfo: PageInfo;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ExportReportListView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ExportReportListView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  downloadLink: string;
                  exportDate: { year: number; month: number; day: number };
                  exportTaskId: number;
                  fileName: string;
                  hash: string;
                  reportName: string | null;
                  reportType: ReportType | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "downloadLink")) {
              return { success: false };
            }
            const parsed_downloadLink = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["downloadLink"]);
            })();
            if (!parsed_downloadLink.success) {
              return { success: false };
            }
            if (!hasProp(value, "exportDate")) {
              return { success: false };
            }
            const parsed_exportDate = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
              })(value["exportDate"]);
            })();
            if (!parsed_exportDate.success) {
              return { success: false };
            }
            if (!hasProp(value, "exportTaskId")) {
              return { success: false };
            }
            const parsed_exportTaskId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["exportTaskId"]);
            })();
            if (!parsed_exportTaskId.success) {
              return { success: false };
            }
            if (!hasProp(value, "fileName")) {
              return { success: false };
            }
            const parsed_fileName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["fileName"]);
            })();
            if (!parsed_fileName.success) {
              return { success: false };
            }
            if (!hasProp(value, "hash")) {
              return { success: false };
            }
            const parsed_hash = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["hash"]);
            })();
            if (!parsed_hash.success) {
              return { success: false };
            }
            if (!hasProp(value, "reportName")) {
              return { success: false };
            }
            const parsed_reportName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["reportName"]);
            })();
            if (!parsed_reportName.success) {
              return { success: false };
            }
            if (!hasProp(value, "reportType")) {
              return { success: false };
            }
            const parsed_reportType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ReportType | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                return ((value: unknown): { success: false } | { success: true; value: "USERS" | "ACTIVITY" | "EXTENDED_USERS_EXPORT" | "ACTIONABLES_ACTIVITY" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "USERS":
                      return { success: true, value: "USERS" };
                    case "ACTIVITY":
                      return { success: true, value: "ACTIVITY" };
                    case "EXTENDED_USERS_EXPORT":
                      return { success: true, value: "EXTENDED_USERS_EXPORT" };
                    case "ACTIONABLES_ACTIVITY":
                      return { success: true, value: "ACTIONABLES_ACTIVITY" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["reportType"]);
            })();
            if (!parsed_reportType.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                downloadLink: parsed_downloadLink.value,
                exportDate: parsed_exportDate.value,
                exportTaskId: parsed_exportTaskId.value,
                fileName: parsed_fileName.value,
                hash: parsed_hash.value,
                reportName: parsed_reportName.value,
                reportType: parsed_reportType.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageInfo")) {
      return { success: false };
    }
    const parsed_pageInfo = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PageInfo } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                page: number;
                pageSize: number;
                totalCount: number;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "page")) {
            return { success: false };
          }
          const parsed_page = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["page"]);
          })();
          if (!parsed_page.success) {
            return { success: false };
          }
          if (!hasProp(value, "pageSize")) {
            return { success: false };
          }
          const parsed_pageSize = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["pageSize"]);
          })();
          if (!parsed_pageSize.success) {
            return { success: false };
          }
          if (!hasProp(value, "totalCount")) {
            return { success: false };
          }
          const parsed_totalCount = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["totalCount"]);
          })();
          if (!parsed_totalCount.success) {
            return { success: false };
          }
          return { success: true, value: { page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
        })(value);
      })(value["pageInfo"]);
    })();
    if (!parsed_pageInfo.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, pageInfo: parsed_pageInfo.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PagedReportListView
export function serialize_PagedReportListView(obj: PagedReportListView): string {
  const serialized = ((value: { items: ReportListView[]; pageInfo: PageInfo }): unknown => {
    return {
      items: ((value: ReportListView[]): unknown => {
        return value.map(
          (value: {
            active: boolean;
            corporateId: number;
            customerFullName: string;
            frequency: ReportFrequency;
            isRunning: boolean | null;
            name: string;
            prefixFileName: boolean;
            reportId: number;
            reportType: ReportType;
            targetExcel: boolean;
            timeFrameFrom: { year: number; month: number; day: number };
            timeFrameTo: { year: number; month: number; day: number };
            zipFile: boolean;
          }): unknown => {
            return {
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              corporateId: ((value: number): unknown => {
                return value;
              })(value["corporateId"]),
              customerFullName: ((value: string): unknown => {
                return value;
              })(value["customerFullName"]),
              frequency: ((value: "ONE_TIME" | "DAILY" | "WEEKLY" | "MONTHLY"): unknown => {
                return value;
              })(value["frequency"]),
              isRunning: ((value: boolean | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["isRunning"]),
              name: ((value: string): unknown => {
                return value;
              })(value["name"]),
              prefixFileName: ((value: boolean): unknown => {
                return value;
              })(value["prefixFileName"]),
              reportId: ((value: number): unknown => {
                return value;
              })(value["reportId"]),
              reportType: ((value: "USERS" | "ACTIVITY" | "EXTENDED_USERS_EXPORT" | "ACTIONABLES_ACTIVITY"): unknown => {
                return value;
              })(value["reportType"]),
              targetExcel: ((value: boolean): unknown => {
                return value;
              })(value["targetExcel"]),
              timeFrameFrom: ((value: { year: number; month: number; day: number }): unknown => {
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["timeFrameFrom"]),
              timeFrameTo: ((value: { year: number; month: number; day: number }): unknown => {
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["timeFrameTo"]),
              zipFile: ((value: boolean): unknown => {
                return value;
              })(value["zipFile"]),
            };
          },
        );
      })(value["items"]),
      pageInfo: ((value: { page: number; pageSize: number; totalCount: number }): unknown => {
        return {
          page: ((value: number): unknown => {
            return value;
          })(value["page"]),
          pageSize: ((value: number): unknown => {
            return value;
          })(value["pageSize"]),
          totalCount: ((value: number): unknown => {
            return value;
          })(value["totalCount"]),
        };
      })(value["pageInfo"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PagedReportListView(str: string): PagedReportListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: ReportListView[];
          pageInfo: PageInfo;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ReportListView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ReportListView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  active: boolean;
                  corporateId: number;
                  customerFullName: string;
                  frequency: ReportFrequency;
                  isRunning: boolean | null;
                  name: string;
                  prefixFileName: boolean;
                  reportId: number;
                  reportType: ReportType;
                  targetExcel: boolean;
                  timeFrameFrom: { year: number; month: number; day: number };
                  timeFrameTo: { year: number; month: number; day: number };
                  zipFile: boolean;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "active")) {
              return { success: false };
            }
            const parsed_active = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["active"]);
            })();
            if (!parsed_active.success) {
              return { success: false };
            }
            if (!hasProp(value, "corporateId")) {
              return { success: false };
            }
            const parsed_corporateId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["corporateId"]);
            })();
            if (!parsed_corporateId.success) {
              return { success: false };
            }
            if (!hasProp(value, "customerFullName")) {
              return { success: false };
            }
            const parsed_customerFullName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["customerFullName"]);
            })();
            if (!parsed_customerFullName.success) {
              return { success: false };
            }
            if (!hasProp(value, "frequency")) {
              return { success: false };
            }
            const parsed_frequency = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ReportFrequency } => {
                return ((value: unknown): { success: false } | { success: true; value: "ONE_TIME" | "DAILY" | "WEEKLY" | "MONTHLY" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "ONE_TIME":
                      return { success: true, value: "ONE_TIME" };
                    case "DAILY":
                      return { success: true, value: "DAILY" };
                    case "WEEKLY":
                      return { success: true, value: "WEEKLY" };
                    case "MONTHLY":
                      return { success: true, value: "MONTHLY" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["frequency"]);
            })();
            if (!parsed_frequency.success) {
              return { success: false };
            }
            if (!hasProp(value, "isRunning")) {
              return { success: false };
            }
            const parsed_isRunning = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["isRunning"]);
            })();
            if (!parsed_isRunning.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "prefixFileName")) {
              return { success: false };
            }
            const parsed_prefixFileName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["prefixFileName"]);
            })();
            if (!parsed_prefixFileName.success) {
              return { success: false };
            }
            if (!hasProp(value, "reportId")) {
              return { success: false };
            }
            const parsed_reportId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["reportId"]);
            })();
            if (!parsed_reportId.success) {
              return { success: false };
            }
            if (!hasProp(value, "reportType")) {
              return { success: false };
            }
            const parsed_reportType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ReportType } => {
                return ((value: unknown): { success: false } | { success: true; value: "USERS" | "ACTIVITY" | "EXTENDED_USERS_EXPORT" | "ACTIONABLES_ACTIVITY" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "USERS":
                      return { success: true, value: "USERS" };
                    case "ACTIVITY":
                      return { success: true, value: "ACTIVITY" };
                    case "EXTENDED_USERS_EXPORT":
                      return { success: true, value: "EXTENDED_USERS_EXPORT" };
                    case "ACTIONABLES_ACTIVITY":
                      return { success: true, value: "ACTIONABLES_ACTIVITY" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["reportType"]);
            })();
            if (!parsed_reportType.success) {
              return { success: false };
            }
            if (!hasProp(value, "targetExcel")) {
              return { success: false };
            }
            const parsed_targetExcel = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["targetExcel"]);
            })();
            if (!parsed_targetExcel.success) {
              return { success: false };
            }
            if (!hasProp(value, "timeFrameFrom")) {
              return { success: false };
            }
            const parsed_timeFrameFrom = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
              })(value["timeFrameFrom"]);
            })();
            if (!parsed_timeFrameFrom.success) {
              return { success: false };
            }
            if (!hasProp(value, "timeFrameTo")) {
              return { success: false };
            }
            const parsed_timeFrameTo = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
              })(value["timeFrameTo"]);
            })();
            if (!parsed_timeFrameTo.success) {
              return { success: false };
            }
            if (!hasProp(value, "zipFile")) {
              return { success: false };
            }
            const parsed_zipFile = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["zipFile"]);
            })();
            if (!parsed_zipFile.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                active: parsed_active.value,
                corporateId: parsed_corporateId.value,
                customerFullName: parsed_customerFullName.value,
                frequency: parsed_frequency.value,
                isRunning: parsed_isRunning.value,
                name: parsed_name.value,
                prefixFileName: parsed_prefixFileName.value,
                reportId: parsed_reportId.value,
                reportType: parsed_reportType.value,
                targetExcel: parsed_targetExcel.value,
                timeFrameFrom: parsed_timeFrameFrom.value,
                timeFrameTo: parsed_timeFrameTo.value,
                zipFile: parsed_zipFile.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageInfo")) {
      return { success: false };
    }
    const parsed_pageInfo = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PageInfo } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                page: number;
                pageSize: number;
                totalCount: number;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "page")) {
            return { success: false };
          }
          const parsed_page = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["page"]);
          })();
          if (!parsed_page.success) {
            return { success: false };
          }
          if (!hasProp(value, "pageSize")) {
            return { success: false };
          }
          const parsed_pageSize = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["pageSize"]);
          })();
          if (!parsed_pageSize.success) {
            return { success: false };
          }
          if (!hasProp(value, "totalCount")) {
            return { success: false };
          }
          const parsed_totalCount = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["totalCount"]);
          })();
          if (!parsed_totalCount.success) {
            return { success: false };
          }
          return { success: true, value: { page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
        })(value);
      })(value["pageInfo"]);
    })();
    if (!parsed_pageInfo.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, pageInfo: parsed_pageInfo.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PagedSummaryListView
export function serialize_PagedSummaryListView(obj: PagedSummaryListView): string {
  const serialized = ((value: { items: SummaryListView[]; page: number; pageSize: number; totalCount: bigint }): unknown => {
    return {
      items: ((value: SummaryListView[]): unknown => {
        return value.map((value: { authors: string; dataId: number; language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"; source: string; title: string }): unknown => {
          return {
            authors: ((value: string): unknown => {
              return value;
            })(value["authors"]),
            dataId: ((value: number): unknown => {
              return value;
            })(value["dataId"]),
            language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
              return value;
            })(value["language"]),
            source: ((value: string): unknown => {
              return value;
            })(value["source"]),
            title: ((value: string): unknown => {
              return value;
            })(value["title"]),
          };
        });
      })(value["items"]),
      page: ((value: number): unknown => {
        return value;
      })(value["page"]),
      pageSize: ((value: number): unknown => {
        return value;
      })(value["pageSize"]),
      totalCount: ((value: bigint): unknown => {
        return value;
      })(value["totalCount"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PagedSummaryListView(str: string): PagedSummaryListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: SummaryListView[];
          page: number;
          pageSize: number;
          totalCount: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SummaryListView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: SummaryListView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  authors: string;
                  dataId: number;
                  language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
                  source: string;
                  title: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "authors")) {
              return { success: false };
            }
            const parsed_authors = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["authors"]);
            })();
            if (!parsed_authors.success) {
              return { success: false };
            }
            if (!hasProp(value, "dataId")) {
              return { success: false };
            }
            const parsed_dataId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["dataId"]);
            })();
            if (!parsed_dataId.success) {
              return { success: false };
            }
            if (!hasProp(value, "language")) {
              return { success: false };
            }
            const parsed_language = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "en":
                    return { success: true, value: "en" };
                  case "de":
                    return { success: true, value: "de" };
                  case "es":
                    return { success: true, value: "es" };
                  case "ru":
                    return { success: true, value: "ru" };
                  case "zh":
                    return { success: true, value: "zh" };
                  case "pt":
                    return { success: true, value: "pt" };
                  case "fr":
                    return { success: true, value: "fr" };
                  default:
                    return { success: false };
                }
              })(value["language"]);
            })();
            if (!parsed_language.success) {
              return { success: false };
            }
            if (!hasProp(value, "source")) {
              return { success: false };
            }
            const parsed_source = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["source"]);
            })();
            if (!parsed_source.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            return { success: true, value: { authors: parsed_authors.value, dataId: parsed_dataId.value, language: parsed_language.value, source: parsed_source.value, title: parsed_title.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "page")) {
      return { success: false };
    }
    const parsed_page = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["page"]);
    })();
    if (!parsed_page.success) {
      return { success: false };
    }
    if (!hasProp(value, "pageSize")) {
      return { success: false };
    }
    const parsed_pageSize = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pageSize"]);
    })();
    if (!parsed_pageSize.success) {
      return { success: false };
    }
    if (!hasProp(value, "totalCount")) {
      return { success: false };
    }
    const parsed_totalCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["totalCount"]);
    })();
    if (!parsed_totalCount.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, page: parsed_page.value, pageSize: parsed_pageSize.value, totalCount: parsed_totalCount.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PayPalPaymentView
export function serialize_PayPalPaymentView(obj: PayPalPaymentView): string {
  const serialized = ((value: { acqAuthorizationCode: string | null; authorizationCode: string | null; merchantId: string | null; paypalBillingAgreementId: string | null; refNumber: string | null; transactionId: number }): unknown => {
    return {
      acqAuthorizationCode: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["acqAuthorizationCode"]),
      authorizationCode: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["authorizationCode"]),
      merchantId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["merchantId"]),
      paypalBillingAgreementId: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["paypalBillingAgreementId"]),
      refNumber: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["refNumber"]),
      transactionId: ((value: number): unknown => {
        return value;
      })(value["transactionId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PayPalPaymentView(str: string): PayPalPaymentView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          acqAuthorizationCode: string | null;
          authorizationCode: string | null;
          merchantId: string | null;
          paypalBillingAgreementId: string | null;
          refNumber: string | null;
          transactionId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "acqAuthorizationCode")) {
      return { success: false };
    }
    const parsed_acqAuthorizationCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["acqAuthorizationCode"]);
    })();
    if (!parsed_acqAuthorizationCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "authorizationCode")) {
      return { success: false };
    }
    const parsed_authorizationCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["authorizationCode"]);
    })();
    if (!parsed_authorizationCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "merchantId")) {
      return { success: false };
    }
    const parsed_merchantId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["merchantId"]);
    })();
    if (!parsed_merchantId.success) {
      return { success: false };
    }
    if (!hasProp(value, "paypalBillingAgreementId")) {
      return { success: false };
    }
    const parsed_paypalBillingAgreementId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["paypalBillingAgreementId"]);
    })();
    if (!parsed_paypalBillingAgreementId.success) {
      return { success: false };
    }
    if (!hasProp(value, "refNumber")) {
      return { success: false };
    }
    const parsed_refNumber = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["refNumber"]);
    })();
    if (!parsed_refNumber.success) {
      return { success: false };
    }
    if (!hasProp(value, "transactionId")) {
      return { success: false };
    }
    const parsed_transactionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["transactionId"]);
    })();
    if (!parsed_transactionId.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        acqAuthorizationCode: parsed_acqAuthorizationCode.value,
        authorizationCode: parsed_authorizationCode.value,
        merchantId: parsed_merchantId.value,
        paypalBillingAgreementId: parsed_paypalBillingAgreementId.value,
        refNumber: parsed_refNumber.value,
        transactionId: parsed_transactionId.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PaymentType
export function serialize_PaymentType(obj: PaymentType): string {
  const serialized = ((value: "CC" | "INVOICE" | "PAYPAL" | "LSV" | "MILES" | "ALIPAY" | "IOS" | "ANDROID"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PaymentType(str: string): PaymentType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "CC" | "INVOICE" | "PAYPAL" | "LSV" | "MILES" | "ALIPAY" | "IOS" | "ANDROID" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "CC":
        return { success: true, value: "CC" };
      case "INVOICE":
        return { success: true, value: "INVOICE" };
      case "PAYPAL":
        return { success: true, value: "PAYPAL" };
      case "LSV":
        return { success: true, value: "LSV" };
      case "MILES":
        return { success: true, value: "MILES" };
      case "ALIPAY":
        return { success: true, value: "ALIPAY" };
      case "IOS":
        return { success: true, value: "IOS" };
      case "ANDROID":
        return { success: true, value: "ANDROID" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// Person
export function serialize_Person(obj: Person): string {
  const serialized = ((value: { firstName: string; gender: Gender; lastName: string | null; middleName: string | null }): unknown => {
    return {
      firstName: ((value: string): unknown => {
        return value;
      })(value["firstName"]),
      gender: ((value: "FEMALE" | "MALE" | "UNKNOWN" | "CUSTOM"): unknown => {
        return value;
      })(value["gender"]),
      lastName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lastName"]),
      middleName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["middleName"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_Person(str: string): Person | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          firstName: string;
          gender: Gender;
          lastName: string | null;
          middleName: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "firstName")) {
      return { success: false };
    }
    const parsed_firstName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["firstName"]);
    })();
    if (!parsed_firstName.success) {
      return { success: false };
    }
    if (!hasProp(value, "gender")) {
      return { success: false };
    }
    const parsed_gender = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Gender } => {
        return ((value: unknown): { success: false } | { success: true; value: "FEMALE" | "MALE" | "UNKNOWN" | "CUSTOM" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "FEMALE":
              return { success: true, value: "FEMALE" };
            case "MALE":
              return { success: true, value: "MALE" };
            case "UNKNOWN":
              return { success: true, value: "UNKNOWN" };
            case "CUSTOM":
              return { success: true, value: "CUSTOM" };
            default:
              return { success: false };
          }
        })(value);
      })(value["gender"]);
    })();
    if (!parsed_gender.success) {
      return { success: false };
    }
    if (!hasProp(value, "lastName")) {
      return { success: false };
    }
    const parsed_lastName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lastName"]);
    })();
    if (!parsed_lastName.success) {
      return { success: false };
    }
    if (!hasProp(value, "middleName")) {
      return { success: false };
    }
    const parsed_middleName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["middleName"]);
    })();
    if (!parsed_middleName.success) {
      return { success: false };
    }
    return { success: true, value: { firstName: parsed_firstName.value, gender: parsed_gender.value, lastName: parsed_lastName.value, middleName: parsed_middleName.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PortalType
export function serialize_PortalType(obj: PortalType): string {
  const serialized = ((value: "b2bAsp" | "leadPortals" | "freeDownloads"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PortalType(str: string): PortalType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "b2bAsp" | "leadPortals" | "freeDownloads" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "b2bAsp":
        return { success: true, value: "b2bAsp" };
      case "leadPortals":
        return { success: true, value: "leadPortals" };
      case "freeDownloads":
        return { success: true, value: "freeDownloads" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PortletContainerAdminEditForm
export function serialize_PortletContainerAdminEditForm(obj: PortletContainerAdminEditForm): string {
  const serialized = ((value: { portletContainerId: number; portletIds: number[]; showDefaults: boolean; status: PortletContainerStatus }): unknown => {
    return {
      portletContainerId: ((value: number): unknown => {
        return value;
      })(value["portletContainerId"]),
      portletIds: ((value: number[]): unknown => {
        return value.map((value: number): unknown => {
          return value;
        });
      })(value["portletIds"]),
      showDefaults: ((value: boolean): unknown => {
        return value;
      })(value["showDefaults"]),
      status: ((value: "DRAFT" | "LIVE"): unknown => {
        return value;
      })(value["status"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PortletContainerAdminEditForm(str: string): PortletContainerAdminEditForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          portletContainerId: number;
          portletIds: number[];
          showDefaults: boolean;
          status: PortletContainerStatus;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "portletContainerId")) {
      return { success: false };
    }
    const parsed_portletContainerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["portletContainerId"]);
    })();
    if (!parsed_portletContainerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "portletIds")) {
      return { success: false };
    }
    const parsed_portletIds = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
          if (typeof value === "number") {
            return { success: true, value: value };
          }
          return { success: false };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["portletIds"]);
    })();
    if (!parsed_portletIds.success) {
      return { success: false };
    }
    if (!hasProp(value, "showDefaults")) {
      return { success: false };
    }
    const parsed_showDefaults = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["showDefaults"]);
    })();
    if (!parsed_showDefaults.success) {
      return { success: false };
    }
    if (!hasProp(value, "status")) {
      return { success: false };
    }
    const parsed_status = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PortletContainerStatus } => {
        return ((value: unknown): { success: false } | { success: true; value: "DRAFT" | "LIVE" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "DRAFT":
              return { success: true, value: "DRAFT" };
            case "LIVE":
              return { success: true, value: "LIVE" };
            default:
              return { success: false };
          }
        })(value);
      })(value["status"]);
    })();
    if (!parsed_status.success) {
      return { success: false };
    }
    return { success: true, value: { portletContainerId: parsed_portletContainerId.value, portletIds: parsed_portletIds.value, showDefaults: parsed_showDefaults.value, status: parsed_status.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PortletContainerAdminEditView
export function serialize_PortletContainerAdminEditView(obj: PortletContainerAdminEditView): string {
  const serialized = ((value: {
    customPortlets: PortletMetadata[];
    customized: boolean;
    defaultPortlets: PortletMetadata[];
    language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
    portletContainerId: number;
    showDefaults: boolean;
    status: PortletContainerStatus;
  }): unknown => {
    return {
      customPortlets: ((value: PortletMetadata[]): unknown => {
        return value.map(
          (value: {
            custom: boolean;
            defaultFilterAllLanguages: boolean;
            embedSortingFiltering: boolean;
            hideTitle: boolean;
            itemCount: number;
            itemLayout: ItemLayout;
            name: string;
            portletId: number;
            portletLayout: PortletLayout;
            subType: string | null;
            subtitle: string;
            title: string;
            type: PortletType;
          }): unknown => {
            return {
              custom: ((value: boolean): unknown => {
                return value;
              })(value["custom"]),
              defaultFilterAllLanguages: ((value: boolean): unknown => {
                return value;
              })(value["defaultFilterAllLanguages"]),
              embedSortingFiltering: ((value: boolean): unknown => {
                return value;
              })(value["embedSortingFiltering"]),
              hideTitle: ((value: boolean): unknown => {
                return value;
              })(value["hideTitle"]),
              itemCount: ((value: number): unknown => {
                return value;
              })(value["itemCount"]),
              itemLayout: ((value: "MINI" | "REGULAR"): unknown => {
                return value;
              })(value["itemLayout"]),
              name: ((value: string): unknown => {
                return value;
              })(value["name"]),
              portletId: ((value: number): unknown => {
                return value;
              })(value["portletId"]),
              portletLayout: ((value: "GRID" | "CAROUSEL"): unknown => {
                return value;
              })(value["portletLayout"]),
              subType: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["subType"]),
              subtitle: ((value: string): unknown => {
                return value;
              })(value["subtitle"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
              type: ((
                value:
                  | "IMAGETEXT"
                  | "SUMMARYLIST"
                  | "TRENDINGCHANNEL"
                  | "FEATUREDCHANNEL"
                  | "FOLLOWINGCHANNEL"
                  | "MYLIST"
                  | "PRIMARYTOPICCHANNEL"
                  | "CHANNELLIST"
                  | "ANNOTATIONLIST"
                  | "LGXPLIST"
                  | "SKETCHNOTESLGXP"
                  | "ACTIONABLELIST"
                  | "ACTIONABLELISTCUSTOM"
                  | "MYACTIONABLES"
                  | "FREESUMMARY"
                  | "SUMMARYLISTCUSTOM"
                  | "CUSTOMPAGELIST"
                  | "PROMOBOXCONFIG"
                  | "MYMIABS"
                  | "ASKGETABSTRACT"
                  | "CUSTOMIZEDMIABS",
              ): unknown => {
                return value;
              })(value["type"]),
            };
          },
        );
      })(value["customPortlets"]),
      customized: ((value: boolean): unknown => {
        return value;
      })(value["customized"]),
      defaultPortlets: ((value: PortletMetadata[]): unknown => {
        return value.map(
          (value: {
            custom: boolean;
            defaultFilterAllLanguages: boolean;
            embedSortingFiltering: boolean;
            hideTitle: boolean;
            itemCount: number;
            itemLayout: ItemLayout;
            name: string;
            portletId: number;
            portletLayout: PortletLayout;
            subType: string | null;
            subtitle: string;
            title: string;
            type: PortletType;
          }): unknown => {
            return {
              custom: ((value: boolean): unknown => {
                return value;
              })(value["custom"]),
              defaultFilterAllLanguages: ((value: boolean): unknown => {
                return value;
              })(value["defaultFilterAllLanguages"]),
              embedSortingFiltering: ((value: boolean): unknown => {
                return value;
              })(value["embedSortingFiltering"]),
              hideTitle: ((value: boolean): unknown => {
                return value;
              })(value["hideTitle"]),
              itemCount: ((value: number): unknown => {
                return value;
              })(value["itemCount"]),
              itemLayout: ((value: "MINI" | "REGULAR"): unknown => {
                return value;
              })(value["itemLayout"]),
              name: ((value: string): unknown => {
                return value;
              })(value["name"]),
              portletId: ((value: number): unknown => {
                return value;
              })(value["portletId"]),
              portletLayout: ((value: "GRID" | "CAROUSEL"): unknown => {
                return value;
              })(value["portletLayout"]),
              subType: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["subType"]),
              subtitle: ((value: string): unknown => {
                return value;
              })(value["subtitle"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
              type: ((
                value:
                  | "IMAGETEXT"
                  | "SUMMARYLIST"
                  | "TRENDINGCHANNEL"
                  | "FEATUREDCHANNEL"
                  | "FOLLOWINGCHANNEL"
                  | "MYLIST"
                  | "PRIMARYTOPICCHANNEL"
                  | "CHANNELLIST"
                  | "ANNOTATIONLIST"
                  | "LGXPLIST"
                  | "SKETCHNOTESLGXP"
                  | "ACTIONABLELIST"
                  | "ACTIONABLELISTCUSTOM"
                  | "MYACTIONABLES"
                  | "FREESUMMARY"
                  | "SUMMARYLISTCUSTOM"
                  | "CUSTOMPAGELIST"
                  | "PROMOBOXCONFIG"
                  | "MYMIABS"
                  | "ASKGETABSTRACT"
                  | "CUSTOMIZEDMIABS",
              ): unknown => {
                return value;
              })(value["type"]),
            };
          },
        );
      })(value["defaultPortlets"]),
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      portletContainerId: ((value: number): unknown => {
        return value;
      })(value["portletContainerId"]),
      showDefaults: ((value: boolean): unknown => {
        return value;
      })(value["showDefaults"]),
      status: ((value: "DRAFT" | "LIVE"): unknown => {
        return value;
      })(value["status"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PortletContainerAdminEditView(str: string): PortletContainerAdminEditView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          customPortlets: PortletMetadata[];
          customized: boolean;
          defaultPortlets: PortletMetadata[];
          language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
          portletContainerId: number;
          showDefaults: boolean;
          status: PortletContainerStatus;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "customPortlets")) {
      return { success: false };
    }
    const parsed_customPortlets = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PortletMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: PortletMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  custom: boolean;
                  defaultFilterAllLanguages: boolean;
                  embedSortingFiltering: boolean;
                  hideTitle: boolean;
                  itemCount: number;
                  itemLayout: ItemLayout;
                  name: string;
                  portletId: number;
                  portletLayout: PortletLayout;
                  subType: string | null;
                  subtitle: string;
                  title: string;
                  type: PortletType;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "custom")) {
              return { success: false };
            }
            const parsed_custom = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["custom"]);
            })();
            if (!parsed_custom.success) {
              return { success: false };
            }
            if (!hasProp(value, "defaultFilterAllLanguages")) {
              return { success: false };
            }
            const parsed_defaultFilterAllLanguages = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["defaultFilterAllLanguages"]);
            })();
            if (!parsed_defaultFilterAllLanguages.success) {
              return { success: false };
            }
            if (!hasProp(value, "embedSortingFiltering")) {
              return { success: false };
            }
            const parsed_embedSortingFiltering = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["embedSortingFiltering"]);
            })();
            if (!parsed_embedSortingFiltering.success) {
              return { success: false };
            }
            if (!hasProp(value, "hideTitle")) {
              return { success: false };
            }
            const parsed_hideTitle = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["hideTitle"]);
            })();
            if (!parsed_hideTitle.success) {
              return { success: false };
            }
            if (!hasProp(value, "itemCount")) {
              return { success: false };
            }
            const parsed_itemCount = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["itemCount"]);
            })();
            if (!parsed_itemCount.success) {
              return { success: false };
            }
            if (!hasProp(value, "itemLayout")) {
              return { success: false };
            }
            const parsed_itemLayout = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ItemLayout } => {
                return ((value: unknown): { success: false } | { success: true; value: "MINI" | "REGULAR" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "MINI":
                      return { success: true, value: "MINI" };
                    case "REGULAR":
                      return { success: true, value: "REGULAR" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["itemLayout"]);
            })();
            if (!parsed_itemLayout.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "portletId")) {
              return { success: false };
            }
            const parsed_portletId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["portletId"]);
            })();
            if (!parsed_portletId.success) {
              return { success: false };
            }
            if (!hasProp(value, "portletLayout")) {
              return { success: false };
            }
            const parsed_portletLayout = (() => {
              return ((value: unknown): { success: false } | { success: true; value: PortletLayout } => {
                return ((value: unknown): { success: false } | { success: true; value: "GRID" | "CAROUSEL" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "GRID":
                      return { success: true, value: "GRID" };
                    case "CAROUSEL":
                      return { success: true, value: "CAROUSEL" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["portletLayout"]);
            })();
            if (!parsed_portletLayout.success) {
              return { success: false };
            }
            if (!hasProp(value, "subType")) {
              return { success: false };
            }
            const parsed_subType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["subType"]);
            })();
            if (!parsed_subType.success) {
              return { success: false };
            }
            if (!hasProp(value, "subtitle")) {
              return { success: false };
            }
            const parsed_subtitle = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["subtitle"]);
            })();
            if (!parsed_subtitle.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            if (!hasProp(value, "type")) {
              return { success: false };
            }
            const parsed_type = (() => {
              return ((value: unknown): { success: false } | { success: true; value: PortletType } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | "IMAGETEXT"
                        | "SUMMARYLIST"
                        | "TRENDINGCHANNEL"
                        | "FEATUREDCHANNEL"
                        | "FOLLOWINGCHANNEL"
                        | "MYLIST"
                        | "PRIMARYTOPICCHANNEL"
                        | "CHANNELLIST"
                        | "ANNOTATIONLIST"
                        | "LGXPLIST"
                        | "SKETCHNOTESLGXP"
                        | "ACTIONABLELIST"
                        | "ACTIONABLELISTCUSTOM"
                        | "MYACTIONABLES"
                        | "FREESUMMARY"
                        | "SUMMARYLISTCUSTOM"
                        | "CUSTOMPAGELIST"
                        | "PROMOBOXCONFIG"
                        | "MYMIABS"
                        | "ASKGETABSTRACT"
                        | "CUSTOMIZEDMIABS";
                    } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "IMAGETEXT":
                      return { success: true, value: "IMAGETEXT" };
                    case "SUMMARYLIST":
                      return { success: true, value: "SUMMARYLIST" };
                    case "TRENDINGCHANNEL":
                      return { success: true, value: "TRENDINGCHANNEL" };
                    case "FEATUREDCHANNEL":
                      return { success: true, value: "FEATUREDCHANNEL" };
                    case "FOLLOWINGCHANNEL":
                      return { success: true, value: "FOLLOWINGCHANNEL" };
                    case "MYLIST":
                      return { success: true, value: "MYLIST" };
                    case "PRIMARYTOPICCHANNEL":
                      return { success: true, value: "PRIMARYTOPICCHANNEL" };
                    case "CHANNELLIST":
                      return { success: true, value: "CHANNELLIST" };
                    case "ANNOTATIONLIST":
                      return { success: true, value: "ANNOTATIONLIST" };
                    case "LGXPLIST":
                      return { success: true, value: "LGXPLIST" };
                    case "SKETCHNOTESLGXP":
                      return { success: true, value: "SKETCHNOTESLGXP" };
                    case "ACTIONABLELIST":
                      return { success: true, value: "ACTIONABLELIST" };
                    case "ACTIONABLELISTCUSTOM":
                      return { success: true, value: "ACTIONABLELISTCUSTOM" };
                    case "MYACTIONABLES":
                      return { success: true, value: "MYACTIONABLES" };
                    case "FREESUMMARY":
                      return { success: true, value: "FREESUMMARY" };
                    case "SUMMARYLISTCUSTOM":
                      return { success: true, value: "SUMMARYLISTCUSTOM" };
                    case "CUSTOMPAGELIST":
                      return { success: true, value: "CUSTOMPAGELIST" };
                    case "PROMOBOXCONFIG":
                      return { success: true, value: "PROMOBOXCONFIG" };
                    case "MYMIABS":
                      return { success: true, value: "MYMIABS" };
                    case "ASKGETABSTRACT":
                      return { success: true, value: "ASKGETABSTRACT" };
                    case "CUSTOMIZEDMIABS":
                      return { success: true, value: "CUSTOMIZEDMIABS" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["type"]);
            })();
            if (!parsed_type.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                custom: parsed_custom.value,
                defaultFilterAllLanguages: parsed_defaultFilterAllLanguages.value,
                embedSortingFiltering: parsed_embedSortingFiltering.value,
                hideTitle: parsed_hideTitle.value,
                itemCount: parsed_itemCount.value,
                itemLayout: parsed_itemLayout.value,
                name: parsed_name.value,
                portletId: parsed_portletId.value,
                portletLayout: parsed_portletLayout.value,
                subType: parsed_subType.value,
                subtitle: parsed_subtitle.value,
                title: parsed_title.value,
                type: parsed_type.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["customPortlets"]);
    })();
    if (!parsed_customPortlets.success) {
      return { success: false };
    }
    if (!hasProp(value, "customized")) {
      return { success: false };
    }
    const parsed_customized = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["customized"]);
    })();
    if (!parsed_customized.success) {
      return { success: false };
    }
    if (!hasProp(value, "defaultPortlets")) {
      return { success: false };
    }
    const parsed_defaultPortlets = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PortletMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: PortletMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  custom: boolean;
                  defaultFilterAllLanguages: boolean;
                  embedSortingFiltering: boolean;
                  hideTitle: boolean;
                  itemCount: number;
                  itemLayout: ItemLayout;
                  name: string;
                  portletId: number;
                  portletLayout: PortletLayout;
                  subType: string | null;
                  subtitle: string;
                  title: string;
                  type: PortletType;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "custom")) {
              return { success: false };
            }
            const parsed_custom = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["custom"]);
            })();
            if (!parsed_custom.success) {
              return { success: false };
            }
            if (!hasProp(value, "defaultFilterAllLanguages")) {
              return { success: false };
            }
            const parsed_defaultFilterAllLanguages = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["defaultFilterAllLanguages"]);
            })();
            if (!parsed_defaultFilterAllLanguages.success) {
              return { success: false };
            }
            if (!hasProp(value, "embedSortingFiltering")) {
              return { success: false };
            }
            const parsed_embedSortingFiltering = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["embedSortingFiltering"]);
            })();
            if (!parsed_embedSortingFiltering.success) {
              return { success: false };
            }
            if (!hasProp(value, "hideTitle")) {
              return { success: false };
            }
            const parsed_hideTitle = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["hideTitle"]);
            })();
            if (!parsed_hideTitle.success) {
              return { success: false };
            }
            if (!hasProp(value, "itemCount")) {
              return { success: false };
            }
            const parsed_itemCount = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["itemCount"]);
            })();
            if (!parsed_itemCount.success) {
              return { success: false };
            }
            if (!hasProp(value, "itemLayout")) {
              return { success: false };
            }
            const parsed_itemLayout = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ItemLayout } => {
                return ((value: unknown): { success: false } | { success: true; value: "MINI" | "REGULAR" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "MINI":
                      return { success: true, value: "MINI" };
                    case "REGULAR":
                      return { success: true, value: "REGULAR" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["itemLayout"]);
            })();
            if (!parsed_itemLayout.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "portletId")) {
              return { success: false };
            }
            const parsed_portletId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["portletId"]);
            })();
            if (!parsed_portletId.success) {
              return { success: false };
            }
            if (!hasProp(value, "portletLayout")) {
              return { success: false };
            }
            const parsed_portletLayout = (() => {
              return ((value: unknown): { success: false } | { success: true; value: PortletLayout } => {
                return ((value: unknown): { success: false } | { success: true; value: "GRID" | "CAROUSEL" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "GRID":
                      return { success: true, value: "GRID" };
                    case "CAROUSEL":
                      return { success: true, value: "CAROUSEL" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["portletLayout"]);
            })();
            if (!parsed_portletLayout.success) {
              return { success: false };
            }
            if (!hasProp(value, "subType")) {
              return { success: false };
            }
            const parsed_subType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["subType"]);
            })();
            if (!parsed_subType.success) {
              return { success: false };
            }
            if (!hasProp(value, "subtitle")) {
              return { success: false };
            }
            const parsed_subtitle = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["subtitle"]);
            })();
            if (!parsed_subtitle.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            if (!hasProp(value, "type")) {
              return { success: false };
            }
            const parsed_type = (() => {
              return ((value: unknown): { success: false } | { success: true; value: PortletType } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | "IMAGETEXT"
                        | "SUMMARYLIST"
                        | "TRENDINGCHANNEL"
                        | "FEATUREDCHANNEL"
                        | "FOLLOWINGCHANNEL"
                        | "MYLIST"
                        | "PRIMARYTOPICCHANNEL"
                        | "CHANNELLIST"
                        | "ANNOTATIONLIST"
                        | "LGXPLIST"
                        | "SKETCHNOTESLGXP"
                        | "ACTIONABLELIST"
                        | "ACTIONABLELISTCUSTOM"
                        | "MYACTIONABLES"
                        | "FREESUMMARY"
                        | "SUMMARYLISTCUSTOM"
                        | "CUSTOMPAGELIST"
                        | "PROMOBOXCONFIG"
                        | "MYMIABS"
                        | "ASKGETABSTRACT"
                        | "CUSTOMIZEDMIABS";
                    } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "IMAGETEXT":
                      return { success: true, value: "IMAGETEXT" };
                    case "SUMMARYLIST":
                      return { success: true, value: "SUMMARYLIST" };
                    case "TRENDINGCHANNEL":
                      return { success: true, value: "TRENDINGCHANNEL" };
                    case "FEATUREDCHANNEL":
                      return { success: true, value: "FEATUREDCHANNEL" };
                    case "FOLLOWINGCHANNEL":
                      return { success: true, value: "FOLLOWINGCHANNEL" };
                    case "MYLIST":
                      return { success: true, value: "MYLIST" };
                    case "PRIMARYTOPICCHANNEL":
                      return { success: true, value: "PRIMARYTOPICCHANNEL" };
                    case "CHANNELLIST":
                      return { success: true, value: "CHANNELLIST" };
                    case "ANNOTATIONLIST":
                      return { success: true, value: "ANNOTATIONLIST" };
                    case "LGXPLIST":
                      return { success: true, value: "LGXPLIST" };
                    case "SKETCHNOTESLGXP":
                      return { success: true, value: "SKETCHNOTESLGXP" };
                    case "ACTIONABLELIST":
                      return { success: true, value: "ACTIONABLELIST" };
                    case "ACTIONABLELISTCUSTOM":
                      return { success: true, value: "ACTIONABLELISTCUSTOM" };
                    case "MYACTIONABLES":
                      return { success: true, value: "MYACTIONABLES" };
                    case "FREESUMMARY":
                      return { success: true, value: "FREESUMMARY" };
                    case "SUMMARYLISTCUSTOM":
                      return { success: true, value: "SUMMARYLISTCUSTOM" };
                    case "CUSTOMPAGELIST":
                      return { success: true, value: "CUSTOMPAGELIST" };
                    case "PROMOBOXCONFIG":
                      return { success: true, value: "PROMOBOXCONFIG" };
                    case "MYMIABS":
                      return { success: true, value: "MYMIABS" };
                    case "ASKGETABSTRACT":
                      return { success: true, value: "ASKGETABSTRACT" };
                    case "CUSTOMIZEDMIABS":
                      return { success: true, value: "CUSTOMIZEDMIABS" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["type"]);
            })();
            if (!parsed_type.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                custom: parsed_custom.value,
                defaultFilterAllLanguages: parsed_defaultFilterAllLanguages.value,
                embedSortingFiltering: parsed_embedSortingFiltering.value,
                hideTitle: parsed_hideTitle.value,
                itemCount: parsed_itemCount.value,
                itemLayout: parsed_itemLayout.value,
                name: parsed_name.value,
                portletId: parsed_portletId.value,
                portletLayout: parsed_portletLayout.value,
                subType: parsed_subType.value,
                subtitle: parsed_subtitle.value,
                title: parsed_title.value,
                type: parsed_type.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["defaultPortlets"]);
    })();
    if (!parsed_defaultPortlets.success) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "en":
            return { success: true, value: "en" };
          case "de":
            return { success: true, value: "de" };
          case "es":
            return { success: true, value: "es" };
          case "ru":
            return { success: true, value: "ru" };
          case "zh":
            return { success: true, value: "zh" };
          case "pt":
            return { success: true, value: "pt" };
          case "fr":
            return { success: true, value: "fr" };
          default:
            return { success: false };
        }
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "portletContainerId")) {
      return { success: false };
    }
    const parsed_portletContainerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["portletContainerId"]);
    })();
    if (!parsed_portletContainerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "showDefaults")) {
      return { success: false };
    }
    const parsed_showDefaults = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["showDefaults"]);
    })();
    if (!parsed_showDefaults.success) {
      return { success: false };
    }
    if (!hasProp(value, "status")) {
      return { success: false };
    }
    const parsed_status = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PortletContainerStatus } => {
        return ((value: unknown): { success: false } | { success: true; value: "DRAFT" | "LIVE" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "DRAFT":
              return { success: true, value: "DRAFT" };
            case "LIVE":
              return { success: true, value: "LIVE" };
            default:
              return { success: false };
          }
        })(value);
      })(value["status"]);
    })();
    if (!parsed_status.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        customPortlets: parsed_customPortlets.value,
        customized: parsed_customized.value,
        defaultPortlets: parsed_defaultPortlets.value,
        language: parsed_language.value,
        portletContainerId: parsed_portletContainerId.value,
        showDefaults: parsed_showDefaults.value,
        status: parsed_status.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PortletContainerStatus
export function serialize_PortletContainerStatus(obj: PortletContainerStatus): string {
  const serialized = ((value: "DRAFT" | "LIVE"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PortletContainerStatus(str: string): PortletContainerStatus | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "DRAFT" | "LIVE" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "DRAFT":
        return { success: true, value: "DRAFT" };
      case "LIVE":
        return { success: true, value: "LIVE" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PortletLayout
export function serialize_PortletLayout(obj: PortletLayout): string {
  const serialized = ((value: "GRID" | "CAROUSEL"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PortletLayout(str: string): PortletLayout | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "GRID" | "CAROUSEL" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "GRID":
        return { success: true, value: "GRID" };
      case "CAROUSEL":
        return { success: true, value: "CAROUSEL" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PortletMetadata
export function serialize_PortletMetadata(obj: PortletMetadata): string {
  const serialized = ((value: {
    custom: boolean;
    defaultFilterAllLanguages: boolean;
    embedSortingFiltering: boolean;
    hideTitle: boolean;
    itemCount: number;
    itemLayout: ItemLayout;
    name: string;
    portletId: number;
    portletLayout: PortletLayout;
    subType: string | null;
    subtitle: string;
    title: string;
    type: PortletType;
  }): unknown => {
    return {
      custom: ((value: boolean): unknown => {
        return value;
      })(value["custom"]),
      defaultFilterAllLanguages: ((value: boolean): unknown => {
        return value;
      })(value["defaultFilterAllLanguages"]),
      embedSortingFiltering: ((value: boolean): unknown => {
        return value;
      })(value["embedSortingFiltering"]),
      hideTitle: ((value: boolean): unknown => {
        return value;
      })(value["hideTitle"]),
      itemCount: ((value: number): unknown => {
        return value;
      })(value["itemCount"]),
      itemLayout: ((value: "MINI" | "REGULAR"): unknown => {
        return value;
      })(value["itemLayout"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      portletId: ((value: number): unknown => {
        return value;
      })(value["portletId"]),
      portletLayout: ((value: "GRID" | "CAROUSEL"): unknown => {
        return value;
      })(value["portletLayout"]),
      subType: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["subType"]),
      subtitle: ((value: string): unknown => {
        return value;
      })(value["subtitle"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
      type: ((
        value:
          | "IMAGETEXT"
          | "SUMMARYLIST"
          | "TRENDINGCHANNEL"
          | "FEATUREDCHANNEL"
          | "FOLLOWINGCHANNEL"
          | "MYLIST"
          | "PRIMARYTOPICCHANNEL"
          | "CHANNELLIST"
          | "ANNOTATIONLIST"
          | "LGXPLIST"
          | "SKETCHNOTESLGXP"
          | "ACTIONABLELIST"
          | "ACTIONABLELISTCUSTOM"
          | "MYACTIONABLES"
          | "FREESUMMARY"
          | "SUMMARYLISTCUSTOM"
          | "CUSTOMPAGELIST"
          | "PROMOBOXCONFIG"
          | "MYMIABS"
          | "ASKGETABSTRACT"
          | "CUSTOMIZEDMIABS",
      ): unknown => {
        return value;
      })(value["type"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PortletMetadata(str: string): PortletMetadata | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          custom: boolean;
          defaultFilterAllLanguages: boolean;
          embedSortingFiltering: boolean;
          hideTitle: boolean;
          itemCount: number;
          itemLayout: ItemLayout;
          name: string;
          portletId: number;
          portletLayout: PortletLayout;
          subType: string | null;
          subtitle: string;
          title: string;
          type: PortletType;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "custom")) {
      return { success: false };
    }
    const parsed_custom = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["custom"]);
    })();
    if (!parsed_custom.success) {
      return { success: false };
    }
    if (!hasProp(value, "defaultFilterAllLanguages")) {
      return { success: false };
    }
    const parsed_defaultFilterAllLanguages = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["defaultFilterAllLanguages"]);
    })();
    if (!parsed_defaultFilterAllLanguages.success) {
      return { success: false };
    }
    if (!hasProp(value, "embedSortingFiltering")) {
      return { success: false };
    }
    const parsed_embedSortingFiltering = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["embedSortingFiltering"]);
    })();
    if (!parsed_embedSortingFiltering.success) {
      return { success: false };
    }
    if (!hasProp(value, "hideTitle")) {
      return { success: false };
    }
    const parsed_hideTitle = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["hideTitle"]);
    })();
    if (!parsed_hideTitle.success) {
      return { success: false };
    }
    if (!hasProp(value, "itemCount")) {
      return { success: false };
    }
    const parsed_itemCount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["itemCount"]);
    })();
    if (!parsed_itemCount.success) {
      return { success: false };
    }
    if (!hasProp(value, "itemLayout")) {
      return { success: false };
    }
    const parsed_itemLayout = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ItemLayout } => {
        return ((value: unknown): { success: false } | { success: true; value: "MINI" | "REGULAR" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "MINI":
              return { success: true, value: "MINI" };
            case "REGULAR":
              return { success: true, value: "REGULAR" };
            default:
              return { success: false };
          }
        })(value);
      })(value["itemLayout"]);
    })();
    if (!parsed_itemLayout.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "portletId")) {
      return { success: false };
    }
    const parsed_portletId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["portletId"]);
    })();
    if (!parsed_portletId.success) {
      return { success: false };
    }
    if (!hasProp(value, "portletLayout")) {
      return { success: false };
    }
    const parsed_portletLayout = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PortletLayout } => {
        return ((value: unknown): { success: false } | { success: true; value: "GRID" | "CAROUSEL" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "GRID":
              return { success: true, value: "GRID" };
            case "CAROUSEL":
              return { success: true, value: "CAROUSEL" };
            default:
              return { success: false };
          }
        })(value);
      })(value["portletLayout"]);
    })();
    if (!parsed_portletLayout.success) {
      return { success: false };
    }
    if (!hasProp(value, "subType")) {
      return { success: false };
    }
    const parsed_subType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["subType"]);
    })();
    if (!parsed_subType.success) {
      return { success: false };
    }
    if (!hasProp(value, "subtitle")) {
      return { success: false };
    }
    const parsed_subtitle = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["subtitle"]);
    })();
    if (!parsed_subtitle.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    if (!hasProp(value, "type")) {
      return { success: false };
    }
    const parsed_type = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PortletType } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value:
                | "IMAGETEXT"
                | "SUMMARYLIST"
                | "TRENDINGCHANNEL"
                | "FEATUREDCHANNEL"
                | "FOLLOWINGCHANNEL"
                | "MYLIST"
                | "PRIMARYTOPICCHANNEL"
                | "CHANNELLIST"
                | "ANNOTATIONLIST"
                | "LGXPLIST"
                | "SKETCHNOTESLGXP"
                | "ACTIONABLELIST"
                | "ACTIONABLELISTCUSTOM"
                | "MYACTIONABLES"
                | "FREESUMMARY"
                | "SUMMARYLISTCUSTOM"
                | "CUSTOMPAGELIST"
                | "PROMOBOXCONFIG"
                | "MYMIABS"
                | "ASKGETABSTRACT"
                | "CUSTOMIZEDMIABS";
            } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "IMAGETEXT":
              return { success: true, value: "IMAGETEXT" };
            case "SUMMARYLIST":
              return { success: true, value: "SUMMARYLIST" };
            case "TRENDINGCHANNEL":
              return { success: true, value: "TRENDINGCHANNEL" };
            case "FEATUREDCHANNEL":
              return { success: true, value: "FEATUREDCHANNEL" };
            case "FOLLOWINGCHANNEL":
              return { success: true, value: "FOLLOWINGCHANNEL" };
            case "MYLIST":
              return { success: true, value: "MYLIST" };
            case "PRIMARYTOPICCHANNEL":
              return { success: true, value: "PRIMARYTOPICCHANNEL" };
            case "CHANNELLIST":
              return { success: true, value: "CHANNELLIST" };
            case "ANNOTATIONLIST":
              return { success: true, value: "ANNOTATIONLIST" };
            case "LGXPLIST":
              return { success: true, value: "LGXPLIST" };
            case "SKETCHNOTESLGXP":
              return { success: true, value: "SKETCHNOTESLGXP" };
            case "ACTIONABLELIST":
              return { success: true, value: "ACTIONABLELIST" };
            case "ACTIONABLELISTCUSTOM":
              return { success: true, value: "ACTIONABLELISTCUSTOM" };
            case "MYACTIONABLES":
              return { success: true, value: "MYACTIONABLES" };
            case "FREESUMMARY":
              return { success: true, value: "FREESUMMARY" };
            case "SUMMARYLISTCUSTOM":
              return { success: true, value: "SUMMARYLISTCUSTOM" };
            case "CUSTOMPAGELIST":
              return { success: true, value: "CUSTOMPAGELIST" };
            case "PROMOBOXCONFIG":
              return { success: true, value: "PROMOBOXCONFIG" };
            case "MYMIABS":
              return { success: true, value: "MYMIABS" };
            case "ASKGETABSTRACT":
              return { success: true, value: "ASKGETABSTRACT" };
            case "CUSTOMIZEDMIABS":
              return { success: true, value: "CUSTOMIZEDMIABS" };
            default:
              return { success: false };
          }
        })(value);
      })(value["type"]);
    })();
    if (!parsed_type.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        custom: parsed_custom.value,
        defaultFilterAllLanguages: parsed_defaultFilterAllLanguages.value,
        embedSortingFiltering: parsed_embedSortingFiltering.value,
        hideTitle: parsed_hideTitle.value,
        itemCount: parsed_itemCount.value,
        itemLayout: parsed_itemLayout.value,
        name: parsed_name.value,
        portletId: parsed_portletId.value,
        portletLayout: parsed_portletLayout.value,
        subType: parsed_subType.value,
        subtitle: parsed_subtitle.value,
        title: parsed_title.value,
        type: parsed_type.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PortletType
export function serialize_PortletType(obj: PortletType): string {
  const serialized = ((
    value:
      | "IMAGETEXT"
      | "SUMMARYLIST"
      | "TRENDINGCHANNEL"
      | "FEATUREDCHANNEL"
      | "FOLLOWINGCHANNEL"
      | "MYLIST"
      | "PRIMARYTOPICCHANNEL"
      | "CHANNELLIST"
      | "ANNOTATIONLIST"
      | "LGXPLIST"
      | "SKETCHNOTESLGXP"
      | "ACTIONABLELIST"
      | "ACTIONABLELISTCUSTOM"
      | "MYACTIONABLES"
      | "FREESUMMARY"
      | "SUMMARYLISTCUSTOM"
      | "CUSTOMPAGELIST"
      | "PROMOBOXCONFIG"
      | "MYMIABS"
      | "ASKGETABSTRACT"
      | "CUSTOMIZEDMIABS",
  ): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PortletType(str: string): PortletType | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value:
          | "IMAGETEXT"
          | "SUMMARYLIST"
          | "TRENDINGCHANNEL"
          | "FEATUREDCHANNEL"
          | "FOLLOWINGCHANNEL"
          | "MYLIST"
          | "PRIMARYTOPICCHANNEL"
          | "CHANNELLIST"
          | "ANNOTATIONLIST"
          | "LGXPLIST"
          | "SKETCHNOTESLGXP"
          | "ACTIONABLELIST"
          | "ACTIONABLELISTCUSTOM"
          | "MYACTIONABLES"
          | "FREESUMMARY"
          | "SUMMARYLISTCUSTOM"
          | "CUSTOMPAGELIST"
          | "PROMOBOXCONFIG"
          | "MYMIABS"
          | "ASKGETABSTRACT"
          | "CUSTOMIZEDMIABS";
      } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "IMAGETEXT":
        return { success: true, value: "IMAGETEXT" };
      case "SUMMARYLIST":
        return { success: true, value: "SUMMARYLIST" };
      case "TRENDINGCHANNEL":
        return { success: true, value: "TRENDINGCHANNEL" };
      case "FEATUREDCHANNEL":
        return { success: true, value: "FEATUREDCHANNEL" };
      case "FOLLOWINGCHANNEL":
        return { success: true, value: "FOLLOWINGCHANNEL" };
      case "MYLIST":
        return { success: true, value: "MYLIST" };
      case "PRIMARYTOPICCHANNEL":
        return { success: true, value: "PRIMARYTOPICCHANNEL" };
      case "CHANNELLIST":
        return { success: true, value: "CHANNELLIST" };
      case "ANNOTATIONLIST":
        return { success: true, value: "ANNOTATIONLIST" };
      case "LGXPLIST":
        return { success: true, value: "LGXPLIST" };
      case "SKETCHNOTESLGXP":
        return { success: true, value: "SKETCHNOTESLGXP" };
      case "ACTIONABLELIST":
        return { success: true, value: "ACTIONABLELIST" };
      case "ACTIONABLELISTCUSTOM":
        return { success: true, value: "ACTIONABLELISTCUSTOM" };
      case "MYACTIONABLES":
        return { success: true, value: "MYACTIONABLES" };
      case "FREESUMMARY":
        return { success: true, value: "FREESUMMARY" };
      case "SUMMARYLISTCUSTOM":
        return { success: true, value: "SUMMARYLISTCUSTOM" };
      case "CUSTOMPAGELIST":
        return { success: true, value: "CUSTOMPAGELIST" };
      case "PROMOBOXCONFIG":
        return { success: true, value: "PROMOBOXCONFIG" };
      case "MYMIABS":
        return { success: true, value: "MYMIABS" };
      case "ASKGETABSTRACT":
        return { success: true, value: "ASKGETABSTRACT" };
      case "CUSTOMIZEDMIABS":
        return { success: true, value: "CUSTOMIZEDMIABS" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ProductAdminView
export function serialize_ProductAdminView(obj: ProductAdminView): string {
  const serialized = ((value: { code: string; productId: bigint }): unknown => {
    return {
      code: ((value: string): unknown => {
        return value;
      })(value["code"]),
      productId: ((value: bigint): unknown => {
        return value;
      })(value["productId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ProductAdminView(str: string): ProductAdminView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          code: string;
          productId: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "code")) {
      return { success: false };
    }
    const parsed_code = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["code"]);
    })();
    if (!parsed_code.success) {
      return { success: false };
    }
    if (!hasProp(value, "productId")) {
      return { success: false };
    }
    const parsed_productId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["productId"]);
    })();
    if (!parsed_productId.success) {
      return { success: false };
    }
    return { success: true, value: { code: parsed_code.value, productId: parsed_productId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PromoBoxConfigEditView
export function serialize_PromoBoxConfigEditView(obj: PromoBoxConfigEditView): string {
  const serialized = ((value: { customBoxes: PromoBoxEditView[]; defaultBoxes: PromoBoxEditView[]; defaultBoxesHidden: number[]; showDefaultBoxes: boolean }): unknown => {
    return {
      customBoxes: ((value: PromoBoxEditView[]): unknown => {
        return value.map(
          (value: {
            backgroundColor: string | null;
            brightTextColor: boolean;
            corporateId: number;
            designApproved: boolean;
            endsAt: { year: number; month: number; day: number } | null;
            excludedLanguages: Language[];
            imageUri: string;
            localizedDescription: { additionalProperties: Record<string, string> };
            localizedImageUris: { additionalProperties: Record<string, string> };
            localizedTitle: { additionalProperties: Record<string, string> };
            localizedWebLinks: { additionalProperties: Record<string, string> };
            loggedIn: boolean;
            loggedOut: boolean;
            mobile: boolean;
            name: string;
            promoBoxId: number;
            requiredFeatures: string[];
            startsAt: { year: number; month: number; day: number } | null;
            tag: PromoBoxTag;
            web: boolean;
            webLink: string;
          }): unknown => {
            return {
              backgroundColor: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["backgroundColor"]),
              brightTextColor: ((value: boolean): unknown => {
                return value;
              })(value["brightTextColor"]),
              corporateId: ((value: number): unknown => {
                return value;
              })(value["corporateId"]),
              designApproved: ((value: boolean): unknown => {
                return value;
              })(value["designApproved"]),
              endsAt: ((value: { year: number; month: number; day: number } | null): unknown => {
                if (value === null) {
                  return null;
                }
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["endsAt"]),
              excludedLanguages: ((value: Language[]): unknown => {
                return value.map((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                  return value;
                });
              })(value["excludedLanguages"]),
              imageUri: ((value: string): unknown => {
                return value;
              })(value["imageUri"]),
              localizedDescription: ((value: { additionalProperties: Record<string, string> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string): unknown => {
                          return value;
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["localizedDescription"]),
              localizedImageUris: ((value: { additionalProperties: Record<string, string> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string): unknown => {
                          return value;
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["localizedImageUris"]),
              localizedTitle: ((value: { additionalProperties: Record<string, string> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string): unknown => {
                          return value;
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["localizedTitle"]),
              localizedWebLinks: ((value: { additionalProperties: Record<string, string> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string): unknown => {
                          return value;
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["localizedWebLinks"]),
              loggedIn: ((value: boolean): unknown => {
                return value;
              })(value["loggedIn"]),
              loggedOut: ((value: boolean): unknown => {
                return value;
              })(value["loggedOut"]),
              mobile: ((value: boolean): unknown => {
                return value;
              })(value["mobile"]),
              name: ((value: string): unknown => {
                return value;
              })(value["name"]),
              promoBoxId: ((value: number): unknown => {
                return value;
              })(value["promoBoxId"]),
              requiredFeatures: ((value: string[]): unknown => {
                return value.map((value: string): unknown => {
                  return value;
                });
              })(value["requiredFeatures"]),
              startsAt: ((value: { year: number; month: number; day: number } | null): unknown => {
                if (value === null) {
                  return null;
                }
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["startsAt"]),
              tag: ((
                value:
                  | "UNDEFINED"
                  | "EXPERT_WEBINAR"
                  | "PRO_TALK"
                  | "FEATURED"
                  | "FEATURED_SUMMARY"
                  | "FEATURED_ACTIONABLE"
                  | "FEATURED_TOPIC"
                  | "EMERGING_TREND"
                  | "INTERNATIONAL_DAY"
                  | "LEARNING_AT_WORK_WEEK"
                  | "Q_A"
                  | "VALUES_AND_CAPABILITIES"
                  | "RECOMMENDED_READS"
                  | "INTERNAL_THOUGHT_LEADER"
                  | "READING_CALENDAR"
                  | "NEW"
                  | "SPOTLIGHT"
                  | "PROMOTION"
                  | "DEAL"
                  | "NEWS"
                  | "CAMPAIGN"
                  | "BOOK_AWARD",
              ): unknown => {
                return value;
              })(value["tag"]),
              web: ((value: boolean): unknown => {
                return value;
              })(value["web"]),
              webLink: ((value: string): unknown => {
                return value;
              })(value["webLink"]),
            };
          },
        );
      })(value["customBoxes"]),
      defaultBoxes: ((value: PromoBoxEditView[]): unknown => {
        return value.map(
          (value: {
            backgroundColor: string | null;
            brightTextColor: boolean;
            corporateId: number;
            designApproved: boolean;
            endsAt: { year: number; month: number; day: number } | null;
            excludedLanguages: Language[];
            imageUri: string;
            localizedDescription: { additionalProperties: Record<string, string> };
            localizedImageUris: { additionalProperties: Record<string, string> };
            localizedTitle: { additionalProperties: Record<string, string> };
            localizedWebLinks: { additionalProperties: Record<string, string> };
            loggedIn: boolean;
            loggedOut: boolean;
            mobile: boolean;
            name: string;
            promoBoxId: number;
            requiredFeatures: string[];
            startsAt: { year: number; month: number; day: number } | null;
            tag: PromoBoxTag;
            web: boolean;
            webLink: string;
          }): unknown => {
            return {
              backgroundColor: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["backgroundColor"]),
              brightTextColor: ((value: boolean): unknown => {
                return value;
              })(value["brightTextColor"]),
              corporateId: ((value: number): unknown => {
                return value;
              })(value["corporateId"]),
              designApproved: ((value: boolean): unknown => {
                return value;
              })(value["designApproved"]),
              endsAt: ((value: { year: number; month: number; day: number } | null): unknown => {
                if (value === null) {
                  return null;
                }
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["endsAt"]),
              excludedLanguages: ((value: Language[]): unknown => {
                return value.map((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                  return value;
                });
              })(value["excludedLanguages"]),
              imageUri: ((value: string): unknown => {
                return value;
              })(value["imageUri"]),
              localizedDescription: ((value: { additionalProperties: Record<string, string> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string): unknown => {
                          return value;
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["localizedDescription"]),
              localizedImageUris: ((value: { additionalProperties: Record<string, string> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string): unknown => {
                          return value;
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["localizedImageUris"]),
              localizedTitle: ((value: { additionalProperties: Record<string, string> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string): unknown => {
                          return value;
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["localizedTitle"]),
              localizedWebLinks: ((value: { additionalProperties: Record<string, string> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string): unknown => {
                          return value;
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["localizedWebLinks"]),
              loggedIn: ((value: boolean): unknown => {
                return value;
              })(value["loggedIn"]),
              loggedOut: ((value: boolean): unknown => {
                return value;
              })(value["loggedOut"]),
              mobile: ((value: boolean): unknown => {
                return value;
              })(value["mobile"]),
              name: ((value: string): unknown => {
                return value;
              })(value["name"]),
              promoBoxId: ((value: number): unknown => {
                return value;
              })(value["promoBoxId"]),
              requiredFeatures: ((value: string[]): unknown => {
                return value.map((value: string): unknown => {
                  return value;
                });
              })(value["requiredFeatures"]),
              startsAt: ((value: { year: number; month: number; day: number } | null): unknown => {
                if (value === null) {
                  return null;
                }
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["startsAt"]),
              tag: ((
                value:
                  | "UNDEFINED"
                  | "EXPERT_WEBINAR"
                  | "PRO_TALK"
                  | "FEATURED"
                  | "FEATURED_SUMMARY"
                  | "FEATURED_ACTIONABLE"
                  | "FEATURED_TOPIC"
                  | "EMERGING_TREND"
                  | "INTERNATIONAL_DAY"
                  | "LEARNING_AT_WORK_WEEK"
                  | "Q_A"
                  | "VALUES_AND_CAPABILITIES"
                  | "RECOMMENDED_READS"
                  | "INTERNAL_THOUGHT_LEADER"
                  | "READING_CALENDAR"
                  | "NEW"
                  | "SPOTLIGHT"
                  | "PROMOTION"
                  | "DEAL"
                  | "NEWS"
                  | "CAMPAIGN"
                  | "BOOK_AWARD",
              ): unknown => {
                return value;
              })(value["tag"]),
              web: ((value: boolean): unknown => {
                return value;
              })(value["web"]),
              webLink: ((value: string): unknown => {
                return value;
              })(value["webLink"]),
            };
          },
        );
      })(value["defaultBoxes"]),
      defaultBoxesHidden: ((value: number[]): unknown => {
        return value.map((value: number): unknown => {
          return value;
        });
      })(value["defaultBoxesHidden"]),
      showDefaultBoxes: ((value: boolean): unknown => {
        return value;
      })(value["showDefaultBoxes"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PromoBoxConfigEditView(str: string): PromoBoxConfigEditView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          customBoxes: PromoBoxEditView[];
          defaultBoxes: PromoBoxEditView[];
          defaultBoxesHidden: number[];
          showDefaultBoxes: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "customBoxes")) {
      return { success: false };
    }
    const parsed_customBoxes = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PromoBoxEditView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: PromoBoxEditView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  backgroundColor: string | null;
                  brightTextColor: boolean;
                  corporateId: number;
                  designApproved: boolean;
                  endsAt: { year: number; month: number; day: number } | null;
                  excludedLanguages: Language[];
                  imageUri: string;
                  localizedDescription: { additionalProperties: Record<string, string> };
                  localizedImageUris: { additionalProperties: Record<string, string> };
                  localizedTitle: { additionalProperties: Record<string, string> };
                  localizedWebLinks: { additionalProperties: Record<string, string> };
                  loggedIn: boolean;
                  loggedOut: boolean;
                  mobile: boolean;
                  name: string;
                  promoBoxId: number;
                  requiredFeatures: string[];
                  startsAt: { year: number; month: number; day: number } | null;
                  tag: PromoBoxTag;
                  web: boolean;
                  webLink: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "backgroundColor")) {
              return { success: false };
            }
            const parsed_backgroundColor = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["backgroundColor"]);
            })();
            if (!parsed_backgroundColor.success) {
              return { success: false };
            }
            if (!hasProp(value, "brightTextColor")) {
              return { success: false };
            }
            const parsed_brightTextColor = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["brightTextColor"]);
            })();
            if (!parsed_brightTextColor.success) {
              return { success: false };
            }
            if (!hasProp(value, "corporateId")) {
              return { success: false };
            }
            const parsed_corporateId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["corporateId"]);
            })();
            if (!parsed_corporateId.success) {
              return { success: false };
            }
            if (!hasProp(value, "designApproved")) {
              return { success: false };
            }
            const parsed_designApproved = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["designApproved"]);
            })();
            if (!parsed_designApproved.success) {
              return { success: false };
            }
            if (!hasProp(value, "endsAt")) {
              return { success: false };
            }
            const parsed_endsAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
              })(value["endsAt"]);
            })();
            if (!parsed_endsAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "excludedLanguages")) {
              return { success: false };
            }
            const parsed_excludedLanguages = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Language[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: Language } => {
                  return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    switch (value) {
                      case "en":
                        return { success: true, value: "en" };
                      case "de":
                        return { success: true, value: "de" };
                      case "es":
                        return { success: true, value: "es" };
                      case "ru":
                        return { success: true, value: "ru" };
                      case "zh":
                        return { success: true, value: "zh" };
                      case "pt":
                        return { success: true, value: "pt" };
                      case "fr":
                        return { success: true, value: "fr" };
                      default:
                        return { success: false };
                    }
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["excludedLanguages"]);
            })();
            if (!parsed_excludedLanguages.success) {
              return { success: false };
            }
            if (!hasProp(value, "imageUri")) {
              return { success: false };
            }
            const parsed_imageUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["imageUri"]);
            })();
            if (!parsed_imageUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "localizedDescription")) {
              return { success: false };
            }
            const parsed_localizedDescription = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
                  const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
                    return [
                      key,
                      ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value),
                    ];
                  });
                  if (parsed.find(([_, value]) => !value.success) !== undefined) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: Object.fromEntries(
                      parsed.map(([key, value]) => {
                        assert(value.success);
                        return [key, value.value];
                      }),
                    ),
                  };
                })();
                if (!parsed_additionalProperties.success) {
                  return { success: false };
                }
                return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
              })(value["localizedDescription"]);
            })();
            if (!parsed_localizedDescription.success) {
              return { success: false };
            }
            if (!hasProp(value, "localizedImageUris")) {
              return { success: false };
            }
            const parsed_localizedImageUris = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
                  const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
                    return [
                      key,
                      ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value),
                    ];
                  });
                  if (parsed.find(([_, value]) => !value.success) !== undefined) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: Object.fromEntries(
                      parsed.map(([key, value]) => {
                        assert(value.success);
                        return [key, value.value];
                      }),
                    ),
                  };
                })();
                if (!parsed_additionalProperties.success) {
                  return { success: false };
                }
                return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
              })(value["localizedImageUris"]);
            })();
            if (!parsed_localizedImageUris.success) {
              return { success: false };
            }
            if (!hasProp(value, "localizedTitle")) {
              return { success: false };
            }
            const parsed_localizedTitle = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
                  const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
                    return [
                      key,
                      ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value),
                    ];
                  });
                  if (parsed.find(([_, value]) => !value.success) !== undefined) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: Object.fromEntries(
                      parsed.map(([key, value]) => {
                        assert(value.success);
                        return [key, value.value];
                      }),
                    ),
                  };
                })();
                if (!parsed_additionalProperties.success) {
                  return { success: false };
                }
                return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
              })(value["localizedTitle"]);
            })();
            if (!parsed_localizedTitle.success) {
              return { success: false };
            }
            if (!hasProp(value, "localizedWebLinks")) {
              return { success: false };
            }
            const parsed_localizedWebLinks = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
                  const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
                    return [
                      key,
                      ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value),
                    ];
                  });
                  if (parsed.find(([_, value]) => !value.success) !== undefined) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: Object.fromEntries(
                      parsed.map(([key, value]) => {
                        assert(value.success);
                        return [key, value.value];
                      }),
                    ),
                  };
                })();
                if (!parsed_additionalProperties.success) {
                  return { success: false };
                }
                return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
              })(value["localizedWebLinks"]);
            })();
            if (!parsed_localizedWebLinks.success) {
              return { success: false };
            }
            if (!hasProp(value, "loggedIn")) {
              return { success: false };
            }
            const parsed_loggedIn = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["loggedIn"]);
            })();
            if (!parsed_loggedIn.success) {
              return { success: false };
            }
            if (!hasProp(value, "loggedOut")) {
              return { success: false };
            }
            const parsed_loggedOut = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["loggedOut"]);
            })();
            if (!parsed_loggedOut.success) {
              return { success: false };
            }
            if (!hasProp(value, "mobile")) {
              return { success: false };
            }
            const parsed_mobile = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["mobile"]);
            })();
            if (!parsed_mobile.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "promoBoxId")) {
              return { success: false };
            }
            const parsed_promoBoxId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["promoBoxId"]);
            })();
            if (!parsed_promoBoxId.success) {
              return { success: false };
            }
            if (!hasProp(value, "requiredFeatures")) {
              return { success: false };
            }
            const parsed_requiredFeatures = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["requiredFeatures"]);
            })();
            if (!parsed_requiredFeatures.success) {
              return { success: false };
            }
            if (!hasProp(value, "startsAt")) {
              return { success: false };
            }
            const parsed_startsAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
              })(value["startsAt"]);
            })();
            if (!parsed_startsAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "tag")) {
              return { success: false };
            }
            const parsed_tag = (() => {
              return ((value: unknown): { success: false } | { success: true; value: PromoBoxTag } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | "UNDEFINED"
                        | "EXPERT_WEBINAR"
                        | "PRO_TALK"
                        | "FEATURED"
                        | "FEATURED_SUMMARY"
                        | "FEATURED_ACTIONABLE"
                        | "FEATURED_TOPIC"
                        | "EMERGING_TREND"
                        | "INTERNATIONAL_DAY"
                        | "LEARNING_AT_WORK_WEEK"
                        | "Q_A"
                        | "VALUES_AND_CAPABILITIES"
                        | "RECOMMENDED_READS"
                        | "INTERNAL_THOUGHT_LEADER"
                        | "READING_CALENDAR"
                        | "NEW"
                        | "SPOTLIGHT"
                        | "PROMOTION"
                        | "DEAL"
                        | "NEWS"
                        | "CAMPAIGN"
                        | "BOOK_AWARD";
                    } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "UNDEFINED":
                      return { success: true, value: "UNDEFINED" };
                    case "EXPERT_WEBINAR":
                      return { success: true, value: "EXPERT_WEBINAR" };
                    case "PRO_TALK":
                      return { success: true, value: "PRO_TALK" };
                    case "FEATURED":
                      return { success: true, value: "FEATURED" };
                    case "FEATURED_SUMMARY":
                      return { success: true, value: "FEATURED_SUMMARY" };
                    case "FEATURED_ACTIONABLE":
                      return { success: true, value: "FEATURED_ACTIONABLE" };
                    case "FEATURED_TOPIC":
                      return { success: true, value: "FEATURED_TOPIC" };
                    case "EMERGING_TREND":
                      return { success: true, value: "EMERGING_TREND" };
                    case "INTERNATIONAL_DAY":
                      return { success: true, value: "INTERNATIONAL_DAY" };
                    case "LEARNING_AT_WORK_WEEK":
                      return { success: true, value: "LEARNING_AT_WORK_WEEK" };
                    case "Q_A":
                      return { success: true, value: "Q_A" };
                    case "VALUES_AND_CAPABILITIES":
                      return { success: true, value: "VALUES_AND_CAPABILITIES" };
                    case "RECOMMENDED_READS":
                      return { success: true, value: "RECOMMENDED_READS" };
                    case "INTERNAL_THOUGHT_LEADER":
                      return { success: true, value: "INTERNAL_THOUGHT_LEADER" };
                    case "READING_CALENDAR":
                      return { success: true, value: "READING_CALENDAR" };
                    case "NEW":
                      return { success: true, value: "NEW" };
                    case "SPOTLIGHT":
                      return { success: true, value: "SPOTLIGHT" };
                    case "PROMOTION":
                      return { success: true, value: "PROMOTION" };
                    case "DEAL":
                      return { success: true, value: "DEAL" };
                    case "NEWS":
                      return { success: true, value: "NEWS" };
                    case "CAMPAIGN":
                      return { success: true, value: "CAMPAIGN" };
                    case "BOOK_AWARD":
                      return { success: true, value: "BOOK_AWARD" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["tag"]);
            })();
            if (!parsed_tag.success) {
              return { success: false };
            }
            if (!hasProp(value, "web")) {
              return { success: false };
            }
            const parsed_web = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["web"]);
            })();
            if (!parsed_web.success) {
              return { success: false };
            }
            if (!hasProp(value, "webLink")) {
              return { success: false };
            }
            const parsed_webLink = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["webLink"]);
            })();
            if (!parsed_webLink.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                backgroundColor: parsed_backgroundColor.value,
                brightTextColor: parsed_brightTextColor.value,
                corporateId: parsed_corporateId.value,
                designApproved: parsed_designApproved.value,
                endsAt: parsed_endsAt.value,
                excludedLanguages: parsed_excludedLanguages.value,
                imageUri: parsed_imageUri.value,
                localizedDescription: parsed_localizedDescription.value,
                localizedImageUris: parsed_localizedImageUris.value,
                localizedTitle: parsed_localizedTitle.value,
                localizedWebLinks: parsed_localizedWebLinks.value,
                loggedIn: parsed_loggedIn.value,
                loggedOut: parsed_loggedOut.value,
                mobile: parsed_mobile.value,
                name: parsed_name.value,
                promoBoxId: parsed_promoBoxId.value,
                requiredFeatures: parsed_requiredFeatures.value,
                startsAt: parsed_startsAt.value,
                tag: parsed_tag.value,
                web: parsed_web.value,
                webLink: parsed_webLink.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["customBoxes"]);
    })();
    if (!parsed_customBoxes.success) {
      return { success: false };
    }
    if (!hasProp(value, "defaultBoxes")) {
      return { success: false };
    }
    const parsed_defaultBoxes = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PromoBoxEditView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: PromoBoxEditView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  backgroundColor: string | null;
                  brightTextColor: boolean;
                  corporateId: number;
                  designApproved: boolean;
                  endsAt: { year: number; month: number; day: number } | null;
                  excludedLanguages: Language[];
                  imageUri: string;
                  localizedDescription: { additionalProperties: Record<string, string> };
                  localizedImageUris: { additionalProperties: Record<string, string> };
                  localizedTitle: { additionalProperties: Record<string, string> };
                  localizedWebLinks: { additionalProperties: Record<string, string> };
                  loggedIn: boolean;
                  loggedOut: boolean;
                  mobile: boolean;
                  name: string;
                  promoBoxId: number;
                  requiredFeatures: string[];
                  startsAt: { year: number; month: number; day: number } | null;
                  tag: PromoBoxTag;
                  web: boolean;
                  webLink: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "backgroundColor")) {
              return { success: false };
            }
            const parsed_backgroundColor = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["backgroundColor"]);
            })();
            if (!parsed_backgroundColor.success) {
              return { success: false };
            }
            if (!hasProp(value, "brightTextColor")) {
              return { success: false };
            }
            const parsed_brightTextColor = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["brightTextColor"]);
            })();
            if (!parsed_brightTextColor.success) {
              return { success: false };
            }
            if (!hasProp(value, "corporateId")) {
              return { success: false };
            }
            const parsed_corporateId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["corporateId"]);
            })();
            if (!parsed_corporateId.success) {
              return { success: false };
            }
            if (!hasProp(value, "designApproved")) {
              return { success: false };
            }
            const parsed_designApproved = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["designApproved"]);
            })();
            if (!parsed_designApproved.success) {
              return { success: false };
            }
            if (!hasProp(value, "endsAt")) {
              return { success: false };
            }
            const parsed_endsAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
              })(value["endsAt"]);
            })();
            if (!parsed_endsAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "excludedLanguages")) {
              return { success: false };
            }
            const parsed_excludedLanguages = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Language[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: Language } => {
                  return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    switch (value) {
                      case "en":
                        return { success: true, value: "en" };
                      case "de":
                        return { success: true, value: "de" };
                      case "es":
                        return { success: true, value: "es" };
                      case "ru":
                        return { success: true, value: "ru" };
                      case "zh":
                        return { success: true, value: "zh" };
                      case "pt":
                        return { success: true, value: "pt" };
                      case "fr":
                        return { success: true, value: "fr" };
                      default:
                        return { success: false };
                    }
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["excludedLanguages"]);
            })();
            if (!parsed_excludedLanguages.success) {
              return { success: false };
            }
            if (!hasProp(value, "imageUri")) {
              return { success: false };
            }
            const parsed_imageUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["imageUri"]);
            })();
            if (!parsed_imageUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "localizedDescription")) {
              return { success: false };
            }
            const parsed_localizedDescription = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
                  const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
                    return [
                      key,
                      ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value),
                    ];
                  });
                  if (parsed.find(([_, value]) => !value.success) !== undefined) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: Object.fromEntries(
                      parsed.map(([key, value]) => {
                        assert(value.success);
                        return [key, value.value];
                      }),
                    ),
                  };
                })();
                if (!parsed_additionalProperties.success) {
                  return { success: false };
                }
                return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
              })(value["localizedDescription"]);
            })();
            if (!parsed_localizedDescription.success) {
              return { success: false };
            }
            if (!hasProp(value, "localizedImageUris")) {
              return { success: false };
            }
            const parsed_localizedImageUris = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
                  const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
                    return [
                      key,
                      ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value),
                    ];
                  });
                  if (parsed.find(([_, value]) => !value.success) !== undefined) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: Object.fromEntries(
                      parsed.map(([key, value]) => {
                        assert(value.success);
                        return [key, value.value];
                      }),
                    ),
                  };
                })();
                if (!parsed_additionalProperties.success) {
                  return { success: false };
                }
                return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
              })(value["localizedImageUris"]);
            })();
            if (!parsed_localizedImageUris.success) {
              return { success: false };
            }
            if (!hasProp(value, "localizedTitle")) {
              return { success: false };
            }
            const parsed_localizedTitle = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
                  const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
                    return [
                      key,
                      ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value),
                    ];
                  });
                  if (parsed.find(([_, value]) => !value.success) !== undefined) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: Object.fromEntries(
                      parsed.map(([key, value]) => {
                        assert(value.success);
                        return [key, value.value];
                      }),
                    ),
                  };
                })();
                if (!parsed_additionalProperties.success) {
                  return { success: false };
                }
                return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
              })(value["localizedTitle"]);
            })();
            if (!parsed_localizedTitle.success) {
              return { success: false };
            }
            if (!hasProp(value, "localizedWebLinks")) {
              return { success: false };
            }
            const parsed_localizedWebLinks = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
                  const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
                    return [
                      key,
                      ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value),
                    ];
                  });
                  if (parsed.find(([_, value]) => !value.success) !== undefined) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: Object.fromEntries(
                      parsed.map(([key, value]) => {
                        assert(value.success);
                        return [key, value.value];
                      }),
                    ),
                  };
                })();
                if (!parsed_additionalProperties.success) {
                  return { success: false };
                }
                return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
              })(value["localizedWebLinks"]);
            })();
            if (!parsed_localizedWebLinks.success) {
              return { success: false };
            }
            if (!hasProp(value, "loggedIn")) {
              return { success: false };
            }
            const parsed_loggedIn = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["loggedIn"]);
            })();
            if (!parsed_loggedIn.success) {
              return { success: false };
            }
            if (!hasProp(value, "loggedOut")) {
              return { success: false };
            }
            const parsed_loggedOut = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["loggedOut"]);
            })();
            if (!parsed_loggedOut.success) {
              return { success: false };
            }
            if (!hasProp(value, "mobile")) {
              return { success: false };
            }
            const parsed_mobile = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["mobile"]);
            })();
            if (!parsed_mobile.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "promoBoxId")) {
              return { success: false };
            }
            const parsed_promoBoxId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["promoBoxId"]);
            })();
            if (!parsed_promoBoxId.success) {
              return { success: false };
            }
            if (!hasProp(value, "requiredFeatures")) {
              return { success: false };
            }
            const parsed_requiredFeatures = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["requiredFeatures"]);
            })();
            if (!parsed_requiredFeatures.success) {
              return { success: false };
            }
            if (!hasProp(value, "startsAt")) {
              return { success: false };
            }
            const parsed_startsAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
              })(value["startsAt"]);
            })();
            if (!parsed_startsAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "tag")) {
              return { success: false };
            }
            const parsed_tag = (() => {
              return ((value: unknown): { success: false } | { success: true; value: PromoBoxTag } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | "UNDEFINED"
                        | "EXPERT_WEBINAR"
                        | "PRO_TALK"
                        | "FEATURED"
                        | "FEATURED_SUMMARY"
                        | "FEATURED_ACTIONABLE"
                        | "FEATURED_TOPIC"
                        | "EMERGING_TREND"
                        | "INTERNATIONAL_DAY"
                        | "LEARNING_AT_WORK_WEEK"
                        | "Q_A"
                        | "VALUES_AND_CAPABILITIES"
                        | "RECOMMENDED_READS"
                        | "INTERNAL_THOUGHT_LEADER"
                        | "READING_CALENDAR"
                        | "NEW"
                        | "SPOTLIGHT"
                        | "PROMOTION"
                        | "DEAL"
                        | "NEWS"
                        | "CAMPAIGN"
                        | "BOOK_AWARD";
                    } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "UNDEFINED":
                      return { success: true, value: "UNDEFINED" };
                    case "EXPERT_WEBINAR":
                      return { success: true, value: "EXPERT_WEBINAR" };
                    case "PRO_TALK":
                      return { success: true, value: "PRO_TALK" };
                    case "FEATURED":
                      return { success: true, value: "FEATURED" };
                    case "FEATURED_SUMMARY":
                      return { success: true, value: "FEATURED_SUMMARY" };
                    case "FEATURED_ACTIONABLE":
                      return { success: true, value: "FEATURED_ACTIONABLE" };
                    case "FEATURED_TOPIC":
                      return { success: true, value: "FEATURED_TOPIC" };
                    case "EMERGING_TREND":
                      return { success: true, value: "EMERGING_TREND" };
                    case "INTERNATIONAL_DAY":
                      return { success: true, value: "INTERNATIONAL_DAY" };
                    case "LEARNING_AT_WORK_WEEK":
                      return { success: true, value: "LEARNING_AT_WORK_WEEK" };
                    case "Q_A":
                      return { success: true, value: "Q_A" };
                    case "VALUES_AND_CAPABILITIES":
                      return { success: true, value: "VALUES_AND_CAPABILITIES" };
                    case "RECOMMENDED_READS":
                      return { success: true, value: "RECOMMENDED_READS" };
                    case "INTERNAL_THOUGHT_LEADER":
                      return { success: true, value: "INTERNAL_THOUGHT_LEADER" };
                    case "READING_CALENDAR":
                      return { success: true, value: "READING_CALENDAR" };
                    case "NEW":
                      return { success: true, value: "NEW" };
                    case "SPOTLIGHT":
                      return { success: true, value: "SPOTLIGHT" };
                    case "PROMOTION":
                      return { success: true, value: "PROMOTION" };
                    case "DEAL":
                      return { success: true, value: "DEAL" };
                    case "NEWS":
                      return { success: true, value: "NEWS" };
                    case "CAMPAIGN":
                      return { success: true, value: "CAMPAIGN" };
                    case "BOOK_AWARD":
                      return { success: true, value: "BOOK_AWARD" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["tag"]);
            })();
            if (!parsed_tag.success) {
              return { success: false };
            }
            if (!hasProp(value, "web")) {
              return { success: false };
            }
            const parsed_web = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["web"]);
            })();
            if (!parsed_web.success) {
              return { success: false };
            }
            if (!hasProp(value, "webLink")) {
              return { success: false };
            }
            const parsed_webLink = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["webLink"]);
            })();
            if (!parsed_webLink.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                backgroundColor: parsed_backgroundColor.value,
                brightTextColor: parsed_brightTextColor.value,
                corporateId: parsed_corporateId.value,
                designApproved: parsed_designApproved.value,
                endsAt: parsed_endsAt.value,
                excludedLanguages: parsed_excludedLanguages.value,
                imageUri: parsed_imageUri.value,
                localizedDescription: parsed_localizedDescription.value,
                localizedImageUris: parsed_localizedImageUris.value,
                localizedTitle: parsed_localizedTitle.value,
                localizedWebLinks: parsed_localizedWebLinks.value,
                loggedIn: parsed_loggedIn.value,
                loggedOut: parsed_loggedOut.value,
                mobile: parsed_mobile.value,
                name: parsed_name.value,
                promoBoxId: parsed_promoBoxId.value,
                requiredFeatures: parsed_requiredFeatures.value,
                startsAt: parsed_startsAt.value,
                tag: parsed_tag.value,
                web: parsed_web.value,
                webLink: parsed_webLink.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["defaultBoxes"]);
    })();
    if (!parsed_defaultBoxes.success) {
      return { success: false };
    }
    if (!hasProp(value, "defaultBoxesHidden")) {
      return { success: false };
    }
    const parsed_defaultBoxesHidden = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
          if (typeof value === "number") {
            return { success: true, value: value };
          }
          return { success: false };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["defaultBoxesHidden"]);
    })();
    if (!parsed_defaultBoxesHidden.success) {
      return { success: false };
    }
    if (!hasProp(value, "showDefaultBoxes")) {
      return { success: false };
    }
    const parsed_showDefaultBoxes = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["showDefaultBoxes"]);
    })();
    if (!parsed_showDefaultBoxes.success) {
      return { success: false };
    }
    return { success: true, value: { customBoxes: parsed_customBoxes.value, defaultBoxes: parsed_defaultBoxes.value, defaultBoxesHidden: parsed_defaultBoxesHidden.value, showDefaultBoxes: parsed_showDefaultBoxes.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PromoBoxConfigForm
export function serialize_PromoBoxConfigForm(obj: PromoBoxConfigForm): string {
  const serialized = ((value: { items: PromoBoxConfigItemForm[]; itemsHidden: number[]; showDefaults: boolean }): unknown => {
    return {
      items: ((value: PromoBoxConfigItemForm[]): unknown => {
        return value.map((value: { endsAt: { year: number; month: number; day: number } | null; promoBoxId: number; startsAt: { year: number; month: number; day: number } | null }): unknown => {
          return {
            endsAt: ((value: { year: number; month: number; day: number } | null): unknown => {
              if (value === null) {
                return null;
              }
              return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
            })(value["endsAt"]),
            promoBoxId: ((value: number): unknown => {
              return value;
            })(value["promoBoxId"]),
            startsAt: ((value: { year: number; month: number; day: number } | null): unknown => {
              if (value === null) {
                return null;
              }
              return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
            })(value["startsAt"]),
          };
        });
      })(value["items"]),
      itemsHidden: ((value: number[]): unknown => {
        return value.map((value: number): unknown => {
          return value;
        });
      })(value["itemsHidden"]),
      showDefaults: ((value: boolean): unknown => {
        return value;
      })(value["showDefaults"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PromoBoxConfigForm(str: string): PromoBoxConfigForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: PromoBoxConfigItemForm[];
          itemsHidden: number[];
          showDefaults: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PromoBoxConfigItemForm[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: PromoBoxConfigItemForm } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  endsAt: { year: number; month: number; day: number } | null;
                  promoBoxId: number;
                  startsAt: { year: number; month: number; day: number } | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "endsAt")) {
              return { success: false };
            }
            const parsed_endsAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
              })(value["endsAt"]);
            })();
            if (!parsed_endsAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "promoBoxId")) {
              return { success: false };
            }
            const parsed_promoBoxId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["promoBoxId"]);
            })();
            if (!parsed_promoBoxId.success) {
              return { success: false };
            }
            if (!hasProp(value, "startsAt")) {
              return { success: false };
            }
            const parsed_startsAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
              })(value["startsAt"]);
            })();
            if (!parsed_startsAt.success) {
              return { success: false };
            }
            return { success: true, value: { endsAt: parsed_endsAt.value, promoBoxId: parsed_promoBoxId.value, startsAt: parsed_startsAt.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    if (!hasProp(value, "itemsHidden")) {
      return { success: false };
    }
    const parsed_itemsHidden = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
          if (typeof value === "number") {
            return { success: true, value: value };
          }
          return { success: false };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["itemsHidden"]);
    })();
    if (!parsed_itemsHidden.success) {
      return { success: false };
    }
    if (!hasProp(value, "showDefaults")) {
      return { success: false };
    }
    const parsed_showDefaults = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["showDefaults"]);
    })();
    if (!parsed_showDefaults.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value, itemsHidden: parsed_itemsHidden.value, showDefaults: parsed_showDefaults.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PromoBoxConfigItemForm
export function serialize_PromoBoxConfigItemForm(obj: PromoBoxConfigItemForm): string {
  const serialized = ((value: { endsAt: { year: number; month: number; day: number } | null; promoBoxId: number; startsAt: { year: number; month: number; day: number } | null }): unknown => {
    return {
      endsAt: ((value: { year: number; month: number; day: number } | null): unknown => {
        if (value === null) {
          return null;
        }
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["endsAt"]),
      promoBoxId: ((value: number): unknown => {
        return value;
      })(value["promoBoxId"]),
      startsAt: ((value: { year: number; month: number; day: number } | null): unknown => {
        if (value === null) {
          return null;
        }
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["startsAt"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PromoBoxConfigItemForm(str: string): PromoBoxConfigItemForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          endsAt: { year: number; month: number; day: number } | null;
          promoBoxId: number;
          startsAt: { year: number; month: number; day: number } | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "endsAt")) {
      return { success: false };
    }
    const parsed_endsAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["endsAt"]);
    })();
    if (!parsed_endsAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "promoBoxId")) {
      return { success: false };
    }
    const parsed_promoBoxId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["promoBoxId"]);
    })();
    if (!parsed_promoBoxId.success) {
      return { success: false };
    }
    if (!hasProp(value, "startsAt")) {
      return { success: false };
    }
    const parsed_startsAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["startsAt"]);
    })();
    if (!parsed_startsAt.success) {
      return { success: false };
    }
    return { success: true, value: { endsAt: parsed_endsAt.value, promoBoxId: parsed_promoBoxId.value, startsAt: parsed_startsAt.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PromoBoxConfigPreview
export function serialize_PromoBoxConfigPreview(obj: PromoBoxConfigPreview): string {
  const serialized = ((value: { boxes: PromoBoxView[] }): unknown => {
    return {
      boxes: ((value: PromoBoxView[]): unknown => {
        return value.map(
          (value: {
            backgroundColor: string | null;
            brightTextColor: boolean;
            description: string | null;
            designApproved: boolean;
            imageUri: string;
            promoBoxId: number;
            tag: PromoBoxTag;
            title: string | null;
            webLink: string;
          }): unknown => {
            return {
              backgroundColor: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["backgroundColor"]),
              brightTextColor: ((value: boolean): unknown => {
                return value;
              })(value["brightTextColor"]),
              description: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["description"]),
              designApproved: ((value: boolean): unknown => {
                return value;
              })(value["designApproved"]),
              imageUri: ((value: string): unknown => {
                return value;
              })(value["imageUri"]),
              promoBoxId: ((value: number): unknown => {
                return value;
              })(value["promoBoxId"]),
              tag: ((
                value:
                  | "UNDEFINED"
                  | "EXPERT_WEBINAR"
                  | "PRO_TALK"
                  | "FEATURED"
                  | "FEATURED_SUMMARY"
                  | "FEATURED_ACTIONABLE"
                  | "FEATURED_TOPIC"
                  | "EMERGING_TREND"
                  | "INTERNATIONAL_DAY"
                  | "LEARNING_AT_WORK_WEEK"
                  | "Q_A"
                  | "VALUES_AND_CAPABILITIES"
                  | "RECOMMENDED_READS"
                  | "INTERNAL_THOUGHT_LEADER"
                  | "READING_CALENDAR"
                  | "NEW"
                  | "SPOTLIGHT"
                  | "PROMOTION"
                  | "DEAL"
                  | "NEWS"
                  | "CAMPAIGN"
                  | "BOOK_AWARD",
              ): unknown => {
                return value;
              })(value["tag"]),
              title: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["title"]),
              webLink: ((value: string): unknown => {
                return value;
              })(value["webLink"]),
            };
          },
        );
      })(value["boxes"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PromoBoxConfigPreview(str: string): PromoBoxConfigPreview | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          boxes: PromoBoxView[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "boxes")) {
      return { success: false };
    }
    const parsed_boxes = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PromoBoxView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: PromoBoxView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  backgroundColor: string | null;
                  brightTextColor: boolean;
                  description: string | null;
                  designApproved: boolean;
                  imageUri: string;
                  promoBoxId: number;
                  tag: PromoBoxTag;
                  title: string | null;
                  webLink: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "backgroundColor")) {
              return { success: false };
            }
            const parsed_backgroundColor = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["backgroundColor"]);
            })();
            if (!parsed_backgroundColor.success) {
              return { success: false };
            }
            if (!hasProp(value, "brightTextColor")) {
              return { success: false };
            }
            const parsed_brightTextColor = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["brightTextColor"]);
            })();
            if (!parsed_brightTextColor.success) {
              return { success: false };
            }
            if (!hasProp(value, "description")) {
              return { success: false };
            }
            const parsed_description = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["description"]);
            })();
            if (!parsed_description.success) {
              return { success: false };
            }
            if (!hasProp(value, "designApproved")) {
              return { success: false };
            }
            const parsed_designApproved = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["designApproved"]);
            })();
            if (!parsed_designApproved.success) {
              return { success: false };
            }
            if (!hasProp(value, "imageUri")) {
              return { success: false };
            }
            const parsed_imageUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["imageUri"]);
            })();
            if (!parsed_imageUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "promoBoxId")) {
              return { success: false };
            }
            const parsed_promoBoxId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["promoBoxId"]);
            })();
            if (!parsed_promoBoxId.success) {
              return { success: false };
            }
            if (!hasProp(value, "tag")) {
              return { success: false };
            }
            const parsed_tag = (() => {
              return ((value: unknown): { success: false } | { success: true; value: PromoBoxTag } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | "UNDEFINED"
                        | "EXPERT_WEBINAR"
                        | "PRO_TALK"
                        | "FEATURED"
                        | "FEATURED_SUMMARY"
                        | "FEATURED_ACTIONABLE"
                        | "FEATURED_TOPIC"
                        | "EMERGING_TREND"
                        | "INTERNATIONAL_DAY"
                        | "LEARNING_AT_WORK_WEEK"
                        | "Q_A"
                        | "VALUES_AND_CAPABILITIES"
                        | "RECOMMENDED_READS"
                        | "INTERNAL_THOUGHT_LEADER"
                        | "READING_CALENDAR"
                        | "NEW"
                        | "SPOTLIGHT"
                        | "PROMOTION"
                        | "DEAL"
                        | "NEWS"
                        | "CAMPAIGN"
                        | "BOOK_AWARD";
                    } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "UNDEFINED":
                      return { success: true, value: "UNDEFINED" };
                    case "EXPERT_WEBINAR":
                      return { success: true, value: "EXPERT_WEBINAR" };
                    case "PRO_TALK":
                      return { success: true, value: "PRO_TALK" };
                    case "FEATURED":
                      return { success: true, value: "FEATURED" };
                    case "FEATURED_SUMMARY":
                      return { success: true, value: "FEATURED_SUMMARY" };
                    case "FEATURED_ACTIONABLE":
                      return { success: true, value: "FEATURED_ACTIONABLE" };
                    case "FEATURED_TOPIC":
                      return { success: true, value: "FEATURED_TOPIC" };
                    case "EMERGING_TREND":
                      return { success: true, value: "EMERGING_TREND" };
                    case "INTERNATIONAL_DAY":
                      return { success: true, value: "INTERNATIONAL_DAY" };
                    case "LEARNING_AT_WORK_WEEK":
                      return { success: true, value: "LEARNING_AT_WORK_WEEK" };
                    case "Q_A":
                      return { success: true, value: "Q_A" };
                    case "VALUES_AND_CAPABILITIES":
                      return { success: true, value: "VALUES_AND_CAPABILITIES" };
                    case "RECOMMENDED_READS":
                      return { success: true, value: "RECOMMENDED_READS" };
                    case "INTERNAL_THOUGHT_LEADER":
                      return { success: true, value: "INTERNAL_THOUGHT_LEADER" };
                    case "READING_CALENDAR":
                      return { success: true, value: "READING_CALENDAR" };
                    case "NEW":
                      return { success: true, value: "NEW" };
                    case "SPOTLIGHT":
                      return { success: true, value: "SPOTLIGHT" };
                    case "PROMOTION":
                      return { success: true, value: "PROMOTION" };
                    case "DEAL":
                      return { success: true, value: "DEAL" };
                    case "NEWS":
                      return { success: true, value: "NEWS" };
                    case "CAMPAIGN":
                      return { success: true, value: "CAMPAIGN" };
                    case "BOOK_AWARD":
                      return { success: true, value: "BOOK_AWARD" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["tag"]);
            })();
            if (!parsed_tag.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            if (!hasProp(value, "webLink")) {
              return { success: false };
            }
            const parsed_webLink = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["webLink"]);
            })();
            if (!parsed_webLink.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                backgroundColor: parsed_backgroundColor.value,
                brightTextColor: parsed_brightTextColor.value,
                description: parsed_description.value,
                designApproved: parsed_designApproved.value,
                imageUri: parsed_imageUri.value,
                promoBoxId: parsed_promoBoxId.value,
                tag: parsed_tag.value,
                title: parsed_title.value,
                webLink: parsed_webLink.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["boxes"]);
    })();
    if (!parsed_boxes.success) {
      return { success: false };
    }
    return { success: true, value: { boxes: parsed_boxes.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PromoBoxConfigPreviewFilters
export function serialize_PromoBoxConfigPreviewFilters(obj: PromoBoxConfigPreviewFilters): string {
  const serialized = ((value: { language: Language; loggedIn: string; platform: string }): unknown => {
    return {
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      loggedIn: ((value: string): unknown => {
        return value;
      })(value["loggedIn"]),
      platform: ((value: string): unknown => {
        return value;
      })(value["platform"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PromoBoxConfigPreviewFilters(str: string): PromoBoxConfigPreviewFilters | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          language: Language;
          loggedIn: string;
          platform: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language } => {
        return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "en":
              return { success: true, value: "en" };
            case "de":
              return { success: true, value: "de" };
            case "es":
              return { success: true, value: "es" };
            case "ru":
              return { success: true, value: "ru" };
            case "zh":
              return { success: true, value: "zh" };
            case "pt":
              return { success: true, value: "pt" };
            case "fr":
              return { success: true, value: "fr" };
            default:
              return { success: false };
          }
        })(value);
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "loggedIn")) {
      return { success: false };
    }
    const parsed_loggedIn = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["loggedIn"]);
    })();
    if (!parsed_loggedIn.success) {
      return { success: false };
    }
    if (!hasProp(value, "platform")) {
      return { success: false };
    }
    const parsed_platform = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["platform"]);
    })();
    if (!parsed_platform.success) {
      return { success: false };
    }
    return { success: true, value: { language: parsed_language.value, loggedIn: parsed_loggedIn.value, platform: parsed_platform.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PromoBoxEditProps
export function serialize_PromoBoxEditProps(obj: PromoBoxEditProps): string {
  const serialized = ((value: { corporateId: number; languages: Language[]; promoBoxId: number }): unknown => {
    return {
      corporateId: ((value: number): unknown => {
        return value;
      })(value["corporateId"]),
      languages: ((value: Language[]): unknown => {
        return value.map((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
          return value;
        });
      })(value["languages"]),
      promoBoxId: ((value: number): unknown => {
        return value;
      })(value["promoBoxId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PromoBoxEditProps(str: string): PromoBoxEditProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          corporateId: number;
          languages: Language[];
          promoBoxId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "languages")) {
      return { success: false };
    }
    const parsed_languages = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Language } => {
          return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            switch (value) {
              case "en":
                return { success: true, value: "en" };
              case "de":
                return { success: true, value: "de" };
              case "es":
                return { success: true, value: "es" };
              case "ru":
                return { success: true, value: "ru" };
              case "zh":
                return { success: true, value: "zh" };
              case "pt":
                return { success: true, value: "pt" };
              case "fr":
                return { success: true, value: "fr" };
              default:
                return { success: false };
            }
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["languages"]);
    })();
    if (!parsed_languages.success) {
      return { success: false };
    }
    if (!hasProp(value, "promoBoxId")) {
      return { success: false };
    }
    const parsed_promoBoxId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["promoBoxId"]);
    })();
    if (!parsed_promoBoxId.success) {
      return { success: false };
    }
    return { success: true, value: { corporateId: parsed_corporateId.value, languages: parsed_languages.value, promoBoxId: parsed_promoBoxId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PromoBoxEditView
export function serialize_PromoBoxEditView(obj: PromoBoxEditView): string {
  const serialized = ((value: {
    backgroundColor: string | null;
    brightTextColor: boolean;
    corporateId: number;
    designApproved: boolean;
    endsAt: { year: number; month: number; day: number } | null;
    excludedLanguages: Language[];
    imageUri: string;
    localizedDescription: { additionalProperties: Record<string, string> };
    localizedImageUris: { additionalProperties: Record<string, string> };
    localizedTitle: { additionalProperties: Record<string, string> };
    localizedWebLinks: { additionalProperties: Record<string, string> };
    loggedIn: boolean;
    loggedOut: boolean;
    mobile: boolean;
    name: string;
    promoBoxId: number;
    requiredFeatures: string[];
    startsAt: { year: number; month: number; day: number } | null;
    tag: PromoBoxTag;
    web: boolean;
    webLink: string;
  }): unknown => {
    return {
      backgroundColor: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["backgroundColor"]),
      brightTextColor: ((value: boolean): unknown => {
        return value;
      })(value["brightTextColor"]),
      corporateId: ((value: number): unknown => {
        return value;
      })(value["corporateId"]),
      designApproved: ((value: boolean): unknown => {
        return value;
      })(value["designApproved"]),
      endsAt: ((value: { year: number; month: number; day: number } | null): unknown => {
        if (value === null) {
          return null;
        }
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["endsAt"]),
      excludedLanguages: ((value: Language[]): unknown => {
        return value.map((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
          return value;
        });
      })(value["excludedLanguages"]),
      imageUri: ((value: string): unknown => {
        return value;
      })(value["imageUri"]),
      localizedDescription: ((value: { additionalProperties: Record<string, string> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: string): unknown => {
                  return value;
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["localizedDescription"]),
      localizedImageUris: ((value: { additionalProperties: Record<string, string> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: string): unknown => {
                  return value;
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["localizedImageUris"]),
      localizedTitle: ((value: { additionalProperties: Record<string, string> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: string): unknown => {
                  return value;
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["localizedTitle"]),
      localizedWebLinks: ((value: { additionalProperties: Record<string, string> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: string): unknown => {
                  return value;
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["localizedWebLinks"]),
      loggedIn: ((value: boolean): unknown => {
        return value;
      })(value["loggedIn"]),
      loggedOut: ((value: boolean): unknown => {
        return value;
      })(value["loggedOut"]),
      mobile: ((value: boolean): unknown => {
        return value;
      })(value["mobile"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      promoBoxId: ((value: number): unknown => {
        return value;
      })(value["promoBoxId"]),
      requiredFeatures: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["requiredFeatures"]),
      startsAt: ((value: { year: number; month: number; day: number } | null): unknown => {
        if (value === null) {
          return null;
        }
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["startsAt"]),
      tag: ((
        value:
          | "UNDEFINED"
          | "EXPERT_WEBINAR"
          | "PRO_TALK"
          | "FEATURED"
          | "FEATURED_SUMMARY"
          | "FEATURED_ACTIONABLE"
          | "FEATURED_TOPIC"
          | "EMERGING_TREND"
          | "INTERNATIONAL_DAY"
          | "LEARNING_AT_WORK_WEEK"
          | "Q_A"
          | "VALUES_AND_CAPABILITIES"
          | "RECOMMENDED_READS"
          | "INTERNAL_THOUGHT_LEADER"
          | "READING_CALENDAR"
          | "NEW"
          | "SPOTLIGHT"
          | "PROMOTION"
          | "DEAL"
          | "NEWS"
          | "CAMPAIGN"
          | "BOOK_AWARD",
      ): unknown => {
        return value;
      })(value["tag"]),
      web: ((value: boolean): unknown => {
        return value;
      })(value["web"]),
      webLink: ((value: string): unknown => {
        return value;
      })(value["webLink"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PromoBoxEditView(str: string): PromoBoxEditView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          backgroundColor: string | null;
          brightTextColor: boolean;
          corporateId: number;
          designApproved: boolean;
          endsAt: { year: number; month: number; day: number } | null;
          excludedLanguages: Language[];
          imageUri: string;
          localizedDescription: { additionalProperties: Record<string, string> };
          localizedImageUris: { additionalProperties: Record<string, string> };
          localizedTitle: { additionalProperties: Record<string, string> };
          localizedWebLinks: { additionalProperties: Record<string, string> };
          loggedIn: boolean;
          loggedOut: boolean;
          mobile: boolean;
          name: string;
          promoBoxId: number;
          requiredFeatures: string[];
          startsAt: { year: number; month: number; day: number } | null;
          tag: PromoBoxTag;
          web: boolean;
          webLink: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "backgroundColor")) {
      return { success: false };
    }
    const parsed_backgroundColor = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["backgroundColor"]);
    })();
    if (!parsed_backgroundColor.success) {
      return { success: false };
    }
    if (!hasProp(value, "brightTextColor")) {
      return { success: false };
    }
    const parsed_brightTextColor = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["brightTextColor"]);
    })();
    if (!parsed_brightTextColor.success) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "designApproved")) {
      return { success: false };
    }
    const parsed_designApproved = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["designApproved"]);
    })();
    if (!parsed_designApproved.success) {
      return { success: false };
    }
    if (!hasProp(value, "endsAt")) {
      return { success: false };
    }
    const parsed_endsAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["endsAt"]);
    })();
    if (!parsed_endsAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "excludedLanguages")) {
      return { success: false };
    }
    const parsed_excludedLanguages = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Language } => {
          return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            switch (value) {
              case "en":
                return { success: true, value: "en" };
              case "de":
                return { success: true, value: "de" };
              case "es":
                return { success: true, value: "es" };
              case "ru":
                return { success: true, value: "ru" };
              case "zh":
                return { success: true, value: "zh" };
              case "pt":
                return { success: true, value: "pt" };
              case "fr":
                return { success: true, value: "fr" };
              default:
                return { success: false };
            }
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["excludedLanguages"]);
    })();
    if (!parsed_excludedLanguages.success) {
      return { success: false };
    }
    if (!hasProp(value, "imageUri")) {
      return { success: false };
    }
    const parsed_imageUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["imageUri"]);
    })();
    if (!parsed_imageUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "localizedDescription")) {
      return { success: false };
    }
    const parsed_localizedDescription = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["localizedDescription"]);
    })();
    if (!parsed_localizedDescription.success) {
      return { success: false };
    }
    if (!hasProp(value, "localizedImageUris")) {
      return { success: false };
    }
    const parsed_localizedImageUris = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["localizedImageUris"]);
    })();
    if (!parsed_localizedImageUris.success) {
      return { success: false };
    }
    if (!hasProp(value, "localizedTitle")) {
      return { success: false };
    }
    const parsed_localizedTitle = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["localizedTitle"]);
    })();
    if (!parsed_localizedTitle.success) {
      return { success: false };
    }
    if (!hasProp(value, "localizedWebLinks")) {
      return { success: false };
    }
    const parsed_localizedWebLinks = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["localizedWebLinks"]);
    })();
    if (!parsed_localizedWebLinks.success) {
      return { success: false };
    }
    if (!hasProp(value, "loggedIn")) {
      return { success: false };
    }
    const parsed_loggedIn = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["loggedIn"]);
    })();
    if (!parsed_loggedIn.success) {
      return { success: false };
    }
    if (!hasProp(value, "loggedOut")) {
      return { success: false };
    }
    const parsed_loggedOut = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["loggedOut"]);
    })();
    if (!parsed_loggedOut.success) {
      return { success: false };
    }
    if (!hasProp(value, "mobile")) {
      return { success: false };
    }
    const parsed_mobile = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["mobile"]);
    })();
    if (!parsed_mobile.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "promoBoxId")) {
      return { success: false };
    }
    const parsed_promoBoxId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["promoBoxId"]);
    })();
    if (!parsed_promoBoxId.success) {
      return { success: false };
    }
    if (!hasProp(value, "requiredFeatures")) {
      return { success: false };
    }
    const parsed_requiredFeatures = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["requiredFeatures"]);
    })();
    if (!parsed_requiredFeatures.success) {
      return { success: false };
    }
    if (!hasProp(value, "startsAt")) {
      return { success: false };
    }
    const parsed_startsAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["startsAt"]);
    })();
    if (!parsed_startsAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "tag")) {
      return { success: false };
    }
    const parsed_tag = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PromoBoxTag } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value:
                | "UNDEFINED"
                | "EXPERT_WEBINAR"
                | "PRO_TALK"
                | "FEATURED"
                | "FEATURED_SUMMARY"
                | "FEATURED_ACTIONABLE"
                | "FEATURED_TOPIC"
                | "EMERGING_TREND"
                | "INTERNATIONAL_DAY"
                | "LEARNING_AT_WORK_WEEK"
                | "Q_A"
                | "VALUES_AND_CAPABILITIES"
                | "RECOMMENDED_READS"
                | "INTERNAL_THOUGHT_LEADER"
                | "READING_CALENDAR"
                | "NEW"
                | "SPOTLIGHT"
                | "PROMOTION"
                | "DEAL"
                | "NEWS"
                | "CAMPAIGN"
                | "BOOK_AWARD";
            } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "UNDEFINED":
              return { success: true, value: "UNDEFINED" };
            case "EXPERT_WEBINAR":
              return { success: true, value: "EXPERT_WEBINAR" };
            case "PRO_TALK":
              return { success: true, value: "PRO_TALK" };
            case "FEATURED":
              return { success: true, value: "FEATURED" };
            case "FEATURED_SUMMARY":
              return { success: true, value: "FEATURED_SUMMARY" };
            case "FEATURED_ACTIONABLE":
              return { success: true, value: "FEATURED_ACTIONABLE" };
            case "FEATURED_TOPIC":
              return { success: true, value: "FEATURED_TOPIC" };
            case "EMERGING_TREND":
              return { success: true, value: "EMERGING_TREND" };
            case "INTERNATIONAL_DAY":
              return { success: true, value: "INTERNATIONAL_DAY" };
            case "LEARNING_AT_WORK_WEEK":
              return { success: true, value: "LEARNING_AT_WORK_WEEK" };
            case "Q_A":
              return { success: true, value: "Q_A" };
            case "VALUES_AND_CAPABILITIES":
              return { success: true, value: "VALUES_AND_CAPABILITIES" };
            case "RECOMMENDED_READS":
              return { success: true, value: "RECOMMENDED_READS" };
            case "INTERNAL_THOUGHT_LEADER":
              return { success: true, value: "INTERNAL_THOUGHT_LEADER" };
            case "READING_CALENDAR":
              return { success: true, value: "READING_CALENDAR" };
            case "NEW":
              return { success: true, value: "NEW" };
            case "SPOTLIGHT":
              return { success: true, value: "SPOTLIGHT" };
            case "PROMOTION":
              return { success: true, value: "PROMOTION" };
            case "DEAL":
              return { success: true, value: "DEAL" };
            case "NEWS":
              return { success: true, value: "NEWS" };
            case "CAMPAIGN":
              return { success: true, value: "CAMPAIGN" };
            case "BOOK_AWARD":
              return { success: true, value: "BOOK_AWARD" };
            default:
              return { success: false };
          }
        })(value);
      })(value["tag"]);
    })();
    if (!parsed_tag.success) {
      return { success: false };
    }
    if (!hasProp(value, "web")) {
      return { success: false };
    }
    const parsed_web = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["web"]);
    })();
    if (!parsed_web.success) {
      return { success: false };
    }
    if (!hasProp(value, "webLink")) {
      return { success: false };
    }
    const parsed_webLink = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["webLink"]);
    })();
    if (!parsed_webLink.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        backgroundColor: parsed_backgroundColor.value,
        brightTextColor: parsed_brightTextColor.value,
        corporateId: parsed_corporateId.value,
        designApproved: parsed_designApproved.value,
        endsAt: parsed_endsAt.value,
        excludedLanguages: parsed_excludedLanguages.value,
        imageUri: parsed_imageUri.value,
        localizedDescription: parsed_localizedDescription.value,
        localizedImageUris: parsed_localizedImageUris.value,
        localizedTitle: parsed_localizedTitle.value,
        localizedWebLinks: parsed_localizedWebLinks.value,
        loggedIn: parsed_loggedIn.value,
        loggedOut: parsed_loggedOut.value,
        mobile: parsed_mobile.value,
        name: parsed_name.value,
        promoBoxId: parsed_promoBoxId.value,
        requiredFeatures: parsed_requiredFeatures.value,
        startsAt: parsed_startsAt.value,
        tag: parsed_tag.value,
        web: parsed_web.value,
        webLink: parsed_webLink.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PromoBoxLibraryView
export function serialize_PromoBoxLibraryView(obj: PromoBoxLibraryView): string {
  const serialized = ((value: { customBoxes: PromoBoxEditView[]; defaultBoxes: PromoBoxEditView[] }): unknown => {
    return {
      customBoxes: ((value: PromoBoxEditView[]): unknown => {
        return value.map(
          (value: {
            backgroundColor: string | null;
            brightTextColor: boolean;
            corporateId: number;
            designApproved: boolean;
            endsAt: { year: number; month: number; day: number } | null;
            excludedLanguages: Language[];
            imageUri: string;
            localizedDescription: { additionalProperties: Record<string, string> };
            localizedImageUris: { additionalProperties: Record<string, string> };
            localizedTitle: { additionalProperties: Record<string, string> };
            localizedWebLinks: { additionalProperties: Record<string, string> };
            loggedIn: boolean;
            loggedOut: boolean;
            mobile: boolean;
            name: string;
            promoBoxId: number;
            requiredFeatures: string[];
            startsAt: { year: number; month: number; day: number } | null;
            tag: PromoBoxTag;
            web: boolean;
            webLink: string;
          }): unknown => {
            return {
              backgroundColor: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["backgroundColor"]),
              brightTextColor: ((value: boolean): unknown => {
                return value;
              })(value["brightTextColor"]),
              corporateId: ((value: number): unknown => {
                return value;
              })(value["corporateId"]),
              designApproved: ((value: boolean): unknown => {
                return value;
              })(value["designApproved"]),
              endsAt: ((value: { year: number; month: number; day: number } | null): unknown => {
                if (value === null) {
                  return null;
                }
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["endsAt"]),
              excludedLanguages: ((value: Language[]): unknown => {
                return value.map((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                  return value;
                });
              })(value["excludedLanguages"]),
              imageUri: ((value: string): unknown => {
                return value;
              })(value["imageUri"]),
              localizedDescription: ((value: { additionalProperties: Record<string, string> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string): unknown => {
                          return value;
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["localizedDescription"]),
              localizedImageUris: ((value: { additionalProperties: Record<string, string> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string): unknown => {
                          return value;
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["localizedImageUris"]),
              localizedTitle: ((value: { additionalProperties: Record<string, string> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string): unknown => {
                          return value;
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["localizedTitle"]),
              localizedWebLinks: ((value: { additionalProperties: Record<string, string> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string): unknown => {
                          return value;
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["localizedWebLinks"]),
              loggedIn: ((value: boolean): unknown => {
                return value;
              })(value["loggedIn"]),
              loggedOut: ((value: boolean): unknown => {
                return value;
              })(value["loggedOut"]),
              mobile: ((value: boolean): unknown => {
                return value;
              })(value["mobile"]),
              name: ((value: string): unknown => {
                return value;
              })(value["name"]),
              promoBoxId: ((value: number): unknown => {
                return value;
              })(value["promoBoxId"]),
              requiredFeatures: ((value: string[]): unknown => {
                return value.map((value: string): unknown => {
                  return value;
                });
              })(value["requiredFeatures"]),
              startsAt: ((value: { year: number; month: number; day: number } | null): unknown => {
                if (value === null) {
                  return null;
                }
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["startsAt"]),
              tag: ((
                value:
                  | "UNDEFINED"
                  | "EXPERT_WEBINAR"
                  | "PRO_TALK"
                  | "FEATURED"
                  | "FEATURED_SUMMARY"
                  | "FEATURED_ACTIONABLE"
                  | "FEATURED_TOPIC"
                  | "EMERGING_TREND"
                  | "INTERNATIONAL_DAY"
                  | "LEARNING_AT_WORK_WEEK"
                  | "Q_A"
                  | "VALUES_AND_CAPABILITIES"
                  | "RECOMMENDED_READS"
                  | "INTERNAL_THOUGHT_LEADER"
                  | "READING_CALENDAR"
                  | "NEW"
                  | "SPOTLIGHT"
                  | "PROMOTION"
                  | "DEAL"
                  | "NEWS"
                  | "CAMPAIGN"
                  | "BOOK_AWARD",
              ): unknown => {
                return value;
              })(value["tag"]),
              web: ((value: boolean): unknown => {
                return value;
              })(value["web"]),
              webLink: ((value: string): unknown => {
                return value;
              })(value["webLink"]),
            };
          },
        );
      })(value["customBoxes"]),
      defaultBoxes: ((value: PromoBoxEditView[]): unknown => {
        return value.map(
          (value: {
            backgroundColor: string | null;
            brightTextColor: boolean;
            corporateId: number;
            designApproved: boolean;
            endsAt: { year: number; month: number; day: number } | null;
            excludedLanguages: Language[];
            imageUri: string;
            localizedDescription: { additionalProperties: Record<string, string> };
            localizedImageUris: { additionalProperties: Record<string, string> };
            localizedTitle: { additionalProperties: Record<string, string> };
            localizedWebLinks: { additionalProperties: Record<string, string> };
            loggedIn: boolean;
            loggedOut: boolean;
            mobile: boolean;
            name: string;
            promoBoxId: number;
            requiredFeatures: string[];
            startsAt: { year: number; month: number; day: number } | null;
            tag: PromoBoxTag;
            web: boolean;
            webLink: string;
          }): unknown => {
            return {
              backgroundColor: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["backgroundColor"]),
              brightTextColor: ((value: boolean): unknown => {
                return value;
              })(value["brightTextColor"]),
              corporateId: ((value: number): unknown => {
                return value;
              })(value["corporateId"]),
              designApproved: ((value: boolean): unknown => {
                return value;
              })(value["designApproved"]),
              endsAt: ((value: { year: number; month: number; day: number } | null): unknown => {
                if (value === null) {
                  return null;
                }
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["endsAt"]),
              excludedLanguages: ((value: Language[]): unknown => {
                return value.map((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                  return value;
                });
              })(value["excludedLanguages"]),
              imageUri: ((value: string): unknown => {
                return value;
              })(value["imageUri"]),
              localizedDescription: ((value: { additionalProperties: Record<string, string> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string): unknown => {
                          return value;
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["localizedDescription"]),
              localizedImageUris: ((value: { additionalProperties: Record<string, string> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string): unknown => {
                          return value;
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["localizedImageUris"]),
              localizedTitle: ((value: { additionalProperties: Record<string, string> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string): unknown => {
                          return value;
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["localizedTitle"]),
              localizedWebLinks: ((value: { additionalProperties: Record<string, string> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string): unknown => {
                          return value;
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["localizedWebLinks"]),
              loggedIn: ((value: boolean): unknown => {
                return value;
              })(value["loggedIn"]),
              loggedOut: ((value: boolean): unknown => {
                return value;
              })(value["loggedOut"]),
              mobile: ((value: boolean): unknown => {
                return value;
              })(value["mobile"]),
              name: ((value: string): unknown => {
                return value;
              })(value["name"]),
              promoBoxId: ((value: number): unknown => {
                return value;
              })(value["promoBoxId"]),
              requiredFeatures: ((value: string[]): unknown => {
                return value.map((value: string): unknown => {
                  return value;
                });
              })(value["requiredFeatures"]),
              startsAt: ((value: { year: number; month: number; day: number } | null): unknown => {
                if (value === null) {
                  return null;
                }
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["startsAt"]),
              tag: ((
                value:
                  | "UNDEFINED"
                  | "EXPERT_WEBINAR"
                  | "PRO_TALK"
                  | "FEATURED"
                  | "FEATURED_SUMMARY"
                  | "FEATURED_ACTIONABLE"
                  | "FEATURED_TOPIC"
                  | "EMERGING_TREND"
                  | "INTERNATIONAL_DAY"
                  | "LEARNING_AT_WORK_WEEK"
                  | "Q_A"
                  | "VALUES_AND_CAPABILITIES"
                  | "RECOMMENDED_READS"
                  | "INTERNAL_THOUGHT_LEADER"
                  | "READING_CALENDAR"
                  | "NEW"
                  | "SPOTLIGHT"
                  | "PROMOTION"
                  | "DEAL"
                  | "NEWS"
                  | "CAMPAIGN"
                  | "BOOK_AWARD",
              ): unknown => {
                return value;
              })(value["tag"]),
              web: ((value: boolean): unknown => {
                return value;
              })(value["web"]),
              webLink: ((value: string): unknown => {
                return value;
              })(value["webLink"]),
            };
          },
        );
      })(value["defaultBoxes"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PromoBoxLibraryView(str: string): PromoBoxLibraryView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          customBoxes: PromoBoxEditView[];
          defaultBoxes: PromoBoxEditView[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "customBoxes")) {
      return { success: false };
    }
    const parsed_customBoxes = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PromoBoxEditView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: PromoBoxEditView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  backgroundColor: string | null;
                  brightTextColor: boolean;
                  corporateId: number;
                  designApproved: boolean;
                  endsAt: { year: number; month: number; day: number } | null;
                  excludedLanguages: Language[];
                  imageUri: string;
                  localizedDescription: { additionalProperties: Record<string, string> };
                  localizedImageUris: { additionalProperties: Record<string, string> };
                  localizedTitle: { additionalProperties: Record<string, string> };
                  localizedWebLinks: { additionalProperties: Record<string, string> };
                  loggedIn: boolean;
                  loggedOut: boolean;
                  mobile: boolean;
                  name: string;
                  promoBoxId: number;
                  requiredFeatures: string[];
                  startsAt: { year: number; month: number; day: number } | null;
                  tag: PromoBoxTag;
                  web: boolean;
                  webLink: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "backgroundColor")) {
              return { success: false };
            }
            const parsed_backgroundColor = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["backgroundColor"]);
            })();
            if (!parsed_backgroundColor.success) {
              return { success: false };
            }
            if (!hasProp(value, "brightTextColor")) {
              return { success: false };
            }
            const parsed_brightTextColor = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["brightTextColor"]);
            })();
            if (!parsed_brightTextColor.success) {
              return { success: false };
            }
            if (!hasProp(value, "corporateId")) {
              return { success: false };
            }
            const parsed_corporateId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["corporateId"]);
            })();
            if (!parsed_corporateId.success) {
              return { success: false };
            }
            if (!hasProp(value, "designApproved")) {
              return { success: false };
            }
            const parsed_designApproved = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["designApproved"]);
            })();
            if (!parsed_designApproved.success) {
              return { success: false };
            }
            if (!hasProp(value, "endsAt")) {
              return { success: false };
            }
            const parsed_endsAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
              })(value["endsAt"]);
            })();
            if (!parsed_endsAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "excludedLanguages")) {
              return { success: false };
            }
            const parsed_excludedLanguages = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Language[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: Language } => {
                  return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    switch (value) {
                      case "en":
                        return { success: true, value: "en" };
                      case "de":
                        return { success: true, value: "de" };
                      case "es":
                        return { success: true, value: "es" };
                      case "ru":
                        return { success: true, value: "ru" };
                      case "zh":
                        return { success: true, value: "zh" };
                      case "pt":
                        return { success: true, value: "pt" };
                      case "fr":
                        return { success: true, value: "fr" };
                      default:
                        return { success: false };
                    }
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["excludedLanguages"]);
            })();
            if (!parsed_excludedLanguages.success) {
              return { success: false };
            }
            if (!hasProp(value, "imageUri")) {
              return { success: false };
            }
            const parsed_imageUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["imageUri"]);
            })();
            if (!parsed_imageUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "localizedDescription")) {
              return { success: false };
            }
            const parsed_localizedDescription = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
                  const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
                    return [
                      key,
                      ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value),
                    ];
                  });
                  if (parsed.find(([_, value]) => !value.success) !== undefined) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: Object.fromEntries(
                      parsed.map(([key, value]) => {
                        assert(value.success);
                        return [key, value.value];
                      }),
                    ),
                  };
                })();
                if (!parsed_additionalProperties.success) {
                  return { success: false };
                }
                return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
              })(value["localizedDescription"]);
            })();
            if (!parsed_localizedDescription.success) {
              return { success: false };
            }
            if (!hasProp(value, "localizedImageUris")) {
              return { success: false };
            }
            const parsed_localizedImageUris = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
                  const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
                    return [
                      key,
                      ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value),
                    ];
                  });
                  if (parsed.find(([_, value]) => !value.success) !== undefined) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: Object.fromEntries(
                      parsed.map(([key, value]) => {
                        assert(value.success);
                        return [key, value.value];
                      }),
                    ),
                  };
                })();
                if (!parsed_additionalProperties.success) {
                  return { success: false };
                }
                return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
              })(value["localizedImageUris"]);
            })();
            if (!parsed_localizedImageUris.success) {
              return { success: false };
            }
            if (!hasProp(value, "localizedTitle")) {
              return { success: false };
            }
            const parsed_localizedTitle = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
                  const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
                    return [
                      key,
                      ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value),
                    ];
                  });
                  if (parsed.find(([_, value]) => !value.success) !== undefined) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: Object.fromEntries(
                      parsed.map(([key, value]) => {
                        assert(value.success);
                        return [key, value.value];
                      }),
                    ),
                  };
                })();
                if (!parsed_additionalProperties.success) {
                  return { success: false };
                }
                return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
              })(value["localizedTitle"]);
            })();
            if (!parsed_localizedTitle.success) {
              return { success: false };
            }
            if (!hasProp(value, "localizedWebLinks")) {
              return { success: false };
            }
            const parsed_localizedWebLinks = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
                  const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
                    return [
                      key,
                      ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value),
                    ];
                  });
                  if (parsed.find(([_, value]) => !value.success) !== undefined) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: Object.fromEntries(
                      parsed.map(([key, value]) => {
                        assert(value.success);
                        return [key, value.value];
                      }),
                    ),
                  };
                })();
                if (!parsed_additionalProperties.success) {
                  return { success: false };
                }
                return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
              })(value["localizedWebLinks"]);
            })();
            if (!parsed_localizedWebLinks.success) {
              return { success: false };
            }
            if (!hasProp(value, "loggedIn")) {
              return { success: false };
            }
            const parsed_loggedIn = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["loggedIn"]);
            })();
            if (!parsed_loggedIn.success) {
              return { success: false };
            }
            if (!hasProp(value, "loggedOut")) {
              return { success: false };
            }
            const parsed_loggedOut = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["loggedOut"]);
            })();
            if (!parsed_loggedOut.success) {
              return { success: false };
            }
            if (!hasProp(value, "mobile")) {
              return { success: false };
            }
            const parsed_mobile = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["mobile"]);
            })();
            if (!parsed_mobile.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "promoBoxId")) {
              return { success: false };
            }
            const parsed_promoBoxId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["promoBoxId"]);
            })();
            if (!parsed_promoBoxId.success) {
              return { success: false };
            }
            if (!hasProp(value, "requiredFeatures")) {
              return { success: false };
            }
            const parsed_requiredFeatures = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["requiredFeatures"]);
            })();
            if (!parsed_requiredFeatures.success) {
              return { success: false };
            }
            if (!hasProp(value, "startsAt")) {
              return { success: false };
            }
            const parsed_startsAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
              })(value["startsAt"]);
            })();
            if (!parsed_startsAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "tag")) {
              return { success: false };
            }
            const parsed_tag = (() => {
              return ((value: unknown): { success: false } | { success: true; value: PromoBoxTag } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | "UNDEFINED"
                        | "EXPERT_WEBINAR"
                        | "PRO_TALK"
                        | "FEATURED"
                        | "FEATURED_SUMMARY"
                        | "FEATURED_ACTIONABLE"
                        | "FEATURED_TOPIC"
                        | "EMERGING_TREND"
                        | "INTERNATIONAL_DAY"
                        | "LEARNING_AT_WORK_WEEK"
                        | "Q_A"
                        | "VALUES_AND_CAPABILITIES"
                        | "RECOMMENDED_READS"
                        | "INTERNAL_THOUGHT_LEADER"
                        | "READING_CALENDAR"
                        | "NEW"
                        | "SPOTLIGHT"
                        | "PROMOTION"
                        | "DEAL"
                        | "NEWS"
                        | "CAMPAIGN"
                        | "BOOK_AWARD";
                    } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "UNDEFINED":
                      return { success: true, value: "UNDEFINED" };
                    case "EXPERT_WEBINAR":
                      return { success: true, value: "EXPERT_WEBINAR" };
                    case "PRO_TALK":
                      return { success: true, value: "PRO_TALK" };
                    case "FEATURED":
                      return { success: true, value: "FEATURED" };
                    case "FEATURED_SUMMARY":
                      return { success: true, value: "FEATURED_SUMMARY" };
                    case "FEATURED_ACTIONABLE":
                      return { success: true, value: "FEATURED_ACTIONABLE" };
                    case "FEATURED_TOPIC":
                      return { success: true, value: "FEATURED_TOPIC" };
                    case "EMERGING_TREND":
                      return { success: true, value: "EMERGING_TREND" };
                    case "INTERNATIONAL_DAY":
                      return { success: true, value: "INTERNATIONAL_DAY" };
                    case "LEARNING_AT_WORK_WEEK":
                      return { success: true, value: "LEARNING_AT_WORK_WEEK" };
                    case "Q_A":
                      return { success: true, value: "Q_A" };
                    case "VALUES_AND_CAPABILITIES":
                      return { success: true, value: "VALUES_AND_CAPABILITIES" };
                    case "RECOMMENDED_READS":
                      return { success: true, value: "RECOMMENDED_READS" };
                    case "INTERNAL_THOUGHT_LEADER":
                      return { success: true, value: "INTERNAL_THOUGHT_LEADER" };
                    case "READING_CALENDAR":
                      return { success: true, value: "READING_CALENDAR" };
                    case "NEW":
                      return { success: true, value: "NEW" };
                    case "SPOTLIGHT":
                      return { success: true, value: "SPOTLIGHT" };
                    case "PROMOTION":
                      return { success: true, value: "PROMOTION" };
                    case "DEAL":
                      return { success: true, value: "DEAL" };
                    case "NEWS":
                      return { success: true, value: "NEWS" };
                    case "CAMPAIGN":
                      return { success: true, value: "CAMPAIGN" };
                    case "BOOK_AWARD":
                      return { success: true, value: "BOOK_AWARD" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["tag"]);
            })();
            if (!parsed_tag.success) {
              return { success: false };
            }
            if (!hasProp(value, "web")) {
              return { success: false };
            }
            const parsed_web = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["web"]);
            })();
            if (!parsed_web.success) {
              return { success: false };
            }
            if (!hasProp(value, "webLink")) {
              return { success: false };
            }
            const parsed_webLink = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["webLink"]);
            })();
            if (!parsed_webLink.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                backgroundColor: parsed_backgroundColor.value,
                brightTextColor: parsed_brightTextColor.value,
                corporateId: parsed_corporateId.value,
                designApproved: parsed_designApproved.value,
                endsAt: parsed_endsAt.value,
                excludedLanguages: parsed_excludedLanguages.value,
                imageUri: parsed_imageUri.value,
                localizedDescription: parsed_localizedDescription.value,
                localizedImageUris: parsed_localizedImageUris.value,
                localizedTitle: parsed_localizedTitle.value,
                localizedWebLinks: parsed_localizedWebLinks.value,
                loggedIn: parsed_loggedIn.value,
                loggedOut: parsed_loggedOut.value,
                mobile: parsed_mobile.value,
                name: parsed_name.value,
                promoBoxId: parsed_promoBoxId.value,
                requiredFeatures: parsed_requiredFeatures.value,
                startsAt: parsed_startsAt.value,
                tag: parsed_tag.value,
                web: parsed_web.value,
                webLink: parsed_webLink.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["customBoxes"]);
    })();
    if (!parsed_customBoxes.success) {
      return { success: false };
    }
    if (!hasProp(value, "defaultBoxes")) {
      return { success: false };
    }
    const parsed_defaultBoxes = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PromoBoxEditView[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: PromoBoxEditView } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  backgroundColor: string | null;
                  brightTextColor: boolean;
                  corporateId: number;
                  designApproved: boolean;
                  endsAt: { year: number; month: number; day: number } | null;
                  excludedLanguages: Language[];
                  imageUri: string;
                  localizedDescription: { additionalProperties: Record<string, string> };
                  localizedImageUris: { additionalProperties: Record<string, string> };
                  localizedTitle: { additionalProperties: Record<string, string> };
                  localizedWebLinks: { additionalProperties: Record<string, string> };
                  loggedIn: boolean;
                  loggedOut: boolean;
                  mobile: boolean;
                  name: string;
                  promoBoxId: number;
                  requiredFeatures: string[];
                  startsAt: { year: number; month: number; day: number } | null;
                  tag: PromoBoxTag;
                  web: boolean;
                  webLink: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "backgroundColor")) {
              return { success: false };
            }
            const parsed_backgroundColor = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["backgroundColor"]);
            })();
            if (!parsed_backgroundColor.success) {
              return { success: false };
            }
            if (!hasProp(value, "brightTextColor")) {
              return { success: false };
            }
            const parsed_brightTextColor = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["brightTextColor"]);
            })();
            if (!parsed_brightTextColor.success) {
              return { success: false };
            }
            if (!hasProp(value, "corporateId")) {
              return { success: false };
            }
            const parsed_corporateId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["corporateId"]);
            })();
            if (!parsed_corporateId.success) {
              return { success: false };
            }
            if (!hasProp(value, "designApproved")) {
              return { success: false };
            }
            const parsed_designApproved = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["designApproved"]);
            })();
            if (!parsed_designApproved.success) {
              return { success: false };
            }
            if (!hasProp(value, "endsAt")) {
              return { success: false };
            }
            const parsed_endsAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
              })(value["endsAt"]);
            })();
            if (!parsed_endsAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "excludedLanguages")) {
              return { success: false };
            }
            const parsed_excludedLanguages = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Language[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: Language } => {
                  return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    switch (value) {
                      case "en":
                        return { success: true, value: "en" };
                      case "de":
                        return { success: true, value: "de" };
                      case "es":
                        return { success: true, value: "es" };
                      case "ru":
                        return { success: true, value: "ru" };
                      case "zh":
                        return { success: true, value: "zh" };
                      case "pt":
                        return { success: true, value: "pt" };
                      case "fr":
                        return { success: true, value: "fr" };
                      default:
                        return { success: false };
                    }
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["excludedLanguages"]);
            })();
            if (!parsed_excludedLanguages.success) {
              return { success: false };
            }
            if (!hasProp(value, "imageUri")) {
              return { success: false };
            }
            const parsed_imageUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["imageUri"]);
            })();
            if (!parsed_imageUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "localizedDescription")) {
              return { success: false };
            }
            const parsed_localizedDescription = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
                  const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
                    return [
                      key,
                      ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value),
                    ];
                  });
                  if (parsed.find(([_, value]) => !value.success) !== undefined) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: Object.fromEntries(
                      parsed.map(([key, value]) => {
                        assert(value.success);
                        return [key, value.value];
                      }),
                    ),
                  };
                })();
                if (!parsed_additionalProperties.success) {
                  return { success: false };
                }
                return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
              })(value["localizedDescription"]);
            })();
            if (!parsed_localizedDescription.success) {
              return { success: false };
            }
            if (!hasProp(value, "localizedImageUris")) {
              return { success: false };
            }
            const parsed_localizedImageUris = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
                  const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
                    return [
                      key,
                      ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value),
                    ];
                  });
                  if (parsed.find(([_, value]) => !value.success) !== undefined) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: Object.fromEntries(
                      parsed.map(([key, value]) => {
                        assert(value.success);
                        return [key, value.value];
                      }),
                    ),
                  };
                })();
                if (!parsed_additionalProperties.success) {
                  return { success: false };
                }
                return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
              })(value["localizedImageUris"]);
            })();
            if (!parsed_localizedImageUris.success) {
              return { success: false };
            }
            if (!hasProp(value, "localizedTitle")) {
              return { success: false };
            }
            const parsed_localizedTitle = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
                  const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
                    return [
                      key,
                      ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value),
                    ];
                  });
                  if (parsed.find(([_, value]) => !value.success) !== undefined) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: Object.fromEntries(
                      parsed.map(([key, value]) => {
                        assert(value.success);
                        return [key, value.value];
                      }),
                    ),
                  };
                })();
                if (!parsed_additionalProperties.success) {
                  return { success: false };
                }
                return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
              })(value["localizedTitle"]);
            })();
            if (!parsed_localizedTitle.success) {
              return { success: false };
            }
            if (!hasProp(value, "localizedWebLinks")) {
              return { success: false };
            }
            const parsed_localizedWebLinks = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
                  const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
                    return [
                      key,
                      ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value),
                    ];
                  });
                  if (parsed.find(([_, value]) => !value.success) !== undefined) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: Object.fromEntries(
                      parsed.map(([key, value]) => {
                        assert(value.success);
                        return [key, value.value];
                      }),
                    ),
                  };
                })();
                if (!parsed_additionalProperties.success) {
                  return { success: false };
                }
                return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
              })(value["localizedWebLinks"]);
            })();
            if (!parsed_localizedWebLinks.success) {
              return { success: false };
            }
            if (!hasProp(value, "loggedIn")) {
              return { success: false };
            }
            const parsed_loggedIn = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["loggedIn"]);
            })();
            if (!parsed_loggedIn.success) {
              return { success: false };
            }
            if (!hasProp(value, "loggedOut")) {
              return { success: false };
            }
            const parsed_loggedOut = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["loggedOut"]);
            })();
            if (!parsed_loggedOut.success) {
              return { success: false };
            }
            if (!hasProp(value, "mobile")) {
              return { success: false };
            }
            const parsed_mobile = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["mobile"]);
            })();
            if (!parsed_mobile.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "promoBoxId")) {
              return { success: false };
            }
            const parsed_promoBoxId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["promoBoxId"]);
            })();
            if (!parsed_promoBoxId.success) {
              return { success: false };
            }
            if (!hasProp(value, "requiredFeatures")) {
              return { success: false };
            }
            const parsed_requiredFeatures = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["requiredFeatures"]);
            })();
            if (!parsed_requiredFeatures.success) {
              return { success: false };
            }
            if (!hasProp(value, "startsAt")) {
              return { success: false };
            }
            const parsed_startsAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
              })(value["startsAt"]);
            })();
            if (!parsed_startsAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "tag")) {
              return { success: false };
            }
            const parsed_tag = (() => {
              return ((value: unknown): { success: false } | { success: true; value: PromoBoxTag } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | "UNDEFINED"
                        | "EXPERT_WEBINAR"
                        | "PRO_TALK"
                        | "FEATURED"
                        | "FEATURED_SUMMARY"
                        | "FEATURED_ACTIONABLE"
                        | "FEATURED_TOPIC"
                        | "EMERGING_TREND"
                        | "INTERNATIONAL_DAY"
                        | "LEARNING_AT_WORK_WEEK"
                        | "Q_A"
                        | "VALUES_AND_CAPABILITIES"
                        | "RECOMMENDED_READS"
                        | "INTERNAL_THOUGHT_LEADER"
                        | "READING_CALENDAR"
                        | "NEW"
                        | "SPOTLIGHT"
                        | "PROMOTION"
                        | "DEAL"
                        | "NEWS"
                        | "CAMPAIGN"
                        | "BOOK_AWARD";
                    } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "UNDEFINED":
                      return { success: true, value: "UNDEFINED" };
                    case "EXPERT_WEBINAR":
                      return { success: true, value: "EXPERT_WEBINAR" };
                    case "PRO_TALK":
                      return { success: true, value: "PRO_TALK" };
                    case "FEATURED":
                      return { success: true, value: "FEATURED" };
                    case "FEATURED_SUMMARY":
                      return { success: true, value: "FEATURED_SUMMARY" };
                    case "FEATURED_ACTIONABLE":
                      return { success: true, value: "FEATURED_ACTIONABLE" };
                    case "FEATURED_TOPIC":
                      return { success: true, value: "FEATURED_TOPIC" };
                    case "EMERGING_TREND":
                      return { success: true, value: "EMERGING_TREND" };
                    case "INTERNATIONAL_DAY":
                      return { success: true, value: "INTERNATIONAL_DAY" };
                    case "LEARNING_AT_WORK_WEEK":
                      return { success: true, value: "LEARNING_AT_WORK_WEEK" };
                    case "Q_A":
                      return { success: true, value: "Q_A" };
                    case "VALUES_AND_CAPABILITIES":
                      return { success: true, value: "VALUES_AND_CAPABILITIES" };
                    case "RECOMMENDED_READS":
                      return { success: true, value: "RECOMMENDED_READS" };
                    case "INTERNAL_THOUGHT_LEADER":
                      return { success: true, value: "INTERNAL_THOUGHT_LEADER" };
                    case "READING_CALENDAR":
                      return { success: true, value: "READING_CALENDAR" };
                    case "NEW":
                      return { success: true, value: "NEW" };
                    case "SPOTLIGHT":
                      return { success: true, value: "SPOTLIGHT" };
                    case "PROMOTION":
                      return { success: true, value: "PROMOTION" };
                    case "DEAL":
                      return { success: true, value: "DEAL" };
                    case "NEWS":
                      return { success: true, value: "NEWS" };
                    case "CAMPAIGN":
                      return { success: true, value: "CAMPAIGN" };
                    case "BOOK_AWARD":
                      return { success: true, value: "BOOK_AWARD" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["tag"]);
            })();
            if (!parsed_tag.success) {
              return { success: false };
            }
            if (!hasProp(value, "web")) {
              return { success: false };
            }
            const parsed_web = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["web"]);
            })();
            if (!parsed_web.success) {
              return { success: false };
            }
            if (!hasProp(value, "webLink")) {
              return { success: false };
            }
            const parsed_webLink = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["webLink"]);
            })();
            if (!parsed_webLink.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                backgroundColor: parsed_backgroundColor.value,
                brightTextColor: parsed_brightTextColor.value,
                corporateId: parsed_corporateId.value,
                designApproved: parsed_designApproved.value,
                endsAt: parsed_endsAt.value,
                excludedLanguages: parsed_excludedLanguages.value,
                imageUri: parsed_imageUri.value,
                localizedDescription: parsed_localizedDescription.value,
                localizedImageUris: parsed_localizedImageUris.value,
                localizedTitle: parsed_localizedTitle.value,
                localizedWebLinks: parsed_localizedWebLinks.value,
                loggedIn: parsed_loggedIn.value,
                loggedOut: parsed_loggedOut.value,
                mobile: parsed_mobile.value,
                name: parsed_name.value,
                promoBoxId: parsed_promoBoxId.value,
                requiredFeatures: parsed_requiredFeatures.value,
                startsAt: parsed_startsAt.value,
                tag: parsed_tag.value,
                web: parsed_web.value,
                webLink: parsed_webLink.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["defaultBoxes"]);
    })();
    if (!parsed_defaultBoxes.success) {
      return { success: false };
    }
    return { success: true, value: { customBoxes: parsed_customBoxes.value, defaultBoxes: parsed_defaultBoxes.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PromoBoxSaveRequest
export function serialize_PromoBoxSaveRequest(obj: PromoBoxSaveRequest): string {
  const serialized = ((value: {
    excludedLanguages: Language[];
    imageUri: string | null;
    localizedDescription: { additionalProperties: Record<string, string> };
    localizedImageUris: { additionalProperties: Record<string, string> };
    localizedTitle: { additionalProperties: Record<string, string> };
    localizedWebLinks: { additionalProperties: Record<string, string> };
    loggedIn: boolean;
    loggedOut: boolean;
    mobile: boolean;
    name: string;
    requiredFeatures: string[];
    tag: PromoBoxTag;
    web: boolean;
    webLink: string;
  }): unknown => {
    return {
      excludedLanguages: ((value: Language[]): unknown => {
        return value.map((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
          return value;
        });
      })(value["excludedLanguages"]),
      imageUri: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["imageUri"]),
      localizedDescription: ((value: { additionalProperties: Record<string, string> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: string): unknown => {
                  return value;
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["localizedDescription"]),
      localizedImageUris: ((value: { additionalProperties: Record<string, string> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: string): unknown => {
                  return value;
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["localizedImageUris"]),
      localizedTitle: ((value: { additionalProperties: Record<string, string> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: string): unknown => {
                  return value;
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["localizedTitle"]),
      localizedWebLinks: ((value: { additionalProperties: Record<string, string> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: string): unknown => {
                  return value;
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["localizedWebLinks"]),
      loggedIn: ((value: boolean): unknown => {
        return value;
      })(value["loggedIn"]),
      loggedOut: ((value: boolean): unknown => {
        return value;
      })(value["loggedOut"]),
      mobile: ((value: boolean): unknown => {
        return value;
      })(value["mobile"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      requiredFeatures: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["requiredFeatures"]),
      tag: ((
        value:
          | "UNDEFINED"
          | "EXPERT_WEBINAR"
          | "PRO_TALK"
          | "FEATURED"
          | "FEATURED_SUMMARY"
          | "FEATURED_ACTIONABLE"
          | "FEATURED_TOPIC"
          | "EMERGING_TREND"
          | "INTERNATIONAL_DAY"
          | "LEARNING_AT_WORK_WEEK"
          | "Q_A"
          | "VALUES_AND_CAPABILITIES"
          | "RECOMMENDED_READS"
          | "INTERNAL_THOUGHT_LEADER"
          | "READING_CALENDAR"
          | "NEW"
          | "SPOTLIGHT"
          | "PROMOTION"
          | "DEAL"
          | "NEWS"
          | "CAMPAIGN"
          | "BOOK_AWARD",
      ): unknown => {
        return value;
      })(value["tag"]),
      web: ((value: boolean): unknown => {
        return value;
      })(value["web"]),
      webLink: ((value: string): unknown => {
        return value;
      })(value["webLink"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PromoBoxSaveRequest(str: string): PromoBoxSaveRequest | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          excludedLanguages: Language[];
          imageUri: string | null;
          localizedDescription: { additionalProperties: Record<string, string> };
          localizedImageUris: { additionalProperties: Record<string, string> };
          localizedTitle: { additionalProperties: Record<string, string> };
          localizedWebLinks: { additionalProperties: Record<string, string> };
          loggedIn: boolean;
          loggedOut: boolean;
          mobile: boolean;
          name: string;
          requiredFeatures: string[];
          tag: PromoBoxTag;
          web: boolean;
          webLink: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "excludedLanguages")) {
      return { success: false };
    }
    const parsed_excludedLanguages = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Language } => {
          return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            switch (value) {
              case "en":
                return { success: true, value: "en" };
              case "de":
                return { success: true, value: "de" };
              case "es":
                return { success: true, value: "es" };
              case "ru":
                return { success: true, value: "ru" };
              case "zh":
                return { success: true, value: "zh" };
              case "pt":
                return { success: true, value: "pt" };
              case "fr":
                return { success: true, value: "fr" };
              default:
                return { success: false };
            }
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["excludedLanguages"]);
    })();
    if (!parsed_excludedLanguages.success) {
      return { success: false };
    }
    if (!hasProp(value, "imageUri")) {
      return { success: false };
    }
    const parsed_imageUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["imageUri"]);
    })();
    if (!parsed_imageUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "localizedDescription")) {
      return { success: false };
    }
    const parsed_localizedDescription = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["localizedDescription"]);
    })();
    if (!parsed_localizedDescription.success) {
      return { success: false };
    }
    if (!hasProp(value, "localizedImageUris")) {
      return { success: false };
    }
    const parsed_localizedImageUris = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["localizedImageUris"]);
    })();
    if (!parsed_localizedImageUris.success) {
      return { success: false };
    }
    if (!hasProp(value, "localizedTitle")) {
      return { success: false };
    }
    const parsed_localizedTitle = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["localizedTitle"]);
    })();
    if (!parsed_localizedTitle.success) {
      return { success: false };
    }
    if (!hasProp(value, "localizedWebLinks")) {
      return { success: false };
    }
    const parsed_localizedWebLinks = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["localizedWebLinks"]);
    })();
    if (!parsed_localizedWebLinks.success) {
      return { success: false };
    }
    if (!hasProp(value, "loggedIn")) {
      return { success: false };
    }
    const parsed_loggedIn = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["loggedIn"]);
    })();
    if (!parsed_loggedIn.success) {
      return { success: false };
    }
    if (!hasProp(value, "loggedOut")) {
      return { success: false };
    }
    const parsed_loggedOut = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["loggedOut"]);
    })();
    if (!parsed_loggedOut.success) {
      return { success: false };
    }
    if (!hasProp(value, "mobile")) {
      return { success: false };
    }
    const parsed_mobile = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["mobile"]);
    })();
    if (!parsed_mobile.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "requiredFeatures")) {
      return { success: false };
    }
    const parsed_requiredFeatures = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["requiredFeatures"]);
    })();
    if (!parsed_requiredFeatures.success) {
      return { success: false };
    }
    if (!hasProp(value, "tag")) {
      return { success: false };
    }
    const parsed_tag = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PromoBoxTag } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value:
                | "UNDEFINED"
                | "EXPERT_WEBINAR"
                | "PRO_TALK"
                | "FEATURED"
                | "FEATURED_SUMMARY"
                | "FEATURED_ACTIONABLE"
                | "FEATURED_TOPIC"
                | "EMERGING_TREND"
                | "INTERNATIONAL_DAY"
                | "LEARNING_AT_WORK_WEEK"
                | "Q_A"
                | "VALUES_AND_CAPABILITIES"
                | "RECOMMENDED_READS"
                | "INTERNAL_THOUGHT_LEADER"
                | "READING_CALENDAR"
                | "NEW"
                | "SPOTLIGHT"
                | "PROMOTION"
                | "DEAL"
                | "NEWS"
                | "CAMPAIGN"
                | "BOOK_AWARD";
            } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "UNDEFINED":
              return { success: true, value: "UNDEFINED" };
            case "EXPERT_WEBINAR":
              return { success: true, value: "EXPERT_WEBINAR" };
            case "PRO_TALK":
              return { success: true, value: "PRO_TALK" };
            case "FEATURED":
              return { success: true, value: "FEATURED" };
            case "FEATURED_SUMMARY":
              return { success: true, value: "FEATURED_SUMMARY" };
            case "FEATURED_ACTIONABLE":
              return { success: true, value: "FEATURED_ACTIONABLE" };
            case "FEATURED_TOPIC":
              return { success: true, value: "FEATURED_TOPIC" };
            case "EMERGING_TREND":
              return { success: true, value: "EMERGING_TREND" };
            case "INTERNATIONAL_DAY":
              return { success: true, value: "INTERNATIONAL_DAY" };
            case "LEARNING_AT_WORK_WEEK":
              return { success: true, value: "LEARNING_AT_WORK_WEEK" };
            case "Q_A":
              return { success: true, value: "Q_A" };
            case "VALUES_AND_CAPABILITIES":
              return { success: true, value: "VALUES_AND_CAPABILITIES" };
            case "RECOMMENDED_READS":
              return { success: true, value: "RECOMMENDED_READS" };
            case "INTERNAL_THOUGHT_LEADER":
              return { success: true, value: "INTERNAL_THOUGHT_LEADER" };
            case "READING_CALENDAR":
              return { success: true, value: "READING_CALENDAR" };
            case "NEW":
              return { success: true, value: "NEW" };
            case "SPOTLIGHT":
              return { success: true, value: "SPOTLIGHT" };
            case "PROMOTION":
              return { success: true, value: "PROMOTION" };
            case "DEAL":
              return { success: true, value: "DEAL" };
            case "NEWS":
              return { success: true, value: "NEWS" };
            case "CAMPAIGN":
              return { success: true, value: "CAMPAIGN" };
            case "BOOK_AWARD":
              return { success: true, value: "BOOK_AWARD" };
            default:
              return { success: false };
          }
        })(value);
      })(value["tag"]);
    })();
    if (!parsed_tag.success) {
      return { success: false };
    }
    if (!hasProp(value, "web")) {
      return { success: false };
    }
    const parsed_web = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["web"]);
    })();
    if (!parsed_web.success) {
      return { success: false };
    }
    if (!hasProp(value, "webLink")) {
      return { success: false };
    }
    const parsed_webLink = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["webLink"]);
    })();
    if (!parsed_webLink.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        excludedLanguages: parsed_excludedLanguages.value,
        imageUri: parsed_imageUri.value,
        localizedDescription: parsed_localizedDescription.value,
        localizedImageUris: parsed_localizedImageUris.value,
        localizedTitle: parsed_localizedTitle.value,
        localizedWebLinks: parsed_localizedWebLinks.value,
        loggedIn: parsed_loggedIn.value,
        loggedOut: parsed_loggedOut.value,
        mobile: parsed_mobile.value,
        name: parsed_name.value,
        requiredFeatures: parsed_requiredFeatures.value,
        tag: parsed_tag.value,
        web: parsed_web.value,
        webLink: parsed_webLink.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PromoBoxTag
export function serialize_PromoBoxTag(obj: PromoBoxTag): string {
  const serialized = ((
    value:
      | "UNDEFINED"
      | "EXPERT_WEBINAR"
      | "PRO_TALK"
      | "FEATURED"
      | "FEATURED_SUMMARY"
      | "FEATURED_ACTIONABLE"
      | "FEATURED_TOPIC"
      | "EMERGING_TREND"
      | "INTERNATIONAL_DAY"
      | "LEARNING_AT_WORK_WEEK"
      | "Q_A"
      | "VALUES_AND_CAPABILITIES"
      | "RECOMMENDED_READS"
      | "INTERNAL_THOUGHT_LEADER"
      | "READING_CALENDAR"
      | "NEW"
      | "SPOTLIGHT"
      | "PROMOTION"
      | "DEAL"
      | "NEWS"
      | "CAMPAIGN"
      | "BOOK_AWARD",
  ): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PromoBoxTag(str: string): PromoBoxTag | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value:
          | "UNDEFINED"
          | "EXPERT_WEBINAR"
          | "PRO_TALK"
          | "FEATURED"
          | "FEATURED_SUMMARY"
          | "FEATURED_ACTIONABLE"
          | "FEATURED_TOPIC"
          | "EMERGING_TREND"
          | "INTERNATIONAL_DAY"
          | "LEARNING_AT_WORK_WEEK"
          | "Q_A"
          | "VALUES_AND_CAPABILITIES"
          | "RECOMMENDED_READS"
          | "INTERNAL_THOUGHT_LEADER"
          | "READING_CALENDAR"
          | "NEW"
          | "SPOTLIGHT"
          | "PROMOTION"
          | "DEAL"
          | "NEWS"
          | "CAMPAIGN"
          | "BOOK_AWARD";
      } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "UNDEFINED":
        return { success: true, value: "UNDEFINED" };
      case "EXPERT_WEBINAR":
        return { success: true, value: "EXPERT_WEBINAR" };
      case "PRO_TALK":
        return { success: true, value: "PRO_TALK" };
      case "FEATURED":
        return { success: true, value: "FEATURED" };
      case "FEATURED_SUMMARY":
        return { success: true, value: "FEATURED_SUMMARY" };
      case "FEATURED_ACTIONABLE":
        return { success: true, value: "FEATURED_ACTIONABLE" };
      case "FEATURED_TOPIC":
        return { success: true, value: "FEATURED_TOPIC" };
      case "EMERGING_TREND":
        return { success: true, value: "EMERGING_TREND" };
      case "INTERNATIONAL_DAY":
        return { success: true, value: "INTERNATIONAL_DAY" };
      case "LEARNING_AT_WORK_WEEK":
        return { success: true, value: "LEARNING_AT_WORK_WEEK" };
      case "Q_A":
        return { success: true, value: "Q_A" };
      case "VALUES_AND_CAPABILITIES":
        return { success: true, value: "VALUES_AND_CAPABILITIES" };
      case "RECOMMENDED_READS":
        return { success: true, value: "RECOMMENDED_READS" };
      case "INTERNAL_THOUGHT_LEADER":
        return { success: true, value: "INTERNAL_THOUGHT_LEADER" };
      case "READING_CALENDAR":
        return { success: true, value: "READING_CALENDAR" };
      case "NEW":
        return { success: true, value: "NEW" };
      case "SPOTLIGHT":
        return { success: true, value: "SPOTLIGHT" };
      case "PROMOTION":
        return { success: true, value: "PROMOTION" };
      case "DEAL":
        return { success: true, value: "DEAL" };
      case "NEWS":
        return { success: true, value: "NEWS" };
      case "CAMPAIGN":
        return { success: true, value: "CAMPAIGN" };
      case "BOOK_AWARD":
        return { success: true, value: "BOOK_AWARD" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PromoBoxView
export function serialize_PromoBoxView(obj: PromoBoxView): string {
  const serialized = ((value: {
    backgroundColor: string | null;
    brightTextColor: boolean;
    description: string | null;
    designApproved: boolean;
    imageUri: string;
    promoBoxId: number;
    tag: PromoBoxTag;
    title: string | null;
    webLink: string;
  }): unknown => {
    return {
      backgroundColor: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["backgroundColor"]),
      brightTextColor: ((value: boolean): unknown => {
        return value;
      })(value["brightTextColor"]),
      description: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["description"]),
      designApproved: ((value: boolean): unknown => {
        return value;
      })(value["designApproved"]),
      imageUri: ((value: string): unknown => {
        return value;
      })(value["imageUri"]),
      promoBoxId: ((value: number): unknown => {
        return value;
      })(value["promoBoxId"]),
      tag: ((
        value:
          | "UNDEFINED"
          | "EXPERT_WEBINAR"
          | "PRO_TALK"
          | "FEATURED"
          | "FEATURED_SUMMARY"
          | "FEATURED_ACTIONABLE"
          | "FEATURED_TOPIC"
          | "EMERGING_TREND"
          | "INTERNATIONAL_DAY"
          | "LEARNING_AT_WORK_WEEK"
          | "Q_A"
          | "VALUES_AND_CAPABILITIES"
          | "RECOMMENDED_READS"
          | "INTERNAL_THOUGHT_LEADER"
          | "READING_CALENDAR"
          | "NEW"
          | "SPOTLIGHT"
          | "PROMOTION"
          | "DEAL"
          | "NEWS"
          | "CAMPAIGN"
          | "BOOK_AWARD",
      ): unknown => {
        return value;
      })(value["tag"]),
      title: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["title"]),
      webLink: ((value: string): unknown => {
        return value;
      })(value["webLink"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PromoBoxView(str: string): PromoBoxView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          backgroundColor: string | null;
          brightTextColor: boolean;
          description: string | null;
          designApproved: boolean;
          imageUri: string;
          promoBoxId: number;
          tag: PromoBoxTag;
          title: string | null;
          webLink: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "backgroundColor")) {
      return { success: false };
    }
    const parsed_backgroundColor = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["backgroundColor"]);
    })();
    if (!parsed_backgroundColor.success) {
      return { success: false };
    }
    if (!hasProp(value, "brightTextColor")) {
      return { success: false };
    }
    const parsed_brightTextColor = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["brightTextColor"]);
    })();
    if (!parsed_brightTextColor.success) {
      return { success: false };
    }
    if (!hasProp(value, "description")) {
      return { success: false };
    }
    const parsed_description = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["description"]);
    })();
    if (!parsed_description.success) {
      return { success: false };
    }
    if (!hasProp(value, "designApproved")) {
      return { success: false };
    }
    const parsed_designApproved = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["designApproved"]);
    })();
    if (!parsed_designApproved.success) {
      return { success: false };
    }
    if (!hasProp(value, "imageUri")) {
      return { success: false };
    }
    const parsed_imageUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["imageUri"]);
    })();
    if (!parsed_imageUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "promoBoxId")) {
      return { success: false };
    }
    const parsed_promoBoxId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["promoBoxId"]);
    })();
    if (!parsed_promoBoxId.success) {
      return { success: false };
    }
    if (!hasProp(value, "tag")) {
      return { success: false };
    }
    const parsed_tag = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PromoBoxTag } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value:
                | "UNDEFINED"
                | "EXPERT_WEBINAR"
                | "PRO_TALK"
                | "FEATURED"
                | "FEATURED_SUMMARY"
                | "FEATURED_ACTIONABLE"
                | "FEATURED_TOPIC"
                | "EMERGING_TREND"
                | "INTERNATIONAL_DAY"
                | "LEARNING_AT_WORK_WEEK"
                | "Q_A"
                | "VALUES_AND_CAPABILITIES"
                | "RECOMMENDED_READS"
                | "INTERNAL_THOUGHT_LEADER"
                | "READING_CALENDAR"
                | "NEW"
                | "SPOTLIGHT"
                | "PROMOTION"
                | "DEAL"
                | "NEWS"
                | "CAMPAIGN"
                | "BOOK_AWARD";
            } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "UNDEFINED":
              return { success: true, value: "UNDEFINED" };
            case "EXPERT_WEBINAR":
              return { success: true, value: "EXPERT_WEBINAR" };
            case "PRO_TALK":
              return { success: true, value: "PRO_TALK" };
            case "FEATURED":
              return { success: true, value: "FEATURED" };
            case "FEATURED_SUMMARY":
              return { success: true, value: "FEATURED_SUMMARY" };
            case "FEATURED_ACTIONABLE":
              return { success: true, value: "FEATURED_ACTIONABLE" };
            case "FEATURED_TOPIC":
              return { success: true, value: "FEATURED_TOPIC" };
            case "EMERGING_TREND":
              return { success: true, value: "EMERGING_TREND" };
            case "INTERNATIONAL_DAY":
              return { success: true, value: "INTERNATIONAL_DAY" };
            case "LEARNING_AT_WORK_WEEK":
              return { success: true, value: "LEARNING_AT_WORK_WEEK" };
            case "Q_A":
              return { success: true, value: "Q_A" };
            case "VALUES_AND_CAPABILITIES":
              return { success: true, value: "VALUES_AND_CAPABILITIES" };
            case "RECOMMENDED_READS":
              return { success: true, value: "RECOMMENDED_READS" };
            case "INTERNAL_THOUGHT_LEADER":
              return { success: true, value: "INTERNAL_THOUGHT_LEADER" };
            case "READING_CALENDAR":
              return { success: true, value: "READING_CALENDAR" };
            case "NEW":
              return { success: true, value: "NEW" };
            case "SPOTLIGHT":
              return { success: true, value: "SPOTLIGHT" };
            case "PROMOTION":
              return { success: true, value: "PROMOTION" };
            case "DEAL":
              return { success: true, value: "DEAL" };
            case "NEWS":
              return { success: true, value: "NEWS" };
            case "CAMPAIGN":
              return { success: true, value: "CAMPAIGN" };
            case "BOOK_AWARD":
              return { success: true, value: "BOOK_AWARD" };
            default:
              return { success: false };
          }
        })(value);
      })(value["tag"]);
    })();
    if (!parsed_tag.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    if (!hasProp(value, "webLink")) {
      return { success: false };
    }
    const parsed_webLink = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["webLink"]);
    })();
    if (!parsed_webLink.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        backgroundColor: parsed_backgroundColor.value,
        brightTextColor: parsed_brightTextColor.value,
        description: parsed_description.value,
        designApproved: parsed_designApproved.value,
        imageUri: parsed_imageUri.value,
        promoBoxId: parsed_promoBoxId.value,
        tag: parsed_tag.value,
        title: parsed_title.value,
        webLink: parsed_webLink.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// PushFrequency
export function serialize_PushFrequency(obj: PushFrequency): string {
  const serialized = ((value: "NONE" | "DAILY" | "ONCE_A_WEEK" | "TWICE_A_MONTH" | "ONCE_A_MONTH"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_PushFrequency(str: string): PushFrequency | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "NONE" | "DAILY" | "ONCE_A_WEEK" | "TWICE_A_MONTH" | "ONCE_A_MONTH" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "NONE":
        return { success: true, value: "NONE" };
      case "DAILY":
        return { success: true, value: "DAILY" };
      case "ONCE_A_WEEK":
        return { success: true, value: "ONCE_A_WEEK" };
      case "TWICE_A_MONTH":
        return { success: true, value: "TWICE_A_MONTH" };
      case "ONCE_A_MONTH":
        return { success: true, value: "ONCE_A_MONTH" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ReferencedItemEdit
export function serialize_ReferencedItemEdit(obj: ReferencedItemEdit): string {
  const serialized = ((value: { refItem: ReferencedLearningItem; tags: Tag[] }): unknown => {
    return {
      refItem: ((value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint }): unknown => {
        switch (value.learningItemType) {
          case "SUMMARY": {
            return {
              learningItemType: "SUMMARY",
              id: ((value: number): unknown => {
                return value;
              })(value["id"]),
            };
          }
          case "ACTIONABLE": {
            return {
              learningItemType: "ACTIONABLE",
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
            };
          }
        }
      })(value["refItem"]),
      tags: ((value: Tag[]): unknown => {
        return value.map(
          (
            value:
              | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
              | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
              | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
              | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
              | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
              | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
              | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
              | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
              | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
          ): unknown => {
            switch (value.discriminator) {
              case "dynamicString": {
                return {
                  discriminator: "dynamicString",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicString"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicBoolean": {
                return {
                  discriminator: "dynamicBoolean",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicBoolean"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: boolean): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicNumber": {
                return {
                  discriminator: "dynamicNumber",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicNumber"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: number): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicVoid": {
                return {
                  discriminator: "dynamicVoid",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicVoid"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                };
              }
              case "channel": {
                return {
                  discriminator: "channel",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                    return {
                      discriminator: ((value: "channel"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "channel"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "primary-skill": {
                return {
                  discriminator: "primary-skill",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                    return {
                      discriminator: ((value: "primary-skill"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "primary-skill"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "skill": {
                return {
                  discriminator: "skill",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                    return {
                      discriminator: ((value: "skill"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "skill"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "key-quality": {
                return {
                  discriminator: "key-quality",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                    return {
                      discriminator: ((value: "key-quality"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "key-quality"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "classification": {
                return {
                  discriminator: "classification",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                    return {
                      discriminator: ((value: "classification"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "classification"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
            }
          },
        );
      })(value["tags"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ReferencedItemEdit(str: string): ReferencedItemEdit | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          refItem: ReferencedLearningItem;
          tags: Tag[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "refItem")) {
      return { success: false };
    }
    const parsed_refItem = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ReferencedLearningItem } => {
        return ((value: unknown): { success: false } | { success: true; value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint } } => {
          return ((value: unknown): { success: false } | { success: true; value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint } } => {
            if (value == null) {
              return { success: false };
            }
            if (typeof value != "object") {
              return { success: false };
            }
            if (!hasProp(value, "learningItemType")) {
              return { success: false };
            }
            if (typeof value["learningItemType"] != "string") {
              return { success: false };
            }
            const discriminator = value["learningItemType"];
            switch (discriminator) {
              case "SUMMARY": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        id: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  return { success: true, value: { id: parsed_id.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { learningItemType: "SUMMARY" as const, ...parsedVariant.value } };
              }
              case "ACTIONABLE": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        id: bigint;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  return { success: true, value: { id: parsed_id.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { learningItemType: "ACTIONABLE" as const, ...parsedVariant.value } };
              }
              default:
                return { success: false };
            }
          })(value);
        })(value);
      })(value["refItem"]);
    })();
    if (!parsed_refItem.success) {
      return { success: false };
    }
    if (!hasProp(value, "tags")) {
      return { success: false };
    }
    const parsed_tags = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                  | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                    | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                    | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                    | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                    | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                    | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                    | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                    | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                    | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "discriminator")) {
                return { success: false };
              }
              if (typeof value["discriminator"] != "string") {
                return { success: false };
              }
              const discriminator = value["discriminator"];
              switch (discriminator) {
                case "dynamicString": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicString;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicString;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicString":
                                    return { success: true, value: "dynamicString" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                }
                case "dynamicBoolean": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicBoolean;
                          value: boolean;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicBoolean;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicBoolean":
                                    return { success: true, value: "dynamicBoolean" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                }
                case "dynamicNumber": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicNumber;
                          value: number;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicNumber;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicNumber":
                                    return { success: true, value: "dynamicNumber" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                }
                case "dynamicVoid": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicVoid;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicVoid;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicVoid":
                                    return { success: true, value: "dynamicVoid" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                }
                case "channel": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindChannel;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindChannel;
                                id: bigint;
                                kind: KindChannel;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                              return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "channel":
                                    return { success: true, value: "channel" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                              return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "channel":
                                    return { success: true, value: "channel" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                }
                case "primary-skill": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindPrimarySkill;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindPrimarySkill;
                                id: bigint;
                                kind: KindPrimarySkill;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "primary-skill":
                                    return { success: true, value: "primary-skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "primary-skill":
                                    return { success: true, value: "primary-skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                }
                case "skill": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindSkill;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindSkill;
                                id: bigint;
                                kind: KindSkill;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "skill":
                                    return { success: true, value: "skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "skill":
                                    return { success: true, value: "skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                }
                case "key-quality": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindKeyQuality;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindKeyQuality;
                                id: bigint;
                                kind: KindKeyQuality;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                              return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "key-quality":
                                    return { success: true, value: "key-quality" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                              return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "key-quality":
                                    return { success: true, value: "key-quality" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                }
                case "classification": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindClassification;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindClassification;
                                id: bigint;
                                kind: KindClassification;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                              return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "classification":
                                    return { success: true, value: "classification" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                              return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "classification":
                                    return { success: true, value: "classification" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["tags"]);
    })();
    if (!parsed_tags.success) {
      return { success: false };
    }
    return { success: true, value: { refItem: parsed_refItem.value, tags: parsed_tags.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ReferencedItemSaveRequest
export function serialize_ReferencedItemSaveRequest(obj: ReferencedItemSaveRequest): string {
  const serialized = ((value: { items: ReferencedItemEdit[] }): unknown => {
    return {
      items: ((value: ReferencedItemEdit[]): unknown => {
        return value.map((value: { refItem: ReferencedLearningItem; tags: Tag[] }): unknown => {
          return {
            refItem: ((value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint }): unknown => {
              switch (value.learningItemType) {
                case "SUMMARY": {
                  return {
                    learningItemType: "SUMMARY",
                    id: ((value: number): unknown => {
                      return value;
                    })(value["id"]),
                  };
                }
                case "ACTIONABLE": {
                  return {
                    learningItemType: "ACTIONABLE",
                    id: ((value: bigint): unknown => {
                      return value;
                    })(value["id"]),
                  };
                }
              }
            })(value["refItem"]),
            tags: ((value: Tag[]): unknown => {
              return value.map(
                (
                  value:
                    | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                    | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                    | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                    | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                    | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                    | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                    | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                    | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                    | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
                ): unknown => {
                  switch (value.discriminator) {
                    case "dynamicString": {
                      return {
                        discriminator: "dynamicString",
                        exclude: ((value: boolean): unknown => {
                          return value;
                        })(value["exclude"]),
                        source: ((value: "USER" | "AI"): unknown => {
                          return value;
                        })(value["source"]),
                        tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                          return {
                            discriminator: ((value: "dynamicString"): unknown => {
                              return value;
                            })(value["discriminator"]),
                            id: ((value: bigint): unknown => {
                              return value;
                            })(value["id"]),
                            kind: ((value: string): unknown => {
                              return value;
                            })(value["kind"]),
                          };
                        })(value["tagKind"]),
                        value: ((value: string): unknown => {
                          return value;
                        })(value["value"]),
                      };
                    }
                    case "dynamicBoolean": {
                      return {
                        discriminator: "dynamicBoolean",
                        exclude: ((value: boolean): unknown => {
                          return value;
                        })(value["exclude"]),
                        source: ((value: "USER" | "AI"): unknown => {
                          return value;
                        })(value["source"]),
                        tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                          return {
                            discriminator: ((value: "dynamicBoolean"): unknown => {
                              return value;
                            })(value["discriminator"]),
                            id: ((value: bigint): unknown => {
                              return value;
                            })(value["id"]),
                            kind: ((value: string): unknown => {
                              return value;
                            })(value["kind"]),
                          };
                        })(value["tagKind"]),
                        value: ((value: boolean): unknown => {
                          return value;
                        })(value["value"]),
                      };
                    }
                    case "dynamicNumber": {
                      return {
                        discriminator: "dynamicNumber",
                        exclude: ((value: boolean): unknown => {
                          return value;
                        })(value["exclude"]),
                        source: ((value: "USER" | "AI"): unknown => {
                          return value;
                        })(value["source"]),
                        tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                          return {
                            discriminator: ((value: "dynamicNumber"): unknown => {
                              return value;
                            })(value["discriminator"]),
                            id: ((value: bigint): unknown => {
                              return value;
                            })(value["id"]),
                            kind: ((value: string): unknown => {
                              return value;
                            })(value["kind"]),
                          };
                        })(value["tagKind"]),
                        value: ((value: number): unknown => {
                          return value;
                        })(value["value"]),
                      };
                    }
                    case "dynamicVoid": {
                      return {
                        discriminator: "dynamicVoid",
                        exclude: ((value: boolean): unknown => {
                          return value;
                        })(value["exclude"]),
                        source: ((value: "USER" | "AI"): unknown => {
                          return value;
                        })(value["source"]),
                        tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                          return {
                            discriminator: ((value: "dynamicVoid"): unknown => {
                              return value;
                            })(value["discriminator"]),
                            id: ((value: bigint): unknown => {
                              return value;
                            })(value["id"]),
                            kind: ((value: string): unknown => {
                              return value;
                            })(value["kind"]),
                          };
                        })(value["tagKind"]),
                      };
                    }
                    case "channel": {
                      return {
                        discriminator: "channel",
                        exclude: ((value: boolean): unknown => {
                          return value;
                        })(value["exclude"]),
                        source: ((value: "USER" | "AI"): unknown => {
                          return value;
                        })(value["source"]),
                        tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                          return {
                            discriminator: ((value: "channel"): unknown => {
                              return value;
                            })(value["discriminator"]),
                            id: ((value: bigint): unknown => {
                              return value;
                            })(value["id"]),
                            kind: ((value: "channel"): unknown => {
                              return value;
                            })(value["kind"]),
                          };
                        })(value["tagKind"]),
                        value: ((value: string): unknown => {
                          return value;
                        })(value["value"]),
                      };
                    }
                    case "primary-skill": {
                      return {
                        discriminator: "primary-skill",
                        exclude: ((value: boolean): unknown => {
                          return value;
                        })(value["exclude"]),
                        source: ((value: "USER" | "AI"): unknown => {
                          return value;
                        })(value["source"]),
                        tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                          return {
                            discriminator: ((value: "primary-skill"): unknown => {
                              return value;
                            })(value["discriminator"]),
                            id: ((value: bigint): unknown => {
                              return value;
                            })(value["id"]),
                            kind: ((value: "primary-skill"): unknown => {
                              return value;
                            })(value["kind"]),
                          };
                        })(value["tagKind"]),
                        value: ((value: string): unknown => {
                          return value;
                        })(value["value"]),
                      };
                    }
                    case "skill": {
                      return {
                        discriminator: "skill",
                        exclude: ((value: boolean): unknown => {
                          return value;
                        })(value["exclude"]),
                        source: ((value: "USER" | "AI"): unknown => {
                          return value;
                        })(value["source"]),
                        tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                          return {
                            discriminator: ((value: "skill"): unknown => {
                              return value;
                            })(value["discriminator"]),
                            id: ((value: bigint): unknown => {
                              return value;
                            })(value["id"]),
                            kind: ((value: "skill"): unknown => {
                              return value;
                            })(value["kind"]),
                          };
                        })(value["tagKind"]),
                        value: ((value: string): unknown => {
                          return value;
                        })(value["value"]),
                      };
                    }
                    case "key-quality": {
                      return {
                        discriminator: "key-quality",
                        exclude: ((value: boolean): unknown => {
                          return value;
                        })(value["exclude"]),
                        source: ((value: "USER" | "AI"): unknown => {
                          return value;
                        })(value["source"]),
                        tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                          return {
                            discriminator: ((value: "key-quality"): unknown => {
                              return value;
                            })(value["discriminator"]),
                            id: ((value: bigint): unknown => {
                              return value;
                            })(value["id"]),
                            kind: ((value: "key-quality"): unknown => {
                              return value;
                            })(value["kind"]),
                          };
                        })(value["tagKind"]),
                        value: ((value: string): unknown => {
                          return value;
                        })(value["value"]),
                      };
                    }
                    case "classification": {
                      return {
                        discriminator: "classification",
                        exclude: ((value: boolean): unknown => {
                          return value;
                        })(value["exclude"]),
                        source: ((value: "USER" | "AI"): unknown => {
                          return value;
                        })(value["source"]),
                        tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                          return {
                            discriminator: ((value: "classification"): unknown => {
                              return value;
                            })(value["discriminator"]),
                            id: ((value: bigint): unknown => {
                              return value;
                            })(value["id"]),
                            kind: ((value: "classification"): unknown => {
                              return value;
                            })(value["kind"]),
                          };
                        })(value["tagKind"]),
                        value: ((value: string): unknown => {
                          return value;
                        })(value["value"]),
                      };
                    }
                  }
                },
              );
            })(value["tags"]),
          };
        });
      })(value["items"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ReferencedItemSaveRequest(str: string): ReferencedItemSaveRequest | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: ReferencedItemEdit[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ReferencedItemEdit[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ReferencedItemEdit } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  refItem: ReferencedLearningItem;
                  tags: Tag[];
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "refItem")) {
              return { success: false };
            }
            const parsed_refItem = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ReferencedLearningItem } => {
                return ((value: unknown): { success: false } | { success: true; value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint } } => {
                  return ((value: unknown): { success: false } | { success: true; value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint } } => {
                    if (value == null) {
                      return { success: false };
                    }
                    if (typeof value != "object") {
                      return { success: false };
                    }
                    if (!hasProp(value, "learningItemType")) {
                      return { success: false };
                    }
                    if (typeof value["learningItemType"] != "string") {
                      return { success: false };
                    }
                    const discriminator = value["learningItemType"];
                    switch (discriminator) {
                      case "SUMMARY": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                id: number;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: number } => {
                              if (typeof value === "number") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { id: parsed_id.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { learningItemType: "SUMMARY" as const, ...parsedVariant.value } };
                      }
                      case "ACTIONABLE": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                id: bigint;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          return { success: true, value: { id: parsed_id.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { learningItemType: "ACTIONABLE" as const, ...parsedVariant.value } };
                      }
                      default:
                        return { success: false };
                    }
                  })(value);
                })(value);
              })(value["refItem"]);
            })();
            if (!parsed_refItem.success) {
              return { success: false };
            }
            if (!hasProp(value, "tags")) {
              return { success: false };
            }
            const parsed_tags = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value:
                          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                          | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                          | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                      } => {
                    return ((
                      value: unknown,
                    ):
                      | { success: false }
                      | {
                          success: true;
                          value:
                            | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                            | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                            | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                            | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                            | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                            | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                            | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                            | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                            | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                        } => {
                      if (value == null) {
                        return { success: false };
                      }
                      if (typeof value != "object") {
                        return { success: false };
                      }
                      if (!hasProp(value, "discriminator")) {
                        return { success: false };
                      }
                      if (typeof value["discriminator"] != "string") {
                        return { success: false };
                      }
                      const discriminator = value["discriminator"];
                      switch (discriminator) {
                        case "dynamicString": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicString;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicString;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicString":
                                            return { success: true, value: "dynamicString" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                        }
                        case "dynamicBoolean": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicBoolean;
                                  value: boolean;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicBoolean;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicBoolean":
                                            return { success: true, value: "dynamicBoolean" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                        }
                        case "dynamicNumber": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicNumber;
                                  value: number;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicNumber;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicNumber":
                                            return { success: true, value: "dynamicNumber" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: number } => {
                                if (typeof value === "number") {
                                  return { success: true, value: value };
                                }
                                return { success: false };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                        }
                        case "dynamicVoid": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicVoid;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicVoid;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicVoid":
                                            return { success: true, value: "dynamicVoid" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                        }
                        case "channel": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindChannel;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindChannel;
                                        id: bigint;
                                        kind: KindChannel;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "channel":
                                            return { success: true, value: "channel" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "channel":
                                            return { success: true, value: "channel" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                        }
                        case "primary-skill": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindPrimarySkill;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindPrimarySkill;
                                        id: bigint;
                                        kind: KindPrimarySkill;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "primary-skill":
                                            return { success: true, value: "primary-skill" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "primary-skill":
                                            return { success: true, value: "primary-skill" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                        }
                        case "skill": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindSkill;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindSkill;
                                        id: bigint;
                                        kind: KindSkill;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "skill":
                                            return { success: true, value: "skill" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "skill":
                                            return { success: true, value: "skill" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                        }
                        case "key-quality": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindKeyQuality;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindKeyQuality;
                                        id: bigint;
                                        kind: KindKeyQuality;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "key-quality":
                                            return { success: true, value: "key-quality" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "key-quality":
                                            return { success: true, value: "key-quality" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                        }
                        case "classification": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindClassification;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindClassification;
                                        id: bigint;
                                        kind: KindClassification;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "classification":
                                            return { success: true, value: "classification" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "classification":
                                            return { success: true, value: "classification" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                        }
                        default:
                          return { success: false };
                      }
                    })(value);
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["tags"]);
            })();
            if (!parsed_tags.success) {
              return { success: false };
            }
            return { success: true, value: { refItem: parsed_refItem.value, tags: parsed_tags.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ReferencedLearningItem
export function serialize_ReferencedLearningItem(obj: ReferencedLearningItem): string {
  const serialized = ((value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint }): unknown => {
    switch (value.learningItemType) {
      case "SUMMARY": {
        return {
          learningItemType: "SUMMARY",
          id: ((value: number): unknown => {
            return value;
          })(value["id"]),
        };
      }
      case "ACTIONABLE": {
        return {
          learningItemType: "ACTIONABLE",
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ReferencedLearningItem(str: string): ReferencedLearningItem | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint } } => {
    return ((value: unknown): { success: false } | { success: true; value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint } } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "learningItemType")) {
        return { success: false };
      }
      if (typeof value["learningItemType"] != "string") {
        return { success: false };
      }
      const discriminator = value["learningItemType"];
      switch (discriminator) {
        case "SUMMARY": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { learningItemType: "SUMMARY" as const, ...parsedVariant.value } };
        }
        case "ACTIONABLE": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: bigint;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { learningItemType: "ACTIONABLE" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ReferencedLearningItemList
export function serialize_ReferencedLearningItemList(obj: ReferencedLearningItemList): string {
  const serialized = ((value: { items: ReferencedLearningItem[] }): unknown => {
    return {
      items: ((value: ReferencedLearningItem[]): unknown => {
        return value.map((value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint }): unknown => {
          switch (value.learningItemType) {
            case "SUMMARY": {
              return {
                learningItemType: "SUMMARY",
                id: ((value: number): unknown => {
                  return value;
                })(value["id"]),
              };
            }
            case "ACTIONABLE": {
              return {
                learningItemType: "ACTIONABLE",
                id: ((value: bigint): unknown => {
                  return value;
                })(value["id"]),
              };
            }
          }
        });
      })(value["items"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ReferencedLearningItemList(str: string): ReferencedLearningItemList | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: ReferencedLearningItem[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ReferencedLearningItem[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: ReferencedLearningItem } => {
          return ((value: unknown): { success: false } | { success: true; value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint } } => {
            return ((value: unknown): { success: false } | { success: true; value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint } } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "learningItemType")) {
                return { success: false };
              }
              if (typeof value["learningItemType"] != "string") {
                return { success: false };
              }
              const discriminator = value["learningItemType"];
              switch (discriminator) {
                case "SUMMARY": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: number;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { learningItemType: "SUMMARY" as const, ...parsedVariant.value } };
                }
                case "ACTIONABLE": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { learningItemType: "ACTIONABLE" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// RenewSubscriptionForm
export function serialize_RenewSubscriptionForm(obj: RenewSubscriptionForm): string {
  const serialized = ((value: {
    ccNumber: string | null;
    ccType: string | null;
    cvv: string | null;
    existing: boolean;
    expMonth: string | null;
    expYear: string | null;
    lsvAccountNumber: string | null;
    lsvBankName: string | null;
    lsvBankTown: string | null;
    lsvClearingNumber: string | null;
    lsvFirstName: string | null;
    lsvIban: string | null;
    lsvLastName: string | null;
    manualPrice: number | null;
    paymentType: string | null;
    productId: number | null;
    promotionCode: string | null;
    street: string | null;
    subscriptionId: number;
    zip: string | null;
  }): unknown => {
    return {
      ccNumber: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["ccNumber"]),
      ccType: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["ccType"]),
      cvv: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["cvv"]),
      existing: ((value: boolean): unknown => {
        return value;
      })(value["existing"]),
      expMonth: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["expMonth"]),
      expYear: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["expYear"]),
      lsvAccountNumber: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lsvAccountNumber"]),
      lsvBankName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lsvBankName"]),
      lsvBankTown: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lsvBankTown"]),
      lsvClearingNumber: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lsvClearingNumber"]),
      lsvFirstName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lsvFirstName"]),
      lsvIban: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lsvIban"]),
      lsvLastName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lsvLastName"]),
      manualPrice: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["manualPrice"]),
      paymentType: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["paymentType"]),
      productId: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["productId"]),
      promotionCode: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["promotionCode"]),
      street: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["street"]),
      subscriptionId: ((value: number): unknown => {
        return value;
      })(value["subscriptionId"]),
      zip: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["zip"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_RenewSubscriptionForm(str: string): RenewSubscriptionForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          ccNumber: string | null;
          ccType: string | null;
          cvv: string | null;
          existing: boolean;
          expMonth: string | null;
          expYear: string | null;
          lsvAccountNumber: string | null;
          lsvBankName: string | null;
          lsvBankTown: string | null;
          lsvClearingNumber: string | null;
          lsvFirstName: string | null;
          lsvIban: string | null;
          lsvLastName: string | null;
          manualPrice: number | null;
          paymentType: string | null;
          productId: number | null;
          promotionCode: string | null;
          street: string | null;
          subscriptionId: number;
          zip: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "ccNumber")) {
      return { success: false };
    }
    const parsed_ccNumber = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ccNumber"]);
    })();
    if (!parsed_ccNumber.success) {
      return { success: false };
    }
    if (!hasProp(value, "ccType")) {
      return { success: false };
    }
    const parsed_ccType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ccType"]);
    })();
    if (!parsed_ccType.success) {
      return { success: false };
    }
    if (!hasProp(value, "cvv")) {
      return { success: false };
    }
    const parsed_cvv = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["cvv"]);
    })();
    if (!parsed_cvv.success) {
      return { success: false };
    }
    if (!hasProp(value, "existing")) {
      return { success: false };
    }
    const parsed_existing = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["existing"]);
    })();
    if (!parsed_existing.success) {
      return { success: false };
    }
    if (!hasProp(value, "expMonth")) {
      return { success: false };
    }
    const parsed_expMonth = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["expMonth"]);
    })();
    if (!parsed_expMonth.success) {
      return { success: false };
    }
    if (!hasProp(value, "expYear")) {
      return { success: false };
    }
    const parsed_expYear = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["expYear"]);
    })();
    if (!parsed_expYear.success) {
      return { success: false };
    }
    if (!hasProp(value, "lsvAccountNumber")) {
      return { success: false };
    }
    const parsed_lsvAccountNumber = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lsvAccountNumber"]);
    })();
    if (!parsed_lsvAccountNumber.success) {
      return { success: false };
    }
    if (!hasProp(value, "lsvBankName")) {
      return { success: false };
    }
    const parsed_lsvBankName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lsvBankName"]);
    })();
    if (!parsed_lsvBankName.success) {
      return { success: false };
    }
    if (!hasProp(value, "lsvBankTown")) {
      return { success: false };
    }
    const parsed_lsvBankTown = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lsvBankTown"]);
    })();
    if (!parsed_lsvBankTown.success) {
      return { success: false };
    }
    if (!hasProp(value, "lsvClearingNumber")) {
      return { success: false };
    }
    const parsed_lsvClearingNumber = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lsvClearingNumber"]);
    })();
    if (!parsed_lsvClearingNumber.success) {
      return { success: false };
    }
    if (!hasProp(value, "lsvFirstName")) {
      return { success: false };
    }
    const parsed_lsvFirstName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lsvFirstName"]);
    })();
    if (!parsed_lsvFirstName.success) {
      return { success: false };
    }
    if (!hasProp(value, "lsvIban")) {
      return { success: false };
    }
    const parsed_lsvIban = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lsvIban"]);
    })();
    if (!parsed_lsvIban.success) {
      return { success: false };
    }
    if (!hasProp(value, "lsvLastName")) {
      return { success: false };
    }
    const parsed_lsvLastName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lsvLastName"]);
    })();
    if (!parsed_lsvLastName.success) {
      return { success: false };
    }
    if (!hasProp(value, "manualPrice")) {
      return { success: false };
    }
    const parsed_manualPrice = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        if (typeof value === "bigint") {
          return { success: true, value: Number(value) };
        }
        return { success: false };
      })(value["manualPrice"]);
    })();
    if (!parsed_manualPrice.success) {
      return { success: false };
    }
    if (!hasProp(value, "paymentType")) {
      return { success: false };
    }
    const parsed_paymentType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["paymentType"]);
    })();
    if (!parsed_paymentType.success) {
      return { success: false };
    }
    if (!hasProp(value, "productId")) {
      return { success: false };
    }
    const parsed_productId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["productId"]);
    })();
    if (!parsed_productId.success) {
      return { success: false };
    }
    if (!hasProp(value, "promotionCode")) {
      return { success: false };
    }
    const parsed_promotionCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["promotionCode"]);
    })();
    if (!parsed_promotionCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "street")) {
      return { success: false };
    }
    const parsed_street = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["street"]);
    })();
    if (!parsed_street.success) {
      return { success: false };
    }
    if (!hasProp(value, "subscriptionId")) {
      return { success: false };
    }
    const parsed_subscriptionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["subscriptionId"]);
    })();
    if (!parsed_subscriptionId.success) {
      return { success: false };
    }
    if (!hasProp(value, "zip")) {
      return { success: false };
    }
    const parsed_zip = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["zip"]);
    })();
    if (!parsed_zip.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        ccNumber: parsed_ccNumber.value,
        ccType: parsed_ccType.value,
        cvv: parsed_cvv.value,
        existing: parsed_existing.value,
        expMonth: parsed_expMonth.value,
        expYear: parsed_expYear.value,
        lsvAccountNumber: parsed_lsvAccountNumber.value,
        lsvBankName: parsed_lsvBankName.value,
        lsvBankTown: parsed_lsvBankTown.value,
        lsvClearingNumber: parsed_lsvClearingNumber.value,
        lsvFirstName: parsed_lsvFirstName.value,
        lsvIban: parsed_lsvIban.value,
        lsvLastName: parsed_lsvLastName.value,
        manualPrice: parsed_manualPrice.value,
        paymentType: parsed_paymentType.value,
        productId: parsed_productId.value,
        promotionCode: parsed_promotionCode.value,
        street: parsed_street.value,
        subscriptionId: parsed_subscriptionId.value,
        zip: parsed_zip.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ReportFrequency
export function serialize_ReportFrequency(obj: ReportFrequency): string {
  const serialized = ((value: "ONE_TIME" | "DAILY" | "WEEKLY" | "MONTHLY"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ReportFrequency(str: string): ReportFrequency | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "ONE_TIME" | "DAILY" | "WEEKLY" | "MONTHLY" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "ONE_TIME":
        return { success: true, value: "ONE_TIME" };
      case "DAILY":
        return { success: true, value: "DAILY" };
      case "WEEKLY":
        return { success: true, value: "WEEKLY" };
      case "MONTHLY":
        return { success: true, value: "MONTHLY" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ReportListView
export function serialize_ReportListView(obj: ReportListView): string {
  const serialized = ((value: {
    active: boolean;
    corporateId: number;
    customerFullName: string;
    frequency: ReportFrequency;
    isRunning: boolean | null;
    name: string;
    prefixFileName: boolean;
    reportId: number;
    reportType: ReportType;
    targetExcel: boolean;
    timeFrameFrom: { year: number; month: number; day: number };
    timeFrameTo: { year: number; month: number; day: number };
    zipFile: boolean;
  }): unknown => {
    return {
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      corporateId: ((value: number): unknown => {
        return value;
      })(value["corporateId"]),
      customerFullName: ((value: string): unknown => {
        return value;
      })(value["customerFullName"]),
      frequency: ((value: "ONE_TIME" | "DAILY" | "WEEKLY" | "MONTHLY"): unknown => {
        return value;
      })(value["frequency"]),
      isRunning: ((value: boolean | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["isRunning"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      prefixFileName: ((value: boolean): unknown => {
        return value;
      })(value["prefixFileName"]),
      reportId: ((value: number): unknown => {
        return value;
      })(value["reportId"]),
      reportType: ((value: "USERS" | "ACTIVITY" | "EXTENDED_USERS_EXPORT" | "ACTIONABLES_ACTIVITY"): unknown => {
        return value;
      })(value["reportType"]),
      targetExcel: ((value: boolean): unknown => {
        return value;
      })(value["targetExcel"]),
      timeFrameFrom: ((value: { year: number; month: number; day: number }): unknown => {
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["timeFrameFrom"]),
      timeFrameTo: ((value: { year: number; month: number; day: number }): unknown => {
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["timeFrameTo"]),
      zipFile: ((value: boolean): unknown => {
        return value;
      })(value["zipFile"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ReportListView(str: string): ReportListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          active: boolean;
          corporateId: number;
          customerFullName: string;
          frequency: ReportFrequency;
          isRunning: boolean | null;
          name: string;
          prefixFileName: boolean;
          reportId: number;
          reportType: ReportType;
          targetExcel: boolean;
          timeFrameFrom: { year: number; month: number; day: number };
          timeFrameTo: { year: number; month: number; day: number };
          zipFile: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerFullName")) {
      return { success: false };
    }
    const parsed_customerFullName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["customerFullName"]);
    })();
    if (!parsed_customerFullName.success) {
      return { success: false };
    }
    if (!hasProp(value, "frequency")) {
      return { success: false };
    }
    const parsed_frequency = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ReportFrequency } => {
        return ((value: unknown): { success: false } | { success: true; value: "ONE_TIME" | "DAILY" | "WEEKLY" | "MONTHLY" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "ONE_TIME":
              return { success: true, value: "ONE_TIME" };
            case "DAILY":
              return { success: true, value: "DAILY" };
            case "WEEKLY":
              return { success: true, value: "WEEKLY" };
            case "MONTHLY":
              return { success: true, value: "MONTHLY" };
            default:
              return { success: false };
          }
        })(value);
      })(value["frequency"]);
    })();
    if (!parsed_frequency.success) {
      return { success: false };
    }
    if (!hasProp(value, "isRunning")) {
      return { success: false };
    }
    const parsed_isRunning = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isRunning"]);
    })();
    if (!parsed_isRunning.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "prefixFileName")) {
      return { success: false };
    }
    const parsed_prefixFileName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["prefixFileName"]);
    })();
    if (!parsed_prefixFileName.success) {
      return { success: false };
    }
    if (!hasProp(value, "reportId")) {
      return { success: false };
    }
    const parsed_reportId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["reportId"]);
    })();
    if (!parsed_reportId.success) {
      return { success: false };
    }
    if (!hasProp(value, "reportType")) {
      return { success: false };
    }
    const parsed_reportType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ReportType } => {
        return ((value: unknown): { success: false } | { success: true; value: "USERS" | "ACTIVITY" | "EXTENDED_USERS_EXPORT" | "ACTIONABLES_ACTIVITY" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "USERS":
              return { success: true, value: "USERS" };
            case "ACTIVITY":
              return { success: true, value: "ACTIVITY" };
            case "EXTENDED_USERS_EXPORT":
              return { success: true, value: "EXTENDED_USERS_EXPORT" };
            case "ACTIONABLES_ACTIVITY":
              return { success: true, value: "ACTIONABLES_ACTIVITY" };
            default:
              return { success: false };
          }
        })(value);
      })(value["reportType"]);
    })();
    if (!parsed_reportType.success) {
      return { success: false };
    }
    if (!hasProp(value, "targetExcel")) {
      return { success: false };
    }
    const parsed_targetExcel = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["targetExcel"]);
    })();
    if (!parsed_targetExcel.success) {
      return { success: false };
    }
    if (!hasProp(value, "timeFrameFrom")) {
      return { success: false };
    }
    const parsed_timeFrameFrom = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["timeFrameFrom"]);
    })();
    if (!parsed_timeFrameFrom.success) {
      return { success: false };
    }
    if (!hasProp(value, "timeFrameTo")) {
      return { success: false };
    }
    const parsed_timeFrameTo = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["timeFrameTo"]);
    })();
    if (!parsed_timeFrameTo.success) {
      return { success: false };
    }
    if (!hasProp(value, "zipFile")) {
      return { success: false };
    }
    const parsed_zipFile = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["zipFile"]);
    })();
    if (!parsed_zipFile.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        active: parsed_active.value,
        corporateId: parsed_corporateId.value,
        customerFullName: parsed_customerFullName.value,
        frequency: parsed_frequency.value,
        isRunning: parsed_isRunning.value,
        name: parsed_name.value,
        prefixFileName: parsed_prefixFileName.value,
        reportId: parsed_reportId.value,
        reportType: parsed_reportType.value,
        targetExcel: parsed_targetExcel.value,
        timeFrameFrom: parsed_timeFrameFrom.value,
        timeFrameTo: parsed_timeFrameTo.value,
        zipFile: parsed_zipFile.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ReportSaveRequest
export function serialize_ReportSaveRequest(obj: ReportSaveRequest): string {
  const serialized = ((value: {
    active: boolean;
    activeUsersOnly: boolean;
    ccRecipients: string;
    corporateId: number;
    customerId: number;
    dayOfWeek: number;
    downloadType: DownloadType;
    frequency: ReportFrequency;
    fromDate: { year: number; month: number; day: number } | null;
    ftpFolder: string;
    ftpHost: string;
    ftpPassphrase: string;
    ftpPassword: string;
    ftpPort: number;
    ftpRemoteFileName: string;
    ftpUsername: string;
    prefixFileName: boolean;
    privateKey: string | null;
    reportId: number;
    reportName: string;
    reportType: ReportType;
    specialReport: boolean;
    targetExcel: boolean;
    toDate: { year: number; month: number; day: number } | null;
    transferProtocol: TransferProtocol;
    zipFile: boolean;
  }): unknown => {
    return {
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      activeUsersOnly: ((value: boolean): unknown => {
        return value;
      })(value["activeUsersOnly"]),
      ccRecipients: ((value: string): unknown => {
        return value;
      })(value["ccRecipients"]),
      corporateId: ((value: number): unknown => {
        return value;
      })(value["corporateId"]),
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
      dayOfWeek: ((value: number): unknown => {
        return value;
      })(value["dayOfWeek"]),
      downloadType: ((value: "PUSH" | "PULL" | "BOTH"): unknown => {
        return value;
      })(value["downloadType"]),
      frequency: ((value: "ONE_TIME" | "DAILY" | "WEEKLY" | "MONTHLY"): unknown => {
        return value;
      })(value["frequency"]),
      fromDate: ((value: { year: number; month: number; day: number } | null): unknown => {
        if (value === null) {
          return null;
        }
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["fromDate"]),
      ftpFolder: ((value: string): unknown => {
        return value;
      })(value["ftpFolder"]),
      ftpHost: ((value: string): unknown => {
        return value;
      })(value["ftpHost"]),
      ftpPassphrase: ((value: string): unknown => {
        return value;
      })(value["ftpPassphrase"]),
      ftpPassword: ((value: string): unknown => {
        return value;
      })(value["ftpPassword"]),
      ftpPort: ((value: number): unknown => {
        return value;
      })(value["ftpPort"]),
      ftpRemoteFileName: ((value: string): unknown => {
        return value;
      })(value["ftpRemoteFileName"]),
      ftpUsername: ((value: string): unknown => {
        return value;
      })(value["ftpUsername"]),
      prefixFileName: ((value: boolean): unknown => {
        return value;
      })(value["prefixFileName"]),
      privateKey: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["privateKey"]),
      reportId: ((value: number): unknown => {
        return value;
      })(value["reportId"]),
      reportName: ((value: string): unknown => {
        return value;
      })(value["reportName"]),
      reportType: ((value: "USERS" | "ACTIVITY" | "EXTENDED_USERS_EXPORT" | "ACTIONABLES_ACTIVITY"): unknown => {
        return value;
      })(value["reportType"]),
      specialReport: ((value: boolean): unknown => {
        return value;
      })(value["specialReport"]),
      targetExcel: ((value: boolean): unknown => {
        return value;
      })(value["targetExcel"]),
      toDate: ((value: { year: number; month: number; day: number } | null): unknown => {
        if (value === null) {
          return null;
        }
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["toDate"]),
      transferProtocol: ((value: "FTP" | "SFTP" | "FTPS"): unknown => {
        return value;
      })(value["transferProtocol"]),
      zipFile: ((value: boolean): unknown => {
        return value;
      })(value["zipFile"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ReportSaveRequest(str: string): ReportSaveRequest | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          active: boolean;
          activeUsersOnly: boolean;
          ccRecipients: string;
          corporateId: number;
          customerId: number;
          dayOfWeek: number;
          downloadType: DownloadType;
          frequency: ReportFrequency;
          fromDate: { year: number; month: number; day: number } | null;
          ftpFolder: string;
          ftpHost: string;
          ftpPassphrase: string;
          ftpPassword: string;
          ftpPort: number;
          ftpRemoteFileName: string;
          ftpUsername: string;
          prefixFileName: boolean;
          privateKey: string | null;
          reportId: number;
          reportName: string;
          reportType: ReportType;
          specialReport: boolean;
          targetExcel: boolean;
          toDate: { year: number; month: number; day: number } | null;
          transferProtocol: TransferProtocol;
          zipFile: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "activeUsersOnly")) {
      return { success: false };
    }
    const parsed_activeUsersOnly = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["activeUsersOnly"]);
    })();
    if (!parsed_activeUsersOnly.success) {
      return { success: false };
    }
    if (!hasProp(value, "ccRecipients")) {
      return { success: false };
    }
    const parsed_ccRecipients = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ccRecipients"]);
    })();
    if (!parsed_ccRecipients.success) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "dayOfWeek")) {
      return { success: false };
    }
    const parsed_dayOfWeek = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["dayOfWeek"]);
    })();
    if (!parsed_dayOfWeek.success) {
      return { success: false };
    }
    if (!hasProp(value, "downloadType")) {
      return { success: false };
    }
    const parsed_downloadType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DownloadType } => {
        return ((value: unknown): { success: false } | { success: true; value: "PUSH" | "PULL" | "BOTH" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "PUSH":
              return { success: true, value: "PUSH" };
            case "PULL":
              return { success: true, value: "PULL" };
            case "BOTH":
              return { success: true, value: "BOTH" };
            default:
              return { success: false };
          }
        })(value);
      })(value["downloadType"]);
    })();
    if (!parsed_downloadType.success) {
      return { success: false };
    }
    if (!hasProp(value, "frequency")) {
      return { success: false };
    }
    const parsed_frequency = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ReportFrequency } => {
        return ((value: unknown): { success: false } | { success: true; value: "ONE_TIME" | "DAILY" | "WEEKLY" | "MONTHLY" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "ONE_TIME":
              return { success: true, value: "ONE_TIME" };
            case "DAILY":
              return { success: true, value: "DAILY" };
            case "WEEKLY":
              return { success: true, value: "WEEKLY" };
            case "MONTHLY":
              return { success: true, value: "MONTHLY" };
            default:
              return { success: false };
          }
        })(value);
      })(value["frequency"]);
    })();
    if (!parsed_frequency.success) {
      return { success: false };
    }
    if (!hasProp(value, "fromDate")) {
      return { success: false };
    }
    const parsed_fromDate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["fromDate"]);
    })();
    if (!parsed_fromDate.success) {
      return { success: false };
    }
    if (!hasProp(value, "ftpFolder")) {
      return { success: false };
    }
    const parsed_ftpFolder = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ftpFolder"]);
    })();
    if (!parsed_ftpFolder.success) {
      return { success: false };
    }
    if (!hasProp(value, "ftpHost")) {
      return { success: false };
    }
    const parsed_ftpHost = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ftpHost"]);
    })();
    if (!parsed_ftpHost.success) {
      return { success: false };
    }
    if (!hasProp(value, "ftpPassphrase")) {
      return { success: false };
    }
    const parsed_ftpPassphrase = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ftpPassphrase"]);
    })();
    if (!parsed_ftpPassphrase.success) {
      return { success: false };
    }
    if (!hasProp(value, "ftpPassword")) {
      return { success: false };
    }
    const parsed_ftpPassword = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ftpPassword"]);
    })();
    if (!parsed_ftpPassword.success) {
      return { success: false };
    }
    if (!hasProp(value, "ftpPort")) {
      return { success: false };
    }
    const parsed_ftpPort = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["ftpPort"]);
    })();
    if (!parsed_ftpPort.success) {
      return { success: false };
    }
    if (!hasProp(value, "ftpRemoteFileName")) {
      return { success: false };
    }
    const parsed_ftpRemoteFileName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ftpRemoteFileName"]);
    })();
    if (!parsed_ftpRemoteFileName.success) {
      return { success: false };
    }
    if (!hasProp(value, "ftpUsername")) {
      return { success: false };
    }
    const parsed_ftpUsername = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ftpUsername"]);
    })();
    if (!parsed_ftpUsername.success) {
      return { success: false };
    }
    if (!hasProp(value, "prefixFileName")) {
      return { success: false };
    }
    const parsed_prefixFileName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["prefixFileName"]);
    })();
    if (!parsed_prefixFileName.success) {
      return { success: false };
    }
    if (!hasProp(value, "privateKey")) {
      return { success: false };
    }
    const parsed_privateKey = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["privateKey"]);
    })();
    if (!parsed_privateKey.success) {
      return { success: false };
    }
    if (!hasProp(value, "reportId")) {
      return { success: false };
    }
    const parsed_reportId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["reportId"]);
    })();
    if (!parsed_reportId.success) {
      return { success: false };
    }
    if (!hasProp(value, "reportName")) {
      return { success: false };
    }
    const parsed_reportName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["reportName"]);
    })();
    if (!parsed_reportName.success) {
      return { success: false };
    }
    if (!hasProp(value, "reportType")) {
      return { success: false };
    }
    const parsed_reportType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ReportType } => {
        return ((value: unknown): { success: false } | { success: true; value: "USERS" | "ACTIVITY" | "EXTENDED_USERS_EXPORT" | "ACTIONABLES_ACTIVITY" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "USERS":
              return { success: true, value: "USERS" };
            case "ACTIVITY":
              return { success: true, value: "ACTIVITY" };
            case "EXTENDED_USERS_EXPORT":
              return { success: true, value: "EXTENDED_USERS_EXPORT" };
            case "ACTIONABLES_ACTIVITY":
              return { success: true, value: "ACTIONABLES_ACTIVITY" };
            default:
              return { success: false };
          }
        })(value);
      })(value["reportType"]);
    })();
    if (!parsed_reportType.success) {
      return { success: false };
    }
    if (!hasProp(value, "specialReport")) {
      return { success: false };
    }
    const parsed_specialReport = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["specialReport"]);
    })();
    if (!parsed_specialReport.success) {
      return { success: false };
    }
    if (!hasProp(value, "targetExcel")) {
      return { success: false };
    }
    const parsed_targetExcel = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["targetExcel"]);
    })();
    if (!parsed_targetExcel.success) {
      return { success: false };
    }
    if (!hasProp(value, "toDate")) {
      return { success: false };
    }
    const parsed_toDate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["toDate"]);
    })();
    if (!parsed_toDate.success) {
      return { success: false };
    }
    if (!hasProp(value, "transferProtocol")) {
      return { success: false };
    }
    const parsed_transferProtocol = (() => {
      return ((value: unknown): { success: false } | { success: true; value: TransferProtocol } => {
        return ((value: unknown): { success: false } | { success: true; value: "FTP" | "SFTP" | "FTPS" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "FTP":
              return { success: true, value: "FTP" };
            case "SFTP":
              return { success: true, value: "SFTP" };
            case "FTPS":
              return { success: true, value: "FTPS" };
            default:
              return { success: false };
          }
        })(value);
      })(value["transferProtocol"]);
    })();
    if (!parsed_transferProtocol.success) {
      return { success: false };
    }
    if (!hasProp(value, "zipFile")) {
      return { success: false };
    }
    const parsed_zipFile = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["zipFile"]);
    })();
    if (!parsed_zipFile.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        active: parsed_active.value,
        activeUsersOnly: parsed_activeUsersOnly.value,
        ccRecipients: parsed_ccRecipients.value,
        corporateId: parsed_corporateId.value,
        customerId: parsed_customerId.value,
        dayOfWeek: parsed_dayOfWeek.value,
        downloadType: parsed_downloadType.value,
        frequency: parsed_frequency.value,
        fromDate: parsed_fromDate.value,
        ftpFolder: parsed_ftpFolder.value,
        ftpHost: parsed_ftpHost.value,
        ftpPassphrase: parsed_ftpPassphrase.value,
        ftpPassword: parsed_ftpPassword.value,
        ftpPort: parsed_ftpPort.value,
        ftpRemoteFileName: parsed_ftpRemoteFileName.value,
        ftpUsername: parsed_ftpUsername.value,
        prefixFileName: parsed_prefixFileName.value,
        privateKey: parsed_privateKey.value,
        reportId: parsed_reportId.value,
        reportName: parsed_reportName.value,
        reportType: parsed_reportType.value,
        specialReport: parsed_specialReport.value,
        targetExcel: parsed_targetExcel.value,
        toDate: parsed_toDate.value,
        transferProtocol: parsed_transferProtocol.value,
        zipFile: parsed_zipFile.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ReportType
export function serialize_ReportType(obj: ReportType): string {
  const serialized = ((value: "USERS" | "ACTIVITY" | "EXTENDED_USERS_EXPORT" | "ACTIONABLES_ACTIVITY"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ReportType(str: string): ReportType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "USERS" | "ACTIVITY" | "EXTENDED_USERS_EXPORT" | "ACTIONABLES_ACTIVITY" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "USERS":
        return { success: true, value: "USERS" };
      case "ACTIVITY":
        return { success: true, value: "ACTIVITY" };
      case "EXTENDED_USERS_EXPORT":
        return { success: true, value: "EXTENDED_USERS_EXPORT" };
      case "ACTIONABLES_ACTIVITY":
        return { success: true, value: "ACTIONABLES_ACTIVITY" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ResendConfirmationEmailForm
export function serialize_ResendConfirmationEmailForm(obj: ResendConfirmationEmailForm): string {
  const serialized = ((value: { customerName: string | null; subscriptionId: number }): unknown => {
    return {
      customerName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["customerName"]),
      subscriptionId: ((value: number): unknown => {
        return value;
      })(value["subscriptionId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ResendConfirmationEmailForm(str: string): ResendConfirmationEmailForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          customerName: string | null;
          subscriptionId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "customerName")) {
      return { success: false };
    }
    const parsed_customerName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["customerName"]);
    })();
    if (!parsed_customerName.success) {
      return { success: false };
    }
    if (!hasProp(value, "subscriptionId")) {
      return { success: false };
    }
    const parsed_subscriptionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["subscriptionId"]);
    })();
    if (!parsed_subscriptionId.success) {
      return { success: false };
    }
    return { success: true, value: { customerName: parsed_customerName.value, subscriptionId: parsed_subscriptionId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// Result
export function serialize_Result(obj: Result): string {
  const serialized = ((value: { completion: boolean; duration: string }): unknown => {
    return {
      completion: ((value: boolean): unknown => {
        return value;
      })(value["completion"]),
      duration: ((value: string): unknown => {
        return value;
      })(value["duration"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_Result(str: string): Result | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          completion: boolean;
          duration: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "completion")) {
      return { success: false };
    }
    const parsed_completion = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["completion"]);
    })();
    if (!parsed_completion.success) {
      return { success: false };
    }
    if (!hasProp(value, "duration")) {
      return { success: false };
    }
    const parsed_duration = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["duration"]);
    })();
    if (!parsed_duration.success) {
      return { success: false };
    }
    return { success: true, value: { completion: parsed_completion.value, duration: parsed_duration.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// RightsProbability
export function serialize_RightsProbability(obj: RightsProbability): string {
  const serialized = ((value: "UNPREDICTABLE" | "LIKELY" | "VERY_LIKELY" | "UNLIKELY"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_RightsProbability(str: string): RightsProbability | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "UNPREDICTABLE" | "LIKELY" | "VERY_LIKELY" | "UNLIKELY" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "UNPREDICTABLE":
        return { success: true, value: "UNPREDICTABLE" };
      case "LIKELY":
        return { success: true, value: "LIKELY" };
      case "VERY_LIKELY":
        return { success: true, value: "VERY_LIKELY" };
      case "UNLIKELY":
        return { success: true, value: "UNLIKELY" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// RightsState
export function serialize_RightsState(obj: RightsState): string {
  const serialized = ((value: "RIGHTS_NOT_REQUESTED" | "PENDING" | "PENDING_PUBLISHER_APPROVAL" | "OK" | "NO_RIGHTS"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_RightsState(str: string): RightsState | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "RIGHTS_NOT_REQUESTED" | "PENDING" | "PENDING_PUBLISHER_APPROVAL" | "OK" | "NO_RIGHTS" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "RIGHTS_NOT_REQUESTED":
        return { success: true, value: "RIGHTS_NOT_REQUESTED" };
      case "PENDING":
        return { success: true, value: "PENDING" };
      case "PENDING_PUBLISHER_APPROVAL":
        return { success: true, value: "PENDING_PUBLISHER_APPROVAL" };
      case "OK":
        return { success: true, value: "OK" };
      case "NO_RIGHTS":
        return { success: true, value: "NO_RIGHTS" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// RoleResource
export function serialize_RoleResource(obj: RoleResource): string {
  const serialized = ((
    value:
      | "ROLE_ADMIN"
      | "PPS_EDITORIAL"
      | "PPS_RIGHTS"
      | "PPS_BOOKMARKETING"
      | "PPS_TOPTENLIST"
      | "PPS_WRITERREPORT"
      | "PPS_STANDARDREPORT"
      | "PPS_ALLSUMMARIES_LIST"
      | "PPS_PUBLISHERADMIN"
      | "PPS_INTERNALSEARCH"
      | "ASP_ACCOUNT_ADMIN"
      | "PUBLISHER_STATS_ALL"
      | "ASP_STATISTICS"
      | "ASP_USER_ADMIN"
      | "PPS_RIGHTSADMINLIST"
      | "ASP_STATISTICS_INTERNAL"
      | "ADMIN_TOOL"
      | "CUSTOMER_ADMIN"
      | "EMAILTOOL"
      | "TRANSACTION_ADMIN"
      | "AFFILIATE_CLV_STATISTICS"
      | "MARKETING_FILE_UPLOAD"
      | "CURRENCY_EXCHANGE_RATE"
      | "PPS_EDITORREPORT"
      | "ADMIN_RELOAD"
      | "EMAILTOOL_SNIPPETS"
      | "ASP_MASSIMPORT_TOOL"
      | "ASP_USER_ADMIN_LASTACCESS"
      | "ASP_CENTRAL_ADMIN"
      | "TXT_BUNDLE_EDIT"
      | "TXT_TEXT_SINGLEEDIT"
      | "TXT_TEXT_MULTIEDIT"
      | "TXT_TOBEREVIEWEDLIST"
      | "TXT_TOBETRANSLATEDLIST"
      | "TXT_INLINE_EDIT"
      | "TXT_LATEST_CHANGES"
      | "TXT_SEARCH"
      | "TXT_MAJOR_CHANGE"
      | "TXT_ONLINE_STATE"
      | "CREDIT_CARD_SETTLEMENT"
      | "TXT_BUNDLE_VIEW"
      | "EMAIL_LIST"
      | "SOCIAL_COMMENT_FLAG"
      | "CUSTOMER_WRITER"
      | "RECOMMENDER_EXPORT"
      | "KREC_EDIT"
      | "WEB_RIGHTS_REQUEST"
      | "SCORM_PACKAGE"
      | "PPS_VIEW"
      | "AFFILIATE_ADMIN"
      | "SUMMARY_QR_CODE"
      | "MARC_XML"
      | "REGENERATE_SUMMARY_FILES"
      | "HR_ADMIN_TOOL"
      | "SUMMARY_STATISTICS"
      | "CF_SUBSCRIPTIONS"
      | "ASP_SSO_DOMAIN"
      | "CUSTOMER_ADMIN_SUBSCRIPTION"
      | "CUSTOMER_ADMIN_FINANCE"
      | "OAUTH2_CLIENT_ADMIN"
      | "ASP_USER_GROUP_ADMIN"
      | "TOP_DOWNLOADS_TOOL"
      | "WRITER_PORTAL"
      | "ASP_LOGO_UPLOAD"
      | "PORTLET_ADMIN"
      | "PORTLET_SUPER_ADMIN"
      | "FREEABSTRACT_ADMIN_TOOL"
      | "PROMOBOXES_ADMIN"
      | "CORPORATE_CONTACT"
      | "CORPORATE_COMMENTS"
      | "PPS_NEW_CONTENT_INPUT"
      | "PPS_NEW_CONTENT_INPUT_ADMIN"
      | "ABSENCES_ADMIN"
      | "CHANNEL_ADMIN"
      | "PROMOBOXES"
      | "SSO_DOMAINS"
      | "PPS_PORTAL"
      | "PPS_PORTAL_ADMIN"
      | "CHANNEL_LIST"
      | "B2B_STATISTICS"
      | "LIBRARY_CONNECTOR"
      | "CHANNEL_ADMIN_GLOBAL"
      | "PPS_FREELANCER_CONTACT_TOOL"
      | "PPS_API_PRODUCTION_POINTS"
      | "PPS_API_PRODUCTION_POINTS_ADMIN"
      | "PPS_API_WRITING_OFFER"
      | "PPS_API_EDITING_OFFER"
      | "PPS_FLOW"
      | "PPS_FLOW_ADMIN"
      | "PPS_API_JOB"
      | "PPS_API_INVOICE"
      | "SCIM_TOKEN_VIEWER"
      | "PPS_API_OFFER"
      | "PRODUCTION_REPORT"
      | "PPS_RIGHTS_FLOW"
      | "PPS_AUTHOR_TOOL"
      | "GA_KNOWLEDGE_BASE"
      | "GA_SERVICE_DESK"
      | "PPS_KNOWLEDGE_BASE"
      | "EDITORIAL_KNOWLEDGE_BASE"
      | "LGXP_ADMIN"
      | "PPS_RIGHTS_TEAM_REPORTS"
      | "LGXP_GLOBAL_ADMIN"
      | "PPS_EDITORIAL_TEAM_REPORTS"
      | "PPS_API_SUMMARY_CREATION"
      | "CUSTOMER_STORY_ADMIN"
      | "PROMOTION_CODE_TOOL"
      | "PPS_GLOBAL_MOST_WANTED_SUMMARY_REPORT"
      | "ASP_LIST"
      | "PPS_USER_API"
      | "ASP_SENSITIVE_CONTENT"
      | "CHANNEL_ADMIN_SEARCH"
      | "PPS_TEAM_MOST_WANTED_EDITORIAL_SUMMARY_REPORT"
      | "PPS_PERSONAL_MOST_WANTED_EDITORIAL_SUMMARY_REPORT"
      | "PPS_TEAM_MOST_WANTED_RIGHTS_SUMMARY_REPORT"
      | "PPS_PERSONAL_MOST_WANTED_RIGHTS_SUMMARY_REPORT"
      | "ACTIONABLE_ADMIN"
      | "PPS_COMPONENT_DEMO"
      | "ASP_MASSIMPORT_TOOL_EXTENDED"
      | "ASP_UNIDENTIFIED_USER_ADMIN"
      | "PPS_PAYMENT_TOOL"
      | "HR_ADMIN_FULL_ACCESS"
      | "PPS_ORIGINAL_SUMMARY_FILE_ACCESS"
      | "CUSTOMER_REPORTS"
      | "PPS_ARCHIVING_SUMMARY"
      | "LGXP_ADMIN_SEARCH"
      | "PPS_USER_BETA_TEST"
      | "ACTIONABLE_ADMIN_SEARCH"
      | "CUSTOM_PAGE_ADMIN"
      | "PPS_EXPENSE_TOOL"
      | "VERIFY_INPUT_TOOL"
      | "VERIFY_PUBLISH"
      | "VERIFY_UPLOAD"
      | "VERIFY_DASHBOARD"
      | "CSM_ADVANCED_SEARCH"
      | "VERIFY_VERIFICATION"
      | "VERIFY_EDITING"
      | "SUMMARIES_BULK_LOAD"
      | "ACTIONABLES_BULK_LOAD"
      | "TAG_ADMIN",
  ): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_RoleResource(str: string): RoleResource | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value:
          | "ROLE_ADMIN"
          | "PPS_EDITORIAL"
          | "PPS_RIGHTS"
          | "PPS_BOOKMARKETING"
          | "PPS_TOPTENLIST"
          | "PPS_WRITERREPORT"
          | "PPS_STANDARDREPORT"
          | "PPS_ALLSUMMARIES_LIST"
          | "PPS_PUBLISHERADMIN"
          | "PPS_INTERNALSEARCH"
          | "ASP_ACCOUNT_ADMIN"
          | "PUBLISHER_STATS_ALL"
          | "ASP_STATISTICS"
          | "ASP_USER_ADMIN"
          | "PPS_RIGHTSADMINLIST"
          | "ASP_STATISTICS_INTERNAL"
          | "ADMIN_TOOL"
          | "CUSTOMER_ADMIN"
          | "EMAILTOOL"
          | "TRANSACTION_ADMIN"
          | "AFFILIATE_CLV_STATISTICS"
          | "MARKETING_FILE_UPLOAD"
          | "CURRENCY_EXCHANGE_RATE"
          | "PPS_EDITORREPORT"
          | "ADMIN_RELOAD"
          | "EMAILTOOL_SNIPPETS"
          | "ASP_MASSIMPORT_TOOL"
          | "ASP_USER_ADMIN_LASTACCESS"
          | "ASP_CENTRAL_ADMIN"
          | "TXT_BUNDLE_EDIT"
          | "TXT_TEXT_SINGLEEDIT"
          | "TXT_TEXT_MULTIEDIT"
          | "TXT_TOBEREVIEWEDLIST"
          | "TXT_TOBETRANSLATEDLIST"
          | "TXT_INLINE_EDIT"
          | "TXT_LATEST_CHANGES"
          | "TXT_SEARCH"
          | "TXT_MAJOR_CHANGE"
          | "TXT_ONLINE_STATE"
          | "CREDIT_CARD_SETTLEMENT"
          | "TXT_BUNDLE_VIEW"
          | "EMAIL_LIST"
          | "SOCIAL_COMMENT_FLAG"
          | "CUSTOMER_WRITER"
          | "RECOMMENDER_EXPORT"
          | "KREC_EDIT"
          | "WEB_RIGHTS_REQUEST"
          | "SCORM_PACKAGE"
          | "PPS_VIEW"
          | "AFFILIATE_ADMIN"
          | "SUMMARY_QR_CODE"
          | "MARC_XML"
          | "REGENERATE_SUMMARY_FILES"
          | "HR_ADMIN_TOOL"
          | "SUMMARY_STATISTICS"
          | "CF_SUBSCRIPTIONS"
          | "ASP_SSO_DOMAIN"
          | "CUSTOMER_ADMIN_SUBSCRIPTION"
          | "CUSTOMER_ADMIN_FINANCE"
          | "OAUTH2_CLIENT_ADMIN"
          | "ASP_USER_GROUP_ADMIN"
          | "TOP_DOWNLOADS_TOOL"
          | "WRITER_PORTAL"
          | "ASP_LOGO_UPLOAD"
          | "PORTLET_ADMIN"
          | "PORTLET_SUPER_ADMIN"
          | "FREEABSTRACT_ADMIN_TOOL"
          | "PROMOBOXES_ADMIN"
          | "CORPORATE_CONTACT"
          | "CORPORATE_COMMENTS"
          | "PPS_NEW_CONTENT_INPUT"
          | "PPS_NEW_CONTENT_INPUT_ADMIN"
          | "ABSENCES_ADMIN"
          | "CHANNEL_ADMIN"
          | "PROMOBOXES"
          | "SSO_DOMAINS"
          | "PPS_PORTAL"
          | "PPS_PORTAL_ADMIN"
          | "CHANNEL_LIST"
          | "B2B_STATISTICS"
          | "LIBRARY_CONNECTOR"
          | "CHANNEL_ADMIN_GLOBAL"
          | "PPS_FREELANCER_CONTACT_TOOL"
          | "PPS_API_PRODUCTION_POINTS"
          | "PPS_API_PRODUCTION_POINTS_ADMIN"
          | "PPS_API_WRITING_OFFER"
          | "PPS_API_EDITING_OFFER"
          | "PPS_FLOW"
          | "PPS_FLOW_ADMIN"
          | "PPS_API_JOB"
          | "PPS_API_INVOICE"
          | "SCIM_TOKEN_VIEWER"
          | "PPS_API_OFFER"
          | "PRODUCTION_REPORT"
          | "PPS_RIGHTS_FLOW"
          | "PPS_AUTHOR_TOOL"
          | "GA_KNOWLEDGE_BASE"
          | "GA_SERVICE_DESK"
          | "PPS_KNOWLEDGE_BASE"
          | "EDITORIAL_KNOWLEDGE_BASE"
          | "LGXP_ADMIN"
          | "PPS_RIGHTS_TEAM_REPORTS"
          | "LGXP_GLOBAL_ADMIN"
          | "PPS_EDITORIAL_TEAM_REPORTS"
          | "PPS_API_SUMMARY_CREATION"
          | "CUSTOMER_STORY_ADMIN"
          | "PROMOTION_CODE_TOOL"
          | "PPS_GLOBAL_MOST_WANTED_SUMMARY_REPORT"
          | "ASP_LIST"
          | "PPS_USER_API"
          | "ASP_SENSITIVE_CONTENT"
          | "CHANNEL_ADMIN_SEARCH"
          | "PPS_TEAM_MOST_WANTED_EDITORIAL_SUMMARY_REPORT"
          | "PPS_PERSONAL_MOST_WANTED_EDITORIAL_SUMMARY_REPORT"
          | "PPS_TEAM_MOST_WANTED_RIGHTS_SUMMARY_REPORT"
          | "PPS_PERSONAL_MOST_WANTED_RIGHTS_SUMMARY_REPORT"
          | "ACTIONABLE_ADMIN"
          | "PPS_COMPONENT_DEMO"
          | "ASP_MASSIMPORT_TOOL_EXTENDED"
          | "ASP_UNIDENTIFIED_USER_ADMIN"
          | "PPS_PAYMENT_TOOL"
          | "HR_ADMIN_FULL_ACCESS"
          | "PPS_ORIGINAL_SUMMARY_FILE_ACCESS"
          | "CUSTOMER_REPORTS"
          | "PPS_ARCHIVING_SUMMARY"
          | "LGXP_ADMIN_SEARCH"
          | "PPS_USER_BETA_TEST"
          | "ACTIONABLE_ADMIN_SEARCH"
          | "CUSTOM_PAGE_ADMIN"
          | "PPS_EXPENSE_TOOL"
          | "VERIFY_INPUT_TOOL"
          | "VERIFY_PUBLISH"
          | "VERIFY_UPLOAD"
          | "VERIFY_DASHBOARD"
          | "CSM_ADVANCED_SEARCH"
          | "VERIFY_VERIFICATION"
          | "VERIFY_EDITING"
          | "SUMMARIES_BULK_LOAD"
          | "ACTIONABLES_BULK_LOAD"
          | "TAG_ADMIN";
      } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "ROLE_ADMIN":
        return { success: true, value: "ROLE_ADMIN" };
      case "PPS_EDITORIAL":
        return { success: true, value: "PPS_EDITORIAL" };
      case "PPS_RIGHTS":
        return { success: true, value: "PPS_RIGHTS" };
      case "PPS_BOOKMARKETING":
        return { success: true, value: "PPS_BOOKMARKETING" };
      case "PPS_TOPTENLIST":
        return { success: true, value: "PPS_TOPTENLIST" };
      case "PPS_WRITERREPORT":
        return { success: true, value: "PPS_WRITERREPORT" };
      case "PPS_STANDARDREPORT":
        return { success: true, value: "PPS_STANDARDREPORT" };
      case "PPS_ALLSUMMARIES_LIST":
        return { success: true, value: "PPS_ALLSUMMARIES_LIST" };
      case "PPS_PUBLISHERADMIN":
        return { success: true, value: "PPS_PUBLISHERADMIN" };
      case "PPS_INTERNALSEARCH":
        return { success: true, value: "PPS_INTERNALSEARCH" };
      case "ASP_ACCOUNT_ADMIN":
        return { success: true, value: "ASP_ACCOUNT_ADMIN" };
      case "PUBLISHER_STATS_ALL":
        return { success: true, value: "PUBLISHER_STATS_ALL" };
      case "ASP_STATISTICS":
        return { success: true, value: "ASP_STATISTICS" };
      case "ASP_USER_ADMIN":
        return { success: true, value: "ASP_USER_ADMIN" };
      case "PPS_RIGHTSADMINLIST":
        return { success: true, value: "PPS_RIGHTSADMINLIST" };
      case "ASP_STATISTICS_INTERNAL":
        return { success: true, value: "ASP_STATISTICS_INTERNAL" };
      case "ADMIN_TOOL":
        return { success: true, value: "ADMIN_TOOL" };
      case "CUSTOMER_ADMIN":
        return { success: true, value: "CUSTOMER_ADMIN" };
      case "EMAILTOOL":
        return { success: true, value: "EMAILTOOL" };
      case "TRANSACTION_ADMIN":
        return { success: true, value: "TRANSACTION_ADMIN" };
      case "AFFILIATE_CLV_STATISTICS":
        return { success: true, value: "AFFILIATE_CLV_STATISTICS" };
      case "MARKETING_FILE_UPLOAD":
        return { success: true, value: "MARKETING_FILE_UPLOAD" };
      case "CURRENCY_EXCHANGE_RATE":
        return { success: true, value: "CURRENCY_EXCHANGE_RATE" };
      case "PPS_EDITORREPORT":
        return { success: true, value: "PPS_EDITORREPORT" };
      case "ADMIN_RELOAD":
        return { success: true, value: "ADMIN_RELOAD" };
      case "EMAILTOOL_SNIPPETS":
        return { success: true, value: "EMAILTOOL_SNIPPETS" };
      case "ASP_MASSIMPORT_TOOL":
        return { success: true, value: "ASP_MASSIMPORT_TOOL" };
      case "ASP_USER_ADMIN_LASTACCESS":
        return { success: true, value: "ASP_USER_ADMIN_LASTACCESS" };
      case "ASP_CENTRAL_ADMIN":
        return { success: true, value: "ASP_CENTRAL_ADMIN" };
      case "TXT_BUNDLE_EDIT":
        return { success: true, value: "TXT_BUNDLE_EDIT" };
      case "TXT_TEXT_SINGLEEDIT":
        return { success: true, value: "TXT_TEXT_SINGLEEDIT" };
      case "TXT_TEXT_MULTIEDIT":
        return { success: true, value: "TXT_TEXT_MULTIEDIT" };
      case "TXT_TOBEREVIEWEDLIST":
        return { success: true, value: "TXT_TOBEREVIEWEDLIST" };
      case "TXT_TOBETRANSLATEDLIST":
        return { success: true, value: "TXT_TOBETRANSLATEDLIST" };
      case "TXT_INLINE_EDIT":
        return { success: true, value: "TXT_INLINE_EDIT" };
      case "TXT_LATEST_CHANGES":
        return { success: true, value: "TXT_LATEST_CHANGES" };
      case "TXT_SEARCH":
        return { success: true, value: "TXT_SEARCH" };
      case "TXT_MAJOR_CHANGE":
        return { success: true, value: "TXT_MAJOR_CHANGE" };
      case "TXT_ONLINE_STATE":
        return { success: true, value: "TXT_ONLINE_STATE" };
      case "CREDIT_CARD_SETTLEMENT":
        return { success: true, value: "CREDIT_CARD_SETTLEMENT" };
      case "TXT_BUNDLE_VIEW":
        return { success: true, value: "TXT_BUNDLE_VIEW" };
      case "EMAIL_LIST":
        return { success: true, value: "EMAIL_LIST" };
      case "SOCIAL_COMMENT_FLAG":
        return { success: true, value: "SOCIAL_COMMENT_FLAG" };
      case "CUSTOMER_WRITER":
        return { success: true, value: "CUSTOMER_WRITER" };
      case "RECOMMENDER_EXPORT":
        return { success: true, value: "RECOMMENDER_EXPORT" };
      case "KREC_EDIT":
        return { success: true, value: "KREC_EDIT" };
      case "WEB_RIGHTS_REQUEST":
        return { success: true, value: "WEB_RIGHTS_REQUEST" };
      case "SCORM_PACKAGE":
        return { success: true, value: "SCORM_PACKAGE" };
      case "PPS_VIEW":
        return { success: true, value: "PPS_VIEW" };
      case "AFFILIATE_ADMIN":
        return { success: true, value: "AFFILIATE_ADMIN" };
      case "SUMMARY_QR_CODE":
        return { success: true, value: "SUMMARY_QR_CODE" };
      case "MARC_XML":
        return { success: true, value: "MARC_XML" };
      case "REGENERATE_SUMMARY_FILES":
        return { success: true, value: "REGENERATE_SUMMARY_FILES" };
      case "HR_ADMIN_TOOL":
        return { success: true, value: "HR_ADMIN_TOOL" };
      case "SUMMARY_STATISTICS":
        return { success: true, value: "SUMMARY_STATISTICS" };
      case "CF_SUBSCRIPTIONS":
        return { success: true, value: "CF_SUBSCRIPTIONS" };
      case "ASP_SSO_DOMAIN":
        return { success: true, value: "ASP_SSO_DOMAIN" };
      case "CUSTOMER_ADMIN_SUBSCRIPTION":
        return { success: true, value: "CUSTOMER_ADMIN_SUBSCRIPTION" };
      case "CUSTOMER_ADMIN_FINANCE":
        return { success: true, value: "CUSTOMER_ADMIN_FINANCE" };
      case "OAUTH2_CLIENT_ADMIN":
        return { success: true, value: "OAUTH2_CLIENT_ADMIN" };
      case "ASP_USER_GROUP_ADMIN":
        return { success: true, value: "ASP_USER_GROUP_ADMIN" };
      case "TOP_DOWNLOADS_TOOL":
        return { success: true, value: "TOP_DOWNLOADS_TOOL" };
      case "WRITER_PORTAL":
        return { success: true, value: "WRITER_PORTAL" };
      case "ASP_LOGO_UPLOAD":
        return { success: true, value: "ASP_LOGO_UPLOAD" };
      case "PORTLET_ADMIN":
        return { success: true, value: "PORTLET_ADMIN" };
      case "PORTLET_SUPER_ADMIN":
        return { success: true, value: "PORTLET_SUPER_ADMIN" };
      case "FREEABSTRACT_ADMIN_TOOL":
        return { success: true, value: "FREEABSTRACT_ADMIN_TOOL" };
      case "PROMOBOXES_ADMIN":
        return { success: true, value: "PROMOBOXES_ADMIN" };
      case "CORPORATE_CONTACT":
        return { success: true, value: "CORPORATE_CONTACT" };
      case "CORPORATE_COMMENTS":
        return { success: true, value: "CORPORATE_COMMENTS" };
      case "PPS_NEW_CONTENT_INPUT":
        return { success: true, value: "PPS_NEW_CONTENT_INPUT" };
      case "PPS_NEW_CONTENT_INPUT_ADMIN":
        return { success: true, value: "PPS_NEW_CONTENT_INPUT_ADMIN" };
      case "ABSENCES_ADMIN":
        return { success: true, value: "ABSENCES_ADMIN" };
      case "CHANNEL_ADMIN":
        return { success: true, value: "CHANNEL_ADMIN" };
      case "PROMOBOXES":
        return { success: true, value: "PROMOBOXES" };
      case "SSO_DOMAINS":
        return { success: true, value: "SSO_DOMAINS" };
      case "PPS_PORTAL":
        return { success: true, value: "PPS_PORTAL" };
      case "PPS_PORTAL_ADMIN":
        return { success: true, value: "PPS_PORTAL_ADMIN" };
      case "CHANNEL_LIST":
        return { success: true, value: "CHANNEL_LIST" };
      case "B2B_STATISTICS":
        return { success: true, value: "B2B_STATISTICS" };
      case "LIBRARY_CONNECTOR":
        return { success: true, value: "LIBRARY_CONNECTOR" };
      case "CHANNEL_ADMIN_GLOBAL":
        return { success: true, value: "CHANNEL_ADMIN_GLOBAL" };
      case "PPS_FREELANCER_CONTACT_TOOL":
        return { success: true, value: "PPS_FREELANCER_CONTACT_TOOL" };
      case "PPS_API_PRODUCTION_POINTS":
        return { success: true, value: "PPS_API_PRODUCTION_POINTS" };
      case "PPS_API_PRODUCTION_POINTS_ADMIN":
        return { success: true, value: "PPS_API_PRODUCTION_POINTS_ADMIN" };
      case "PPS_API_WRITING_OFFER":
        return { success: true, value: "PPS_API_WRITING_OFFER" };
      case "PPS_API_EDITING_OFFER":
        return { success: true, value: "PPS_API_EDITING_OFFER" };
      case "PPS_FLOW":
        return { success: true, value: "PPS_FLOW" };
      case "PPS_FLOW_ADMIN":
        return { success: true, value: "PPS_FLOW_ADMIN" };
      case "PPS_API_JOB":
        return { success: true, value: "PPS_API_JOB" };
      case "PPS_API_INVOICE":
        return { success: true, value: "PPS_API_INVOICE" };
      case "SCIM_TOKEN_VIEWER":
        return { success: true, value: "SCIM_TOKEN_VIEWER" };
      case "PPS_API_OFFER":
        return { success: true, value: "PPS_API_OFFER" };
      case "PRODUCTION_REPORT":
        return { success: true, value: "PRODUCTION_REPORT" };
      case "PPS_RIGHTS_FLOW":
        return { success: true, value: "PPS_RIGHTS_FLOW" };
      case "PPS_AUTHOR_TOOL":
        return { success: true, value: "PPS_AUTHOR_TOOL" };
      case "GA_KNOWLEDGE_BASE":
        return { success: true, value: "GA_KNOWLEDGE_BASE" };
      case "GA_SERVICE_DESK":
        return { success: true, value: "GA_SERVICE_DESK" };
      case "PPS_KNOWLEDGE_BASE":
        return { success: true, value: "PPS_KNOWLEDGE_BASE" };
      case "EDITORIAL_KNOWLEDGE_BASE":
        return { success: true, value: "EDITORIAL_KNOWLEDGE_BASE" };
      case "LGXP_ADMIN":
        return { success: true, value: "LGXP_ADMIN" };
      case "PPS_RIGHTS_TEAM_REPORTS":
        return { success: true, value: "PPS_RIGHTS_TEAM_REPORTS" };
      case "LGXP_GLOBAL_ADMIN":
        return { success: true, value: "LGXP_GLOBAL_ADMIN" };
      case "PPS_EDITORIAL_TEAM_REPORTS":
        return { success: true, value: "PPS_EDITORIAL_TEAM_REPORTS" };
      case "PPS_API_SUMMARY_CREATION":
        return { success: true, value: "PPS_API_SUMMARY_CREATION" };
      case "CUSTOMER_STORY_ADMIN":
        return { success: true, value: "CUSTOMER_STORY_ADMIN" };
      case "PROMOTION_CODE_TOOL":
        return { success: true, value: "PROMOTION_CODE_TOOL" };
      case "PPS_GLOBAL_MOST_WANTED_SUMMARY_REPORT":
        return { success: true, value: "PPS_GLOBAL_MOST_WANTED_SUMMARY_REPORT" };
      case "ASP_LIST":
        return { success: true, value: "ASP_LIST" };
      case "PPS_USER_API":
        return { success: true, value: "PPS_USER_API" };
      case "ASP_SENSITIVE_CONTENT":
        return { success: true, value: "ASP_SENSITIVE_CONTENT" };
      case "CHANNEL_ADMIN_SEARCH":
        return { success: true, value: "CHANNEL_ADMIN_SEARCH" };
      case "PPS_TEAM_MOST_WANTED_EDITORIAL_SUMMARY_REPORT":
        return { success: true, value: "PPS_TEAM_MOST_WANTED_EDITORIAL_SUMMARY_REPORT" };
      case "PPS_PERSONAL_MOST_WANTED_EDITORIAL_SUMMARY_REPORT":
        return { success: true, value: "PPS_PERSONAL_MOST_WANTED_EDITORIAL_SUMMARY_REPORT" };
      case "PPS_TEAM_MOST_WANTED_RIGHTS_SUMMARY_REPORT":
        return { success: true, value: "PPS_TEAM_MOST_WANTED_RIGHTS_SUMMARY_REPORT" };
      case "PPS_PERSONAL_MOST_WANTED_RIGHTS_SUMMARY_REPORT":
        return { success: true, value: "PPS_PERSONAL_MOST_WANTED_RIGHTS_SUMMARY_REPORT" };
      case "ACTIONABLE_ADMIN":
        return { success: true, value: "ACTIONABLE_ADMIN" };
      case "PPS_COMPONENT_DEMO":
        return { success: true, value: "PPS_COMPONENT_DEMO" };
      case "ASP_MASSIMPORT_TOOL_EXTENDED":
        return { success: true, value: "ASP_MASSIMPORT_TOOL_EXTENDED" };
      case "ASP_UNIDENTIFIED_USER_ADMIN":
        return { success: true, value: "ASP_UNIDENTIFIED_USER_ADMIN" };
      case "PPS_PAYMENT_TOOL":
        return { success: true, value: "PPS_PAYMENT_TOOL" };
      case "HR_ADMIN_FULL_ACCESS":
        return { success: true, value: "HR_ADMIN_FULL_ACCESS" };
      case "PPS_ORIGINAL_SUMMARY_FILE_ACCESS":
        return { success: true, value: "PPS_ORIGINAL_SUMMARY_FILE_ACCESS" };
      case "CUSTOMER_REPORTS":
        return { success: true, value: "CUSTOMER_REPORTS" };
      case "PPS_ARCHIVING_SUMMARY":
        return { success: true, value: "PPS_ARCHIVING_SUMMARY" };
      case "LGXP_ADMIN_SEARCH":
        return { success: true, value: "LGXP_ADMIN_SEARCH" };
      case "PPS_USER_BETA_TEST":
        return { success: true, value: "PPS_USER_BETA_TEST" };
      case "ACTIONABLE_ADMIN_SEARCH":
        return { success: true, value: "ACTIONABLE_ADMIN_SEARCH" };
      case "CUSTOM_PAGE_ADMIN":
        return { success: true, value: "CUSTOM_PAGE_ADMIN" };
      case "PPS_EXPENSE_TOOL":
        return { success: true, value: "PPS_EXPENSE_TOOL" };
      case "VERIFY_INPUT_TOOL":
        return { success: true, value: "VERIFY_INPUT_TOOL" };
      case "VERIFY_PUBLISH":
        return { success: true, value: "VERIFY_PUBLISH" };
      case "VERIFY_UPLOAD":
        return { success: true, value: "VERIFY_UPLOAD" };
      case "VERIFY_DASHBOARD":
        return { success: true, value: "VERIFY_DASHBOARD" };
      case "CSM_ADVANCED_SEARCH":
        return { success: true, value: "CSM_ADVANCED_SEARCH" };
      case "VERIFY_VERIFICATION":
        return { success: true, value: "VERIFY_VERIFICATION" };
      case "VERIFY_EDITING":
        return { success: true, value: "VERIFY_EDITING" };
      case "SUMMARIES_BULK_LOAD":
        return { success: true, value: "SUMMARIES_BULK_LOAD" };
      case "ACTIONABLES_BULK_LOAD":
        return { success: true, value: "ACTIONABLES_BULK_LOAD" };
      case "TAG_ADMIN":
        return { success: true, value: "TAG_ADMIN" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// RoleResourceConstraint
export function serialize_RoleResourceConstraint(obj: RoleResourceConstraint): string {
  const serialized = ((value: { corporateIds: number[]; customerId: number; languages: Language[]; resource: RoleResource }): unknown => {
    return {
      corporateIds: ((value: number[]): unknown => {
        return value.map((value: number): unknown => {
          return value;
        });
      })(value["corporateIds"]),
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
      languages: ((value: Language[]): unknown => {
        return value.map((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
          return value;
        });
      })(value["languages"]),
      resource: ((
        value:
          | "ROLE_ADMIN"
          | "PPS_EDITORIAL"
          | "PPS_RIGHTS"
          | "PPS_BOOKMARKETING"
          | "PPS_TOPTENLIST"
          | "PPS_WRITERREPORT"
          | "PPS_STANDARDREPORT"
          | "PPS_ALLSUMMARIES_LIST"
          | "PPS_PUBLISHERADMIN"
          | "PPS_INTERNALSEARCH"
          | "ASP_ACCOUNT_ADMIN"
          | "PUBLISHER_STATS_ALL"
          | "ASP_STATISTICS"
          | "ASP_USER_ADMIN"
          | "PPS_RIGHTSADMINLIST"
          | "ASP_STATISTICS_INTERNAL"
          | "ADMIN_TOOL"
          | "CUSTOMER_ADMIN"
          | "EMAILTOOL"
          | "TRANSACTION_ADMIN"
          | "AFFILIATE_CLV_STATISTICS"
          | "MARKETING_FILE_UPLOAD"
          | "CURRENCY_EXCHANGE_RATE"
          | "PPS_EDITORREPORT"
          | "ADMIN_RELOAD"
          | "EMAILTOOL_SNIPPETS"
          | "ASP_MASSIMPORT_TOOL"
          | "ASP_USER_ADMIN_LASTACCESS"
          | "ASP_CENTRAL_ADMIN"
          | "TXT_BUNDLE_EDIT"
          | "TXT_TEXT_SINGLEEDIT"
          | "TXT_TEXT_MULTIEDIT"
          | "TXT_TOBEREVIEWEDLIST"
          | "TXT_TOBETRANSLATEDLIST"
          | "TXT_INLINE_EDIT"
          | "TXT_LATEST_CHANGES"
          | "TXT_SEARCH"
          | "TXT_MAJOR_CHANGE"
          | "TXT_ONLINE_STATE"
          | "CREDIT_CARD_SETTLEMENT"
          | "TXT_BUNDLE_VIEW"
          | "EMAIL_LIST"
          | "SOCIAL_COMMENT_FLAG"
          | "CUSTOMER_WRITER"
          | "RECOMMENDER_EXPORT"
          | "KREC_EDIT"
          | "WEB_RIGHTS_REQUEST"
          | "SCORM_PACKAGE"
          | "PPS_VIEW"
          | "AFFILIATE_ADMIN"
          | "SUMMARY_QR_CODE"
          | "MARC_XML"
          | "REGENERATE_SUMMARY_FILES"
          | "HR_ADMIN_TOOL"
          | "SUMMARY_STATISTICS"
          | "CF_SUBSCRIPTIONS"
          | "ASP_SSO_DOMAIN"
          | "CUSTOMER_ADMIN_SUBSCRIPTION"
          | "CUSTOMER_ADMIN_FINANCE"
          | "OAUTH2_CLIENT_ADMIN"
          | "ASP_USER_GROUP_ADMIN"
          | "TOP_DOWNLOADS_TOOL"
          | "WRITER_PORTAL"
          | "ASP_LOGO_UPLOAD"
          | "PORTLET_ADMIN"
          | "PORTLET_SUPER_ADMIN"
          | "FREEABSTRACT_ADMIN_TOOL"
          | "PROMOBOXES_ADMIN"
          | "CORPORATE_CONTACT"
          | "CORPORATE_COMMENTS"
          | "PPS_NEW_CONTENT_INPUT"
          | "PPS_NEW_CONTENT_INPUT_ADMIN"
          | "ABSENCES_ADMIN"
          | "CHANNEL_ADMIN"
          | "PROMOBOXES"
          | "SSO_DOMAINS"
          | "PPS_PORTAL"
          | "PPS_PORTAL_ADMIN"
          | "CHANNEL_LIST"
          | "B2B_STATISTICS"
          | "LIBRARY_CONNECTOR"
          | "CHANNEL_ADMIN_GLOBAL"
          | "PPS_FREELANCER_CONTACT_TOOL"
          | "PPS_API_PRODUCTION_POINTS"
          | "PPS_API_PRODUCTION_POINTS_ADMIN"
          | "PPS_API_WRITING_OFFER"
          | "PPS_API_EDITING_OFFER"
          | "PPS_FLOW"
          | "PPS_FLOW_ADMIN"
          | "PPS_API_JOB"
          | "PPS_API_INVOICE"
          | "SCIM_TOKEN_VIEWER"
          | "PPS_API_OFFER"
          | "PRODUCTION_REPORT"
          | "PPS_RIGHTS_FLOW"
          | "PPS_AUTHOR_TOOL"
          | "GA_KNOWLEDGE_BASE"
          | "GA_SERVICE_DESK"
          | "PPS_KNOWLEDGE_BASE"
          | "EDITORIAL_KNOWLEDGE_BASE"
          | "LGXP_ADMIN"
          | "PPS_RIGHTS_TEAM_REPORTS"
          | "LGXP_GLOBAL_ADMIN"
          | "PPS_EDITORIAL_TEAM_REPORTS"
          | "PPS_API_SUMMARY_CREATION"
          | "CUSTOMER_STORY_ADMIN"
          | "PROMOTION_CODE_TOOL"
          | "PPS_GLOBAL_MOST_WANTED_SUMMARY_REPORT"
          | "ASP_LIST"
          | "PPS_USER_API"
          | "ASP_SENSITIVE_CONTENT"
          | "CHANNEL_ADMIN_SEARCH"
          | "PPS_TEAM_MOST_WANTED_EDITORIAL_SUMMARY_REPORT"
          | "PPS_PERSONAL_MOST_WANTED_EDITORIAL_SUMMARY_REPORT"
          | "PPS_TEAM_MOST_WANTED_RIGHTS_SUMMARY_REPORT"
          | "PPS_PERSONAL_MOST_WANTED_RIGHTS_SUMMARY_REPORT"
          | "ACTIONABLE_ADMIN"
          | "PPS_COMPONENT_DEMO"
          | "ASP_MASSIMPORT_TOOL_EXTENDED"
          | "ASP_UNIDENTIFIED_USER_ADMIN"
          | "PPS_PAYMENT_TOOL"
          | "HR_ADMIN_FULL_ACCESS"
          | "PPS_ORIGINAL_SUMMARY_FILE_ACCESS"
          | "CUSTOMER_REPORTS"
          | "PPS_ARCHIVING_SUMMARY"
          | "LGXP_ADMIN_SEARCH"
          | "PPS_USER_BETA_TEST"
          | "ACTIONABLE_ADMIN_SEARCH"
          | "CUSTOM_PAGE_ADMIN"
          | "PPS_EXPENSE_TOOL"
          | "VERIFY_INPUT_TOOL"
          | "VERIFY_PUBLISH"
          | "VERIFY_UPLOAD"
          | "VERIFY_DASHBOARD"
          | "CSM_ADVANCED_SEARCH"
          | "VERIFY_VERIFICATION"
          | "VERIFY_EDITING"
          | "SUMMARIES_BULK_LOAD"
          | "ACTIONABLES_BULK_LOAD"
          | "TAG_ADMIN",
      ): unknown => {
        return value;
      })(value["resource"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_RoleResourceConstraint(str: string): RoleResourceConstraint | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          corporateIds: number[];
          customerId: number;
          languages: Language[];
          resource: RoleResource;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "corporateIds")) {
      return { success: false };
    }
    const parsed_corporateIds = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
          if (typeof value === "number") {
            return { success: true, value: value };
          }
          return { success: false };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["corporateIds"]);
    })();
    if (!parsed_corporateIds.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "languages")) {
      return { success: false };
    }
    const parsed_languages = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Language } => {
          return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            switch (value) {
              case "en":
                return { success: true, value: "en" };
              case "de":
                return { success: true, value: "de" };
              case "es":
                return { success: true, value: "es" };
              case "ru":
                return { success: true, value: "ru" };
              case "zh":
                return { success: true, value: "zh" };
              case "pt":
                return { success: true, value: "pt" };
              case "fr":
                return { success: true, value: "fr" };
              default:
                return { success: false };
            }
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["languages"]);
    })();
    if (!parsed_languages.success) {
      return { success: false };
    }
    if (!hasProp(value, "resource")) {
      return { success: false };
    }
    const parsed_resource = (() => {
      return ((value: unknown): { success: false } | { success: true; value: RoleResource } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value:
                | "ROLE_ADMIN"
                | "PPS_EDITORIAL"
                | "PPS_RIGHTS"
                | "PPS_BOOKMARKETING"
                | "PPS_TOPTENLIST"
                | "PPS_WRITERREPORT"
                | "PPS_STANDARDREPORT"
                | "PPS_ALLSUMMARIES_LIST"
                | "PPS_PUBLISHERADMIN"
                | "PPS_INTERNALSEARCH"
                | "ASP_ACCOUNT_ADMIN"
                | "PUBLISHER_STATS_ALL"
                | "ASP_STATISTICS"
                | "ASP_USER_ADMIN"
                | "PPS_RIGHTSADMINLIST"
                | "ASP_STATISTICS_INTERNAL"
                | "ADMIN_TOOL"
                | "CUSTOMER_ADMIN"
                | "EMAILTOOL"
                | "TRANSACTION_ADMIN"
                | "AFFILIATE_CLV_STATISTICS"
                | "MARKETING_FILE_UPLOAD"
                | "CURRENCY_EXCHANGE_RATE"
                | "PPS_EDITORREPORT"
                | "ADMIN_RELOAD"
                | "EMAILTOOL_SNIPPETS"
                | "ASP_MASSIMPORT_TOOL"
                | "ASP_USER_ADMIN_LASTACCESS"
                | "ASP_CENTRAL_ADMIN"
                | "TXT_BUNDLE_EDIT"
                | "TXT_TEXT_SINGLEEDIT"
                | "TXT_TEXT_MULTIEDIT"
                | "TXT_TOBEREVIEWEDLIST"
                | "TXT_TOBETRANSLATEDLIST"
                | "TXT_INLINE_EDIT"
                | "TXT_LATEST_CHANGES"
                | "TXT_SEARCH"
                | "TXT_MAJOR_CHANGE"
                | "TXT_ONLINE_STATE"
                | "CREDIT_CARD_SETTLEMENT"
                | "TXT_BUNDLE_VIEW"
                | "EMAIL_LIST"
                | "SOCIAL_COMMENT_FLAG"
                | "CUSTOMER_WRITER"
                | "RECOMMENDER_EXPORT"
                | "KREC_EDIT"
                | "WEB_RIGHTS_REQUEST"
                | "SCORM_PACKAGE"
                | "PPS_VIEW"
                | "AFFILIATE_ADMIN"
                | "SUMMARY_QR_CODE"
                | "MARC_XML"
                | "REGENERATE_SUMMARY_FILES"
                | "HR_ADMIN_TOOL"
                | "SUMMARY_STATISTICS"
                | "CF_SUBSCRIPTIONS"
                | "ASP_SSO_DOMAIN"
                | "CUSTOMER_ADMIN_SUBSCRIPTION"
                | "CUSTOMER_ADMIN_FINANCE"
                | "OAUTH2_CLIENT_ADMIN"
                | "ASP_USER_GROUP_ADMIN"
                | "TOP_DOWNLOADS_TOOL"
                | "WRITER_PORTAL"
                | "ASP_LOGO_UPLOAD"
                | "PORTLET_ADMIN"
                | "PORTLET_SUPER_ADMIN"
                | "FREEABSTRACT_ADMIN_TOOL"
                | "PROMOBOXES_ADMIN"
                | "CORPORATE_CONTACT"
                | "CORPORATE_COMMENTS"
                | "PPS_NEW_CONTENT_INPUT"
                | "PPS_NEW_CONTENT_INPUT_ADMIN"
                | "ABSENCES_ADMIN"
                | "CHANNEL_ADMIN"
                | "PROMOBOXES"
                | "SSO_DOMAINS"
                | "PPS_PORTAL"
                | "PPS_PORTAL_ADMIN"
                | "CHANNEL_LIST"
                | "B2B_STATISTICS"
                | "LIBRARY_CONNECTOR"
                | "CHANNEL_ADMIN_GLOBAL"
                | "PPS_FREELANCER_CONTACT_TOOL"
                | "PPS_API_PRODUCTION_POINTS"
                | "PPS_API_PRODUCTION_POINTS_ADMIN"
                | "PPS_API_WRITING_OFFER"
                | "PPS_API_EDITING_OFFER"
                | "PPS_FLOW"
                | "PPS_FLOW_ADMIN"
                | "PPS_API_JOB"
                | "PPS_API_INVOICE"
                | "SCIM_TOKEN_VIEWER"
                | "PPS_API_OFFER"
                | "PRODUCTION_REPORT"
                | "PPS_RIGHTS_FLOW"
                | "PPS_AUTHOR_TOOL"
                | "GA_KNOWLEDGE_BASE"
                | "GA_SERVICE_DESK"
                | "PPS_KNOWLEDGE_BASE"
                | "EDITORIAL_KNOWLEDGE_BASE"
                | "LGXP_ADMIN"
                | "PPS_RIGHTS_TEAM_REPORTS"
                | "LGXP_GLOBAL_ADMIN"
                | "PPS_EDITORIAL_TEAM_REPORTS"
                | "PPS_API_SUMMARY_CREATION"
                | "CUSTOMER_STORY_ADMIN"
                | "PROMOTION_CODE_TOOL"
                | "PPS_GLOBAL_MOST_WANTED_SUMMARY_REPORT"
                | "ASP_LIST"
                | "PPS_USER_API"
                | "ASP_SENSITIVE_CONTENT"
                | "CHANNEL_ADMIN_SEARCH"
                | "PPS_TEAM_MOST_WANTED_EDITORIAL_SUMMARY_REPORT"
                | "PPS_PERSONAL_MOST_WANTED_EDITORIAL_SUMMARY_REPORT"
                | "PPS_TEAM_MOST_WANTED_RIGHTS_SUMMARY_REPORT"
                | "PPS_PERSONAL_MOST_WANTED_RIGHTS_SUMMARY_REPORT"
                | "ACTIONABLE_ADMIN"
                | "PPS_COMPONENT_DEMO"
                | "ASP_MASSIMPORT_TOOL_EXTENDED"
                | "ASP_UNIDENTIFIED_USER_ADMIN"
                | "PPS_PAYMENT_TOOL"
                | "HR_ADMIN_FULL_ACCESS"
                | "PPS_ORIGINAL_SUMMARY_FILE_ACCESS"
                | "CUSTOMER_REPORTS"
                | "PPS_ARCHIVING_SUMMARY"
                | "LGXP_ADMIN_SEARCH"
                | "PPS_USER_BETA_TEST"
                | "ACTIONABLE_ADMIN_SEARCH"
                | "CUSTOM_PAGE_ADMIN"
                | "PPS_EXPENSE_TOOL"
                | "VERIFY_INPUT_TOOL"
                | "VERIFY_PUBLISH"
                | "VERIFY_UPLOAD"
                | "VERIFY_DASHBOARD"
                | "CSM_ADVANCED_SEARCH"
                | "VERIFY_VERIFICATION"
                | "VERIFY_EDITING"
                | "SUMMARIES_BULK_LOAD"
                | "ACTIONABLES_BULK_LOAD"
                | "TAG_ADMIN";
            } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "ROLE_ADMIN":
              return { success: true, value: "ROLE_ADMIN" };
            case "PPS_EDITORIAL":
              return { success: true, value: "PPS_EDITORIAL" };
            case "PPS_RIGHTS":
              return { success: true, value: "PPS_RIGHTS" };
            case "PPS_BOOKMARKETING":
              return { success: true, value: "PPS_BOOKMARKETING" };
            case "PPS_TOPTENLIST":
              return { success: true, value: "PPS_TOPTENLIST" };
            case "PPS_WRITERREPORT":
              return { success: true, value: "PPS_WRITERREPORT" };
            case "PPS_STANDARDREPORT":
              return { success: true, value: "PPS_STANDARDREPORT" };
            case "PPS_ALLSUMMARIES_LIST":
              return { success: true, value: "PPS_ALLSUMMARIES_LIST" };
            case "PPS_PUBLISHERADMIN":
              return { success: true, value: "PPS_PUBLISHERADMIN" };
            case "PPS_INTERNALSEARCH":
              return { success: true, value: "PPS_INTERNALSEARCH" };
            case "ASP_ACCOUNT_ADMIN":
              return { success: true, value: "ASP_ACCOUNT_ADMIN" };
            case "PUBLISHER_STATS_ALL":
              return { success: true, value: "PUBLISHER_STATS_ALL" };
            case "ASP_STATISTICS":
              return { success: true, value: "ASP_STATISTICS" };
            case "ASP_USER_ADMIN":
              return { success: true, value: "ASP_USER_ADMIN" };
            case "PPS_RIGHTSADMINLIST":
              return { success: true, value: "PPS_RIGHTSADMINLIST" };
            case "ASP_STATISTICS_INTERNAL":
              return { success: true, value: "ASP_STATISTICS_INTERNAL" };
            case "ADMIN_TOOL":
              return { success: true, value: "ADMIN_TOOL" };
            case "CUSTOMER_ADMIN":
              return { success: true, value: "CUSTOMER_ADMIN" };
            case "EMAILTOOL":
              return { success: true, value: "EMAILTOOL" };
            case "TRANSACTION_ADMIN":
              return { success: true, value: "TRANSACTION_ADMIN" };
            case "AFFILIATE_CLV_STATISTICS":
              return { success: true, value: "AFFILIATE_CLV_STATISTICS" };
            case "MARKETING_FILE_UPLOAD":
              return { success: true, value: "MARKETING_FILE_UPLOAD" };
            case "CURRENCY_EXCHANGE_RATE":
              return { success: true, value: "CURRENCY_EXCHANGE_RATE" };
            case "PPS_EDITORREPORT":
              return { success: true, value: "PPS_EDITORREPORT" };
            case "ADMIN_RELOAD":
              return { success: true, value: "ADMIN_RELOAD" };
            case "EMAILTOOL_SNIPPETS":
              return { success: true, value: "EMAILTOOL_SNIPPETS" };
            case "ASP_MASSIMPORT_TOOL":
              return { success: true, value: "ASP_MASSIMPORT_TOOL" };
            case "ASP_USER_ADMIN_LASTACCESS":
              return { success: true, value: "ASP_USER_ADMIN_LASTACCESS" };
            case "ASP_CENTRAL_ADMIN":
              return { success: true, value: "ASP_CENTRAL_ADMIN" };
            case "TXT_BUNDLE_EDIT":
              return { success: true, value: "TXT_BUNDLE_EDIT" };
            case "TXT_TEXT_SINGLEEDIT":
              return { success: true, value: "TXT_TEXT_SINGLEEDIT" };
            case "TXT_TEXT_MULTIEDIT":
              return { success: true, value: "TXT_TEXT_MULTIEDIT" };
            case "TXT_TOBEREVIEWEDLIST":
              return { success: true, value: "TXT_TOBEREVIEWEDLIST" };
            case "TXT_TOBETRANSLATEDLIST":
              return { success: true, value: "TXT_TOBETRANSLATEDLIST" };
            case "TXT_INLINE_EDIT":
              return { success: true, value: "TXT_INLINE_EDIT" };
            case "TXT_LATEST_CHANGES":
              return { success: true, value: "TXT_LATEST_CHANGES" };
            case "TXT_SEARCH":
              return { success: true, value: "TXT_SEARCH" };
            case "TXT_MAJOR_CHANGE":
              return { success: true, value: "TXT_MAJOR_CHANGE" };
            case "TXT_ONLINE_STATE":
              return { success: true, value: "TXT_ONLINE_STATE" };
            case "CREDIT_CARD_SETTLEMENT":
              return { success: true, value: "CREDIT_CARD_SETTLEMENT" };
            case "TXT_BUNDLE_VIEW":
              return { success: true, value: "TXT_BUNDLE_VIEW" };
            case "EMAIL_LIST":
              return { success: true, value: "EMAIL_LIST" };
            case "SOCIAL_COMMENT_FLAG":
              return { success: true, value: "SOCIAL_COMMENT_FLAG" };
            case "CUSTOMER_WRITER":
              return { success: true, value: "CUSTOMER_WRITER" };
            case "RECOMMENDER_EXPORT":
              return { success: true, value: "RECOMMENDER_EXPORT" };
            case "KREC_EDIT":
              return { success: true, value: "KREC_EDIT" };
            case "WEB_RIGHTS_REQUEST":
              return { success: true, value: "WEB_RIGHTS_REQUEST" };
            case "SCORM_PACKAGE":
              return { success: true, value: "SCORM_PACKAGE" };
            case "PPS_VIEW":
              return { success: true, value: "PPS_VIEW" };
            case "AFFILIATE_ADMIN":
              return { success: true, value: "AFFILIATE_ADMIN" };
            case "SUMMARY_QR_CODE":
              return { success: true, value: "SUMMARY_QR_CODE" };
            case "MARC_XML":
              return { success: true, value: "MARC_XML" };
            case "REGENERATE_SUMMARY_FILES":
              return { success: true, value: "REGENERATE_SUMMARY_FILES" };
            case "HR_ADMIN_TOOL":
              return { success: true, value: "HR_ADMIN_TOOL" };
            case "SUMMARY_STATISTICS":
              return { success: true, value: "SUMMARY_STATISTICS" };
            case "CF_SUBSCRIPTIONS":
              return { success: true, value: "CF_SUBSCRIPTIONS" };
            case "ASP_SSO_DOMAIN":
              return { success: true, value: "ASP_SSO_DOMAIN" };
            case "CUSTOMER_ADMIN_SUBSCRIPTION":
              return { success: true, value: "CUSTOMER_ADMIN_SUBSCRIPTION" };
            case "CUSTOMER_ADMIN_FINANCE":
              return { success: true, value: "CUSTOMER_ADMIN_FINANCE" };
            case "OAUTH2_CLIENT_ADMIN":
              return { success: true, value: "OAUTH2_CLIENT_ADMIN" };
            case "ASP_USER_GROUP_ADMIN":
              return { success: true, value: "ASP_USER_GROUP_ADMIN" };
            case "TOP_DOWNLOADS_TOOL":
              return { success: true, value: "TOP_DOWNLOADS_TOOL" };
            case "WRITER_PORTAL":
              return { success: true, value: "WRITER_PORTAL" };
            case "ASP_LOGO_UPLOAD":
              return { success: true, value: "ASP_LOGO_UPLOAD" };
            case "PORTLET_ADMIN":
              return { success: true, value: "PORTLET_ADMIN" };
            case "PORTLET_SUPER_ADMIN":
              return { success: true, value: "PORTLET_SUPER_ADMIN" };
            case "FREEABSTRACT_ADMIN_TOOL":
              return { success: true, value: "FREEABSTRACT_ADMIN_TOOL" };
            case "PROMOBOXES_ADMIN":
              return { success: true, value: "PROMOBOXES_ADMIN" };
            case "CORPORATE_CONTACT":
              return { success: true, value: "CORPORATE_CONTACT" };
            case "CORPORATE_COMMENTS":
              return { success: true, value: "CORPORATE_COMMENTS" };
            case "PPS_NEW_CONTENT_INPUT":
              return { success: true, value: "PPS_NEW_CONTENT_INPUT" };
            case "PPS_NEW_CONTENT_INPUT_ADMIN":
              return { success: true, value: "PPS_NEW_CONTENT_INPUT_ADMIN" };
            case "ABSENCES_ADMIN":
              return { success: true, value: "ABSENCES_ADMIN" };
            case "CHANNEL_ADMIN":
              return { success: true, value: "CHANNEL_ADMIN" };
            case "PROMOBOXES":
              return { success: true, value: "PROMOBOXES" };
            case "SSO_DOMAINS":
              return { success: true, value: "SSO_DOMAINS" };
            case "PPS_PORTAL":
              return { success: true, value: "PPS_PORTAL" };
            case "PPS_PORTAL_ADMIN":
              return { success: true, value: "PPS_PORTAL_ADMIN" };
            case "CHANNEL_LIST":
              return { success: true, value: "CHANNEL_LIST" };
            case "B2B_STATISTICS":
              return { success: true, value: "B2B_STATISTICS" };
            case "LIBRARY_CONNECTOR":
              return { success: true, value: "LIBRARY_CONNECTOR" };
            case "CHANNEL_ADMIN_GLOBAL":
              return { success: true, value: "CHANNEL_ADMIN_GLOBAL" };
            case "PPS_FREELANCER_CONTACT_TOOL":
              return { success: true, value: "PPS_FREELANCER_CONTACT_TOOL" };
            case "PPS_API_PRODUCTION_POINTS":
              return { success: true, value: "PPS_API_PRODUCTION_POINTS" };
            case "PPS_API_PRODUCTION_POINTS_ADMIN":
              return { success: true, value: "PPS_API_PRODUCTION_POINTS_ADMIN" };
            case "PPS_API_WRITING_OFFER":
              return { success: true, value: "PPS_API_WRITING_OFFER" };
            case "PPS_API_EDITING_OFFER":
              return { success: true, value: "PPS_API_EDITING_OFFER" };
            case "PPS_FLOW":
              return { success: true, value: "PPS_FLOW" };
            case "PPS_FLOW_ADMIN":
              return { success: true, value: "PPS_FLOW_ADMIN" };
            case "PPS_API_JOB":
              return { success: true, value: "PPS_API_JOB" };
            case "PPS_API_INVOICE":
              return { success: true, value: "PPS_API_INVOICE" };
            case "SCIM_TOKEN_VIEWER":
              return { success: true, value: "SCIM_TOKEN_VIEWER" };
            case "PPS_API_OFFER":
              return { success: true, value: "PPS_API_OFFER" };
            case "PRODUCTION_REPORT":
              return { success: true, value: "PRODUCTION_REPORT" };
            case "PPS_RIGHTS_FLOW":
              return { success: true, value: "PPS_RIGHTS_FLOW" };
            case "PPS_AUTHOR_TOOL":
              return { success: true, value: "PPS_AUTHOR_TOOL" };
            case "GA_KNOWLEDGE_BASE":
              return { success: true, value: "GA_KNOWLEDGE_BASE" };
            case "GA_SERVICE_DESK":
              return { success: true, value: "GA_SERVICE_DESK" };
            case "PPS_KNOWLEDGE_BASE":
              return { success: true, value: "PPS_KNOWLEDGE_BASE" };
            case "EDITORIAL_KNOWLEDGE_BASE":
              return { success: true, value: "EDITORIAL_KNOWLEDGE_BASE" };
            case "LGXP_ADMIN":
              return { success: true, value: "LGXP_ADMIN" };
            case "PPS_RIGHTS_TEAM_REPORTS":
              return { success: true, value: "PPS_RIGHTS_TEAM_REPORTS" };
            case "LGXP_GLOBAL_ADMIN":
              return { success: true, value: "LGXP_GLOBAL_ADMIN" };
            case "PPS_EDITORIAL_TEAM_REPORTS":
              return { success: true, value: "PPS_EDITORIAL_TEAM_REPORTS" };
            case "PPS_API_SUMMARY_CREATION":
              return { success: true, value: "PPS_API_SUMMARY_CREATION" };
            case "CUSTOMER_STORY_ADMIN":
              return { success: true, value: "CUSTOMER_STORY_ADMIN" };
            case "PROMOTION_CODE_TOOL":
              return { success: true, value: "PROMOTION_CODE_TOOL" };
            case "PPS_GLOBAL_MOST_WANTED_SUMMARY_REPORT":
              return { success: true, value: "PPS_GLOBAL_MOST_WANTED_SUMMARY_REPORT" };
            case "ASP_LIST":
              return { success: true, value: "ASP_LIST" };
            case "PPS_USER_API":
              return { success: true, value: "PPS_USER_API" };
            case "ASP_SENSITIVE_CONTENT":
              return { success: true, value: "ASP_SENSITIVE_CONTENT" };
            case "CHANNEL_ADMIN_SEARCH":
              return { success: true, value: "CHANNEL_ADMIN_SEARCH" };
            case "PPS_TEAM_MOST_WANTED_EDITORIAL_SUMMARY_REPORT":
              return { success: true, value: "PPS_TEAM_MOST_WANTED_EDITORIAL_SUMMARY_REPORT" };
            case "PPS_PERSONAL_MOST_WANTED_EDITORIAL_SUMMARY_REPORT":
              return { success: true, value: "PPS_PERSONAL_MOST_WANTED_EDITORIAL_SUMMARY_REPORT" };
            case "PPS_TEAM_MOST_WANTED_RIGHTS_SUMMARY_REPORT":
              return { success: true, value: "PPS_TEAM_MOST_WANTED_RIGHTS_SUMMARY_REPORT" };
            case "PPS_PERSONAL_MOST_WANTED_RIGHTS_SUMMARY_REPORT":
              return { success: true, value: "PPS_PERSONAL_MOST_WANTED_RIGHTS_SUMMARY_REPORT" };
            case "ACTIONABLE_ADMIN":
              return { success: true, value: "ACTIONABLE_ADMIN" };
            case "PPS_COMPONENT_DEMO":
              return { success: true, value: "PPS_COMPONENT_DEMO" };
            case "ASP_MASSIMPORT_TOOL_EXTENDED":
              return { success: true, value: "ASP_MASSIMPORT_TOOL_EXTENDED" };
            case "ASP_UNIDENTIFIED_USER_ADMIN":
              return { success: true, value: "ASP_UNIDENTIFIED_USER_ADMIN" };
            case "PPS_PAYMENT_TOOL":
              return { success: true, value: "PPS_PAYMENT_TOOL" };
            case "HR_ADMIN_FULL_ACCESS":
              return { success: true, value: "HR_ADMIN_FULL_ACCESS" };
            case "PPS_ORIGINAL_SUMMARY_FILE_ACCESS":
              return { success: true, value: "PPS_ORIGINAL_SUMMARY_FILE_ACCESS" };
            case "CUSTOMER_REPORTS":
              return { success: true, value: "CUSTOMER_REPORTS" };
            case "PPS_ARCHIVING_SUMMARY":
              return { success: true, value: "PPS_ARCHIVING_SUMMARY" };
            case "LGXP_ADMIN_SEARCH":
              return { success: true, value: "LGXP_ADMIN_SEARCH" };
            case "PPS_USER_BETA_TEST":
              return { success: true, value: "PPS_USER_BETA_TEST" };
            case "ACTIONABLE_ADMIN_SEARCH":
              return { success: true, value: "ACTIONABLE_ADMIN_SEARCH" };
            case "CUSTOM_PAGE_ADMIN":
              return { success: true, value: "CUSTOM_PAGE_ADMIN" };
            case "PPS_EXPENSE_TOOL":
              return { success: true, value: "PPS_EXPENSE_TOOL" };
            case "VERIFY_INPUT_TOOL":
              return { success: true, value: "VERIFY_INPUT_TOOL" };
            case "VERIFY_PUBLISH":
              return { success: true, value: "VERIFY_PUBLISH" };
            case "VERIFY_UPLOAD":
              return { success: true, value: "VERIFY_UPLOAD" };
            case "VERIFY_DASHBOARD":
              return { success: true, value: "VERIFY_DASHBOARD" };
            case "CSM_ADVANCED_SEARCH":
              return { success: true, value: "CSM_ADVANCED_SEARCH" };
            case "VERIFY_VERIFICATION":
              return { success: true, value: "VERIFY_VERIFICATION" };
            case "VERIFY_EDITING":
              return { success: true, value: "VERIFY_EDITING" };
            case "SUMMARIES_BULK_LOAD":
              return { success: true, value: "SUMMARIES_BULK_LOAD" };
            case "ACTIONABLES_BULK_LOAD":
              return { success: true, value: "ACTIONABLES_BULK_LOAD" };
            case "TAG_ADMIN":
              return { success: true, value: "TAG_ADMIN" };
            default:
              return { success: false };
          }
        })(value);
      })(value["resource"]);
    })();
    if (!parsed_resource.success) {
      return { success: false };
    }
    return { success: true, value: { corporateIds: parsed_corporateIds.value, customerId: parsed_customerId.value, languages: parsed_languages.value, resource: parsed_resource.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SearchFiltersForm
export function serialize_SearchFiltersForm(obj: SearchFiltersForm): string {
  const serialized = ((value: { actionableLanguageFormFilter: Language[]; channelPage: number; query: string; summariesPage: number; summarySearchFilters: SummarySearchFilters }): unknown => {
    return {
      actionableLanguageFormFilter: ((value: Language[]): unknown => {
        return value.map((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
          return value;
        });
      })(value["actionableLanguageFormFilter"]),
      channelPage: ((value: number): unknown => {
        return value;
      })(value["channelPage"]),
      query: ((value: string): unknown => {
        return value;
      })(value["query"]),
      summariesPage: ((value: number): unknown => {
        return value;
      })(value["summariesPage"]),
      summarySearchFilters: ((value: {
        audioFormFilter: boolean;
        languageFormFilter: string[];
        miabFormFilter: boolean;
        publicationDateFormFilter: number[];
        qualityFormFilter: string[];
        ratingFormFilter: number[];
        sourceFormFilter: string[];
      }): unknown => {
        return {
          audioFormFilter: ((value: boolean): unknown => {
            return value;
          })(value["audioFormFilter"]),
          languageFormFilter: ((value: string[]): unknown => {
            return value.map((value: string): unknown => {
              return value;
            });
          })(value["languageFormFilter"]),
          miabFormFilter: ((value: boolean): unknown => {
            return value;
          })(value["miabFormFilter"]),
          publicationDateFormFilter: ((value: number[]): unknown => {
            return value.map((value: number): unknown => {
              return value;
            });
          })(value["publicationDateFormFilter"]),
          qualityFormFilter: ((value: string[]): unknown => {
            return value.map((value: string): unknown => {
              return value;
            });
          })(value["qualityFormFilter"]),
          ratingFormFilter: ((value: number[]): unknown => {
            return value.map((value: number): unknown => {
              return value;
            });
          })(value["ratingFormFilter"]),
          sourceFormFilter: ((value: string[]): unknown => {
            return value.map((value: string): unknown => {
              return value;
            });
          })(value["sourceFormFilter"]),
        };
      })(value["summarySearchFilters"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SearchFiltersForm(str: string): SearchFiltersForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          actionableLanguageFormFilter: Language[];
          channelPage: number;
          query: string;
          summariesPage: number;
          summarySearchFilters: SummarySearchFilters;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "actionableLanguageFormFilter")) {
      return { success: false };
    }
    const parsed_actionableLanguageFormFilter = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Language } => {
          return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
            if (typeof value !== "string") {
              return { success: false };
            }
            switch (value) {
              case "en":
                return { success: true, value: "en" };
              case "de":
                return { success: true, value: "de" };
              case "es":
                return { success: true, value: "es" };
              case "ru":
                return { success: true, value: "ru" };
              case "zh":
                return { success: true, value: "zh" };
              case "pt":
                return { success: true, value: "pt" };
              case "fr":
                return { success: true, value: "fr" };
              default:
                return { success: false };
            }
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["actionableLanguageFormFilter"]);
    })();
    if (!parsed_actionableLanguageFormFilter.success) {
      return { success: false };
    }
    if (!hasProp(value, "channelPage")) {
      return { success: false };
    }
    const parsed_channelPage = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["channelPage"]);
    })();
    if (!parsed_channelPage.success) {
      return { success: false };
    }
    if (!hasProp(value, "query")) {
      return { success: false };
    }
    const parsed_query = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["query"]);
    })();
    if (!parsed_query.success) {
      return { success: false };
    }
    if (!hasProp(value, "summariesPage")) {
      return { success: false };
    }
    const parsed_summariesPage = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["summariesPage"]);
    })();
    if (!parsed_summariesPage.success) {
      return { success: false };
    }
    if (!hasProp(value, "summarySearchFilters")) {
      return { success: false };
    }
    const parsed_summarySearchFilters = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SummarySearchFilters } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                audioFormFilter: boolean;
                languageFormFilter: string[];
                miabFormFilter: boolean;
                publicationDateFormFilter: number[];
                qualityFormFilter: string[];
                ratingFormFilter: number[];
                sourceFormFilter: string[];
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "audioFormFilter")) {
            return { success: false };
          }
          const parsed_audioFormFilter = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["audioFormFilter"]);
          })();
          if (!parsed_audioFormFilter.success) {
            return { success: false };
          }
          if (!hasProp(value, "languageFormFilter")) {
            return { success: false };
          }
          const parsed_languageFormFilter = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["languageFormFilter"]);
          })();
          if (!parsed_languageFormFilter.success) {
            return { success: false };
          }
          if (!hasProp(value, "miabFormFilter")) {
            return { success: false };
          }
          const parsed_miabFormFilter = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["miabFormFilter"]);
          })();
          if (!parsed_miabFormFilter.success) {
            return { success: false };
          }
          if (!hasProp(value, "publicationDateFormFilter")) {
            return { success: false };
          }
          const parsed_publicationDateFormFilter = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["publicationDateFormFilter"]);
          })();
          if (!parsed_publicationDateFormFilter.success) {
            return { success: false };
          }
          if (!hasProp(value, "qualityFormFilter")) {
            return { success: false };
          }
          const parsed_qualityFormFilter = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["qualityFormFilter"]);
          })();
          if (!parsed_qualityFormFilter.success) {
            return { success: false };
          }
          if (!hasProp(value, "ratingFormFilter")) {
            return { success: false };
          }
          const parsed_ratingFormFilter = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["ratingFormFilter"]);
          })();
          if (!parsed_ratingFormFilter.success) {
            return { success: false };
          }
          if (!hasProp(value, "sourceFormFilter")) {
            return { success: false };
          }
          const parsed_sourceFormFilter = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["sourceFormFilter"]);
          })();
          if (!parsed_sourceFormFilter.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              audioFormFilter: parsed_audioFormFilter.value,
              languageFormFilter: parsed_languageFormFilter.value,
              miabFormFilter: parsed_miabFormFilter.value,
              publicationDateFormFilter: parsed_publicationDateFormFilter.value,
              qualityFormFilter: parsed_qualityFormFilter.value,
              ratingFormFilter: parsed_ratingFormFilter.value,
              sourceFormFilter: parsed_sourceFormFilter.value,
            },
          };
        })(value);
      })(value["summarySearchFilters"]);
    })();
    if (!parsed_summarySearchFilters.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        actionableLanguageFormFilter: parsed_actionableLanguageFormFilter.value,
        channelPage: parsed_channelPage.value,
        query: parsed_query.value,
        summariesPage: parsed_summariesPage.value,
        summarySearchFilters: parsed_summarySearchFilters.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ShareEvent
export function serialize_ShareEvent(obj: ShareEvent): string {
  const serialized = ((value: { contentItemType: ContentItemType; id: bigint; shareMedium: "COPY_LINK" }): unknown => {
    return {
      contentItemType: ((value: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE" | "MIAB"): unknown => {
        return value;
      })(value["contentItemType"]),
      id: ((value: bigint): unknown => {
        return value;
      })(value["id"]),
      shareMedium: ((value: "COPY_LINK"): unknown => {
        return value;
      })(value["shareMedium"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ShareEvent(str: string): ShareEvent | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          contentItemType: ContentItemType;
          id: bigint;
          shareMedium: "COPY_LINK";
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "contentItemType")) {
      return { success: false };
    }
    const parsed_contentItemType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ContentItemType } => {
        return ((value: unknown): { success: false } | { success: true; value: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE" | "MIAB" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "UNKNOWN":
              return { success: true, value: "UNKNOWN" };
            case "SUMMARY":
              return { success: true, value: "SUMMARY" };
            case "CATEGORY":
              return { success: true, value: "CATEGORY" };
            case "CHANNEL":
              return { success: true, value: "CHANNEL" };
            case "LGXP":
              return { success: true, value: "LGXP" };
            case "ACTIONABLE":
              return { success: true, value: "ACTIONABLE" };
            case "MYLIST":
              return { success: true, value: "MYLIST" };
            case "CUSTOMPAGE":
              return { success: true, value: "CUSTOMPAGE" };
            case "MIAB":
              return { success: true, value: "MIAB" };
            default:
              return { success: false };
          }
        })(value);
      })(value["contentItemType"]);
    })();
    if (!parsed_contentItemType.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    if (!hasProp(value, "shareMedium")) {
      return { success: false };
    }
    const parsed_shareMedium = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "COPY_LINK" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "COPY_LINK":
            return { success: true, value: "COPY_LINK" };
          default:
            return { success: false };
        }
      })(value["shareMedium"]);
    })();
    if (!parsed_shareMedium.success) {
      return { success: false };
    }
    return { success: true, value: { contentItemType: parsed_contentItemType.value, id: parsed_id.value, shareMedium: parsed_shareMedium.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SkillPromptAnswer
export function serialize_SkillPromptAnswer(obj: SkillPromptAnswer): string {
  const serialized = ((value: { primaryTag: string; secondaryTags: string[] }): unknown => {
    return {
      primaryTag: ((value: string): unknown => {
        return value;
      })(value["primaryTag"]),
      secondaryTags: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["secondaryTags"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SkillPromptAnswer(str: string): SkillPromptAnswer | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          primaryTag: string;
          secondaryTags: string[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "primaryTag")) {
      return { success: false };
    }
    const parsed_primaryTag = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["primaryTag"]);
    })();
    if (!parsed_primaryTag.success) {
      return { success: false };
    }
    if (!hasProp(value, "secondaryTags")) {
      return { success: false };
    }
    const parsed_secondaryTags = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["secondaryTags"]);
    })();
    if (!parsed_secondaryTags.success) {
      return { success: false };
    }
    return { success: true, value: { primaryTag: parsed_primaryTag.value, secondaryTags: parsed_secondaryTags.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SkillTaxonomy
export function serialize_SkillTaxonomy(obj: SkillTaxonomy): string {
  const serialized = ((value: {
    skills: {
      sub_skills: {
        sub_skills: {
          title: string;
          title_DE: string | null;
          title_EN: string | null;
          title_ES: string | null;
        }[];
        title: string;
        title_DE: string | null;
        title_EN: string | null;
        title_ES: string | null;
      }[];
      title: string;
      title_DE: string | null;
      title_EN: string | null;
      title_ES: string | null;
    }[];
  }): unknown => {
    return {
      skills: ((
        value: {
          sub_skills: {
            sub_skills: {
              title: string;
              title_DE: string | null;
              title_EN: string | null;
              title_ES: string | null;
            }[];
            title: string;
            title_DE: string | null;
            title_EN: string | null;
            title_ES: string | null;
          }[];
          title: string;
          title_DE: string | null;
          title_EN: string | null;
          title_ES: string | null;
        }[],
      ): unknown => {
        return value.map(
          (value: {
            sub_skills: {
              sub_skills: {
                title: string;
                title_DE: string | null;
                title_EN: string | null;
                title_ES: string | null;
              }[];
              title: string;
              title_DE: string | null;
              title_EN: string | null;
              title_ES: string | null;
            }[];
            title: string;
            title_DE: string | null;
            title_EN: string | null;
            title_ES: string | null;
          }): unknown => {
            return {
              sub_skills: ((
                value: {
                  sub_skills: {
                    title: string;
                    title_DE: string | null;
                    title_EN: string | null;
                    title_ES: string | null;
                  }[];
                  title: string;
                  title_DE: string | null;
                  title_EN: string | null;
                  title_ES: string | null;
                }[],
              ): unknown => {
                return value.map(
                  (value: {
                    sub_skills: {
                      title: string;
                      title_DE: string | null;
                      title_EN: string | null;
                      title_ES: string | null;
                    }[];
                    title: string;
                    title_DE: string | null;
                    title_EN: string | null;
                    title_ES: string | null;
                  }): unknown => {
                    return {
                      sub_skills: ((
                        value: {
                          title: string;
                          title_DE: string | null;
                          title_EN: string | null;
                          title_ES: string | null;
                        }[],
                      ): unknown => {
                        return value.map((value: { title: string; title_DE: string | null; title_EN: string | null; title_ES: string | null }): unknown => {
                          return {
                            title: ((value: string): unknown => {
                              return value;
                            })(value["title"]),
                            title_DE: ((value: string | null): unknown => {
                              if (value === null) {
                                return null;
                              }
                              return value;
                            })(value["title_DE"]),
                            title_EN: ((value: string | null): unknown => {
                              if (value === null) {
                                return null;
                              }
                              return value;
                            })(value["title_EN"]),
                            title_ES: ((value: string | null): unknown => {
                              if (value === null) {
                                return null;
                              }
                              return value;
                            })(value["title_ES"]),
                          };
                        });
                      })(value["sub_skills"]),
                      title: ((value: string): unknown => {
                        return value;
                      })(value["title"]),
                      title_DE: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["title_DE"]),
                      title_EN: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["title_EN"]),
                      title_ES: ((value: string | null): unknown => {
                        if (value === null) {
                          return null;
                        }
                        return value;
                      })(value["title_ES"]),
                    };
                  },
                );
              })(value["sub_skills"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
              title_DE: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["title_DE"]),
              title_EN: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["title_EN"]),
              title_ES: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["title_ES"]),
            };
          },
        );
      })(value["skills"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SkillTaxonomy(str: string): SkillTaxonomy | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          skills: {
            sub_skills: {
              sub_skills: {
                title: string;
                title_DE: string | null;
                title_EN: string | null;
                title_ES: string | null;
              }[];
              title: string;
              title_DE: string | null;
              title_EN: string | null;
              title_ES: string | null;
            }[];
            title: string;
            title_DE: string | null;
            title_EN: string | null;
            title_ES: string | null;
          }[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "skills")) {
      return { success: false };
    }
    const parsed_skills = (() => {
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value: {
              sub_skills: {
                sub_skills: {
                  title: string;
                  title_DE: string | null;
                  title_EN: string | null;
                  title_ES: string | null;
                }[];
                title: string;
                title_DE: string | null;
                title_EN: string | null;
                title_ES: string | null;
              }[];
              title: string;
              title_DE: string | null;
              title_EN: string | null;
              title_ES: string | null;
            }[];
          } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map(
          (
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  sub_skills: {
                    sub_skills: {
                      title: string;
                      title_DE: string | null;
                      title_EN: string | null;
                      title_ES: string | null;
                    }[];
                    title: string;
                    title_DE: string | null;
                    title_EN: string | null;
                    title_ES: string | null;
                  }[];
                  title: string;
                  title_DE: string | null;
                  title_EN: string | null;
                  title_ES: string | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "sub_skills")) {
              return { success: false };
            }
            const parsed_sub_skills = (() => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      sub_skills: {
                        title: string;
                        title_DE: string | null;
                        title_EN: string | null;
                        title_ES: string | null;
                      }[];
                      title: string;
                      title_DE: string | null;
                      title_EN: string | null;
                      title_ES: string | null;
                    }[];
                  } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map(
                  (
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          sub_skills: {
                            title: string;
                            title_DE: string | null;
                            title_EN: string | null;
                            title_ES: string | null;
                          }[];
                          title: string;
                          title_DE: string | null;
                          title_EN: string | null;
                          title_ES: string | null;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "sub_skills")) {
                      return { success: false };
                    }
                    const parsed_sub_skills = (() => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              title: string;
                              title_DE: string | null;
                              title_EN: string | null;
                              title_ES: string | null;
                            }[];
                          } => {
                        if (!isArray(value)) {
                          return { success: false };
                        }
                        const parsed = value.map(
                          (
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  title: string;
                                  title_DE: string | null;
                                  title_EN: string | null;
                                  title_ES: string | null;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "title")) {
                              return { success: false };
                            }
                            const parsed_title = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["title"]);
                            })();
                            if (!parsed_title.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "title_DE")) {
                              return { success: false };
                            }
                            const parsed_title_DE = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                                if (value === null) {
                                  return { success: true, value: null };
                                }
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["title_DE"]);
                            })();
                            if (!parsed_title_DE.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "title_EN")) {
                              return { success: false };
                            }
                            const parsed_title_EN = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                                if (value === null) {
                                  return { success: true, value: null };
                                }
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["title_EN"]);
                            })();
                            if (!parsed_title_EN.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "title_ES")) {
                              return { success: false };
                            }
                            const parsed_title_ES = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                                if (value === null) {
                                  return { success: true, value: null };
                                }
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["title_ES"]);
                            })();
                            if (!parsed_title_ES.success) {
                              return { success: false };
                            }
                            return { success: true, value: { title: parsed_title.value, title_DE: parsed_title_DE.value, title_EN: parsed_title_EN.value, title_ES: parsed_title_ES.value } };
                          },
                        );
                        if (parsed.find((value) => !value.success) !== undefined) {
                          return { success: false };
                        }
                        return {
                          success: true,
                          value: parsed.map((entry) => {
                            assert(entry.success == true);
                            return entry.value;
                          }),
                        };
                      })(value["sub_skills"]);
                    })();
                    if (!parsed_sub_skills.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "title")) {
                      return { success: false };
                    }
                    const parsed_title = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["title"]);
                    })();
                    if (!parsed_title.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "title_DE")) {
                      return { success: false };
                    }
                    const parsed_title_DE = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["title_DE"]);
                    })();
                    if (!parsed_title_DE.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "title_EN")) {
                      return { success: false };
                    }
                    const parsed_title_EN = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["title_EN"]);
                    })();
                    if (!parsed_title_EN.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "title_ES")) {
                      return { success: false };
                    }
                    const parsed_title_ES = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["title_ES"]);
                    })();
                    if (!parsed_title_ES.success) {
                      return { success: false };
                    }
                    return { success: true, value: { sub_skills: parsed_sub_skills.value, title: parsed_title.value, title_DE: parsed_title_DE.value, title_EN: parsed_title_EN.value, title_ES: parsed_title_ES.value } };
                  },
                );
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["sub_skills"]);
            })();
            if (!parsed_sub_skills.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            if (!hasProp(value, "title_DE")) {
              return { success: false };
            }
            const parsed_title_DE = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title_DE"]);
            })();
            if (!parsed_title_DE.success) {
              return { success: false };
            }
            if (!hasProp(value, "title_EN")) {
              return { success: false };
            }
            const parsed_title_EN = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title_EN"]);
            })();
            if (!parsed_title_EN.success) {
              return { success: false };
            }
            if (!hasProp(value, "title_ES")) {
              return { success: false };
            }
            const parsed_title_ES = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title_ES"]);
            })();
            if (!parsed_title_ES.success) {
              return { success: false };
            }
            return { success: true, value: { sub_skills: parsed_sub_skills.value, title: parsed_title.value, title_DE: parsed_title_DE.value, title_EN: parsed_title_EN.value, title_ES: parsed_title_ES.value } };
          },
        );
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["skills"]);
    })();
    if (!parsed_skills.success) {
      return { success: false };
    }
    return { success: true, value: { skills: parsed_skills.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// StudentIdVerificationProvider
export function serialize_StudentIdVerificationProvider(obj: StudentIdVerificationProvider): string {
  const serialized = ((value: "UNIDAYS" | "UNIDAYS_PERK" | "SHEERID" | "MANUAL" | "UNKNOWN"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_StudentIdVerificationProvider(str: string): StudentIdVerificationProvider | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "UNIDAYS" | "UNIDAYS_PERK" | "SHEERID" | "MANUAL" | "UNKNOWN" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "UNIDAYS":
        return { success: true, value: "UNIDAYS" };
      case "UNIDAYS_PERK":
        return { success: true, value: "UNIDAYS_PERK" };
      case "SHEERID":
        return { success: true, value: "SHEERID" };
      case "MANUAL":
        return { success: true, value: "MANUAL" };
      case "UNKNOWN":
        return { success: true, value: "UNKNOWN" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// StudentVerificationStatus
export function serialize_StudentVerificationStatus(obj: StudentVerificationStatus): string {
  const serialized = ((value: "VERIFIED" | "EXPIRED" | "FAILED" | "IN_PROGRESS"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_StudentVerificationStatus(str: string): StudentVerificationStatus | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "VERIFIED" | "EXPIRED" | "FAILED" | "IN_PROGRESS" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "VERIFIED":
        return { success: true, value: "VERIFIED" };
      case "EXPIRED":
        return { success: true, value: "EXPIRED" };
      case "FAILED":
        return { success: true, value: "FAILED" };
      case "IN_PROGRESS":
        return { success: true, value: "IN_PROGRESS" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// StudentVerificationView
export function serialize_StudentVerificationView(obj: StudentVerificationView): string {
  const serialized = ((value: {
    countryOfStudy: string;
    createdAt: DateTime<true>;
    fieldOfStudy: string | null;
    proof: string | null;
    provider: StudentIdVerificationProvider;
    status: StudentVerificationStatus;
    subscriptionId: number | null;
    verifiedAt: DateTime<true> | null;
  }): unknown => {
    return {
      countryOfStudy: ((value: string): unknown => {
        return value;
      })(value["countryOfStudy"]),
      createdAt: ((value: DateTime<true>): unknown => {
        return value.toMillis();
      })(value["createdAt"]),
      fieldOfStudy: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["fieldOfStudy"]),
      proof: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["proof"]),
      provider: ((value: "UNIDAYS" | "UNIDAYS_PERK" | "SHEERID" | "MANUAL" | "UNKNOWN"): unknown => {
        return value;
      })(value["provider"]),
      status: ((value: "VERIFIED" | "EXPIRED" | "FAILED" | "IN_PROGRESS"): unknown => {
        return value;
      })(value["status"]),
      subscriptionId: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["subscriptionId"]),
      verifiedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["verifiedAt"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_StudentVerificationView(str: string): StudentVerificationView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          countryOfStudy: string;
          createdAt: DateTime<true>;
          fieldOfStudy: string | null;
          proof: string | null;
          provider: StudentIdVerificationProvider;
          status: StudentVerificationStatus;
          subscriptionId: number | null;
          verifiedAt: DateTime<true> | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "countryOfStudy")) {
      return { success: false };
    }
    const parsed_countryOfStudy = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["countryOfStudy"]);
    })();
    if (!parsed_countryOfStudy.success) {
      return { success: false };
    }
    if (!hasProp(value, "createdAt")) {
      return { success: false };
    }
    const parsed_createdAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["createdAt"]);
    })();
    if (!parsed_createdAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "fieldOfStudy")) {
      return { success: false };
    }
    const parsed_fieldOfStudy = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["fieldOfStudy"]);
    })();
    if (!parsed_fieldOfStudy.success) {
      return { success: false };
    }
    if (!hasProp(value, "proof")) {
      return { success: false };
    }
    const parsed_proof = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["proof"]);
    })();
    if (!parsed_proof.success) {
      return { success: false };
    }
    if (!hasProp(value, "provider")) {
      return { success: false };
    }
    const parsed_provider = (() => {
      return ((value: unknown): { success: false } | { success: true; value: StudentIdVerificationProvider } => {
        return ((value: unknown): { success: false } | { success: true; value: "UNIDAYS" | "UNIDAYS_PERK" | "SHEERID" | "MANUAL" | "UNKNOWN" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "UNIDAYS":
              return { success: true, value: "UNIDAYS" };
            case "UNIDAYS_PERK":
              return { success: true, value: "UNIDAYS_PERK" };
            case "SHEERID":
              return { success: true, value: "SHEERID" };
            case "MANUAL":
              return { success: true, value: "MANUAL" };
            case "UNKNOWN":
              return { success: true, value: "UNKNOWN" };
            default:
              return { success: false };
          }
        })(value);
      })(value["provider"]);
    })();
    if (!parsed_provider.success) {
      return { success: false };
    }
    if (!hasProp(value, "status")) {
      return { success: false };
    }
    const parsed_status = (() => {
      return ((value: unknown): { success: false } | { success: true; value: StudentVerificationStatus } => {
        return ((value: unknown): { success: false } | { success: true; value: "VERIFIED" | "EXPIRED" | "FAILED" | "IN_PROGRESS" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "VERIFIED":
              return { success: true, value: "VERIFIED" };
            case "EXPIRED":
              return { success: true, value: "EXPIRED" };
            case "FAILED":
              return { success: true, value: "FAILED" };
            case "IN_PROGRESS":
              return { success: true, value: "IN_PROGRESS" };
            default:
              return { success: false };
          }
        })(value);
      })(value["status"]);
    })();
    if (!parsed_status.success) {
      return { success: false };
    }
    if (!hasProp(value, "subscriptionId")) {
      return { success: false };
    }
    const parsed_subscriptionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["subscriptionId"]);
    })();
    if (!parsed_subscriptionId.success) {
      return { success: false };
    }
    if (!hasProp(value, "verifiedAt")) {
      return { success: false };
    }
    const parsed_verifiedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["verifiedAt"]);
    })();
    if (!parsed_verifiedAt.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        countryOfStudy: parsed_countryOfStudy.value,
        createdAt: parsed_createdAt.value,
        fieldOfStudy: parsed_fieldOfStudy.value,
        proof: parsed_proof.value,
        provider: parsed_provider.value,
        status: parsed_status.value,
        subscriptionId: parsed_subscriptionId.value,
        verifiedAt: parsed_verifiedAt.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SubscriptionAdminForm
export function serialize_SubscriptionAdminForm(obj: SubscriptionAdminForm): string {
  const serialized = ((value: {
    extraMonths: number | null;
    isPaid: boolean;
    productId: number;
    reference: string | null;
    showCommentModal: boolean;
    status: SubscriptionStatus;
    studentVerificationCountryOfStudy: string | null;
    studentVerificationFieldOfStudy: string | null;
    studentVerificationProof: string | null;
    studentVerificationProvider: string | null;
    studentVerificationStatus: string | null;
    studentVerificationVerifiedAt: DateTime<true> | null;
    subscriptionEndDate: { year: number; month: number; day: number };
    subscriptionId: number;
    subscriptionStartDate: { year: number; month: number; day: number };
    transactions: SubscriptionTransaction[];
  }): unknown => {
    return {
      extraMonths: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["extraMonths"]),
      isPaid: ((value: boolean): unknown => {
        return value;
      })(value["isPaid"]),
      productId: ((value: number): unknown => {
        return value;
      })(value["productId"]),
      reference: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["reference"]),
      showCommentModal: ((value: boolean): unknown => {
        return value;
      })(value["showCommentModal"]),
      status: ((value: "ACTIVE" | "STOP" | "CANCEL" | "RENEWED" | "RENEWALFAILED"): unknown => {
        return value;
      })(value["status"]),
      studentVerificationCountryOfStudy: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["studentVerificationCountryOfStudy"]),
      studentVerificationFieldOfStudy: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["studentVerificationFieldOfStudy"]),
      studentVerificationProof: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["studentVerificationProof"]),
      studentVerificationProvider: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["studentVerificationProvider"]),
      studentVerificationStatus: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["studentVerificationStatus"]),
      studentVerificationVerifiedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["studentVerificationVerifiedAt"]),
      subscriptionEndDate: ((value: { year: number; month: number; day: number }): unknown => {
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["subscriptionEndDate"]),
      subscriptionId: ((value: number): unknown => {
        return value;
      })(value["subscriptionId"]),
      subscriptionStartDate: ((value: { year: number; month: number; day: number }): unknown => {
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["subscriptionStartDate"]),
      transactions: ((value: SubscriptionTransaction[]): unknown => {
        return value.map(
          (value: {
            amount: DisplayMonetaryAmount;
            comment: string | null;
            customerId: number;
            generatedAt: { year: number; month: number; day: number };
            paymentInfoAlipay: AlipayPaymentView | null;
            paymentInfoAndroid: AndroidPaymentView | null;
            paymentInfoCC: CreditCardPaymentView | null;
            paymentInfoIOS: IosPaymentView | null;
            paymentInfoInvoice: InvoicePaymentView | null;
            paymentInfoLSV: LsvPaymentView | null;
            paymentInfoMiles: MilesPaymentView | null;
            paymentInfoPaypal: PayPalPaymentView | null;
            paymentReceiptUri: string;
            paymentType: PaymentType;
            quantity: number;
            subscriptionId: number;
            transactionId: number;
            transactionType: "DEBIT" | "CREDIT";
          }): unknown => {
            return {
              amount: ((value: { amount: number; currencyCode: string; displayValue: string }): unknown => {
                return {
                  amount: ((value: number): unknown => {
                    return value;
                  })(value["amount"]),
                  currencyCode: ((value: string): unknown => {
                    return value;
                  })(value["currencyCode"]),
                  displayValue: ((value: string): unknown => {
                    return value;
                  })(value["displayValue"]),
                };
              })(value["amount"]),
              comment: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["comment"]),
              customerId: ((value: number): unknown => {
                return value;
              })(value["customerId"]),
              generatedAt: ((value: { year: number; month: number; day: number }): unknown => {
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["generatedAt"]),
              paymentInfoAlipay: ((
                value: {
                  outTradeNo: string | null;
                  tradeNo: string | null;
                  transactionId: number;
                } | null,
              ): unknown => {
                if (value === null) {
                  return null;
                }
                return {
                  outTradeNo: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["outTradeNo"]),
                  tradeNo: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["tradeNo"]),
                  transactionId: ((value: number): unknown => {
                    return value;
                  })(value["transactionId"]),
                };
              })(value["paymentInfoAlipay"]),
              paymentInfoAndroid: ((
                value: {
                  appStoreProductId: string | null;
                  autoRenewing: boolean;
                  countryCode: string | null;
                  orderId: string | null;
                  paymentState: number;
                  purchaseState: number;
                  testPurchase: boolean;
                  transactionId: number;
                } | null,
              ): unknown => {
                if (value === null) {
                  return null;
                }
                return {
                  appStoreProductId: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["appStoreProductId"]),
                  autoRenewing: ((value: boolean): unknown => {
                    return value;
                  })(value["autoRenewing"]),
                  countryCode: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["countryCode"]),
                  orderId: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["orderId"]),
                  paymentState: ((value: number): unknown => {
                    return value;
                  })(value["paymentState"]),
                  purchaseState: ((value: number): unknown => {
                    return value;
                  })(value["purchaseState"]),
                  testPurchase: ((value: boolean): unknown => {
                    return value;
                  })(value["testPurchase"]),
                  transactionId: ((value: number): unknown => {
                    return value;
                  })(value["transactionId"]),
                };
              })(value["paymentInfoAndroid"]),
              paymentInfoCC: ((
                value: {
                  acqAuthorizationCode: string | null;
                  authorizationCode: string | null;
                  batchRefNumber: string | null;
                  creditCardAlias: string | null;
                  creditCardNumber: string;
                  creditCardType: CreditCardType;
                  datatransMerchantId: string | null;
                  expirationMonth: number;
                  expirationYear: number;
                  gatewayProvider: string;
                  nameOnCard: string | null;
                  refNumber: string | null;
                  transactionDateTime: DateTime<true>;
                  transactionId: number;
                } | null,
              ): unknown => {
                if (value === null) {
                  return null;
                }
                return {
                  acqAuthorizationCode: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["acqAuthorizationCode"]),
                  authorizationCode: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["authorizationCode"]),
                  batchRefNumber: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["batchRefNumber"]),
                  creditCardAlias: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["creditCardAlias"]),
                  creditCardNumber: ((value: string): unknown => {
                    return value;
                  })(value["creditCardNumber"]),
                  creditCardType: ((value: "VISA" | "MASTERCARD" | "AMEXCO"): unknown => {
                    return value;
                  })(value["creditCardType"]),
                  datatransMerchantId: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["datatransMerchantId"]),
                  expirationMonth: ((value: number): unknown => {
                    return value;
                  })(value["expirationMonth"]),
                  expirationYear: ((value: number): unknown => {
                    return value;
                  })(value["expirationYear"]),
                  gatewayProvider: ((value: string): unknown => {
                    return value;
                  })(value["gatewayProvider"]),
                  nameOnCard: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["nameOnCard"]),
                  refNumber: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["refNumber"]),
                  transactionDateTime: ((value: DateTime<true>): unknown => {
                    return value.toMillis();
                  })(value["transactionDateTime"]),
                  transactionId: ((value: number): unknown => {
                    return value;
                  })(value["transactionId"]),
                };
              })(value["paymentInfoCC"]),
              paymentInfoIOS: ((
                value: {
                  appStoreProductId: string;
                  autoRenewProductId: string | null;
                  autoRenewing: boolean;
                  expirationIntent: number;
                  itunesTransactionId: string;
                  originalItunesTransactionId: string | null;
                  testPurchase: boolean;
                  transactionId: number;
                  webOrderLineItemId: bigint;
                } | null,
              ): unknown => {
                if (value === null) {
                  return null;
                }
                return {
                  appStoreProductId: ((value: string): unknown => {
                    return value;
                  })(value["appStoreProductId"]),
                  autoRenewProductId: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["autoRenewProductId"]),
                  autoRenewing: ((value: boolean): unknown => {
                    return value;
                  })(value["autoRenewing"]),
                  expirationIntent: ((value: number): unknown => {
                    return value;
                  })(value["expirationIntent"]),
                  itunesTransactionId: ((value: string): unknown => {
                    return value;
                  })(value["itunesTransactionId"]),
                  originalItunesTransactionId: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["originalItunesTransactionId"]),
                  testPurchase: ((value: boolean): unknown => {
                    return value;
                  })(value["testPurchase"]),
                  transactionId: ((value: number): unknown => {
                    return value;
                  })(value["transactionId"]),
                  webOrderLineItemId: ((value: bigint): unknown => {
                    return value;
                  })(value["webOrderLineItemId"]),
                };
              })(value["paymentInfoIOS"]),
              paymentInfoInvoice: ((
                value: {
                  paidAt: DateTime<true> | null;
                  reference: string | null;
                  transactionId: number;
                } | null,
              ): unknown => {
                if (value === null) {
                  return null;
                }
                return {
                  paidAt: ((value: DateTime<true> | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value.toMillis();
                  })(value["paidAt"]),
                  reference: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["reference"]),
                  transactionId: ((value: number): unknown => {
                    return value;
                  })(value["transactionId"]),
                };
              })(value["paymentInfoInvoice"]),
              paymentInfoLSV: ((
                value: {
                  accountNumber: string | null;
                  acqAuthorizationCode: string | null;
                  authorizationCode: string | null;
                  bankIban: string | null;
                  bankName: string | null;
                  bankTown: string | null;
                  clearingNumber: string | null;
                  firstName: string;
                  lastName: string;
                  merchantId: string | null;
                  refNumber: string | null;
                  transactionId: number;
                } | null,
              ): unknown => {
                if (value === null) {
                  return null;
                }
                return {
                  accountNumber: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["accountNumber"]),
                  acqAuthorizationCode: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["acqAuthorizationCode"]),
                  authorizationCode: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["authorizationCode"]),
                  bankIban: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["bankIban"]),
                  bankName: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["bankName"]),
                  bankTown: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["bankTown"]),
                  clearingNumber: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["clearingNumber"]),
                  firstName: ((value: string): unknown => {
                    return value;
                  })(value["firstName"]),
                  lastName: ((value: string): unknown => {
                    return value;
                  })(value["lastName"]),
                  merchantId: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["merchantId"]),
                  refNumber: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["refNumber"]),
                  transactionId: ((value: number): unknown => {
                    return value;
                  })(value["transactionId"]),
                };
              })(value["paymentInfoLSV"]),
              paymentInfoMiles: ((
                value: {
                  cardNumber: string | null;
                  milesTransactionId: number | null;
                  pin: string | null;
                  transactionId: number;
                } | null,
              ): unknown => {
                if (value === null) {
                  return null;
                }
                return {
                  cardNumber: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["cardNumber"]),
                  milesTransactionId: ((value: number | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["milesTransactionId"]),
                  pin: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["pin"]),
                  transactionId: ((value: number): unknown => {
                    return value;
                  })(value["transactionId"]),
                };
              })(value["paymentInfoMiles"]),
              paymentInfoPaypal: ((
                value: {
                  acqAuthorizationCode: string | null;
                  authorizationCode: string | null;
                  merchantId: string | null;
                  paypalBillingAgreementId: string | null;
                  refNumber: string | null;
                  transactionId: number;
                } | null,
              ): unknown => {
                if (value === null) {
                  return null;
                }
                return {
                  acqAuthorizationCode: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["acqAuthorizationCode"]),
                  authorizationCode: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["authorizationCode"]),
                  merchantId: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["merchantId"]),
                  paypalBillingAgreementId: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["paypalBillingAgreementId"]),
                  refNumber: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["refNumber"]),
                  transactionId: ((value: number): unknown => {
                    return value;
                  })(value["transactionId"]),
                };
              })(value["paymentInfoPaypal"]),
              paymentReceiptUri: ((value: string): unknown => {
                return value;
              })(value["paymentReceiptUri"]),
              paymentType: ((value: "CC" | "INVOICE" | "PAYPAL" | "LSV" | "MILES" | "ALIPAY" | "IOS" | "ANDROID"): unknown => {
                return value;
              })(value["paymentType"]),
              quantity: ((value: number): unknown => {
                return value;
              })(value["quantity"]),
              subscriptionId: ((value: number): unknown => {
                return value;
              })(value["subscriptionId"]),
              transactionId: ((value: number): unknown => {
                return value;
              })(value["transactionId"]),
              transactionType: ((value: "DEBIT" | "CREDIT"): unknown => {
                return value;
              })(value["transactionType"]),
            };
          },
        );
      })(value["transactions"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SubscriptionAdminForm(str: string): SubscriptionAdminForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          extraMonths: number | null;
          isPaid: boolean;
          productId: number;
          reference: string | null;
          showCommentModal: boolean;
          status: SubscriptionStatus;
          studentVerificationCountryOfStudy: string | null;
          studentVerificationFieldOfStudy: string | null;
          studentVerificationProof: string | null;
          studentVerificationProvider: string | null;
          studentVerificationStatus: string | null;
          studentVerificationVerifiedAt: DateTime<true> | null;
          subscriptionEndDate: { year: number; month: number; day: number };
          subscriptionId: number;
          subscriptionStartDate: { year: number; month: number; day: number };
          transactions: SubscriptionTransaction[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "extraMonths")) {
      return { success: false };
    }
    const parsed_extraMonths = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["extraMonths"]);
    })();
    if (!parsed_extraMonths.success) {
      return { success: false };
    }
    if (!hasProp(value, "isPaid")) {
      return { success: false };
    }
    const parsed_isPaid = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isPaid"]);
    })();
    if (!parsed_isPaid.success) {
      return { success: false };
    }
    if (!hasProp(value, "productId")) {
      return { success: false };
    }
    const parsed_productId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["productId"]);
    })();
    if (!parsed_productId.success) {
      return { success: false };
    }
    if (!hasProp(value, "reference")) {
      return { success: false };
    }
    const parsed_reference = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["reference"]);
    })();
    if (!parsed_reference.success) {
      return { success: false };
    }
    if (!hasProp(value, "showCommentModal")) {
      return { success: false };
    }
    const parsed_showCommentModal = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["showCommentModal"]);
    })();
    if (!parsed_showCommentModal.success) {
      return { success: false };
    }
    if (!hasProp(value, "status")) {
      return { success: false };
    }
    const parsed_status = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SubscriptionStatus } => {
        return ((value: unknown): { success: false } | { success: true; value: "ACTIVE" | "STOP" | "CANCEL" | "RENEWED" | "RENEWALFAILED" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "ACTIVE":
              return { success: true, value: "ACTIVE" };
            case "STOP":
              return { success: true, value: "STOP" };
            case "CANCEL":
              return { success: true, value: "CANCEL" };
            case "RENEWED":
              return { success: true, value: "RENEWED" };
            case "RENEWALFAILED":
              return { success: true, value: "RENEWALFAILED" };
            default:
              return { success: false };
          }
        })(value);
      })(value["status"]);
    })();
    if (!parsed_status.success) {
      return { success: false };
    }
    if (!hasProp(value, "studentVerificationCountryOfStudy")) {
      return { success: false };
    }
    const parsed_studentVerificationCountryOfStudy = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["studentVerificationCountryOfStudy"]);
    })();
    if (!parsed_studentVerificationCountryOfStudy.success) {
      return { success: false };
    }
    if (!hasProp(value, "studentVerificationFieldOfStudy")) {
      return { success: false };
    }
    const parsed_studentVerificationFieldOfStudy = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["studentVerificationFieldOfStudy"]);
    })();
    if (!parsed_studentVerificationFieldOfStudy.success) {
      return { success: false };
    }
    if (!hasProp(value, "studentVerificationProof")) {
      return { success: false };
    }
    const parsed_studentVerificationProof = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["studentVerificationProof"]);
    })();
    if (!parsed_studentVerificationProof.success) {
      return { success: false };
    }
    if (!hasProp(value, "studentVerificationProvider")) {
      return { success: false };
    }
    const parsed_studentVerificationProvider = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["studentVerificationProvider"]);
    })();
    if (!parsed_studentVerificationProvider.success) {
      return { success: false };
    }
    if (!hasProp(value, "studentVerificationStatus")) {
      return { success: false };
    }
    const parsed_studentVerificationStatus = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["studentVerificationStatus"]);
    })();
    if (!parsed_studentVerificationStatus.success) {
      return { success: false };
    }
    if (!hasProp(value, "studentVerificationVerifiedAt")) {
      return { success: false };
    }
    const parsed_studentVerificationVerifiedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["studentVerificationVerifiedAt"]);
    })();
    if (!parsed_studentVerificationVerifiedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "subscriptionEndDate")) {
      return { success: false };
    }
    const parsed_subscriptionEndDate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["subscriptionEndDate"]);
    })();
    if (!parsed_subscriptionEndDate.success) {
      return { success: false };
    }
    if (!hasProp(value, "subscriptionId")) {
      return { success: false };
    }
    const parsed_subscriptionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["subscriptionId"]);
    })();
    if (!parsed_subscriptionId.success) {
      return { success: false };
    }
    if (!hasProp(value, "subscriptionStartDate")) {
      return { success: false };
    }
    const parsed_subscriptionStartDate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["subscriptionStartDate"]);
    })();
    if (!parsed_subscriptionStartDate.success) {
      return { success: false };
    }
    if (!hasProp(value, "transactions")) {
      return { success: false };
    }
    const parsed_transactions = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SubscriptionTransaction[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: SubscriptionTransaction } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  amount: DisplayMonetaryAmount;
                  comment: string | null;
                  customerId: number;
                  generatedAt: { year: number; month: number; day: number };
                  paymentInfoAlipay: AlipayPaymentView | null;
                  paymentInfoAndroid: AndroidPaymentView | null;
                  paymentInfoCC: CreditCardPaymentView | null;
                  paymentInfoIOS: IosPaymentView | null;
                  paymentInfoInvoice: InvoicePaymentView | null;
                  paymentInfoLSV: LsvPaymentView | null;
                  paymentInfoMiles: MilesPaymentView | null;
                  paymentInfoPaypal: PayPalPaymentView | null;
                  paymentReceiptUri: string;
                  paymentType: PaymentType;
                  quantity: number;
                  subscriptionId: number;
                  transactionId: number;
                  transactionType: "DEBIT" | "CREDIT";
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "amount")) {
              return { success: false };
            }
            const parsed_amount = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DisplayMonetaryAmount } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        amount: number;
                        currencyCode: string;
                        displayValue: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "amount")) {
                    return { success: false };
                  }
                  const parsed_amount = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: Number(value) };
                      }
                      return { success: false };
                    })(value["amount"]);
                  })();
                  if (!parsed_amount.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "currencyCode")) {
                    return { success: false };
                  }
                  const parsed_currencyCode = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["currencyCode"]);
                  })();
                  if (!parsed_currencyCode.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "displayValue")) {
                    return { success: false };
                  }
                  const parsed_displayValue = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["displayValue"]);
                  })();
                  if (!parsed_displayValue.success) {
                    return { success: false };
                  }
                  return { success: true, value: { amount: parsed_amount.value, currencyCode: parsed_currencyCode.value, displayValue: parsed_displayValue.value } };
                })(value);
              })(value["amount"]);
            })();
            if (!parsed_amount.success) {
              return { success: false };
            }
            if (!hasProp(value, "comment")) {
              return { success: false };
            }
            const parsed_comment = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["comment"]);
            })();
            if (!parsed_comment.success) {
              return { success: false };
            }
            if (!hasProp(value, "customerId")) {
              return { success: false };
            }
            const parsed_customerId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["customerId"]);
            })();
            if (!parsed_customerId.success) {
              return { success: false };
            }
            if (!hasProp(value, "generatedAt")) {
              return { success: false };
            }
            const parsed_generatedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
              })(value["generatedAt"]);
            })();
            if (!parsed_generatedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "paymentInfoAlipay")) {
              return { success: false };
            }
            const parsed_paymentInfoAlipay = (() => {
              return ((value: unknown): { success: false } | { success: true; value: AlipayPaymentView | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        outTradeNo: string | null;
                        tradeNo: string | null;
                        transactionId: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "outTradeNo")) {
                    return { success: false };
                  }
                  const parsed_outTradeNo = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["outTradeNo"]);
                  })();
                  if (!parsed_outTradeNo.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tradeNo")) {
                    return { success: false };
                  }
                  const parsed_tradeNo = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["tradeNo"]);
                  })();
                  if (!parsed_tradeNo.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "transactionId")) {
                    return { success: false };
                  }
                  const parsed_transactionId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["transactionId"]);
                  })();
                  if (!parsed_transactionId.success) {
                    return { success: false };
                  }
                  return { success: true, value: { outTradeNo: parsed_outTradeNo.value, tradeNo: parsed_tradeNo.value, transactionId: parsed_transactionId.value } };
                })(value);
              })(value["paymentInfoAlipay"]);
            })();
            if (!parsed_paymentInfoAlipay.success) {
              return { success: false };
            }
            if (!hasProp(value, "paymentInfoAndroid")) {
              return { success: false };
            }
            const parsed_paymentInfoAndroid = (() => {
              return ((value: unknown): { success: false } | { success: true; value: AndroidPaymentView | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        appStoreProductId: string | null;
                        autoRenewing: boolean;
                        countryCode: string | null;
                        orderId: string | null;
                        paymentState: number;
                        purchaseState: number;
                        testPurchase: boolean;
                        transactionId: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "appStoreProductId")) {
                    return { success: false };
                  }
                  const parsed_appStoreProductId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["appStoreProductId"]);
                  })();
                  if (!parsed_appStoreProductId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "autoRenewing")) {
                    return { success: false };
                  }
                  const parsed_autoRenewing = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["autoRenewing"]);
                  })();
                  if (!parsed_autoRenewing.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "countryCode")) {
                    return { success: false };
                  }
                  const parsed_countryCode = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["countryCode"]);
                  })();
                  if (!parsed_countryCode.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "orderId")) {
                    return { success: false };
                  }
                  const parsed_orderId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["orderId"]);
                  })();
                  if (!parsed_orderId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "paymentState")) {
                    return { success: false };
                  }
                  const parsed_paymentState = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["paymentState"]);
                  })();
                  if (!parsed_paymentState.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "purchaseState")) {
                    return { success: false };
                  }
                  const parsed_purchaseState = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["purchaseState"]);
                  })();
                  if (!parsed_purchaseState.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "testPurchase")) {
                    return { success: false };
                  }
                  const parsed_testPurchase = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["testPurchase"]);
                  })();
                  if (!parsed_testPurchase.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "transactionId")) {
                    return { success: false };
                  }
                  const parsed_transactionId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["transactionId"]);
                  })();
                  if (!parsed_transactionId.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      appStoreProductId: parsed_appStoreProductId.value,
                      autoRenewing: parsed_autoRenewing.value,
                      countryCode: parsed_countryCode.value,
                      orderId: parsed_orderId.value,
                      paymentState: parsed_paymentState.value,
                      purchaseState: parsed_purchaseState.value,
                      testPurchase: parsed_testPurchase.value,
                      transactionId: parsed_transactionId.value,
                    },
                  };
                })(value);
              })(value["paymentInfoAndroid"]);
            })();
            if (!parsed_paymentInfoAndroid.success) {
              return { success: false };
            }
            if (!hasProp(value, "paymentInfoCC")) {
              return { success: false };
            }
            const parsed_paymentInfoCC = (() => {
              return ((value: unknown): { success: false } | { success: true; value: CreditCardPaymentView | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        acqAuthorizationCode: string | null;
                        authorizationCode: string | null;
                        batchRefNumber: string | null;
                        creditCardAlias: string | null;
                        creditCardNumber: string;
                        creditCardType: CreditCardType;
                        datatransMerchantId: string | null;
                        expirationMonth: number;
                        expirationYear: number;
                        gatewayProvider: string;
                        nameOnCard: string | null;
                        refNumber: string | null;
                        transactionDateTime: DateTime<true>;
                        transactionId: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "acqAuthorizationCode")) {
                    return { success: false };
                  }
                  const parsed_acqAuthorizationCode = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["acqAuthorizationCode"]);
                  })();
                  if (!parsed_acqAuthorizationCode.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "authorizationCode")) {
                    return { success: false };
                  }
                  const parsed_authorizationCode = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["authorizationCode"]);
                  })();
                  if (!parsed_authorizationCode.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "batchRefNumber")) {
                    return { success: false };
                  }
                  const parsed_batchRefNumber = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["batchRefNumber"]);
                  })();
                  if (!parsed_batchRefNumber.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "creditCardAlias")) {
                    return { success: false };
                  }
                  const parsed_creditCardAlias = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["creditCardAlias"]);
                  })();
                  if (!parsed_creditCardAlias.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "creditCardNumber")) {
                    return { success: false };
                  }
                  const parsed_creditCardNumber = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["creditCardNumber"]);
                  })();
                  if (!parsed_creditCardNumber.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "creditCardType")) {
                    return { success: false };
                  }
                  const parsed_creditCardType = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: CreditCardType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "VISA" | "MASTERCARD" | "AMEXCO" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "VISA":
                            return { success: true, value: "VISA" };
                          case "MASTERCARD":
                            return { success: true, value: "MASTERCARD" };
                          case "AMEXCO":
                            return { success: true, value: "AMEXCO" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["creditCardType"]);
                  })();
                  if (!parsed_creditCardType.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "datatransMerchantId")) {
                    return { success: false };
                  }
                  const parsed_datatransMerchantId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["datatransMerchantId"]);
                  })();
                  if (!parsed_datatransMerchantId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "expirationMonth")) {
                    return { success: false };
                  }
                  const parsed_expirationMonth = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["expirationMonth"]);
                  })();
                  if (!parsed_expirationMonth.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "expirationYear")) {
                    return { success: false };
                  }
                  const parsed_expirationYear = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["expirationYear"]);
                  })();
                  if (!parsed_expirationYear.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "gatewayProvider")) {
                    return { success: false };
                  }
                  const parsed_gatewayProvider = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["gatewayProvider"]);
                  })();
                  if (!parsed_gatewayProvider.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "nameOnCard")) {
                    return { success: false };
                  }
                  const parsed_nameOnCard = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["nameOnCard"]);
                  })();
                  if (!parsed_nameOnCard.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "refNumber")) {
                    return { success: false };
                  }
                  const parsed_refNumber = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["refNumber"]);
                  })();
                  if (!parsed_refNumber.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "transactionDateTime")) {
                    return { success: false };
                  }
                  const parsed_transactionDateTime = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                      if (typeof value !== "number") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromMillis(value);
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: parsed };
                    })(value["transactionDateTime"]);
                  })();
                  if (!parsed_transactionDateTime.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "transactionId")) {
                    return { success: false };
                  }
                  const parsed_transactionId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["transactionId"]);
                  })();
                  if (!parsed_transactionId.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      acqAuthorizationCode: parsed_acqAuthorizationCode.value,
                      authorizationCode: parsed_authorizationCode.value,
                      batchRefNumber: parsed_batchRefNumber.value,
                      creditCardAlias: parsed_creditCardAlias.value,
                      creditCardNumber: parsed_creditCardNumber.value,
                      creditCardType: parsed_creditCardType.value,
                      datatransMerchantId: parsed_datatransMerchantId.value,
                      expirationMonth: parsed_expirationMonth.value,
                      expirationYear: parsed_expirationYear.value,
                      gatewayProvider: parsed_gatewayProvider.value,
                      nameOnCard: parsed_nameOnCard.value,
                      refNumber: parsed_refNumber.value,
                      transactionDateTime: parsed_transactionDateTime.value,
                      transactionId: parsed_transactionId.value,
                    },
                  };
                })(value);
              })(value["paymentInfoCC"]);
            })();
            if (!parsed_paymentInfoCC.success) {
              return { success: false };
            }
            if (!hasProp(value, "paymentInfoIOS")) {
              return { success: false };
            }
            const parsed_paymentInfoIOS = (() => {
              return ((value: unknown): { success: false } | { success: true; value: IosPaymentView | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        appStoreProductId: string;
                        autoRenewProductId: string | null;
                        autoRenewing: boolean;
                        expirationIntent: number;
                        itunesTransactionId: string;
                        originalItunesTransactionId: string | null;
                        testPurchase: boolean;
                        transactionId: number;
                        webOrderLineItemId: bigint;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "appStoreProductId")) {
                    return { success: false };
                  }
                  const parsed_appStoreProductId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["appStoreProductId"]);
                  })();
                  if (!parsed_appStoreProductId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "autoRenewProductId")) {
                    return { success: false };
                  }
                  const parsed_autoRenewProductId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["autoRenewProductId"]);
                  })();
                  if (!parsed_autoRenewProductId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "autoRenewing")) {
                    return { success: false };
                  }
                  const parsed_autoRenewing = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["autoRenewing"]);
                  })();
                  if (!parsed_autoRenewing.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "expirationIntent")) {
                    return { success: false };
                  }
                  const parsed_expirationIntent = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["expirationIntent"]);
                  })();
                  if (!parsed_expirationIntent.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "itunesTransactionId")) {
                    return { success: false };
                  }
                  const parsed_itunesTransactionId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["itunesTransactionId"]);
                  })();
                  if (!parsed_itunesTransactionId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "originalItunesTransactionId")) {
                    return { success: false };
                  }
                  const parsed_originalItunesTransactionId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["originalItunesTransactionId"]);
                  })();
                  if (!parsed_originalItunesTransactionId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "testPurchase")) {
                    return { success: false };
                  }
                  const parsed_testPurchase = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["testPurchase"]);
                  })();
                  if (!parsed_testPurchase.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "transactionId")) {
                    return { success: false };
                  }
                  const parsed_transactionId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["transactionId"]);
                  })();
                  if (!parsed_transactionId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "webOrderLineItemId")) {
                    return { success: false };
                  }
                  const parsed_webOrderLineItemId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["webOrderLineItemId"]);
                  })();
                  if (!parsed_webOrderLineItemId.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      appStoreProductId: parsed_appStoreProductId.value,
                      autoRenewProductId: parsed_autoRenewProductId.value,
                      autoRenewing: parsed_autoRenewing.value,
                      expirationIntent: parsed_expirationIntent.value,
                      itunesTransactionId: parsed_itunesTransactionId.value,
                      originalItunesTransactionId: parsed_originalItunesTransactionId.value,
                      testPurchase: parsed_testPurchase.value,
                      transactionId: parsed_transactionId.value,
                      webOrderLineItemId: parsed_webOrderLineItemId.value,
                    },
                  };
                })(value);
              })(value["paymentInfoIOS"]);
            })();
            if (!parsed_paymentInfoIOS.success) {
              return { success: false };
            }
            if (!hasProp(value, "paymentInfoInvoice")) {
              return { success: false };
            }
            const parsed_paymentInfoInvoice = (() => {
              return ((value: unknown): { success: false } | { success: true; value: InvoicePaymentView | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        paidAt: DateTime<true> | null;
                        reference: string | null;
                        transactionId: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "paidAt")) {
                    return { success: false };
                  }
                  const parsed_paidAt = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "number") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromMillis(value);
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: parsed };
                    })(value["paidAt"]);
                  })();
                  if (!parsed_paidAt.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "reference")) {
                    return { success: false };
                  }
                  const parsed_reference = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["reference"]);
                  })();
                  if (!parsed_reference.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "transactionId")) {
                    return { success: false };
                  }
                  const parsed_transactionId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["transactionId"]);
                  })();
                  if (!parsed_transactionId.success) {
                    return { success: false };
                  }
                  return { success: true, value: { paidAt: parsed_paidAt.value, reference: parsed_reference.value, transactionId: parsed_transactionId.value } };
                })(value);
              })(value["paymentInfoInvoice"]);
            })();
            if (!parsed_paymentInfoInvoice.success) {
              return { success: false };
            }
            if (!hasProp(value, "paymentInfoLSV")) {
              return { success: false };
            }
            const parsed_paymentInfoLSV = (() => {
              return ((value: unknown): { success: false } | { success: true; value: LsvPaymentView | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        accountNumber: string | null;
                        acqAuthorizationCode: string | null;
                        authorizationCode: string | null;
                        bankIban: string | null;
                        bankName: string | null;
                        bankTown: string | null;
                        clearingNumber: string | null;
                        firstName: string;
                        lastName: string;
                        merchantId: string | null;
                        refNumber: string | null;
                        transactionId: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "accountNumber")) {
                    return { success: false };
                  }
                  const parsed_accountNumber = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["accountNumber"]);
                  })();
                  if (!parsed_accountNumber.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "acqAuthorizationCode")) {
                    return { success: false };
                  }
                  const parsed_acqAuthorizationCode = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["acqAuthorizationCode"]);
                  })();
                  if (!parsed_acqAuthorizationCode.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "authorizationCode")) {
                    return { success: false };
                  }
                  const parsed_authorizationCode = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["authorizationCode"]);
                  })();
                  if (!parsed_authorizationCode.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "bankIban")) {
                    return { success: false };
                  }
                  const parsed_bankIban = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["bankIban"]);
                  })();
                  if (!parsed_bankIban.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "bankName")) {
                    return { success: false };
                  }
                  const parsed_bankName = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["bankName"]);
                  })();
                  if (!parsed_bankName.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "bankTown")) {
                    return { success: false };
                  }
                  const parsed_bankTown = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["bankTown"]);
                  })();
                  if (!parsed_bankTown.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "clearingNumber")) {
                    return { success: false };
                  }
                  const parsed_clearingNumber = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["clearingNumber"]);
                  })();
                  if (!parsed_clearingNumber.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "firstName")) {
                    return { success: false };
                  }
                  const parsed_firstName = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["firstName"]);
                  })();
                  if (!parsed_firstName.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "lastName")) {
                    return { success: false };
                  }
                  const parsed_lastName = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["lastName"]);
                  })();
                  if (!parsed_lastName.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "merchantId")) {
                    return { success: false };
                  }
                  const parsed_merchantId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["merchantId"]);
                  })();
                  if (!parsed_merchantId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "refNumber")) {
                    return { success: false };
                  }
                  const parsed_refNumber = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["refNumber"]);
                  })();
                  if (!parsed_refNumber.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "transactionId")) {
                    return { success: false };
                  }
                  const parsed_transactionId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["transactionId"]);
                  })();
                  if (!parsed_transactionId.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      accountNumber: parsed_accountNumber.value,
                      acqAuthorizationCode: parsed_acqAuthorizationCode.value,
                      authorizationCode: parsed_authorizationCode.value,
                      bankIban: parsed_bankIban.value,
                      bankName: parsed_bankName.value,
                      bankTown: parsed_bankTown.value,
                      clearingNumber: parsed_clearingNumber.value,
                      firstName: parsed_firstName.value,
                      lastName: parsed_lastName.value,
                      merchantId: parsed_merchantId.value,
                      refNumber: parsed_refNumber.value,
                      transactionId: parsed_transactionId.value,
                    },
                  };
                })(value);
              })(value["paymentInfoLSV"]);
            })();
            if (!parsed_paymentInfoLSV.success) {
              return { success: false };
            }
            if (!hasProp(value, "paymentInfoMiles")) {
              return { success: false };
            }
            const parsed_paymentInfoMiles = (() => {
              return ((value: unknown): { success: false } | { success: true; value: MilesPaymentView | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        cardNumber: string | null;
                        milesTransactionId: number | null;
                        pin: string | null;
                        transactionId: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "cardNumber")) {
                    return { success: false };
                  }
                  const parsed_cardNumber = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["cardNumber"]);
                  })();
                  if (!parsed_cardNumber.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "milesTransactionId")) {
                    return { success: false };
                  }
                  const parsed_milesTransactionId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["milesTransactionId"]);
                  })();
                  if (!parsed_milesTransactionId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "pin")) {
                    return { success: false };
                  }
                  const parsed_pin = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["pin"]);
                  })();
                  if (!parsed_pin.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "transactionId")) {
                    return { success: false };
                  }
                  const parsed_transactionId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["transactionId"]);
                  })();
                  if (!parsed_transactionId.success) {
                    return { success: false };
                  }
                  return { success: true, value: { cardNumber: parsed_cardNumber.value, milesTransactionId: parsed_milesTransactionId.value, pin: parsed_pin.value, transactionId: parsed_transactionId.value } };
                })(value);
              })(value["paymentInfoMiles"]);
            })();
            if (!parsed_paymentInfoMiles.success) {
              return { success: false };
            }
            if (!hasProp(value, "paymentInfoPaypal")) {
              return { success: false };
            }
            const parsed_paymentInfoPaypal = (() => {
              return ((value: unknown): { success: false } | { success: true; value: PayPalPaymentView | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        acqAuthorizationCode: string | null;
                        authorizationCode: string | null;
                        merchantId: string | null;
                        paypalBillingAgreementId: string | null;
                        refNumber: string | null;
                        transactionId: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "acqAuthorizationCode")) {
                    return { success: false };
                  }
                  const parsed_acqAuthorizationCode = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["acqAuthorizationCode"]);
                  })();
                  if (!parsed_acqAuthorizationCode.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "authorizationCode")) {
                    return { success: false };
                  }
                  const parsed_authorizationCode = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["authorizationCode"]);
                  })();
                  if (!parsed_authorizationCode.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "merchantId")) {
                    return { success: false };
                  }
                  const parsed_merchantId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["merchantId"]);
                  })();
                  if (!parsed_merchantId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "paypalBillingAgreementId")) {
                    return { success: false };
                  }
                  const parsed_paypalBillingAgreementId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["paypalBillingAgreementId"]);
                  })();
                  if (!parsed_paypalBillingAgreementId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "refNumber")) {
                    return { success: false };
                  }
                  const parsed_refNumber = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["refNumber"]);
                  })();
                  if (!parsed_refNumber.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "transactionId")) {
                    return { success: false };
                  }
                  const parsed_transactionId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["transactionId"]);
                  })();
                  if (!parsed_transactionId.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      acqAuthorizationCode: parsed_acqAuthorizationCode.value,
                      authorizationCode: parsed_authorizationCode.value,
                      merchantId: parsed_merchantId.value,
                      paypalBillingAgreementId: parsed_paypalBillingAgreementId.value,
                      refNumber: parsed_refNumber.value,
                      transactionId: parsed_transactionId.value,
                    },
                  };
                })(value);
              })(value["paymentInfoPaypal"]);
            })();
            if (!parsed_paymentInfoPaypal.success) {
              return { success: false };
            }
            if (!hasProp(value, "paymentReceiptUri")) {
              return { success: false };
            }
            const parsed_paymentReceiptUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["paymentReceiptUri"]);
            })();
            if (!parsed_paymentReceiptUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "paymentType")) {
              return { success: false };
            }
            const parsed_paymentType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: PaymentType } => {
                return ((value: unknown): { success: false } | { success: true; value: "CC" | "INVOICE" | "PAYPAL" | "LSV" | "MILES" | "ALIPAY" | "IOS" | "ANDROID" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "CC":
                      return { success: true, value: "CC" };
                    case "INVOICE":
                      return { success: true, value: "INVOICE" };
                    case "PAYPAL":
                      return { success: true, value: "PAYPAL" };
                    case "LSV":
                      return { success: true, value: "LSV" };
                    case "MILES":
                      return { success: true, value: "MILES" };
                    case "ALIPAY":
                      return { success: true, value: "ALIPAY" };
                    case "IOS":
                      return { success: true, value: "IOS" };
                    case "ANDROID":
                      return { success: true, value: "ANDROID" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["paymentType"]);
            })();
            if (!parsed_paymentType.success) {
              return { success: false };
            }
            if (!hasProp(value, "quantity")) {
              return { success: false };
            }
            const parsed_quantity = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["quantity"]);
            })();
            if (!parsed_quantity.success) {
              return { success: false };
            }
            if (!hasProp(value, "subscriptionId")) {
              return { success: false };
            }
            const parsed_subscriptionId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["subscriptionId"]);
            })();
            if (!parsed_subscriptionId.success) {
              return { success: false };
            }
            if (!hasProp(value, "transactionId")) {
              return { success: false };
            }
            const parsed_transactionId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["transactionId"]);
            })();
            if (!parsed_transactionId.success) {
              return { success: false };
            }
            if (!hasProp(value, "transactionType")) {
              return { success: false };
            }
            const parsed_transactionType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "DEBIT" | "CREDIT" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "DEBIT":
                    return { success: true, value: "DEBIT" };
                  case "CREDIT":
                    return { success: true, value: "CREDIT" };
                  default:
                    return { success: false };
                }
              })(value["transactionType"]);
            })();
            if (!parsed_transactionType.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                amount: parsed_amount.value,
                comment: parsed_comment.value,
                customerId: parsed_customerId.value,
                generatedAt: parsed_generatedAt.value,
                paymentInfoAlipay: parsed_paymentInfoAlipay.value,
                paymentInfoAndroid: parsed_paymentInfoAndroid.value,
                paymentInfoCC: parsed_paymentInfoCC.value,
                paymentInfoIOS: parsed_paymentInfoIOS.value,
                paymentInfoInvoice: parsed_paymentInfoInvoice.value,
                paymentInfoLSV: parsed_paymentInfoLSV.value,
                paymentInfoMiles: parsed_paymentInfoMiles.value,
                paymentInfoPaypal: parsed_paymentInfoPaypal.value,
                paymentReceiptUri: parsed_paymentReceiptUri.value,
                paymentType: parsed_paymentType.value,
                quantity: parsed_quantity.value,
                subscriptionId: parsed_subscriptionId.value,
                transactionId: parsed_transactionId.value,
                transactionType: parsed_transactionType.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["transactions"]);
    })();
    if (!parsed_transactions.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        extraMonths: parsed_extraMonths.value,
        isPaid: parsed_isPaid.value,
        productId: parsed_productId.value,
        reference: parsed_reference.value,
        showCommentModal: parsed_showCommentModal.value,
        status: parsed_status.value,
        studentVerificationCountryOfStudy: parsed_studentVerificationCountryOfStudy.value,
        studentVerificationFieldOfStudy: parsed_studentVerificationFieldOfStudy.value,
        studentVerificationProof: parsed_studentVerificationProof.value,
        studentVerificationProvider: parsed_studentVerificationProvider.value,
        studentVerificationStatus: parsed_studentVerificationStatus.value,
        studentVerificationVerifiedAt: parsed_studentVerificationVerifiedAt.value,
        subscriptionEndDate: parsed_subscriptionEndDate.value,
        subscriptionId: parsed_subscriptionId.value,
        subscriptionStartDate: parsed_subscriptionStartDate.value,
        transactions: parsed_transactions.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SubscriptionAdminView
export function serialize_SubscriptionAdminView(obj: SubscriptionAdminView): string {
  const serialized = ((value: {
    affiliate: string | null;
    cancelLinkForCustomerUri: string;
    comment: string | null;
    customerId: number;
    emailAddressForCancelConfirmation: string;
    extraMonths: number | null;
    giftCertificateUri: string | null;
    giftCodesAvailable: GiftCodeStatus;
    invoicesAndReminders: Invoice[] | null;
    isGiftSubscription: boolean;
    isPaid: boolean;
    motivationToBuy: string | null;
    productId: number;
    productName: string;
    promotionCode: string | null;
    reference: string;
    referredBy: string | null;
    renewSource: SubscriptionRenewSource;
    status: SubscriptionStatus;
    studentVerification: StudentVerificationView | null;
    subscriptionEndDate: { year: number; month: number; day: number };
    subscriptionId: number;
    subscriptionRenewInfo: SubscriptionRenewInfoView | null;
    subscriptionStartDate: { year: number; month: number; day: number };
    transactions: SubscriptionTransaction[];
  }): unknown => {
    return {
      affiliate: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["affiliate"]),
      cancelLinkForCustomerUri: ((value: string): unknown => {
        return value;
      })(value["cancelLinkForCustomerUri"]),
      comment: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["comment"]),
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
      emailAddressForCancelConfirmation: ((value: string): unknown => {
        return value;
      })(value["emailAddressForCancelConfirmation"]),
      extraMonths: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["extraMonths"]),
      giftCertificateUri: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["giftCertificateUri"]),
      giftCodesAvailable: ((value: "AVAILABLE" | "UNAVAILABLE"): unknown => {
        return value;
      })(value["giftCodesAvailable"]),
      invoicesAndReminders: ((value: Invoice[] | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.map((value: { generatedAt: DateTime<true>; invoiceId: number; invoiceLevel: number; invoiceUri: string | null; pdfCounter: number; transactionId: number }): unknown => {
          return {
            generatedAt: ((value: DateTime<true>): unknown => {
              return value.toMillis();
            })(value["generatedAt"]),
            invoiceId: ((value: number): unknown => {
              return value;
            })(value["invoiceId"]),
            invoiceLevel: ((value: number): unknown => {
              return value;
            })(value["invoiceLevel"]),
            invoiceUri: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["invoiceUri"]),
            pdfCounter: ((value: number): unknown => {
              return value;
            })(value["pdfCounter"]),
            transactionId: ((value: number): unknown => {
              return value;
            })(value["transactionId"]),
          };
        });
      })(value["invoicesAndReminders"]),
      isGiftSubscription: ((value: boolean): unknown => {
        return value;
      })(value["isGiftSubscription"]),
      isPaid: ((value: boolean): unknown => {
        return value;
      })(value["isPaid"]),
      motivationToBuy: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["motivationToBuy"]),
      productId: ((value: number): unknown => {
        return value;
      })(value["productId"]),
      productName: ((value: string): unknown => {
        return value;
      })(value["productName"]),
      promotionCode: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["promotionCode"]),
      reference: ((value: string): unknown => {
        return value;
      })(value["reference"]),
      referredBy: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["referredBy"]),
      renewSource: ((value: "NEW" | "AUTOMATIC" | "MANUAL" | "UPGRADE"): unknown => {
        return value;
      })(value["renewSource"]),
      status: ((value: "ACTIVE" | "STOP" | "CANCEL" | "RENEWED" | "RENEWALFAILED"): unknown => {
        return value;
      })(value["status"]),
      studentVerification: ((
        value: {
          countryOfStudy: string;
          createdAt: DateTime<true>;
          fieldOfStudy: string | null;
          proof: string | null;
          provider: StudentIdVerificationProvider;
          status: StudentVerificationStatus;
          subscriptionId: number | null;
          verifiedAt: DateTime<true> | null;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          countryOfStudy: ((value: string): unknown => {
            return value;
          })(value["countryOfStudy"]),
          createdAt: ((value: DateTime<true>): unknown => {
            return value.toMillis();
          })(value["createdAt"]),
          fieldOfStudy: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["fieldOfStudy"]),
          proof: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["proof"]),
          provider: ((value: "UNIDAYS" | "UNIDAYS_PERK" | "SHEERID" | "MANUAL" | "UNKNOWN"): unknown => {
            return value;
          })(value["provider"]),
          status: ((value: "VERIFIED" | "EXPIRED" | "FAILED" | "IN_PROGRESS"): unknown => {
            return value;
          })(value["status"]),
          subscriptionId: ((value: number | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["subscriptionId"]),
          verifiedAt: ((value: DateTime<true> | null): unknown => {
            if (value === null) {
              return null;
            }
            return value.toMillis();
          })(value["verifiedAt"]),
        };
      })(value["studentVerification"]),
      subscriptionEndDate: ((value: { year: number; month: number; day: number }): unknown => {
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["subscriptionEndDate"]),
      subscriptionId: ((value: number): unknown => {
        return value;
      })(value["subscriptionId"]),
      subscriptionRenewInfo: ((
        value: {
          ccNumber: string | null;
          ccType: CreditCardType | null;
          cvv: string | null;
          expMonth: string | null;
          expYear: string | null;
          lsvAccountNumber: string | null;
          lsvBankIban: string | null;
          lsvBankName: string | null;
          lsvBankTown: string | null;
          lsvClearingNumber: string | null;
          lsvFirstName: string | null;
          lsvLastName: string | null;
          manualPrice: number | null;
          paymentType: string | null;
          productId: number | null;
          promotionCode: string | null;
          street: string | null;
          subscriptionId: number;
          zip: string | null;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          ccNumber: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["ccNumber"]),
          ccType: ((value: ("VISA" | "MASTERCARD" | "AMEXCO") | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["ccType"]),
          cvv: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["cvv"]),
          expMonth: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["expMonth"]),
          expYear: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["expYear"]),
          lsvAccountNumber: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["lsvAccountNumber"]),
          lsvBankIban: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["lsvBankIban"]),
          lsvBankName: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["lsvBankName"]),
          lsvBankTown: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["lsvBankTown"]),
          lsvClearingNumber: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["lsvClearingNumber"]),
          lsvFirstName: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["lsvFirstName"]),
          lsvLastName: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["lsvLastName"]),
          manualPrice: ((value: number | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["manualPrice"]),
          paymentType: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["paymentType"]),
          productId: ((value: number | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["productId"]),
          promotionCode: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["promotionCode"]),
          street: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["street"]),
          subscriptionId: ((value: number): unknown => {
            return value;
          })(value["subscriptionId"]),
          zip: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["zip"]),
        };
      })(value["subscriptionRenewInfo"]),
      subscriptionStartDate: ((value: { year: number; month: number; day: number }): unknown => {
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["subscriptionStartDate"]),
      transactions: ((value: SubscriptionTransaction[]): unknown => {
        return value.map(
          (value: {
            amount: DisplayMonetaryAmount;
            comment: string | null;
            customerId: number;
            generatedAt: { year: number; month: number; day: number };
            paymentInfoAlipay: AlipayPaymentView | null;
            paymentInfoAndroid: AndroidPaymentView | null;
            paymentInfoCC: CreditCardPaymentView | null;
            paymentInfoIOS: IosPaymentView | null;
            paymentInfoInvoice: InvoicePaymentView | null;
            paymentInfoLSV: LsvPaymentView | null;
            paymentInfoMiles: MilesPaymentView | null;
            paymentInfoPaypal: PayPalPaymentView | null;
            paymentReceiptUri: string;
            paymentType: PaymentType;
            quantity: number;
            subscriptionId: number;
            transactionId: number;
            transactionType: "DEBIT" | "CREDIT";
          }): unknown => {
            return {
              amount: ((value: { amount: number; currencyCode: string; displayValue: string }): unknown => {
                return {
                  amount: ((value: number): unknown => {
                    return value;
                  })(value["amount"]),
                  currencyCode: ((value: string): unknown => {
                    return value;
                  })(value["currencyCode"]),
                  displayValue: ((value: string): unknown => {
                    return value;
                  })(value["displayValue"]),
                };
              })(value["amount"]),
              comment: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["comment"]),
              customerId: ((value: number): unknown => {
                return value;
              })(value["customerId"]),
              generatedAt: ((value: { year: number; month: number; day: number }): unknown => {
                return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
              })(value["generatedAt"]),
              paymentInfoAlipay: ((
                value: {
                  outTradeNo: string | null;
                  tradeNo: string | null;
                  transactionId: number;
                } | null,
              ): unknown => {
                if (value === null) {
                  return null;
                }
                return {
                  outTradeNo: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["outTradeNo"]),
                  tradeNo: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["tradeNo"]),
                  transactionId: ((value: number): unknown => {
                    return value;
                  })(value["transactionId"]),
                };
              })(value["paymentInfoAlipay"]),
              paymentInfoAndroid: ((
                value: {
                  appStoreProductId: string | null;
                  autoRenewing: boolean;
                  countryCode: string | null;
                  orderId: string | null;
                  paymentState: number;
                  purchaseState: number;
                  testPurchase: boolean;
                  transactionId: number;
                } | null,
              ): unknown => {
                if (value === null) {
                  return null;
                }
                return {
                  appStoreProductId: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["appStoreProductId"]),
                  autoRenewing: ((value: boolean): unknown => {
                    return value;
                  })(value["autoRenewing"]),
                  countryCode: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["countryCode"]),
                  orderId: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["orderId"]),
                  paymentState: ((value: number): unknown => {
                    return value;
                  })(value["paymentState"]),
                  purchaseState: ((value: number): unknown => {
                    return value;
                  })(value["purchaseState"]),
                  testPurchase: ((value: boolean): unknown => {
                    return value;
                  })(value["testPurchase"]),
                  transactionId: ((value: number): unknown => {
                    return value;
                  })(value["transactionId"]),
                };
              })(value["paymentInfoAndroid"]),
              paymentInfoCC: ((
                value: {
                  acqAuthorizationCode: string | null;
                  authorizationCode: string | null;
                  batchRefNumber: string | null;
                  creditCardAlias: string | null;
                  creditCardNumber: string;
                  creditCardType: CreditCardType;
                  datatransMerchantId: string | null;
                  expirationMonth: number;
                  expirationYear: number;
                  gatewayProvider: string;
                  nameOnCard: string | null;
                  refNumber: string | null;
                  transactionDateTime: DateTime<true>;
                  transactionId: number;
                } | null,
              ): unknown => {
                if (value === null) {
                  return null;
                }
                return {
                  acqAuthorizationCode: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["acqAuthorizationCode"]),
                  authorizationCode: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["authorizationCode"]),
                  batchRefNumber: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["batchRefNumber"]),
                  creditCardAlias: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["creditCardAlias"]),
                  creditCardNumber: ((value: string): unknown => {
                    return value;
                  })(value["creditCardNumber"]),
                  creditCardType: ((value: "VISA" | "MASTERCARD" | "AMEXCO"): unknown => {
                    return value;
                  })(value["creditCardType"]),
                  datatransMerchantId: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["datatransMerchantId"]),
                  expirationMonth: ((value: number): unknown => {
                    return value;
                  })(value["expirationMonth"]),
                  expirationYear: ((value: number): unknown => {
                    return value;
                  })(value["expirationYear"]),
                  gatewayProvider: ((value: string): unknown => {
                    return value;
                  })(value["gatewayProvider"]),
                  nameOnCard: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["nameOnCard"]),
                  refNumber: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["refNumber"]),
                  transactionDateTime: ((value: DateTime<true>): unknown => {
                    return value.toMillis();
                  })(value["transactionDateTime"]),
                  transactionId: ((value: number): unknown => {
                    return value;
                  })(value["transactionId"]),
                };
              })(value["paymentInfoCC"]),
              paymentInfoIOS: ((
                value: {
                  appStoreProductId: string;
                  autoRenewProductId: string | null;
                  autoRenewing: boolean;
                  expirationIntent: number;
                  itunesTransactionId: string;
                  originalItunesTransactionId: string | null;
                  testPurchase: boolean;
                  transactionId: number;
                  webOrderLineItemId: bigint;
                } | null,
              ): unknown => {
                if (value === null) {
                  return null;
                }
                return {
                  appStoreProductId: ((value: string): unknown => {
                    return value;
                  })(value["appStoreProductId"]),
                  autoRenewProductId: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["autoRenewProductId"]),
                  autoRenewing: ((value: boolean): unknown => {
                    return value;
                  })(value["autoRenewing"]),
                  expirationIntent: ((value: number): unknown => {
                    return value;
                  })(value["expirationIntent"]),
                  itunesTransactionId: ((value: string): unknown => {
                    return value;
                  })(value["itunesTransactionId"]),
                  originalItunesTransactionId: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["originalItunesTransactionId"]),
                  testPurchase: ((value: boolean): unknown => {
                    return value;
                  })(value["testPurchase"]),
                  transactionId: ((value: number): unknown => {
                    return value;
                  })(value["transactionId"]),
                  webOrderLineItemId: ((value: bigint): unknown => {
                    return value;
                  })(value["webOrderLineItemId"]),
                };
              })(value["paymentInfoIOS"]),
              paymentInfoInvoice: ((
                value: {
                  paidAt: DateTime<true> | null;
                  reference: string | null;
                  transactionId: number;
                } | null,
              ): unknown => {
                if (value === null) {
                  return null;
                }
                return {
                  paidAt: ((value: DateTime<true> | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value.toMillis();
                  })(value["paidAt"]),
                  reference: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["reference"]),
                  transactionId: ((value: number): unknown => {
                    return value;
                  })(value["transactionId"]),
                };
              })(value["paymentInfoInvoice"]),
              paymentInfoLSV: ((
                value: {
                  accountNumber: string | null;
                  acqAuthorizationCode: string | null;
                  authorizationCode: string | null;
                  bankIban: string | null;
                  bankName: string | null;
                  bankTown: string | null;
                  clearingNumber: string | null;
                  firstName: string;
                  lastName: string;
                  merchantId: string | null;
                  refNumber: string | null;
                  transactionId: number;
                } | null,
              ): unknown => {
                if (value === null) {
                  return null;
                }
                return {
                  accountNumber: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["accountNumber"]),
                  acqAuthorizationCode: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["acqAuthorizationCode"]),
                  authorizationCode: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["authorizationCode"]),
                  bankIban: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["bankIban"]),
                  bankName: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["bankName"]),
                  bankTown: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["bankTown"]),
                  clearingNumber: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["clearingNumber"]),
                  firstName: ((value: string): unknown => {
                    return value;
                  })(value["firstName"]),
                  lastName: ((value: string): unknown => {
                    return value;
                  })(value["lastName"]),
                  merchantId: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["merchantId"]),
                  refNumber: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["refNumber"]),
                  transactionId: ((value: number): unknown => {
                    return value;
                  })(value["transactionId"]),
                };
              })(value["paymentInfoLSV"]),
              paymentInfoMiles: ((
                value: {
                  cardNumber: string | null;
                  milesTransactionId: number | null;
                  pin: string | null;
                  transactionId: number;
                } | null,
              ): unknown => {
                if (value === null) {
                  return null;
                }
                return {
                  cardNumber: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["cardNumber"]),
                  milesTransactionId: ((value: number | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["milesTransactionId"]),
                  pin: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["pin"]),
                  transactionId: ((value: number): unknown => {
                    return value;
                  })(value["transactionId"]),
                };
              })(value["paymentInfoMiles"]),
              paymentInfoPaypal: ((
                value: {
                  acqAuthorizationCode: string | null;
                  authorizationCode: string | null;
                  merchantId: string | null;
                  paypalBillingAgreementId: string | null;
                  refNumber: string | null;
                  transactionId: number;
                } | null,
              ): unknown => {
                if (value === null) {
                  return null;
                }
                return {
                  acqAuthorizationCode: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["acqAuthorizationCode"]),
                  authorizationCode: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["authorizationCode"]),
                  merchantId: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["merchantId"]),
                  paypalBillingAgreementId: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["paypalBillingAgreementId"]),
                  refNumber: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["refNumber"]),
                  transactionId: ((value: number): unknown => {
                    return value;
                  })(value["transactionId"]),
                };
              })(value["paymentInfoPaypal"]),
              paymentReceiptUri: ((value: string): unknown => {
                return value;
              })(value["paymentReceiptUri"]),
              paymentType: ((value: "CC" | "INVOICE" | "PAYPAL" | "LSV" | "MILES" | "ALIPAY" | "IOS" | "ANDROID"): unknown => {
                return value;
              })(value["paymentType"]),
              quantity: ((value: number): unknown => {
                return value;
              })(value["quantity"]),
              subscriptionId: ((value: number): unknown => {
                return value;
              })(value["subscriptionId"]),
              transactionId: ((value: number): unknown => {
                return value;
              })(value["transactionId"]),
              transactionType: ((value: "DEBIT" | "CREDIT"): unknown => {
                return value;
              })(value["transactionType"]),
            };
          },
        );
      })(value["transactions"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SubscriptionAdminView(str: string): SubscriptionAdminView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          affiliate: string | null;
          cancelLinkForCustomerUri: string;
          comment: string | null;
          customerId: number;
          emailAddressForCancelConfirmation: string;
          extraMonths: number | null;
          giftCertificateUri: string | null;
          giftCodesAvailable: GiftCodeStatus;
          invoicesAndReminders: Invoice[] | null;
          isGiftSubscription: boolean;
          isPaid: boolean;
          motivationToBuy: string | null;
          productId: number;
          productName: string;
          promotionCode: string | null;
          reference: string;
          referredBy: string | null;
          renewSource: SubscriptionRenewSource;
          status: SubscriptionStatus;
          studentVerification: StudentVerificationView | null;
          subscriptionEndDate: { year: number; month: number; day: number };
          subscriptionId: number;
          subscriptionRenewInfo: SubscriptionRenewInfoView | null;
          subscriptionStartDate: { year: number; month: number; day: number };
          transactions: SubscriptionTransaction[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "affiliate")) {
      return { success: false };
    }
    const parsed_affiliate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["affiliate"]);
    })();
    if (!parsed_affiliate.success) {
      return { success: false };
    }
    if (!hasProp(value, "cancelLinkForCustomerUri")) {
      return { success: false };
    }
    const parsed_cancelLinkForCustomerUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["cancelLinkForCustomerUri"]);
    })();
    if (!parsed_cancelLinkForCustomerUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "comment")) {
      return { success: false };
    }
    const parsed_comment = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["comment"]);
    })();
    if (!parsed_comment.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "emailAddressForCancelConfirmation")) {
      return { success: false };
    }
    const parsed_emailAddressForCancelConfirmation = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["emailAddressForCancelConfirmation"]);
    })();
    if (!parsed_emailAddressForCancelConfirmation.success) {
      return { success: false };
    }
    if (!hasProp(value, "extraMonths")) {
      return { success: false };
    }
    const parsed_extraMonths = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["extraMonths"]);
    })();
    if (!parsed_extraMonths.success) {
      return { success: false };
    }
    if (!hasProp(value, "giftCertificateUri")) {
      return { success: false };
    }
    const parsed_giftCertificateUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["giftCertificateUri"]);
    })();
    if (!parsed_giftCertificateUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "giftCodesAvailable")) {
      return { success: false };
    }
    const parsed_giftCodesAvailable = (() => {
      return ((value: unknown): { success: false } | { success: true; value: GiftCodeStatus } => {
        return ((value: unknown): { success: false } | { success: true; value: "AVAILABLE" | "UNAVAILABLE" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "AVAILABLE":
              return { success: true, value: "AVAILABLE" };
            case "UNAVAILABLE":
              return { success: true, value: "UNAVAILABLE" };
            default:
              return { success: false };
          }
        })(value);
      })(value["giftCodesAvailable"]);
    })();
    if (!parsed_giftCodesAvailable.success) {
      return { success: false };
    }
    if (!hasProp(value, "invoicesAndReminders")) {
      return { success: false };
    }
    const parsed_invoicesAndReminders = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Invoice[] | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Invoice } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  generatedAt: DateTime<true>;
                  invoiceId: number;
                  invoiceLevel: number;
                  invoiceUri: string | null;
                  pdfCounter: number;
                  transactionId: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "generatedAt")) {
              return { success: false };
            }
            const parsed_generatedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["generatedAt"]);
            })();
            if (!parsed_generatedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "invoiceId")) {
              return { success: false };
            }
            const parsed_invoiceId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["invoiceId"]);
            })();
            if (!parsed_invoiceId.success) {
              return { success: false };
            }
            if (!hasProp(value, "invoiceLevel")) {
              return { success: false };
            }
            const parsed_invoiceLevel = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["invoiceLevel"]);
            })();
            if (!parsed_invoiceLevel.success) {
              return { success: false };
            }
            if (!hasProp(value, "invoiceUri")) {
              return { success: false };
            }
            const parsed_invoiceUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["invoiceUri"]);
            })();
            if (!parsed_invoiceUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "pdfCounter")) {
              return { success: false };
            }
            const parsed_pdfCounter = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["pdfCounter"]);
            })();
            if (!parsed_pdfCounter.success) {
              return { success: false };
            }
            if (!hasProp(value, "transactionId")) {
              return { success: false };
            }
            const parsed_transactionId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["transactionId"]);
            })();
            if (!parsed_transactionId.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                generatedAt: parsed_generatedAt.value,
                invoiceId: parsed_invoiceId.value,
                invoiceLevel: parsed_invoiceLevel.value,
                invoiceUri: parsed_invoiceUri.value,
                pdfCounter: parsed_pdfCounter.value,
                transactionId: parsed_transactionId.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["invoicesAndReminders"]);
    })();
    if (!parsed_invoicesAndReminders.success) {
      return { success: false };
    }
    if (!hasProp(value, "isGiftSubscription")) {
      return { success: false };
    }
    const parsed_isGiftSubscription = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isGiftSubscription"]);
    })();
    if (!parsed_isGiftSubscription.success) {
      return { success: false };
    }
    if (!hasProp(value, "isPaid")) {
      return { success: false };
    }
    const parsed_isPaid = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isPaid"]);
    })();
    if (!parsed_isPaid.success) {
      return { success: false };
    }
    if (!hasProp(value, "motivationToBuy")) {
      return { success: false };
    }
    const parsed_motivationToBuy = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["motivationToBuy"]);
    })();
    if (!parsed_motivationToBuy.success) {
      return { success: false };
    }
    if (!hasProp(value, "productId")) {
      return { success: false };
    }
    const parsed_productId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["productId"]);
    })();
    if (!parsed_productId.success) {
      return { success: false };
    }
    if (!hasProp(value, "productName")) {
      return { success: false };
    }
    const parsed_productName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["productName"]);
    })();
    if (!parsed_productName.success) {
      return { success: false };
    }
    if (!hasProp(value, "promotionCode")) {
      return { success: false };
    }
    const parsed_promotionCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["promotionCode"]);
    })();
    if (!parsed_promotionCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "reference")) {
      return { success: false };
    }
    const parsed_reference = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["reference"]);
    })();
    if (!parsed_reference.success) {
      return { success: false };
    }
    if (!hasProp(value, "referredBy")) {
      return { success: false };
    }
    const parsed_referredBy = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["referredBy"]);
    })();
    if (!parsed_referredBy.success) {
      return { success: false };
    }
    if (!hasProp(value, "renewSource")) {
      return { success: false };
    }
    const parsed_renewSource = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SubscriptionRenewSource } => {
        return ((value: unknown): { success: false } | { success: true; value: "NEW" | "AUTOMATIC" | "MANUAL" | "UPGRADE" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "NEW":
              return { success: true, value: "NEW" };
            case "AUTOMATIC":
              return { success: true, value: "AUTOMATIC" };
            case "MANUAL":
              return { success: true, value: "MANUAL" };
            case "UPGRADE":
              return { success: true, value: "UPGRADE" };
            default:
              return { success: false };
          }
        })(value);
      })(value["renewSource"]);
    })();
    if (!parsed_renewSource.success) {
      return { success: false };
    }
    if (!hasProp(value, "status")) {
      return { success: false };
    }
    const parsed_status = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SubscriptionStatus } => {
        return ((value: unknown): { success: false } | { success: true; value: "ACTIVE" | "STOP" | "CANCEL" | "RENEWED" | "RENEWALFAILED" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "ACTIVE":
              return { success: true, value: "ACTIVE" };
            case "STOP":
              return { success: true, value: "STOP" };
            case "CANCEL":
              return { success: true, value: "CANCEL" };
            case "RENEWED":
              return { success: true, value: "RENEWED" };
            case "RENEWALFAILED":
              return { success: true, value: "RENEWALFAILED" };
            default:
              return { success: false };
          }
        })(value);
      })(value["status"]);
    })();
    if (!parsed_status.success) {
      return { success: false };
    }
    if (!hasProp(value, "studentVerification")) {
      return { success: false };
    }
    const parsed_studentVerification = (() => {
      return ((value: unknown): { success: false } | { success: true; value: StudentVerificationView | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                countryOfStudy: string;
                createdAt: DateTime<true>;
                fieldOfStudy: string | null;
                proof: string | null;
                provider: StudentIdVerificationProvider;
                status: StudentVerificationStatus;
                subscriptionId: number | null;
                verifiedAt: DateTime<true> | null;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "countryOfStudy")) {
            return { success: false };
          }
          const parsed_countryOfStudy = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["countryOfStudy"]);
          })();
          if (!parsed_countryOfStudy.success) {
            return { success: false };
          }
          if (!hasProp(value, "createdAt")) {
            return { success: false };
          }
          const parsed_createdAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
              if (typeof value !== "number") {
                return { success: false };
              }
              const parsed = DateTime.fromMillis(value);
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: parsed };
            })(value["createdAt"]);
          })();
          if (!parsed_createdAt.success) {
            return { success: false };
          }
          if (!hasProp(value, "fieldOfStudy")) {
            return { success: false };
          }
          const parsed_fieldOfStudy = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["fieldOfStudy"]);
          })();
          if (!parsed_fieldOfStudy.success) {
            return { success: false };
          }
          if (!hasProp(value, "proof")) {
            return { success: false };
          }
          const parsed_proof = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["proof"]);
          })();
          if (!parsed_proof.success) {
            return { success: false };
          }
          if (!hasProp(value, "provider")) {
            return { success: false };
          }
          const parsed_provider = (() => {
            return ((value: unknown): { success: false } | { success: true; value: StudentIdVerificationProvider } => {
              return ((value: unknown): { success: false } | { success: true; value: "UNIDAYS" | "UNIDAYS_PERK" | "SHEERID" | "MANUAL" | "UNKNOWN" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "UNIDAYS":
                    return { success: true, value: "UNIDAYS" };
                  case "UNIDAYS_PERK":
                    return { success: true, value: "UNIDAYS_PERK" };
                  case "SHEERID":
                    return { success: true, value: "SHEERID" };
                  case "MANUAL":
                    return { success: true, value: "MANUAL" };
                  case "UNKNOWN":
                    return { success: true, value: "UNKNOWN" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["provider"]);
          })();
          if (!parsed_provider.success) {
            return { success: false };
          }
          if (!hasProp(value, "status")) {
            return { success: false };
          }
          const parsed_status = (() => {
            return ((value: unknown): { success: false } | { success: true; value: StudentVerificationStatus } => {
              return ((value: unknown): { success: false } | { success: true; value: "VERIFIED" | "EXPIRED" | "FAILED" | "IN_PROGRESS" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "VERIFIED":
                    return { success: true, value: "VERIFIED" };
                  case "EXPIRED":
                    return { success: true, value: "EXPIRED" };
                  case "FAILED":
                    return { success: true, value: "FAILED" };
                  case "IN_PROGRESS":
                    return { success: true, value: "IN_PROGRESS" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["status"]);
          })();
          if (!parsed_status.success) {
            return { success: false };
          }
          if (!hasProp(value, "subscriptionId")) {
            return { success: false };
          }
          const parsed_subscriptionId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["subscriptionId"]);
          })();
          if (!parsed_subscriptionId.success) {
            return { success: false };
          }
          if (!hasProp(value, "verifiedAt")) {
            return { success: false };
          }
          const parsed_verifiedAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "number") {
                return { success: false };
              }
              const parsed = DateTime.fromMillis(value);
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: parsed };
            })(value["verifiedAt"]);
          })();
          if (!parsed_verifiedAt.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              countryOfStudy: parsed_countryOfStudy.value,
              createdAt: parsed_createdAt.value,
              fieldOfStudy: parsed_fieldOfStudy.value,
              proof: parsed_proof.value,
              provider: parsed_provider.value,
              status: parsed_status.value,
              subscriptionId: parsed_subscriptionId.value,
              verifiedAt: parsed_verifiedAt.value,
            },
          };
        })(value);
      })(value["studentVerification"]);
    })();
    if (!parsed_studentVerification.success) {
      return { success: false };
    }
    if (!hasProp(value, "subscriptionEndDate")) {
      return { success: false };
    }
    const parsed_subscriptionEndDate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["subscriptionEndDate"]);
    })();
    if (!parsed_subscriptionEndDate.success) {
      return { success: false };
    }
    if (!hasProp(value, "subscriptionId")) {
      return { success: false };
    }
    const parsed_subscriptionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["subscriptionId"]);
    })();
    if (!parsed_subscriptionId.success) {
      return { success: false };
    }
    if (!hasProp(value, "subscriptionRenewInfo")) {
      return { success: false };
    }
    const parsed_subscriptionRenewInfo = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SubscriptionRenewInfoView | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                ccNumber: string | null;
                ccType: CreditCardType | null;
                cvv: string | null;
                expMonth: string | null;
                expYear: string | null;
                lsvAccountNumber: string | null;
                lsvBankIban: string | null;
                lsvBankName: string | null;
                lsvBankTown: string | null;
                lsvClearingNumber: string | null;
                lsvFirstName: string | null;
                lsvLastName: string | null;
                manualPrice: number | null;
                paymentType: string | null;
                productId: number | null;
                promotionCode: string | null;
                street: string | null;
                subscriptionId: number;
                zip: string | null;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "ccNumber")) {
            return { success: false };
          }
          const parsed_ccNumber = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["ccNumber"]);
          })();
          if (!parsed_ccNumber.success) {
            return { success: false };
          }
          if (!hasProp(value, "ccType")) {
            return { success: false };
          }
          const parsed_ccType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: CreditCardType | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              return ((value: unknown): { success: false } | { success: true; value: "VISA" | "MASTERCARD" | "AMEXCO" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "VISA":
                    return { success: true, value: "VISA" };
                  case "MASTERCARD":
                    return { success: true, value: "MASTERCARD" };
                  case "AMEXCO":
                    return { success: true, value: "AMEXCO" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["ccType"]);
          })();
          if (!parsed_ccType.success) {
            return { success: false };
          }
          if (!hasProp(value, "cvv")) {
            return { success: false };
          }
          const parsed_cvv = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["cvv"]);
          })();
          if (!parsed_cvv.success) {
            return { success: false };
          }
          if (!hasProp(value, "expMonth")) {
            return { success: false };
          }
          const parsed_expMonth = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["expMonth"]);
          })();
          if (!parsed_expMonth.success) {
            return { success: false };
          }
          if (!hasProp(value, "expYear")) {
            return { success: false };
          }
          const parsed_expYear = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["expYear"]);
          })();
          if (!parsed_expYear.success) {
            return { success: false };
          }
          if (!hasProp(value, "lsvAccountNumber")) {
            return { success: false };
          }
          const parsed_lsvAccountNumber = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lsvAccountNumber"]);
          })();
          if (!parsed_lsvAccountNumber.success) {
            return { success: false };
          }
          if (!hasProp(value, "lsvBankIban")) {
            return { success: false };
          }
          const parsed_lsvBankIban = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lsvBankIban"]);
          })();
          if (!parsed_lsvBankIban.success) {
            return { success: false };
          }
          if (!hasProp(value, "lsvBankName")) {
            return { success: false };
          }
          const parsed_lsvBankName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lsvBankName"]);
          })();
          if (!parsed_lsvBankName.success) {
            return { success: false };
          }
          if (!hasProp(value, "lsvBankTown")) {
            return { success: false };
          }
          const parsed_lsvBankTown = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lsvBankTown"]);
          })();
          if (!parsed_lsvBankTown.success) {
            return { success: false };
          }
          if (!hasProp(value, "lsvClearingNumber")) {
            return { success: false };
          }
          const parsed_lsvClearingNumber = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lsvClearingNumber"]);
          })();
          if (!parsed_lsvClearingNumber.success) {
            return { success: false };
          }
          if (!hasProp(value, "lsvFirstName")) {
            return { success: false };
          }
          const parsed_lsvFirstName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lsvFirstName"]);
          })();
          if (!parsed_lsvFirstName.success) {
            return { success: false };
          }
          if (!hasProp(value, "lsvLastName")) {
            return { success: false };
          }
          const parsed_lsvLastName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lsvLastName"]);
          })();
          if (!parsed_lsvLastName.success) {
            return { success: false };
          }
          if (!hasProp(value, "manualPrice")) {
            return { success: false };
          }
          const parsed_manualPrice = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              if (typeof value === "bigint") {
                return { success: true, value: Number(value) };
              }
              return { success: false };
            })(value["manualPrice"]);
          })();
          if (!parsed_manualPrice.success) {
            return { success: false };
          }
          if (!hasProp(value, "paymentType")) {
            return { success: false };
          }
          const parsed_paymentType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["paymentType"]);
          })();
          if (!parsed_paymentType.success) {
            return { success: false };
          }
          if (!hasProp(value, "productId")) {
            return { success: false };
          }
          const parsed_productId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["productId"]);
          })();
          if (!parsed_productId.success) {
            return { success: false };
          }
          if (!hasProp(value, "promotionCode")) {
            return { success: false };
          }
          const parsed_promotionCode = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["promotionCode"]);
          })();
          if (!parsed_promotionCode.success) {
            return { success: false };
          }
          if (!hasProp(value, "street")) {
            return { success: false };
          }
          const parsed_street = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["street"]);
          })();
          if (!parsed_street.success) {
            return { success: false };
          }
          if (!hasProp(value, "subscriptionId")) {
            return { success: false };
          }
          const parsed_subscriptionId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["subscriptionId"]);
          })();
          if (!parsed_subscriptionId.success) {
            return { success: false };
          }
          if (!hasProp(value, "zip")) {
            return { success: false };
          }
          const parsed_zip = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["zip"]);
          })();
          if (!parsed_zip.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              ccNumber: parsed_ccNumber.value,
              ccType: parsed_ccType.value,
              cvv: parsed_cvv.value,
              expMonth: parsed_expMonth.value,
              expYear: parsed_expYear.value,
              lsvAccountNumber: parsed_lsvAccountNumber.value,
              lsvBankIban: parsed_lsvBankIban.value,
              lsvBankName: parsed_lsvBankName.value,
              lsvBankTown: parsed_lsvBankTown.value,
              lsvClearingNumber: parsed_lsvClearingNumber.value,
              lsvFirstName: parsed_lsvFirstName.value,
              lsvLastName: parsed_lsvLastName.value,
              manualPrice: parsed_manualPrice.value,
              paymentType: parsed_paymentType.value,
              productId: parsed_productId.value,
              promotionCode: parsed_promotionCode.value,
              street: parsed_street.value,
              subscriptionId: parsed_subscriptionId.value,
              zip: parsed_zip.value,
            },
          };
        })(value);
      })(value["subscriptionRenewInfo"]);
    })();
    if (!parsed_subscriptionRenewInfo.success) {
      return { success: false };
    }
    if (!hasProp(value, "subscriptionStartDate")) {
      return { success: false };
    }
    const parsed_subscriptionStartDate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["subscriptionStartDate"]);
    })();
    if (!parsed_subscriptionStartDate.success) {
      return { success: false };
    }
    if (!hasProp(value, "transactions")) {
      return { success: false };
    }
    const parsed_transactions = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SubscriptionTransaction[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: SubscriptionTransaction } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  amount: DisplayMonetaryAmount;
                  comment: string | null;
                  customerId: number;
                  generatedAt: { year: number; month: number; day: number };
                  paymentInfoAlipay: AlipayPaymentView | null;
                  paymentInfoAndroid: AndroidPaymentView | null;
                  paymentInfoCC: CreditCardPaymentView | null;
                  paymentInfoIOS: IosPaymentView | null;
                  paymentInfoInvoice: InvoicePaymentView | null;
                  paymentInfoLSV: LsvPaymentView | null;
                  paymentInfoMiles: MilesPaymentView | null;
                  paymentInfoPaypal: PayPalPaymentView | null;
                  paymentReceiptUri: string;
                  paymentType: PaymentType;
                  quantity: number;
                  subscriptionId: number;
                  transactionId: number;
                  transactionType: "DEBIT" | "CREDIT";
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "amount")) {
              return { success: false };
            }
            const parsed_amount = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DisplayMonetaryAmount } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        amount: number;
                        currencyCode: string;
                        displayValue: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "amount")) {
                    return { success: false };
                  }
                  const parsed_amount = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: Number(value) };
                      }
                      return { success: false };
                    })(value["amount"]);
                  })();
                  if (!parsed_amount.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "currencyCode")) {
                    return { success: false };
                  }
                  const parsed_currencyCode = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["currencyCode"]);
                  })();
                  if (!parsed_currencyCode.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "displayValue")) {
                    return { success: false };
                  }
                  const parsed_displayValue = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["displayValue"]);
                  })();
                  if (!parsed_displayValue.success) {
                    return { success: false };
                  }
                  return { success: true, value: { amount: parsed_amount.value, currencyCode: parsed_currencyCode.value, displayValue: parsed_displayValue.value } };
                })(value);
              })(value["amount"]);
            })();
            if (!parsed_amount.success) {
              return { success: false };
            }
            if (!hasProp(value, "comment")) {
              return { success: false };
            }
            const parsed_comment = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["comment"]);
            })();
            if (!parsed_comment.success) {
              return { success: false };
            }
            if (!hasProp(value, "customerId")) {
              return { success: false };
            }
            const parsed_customerId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["customerId"]);
            })();
            if (!parsed_customerId.success) {
              return { success: false };
            }
            if (!hasProp(value, "generatedAt")) {
              return { success: false };
            }
            const parsed_generatedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
              })(value["generatedAt"]);
            })();
            if (!parsed_generatedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "paymentInfoAlipay")) {
              return { success: false };
            }
            const parsed_paymentInfoAlipay = (() => {
              return ((value: unknown): { success: false } | { success: true; value: AlipayPaymentView | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        outTradeNo: string | null;
                        tradeNo: string | null;
                        transactionId: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "outTradeNo")) {
                    return { success: false };
                  }
                  const parsed_outTradeNo = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["outTradeNo"]);
                  })();
                  if (!parsed_outTradeNo.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tradeNo")) {
                    return { success: false };
                  }
                  const parsed_tradeNo = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["tradeNo"]);
                  })();
                  if (!parsed_tradeNo.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "transactionId")) {
                    return { success: false };
                  }
                  const parsed_transactionId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["transactionId"]);
                  })();
                  if (!parsed_transactionId.success) {
                    return { success: false };
                  }
                  return { success: true, value: { outTradeNo: parsed_outTradeNo.value, tradeNo: parsed_tradeNo.value, transactionId: parsed_transactionId.value } };
                })(value);
              })(value["paymentInfoAlipay"]);
            })();
            if (!parsed_paymentInfoAlipay.success) {
              return { success: false };
            }
            if (!hasProp(value, "paymentInfoAndroid")) {
              return { success: false };
            }
            const parsed_paymentInfoAndroid = (() => {
              return ((value: unknown): { success: false } | { success: true; value: AndroidPaymentView | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        appStoreProductId: string | null;
                        autoRenewing: boolean;
                        countryCode: string | null;
                        orderId: string | null;
                        paymentState: number;
                        purchaseState: number;
                        testPurchase: boolean;
                        transactionId: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "appStoreProductId")) {
                    return { success: false };
                  }
                  const parsed_appStoreProductId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["appStoreProductId"]);
                  })();
                  if (!parsed_appStoreProductId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "autoRenewing")) {
                    return { success: false };
                  }
                  const parsed_autoRenewing = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["autoRenewing"]);
                  })();
                  if (!parsed_autoRenewing.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "countryCode")) {
                    return { success: false };
                  }
                  const parsed_countryCode = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["countryCode"]);
                  })();
                  if (!parsed_countryCode.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "orderId")) {
                    return { success: false };
                  }
                  const parsed_orderId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["orderId"]);
                  })();
                  if (!parsed_orderId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "paymentState")) {
                    return { success: false };
                  }
                  const parsed_paymentState = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["paymentState"]);
                  })();
                  if (!parsed_paymentState.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "purchaseState")) {
                    return { success: false };
                  }
                  const parsed_purchaseState = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["purchaseState"]);
                  })();
                  if (!parsed_purchaseState.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "testPurchase")) {
                    return { success: false };
                  }
                  const parsed_testPurchase = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["testPurchase"]);
                  })();
                  if (!parsed_testPurchase.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "transactionId")) {
                    return { success: false };
                  }
                  const parsed_transactionId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["transactionId"]);
                  })();
                  if (!parsed_transactionId.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      appStoreProductId: parsed_appStoreProductId.value,
                      autoRenewing: parsed_autoRenewing.value,
                      countryCode: parsed_countryCode.value,
                      orderId: parsed_orderId.value,
                      paymentState: parsed_paymentState.value,
                      purchaseState: parsed_purchaseState.value,
                      testPurchase: parsed_testPurchase.value,
                      transactionId: parsed_transactionId.value,
                    },
                  };
                })(value);
              })(value["paymentInfoAndroid"]);
            })();
            if (!parsed_paymentInfoAndroid.success) {
              return { success: false };
            }
            if (!hasProp(value, "paymentInfoCC")) {
              return { success: false };
            }
            const parsed_paymentInfoCC = (() => {
              return ((value: unknown): { success: false } | { success: true; value: CreditCardPaymentView | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        acqAuthorizationCode: string | null;
                        authorizationCode: string | null;
                        batchRefNumber: string | null;
                        creditCardAlias: string | null;
                        creditCardNumber: string;
                        creditCardType: CreditCardType;
                        datatransMerchantId: string | null;
                        expirationMonth: number;
                        expirationYear: number;
                        gatewayProvider: string;
                        nameOnCard: string | null;
                        refNumber: string | null;
                        transactionDateTime: DateTime<true>;
                        transactionId: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "acqAuthorizationCode")) {
                    return { success: false };
                  }
                  const parsed_acqAuthorizationCode = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["acqAuthorizationCode"]);
                  })();
                  if (!parsed_acqAuthorizationCode.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "authorizationCode")) {
                    return { success: false };
                  }
                  const parsed_authorizationCode = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["authorizationCode"]);
                  })();
                  if (!parsed_authorizationCode.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "batchRefNumber")) {
                    return { success: false };
                  }
                  const parsed_batchRefNumber = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["batchRefNumber"]);
                  })();
                  if (!parsed_batchRefNumber.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "creditCardAlias")) {
                    return { success: false };
                  }
                  const parsed_creditCardAlias = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["creditCardAlias"]);
                  })();
                  if (!parsed_creditCardAlias.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "creditCardNumber")) {
                    return { success: false };
                  }
                  const parsed_creditCardNumber = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["creditCardNumber"]);
                  })();
                  if (!parsed_creditCardNumber.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "creditCardType")) {
                    return { success: false };
                  }
                  const parsed_creditCardType = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: CreditCardType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "VISA" | "MASTERCARD" | "AMEXCO" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "VISA":
                            return { success: true, value: "VISA" };
                          case "MASTERCARD":
                            return { success: true, value: "MASTERCARD" };
                          case "AMEXCO":
                            return { success: true, value: "AMEXCO" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["creditCardType"]);
                  })();
                  if (!parsed_creditCardType.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "datatransMerchantId")) {
                    return { success: false };
                  }
                  const parsed_datatransMerchantId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["datatransMerchantId"]);
                  })();
                  if (!parsed_datatransMerchantId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "expirationMonth")) {
                    return { success: false };
                  }
                  const parsed_expirationMonth = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["expirationMonth"]);
                  })();
                  if (!parsed_expirationMonth.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "expirationYear")) {
                    return { success: false };
                  }
                  const parsed_expirationYear = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["expirationYear"]);
                  })();
                  if (!parsed_expirationYear.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "gatewayProvider")) {
                    return { success: false };
                  }
                  const parsed_gatewayProvider = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["gatewayProvider"]);
                  })();
                  if (!parsed_gatewayProvider.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "nameOnCard")) {
                    return { success: false };
                  }
                  const parsed_nameOnCard = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["nameOnCard"]);
                  })();
                  if (!parsed_nameOnCard.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "refNumber")) {
                    return { success: false };
                  }
                  const parsed_refNumber = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["refNumber"]);
                  })();
                  if (!parsed_refNumber.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "transactionDateTime")) {
                    return { success: false };
                  }
                  const parsed_transactionDateTime = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                      if (typeof value !== "number") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromMillis(value);
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: parsed };
                    })(value["transactionDateTime"]);
                  })();
                  if (!parsed_transactionDateTime.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "transactionId")) {
                    return { success: false };
                  }
                  const parsed_transactionId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["transactionId"]);
                  })();
                  if (!parsed_transactionId.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      acqAuthorizationCode: parsed_acqAuthorizationCode.value,
                      authorizationCode: parsed_authorizationCode.value,
                      batchRefNumber: parsed_batchRefNumber.value,
                      creditCardAlias: parsed_creditCardAlias.value,
                      creditCardNumber: parsed_creditCardNumber.value,
                      creditCardType: parsed_creditCardType.value,
                      datatransMerchantId: parsed_datatransMerchantId.value,
                      expirationMonth: parsed_expirationMonth.value,
                      expirationYear: parsed_expirationYear.value,
                      gatewayProvider: parsed_gatewayProvider.value,
                      nameOnCard: parsed_nameOnCard.value,
                      refNumber: parsed_refNumber.value,
                      transactionDateTime: parsed_transactionDateTime.value,
                      transactionId: parsed_transactionId.value,
                    },
                  };
                })(value);
              })(value["paymentInfoCC"]);
            })();
            if (!parsed_paymentInfoCC.success) {
              return { success: false };
            }
            if (!hasProp(value, "paymentInfoIOS")) {
              return { success: false };
            }
            const parsed_paymentInfoIOS = (() => {
              return ((value: unknown): { success: false } | { success: true; value: IosPaymentView | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        appStoreProductId: string;
                        autoRenewProductId: string | null;
                        autoRenewing: boolean;
                        expirationIntent: number;
                        itunesTransactionId: string;
                        originalItunesTransactionId: string | null;
                        testPurchase: boolean;
                        transactionId: number;
                        webOrderLineItemId: bigint;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "appStoreProductId")) {
                    return { success: false };
                  }
                  const parsed_appStoreProductId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["appStoreProductId"]);
                  })();
                  if (!parsed_appStoreProductId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "autoRenewProductId")) {
                    return { success: false };
                  }
                  const parsed_autoRenewProductId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["autoRenewProductId"]);
                  })();
                  if (!parsed_autoRenewProductId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "autoRenewing")) {
                    return { success: false };
                  }
                  const parsed_autoRenewing = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["autoRenewing"]);
                  })();
                  if (!parsed_autoRenewing.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "expirationIntent")) {
                    return { success: false };
                  }
                  const parsed_expirationIntent = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["expirationIntent"]);
                  })();
                  if (!parsed_expirationIntent.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "itunesTransactionId")) {
                    return { success: false };
                  }
                  const parsed_itunesTransactionId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["itunesTransactionId"]);
                  })();
                  if (!parsed_itunesTransactionId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "originalItunesTransactionId")) {
                    return { success: false };
                  }
                  const parsed_originalItunesTransactionId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["originalItunesTransactionId"]);
                  })();
                  if (!parsed_originalItunesTransactionId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "testPurchase")) {
                    return { success: false };
                  }
                  const parsed_testPurchase = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["testPurchase"]);
                  })();
                  if (!parsed_testPurchase.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "transactionId")) {
                    return { success: false };
                  }
                  const parsed_transactionId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["transactionId"]);
                  })();
                  if (!parsed_transactionId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "webOrderLineItemId")) {
                    return { success: false };
                  }
                  const parsed_webOrderLineItemId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["webOrderLineItemId"]);
                  })();
                  if (!parsed_webOrderLineItemId.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      appStoreProductId: parsed_appStoreProductId.value,
                      autoRenewProductId: parsed_autoRenewProductId.value,
                      autoRenewing: parsed_autoRenewing.value,
                      expirationIntent: parsed_expirationIntent.value,
                      itunesTransactionId: parsed_itunesTransactionId.value,
                      originalItunesTransactionId: parsed_originalItunesTransactionId.value,
                      testPurchase: parsed_testPurchase.value,
                      transactionId: parsed_transactionId.value,
                      webOrderLineItemId: parsed_webOrderLineItemId.value,
                    },
                  };
                })(value);
              })(value["paymentInfoIOS"]);
            })();
            if (!parsed_paymentInfoIOS.success) {
              return { success: false };
            }
            if (!hasProp(value, "paymentInfoInvoice")) {
              return { success: false };
            }
            const parsed_paymentInfoInvoice = (() => {
              return ((value: unknown): { success: false } | { success: true; value: InvoicePaymentView | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        paidAt: DateTime<true> | null;
                        reference: string | null;
                        transactionId: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "paidAt")) {
                    return { success: false };
                  }
                  const parsed_paidAt = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "number") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromMillis(value);
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: parsed };
                    })(value["paidAt"]);
                  })();
                  if (!parsed_paidAt.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "reference")) {
                    return { success: false };
                  }
                  const parsed_reference = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["reference"]);
                  })();
                  if (!parsed_reference.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "transactionId")) {
                    return { success: false };
                  }
                  const parsed_transactionId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["transactionId"]);
                  })();
                  if (!parsed_transactionId.success) {
                    return { success: false };
                  }
                  return { success: true, value: { paidAt: parsed_paidAt.value, reference: parsed_reference.value, transactionId: parsed_transactionId.value } };
                })(value);
              })(value["paymentInfoInvoice"]);
            })();
            if (!parsed_paymentInfoInvoice.success) {
              return { success: false };
            }
            if (!hasProp(value, "paymentInfoLSV")) {
              return { success: false };
            }
            const parsed_paymentInfoLSV = (() => {
              return ((value: unknown): { success: false } | { success: true; value: LsvPaymentView | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        accountNumber: string | null;
                        acqAuthorizationCode: string | null;
                        authorizationCode: string | null;
                        bankIban: string | null;
                        bankName: string | null;
                        bankTown: string | null;
                        clearingNumber: string | null;
                        firstName: string;
                        lastName: string;
                        merchantId: string | null;
                        refNumber: string | null;
                        transactionId: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "accountNumber")) {
                    return { success: false };
                  }
                  const parsed_accountNumber = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["accountNumber"]);
                  })();
                  if (!parsed_accountNumber.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "acqAuthorizationCode")) {
                    return { success: false };
                  }
                  const parsed_acqAuthorizationCode = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["acqAuthorizationCode"]);
                  })();
                  if (!parsed_acqAuthorizationCode.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "authorizationCode")) {
                    return { success: false };
                  }
                  const parsed_authorizationCode = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["authorizationCode"]);
                  })();
                  if (!parsed_authorizationCode.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "bankIban")) {
                    return { success: false };
                  }
                  const parsed_bankIban = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["bankIban"]);
                  })();
                  if (!parsed_bankIban.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "bankName")) {
                    return { success: false };
                  }
                  const parsed_bankName = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["bankName"]);
                  })();
                  if (!parsed_bankName.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "bankTown")) {
                    return { success: false };
                  }
                  const parsed_bankTown = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["bankTown"]);
                  })();
                  if (!parsed_bankTown.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "clearingNumber")) {
                    return { success: false };
                  }
                  const parsed_clearingNumber = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["clearingNumber"]);
                  })();
                  if (!parsed_clearingNumber.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "firstName")) {
                    return { success: false };
                  }
                  const parsed_firstName = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["firstName"]);
                  })();
                  if (!parsed_firstName.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "lastName")) {
                    return { success: false };
                  }
                  const parsed_lastName = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["lastName"]);
                  })();
                  if (!parsed_lastName.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "merchantId")) {
                    return { success: false };
                  }
                  const parsed_merchantId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["merchantId"]);
                  })();
                  if (!parsed_merchantId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "refNumber")) {
                    return { success: false };
                  }
                  const parsed_refNumber = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["refNumber"]);
                  })();
                  if (!parsed_refNumber.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "transactionId")) {
                    return { success: false };
                  }
                  const parsed_transactionId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["transactionId"]);
                  })();
                  if (!parsed_transactionId.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      accountNumber: parsed_accountNumber.value,
                      acqAuthorizationCode: parsed_acqAuthorizationCode.value,
                      authorizationCode: parsed_authorizationCode.value,
                      bankIban: parsed_bankIban.value,
                      bankName: parsed_bankName.value,
                      bankTown: parsed_bankTown.value,
                      clearingNumber: parsed_clearingNumber.value,
                      firstName: parsed_firstName.value,
                      lastName: parsed_lastName.value,
                      merchantId: parsed_merchantId.value,
                      refNumber: parsed_refNumber.value,
                      transactionId: parsed_transactionId.value,
                    },
                  };
                })(value);
              })(value["paymentInfoLSV"]);
            })();
            if (!parsed_paymentInfoLSV.success) {
              return { success: false };
            }
            if (!hasProp(value, "paymentInfoMiles")) {
              return { success: false };
            }
            const parsed_paymentInfoMiles = (() => {
              return ((value: unknown): { success: false } | { success: true; value: MilesPaymentView | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        cardNumber: string | null;
                        milesTransactionId: number | null;
                        pin: string | null;
                        transactionId: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "cardNumber")) {
                    return { success: false };
                  }
                  const parsed_cardNumber = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["cardNumber"]);
                  })();
                  if (!parsed_cardNumber.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "milesTransactionId")) {
                    return { success: false };
                  }
                  const parsed_milesTransactionId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["milesTransactionId"]);
                  })();
                  if (!parsed_milesTransactionId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "pin")) {
                    return { success: false };
                  }
                  const parsed_pin = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["pin"]);
                  })();
                  if (!parsed_pin.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "transactionId")) {
                    return { success: false };
                  }
                  const parsed_transactionId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["transactionId"]);
                  })();
                  if (!parsed_transactionId.success) {
                    return { success: false };
                  }
                  return { success: true, value: { cardNumber: parsed_cardNumber.value, milesTransactionId: parsed_milesTransactionId.value, pin: parsed_pin.value, transactionId: parsed_transactionId.value } };
                })(value);
              })(value["paymentInfoMiles"]);
            })();
            if (!parsed_paymentInfoMiles.success) {
              return { success: false };
            }
            if (!hasProp(value, "paymentInfoPaypal")) {
              return { success: false };
            }
            const parsed_paymentInfoPaypal = (() => {
              return ((value: unknown): { success: false } | { success: true; value: PayPalPaymentView | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        acqAuthorizationCode: string | null;
                        authorizationCode: string | null;
                        merchantId: string | null;
                        paypalBillingAgreementId: string | null;
                        refNumber: string | null;
                        transactionId: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "acqAuthorizationCode")) {
                    return { success: false };
                  }
                  const parsed_acqAuthorizationCode = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["acqAuthorizationCode"]);
                  })();
                  if (!parsed_acqAuthorizationCode.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "authorizationCode")) {
                    return { success: false };
                  }
                  const parsed_authorizationCode = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["authorizationCode"]);
                  })();
                  if (!parsed_authorizationCode.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "merchantId")) {
                    return { success: false };
                  }
                  const parsed_merchantId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["merchantId"]);
                  })();
                  if (!parsed_merchantId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "paypalBillingAgreementId")) {
                    return { success: false };
                  }
                  const parsed_paypalBillingAgreementId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["paypalBillingAgreementId"]);
                  })();
                  if (!parsed_paypalBillingAgreementId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "refNumber")) {
                    return { success: false };
                  }
                  const parsed_refNumber = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["refNumber"]);
                  })();
                  if (!parsed_refNumber.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "transactionId")) {
                    return { success: false };
                  }
                  const parsed_transactionId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["transactionId"]);
                  })();
                  if (!parsed_transactionId.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      acqAuthorizationCode: parsed_acqAuthorizationCode.value,
                      authorizationCode: parsed_authorizationCode.value,
                      merchantId: parsed_merchantId.value,
                      paypalBillingAgreementId: parsed_paypalBillingAgreementId.value,
                      refNumber: parsed_refNumber.value,
                      transactionId: parsed_transactionId.value,
                    },
                  };
                })(value);
              })(value["paymentInfoPaypal"]);
            })();
            if (!parsed_paymentInfoPaypal.success) {
              return { success: false };
            }
            if (!hasProp(value, "paymentReceiptUri")) {
              return { success: false };
            }
            const parsed_paymentReceiptUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["paymentReceiptUri"]);
            })();
            if (!parsed_paymentReceiptUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "paymentType")) {
              return { success: false };
            }
            const parsed_paymentType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: PaymentType } => {
                return ((value: unknown): { success: false } | { success: true; value: "CC" | "INVOICE" | "PAYPAL" | "LSV" | "MILES" | "ALIPAY" | "IOS" | "ANDROID" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "CC":
                      return { success: true, value: "CC" };
                    case "INVOICE":
                      return { success: true, value: "INVOICE" };
                    case "PAYPAL":
                      return { success: true, value: "PAYPAL" };
                    case "LSV":
                      return { success: true, value: "LSV" };
                    case "MILES":
                      return { success: true, value: "MILES" };
                    case "ALIPAY":
                      return { success: true, value: "ALIPAY" };
                    case "IOS":
                      return { success: true, value: "IOS" };
                    case "ANDROID":
                      return { success: true, value: "ANDROID" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["paymentType"]);
            })();
            if (!parsed_paymentType.success) {
              return { success: false };
            }
            if (!hasProp(value, "quantity")) {
              return { success: false };
            }
            const parsed_quantity = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["quantity"]);
            })();
            if (!parsed_quantity.success) {
              return { success: false };
            }
            if (!hasProp(value, "subscriptionId")) {
              return { success: false };
            }
            const parsed_subscriptionId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["subscriptionId"]);
            })();
            if (!parsed_subscriptionId.success) {
              return { success: false };
            }
            if (!hasProp(value, "transactionId")) {
              return { success: false };
            }
            const parsed_transactionId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["transactionId"]);
            })();
            if (!parsed_transactionId.success) {
              return { success: false };
            }
            if (!hasProp(value, "transactionType")) {
              return { success: false };
            }
            const parsed_transactionType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "DEBIT" | "CREDIT" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "DEBIT":
                    return { success: true, value: "DEBIT" };
                  case "CREDIT":
                    return { success: true, value: "CREDIT" };
                  default:
                    return { success: false };
                }
              })(value["transactionType"]);
            })();
            if (!parsed_transactionType.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                amount: parsed_amount.value,
                comment: parsed_comment.value,
                customerId: parsed_customerId.value,
                generatedAt: parsed_generatedAt.value,
                paymentInfoAlipay: parsed_paymentInfoAlipay.value,
                paymentInfoAndroid: parsed_paymentInfoAndroid.value,
                paymentInfoCC: parsed_paymentInfoCC.value,
                paymentInfoIOS: parsed_paymentInfoIOS.value,
                paymentInfoInvoice: parsed_paymentInfoInvoice.value,
                paymentInfoLSV: parsed_paymentInfoLSV.value,
                paymentInfoMiles: parsed_paymentInfoMiles.value,
                paymentInfoPaypal: parsed_paymentInfoPaypal.value,
                paymentReceiptUri: parsed_paymentReceiptUri.value,
                paymentType: parsed_paymentType.value,
                quantity: parsed_quantity.value,
                subscriptionId: parsed_subscriptionId.value,
                transactionId: parsed_transactionId.value,
                transactionType: parsed_transactionType.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["transactions"]);
    })();
    if (!parsed_transactions.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        affiliate: parsed_affiliate.value,
        cancelLinkForCustomerUri: parsed_cancelLinkForCustomerUri.value,
        comment: parsed_comment.value,
        customerId: parsed_customerId.value,
        emailAddressForCancelConfirmation: parsed_emailAddressForCancelConfirmation.value,
        extraMonths: parsed_extraMonths.value,
        giftCertificateUri: parsed_giftCertificateUri.value,
        giftCodesAvailable: parsed_giftCodesAvailable.value,
        invoicesAndReminders: parsed_invoicesAndReminders.value,
        isGiftSubscription: parsed_isGiftSubscription.value,
        isPaid: parsed_isPaid.value,
        motivationToBuy: parsed_motivationToBuy.value,
        productId: parsed_productId.value,
        productName: parsed_productName.value,
        promotionCode: parsed_promotionCode.value,
        reference: parsed_reference.value,
        referredBy: parsed_referredBy.value,
        renewSource: parsed_renewSource.value,
        status: parsed_status.value,
        studentVerification: parsed_studentVerification.value,
        subscriptionEndDate: parsed_subscriptionEndDate.value,
        subscriptionId: parsed_subscriptionId.value,
        subscriptionRenewInfo: parsed_subscriptionRenewInfo.value,
        subscriptionStartDate: parsed_subscriptionStartDate.value,
        transactions: parsed_transactions.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SubscriptionCreditForm
export function serialize_SubscriptionCreditForm(obj: SubscriptionCreditForm): string {
  const serialized = ((value: { amountToCredit: number; balance: number; comment: string | null; sendEmail: boolean; stopSubscription: boolean; subscriptionId: number; transactionTypeId: number }): unknown => {
    return {
      amountToCredit: ((value: number): unknown => {
        return value;
      })(value["amountToCredit"]),
      balance: ((value: number): unknown => {
        return value;
      })(value["balance"]),
      comment: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["comment"]),
      sendEmail: ((value: boolean): unknown => {
        return value;
      })(value["sendEmail"]),
      stopSubscription: ((value: boolean): unknown => {
        return value;
      })(value["stopSubscription"]),
      subscriptionId: ((value: number): unknown => {
        return value;
      })(value["subscriptionId"]),
      transactionTypeId: ((value: number): unknown => {
        return value;
      })(value["transactionTypeId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SubscriptionCreditForm(str: string): SubscriptionCreditForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          amountToCredit: number;
          balance: number;
          comment: string | null;
          sendEmail: boolean;
          stopSubscription: boolean;
          subscriptionId: number;
          transactionTypeId: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "amountToCredit")) {
      return { success: false };
    }
    const parsed_amountToCredit = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        if (typeof value === "bigint") {
          return { success: true, value: Number(value) };
        }
        return { success: false };
      })(value["amountToCredit"]);
    })();
    if (!parsed_amountToCredit.success) {
      return { success: false };
    }
    if (!hasProp(value, "balance")) {
      return { success: false };
    }
    const parsed_balance = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        if (typeof value === "bigint") {
          return { success: true, value: Number(value) };
        }
        return { success: false };
      })(value["balance"]);
    })();
    if (!parsed_balance.success) {
      return { success: false };
    }
    if (!hasProp(value, "comment")) {
      return { success: false };
    }
    const parsed_comment = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["comment"]);
    })();
    if (!parsed_comment.success) {
      return { success: false };
    }
    if (!hasProp(value, "sendEmail")) {
      return { success: false };
    }
    const parsed_sendEmail = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["sendEmail"]);
    })();
    if (!parsed_sendEmail.success) {
      return { success: false };
    }
    if (!hasProp(value, "stopSubscription")) {
      return { success: false };
    }
    const parsed_stopSubscription = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["stopSubscription"]);
    })();
    if (!parsed_stopSubscription.success) {
      return { success: false };
    }
    if (!hasProp(value, "subscriptionId")) {
      return { success: false };
    }
    const parsed_subscriptionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["subscriptionId"]);
    })();
    if (!parsed_subscriptionId.success) {
      return { success: false };
    }
    if (!hasProp(value, "transactionTypeId")) {
      return { success: false };
    }
    const parsed_transactionTypeId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["transactionTypeId"]);
    })();
    if (!parsed_transactionTypeId.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        amountToCredit: parsed_amountToCredit.value,
        balance: parsed_balance.value,
        comment: parsed_comment.value,
        sendEmail: parsed_sendEmail.value,
        stopSubscription: parsed_stopSubscription.value,
        subscriptionId: parsed_subscriptionId.value,
        transactionTypeId: parsed_transactionTypeId.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SubscriptionCreditView
export function serialize_SubscriptionCreditView(obj: SubscriptionCreditView): string {
  const serialized = ((value: {
    amountToCredit: number;
    balance: number;
    currencyCode: string | null;
    customerId: number;
    emailSubject: string | null;
    emailText: string | null;
    isEmailHtml: boolean | null;
    isGiftSubscription: boolean;
    isSubscriptionStopped: boolean;
    subscriptionId: number;
    transaction: SubscriptionTransaction;
  }): unknown => {
    return {
      amountToCredit: ((value: number): unknown => {
        return value;
      })(value["amountToCredit"]),
      balance: ((value: number): unknown => {
        return value;
      })(value["balance"]),
      currencyCode: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["currencyCode"]),
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
      emailSubject: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["emailSubject"]),
      emailText: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["emailText"]),
      isEmailHtml: ((value: boolean | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["isEmailHtml"]),
      isGiftSubscription: ((value: boolean): unknown => {
        return value;
      })(value["isGiftSubscription"]),
      isSubscriptionStopped: ((value: boolean): unknown => {
        return value;
      })(value["isSubscriptionStopped"]),
      subscriptionId: ((value: number): unknown => {
        return value;
      })(value["subscriptionId"]),
      transaction: ((value: {
        amount: DisplayMonetaryAmount;
        comment: string | null;
        customerId: number;
        generatedAt: { year: number; month: number; day: number };
        paymentInfoAlipay: AlipayPaymentView | null;
        paymentInfoAndroid: AndroidPaymentView | null;
        paymentInfoCC: CreditCardPaymentView | null;
        paymentInfoIOS: IosPaymentView | null;
        paymentInfoInvoice: InvoicePaymentView | null;
        paymentInfoLSV: LsvPaymentView | null;
        paymentInfoMiles: MilesPaymentView | null;
        paymentInfoPaypal: PayPalPaymentView | null;
        paymentReceiptUri: string;
        paymentType: PaymentType;
        quantity: number;
        subscriptionId: number;
        transactionId: number;
        transactionType: "DEBIT" | "CREDIT";
      }): unknown => {
        return {
          amount: ((value: { amount: number; currencyCode: string; displayValue: string }): unknown => {
            return {
              amount: ((value: number): unknown => {
                return value;
              })(value["amount"]),
              currencyCode: ((value: string): unknown => {
                return value;
              })(value["currencyCode"]),
              displayValue: ((value: string): unknown => {
                return value;
              })(value["displayValue"]),
            };
          })(value["amount"]),
          comment: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["comment"]),
          customerId: ((value: number): unknown => {
            return value;
          })(value["customerId"]),
          generatedAt: ((value: { year: number; month: number; day: number }): unknown => {
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["generatedAt"]),
          paymentInfoAlipay: ((
            value: {
              outTradeNo: string | null;
              tradeNo: string | null;
              transactionId: number;
            } | null,
          ): unknown => {
            if (value === null) {
              return null;
            }
            return {
              outTradeNo: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["outTradeNo"]),
              tradeNo: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["tradeNo"]),
              transactionId: ((value: number): unknown => {
                return value;
              })(value["transactionId"]),
            };
          })(value["paymentInfoAlipay"]),
          paymentInfoAndroid: ((
            value: {
              appStoreProductId: string | null;
              autoRenewing: boolean;
              countryCode: string | null;
              orderId: string | null;
              paymentState: number;
              purchaseState: number;
              testPurchase: boolean;
              transactionId: number;
            } | null,
          ): unknown => {
            if (value === null) {
              return null;
            }
            return {
              appStoreProductId: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["appStoreProductId"]),
              autoRenewing: ((value: boolean): unknown => {
                return value;
              })(value["autoRenewing"]),
              countryCode: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["countryCode"]),
              orderId: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["orderId"]),
              paymentState: ((value: number): unknown => {
                return value;
              })(value["paymentState"]),
              purchaseState: ((value: number): unknown => {
                return value;
              })(value["purchaseState"]),
              testPurchase: ((value: boolean): unknown => {
                return value;
              })(value["testPurchase"]),
              transactionId: ((value: number): unknown => {
                return value;
              })(value["transactionId"]),
            };
          })(value["paymentInfoAndroid"]),
          paymentInfoCC: ((
            value: {
              acqAuthorizationCode: string | null;
              authorizationCode: string | null;
              batchRefNumber: string | null;
              creditCardAlias: string | null;
              creditCardNumber: string;
              creditCardType: CreditCardType;
              datatransMerchantId: string | null;
              expirationMonth: number;
              expirationYear: number;
              gatewayProvider: string;
              nameOnCard: string | null;
              refNumber: string | null;
              transactionDateTime: DateTime<true>;
              transactionId: number;
            } | null,
          ): unknown => {
            if (value === null) {
              return null;
            }
            return {
              acqAuthorizationCode: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["acqAuthorizationCode"]),
              authorizationCode: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["authorizationCode"]),
              batchRefNumber: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["batchRefNumber"]),
              creditCardAlias: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["creditCardAlias"]),
              creditCardNumber: ((value: string): unknown => {
                return value;
              })(value["creditCardNumber"]),
              creditCardType: ((value: "VISA" | "MASTERCARD" | "AMEXCO"): unknown => {
                return value;
              })(value["creditCardType"]),
              datatransMerchantId: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["datatransMerchantId"]),
              expirationMonth: ((value: number): unknown => {
                return value;
              })(value["expirationMonth"]),
              expirationYear: ((value: number): unknown => {
                return value;
              })(value["expirationYear"]),
              gatewayProvider: ((value: string): unknown => {
                return value;
              })(value["gatewayProvider"]),
              nameOnCard: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["nameOnCard"]),
              refNumber: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["refNumber"]),
              transactionDateTime: ((value: DateTime<true>): unknown => {
                return value.toMillis();
              })(value["transactionDateTime"]),
              transactionId: ((value: number): unknown => {
                return value;
              })(value["transactionId"]),
            };
          })(value["paymentInfoCC"]),
          paymentInfoIOS: ((
            value: {
              appStoreProductId: string;
              autoRenewProductId: string | null;
              autoRenewing: boolean;
              expirationIntent: number;
              itunesTransactionId: string;
              originalItunesTransactionId: string | null;
              testPurchase: boolean;
              transactionId: number;
              webOrderLineItemId: bigint;
            } | null,
          ): unknown => {
            if (value === null) {
              return null;
            }
            return {
              appStoreProductId: ((value: string): unknown => {
                return value;
              })(value["appStoreProductId"]),
              autoRenewProductId: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["autoRenewProductId"]),
              autoRenewing: ((value: boolean): unknown => {
                return value;
              })(value["autoRenewing"]),
              expirationIntent: ((value: number): unknown => {
                return value;
              })(value["expirationIntent"]),
              itunesTransactionId: ((value: string): unknown => {
                return value;
              })(value["itunesTransactionId"]),
              originalItunesTransactionId: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["originalItunesTransactionId"]),
              testPurchase: ((value: boolean): unknown => {
                return value;
              })(value["testPurchase"]),
              transactionId: ((value: number): unknown => {
                return value;
              })(value["transactionId"]),
              webOrderLineItemId: ((value: bigint): unknown => {
                return value;
              })(value["webOrderLineItemId"]),
            };
          })(value["paymentInfoIOS"]),
          paymentInfoInvoice: ((
            value: {
              paidAt: DateTime<true> | null;
              reference: string | null;
              transactionId: number;
            } | null,
          ): unknown => {
            if (value === null) {
              return null;
            }
            return {
              paidAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["paidAt"]),
              reference: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["reference"]),
              transactionId: ((value: number): unknown => {
                return value;
              })(value["transactionId"]),
            };
          })(value["paymentInfoInvoice"]),
          paymentInfoLSV: ((
            value: {
              accountNumber: string | null;
              acqAuthorizationCode: string | null;
              authorizationCode: string | null;
              bankIban: string | null;
              bankName: string | null;
              bankTown: string | null;
              clearingNumber: string | null;
              firstName: string;
              lastName: string;
              merchantId: string | null;
              refNumber: string | null;
              transactionId: number;
            } | null,
          ): unknown => {
            if (value === null) {
              return null;
            }
            return {
              accountNumber: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["accountNumber"]),
              acqAuthorizationCode: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["acqAuthorizationCode"]),
              authorizationCode: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["authorizationCode"]),
              bankIban: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["bankIban"]),
              bankName: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["bankName"]),
              bankTown: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["bankTown"]),
              clearingNumber: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["clearingNumber"]),
              firstName: ((value: string): unknown => {
                return value;
              })(value["firstName"]),
              lastName: ((value: string): unknown => {
                return value;
              })(value["lastName"]),
              merchantId: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["merchantId"]),
              refNumber: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["refNumber"]),
              transactionId: ((value: number): unknown => {
                return value;
              })(value["transactionId"]),
            };
          })(value["paymentInfoLSV"]),
          paymentInfoMiles: ((
            value: {
              cardNumber: string | null;
              milesTransactionId: number | null;
              pin: string | null;
              transactionId: number;
            } | null,
          ): unknown => {
            if (value === null) {
              return null;
            }
            return {
              cardNumber: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["cardNumber"]),
              milesTransactionId: ((value: number | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["milesTransactionId"]),
              pin: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["pin"]),
              transactionId: ((value: number): unknown => {
                return value;
              })(value["transactionId"]),
            };
          })(value["paymentInfoMiles"]),
          paymentInfoPaypal: ((
            value: {
              acqAuthorizationCode: string | null;
              authorizationCode: string | null;
              merchantId: string | null;
              paypalBillingAgreementId: string | null;
              refNumber: string | null;
              transactionId: number;
            } | null,
          ): unknown => {
            if (value === null) {
              return null;
            }
            return {
              acqAuthorizationCode: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["acqAuthorizationCode"]),
              authorizationCode: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["authorizationCode"]),
              merchantId: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["merchantId"]),
              paypalBillingAgreementId: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["paypalBillingAgreementId"]),
              refNumber: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["refNumber"]),
              transactionId: ((value: number): unknown => {
                return value;
              })(value["transactionId"]),
            };
          })(value["paymentInfoPaypal"]),
          paymentReceiptUri: ((value: string): unknown => {
            return value;
          })(value["paymentReceiptUri"]),
          paymentType: ((value: "CC" | "INVOICE" | "PAYPAL" | "LSV" | "MILES" | "ALIPAY" | "IOS" | "ANDROID"): unknown => {
            return value;
          })(value["paymentType"]),
          quantity: ((value: number): unknown => {
            return value;
          })(value["quantity"]),
          subscriptionId: ((value: number): unknown => {
            return value;
          })(value["subscriptionId"]),
          transactionId: ((value: number): unknown => {
            return value;
          })(value["transactionId"]),
          transactionType: ((value: "DEBIT" | "CREDIT"): unknown => {
            return value;
          })(value["transactionType"]),
        };
      })(value["transaction"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SubscriptionCreditView(str: string): SubscriptionCreditView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          amountToCredit: number;
          balance: number;
          currencyCode: string | null;
          customerId: number;
          emailSubject: string | null;
          emailText: string | null;
          isEmailHtml: boolean | null;
          isGiftSubscription: boolean;
          isSubscriptionStopped: boolean;
          subscriptionId: number;
          transaction: SubscriptionTransaction;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "amountToCredit")) {
      return { success: false };
    }
    const parsed_amountToCredit = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        if (typeof value === "bigint") {
          return { success: true, value: Number(value) };
        }
        return { success: false };
      })(value["amountToCredit"]);
    })();
    if (!parsed_amountToCredit.success) {
      return { success: false };
    }
    if (!hasProp(value, "balance")) {
      return { success: false };
    }
    const parsed_balance = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        if (typeof value === "bigint") {
          return { success: true, value: Number(value) };
        }
        return { success: false };
      })(value["balance"]);
    })();
    if (!parsed_balance.success) {
      return { success: false };
    }
    if (!hasProp(value, "currencyCode")) {
      return { success: false };
    }
    const parsed_currencyCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["currencyCode"]);
    })();
    if (!parsed_currencyCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "emailSubject")) {
      return { success: false };
    }
    const parsed_emailSubject = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["emailSubject"]);
    })();
    if (!parsed_emailSubject.success) {
      return { success: false };
    }
    if (!hasProp(value, "emailText")) {
      return { success: false };
    }
    const parsed_emailText = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["emailText"]);
    })();
    if (!parsed_emailText.success) {
      return { success: false };
    }
    if (!hasProp(value, "isEmailHtml")) {
      return { success: false };
    }
    const parsed_isEmailHtml = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isEmailHtml"]);
    })();
    if (!parsed_isEmailHtml.success) {
      return { success: false };
    }
    if (!hasProp(value, "isGiftSubscription")) {
      return { success: false };
    }
    const parsed_isGiftSubscription = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isGiftSubscription"]);
    })();
    if (!parsed_isGiftSubscription.success) {
      return { success: false };
    }
    if (!hasProp(value, "isSubscriptionStopped")) {
      return { success: false };
    }
    const parsed_isSubscriptionStopped = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isSubscriptionStopped"]);
    })();
    if (!parsed_isSubscriptionStopped.success) {
      return { success: false };
    }
    if (!hasProp(value, "subscriptionId")) {
      return { success: false };
    }
    const parsed_subscriptionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["subscriptionId"]);
    })();
    if (!parsed_subscriptionId.success) {
      return { success: false };
    }
    if (!hasProp(value, "transaction")) {
      return { success: false };
    }
    const parsed_transaction = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SubscriptionTransaction } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                amount: DisplayMonetaryAmount;
                comment: string | null;
                customerId: number;
                generatedAt: { year: number; month: number; day: number };
                paymentInfoAlipay: AlipayPaymentView | null;
                paymentInfoAndroid: AndroidPaymentView | null;
                paymentInfoCC: CreditCardPaymentView | null;
                paymentInfoIOS: IosPaymentView | null;
                paymentInfoInvoice: InvoicePaymentView | null;
                paymentInfoLSV: LsvPaymentView | null;
                paymentInfoMiles: MilesPaymentView | null;
                paymentInfoPaypal: PayPalPaymentView | null;
                paymentReceiptUri: string;
                paymentType: PaymentType;
                quantity: number;
                subscriptionId: number;
                transactionId: number;
                transactionType: "DEBIT" | "CREDIT";
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "amount")) {
            return { success: false };
          }
          const parsed_amount = (() => {
            return ((value: unknown): { success: false } | { success: true; value: DisplayMonetaryAmount } => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      amount: number;
                      currencyCode: string;
                      displayValue: string;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "amount")) {
                  return { success: false };
                }
                const parsed_amount = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: number } => {
                    if (typeof value === "number") {
                      return { success: true, value: value };
                    }
                    if (typeof value === "bigint") {
                      return { success: true, value: Number(value) };
                    }
                    return { success: false };
                  })(value["amount"]);
                })();
                if (!parsed_amount.success) {
                  return { success: false };
                }
                if (!hasProp(value, "currencyCode")) {
                  return { success: false };
                }
                const parsed_currencyCode = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["currencyCode"]);
                })();
                if (!parsed_currencyCode.success) {
                  return { success: false };
                }
                if (!hasProp(value, "displayValue")) {
                  return { success: false };
                }
                const parsed_displayValue = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["displayValue"]);
                })();
                if (!parsed_displayValue.success) {
                  return { success: false };
                }
                return { success: true, value: { amount: parsed_amount.value, currencyCode: parsed_currencyCode.value, displayValue: parsed_displayValue.value } };
              })(value);
            })(value["amount"]);
          })();
          if (!parsed_amount.success) {
            return { success: false };
          }
          if (!hasProp(value, "comment")) {
            return { success: false };
          }
          const parsed_comment = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["comment"]);
          })();
          if (!parsed_comment.success) {
            return { success: false };
          }
          if (!hasProp(value, "customerId")) {
            return { success: false };
          }
          const parsed_customerId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["customerId"]);
          })();
          if (!parsed_customerId.success) {
            return { success: false };
          }
          if (!hasProp(value, "generatedAt")) {
            return { success: false };
          }
          const parsed_generatedAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["generatedAt"]);
          })();
          if (!parsed_generatedAt.success) {
            return { success: false };
          }
          if (!hasProp(value, "paymentInfoAlipay")) {
            return { success: false };
          }
          const parsed_paymentInfoAlipay = (() => {
            return ((value: unknown): { success: false } | { success: true; value: AlipayPaymentView | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      outTradeNo: string | null;
                      tradeNo: string | null;
                      transactionId: number;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "outTradeNo")) {
                  return { success: false };
                }
                const parsed_outTradeNo = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["outTradeNo"]);
                })();
                if (!parsed_outTradeNo.success) {
                  return { success: false };
                }
                if (!hasProp(value, "tradeNo")) {
                  return { success: false };
                }
                const parsed_tradeNo = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["tradeNo"]);
                })();
                if (!parsed_tradeNo.success) {
                  return { success: false };
                }
                if (!hasProp(value, "transactionId")) {
                  return { success: false };
                }
                const parsed_transactionId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: number } => {
                    if (typeof value === "number") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["transactionId"]);
                })();
                if (!parsed_transactionId.success) {
                  return { success: false };
                }
                return { success: true, value: { outTradeNo: parsed_outTradeNo.value, tradeNo: parsed_tradeNo.value, transactionId: parsed_transactionId.value } };
              })(value);
            })(value["paymentInfoAlipay"]);
          })();
          if (!parsed_paymentInfoAlipay.success) {
            return { success: false };
          }
          if (!hasProp(value, "paymentInfoAndroid")) {
            return { success: false };
          }
          const parsed_paymentInfoAndroid = (() => {
            return ((value: unknown): { success: false } | { success: true; value: AndroidPaymentView | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      appStoreProductId: string | null;
                      autoRenewing: boolean;
                      countryCode: string | null;
                      orderId: string | null;
                      paymentState: number;
                      purchaseState: number;
                      testPurchase: boolean;
                      transactionId: number;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "appStoreProductId")) {
                  return { success: false };
                }
                const parsed_appStoreProductId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["appStoreProductId"]);
                })();
                if (!parsed_appStoreProductId.success) {
                  return { success: false };
                }
                if (!hasProp(value, "autoRenewing")) {
                  return { success: false };
                }
                const parsed_autoRenewing = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                    if (typeof value !== "boolean") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["autoRenewing"]);
                })();
                if (!parsed_autoRenewing.success) {
                  return { success: false };
                }
                if (!hasProp(value, "countryCode")) {
                  return { success: false };
                }
                const parsed_countryCode = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["countryCode"]);
                })();
                if (!parsed_countryCode.success) {
                  return { success: false };
                }
                if (!hasProp(value, "orderId")) {
                  return { success: false };
                }
                const parsed_orderId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["orderId"]);
                })();
                if (!parsed_orderId.success) {
                  return { success: false };
                }
                if (!hasProp(value, "paymentState")) {
                  return { success: false };
                }
                const parsed_paymentState = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: number } => {
                    if (typeof value === "number") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["paymentState"]);
                })();
                if (!parsed_paymentState.success) {
                  return { success: false };
                }
                if (!hasProp(value, "purchaseState")) {
                  return { success: false };
                }
                const parsed_purchaseState = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: number } => {
                    if (typeof value === "number") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["purchaseState"]);
                })();
                if (!parsed_purchaseState.success) {
                  return { success: false };
                }
                if (!hasProp(value, "testPurchase")) {
                  return { success: false };
                }
                const parsed_testPurchase = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                    if (typeof value !== "boolean") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["testPurchase"]);
                })();
                if (!parsed_testPurchase.success) {
                  return { success: false };
                }
                if (!hasProp(value, "transactionId")) {
                  return { success: false };
                }
                const parsed_transactionId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: number } => {
                    if (typeof value === "number") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["transactionId"]);
                })();
                if (!parsed_transactionId.success) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: {
                    appStoreProductId: parsed_appStoreProductId.value,
                    autoRenewing: parsed_autoRenewing.value,
                    countryCode: parsed_countryCode.value,
                    orderId: parsed_orderId.value,
                    paymentState: parsed_paymentState.value,
                    purchaseState: parsed_purchaseState.value,
                    testPurchase: parsed_testPurchase.value,
                    transactionId: parsed_transactionId.value,
                  },
                };
              })(value);
            })(value["paymentInfoAndroid"]);
          })();
          if (!parsed_paymentInfoAndroid.success) {
            return { success: false };
          }
          if (!hasProp(value, "paymentInfoCC")) {
            return { success: false };
          }
          const parsed_paymentInfoCC = (() => {
            return ((value: unknown): { success: false } | { success: true; value: CreditCardPaymentView | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      acqAuthorizationCode: string | null;
                      authorizationCode: string | null;
                      batchRefNumber: string | null;
                      creditCardAlias: string | null;
                      creditCardNumber: string;
                      creditCardType: CreditCardType;
                      datatransMerchantId: string | null;
                      expirationMonth: number;
                      expirationYear: number;
                      gatewayProvider: string;
                      nameOnCard: string | null;
                      refNumber: string | null;
                      transactionDateTime: DateTime<true>;
                      transactionId: number;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "acqAuthorizationCode")) {
                  return { success: false };
                }
                const parsed_acqAuthorizationCode = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["acqAuthorizationCode"]);
                })();
                if (!parsed_acqAuthorizationCode.success) {
                  return { success: false };
                }
                if (!hasProp(value, "authorizationCode")) {
                  return { success: false };
                }
                const parsed_authorizationCode = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["authorizationCode"]);
                })();
                if (!parsed_authorizationCode.success) {
                  return { success: false };
                }
                if (!hasProp(value, "batchRefNumber")) {
                  return { success: false };
                }
                const parsed_batchRefNumber = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["batchRefNumber"]);
                })();
                if (!parsed_batchRefNumber.success) {
                  return { success: false };
                }
                if (!hasProp(value, "creditCardAlias")) {
                  return { success: false };
                }
                const parsed_creditCardAlias = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["creditCardAlias"]);
                })();
                if (!parsed_creditCardAlias.success) {
                  return { success: false };
                }
                if (!hasProp(value, "creditCardNumber")) {
                  return { success: false };
                }
                const parsed_creditCardNumber = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["creditCardNumber"]);
                })();
                if (!parsed_creditCardNumber.success) {
                  return { success: false };
                }
                if (!hasProp(value, "creditCardType")) {
                  return { success: false };
                }
                const parsed_creditCardType = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: CreditCardType } => {
                    return ((value: unknown): { success: false } | { success: true; value: "VISA" | "MASTERCARD" | "AMEXCO" } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      switch (value) {
                        case "VISA":
                          return { success: true, value: "VISA" };
                        case "MASTERCARD":
                          return { success: true, value: "MASTERCARD" };
                        case "AMEXCO":
                          return { success: true, value: "AMEXCO" };
                        default:
                          return { success: false };
                      }
                    })(value);
                  })(value["creditCardType"]);
                })();
                if (!parsed_creditCardType.success) {
                  return { success: false };
                }
                if (!hasProp(value, "datatransMerchantId")) {
                  return { success: false };
                }
                const parsed_datatransMerchantId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["datatransMerchantId"]);
                })();
                if (!parsed_datatransMerchantId.success) {
                  return { success: false };
                }
                if (!hasProp(value, "expirationMonth")) {
                  return { success: false };
                }
                const parsed_expirationMonth = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: number } => {
                    if (typeof value === "number") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["expirationMonth"]);
                })();
                if (!parsed_expirationMonth.success) {
                  return { success: false };
                }
                if (!hasProp(value, "expirationYear")) {
                  return { success: false };
                }
                const parsed_expirationYear = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: number } => {
                    if (typeof value === "number") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["expirationYear"]);
                })();
                if (!parsed_expirationYear.success) {
                  return { success: false };
                }
                if (!hasProp(value, "gatewayProvider")) {
                  return { success: false };
                }
                const parsed_gatewayProvider = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["gatewayProvider"]);
                })();
                if (!parsed_gatewayProvider.success) {
                  return { success: false };
                }
                if (!hasProp(value, "nameOnCard")) {
                  return { success: false };
                }
                const parsed_nameOnCard = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["nameOnCard"]);
                })();
                if (!parsed_nameOnCard.success) {
                  return { success: false };
                }
                if (!hasProp(value, "refNumber")) {
                  return { success: false };
                }
                const parsed_refNumber = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["refNumber"]);
                })();
                if (!parsed_refNumber.success) {
                  return { success: false };
                }
                if (!hasProp(value, "transactionDateTime")) {
                  return { success: false };
                }
                const parsed_transactionDateTime = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                    if (typeof value !== "number") {
                      return { success: false };
                    }
                    const parsed = DateTime.fromMillis(value);
                    if (!parsed.isValid) {
                      return { success: false };
                    }
                    return { success: true, value: parsed };
                  })(value["transactionDateTime"]);
                })();
                if (!parsed_transactionDateTime.success) {
                  return { success: false };
                }
                if (!hasProp(value, "transactionId")) {
                  return { success: false };
                }
                const parsed_transactionId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: number } => {
                    if (typeof value === "number") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["transactionId"]);
                })();
                if (!parsed_transactionId.success) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: {
                    acqAuthorizationCode: parsed_acqAuthorizationCode.value,
                    authorizationCode: parsed_authorizationCode.value,
                    batchRefNumber: parsed_batchRefNumber.value,
                    creditCardAlias: parsed_creditCardAlias.value,
                    creditCardNumber: parsed_creditCardNumber.value,
                    creditCardType: parsed_creditCardType.value,
                    datatransMerchantId: parsed_datatransMerchantId.value,
                    expirationMonth: parsed_expirationMonth.value,
                    expirationYear: parsed_expirationYear.value,
                    gatewayProvider: parsed_gatewayProvider.value,
                    nameOnCard: parsed_nameOnCard.value,
                    refNumber: parsed_refNumber.value,
                    transactionDateTime: parsed_transactionDateTime.value,
                    transactionId: parsed_transactionId.value,
                  },
                };
              })(value);
            })(value["paymentInfoCC"]);
          })();
          if (!parsed_paymentInfoCC.success) {
            return { success: false };
          }
          if (!hasProp(value, "paymentInfoIOS")) {
            return { success: false };
          }
          const parsed_paymentInfoIOS = (() => {
            return ((value: unknown): { success: false } | { success: true; value: IosPaymentView | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      appStoreProductId: string;
                      autoRenewProductId: string | null;
                      autoRenewing: boolean;
                      expirationIntent: number;
                      itunesTransactionId: string;
                      originalItunesTransactionId: string | null;
                      testPurchase: boolean;
                      transactionId: number;
                      webOrderLineItemId: bigint;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "appStoreProductId")) {
                  return { success: false };
                }
                const parsed_appStoreProductId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["appStoreProductId"]);
                })();
                if (!parsed_appStoreProductId.success) {
                  return { success: false };
                }
                if (!hasProp(value, "autoRenewProductId")) {
                  return { success: false };
                }
                const parsed_autoRenewProductId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["autoRenewProductId"]);
                })();
                if (!parsed_autoRenewProductId.success) {
                  return { success: false };
                }
                if (!hasProp(value, "autoRenewing")) {
                  return { success: false };
                }
                const parsed_autoRenewing = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                    if (typeof value !== "boolean") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["autoRenewing"]);
                })();
                if (!parsed_autoRenewing.success) {
                  return { success: false };
                }
                if (!hasProp(value, "expirationIntent")) {
                  return { success: false };
                }
                const parsed_expirationIntent = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: number } => {
                    if (typeof value === "number") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["expirationIntent"]);
                })();
                if (!parsed_expirationIntent.success) {
                  return { success: false };
                }
                if (!hasProp(value, "itunesTransactionId")) {
                  return { success: false };
                }
                const parsed_itunesTransactionId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["itunesTransactionId"]);
                })();
                if (!parsed_itunesTransactionId.success) {
                  return { success: false };
                }
                if (!hasProp(value, "originalItunesTransactionId")) {
                  return { success: false };
                }
                const parsed_originalItunesTransactionId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["originalItunesTransactionId"]);
                })();
                if (!parsed_originalItunesTransactionId.success) {
                  return { success: false };
                }
                if (!hasProp(value, "testPurchase")) {
                  return { success: false };
                }
                const parsed_testPurchase = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                    if (typeof value !== "boolean") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["testPurchase"]);
                })();
                if (!parsed_testPurchase.success) {
                  return { success: false };
                }
                if (!hasProp(value, "transactionId")) {
                  return { success: false };
                }
                const parsed_transactionId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: number } => {
                    if (typeof value === "number") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["transactionId"]);
                })();
                if (!parsed_transactionId.success) {
                  return { success: false };
                }
                if (!hasProp(value, "webOrderLineItemId")) {
                  return { success: false };
                }
                const parsed_webOrderLineItemId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                    if (typeof value === "number") {
                      return { success: true, value: BigInt(value) };
                    }
                    if (typeof value === "bigint") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["webOrderLineItemId"]);
                })();
                if (!parsed_webOrderLineItemId.success) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: {
                    appStoreProductId: parsed_appStoreProductId.value,
                    autoRenewProductId: parsed_autoRenewProductId.value,
                    autoRenewing: parsed_autoRenewing.value,
                    expirationIntent: parsed_expirationIntent.value,
                    itunesTransactionId: parsed_itunesTransactionId.value,
                    originalItunesTransactionId: parsed_originalItunesTransactionId.value,
                    testPurchase: parsed_testPurchase.value,
                    transactionId: parsed_transactionId.value,
                    webOrderLineItemId: parsed_webOrderLineItemId.value,
                  },
                };
              })(value);
            })(value["paymentInfoIOS"]);
          })();
          if (!parsed_paymentInfoIOS.success) {
            return { success: false };
          }
          if (!hasProp(value, "paymentInfoInvoice")) {
            return { success: false };
          }
          const parsed_paymentInfoInvoice = (() => {
            return ((value: unknown): { success: false } | { success: true; value: InvoicePaymentView | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      paidAt: DateTime<true> | null;
                      reference: string | null;
                      transactionId: number;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "paidAt")) {
                  return { success: false };
                }
                const parsed_paidAt = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "number") {
                      return { success: false };
                    }
                    const parsed = DateTime.fromMillis(value);
                    if (!parsed.isValid) {
                      return { success: false };
                    }
                    return { success: true, value: parsed };
                  })(value["paidAt"]);
                })();
                if (!parsed_paidAt.success) {
                  return { success: false };
                }
                if (!hasProp(value, "reference")) {
                  return { success: false };
                }
                const parsed_reference = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["reference"]);
                })();
                if (!parsed_reference.success) {
                  return { success: false };
                }
                if (!hasProp(value, "transactionId")) {
                  return { success: false };
                }
                const parsed_transactionId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: number } => {
                    if (typeof value === "number") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["transactionId"]);
                })();
                if (!parsed_transactionId.success) {
                  return { success: false };
                }
                return { success: true, value: { paidAt: parsed_paidAt.value, reference: parsed_reference.value, transactionId: parsed_transactionId.value } };
              })(value);
            })(value["paymentInfoInvoice"]);
          })();
          if (!parsed_paymentInfoInvoice.success) {
            return { success: false };
          }
          if (!hasProp(value, "paymentInfoLSV")) {
            return { success: false };
          }
          const parsed_paymentInfoLSV = (() => {
            return ((value: unknown): { success: false } | { success: true; value: LsvPaymentView | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      accountNumber: string | null;
                      acqAuthorizationCode: string | null;
                      authorizationCode: string | null;
                      bankIban: string | null;
                      bankName: string | null;
                      bankTown: string | null;
                      clearingNumber: string | null;
                      firstName: string;
                      lastName: string;
                      merchantId: string | null;
                      refNumber: string | null;
                      transactionId: number;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "accountNumber")) {
                  return { success: false };
                }
                const parsed_accountNumber = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["accountNumber"]);
                })();
                if (!parsed_accountNumber.success) {
                  return { success: false };
                }
                if (!hasProp(value, "acqAuthorizationCode")) {
                  return { success: false };
                }
                const parsed_acqAuthorizationCode = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["acqAuthorizationCode"]);
                })();
                if (!parsed_acqAuthorizationCode.success) {
                  return { success: false };
                }
                if (!hasProp(value, "authorizationCode")) {
                  return { success: false };
                }
                const parsed_authorizationCode = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["authorizationCode"]);
                })();
                if (!parsed_authorizationCode.success) {
                  return { success: false };
                }
                if (!hasProp(value, "bankIban")) {
                  return { success: false };
                }
                const parsed_bankIban = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["bankIban"]);
                })();
                if (!parsed_bankIban.success) {
                  return { success: false };
                }
                if (!hasProp(value, "bankName")) {
                  return { success: false };
                }
                const parsed_bankName = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["bankName"]);
                })();
                if (!parsed_bankName.success) {
                  return { success: false };
                }
                if (!hasProp(value, "bankTown")) {
                  return { success: false };
                }
                const parsed_bankTown = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["bankTown"]);
                })();
                if (!parsed_bankTown.success) {
                  return { success: false };
                }
                if (!hasProp(value, "clearingNumber")) {
                  return { success: false };
                }
                const parsed_clearingNumber = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["clearingNumber"]);
                })();
                if (!parsed_clearingNumber.success) {
                  return { success: false };
                }
                if (!hasProp(value, "firstName")) {
                  return { success: false };
                }
                const parsed_firstName = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["firstName"]);
                })();
                if (!parsed_firstName.success) {
                  return { success: false };
                }
                if (!hasProp(value, "lastName")) {
                  return { success: false };
                }
                const parsed_lastName = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["lastName"]);
                })();
                if (!parsed_lastName.success) {
                  return { success: false };
                }
                if (!hasProp(value, "merchantId")) {
                  return { success: false };
                }
                const parsed_merchantId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["merchantId"]);
                })();
                if (!parsed_merchantId.success) {
                  return { success: false };
                }
                if (!hasProp(value, "refNumber")) {
                  return { success: false };
                }
                const parsed_refNumber = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["refNumber"]);
                })();
                if (!parsed_refNumber.success) {
                  return { success: false };
                }
                if (!hasProp(value, "transactionId")) {
                  return { success: false };
                }
                const parsed_transactionId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: number } => {
                    if (typeof value === "number") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["transactionId"]);
                })();
                if (!parsed_transactionId.success) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: {
                    accountNumber: parsed_accountNumber.value,
                    acqAuthorizationCode: parsed_acqAuthorizationCode.value,
                    authorizationCode: parsed_authorizationCode.value,
                    bankIban: parsed_bankIban.value,
                    bankName: parsed_bankName.value,
                    bankTown: parsed_bankTown.value,
                    clearingNumber: parsed_clearingNumber.value,
                    firstName: parsed_firstName.value,
                    lastName: parsed_lastName.value,
                    merchantId: parsed_merchantId.value,
                    refNumber: parsed_refNumber.value,
                    transactionId: parsed_transactionId.value,
                  },
                };
              })(value);
            })(value["paymentInfoLSV"]);
          })();
          if (!parsed_paymentInfoLSV.success) {
            return { success: false };
          }
          if (!hasProp(value, "paymentInfoMiles")) {
            return { success: false };
          }
          const parsed_paymentInfoMiles = (() => {
            return ((value: unknown): { success: false } | { success: true; value: MilesPaymentView | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      cardNumber: string | null;
                      milesTransactionId: number | null;
                      pin: string | null;
                      transactionId: number;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "cardNumber")) {
                  return { success: false };
                }
                const parsed_cardNumber = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["cardNumber"]);
                })();
                if (!parsed_cardNumber.success) {
                  return { success: false };
                }
                if (!hasProp(value, "milesTransactionId")) {
                  return { success: false };
                }
                const parsed_milesTransactionId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value === "number") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["milesTransactionId"]);
                })();
                if (!parsed_milesTransactionId.success) {
                  return { success: false };
                }
                if (!hasProp(value, "pin")) {
                  return { success: false };
                }
                const parsed_pin = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["pin"]);
                })();
                if (!parsed_pin.success) {
                  return { success: false };
                }
                if (!hasProp(value, "transactionId")) {
                  return { success: false };
                }
                const parsed_transactionId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: number } => {
                    if (typeof value === "number") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["transactionId"]);
                })();
                if (!parsed_transactionId.success) {
                  return { success: false };
                }
                return { success: true, value: { cardNumber: parsed_cardNumber.value, milesTransactionId: parsed_milesTransactionId.value, pin: parsed_pin.value, transactionId: parsed_transactionId.value } };
              })(value);
            })(value["paymentInfoMiles"]);
          })();
          if (!parsed_paymentInfoMiles.success) {
            return { success: false };
          }
          if (!hasProp(value, "paymentInfoPaypal")) {
            return { success: false };
          }
          const parsed_paymentInfoPaypal = (() => {
            return ((value: unknown): { success: false } | { success: true; value: PayPalPaymentView | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      acqAuthorizationCode: string | null;
                      authorizationCode: string | null;
                      merchantId: string | null;
                      paypalBillingAgreementId: string | null;
                      refNumber: string | null;
                      transactionId: number;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "acqAuthorizationCode")) {
                  return { success: false };
                }
                const parsed_acqAuthorizationCode = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["acqAuthorizationCode"]);
                })();
                if (!parsed_acqAuthorizationCode.success) {
                  return { success: false };
                }
                if (!hasProp(value, "authorizationCode")) {
                  return { success: false };
                }
                const parsed_authorizationCode = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["authorizationCode"]);
                })();
                if (!parsed_authorizationCode.success) {
                  return { success: false };
                }
                if (!hasProp(value, "merchantId")) {
                  return { success: false };
                }
                const parsed_merchantId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["merchantId"]);
                })();
                if (!parsed_merchantId.success) {
                  return { success: false };
                }
                if (!hasProp(value, "paypalBillingAgreementId")) {
                  return { success: false };
                }
                const parsed_paypalBillingAgreementId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["paypalBillingAgreementId"]);
                })();
                if (!parsed_paypalBillingAgreementId.success) {
                  return { success: false };
                }
                if (!hasProp(value, "refNumber")) {
                  return { success: false };
                }
                const parsed_refNumber = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                    if (value === null) {
                      return { success: true, value: null };
                    }
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["refNumber"]);
                })();
                if (!parsed_refNumber.success) {
                  return { success: false };
                }
                if (!hasProp(value, "transactionId")) {
                  return { success: false };
                }
                const parsed_transactionId = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: number } => {
                    if (typeof value === "number") {
                      return { success: true, value: value };
                    }
                    return { success: false };
                  })(value["transactionId"]);
                })();
                if (!parsed_transactionId.success) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: {
                    acqAuthorizationCode: parsed_acqAuthorizationCode.value,
                    authorizationCode: parsed_authorizationCode.value,
                    merchantId: parsed_merchantId.value,
                    paypalBillingAgreementId: parsed_paypalBillingAgreementId.value,
                    refNumber: parsed_refNumber.value,
                    transactionId: parsed_transactionId.value,
                  },
                };
              })(value);
            })(value["paymentInfoPaypal"]);
          })();
          if (!parsed_paymentInfoPaypal.success) {
            return { success: false };
          }
          if (!hasProp(value, "paymentReceiptUri")) {
            return { success: false };
          }
          const parsed_paymentReceiptUri = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["paymentReceiptUri"]);
          })();
          if (!parsed_paymentReceiptUri.success) {
            return { success: false };
          }
          if (!hasProp(value, "paymentType")) {
            return { success: false };
          }
          const parsed_paymentType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: PaymentType } => {
              return ((value: unknown): { success: false } | { success: true; value: "CC" | "INVOICE" | "PAYPAL" | "LSV" | "MILES" | "ALIPAY" | "IOS" | "ANDROID" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "CC":
                    return { success: true, value: "CC" };
                  case "INVOICE":
                    return { success: true, value: "INVOICE" };
                  case "PAYPAL":
                    return { success: true, value: "PAYPAL" };
                  case "LSV":
                    return { success: true, value: "LSV" };
                  case "MILES":
                    return { success: true, value: "MILES" };
                  case "ALIPAY":
                    return { success: true, value: "ALIPAY" };
                  case "IOS":
                    return { success: true, value: "IOS" };
                  case "ANDROID":
                    return { success: true, value: "ANDROID" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["paymentType"]);
          })();
          if (!parsed_paymentType.success) {
            return { success: false };
          }
          if (!hasProp(value, "quantity")) {
            return { success: false };
          }
          const parsed_quantity = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["quantity"]);
          })();
          if (!parsed_quantity.success) {
            return { success: false };
          }
          if (!hasProp(value, "subscriptionId")) {
            return { success: false };
          }
          const parsed_subscriptionId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["subscriptionId"]);
          })();
          if (!parsed_subscriptionId.success) {
            return { success: false };
          }
          if (!hasProp(value, "transactionId")) {
            return { success: false };
          }
          const parsed_transactionId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["transactionId"]);
          })();
          if (!parsed_transactionId.success) {
            return { success: false };
          }
          if (!hasProp(value, "transactionType")) {
            return { success: false };
          }
          const parsed_transactionType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: "DEBIT" | "CREDIT" } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              switch (value) {
                case "DEBIT":
                  return { success: true, value: "DEBIT" };
                case "CREDIT":
                  return { success: true, value: "CREDIT" };
                default:
                  return { success: false };
              }
            })(value["transactionType"]);
          })();
          if (!parsed_transactionType.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              amount: parsed_amount.value,
              comment: parsed_comment.value,
              customerId: parsed_customerId.value,
              generatedAt: parsed_generatedAt.value,
              paymentInfoAlipay: parsed_paymentInfoAlipay.value,
              paymentInfoAndroid: parsed_paymentInfoAndroid.value,
              paymentInfoCC: parsed_paymentInfoCC.value,
              paymentInfoIOS: parsed_paymentInfoIOS.value,
              paymentInfoInvoice: parsed_paymentInfoInvoice.value,
              paymentInfoLSV: parsed_paymentInfoLSV.value,
              paymentInfoMiles: parsed_paymentInfoMiles.value,
              paymentInfoPaypal: parsed_paymentInfoPaypal.value,
              paymentReceiptUri: parsed_paymentReceiptUri.value,
              paymentType: parsed_paymentType.value,
              quantity: parsed_quantity.value,
              subscriptionId: parsed_subscriptionId.value,
              transactionId: parsed_transactionId.value,
              transactionType: parsed_transactionType.value,
            },
          };
        })(value);
      })(value["transaction"]);
    })();
    if (!parsed_transaction.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        amountToCredit: parsed_amountToCredit.value,
        balance: parsed_balance.value,
        currencyCode: parsed_currencyCode.value,
        customerId: parsed_customerId.value,
        emailSubject: parsed_emailSubject.value,
        emailText: parsed_emailText.value,
        isEmailHtml: parsed_isEmailHtml.value,
        isGiftSubscription: parsed_isGiftSubscription.value,
        isSubscriptionStopped: parsed_isSubscriptionStopped.value,
        subscriptionId: parsed_subscriptionId.value,
        transaction: parsed_transaction.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SubscriptionPayment
export function serialize_SubscriptionPayment(obj: SubscriptionPayment): string {
  const serialized = ((value: { balance: MonetaryAmount; isMobilePayment: boolean; paymentType: string | null }): unknown => {
    return {
      balance: ((value: { amount: number; currencyCode: string; displayValue: string }): unknown => {
        return {
          amount: ((value: number): unknown => {
            return value;
          })(value["amount"]),
          currencyCode: ((value: string): unknown => {
            return value;
          })(value["currencyCode"]),
          displayValue: ((value: string): unknown => {
            return value;
          })(value["displayValue"]),
        };
      })(value["balance"]),
      isMobilePayment: ((value: boolean): unknown => {
        return value;
      })(value["isMobilePayment"]),
      paymentType: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["paymentType"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SubscriptionPayment(str: string): SubscriptionPayment | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          balance: MonetaryAmount;
          isMobilePayment: boolean;
          paymentType: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "balance")) {
      return { success: false };
    }
    const parsed_balance = (() => {
      return ((value: unknown): { success: false } | { success: true; value: MonetaryAmount } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                amount: number;
                currencyCode: string;
                displayValue: string;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "amount")) {
            return { success: false };
          }
          const parsed_amount = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              if (typeof value === "bigint") {
                return { success: true, value: Number(value) };
              }
              return { success: false };
            })(value["amount"]);
          })();
          if (!parsed_amount.success) {
            return { success: false };
          }
          if (!hasProp(value, "currencyCode")) {
            return { success: false };
          }
          const parsed_currencyCode = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["currencyCode"]);
          })();
          if (!parsed_currencyCode.success) {
            return { success: false };
          }
          if (!hasProp(value, "displayValue")) {
            return { success: false };
          }
          const parsed_displayValue = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["displayValue"]);
          })();
          if (!parsed_displayValue.success) {
            return { success: false };
          }
          return { success: true, value: { amount: parsed_amount.value, currencyCode: parsed_currencyCode.value, displayValue: parsed_displayValue.value } };
        })(value);
      })(value["balance"]);
    })();
    if (!parsed_balance.success) {
      return { success: false };
    }
    if (!hasProp(value, "isMobilePayment")) {
      return { success: false };
    }
    const parsed_isMobilePayment = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isMobilePayment"]);
    })();
    if (!parsed_isMobilePayment.success) {
      return { success: false };
    }
    if (!hasProp(value, "paymentType")) {
      return { success: false };
    }
    const parsed_paymentType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["paymentType"]);
    })();
    if (!parsed_paymentType.success) {
      return { success: false };
    }
    return { success: true, value: { balance: parsed_balance.value, isMobilePayment: parsed_isMobilePayment.value, paymentType: parsed_paymentType.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SubscriptionRenewInfoView
export function serialize_SubscriptionRenewInfoView(obj: SubscriptionRenewInfoView): string {
  const serialized = ((value: {
    ccNumber: string | null;
    ccType: CreditCardType | null;
    cvv: string | null;
    expMonth: string | null;
    expYear: string | null;
    lsvAccountNumber: string | null;
    lsvBankIban: string | null;
    lsvBankName: string | null;
    lsvBankTown: string | null;
    lsvClearingNumber: string | null;
    lsvFirstName: string | null;
    lsvLastName: string | null;
    manualPrice: number | null;
    paymentType: string | null;
    productId: number | null;
    promotionCode: string | null;
    street: string | null;
    subscriptionId: number;
    zip: string | null;
  }): unknown => {
    return {
      ccNumber: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["ccNumber"]),
      ccType: ((value: ("VISA" | "MASTERCARD" | "AMEXCO") | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["ccType"]),
      cvv: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["cvv"]),
      expMonth: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["expMonth"]),
      expYear: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["expYear"]),
      lsvAccountNumber: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lsvAccountNumber"]),
      lsvBankIban: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lsvBankIban"]),
      lsvBankName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lsvBankName"]),
      lsvBankTown: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lsvBankTown"]),
      lsvClearingNumber: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lsvClearingNumber"]),
      lsvFirstName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lsvFirstName"]),
      lsvLastName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["lsvLastName"]),
      manualPrice: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["manualPrice"]),
      paymentType: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["paymentType"]),
      productId: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["productId"]),
      promotionCode: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["promotionCode"]),
      street: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["street"]),
      subscriptionId: ((value: number): unknown => {
        return value;
      })(value["subscriptionId"]),
      zip: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["zip"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SubscriptionRenewInfoView(str: string): SubscriptionRenewInfoView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          ccNumber: string | null;
          ccType: CreditCardType | null;
          cvv: string | null;
          expMonth: string | null;
          expYear: string | null;
          lsvAccountNumber: string | null;
          lsvBankIban: string | null;
          lsvBankName: string | null;
          lsvBankTown: string | null;
          lsvClearingNumber: string | null;
          lsvFirstName: string | null;
          lsvLastName: string | null;
          manualPrice: number | null;
          paymentType: string | null;
          productId: number | null;
          promotionCode: string | null;
          street: string | null;
          subscriptionId: number;
          zip: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "ccNumber")) {
      return { success: false };
    }
    const parsed_ccNumber = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ccNumber"]);
    })();
    if (!parsed_ccNumber.success) {
      return { success: false };
    }
    if (!hasProp(value, "ccType")) {
      return { success: false };
    }
    const parsed_ccType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CreditCardType | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((value: unknown): { success: false } | { success: true; value: "VISA" | "MASTERCARD" | "AMEXCO" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "VISA":
              return { success: true, value: "VISA" };
            case "MASTERCARD":
              return { success: true, value: "MASTERCARD" };
            case "AMEXCO":
              return { success: true, value: "AMEXCO" };
            default:
              return { success: false };
          }
        })(value);
      })(value["ccType"]);
    })();
    if (!parsed_ccType.success) {
      return { success: false };
    }
    if (!hasProp(value, "cvv")) {
      return { success: false };
    }
    const parsed_cvv = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["cvv"]);
    })();
    if (!parsed_cvv.success) {
      return { success: false };
    }
    if (!hasProp(value, "expMonth")) {
      return { success: false };
    }
    const parsed_expMonth = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["expMonth"]);
    })();
    if (!parsed_expMonth.success) {
      return { success: false };
    }
    if (!hasProp(value, "expYear")) {
      return { success: false };
    }
    const parsed_expYear = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["expYear"]);
    })();
    if (!parsed_expYear.success) {
      return { success: false };
    }
    if (!hasProp(value, "lsvAccountNumber")) {
      return { success: false };
    }
    const parsed_lsvAccountNumber = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lsvAccountNumber"]);
    })();
    if (!parsed_lsvAccountNumber.success) {
      return { success: false };
    }
    if (!hasProp(value, "lsvBankIban")) {
      return { success: false };
    }
    const parsed_lsvBankIban = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lsvBankIban"]);
    })();
    if (!parsed_lsvBankIban.success) {
      return { success: false };
    }
    if (!hasProp(value, "lsvBankName")) {
      return { success: false };
    }
    const parsed_lsvBankName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lsvBankName"]);
    })();
    if (!parsed_lsvBankName.success) {
      return { success: false };
    }
    if (!hasProp(value, "lsvBankTown")) {
      return { success: false };
    }
    const parsed_lsvBankTown = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lsvBankTown"]);
    })();
    if (!parsed_lsvBankTown.success) {
      return { success: false };
    }
    if (!hasProp(value, "lsvClearingNumber")) {
      return { success: false };
    }
    const parsed_lsvClearingNumber = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lsvClearingNumber"]);
    })();
    if (!parsed_lsvClearingNumber.success) {
      return { success: false };
    }
    if (!hasProp(value, "lsvFirstName")) {
      return { success: false };
    }
    const parsed_lsvFirstName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lsvFirstName"]);
    })();
    if (!parsed_lsvFirstName.success) {
      return { success: false };
    }
    if (!hasProp(value, "lsvLastName")) {
      return { success: false };
    }
    const parsed_lsvLastName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lsvLastName"]);
    })();
    if (!parsed_lsvLastName.success) {
      return { success: false };
    }
    if (!hasProp(value, "manualPrice")) {
      return { success: false };
    }
    const parsed_manualPrice = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        if (typeof value === "bigint") {
          return { success: true, value: Number(value) };
        }
        return { success: false };
      })(value["manualPrice"]);
    })();
    if (!parsed_manualPrice.success) {
      return { success: false };
    }
    if (!hasProp(value, "paymentType")) {
      return { success: false };
    }
    const parsed_paymentType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["paymentType"]);
    })();
    if (!parsed_paymentType.success) {
      return { success: false };
    }
    if (!hasProp(value, "productId")) {
      return { success: false };
    }
    const parsed_productId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["productId"]);
    })();
    if (!parsed_productId.success) {
      return { success: false };
    }
    if (!hasProp(value, "promotionCode")) {
      return { success: false };
    }
    const parsed_promotionCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["promotionCode"]);
    })();
    if (!parsed_promotionCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "street")) {
      return { success: false };
    }
    const parsed_street = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["street"]);
    })();
    if (!parsed_street.success) {
      return { success: false };
    }
    if (!hasProp(value, "subscriptionId")) {
      return { success: false };
    }
    const parsed_subscriptionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["subscriptionId"]);
    })();
    if (!parsed_subscriptionId.success) {
      return { success: false };
    }
    if (!hasProp(value, "zip")) {
      return { success: false };
    }
    const parsed_zip = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["zip"]);
    })();
    if (!parsed_zip.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        ccNumber: parsed_ccNumber.value,
        ccType: parsed_ccType.value,
        cvv: parsed_cvv.value,
        expMonth: parsed_expMonth.value,
        expYear: parsed_expYear.value,
        lsvAccountNumber: parsed_lsvAccountNumber.value,
        lsvBankIban: parsed_lsvBankIban.value,
        lsvBankName: parsed_lsvBankName.value,
        lsvBankTown: parsed_lsvBankTown.value,
        lsvClearingNumber: parsed_lsvClearingNumber.value,
        lsvFirstName: parsed_lsvFirstName.value,
        lsvLastName: parsed_lsvLastName.value,
        manualPrice: parsed_manualPrice.value,
        paymentType: parsed_paymentType.value,
        productId: parsed_productId.value,
        promotionCode: parsed_promotionCode.value,
        street: parsed_street.value,
        subscriptionId: parsed_subscriptionId.value,
        zip: parsed_zip.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SubscriptionRenewSource
export function serialize_SubscriptionRenewSource(obj: SubscriptionRenewSource): string {
  const serialized = ((value: "NEW" | "AUTOMATIC" | "MANUAL" | "UPGRADE"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SubscriptionRenewSource(str: string): SubscriptionRenewSource | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "NEW" | "AUTOMATIC" | "MANUAL" | "UPGRADE" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "NEW":
        return { success: true, value: "NEW" };
      case "AUTOMATIC":
        return { success: true, value: "AUTOMATIC" };
      case "MANUAL":
        return { success: true, value: "MANUAL" };
      case "UPGRADE":
        return { success: true, value: "UPGRADE" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SubscriptionStatus
export function serialize_SubscriptionStatus(obj: SubscriptionStatus): string {
  const serialized = ((value: "ACTIVE" | "STOP" | "CANCEL" | "RENEWED" | "RENEWALFAILED"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SubscriptionStatus(str: string): SubscriptionStatus | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "ACTIVE" | "STOP" | "CANCEL" | "RENEWED" | "RENEWALFAILED" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "ACTIVE":
        return { success: true, value: "ACTIVE" };
      case "STOP":
        return { success: true, value: "STOP" };
      case "CANCEL":
        return { success: true, value: "CANCEL" };
      case "RENEWED":
        return { success: true, value: "RENEWED" };
      case "RENEWALFAILED":
        return { success: true, value: "RENEWALFAILED" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SubscriptionTransaction
export function serialize_SubscriptionTransaction(obj: SubscriptionTransaction): string {
  const serialized = ((value: {
    amount: DisplayMonetaryAmount;
    comment: string | null;
    customerId: number;
    generatedAt: { year: number; month: number; day: number };
    paymentInfoAlipay: AlipayPaymentView | null;
    paymentInfoAndroid: AndroidPaymentView | null;
    paymentInfoCC: CreditCardPaymentView | null;
    paymentInfoIOS: IosPaymentView | null;
    paymentInfoInvoice: InvoicePaymentView | null;
    paymentInfoLSV: LsvPaymentView | null;
    paymentInfoMiles: MilesPaymentView | null;
    paymentInfoPaypal: PayPalPaymentView | null;
    paymentReceiptUri: string;
    paymentType: PaymentType;
    quantity: number;
    subscriptionId: number;
    transactionId: number;
    transactionType: "DEBIT" | "CREDIT";
  }): unknown => {
    return {
      amount: ((value: { amount: number; currencyCode: string; displayValue: string }): unknown => {
        return {
          amount: ((value: number): unknown => {
            return value;
          })(value["amount"]),
          currencyCode: ((value: string): unknown => {
            return value;
          })(value["currencyCode"]),
          displayValue: ((value: string): unknown => {
            return value;
          })(value["displayValue"]),
        };
      })(value["amount"]),
      comment: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["comment"]),
      customerId: ((value: number): unknown => {
        return value;
      })(value["customerId"]),
      generatedAt: ((value: { year: number; month: number; day: number }): unknown => {
        return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
      })(value["generatedAt"]),
      paymentInfoAlipay: ((
        value: {
          outTradeNo: string | null;
          tradeNo: string | null;
          transactionId: number;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          outTradeNo: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["outTradeNo"]),
          tradeNo: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["tradeNo"]),
          transactionId: ((value: number): unknown => {
            return value;
          })(value["transactionId"]),
        };
      })(value["paymentInfoAlipay"]),
      paymentInfoAndroid: ((
        value: {
          appStoreProductId: string | null;
          autoRenewing: boolean;
          countryCode: string | null;
          orderId: string | null;
          paymentState: number;
          purchaseState: number;
          testPurchase: boolean;
          transactionId: number;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          appStoreProductId: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["appStoreProductId"]),
          autoRenewing: ((value: boolean): unknown => {
            return value;
          })(value["autoRenewing"]),
          countryCode: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["countryCode"]),
          orderId: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["orderId"]),
          paymentState: ((value: number): unknown => {
            return value;
          })(value["paymentState"]),
          purchaseState: ((value: number): unknown => {
            return value;
          })(value["purchaseState"]),
          testPurchase: ((value: boolean): unknown => {
            return value;
          })(value["testPurchase"]),
          transactionId: ((value: number): unknown => {
            return value;
          })(value["transactionId"]),
        };
      })(value["paymentInfoAndroid"]),
      paymentInfoCC: ((
        value: {
          acqAuthorizationCode: string | null;
          authorizationCode: string | null;
          batchRefNumber: string | null;
          creditCardAlias: string | null;
          creditCardNumber: string;
          creditCardType: CreditCardType;
          datatransMerchantId: string | null;
          expirationMonth: number;
          expirationYear: number;
          gatewayProvider: string;
          nameOnCard: string | null;
          refNumber: string | null;
          transactionDateTime: DateTime<true>;
          transactionId: number;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          acqAuthorizationCode: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["acqAuthorizationCode"]),
          authorizationCode: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["authorizationCode"]),
          batchRefNumber: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["batchRefNumber"]),
          creditCardAlias: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["creditCardAlias"]),
          creditCardNumber: ((value: string): unknown => {
            return value;
          })(value["creditCardNumber"]),
          creditCardType: ((value: "VISA" | "MASTERCARD" | "AMEXCO"): unknown => {
            return value;
          })(value["creditCardType"]),
          datatransMerchantId: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["datatransMerchantId"]),
          expirationMonth: ((value: number): unknown => {
            return value;
          })(value["expirationMonth"]),
          expirationYear: ((value: number): unknown => {
            return value;
          })(value["expirationYear"]),
          gatewayProvider: ((value: string): unknown => {
            return value;
          })(value["gatewayProvider"]),
          nameOnCard: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["nameOnCard"]),
          refNumber: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["refNumber"]),
          transactionDateTime: ((value: DateTime<true>): unknown => {
            return value.toMillis();
          })(value["transactionDateTime"]),
          transactionId: ((value: number): unknown => {
            return value;
          })(value["transactionId"]),
        };
      })(value["paymentInfoCC"]),
      paymentInfoIOS: ((
        value: {
          appStoreProductId: string;
          autoRenewProductId: string | null;
          autoRenewing: boolean;
          expirationIntent: number;
          itunesTransactionId: string;
          originalItunesTransactionId: string | null;
          testPurchase: boolean;
          transactionId: number;
          webOrderLineItemId: bigint;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          appStoreProductId: ((value: string): unknown => {
            return value;
          })(value["appStoreProductId"]),
          autoRenewProductId: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["autoRenewProductId"]),
          autoRenewing: ((value: boolean): unknown => {
            return value;
          })(value["autoRenewing"]),
          expirationIntent: ((value: number): unknown => {
            return value;
          })(value["expirationIntent"]),
          itunesTransactionId: ((value: string): unknown => {
            return value;
          })(value["itunesTransactionId"]),
          originalItunesTransactionId: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["originalItunesTransactionId"]),
          testPurchase: ((value: boolean): unknown => {
            return value;
          })(value["testPurchase"]),
          transactionId: ((value: number): unknown => {
            return value;
          })(value["transactionId"]),
          webOrderLineItemId: ((value: bigint): unknown => {
            return value;
          })(value["webOrderLineItemId"]),
        };
      })(value["paymentInfoIOS"]),
      paymentInfoInvoice: ((
        value: {
          paidAt: DateTime<true> | null;
          reference: string | null;
          transactionId: number;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          paidAt: ((value: DateTime<true> | null): unknown => {
            if (value === null) {
              return null;
            }
            return value.toMillis();
          })(value["paidAt"]),
          reference: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["reference"]),
          transactionId: ((value: number): unknown => {
            return value;
          })(value["transactionId"]),
        };
      })(value["paymentInfoInvoice"]),
      paymentInfoLSV: ((
        value: {
          accountNumber: string | null;
          acqAuthorizationCode: string | null;
          authorizationCode: string | null;
          bankIban: string | null;
          bankName: string | null;
          bankTown: string | null;
          clearingNumber: string | null;
          firstName: string;
          lastName: string;
          merchantId: string | null;
          refNumber: string | null;
          transactionId: number;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          accountNumber: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["accountNumber"]),
          acqAuthorizationCode: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["acqAuthorizationCode"]),
          authorizationCode: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["authorizationCode"]),
          bankIban: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["bankIban"]),
          bankName: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["bankName"]),
          bankTown: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["bankTown"]),
          clearingNumber: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["clearingNumber"]),
          firstName: ((value: string): unknown => {
            return value;
          })(value["firstName"]),
          lastName: ((value: string): unknown => {
            return value;
          })(value["lastName"]),
          merchantId: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["merchantId"]),
          refNumber: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["refNumber"]),
          transactionId: ((value: number): unknown => {
            return value;
          })(value["transactionId"]),
        };
      })(value["paymentInfoLSV"]),
      paymentInfoMiles: ((
        value: {
          cardNumber: string | null;
          milesTransactionId: number | null;
          pin: string | null;
          transactionId: number;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          cardNumber: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["cardNumber"]),
          milesTransactionId: ((value: number | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["milesTransactionId"]),
          pin: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["pin"]),
          transactionId: ((value: number): unknown => {
            return value;
          })(value["transactionId"]),
        };
      })(value["paymentInfoMiles"]),
      paymentInfoPaypal: ((
        value: {
          acqAuthorizationCode: string | null;
          authorizationCode: string | null;
          merchantId: string | null;
          paypalBillingAgreementId: string | null;
          refNumber: string | null;
          transactionId: number;
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          acqAuthorizationCode: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["acqAuthorizationCode"]),
          authorizationCode: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["authorizationCode"]),
          merchantId: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["merchantId"]),
          paypalBillingAgreementId: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["paypalBillingAgreementId"]),
          refNumber: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["refNumber"]),
          transactionId: ((value: number): unknown => {
            return value;
          })(value["transactionId"]),
        };
      })(value["paymentInfoPaypal"]),
      paymentReceiptUri: ((value: string): unknown => {
        return value;
      })(value["paymentReceiptUri"]),
      paymentType: ((value: "CC" | "INVOICE" | "PAYPAL" | "LSV" | "MILES" | "ALIPAY" | "IOS" | "ANDROID"): unknown => {
        return value;
      })(value["paymentType"]),
      quantity: ((value: number): unknown => {
        return value;
      })(value["quantity"]),
      subscriptionId: ((value: number): unknown => {
        return value;
      })(value["subscriptionId"]),
      transactionId: ((value: number): unknown => {
        return value;
      })(value["transactionId"]),
      transactionType: ((value: "DEBIT" | "CREDIT"): unknown => {
        return value;
      })(value["transactionType"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SubscriptionTransaction(str: string): SubscriptionTransaction | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          amount: DisplayMonetaryAmount;
          comment: string | null;
          customerId: number;
          generatedAt: { year: number; month: number; day: number };
          paymentInfoAlipay: AlipayPaymentView | null;
          paymentInfoAndroid: AndroidPaymentView | null;
          paymentInfoCC: CreditCardPaymentView | null;
          paymentInfoIOS: IosPaymentView | null;
          paymentInfoInvoice: InvoicePaymentView | null;
          paymentInfoLSV: LsvPaymentView | null;
          paymentInfoMiles: MilesPaymentView | null;
          paymentInfoPaypal: PayPalPaymentView | null;
          paymentReceiptUri: string;
          paymentType: PaymentType;
          quantity: number;
          subscriptionId: number;
          transactionId: number;
          transactionType: "DEBIT" | "CREDIT";
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "amount")) {
      return { success: false };
    }
    const parsed_amount = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DisplayMonetaryAmount } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                amount: number;
                currencyCode: string;
                displayValue: string;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "amount")) {
            return { success: false };
          }
          const parsed_amount = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              if (typeof value === "bigint") {
                return { success: true, value: Number(value) };
              }
              return { success: false };
            })(value["amount"]);
          })();
          if (!parsed_amount.success) {
            return { success: false };
          }
          if (!hasProp(value, "currencyCode")) {
            return { success: false };
          }
          const parsed_currencyCode = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["currencyCode"]);
          })();
          if (!parsed_currencyCode.success) {
            return { success: false };
          }
          if (!hasProp(value, "displayValue")) {
            return { success: false };
          }
          const parsed_displayValue = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["displayValue"]);
          })();
          if (!parsed_displayValue.success) {
            return { success: false };
          }
          return { success: true, value: { amount: parsed_amount.value, currencyCode: parsed_currencyCode.value, displayValue: parsed_displayValue.value } };
        })(value);
      })(value["amount"]);
    })();
    if (!parsed_amount.success) {
      return { success: false };
    }
    if (!hasProp(value, "comment")) {
      return { success: false };
    }
    const parsed_comment = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["comment"]);
    })();
    if (!parsed_comment.success) {
      return { success: false };
    }
    if (!hasProp(value, "customerId")) {
      return { success: false };
    }
    const parsed_customerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["customerId"]);
    })();
    if (!parsed_customerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "generatedAt")) {
      return { success: false };
    }
    const parsed_generatedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
      })(value["generatedAt"]);
    })();
    if (!parsed_generatedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "paymentInfoAlipay")) {
      return { success: false };
    }
    const parsed_paymentInfoAlipay = (() => {
      return ((value: unknown): { success: false } | { success: true; value: AlipayPaymentView | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                outTradeNo: string | null;
                tradeNo: string | null;
                transactionId: number;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "outTradeNo")) {
            return { success: false };
          }
          const parsed_outTradeNo = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["outTradeNo"]);
          })();
          if (!parsed_outTradeNo.success) {
            return { success: false };
          }
          if (!hasProp(value, "tradeNo")) {
            return { success: false };
          }
          const parsed_tradeNo = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["tradeNo"]);
          })();
          if (!parsed_tradeNo.success) {
            return { success: false };
          }
          if (!hasProp(value, "transactionId")) {
            return { success: false };
          }
          const parsed_transactionId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["transactionId"]);
          })();
          if (!parsed_transactionId.success) {
            return { success: false };
          }
          return { success: true, value: { outTradeNo: parsed_outTradeNo.value, tradeNo: parsed_tradeNo.value, transactionId: parsed_transactionId.value } };
        })(value);
      })(value["paymentInfoAlipay"]);
    })();
    if (!parsed_paymentInfoAlipay.success) {
      return { success: false };
    }
    if (!hasProp(value, "paymentInfoAndroid")) {
      return { success: false };
    }
    const parsed_paymentInfoAndroid = (() => {
      return ((value: unknown): { success: false } | { success: true; value: AndroidPaymentView | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                appStoreProductId: string | null;
                autoRenewing: boolean;
                countryCode: string | null;
                orderId: string | null;
                paymentState: number;
                purchaseState: number;
                testPurchase: boolean;
                transactionId: number;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "appStoreProductId")) {
            return { success: false };
          }
          const parsed_appStoreProductId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["appStoreProductId"]);
          })();
          if (!parsed_appStoreProductId.success) {
            return { success: false };
          }
          if (!hasProp(value, "autoRenewing")) {
            return { success: false };
          }
          const parsed_autoRenewing = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["autoRenewing"]);
          })();
          if (!parsed_autoRenewing.success) {
            return { success: false };
          }
          if (!hasProp(value, "countryCode")) {
            return { success: false };
          }
          const parsed_countryCode = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["countryCode"]);
          })();
          if (!parsed_countryCode.success) {
            return { success: false };
          }
          if (!hasProp(value, "orderId")) {
            return { success: false };
          }
          const parsed_orderId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["orderId"]);
          })();
          if (!parsed_orderId.success) {
            return { success: false };
          }
          if (!hasProp(value, "paymentState")) {
            return { success: false };
          }
          const parsed_paymentState = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["paymentState"]);
          })();
          if (!parsed_paymentState.success) {
            return { success: false };
          }
          if (!hasProp(value, "purchaseState")) {
            return { success: false };
          }
          const parsed_purchaseState = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["purchaseState"]);
          })();
          if (!parsed_purchaseState.success) {
            return { success: false };
          }
          if (!hasProp(value, "testPurchase")) {
            return { success: false };
          }
          const parsed_testPurchase = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["testPurchase"]);
          })();
          if (!parsed_testPurchase.success) {
            return { success: false };
          }
          if (!hasProp(value, "transactionId")) {
            return { success: false };
          }
          const parsed_transactionId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["transactionId"]);
          })();
          if (!parsed_transactionId.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              appStoreProductId: parsed_appStoreProductId.value,
              autoRenewing: parsed_autoRenewing.value,
              countryCode: parsed_countryCode.value,
              orderId: parsed_orderId.value,
              paymentState: parsed_paymentState.value,
              purchaseState: parsed_purchaseState.value,
              testPurchase: parsed_testPurchase.value,
              transactionId: parsed_transactionId.value,
            },
          };
        })(value);
      })(value["paymentInfoAndroid"]);
    })();
    if (!parsed_paymentInfoAndroid.success) {
      return { success: false };
    }
    if (!hasProp(value, "paymentInfoCC")) {
      return { success: false };
    }
    const parsed_paymentInfoCC = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CreditCardPaymentView | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                acqAuthorizationCode: string | null;
                authorizationCode: string | null;
                batchRefNumber: string | null;
                creditCardAlias: string | null;
                creditCardNumber: string;
                creditCardType: CreditCardType;
                datatransMerchantId: string | null;
                expirationMonth: number;
                expirationYear: number;
                gatewayProvider: string;
                nameOnCard: string | null;
                refNumber: string | null;
                transactionDateTime: DateTime<true>;
                transactionId: number;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "acqAuthorizationCode")) {
            return { success: false };
          }
          const parsed_acqAuthorizationCode = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["acqAuthorizationCode"]);
          })();
          if (!parsed_acqAuthorizationCode.success) {
            return { success: false };
          }
          if (!hasProp(value, "authorizationCode")) {
            return { success: false };
          }
          const parsed_authorizationCode = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["authorizationCode"]);
          })();
          if (!parsed_authorizationCode.success) {
            return { success: false };
          }
          if (!hasProp(value, "batchRefNumber")) {
            return { success: false };
          }
          const parsed_batchRefNumber = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["batchRefNumber"]);
          })();
          if (!parsed_batchRefNumber.success) {
            return { success: false };
          }
          if (!hasProp(value, "creditCardAlias")) {
            return { success: false };
          }
          const parsed_creditCardAlias = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["creditCardAlias"]);
          })();
          if (!parsed_creditCardAlias.success) {
            return { success: false };
          }
          if (!hasProp(value, "creditCardNumber")) {
            return { success: false };
          }
          const parsed_creditCardNumber = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["creditCardNumber"]);
          })();
          if (!parsed_creditCardNumber.success) {
            return { success: false };
          }
          if (!hasProp(value, "creditCardType")) {
            return { success: false };
          }
          const parsed_creditCardType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: CreditCardType } => {
              return ((value: unknown): { success: false } | { success: true; value: "VISA" | "MASTERCARD" | "AMEXCO" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "VISA":
                    return { success: true, value: "VISA" };
                  case "MASTERCARD":
                    return { success: true, value: "MASTERCARD" };
                  case "AMEXCO":
                    return { success: true, value: "AMEXCO" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["creditCardType"]);
          })();
          if (!parsed_creditCardType.success) {
            return { success: false };
          }
          if (!hasProp(value, "datatransMerchantId")) {
            return { success: false };
          }
          const parsed_datatransMerchantId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["datatransMerchantId"]);
          })();
          if (!parsed_datatransMerchantId.success) {
            return { success: false };
          }
          if (!hasProp(value, "expirationMonth")) {
            return { success: false };
          }
          const parsed_expirationMonth = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["expirationMonth"]);
          })();
          if (!parsed_expirationMonth.success) {
            return { success: false };
          }
          if (!hasProp(value, "expirationYear")) {
            return { success: false };
          }
          const parsed_expirationYear = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["expirationYear"]);
          })();
          if (!parsed_expirationYear.success) {
            return { success: false };
          }
          if (!hasProp(value, "gatewayProvider")) {
            return { success: false };
          }
          const parsed_gatewayProvider = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["gatewayProvider"]);
          })();
          if (!parsed_gatewayProvider.success) {
            return { success: false };
          }
          if (!hasProp(value, "nameOnCard")) {
            return { success: false };
          }
          const parsed_nameOnCard = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["nameOnCard"]);
          })();
          if (!parsed_nameOnCard.success) {
            return { success: false };
          }
          if (!hasProp(value, "refNumber")) {
            return { success: false };
          }
          const parsed_refNumber = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["refNumber"]);
          })();
          if (!parsed_refNumber.success) {
            return { success: false };
          }
          if (!hasProp(value, "transactionDateTime")) {
            return { success: false };
          }
          const parsed_transactionDateTime = (() => {
            return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
              if (typeof value !== "number") {
                return { success: false };
              }
              const parsed = DateTime.fromMillis(value);
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: parsed };
            })(value["transactionDateTime"]);
          })();
          if (!parsed_transactionDateTime.success) {
            return { success: false };
          }
          if (!hasProp(value, "transactionId")) {
            return { success: false };
          }
          const parsed_transactionId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["transactionId"]);
          })();
          if (!parsed_transactionId.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              acqAuthorizationCode: parsed_acqAuthorizationCode.value,
              authorizationCode: parsed_authorizationCode.value,
              batchRefNumber: parsed_batchRefNumber.value,
              creditCardAlias: parsed_creditCardAlias.value,
              creditCardNumber: parsed_creditCardNumber.value,
              creditCardType: parsed_creditCardType.value,
              datatransMerchantId: parsed_datatransMerchantId.value,
              expirationMonth: parsed_expirationMonth.value,
              expirationYear: parsed_expirationYear.value,
              gatewayProvider: parsed_gatewayProvider.value,
              nameOnCard: parsed_nameOnCard.value,
              refNumber: parsed_refNumber.value,
              transactionDateTime: parsed_transactionDateTime.value,
              transactionId: parsed_transactionId.value,
            },
          };
        })(value);
      })(value["paymentInfoCC"]);
    })();
    if (!parsed_paymentInfoCC.success) {
      return { success: false };
    }
    if (!hasProp(value, "paymentInfoIOS")) {
      return { success: false };
    }
    const parsed_paymentInfoIOS = (() => {
      return ((value: unknown): { success: false } | { success: true; value: IosPaymentView | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                appStoreProductId: string;
                autoRenewProductId: string | null;
                autoRenewing: boolean;
                expirationIntent: number;
                itunesTransactionId: string;
                originalItunesTransactionId: string | null;
                testPurchase: boolean;
                transactionId: number;
                webOrderLineItemId: bigint;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "appStoreProductId")) {
            return { success: false };
          }
          const parsed_appStoreProductId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["appStoreProductId"]);
          })();
          if (!parsed_appStoreProductId.success) {
            return { success: false };
          }
          if (!hasProp(value, "autoRenewProductId")) {
            return { success: false };
          }
          const parsed_autoRenewProductId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["autoRenewProductId"]);
          })();
          if (!parsed_autoRenewProductId.success) {
            return { success: false };
          }
          if (!hasProp(value, "autoRenewing")) {
            return { success: false };
          }
          const parsed_autoRenewing = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["autoRenewing"]);
          })();
          if (!parsed_autoRenewing.success) {
            return { success: false };
          }
          if (!hasProp(value, "expirationIntent")) {
            return { success: false };
          }
          const parsed_expirationIntent = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["expirationIntent"]);
          })();
          if (!parsed_expirationIntent.success) {
            return { success: false };
          }
          if (!hasProp(value, "itunesTransactionId")) {
            return { success: false };
          }
          const parsed_itunesTransactionId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["itunesTransactionId"]);
          })();
          if (!parsed_itunesTransactionId.success) {
            return { success: false };
          }
          if (!hasProp(value, "originalItunesTransactionId")) {
            return { success: false };
          }
          const parsed_originalItunesTransactionId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["originalItunesTransactionId"]);
          })();
          if (!parsed_originalItunesTransactionId.success) {
            return { success: false };
          }
          if (!hasProp(value, "testPurchase")) {
            return { success: false };
          }
          const parsed_testPurchase = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["testPurchase"]);
          })();
          if (!parsed_testPurchase.success) {
            return { success: false };
          }
          if (!hasProp(value, "transactionId")) {
            return { success: false };
          }
          const parsed_transactionId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["transactionId"]);
          })();
          if (!parsed_transactionId.success) {
            return { success: false };
          }
          if (!hasProp(value, "webOrderLineItemId")) {
            return { success: false };
          }
          const parsed_webOrderLineItemId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
              if (typeof value === "number") {
                return { success: true, value: BigInt(value) };
              }
              if (typeof value === "bigint") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["webOrderLineItemId"]);
          })();
          if (!parsed_webOrderLineItemId.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              appStoreProductId: parsed_appStoreProductId.value,
              autoRenewProductId: parsed_autoRenewProductId.value,
              autoRenewing: parsed_autoRenewing.value,
              expirationIntent: parsed_expirationIntent.value,
              itunesTransactionId: parsed_itunesTransactionId.value,
              originalItunesTransactionId: parsed_originalItunesTransactionId.value,
              testPurchase: parsed_testPurchase.value,
              transactionId: parsed_transactionId.value,
              webOrderLineItemId: parsed_webOrderLineItemId.value,
            },
          };
        })(value);
      })(value["paymentInfoIOS"]);
    })();
    if (!parsed_paymentInfoIOS.success) {
      return { success: false };
    }
    if (!hasProp(value, "paymentInfoInvoice")) {
      return { success: false };
    }
    const parsed_paymentInfoInvoice = (() => {
      return ((value: unknown): { success: false } | { success: true; value: InvoicePaymentView | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                paidAt: DateTime<true> | null;
                reference: string | null;
                transactionId: number;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "paidAt")) {
            return { success: false };
          }
          const parsed_paidAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "number") {
                return { success: false };
              }
              const parsed = DateTime.fromMillis(value);
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: parsed };
            })(value["paidAt"]);
          })();
          if (!parsed_paidAt.success) {
            return { success: false };
          }
          if (!hasProp(value, "reference")) {
            return { success: false };
          }
          const parsed_reference = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["reference"]);
          })();
          if (!parsed_reference.success) {
            return { success: false };
          }
          if (!hasProp(value, "transactionId")) {
            return { success: false };
          }
          const parsed_transactionId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["transactionId"]);
          })();
          if (!parsed_transactionId.success) {
            return { success: false };
          }
          return { success: true, value: { paidAt: parsed_paidAt.value, reference: parsed_reference.value, transactionId: parsed_transactionId.value } };
        })(value);
      })(value["paymentInfoInvoice"]);
    })();
    if (!parsed_paymentInfoInvoice.success) {
      return { success: false };
    }
    if (!hasProp(value, "paymentInfoLSV")) {
      return { success: false };
    }
    const parsed_paymentInfoLSV = (() => {
      return ((value: unknown): { success: false } | { success: true; value: LsvPaymentView | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                accountNumber: string | null;
                acqAuthorizationCode: string | null;
                authorizationCode: string | null;
                bankIban: string | null;
                bankName: string | null;
                bankTown: string | null;
                clearingNumber: string | null;
                firstName: string;
                lastName: string;
                merchantId: string | null;
                refNumber: string | null;
                transactionId: number;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "accountNumber")) {
            return { success: false };
          }
          const parsed_accountNumber = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["accountNumber"]);
          })();
          if (!parsed_accountNumber.success) {
            return { success: false };
          }
          if (!hasProp(value, "acqAuthorizationCode")) {
            return { success: false };
          }
          const parsed_acqAuthorizationCode = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["acqAuthorizationCode"]);
          })();
          if (!parsed_acqAuthorizationCode.success) {
            return { success: false };
          }
          if (!hasProp(value, "authorizationCode")) {
            return { success: false };
          }
          const parsed_authorizationCode = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["authorizationCode"]);
          })();
          if (!parsed_authorizationCode.success) {
            return { success: false };
          }
          if (!hasProp(value, "bankIban")) {
            return { success: false };
          }
          const parsed_bankIban = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["bankIban"]);
          })();
          if (!parsed_bankIban.success) {
            return { success: false };
          }
          if (!hasProp(value, "bankName")) {
            return { success: false };
          }
          const parsed_bankName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["bankName"]);
          })();
          if (!parsed_bankName.success) {
            return { success: false };
          }
          if (!hasProp(value, "bankTown")) {
            return { success: false };
          }
          const parsed_bankTown = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["bankTown"]);
          })();
          if (!parsed_bankTown.success) {
            return { success: false };
          }
          if (!hasProp(value, "clearingNumber")) {
            return { success: false };
          }
          const parsed_clearingNumber = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["clearingNumber"]);
          })();
          if (!parsed_clearingNumber.success) {
            return { success: false };
          }
          if (!hasProp(value, "firstName")) {
            return { success: false };
          }
          const parsed_firstName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["firstName"]);
          })();
          if (!parsed_firstName.success) {
            return { success: false };
          }
          if (!hasProp(value, "lastName")) {
            return { success: false };
          }
          const parsed_lastName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lastName"]);
          })();
          if (!parsed_lastName.success) {
            return { success: false };
          }
          if (!hasProp(value, "merchantId")) {
            return { success: false };
          }
          const parsed_merchantId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["merchantId"]);
          })();
          if (!parsed_merchantId.success) {
            return { success: false };
          }
          if (!hasProp(value, "refNumber")) {
            return { success: false };
          }
          const parsed_refNumber = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["refNumber"]);
          })();
          if (!parsed_refNumber.success) {
            return { success: false };
          }
          if (!hasProp(value, "transactionId")) {
            return { success: false };
          }
          const parsed_transactionId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["transactionId"]);
          })();
          if (!parsed_transactionId.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              accountNumber: parsed_accountNumber.value,
              acqAuthorizationCode: parsed_acqAuthorizationCode.value,
              authorizationCode: parsed_authorizationCode.value,
              bankIban: parsed_bankIban.value,
              bankName: parsed_bankName.value,
              bankTown: parsed_bankTown.value,
              clearingNumber: parsed_clearingNumber.value,
              firstName: parsed_firstName.value,
              lastName: parsed_lastName.value,
              merchantId: parsed_merchantId.value,
              refNumber: parsed_refNumber.value,
              transactionId: parsed_transactionId.value,
            },
          };
        })(value);
      })(value["paymentInfoLSV"]);
    })();
    if (!parsed_paymentInfoLSV.success) {
      return { success: false };
    }
    if (!hasProp(value, "paymentInfoMiles")) {
      return { success: false };
    }
    const parsed_paymentInfoMiles = (() => {
      return ((value: unknown): { success: false } | { success: true; value: MilesPaymentView | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                cardNumber: string | null;
                milesTransactionId: number | null;
                pin: string | null;
                transactionId: number;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "cardNumber")) {
            return { success: false };
          }
          const parsed_cardNumber = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["cardNumber"]);
          })();
          if (!parsed_cardNumber.success) {
            return { success: false };
          }
          if (!hasProp(value, "milesTransactionId")) {
            return { success: false };
          }
          const parsed_milesTransactionId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["milesTransactionId"]);
          })();
          if (!parsed_milesTransactionId.success) {
            return { success: false };
          }
          if (!hasProp(value, "pin")) {
            return { success: false };
          }
          const parsed_pin = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["pin"]);
          })();
          if (!parsed_pin.success) {
            return { success: false };
          }
          if (!hasProp(value, "transactionId")) {
            return { success: false };
          }
          const parsed_transactionId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["transactionId"]);
          })();
          if (!parsed_transactionId.success) {
            return { success: false };
          }
          return { success: true, value: { cardNumber: parsed_cardNumber.value, milesTransactionId: parsed_milesTransactionId.value, pin: parsed_pin.value, transactionId: parsed_transactionId.value } };
        })(value);
      })(value["paymentInfoMiles"]);
    })();
    if (!parsed_paymentInfoMiles.success) {
      return { success: false };
    }
    if (!hasProp(value, "paymentInfoPaypal")) {
      return { success: false };
    }
    const parsed_paymentInfoPaypal = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PayPalPaymentView | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                acqAuthorizationCode: string | null;
                authorizationCode: string | null;
                merchantId: string | null;
                paypalBillingAgreementId: string | null;
                refNumber: string | null;
                transactionId: number;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "acqAuthorizationCode")) {
            return { success: false };
          }
          const parsed_acqAuthorizationCode = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["acqAuthorizationCode"]);
          })();
          if (!parsed_acqAuthorizationCode.success) {
            return { success: false };
          }
          if (!hasProp(value, "authorizationCode")) {
            return { success: false };
          }
          const parsed_authorizationCode = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["authorizationCode"]);
          })();
          if (!parsed_authorizationCode.success) {
            return { success: false };
          }
          if (!hasProp(value, "merchantId")) {
            return { success: false };
          }
          const parsed_merchantId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["merchantId"]);
          })();
          if (!parsed_merchantId.success) {
            return { success: false };
          }
          if (!hasProp(value, "paypalBillingAgreementId")) {
            return { success: false };
          }
          const parsed_paypalBillingAgreementId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["paypalBillingAgreementId"]);
          })();
          if (!parsed_paypalBillingAgreementId.success) {
            return { success: false };
          }
          if (!hasProp(value, "refNumber")) {
            return { success: false };
          }
          const parsed_refNumber = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["refNumber"]);
          })();
          if (!parsed_refNumber.success) {
            return { success: false };
          }
          if (!hasProp(value, "transactionId")) {
            return { success: false };
          }
          const parsed_transactionId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["transactionId"]);
          })();
          if (!parsed_transactionId.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              acqAuthorizationCode: parsed_acqAuthorizationCode.value,
              authorizationCode: parsed_authorizationCode.value,
              merchantId: parsed_merchantId.value,
              paypalBillingAgreementId: parsed_paypalBillingAgreementId.value,
              refNumber: parsed_refNumber.value,
              transactionId: parsed_transactionId.value,
            },
          };
        })(value);
      })(value["paymentInfoPaypal"]);
    })();
    if (!parsed_paymentInfoPaypal.success) {
      return { success: false };
    }
    if (!hasProp(value, "paymentReceiptUri")) {
      return { success: false };
    }
    const parsed_paymentReceiptUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["paymentReceiptUri"]);
    })();
    if (!parsed_paymentReceiptUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "paymentType")) {
      return { success: false };
    }
    const parsed_paymentType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PaymentType } => {
        return ((value: unknown): { success: false } | { success: true; value: "CC" | "INVOICE" | "PAYPAL" | "LSV" | "MILES" | "ALIPAY" | "IOS" | "ANDROID" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "CC":
              return { success: true, value: "CC" };
            case "INVOICE":
              return { success: true, value: "INVOICE" };
            case "PAYPAL":
              return { success: true, value: "PAYPAL" };
            case "LSV":
              return { success: true, value: "LSV" };
            case "MILES":
              return { success: true, value: "MILES" };
            case "ALIPAY":
              return { success: true, value: "ALIPAY" };
            case "IOS":
              return { success: true, value: "IOS" };
            case "ANDROID":
              return { success: true, value: "ANDROID" };
            default:
              return { success: false };
          }
        })(value);
      })(value["paymentType"]);
    })();
    if (!parsed_paymentType.success) {
      return { success: false };
    }
    if (!hasProp(value, "quantity")) {
      return { success: false };
    }
    const parsed_quantity = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["quantity"]);
    })();
    if (!parsed_quantity.success) {
      return { success: false };
    }
    if (!hasProp(value, "subscriptionId")) {
      return { success: false };
    }
    const parsed_subscriptionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["subscriptionId"]);
    })();
    if (!parsed_subscriptionId.success) {
      return { success: false };
    }
    if (!hasProp(value, "transactionId")) {
      return { success: false };
    }
    const parsed_transactionId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["transactionId"]);
    })();
    if (!parsed_transactionId.success) {
      return { success: false };
    }
    if (!hasProp(value, "transactionType")) {
      return { success: false };
    }
    const parsed_transactionType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "DEBIT" | "CREDIT" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "DEBIT":
            return { success: true, value: "DEBIT" };
          case "CREDIT":
            return { success: true, value: "CREDIT" };
          default:
            return { success: false };
        }
      })(value["transactionType"]);
    })();
    if (!parsed_transactionType.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        amount: parsed_amount.value,
        comment: parsed_comment.value,
        customerId: parsed_customerId.value,
        generatedAt: parsed_generatedAt.value,
        paymentInfoAlipay: parsed_paymentInfoAlipay.value,
        paymentInfoAndroid: parsed_paymentInfoAndroid.value,
        paymentInfoCC: parsed_paymentInfoCC.value,
        paymentInfoIOS: parsed_paymentInfoIOS.value,
        paymentInfoInvoice: parsed_paymentInfoInvoice.value,
        paymentInfoLSV: parsed_paymentInfoLSV.value,
        paymentInfoMiles: parsed_paymentInfoMiles.value,
        paymentInfoPaypal: parsed_paymentInfoPaypal.value,
        paymentReceiptUri: parsed_paymentReceiptUri.value,
        paymentType: parsed_paymentType.value,
        quantity: parsed_quantity.value,
        subscriptionId: parsed_subscriptionId.value,
        transactionId: parsed_transactionId.value,
        transactionType: parsed_transactionType.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SummaryActivityReportView
export function serialize_SummaryActivityReportView(obj: SummaryActivityReportView): string {
  const serialized = ((value: {
    customer: CustomerReportView | null;
    downloadCountry: string | null;
    downloadFormat: string | null;
    primarySkills: string[];
    skills: string[];
    summary: SummaryMetadata;
    timeStamp: DateTime<true>;
  }): unknown => {
    return {
      customer: ((
        value: {
          country: string | null;
          customerId: number;
          email: string | null;
          endDate: { year: number; month: number; day: number };
          externalId: string | null;
          extraField1: string | null;
          extraField2: string | null;
          extraField3: string | null;
          firstName: string | null;
          language: Language;
          lastName: string | null;
          signupDate: { year: number; month: number; day: number };
          status: string;
          title: number | null;
          town: string | null;
          userGroups: string[];
        } | null,
      ): unknown => {
        if (value === null) {
          return null;
        }
        return {
          country: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["country"]),
          customerId: ((value: number): unknown => {
            return value;
          })(value["customerId"]),
          email: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["email"]),
          endDate: ((value: { year: number; month: number; day: number }): unknown => {
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["endDate"]),
          externalId: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["externalId"]),
          extraField1: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["extraField1"]),
          extraField2: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["extraField2"]),
          extraField3: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["extraField3"]),
          firstName: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["firstName"]),
          language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
            return value;
          })(value["language"]),
          lastName: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["lastName"]),
          signupDate: ((value: { year: number; month: number; day: number }): unknown => {
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["signupDate"]),
          status: ((value: string): unknown => {
            return value;
          })(value["status"]),
          title: ((value: number | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["title"]),
          town: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["town"]),
          userGroups: ((value: string[]): unknown => {
            return value.map((value: string): unknown => {
              return value;
            });
          })(value["userGroups"]),
        };
      })(value["customer"]),
      downloadCountry: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["downloadCountry"]),
      downloadFormat: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["downloadFormat"]),
      primarySkills: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["primarySkills"]),
      skills: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["skills"]),
      summary: ((value: {
        activatedAt: DateTime<true> | null;
        activationTime: DateTime<true> | null;
        active: boolean;
        audioActivatedAt: DateTime<true> | null;
        audioActive: boolean;
        audioDuration: number;
        authorInfo: string | null;
        authors: {
          authorId: number;
          firstName: string | null;
          gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
          lastName: string | null;
          middleName: string | null;
        }[];
        backgroundColor: string | null;
        bestseller: boolean;
        businessClassic: boolean;
        canonicalName: string;
        contentItemId: bigint;
        contentItemType: ContentItemType;
        countriesExcluded: Country[];
        countriesIncluded: Country[];
        coverModifiedAt: DateTime<true> | null;
        coverUri: string;
        dataId: number;
        descriptionHtml: string | null;
        documentType: SummaryDocumentType;
        duration: number;
        imageBorder: boolean;
        language: Language;
        modifiedAt: DateTime<true> | null;
        originalTitle: string | null;
        previewAvailable: boolean;
        publicationDate: string | null;
        publisherInfo: string | null;
        publisherName: string | null;
        rating: number | null;
        sourceType: SummarySourceType;
        subtitle: string | null;
        summaryPages: number;
        summaryWords: number;
        tags: Tag[];
        teaser: string | null;
        title: string | null;
      }): unknown => {
        return {
          activatedAt: ((value: DateTime<true> | null): unknown => {
            if (value === null) {
              return null;
            }
            return value.toMillis();
          })(value["activatedAt"]),
          activationTime: ((value: DateTime<true> | null): unknown => {
            if (value === null) {
              return null;
            }
            return value.toMillis();
          })(value["activationTime"]),
          active: ((value: boolean): unknown => {
            return value;
          })(value["active"]),
          audioActivatedAt: ((value: DateTime<true> | null): unknown => {
            if (value === null) {
              return null;
            }
            return value.toMillis();
          })(value["audioActivatedAt"]),
          audioActive: ((value: boolean): unknown => {
            return value;
          })(value["audioActive"]),
          audioDuration: ((value: number): unknown => {
            return value;
          })(value["audioDuration"]),
          authorInfo: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["authorInfo"]),
          authors: ((
            value: {
              authorId: number;
              firstName: string | null;
              gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
              lastName: string | null;
              middleName: string | null;
            }[],
          ): unknown => {
            return value.map((value: { authorId: number; firstName: string | null; gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null; lastName: string | null; middleName: string | null }): unknown => {
              return {
                authorId: ((value: number): unknown => {
                  return value;
                })(value["authorId"]),
                firstName: ((value: string | null): unknown => {
                  if (value === null) {
                    return null;
                  }
                  return value;
                })(value["firstName"]),
                gender: ((value: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null): unknown => {
                  if (value === null) {
                    return null;
                  }
                  return value;
                })(value["gender"]),
                lastName: ((value: string | null): unknown => {
                  if (value === null) {
                    return null;
                  }
                  return value;
                })(value["lastName"]),
                middleName: ((value: string | null): unknown => {
                  if (value === null) {
                    return null;
                  }
                  return value;
                })(value["middleName"]),
              };
            });
          })(value["authors"]),
          backgroundColor: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["backgroundColor"]),
          bestseller: ((value: boolean): unknown => {
            return value;
          })(value["bestseller"]),
          businessClassic: ((value: boolean): unknown => {
            return value;
          })(value["businessClassic"]),
          canonicalName: ((value: string): unknown => {
            return value;
          })(value["canonicalName"]),
          contentItemId: ((value: bigint): unknown => {
            return value;
          })(value["contentItemId"]),
          contentItemType: ((value: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE" | "MIAB"): unknown => {
            return value;
          })(value["contentItemType"]),
          countriesExcluded: ((value: Country[]): unknown => {
            return value.map(
              (value: {
                countryCode: string;
                currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                names: {
                  de: string;
                  en: string;
                  es: string;
                  fr: string;
                  pt: string;
                  ru: string;
                  zh: string;
                };
                phonePrefix: string;
                region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
              }): unknown => {
                return {
                  countryCode: ((value: string): unknown => {
                    return value;
                  })(value["countryCode"]),
                  currency: ((value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY"): unknown => {
                    return value;
                  })(value["currency"]),
                  names: ((value: { de: string; en: string; es: string; fr: string; pt: string; ru: string; zh: string }): unknown => {
                    return {
                      de: ((value: string): unknown => {
                        return value;
                      })(value["de"]),
                      en: ((value: string): unknown => {
                        return value;
                      })(value["en"]),
                      es: ((value: string): unknown => {
                        return value;
                      })(value["es"]),
                      fr: ((value: string): unknown => {
                        return value;
                      })(value["fr"]),
                      pt: ((value: string): unknown => {
                        return value;
                      })(value["pt"]),
                      ru: ((value: string): unknown => {
                        return value;
                      })(value["ru"]),
                      zh: ((value: string): unknown => {
                        return value;
                      })(value["zh"]),
                    };
                  })(value["names"]),
                  phonePrefix: ((value: string): unknown => {
                    return value;
                  })(value["phonePrefix"]),
                  region: ((value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA"): unknown => {
                    return value;
                  })(value["region"]),
                };
              },
            );
          })(value["countriesExcluded"]),
          countriesIncluded: ((value: Country[]): unknown => {
            return value.map(
              (value: {
                countryCode: string;
                currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                names: {
                  de: string;
                  en: string;
                  es: string;
                  fr: string;
                  pt: string;
                  ru: string;
                  zh: string;
                };
                phonePrefix: string;
                region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
              }): unknown => {
                return {
                  countryCode: ((value: string): unknown => {
                    return value;
                  })(value["countryCode"]),
                  currency: ((value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY"): unknown => {
                    return value;
                  })(value["currency"]),
                  names: ((value: { de: string; en: string; es: string; fr: string; pt: string; ru: string; zh: string }): unknown => {
                    return {
                      de: ((value: string): unknown => {
                        return value;
                      })(value["de"]),
                      en: ((value: string): unknown => {
                        return value;
                      })(value["en"]),
                      es: ((value: string): unknown => {
                        return value;
                      })(value["es"]),
                      fr: ((value: string): unknown => {
                        return value;
                      })(value["fr"]),
                      pt: ((value: string): unknown => {
                        return value;
                      })(value["pt"]),
                      ru: ((value: string): unknown => {
                        return value;
                      })(value["ru"]),
                      zh: ((value: string): unknown => {
                        return value;
                      })(value["zh"]),
                    };
                  })(value["names"]),
                  phonePrefix: ((value: string): unknown => {
                    return value;
                  })(value["phonePrefix"]),
                  region: ((value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA"): unknown => {
                    return value;
                  })(value["region"]),
                };
              },
            );
          })(value["countriesIncluded"]),
          coverModifiedAt: ((value: DateTime<true> | null): unknown => {
            if (value === null) {
              return null;
            }
            return value.toMillis();
          })(value["coverModifiedAt"]),
          coverUri: ((value: string): unknown => {
            return value;
          })(value["coverUri"]),
          dataId: ((value: number): unknown => {
            return value;
          })(value["dataId"]),
          descriptionHtml: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["descriptionHtml"]),
          documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
            return value;
          })(value["documentType"]),
          duration: ((value: number): unknown => {
            return value;
          })(value["duration"]),
          imageBorder: ((value: boolean): unknown => {
            return value;
          })(value["imageBorder"]),
          language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
            return value;
          })(value["language"]),
          modifiedAt: ((value: DateTime<true> | null): unknown => {
            if (value === null) {
              return null;
            }
            return value.toMillis();
          })(value["modifiedAt"]),
          originalTitle: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["originalTitle"]),
          previewAvailable: ((value: boolean): unknown => {
            return value;
          })(value["previewAvailable"]),
          publicationDate: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["publicationDate"]),
          publisherInfo: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["publisherInfo"]),
          publisherName: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["publisherName"]),
          rating: ((value: number | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["rating"]),
          sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
            return value;
          })(value["sourceType"]),
          subtitle: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["subtitle"]),
          summaryPages: ((value: number): unknown => {
            return value;
          })(value["summaryPages"]),
          summaryWords: ((value: number): unknown => {
            return value;
          })(value["summaryWords"]),
          tags: ((value: Tag[]): unknown => {
            return value.map(
              (
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                  | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
              ): unknown => {
                switch (value.discriminator) {
                  case "dynamicString": {
                    return {
                      discriminator: "dynamicString",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicString"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicBoolean": {
                    return {
                      discriminator: "dynamicBoolean",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicBoolean"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: boolean): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicNumber": {
                    return {
                      discriminator: "dynamicNumber",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicNumber"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: number): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "dynamicVoid": {
                    return {
                      discriminator: "dynamicVoid",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                        return {
                          discriminator: ((value: "dynamicVoid"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: string): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                    };
                  }
                  case "channel": {
                    return {
                      discriminator: "channel",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                        return {
                          discriminator: ((value: "channel"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "channel"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "primary-skill": {
                    return {
                      discriminator: "primary-skill",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                        return {
                          discriminator: ((value: "primary-skill"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "primary-skill"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "skill": {
                    return {
                      discriminator: "skill",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                        return {
                          discriminator: ((value: "skill"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "skill"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "key-quality": {
                    return {
                      discriminator: "key-quality",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                        return {
                          discriminator: ((value: "key-quality"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "key-quality"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                  case "classification": {
                    return {
                      discriminator: "classification",
                      exclude: ((value: boolean): unknown => {
                        return value;
                      })(value["exclude"]),
                      source: ((value: "USER" | "AI"): unknown => {
                        return value;
                      })(value["source"]),
                      tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                        return {
                          discriminator: ((value: "classification"): unknown => {
                            return value;
                          })(value["discriminator"]),
                          id: ((value: bigint): unknown => {
                            return value;
                          })(value["id"]),
                          kind: ((value: "classification"): unknown => {
                            return value;
                          })(value["kind"]),
                        };
                      })(value["tagKind"]),
                      value: ((value: string): unknown => {
                        return value;
                      })(value["value"]),
                    };
                  }
                }
              },
            );
          })(value["tags"]),
          teaser: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["teaser"]),
          title: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["title"]),
        };
      })(value["summary"]),
      timeStamp: ((value: DateTime<true>): unknown => {
        return value.toISO();
      })(value["timeStamp"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SummaryActivityReportView(str: string): SummaryActivityReportView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          customer: CustomerReportView | null;
          downloadCountry: string | null;
          downloadFormat: string | null;
          primarySkills: string[];
          skills: string[];
          summary: SummaryMetadata;
          timeStamp: DateTime<true>;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "customer")) {
      return { success: false };
    }
    const parsed_customer = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CustomerReportView | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                country: string | null;
                customerId: number;
                email: string | null;
                endDate: { year: number; month: number; day: number };
                externalId: string | null;
                extraField1: string | null;
                extraField2: string | null;
                extraField3: string | null;
                firstName: string | null;
                language: Language;
                lastName: string | null;
                signupDate: { year: number; month: number; day: number };
                status: string;
                title: number | null;
                town: string | null;
                userGroups: string[];
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "country")) {
            return { success: false };
          }
          const parsed_country = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["country"]);
          })();
          if (!parsed_country.success) {
            return { success: false };
          }
          if (!hasProp(value, "customerId")) {
            return { success: false };
          }
          const parsed_customerId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["customerId"]);
          })();
          if (!parsed_customerId.success) {
            return { success: false };
          }
          if (!hasProp(value, "email")) {
            return { success: false };
          }
          const parsed_email = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["email"]);
          })();
          if (!parsed_email.success) {
            return { success: false };
          }
          if (!hasProp(value, "endDate")) {
            return { success: false };
          }
          const parsed_endDate = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["endDate"]);
          })();
          if (!parsed_endDate.success) {
            return { success: false };
          }
          if (!hasProp(value, "externalId")) {
            return { success: false };
          }
          const parsed_externalId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["externalId"]);
          })();
          if (!parsed_externalId.success) {
            return { success: false };
          }
          if (!hasProp(value, "extraField1")) {
            return { success: false };
          }
          const parsed_extraField1 = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["extraField1"]);
          })();
          if (!parsed_extraField1.success) {
            return { success: false };
          }
          if (!hasProp(value, "extraField2")) {
            return { success: false };
          }
          const parsed_extraField2 = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["extraField2"]);
          })();
          if (!parsed_extraField2.success) {
            return { success: false };
          }
          if (!hasProp(value, "extraField3")) {
            return { success: false };
          }
          const parsed_extraField3 = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["extraField3"]);
          })();
          if (!parsed_extraField3.success) {
            return { success: false };
          }
          if (!hasProp(value, "firstName")) {
            return { success: false };
          }
          const parsed_firstName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["firstName"]);
          })();
          if (!parsed_firstName.success) {
            return { success: false };
          }
          if (!hasProp(value, "language")) {
            return { success: false };
          }
          const parsed_language = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Language } => {
              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "en":
                    return { success: true, value: "en" };
                  case "de":
                    return { success: true, value: "de" };
                  case "es":
                    return { success: true, value: "es" };
                  case "ru":
                    return { success: true, value: "ru" };
                  case "zh":
                    return { success: true, value: "zh" };
                  case "pt":
                    return { success: true, value: "pt" };
                  case "fr":
                    return { success: true, value: "fr" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["language"]);
          })();
          if (!parsed_language.success) {
            return { success: false };
          }
          if (!hasProp(value, "lastName")) {
            return { success: false };
          }
          const parsed_lastName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lastName"]);
          })();
          if (!parsed_lastName.success) {
            return { success: false };
          }
          if (!hasProp(value, "signupDate")) {
            return { success: false };
          }
          const parsed_signupDate = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["signupDate"]);
          })();
          if (!parsed_signupDate.success) {
            return { success: false };
          }
          if (!hasProp(value, "status")) {
            return { success: false };
          }
          const parsed_status = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["status"]);
          })();
          if (!parsed_status.success) {
            return { success: false };
          }
          if (!hasProp(value, "title")) {
            return { success: false };
          }
          const parsed_title = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["title"]);
          })();
          if (!parsed_title.success) {
            return { success: false };
          }
          if (!hasProp(value, "town")) {
            return { success: false };
          }
          const parsed_town = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["town"]);
          })();
          if (!parsed_town.success) {
            return { success: false };
          }
          if (!hasProp(value, "userGroups")) {
            return { success: false };
          }
          const parsed_userGroups = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["userGroups"]);
          })();
          if (!parsed_userGroups.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              country: parsed_country.value,
              customerId: parsed_customerId.value,
              email: parsed_email.value,
              endDate: parsed_endDate.value,
              externalId: parsed_externalId.value,
              extraField1: parsed_extraField1.value,
              extraField2: parsed_extraField2.value,
              extraField3: parsed_extraField3.value,
              firstName: parsed_firstName.value,
              language: parsed_language.value,
              lastName: parsed_lastName.value,
              signupDate: parsed_signupDate.value,
              status: parsed_status.value,
              title: parsed_title.value,
              town: parsed_town.value,
              userGroups: parsed_userGroups.value,
            },
          };
        })(value);
      })(value["customer"]);
    })();
    if (!parsed_customer.success) {
      return { success: false };
    }
    if (!hasProp(value, "downloadCountry")) {
      return { success: false };
    }
    const parsed_downloadCountry = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["downloadCountry"]);
    })();
    if (!parsed_downloadCountry.success) {
      return { success: false };
    }
    if (!hasProp(value, "downloadFormat")) {
      return { success: false };
    }
    const parsed_downloadFormat = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["downloadFormat"]);
    })();
    if (!parsed_downloadFormat.success) {
      return { success: false };
    }
    if (!hasProp(value, "primarySkills")) {
      return { success: false };
    }
    const parsed_primarySkills = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["primarySkills"]);
    })();
    if (!parsed_primarySkills.success) {
      return { success: false };
    }
    if (!hasProp(value, "skills")) {
      return { success: false };
    }
    const parsed_skills = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["skills"]);
    })();
    if (!parsed_skills.success) {
      return { success: false };
    }
    if (!hasProp(value, "summary")) {
      return { success: false };
    }
    const parsed_summary = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SummaryMetadata } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                activatedAt: DateTime<true> | null;
                activationTime: DateTime<true> | null;
                active: boolean;
                audioActivatedAt: DateTime<true> | null;
                audioActive: boolean;
                audioDuration: number;
                authorInfo: string | null;
                authors: {
                  authorId: number;
                  firstName: string | null;
                  gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                  lastName: string | null;
                  middleName: string | null;
                }[];
                backgroundColor: string | null;
                bestseller: boolean;
                businessClassic: boolean;
                canonicalName: string;
                contentItemId: bigint;
                contentItemType: ContentItemType;
                countriesExcluded: Country[];
                countriesIncluded: Country[];
                coverModifiedAt: DateTime<true> | null;
                coverUri: string;
                dataId: number;
                descriptionHtml: string | null;
                documentType: SummaryDocumentType;
                duration: number;
                imageBorder: boolean;
                language: Language;
                modifiedAt: DateTime<true> | null;
                originalTitle: string | null;
                previewAvailable: boolean;
                publicationDate: string | null;
                publisherInfo: string | null;
                publisherName: string | null;
                rating: number | null;
                sourceType: SummarySourceType;
                subtitle: string | null;
                summaryPages: number;
                summaryWords: number;
                tags: Tag[];
                teaser: string | null;
                title: string | null;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "activatedAt")) {
            return { success: false };
          }
          const parsed_activatedAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "number") {
                return { success: false };
              }
              const parsed = DateTime.fromMillis(value);
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: parsed };
            })(value["activatedAt"]);
          })();
          if (!parsed_activatedAt.success) {
            return { success: false };
          }
          if (!hasProp(value, "activationTime")) {
            return { success: false };
          }
          const parsed_activationTime = (() => {
            return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "number") {
                return { success: false };
              }
              const parsed = DateTime.fromMillis(value);
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: parsed };
            })(value["activationTime"]);
          })();
          if (!parsed_activationTime.success) {
            return { success: false };
          }
          if (!hasProp(value, "active")) {
            return { success: false };
          }
          const parsed_active = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["active"]);
          })();
          if (!parsed_active.success) {
            return { success: false };
          }
          if (!hasProp(value, "audioActivatedAt")) {
            return { success: false };
          }
          const parsed_audioActivatedAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "number") {
                return { success: false };
              }
              const parsed = DateTime.fromMillis(value);
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: parsed };
            })(value["audioActivatedAt"]);
          })();
          if (!parsed_audioActivatedAt.success) {
            return { success: false };
          }
          if (!hasProp(value, "audioActive")) {
            return { success: false };
          }
          const parsed_audioActive = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["audioActive"]);
          })();
          if (!parsed_audioActive.success) {
            return { success: false };
          }
          if (!hasProp(value, "audioDuration")) {
            return { success: false };
          }
          const parsed_audioDuration = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["audioDuration"]);
          })();
          if (!parsed_audioDuration.success) {
            return { success: false };
          }
          if (!hasProp(value, "authorInfo")) {
            return { success: false };
          }
          const parsed_authorInfo = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["authorInfo"]);
          })();
          if (!parsed_authorInfo.success) {
            return { success: false };
          }
          if (!hasProp(value, "authors")) {
            return { success: false };
          }
          const parsed_authors = (() => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value: {
                    authorId: number;
                    firstName: string | null;
                    gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                    lastName: string | null;
                    middleName: string | null;
                  }[];
                } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map(
                (
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        authorId: number;
                        firstName: string | null;
                        gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                        lastName: string | null;
                        middleName: string | null;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "authorId")) {
                    return { success: false };
                  }
                  const parsed_authorId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["authorId"]);
                  })();
                  if (!parsed_authorId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "firstName")) {
                    return { success: false };
                  }
                  const parsed_firstName = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["firstName"]);
                  })();
                  if (!parsed_firstName.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "gender")) {
                    return { success: false };
                  }
                  const parsed_gender = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      switch (value) {
                        case "MALE":
                          return { success: true, value: "MALE" };
                        case "FEMALE":
                          return { success: true, value: "FEMALE" };
                        case "CUSTOM":
                          return { success: true, value: "CUSTOM" };
                        case "UNKNOWN":
                          return { success: true, value: "UNKNOWN" };
                        default:
                          return { success: false };
                      }
                    })(value["gender"]);
                  })();
                  if (!parsed_gender.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "lastName")) {
                    return { success: false };
                  }
                  const parsed_lastName = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["lastName"]);
                  })();
                  if (!parsed_lastName.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "middleName")) {
                    return { success: false };
                  }
                  const parsed_middleName = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["middleName"]);
                  })();
                  if (!parsed_middleName.success) {
                    return { success: false };
                  }
                  return { success: true, value: { authorId: parsed_authorId.value, firstName: parsed_firstName.value, gender: parsed_gender.value, lastName: parsed_lastName.value, middleName: parsed_middleName.value } };
                },
              );
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["authors"]);
          })();
          if (!parsed_authors.success) {
            return { success: false };
          }
          if (!hasProp(value, "backgroundColor")) {
            return { success: false };
          }
          const parsed_backgroundColor = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["backgroundColor"]);
          })();
          if (!parsed_backgroundColor.success) {
            return { success: false };
          }
          if (!hasProp(value, "bestseller")) {
            return { success: false };
          }
          const parsed_bestseller = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["bestseller"]);
          })();
          if (!parsed_bestseller.success) {
            return { success: false };
          }
          if (!hasProp(value, "businessClassic")) {
            return { success: false };
          }
          const parsed_businessClassic = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["businessClassic"]);
          })();
          if (!parsed_businessClassic.success) {
            return { success: false };
          }
          if (!hasProp(value, "canonicalName")) {
            return { success: false };
          }
          const parsed_canonicalName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["canonicalName"]);
          })();
          if (!parsed_canonicalName.success) {
            return { success: false };
          }
          if (!hasProp(value, "contentItemId")) {
            return { success: false };
          }
          const parsed_contentItemId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
              if (typeof value === "number") {
                return { success: true, value: BigInt(value) };
              }
              if (typeof value === "bigint") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["contentItemId"]);
          })();
          if (!parsed_contentItemId.success) {
            return { success: false };
          }
          if (!hasProp(value, "contentItemType")) {
            return { success: false };
          }
          const parsed_contentItemType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ContentItemType } => {
              return ((value: unknown): { success: false } | { success: true; value: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE" | "MIAB" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "UNKNOWN":
                    return { success: true, value: "UNKNOWN" };
                  case "SUMMARY":
                    return { success: true, value: "SUMMARY" };
                  case "CATEGORY":
                    return { success: true, value: "CATEGORY" };
                  case "CHANNEL":
                    return { success: true, value: "CHANNEL" };
                  case "LGXP":
                    return { success: true, value: "LGXP" };
                  case "ACTIONABLE":
                    return { success: true, value: "ACTIONABLE" };
                  case "MYLIST":
                    return { success: true, value: "MYLIST" };
                  case "CUSTOMPAGE":
                    return { success: true, value: "CUSTOMPAGE" };
                  case "MIAB":
                    return { success: true, value: "MIAB" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["contentItemType"]);
          })();
          if (!parsed_contentItemType.success) {
            return { success: false };
          }
          if (!hasProp(value, "countriesExcluded")) {
            return { success: false };
          }
          const parsed_countriesExcluded = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Country[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: Country } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        countryCode: string;
                        currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                        names: {
                          de: string;
                          en: string;
                          es: string;
                          fr: string;
                          pt: string;
                          ru: string;
                          zh: string;
                        };
                        phonePrefix: string;
                        region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "countryCode")) {
                    return { success: false };
                  }
                  const parsed_countryCode = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["countryCode"]);
                  })();
                  if (!parsed_countryCode.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "currency")) {
                    return { success: false };
                  }
                  const parsed_currency = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY" } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      switch (value) {
                        case "CHF":
                          return { success: true, value: "CHF" };
                        case "CNY":
                          return { success: true, value: "CNY" };
                        case "USD":
                          return { success: true, value: "USD" };
                        case "EUR":
                          return { success: true, value: "EUR" };
                        case "GBP":
                          return { success: true, value: "GBP" };
                        case "INR":
                          return { success: true, value: "INR" };
                        case "JPY":
                          return { success: true, value: "JPY" };
                        default:
                          return { success: false };
                      }
                    })(value["currency"]);
                  })();
                  if (!parsed_currency.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "names")) {
                    return { success: false };
                  }
                  const parsed_names = (() => {
                    return ((
                      value: unknown,
                    ):
                      | { success: false }
                      | {
                          success: true;
                          value: {
                            de: string;
                            en: string;
                            es: string;
                            fr: string;
                            pt: string;
                            ru: string;
                            zh: string;
                          };
                        } => {
                      if (typeof value !== "object") {
                        return { success: false };
                      }
                      if (value === null) {
                        return { success: false };
                      }
                      if (!hasProp(value, "de")) {
                        return { success: false };
                      }
                      const parsed_de = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: string } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          return { success: true, value: value };
                        })(value["de"]);
                      })();
                      if (!parsed_de.success) {
                        return { success: false };
                      }
                      if (!hasProp(value, "en")) {
                        return { success: false };
                      }
                      const parsed_en = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: string } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          return { success: true, value: value };
                        })(value["en"]);
                      })();
                      if (!parsed_en.success) {
                        return { success: false };
                      }
                      if (!hasProp(value, "es")) {
                        return { success: false };
                      }
                      const parsed_es = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: string } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          return { success: true, value: value };
                        })(value["es"]);
                      })();
                      if (!parsed_es.success) {
                        return { success: false };
                      }
                      if (!hasProp(value, "fr")) {
                        return { success: false };
                      }
                      const parsed_fr = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: string } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          return { success: true, value: value };
                        })(value["fr"]);
                      })();
                      if (!parsed_fr.success) {
                        return { success: false };
                      }
                      if (!hasProp(value, "pt")) {
                        return { success: false };
                      }
                      const parsed_pt = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: string } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          return { success: true, value: value };
                        })(value["pt"]);
                      })();
                      if (!parsed_pt.success) {
                        return { success: false };
                      }
                      if (!hasProp(value, "ru")) {
                        return { success: false };
                      }
                      const parsed_ru = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: string } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          return { success: true, value: value };
                        })(value["ru"]);
                      })();
                      if (!parsed_ru.success) {
                        return { success: false };
                      }
                      if (!hasProp(value, "zh")) {
                        return { success: false };
                      }
                      const parsed_zh = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: string } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          return { success: true, value: value };
                        })(value["zh"]);
                      })();
                      if (!parsed_zh.success) {
                        return { success: false };
                      }
                      return { success: true, value: { de: parsed_de.value, en: parsed_en.value, es: parsed_es.value, fr: parsed_fr.value, pt: parsed_pt.value, ru: parsed_ru.value, zh: parsed_zh.value } };
                    })(value["names"]);
                  })();
                  if (!parsed_names.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "phonePrefix")) {
                    return { success: false };
                  }
                  const parsed_phonePrefix = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["phonePrefix"]);
                  })();
                  if (!parsed_phonePrefix.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "region")) {
                    return { success: false };
                  }
                  const parsed_region = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA" } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      switch (value) {
                        case "SOUTHAMERICA":
                          return { success: true, value: "SOUTHAMERICA" };
                        case "EUROPE":
                          return { success: true, value: "EUROPE" };
                        case "OCEANIA":
                          return { success: true, value: "OCEANIA" };
                        case "NORTHAMERICA":
                          return { success: true, value: "NORTHAMERICA" };
                        case "AFRICA":
                          return { success: true, value: "AFRICA" };
                        case "ANTARCTICA":
                          return { success: true, value: "ANTARCTICA" };
                        case "ASIA":
                          return { success: true, value: "ASIA" };
                        default:
                          return { success: false };
                      }
                    })(value["region"]);
                  })();
                  if (!parsed_region.success) {
                    return { success: false };
                  }
                  return { success: true, value: { countryCode: parsed_countryCode.value, currency: parsed_currency.value, names: parsed_names.value, phonePrefix: parsed_phonePrefix.value, region: parsed_region.value } };
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["countriesExcluded"]);
          })();
          if (!parsed_countriesExcluded.success) {
            return { success: false };
          }
          if (!hasProp(value, "countriesIncluded")) {
            return { success: false };
          }
          const parsed_countriesIncluded = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Country[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: Country } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        countryCode: string;
                        currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                        names: {
                          de: string;
                          en: string;
                          es: string;
                          fr: string;
                          pt: string;
                          ru: string;
                          zh: string;
                        };
                        phonePrefix: string;
                        region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "countryCode")) {
                    return { success: false };
                  }
                  const parsed_countryCode = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["countryCode"]);
                  })();
                  if (!parsed_countryCode.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "currency")) {
                    return { success: false };
                  }
                  const parsed_currency = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY" } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      switch (value) {
                        case "CHF":
                          return { success: true, value: "CHF" };
                        case "CNY":
                          return { success: true, value: "CNY" };
                        case "USD":
                          return { success: true, value: "USD" };
                        case "EUR":
                          return { success: true, value: "EUR" };
                        case "GBP":
                          return { success: true, value: "GBP" };
                        case "INR":
                          return { success: true, value: "INR" };
                        case "JPY":
                          return { success: true, value: "JPY" };
                        default:
                          return { success: false };
                      }
                    })(value["currency"]);
                  })();
                  if (!parsed_currency.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "names")) {
                    return { success: false };
                  }
                  const parsed_names = (() => {
                    return ((
                      value: unknown,
                    ):
                      | { success: false }
                      | {
                          success: true;
                          value: {
                            de: string;
                            en: string;
                            es: string;
                            fr: string;
                            pt: string;
                            ru: string;
                            zh: string;
                          };
                        } => {
                      if (typeof value !== "object") {
                        return { success: false };
                      }
                      if (value === null) {
                        return { success: false };
                      }
                      if (!hasProp(value, "de")) {
                        return { success: false };
                      }
                      const parsed_de = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: string } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          return { success: true, value: value };
                        })(value["de"]);
                      })();
                      if (!parsed_de.success) {
                        return { success: false };
                      }
                      if (!hasProp(value, "en")) {
                        return { success: false };
                      }
                      const parsed_en = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: string } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          return { success: true, value: value };
                        })(value["en"]);
                      })();
                      if (!parsed_en.success) {
                        return { success: false };
                      }
                      if (!hasProp(value, "es")) {
                        return { success: false };
                      }
                      const parsed_es = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: string } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          return { success: true, value: value };
                        })(value["es"]);
                      })();
                      if (!parsed_es.success) {
                        return { success: false };
                      }
                      if (!hasProp(value, "fr")) {
                        return { success: false };
                      }
                      const parsed_fr = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: string } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          return { success: true, value: value };
                        })(value["fr"]);
                      })();
                      if (!parsed_fr.success) {
                        return { success: false };
                      }
                      if (!hasProp(value, "pt")) {
                        return { success: false };
                      }
                      const parsed_pt = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: string } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          return { success: true, value: value };
                        })(value["pt"]);
                      })();
                      if (!parsed_pt.success) {
                        return { success: false };
                      }
                      if (!hasProp(value, "ru")) {
                        return { success: false };
                      }
                      const parsed_ru = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: string } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          return { success: true, value: value };
                        })(value["ru"]);
                      })();
                      if (!parsed_ru.success) {
                        return { success: false };
                      }
                      if (!hasProp(value, "zh")) {
                        return { success: false };
                      }
                      const parsed_zh = (() => {
                        return ((value: unknown): { success: false } | { success: true; value: string } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          return { success: true, value: value };
                        })(value["zh"]);
                      })();
                      if (!parsed_zh.success) {
                        return { success: false };
                      }
                      return { success: true, value: { de: parsed_de.value, en: parsed_en.value, es: parsed_es.value, fr: parsed_fr.value, pt: parsed_pt.value, ru: parsed_ru.value, zh: parsed_zh.value } };
                    })(value["names"]);
                  })();
                  if (!parsed_names.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "phonePrefix")) {
                    return { success: false };
                  }
                  const parsed_phonePrefix = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["phonePrefix"]);
                  })();
                  if (!parsed_phonePrefix.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "region")) {
                    return { success: false };
                  }
                  const parsed_region = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA" } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      switch (value) {
                        case "SOUTHAMERICA":
                          return { success: true, value: "SOUTHAMERICA" };
                        case "EUROPE":
                          return { success: true, value: "EUROPE" };
                        case "OCEANIA":
                          return { success: true, value: "OCEANIA" };
                        case "NORTHAMERICA":
                          return { success: true, value: "NORTHAMERICA" };
                        case "AFRICA":
                          return { success: true, value: "AFRICA" };
                        case "ANTARCTICA":
                          return { success: true, value: "ANTARCTICA" };
                        case "ASIA":
                          return { success: true, value: "ASIA" };
                        default:
                          return { success: false };
                      }
                    })(value["region"]);
                  })();
                  if (!parsed_region.success) {
                    return { success: false };
                  }
                  return { success: true, value: { countryCode: parsed_countryCode.value, currency: parsed_currency.value, names: parsed_names.value, phonePrefix: parsed_phonePrefix.value, region: parsed_region.value } };
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["countriesIncluded"]);
          })();
          if (!parsed_countriesIncluded.success) {
            return { success: false };
          }
          if (!hasProp(value, "coverModifiedAt")) {
            return { success: false };
          }
          const parsed_coverModifiedAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "number") {
                return { success: false };
              }
              const parsed = DateTime.fromMillis(value);
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: parsed };
            })(value["coverModifiedAt"]);
          })();
          if (!parsed_coverModifiedAt.success) {
            return { success: false };
          }
          if (!hasProp(value, "coverUri")) {
            return { success: false };
          }
          const parsed_coverUri = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["coverUri"]);
          })();
          if (!parsed_coverUri.success) {
            return { success: false };
          }
          if (!hasProp(value, "dataId")) {
            return { success: false };
          }
          const parsed_dataId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["dataId"]);
          })();
          if (!parsed_dataId.success) {
            return { success: false };
          }
          if (!hasProp(value, "descriptionHtml")) {
            return { success: false };
          }
          const parsed_descriptionHtml = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["descriptionHtml"]);
          })();
          if (!parsed_descriptionHtml.success) {
            return { success: false };
          }
          if (!hasProp(value, "documentType")) {
            return { success: false };
          }
          const parsed_documentType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
              return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "ABSTRACT":
                    return { success: true, value: "ABSTRACT" };
                  case "REVIEW":
                    return { success: true, value: "REVIEW" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["documentType"]);
          })();
          if (!parsed_documentType.success) {
            return { success: false };
          }
          if (!hasProp(value, "duration")) {
            return { success: false };
          }
          const parsed_duration = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["duration"]);
          })();
          if (!parsed_duration.success) {
            return { success: false };
          }
          if (!hasProp(value, "imageBorder")) {
            return { success: false };
          }
          const parsed_imageBorder = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["imageBorder"]);
          })();
          if (!parsed_imageBorder.success) {
            return { success: false };
          }
          if (!hasProp(value, "language")) {
            return { success: false };
          }
          const parsed_language = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Language } => {
              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "en":
                    return { success: true, value: "en" };
                  case "de":
                    return { success: true, value: "de" };
                  case "es":
                    return { success: true, value: "es" };
                  case "ru":
                    return { success: true, value: "ru" };
                  case "zh":
                    return { success: true, value: "zh" };
                  case "pt":
                    return { success: true, value: "pt" };
                  case "fr":
                    return { success: true, value: "fr" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["language"]);
          })();
          if (!parsed_language.success) {
            return { success: false };
          }
          if (!hasProp(value, "modifiedAt")) {
            return { success: false };
          }
          const parsed_modifiedAt = (() => {
            return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "number") {
                return { success: false };
              }
              const parsed = DateTime.fromMillis(value);
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: parsed };
            })(value["modifiedAt"]);
          })();
          if (!parsed_modifiedAt.success) {
            return { success: false };
          }
          if (!hasProp(value, "originalTitle")) {
            return { success: false };
          }
          const parsed_originalTitle = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["originalTitle"]);
          })();
          if (!parsed_originalTitle.success) {
            return { success: false };
          }
          if (!hasProp(value, "previewAvailable")) {
            return { success: false };
          }
          const parsed_previewAvailable = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["previewAvailable"]);
          })();
          if (!parsed_previewAvailable.success) {
            return { success: false };
          }
          if (!hasProp(value, "publicationDate")) {
            return { success: false };
          }
          const parsed_publicationDate = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["publicationDate"]);
          })();
          if (!parsed_publicationDate.success) {
            return { success: false };
          }
          if (!hasProp(value, "publisherInfo")) {
            return { success: false };
          }
          const parsed_publisherInfo = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["publisherInfo"]);
          })();
          if (!parsed_publisherInfo.success) {
            return { success: false };
          }
          if (!hasProp(value, "publisherName")) {
            return { success: false };
          }
          const parsed_publisherName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["publisherName"]);
          })();
          if (!parsed_publisherName.success) {
            return { success: false };
          }
          if (!hasProp(value, "rating")) {
            return { success: false };
          }
          const parsed_rating = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["rating"]);
          })();
          if (!parsed_rating.success) {
            return { success: false };
          }
          if (!hasProp(value, "sourceType")) {
            return { success: false };
          }
          const parsed_sourceType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
              return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "BOOK":
                    return { success: true, value: "BOOK" };
                  case "VIDEO":
                    return { success: true, value: "VIDEO" };
                  case "ARTICLE":
                    return { success: true, value: "ARTICLE" };
                  case "CHAPTER":
                    return { success: true, value: "CHAPTER" };
                  case "REPORT":
                    return { success: true, value: "REPORT" };
                  case "PODCAST":
                    return { success: true, value: "PODCAST" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["sourceType"]);
          })();
          if (!parsed_sourceType.success) {
            return { success: false };
          }
          if (!hasProp(value, "subtitle")) {
            return { success: false };
          }
          const parsed_subtitle = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["subtitle"]);
          })();
          if (!parsed_subtitle.success) {
            return { success: false };
          }
          if (!hasProp(value, "summaryPages")) {
            return { success: false };
          }
          const parsed_summaryPages = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["summaryPages"]);
          })();
          if (!parsed_summaryPages.success) {
            return { success: false };
          }
          if (!hasProp(value, "summaryWords")) {
            return { success: false };
          }
          const parsed_summaryWords = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["summaryWords"]);
          })();
          if (!parsed_summaryWords.success) {
            return { success: false };
          }
          if (!hasProp(value, "tags")) {
            return { success: false };
          }
          const parsed_tags = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value:
                        | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                        | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                        | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                        | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                        | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                        | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                        | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                        | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                        | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                    } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value:
                          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                          | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                          | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                      } => {
                    if (value == null) {
                      return { success: false };
                    }
                    if (typeof value != "object") {
                      return { success: false };
                    }
                    if (!hasProp(value, "discriminator")) {
                      return { success: false };
                    }
                    if (typeof value["discriminator"] != "string") {
                      return { success: false };
                    }
                    const discriminator = value["discriminator"];
                    switch (discriminator) {
                      case "dynamicString": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicString;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicString;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicString":
                                          return { success: true, value: "dynamicString" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                      }
                      case "dynamicBoolean": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicBoolean;
                                value: boolean;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicBoolean;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicBoolean":
                                          return { success: true, value: "dynamicBoolean" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                      }
                      case "dynamicNumber": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicNumber;
                                value: number;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicNumber;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicNumber":
                                          return { success: true, value: "dynamicNumber" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: number } => {
                              if (typeof value === "number") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                      }
                      case "dynamicVoid": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindDynamicVoid;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindDynamicVoid;
                                      id: bigint;
                                      kind: string;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "dynamicVoid":
                                          return { success: true, value: "dynamicVoid" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                      }
                      case "channel": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindChannel;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindChannel;
                                      id: bigint;
                                      kind: KindChannel;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "channel":
                                          return { success: true, value: "channel" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "channel":
                                          return { success: true, value: "channel" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                      }
                      case "primary-skill": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindPrimarySkill;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindPrimarySkill;
                                      id: bigint;
                                      kind: KindPrimarySkill;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "primary-skill":
                                          return { success: true, value: "primary-skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "primary-skill":
                                          return { success: true, value: "primary-skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                      }
                      case "skill": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindSkill;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindSkill;
                                      id: bigint;
                                      kind: KindSkill;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "skill":
                                          return { success: true, value: "skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "skill":
                                          return { success: true, value: "skill" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                      }
                      case "key-quality": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindKeyQuality;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindKeyQuality;
                                      id: bigint;
                                      kind: KindKeyQuality;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "key-quality":
                                          return { success: true, value: "key-quality" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "key-quality":
                                          return { success: true, value: "key-quality" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                      }
                      case "classification": {
                        const parsedVariant = ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                exclude: boolean;
                                source: TagSourceType;
                                tagKind: TagKindClassification;
                                value: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "exclude")) {
                            return { success: false };
                          }
                          const parsed_exclude = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                              if (typeof value !== "boolean") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["exclude"]);
                          })();
                          if (!parsed_exclude.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "source")) {
                            return { success: false };
                          }
                          const parsed_source = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                              return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "USER":
                                    return { success: true, value: "USER" };
                                  case "AI":
                                    return { success: true, value: "AI" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["source"]);
                          })();
                          if (!parsed_source.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "tagKind")) {
                            return { success: false };
                          }
                          const parsed_tagKind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      discriminator: KindClassification;
                                      id: bigint;
                                      kind: KindClassification;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "discriminator")) {
                                  return { success: false };
                                }
                                const parsed_discriminator = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "classification":
                                          return { success: true, value: "classification" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["discriminator"]);
                                })();
                                if (!parsed_discriminator.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "id")) {
                                  return { success: false };
                                }
                                const parsed_id = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: BigInt(value) };
                                    }
                                    if (typeof value === "bigint") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["id"]);
                                })();
                                if (!parsed_id.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "kind")) {
                                  return { success: false };
                                }
                                const parsed_kind = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                    return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      switch (value) {
                                        case "classification":
                                          return { success: true, value: "classification" };
                                        default:
                                          return { success: false };
                                      }
                                    })(value);
                                  })(value["kind"]);
                                })();
                                if (!parsed_kind.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                              })(value);
                            })(value["tagKind"]);
                          })();
                          if (!parsed_tagKind.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "value")) {
                            return { success: false };
                          }
                          const parsed_value = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["value"]);
                          })();
                          if (!parsed_value.success) {
                            return { success: false };
                          }
                          return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                        })(value);
                        if (!parsedVariant.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                      }
                      default:
                        return { success: false };
                    }
                  })(value);
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["tags"]);
          })();
          if (!parsed_tags.success) {
            return { success: false };
          }
          if (!hasProp(value, "teaser")) {
            return { success: false };
          }
          const parsed_teaser = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["teaser"]);
          })();
          if (!parsed_teaser.success) {
            return { success: false };
          }
          if (!hasProp(value, "title")) {
            return { success: false };
          }
          const parsed_title = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["title"]);
          })();
          if (!parsed_title.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              activatedAt: parsed_activatedAt.value,
              activationTime: parsed_activationTime.value,
              active: parsed_active.value,
              audioActivatedAt: parsed_audioActivatedAt.value,
              audioActive: parsed_audioActive.value,
              audioDuration: parsed_audioDuration.value,
              authorInfo: parsed_authorInfo.value,
              authors: parsed_authors.value,
              backgroundColor: parsed_backgroundColor.value,
              bestseller: parsed_bestseller.value,
              businessClassic: parsed_businessClassic.value,
              canonicalName: parsed_canonicalName.value,
              contentItemId: parsed_contentItemId.value,
              contentItemType: parsed_contentItemType.value,
              countriesExcluded: parsed_countriesExcluded.value,
              countriesIncluded: parsed_countriesIncluded.value,
              coverModifiedAt: parsed_coverModifiedAt.value,
              coverUri: parsed_coverUri.value,
              dataId: parsed_dataId.value,
              descriptionHtml: parsed_descriptionHtml.value,
              documentType: parsed_documentType.value,
              duration: parsed_duration.value,
              imageBorder: parsed_imageBorder.value,
              language: parsed_language.value,
              modifiedAt: parsed_modifiedAt.value,
              originalTitle: parsed_originalTitle.value,
              previewAvailable: parsed_previewAvailable.value,
              publicationDate: parsed_publicationDate.value,
              publisherInfo: parsed_publisherInfo.value,
              publisherName: parsed_publisherName.value,
              rating: parsed_rating.value,
              sourceType: parsed_sourceType.value,
              subtitle: parsed_subtitle.value,
              summaryPages: parsed_summaryPages.value,
              summaryWords: parsed_summaryWords.value,
              tags: parsed_tags.value,
              teaser: parsed_teaser.value,
              title: parsed_title.value,
            },
          };
        })(value);
      })(value["summary"]);
    })();
    if (!parsed_summary.success) {
      return { success: false };
    }
    if (!hasProp(value, "timeStamp")) {
      return { success: false };
    }
    const parsed_timeStamp = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["timeStamp"]);
    })();
    if (!parsed_timeStamp.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        customer: parsed_customer.value,
        downloadCountry: parsed_downloadCountry.value,
        downloadFormat: parsed_downloadFormat.value,
        primarySkills: parsed_primarySkills.value,
        skills: parsed_skills.value,
        summary: parsed_summary.value,
        timeStamp: parsed_timeStamp.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SummaryBulkLoadForm
export function serialize_SummaryBulkLoadForm(obj: SummaryBulkLoadForm): string {
  const serialized = ((value: { corporateId: number | null; dataIds: number[]; includeInactive: boolean }): unknown => {
    return {
      corporateId: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["corporateId"]),
      dataIds: ((value: number[]): unknown => {
        return value.map((value: number): unknown => {
          return value;
        });
      })(value["dataIds"]),
      includeInactive: ((value: boolean): unknown => {
        return value;
      })(value["includeInactive"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SummaryBulkLoadForm(str: string): SummaryBulkLoadForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          corporateId: number | null;
          dataIds: number[];
          includeInactive: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "dataIds")) {
      return { success: false };
    }
    const parsed_dataIds = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
          if (typeof value === "number") {
            return { success: true, value: value };
          }
          return { success: false };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["dataIds"]);
    })();
    if (!parsed_dataIds.success) {
      return { success: false };
    }
    if (!hasProp(value, "includeInactive")) {
      return { success: false };
    }
    const parsed_includeInactive = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["includeInactive"]);
    })();
    if (!parsed_includeInactive.success) {
      return { success: false };
    }
    return { success: true, value: { corporateId: parsed_corporateId.value, dataIds: parsed_dataIds.value, includeInactive: parsed_includeInactive.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SummaryCardDemoProps
export function serialize_SummaryCardDemoProps(obj: SummaryCardDemoProps): string {
  const serialized = ((value: { summaryList: SummaryMetadata[] }): unknown => {
    return {
      summaryList: ((value: SummaryMetadata[]): unknown => {
        return value.map(
          (value: {
            activatedAt: DateTime<true> | null;
            activationTime: DateTime<true> | null;
            active: boolean;
            audioActivatedAt: DateTime<true> | null;
            audioActive: boolean;
            audioDuration: number;
            authorInfo: string | null;
            authors: {
              authorId: number;
              firstName: string | null;
              gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
              lastName: string | null;
              middleName: string | null;
            }[];
            backgroundColor: string | null;
            bestseller: boolean;
            businessClassic: boolean;
            canonicalName: string;
            contentItemId: bigint;
            contentItemType: ContentItemType;
            countriesExcluded: Country[];
            countriesIncluded: Country[];
            coverModifiedAt: DateTime<true> | null;
            coverUri: string;
            dataId: number;
            descriptionHtml: string | null;
            documentType: SummaryDocumentType;
            duration: number;
            imageBorder: boolean;
            language: Language;
            modifiedAt: DateTime<true> | null;
            originalTitle: string | null;
            previewAvailable: boolean;
            publicationDate: string | null;
            publisherInfo: string | null;
            publisherName: string | null;
            rating: number | null;
            sourceType: SummarySourceType;
            subtitle: string | null;
            summaryPages: number;
            summaryWords: number;
            tags: Tag[];
            teaser: string | null;
            title: string | null;
          }): unknown => {
            return {
              activatedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["activatedAt"]),
              activationTime: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["activationTime"]),
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              audioActivatedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["audioActivatedAt"]),
              audioActive: ((value: boolean): unknown => {
                return value;
              })(value["audioActive"]),
              audioDuration: ((value: number): unknown => {
                return value;
              })(value["audioDuration"]),
              authorInfo: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["authorInfo"]),
              authors: ((
                value: {
                  authorId: number;
                  firstName: string | null;
                  gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                  lastName: string | null;
                  middleName: string | null;
                }[],
              ): unknown => {
                return value.map((value: { authorId: number; firstName: string | null; gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null; lastName: string | null; middleName: string | null }): unknown => {
                  return {
                    authorId: ((value: number): unknown => {
                      return value;
                    })(value["authorId"]),
                    firstName: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["firstName"]),
                    gender: ((value: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["gender"]),
                    lastName: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["lastName"]),
                    middleName: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["middleName"]),
                  };
                });
              })(value["authors"]),
              backgroundColor: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["backgroundColor"]),
              bestseller: ((value: boolean): unknown => {
                return value;
              })(value["bestseller"]),
              businessClassic: ((value: boolean): unknown => {
                return value;
              })(value["businessClassic"]),
              canonicalName: ((value: string): unknown => {
                return value;
              })(value["canonicalName"]),
              contentItemId: ((value: bigint): unknown => {
                return value;
              })(value["contentItemId"]),
              contentItemType: ((value: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE" | "MIAB"): unknown => {
                return value;
              })(value["contentItemType"]),
              countriesExcluded: ((value: Country[]): unknown => {
                return value.map(
                  (value: {
                    countryCode: string;
                    currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                    names: {
                      de: string;
                      en: string;
                      es: string;
                      fr: string;
                      pt: string;
                      ru: string;
                      zh: string;
                    };
                    phonePrefix: string;
                    region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                  }): unknown => {
                    return {
                      countryCode: ((value: string): unknown => {
                        return value;
                      })(value["countryCode"]),
                      currency: ((value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY"): unknown => {
                        return value;
                      })(value["currency"]),
                      names: ((value: { de: string; en: string; es: string; fr: string; pt: string; ru: string; zh: string }): unknown => {
                        return {
                          de: ((value: string): unknown => {
                            return value;
                          })(value["de"]),
                          en: ((value: string): unknown => {
                            return value;
                          })(value["en"]),
                          es: ((value: string): unknown => {
                            return value;
                          })(value["es"]),
                          fr: ((value: string): unknown => {
                            return value;
                          })(value["fr"]),
                          pt: ((value: string): unknown => {
                            return value;
                          })(value["pt"]),
                          ru: ((value: string): unknown => {
                            return value;
                          })(value["ru"]),
                          zh: ((value: string): unknown => {
                            return value;
                          })(value["zh"]),
                        };
                      })(value["names"]),
                      phonePrefix: ((value: string): unknown => {
                        return value;
                      })(value["phonePrefix"]),
                      region: ((value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA"): unknown => {
                        return value;
                      })(value["region"]),
                    };
                  },
                );
              })(value["countriesExcluded"]),
              countriesIncluded: ((value: Country[]): unknown => {
                return value.map(
                  (value: {
                    countryCode: string;
                    currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                    names: {
                      de: string;
                      en: string;
                      es: string;
                      fr: string;
                      pt: string;
                      ru: string;
                      zh: string;
                    };
                    phonePrefix: string;
                    region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                  }): unknown => {
                    return {
                      countryCode: ((value: string): unknown => {
                        return value;
                      })(value["countryCode"]),
                      currency: ((value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY"): unknown => {
                        return value;
                      })(value["currency"]),
                      names: ((value: { de: string; en: string; es: string; fr: string; pt: string; ru: string; zh: string }): unknown => {
                        return {
                          de: ((value: string): unknown => {
                            return value;
                          })(value["de"]),
                          en: ((value: string): unknown => {
                            return value;
                          })(value["en"]),
                          es: ((value: string): unknown => {
                            return value;
                          })(value["es"]),
                          fr: ((value: string): unknown => {
                            return value;
                          })(value["fr"]),
                          pt: ((value: string): unknown => {
                            return value;
                          })(value["pt"]),
                          ru: ((value: string): unknown => {
                            return value;
                          })(value["ru"]),
                          zh: ((value: string): unknown => {
                            return value;
                          })(value["zh"]),
                        };
                      })(value["names"]),
                      phonePrefix: ((value: string): unknown => {
                        return value;
                      })(value["phonePrefix"]),
                      region: ((value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA"): unknown => {
                        return value;
                      })(value["region"]),
                    };
                  },
                );
              })(value["countriesIncluded"]),
              coverModifiedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["coverModifiedAt"]),
              coverUri: ((value: string): unknown => {
                return value;
              })(value["coverUri"]),
              dataId: ((value: number): unknown => {
                return value;
              })(value["dataId"]),
              descriptionHtml: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["descriptionHtml"]),
              documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
                return value;
              })(value["documentType"]),
              duration: ((value: number): unknown => {
                return value;
              })(value["duration"]),
              imageBorder: ((value: boolean): unknown => {
                return value;
              })(value["imageBorder"]),
              language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                return value;
              })(value["language"]),
              modifiedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["modifiedAt"]),
              originalTitle: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["originalTitle"]),
              previewAvailable: ((value: boolean): unknown => {
                return value;
              })(value["previewAvailable"]),
              publicationDate: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["publicationDate"]),
              publisherInfo: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["publisherInfo"]),
              publisherName: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["publisherName"]),
              rating: ((value: number | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["rating"]),
              sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
                return value;
              })(value["sourceType"]),
              subtitle: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["subtitle"]),
              summaryPages: ((value: number): unknown => {
                return value;
              })(value["summaryPages"]),
              summaryWords: ((value: number): unknown => {
                return value;
              })(value["summaryWords"]),
              tags: ((value: Tag[]): unknown => {
                return value.map(
                  (
                    value:
                      | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                      | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                      | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                      | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                      | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                      | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                      | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                      | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                      | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
                  ): unknown => {
                    switch (value.discriminator) {
                      case "dynamicString": {
                        return {
                          discriminator: "dynamicString",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicString"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicBoolean": {
                        return {
                          discriminator: "dynamicBoolean",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicBoolean"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: boolean): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicNumber": {
                        return {
                          discriminator: "dynamicNumber",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicNumber"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: number): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicVoid": {
                        return {
                          discriminator: "dynamicVoid",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicVoid"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                        };
                      }
                      case "channel": {
                        return {
                          discriminator: "channel",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                            return {
                              discriminator: ((value: "channel"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "channel"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "primary-skill": {
                        return {
                          discriminator: "primary-skill",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                            return {
                              discriminator: ((value: "primary-skill"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "primary-skill"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "skill": {
                        return {
                          discriminator: "skill",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                            return {
                              discriminator: ((value: "skill"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "skill"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "key-quality": {
                        return {
                          discriminator: "key-quality",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                            return {
                              discriminator: ((value: "key-quality"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "key-quality"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "classification": {
                        return {
                          discriminator: "classification",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                            return {
                              discriminator: ((value: "classification"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "classification"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                    }
                  },
                );
              })(value["tags"]),
              teaser: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["teaser"]),
              title: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["title"]),
            };
          },
        );
      })(value["summaryList"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SummaryCardDemoProps(str: string): SummaryCardDemoProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          summaryList: SummaryMetadata[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "summaryList")) {
      return { success: false };
    }
    const parsed_summaryList = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SummaryMetadata[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: SummaryMetadata } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  activatedAt: DateTime<true> | null;
                  activationTime: DateTime<true> | null;
                  active: boolean;
                  audioActivatedAt: DateTime<true> | null;
                  audioActive: boolean;
                  audioDuration: number;
                  authorInfo: string | null;
                  authors: {
                    authorId: number;
                    firstName: string | null;
                    gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                    lastName: string | null;
                    middleName: string | null;
                  }[];
                  backgroundColor: string | null;
                  bestseller: boolean;
                  businessClassic: boolean;
                  canonicalName: string;
                  contentItemId: bigint;
                  contentItemType: ContentItemType;
                  countriesExcluded: Country[];
                  countriesIncluded: Country[];
                  coverModifiedAt: DateTime<true> | null;
                  coverUri: string;
                  dataId: number;
                  descriptionHtml: string | null;
                  documentType: SummaryDocumentType;
                  duration: number;
                  imageBorder: boolean;
                  language: Language;
                  modifiedAt: DateTime<true> | null;
                  originalTitle: string | null;
                  previewAvailable: boolean;
                  publicationDate: string | null;
                  publisherInfo: string | null;
                  publisherName: string | null;
                  rating: number | null;
                  sourceType: SummarySourceType;
                  subtitle: string | null;
                  summaryPages: number;
                  summaryWords: number;
                  tags: Tag[];
                  teaser: string | null;
                  title: string | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "activatedAt")) {
              return { success: false };
            }
            const parsed_activatedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["activatedAt"]);
            })();
            if (!parsed_activatedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "activationTime")) {
              return { success: false };
            }
            const parsed_activationTime = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["activationTime"]);
            })();
            if (!parsed_activationTime.success) {
              return { success: false };
            }
            if (!hasProp(value, "active")) {
              return { success: false };
            }
            const parsed_active = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["active"]);
            })();
            if (!parsed_active.success) {
              return { success: false };
            }
            if (!hasProp(value, "audioActivatedAt")) {
              return { success: false };
            }
            const parsed_audioActivatedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["audioActivatedAt"]);
            })();
            if (!parsed_audioActivatedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "audioActive")) {
              return { success: false };
            }
            const parsed_audioActive = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["audioActive"]);
            })();
            if (!parsed_audioActive.success) {
              return { success: false };
            }
            if (!hasProp(value, "audioDuration")) {
              return { success: false };
            }
            const parsed_audioDuration = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["audioDuration"]);
            })();
            if (!parsed_audioDuration.success) {
              return { success: false };
            }
            if (!hasProp(value, "authorInfo")) {
              return { success: false };
            }
            const parsed_authorInfo = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["authorInfo"]);
            })();
            if (!parsed_authorInfo.success) {
              return { success: false };
            }
            if (!hasProp(value, "authors")) {
              return { success: false };
            }
            const parsed_authors = (() => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      authorId: number;
                      firstName: string | null;
                      gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                      lastName: string | null;
                      middleName: string | null;
                    }[];
                  } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map(
                  (
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          authorId: number;
                          firstName: string | null;
                          gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                          lastName: string | null;
                          middleName: string | null;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "authorId")) {
                      return { success: false };
                    }
                    const parsed_authorId = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["authorId"]);
                    })();
                    if (!parsed_authorId.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "firstName")) {
                      return { success: false };
                    }
                    const parsed_firstName = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["firstName"]);
                    })();
                    if (!parsed_firstName.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "gender")) {
                      return { success: false };
                    }
                    const parsed_gender = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "MALE":
                            return { success: true, value: "MALE" };
                          case "FEMALE":
                            return { success: true, value: "FEMALE" };
                          case "CUSTOM":
                            return { success: true, value: "CUSTOM" };
                          case "UNKNOWN":
                            return { success: true, value: "UNKNOWN" };
                          default:
                            return { success: false };
                        }
                      })(value["gender"]);
                    })();
                    if (!parsed_gender.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "lastName")) {
                      return { success: false };
                    }
                    const parsed_lastName = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["lastName"]);
                    })();
                    if (!parsed_lastName.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "middleName")) {
                      return { success: false };
                    }
                    const parsed_middleName = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                        if (value === null) {
                          return { success: true, value: null };
                        }
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["middleName"]);
                    })();
                    if (!parsed_middleName.success) {
                      return { success: false };
                    }
                    return { success: true, value: { authorId: parsed_authorId.value, firstName: parsed_firstName.value, gender: parsed_gender.value, lastName: parsed_lastName.value, middleName: parsed_middleName.value } };
                  },
                );
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["authors"]);
            })();
            if (!parsed_authors.success) {
              return { success: false };
            }
            if (!hasProp(value, "backgroundColor")) {
              return { success: false };
            }
            const parsed_backgroundColor = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["backgroundColor"]);
            })();
            if (!parsed_backgroundColor.success) {
              return { success: false };
            }
            if (!hasProp(value, "bestseller")) {
              return { success: false };
            }
            const parsed_bestseller = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["bestseller"]);
            })();
            if (!parsed_bestseller.success) {
              return { success: false };
            }
            if (!hasProp(value, "businessClassic")) {
              return { success: false };
            }
            const parsed_businessClassic = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["businessClassic"]);
            })();
            if (!parsed_businessClassic.success) {
              return { success: false };
            }
            if (!hasProp(value, "canonicalName")) {
              return { success: false };
            }
            const parsed_canonicalName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["canonicalName"]);
            })();
            if (!parsed_canonicalName.success) {
              return { success: false };
            }
            if (!hasProp(value, "contentItemId")) {
              return { success: false };
            }
            const parsed_contentItemId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["contentItemId"]);
            })();
            if (!parsed_contentItemId.success) {
              return { success: false };
            }
            if (!hasProp(value, "contentItemType")) {
              return { success: false };
            }
            const parsed_contentItemType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ContentItemType } => {
                return ((value: unknown): { success: false } | { success: true; value: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE" | "MIAB" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "UNKNOWN":
                      return { success: true, value: "UNKNOWN" };
                    case "SUMMARY":
                      return { success: true, value: "SUMMARY" };
                    case "CATEGORY":
                      return { success: true, value: "CATEGORY" };
                    case "CHANNEL":
                      return { success: true, value: "CHANNEL" };
                    case "LGXP":
                      return { success: true, value: "LGXP" };
                    case "ACTIONABLE":
                      return { success: true, value: "ACTIONABLE" };
                    case "MYLIST":
                      return { success: true, value: "MYLIST" };
                    case "CUSTOMPAGE":
                      return { success: true, value: "CUSTOMPAGE" };
                    case "MIAB":
                      return { success: true, value: "MIAB" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["contentItemType"]);
            })();
            if (!parsed_contentItemType.success) {
              return { success: false };
            }
            if (!hasProp(value, "countriesExcluded")) {
              return { success: false };
            }
            const parsed_countriesExcluded = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Country[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: Country } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          countryCode: string;
                          currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                          names: {
                            de: string;
                            en: string;
                            es: string;
                            fr: string;
                            pt: string;
                            ru: string;
                            zh: string;
                          };
                          phonePrefix: string;
                          region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "countryCode")) {
                      return { success: false };
                    }
                    const parsed_countryCode = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["countryCode"]);
                    })();
                    if (!parsed_countryCode.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "currency")) {
                      return { success: false };
                    }
                    const parsed_currency = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "CHF":
                            return { success: true, value: "CHF" };
                          case "CNY":
                            return { success: true, value: "CNY" };
                          case "USD":
                            return { success: true, value: "USD" };
                          case "EUR":
                            return { success: true, value: "EUR" };
                          case "GBP":
                            return { success: true, value: "GBP" };
                          case "INR":
                            return { success: true, value: "INR" };
                          case "JPY":
                            return { success: true, value: "JPY" };
                          default:
                            return { success: false };
                        }
                      })(value["currency"]);
                    })();
                    if (!parsed_currency.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "names")) {
                      return { success: false };
                    }
                    const parsed_names = (() => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              de: string;
                              en: string;
                              es: string;
                              fr: string;
                              pt: string;
                              ru: string;
                              zh: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "de")) {
                          return { success: false };
                        }
                        const parsed_de = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["de"]);
                        })();
                        if (!parsed_de.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "en")) {
                          return { success: false };
                        }
                        const parsed_en = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["en"]);
                        })();
                        if (!parsed_en.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "es")) {
                          return { success: false };
                        }
                        const parsed_es = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["es"]);
                        })();
                        if (!parsed_es.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "fr")) {
                          return { success: false };
                        }
                        const parsed_fr = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["fr"]);
                        })();
                        if (!parsed_fr.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "pt")) {
                          return { success: false };
                        }
                        const parsed_pt = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["pt"]);
                        })();
                        if (!parsed_pt.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "ru")) {
                          return { success: false };
                        }
                        const parsed_ru = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["ru"]);
                        })();
                        if (!parsed_ru.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "zh")) {
                          return { success: false };
                        }
                        const parsed_zh = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["zh"]);
                        })();
                        if (!parsed_zh.success) {
                          return { success: false };
                        }
                        return { success: true, value: { de: parsed_de.value, en: parsed_en.value, es: parsed_es.value, fr: parsed_fr.value, pt: parsed_pt.value, ru: parsed_ru.value, zh: parsed_zh.value } };
                      })(value["names"]);
                    })();
                    if (!parsed_names.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "phonePrefix")) {
                      return { success: false };
                    }
                    const parsed_phonePrefix = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["phonePrefix"]);
                    })();
                    if (!parsed_phonePrefix.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "region")) {
                      return { success: false };
                    }
                    const parsed_region = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "SOUTHAMERICA":
                            return { success: true, value: "SOUTHAMERICA" };
                          case "EUROPE":
                            return { success: true, value: "EUROPE" };
                          case "OCEANIA":
                            return { success: true, value: "OCEANIA" };
                          case "NORTHAMERICA":
                            return { success: true, value: "NORTHAMERICA" };
                          case "AFRICA":
                            return { success: true, value: "AFRICA" };
                          case "ANTARCTICA":
                            return { success: true, value: "ANTARCTICA" };
                          case "ASIA":
                            return { success: true, value: "ASIA" };
                          default:
                            return { success: false };
                        }
                      })(value["region"]);
                    })();
                    if (!parsed_region.success) {
                      return { success: false };
                    }
                    return { success: true, value: { countryCode: parsed_countryCode.value, currency: parsed_currency.value, names: parsed_names.value, phonePrefix: parsed_phonePrefix.value, region: parsed_region.value } };
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["countriesExcluded"]);
            })();
            if (!parsed_countriesExcluded.success) {
              return { success: false };
            }
            if (!hasProp(value, "countriesIncluded")) {
              return { success: false };
            }
            const parsed_countriesIncluded = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Country[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: Country } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          countryCode: string;
                          currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                          names: {
                            de: string;
                            en: string;
                            es: string;
                            fr: string;
                            pt: string;
                            ru: string;
                            zh: string;
                          };
                          phonePrefix: string;
                          region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "countryCode")) {
                      return { success: false };
                    }
                    const parsed_countryCode = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["countryCode"]);
                    })();
                    if (!parsed_countryCode.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "currency")) {
                      return { success: false };
                    }
                    const parsed_currency = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "CHF":
                            return { success: true, value: "CHF" };
                          case "CNY":
                            return { success: true, value: "CNY" };
                          case "USD":
                            return { success: true, value: "USD" };
                          case "EUR":
                            return { success: true, value: "EUR" };
                          case "GBP":
                            return { success: true, value: "GBP" };
                          case "INR":
                            return { success: true, value: "INR" };
                          case "JPY":
                            return { success: true, value: "JPY" };
                          default:
                            return { success: false };
                        }
                      })(value["currency"]);
                    })();
                    if (!parsed_currency.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "names")) {
                      return { success: false };
                    }
                    const parsed_names = (() => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              de: string;
                              en: string;
                              es: string;
                              fr: string;
                              pt: string;
                              ru: string;
                              zh: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "de")) {
                          return { success: false };
                        }
                        const parsed_de = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["de"]);
                        })();
                        if (!parsed_de.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "en")) {
                          return { success: false };
                        }
                        const parsed_en = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["en"]);
                        })();
                        if (!parsed_en.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "es")) {
                          return { success: false };
                        }
                        const parsed_es = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["es"]);
                        })();
                        if (!parsed_es.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "fr")) {
                          return { success: false };
                        }
                        const parsed_fr = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["fr"]);
                        })();
                        if (!parsed_fr.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "pt")) {
                          return { success: false };
                        }
                        const parsed_pt = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["pt"]);
                        })();
                        if (!parsed_pt.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "ru")) {
                          return { success: false };
                        }
                        const parsed_ru = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["ru"]);
                        })();
                        if (!parsed_ru.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "zh")) {
                          return { success: false };
                        }
                        const parsed_zh = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["zh"]);
                        })();
                        if (!parsed_zh.success) {
                          return { success: false };
                        }
                        return { success: true, value: { de: parsed_de.value, en: parsed_en.value, es: parsed_es.value, fr: parsed_fr.value, pt: parsed_pt.value, ru: parsed_ru.value, zh: parsed_zh.value } };
                      })(value["names"]);
                    })();
                    if (!parsed_names.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "phonePrefix")) {
                      return { success: false };
                    }
                    const parsed_phonePrefix = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["phonePrefix"]);
                    })();
                    if (!parsed_phonePrefix.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "region")) {
                      return { success: false };
                    }
                    const parsed_region = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "SOUTHAMERICA":
                            return { success: true, value: "SOUTHAMERICA" };
                          case "EUROPE":
                            return { success: true, value: "EUROPE" };
                          case "OCEANIA":
                            return { success: true, value: "OCEANIA" };
                          case "NORTHAMERICA":
                            return { success: true, value: "NORTHAMERICA" };
                          case "AFRICA":
                            return { success: true, value: "AFRICA" };
                          case "ANTARCTICA":
                            return { success: true, value: "ANTARCTICA" };
                          case "ASIA":
                            return { success: true, value: "ASIA" };
                          default:
                            return { success: false };
                        }
                      })(value["region"]);
                    })();
                    if (!parsed_region.success) {
                      return { success: false };
                    }
                    return { success: true, value: { countryCode: parsed_countryCode.value, currency: parsed_currency.value, names: parsed_names.value, phonePrefix: parsed_phonePrefix.value, region: parsed_region.value } };
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["countriesIncluded"]);
            })();
            if (!parsed_countriesIncluded.success) {
              return { success: false };
            }
            if (!hasProp(value, "coverModifiedAt")) {
              return { success: false };
            }
            const parsed_coverModifiedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["coverModifiedAt"]);
            })();
            if (!parsed_coverModifiedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "coverUri")) {
              return { success: false };
            }
            const parsed_coverUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["coverUri"]);
            })();
            if (!parsed_coverUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "dataId")) {
              return { success: false };
            }
            const parsed_dataId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["dataId"]);
            })();
            if (!parsed_dataId.success) {
              return { success: false };
            }
            if (!hasProp(value, "descriptionHtml")) {
              return { success: false };
            }
            const parsed_descriptionHtml = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["descriptionHtml"]);
            })();
            if (!parsed_descriptionHtml.success) {
              return { success: false };
            }
            if (!hasProp(value, "documentType")) {
              return { success: false };
            }
            const parsed_documentType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
                return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "ABSTRACT":
                      return { success: true, value: "ABSTRACT" };
                    case "REVIEW":
                      return { success: true, value: "REVIEW" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["documentType"]);
            })();
            if (!parsed_documentType.success) {
              return { success: false };
            }
            if (!hasProp(value, "duration")) {
              return { success: false };
            }
            const parsed_duration = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["duration"]);
            })();
            if (!parsed_duration.success) {
              return { success: false };
            }
            if (!hasProp(value, "imageBorder")) {
              return { success: false };
            }
            const parsed_imageBorder = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["imageBorder"]);
            })();
            if (!parsed_imageBorder.success) {
              return { success: false };
            }
            if (!hasProp(value, "language")) {
              return { success: false };
            }
            const parsed_language = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Language } => {
                return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "en":
                      return { success: true, value: "en" };
                    case "de":
                      return { success: true, value: "de" };
                    case "es":
                      return { success: true, value: "es" };
                    case "ru":
                      return { success: true, value: "ru" };
                    case "zh":
                      return { success: true, value: "zh" };
                    case "pt":
                      return { success: true, value: "pt" };
                    case "fr":
                      return { success: true, value: "fr" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["language"]);
            })();
            if (!parsed_language.success) {
              return { success: false };
            }
            if (!hasProp(value, "modifiedAt")) {
              return { success: false };
            }
            const parsed_modifiedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["modifiedAt"]);
            })();
            if (!parsed_modifiedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "originalTitle")) {
              return { success: false };
            }
            const parsed_originalTitle = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["originalTitle"]);
            })();
            if (!parsed_originalTitle.success) {
              return { success: false };
            }
            if (!hasProp(value, "previewAvailable")) {
              return { success: false };
            }
            const parsed_previewAvailable = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["previewAvailable"]);
            })();
            if (!parsed_previewAvailable.success) {
              return { success: false };
            }
            if (!hasProp(value, "publicationDate")) {
              return { success: false };
            }
            const parsed_publicationDate = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["publicationDate"]);
            })();
            if (!parsed_publicationDate.success) {
              return { success: false };
            }
            if (!hasProp(value, "publisherInfo")) {
              return { success: false };
            }
            const parsed_publisherInfo = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["publisherInfo"]);
            })();
            if (!parsed_publisherInfo.success) {
              return { success: false };
            }
            if (!hasProp(value, "publisherName")) {
              return { success: false };
            }
            const parsed_publisherName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["publisherName"]);
            })();
            if (!parsed_publisherName.success) {
              return { success: false };
            }
            if (!hasProp(value, "rating")) {
              return { success: false };
            }
            const parsed_rating = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["rating"]);
            })();
            if (!parsed_rating.success) {
              return { success: false };
            }
            if (!hasProp(value, "sourceType")) {
              return { success: false };
            }
            const parsed_sourceType = (() => {
              return ((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
                return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "BOOK":
                      return { success: true, value: "BOOK" };
                    case "VIDEO":
                      return { success: true, value: "VIDEO" };
                    case "ARTICLE":
                      return { success: true, value: "ARTICLE" };
                    case "CHAPTER":
                      return { success: true, value: "CHAPTER" };
                    case "REPORT":
                      return { success: true, value: "REPORT" };
                    case "PODCAST":
                      return { success: true, value: "PODCAST" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["sourceType"]);
            })();
            if (!parsed_sourceType.success) {
              return { success: false };
            }
            if (!hasProp(value, "subtitle")) {
              return { success: false };
            }
            const parsed_subtitle = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["subtitle"]);
            })();
            if (!parsed_subtitle.success) {
              return { success: false };
            }
            if (!hasProp(value, "summaryPages")) {
              return { success: false };
            }
            const parsed_summaryPages = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["summaryPages"]);
            })();
            if (!parsed_summaryPages.success) {
              return { success: false };
            }
            if (!hasProp(value, "summaryWords")) {
              return { success: false };
            }
            const parsed_summaryWords = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["summaryWords"]);
            })();
            if (!parsed_summaryWords.success) {
              return { success: false };
            }
            if (!hasProp(value, "tags")) {
              return { success: false };
            }
            const parsed_tags = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                  return ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value:
                          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                          | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                          | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                      } => {
                    return ((
                      value: unknown,
                    ):
                      | { success: false }
                      | {
                          success: true;
                          value:
                            | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                            | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                            | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                            | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                            | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                            | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                            | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                            | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                            | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                        } => {
                      if (value == null) {
                        return { success: false };
                      }
                      if (typeof value != "object") {
                        return { success: false };
                      }
                      if (!hasProp(value, "discriminator")) {
                        return { success: false };
                      }
                      if (typeof value["discriminator"] != "string") {
                        return { success: false };
                      }
                      const discriminator = value["discriminator"];
                      switch (discriminator) {
                        case "dynamicString": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicString;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicString;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicString":
                                            return { success: true, value: "dynamicString" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                        }
                        case "dynamicBoolean": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicBoolean;
                                  value: boolean;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicBoolean;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicBoolean":
                                            return { success: true, value: "dynamicBoolean" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                        }
                        case "dynamicNumber": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicNumber;
                                  value: number;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicNumber;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicNumber":
                                            return { success: true, value: "dynamicNumber" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: number } => {
                                if (typeof value === "number") {
                                  return { success: true, value: value };
                                }
                                return { success: false };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                        }
                        case "dynamicVoid": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindDynamicVoid;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindDynamicVoid;
                                        id: bigint;
                                        kind: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "dynamicVoid":
                                            return { success: true, value: "dynamicVoid" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                        }
                        case "channel": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindChannel;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindChannel;
                                        id: bigint;
                                        kind: KindChannel;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "channel":
                                            return { success: true, value: "channel" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "channel":
                                            return { success: true, value: "channel" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                        }
                        case "primary-skill": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindPrimarySkill;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindPrimarySkill;
                                        id: bigint;
                                        kind: KindPrimarySkill;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "primary-skill":
                                            return { success: true, value: "primary-skill" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "primary-skill":
                                            return { success: true, value: "primary-skill" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                        }
                        case "skill": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindSkill;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindSkill;
                                        id: bigint;
                                        kind: KindSkill;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "skill":
                                            return { success: true, value: "skill" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "skill":
                                            return { success: true, value: "skill" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                        }
                        case "key-quality": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindKeyQuality;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindKeyQuality;
                                        id: bigint;
                                        kind: KindKeyQuality;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "key-quality":
                                            return { success: true, value: "key-quality" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "key-quality":
                                            return { success: true, value: "key-quality" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                        }
                        case "classification": {
                          const parsedVariant = ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  exclude: boolean;
                                  source: TagSourceType;
                                  tagKind: TagKindClassification;
                                  value: string;
                                };
                              } => {
                            if (typeof value !== "object") {
                              return { success: false };
                            }
                            if (value === null) {
                              return { success: false };
                            }
                            if (!hasProp(value, "exclude")) {
                              return { success: false };
                            }
                            const parsed_exclude = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                if (typeof value !== "boolean") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["exclude"]);
                            })();
                            if (!parsed_exclude.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "source")) {
                              return { success: false };
                            }
                            const parsed_source = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  switch (value) {
                                    case "USER":
                                      return { success: true, value: "USER" };
                                    case "AI":
                                      return { success: true, value: "AI" };
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value["source"]);
                            })();
                            if (!parsed_source.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "tagKind")) {
                              return { success: false };
                            }
                            const parsed_tagKind = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        discriminator: KindClassification;
                                        id: bigint;
                                        kind: KindClassification;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  const parsed_discriminator = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "classification":
                                            return { success: true, value: "classification" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["discriminator"]);
                                  })();
                                  if (!parsed_discriminator.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "id")) {
                                    return { success: false };
                                  }
                                  const parsed_id = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: BigInt(value) };
                                      }
                                      if (typeof value === "bigint") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["id"]);
                                  })();
                                  if (!parsed_id.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "kind")) {
                                    return { success: false };
                                  }
                                  const parsed_kind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "classification":
                                            return { success: true, value: "classification" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["kind"]);
                                  })();
                                  if (!parsed_kind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                })(value);
                              })(value["tagKind"]);
                            })();
                            if (!parsed_tagKind.success) {
                              return { success: false };
                            }
                            if (!hasProp(value, "value")) {
                              return { success: false };
                            }
                            const parsed_value = (() => {
                              return ((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              })(value["value"]);
                            })();
                            if (!parsed_value.success) {
                              return { success: false };
                            }
                            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                          })(value);
                          if (!parsedVariant.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                        }
                        default:
                          return { success: false };
                      }
                    })(value);
                  })(value);
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["tags"]);
            })();
            if (!parsed_tags.success) {
              return { success: false };
            }
            if (!hasProp(value, "teaser")) {
              return { success: false };
            }
            const parsed_teaser = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["teaser"]);
            })();
            if (!parsed_teaser.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                activatedAt: parsed_activatedAt.value,
                activationTime: parsed_activationTime.value,
                active: parsed_active.value,
                audioActivatedAt: parsed_audioActivatedAt.value,
                audioActive: parsed_audioActive.value,
                audioDuration: parsed_audioDuration.value,
                authorInfo: parsed_authorInfo.value,
                authors: parsed_authors.value,
                backgroundColor: parsed_backgroundColor.value,
                bestseller: parsed_bestseller.value,
                businessClassic: parsed_businessClassic.value,
                canonicalName: parsed_canonicalName.value,
                contentItemId: parsed_contentItemId.value,
                contentItemType: parsed_contentItemType.value,
                countriesExcluded: parsed_countriesExcluded.value,
                countriesIncluded: parsed_countriesIncluded.value,
                coverModifiedAt: parsed_coverModifiedAt.value,
                coverUri: parsed_coverUri.value,
                dataId: parsed_dataId.value,
                descriptionHtml: parsed_descriptionHtml.value,
                documentType: parsed_documentType.value,
                duration: parsed_duration.value,
                imageBorder: parsed_imageBorder.value,
                language: parsed_language.value,
                modifiedAt: parsed_modifiedAt.value,
                originalTitle: parsed_originalTitle.value,
                previewAvailable: parsed_previewAvailable.value,
                publicationDate: parsed_publicationDate.value,
                publisherInfo: parsed_publisherInfo.value,
                publisherName: parsed_publisherName.value,
                rating: parsed_rating.value,
                sourceType: parsed_sourceType.value,
                subtitle: parsed_subtitle.value,
                summaryPages: parsed_summaryPages.value,
                summaryWords: parsed_summaryWords.value,
                tags: parsed_tags.value,
                teaser: parsed_teaser.value,
                title: parsed_title.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["summaryList"]);
    })();
    if (!parsed_summaryList.success) {
      return { success: false };
    }
    return { success: true, value: { summaryList: parsed_summaryList.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SummaryContent
export function serialize_SummaryContent(obj: SummaryContent): string {
  const serialized = ((value: {
    aboutAuthorHtml: string | null;
    aboutTextHtml: string | null;
    abstractTextHtml: string | null;
    bookId: number;
    dataId: number;
    historicalBackgroundHtml: string | null;
    introHtml: string | null;
    longReviewHtml: string | null;
    outputFormat: SummaryOutputFormat;
    reviewLeadHtml: string | null;
    reviewTitle: string | null;
    sourceIsbn: string | null;
    sourcePages: number;
    sourceUrl: string | null;
    takeaways: string[];
  }): unknown => {
    return {
      aboutAuthorHtml: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["aboutAuthorHtml"]),
      aboutTextHtml: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["aboutTextHtml"]),
      abstractTextHtml: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["abstractTextHtml"]),
      bookId: ((value: number): unknown => {
        return value;
      })(value["bookId"]),
      dataId: ((value: number): unknown => {
        return value;
      })(value["dataId"]),
      historicalBackgroundHtml: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["historicalBackgroundHtml"]),
      introHtml: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["introHtml"]),
      longReviewHtml: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["longReviewHtml"]),
      outputFormat: ((value: "TWO_PAGES" | "FIVE_PAGES" | "EIGHT_PAGES"): unknown => {
        return value;
      })(value["outputFormat"]),
      reviewLeadHtml: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["reviewLeadHtml"]),
      reviewTitle: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["reviewTitle"]),
      sourceIsbn: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["sourceIsbn"]),
      sourcePages: ((value: number): unknown => {
        return value;
      })(value["sourcePages"]),
      sourceUrl: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["sourceUrl"]),
      takeaways: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["takeaways"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SummaryContent(str: string): SummaryContent | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          aboutAuthorHtml: string | null;
          aboutTextHtml: string | null;
          abstractTextHtml: string | null;
          bookId: number;
          dataId: number;
          historicalBackgroundHtml: string | null;
          introHtml: string | null;
          longReviewHtml: string | null;
          outputFormat: SummaryOutputFormat;
          reviewLeadHtml: string | null;
          reviewTitle: string | null;
          sourceIsbn: string | null;
          sourcePages: number;
          sourceUrl: string | null;
          takeaways: string[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "aboutAuthorHtml")) {
      return { success: false };
    }
    const parsed_aboutAuthorHtml = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["aboutAuthorHtml"]);
    })();
    if (!parsed_aboutAuthorHtml.success) {
      return { success: false };
    }
    if (!hasProp(value, "aboutTextHtml")) {
      return { success: false };
    }
    const parsed_aboutTextHtml = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["aboutTextHtml"]);
    })();
    if (!parsed_aboutTextHtml.success) {
      return { success: false };
    }
    if (!hasProp(value, "abstractTextHtml")) {
      return { success: false };
    }
    const parsed_abstractTextHtml = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["abstractTextHtml"]);
    })();
    if (!parsed_abstractTextHtml.success) {
      return { success: false };
    }
    if (!hasProp(value, "bookId")) {
      return { success: false };
    }
    const parsed_bookId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["bookId"]);
    })();
    if (!parsed_bookId.success) {
      return { success: false };
    }
    if (!hasProp(value, "dataId")) {
      return { success: false };
    }
    const parsed_dataId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["dataId"]);
    })();
    if (!parsed_dataId.success) {
      return { success: false };
    }
    if (!hasProp(value, "historicalBackgroundHtml")) {
      return { success: false };
    }
    const parsed_historicalBackgroundHtml = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["historicalBackgroundHtml"]);
    })();
    if (!parsed_historicalBackgroundHtml.success) {
      return { success: false };
    }
    if (!hasProp(value, "introHtml")) {
      return { success: false };
    }
    const parsed_introHtml = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["introHtml"]);
    })();
    if (!parsed_introHtml.success) {
      return { success: false };
    }
    if (!hasProp(value, "longReviewHtml")) {
      return { success: false };
    }
    const parsed_longReviewHtml = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["longReviewHtml"]);
    })();
    if (!parsed_longReviewHtml.success) {
      return { success: false };
    }
    if (!hasProp(value, "outputFormat")) {
      return { success: false };
    }
    const parsed_outputFormat = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SummaryOutputFormat } => {
        return ((value: unknown): { success: false } | { success: true; value: "TWO_PAGES" | "FIVE_PAGES" | "EIGHT_PAGES" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "TWO_PAGES":
              return { success: true, value: "TWO_PAGES" };
            case "FIVE_PAGES":
              return { success: true, value: "FIVE_PAGES" };
            case "EIGHT_PAGES":
              return { success: true, value: "EIGHT_PAGES" };
            default:
              return { success: false };
          }
        })(value);
      })(value["outputFormat"]);
    })();
    if (!parsed_outputFormat.success) {
      return { success: false };
    }
    if (!hasProp(value, "reviewLeadHtml")) {
      return { success: false };
    }
    const parsed_reviewLeadHtml = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["reviewLeadHtml"]);
    })();
    if (!parsed_reviewLeadHtml.success) {
      return { success: false };
    }
    if (!hasProp(value, "reviewTitle")) {
      return { success: false };
    }
    const parsed_reviewTitle = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["reviewTitle"]);
    })();
    if (!parsed_reviewTitle.success) {
      return { success: false };
    }
    if (!hasProp(value, "sourceIsbn")) {
      return { success: false };
    }
    const parsed_sourceIsbn = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["sourceIsbn"]);
    })();
    if (!parsed_sourceIsbn.success) {
      return { success: false };
    }
    if (!hasProp(value, "sourcePages")) {
      return { success: false };
    }
    const parsed_sourcePages = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["sourcePages"]);
    })();
    if (!parsed_sourcePages.success) {
      return { success: false };
    }
    if (!hasProp(value, "sourceUrl")) {
      return { success: false };
    }
    const parsed_sourceUrl = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["sourceUrl"]);
    })();
    if (!parsed_sourceUrl.success) {
      return { success: false };
    }
    if (!hasProp(value, "takeaways")) {
      return { success: false };
    }
    const parsed_takeaways = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["takeaways"]);
    })();
    if (!parsed_takeaways.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        aboutAuthorHtml: parsed_aboutAuthorHtml.value,
        aboutTextHtml: parsed_aboutTextHtml.value,
        abstractTextHtml: parsed_abstractTextHtml.value,
        bookId: parsed_bookId.value,
        dataId: parsed_dataId.value,
        historicalBackgroundHtml: parsed_historicalBackgroundHtml.value,
        introHtml: parsed_introHtml.value,
        longReviewHtml: parsed_longReviewHtml.value,
        outputFormat: parsed_outputFormat.value,
        reviewLeadHtml: parsed_reviewLeadHtml.value,
        reviewTitle: parsed_reviewTitle.value,
        sourceIsbn: parsed_sourceIsbn.value,
        sourcePages: parsed_sourcePages.value,
        sourceUrl: parsed_sourceUrl.value,
        takeaways: parsed_takeaways.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SummaryCoverInformation
export function serialize_SummaryCoverInformation(obj: SummaryCoverInformation): string {
  const serialized = ((value: { imageUri: string; summarySourceType: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"; title: string | null }): unknown => {
    return {
      imageUri: ((value: string): unknown => {
        return value;
      })(value["imageUri"]),
      summarySourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
        return value;
      })(value["summarySourceType"]),
      title: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SummaryCoverInformation(str: string): SummaryCoverInformation | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          imageUri: string;
          summarySourceType: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST";
          title: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "imageUri")) {
      return { success: false };
    }
    const parsed_imageUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["imageUri"]);
    })();
    if (!parsed_imageUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "summarySourceType")) {
      return { success: false };
    }
    const parsed_summarySourceType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "BOOK":
            return { success: true, value: "BOOK" };
          case "VIDEO":
            return { success: true, value: "VIDEO" };
          case "ARTICLE":
            return { success: true, value: "ARTICLE" };
          case "CHAPTER":
            return { success: true, value: "CHAPTER" };
          case "REPORT":
            return { success: true, value: "REPORT" };
          case "PODCAST":
            return { success: true, value: "PODCAST" };
          default:
            return { success: false };
        }
      })(value["summarySourceType"]);
    })();
    if (!parsed_summarySourceType.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return { success: true, value: { imageUri: parsed_imageUri.value, summarySourceType: parsed_summarySourceType.value, title: parsed_title.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SummaryDocumentType
export function serialize_SummaryDocumentType(obj: SummaryDocumentType): string {
  const serialized = ((value: "ABSTRACT" | "REVIEW"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SummaryDocumentType(str: string): SummaryDocumentType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "ABSTRACT":
        return { success: true, value: "ABSTRACT" };
      case "REVIEW":
        return { success: true, value: "REVIEW" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SummaryId
export function serialize_SummaryId(obj: SummaryId): string {
  const serialized = ((value: { contentType: "SUMMARY"; id: bigint }): unknown => {
    return {
      contentType: ((value: "SUMMARY"): unknown => {
        return value;
      })(value["contentType"]),
      id: ((value: bigint): unknown => {
        return value;
      })(value["id"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SummaryId(str: string): SummaryId | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          contentType: "SUMMARY";
          id: bigint;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "contentType")) {
      return { success: false };
    }
    const parsed_contentType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "SUMMARY" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "SUMMARY":
            return { success: true, value: "SUMMARY" };
          default:
            return { success: false };
        }
      })(value["contentType"]);
    })();
    if (!parsed_contentType.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    return { success: true, value: { contentType: parsed_contentType.value, id: parsed_id.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SummaryListView
export function serialize_SummaryListView(obj: SummaryListView): string {
  const serialized = ((value: { authors: string; dataId: number; language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"; source: string; title: string }): unknown => {
    return {
      authors: ((value: string): unknown => {
        return value;
      })(value["authors"]),
      dataId: ((value: number): unknown => {
        return value;
      })(value["dataId"]),
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      source: ((value: string): unknown => {
        return value;
      })(value["source"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SummaryListView(str: string): SummaryListView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          authors: string;
          dataId: number;
          language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
          source: string;
          title: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "authors")) {
      return { success: false };
    }
    const parsed_authors = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["authors"]);
    })();
    if (!parsed_authors.success) {
      return { success: false };
    }
    if (!hasProp(value, "dataId")) {
      return { success: false };
    }
    const parsed_dataId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["dataId"]);
    })();
    if (!parsed_dataId.success) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        switch (value) {
          case "en":
            return { success: true, value: "en" };
          case "de":
            return { success: true, value: "de" };
          case "es":
            return { success: true, value: "es" };
          case "ru":
            return { success: true, value: "ru" };
          case "zh":
            return { success: true, value: "zh" };
          case "pt":
            return { success: true, value: "pt" };
          case "fr":
            return { success: true, value: "fr" };
          default:
            return { success: false };
        }
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "source")) {
      return { success: false };
    }
    const parsed_source = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["source"]);
    })();
    if (!parsed_source.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return { success: true, value: { authors: parsed_authors.value, dataId: parsed_dataId.value, language: parsed_language.value, source: parsed_source.value, title: parsed_title.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SummaryMetadata
export function serialize_SummaryMetadata(obj: SummaryMetadata): string {
  const serialized = ((value: {
    activatedAt: DateTime<true> | null;
    activationTime: DateTime<true> | null;
    active: boolean;
    audioActivatedAt: DateTime<true> | null;
    audioActive: boolean;
    audioDuration: number;
    authorInfo: string | null;
    authors: {
      authorId: number;
      firstName: string | null;
      gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
      lastName: string | null;
      middleName: string | null;
    }[];
    backgroundColor: string | null;
    bestseller: boolean;
    businessClassic: boolean;
    canonicalName: string;
    contentItemId: bigint;
    contentItemType: ContentItemType;
    countriesExcluded: Country[];
    countriesIncluded: Country[];
    coverModifiedAt: DateTime<true> | null;
    coverUri: string;
    dataId: number;
    descriptionHtml: string | null;
    documentType: SummaryDocumentType;
    duration: number;
    imageBorder: boolean;
    language: Language;
    modifiedAt: DateTime<true> | null;
    originalTitle: string | null;
    previewAvailable: boolean;
    publicationDate: string | null;
    publisherInfo: string | null;
    publisherName: string | null;
    rating: number | null;
    sourceType: SummarySourceType;
    subtitle: string | null;
    summaryPages: number;
    summaryWords: number;
    tags: Tag[];
    teaser: string | null;
    title: string | null;
  }): unknown => {
    return {
      activatedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["activatedAt"]),
      activationTime: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["activationTime"]),
      active: ((value: boolean): unknown => {
        return value;
      })(value["active"]),
      audioActivatedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["audioActivatedAt"]),
      audioActive: ((value: boolean): unknown => {
        return value;
      })(value["audioActive"]),
      audioDuration: ((value: number): unknown => {
        return value;
      })(value["audioDuration"]),
      authorInfo: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["authorInfo"]),
      authors: ((
        value: {
          authorId: number;
          firstName: string | null;
          gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
          lastName: string | null;
          middleName: string | null;
        }[],
      ): unknown => {
        return value.map((value: { authorId: number; firstName: string | null; gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null; lastName: string | null; middleName: string | null }): unknown => {
          return {
            authorId: ((value: number): unknown => {
              return value;
            })(value["authorId"]),
            firstName: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["firstName"]),
            gender: ((value: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["gender"]),
            lastName: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["lastName"]),
            middleName: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["middleName"]),
          };
        });
      })(value["authors"]),
      backgroundColor: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["backgroundColor"]),
      bestseller: ((value: boolean): unknown => {
        return value;
      })(value["bestseller"]),
      businessClassic: ((value: boolean): unknown => {
        return value;
      })(value["businessClassic"]),
      canonicalName: ((value: string): unknown => {
        return value;
      })(value["canonicalName"]),
      contentItemId: ((value: bigint): unknown => {
        return value;
      })(value["contentItemId"]),
      contentItemType: ((value: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE" | "MIAB"): unknown => {
        return value;
      })(value["contentItemType"]),
      countriesExcluded: ((value: Country[]): unknown => {
        return value.map(
          (value: {
            countryCode: string;
            currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
            names: {
              de: string;
              en: string;
              es: string;
              fr: string;
              pt: string;
              ru: string;
              zh: string;
            };
            phonePrefix: string;
            region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
          }): unknown => {
            return {
              countryCode: ((value: string): unknown => {
                return value;
              })(value["countryCode"]),
              currency: ((value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY"): unknown => {
                return value;
              })(value["currency"]),
              names: ((value: { de: string; en: string; es: string; fr: string; pt: string; ru: string; zh: string }): unknown => {
                return {
                  de: ((value: string): unknown => {
                    return value;
                  })(value["de"]),
                  en: ((value: string): unknown => {
                    return value;
                  })(value["en"]),
                  es: ((value: string): unknown => {
                    return value;
                  })(value["es"]),
                  fr: ((value: string): unknown => {
                    return value;
                  })(value["fr"]),
                  pt: ((value: string): unknown => {
                    return value;
                  })(value["pt"]),
                  ru: ((value: string): unknown => {
                    return value;
                  })(value["ru"]),
                  zh: ((value: string): unknown => {
                    return value;
                  })(value["zh"]),
                };
              })(value["names"]),
              phonePrefix: ((value: string): unknown => {
                return value;
              })(value["phonePrefix"]),
              region: ((value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA"): unknown => {
                return value;
              })(value["region"]),
            };
          },
        );
      })(value["countriesExcluded"]),
      countriesIncluded: ((value: Country[]): unknown => {
        return value.map(
          (value: {
            countryCode: string;
            currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
            names: {
              de: string;
              en: string;
              es: string;
              fr: string;
              pt: string;
              ru: string;
              zh: string;
            };
            phonePrefix: string;
            region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
          }): unknown => {
            return {
              countryCode: ((value: string): unknown => {
                return value;
              })(value["countryCode"]),
              currency: ((value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY"): unknown => {
                return value;
              })(value["currency"]),
              names: ((value: { de: string; en: string; es: string; fr: string; pt: string; ru: string; zh: string }): unknown => {
                return {
                  de: ((value: string): unknown => {
                    return value;
                  })(value["de"]),
                  en: ((value: string): unknown => {
                    return value;
                  })(value["en"]),
                  es: ((value: string): unknown => {
                    return value;
                  })(value["es"]),
                  fr: ((value: string): unknown => {
                    return value;
                  })(value["fr"]),
                  pt: ((value: string): unknown => {
                    return value;
                  })(value["pt"]),
                  ru: ((value: string): unknown => {
                    return value;
                  })(value["ru"]),
                  zh: ((value: string): unknown => {
                    return value;
                  })(value["zh"]),
                };
              })(value["names"]),
              phonePrefix: ((value: string): unknown => {
                return value;
              })(value["phonePrefix"]),
              region: ((value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA"): unknown => {
                return value;
              })(value["region"]),
            };
          },
        );
      })(value["countriesIncluded"]),
      coverModifiedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["coverModifiedAt"]),
      coverUri: ((value: string): unknown => {
        return value;
      })(value["coverUri"]),
      dataId: ((value: number): unknown => {
        return value;
      })(value["dataId"]),
      descriptionHtml: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["descriptionHtml"]),
      documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
        return value;
      })(value["documentType"]),
      duration: ((value: number): unknown => {
        return value;
      })(value["duration"]),
      imageBorder: ((value: boolean): unknown => {
        return value;
      })(value["imageBorder"]),
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      modifiedAt: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toMillis();
      })(value["modifiedAt"]),
      originalTitle: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["originalTitle"]),
      previewAvailable: ((value: boolean): unknown => {
        return value;
      })(value["previewAvailable"]),
      publicationDate: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["publicationDate"]),
      publisherInfo: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["publisherInfo"]),
      publisherName: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["publisherName"]),
      rating: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["rating"]),
      sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
        return value;
      })(value["sourceType"]),
      subtitle: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["subtitle"]),
      summaryPages: ((value: number): unknown => {
        return value;
      })(value["summaryPages"]),
      summaryWords: ((value: number): unknown => {
        return value;
      })(value["summaryWords"]),
      tags: ((value: Tag[]): unknown => {
        return value.map(
          (
            value:
              | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
              | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
              | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
              | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
              | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
              | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
              | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
              | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
              | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
          ): unknown => {
            switch (value.discriminator) {
              case "dynamicString": {
                return {
                  discriminator: "dynamicString",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicString"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicBoolean": {
                return {
                  discriminator: "dynamicBoolean",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicBoolean"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: boolean): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicNumber": {
                return {
                  discriminator: "dynamicNumber",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicNumber"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: number): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicVoid": {
                return {
                  discriminator: "dynamicVoid",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicVoid"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                };
              }
              case "channel": {
                return {
                  discriminator: "channel",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                    return {
                      discriminator: ((value: "channel"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "channel"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "primary-skill": {
                return {
                  discriminator: "primary-skill",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                    return {
                      discriminator: ((value: "primary-skill"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "primary-skill"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "skill": {
                return {
                  discriminator: "skill",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                    return {
                      discriminator: ((value: "skill"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "skill"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "key-quality": {
                return {
                  discriminator: "key-quality",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                    return {
                      discriminator: ((value: "key-quality"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "key-quality"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "classification": {
                return {
                  discriminator: "classification",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                    return {
                      discriminator: ((value: "classification"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "classification"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
            }
          },
        );
      })(value["tags"]),
      teaser: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["teaser"]),
      title: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SummaryMetadata(str: string): SummaryMetadata | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          activatedAt: DateTime<true> | null;
          activationTime: DateTime<true> | null;
          active: boolean;
          audioActivatedAt: DateTime<true> | null;
          audioActive: boolean;
          audioDuration: number;
          authorInfo: string | null;
          authors: {
            authorId: number;
            firstName: string | null;
            gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
            lastName: string | null;
            middleName: string | null;
          }[];
          backgroundColor: string | null;
          bestseller: boolean;
          businessClassic: boolean;
          canonicalName: string;
          contentItemId: bigint;
          contentItemType: ContentItemType;
          countriesExcluded: Country[];
          countriesIncluded: Country[];
          coverModifiedAt: DateTime<true> | null;
          coverUri: string;
          dataId: number;
          descriptionHtml: string | null;
          documentType: SummaryDocumentType;
          duration: number;
          imageBorder: boolean;
          language: Language;
          modifiedAt: DateTime<true> | null;
          originalTitle: string | null;
          previewAvailable: boolean;
          publicationDate: string | null;
          publisherInfo: string | null;
          publisherName: string | null;
          rating: number | null;
          sourceType: SummarySourceType;
          subtitle: string | null;
          summaryPages: number;
          summaryWords: number;
          tags: Tag[];
          teaser: string | null;
          title: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "activatedAt")) {
      return { success: false };
    }
    const parsed_activatedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["activatedAt"]);
    })();
    if (!parsed_activatedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "activationTime")) {
      return { success: false };
    }
    const parsed_activationTime = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["activationTime"]);
    })();
    if (!parsed_activationTime.success) {
      return { success: false };
    }
    if (!hasProp(value, "active")) {
      return { success: false };
    }
    const parsed_active = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["active"]);
    })();
    if (!parsed_active.success) {
      return { success: false };
    }
    if (!hasProp(value, "audioActivatedAt")) {
      return { success: false };
    }
    const parsed_audioActivatedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["audioActivatedAt"]);
    })();
    if (!parsed_audioActivatedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "audioActive")) {
      return { success: false };
    }
    const parsed_audioActive = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["audioActive"]);
    })();
    if (!parsed_audioActive.success) {
      return { success: false };
    }
    if (!hasProp(value, "audioDuration")) {
      return { success: false };
    }
    const parsed_audioDuration = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["audioDuration"]);
    })();
    if (!parsed_audioDuration.success) {
      return { success: false };
    }
    if (!hasProp(value, "authorInfo")) {
      return { success: false };
    }
    const parsed_authorInfo = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["authorInfo"]);
    })();
    if (!parsed_authorInfo.success) {
      return { success: false };
    }
    if (!hasProp(value, "authors")) {
      return { success: false };
    }
    const parsed_authors = (() => {
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value: {
              authorId: number;
              firstName: string | null;
              gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
              lastName: string | null;
              middleName: string | null;
            }[];
          } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map(
          (
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  authorId: number;
                  firstName: string | null;
                  gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                  lastName: string | null;
                  middleName: string | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "authorId")) {
              return { success: false };
            }
            const parsed_authorId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["authorId"]);
            })();
            if (!parsed_authorId.success) {
              return { success: false };
            }
            if (!hasProp(value, "firstName")) {
              return { success: false };
            }
            const parsed_firstName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["firstName"]);
            })();
            if (!parsed_firstName.success) {
              return { success: false };
            }
            if (!hasProp(value, "gender")) {
              return { success: false };
            }
            const parsed_gender = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "MALE":
                    return { success: true, value: "MALE" };
                  case "FEMALE":
                    return { success: true, value: "FEMALE" };
                  case "CUSTOM":
                    return { success: true, value: "CUSTOM" };
                  case "UNKNOWN":
                    return { success: true, value: "UNKNOWN" };
                  default:
                    return { success: false };
                }
              })(value["gender"]);
            })();
            if (!parsed_gender.success) {
              return { success: false };
            }
            if (!hasProp(value, "lastName")) {
              return { success: false };
            }
            const parsed_lastName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["lastName"]);
            })();
            if (!parsed_lastName.success) {
              return { success: false };
            }
            if (!hasProp(value, "middleName")) {
              return { success: false };
            }
            const parsed_middleName = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["middleName"]);
            })();
            if (!parsed_middleName.success) {
              return { success: false };
            }
            return { success: true, value: { authorId: parsed_authorId.value, firstName: parsed_firstName.value, gender: parsed_gender.value, lastName: parsed_lastName.value, middleName: parsed_middleName.value } };
          },
        );
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["authors"]);
    })();
    if (!parsed_authors.success) {
      return { success: false };
    }
    if (!hasProp(value, "backgroundColor")) {
      return { success: false };
    }
    const parsed_backgroundColor = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["backgroundColor"]);
    })();
    if (!parsed_backgroundColor.success) {
      return { success: false };
    }
    if (!hasProp(value, "bestseller")) {
      return { success: false };
    }
    const parsed_bestseller = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["bestseller"]);
    })();
    if (!parsed_bestseller.success) {
      return { success: false };
    }
    if (!hasProp(value, "businessClassic")) {
      return { success: false };
    }
    const parsed_businessClassic = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["businessClassic"]);
    })();
    if (!parsed_businessClassic.success) {
      return { success: false };
    }
    if (!hasProp(value, "canonicalName")) {
      return { success: false };
    }
    const parsed_canonicalName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["canonicalName"]);
    })();
    if (!parsed_canonicalName.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentItemId")) {
      return { success: false };
    }
    const parsed_contentItemId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["contentItemId"]);
    })();
    if (!parsed_contentItemId.success) {
      return { success: false };
    }
    if (!hasProp(value, "contentItemType")) {
      return { success: false };
    }
    const parsed_contentItemType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ContentItemType } => {
        return ((value: unknown): { success: false } | { success: true; value: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE" | "MIAB" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "UNKNOWN":
              return { success: true, value: "UNKNOWN" };
            case "SUMMARY":
              return { success: true, value: "SUMMARY" };
            case "CATEGORY":
              return { success: true, value: "CATEGORY" };
            case "CHANNEL":
              return { success: true, value: "CHANNEL" };
            case "LGXP":
              return { success: true, value: "LGXP" };
            case "ACTIONABLE":
              return { success: true, value: "ACTIONABLE" };
            case "MYLIST":
              return { success: true, value: "MYLIST" };
            case "CUSTOMPAGE":
              return { success: true, value: "CUSTOMPAGE" };
            case "MIAB":
              return { success: true, value: "MIAB" };
            default:
              return { success: false };
          }
        })(value);
      })(value["contentItemType"]);
    })();
    if (!parsed_contentItemType.success) {
      return { success: false };
    }
    if (!hasProp(value, "countriesExcluded")) {
      return { success: false };
    }
    const parsed_countriesExcluded = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Country[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Country } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  countryCode: string;
                  currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                  names: {
                    de: string;
                    en: string;
                    es: string;
                    fr: string;
                    pt: string;
                    ru: string;
                    zh: string;
                  };
                  phonePrefix: string;
                  region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "countryCode")) {
              return { success: false };
            }
            const parsed_countryCode = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["countryCode"]);
            })();
            if (!parsed_countryCode.success) {
              return { success: false };
            }
            if (!hasProp(value, "currency")) {
              return { success: false };
            }
            const parsed_currency = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "CHF":
                    return { success: true, value: "CHF" };
                  case "CNY":
                    return { success: true, value: "CNY" };
                  case "USD":
                    return { success: true, value: "USD" };
                  case "EUR":
                    return { success: true, value: "EUR" };
                  case "GBP":
                    return { success: true, value: "GBP" };
                  case "INR":
                    return { success: true, value: "INR" };
                  case "JPY":
                    return { success: true, value: "JPY" };
                  default:
                    return { success: false };
                }
              })(value["currency"]);
            })();
            if (!parsed_currency.success) {
              return { success: false };
            }
            if (!hasProp(value, "names")) {
              return { success: false };
            }
            const parsed_names = (() => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      de: string;
                      en: string;
                      es: string;
                      fr: string;
                      pt: string;
                      ru: string;
                      zh: string;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "de")) {
                  return { success: false };
                }
                const parsed_de = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["de"]);
                })();
                if (!parsed_de.success) {
                  return { success: false };
                }
                if (!hasProp(value, "en")) {
                  return { success: false };
                }
                const parsed_en = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["en"]);
                })();
                if (!parsed_en.success) {
                  return { success: false };
                }
                if (!hasProp(value, "es")) {
                  return { success: false };
                }
                const parsed_es = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["es"]);
                })();
                if (!parsed_es.success) {
                  return { success: false };
                }
                if (!hasProp(value, "fr")) {
                  return { success: false };
                }
                const parsed_fr = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["fr"]);
                })();
                if (!parsed_fr.success) {
                  return { success: false };
                }
                if (!hasProp(value, "pt")) {
                  return { success: false };
                }
                const parsed_pt = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["pt"]);
                })();
                if (!parsed_pt.success) {
                  return { success: false };
                }
                if (!hasProp(value, "ru")) {
                  return { success: false };
                }
                const parsed_ru = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["ru"]);
                })();
                if (!parsed_ru.success) {
                  return { success: false };
                }
                if (!hasProp(value, "zh")) {
                  return { success: false };
                }
                const parsed_zh = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["zh"]);
                })();
                if (!parsed_zh.success) {
                  return { success: false };
                }
                return { success: true, value: { de: parsed_de.value, en: parsed_en.value, es: parsed_es.value, fr: parsed_fr.value, pt: parsed_pt.value, ru: parsed_ru.value, zh: parsed_zh.value } };
              })(value["names"]);
            })();
            if (!parsed_names.success) {
              return { success: false };
            }
            if (!hasProp(value, "phonePrefix")) {
              return { success: false };
            }
            const parsed_phonePrefix = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["phonePrefix"]);
            })();
            if (!parsed_phonePrefix.success) {
              return { success: false };
            }
            if (!hasProp(value, "region")) {
              return { success: false };
            }
            const parsed_region = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "SOUTHAMERICA":
                    return { success: true, value: "SOUTHAMERICA" };
                  case "EUROPE":
                    return { success: true, value: "EUROPE" };
                  case "OCEANIA":
                    return { success: true, value: "OCEANIA" };
                  case "NORTHAMERICA":
                    return { success: true, value: "NORTHAMERICA" };
                  case "AFRICA":
                    return { success: true, value: "AFRICA" };
                  case "ANTARCTICA":
                    return { success: true, value: "ANTARCTICA" };
                  case "ASIA":
                    return { success: true, value: "ASIA" };
                  default:
                    return { success: false };
                }
              })(value["region"]);
            })();
            if (!parsed_region.success) {
              return { success: false };
            }
            return { success: true, value: { countryCode: parsed_countryCode.value, currency: parsed_currency.value, names: parsed_names.value, phonePrefix: parsed_phonePrefix.value, region: parsed_region.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["countriesExcluded"]);
    })();
    if (!parsed_countriesExcluded.success) {
      return { success: false };
    }
    if (!hasProp(value, "countriesIncluded")) {
      return { success: false };
    }
    const parsed_countriesIncluded = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Country[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Country } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  countryCode: string;
                  currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                  names: {
                    de: string;
                    en: string;
                    es: string;
                    fr: string;
                    pt: string;
                    ru: string;
                    zh: string;
                  };
                  phonePrefix: string;
                  region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "countryCode")) {
              return { success: false };
            }
            const parsed_countryCode = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["countryCode"]);
            })();
            if (!parsed_countryCode.success) {
              return { success: false };
            }
            if (!hasProp(value, "currency")) {
              return { success: false };
            }
            const parsed_currency = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "CHF":
                    return { success: true, value: "CHF" };
                  case "CNY":
                    return { success: true, value: "CNY" };
                  case "USD":
                    return { success: true, value: "USD" };
                  case "EUR":
                    return { success: true, value: "EUR" };
                  case "GBP":
                    return { success: true, value: "GBP" };
                  case "INR":
                    return { success: true, value: "INR" };
                  case "JPY":
                    return { success: true, value: "JPY" };
                  default:
                    return { success: false };
                }
              })(value["currency"]);
            })();
            if (!parsed_currency.success) {
              return { success: false };
            }
            if (!hasProp(value, "names")) {
              return { success: false };
            }
            const parsed_names = (() => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      de: string;
                      en: string;
                      es: string;
                      fr: string;
                      pt: string;
                      ru: string;
                      zh: string;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "de")) {
                  return { success: false };
                }
                const parsed_de = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["de"]);
                })();
                if (!parsed_de.success) {
                  return { success: false };
                }
                if (!hasProp(value, "en")) {
                  return { success: false };
                }
                const parsed_en = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["en"]);
                })();
                if (!parsed_en.success) {
                  return { success: false };
                }
                if (!hasProp(value, "es")) {
                  return { success: false };
                }
                const parsed_es = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["es"]);
                })();
                if (!parsed_es.success) {
                  return { success: false };
                }
                if (!hasProp(value, "fr")) {
                  return { success: false };
                }
                const parsed_fr = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["fr"]);
                })();
                if (!parsed_fr.success) {
                  return { success: false };
                }
                if (!hasProp(value, "pt")) {
                  return { success: false };
                }
                const parsed_pt = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["pt"]);
                })();
                if (!parsed_pt.success) {
                  return { success: false };
                }
                if (!hasProp(value, "ru")) {
                  return { success: false };
                }
                const parsed_ru = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["ru"]);
                })();
                if (!parsed_ru.success) {
                  return { success: false };
                }
                if (!hasProp(value, "zh")) {
                  return { success: false };
                }
                const parsed_zh = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["zh"]);
                })();
                if (!parsed_zh.success) {
                  return { success: false };
                }
                return { success: true, value: { de: parsed_de.value, en: parsed_en.value, es: parsed_es.value, fr: parsed_fr.value, pt: parsed_pt.value, ru: parsed_ru.value, zh: parsed_zh.value } };
              })(value["names"]);
            })();
            if (!parsed_names.success) {
              return { success: false };
            }
            if (!hasProp(value, "phonePrefix")) {
              return { success: false };
            }
            const parsed_phonePrefix = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["phonePrefix"]);
            })();
            if (!parsed_phonePrefix.success) {
              return { success: false };
            }
            if (!hasProp(value, "region")) {
              return { success: false };
            }
            const parsed_region = (() => {
              return ((value: unknown): { success: false } | { success: true; value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "SOUTHAMERICA":
                    return { success: true, value: "SOUTHAMERICA" };
                  case "EUROPE":
                    return { success: true, value: "EUROPE" };
                  case "OCEANIA":
                    return { success: true, value: "OCEANIA" };
                  case "NORTHAMERICA":
                    return { success: true, value: "NORTHAMERICA" };
                  case "AFRICA":
                    return { success: true, value: "AFRICA" };
                  case "ANTARCTICA":
                    return { success: true, value: "ANTARCTICA" };
                  case "ASIA":
                    return { success: true, value: "ASIA" };
                  default:
                    return { success: false };
                }
              })(value["region"]);
            })();
            if (!parsed_region.success) {
              return { success: false };
            }
            return { success: true, value: { countryCode: parsed_countryCode.value, currency: parsed_currency.value, names: parsed_names.value, phonePrefix: parsed_phonePrefix.value, region: parsed_region.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["countriesIncluded"]);
    })();
    if (!parsed_countriesIncluded.success) {
      return { success: false };
    }
    if (!hasProp(value, "coverModifiedAt")) {
      return { success: false };
    }
    const parsed_coverModifiedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["coverModifiedAt"]);
    })();
    if (!parsed_coverModifiedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "coverUri")) {
      return { success: false };
    }
    const parsed_coverUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["coverUri"]);
    })();
    if (!parsed_coverUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "dataId")) {
      return { success: false };
    }
    const parsed_dataId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["dataId"]);
    })();
    if (!parsed_dataId.success) {
      return { success: false };
    }
    if (!hasProp(value, "descriptionHtml")) {
      return { success: false };
    }
    const parsed_descriptionHtml = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["descriptionHtml"]);
    })();
    if (!parsed_descriptionHtml.success) {
      return { success: false };
    }
    if (!hasProp(value, "documentType")) {
      return { success: false };
    }
    const parsed_documentType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
        return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "ABSTRACT":
              return { success: true, value: "ABSTRACT" };
            case "REVIEW":
              return { success: true, value: "REVIEW" };
            default:
              return { success: false };
          }
        })(value);
      })(value["documentType"]);
    })();
    if (!parsed_documentType.success) {
      return { success: false };
    }
    if (!hasProp(value, "duration")) {
      return { success: false };
    }
    const parsed_duration = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["duration"]);
    })();
    if (!parsed_duration.success) {
      return { success: false };
    }
    if (!hasProp(value, "imageBorder")) {
      return { success: false };
    }
    const parsed_imageBorder = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["imageBorder"]);
    })();
    if (!parsed_imageBorder.success) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language } => {
        return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "en":
              return { success: true, value: "en" };
            case "de":
              return { success: true, value: "de" };
            case "es":
              return { success: true, value: "es" };
            case "ru":
              return { success: true, value: "ru" };
            case "zh":
              return { success: true, value: "zh" };
            case "pt":
              return { success: true, value: "pt" };
            case "fr":
              return { success: true, value: "fr" };
            default:
              return { success: false };
          }
        })(value);
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "modifiedAt")) {
      return { success: false };
    }
    const parsed_modifiedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["modifiedAt"]);
    })();
    if (!parsed_modifiedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "originalTitle")) {
      return { success: false };
    }
    const parsed_originalTitle = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["originalTitle"]);
    })();
    if (!parsed_originalTitle.success) {
      return { success: false };
    }
    if (!hasProp(value, "previewAvailable")) {
      return { success: false };
    }
    const parsed_previewAvailable = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["previewAvailable"]);
    })();
    if (!parsed_previewAvailable.success) {
      return { success: false };
    }
    if (!hasProp(value, "publicationDate")) {
      return { success: false };
    }
    const parsed_publicationDate = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["publicationDate"]);
    })();
    if (!parsed_publicationDate.success) {
      return { success: false };
    }
    if (!hasProp(value, "publisherInfo")) {
      return { success: false };
    }
    const parsed_publisherInfo = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["publisherInfo"]);
    })();
    if (!parsed_publisherInfo.success) {
      return { success: false };
    }
    if (!hasProp(value, "publisherName")) {
      return { success: false };
    }
    const parsed_publisherName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["publisherName"]);
    })();
    if (!parsed_publisherName.success) {
      return { success: false };
    }
    if (!hasProp(value, "rating")) {
      return { success: false };
    }
    const parsed_rating = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["rating"]);
    })();
    if (!parsed_rating.success) {
      return { success: false };
    }
    if (!hasProp(value, "sourceType")) {
      return { success: false };
    }
    const parsed_sourceType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
        return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "BOOK":
              return { success: true, value: "BOOK" };
            case "VIDEO":
              return { success: true, value: "VIDEO" };
            case "ARTICLE":
              return { success: true, value: "ARTICLE" };
            case "CHAPTER":
              return { success: true, value: "CHAPTER" };
            case "REPORT":
              return { success: true, value: "REPORT" };
            case "PODCAST":
              return { success: true, value: "PODCAST" };
            default:
              return { success: false };
          }
        })(value);
      })(value["sourceType"]);
    })();
    if (!parsed_sourceType.success) {
      return { success: false };
    }
    if (!hasProp(value, "subtitle")) {
      return { success: false };
    }
    const parsed_subtitle = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["subtitle"]);
    })();
    if (!parsed_subtitle.success) {
      return { success: false };
    }
    if (!hasProp(value, "summaryPages")) {
      return { success: false };
    }
    const parsed_summaryPages = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["summaryPages"]);
    })();
    if (!parsed_summaryPages.success) {
      return { success: false };
    }
    if (!hasProp(value, "summaryWords")) {
      return { success: false };
    }
    const parsed_summaryWords = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["summaryWords"]);
    })();
    if (!parsed_summaryWords.success) {
      return { success: false };
    }
    if (!hasProp(value, "tags")) {
      return { success: false };
    }
    const parsed_tags = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                  | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                    | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                    | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                    | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                    | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                    | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                    | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                    | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                    | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "discriminator")) {
                return { success: false };
              }
              if (typeof value["discriminator"] != "string") {
                return { success: false };
              }
              const discriminator = value["discriminator"];
              switch (discriminator) {
                case "dynamicString": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicString;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicString;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicString":
                                    return { success: true, value: "dynamicString" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                }
                case "dynamicBoolean": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicBoolean;
                          value: boolean;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicBoolean;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicBoolean":
                                    return { success: true, value: "dynamicBoolean" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                }
                case "dynamicNumber": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicNumber;
                          value: number;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicNumber;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicNumber":
                                    return { success: true, value: "dynamicNumber" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                }
                case "dynamicVoid": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicVoid;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicVoid;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicVoid":
                                    return { success: true, value: "dynamicVoid" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                }
                case "channel": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindChannel;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindChannel;
                                id: bigint;
                                kind: KindChannel;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                              return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "channel":
                                    return { success: true, value: "channel" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                              return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "channel":
                                    return { success: true, value: "channel" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                }
                case "primary-skill": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindPrimarySkill;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindPrimarySkill;
                                id: bigint;
                                kind: KindPrimarySkill;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "primary-skill":
                                    return { success: true, value: "primary-skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "primary-skill":
                                    return { success: true, value: "primary-skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                }
                case "skill": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindSkill;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindSkill;
                                id: bigint;
                                kind: KindSkill;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "skill":
                                    return { success: true, value: "skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "skill":
                                    return { success: true, value: "skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                }
                case "key-quality": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindKeyQuality;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindKeyQuality;
                                id: bigint;
                                kind: KindKeyQuality;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                              return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "key-quality":
                                    return { success: true, value: "key-quality" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                              return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "key-quality":
                                    return { success: true, value: "key-quality" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                }
                case "classification": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindClassification;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindClassification;
                                id: bigint;
                                kind: KindClassification;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                              return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "classification":
                                    return { success: true, value: "classification" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                              return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "classification":
                                    return { success: true, value: "classification" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["tags"]);
    })();
    if (!parsed_tags.success) {
      return { success: false };
    }
    if (!hasProp(value, "teaser")) {
      return { success: false };
    }
    const parsed_teaser = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["teaser"]);
    })();
    if (!parsed_teaser.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        activatedAt: parsed_activatedAt.value,
        activationTime: parsed_activationTime.value,
        active: parsed_active.value,
        audioActivatedAt: parsed_audioActivatedAt.value,
        audioActive: parsed_audioActive.value,
        audioDuration: parsed_audioDuration.value,
        authorInfo: parsed_authorInfo.value,
        authors: parsed_authors.value,
        backgroundColor: parsed_backgroundColor.value,
        bestseller: parsed_bestseller.value,
        businessClassic: parsed_businessClassic.value,
        canonicalName: parsed_canonicalName.value,
        contentItemId: parsed_contentItemId.value,
        contentItemType: parsed_contentItemType.value,
        countriesExcluded: parsed_countriesExcluded.value,
        countriesIncluded: parsed_countriesIncluded.value,
        coverModifiedAt: parsed_coverModifiedAt.value,
        coverUri: parsed_coverUri.value,
        dataId: parsed_dataId.value,
        descriptionHtml: parsed_descriptionHtml.value,
        documentType: parsed_documentType.value,
        duration: parsed_duration.value,
        imageBorder: parsed_imageBorder.value,
        language: parsed_language.value,
        modifiedAt: parsed_modifiedAt.value,
        originalTitle: parsed_originalTitle.value,
        previewAvailable: parsed_previewAvailable.value,
        publicationDate: parsed_publicationDate.value,
        publisherInfo: parsed_publisherInfo.value,
        publisherName: parsed_publisherName.value,
        rating: parsed_rating.value,
        sourceType: parsed_sourceType.value,
        subtitle: parsed_subtitle.value,
        summaryPages: parsed_summaryPages.value,
        summaryWords: parsed_summaryWords.value,
        tags: parsed_tags.value,
        teaser: parsed_teaser.value,
        title: parsed_title.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SummaryOutputFormat
export function serialize_SummaryOutputFormat(obj: SummaryOutputFormat): string {
  const serialized = ((value: "TWO_PAGES" | "FIVE_PAGES" | "EIGHT_PAGES"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SummaryOutputFormat(str: string): SummaryOutputFormat | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "TWO_PAGES" | "FIVE_PAGES" | "EIGHT_PAGES" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "TWO_PAGES":
        return { success: true, value: "TWO_PAGES" };
      case "FIVE_PAGES":
        return { success: true, value: "FIVE_PAGES" };
      case "EIGHT_PAGES":
        return { success: true, value: "EIGHT_PAGES" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SummarySearchFilters
export function serialize_SummarySearchFilters(obj: SummarySearchFilters): string {
  const serialized = ((value: {
    audioFormFilter: boolean;
    languageFormFilter: string[];
    miabFormFilter: boolean;
    publicationDateFormFilter: number[];
    qualityFormFilter: string[];
    ratingFormFilter: number[];
    sourceFormFilter: string[];
  }): unknown => {
    return {
      audioFormFilter: ((value: boolean): unknown => {
        return value;
      })(value["audioFormFilter"]),
      languageFormFilter: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["languageFormFilter"]),
      miabFormFilter: ((value: boolean): unknown => {
        return value;
      })(value["miabFormFilter"]),
      publicationDateFormFilter: ((value: number[]): unknown => {
        return value.map((value: number): unknown => {
          return value;
        });
      })(value["publicationDateFormFilter"]),
      qualityFormFilter: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["qualityFormFilter"]),
      ratingFormFilter: ((value: number[]): unknown => {
        return value.map((value: number): unknown => {
          return value;
        });
      })(value["ratingFormFilter"]),
      sourceFormFilter: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["sourceFormFilter"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SummarySearchFilters(str: string): SummarySearchFilters | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          audioFormFilter: boolean;
          languageFormFilter: string[];
          miabFormFilter: boolean;
          publicationDateFormFilter: number[];
          qualityFormFilter: string[];
          ratingFormFilter: number[];
          sourceFormFilter: string[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "audioFormFilter")) {
      return { success: false };
    }
    const parsed_audioFormFilter = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["audioFormFilter"]);
    })();
    if (!parsed_audioFormFilter.success) {
      return { success: false };
    }
    if (!hasProp(value, "languageFormFilter")) {
      return { success: false };
    }
    const parsed_languageFormFilter = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["languageFormFilter"]);
    })();
    if (!parsed_languageFormFilter.success) {
      return { success: false };
    }
    if (!hasProp(value, "miabFormFilter")) {
      return { success: false };
    }
    const parsed_miabFormFilter = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["miabFormFilter"]);
    })();
    if (!parsed_miabFormFilter.success) {
      return { success: false };
    }
    if (!hasProp(value, "publicationDateFormFilter")) {
      return { success: false };
    }
    const parsed_publicationDateFormFilter = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
          if (typeof value === "number") {
            return { success: true, value: value };
          }
          return { success: false };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["publicationDateFormFilter"]);
    })();
    if (!parsed_publicationDateFormFilter.success) {
      return { success: false };
    }
    if (!hasProp(value, "qualityFormFilter")) {
      return { success: false };
    }
    const parsed_qualityFormFilter = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["qualityFormFilter"]);
    })();
    if (!parsed_qualityFormFilter.success) {
      return { success: false };
    }
    if (!hasProp(value, "ratingFormFilter")) {
      return { success: false };
    }
    const parsed_ratingFormFilter = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: number } => {
          if (typeof value === "number") {
            return { success: true, value: value };
          }
          return { success: false };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["ratingFormFilter"]);
    })();
    if (!parsed_ratingFormFilter.success) {
      return { success: false };
    }
    if (!hasProp(value, "sourceFormFilter")) {
      return { success: false };
    }
    const parsed_sourceFormFilter = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["sourceFormFilter"]);
    })();
    if (!parsed_sourceFormFilter.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        audioFormFilter: parsed_audioFormFilter.value,
        languageFormFilter: parsed_languageFormFilter.value,
        miabFormFilter: parsed_miabFormFilter.value,
        publicationDateFormFilter: parsed_publicationDateFormFilter.value,
        qualityFormFilter: parsed_qualityFormFilter.value,
        ratingFormFilter: parsed_ratingFormFilter.value,
        sourceFormFilter: parsed_sourceFormFilter.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// SummarySourceType
export function serialize_SummarySourceType(obj: SummarySourceType): string {
  const serialized = ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_SummarySourceType(str: string): SummarySourceType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "BOOK":
        return { success: true, value: "BOOK" };
      case "VIDEO":
        return { success: true, value: "VIDEO" };
      case "ARTICLE":
        return { success: true, value: "ARTICLE" };
      case "CHAPTER":
        return { success: true, value: "CHAPTER" };
      case "REPORT":
        return { success: true, value: "REPORT" };
      case "PODCAST":
        return { success: true, value: "PODCAST" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// Tag
export function serialize_Tag(obj: Tag): string {
  const serialized = ((
    value:
      | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
      | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
      | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
      | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
      | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
      | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
      | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
      | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
      | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
  ): unknown => {
    switch (value.discriminator) {
      case "dynamicString": {
        return {
          discriminator: "dynamicString",
          exclude: ((value: boolean): unknown => {
            return value;
          })(value["exclude"]),
          source: ((value: "USER" | "AI"): unknown => {
            return value;
          })(value["source"]),
          tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
            return {
              discriminator: ((value: "dynamicString"): unknown => {
                return value;
              })(value["discriminator"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              kind: ((value: string): unknown => {
                return value;
              })(value["kind"]),
            };
          })(value["tagKind"]),
          value: ((value: string): unknown => {
            return value;
          })(value["value"]),
        };
      }
      case "dynamicBoolean": {
        return {
          discriminator: "dynamicBoolean",
          exclude: ((value: boolean): unknown => {
            return value;
          })(value["exclude"]),
          source: ((value: "USER" | "AI"): unknown => {
            return value;
          })(value["source"]),
          tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
            return {
              discriminator: ((value: "dynamicBoolean"): unknown => {
                return value;
              })(value["discriminator"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              kind: ((value: string): unknown => {
                return value;
              })(value["kind"]),
            };
          })(value["tagKind"]),
          value: ((value: boolean): unknown => {
            return value;
          })(value["value"]),
        };
      }
      case "dynamicNumber": {
        return {
          discriminator: "dynamicNumber",
          exclude: ((value: boolean): unknown => {
            return value;
          })(value["exclude"]),
          source: ((value: "USER" | "AI"): unknown => {
            return value;
          })(value["source"]),
          tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
            return {
              discriminator: ((value: "dynamicNumber"): unknown => {
                return value;
              })(value["discriminator"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              kind: ((value: string): unknown => {
                return value;
              })(value["kind"]),
            };
          })(value["tagKind"]),
          value: ((value: number): unknown => {
            return value;
          })(value["value"]),
        };
      }
      case "dynamicVoid": {
        return {
          discriminator: "dynamicVoid",
          exclude: ((value: boolean): unknown => {
            return value;
          })(value["exclude"]),
          source: ((value: "USER" | "AI"): unknown => {
            return value;
          })(value["source"]),
          tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
            return {
              discriminator: ((value: "dynamicVoid"): unknown => {
                return value;
              })(value["discriminator"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              kind: ((value: string): unknown => {
                return value;
              })(value["kind"]),
            };
          })(value["tagKind"]),
        };
      }
      case "channel": {
        return {
          discriminator: "channel",
          exclude: ((value: boolean): unknown => {
            return value;
          })(value["exclude"]),
          source: ((value: "USER" | "AI"): unknown => {
            return value;
          })(value["source"]),
          tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
            return {
              discriminator: ((value: "channel"): unknown => {
                return value;
              })(value["discriminator"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              kind: ((value: "channel"): unknown => {
                return value;
              })(value["kind"]),
            };
          })(value["tagKind"]),
          value: ((value: string): unknown => {
            return value;
          })(value["value"]),
        };
      }
      case "primary-skill": {
        return {
          discriminator: "primary-skill",
          exclude: ((value: boolean): unknown => {
            return value;
          })(value["exclude"]),
          source: ((value: "USER" | "AI"): unknown => {
            return value;
          })(value["source"]),
          tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
            return {
              discriminator: ((value: "primary-skill"): unknown => {
                return value;
              })(value["discriminator"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              kind: ((value: "primary-skill"): unknown => {
                return value;
              })(value["kind"]),
            };
          })(value["tagKind"]),
          value: ((value: string): unknown => {
            return value;
          })(value["value"]),
        };
      }
      case "skill": {
        return {
          discriminator: "skill",
          exclude: ((value: boolean): unknown => {
            return value;
          })(value["exclude"]),
          source: ((value: "USER" | "AI"): unknown => {
            return value;
          })(value["source"]),
          tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
            return {
              discriminator: ((value: "skill"): unknown => {
                return value;
              })(value["discriminator"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              kind: ((value: "skill"): unknown => {
                return value;
              })(value["kind"]),
            };
          })(value["tagKind"]),
          value: ((value: string): unknown => {
            return value;
          })(value["value"]),
        };
      }
      case "key-quality": {
        return {
          discriminator: "key-quality",
          exclude: ((value: boolean): unknown => {
            return value;
          })(value["exclude"]),
          source: ((value: "USER" | "AI"): unknown => {
            return value;
          })(value["source"]),
          tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
            return {
              discriminator: ((value: "key-quality"): unknown => {
                return value;
              })(value["discriminator"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              kind: ((value: "key-quality"): unknown => {
                return value;
              })(value["kind"]),
            };
          })(value["tagKind"]),
          value: ((value: string): unknown => {
            return value;
          })(value["value"]),
        };
      }
      case "classification": {
        return {
          discriminator: "classification",
          exclude: ((value: boolean): unknown => {
            return value;
          })(value["exclude"]),
          source: ((value: "USER" | "AI"): unknown => {
            return value;
          })(value["source"]),
          tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
            return {
              discriminator: ((value: "classification"): unknown => {
                return value;
              })(value["discriminator"]),
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
              kind: ((value: "classification"): unknown => {
                return value;
              })(value["kind"]),
            };
          })(value["tagKind"]),
          value: ((value: string): unknown => {
            return value;
          })(value["value"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_Tag(str: string): Tag | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value:
          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
          | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
          | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
      } => {
    return ((
      value: unknown,
    ):
      | { success: false }
      | {
          success: true;
          value:
            | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
            | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
            | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
            | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
            | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
            | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
            | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
            | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
            | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
        } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "discriminator")) {
        return { success: false };
      }
      if (typeof value["discriminator"] != "string") {
        return { success: false };
      }
      const discriminator = value["discriminator"];
      switch (discriminator) {
        case "dynamicString": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  exclude: boolean;
                  source: TagSourceType;
                  tagKind: TagKindDynamicString;
                  value: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "exclude")) {
              return { success: false };
            }
            const parsed_exclude = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["exclude"]);
            })();
            if (!parsed_exclude.success) {
              return { success: false };
            }
            if (!hasProp(value, "source")) {
              return { success: false };
            }
            const parsed_source = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "USER":
                      return { success: true, value: "USER" };
                    case "AI":
                      return { success: true, value: "AI" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["source"]);
            })();
            if (!parsed_source.success) {
              return { success: false };
            }
            if (!hasProp(value, "tagKind")) {
              return { success: false };
            }
            const parsed_tagKind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        discriminator: KindDynamicString;
                        id: bigint;
                        kind: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "discriminator")) {
                    return { success: false };
                  }
                  const parsed_discriminator = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                      return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "dynamicString":
                            return { success: true, value: "dynamicString" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["discriminator"]);
                  })();
                  if (!parsed_discriminator.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                })(value);
              })(value["tagKind"]);
            })();
            if (!parsed_tagKind.success) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
        }
        case "dynamicBoolean": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  exclude: boolean;
                  source: TagSourceType;
                  tagKind: TagKindDynamicBoolean;
                  value: boolean;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "exclude")) {
              return { success: false };
            }
            const parsed_exclude = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["exclude"]);
            })();
            if (!parsed_exclude.success) {
              return { success: false };
            }
            if (!hasProp(value, "source")) {
              return { success: false };
            }
            const parsed_source = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "USER":
                      return { success: true, value: "USER" };
                    case "AI":
                      return { success: true, value: "AI" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["source"]);
            })();
            if (!parsed_source.success) {
              return { success: false };
            }
            if (!hasProp(value, "tagKind")) {
              return { success: false };
            }
            const parsed_tagKind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        discriminator: KindDynamicBoolean;
                        id: bigint;
                        kind: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "discriminator")) {
                    return { success: false };
                  }
                  const parsed_discriminator = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                      return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "dynamicBoolean":
                            return { success: true, value: "dynamicBoolean" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["discriminator"]);
                  })();
                  if (!parsed_discriminator.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                })(value);
              })(value["tagKind"]);
            })();
            if (!parsed_tagKind.success) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
        }
        case "dynamicNumber": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  exclude: boolean;
                  source: TagSourceType;
                  tagKind: TagKindDynamicNumber;
                  value: number;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "exclude")) {
              return { success: false };
            }
            const parsed_exclude = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["exclude"]);
            })();
            if (!parsed_exclude.success) {
              return { success: false };
            }
            if (!hasProp(value, "source")) {
              return { success: false };
            }
            const parsed_source = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "USER":
                      return { success: true, value: "USER" };
                    case "AI":
                      return { success: true, value: "AI" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["source"]);
            })();
            if (!parsed_source.success) {
              return { success: false };
            }
            if (!hasProp(value, "tagKind")) {
              return { success: false };
            }
            const parsed_tagKind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        discriminator: KindDynamicNumber;
                        id: bigint;
                        kind: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "discriminator")) {
                    return { success: false };
                  }
                  const parsed_discriminator = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                      return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "dynamicNumber":
                            return { success: true, value: "dynamicNumber" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["discriminator"]);
                  })();
                  if (!parsed_discriminator.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                })(value);
              })(value["tagKind"]);
            })();
            if (!parsed_tagKind.success) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
        }
        case "dynamicVoid": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  exclude: boolean;
                  source: TagSourceType;
                  tagKind: TagKindDynamicVoid;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "exclude")) {
              return { success: false };
            }
            const parsed_exclude = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["exclude"]);
            })();
            if (!parsed_exclude.success) {
              return { success: false };
            }
            if (!hasProp(value, "source")) {
              return { success: false };
            }
            const parsed_source = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "USER":
                      return { success: true, value: "USER" };
                    case "AI":
                      return { success: true, value: "AI" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["source"]);
            })();
            if (!parsed_source.success) {
              return { success: false };
            }
            if (!hasProp(value, "tagKind")) {
              return { success: false };
            }
            const parsed_tagKind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        discriminator: KindDynamicVoid;
                        id: bigint;
                        kind: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "discriminator")) {
                    return { success: false };
                  }
                  const parsed_discriminator = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                      return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "dynamicVoid":
                            return { success: true, value: "dynamicVoid" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["discriminator"]);
                  })();
                  if (!parsed_discriminator.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                })(value);
              })(value["tagKind"]);
            })();
            if (!parsed_tagKind.success) {
              return { success: false };
            }
            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
        }
        case "channel": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  exclude: boolean;
                  source: TagSourceType;
                  tagKind: TagKindChannel;
                  value: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "exclude")) {
              return { success: false };
            }
            const parsed_exclude = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["exclude"]);
            })();
            if (!parsed_exclude.success) {
              return { success: false };
            }
            if (!hasProp(value, "source")) {
              return { success: false };
            }
            const parsed_source = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "USER":
                      return { success: true, value: "USER" };
                    case "AI":
                      return { success: true, value: "AI" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["source"]);
            })();
            if (!parsed_source.success) {
              return { success: false };
            }
            if (!hasProp(value, "tagKind")) {
              return { success: false };
            }
            const parsed_tagKind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        discriminator: KindChannel;
                        id: bigint;
                        kind: KindChannel;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "discriminator")) {
                    return { success: false };
                  }
                  const parsed_discriminator = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                      return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "channel":
                            return { success: true, value: "channel" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["discriminator"]);
                  })();
                  if (!parsed_discriminator.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                      return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "channel":
                            return { success: true, value: "channel" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                })(value);
              })(value["tagKind"]);
            })();
            if (!parsed_tagKind.success) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
        }
        case "primary-skill": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  exclude: boolean;
                  source: TagSourceType;
                  tagKind: TagKindPrimarySkill;
                  value: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "exclude")) {
              return { success: false };
            }
            const parsed_exclude = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["exclude"]);
            })();
            if (!parsed_exclude.success) {
              return { success: false };
            }
            if (!hasProp(value, "source")) {
              return { success: false };
            }
            const parsed_source = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "USER":
                      return { success: true, value: "USER" };
                    case "AI":
                      return { success: true, value: "AI" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["source"]);
            })();
            if (!parsed_source.success) {
              return { success: false };
            }
            if (!hasProp(value, "tagKind")) {
              return { success: false };
            }
            const parsed_tagKind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        discriminator: KindPrimarySkill;
                        id: bigint;
                        kind: KindPrimarySkill;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "discriminator")) {
                    return { success: false };
                  }
                  const parsed_discriminator = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                      return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "primary-skill":
                            return { success: true, value: "primary-skill" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["discriminator"]);
                  })();
                  if (!parsed_discriminator.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                      return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "primary-skill":
                            return { success: true, value: "primary-skill" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                })(value);
              })(value["tagKind"]);
            })();
            if (!parsed_tagKind.success) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
        }
        case "skill": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  exclude: boolean;
                  source: TagSourceType;
                  tagKind: TagKindSkill;
                  value: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "exclude")) {
              return { success: false };
            }
            const parsed_exclude = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["exclude"]);
            })();
            if (!parsed_exclude.success) {
              return { success: false };
            }
            if (!hasProp(value, "source")) {
              return { success: false };
            }
            const parsed_source = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "USER":
                      return { success: true, value: "USER" };
                    case "AI":
                      return { success: true, value: "AI" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["source"]);
            })();
            if (!parsed_source.success) {
              return { success: false };
            }
            if (!hasProp(value, "tagKind")) {
              return { success: false };
            }
            const parsed_tagKind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        discriminator: KindSkill;
                        id: bigint;
                        kind: KindSkill;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "discriminator")) {
                    return { success: false };
                  }
                  const parsed_discriminator = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                      return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "skill":
                            return { success: true, value: "skill" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["discriminator"]);
                  })();
                  if (!parsed_discriminator.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                      return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "skill":
                            return { success: true, value: "skill" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                })(value);
              })(value["tagKind"]);
            })();
            if (!parsed_tagKind.success) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
        }
        case "key-quality": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  exclude: boolean;
                  source: TagSourceType;
                  tagKind: TagKindKeyQuality;
                  value: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "exclude")) {
              return { success: false };
            }
            const parsed_exclude = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["exclude"]);
            })();
            if (!parsed_exclude.success) {
              return { success: false };
            }
            if (!hasProp(value, "source")) {
              return { success: false };
            }
            const parsed_source = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "USER":
                      return { success: true, value: "USER" };
                    case "AI":
                      return { success: true, value: "AI" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["source"]);
            })();
            if (!parsed_source.success) {
              return { success: false };
            }
            if (!hasProp(value, "tagKind")) {
              return { success: false };
            }
            const parsed_tagKind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        discriminator: KindKeyQuality;
                        id: bigint;
                        kind: KindKeyQuality;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "discriminator")) {
                    return { success: false };
                  }
                  const parsed_discriminator = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                      return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "key-quality":
                            return { success: true, value: "key-quality" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["discriminator"]);
                  })();
                  if (!parsed_discriminator.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                      return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "key-quality":
                            return { success: true, value: "key-quality" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                })(value);
              })(value["tagKind"]);
            })();
            if (!parsed_tagKind.success) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
        }
        case "classification": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  exclude: boolean;
                  source: TagSourceType;
                  tagKind: TagKindClassification;
                  value: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "exclude")) {
              return { success: false };
            }
            const parsed_exclude = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["exclude"]);
            })();
            if (!parsed_exclude.success) {
              return { success: false };
            }
            if (!hasProp(value, "source")) {
              return { success: false };
            }
            const parsed_source = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "USER":
                      return { success: true, value: "USER" };
                    case "AI":
                      return { success: true, value: "AI" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["source"]);
            })();
            if (!parsed_source.success) {
              return { success: false };
            }
            if (!hasProp(value, "tagKind")) {
              return { success: false };
            }
            const parsed_tagKind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        discriminator: KindClassification;
                        id: bigint;
                        kind: KindClassification;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "discriminator")) {
                    return { success: false };
                  }
                  const parsed_discriminator = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                      return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "classification":
                            return { success: true, value: "classification" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["discriminator"]);
                  })();
                  if (!parsed_discriminator.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "kind")) {
                    return { success: false };
                  }
                  const parsed_kind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                      return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "classification":
                            return { success: true, value: "classification" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["kind"]);
                  })();
                  if (!parsed_kind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                })(value);
              })(value["tagKind"]);
            })();
            if (!parsed_tagKind.success) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagAdminFilter
export function serialize_TagAdminFilter(obj: TagAdminFilter): string {
  const serialized = ((value: { query: string; tagKinds: TagKind[] }): unknown => {
    return {
      query: ((value: string): unknown => {
        return value;
      })(value["query"]),
      tagKinds: ((value: TagKind[]): unknown => {
        return value.map(
          (
            value:
              | { discriminator: "classification"; id: bigint; kind: KindClassification }
              | { discriminator: "channel"; id: bigint; kind: KindChannel }
              | { discriminator: "primary-skill"; id: bigint; kind: KindPrimarySkill }
              | { discriminator: "skill"; id: bigint; kind: KindSkill }
              | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
              | { discriminator: "dynamicVoid"; id: bigint; kind: string }
              | { discriminator: "dynamicString"; id: bigint; kind: string }
              | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
              | { discriminator: "dynamicNumber"; id: bigint; kind: string },
          ): unknown => {
            switch (value.discriminator) {
              case "classification": {
                return {
                  discriminator: "classification",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "classification"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
              case "channel": {
                return {
                  discriminator: "channel",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "channel"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
              case "primary-skill": {
                return {
                  discriminator: "primary-skill",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "primary-skill"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
              case "skill": {
                return {
                  discriminator: "skill",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "skill"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
              case "key-quality": {
                return {
                  discriminator: "key-quality",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "key-quality"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
              case "dynamicVoid": {
                return {
                  discriminator: "dynamicVoid",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
              case "dynamicString": {
                return {
                  discriminator: "dynamicString",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
              case "dynamicBoolean": {
                return {
                  discriminator: "dynamicBoolean",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
              case "dynamicNumber": {
                return {
                  discriminator: "dynamicNumber",
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              }
            }
          },
        );
      })(value["tagKinds"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagAdminFilter(str: string): TagAdminFilter | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          query: string;
          tagKinds: TagKind[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "query")) {
      return { success: false };
    }
    const parsed_query = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["query"]);
    })();
    if (!parsed_query.success) {
      return { success: false };
    }
    if (!hasProp(value, "tagKinds")) {
      return { success: false };
    }
    const parsed_tagKinds = (() => {
      return ((value: unknown): { success: false } | { success: true; value: TagKind[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: TagKind } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { discriminator: "classification"; id: bigint; kind: KindClassification }
                  | { discriminator: "channel"; id: bigint; kind: KindChannel }
                  | { discriminator: "primary-skill"; id: bigint; kind: KindPrimarySkill }
                  | { discriminator: "skill"; id: bigint; kind: KindSkill }
                  | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
                  | { discriminator: "dynamicVoid"; id: bigint; kind: string }
                  | { discriminator: "dynamicString"; id: bigint; kind: string }
                  | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
                  | { discriminator: "dynamicNumber"; id: bigint; kind: string };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { discriminator: "classification"; id: bigint; kind: KindClassification }
                    | { discriminator: "channel"; id: bigint; kind: KindChannel }
                    | { discriminator: "primary-skill"; id: bigint; kind: KindPrimarySkill }
                    | { discriminator: "skill"; id: bigint; kind: KindSkill }
                    | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
                    | { discriminator: "dynamicVoid"; id: bigint; kind: string }
                    | { discriminator: "dynamicString"; id: bigint; kind: string }
                    | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
                    | { discriminator: "dynamicNumber"; id: bigint; kind: string };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "discriminator")) {
                return { success: false };
              }
              if (typeof value["discriminator"] != "string") {
                return { success: false };
              }
              const discriminator = value["discriminator"];
              switch (discriminator) {
                case "classification": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: KindClassification;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                        return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "classification":
                              return { success: true, value: "classification" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                }
                case "channel": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: KindChannel;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                        return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "channel":
                              return { success: true, value: "channel" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                }
                case "primary-skill": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: KindPrimarySkill;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                        return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "primary-skill":
                              return { success: true, value: "primary-skill" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                }
                case "skill": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: KindSkill;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                        return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "skill":
                              return { success: true, value: "skill" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                }
                case "key-quality": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: KindKeyQuality;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                        return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "key-quality":
                              return { success: true, value: "key-quality" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                }
                case "dynamicVoid": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                }
                case "dynamicString": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                }
                case "dynamicBoolean": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                }
                case "dynamicNumber": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          id: bigint;
                          kind: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "id")) {
                      return { success: false };
                    }
                    const parsed_id = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                        if (typeof value === "number") {
                          return { success: true, value: BigInt(value) };
                        }
                        if (typeof value === "bigint") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["id"]);
                    })();
                    if (!parsed_id.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "kind")) {
                      return { success: false };
                    }
                    const parsed_kind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["kind"]);
                    })();
                    if (!parsed_kind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["tagKinds"]);
    })();
    if (!parsed_tagKinds.success) {
      return { success: false };
    }
    return { success: true, value: { query: parsed_query.value, tagKinds: parsed_tagKinds.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagArray
export function serialize_TagArray(obj: TagArray): string {
  const serialized = ((value: { arr: Tag[] }): unknown => {
    return {
      arr: ((value: Tag[]): unknown => {
        return value.map(
          (
            value:
              | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
              | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
              | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
              | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
              | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
              | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
              | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
              | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
              | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
          ): unknown => {
            switch (value.discriminator) {
              case "dynamicString": {
                return {
                  discriminator: "dynamicString",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicString"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicBoolean": {
                return {
                  discriminator: "dynamicBoolean",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicBoolean"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: boolean): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicNumber": {
                return {
                  discriminator: "dynamicNumber",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicNumber"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: number): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "dynamicVoid": {
                return {
                  discriminator: "dynamicVoid",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                    return {
                      discriminator: ((value: "dynamicVoid"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: string): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                };
              }
              case "channel": {
                return {
                  discriminator: "channel",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                    return {
                      discriminator: ((value: "channel"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "channel"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "primary-skill": {
                return {
                  discriminator: "primary-skill",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                    return {
                      discriminator: ((value: "primary-skill"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "primary-skill"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "skill": {
                return {
                  discriminator: "skill",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                    return {
                      discriminator: ((value: "skill"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "skill"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "key-quality": {
                return {
                  discriminator: "key-quality",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                    return {
                      discriminator: ((value: "key-quality"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "key-quality"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
              case "classification": {
                return {
                  discriminator: "classification",
                  exclude: ((value: boolean): unknown => {
                    return value;
                  })(value["exclude"]),
                  source: ((value: "USER" | "AI"): unknown => {
                    return value;
                  })(value["source"]),
                  tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                    return {
                      discriminator: ((value: "classification"): unknown => {
                        return value;
                      })(value["discriminator"]),
                      id: ((value: bigint): unknown => {
                        return value;
                      })(value["id"]),
                      kind: ((value: "classification"): unknown => {
                        return value;
                      })(value["kind"]),
                    };
                  })(value["tagKind"]),
                  value: ((value: string): unknown => {
                    return value;
                  })(value["value"]),
                };
              }
            }
          },
        );
      })(value["arr"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagArray(str: string): TagArray | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          arr: Tag[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "arr")) {
      return { success: false };
    }
    const parsed_arr = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                  | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
              } => {
            return ((
              value: unknown,
            ):
              | { success: false }
              | {
                  success: true;
                  value:
                    | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                    | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                    | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                    | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                    | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                    | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                    | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                    | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                    | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                } => {
              if (value == null) {
                return { success: false };
              }
              if (typeof value != "object") {
                return { success: false };
              }
              if (!hasProp(value, "discriminator")) {
                return { success: false };
              }
              if (typeof value["discriminator"] != "string") {
                return { success: false };
              }
              const discriminator = value["discriminator"];
              switch (discriminator) {
                case "dynamicString": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicString;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicString;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicString":
                                    return { success: true, value: "dynamicString" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                }
                case "dynamicBoolean": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicBoolean;
                          value: boolean;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicBoolean;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicBoolean":
                                    return { success: true, value: "dynamicBoolean" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                }
                case "dynamicNumber": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicNumber;
                          value: number;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicNumber;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicNumber":
                                    return { success: true, value: "dynamicNumber" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: number } => {
                        if (typeof value === "number") {
                          return { success: true, value: value };
                        }
                        return { success: false };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                }
                case "dynamicVoid": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindDynamicVoid;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindDynamicVoid;
                                id: bigint;
                                kind: string;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                              return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "dynamicVoid":
                                    return { success: true, value: "dynamicVoid" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                }
                case "channel": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindChannel;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindChannel;
                                id: bigint;
                                kind: KindChannel;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                              return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "channel":
                                    return { success: true, value: "channel" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                              return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "channel":
                                    return { success: true, value: "channel" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                }
                case "primary-skill": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindPrimarySkill;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindPrimarySkill;
                                id: bigint;
                                kind: KindPrimarySkill;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "primary-skill":
                                    return { success: true, value: "primary-skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "primary-skill":
                                    return { success: true, value: "primary-skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                }
                case "skill": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindSkill;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindSkill;
                                id: bigint;
                                kind: KindSkill;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "skill":
                                    return { success: true, value: "skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                              return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "skill":
                                    return { success: true, value: "skill" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                }
                case "key-quality": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindKeyQuality;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindKeyQuality;
                                id: bigint;
                                kind: KindKeyQuality;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                              return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "key-quality":
                                    return { success: true, value: "key-quality" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                              return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "key-quality":
                                    return { success: true, value: "key-quality" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                }
                case "classification": {
                  const parsedVariant = ((
                    value: unknown,
                  ):
                    | { success: false }
                    | {
                        success: true;
                        value: {
                          exclude: boolean;
                          source: TagSourceType;
                          tagKind: TagKindClassification;
                          value: string;
                        };
                      } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    if (!hasProp(value, "exclude")) {
                      return { success: false };
                    }
                    const parsed_exclude = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                        if (typeof value !== "boolean") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["exclude"]);
                    })();
                    if (!parsed_exclude.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "source")) {
                      return { success: false };
                    }
                    const parsed_source = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                        return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                          if (typeof value !== "string") {
                            return { success: false };
                          }
                          switch (value) {
                            case "USER":
                              return { success: true, value: "USER" };
                            case "AI":
                              return { success: true, value: "AI" };
                            default:
                              return { success: false };
                          }
                        })(value);
                      })(value["source"]);
                    })();
                    if (!parsed_source.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "tagKind")) {
                      return { success: false };
                    }
                    const parsed_tagKind = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                discriminator: KindClassification;
                                id: bigint;
                                kind: KindClassification;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "discriminator")) {
                            return { success: false };
                          }
                          const parsed_discriminator = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                              return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "classification":
                                    return { success: true, value: "classification" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["discriminator"]);
                          })();
                          if (!parsed_discriminator.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "id")) {
                            return { success: false };
                          }
                          const parsed_id = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                              if (typeof value === "number") {
                                return { success: true, value: BigInt(value) };
                              }
                              if (typeof value === "bigint") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["id"]);
                          })();
                          if (!parsed_id.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "kind")) {
                            return { success: false };
                          }
                          const parsed_kind = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                              return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                switch (value) {
                                  case "classification":
                                    return { success: true, value: "classification" };
                                  default:
                                    return { success: false };
                                }
                              })(value);
                            })(value["kind"]);
                          })();
                          if (!parsed_kind.success) {
                            return { success: false };
                          }
                          return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                        })(value);
                      })(value["tagKind"]);
                    })();
                    if (!parsed_tagKind.success) {
                      return { success: false };
                    }
                    if (!hasProp(value, "value")) {
                      return { success: false };
                    }
                    const parsed_value = (() => {
                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      })(value["value"]);
                    })();
                    if (!parsed_value.success) {
                      return { success: false };
                    }
                    return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                  })(value);
                  if (!parsedVariant.success) {
                    return { success: false };
                  }
                  return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                }
                default:
                  return { success: false };
              }
            })(value);
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["arr"]);
    })();
    if (!parsed_arr.success) {
      return { success: false };
    }
    return { success: true, value: { arr: parsed_arr.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagImport
export function serialize_TagImport(obj: TagImport): string {
  const serialized = ((value: {
    data: {
      dataId: number;
      primary_skill: string;
      secondary_skills: string[];
    }[];
  }): unknown => {
    return {
      data: ((
        value: {
          dataId: number;
          primary_skill: string;
          secondary_skills: string[];
        }[],
      ): unknown => {
        return value.map((value: { dataId: number; primary_skill: string; secondary_skills: string[] }): unknown => {
          return {
            dataId: ((value: number): unknown => {
              return value;
            })(value["dataId"]),
            primary_skill: ((value: string): unknown => {
              return value;
            })(value["primary_skill"]),
            secondary_skills: ((value: string[]): unknown => {
              return value.map((value: string): unknown => {
                return value;
              });
            })(value["secondary_skills"]),
          };
        });
      })(value["data"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagImport(str: string): TagImport | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          data: {
            dataId: number;
            primary_skill: string;
            secondary_skills: string[];
          }[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "data")) {
      return { success: false };
    }
    const parsed_data = (() => {
      return ((
        value: unknown,
      ):
        | { success: false }
        | {
            success: true;
            value: {
              dataId: number;
              primary_skill: string;
              secondary_skills: string[];
            }[];
          } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map(
          (
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  dataId: number;
                  primary_skill: string;
                  secondary_skills: string[];
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "dataId")) {
              return { success: false };
            }
            const parsed_dataId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["dataId"]);
            })();
            if (!parsed_dataId.success) {
              return { success: false };
            }
            if (!hasProp(value, "primary_skill")) {
              return { success: false };
            }
            const parsed_primary_skill = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["primary_skill"]);
            })();
            if (!parsed_primary_skill.success) {
              return { success: false };
            }
            if (!hasProp(value, "secondary_skills")) {
              return { success: false };
            }
            const parsed_secondary_skills = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value["secondary_skills"]);
            })();
            if (!parsed_secondary_skills.success) {
              return { success: false };
            }
            return { success: true, value: { dataId: parsed_dataId.value, primary_skill: parsed_primary_skill.value, secondary_skills: parsed_secondary_skills.value } };
          },
        );
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["data"]);
    })();
    if (!parsed_data.success) {
      return { success: false };
    }
    return { success: true, value: { data: parsed_data.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagKind
export function serialize_TagKind(obj: TagKind): string {
  const serialized = ((
    value:
      | { discriminator: "classification"; id: bigint; kind: KindClassification }
      | { discriminator: "channel"; id: bigint; kind: KindChannel }
      | { discriminator: "primary-skill"; id: bigint; kind: KindPrimarySkill }
      | { discriminator: "skill"; id: bigint; kind: KindSkill }
      | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
      | { discriminator: "dynamicVoid"; id: bigint; kind: string }
      | { discriminator: "dynamicString"; id: bigint; kind: string }
      | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
      | { discriminator: "dynamicNumber"; id: bigint; kind: string },
  ): unknown => {
    switch (value.discriminator) {
      case "classification": {
        return {
          discriminator: "classification",
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
          kind: ((value: "classification"): unknown => {
            return value;
          })(value["kind"]),
        };
      }
      case "channel": {
        return {
          discriminator: "channel",
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
          kind: ((value: "channel"): unknown => {
            return value;
          })(value["kind"]),
        };
      }
      case "primary-skill": {
        return {
          discriminator: "primary-skill",
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
          kind: ((value: "primary-skill"): unknown => {
            return value;
          })(value["kind"]),
        };
      }
      case "skill": {
        return {
          discriminator: "skill",
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
          kind: ((value: "skill"): unknown => {
            return value;
          })(value["kind"]),
        };
      }
      case "key-quality": {
        return {
          discriminator: "key-quality",
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
          kind: ((value: "key-quality"): unknown => {
            return value;
          })(value["kind"]),
        };
      }
      case "dynamicVoid": {
        return {
          discriminator: "dynamicVoid",
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
          kind: ((value: string): unknown => {
            return value;
          })(value["kind"]),
        };
      }
      case "dynamicString": {
        return {
          discriminator: "dynamicString",
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
          kind: ((value: string): unknown => {
            return value;
          })(value["kind"]),
        };
      }
      case "dynamicBoolean": {
        return {
          discriminator: "dynamicBoolean",
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
          kind: ((value: string): unknown => {
            return value;
          })(value["kind"]),
        };
      }
      case "dynamicNumber": {
        return {
          discriminator: "dynamicNumber",
          id: ((value: bigint): unknown => {
            return value;
          })(value["id"]),
          kind: ((value: string): unknown => {
            return value;
          })(value["kind"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagKind(str: string): TagKind | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value:
          | { discriminator: "classification"; id: bigint; kind: KindClassification }
          | { discriminator: "channel"; id: bigint; kind: KindChannel }
          | { discriminator: "primary-skill"; id: bigint; kind: KindPrimarySkill }
          | { discriminator: "skill"; id: bigint; kind: KindSkill }
          | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
          | { discriminator: "dynamicVoid"; id: bigint; kind: string }
          | { discriminator: "dynamicString"; id: bigint; kind: string }
          | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
          | { discriminator: "dynamicNumber"; id: bigint; kind: string };
      } => {
    return ((
      value: unknown,
    ):
      | { success: false }
      | {
          success: true;
          value:
            | { discriminator: "classification"; id: bigint; kind: KindClassification }
            | { discriminator: "channel"; id: bigint; kind: KindChannel }
            | { discriminator: "primary-skill"; id: bigint; kind: KindPrimarySkill }
            | { discriminator: "skill"; id: bigint; kind: KindSkill }
            | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
            | { discriminator: "dynamicVoid"; id: bigint; kind: string }
            | { discriminator: "dynamicString"; id: bigint; kind: string }
            | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
            | { discriminator: "dynamicNumber"; id: bigint; kind: string };
        } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "discriminator")) {
        return { success: false };
      }
      if (typeof value["discriminator"] != "string") {
        return { success: false };
      }
      const discriminator = value["discriminator"];
      switch (discriminator) {
        case "classification": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: bigint;
                  kind: KindClassification;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            if (!hasProp(value, "kind")) {
              return { success: false };
            }
            const parsed_kind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "classification":
                      return { success: true, value: "classification" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["kind"]);
            })();
            if (!parsed_kind.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
        }
        case "channel": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: bigint;
                  kind: KindChannel;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            if (!hasProp(value, "kind")) {
              return { success: false };
            }
            const parsed_kind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "channel":
                      return { success: true, value: "channel" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["kind"]);
            })();
            if (!parsed_kind.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
        }
        case "primary-skill": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: bigint;
                  kind: KindPrimarySkill;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            if (!hasProp(value, "kind")) {
              return { success: false };
            }
            const parsed_kind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "primary-skill":
                      return { success: true, value: "primary-skill" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["kind"]);
            })();
            if (!parsed_kind.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
        }
        case "skill": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: bigint;
                  kind: KindSkill;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            if (!hasProp(value, "kind")) {
              return { success: false };
            }
            const parsed_kind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "skill":
                      return { success: true, value: "skill" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["kind"]);
            })();
            if (!parsed_kind.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
        }
        case "key-quality": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: bigint;
                  kind: KindKeyQuality;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            if (!hasProp(value, "kind")) {
              return { success: false };
            }
            const parsed_kind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "key-quality":
                      return { success: true, value: "key-quality" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["kind"]);
            })();
            if (!parsed_kind.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
        }
        case "dynamicVoid": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: bigint;
                  kind: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            if (!hasProp(value, "kind")) {
              return { success: false };
            }
            const parsed_kind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["kind"]);
            })();
            if (!parsed_kind.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
        }
        case "dynamicString": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: bigint;
                  kind: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            if (!hasProp(value, "kind")) {
              return { success: false };
            }
            const parsed_kind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["kind"]);
            })();
            if (!parsed_kind.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
        }
        case "dynamicBoolean": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: bigint;
                  kind: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            if (!hasProp(value, "kind")) {
              return { success: false };
            }
            const parsed_kind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["kind"]);
            })();
            if (!parsed_kind.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
        }
        case "dynamicNumber": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  id: bigint;
                  kind: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "id")) {
              return { success: false };
            }
            const parsed_id = (() => {
              return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                if (typeof value === "number") {
                  return { success: true, value: BigInt(value) };
                }
                if (typeof value === "bigint") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["id"]);
            })();
            if (!parsed_id.success) {
              return { success: false };
            }
            if (!hasProp(value, "kind")) {
              return { success: false };
            }
            const parsed_kind = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["kind"]);
            })();
            if (!parsed_kind.success) {
              return { success: false };
            }
            return { success: true, value: { id: parsed_id.value, kind: parsed_kind.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagKindChannel
export function serialize_TagKindChannel(obj: TagKindChannel): string {
  const serialized = ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
    return {
      discriminator: ((value: "channel"): unknown => {
        return value;
      })(value["discriminator"]),
      id: ((value: bigint): unknown => {
        return value;
      })(value["id"]),
      kind: ((value: "channel"): unknown => {
        return value;
      })(value["kind"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagKindChannel(str: string): TagKindChannel | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          discriminator: KindChannel;
          id: bigint;
          kind: KindChannel;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "discriminator")) {
      return { success: false };
    }
    const parsed_discriminator = (() => {
      return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
        return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "channel":
              return { success: true, value: "channel" };
            default:
              return { success: false };
          }
        })(value);
      })(value["discriminator"]);
    })();
    if (!parsed_discriminator.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    if (!hasProp(value, "kind")) {
      return { success: false };
    }
    const parsed_kind = (() => {
      return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
        return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "channel":
              return { success: true, value: "channel" };
            default:
              return { success: false };
          }
        })(value);
      })(value["kind"]);
    })();
    if (!parsed_kind.success) {
      return { success: false };
    }
    return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagKindClassification
export function serialize_TagKindClassification(obj: TagKindClassification): string {
  const serialized = ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
    return {
      discriminator: ((value: "classification"): unknown => {
        return value;
      })(value["discriminator"]),
      id: ((value: bigint): unknown => {
        return value;
      })(value["id"]),
      kind: ((value: "classification"): unknown => {
        return value;
      })(value["kind"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagKindClassification(str: string): TagKindClassification | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          discriminator: KindClassification;
          id: bigint;
          kind: KindClassification;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "discriminator")) {
      return { success: false };
    }
    const parsed_discriminator = (() => {
      return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
        return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "classification":
              return { success: true, value: "classification" };
            default:
              return { success: false };
          }
        })(value);
      })(value["discriminator"]);
    })();
    if (!parsed_discriminator.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    if (!hasProp(value, "kind")) {
      return { success: false };
    }
    const parsed_kind = (() => {
      return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
        return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "classification":
              return { success: true, value: "classification" };
            default:
              return { success: false };
          }
        })(value);
      })(value["kind"]);
    })();
    if (!parsed_kind.success) {
      return { success: false };
    }
    return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagKindDynamicBoolean
export function serialize_TagKindDynamicBoolean(obj: TagKindDynamicBoolean): string {
  const serialized = ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
    return {
      discriminator: ((value: "dynamicBoolean"): unknown => {
        return value;
      })(value["discriminator"]),
      id: ((value: bigint): unknown => {
        return value;
      })(value["id"]),
      kind: ((value: string): unknown => {
        return value;
      })(value["kind"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagKindDynamicBoolean(str: string): TagKindDynamicBoolean | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          discriminator: KindDynamicBoolean;
          id: bigint;
          kind: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "discriminator")) {
      return { success: false };
    }
    const parsed_discriminator = (() => {
      return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
        return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "dynamicBoolean":
              return { success: true, value: "dynamicBoolean" };
            default:
              return { success: false };
          }
        })(value);
      })(value["discriminator"]);
    })();
    if (!parsed_discriminator.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    if (!hasProp(value, "kind")) {
      return { success: false };
    }
    const parsed_kind = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["kind"]);
    })();
    if (!parsed_kind.success) {
      return { success: false };
    }
    return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagKindDynamicNumber
export function serialize_TagKindDynamicNumber(obj: TagKindDynamicNumber): string {
  const serialized = ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
    return {
      discriminator: ((value: "dynamicNumber"): unknown => {
        return value;
      })(value["discriminator"]),
      id: ((value: bigint): unknown => {
        return value;
      })(value["id"]),
      kind: ((value: string): unknown => {
        return value;
      })(value["kind"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagKindDynamicNumber(str: string): TagKindDynamicNumber | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          discriminator: KindDynamicNumber;
          id: bigint;
          kind: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "discriminator")) {
      return { success: false };
    }
    const parsed_discriminator = (() => {
      return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
        return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "dynamicNumber":
              return { success: true, value: "dynamicNumber" };
            default:
              return { success: false };
          }
        })(value);
      })(value["discriminator"]);
    })();
    if (!parsed_discriminator.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    if (!hasProp(value, "kind")) {
      return { success: false };
    }
    const parsed_kind = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["kind"]);
    })();
    if (!parsed_kind.success) {
      return { success: false };
    }
    return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagKindDynamicString
export function serialize_TagKindDynamicString(obj: TagKindDynamicString): string {
  const serialized = ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
    return {
      discriminator: ((value: "dynamicString"): unknown => {
        return value;
      })(value["discriminator"]),
      id: ((value: bigint): unknown => {
        return value;
      })(value["id"]),
      kind: ((value: string): unknown => {
        return value;
      })(value["kind"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagKindDynamicString(str: string): TagKindDynamicString | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          discriminator: KindDynamicString;
          id: bigint;
          kind: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "discriminator")) {
      return { success: false };
    }
    const parsed_discriminator = (() => {
      return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
        return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "dynamicString":
              return { success: true, value: "dynamicString" };
            default:
              return { success: false };
          }
        })(value);
      })(value["discriminator"]);
    })();
    if (!parsed_discriminator.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    if (!hasProp(value, "kind")) {
      return { success: false };
    }
    const parsed_kind = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["kind"]);
    })();
    if (!parsed_kind.success) {
      return { success: false };
    }
    return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagKindDynamicVoid
export function serialize_TagKindDynamicVoid(obj: TagKindDynamicVoid): string {
  const serialized = ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
    return {
      discriminator: ((value: "dynamicVoid"): unknown => {
        return value;
      })(value["discriminator"]),
      id: ((value: bigint): unknown => {
        return value;
      })(value["id"]),
      kind: ((value: string): unknown => {
        return value;
      })(value["kind"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagKindDynamicVoid(str: string): TagKindDynamicVoid | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          discriminator: KindDynamicVoid;
          id: bigint;
          kind: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "discriminator")) {
      return { success: false };
    }
    const parsed_discriminator = (() => {
      return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
        return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "dynamicVoid":
              return { success: true, value: "dynamicVoid" };
            default:
              return { success: false };
          }
        })(value);
      })(value["discriminator"]);
    })();
    if (!parsed_discriminator.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    if (!hasProp(value, "kind")) {
      return { success: false };
    }
    const parsed_kind = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["kind"]);
    })();
    if (!parsed_kind.success) {
      return { success: false };
    }
    return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagKindKeyQuality
export function serialize_TagKindKeyQuality(obj: TagKindKeyQuality): string {
  const serialized = ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
    return {
      discriminator: ((value: "key-quality"): unknown => {
        return value;
      })(value["discriminator"]),
      id: ((value: bigint): unknown => {
        return value;
      })(value["id"]),
      kind: ((value: "key-quality"): unknown => {
        return value;
      })(value["kind"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagKindKeyQuality(str: string): TagKindKeyQuality | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          discriminator: KindKeyQuality;
          id: bigint;
          kind: KindKeyQuality;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "discriminator")) {
      return { success: false };
    }
    const parsed_discriminator = (() => {
      return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
        return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "key-quality":
              return { success: true, value: "key-quality" };
            default:
              return { success: false };
          }
        })(value);
      })(value["discriminator"]);
    })();
    if (!parsed_discriminator.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    if (!hasProp(value, "kind")) {
      return { success: false };
    }
    const parsed_kind = (() => {
      return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
        return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "key-quality":
              return { success: true, value: "key-quality" };
            default:
              return { success: false };
          }
        })(value);
      })(value["kind"]);
    })();
    if (!parsed_kind.success) {
      return { success: false };
    }
    return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagKindPrimarySkill
export function serialize_TagKindPrimarySkill(obj: TagKindPrimarySkill): string {
  const serialized = ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
    return {
      discriminator: ((value: "primary-skill"): unknown => {
        return value;
      })(value["discriminator"]),
      id: ((value: bigint): unknown => {
        return value;
      })(value["id"]),
      kind: ((value: "primary-skill"): unknown => {
        return value;
      })(value["kind"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagKindPrimarySkill(str: string): TagKindPrimarySkill | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          discriminator: KindPrimarySkill;
          id: bigint;
          kind: KindPrimarySkill;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "discriminator")) {
      return { success: false };
    }
    const parsed_discriminator = (() => {
      return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
        return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "primary-skill":
              return { success: true, value: "primary-skill" };
            default:
              return { success: false };
          }
        })(value);
      })(value["discriminator"]);
    })();
    if (!parsed_discriminator.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    if (!hasProp(value, "kind")) {
      return { success: false };
    }
    const parsed_kind = (() => {
      return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
        return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "primary-skill":
              return { success: true, value: "primary-skill" };
            default:
              return { success: false };
          }
        })(value);
      })(value["kind"]);
    })();
    if (!parsed_kind.success) {
      return { success: false };
    }
    return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagKindSkill
export function serialize_TagKindSkill(obj: TagKindSkill): string {
  const serialized = ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
    return {
      discriminator: ((value: "skill"): unknown => {
        return value;
      })(value["discriminator"]),
      id: ((value: bigint): unknown => {
        return value;
      })(value["id"]),
      kind: ((value: "skill"): unknown => {
        return value;
      })(value["kind"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagKindSkill(str: string): TagKindSkill | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          discriminator: KindSkill;
          id: bigint;
          kind: KindSkill;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "discriminator")) {
      return { success: false };
    }
    const parsed_discriminator = (() => {
      return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
        return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "skill":
              return { success: true, value: "skill" };
            default:
              return { success: false };
          }
        })(value);
      })(value["discriminator"]);
    })();
    if (!parsed_discriminator.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: bigint } => {
        if (typeof value === "number") {
          return { success: true, value: BigInt(value) };
        }
        if (typeof value === "bigint") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    if (!hasProp(value, "kind")) {
      return { success: false };
    }
    const parsed_kind = (() => {
      return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
        return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "skill":
              return { success: true, value: "skill" };
            default:
              return { success: false };
          }
        })(value);
      })(value["kind"]);
    })();
    if (!parsed_kind.success) {
      return { success: false };
    }
    return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagSourceType
export function serialize_TagSourceType(obj: TagSourceType): string {
  const serialized = ((value: "USER" | "AI"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagSourceType(str: string): TagSourceType | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "USER":
        return { success: true, value: "USER" };
      case "AI":
        return { success: true, value: "AI" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TagValidationResponse
export function serialize_TagValidationResponse(obj: TagValidationResponse): string {
  const serialized = ((value: { status: "success" } | { status: "error"; message: string }): unknown => {
    switch (value.status) {
      case "success": {
        return { status: "success" };
      }
      case "error": {
        return {
          status: "error",
          message: ((value: string): unknown => {
            return value;
          })(value["message"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TagValidationResponse(str: string): TagValidationResponse | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: { status: "success" } | { status: "error"; message: string } } => {
    return ((value: unknown): { success: false } | { success: true; value: { status: "success" } | { status: "error"; message: string } } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "status")) {
        return { success: false };
      }
      if (typeof value["status"] != "string") {
        return { success: false };
      }
      const discriminator = value["status"];
      switch (discriminator) {
        case "success": {
          const parsedVariant = ((value: unknown): { success: false } | { success: true; value: Record<string, never> } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            return { success: true, value: {} };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { status: "success" as const, ...parsedVariant.value } };
        }
        case "error": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  message: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "message")) {
              return { success: false };
            }
            const parsed_message = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["message"]);
            })();
            if (!parsed_message.success) {
              return { success: false };
            }
            return { success: true, value: { message: parsed_message.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { status: "error" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TaggedItem
export function serialize_TaggedItem(obj: TaggedItem): string {
  const serialized = ((value: { item: ReferencedLearningItem; tag: Tag }): unknown => {
    return {
      item: ((value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint }): unknown => {
        switch (value.learningItemType) {
          case "SUMMARY": {
            return {
              learningItemType: "SUMMARY",
              id: ((value: number): unknown => {
                return value;
              })(value["id"]),
            };
          }
          case "ACTIONABLE": {
            return {
              learningItemType: "ACTIONABLE",
              id: ((value: bigint): unknown => {
                return value;
              })(value["id"]),
            };
          }
        }
      })(value["item"]),
      tag: ((
        value:
          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
          | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
          | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
      ): unknown => {
        switch (value.discriminator) {
          case "dynamicString": {
            return {
              discriminator: "dynamicString",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                return {
                  discriminator: ((value: "dynamicString"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "dynamicBoolean": {
            return {
              discriminator: "dynamicBoolean",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                return {
                  discriminator: ((value: "dynamicBoolean"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: boolean): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "dynamicNumber": {
            return {
              discriminator: "dynamicNumber",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                return {
                  discriminator: ((value: "dynamicNumber"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: number): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "dynamicVoid": {
            return {
              discriminator: "dynamicVoid",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                return {
                  discriminator: ((value: "dynamicVoid"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: string): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
            };
          }
          case "channel": {
            return {
              discriminator: "channel",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                return {
                  discriminator: ((value: "channel"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "channel"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "primary-skill": {
            return {
              discriminator: "primary-skill",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                return {
                  discriminator: ((value: "primary-skill"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "primary-skill"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "skill": {
            return {
              discriminator: "skill",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                return {
                  discriminator: ((value: "skill"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "skill"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "key-quality": {
            return {
              discriminator: "key-quality",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                return {
                  discriminator: ((value: "key-quality"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "key-quality"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
          case "classification": {
            return {
              discriminator: "classification",
              exclude: ((value: boolean): unknown => {
                return value;
              })(value["exclude"]),
              source: ((value: "USER" | "AI"): unknown => {
                return value;
              })(value["source"]),
              tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                return {
                  discriminator: ((value: "classification"): unknown => {
                    return value;
                  })(value["discriminator"]),
                  id: ((value: bigint): unknown => {
                    return value;
                  })(value["id"]),
                  kind: ((value: "classification"): unknown => {
                    return value;
                  })(value["kind"]),
                };
              })(value["tagKind"]),
              value: ((value: string): unknown => {
                return value;
              })(value["value"]),
            };
          }
        }
      })(value["tag"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TaggedItem(str: string): TaggedItem | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          item: ReferencedLearningItem;
          tag: Tag;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "item")) {
      return { success: false };
    }
    const parsed_item = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ReferencedLearningItem } => {
        return ((value: unknown): { success: false } | { success: true; value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint } } => {
          return ((value: unknown): { success: false } | { success: true; value: { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint } } => {
            if (value == null) {
              return { success: false };
            }
            if (typeof value != "object") {
              return { success: false };
            }
            if (!hasProp(value, "learningItemType")) {
              return { success: false };
            }
            if (typeof value["learningItemType"] != "string") {
              return { success: false };
            }
            const discriminator = value["learningItemType"];
            switch (discriminator) {
              case "SUMMARY": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        id: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  return { success: true, value: { id: parsed_id.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { learningItemType: "SUMMARY" as const, ...parsedVariant.value } };
              }
              case "ACTIONABLE": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        id: bigint;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "id")) {
                    return { success: false };
                  }
                  const parsed_id = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["id"]);
                  })();
                  if (!parsed_id.success) {
                    return { success: false };
                  }
                  return { success: true, value: { id: parsed_id.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { learningItemType: "ACTIONABLE" as const, ...parsedVariant.value } };
              }
              default:
                return { success: false };
            }
          })(value);
        })(value);
      })(value["item"]);
    })();
    if (!parsed_item.success) {
      return { success: false };
    }
    if (!hasProp(value, "tag")) {
      return { success: false };
    }
    const parsed_tag = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Tag } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value:
                | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
            } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value:
                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                  | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                  | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
              } => {
            if (value == null) {
              return { success: false };
            }
            if (typeof value != "object") {
              return { success: false };
            }
            if (!hasProp(value, "discriminator")) {
              return { success: false };
            }
            if (typeof value["discriminator"] != "string") {
              return { success: false };
            }
            const discriminator = value["discriminator"];
            switch (discriminator) {
              case "dynamicString": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindDynamicString;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindDynamicString;
                              id: bigint;
                              kind: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                            return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "dynamicString":
                                  return { success: true, value: "dynamicString" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
              }
              case "dynamicBoolean": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindDynamicBoolean;
                        value: boolean;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindDynamicBoolean;
                              id: bigint;
                              kind: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                            return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "dynamicBoolean":
                                  return { success: true, value: "dynamicBoolean" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
              }
              case "dynamicNumber": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindDynamicNumber;
                        value: number;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindDynamicNumber;
                              id: bigint;
                              kind: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                            return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "dynamicNumber":
                                  return { success: true, value: "dynamicNumber" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
              }
              case "dynamicVoid": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindDynamicVoid;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindDynamicVoid;
                              id: bigint;
                              kind: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                            return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "dynamicVoid":
                                  return { success: true, value: "dynamicVoid" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
              }
              case "channel": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindChannel;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindChannel;
                              id: bigint;
                              kind: KindChannel;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                            return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "channel":
                                  return { success: true, value: "channel" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                            return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "channel":
                                  return { success: true, value: "channel" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
              }
              case "primary-skill": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindPrimarySkill;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindPrimarySkill;
                              id: bigint;
                              kind: KindPrimarySkill;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                            return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "primary-skill":
                                  return { success: true, value: "primary-skill" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                            return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "primary-skill":
                                  return { success: true, value: "primary-skill" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
              }
              case "skill": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindSkill;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindSkill;
                              id: bigint;
                              kind: KindSkill;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                            return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "skill":
                                  return { success: true, value: "skill" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                            return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "skill":
                                  return { success: true, value: "skill" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
              }
              case "key-quality": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindKeyQuality;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindKeyQuality;
                              id: bigint;
                              kind: KindKeyQuality;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                            return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "key-quality":
                                  return { success: true, value: "key-quality" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                            return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "key-quality":
                                  return { success: true, value: "key-quality" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
              }
              case "classification": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        exclude: boolean;
                        source: TagSourceType;
                        tagKind: TagKindClassification;
                        value: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "exclude")) {
                    return { success: false };
                  }
                  const parsed_exclude = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["exclude"]);
                  })();
                  if (!parsed_exclude.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "source")) {
                    return { success: false };
                  }
                  const parsed_source = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "USER":
                            return { success: true, value: "USER" };
                          case "AI":
                            return { success: true, value: "AI" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["source"]);
                  })();
                  if (!parsed_source.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tagKind")) {
                    return { success: false };
                  }
                  const parsed_tagKind = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              discriminator: KindClassification;
                              id: bigint;
                              kind: KindClassification;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "discriminator")) {
                          return { success: false };
                        }
                        const parsed_discriminator = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                            return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "classification":
                                  return { success: true, value: "classification" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["discriminator"]);
                        })();
                        if (!parsed_discriminator.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "id")) {
                          return { success: false };
                        }
                        const parsed_id = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["id"]);
                        })();
                        if (!parsed_id.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "kind")) {
                          return { success: false };
                        }
                        const parsed_kind = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                            return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "classification":
                                  return { success: true, value: "classification" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["kind"]);
                        })();
                        if (!parsed_kind.success) {
                          return { success: false };
                        }
                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                      })(value);
                    })(value["tagKind"]);
                  })();
                  if (!parsed_tagKind.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "value")) {
                    return { success: false };
                  }
                  const parsed_value = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["value"]);
                  })();
                  if (!parsed_value.success) {
                    return { success: false };
                  }
                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
              }
              default:
                return { success: false };
            }
          })(value);
        })(value);
      })(value["tag"]);
    })();
    if (!parsed_tag.success) {
      return { success: false };
    }
    return { success: true, value: { item: parsed_item.value, tag: parsed_tag.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TeamSubscription
export function serialize_TeamSubscription(obj: TeamSubscription): string {
  const serialized = ((value: { teamPortalCorporateId: number; teamPortalUrl: string; teamPortalUsername: string }): unknown => {
    return {
      teamPortalCorporateId: ((value: number): unknown => {
        return value;
      })(value["teamPortalCorporateId"]),
      teamPortalUrl: ((value: string): unknown => {
        return value;
      })(value["teamPortalUrl"]),
      teamPortalUsername: ((value: string): unknown => {
        return value;
      })(value["teamPortalUsername"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TeamSubscription(str: string): TeamSubscription | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          teamPortalCorporateId: number;
          teamPortalUrl: string;
          teamPortalUsername: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "teamPortalCorporateId")) {
      return { success: false };
    }
    const parsed_teamPortalCorporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["teamPortalCorporateId"]);
    })();
    if (!parsed_teamPortalCorporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "teamPortalUrl")) {
      return { success: false };
    }
    const parsed_teamPortalUrl = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["teamPortalUrl"]);
    })();
    if (!parsed_teamPortalUrl.success) {
      return { success: false };
    }
    if (!hasProp(value, "teamPortalUsername")) {
      return { success: false };
    }
    const parsed_teamPortalUsername = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["teamPortalUsername"]);
    })();
    if (!parsed_teamPortalUsername.success) {
      return { success: false };
    }
    return { success: true, value: { teamPortalCorporateId: parsed_teamPortalCorporateId.value, teamPortalUrl: parsed_teamPortalUrl.value, teamPortalUsername: parsed_teamPortalUsername.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ToastState
export function serialize_ToastState(obj: ToastState): string {
  const serialized = ((value: { message: string; title: string | null; uuid: string; variant: ToastVariant }): unknown => {
    return {
      message: ((value: string): unknown => {
        return value;
      })(value["message"]),
      title: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["title"]),
      uuid: ((value: string): unknown => {
        return value;
      })(value["uuid"]),
      variant: ((value: "primary" | "success" | "warning" | "danger"): unknown => {
        return value;
      })(value["variant"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ToastState(str: string): ToastState | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          message: string;
          title: string | null;
          uuid: string;
          variant: ToastVariant;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "message")) {
      return { success: false };
    }
    const parsed_message = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["message"]);
    })();
    if (!parsed_message.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    if (!hasProp(value, "uuid")) {
      return { success: false };
    }
    const parsed_uuid = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["uuid"]);
    })();
    if (!parsed_uuid.success) {
      return { success: false };
    }
    if (!hasProp(value, "variant")) {
      return { success: false };
    }
    const parsed_variant = (() => {
      return ((value: unknown): { success: false } | { success: true; value: ToastVariant } => {
        return ((value: unknown): { success: false } | { success: true; value: "primary" | "success" | "warning" | "danger" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "primary":
              return { success: true, value: "primary" };
            case "success":
              return { success: true, value: "success" };
            case "warning":
              return { success: true, value: "warning" };
            case "danger":
              return { success: true, value: "danger" };
            default:
              return { success: false };
          }
        })(value);
      })(value["variant"]);
    })();
    if (!parsed_variant.success) {
      return { success: false };
    }
    return { success: true, value: { message: parsed_message.value, title: parsed_title.value, uuid: parsed_uuid.value, variant: parsed_variant.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ToastVariant
export function serialize_ToastVariant(obj: ToastVariant): string {
  const serialized = ((value: "primary" | "success" | "warning" | "danger"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ToastVariant(str: string): ToastVariant | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "primary" | "success" | "warning" | "danger" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "primary":
        return { success: true, value: "primary" };
      case "success":
        return { success: true, value: "success" };
      case "warning":
        return { success: true, value: "warning" };
      case "danger":
        return { success: true, value: "danger" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// TransferProtocol
export function serialize_TransferProtocol(obj: TransferProtocol): string {
  const serialized = ((value: "FTP" | "SFTP" | "FTPS"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_TransferProtocol(str: string): TransferProtocol | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "FTP" | "SFTP" | "FTPS" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "FTP":
        return { success: true, value: "FTP" };
      case "SFTP":
        return { success: true, value: "SFTP" };
      case "FTPS":
        return { success: true, value: "FTPS" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// Translations
export function serialize_Translations(obj: Translations): string {
  const serialized = ((value: { keys: { additionalProperties: Record<string, string> } }): unknown => {
    return {
      keys: ((value: { additionalProperties: Record<string, string> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: string): unknown => {
                  return value;
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["keys"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_Translations(str: string): Translations | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          keys: { additionalProperties: Record<string, string> };
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "keys")) {
      return { success: false };
    }
    const parsed_keys = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["keys"]);
    })();
    if (!parsed_keys.success) {
      return { success: false };
    }
    return { success: true, value: { keys: parsed_keys.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// UserContext
export function serialize_UserContext(obj: UserContext): string {
  const serialized = ((value: { loggedIn: boolean; u: string | null }): unknown => {
    return {
      loggedIn: ((value: boolean): unknown => {
        return value;
      })(value["loggedIn"]),
      u: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["u"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_UserContext(str: string): UserContext | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          loggedIn: boolean;
          u: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "loggedIn")) {
      return { success: false };
    }
    const parsed_loggedIn = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["loggedIn"]);
    })();
    if (!parsed_loggedIn.success) {
      return { success: false };
    }
    if (!hasProp(value, "u")) {
      return { success: false };
    }
    const parsed_u = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["u"]);
    })();
    if (!parsed_u.success) {
      return { success: false };
    }
    return { success: true, value: { loggedIn: parsed_loggedIn.value, u: parsed_u.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// UserReportView
export function serialize_UserReportView(obj: UserReportView): string {
  const serialized = ((value: { customer: CustomerReportView; lastMobileUsage: DateTime<true> | null; lastWebUsage: DateTime<true> | null; pull: number; push: number }): unknown => {
    return {
      customer: ((value: {
        country: string | null;
        customerId: number;
        email: string | null;
        endDate: { year: number; month: number; day: number };
        externalId: string | null;
        extraField1: string | null;
        extraField2: string | null;
        extraField3: string | null;
        firstName: string | null;
        language: Language;
        lastName: string | null;
        signupDate: { year: number; month: number; day: number };
        status: string;
        title: number | null;
        town: string | null;
        userGroups: string[];
      }): unknown => {
        return {
          country: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["country"]),
          customerId: ((value: number): unknown => {
            return value;
          })(value["customerId"]),
          email: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["email"]),
          endDate: ((value: { year: number; month: number; day: number }): unknown => {
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["endDate"]),
          externalId: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["externalId"]),
          extraField1: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["extraField1"]),
          extraField2: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["extraField2"]),
          extraField3: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["extraField3"]),
          firstName: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["firstName"]),
          language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
            return value;
          })(value["language"]),
          lastName: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["lastName"]),
          signupDate: ((value: { year: number; month: number; day: number }): unknown => {
            return `${value.year.toFixed().padStart(4, "0")}-${value.month.toFixed().padStart(2, "0")}-${value.day.toFixed().padStart(2, "0")}`;
          })(value["signupDate"]),
          status: ((value: string): unknown => {
            return value;
          })(value["status"]),
          title: ((value: number | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["title"]),
          town: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["town"]),
          userGroups: ((value: string[]): unknown => {
            return value.map((value: string): unknown => {
              return value;
            });
          })(value["userGroups"]),
        };
      })(value["customer"]),
      lastMobileUsage: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toISO();
      })(value["lastMobileUsage"]),
      lastWebUsage: ((value: DateTime<true> | null): unknown => {
        if (value === null) {
          return null;
        }
        return value.toISO();
      })(value["lastWebUsage"]),
      pull: ((value: number): unknown => {
        return value;
      })(value["pull"]),
      push: ((value: number): unknown => {
        return value;
      })(value["push"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_UserReportView(str: string): UserReportView | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          customer: CustomerReportView;
          lastMobileUsage: DateTime<true> | null;
          lastWebUsage: DateTime<true> | null;
          pull: number;
          push: number;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "customer")) {
      return { success: false };
    }
    const parsed_customer = (() => {
      return ((value: unknown): { success: false } | { success: true; value: CustomerReportView } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                country: string | null;
                customerId: number;
                email: string | null;
                endDate: { year: number; month: number; day: number };
                externalId: string | null;
                extraField1: string | null;
                extraField2: string | null;
                extraField3: string | null;
                firstName: string | null;
                language: Language;
                lastName: string | null;
                signupDate: { year: number; month: number; day: number };
                status: string;
                title: number | null;
                town: string | null;
                userGroups: string[];
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "country")) {
            return { success: false };
          }
          const parsed_country = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["country"]);
          })();
          if (!parsed_country.success) {
            return { success: false };
          }
          if (!hasProp(value, "customerId")) {
            return { success: false };
          }
          const parsed_customerId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["customerId"]);
          })();
          if (!parsed_customerId.success) {
            return { success: false };
          }
          if (!hasProp(value, "email")) {
            return { success: false };
          }
          const parsed_email = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["email"]);
          })();
          if (!parsed_email.success) {
            return { success: false };
          }
          if (!hasProp(value, "endDate")) {
            return { success: false };
          }
          const parsed_endDate = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["endDate"]);
          })();
          if (!parsed_endDate.success) {
            return { success: false };
          }
          if (!hasProp(value, "externalId")) {
            return { success: false };
          }
          const parsed_externalId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["externalId"]);
          })();
          if (!parsed_externalId.success) {
            return { success: false };
          }
          if (!hasProp(value, "extraField1")) {
            return { success: false };
          }
          const parsed_extraField1 = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["extraField1"]);
          })();
          if (!parsed_extraField1.success) {
            return { success: false };
          }
          if (!hasProp(value, "extraField2")) {
            return { success: false };
          }
          const parsed_extraField2 = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["extraField2"]);
          })();
          if (!parsed_extraField2.success) {
            return { success: false };
          }
          if (!hasProp(value, "extraField3")) {
            return { success: false };
          }
          const parsed_extraField3 = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["extraField3"]);
          })();
          if (!parsed_extraField3.success) {
            return { success: false };
          }
          if (!hasProp(value, "firstName")) {
            return { success: false };
          }
          const parsed_firstName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["firstName"]);
          })();
          if (!parsed_firstName.success) {
            return { success: false };
          }
          if (!hasProp(value, "language")) {
            return { success: false };
          }
          const parsed_language = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Language } => {
              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "en":
                    return { success: true, value: "en" };
                  case "de":
                    return { success: true, value: "de" };
                  case "es":
                    return { success: true, value: "es" };
                  case "ru":
                    return { success: true, value: "ru" };
                  case "zh":
                    return { success: true, value: "zh" };
                  case "pt":
                    return { success: true, value: "pt" };
                  case "fr":
                    return { success: true, value: "fr" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["language"]);
          })();
          if (!parsed_language.success) {
            return { success: false };
          }
          if (!hasProp(value, "lastName")) {
            return { success: false };
          }
          const parsed_lastName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lastName"]);
          })();
          if (!parsed_lastName.success) {
            return { success: false };
          }
          if (!hasProp(value, "signupDate")) {
            return { success: false };
          }
          const parsed_signupDate = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { year: number; month: number; day: number } } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              const parsed = DateTime.fromFormat(value, "yyyy-LL-dd");
              if (!parsed.isValid) {
                return { success: false };
              }
              return { success: true, value: { year: parsed.year, month: parsed.month, day: parsed.day } };
            })(value["signupDate"]);
          })();
          if (!parsed_signupDate.success) {
            return { success: false };
          }
          if (!hasProp(value, "status")) {
            return { success: false };
          }
          const parsed_status = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["status"]);
          })();
          if (!parsed_status.success) {
            return { success: false };
          }
          if (!hasProp(value, "title")) {
            return { success: false };
          }
          const parsed_title = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["title"]);
          })();
          if (!parsed_title.success) {
            return { success: false };
          }
          if (!hasProp(value, "town")) {
            return { success: false };
          }
          const parsed_town = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["town"]);
          })();
          if (!parsed_town.success) {
            return { success: false };
          }
          if (!hasProp(value, "userGroups")) {
            return { success: false };
          }
          const parsed_userGroups = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["userGroups"]);
          })();
          if (!parsed_userGroups.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              country: parsed_country.value,
              customerId: parsed_customerId.value,
              email: parsed_email.value,
              endDate: parsed_endDate.value,
              externalId: parsed_externalId.value,
              extraField1: parsed_extraField1.value,
              extraField2: parsed_extraField2.value,
              extraField3: parsed_extraField3.value,
              firstName: parsed_firstName.value,
              language: parsed_language.value,
              lastName: parsed_lastName.value,
              signupDate: parsed_signupDate.value,
              status: parsed_status.value,
              title: parsed_title.value,
              town: parsed_town.value,
              userGroups: parsed_userGroups.value,
            },
          };
        })(value);
      })(value["customer"]);
    })();
    if (!parsed_customer.success) {
      return { success: false };
    }
    if (!hasProp(value, "lastMobileUsage")) {
      return { success: false };
    }
    const parsed_lastMobileUsage = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["lastMobileUsage"]);
    })();
    if (!parsed_lastMobileUsage.success) {
      return { success: false };
    }
    if (!hasProp(value, "lastWebUsage")) {
      return { success: false };
    }
    const parsed_lastWebUsage = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        const parsed = DateTime.fromISO(value.toUpperCase(), { setZone: true });
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["lastWebUsage"]);
    })();
    if (!parsed_lastWebUsage.success) {
      return { success: false };
    }
    if (!hasProp(value, "pull")) {
      return { success: false };
    }
    const parsed_pull = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["pull"]);
    })();
    if (!parsed_pull.success) {
      return { success: false };
    }
    if (!hasProp(value, "push")) {
      return { success: false };
    }
    const parsed_push = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["push"]);
    })();
    if (!parsed_push.success) {
      return { success: false };
    }
    return { success: true, value: { customer: parsed_customer.value, lastMobileUsage: parsed_lastMobileUsage.value, lastWebUsage: parsed_lastWebUsage.value, pull: parsed_pull.value, push: parsed_push.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// ValidationErrorReasons
export function serialize_ValidationErrorReasons(obj: ValidationErrorReasons): string {
  const serialized = ((value: { fields: { additionalProperties: Record<string, string[]> }; general: string[] }): unknown => {
    return {
      fields: ((value: { additionalProperties: Record<string, string[]> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: string[]): unknown => {
                  return value.map((value: string): unknown => {
                    return value;
                  });
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["fields"]),
      general: ((value: string[]): unknown => {
        return value.map((value: string): unknown => {
          return value;
        });
      })(value["general"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_ValidationErrorReasons(str: string): ValidationErrorReasons | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          fields: { additionalProperties: Record<string, string[]> };
          general: string[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "fields")) {
      return { success: false };
    }
    const parsed_fields = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string[]> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string[]> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string[] }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: string[] } => {
                if (!isArray(value)) {
                  return { success: false };
                }
                const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  return { success: true, value: value };
                });
                if (parsed.find((value) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: parsed.map((entry) => {
                    assert(entry.success == true);
                    return entry.value;
                  }),
                };
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["fields"]);
    })();
    if (!parsed_fields.success) {
      return { success: false };
    }
    if (!hasProp(value, "general")) {
      return { success: false };
    }
    const parsed_general = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          return { success: true, value: value };
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["general"]);
    })();
    if (!parsed_general.success) {
      return { success: false };
    }
    return { success: true, value: { fields: parsed_fields.value, general: parsed_general.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// Verb
export function serialize_Verb(obj: Verb): string {
  const serialized = ((value: { display: { additionalProperties: Record<string, string> }; id: string }): unknown => {
    return {
      display: ((value: { additionalProperties: Record<string, string> }): unknown => {
        const unordered_obj: Record<string, unknown> = {
          ...Object.fromEntries(
            Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
              return [
                propertyKey,
                ((value: string): unknown => {
                  return value;
                })(propertyValue),
              ];
            }),
          ),
        };
        return Object.keys(unordered_obj)
          .sort()
          .reduce(
            (obj, key) => {
              obj[key] = unordered_obj[key];
              return obj;
            },
            {} as Record<string, unknown>,
          );
      })(value["display"]),
      id: ((value: string): unknown => {
        return value;
      })(value["id"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_Verb(str: string): Verb | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          display: { additionalProperties: Record<string, string> };
          id: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "display")) {
      return { success: false };
    }
    const parsed_display = (() => {
      return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
        if (typeof value !== "object") {
          return { success: false };
        }
        if (value === null) {
          return { success: false };
        }
        const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
          const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
            return [
              key,
              ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value),
            ];
          });
          if (parsed.find(([_, value]) => !value.success) !== undefined) {
            return { success: false };
          }
          return {
            success: true,
            value: Object.fromEntries(
              parsed.map(([key, value]) => {
                assert(value.success);
                return [key, value.value];
              }),
            ),
          };
        })();
        if (!parsed_additionalProperties.success) {
          return { success: false };
        }
        return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
      })(value["display"]);
    })();
    if (!parsed_display.success) {
      return { success: false };
    }
    if (!hasProp(value, "id")) {
      return { success: false };
    }
    const parsed_id = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["id"]);
    })();
    if (!parsed_id.success) {
      return { success: false };
    }
    return { success: true, value: { display: parsed_display.value, id: parsed_id.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// VerifaiCreatorPageProps
export function serialize_VerifaiCreatorPageProps(obj: VerifaiCreatorPageProps): string {
  const serialized = ((value: { hashId: string }): unknown => {
    return {
      hashId: ((value: string): unknown => {
        return value;
      })(value["hashId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_VerifaiCreatorPageProps(str: string): VerifaiCreatorPageProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          hashId: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "hashId")) {
      return { success: false };
    }
    const parsed_hashId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["hashId"]);
    })();
    if (!parsed_hashId.success) {
      return { success: false };
    }
    return { success: true, value: { hashId: parsed_hashId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// VerifaiNavLink
export function serialize_VerifaiNavLink(obj: VerifaiNavLink): string {
  const serialized = ((value: { ico: string | null; name: string; url: string }): unknown => {
    return {
      ico: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["ico"]),
      name: ((value: string): unknown => {
        return value;
      })(value["name"]),
      url: ((value: string): unknown => {
        return value;
      })(value["url"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_VerifaiNavLink(str: string): VerifaiNavLink | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          ico: string | null;
          name: string;
          url: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "ico")) {
      return { success: false };
    }
    const parsed_ico = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ico"]);
    })();
    if (!parsed_ico.success) {
      return { success: false };
    }
    if (!hasProp(value, "name")) {
      return { success: false };
    }
    const parsed_name = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["name"]);
    })();
    if (!parsed_name.success) {
      return { success: false };
    }
    if (!hasProp(value, "url")) {
      return { success: false };
    }
    const parsed_url = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["url"]);
    })();
    if (!parsed_url.success) {
      return { success: false };
    }
    return { success: true, value: { ico: parsed_ico.value, name: parsed_name.value, url: parsed_url.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// VerifaiNavigationProps
export function serialize_VerifaiNavigationProps(obj: VerifaiNavigationProps): string {
  const serialized = ((value: { navLinks: VerifaiNavLink[] }): unknown => {
    return {
      navLinks: ((value: VerifaiNavLink[]): unknown => {
        return value.map((value: { ico: string | null; name: string; url: string }): unknown => {
          return {
            ico: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["ico"]),
            name: ((value: string): unknown => {
              return value;
            })(value["name"]),
            url: ((value: string): unknown => {
              return value;
            })(value["url"]),
          };
        });
      })(value["navLinks"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_VerifaiNavigationProps(str: string): VerifaiNavigationProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          navLinks: VerifaiNavLink[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "navLinks")) {
      return { success: false };
    }
    const parsed_navLinks = (() => {
      return ((value: unknown): { success: false } | { success: true; value: VerifaiNavLink[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: VerifaiNavLink } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  ico: string | null;
                  name: string;
                  url: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "ico")) {
              return { success: false };
            }
            const parsed_ico = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["ico"]);
            })();
            if (!parsed_ico.success) {
              return { success: false };
            }
            if (!hasProp(value, "name")) {
              return { success: false };
            }
            const parsed_name = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["name"]);
            })();
            if (!parsed_name.success) {
              return { success: false };
            }
            if (!hasProp(value, "url")) {
              return { success: false };
            }
            const parsed_url = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["url"]);
            })();
            if (!parsed_url.success) {
              return { success: false };
            }
            return { success: true, value: { ico: parsed_ico.value, name: parsed_name.value, url: parsed_url.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["navLinks"]);
    })();
    if (!parsed_navLinks.success) {
      return { success: false };
    }
    return { success: true, value: { navLinks: parsed_navLinks.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// VueDemoProps
export function serialize_VueDemoProps(obj: VueDemoProps): string {
  const serialized = ((value: { language: Language; subtitle: string }): unknown => {
    return {
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      subtitle: ((value: string): unknown => {
        return value;
      })(value["subtitle"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_VueDemoProps(str: string): VueDemoProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          language: Language;
          subtitle: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language } => {
        return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "en":
              return { success: true, value: "en" };
            case "de":
              return { success: true, value: "de" };
            case "es":
              return { success: true, value: "es" };
            case "ru":
              return { success: true, value: "ru" };
            case "zh":
              return { success: true, value: "zh" };
            case "pt":
              return { success: true, value: "pt" };
            case "fr":
              return { success: true, value: "fr" };
            default:
              return { success: false };
          }
        })(value);
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "subtitle")) {
      return { success: false };
    }
    const parsed_subtitle = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["subtitle"]);
    })();
    if (!parsed_subtitle.success) {
      return { success: false };
    }
    return { success: true, value: { language: parsed_language.value, subtitle: parsed_subtitle.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// WarmupV2EditForm
export function serialize_WarmupV2EditForm(obj: WarmupV2EditForm): string {
  const serialized = ((value: { countryCode: string; email: string; firstName: string; frequency: PushFrequency; language: Language; lastName: string; password: string; termsAndConditions: boolean }): unknown => {
    return {
      countryCode: ((value: string): unknown => {
        return value;
      })(value["countryCode"]),
      email: ((value: string): unknown => {
        return value;
      })(value["email"]),
      firstName: ((value: string): unknown => {
        return value;
      })(value["firstName"]),
      frequency: ((value: "NONE" | "DAILY" | "ONCE_A_WEEK" | "TWICE_A_MONTH" | "ONCE_A_MONTH"): unknown => {
        return value;
      })(value["frequency"]),
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      lastName: ((value: string): unknown => {
        return value;
      })(value["lastName"]),
      password: ((value: string): unknown => {
        return value;
      })(value["password"]),
      termsAndConditions: ((value: boolean): unknown => {
        return value;
      })(value["termsAndConditions"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_WarmupV2EditForm(str: string): WarmupV2EditForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          countryCode: string;
          email: string;
          firstName: string;
          frequency: PushFrequency;
          language: Language;
          lastName: string;
          password: string;
          termsAndConditions: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "countryCode")) {
      return { success: false };
    }
    const parsed_countryCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["countryCode"]);
    })();
    if (!parsed_countryCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "email")) {
      return { success: false };
    }
    const parsed_email = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["email"]);
    })();
    if (!parsed_email.success) {
      return { success: false };
    }
    if (!hasProp(value, "firstName")) {
      return { success: false };
    }
    const parsed_firstName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["firstName"]);
    })();
    if (!parsed_firstName.success) {
      return { success: false };
    }
    if (!hasProp(value, "frequency")) {
      return { success: false };
    }
    const parsed_frequency = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PushFrequency } => {
        return ((value: unknown): { success: false } | { success: true; value: "NONE" | "DAILY" | "ONCE_A_WEEK" | "TWICE_A_MONTH" | "ONCE_A_MONTH" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "NONE":
              return { success: true, value: "NONE" };
            case "DAILY":
              return { success: true, value: "DAILY" };
            case "ONCE_A_WEEK":
              return { success: true, value: "ONCE_A_WEEK" };
            case "TWICE_A_MONTH":
              return { success: true, value: "TWICE_A_MONTH" };
            case "ONCE_A_MONTH":
              return { success: true, value: "ONCE_A_MONTH" };
            default:
              return { success: false };
          }
        })(value);
      })(value["frequency"]);
    })();
    if (!parsed_frequency.success) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language } => {
        return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "en":
              return { success: true, value: "en" };
            case "de":
              return { success: true, value: "de" };
            case "es":
              return { success: true, value: "es" };
            case "ru":
              return { success: true, value: "ru" };
            case "zh":
              return { success: true, value: "zh" };
            case "pt":
              return { success: true, value: "pt" };
            case "fr":
              return { success: true, value: "fr" };
            default:
              return { success: false };
          }
        })(value);
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "lastName")) {
      return { success: false };
    }
    const parsed_lastName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lastName"]);
    })();
    if (!parsed_lastName.success) {
      return { success: false };
    }
    if (!hasProp(value, "password")) {
      return { success: false };
    }
    const parsed_password = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["password"]);
    })();
    if (!parsed_password.success) {
      return { success: false };
    }
    if (!hasProp(value, "termsAndConditions")) {
      return { success: false };
    }
    const parsed_termsAndConditions = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["termsAndConditions"]);
    })();
    if (!parsed_termsAndConditions.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        countryCode: parsed_countryCode.value,
        email: parsed_email.value,
        firstName: parsed_firstName.value,
        frequency: parsed_frequency.value,
        language: parsed_language.value,
        lastName: parsed_lastName.value,
        password: parsed_password.value,
        termsAndConditions: parsed_termsAndConditions.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// WarmupV2RelayType
export function serialize_WarmupV2RelayType(obj: WarmupV2RelayType): string {
  const serialized = ((value: { kind: "DEFAULT"; relayState: string } | { kind: "SUMMARY"; relayState: string; summary: SummaryMetadata } | { kind: "ACTIONABLE"; actionable: ActionableMetadata; relayState: string }): unknown => {
    switch (value.kind) {
      case "DEFAULT": {
        return {
          kind: "DEFAULT",
          relayState: ((value: string): unknown => {
            return value;
          })(value["relayState"]),
        };
      }
      case "SUMMARY": {
        return {
          kind: "SUMMARY",
          relayState: ((value: string): unknown => {
            return value;
          })(value["relayState"]),
          summary: ((value: {
            activatedAt: DateTime<true> | null;
            activationTime: DateTime<true> | null;
            active: boolean;
            audioActivatedAt: DateTime<true> | null;
            audioActive: boolean;
            audioDuration: number;
            authorInfo: string | null;
            authors: {
              authorId: number;
              firstName: string | null;
              gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
              lastName: string | null;
              middleName: string | null;
            }[];
            backgroundColor: string | null;
            bestseller: boolean;
            businessClassic: boolean;
            canonicalName: string;
            contentItemId: bigint;
            contentItemType: ContentItemType;
            countriesExcluded: Country[];
            countriesIncluded: Country[];
            coverModifiedAt: DateTime<true> | null;
            coverUri: string;
            dataId: number;
            descriptionHtml: string | null;
            documentType: SummaryDocumentType;
            duration: number;
            imageBorder: boolean;
            language: Language;
            modifiedAt: DateTime<true> | null;
            originalTitle: string | null;
            previewAvailable: boolean;
            publicationDate: string | null;
            publisherInfo: string | null;
            publisherName: string | null;
            rating: number | null;
            sourceType: SummarySourceType;
            subtitle: string | null;
            summaryPages: number;
            summaryWords: number;
            tags: Tag[];
            teaser: string | null;
            title: string | null;
          }): unknown => {
            return {
              activatedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["activatedAt"]),
              activationTime: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["activationTime"]),
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              audioActivatedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["audioActivatedAt"]),
              audioActive: ((value: boolean): unknown => {
                return value;
              })(value["audioActive"]),
              audioDuration: ((value: number): unknown => {
                return value;
              })(value["audioDuration"]),
              authorInfo: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["authorInfo"]),
              authors: ((
                value: {
                  authorId: number;
                  firstName: string | null;
                  gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                  lastName: string | null;
                  middleName: string | null;
                }[],
              ): unknown => {
                return value.map((value: { authorId: number; firstName: string | null; gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null; lastName: string | null; middleName: string | null }): unknown => {
                  return {
                    authorId: ((value: number): unknown => {
                      return value;
                    })(value["authorId"]),
                    firstName: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["firstName"]),
                    gender: ((value: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["gender"]),
                    lastName: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["lastName"]),
                    middleName: ((value: string | null): unknown => {
                      if (value === null) {
                        return null;
                      }
                      return value;
                    })(value["middleName"]),
                  };
                });
              })(value["authors"]),
              backgroundColor: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["backgroundColor"]),
              bestseller: ((value: boolean): unknown => {
                return value;
              })(value["bestseller"]),
              businessClassic: ((value: boolean): unknown => {
                return value;
              })(value["businessClassic"]),
              canonicalName: ((value: string): unknown => {
                return value;
              })(value["canonicalName"]),
              contentItemId: ((value: bigint): unknown => {
                return value;
              })(value["contentItemId"]),
              contentItemType: ((value: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE" | "MIAB"): unknown => {
                return value;
              })(value["contentItemType"]),
              countriesExcluded: ((value: Country[]): unknown => {
                return value.map(
                  (value: {
                    countryCode: string;
                    currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                    names: {
                      de: string;
                      en: string;
                      es: string;
                      fr: string;
                      pt: string;
                      ru: string;
                      zh: string;
                    };
                    phonePrefix: string;
                    region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                  }): unknown => {
                    return {
                      countryCode: ((value: string): unknown => {
                        return value;
                      })(value["countryCode"]),
                      currency: ((value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY"): unknown => {
                        return value;
                      })(value["currency"]),
                      names: ((value: { de: string; en: string; es: string; fr: string; pt: string; ru: string; zh: string }): unknown => {
                        return {
                          de: ((value: string): unknown => {
                            return value;
                          })(value["de"]),
                          en: ((value: string): unknown => {
                            return value;
                          })(value["en"]),
                          es: ((value: string): unknown => {
                            return value;
                          })(value["es"]),
                          fr: ((value: string): unknown => {
                            return value;
                          })(value["fr"]),
                          pt: ((value: string): unknown => {
                            return value;
                          })(value["pt"]),
                          ru: ((value: string): unknown => {
                            return value;
                          })(value["ru"]),
                          zh: ((value: string): unknown => {
                            return value;
                          })(value["zh"]),
                        };
                      })(value["names"]),
                      phonePrefix: ((value: string): unknown => {
                        return value;
                      })(value["phonePrefix"]),
                      region: ((value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA"): unknown => {
                        return value;
                      })(value["region"]),
                    };
                  },
                );
              })(value["countriesExcluded"]),
              countriesIncluded: ((value: Country[]): unknown => {
                return value.map(
                  (value: {
                    countryCode: string;
                    currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                    names: {
                      de: string;
                      en: string;
                      es: string;
                      fr: string;
                      pt: string;
                      ru: string;
                      zh: string;
                    };
                    phonePrefix: string;
                    region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                  }): unknown => {
                    return {
                      countryCode: ((value: string): unknown => {
                        return value;
                      })(value["countryCode"]),
                      currency: ((value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY"): unknown => {
                        return value;
                      })(value["currency"]),
                      names: ((value: { de: string; en: string; es: string; fr: string; pt: string; ru: string; zh: string }): unknown => {
                        return {
                          de: ((value: string): unknown => {
                            return value;
                          })(value["de"]),
                          en: ((value: string): unknown => {
                            return value;
                          })(value["en"]),
                          es: ((value: string): unknown => {
                            return value;
                          })(value["es"]),
                          fr: ((value: string): unknown => {
                            return value;
                          })(value["fr"]),
                          pt: ((value: string): unknown => {
                            return value;
                          })(value["pt"]),
                          ru: ((value: string): unknown => {
                            return value;
                          })(value["ru"]),
                          zh: ((value: string): unknown => {
                            return value;
                          })(value["zh"]),
                        };
                      })(value["names"]),
                      phonePrefix: ((value: string): unknown => {
                        return value;
                      })(value["phonePrefix"]),
                      region: ((value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA"): unknown => {
                        return value;
                      })(value["region"]),
                    };
                  },
                );
              })(value["countriesIncluded"]),
              coverModifiedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["coverModifiedAt"]),
              coverUri: ((value: string): unknown => {
                return value;
              })(value["coverUri"]),
              dataId: ((value: number): unknown => {
                return value;
              })(value["dataId"]),
              descriptionHtml: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["descriptionHtml"]),
              documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
                return value;
              })(value["documentType"]),
              duration: ((value: number): unknown => {
                return value;
              })(value["duration"]),
              imageBorder: ((value: boolean): unknown => {
                return value;
              })(value["imageBorder"]),
              language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                return value;
              })(value["language"]),
              modifiedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["modifiedAt"]),
              originalTitle: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["originalTitle"]),
              previewAvailable: ((value: boolean): unknown => {
                return value;
              })(value["previewAvailable"]),
              publicationDate: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["publicationDate"]),
              publisherInfo: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["publisherInfo"]),
              publisherName: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["publisherName"]),
              rating: ((value: number | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["rating"]),
              sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
                return value;
              })(value["sourceType"]),
              subtitle: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["subtitle"]),
              summaryPages: ((value: number): unknown => {
                return value;
              })(value["summaryPages"]),
              summaryWords: ((value: number): unknown => {
                return value;
              })(value["summaryWords"]),
              tags: ((value: Tag[]): unknown => {
                return value.map(
                  (
                    value:
                      | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                      | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                      | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                      | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                      | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                      | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                      | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                      | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                      | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
                  ): unknown => {
                    switch (value.discriminator) {
                      case "dynamicString": {
                        return {
                          discriminator: "dynamicString",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicString"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicBoolean": {
                        return {
                          discriminator: "dynamicBoolean",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicBoolean"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: boolean): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicNumber": {
                        return {
                          discriminator: "dynamicNumber",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicNumber"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: number): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicVoid": {
                        return {
                          discriminator: "dynamicVoid",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicVoid"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                        };
                      }
                      case "channel": {
                        return {
                          discriminator: "channel",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                            return {
                              discriminator: ((value: "channel"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "channel"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "primary-skill": {
                        return {
                          discriminator: "primary-skill",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                            return {
                              discriminator: ((value: "primary-skill"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "primary-skill"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "skill": {
                        return {
                          discriminator: "skill",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                            return {
                              discriminator: ((value: "skill"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "skill"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "key-quality": {
                        return {
                          discriminator: "key-quality",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                            return {
                              discriminator: ((value: "key-quality"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "key-quality"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "classification": {
                        return {
                          discriminator: "classification",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                            return {
                              discriminator: ((value: "classification"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "classification"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                    }
                  },
                );
              })(value["tags"]),
              teaser: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["teaser"]),
              title: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["title"]),
            };
          })(value["summary"]),
        };
      }
      case "ACTIONABLE": {
        return {
          kind: "ACTIONABLE",
          actionable: ((value: {
            actionableId: bigint;
            activatedAt: DateTime<true> | null;
            active: boolean;
            coverUri: string;
            description: string;
            language: Language;
            modifiedAt: DateTime<true>;
            name: string;
            tags: Tag[];
            title: string;
          }): unknown => {
            return {
              actionableId: ((value: bigint): unknown => {
                return value;
              })(value["actionableId"]),
              activatedAt: ((value: DateTime<true> | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value.toMillis();
              })(value["activatedAt"]),
              active: ((value: boolean): unknown => {
                return value;
              })(value["active"]),
              coverUri: ((value: string): unknown => {
                return value;
              })(value["coverUri"]),
              description: ((value: string): unknown => {
                return value;
              })(value["description"]),
              language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                return value;
              })(value["language"]),
              modifiedAt: ((value: DateTime<true>): unknown => {
                return value.toMillis();
              })(value["modifiedAt"]),
              name: ((value: string): unknown => {
                return value;
              })(value["name"]),
              tags: ((value: Tag[]): unknown => {
                return value.map(
                  (
                    value:
                      | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                      | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                      | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                      | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                      | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                      | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                      | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                      | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                      | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
                  ): unknown => {
                    switch (value.discriminator) {
                      case "dynamicString": {
                        return {
                          discriminator: "dynamicString",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicString"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicBoolean": {
                        return {
                          discriminator: "dynamicBoolean",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicBoolean"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: boolean): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicNumber": {
                        return {
                          discriminator: "dynamicNumber",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicNumber"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: number): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "dynamicVoid": {
                        return {
                          discriminator: "dynamicVoid",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                            return {
                              discriminator: ((value: "dynamicVoid"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: string): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                        };
                      }
                      case "channel": {
                        return {
                          discriminator: "channel",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                            return {
                              discriminator: ((value: "channel"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "channel"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "primary-skill": {
                        return {
                          discriminator: "primary-skill",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                            return {
                              discriminator: ((value: "primary-skill"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "primary-skill"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "skill": {
                        return {
                          discriminator: "skill",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                            return {
                              discriminator: ((value: "skill"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "skill"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "key-quality": {
                        return {
                          discriminator: "key-quality",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                            return {
                              discriminator: ((value: "key-quality"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "key-quality"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                      case "classification": {
                        return {
                          discriminator: "classification",
                          exclude: ((value: boolean): unknown => {
                            return value;
                          })(value["exclude"]),
                          source: ((value: "USER" | "AI"): unknown => {
                            return value;
                          })(value["source"]),
                          tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                            return {
                              discriminator: ((value: "classification"): unknown => {
                                return value;
                              })(value["discriminator"]),
                              id: ((value: bigint): unknown => {
                                return value;
                              })(value["id"]),
                              kind: ((value: "classification"): unknown => {
                                return value;
                              })(value["kind"]),
                            };
                          })(value["tagKind"]),
                          value: ((value: string): unknown => {
                            return value;
                          })(value["value"]),
                        };
                      }
                    }
                  },
                );
              })(value["tags"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
            };
          })(value["actionable"]),
          relayState: ((value: string): unknown => {
            return value;
          })(value["relayState"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_WarmupV2RelayType(str: string): WarmupV2RelayType | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | { success: true; value: { kind: "DEFAULT"; relayState: string } | { kind: "SUMMARY"; relayState: string; summary: SummaryMetadata } | { kind: "ACTIONABLE"; actionable: ActionableMetadata; relayState: string } } => {
    return ((
      value: unknown,
    ):
      | { success: false }
      | { success: true; value: { kind: "DEFAULT"; relayState: string } | { kind: "SUMMARY"; relayState: string; summary: SummaryMetadata } | { kind: "ACTIONABLE"; actionable: ActionableMetadata; relayState: string } } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "kind")) {
        return { success: false };
      }
      if (typeof value["kind"] != "string") {
        return { success: false };
      }
      const discriminator = value["kind"];
      switch (discriminator) {
        case "DEFAULT": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  relayState: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "relayState")) {
              return { success: false };
            }
            const parsed_relayState = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["relayState"]);
            })();
            if (!parsed_relayState.success) {
              return { success: false };
            }
            return { success: true, value: { relayState: parsed_relayState.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "DEFAULT" as const, ...parsedVariant.value } };
        }
        case "SUMMARY": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  relayState: string;
                  summary: SummaryMetadata;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "relayState")) {
              return { success: false };
            }
            const parsed_relayState = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["relayState"]);
            })();
            if (!parsed_relayState.success) {
              return { success: false };
            }
            if (!hasProp(value, "summary")) {
              return { success: false };
            }
            const parsed_summary = (() => {
              return ((value: unknown): { success: false } | { success: true; value: SummaryMetadata } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        activatedAt: DateTime<true> | null;
                        activationTime: DateTime<true> | null;
                        active: boolean;
                        audioActivatedAt: DateTime<true> | null;
                        audioActive: boolean;
                        audioDuration: number;
                        authorInfo: string | null;
                        authors: {
                          authorId: number;
                          firstName: string | null;
                          gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                          lastName: string | null;
                          middleName: string | null;
                        }[];
                        backgroundColor: string | null;
                        bestseller: boolean;
                        businessClassic: boolean;
                        canonicalName: string;
                        contentItemId: bigint;
                        contentItemType: ContentItemType;
                        countriesExcluded: Country[];
                        countriesIncluded: Country[];
                        coverModifiedAt: DateTime<true> | null;
                        coverUri: string;
                        dataId: number;
                        descriptionHtml: string | null;
                        documentType: SummaryDocumentType;
                        duration: number;
                        imageBorder: boolean;
                        language: Language;
                        modifiedAt: DateTime<true> | null;
                        originalTitle: string | null;
                        previewAvailable: boolean;
                        publicationDate: string | null;
                        publisherInfo: string | null;
                        publisherName: string | null;
                        rating: number | null;
                        sourceType: SummarySourceType;
                        subtitle: string | null;
                        summaryPages: number;
                        summaryWords: number;
                        tags: Tag[];
                        teaser: string | null;
                        title: string | null;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "activatedAt")) {
                    return { success: false };
                  }
                  const parsed_activatedAt = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "number") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromMillis(value);
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: parsed };
                    })(value["activatedAt"]);
                  })();
                  if (!parsed_activatedAt.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "activationTime")) {
                    return { success: false };
                  }
                  const parsed_activationTime = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "number") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromMillis(value);
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: parsed };
                    })(value["activationTime"]);
                  })();
                  if (!parsed_activationTime.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "active")) {
                    return { success: false };
                  }
                  const parsed_active = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["active"]);
                  })();
                  if (!parsed_active.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "audioActivatedAt")) {
                    return { success: false };
                  }
                  const parsed_audioActivatedAt = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "number") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromMillis(value);
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: parsed };
                    })(value["audioActivatedAt"]);
                  })();
                  if (!parsed_audioActivatedAt.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "audioActive")) {
                    return { success: false };
                  }
                  const parsed_audioActive = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["audioActive"]);
                  })();
                  if (!parsed_audioActive.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "audioDuration")) {
                    return { success: false };
                  }
                  const parsed_audioDuration = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["audioDuration"]);
                  })();
                  if (!parsed_audioDuration.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "authorInfo")) {
                    return { success: false };
                  }
                  const parsed_authorInfo = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["authorInfo"]);
                  })();
                  if (!parsed_authorInfo.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "authors")) {
                    return { success: false };
                  }
                  const parsed_authors = (() => {
                    return ((
                      value: unknown,
                    ):
                      | { success: false }
                      | {
                          success: true;
                          value: {
                            authorId: number;
                            firstName: string | null;
                            gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                            lastName: string | null;
                            middleName: string | null;
                          }[];
                        } => {
                      if (!isArray(value)) {
                        return { success: false };
                      }
                      const parsed = value.map(
                        (
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                authorId: number;
                                firstName: string | null;
                                gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                                lastName: string | null;
                                middleName: string | null;
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "authorId")) {
                            return { success: false };
                          }
                          const parsed_authorId = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: number } => {
                              if (typeof value === "number") {
                                return { success: true, value: value };
                              }
                              return { success: false };
                            })(value["authorId"]);
                          })();
                          if (!parsed_authorId.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "firstName")) {
                            return { success: false };
                          }
                          const parsed_firstName = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["firstName"]);
                          })();
                          if (!parsed_firstName.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "gender")) {
                            return { success: false };
                          }
                          const parsed_gender = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "MALE":
                                  return { success: true, value: "MALE" };
                                case "FEMALE":
                                  return { success: true, value: "FEMALE" };
                                case "CUSTOM":
                                  return { success: true, value: "CUSTOM" };
                                case "UNKNOWN":
                                  return { success: true, value: "UNKNOWN" };
                                default:
                                  return { success: false };
                              }
                            })(value["gender"]);
                          })();
                          if (!parsed_gender.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "lastName")) {
                            return { success: false };
                          }
                          const parsed_lastName = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["lastName"]);
                          })();
                          if (!parsed_lastName.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "middleName")) {
                            return { success: false };
                          }
                          const parsed_middleName = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                              if (value === null) {
                                return { success: true, value: null };
                              }
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["middleName"]);
                          })();
                          if (!parsed_middleName.success) {
                            return { success: false };
                          }
                          return { success: true, value: { authorId: parsed_authorId.value, firstName: parsed_firstName.value, gender: parsed_gender.value, lastName: parsed_lastName.value, middleName: parsed_middleName.value } };
                        },
                      );
                      if (parsed.find((value) => !value.success) !== undefined) {
                        return { success: false };
                      }
                      return {
                        success: true,
                        value: parsed.map((entry) => {
                          assert(entry.success == true);
                          return entry.value;
                        }),
                      };
                    })(value["authors"]);
                  })();
                  if (!parsed_authors.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "backgroundColor")) {
                    return { success: false };
                  }
                  const parsed_backgroundColor = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["backgroundColor"]);
                  })();
                  if (!parsed_backgroundColor.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "bestseller")) {
                    return { success: false };
                  }
                  const parsed_bestseller = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["bestseller"]);
                  })();
                  if (!parsed_bestseller.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "businessClassic")) {
                    return { success: false };
                  }
                  const parsed_businessClassic = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["businessClassic"]);
                  })();
                  if (!parsed_businessClassic.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "canonicalName")) {
                    return { success: false };
                  }
                  const parsed_canonicalName = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["canonicalName"]);
                  })();
                  if (!parsed_canonicalName.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "contentItemId")) {
                    return { success: false };
                  }
                  const parsed_contentItemId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["contentItemId"]);
                  })();
                  if (!parsed_contentItemId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "contentItemType")) {
                    return { success: false };
                  }
                  const parsed_contentItemType = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: ContentItemType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE" | "MIAB" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "UNKNOWN":
                            return { success: true, value: "UNKNOWN" };
                          case "SUMMARY":
                            return { success: true, value: "SUMMARY" };
                          case "CATEGORY":
                            return { success: true, value: "CATEGORY" };
                          case "CHANNEL":
                            return { success: true, value: "CHANNEL" };
                          case "LGXP":
                            return { success: true, value: "LGXP" };
                          case "ACTIONABLE":
                            return { success: true, value: "ACTIONABLE" };
                          case "MYLIST":
                            return { success: true, value: "MYLIST" };
                          case "CUSTOMPAGE":
                            return { success: true, value: "CUSTOMPAGE" };
                          case "MIAB":
                            return { success: true, value: "MIAB" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["contentItemType"]);
                  })();
                  if (!parsed_contentItemType.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "countriesExcluded")) {
                    return { success: false };
                  }
                  const parsed_countriesExcluded = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: Country[] } => {
                      if (!isArray(value)) {
                        return { success: false };
                      }
                      const parsed = value.map((value: unknown): { success: false } | { success: true; value: Country } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                countryCode: string;
                                currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                                names: {
                                  de: string;
                                  en: string;
                                  es: string;
                                  fr: string;
                                  pt: string;
                                  ru: string;
                                  zh: string;
                                };
                                phonePrefix: string;
                                region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "countryCode")) {
                            return { success: false };
                          }
                          const parsed_countryCode = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["countryCode"]);
                          })();
                          if (!parsed_countryCode.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "currency")) {
                            return { success: false };
                          }
                          const parsed_currency = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "CHF":
                                  return { success: true, value: "CHF" };
                                case "CNY":
                                  return { success: true, value: "CNY" };
                                case "USD":
                                  return { success: true, value: "USD" };
                                case "EUR":
                                  return { success: true, value: "EUR" };
                                case "GBP":
                                  return { success: true, value: "GBP" };
                                case "INR":
                                  return { success: true, value: "INR" };
                                case "JPY":
                                  return { success: true, value: "JPY" };
                                default:
                                  return { success: false };
                              }
                            })(value["currency"]);
                          })();
                          if (!parsed_currency.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "names")) {
                            return { success: false };
                          }
                          const parsed_names = (() => {
                            return ((
                              value: unknown,
                            ):
                              | { success: false }
                              | {
                                  success: true;
                                  value: {
                                    de: string;
                                    en: string;
                                    es: string;
                                    fr: string;
                                    pt: string;
                                    ru: string;
                                    zh: string;
                                  };
                                } => {
                              if (typeof value !== "object") {
                                return { success: false };
                              }
                              if (value === null) {
                                return { success: false };
                              }
                              if (!hasProp(value, "de")) {
                                return { success: false };
                              }
                              const parsed_de = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["de"]);
                              })();
                              if (!parsed_de.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "en")) {
                                return { success: false };
                              }
                              const parsed_en = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["en"]);
                              })();
                              if (!parsed_en.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "es")) {
                                return { success: false };
                              }
                              const parsed_es = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["es"]);
                              })();
                              if (!parsed_es.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "fr")) {
                                return { success: false };
                              }
                              const parsed_fr = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["fr"]);
                              })();
                              if (!parsed_fr.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "pt")) {
                                return { success: false };
                              }
                              const parsed_pt = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["pt"]);
                              })();
                              if (!parsed_pt.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "ru")) {
                                return { success: false };
                              }
                              const parsed_ru = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["ru"]);
                              })();
                              if (!parsed_ru.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "zh")) {
                                return { success: false };
                              }
                              const parsed_zh = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["zh"]);
                              })();
                              if (!parsed_zh.success) {
                                return { success: false };
                              }
                              return { success: true, value: { de: parsed_de.value, en: parsed_en.value, es: parsed_es.value, fr: parsed_fr.value, pt: parsed_pt.value, ru: parsed_ru.value, zh: parsed_zh.value } };
                            })(value["names"]);
                          })();
                          if (!parsed_names.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "phonePrefix")) {
                            return { success: false };
                          }
                          const parsed_phonePrefix = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["phonePrefix"]);
                          })();
                          if (!parsed_phonePrefix.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "region")) {
                            return { success: false };
                          }
                          const parsed_region = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "SOUTHAMERICA":
                                  return { success: true, value: "SOUTHAMERICA" };
                                case "EUROPE":
                                  return { success: true, value: "EUROPE" };
                                case "OCEANIA":
                                  return { success: true, value: "OCEANIA" };
                                case "NORTHAMERICA":
                                  return { success: true, value: "NORTHAMERICA" };
                                case "AFRICA":
                                  return { success: true, value: "AFRICA" };
                                case "ANTARCTICA":
                                  return { success: true, value: "ANTARCTICA" };
                                case "ASIA":
                                  return { success: true, value: "ASIA" };
                                default:
                                  return { success: false };
                              }
                            })(value["region"]);
                          })();
                          if (!parsed_region.success) {
                            return { success: false };
                          }
                          return { success: true, value: { countryCode: parsed_countryCode.value, currency: parsed_currency.value, names: parsed_names.value, phonePrefix: parsed_phonePrefix.value, region: parsed_region.value } };
                        })(value);
                      });
                      if (parsed.find((value) => !value.success) !== undefined) {
                        return { success: false };
                      }
                      return {
                        success: true,
                        value: parsed.map((entry) => {
                          assert(entry.success == true);
                          return entry.value;
                        }),
                      };
                    })(value["countriesExcluded"]);
                  })();
                  if (!parsed_countriesExcluded.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "countriesIncluded")) {
                    return { success: false };
                  }
                  const parsed_countriesIncluded = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: Country[] } => {
                      if (!isArray(value)) {
                        return { success: false };
                      }
                      const parsed = value.map((value: unknown): { success: false } | { success: true; value: Country } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value: {
                                countryCode: string;
                                currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                                names: {
                                  de: string;
                                  en: string;
                                  es: string;
                                  fr: string;
                                  pt: string;
                                  ru: string;
                                  zh: string;
                                };
                                phonePrefix: string;
                                region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                              };
                            } => {
                          if (typeof value !== "object") {
                            return { success: false };
                          }
                          if (value === null) {
                            return { success: false };
                          }
                          if (!hasProp(value, "countryCode")) {
                            return { success: false };
                          }
                          const parsed_countryCode = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["countryCode"]);
                          })();
                          if (!parsed_countryCode.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "currency")) {
                            return { success: false };
                          }
                          const parsed_currency = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "CHF":
                                  return { success: true, value: "CHF" };
                                case "CNY":
                                  return { success: true, value: "CNY" };
                                case "USD":
                                  return { success: true, value: "USD" };
                                case "EUR":
                                  return { success: true, value: "EUR" };
                                case "GBP":
                                  return { success: true, value: "GBP" };
                                case "INR":
                                  return { success: true, value: "INR" };
                                case "JPY":
                                  return { success: true, value: "JPY" };
                                default:
                                  return { success: false };
                              }
                            })(value["currency"]);
                          })();
                          if (!parsed_currency.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "names")) {
                            return { success: false };
                          }
                          const parsed_names = (() => {
                            return ((
                              value: unknown,
                            ):
                              | { success: false }
                              | {
                                  success: true;
                                  value: {
                                    de: string;
                                    en: string;
                                    es: string;
                                    fr: string;
                                    pt: string;
                                    ru: string;
                                    zh: string;
                                  };
                                } => {
                              if (typeof value !== "object") {
                                return { success: false };
                              }
                              if (value === null) {
                                return { success: false };
                              }
                              if (!hasProp(value, "de")) {
                                return { success: false };
                              }
                              const parsed_de = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["de"]);
                              })();
                              if (!parsed_de.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "en")) {
                                return { success: false };
                              }
                              const parsed_en = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["en"]);
                              })();
                              if (!parsed_en.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "es")) {
                                return { success: false };
                              }
                              const parsed_es = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["es"]);
                              })();
                              if (!parsed_es.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "fr")) {
                                return { success: false };
                              }
                              const parsed_fr = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["fr"]);
                              })();
                              if (!parsed_fr.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "pt")) {
                                return { success: false };
                              }
                              const parsed_pt = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["pt"]);
                              })();
                              if (!parsed_pt.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "ru")) {
                                return { success: false };
                              }
                              const parsed_ru = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["ru"]);
                              })();
                              if (!parsed_ru.success) {
                                return { success: false };
                              }
                              if (!hasProp(value, "zh")) {
                                return { success: false };
                              }
                              const parsed_zh = (() => {
                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                  if (typeof value !== "string") {
                                    return { success: false };
                                  }
                                  return { success: true, value: value };
                                })(value["zh"]);
                              })();
                              if (!parsed_zh.success) {
                                return { success: false };
                              }
                              return { success: true, value: { de: parsed_de.value, en: parsed_en.value, es: parsed_es.value, fr: parsed_fr.value, pt: parsed_pt.value, ru: parsed_ru.value, zh: parsed_zh.value } };
                            })(value["names"]);
                          })();
                          if (!parsed_names.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "phonePrefix")) {
                            return { success: false };
                          }
                          const parsed_phonePrefix = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: string } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              return { success: true, value: value };
                            })(value["phonePrefix"]);
                          })();
                          if (!parsed_phonePrefix.success) {
                            return { success: false };
                          }
                          if (!hasProp(value, "region")) {
                            return { success: false };
                          }
                          const parsed_region = (() => {
                            return ((value: unknown): { success: false } | { success: true; value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "SOUTHAMERICA":
                                  return { success: true, value: "SOUTHAMERICA" };
                                case "EUROPE":
                                  return { success: true, value: "EUROPE" };
                                case "OCEANIA":
                                  return { success: true, value: "OCEANIA" };
                                case "NORTHAMERICA":
                                  return { success: true, value: "NORTHAMERICA" };
                                case "AFRICA":
                                  return { success: true, value: "AFRICA" };
                                case "ANTARCTICA":
                                  return { success: true, value: "ANTARCTICA" };
                                case "ASIA":
                                  return { success: true, value: "ASIA" };
                                default:
                                  return { success: false };
                              }
                            })(value["region"]);
                          })();
                          if (!parsed_region.success) {
                            return { success: false };
                          }
                          return { success: true, value: { countryCode: parsed_countryCode.value, currency: parsed_currency.value, names: parsed_names.value, phonePrefix: parsed_phonePrefix.value, region: parsed_region.value } };
                        })(value);
                      });
                      if (parsed.find((value) => !value.success) !== undefined) {
                        return { success: false };
                      }
                      return {
                        success: true,
                        value: parsed.map((entry) => {
                          assert(entry.success == true);
                          return entry.value;
                        }),
                      };
                    })(value["countriesIncluded"]);
                  })();
                  if (!parsed_countriesIncluded.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "coverModifiedAt")) {
                    return { success: false };
                  }
                  const parsed_coverModifiedAt = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "number") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromMillis(value);
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: parsed };
                    })(value["coverModifiedAt"]);
                  })();
                  if (!parsed_coverModifiedAt.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "coverUri")) {
                    return { success: false };
                  }
                  const parsed_coverUri = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["coverUri"]);
                  })();
                  if (!parsed_coverUri.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "dataId")) {
                    return { success: false };
                  }
                  const parsed_dataId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["dataId"]);
                  })();
                  if (!parsed_dataId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "descriptionHtml")) {
                    return { success: false };
                  }
                  const parsed_descriptionHtml = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["descriptionHtml"]);
                  })();
                  if (!parsed_descriptionHtml.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "documentType")) {
                    return { success: false };
                  }
                  const parsed_documentType = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "ABSTRACT":
                            return { success: true, value: "ABSTRACT" };
                          case "REVIEW":
                            return { success: true, value: "REVIEW" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["documentType"]);
                  })();
                  if (!parsed_documentType.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "duration")) {
                    return { success: false };
                  }
                  const parsed_duration = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["duration"]);
                  })();
                  if (!parsed_duration.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "imageBorder")) {
                    return { success: false };
                  }
                  const parsed_imageBorder = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["imageBorder"]);
                  })();
                  if (!parsed_imageBorder.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "language")) {
                    return { success: false };
                  }
                  const parsed_language = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: Language } => {
                      return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "en":
                            return { success: true, value: "en" };
                          case "de":
                            return { success: true, value: "de" };
                          case "es":
                            return { success: true, value: "es" };
                          case "ru":
                            return { success: true, value: "ru" };
                          case "zh":
                            return { success: true, value: "zh" };
                          case "pt":
                            return { success: true, value: "pt" };
                          case "fr":
                            return { success: true, value: "fr" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["language"]);
                  })();
                  if (!parsed_language.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "modifiedAt")) {
                    return { success: false };
                  }
                  const parsed_modifiedAt = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "number") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromMillis(value);
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: parsed };
                    })(value["modifiedAt"]);
                  })();
                  if (!parsed_modifiedAt.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "originalTitle")) {
                    return { success: false };
                  }
                  const parsed_originalTitle = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["originalTitle"]);
                  })();
                  if (!parsed_originalTitle.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "previewAvailable")) {
                    return { success: false };
                  }
                  const parsed_previewAvailable = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["previewAvailable"]);
                  })();
                  if (!parsed_previewAvailable.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "publicationDate")) {
                    return { success: false };
                  }
                  const parsed_publicationDate = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["publicationDate"]);
                  })();
                  if (!parsed_publicationDate.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "publisherInfo")) {
                    return { success: false };
                  }
                  const parsed_publisherInfo = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["publisherInfo"]);
                  })();
                  if (!parsed_publisherInfo.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "publisherName")) {
                    return { success: false };
                  }
                  const parsed_publisherName = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["publisherName"]);
                  })();
                  if (!parsed_publisherName.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "rating")) {
                    return { success: false };
                  }
                  const parsed_rating = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["rating"]);
                  })();
                  if (!parsed_rating.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "sourceType")) {
                    return { success: false };
                  }
                  const parsed_sourceType = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
                      return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "BOOK":
                            return { success: true, value: "BOOK" };
                          case "VIDEO":
                            return { success: true, value: "VIDEO" };
                          case "ARTICLE":
                            return { success: true, value: "ARTICLE" };
                          case "CHAPTER":
                            return { success: true, value: "CHAPTER" };
                          case "REPORT":
                            return { success: true, value: "REPORT" };
                          case "PODCAST":
                            return { success: true, value: "PODCAST" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["sourceType"]);
                  })();
                  if (!parsed_sourceType.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "subtitle")) {
                    return { success: false };
                  }
                  const parsed_subtitle = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["subtitle"]);
                  })();
                  if (!parsed_subtitle.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "summaryPages")) {
                    return { success: false };
                  }
                  const parsed_summaryPages = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["summaryPages"]);
                  })();
                  if (!parsed_summaryPages.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "summaryWords")) {
                    return { success: false };
                  }
                  const parsed_summaryWords = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["summaryWords"]);
                  })();
                  if (!parsed_summaryWords.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tags")) {
                    return { success: false };
                  }
                  const parsed_tags = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
                      if (!isArray(value)) {
                        return { success: false };
                      }
                      const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value:
                                | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                                | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                                | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                                | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                                | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                                | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                                | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                                | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                                | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                            } => {
                          return ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value:
                                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                                  | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                                  | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                              } => {
                            if (value == null) {
                              return { success: false };
                            }
                            if (typeof value != "object") {
                              return { success: false };
                            }
                            if (!hasProp(value, "discriminator")) {
                              return { success: false };
                            }
                            if (typeof value["discriminator"] != "string") {
                              return { success: false };
                            }
                            const discriminator = value["discriminator"];
                            switch (discriminator) {
                              case "dynamicString": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindDynamicString;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindDynamicString;
                                              id: bigint;
                                              kind: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "dynamicString":
                                                  return { success: true, value: "dynamicString" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                              }
                              case "dynamicBoolean": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindDynamicBoolean;
                                        value: boolean;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindDynamicBoolean;
                                              id: bigint;
                                              kind: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "dynamicBoolean":
                                                  return { success: true, value: "dynamicBoolean" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                              }
                              case "dynamicNumber": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindDynamicNumber;
                                        value: number;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindDynamicNumber;
                                              id: bigint;
                                              kind: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "dynamicNumber":
                                                  return { success: true, value: "dynamicNumber" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                              }
                              case "dynamicVoid": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindDynamicVoid;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindDynamicVoid;
                                              id: bigint;
                                              kind: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "dynamicVoid":
                                                  return { success: true, value: "dynamicVoid" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                              }
                              case "channel": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindChannel;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindChannel;
                                              id: bigint;
                                              kind: KindChannel;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "channel":
                                                  return { success: true, value: "channel" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "channel":
                                                  return { success: true, value: "channel" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                              }
                              case "primary-skill": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindPrimarySkill;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindPrimarySkill;
                                              id: bigint;
                                              kind: KindPrimarySkill;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "primary-skill":
                                                  return { success: true, value: "primary-skill" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "primary-skill":
                                                  return { success: true, value: "primary-skill" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                              }
                              case "skill": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindSkill;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindSkill;
                                              id: bigint;
                                              kind: KindSkill;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "skill":
                                                  return { success: true, value: "skill" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "skill":
                                                  return { success: true, value: "skill" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                              }
                              case "key-quality": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindKeyQuality;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindKeyQuality;
                                              id: bigint;
                                              kind: KindKeyQuality;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "key-quality":
                                                  return { success: true, value: "key-quality" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "key-quality":
                                                  return { success: true, value: "key-quality" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                              }
                              case "classification": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindClassification;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindClassification;
                                              id: bigint;
                                              kind: KindClassification;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "classification":
                                                  return { success: true, value: "classification" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "classification":
                                                  return { success: true, value: "classification" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                              }
                              default:
                                return { success: false };
                            }
                          })(value);
                        })(value);
                      });
                      if (parsed.find((value) => !value.success) !== undefined) {
                        return { success: false };
                      }
                      return {
                        success: true,
                        value: parsed.map((entry) => {
                          assert(entry.success == true);
                          return entry.value;
                        }),
                      };
                    })(value["tags"]);
                  })();
                  if (!parsed_tags.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "teaser")) {
                    return { success: false };
                  }
                  const parsed_teaser = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["teaser"]);
                  })();
                  if (!parsed_teaser.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "title")) {
                    return { success: false };
                  }
                  const parsed_title = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["title"]);
                  })();
                  if (!parsed_title.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      activatedAt: parsed_activatedAt.value,
                      activationTime: parsed_activationTime.value,
                      active: parsed_active.value,
                      audioActivatedAt: parsed_audioActivatedAt.value,
                      audioActive: parsed_audioActive.value,
                      audioDuration: parsed_audioDuration.value,
                      authorInfo: parsed_authorInfo.value,
                      authors: parsed_authors.value,
                      backgroundColor: parsed_backgroundColor.value,
                      bestseller: parsed_bestseller.value,
                      businessClassic: parsed_businessClassic.value,
                      canonicalName: parsed_canonicalName.value,
                      contentItemId: parsed_contentItemId.value,
                      contentItemType: parsed_contentItemType.value,
                      countriesExcluded: parsed_countriesExcluded.value,
                      countriesIncluded: parsed_countriesIncluded.value,
                      coverModifiedAt: parsed_coverModifiedAt.value,
                      coverUri: parsed_coverUri.value,
                      dataId: parsed_dataId.value,
                      descriptionHtml: parsed_descriptionHtml.value,
                      documentType: parsed_documentType.value,
                      duration: parsed_duration.value,
                      imageBorder: parsed_imageBorder.value,
                      language: parsed_language.value,
                      modifiedAt: parsed_modifiedAt.value,
                      originalTitle: parsed_originalTitle.value,
                      previewAvailable: parsed_previewAvailable.value,
                      publicationDate: parsed_publicationDate.value,
                      publisherInfo: parsed_publisherInfo.value,
                      publisherName: parsed_publisherName.value,
                      rating: parsed_rating.value,
                      sourceType: parsed_sourceType.value,
                      subtitle: parsed_subtitle.value,
                      summaryPages: parsed_summaryPages.value,
                      summaryWords: parsed_summaryWords.value,
                      tags: parsed_tags.value,
                      teaser: parsed_teaser.value,
                      title: parsed_title.value,
                    },
                  };
                })(value);
              })(value["summary"]);
            })();
            if (!parsed_summary.success) {
              return { success: false };
            }
            return { success: true, value: { relayState: parsed_relayState.value, summary: parsed_summary.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "SUMMARY" as const, ...parsedVariant.value } };
        }
        case "ACTIONABLE": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  actionable: ActionableMetadata;
                  relayState: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "actionable")) {
              return { success: false };
            }
            const parsed_actionable = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ActionableMetadata } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        actionableId: bigint;
                        activatedAt: DateTime<true> | null;
                        active: boolean;
                        coverUri: string;
                        description: string;
                        language: Language;
                        modifiedAt: DateTime<true>;
                        name: string;
                        tags: Tag[];
                        title: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "actionableId")) {
                    return { success: false };
                  }
                  const parsed_actionableId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                      if (typeof value === "number") {
                        return { success: true, value: BigInt(value) };
                      }
                      if (typeof value === "bigint") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["actionableId"]);
                  })();
                  if (!parsed_actionableId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "activatedAt")) {
                    return { success: false };
                  }
                  const parsed_activatedAt = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "number") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromMillis(value);
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: parsed };
                    })(value["activatedAt"]);
                  })();
                  if (!parsed_activatedAt.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "active")) {
                    return { success: false };
                  }
                  const parsed_active = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["active"]);
                  })();
                  if (!parsed_active.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "coverUri")) {
                    return { success: false };
                  }
                  const parsed_coverUri = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["coverUri"]);
                  })();
                  if (!parsed_coverUri.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "description")) {
                    return { success: false };
                  }
                  const parsed_description = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["description"]);
                  })();
                  if (!parsed_description.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "language")) {
                    return { success: false };
                  }
                  const parsed_language = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: Language } => {
                      return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "en":
                            return { success: true, value: "en" };
                          case "de":
                            return { success: true, value: "de" };
                          case "es":
                            return { success: true, value: "es" };
                          case "ru":
                            return { success: true, value: "ru" };
                          case "zh":
                            return { success: true, value: "zh" };
                          case "pt":
                            return { success: true, value: "pt" };
                          case "fr":
                            return { success: true, value: "fr" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["language"]);
                  })();
                  if (!parsed_language.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "modifiedAt")) {
                    return { success: false };
                  }
                  const parsed_modifiedAt = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                      if (typeof value !== "number") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromMillis(value);
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: parsed };
                    })(value["modifiedAt"]);
                  })();
                  if (!parsed_modifiedAt.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "name")) {
                    return { success: false };
                  }
                  const parsed_name = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["name"]);
                  })();
                  if (!parsed_name.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tags")) {
                    return { success: false };
                  }
                  const parsed_tags = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
                      if (!isArray(value)) {
                        return { success: false };
                      }
                      const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                        return ((
                          value: unknown,
                        ):
                          | { success: false }
                          | {
                              success: true;
                              value:
                                | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                                | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                                | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                                | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                                | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                                | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                                | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                                | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                                | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                            } => {
                          return ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value:
                                  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                                  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                                  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                                  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                                  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                                  | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                                  | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                                  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                                  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                              } => {
                            if (value == null) {
                              return { success: false };
                            }
                            if (typeof value != "object") {
                              return { success: false };
                            }
                            if (!hasProp(value, "discriminator")) {
                              return { success: false };
                            }
                            if (typeof value["discriminator"] != "string") {
                              return { success: false };
                            }
                            const discriminator = value["discriminator"];
                            switch (discriminator) {
                              case "dynamicString": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindDynamicString;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindDynamicString;
                                              id: bigint;
                                              kind: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "dynamicString":
                                                  return { success: true, value: "dynamicString" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                              }
                              case "dynamicBoolean": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindDynamicBoolean;
                                        value: boolean;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindDynamicBoolean;
                                              id: bigint;
                                              kind: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "dynamicBoolean":
                                                  return { success: true, value: "dynamicBoolean" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                              }
                              case "dynamicNumber": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindDynamicNumber;
                                        value: number;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindDynamicNumber;
                                              id: bigint;
                                              kind: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "dynamicNumber":
                                                  return { success: true, value: "dynamicNumber" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                                      if (typeof value === "number") {
                                        return { success: true, value: value };
                                      }
                                      return { success: false };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                              }
                              case "dynamicVoid": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindDynamicVoid;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindDynamicVoid;
                                              id: bigint;
                                              kind: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "dynamicVoid":
                                                  return { success: true, value: "dynamicVoid" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                              }
                              case "channel": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindChannel;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindChannel;
                                              id: bigint;
                                              kind: KindChannel;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "channel":
                                                  return { success: true, value: "channel" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "channel":
                                                  return { success: true, value: "channel" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                              }
                              case "primary-skill": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindPrimarySkill;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindPrimarySkill;
                                              id: bigint;
                                              kind: KindPrimarySkill;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "primary-skill":
                                                  return { success: true, value: "primary-skill" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "primary-skill":
                                                  return { success: true, value: "primary-skill" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                              }
                              case "skill": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindSkill;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindSkill;
                                              id: bigint;
                                              kind: KindSkill;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "skill":
                                                  return { success: true, value: "skill" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "skill":
                                                  return { success: true, value: "skill" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                              }
                              case "key-quality": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindKeyQuality;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindKeyQuality;
                                              id: bigint;
                                              kind: KindKeyQuality;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "key-quality":
                                                  return { success: true, value: "key-quality" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "key-quality":
                                                  return { success: true, value: "key-quality" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                              }
                              case "classification": {
                                const parsedVariant = ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value: {
                                        exclude: boolean;
                                        source: TagSourceType;
                                        tagKind: TagKindClassification;
                                        value: string;
                                      };
                                    } => {
                                  if (typeof value !== "object") {
                                    return { success: false };
                                  }
                                  if (value === null) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "exclude")) {
                                    return { success: false };
                                  }
                                  const parsed_exclude = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                      if (typeof value !== "boolean") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["exclude"]);
                                  })();
                                  if (!parsed_exclude.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "source")) {
                                    return { success: false };
                                  }
                                  const parsed_source = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                      return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        switch (value) {
                                          case "USER":
                                            return { success: true, value: "USER" };
                                          case "AI":
                                            return { success: true, value: "AI" };
                                          default:
                                            return { success: false };
                                        }
                                      })(value);
                                    })(value["source"]);
                                  })();
                                  if (!parsed_source.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "tagKind")) {
                                    return { success: false };
                                  }
                                  const parsed_tagKind = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                                      return ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              discriminator: KindClassification;
                                              id: bigint;
                                              kind: KindClassification;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "discriminator")) {
                                          return { success: false };
                                        }
                                        const parsed_discriminator = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "classification":
                                                  return { success: true, value: "classification" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["discriminator"]);
                                        })();
                                        if (!parsed_discriminator.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "id")) {
                                          return { success: false };
                                        }
                                        const parsed_id = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: BigInt(value) };
                                            }
                                            if (typeof value === "bigint") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["id"]);
                                        })();
                                        if (!parsed_id.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "kind")) {
                                          return { success: false };
                                        }
                                        const parsed_kind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "classification":
                                                  return { success: true, value: "classification" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["kind"]);
                                        })();
                                        if (!parsed_kind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                      })(value);
                                    })(value["tagKind"]);
                                  })();
                                  if (!parsed_tagKind.success) {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "value")) {
                                    return { success: false };
                                  }
                                  const parsed_value = (() => {
                                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                                      if (typeof value !== "string") {
                                        return { success: false };
                                      }
                                      return { success: true, value: value };
                                    })(value["value"]);
                                  })();
                                  if (!parsed_value.success) {
                                    return { success: false };
                                  }
                                  return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                })(value);
                                if (!parsedVariant.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                              }
                              default:
                                return { success: false };
                            }
                          })(value);
                        })(value);
                      });
                      if (parsed.find((value) => !value.success) !== undefined) {
                        return { success: false };
                      }
                      return {
                        success: true,
                        value: parsed.map((entry) => {
                          assert(entry.success == true);
                          return entry.value;
                        }),
                      };
                    })(value["tags"]);
                  })();
                  if (!parsed_tags.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "title")) {
                    return { success: false };
                  }
                  const parsed_title = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["title"]);
                  })();
                  if (!parsed_title.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      actionableId: parsed_actionableId.value,
                      activatedAt: parsed_activatedAt.value,
                      active: parsed_active.value,
                      coverUri: parsed_coverUri.value,
                      description: parsed_description.value,
                      language: parsed_language.value,
                      modifiedAt: parsed_modifiedAt.value,
                      name: parsed_name.value,
                      tags: parsed_tags.value,
                      title: parsed_title.value,
                    },
                  };
                })(value);
              })(value["actionable"]);
            })();
            if (!parsed_actionable.success) {
              return { success: false };
            }
            if (!hasProp(value, "relayState")) {
              return { success: false };
            }
            const parsed_relayState = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["relayState"]);
            })();
            if (!parsed_relayState.success) {
              return { success: false };
            }
            return { success: true, value: { actionable: parsed_actionable.value, relayState: parsed_relayState.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "ACTIONABLE" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// WarmupV2SsoRequest
export function serialize_WarmupV2SsoRequest(obj: WarmupV2SsoRequest): string {
  const serialized = ((value: { corporateId: number; function: string | null; ssoUid: string; title: number | null; town: string | null }): unknown => {
    return {
      corporateId: ((value: number): unknown => {
        return value;
      })(value["corporateId"]),
      function: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["function"]),
      ssoUid: ((value: string): unknown => {
        return value;
      })(value["ssoUid"]),
      title: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["title"]),
      town: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["town"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_WarmupV2SsoRequest(str: string): WarmupV2SsoRequest | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          corporateId: number;
          function: string | null;
          ssoUid: string;
          title: number | null;
          town: string | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "corporateId")) {
      return { success: false };
    }
    const parsed_corporateId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["corporateId"]);
    })();
    if (!parsed_corporateId.success) {
      return { success: false };
    }
    if (!hasProp(value, "function")) {
      return { success: false };
    }
    const parsed_function = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["function"]);
    })();
    if (!parsed_function.success) {
      return { success: false };
    }
    if (!hasProp(value, "ssoUid")) {
      return { success: false };
    }
    const parsed_ssoUid = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["ssoUid"]);
    })();
    if (!parsed_ssoUid.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    if (!hasProp(value, "town")) {
      return { success: false };
    }
    const parsed_town = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["town"]);
    })();
    if (!parsed_town.success) {
      return { success: false };
    }
    return { success: true, value: { corporateId: parsed_corporateId.value, function: parsed_function.value, ssoUid: parsed_ssoUid.value, title: parsed_title.value, town: parsed_town.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// WarmupV2Submission
export function serialize_WarmupV2Submission(obj: WarmupV2Submission): string {
  const serialized = ((value: { evrUuid: string; form: WarmupV2EditForm; ssoRequest: WarmupV2SsoRequest }): unknown => {
    return {
      evrUuid: ((value: string): unknown => {
        return value;
      })(value["evrUuid"]),
      form: ((value: { countryCode: string; email: string; firstName: string; frequency: PushFrequency; language: Language; lastName: string; password: string; termsAndConditions: boolean }): unknown => {
        return {
          countryCode: ((value: string): unknown => {
            return value;
          })(value["countryCode"]),
          email: ((value: string): unknown => {
            return value;
          })(value["email"]),
          firstName: ((value: string): unknown => {
            return value;
          })(value["firstName"]),
          frequency: ((value: "NONE" | "DAILY" | "ONCE_A_WEEK" | "TWICE_A_MONTH" | "ONCE_A_MONTH"): unknown => {
            return value;
          })(value["frequency"]),
          language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
            return value;
          })(value["language"]),
          lastName: ((value: string): unknown => {
            return value;
          })(value["lastName"]),
          password: ((value: string): unknown => {
            return value;
          })(value["password"]),
          termsAndConditions: ((value: boolean): unknown => {
            return value;
          })(value["termsAndConditions"]),
        };
      })(value["form"]),
      ssoRequest: ((value: { corporateId: number; function: string | null; ssoUid: string; title: number | null; town: string | null }): unknown => {
        return {
          corporateId: ((value: number): unknown => {
            return value;
          })(value["corporateId"]),
          function: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["function"]),
          ssoUid: ((value: string): unknown => {
            return value;
          })(value["ssoUid"]),
          title: ((value: number | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["title"]),
          town: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["town"]),
        };
      })(value["ssoRequest"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_WarmupV2Submission(str: string): WarmupV2Submission | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          evrUuid: string;
          form: WarmupV2EditForm;
          ssoRequest: WarmupV2SsoRequest;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "evrUuid")) {
      return { success: false };
    }
    const parsed_evrUuid = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["evrUuid"]);
    })();
    if (!parsed_evrUuid.success) {
      return { success: false };
    }
    if (!hasProp(value, "form")) {
      return { success: false };
    }
    const parsed_form = (() => {
      return ((value: unknown): { success: false } | { success: true; value: WarmupV2EditForm } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                countryCode: string;
                email: string;
                firstName: string;
                frequency: PushFrequency;
                language: Language;
                lastName: string;
                password: string;
                termsAndConditions: boolean;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "countryCode")) {
            return { success: false };
          }
          const parsed_countryCode = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["countryCode"]);
          })();
          if (!parsed_countryCode.success) {
            return { success: false };
          }
          if (!hasProp(value, "email")) {
            return { success: false };
          }
          const parsed_email = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["email"]);
          })();
          if (!parsed_email.success) {
            return { success: false };
          }
          if (!hasProp(value, "firstName")) {
            return { success: false };
          }
          const parsed_firstName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["firstName"]);
          })();
          if (!parsed_firstName.success) {
            return { success: false };
          }
          if (!hasProp(value, "frequency")) {
            return { success: false };
          }
          const parsed_frequency = (() => {
            return ((value: unknown): { success: false } | { success: true; value: PushFrequency } => {
              return ((value: unknown): { success: false } | { success: true; value: "NONE" | "DAILY" | "ONCE_A_WEEK" | "TWICE_A_MONTH" | "ONCE_A_MONTH" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "NONE":
                    return { success: true, value: "NONE" };
                  case "DAILY":
                    return { success: true, value: "DAILY" };
                  case "ONCE_A_WEEK":
                    return { success: true, value: "ONCE_A_WEEK" };
                  case "TWICE_A_MONTH":
                    return { success: true, value: "TWICE_A_MONTH" };
                  case "ONCE_A_MONTH":
                    return { success: true, value: "ONCE_A_MONTH" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["frequency"]);
          })();
          if (!parsed_frequency.success) {
            return { success: false };
          }
          if (!hasProp(value, "language")) {
            return { success: false };
          }
          const parsed_language = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Language } => {
              return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                switch (value) {
                  case "en":
                    return { success: true, value: "en" };
                  case "de":
                    return { success: true, value: "de" };
                  case "es":
                    return { success: true, value: "es" };
                  case "ru":
                    return { success: true, value: "ru" };
                  case "zh":
                    return { success: true, value: "zh" };
                  case "pt":
                    return { success: true, value: "pt" };
                  case "fr":
                    return { success: true, value: "fr" };
                  default:
                    return { success: false };
                }
              })(value);
            })(value["language"]);
          })();
          if (!parsed_language.success) {
            return { success: false };
          }
          if (!hasProp(value, "lastName")) {
            return { success: false };
          }
          const parsed_lastName = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["lastName"]);
          })();
          if (!parsed_lastName.success) {
            return { success: false };
          }
          if (!hasProp(value, "password")) {
            return { success: false };
          }
          const parsed_password = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["password"]);
          })();
          if (!parsed_password.success) {
            return { success: false };
          }
          if (!hasProp(value, "termsAndConditions")) {
            return { success: false };
          }
          const parsed_termsAndConditions = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["termsAndConditions"]);
          })();
          if (!parsed_termsAndConditions.success) {
            return { success: false };
          }
          return {
            success: true,
            value: {
              countryCode: parsed_countryCode.value,
              email: parsed_email.value,
              firstName: parsed_firstName.value,
              frequency: parsed_frequency.value,
              language: parsed_language.value,
              lastName: parsed_lastName.value,
              password: parsed_password.value,
              termsAndConditions: parsed_termsAndConditions.value,
            },
          };
        })(value);
      })(value["form"]);
    })();
    if (!parsed_form.success) {
      return { success: false };
    }
    if (!hasProp(value, "ssoRequest")) {
      return { success: false };
    }
    const parsed_ssoRequest = (() => {
      return ((value: unknown): { success: false } | { success: true; value: WarmupV2SsoRequest } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                corporateId: number;
                function: string | null;
                ssoUid: string;
                title: number | null;
                town: string | null;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "corporateId")) {
            return { success: false };
          }
          const parsed_corporateId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["corporateId"]);
          })();
          if (!parsed_corporateId.success) {
            return { success: false };
          }
          if (!hasProp(value, "function")) {
            return { success: false };
          }
          const parsed_function = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["function"]);
          })();
          if (!parsed_function.success) {
            return { success: false };
          }
          if (!hasProp(value, "ssoUid")) {
            return { success: false };
          }
          const parsed_ssoUid = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["ssoUid"]);
          })();
          if (!parsed_ssoUid.success) {
            return { success: false };
          }
          if (!hasProp(value, "title")) {
            return { success: false };
          }
          const parsed_title = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["title"]);
          })();
          if (!parsed_title.success) {
            return { success: false };
          }
          if (!hasProp(value, "town")) {
            return { success: false };
          }
          const parsed_town = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["town"]);
          })();
          if (!parsed_town.success) {
            return { success: false };
          }
          return { success: true, value: { corporateId: parsed_corporateId.value, function: parsed_function.value, ssoUid: parsed_ssoUid.value, title: parsed_title.value, town: parsed_town.value } };
        })(value);
      })(value["ssoRequest"]);
    })();
    if (!parsed_ssoRequest.success) {
      return { success: false };
    }
    return { success: true, value: { evrUuid: parsed_evrUuid.value, form: parsed_form.value, ssoRequest: parsed_ssoRequest.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// WarmupV2ValidatedForm
export function serialize_WarmupV2ValidatedForm(obj: WarmupV2ValidatedForm): string {
  const serialized = ((value: { countryCode: string; email: string; firstName: string; frequency: PushFrequency; language: Language; lastName: string; password: string; termsAndConditions: boolean }): unknown => {
    return {
      countryCode: ((value: string): unknown => {
        return value;
      })(value["countryCode"]),
      email: ((value: string): unknown => {
        return value;
      })(value["email"]),
      firstName: ((value: string): unknown => {
        return value;
      })(value["firstName"]),
      frequency: ((value: "NONE" | "DAILY" | "ONCE_A_WEEK" | "TWICE_A_MONTH" | "ONCE_A_MONTH"): unknown => {
        return value;
      })(value["frequency"]),
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      lastName: ((value: string): unknown => {
        return value;
      })(value["lastName"]),
      password: ((value: string): unknown => {
        return value;
      })(value["password"]),
      termsAndConditions: ((value: boolean): unknown => {
        return value;
      })(value["termsAndConditions"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_WarmupV2ValidatedForm(str: string): WarmupV2ValidatedForm | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          countryCode: string;
          email: string;
          firstName: string;
          frequency: PushFrequency;
          language: Language;
          lastName: string;
          password: string;
          termsAndConditions: boolean;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "countryCode")) {
      return { success: false };
    }
    const parsed_countryCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["countryCode"]);
    })();
    if (!parsed_countryCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "email")) {
      return { success: false };
    }
    const parsed_email = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["email"]);
    })();
    if (!parsed_email.success) {
      return { success: false };
    }
    if (!hasProp(value, "firstName")) {
      return { success: false };
    }
    const parsed_firstName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["firstName"]);
    })();
    if (!parsed_firstName.success) {
      return { success: false };
    }
    if (!hasProp(value, "frequency")) {
      return { success: false };
    }
    const parsed_frequency = (() => {
      return ((value: unknown): { success: false } | { success: true; value: PushFrequency } => {
        return ((value: unknown): { success: false } | { success: true; value: "NONE" | "DAILY" | "ONCE_A_WEEK" | "TWICE_A_MONTH" | "ONCE_A_MONTH" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "NONE":
              return { success: true, value: "NONE" };
            case "DAILY":
              return { success: true, value: "DAILY" };
            case "ONCE_A_WEEK":
              return { success: true, value: "ONCE_A_WEEK" };
            case "TWICE_A_MONTH":
              return { success: true, value: "TWICE_A_MONTH" };
            case "ONCE_A_MONTH":
              return { success: true, value: "ONCE_A_MONTH" };
            default:
              return { success: false };
          }
        })(value);
      })(value["frequency"]);
    })();
    if (!parsed_frequency.success) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language } => {
        return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "en":
              return { success: true, value: "en" };
            case "de":
              return { success: true, value: "de" };
            case "es":
              return { success: true, value: "es" };
            case "ru":
              return { success: true, value: "ru" };
            case "zh":
              return { success: true, value: "zh" };
            case "pt":
              return { success: true, value: "pt" };
            case "fr":
              return { success: true, value: "fr" };
            default:
              return { success: false };
          }
        })(value);
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "lastName")) {
      return { success: false };
    }
    const parsed_lastName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lastName"]);
    })();
    if (!parsed_lastName.success) {
      return { success: false };
    }
    if (!hasProp(value, "password")) {
      return { success: false };
    }
    const parsed_password = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["password"]);
    })();
    if (!parsed_password.success) {
      return { success: false };
    }
    if (!hasProp(value, "termsAndConditions")) {
      return { success: false };
    }
    const parsed_termsAndConditions = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["termsAndConditions"]);
    })();
    if (!parsed_termsAndConditions.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        countryCode: parsed_countryCode.value,
        email: parsed_email.value,
        firstName: parsed_firstName.value,
        frequency: parsed_frequency.value,
        language: parsed_language.value,
        lastName: parsed_lastName.value,
        password: parsed_password.value,
        termsAndConditions: parsed_termsAndConditions.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// WarmupV2ValidationResult
export function serialize_WarmupV2ValidationResult(obj: WarmupV2ValidationResult): string {
  const serialized = ((value: { kind: "SUCCESS"; validatedForm: WarmupV2ValidatedForm } | { kind: "ERROR"; reasons: ValidationErrorReasons }): unknown => {
    switch (value.kind) {
      case "SUCCESS": {
        return {
          kind: "SUCCESS",
          validatedForm: ((value: { countryCode: string; email: string; firstName: string; frequency: PushFrequency; language: Language; lastName: string; password: string; termsAndConditions: boolean }): unknown => {
            return {
              countryCode: ((value: string): unknown => {
                return value;
              })(value["countryCode"]),
              email: ((value: string): unknown => {
                return value;
              })(value["email"]),
              firstName: ((value: string): unknown => {
                return value;
              })(value["firstName"]),
              frequency: ((value: "NONE" | "DAILY" | "ONCE_A_WEEK" | "TWICE_A_MONTH" | "ONCE_A_MONTH"): unknown => {
                return value;
              })(value["frequency"]),
              language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                return value;
              })(value["language"]),
              lastName: ((value: string): unknown => {
                return value;
              })(value["lastName"]),
              password: ((value: string): unknown => {
                return value;
              })(value["password"]),
              termsAndConditions: ((value: boolean): unknown => {
                return value;
              })(value["termsAndConditions"]),
            };
          })(value["validatedForm"]),
        };
      }
      case "ERROR": {
        return {
          kind: "ERROR",
          reasons: ((value: { fields: { additionalProperties: Record<string, string[]> }; general: string[] }): unknown => {
            return {
              fields: ((value: { additionalProperties: Record<string, string[]> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string[]): unknown => {
                          return value.map((value: string): unknown => {
                            return value;
                          });
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["fields"]),
              general: ((value: string[]): unknown => {
                return value.map((value: string): unknown => {
                  return value;
                });
              })(value["general"]),
            };
          })(value["reasons"]),
        };
      }
    }
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_WarmupV2ValidationResult(str: string): WarmupV2ValidationResult | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: { kind: "SUCCESS"; validatedForm: WarmupV2ValidatedForm } | { kind: "ERROR"; reasons: ValidationErrorReasons } } => {
    return ((value: unknown): { success: false } | { success: true; value: { kind: "SUCCESS"; validatedForm: WarmupV2ValidatedForm } | { kind: "ERROR"; reasons: ValidationErrorReasons } } => {
      if (value == null) {
        return { success: false };
      }
      if (typeof value != "object") {
        return { success: false };
      }
      if (!hasProp(value, "kind")) {
        return { success: false };
      }
      if (typeof value["kind"] != "string") {
        return { success: false };
      }
      const discriminator = value["kind"];
      switch (discriminator) {
        case "SUCCESS": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  validatedForm: WarmupV2ValidatedForm;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "validatedForm")) {
              return { success: false };
            }
            const parsed_validatedForm = (() => {
              return ((value: unknown): { success: false } | { success: true; value: WarmupV2ValidatedForm } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        countryCode: string;
                        email: string;
                        firstName: string;
                        frequency: PushFrequency;
                        language: Language;
                        lastName: string;
                        password: string;
                        termsAndConditions: boolean;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "countryCode")) {
                    return { success: false };
                  }
                  const parsed_countryCode = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["countryCode"]);
                  })();
                  if (!parsed_countryCode.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "email")) {
                    return { success: false };
                  }
                  const parsed_email = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["email"]);
                  })();
                  if (!parsed_email.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "firstName")) {
                    return { success: false };
                  }
                  const parsed_firstName = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["firstName"]);
                  })();
                  if (!parsed_firstName.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "frequency")) {
                    return { success: false };
                  }
                  const parsed_frequency = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: PushFrequency } => {
                      return ((value: unknown): { success: false } | { success: true; value: "NONE" | "DAILY" | "ONCE_A_WEEK" | "TWICE_A_MONTH" | "ONCE_A_MONTH" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "NONE":
                            return { success: true, value: "NONE" };
                          case "DAILY":
                            return { success: true, value: "DAILY" };
                          case "ONCE_A_WEEK":
                            return { success: true, value: "ONCE_A_WEEK" };
                          case "TWICE_A_MONTH":
                            return { success: true, value: "TWICE_A_MONTH" };
                          case "ONCE_A_MONTH":
                            return { success: true, value: "ONCE_A_MONTH" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["frequency"]);
                  })();
                  if (!parsed_frequency.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "language")) {
                    return { success: false };
                  }
                  const parsed_language = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: Language } => {
                      return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "en":
                            return { success: true, value: "en" };
                          case "de":
                            return { success: true, value: "de" };
                          case "es":
                            return { success: true, value: "es" };
                          case "ru":
                            return { success: true, value: "ru" };
                          case "zh":
                            return { success: true, value: "zh" };
                          case "pt":
                            return { success: true, value: "pt" };
                          case "fr":
                            return { success: true, value: "fr" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["language"]);
                  })();
                  if (!parsed_language.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "lastName")) {
                    return { success: false };
                  }
                  const parsed_lastName = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["lastName"]);
                  })();
                  if (!parsed_lastName.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "password")) {
                    return { success: false };
                  }
                  const parsed_password = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["password"]);
                  })();
                  if (!parsed_password.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "termsAndConditions")) {
                    return { success: false };
                  }
                  const parsed_termsAndConditions = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["termsAndConditions"]);
                  })();
                  if (!parsed_termsAndConditions.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      countryCode: parsed_countryCode.value,
                      email: parsed_email.value,
                      firstName: parsed_firstName.value,
                      frequency: parsed_frequency.value,
                      language: parsed_language.value,
                      lastName: parsed_lastName.value,
                      password: parsed_password.value,
                      termsAndConditions: parsed_termsAndConditions.value,
                    },
                  };
                })(value);
              })(value["validatedForm"]);
            })();
            if (!parsed_validatedForm.success) {
              return { success: false };
            }
            return { success: true, value: { validatedForm: parsed_validatedForm.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "SUCCESS" as const, ...parsedVariant.value } };
        }
        case "ERROR": {
          const parsedVariant = ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  reasons: ValidationErrorReasons;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "reasons")) {
              return { success: false };
            }
            const parsed_reasons = (() => {
              return ((value: unknown): { success: false } | { success: true; value: ValidationErrorReasons } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        fields: { additionalProperties: Record<string, string[]> };
                        general: string[];
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "fields")) {
                    return { success: false };
                  }
                  const parsed_fields = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string[]> } } => {
                      if (typeof value !== "object") {
                        return { success: false };
                      }
                      if (value === null) {
                        return { success: false };
                      }
                      const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string[]> } => {
                        const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string[] }] => {
                          return [
                            key,
                            ((value: unknown): { success: false } | { success: true; value: string[] } => {
                              if (!isArray(value)) {
                                return { success: false };
                              }
                              const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                                if (typeof value !== "string") {
                                  return { success: false };
                                }
                                return { success: true, value: value };
                              });
                              if (parsed.find((value) => !value.success) !== undefined) {
                                return { success: false };
                              }
                              return {
                                success: true,
                                value: parsed.map((entry) => {
                                  assert(entry.success == true);
                                  return entry.value;
                                }),
                              };
                            })(value),
                          ];
                        });
                        if (parsed.find(([_, value]) => !value.success) !== undefined) {
                          return { success: false };
                        }
                        return {
                          success: true,
                          value: Object.fromEntries(
                            parsed.map(([key, value]) => {
                              assert(value.success);
                              return [key, value.value];
                            }),
                          ),
                        };
                      })();
                      if (!parsed_additionalProperties.success) {
                        return { success: false };
                      }
                      return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
                    })(value["fields"]);
                  })();
                  if (!parsed_fields.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "general")) {
                    return { success: false };
                  }
                  const parsed_general = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string[] } => {
                      if (!isArray(value)) {
                        return { success: false };
                      }
                      const parsed = value.map((value: unknown): { success: false } | { success: true; value: string } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        return { success: true, value: value };
                      });
                      if (parsed.find((value) => !value.success) !== undefined) {
                        return { success: false };
                      }
                      return {
                        success: true,
                        value: parsed.map((entry) => {
                          assert(entry.success == true);
                          return entry.value;
                        }),
                      };
                    })(value["general"]);
                  })();
                  if (!parsed_general.success) {
                    return { success: false };
                  }
                  return { success: true, value: { fields: parsed_fields.value, general: parsed_general.value } };
                })(value);
              })(value["reasons"]);
            })();
            if (!parsed_reasons.success) {
              return { success: false };
            }
            return { success: true, value: { reasons: parsed_reasons.value } };
          })(value);
          if (!parsedVariant.success) {
            return { success: false };
          }
          return { success: true, value: { kind: "ERROR" as const, ...parsedVariant.value } };
        }
        default:
          return { success: false };
      }
    })(value);
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// WarmupV2WidgetProps
export function serialize_WarmupV2WidgetProps(obj: WarmupV2WidgetProps): string {
  const serialized = ((value: {
    corporationName: string;
    countryCode: string;
    email: string;
    evrUuid: string;
    firstName: string;
    language: Language;
    lastName: string;
    relayType: WarmupV2RelayType;
    ssoRequest: WarmupV2SsoRequest;
  }): unknown => {
    return {
      corporationName: ((value: string): unknown => {
        return value;
      })(value["corporationName"]),
      countryCode: ((value: string): unknown => {
        return value;
      })(value["countryCode"]),
      email: ((value: string): unknown => {
        return value;
      })(value["email"]),
      evrUuid: ((value: string): unknown => {
        return value;
      })(value["evrUuid"]),
      firstName: ((value: string): unknown => {
        return value;
      })(value["firstName"]),
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      lastName: ((value: string): unknown => {
        return value;
      })(value["lastName"]),
      relayType: ((value: { kind: "DEFAULT"; relayState: string } | { kind: "SUMMARY"; relayState: string; summary: SummaryMetadata } | { kind: "ACTIONABLE"; actionable: ActionableMetadata; relayState: string }): unknown => {
        switch (value.kind) {
          case "DEFAULT": {
            return {
              kind: "DEFAULT",
              relayState: ((value: string): unknown => {
                return value;
              })(value["relayState"]),
            };
          }
          case "SUMMARY": {
            return {
              kind: "SUMMARY",
              relayState: ((value: string): unknown => {
                return value;
              })(value["relayState"]),
              summary: ((value: {
                activatedAt: DateTime<true> | null;
                activationTime: DateTime<true> | null;
                active: boolean;
                audioActivatedAt: DateTime<true> | null;
                audioActive: boolean;
                audioDuration: number;
                authorInfo: string | null;
                authors: {
                  authorId: number;
                  firstName: string | null;
                  gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                  lastName: string | null;
                  middleName: string | null;
                }[];
                backgroundColor: string | null;
                bestseller: boolean;
                businessClassic: boolean;
                canonicalName: string;
                contentItemId: bigint;
                contentItemType: ContentItemType;
                countriesExcluded: Country[];
                countriesIncluded: Country[];
                coverModifiedAt: DateTime<true> | null;
                coverUri: string;
                dataId: number;
                descriptionHtml: string | null;
                documentType: SummaryDocumentType;
                duration: number;
                imageBorder: boolean;
                language: Language;
                modifiedAt: DateTime<true> | null;
                originalTitle: string | null;
                previewAvailable: boolean;
                publicationDate: string | null;
                publisherInfo: string | null;
                publisherName: string | null;
                rating: number | null;
                sourceType: SummarySourceType;
                subtitle: string | null;
                summaryPages: number;
                summaryWords: number;
                tags: Tag[];
                teaser: string | null;
                title: string | null;
              }): unknown => {
                return {
                  activatedAt: ((value: DateTime<true> | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value.toMillis();
                  })(value["activatedAt"]),
                  activationTime: ((value: DateTime<true> | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value.toMillis();
                  })(value["activationTime"]),
                  active: ((value: boolean): unknown => {
                    return value;
                  })(value["active"]),
                  audioActivatedAt: ((value: DateTime<true> | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value.toMillis();
                  })(value["audioActivatedAt"]),
                  audioActive: ((value: boolean): unknown => {
                    return value;
                  })(value["audioActive"]),
                  audioDuration: ((value: number): unknown => {
                    return value;
                  })(value["audioDuration"]),
                  authorInfo: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["authorInfo"]),
                  authors: ((
                    value: {
                      authorId: number;
                      firstName: string | null;
                      gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                      lastName: string | null;
                      middleName: string | null;
                    }[],
                  ): unknown => {
                    return value.map((value: { authorId: number; firstName: string | null; gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null; lastName: string | null; middleName: string | null }): unknown => {
                      return {
                        authorId: ((value: number): unknown => {
                          return value;
                        })(value["authorId"]),
                        firstName: ((value: string | null): unknown => {
                          if (value === null) {
                            return null;
                          }
                          return value;
                        })(value["firstName"]),
                        gender: ((value: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null): unknown => {
                          if (value === null) {
                            return null;
                          }
                          return value;
                        })(value["gender"]),
                        lastName: ((value: string | null): unknown => {
                          if (value === null) {
                            return null;
                          }
                          return value;
                        })(value["lastName"]),
                        middleName: ((value: string | null): unknown => {
                          if (value === null) {
                            return null;
                          }
                          return value;
                        })(value["middleName"]),
                      };
                    });
                  })(value["authors"]),
                  backgroundColor: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["backgroundColor"]),
                  bestseller: ((value: boolean): unknown => {
                    return value;
                  })(value["bestseller"]),
                  businessClassic: ((value: boolean): unknown => {
                    return value;
                  })(value["businessClassic"]),
                  canonicalName: ((value: string): unknown => {
                    return value;
                  })(value["canonicalName"]),
                  contentItemId: ((value: bigint): unknown => {
                    return value;
                  })(value["contentItemId"]),
                  contentItemType: ((value: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE" | "MIAB"): unknown => {
                    return value;
                  })(value["contentItemType"]),
                  countriesExcluded: ((value: Country[]): unknown => {
                    return value.map(
                      (value: {
                        countryCode: string;
                        currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                        names: {
                          de: string;
                          en: string;
                          es: string;
                          fr: string;
                          pt: string;
                          ru: string;
                          zh: string;
                        };
                        phonePrefix: string;
                        region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                      }): unknown => {
                        return {
                          countryCode: ((value: string): unknown => {
                            return value;
                          })(value["countryCode"]),
                          currency: ((value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY"): unknown => {
                            return value;
                          })(value["currency"]),
                          names: ((value: { de: string; en: string; es: string; fr: string; pt: string; ru: string; zh: string }): unknown => {
                            return {
                              de: ((value: string): unknown => {
                                return value;
                              })(value["de"]),
                              en: ((value: string): unknown => {
                                return value;
                              })(value["en"]),
                              es: ((value: string): unknown => {
                                return value;
                              })(value["es"]),
                              fr: ((value: string): unknown => {
                                return value;
                              })(value["fr"]),
                              pt: ((value: string): unknown => {
                                return value;
                              })(value["pt"]),
                              ru: ((value: string): unknown => {
                                return value;
                              })(value["ru"]),
                              zh: ((value: string): unknown => {
                                return value;
                              })(value["zh"]),
                            };
                          })(value["names"]),
                          phonePrefix: ((value: string): unknown => {
                            return value;
                          })(value["phonePrefix"]),
                          region: ((value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA"): unknown => {
                            return value;
                          })(value["region"]),
                        };
                      },
                    );
                  })(value["countriesExcluded"]),
                  countriesIncluded: ((value: Country[]): unknown => {
                    return value.map(
                      (value: {
                        countryCode: string;
                        currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                        names: {
                          de: string;
                          en: string;
                          es: string;
                          fr: string;
                          pt: string;
                          ru: string;
                          zh: string;
                        };
                        phonePrefix: string;
                        region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                      }): unknown => {
                        return {
                          countryCode: ((value: string): unknown => {
                            return value;
                          })(value["countryCode"]),
                          currency: ((value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY"): unknown => {
                            return value;
                          })(value["currency"]),
                          names: ((value: { de: string; en: string; es: string; fr: string; pt: string; ru: string; zh: string }): unknown => {
                            return {
                              de: ((value: string): unknown => {
                                return value;
                              })(value["de"]),
                              en: ((value: string): unknown => {
                                return value;
                              })(value["en"]),
                              es: ((value: string): unknown => {
                                return value;
                              })(value["es"]),
                              fr: ((value: string): unknown => {
                                return value;
                              })(value["fr"]),
                              pt: ((value: string): unknown => {
                                return value;
                              })(value["pt"]),
                              ru: ((value: string): unknown => {
                                return value;
                              })(value["ru"]),
                              zh: ((value: string): unknown => {
                                return value;
                              })(value["zh"]),
                            };
                          })(value["names"]),
                          phonePrefix: ((value: string): unknown => {
                            return value;
                          })(value["phonePrefix"]),
                          region: ((value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA"): unknown => {
                            return value;
                          })(value["region"]),
                        };
                      },
                    );
                  })(value["countriesIncluded"]),
                  coverModifiedAt: ((value: DateTime<true> | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value.toMillis();
                  })(value["coverModifiedAt"]),
                  coverUri: ((value: string): unknown => {
                    return value;
                  })(value["coverUri"]),
                  dataId: ((value: number): unknown => {
                    return value;
                  })(value["dataId"]),
                  descriptionHtml: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["descriptionHtml"]),
                  documentType: ((value: "ABSTRACT" | "REVIEW"): unknown => {
                    return value;
                  })(value["documentType"]),
                  duration: ((value: number): unknown => {
                    return value;
                  })(value["duration"]),
                  imageBorder: ((value: boolean): unknown => {
                    return value;
                  })(value["imageBorder"]),
                  language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                    return value;
                  })(value["language"]),
                  modifiedAt: ((value: DateTime<true> | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value.toMillis();
                  })(value["modifiedAt"]),
                  originalTitle: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["originalTitle"]),
                  previewAvailable: ((value: boolean): unknown => {
                    return value;
                  })(value["previewAvailable"]),
                  publicationDate: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["publicationDate"]),
                  publisherInfo: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["publisherInfo"]),
                  publisherName: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["publisherName"]),
                  rating: ((value: number | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["rating"]),
                  sourceType: ((value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST"): unknown => {
                    return value;
                  })(value["sourceType"]),
                  subtitle: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["subtitle"]),
                  summaryPages: ((value: number): unknown => {
                    return value;
                  })(value["summaryPages"]),
                  summaryWords: ((value: number): unknown => {
                    return value;
                  })(value["summaryWords"]),
                  tags: ((value: Tag[]): unknown => {
                    return value.map(
                      (
                        value:
                          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                          | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                          | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
                      ): unknown => {
                        switch (value.discriminator) {
                          case "dynamicString": {
                            return {
                              discriminator: "dynamicString",
                              exclude: ((value: boolean): unknown => {
                                return value;
                              })(value["exclude"]),
                              source: ((value: "USER" | "AI"): unknown => {
                                return value;
                              })(value["source"]),
                              tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                                return {
                                  discriminator: ((value: "dynamicString"): unknown => {
                                    return value;
                                  })(value["discriminator"]),
                                  id: ((value: bigint): unknown => {
                                    return value;
                                  })(value["id"]),
                                  kind: ((value: string): unknown => {
                                    return value;
                                  })(value["kind"]),
                                };
                              })(value["tagKind"]),
                              value: ((value: string): unknown => {
                                return value;
                              })(value["value"]),
                            };
                          }
                          case "dynamicBoolean": {
                            return {
                              discriminator: "dynamicBoolean",
                              exclude: ((value: boolean): unknown => {
                                return value;
                              })(value["exclude"]),
                              source: ((value: "USER" | "AI"): unknown => {
                                return value;
                              })(value["source"]),
                              tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                                return {
                                  discriminator: ((value: "dynamicBoolean"): unknown => {
                                    return value;
                                  })(value["discriminator"]),
                                  id: ((value: bigint): unknown => {
                                    return value;
                                  })(value["id"]),
                                  kind: ((value: string): unknown => {
                                    return value;
                                  })(value["kind"]),
                                };
                              })(value["tagKind"]),
                              value: ((value: boolean): unknown => {
                                return value;
                              })(value["value"]),
                            };
                          }
                          case "dynamicNumber": {
                            return {
                              discriminator: "dynamicNumber",
                              exclude: ((value: boolean): unknown => {
                                return value;
                              })(value["exclude"]),
                              source: ((value: "USER" | "AI"): unknown => {
                                return value;
                              })(value["source"]),
                              tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                                return {
                                  discriminator: ((value: "dynamicNumber"): unknown => {
                                    return value;
                                  })(value["discriminator"]),
                                  id: ((value: bigint): unknown => {
                                    return value;
                                  })(value["id"]),
                                  kind: ((value: string): unknown => {
                                    return value;
                                  })(value["kind"]),
                                };
                              })(value["tagKind"]),
                              value: ((value: number): unknown => {
                                return value;
                              })(value["value"]),
                            };
                          }
                          case "dynamicVoid": {
                            return {
                              discriminator: "dynamicVoid",
                              exclude: ((value: boolean): unknown => {
                                return value;
                              })(value["exclude"]),
                              source: ((value: "USER" | "AI"): unknown => {
                                return value;
                              })(value["source"]),
                              tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                                return {
                                  discriminator: ((value: "dynamicVoid"): unknown => {
                                    return value;
                                  })(value["discriminator"]),
                                  id: ((value: bigint): unknown => {
                                    return value;
                                  })(value["id"]),
                                  kind: ((value: string): unknown => {
                                    return value;
                                  })(value["kind"]),
                                };
                              })(value["tagKind"]),
                            };
                          }
                          case "channel": {
                            return {
                              discriminator: "channel",
                              exclude: ((value: boolean): unknown => {
                                return value;
                              })(value["exclude"]),
                              source: ((value: "USER" | "AI"): unknown => {
                                return value;
                              })(value["source"]),
                              tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                                return {
                                  discriminator: ((value: "channel"): unknown => {
                                    return value;
                                  })(value["discriminator"]),
                                  id: ((value: bigint): unknown => {
                                    return value;
                                  })(value["id"]),
                                  kind: ((value: "channel"): unknown => {
                                    return value;
                                  })(value["kind"]),
                                };
                              })(value["tagKind"]),
                              value: ((value: string): unknown => {
                                return value;
                              })(value["value"]),
                            };
                          }
                          case "primary-skill": {
                            return {
                              discriminator: "primary-skill",
                              exclude: ((value: boolean): unknown => {
                                return value;
                              })(value["exclude"]),
                              source: ((value: "USER" | "AI"): unknown => {
                                return value;
                              })(value["source"]),
                              tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                                return {
                                  discriminator: ((value: "primary-skill"): unknown => {
                                    return value;
                                  })(value["discriminator"]),
                                  id: ((value: bigint): unknown => {
                                    return value;
                                  })(value["id"]),
                                  kind: ((value: "primary-skill"): unknown => {
                                    return value;
                                  })(value["kind"]),
                                };
                              })(value["tagKind"]),
                              value: ((value: string): unknown => {
                                return value;
                              })(value["value"]),
                            };
                          }
                          case "skill": {
                            return {
                              discriminator: "skill",
                              exclude: ((value: boolean): unknown => {
                                return value;
                              })(value["exclude"]),
                              source: ((value: "USER" | "AI"): unknown => {
                                return value;
                              })(value["source"]),
                              tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                                return {
                                  discriminator: ((value: "skill"): unknown => {
                                    return value;
                                  })(value["discriminator"]),
                                  id: ((value: bigint): unknown => {
                                    return value;
                                  })(value["id"]),
                                  kind: ((value: "skill"): unknown => {
                                    return value;
                                  })(value["kind"]),
                                };
                              })(value["tagKind"]),
                              value: ((value: string): unknown => {
                                return value;
                              })(value["value"]),
                            };
                          }
                          case "key-quality": {
                            return {
                              discriminator: "key-quality",
                              exclude: ((value: boolean): unknown => {
                                return value;
                              })(value["exclude"]),
                              source: ((value: "USER" | "AI"): unknown => {
                                return value;
                              })(value["source"]),
                              tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                                return {
                                  discriminator: ((value: "key-quality"): unknown => {
                                    return value;
                                  })(value["discriminator"]),
                                  id: ((value: bigint): unknown => {
                                    return value;
                                  })(value["id"]),
                                  kind: ((value: "key-quality"): unknown => {
                                    return value;
                                  })(value["kind"]),
                                };
                              })(value["tagKind"]),
                              value: ((value: string): unknown => {
                                return value;
                              })(value["value"]),
                            };
                          }
                          case "classification": {
                            return {
                              discriminator: "classification",
                              exclude: ((value: boolean): unknown => {
                                return value;
                              })(value["exclude"]),
                              source: ((value: "USER" | "AI"): unknown => {
                                return value;
                              })(value["source"]),
                              tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                                return {
                                  discriminator: ((value: "classification"): unknown => {
                                    return value;
                                  })(value["discriminator"]),
                                  id: ((value: bigint): unknown => {
                                    return value;
                                  })(value["id"]),
                                  kind: ((value: "classification"): unknown => {
                                    return value;
                                  })(value["kind"]),
                                };
                              })(value["tagKind"]),
                              value: ((value: string): unknown => {
                                return value;
                              })(value["value"]),
                            };
                          }
                        }
                      },
                    );
                  })(value["tags"]),
                  teaser: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["teaser"]),
                  title: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["title"]),
                };
              })(value["summary"]),
            };
          }
          case "ACTIONABLE": {
            return {
              kind: "ACTIONABLE",
              actionable: ((value: {
                actionableId: bigint;
                activatedAt: DateTime<true> | null;
                active: boolean;
                coverUri: string;
                description: string;
                language: Language;
                modifiedAt: DateTime<true>;
                name: string;
                tags: Tag[];
                title: string;
              }): unknown => {
                return {
                  actionableId: ((value: bigint): unknown => {
                    return value;
                  })(value["actionableId"]),
                  activatedAt: ((value: DateTime<true> | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value.toMillis();
                  })(value["activatedAt"]),
                  active: ((value: boolean): unknown => {
                    return value;
                  })(value["active"]),
                  coverUri: ((value: string): unknown => {
                    return value;
                  })(value["coverUri"]),
                  description: ((value: string): unknown => {
                    return value;
                  })(value["description"]),
                  language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                    return value;
                  })(value["language"]),
                  modifiedAt: ((value: DateTime<true>): unknown => {
                    return value.toMillis();
                  })(value["modifiedAt"]),
                  name: ((value: string): unknown => {
                    return value;
                  })(value["name"]),
                  tags: ((value: Tag[]): unknown => {
                    return value.map(
                      (
                        value:
                          | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                          | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                          | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                          | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                          | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                          | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                          | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                          | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                          | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string },
                      ): unknown => {
                        switch (value.discriminator) {
                          case "dynamicString": {
                            return {
                              discriminator: "dynamicString",
                              exclude: ((value: boolean): unknown => {
                                return value;
                              })(value["exclude"]),
                              source: ((value: "USER" | "AI"): unknown => {
                                return value;
                              })(value["source"]),
                              tagKind: ((value: { discriminator: KindDynamicString; id: bigint; kind: string }): unknown => {
                                return {
                                  discriminator: ((value: "dynamicString"): unknown => {
                                    return value;
                                  })(value["discriminator"]),
                                  id: ((value: bigint): unknown => {
                                    return value;
                                  })(value["id"]),
                                  kind: ((value: string): unknown => {
                                    return value;
                                  })(value["kind"]),
                                };
                              })(value["tagKind"]),
                              value: ((value: string): unknown => {
                                return value;
                              })(value["value"]),
                            };
                          }
                          case "dynamicBoolean": {
                            return {
                              discriminator: "dynamicBoolean",
                              exclude: ((value: boolean): unknown => {
                                return value;
                              })(value["exclude"]),
                              source: ((value: "USER" | "AI"): unknown => {
                                return value;
                              })(value["source"]),
                              tagKind: ((value: { discriminator: KindDynamicBoolean; id: bigint; kind: string }): unknown => {
                                return {
                                  discriminator: ((value: "dynamicBoolean"): unknown => {
                                    return value;
                                  })(value["discriminator"]),
                                  id: ((value: bigint): unknown => {
                                    return value;
                                  })(value["id"]),
                                  kind: ((value: string): unknown => {
                                    return value;
                                  })(value["kind"]),
                                };
                              })(value["tagKind"]),
                              value: ((value: boolean): unknown => {
                                return value;
                              })(value["value"]),
                            };
                          }
                          case "dynamicNumber": {
                            return {
                              discriminator: "dynamicNumber",
                              exclude: ((value: boolean): unknown => {
                                return value;
                              })(value["exclude"]),
                              source: ((value: "USER" | "AI"): unknown => {
                                return value;
                              })(value["source"]),
                              tagKind: ((value: { discriminator: KindDynamicNumber; id: bigint; kind: string }): unknown => {
                                return {
                                  discriminator: ((value: "dynamicNumber"): unknown => {
                                    return value;
                                  })(value["discriminator"]),
                                  id: ((value: bigint): unknown => {
                                    return value;
                                  })(value["id"]),
                                  kind: ((value: string): unknown => {
                                    return value;
                                  })(value["kind"]),
                                };
                              })(value["tagKind"]),
                              value: ((value: number): unknown => {
                                return value;
                              })(value["value"]),
                            };
                          }
                          case "dynamicVoid": {
                            return {
                              discriminator: "dynamicVoid",
                              exclude: ((value: boolean): unknown => {
                                return value;
                              })(value["exclude"]),
                              source: ((value: "USER" | "AI"): unknown => {
                                return value;
                              })(value["source"]),
                              tagKind: ((value: { discriminator: KindDynamicVoid; id: bigint; kind: string }): unknown => {
                                return {
                                  discriminator: ((value: "dynamicVoid"): unknown => {
                                    return value;
                                  })(value["discriminator"]),
                                  id: ((value: bigint): unknown => {
                                    return value;
                                  })(value["id"]),
                                  kind: ((value: string): unknown => {
                                    return value;
                                  })(value["kind"]),
                                };
                              })(value["tagKind"]),
                            };
                          }
                          case "channel": {
                            return {
                              discriminator: "channel",
                              exclude: ((value: boolean): unknown => {
                                return value;
                              })(value["exclude"]),
                              source: ((value: "USER" | "AI"): unknown => {
                                return value;
                              })(value["source"]),
                              tagKind: ((value: { discriminator: KindChannel; id: bigint; kind: KindChannel }): unknown => {
                                return {
                                  discriminator: ((value: "channel"): unknown => {
                                    return value;
                                  })(value["discriminator"]),
                                  id: ((value: bigint): unknown => {
                                    return value;
                                  })(value["id"]),
                                  kind: ((value: "channel"): unknown => {
                                    return value;
                                  })(value["kind"]),
                                };
                              })(value["tagKind"]),
                              value: ((value: string): unknown => {
                                return value;
                              })(value["value"]),
                            };
                          }
                          case "primary-skill": {
                            return {
                              discriminator: "primary-skill",
                              exclude: ((value: boolean): unknown => {
                                return value;
                              })(value["exclude"]),
                              source: ((value: "USER" | "AI"): unknown => {
                                return value;
                              })(value["source"]),
                              tagKind: ((value: { discriminator: KindPrimarySkill; id: bigint; kind: KindPrimarySkill }): unknown => {
                                return {
                                  discriminator: ((value: "primary-skill"): unknown => {
                                    return value;
                                  })(value["discriminator"]),
                                  id: ((value: bigint): unknown => {
                                    return value;
                                  })(value["id"]),
                                  kind: ((value: "primary-skill"): unknown => {
                                    return value;
                                  })(value["kind"]),
                                };
                              })(value["tagKind"]),
                              value: ((value: string): unknown => {
                                return value;
                              })(value["value"]),
                            };
                          }
                          case "skill": {
                            return {
                              discriminator: "skill",
                              exclude: ((value: boolean): unknown => {
                                return value;
                              })(value["exclude"]),
                              source: ((value: "USER" | "AI"): unknown => {
                                return value;
                              })(value["source"]),
                              tagKind: ((value: { discriminator: KindSkill; id: bigint; kind: KindSkill }): unknown => {
                                return {
                                  discriminator: ((value: "skill"): unknown => {
                                    return value;
                                  })(value["discriminator"]),
                                  id: ((value: bigint): unknown => {
                                    return value;
                                  })(value["id"]),
                                  kind: ((value: "skill"): unknown => {
                                    return value;
                                  })(value["kind"]),
                                };
                              })(value["tagKind"]),
                              value: ((value: string): unknown => {
                                return value;
                              })(value["value"]),
                            };
                          }
                          case "key-quality": {
                            return {
                              discriminator: "key-quality",
                              exclude: ((value: boolean): unknown => {
                                return value;
                              })(value["exclude"]),
                              source: ((value: "USER" | "AI"): unknown => {
                                return value;
                              })(value["source"]),
                              tagKind: ((value: { discriminator: KindKeyQuality; id: bigint; kind: KindKeyQuality }): unknown => {
                                return {
                                  discriminator: ((value: "key-quality"): unknown => {
                                    return value;
                                  })(value["discriminator"]),
                                  id: ((value: bigint): unknown => {
                                    return value;
                                  })(value["id"]),
                                  kind: ((value: "key-quality"): unknown => {
                                    return value;
                                  })(value["kind"]),
                                };
                              })(value["tagKind"]),
                              value: ((value: string): unknown => {
                                return value;
                              })(value["value"]),
                            };
                          }
                          case "classification": {
                            return {
                              discriminator: "classification",
                              exclude: ((value: boolean): unknown => {
                                return value;
                              })(value["exclude"]),
                              source: ((value: "USER" | "AI"): unknown => {
                                return value;
                              })(value["source"]),
                              tagKind: ((value: { discriminator: KindClassification; id: bigint; kind: KindClassification }): unknown => {
                                return {
                                  discriminator: ((value: "classification"): unknown => {
                                    return value;
                                  })(value["discriminator"]),
                                  id: ((value: bigint): unknown => {
                                    return value;
                                  })(value["id"]),
                                  kind: ((value: "classification"): unknown => {
                                    return value;
                                  })(value["kind"]),
                                };
                              })(value["tagKind"]),
                              value: ((value: string): unknown => {
                                return value;
                              })(value["value"]),
                            };
                          }
                        }
                      },
                    );
                  })(value["tags"]),
                  title: ((value: string): unknown => {
                    return value;
                  })(value["title"]),
                };
              })(value["actionable"]),
              relayState: ((value: string): unknown => {
                return value;
              })(value["relayState"]),
            };
          }
        }
      })(value["relayType"]),
      ssoRequest: ((value: { corporateId: number; function: string | null; ssoUid: string; title: number | null; town: string | null }): unknown => {
        return {
          corporateId: ((value: number): unknown => {
            return value;
          })(value["corporateId"]),
          function: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["function"]),
          ssoUid: ((value: string): unknown => {
            return value;
          })(value["ssoUid"]),
          title: ((value: number | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["title"]),
          town: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["town"]),
        };
      })(value["ssoRequest"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_WarmupV2WidgetProps(str: string): WarmupV2WidgetProps | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          corporationName: string;
          countryCode: string;
          email: string;
          evrUuid: string;
          firstName: string;
          language: Language;
          lastName: string;
          relayType: WarmupV2RelayType;
          ssoRequest: WarmupV2SsoRequest;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "corporationName")) {
      return { success: false };
    }
    const parsed_corporationName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["corporationName"]);
    })();
    if (!parsed_corporationName.success) {
      return { success: false };
    }
    if (!hasProp(value, "countryCode")) {
      return { success: false };
    }
    const parsed_countryCode = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["countryCode"]);
    })();
    if (!parsed_countryCode.success) {
      return { success: false };
    }
    if (!hasProp(value, "email")) {
      return { success: false };
    }
    const parsed_email = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["email"]);
    })();
    if (!parsed_email.success) {
      return { success: false };
    }
    if (!hasProp(value, "evrUuid")) {
      return { success: false };
    }
    const parsed_evrUuid = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["evrUuid"]);
    })();
    if (!parsed_evrUuid.success) {
      return { success: false };
    }
    if (!hasProp(value, "firstName")) {
      return { success: false };
    }
    const parsed_firstName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["firstName"]);
    })();
    if (!parsed_firstName.success) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language } => {
        return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "en":
              return { success: true, value: "en" };
            case "de":
              return { success: true, value: "de" };
            case "es":
              return { success: true, value: "es" };
            case "ru":
              return { success: true, value: "ru" };
            case "zh":
              return { success: true, value: "zh" };
            case "pt":
              return { success: true, value: "pt" };
            case "fr":
              return { success: true, value: "fr" };
            default:
              return { success: false };
          }
        })(value);
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "lastName")) {
      return { success: false };
    }
    const parsed_lastName = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["lastName"]);
    })();
    if (!parsed_lastName.success) {
      return { success: false };
    }
    if (!hasProp(value, "relayType")) {
      return { success: false };
    }
    const parsed_relayType = (() => {
      return ((value: unknown): { success: false } | { success: true; value: WarmupV2RelayType } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | { success: true; value: { kind: "DEFAULT"; relayState: string } | { kind: "SUMMARY"; relayState: string; summary: SummaryMetadata } | { kind: "ACTIONABLE"; actionable: ActionableMetadata; relayState: string } } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | { success: true; value: { kind: "DEFAULT"; relayState: string } | { kind: "SUMMARY"; relayState: string; summary: SummaryMetadata } | { kind: "ACTIONABLE"; actionable: ActionableMetadata; relayState: string } } => {
            if (value == null) {
              return { success: false };
            }
            if (typeof value != "object") {
              return { success: false };
            }
            if (!hasProp(value, "kind")) {
              return { success: false };
            }
            if (typeof value["kind"] != "string") {
              return { success: false };
            }
            const discriminator = value["kind"];
            switch (discriminator) {
              case "DEFAULT": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        relayState: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "relayState")) {
                    return { success: false };
                  }
                  const parsed_relayState = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["relayState"]);
                  })();
                  if (!parsed_relayState.success) {
                    return { success: false };
                  }
                  return { success: true, value: { relayState: parsed_relayState.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { kind: "DEFAULT" as const, ...parsedVariant.value } };
              }
              case "SUMMARY": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        relayState: string;
                        summary: SummaryMetadata;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "relayState")) {
                    return { success: false };
                  }
                  const parsed_relayState = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["relayState"]);
                  })();
                  if (!parsed_relayState.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "summary")) {
                    return { success: false };
                  }
                  const parsed_summary = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: SummaryMetadata } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              activatedAt: DateTime<true> | null;
                              activationTime: DateTime<true> | null;
                              active: boolean;
                              audioActivatedAt: DateTime<true> | null;
                              audioActive: boolean;
                              audioDuration: number;
                              authorInfo: string | null;
                              authors: {
                                authorId: number;
                                firstName: string | null;
                                gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                                lastName: string | null;
                                middleName: string | null;
                              }[];
                              backgroundColor: string | null;
                              bestseller: boolean;
                              businessClassic: boolean;
                              canonicalName: string;
                              contentItemId: bigint;
                              contentItemType: ContentItemType;
                              countriesExcluded: Country[];
                              countriesIncluded: Country[];
                              coverModifiedAt: DateTime<true> | null;
                              coverUri: string;
                              dataId: number;
                              descriptionHtml: string | null;
                              documentType: SummaryDocumentType;
                              duration: number;
                              imageBorder: boolean;
                              language: Language;
                              modifiedAt: DateTime<true> | null;
                              originalTitle: string | null;
                              previewAvailable: boolean;
                              publicationDate: string | null;
                              publisherInfo: string | null;
                              publisherName: string | null;
                              rating: number | null;
                              sourceType: SummarySourceType;
                              subtitle: string | null;
                              summaryPages: number;
                              summaryWords: number;
                              tags: Tag[];
                              teaser: string | null;
                              title: string | null;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "activatedAt")) {
                          return { success: false };
                        }
                        const parsed_activatedAt = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "number") {
                              return { success: false };
                            }
                            const parsed = DateTime.fromMillis(value);
                            if (!parsed.isValid) {
                              return { success: false };
                            }
                            return { success: true, value: parsed };
                          })(value["activatedAt"]);
                        })();
                        if (!parsed_activatedAt.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "activationTime")) {
                          return { success: false };
                        }
                        const parsed_activationTime = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "number") {
                              return { success: false };
                            }
                            const parsed = DateTime.fromMillis(value);
                            if (!parsed.isValid) {
                              return { success: false };
                            }
                            return { success: true, value: parsed };
                          })(value["activationTime"]);
                        })();
                        if (!parsed_activationTime.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "active")) {
                          return { success: false };
                        }
                        const parsed_active = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                            if (typeof value !== "boolean") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["active"]);
                        })();
                        if (!parsed_active.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "audioActivatedAt")) {
                          return { success: false };
                        }
                        const parsed_audioActivatedAt = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "number") {
                              return { success: false };
                            }
                            const parsed = DateTime.fromMillis(value);
                            if (!parsed.isValid) {
                              return { success: false };
                            }
                            return { success: true, value: parsed };
                          })(value["audioActivatedAt"]);
                        })();
                        if (!parsed_audioActivatedAt.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "audioActive")) {
                          return { success: false };
                        }
                        const parsed_audioActive = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                            if (typeof value !== "boolean") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["audioActive"]);
                        })();
                        if (!parsed_audioActive.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "audioDuration")) {
                          return { success: false };
                        }
                        const parsed_audioDuration = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: number } => {
                            if (typeof value === "number") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["audioDuration"]);
                        })();
                        if (!parsed_audioDuration.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "authorInfo")) {
                          return { success: false };
                        }
                        const parsed_authorInfo = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["authorInfo"]);
                        })();
                        if (!parsed_authorInfo.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "authors")) {
                          return { success: false };
                        }
                        const parsed_authors = (() => {
                          return ((
                            value: unknown,
                          ):
                            | { success: false }
                            | {
                                success: true;
                                value: {
                                  authorId: number;
                                  firstName: string | null;
                                  gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                                  lastName: string | null;
                                  middleName: string | null;
                                }[];
                              } => {
                            if (!isArray(value)) {
                              return { success: false };
                            }
                            const parsed = value.map(
                              (
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      authorId: number;
                                      firstName: string | null;
                                      gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
                                      lastName: string | null;
                                      middleName: string | null;
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "authorId")) {
                                  return { success: false };
                                }
                                const parsed_authorId = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: number } => {
                                    if (typeof value === "number") {
                                      return { success: true, value: value };
                                    }
                                    return { success: false };
                                  })(value["authorId"]);
                                })();
                                if (!parsed_authorId.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "firstName")) {
                                  return { success: false };
                                }
                                const parsed_firstName = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                                    if (value === null) {
                                      return { success: true, value: null };
                                    }
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["firstName"]);
                                })();
                                if (!parsed_firstName.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "gender")) {
                                  return { success: false };
                                }
                                const parsed_gender = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null } => {
                                    if (value === null) {
                                      return { success: true, value: null };
                                    }
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    switch (value) {
                                      case "MALE":
                                        return { success: true, value: "MALE" };
                                      case "FEMALE":
                                        return { success: true, value: "FEMALE" };
                                      case "CUSTOM":
                                        return { success: true, value: "CUSTOM" };
                                      case "UNKNOWN":
                                        return { success: true, value: "UNKNOWN" };
                                      default:
                                        return { success: false };
                                    }
                                  })(value["gender"]);
                                })();
                                if (!parsed_gender.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "lastName")) {
                                  return { success: false };
                                }
                                const parsed_lastName = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                                    if (value === null) {
                                      return { success: true, value: null };
                                    }
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["lastName"]);
                                })();
                                if (!parsed_lastName.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "middleName")) {
                                  return { success: false };
                                }
                                const parsed_middleName = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                                    if (value === null) {
                                      return { success: true, value: null };
                                    }
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["middleName"]);
                                })();
                                if (!parsed_middleName.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { authorId: parsed_authorId.value, firstName: parsed_firstName.value, gender: parsed_gender.value, lastName: parsed_lastName.value, middleName: parsed_middleName.value } };
                              },
                            );
                            if (parsed.find((value) => !value.success) !== undefined) {
                              return { success: false };
                            }
                            return {
                              success: true,
                              value: parsed.map((entry) => {
                                assert(entry.success == true);
                                return entry.value;
                              }),
                            };
                          })(value["authors"]);
                        })();
                        if (!parsed_authors.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "backgroundColor")) {
                          return { success: false };
                        }
                        const parsed_backgroundColor = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["backgroundColor"]);
                        })();
                        if (!parsed_backgroundColor.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "bestseller")) {
                          return { success: false };
                        }
                        const parsed_bestseller = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                            if (typeof value !== "boolean") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["bestseller"]);
                        })();
                        if (!parsed_bestseller.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "businessClassic")) {
                          return { success: false };
                        }
                        const parsed_businessClassic = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                            if (typeof value !== "boolean") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["businessClassic"]);
                        })();
                        if (!parsed_businessClassic.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "canonicalName")) {
                          return { success: false };
                        }
                        const parsed_canonicalName = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["canonicalName"]);
                        })();
                        if (!parsed_canonicalName.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "contentItemId")) {
                          return { success: false };
                        }
                        const parsed_contentItemId = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["contentItemId"]);
                        })();
                        if (!parsed_contentItemId.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "contentItemType")) {
                          return { success: false };
                        }
                        const parsed_contentItemType = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: ContentItemType } => {
                            return ((value: unknown): { success: false } | { success: true; value: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE" | "MIAB" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "UNKNOWN":
                                  return { success: true, value: "UNKNOWN" };
                                case "SUMMARY":
                                  return { success: true, value: "SUMMARY" };
                                case "CATEGORY":
                                  return { success: true, value: "CATEGORY" };
                                case "CHANNEL":
                                  return { success: true, value: "CHANNEL" };
                                case "LGXP":
                                  return { success: true, value: "LGXP" };
                                case "ACTIONABLE":
                                  return { success: true, value: "ACTIONABLE" };
                                case "MYLIST":
                                  return { success: true, value: "MYLIST" };
                                case "CUSTOMPAGE":
                                  return { success: true, value: "CUSTOMPAGE" };
                                case "MIAB":
                                  return { success: true, value: "MIAB" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["contentItemType"]);
                        })();
                        if (!parsed_contentItemType.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "countriesExcluded")) {
                          return { success: false };
                        }
                        const parsed_countriesExcluded = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: Country[] } => {
                            if (!isArray(value)) {
                              return { success: false };
                            }
                            const parsed = value.map((value: unknown): { success: false } | { success: true; value: Country } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      countryCode: string;
                                      currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                                      names: {
                                        de: string;
                                        en: string;
                                        es: string;
                                        fr: string;
                                        pt: string;
                                        ru: string;
                                        zh: string;
                                      };
                                      phonePrefix: string;
                                      region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "countryCode")) {
                                  return { success: false };
                                }
                                const parsed_countryCode = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["countryCode"]);
                                })();
                                if (!parsed_countryCode.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "currency")) {
                                  return { success: false };
                                }
                                const parsed_currency = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY" } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    switch (value) {
                                      case "CHF":
                                        return { success: true, value: "CHF" };
                                      case "CNY":
                                        return { success: true, value: "CNY" };
                                      case "USD":
                                        return { success: true, value: "USD" };
                                      case "EUR":
                                        return { success: true, value: "EUR" };
                                      case "GBP":
                                        return { success: true, value: "GBP" };
                                      case "INR":
                                        return { success: true, value: "INR" };
                                      case "JPY":
                                        return { success: true, value: "JPY" };
                                      default:
                                        return { success: false };
                                    }
                                  })(value["currency"]);
                                })();
                                if (!parsed_currency.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "names")) {
                                  return { success: false };
                                }
                                const parsed_names = (() => {
                                  return ((
                                    value: unknown,
                                  ):
                                    | { success: false }
                                    | {
                                        success: true;
                                        value: {
                                          de: string;
                                          en: string;
                                          es: string;
                                          fr: string;
                                          pt: string;
                                          ru: string;
                                          zh: string;
                                        };
                                      } => {
                                    if (typeof value !== "object") {
                                      return { success: false };
                                    }
                                    if (value === null) {
                                      return { success: false };
                                    }
                                    if (!hasProp(value, "de")) {
                                      return { success: false };
                                    }
                                    const parsed_de = (() => {
                                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        return { success: true, value: value };
                                      })(value["de"]);
                                    })();
                                    if (!parsed_de.success) {
                                      return { success: false };
                                    }
                                    if (!hasProp(value, "en")) {
                                      return { success: false };
                                    }
                                    const parsed_en = (() => {
                                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        return { success: true, value: value };
                                      })(value["en"]);
                                    })();
                                    if (!parsed_en.success) {
                                      return { success: false };
                                    }
                                    if (!hasProp(value, "es")) {
                                      return { success: false };
                                    }
                                    const parsed_es = (() => {
                                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        return { success: true, value: value };
                                      })(value["es"]);
                                    })();
                                    if (!parsed_es.success) {
                                      return { success: false };
                                    }
                                    if (!hasProp(value, "fr")) {
                                      return { success: false };
                                    }
                                    const parsed_fr = (() => {
                                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        return { success: true, value: value };
                                      })(value["fr"]);
                                    })();
                                    if (!parsed_fr.success) {
                                      return { success: false };
                                    }
                                    if (!hasProp(value, "pt")) {
                                      return { success: false };
                                    }
                                    const parsed_pt = (() => {
                                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        return { success: true, value: value };
                                      })(value["pt"]);
                                    })();
                                    if (!parsed_pt.success) {
                                      return { success: false };
                                    }
                                    if (!hasProp(value, "ru")) {
                                      return { success: false };
                                    }
                                    const parsed_ru = (() => {
                                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        return { success: true, value: value };
                                      })(value["ru"]);
                                    })();
                                    if (!parsed_ru.success) {
                                      return { success: false };
                                    }
                                    if (!hasProp(value, "zh")) {
                                      return { success: false };
                                    }
                                    const parsed_zh = (() => {
                                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        return { success: true, value: value };
                                      })(value["zh"]);
                                    })();
                                    if (!parsed_zh.success) {
                                      return { success: false };
                                    }
                                    return { success: true, value: { de: parsed_de.value, en: parsed_en.value, es: parsed_es.value, fr: parsed_fr.value, pt: parsed_pt.value, ru: parsed_ru.value, zh: parsed_zh.value } };
                                  })(value["names"]);
                                })();
                                if (!parsed_names.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "phonePrefix")) {
                                  return { success: false };
                                }
                                const parsed_phonePrefix = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["phonePrefix"]);
                                })();
                                if (!parsed_phonePrefix.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "region")) {
                                  return { success: false };
                                }
                                const parsed_region = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA" } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    switch (value) {
                                      case "SOUTHAMERICA":
                                        return { success: true, value: "SOUTHAMERICA" };
                                      case "EUROPE":
                                        return { success: true, value: "EUROPE" };
                                      case "OCEANIA":
                                        return { success: true, value: "OCEANIA" };
                                      case "NORTHAMERICA":
                                        return { success: true, value: "NORTHAMERICA" };
                                      case "AFRICA":
                                        return { success: true, value: "AFRICA" };
                                      case "ANTARCTICA":
                                        return { success: true, value: "ANTARCTICA" };
                                      case "ASIA":
                                        return { success: true, value: "ASIA" };
                                      default:
                                        return { success: false };
                                    }
                                  })(value["region"]);
                                })();
                                if (!parsed_region.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { countryCode: parsed_countryCode.value, currency: parsed_currency.value, names: parsed_names.value, phonePrefix: parsed_phonePrefix.value, region: parsed_region.value } };
                              })(value);
                            });
                            if (parsed.find((value) => !value.success) !== undefined) {
                              return { success: false };
                            }
                            return {
                              success: true,
                              value: parsed.map((entry) => {
                                assert(entry.success == true);
                                return entry.value;
                              }),
                            };
                          })(value["countriesExcluded"]);
                        })();
                        if (!parsed_countriesExcluded.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "countriesIncluded")) {
                          return { success: false };
                        }
                        const parsed_countriesIncluded = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: Country[] } => {
                            if (!isArray(value)) {
                              return { success: false };
                            }
                            const parsed = value.map((value: unknown): { success: false } | { success: true; value: Country } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value: {
                                      countryCode: string;
                                      currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
                                      names: {
                                        de: string;
                                        en: string;
                                        es: string;
                                        fr: string;
                                        pt: string;
                                        ru: string;
                                        zh: string;
                                      };
                                      phonePrefix: string;
                                      region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
                                    };
                                  } => {
                                if (typeof value !== "object") {
                                  return { success: false };
                                }
                                if (value === null) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "countryCode")) {
                                  return { success: false };
                                }
                                const parsed_countryCode = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["countryCode"]);
                                })();
                                if (!parsed_countryCode.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "currency")) {
                                  return { success: false };
                                }
                                const parsed_currency = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY" } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    switch (value) {
                                      case "CHF":
                                        return { success: true, value: "CHF" };
                                      case "CNY":
                                        return { success: true, value: "CNY" };
                                      case "USD":
                                        return { success: true, value: "USD" };
                                      case "EUR":
                                        return { success: true, value: "EUR" };
                                      case "GBP":
                                        return { success: true, value: "GBP" };
                                      case "INR":
                                        return { success: true, value: "INR" };
                                      case "JPY":
                                        return { success: true, value: "JPY" };
                                      default:
                                        return { success: false };
                                    }
                                  })(value["currency"]);
                                })();
                                if (!parsed_currency.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "names")) {
                                  return { success: false };
                                }
                                const parsed_names = (() => {
                                  return ((
                                    value: unknown,
                                  ):
                                    | { success: false }
                                    | {
                                        success: true;
                                        value: {
                                          de: string;
                                          en: string;
                                          es: string;
                                          fr: string;
                                          pt: string;
                                          ru: string;
                                          zh: string;
                                        };
                                      } => {
                                    if (typeof value !== "object") {
                                      return { success: false };
                                    }
                                    if (value === null) {
                                      return { success: false };
                                    }
                                    if (!hasProp(value, "de")) {
                                      return { success: false };
                                    }
                                    const parsed_de = (() => {
                                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        return { success: true, value: value };
                                      })(value["de"]);
                                    })();
                                    if (!parsed_de.success) {
                                      return { success: false };
                                    }
                                    if (!hasProp(value, "en")) {
                                      return { success: false };
                                    }
                                    const parsed_en = (() => {
                                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        return { success: true, value: value };
                                      })(value["en"]);
                                    })();
                                    if (!parsed_en.success) {
                                      return { success: false };
                                    }
                                    if (!hasProp(value, "es")) {
                                      return { success: false };
                                    }
                                    const parsed_es = (() => {
                                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        return { success: true, value: value };
                                      })(value["es"]);
                                    })();
                                    if (!parsed_es.success) {
                                      return { success: false };
                                    }
                                    if (!hasProp(value, "fr")) {
                                      return { success: false };
                                    }
                                    const parsed_fr = (() => {
                                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        return { success: true, value: value };
                                      })(value["fr"]);
                                    })();
                                    if (!parsed_fr.success) {
                                      return { success: false };
                                    }
                                    if (!hasProp(value, "pt")) {
                                      return { success: false };
                                    }
                                    const parsed_pt = (() => {
                                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        return { success: true, value: value };
                                      })(value["pt"]);
                                    })();
                                    if (!parsed_pt.success) {
                                      return { success: false };
                                    }
                                    if (!hasProp(value, "ru")) {
                                      return { success: false };
                                    }
                                    const parsed_ru = (() => {
                                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        return { success: true, value: value };
                                      })(value["ru"]);
                                    })();
                                    if (!parsed_ru.success) {
                                      return { success: false };
                                    }
                                    if (!hasProp(value, "zh")) {
                                      return { success: false };
                                    }
                                    const parsed_zh = (() => {
                                      return ((value: unknown): { success: false } | { success: true; value: string } => {
                                        if (typeof value !== "string") {
                                          return { success: false };
                                        }
                                        return { success: true, value: value };
                                      })(value["zh"]);
                                    })();
                                    if (!parsed_zh.success) {
                                      return { success: false };
                                    }
                                    return { success: true, value: { de: parsed_de.value, en: parsed_en.value, es: parsed_es.value, fr: parsed_fr.value, pt: parsed_pt.value, ru: parsed_ru.value, zh: parsed_zh.value } };
                                  })(value["names"]);
                                })();
                                if (!parsed_names.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "phonePrefix")) {
                                  return { success: false };
                                }
                                const parsed_phonePrefix = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    return { success: true, value: value };
                                  })(value["phonePrefix"]);
                                })();
                                if (!parsed_phonePrefix.success) {
                                  return { success: false };
                                }
                                if (!hasProp(value, "region")) {
                                  return { success: false };
                                }
                                const parsed_region = (() => {
                                  return ((value: unknown): { success: false } | { success: true; value: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA" } => {
                                    if (typeof value !== "string") {
                                      return { success: false };
                                    }
                                    switch (value) {
                                      case "SOUTHAMERICA":
                                        return { success: true, value: "SOUTHAMERICA" };
                                      case "EUROPE":
                                        return { success: true, value: "EUROPE" };
                                      case "OCEANIA":
                                        return { success: true, value: "OCEANIA" };
                                      case "NORTHAMERICA":
                                        return { success: true, value: "NORTHAMERICA" };
                                      case "AFRICA":
                                        return { success: true, value: "AFRICA" };
                                      case "ANTARCTICA":
                                        return { success: true, value: "ANTARCTICA" };
                                      case "ASIA":
                                        return { success: true, value: "ASIA" };
                                      default:
                                        return { success: false };
                                    }
                                  })(value["region"]);
                                })();
                                if (!parsed_region.success) {
                                  return { success: false };
                                }
                                return { success: true, value: { countryCode: parsed_countryCode.value, currency: parsed_currency.value, names: parsed_names.value, phonePrefix: parsed_phonePrefix.value, region: parsed_region.value } };
                              })(value);
                            });
                            if (parsed.find((value) => !value.success) !== undefined) {
                              return { success: false };
                            }
                            return {
                              success: true,
                              value: parsed.map((entry) => {
                                assert(entry.success == true);
                                return entry.value;
                              }),
                            };
                          })(value["countriesIncluded"]);
                        })();
                        if (!parsed_countriesIncluded.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "coverModifiedAt")) {
                          return { success: false };
                        }
                        const parsed_coverModifiedAt = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "number") {
                              return { success: false };
                            }
                            const parsed = DateTime.fromMillis(value);
                            if (!parsed.isValid) {
                              return { success: false };
                            }
                            return { success: true, value: parsed };
                          })(value["coverModifiedAt"]);
                        })();
                        if (!parsed_coverModifiedAt.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "coverUri")) {
                          return { success: false };
                        }
                        const parsed_coverUri = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["coverUri"]);
                        })();
                        if (!parsed_coverUri.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "dataId")) {
                          return { success: false };
                        }
                        const parsed_dataId = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: number } => {
                            if (typeof value === "number") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["dataId"]);
                        })();
                        if (!parsed_dataId.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "descriptionHtml")) {
                          return { success: false };
                        }
                        const parsed_descriptionHtml = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["descriptionHtml"]);
                        })();
                        if (!parsed_descriptionHtml.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "documentType")) {
                          return { success: false };
                        }
                        const parsed_documentType = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: SummaryDocumentType } => {
                            return ((value: unknown): { success: false } | { success: true; value: "ABSTRACT" | "REVIEW" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "ABSTRACT":
                                  return { success: true, value: "ABSTRACT" };
                                case "REVIEW":
                                  return { success: true, value: "REVIEW" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["documentType"]);
                        })();
                        if (!parsed_documentType.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "duration")) {
                          return { success: false };
                        }
                        const parsed_duration = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: number } => {
                            if (typeof value === "number") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["duration"]);
                        })();
                        if (!parsed_duration.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "imageBorder")) {
                          return { success: false };
                        }
                        const parsed_imageBorder = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                            if (typeof value !== "boolean") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["imageBorder"]);
                        })();
                        if (!parsed_imageBorder.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "language")) {
                          return { success: false };
                        }
                        const parsed_language = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: Language } => {
                            return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "en":
                                  return { success: true, value: "en" };
                                case "de":
                                  return { success: true, value: "de" };
                                case "es":
                                  return { success: true, value: "es" };
                                case "ru":
                                  return { success: true, value: "ru" };
                                case "zh":
                                  return { success: true, value: "zh" };
                                case "pt":
                                  return { success: true, value: "pt" };
                                case "fr":
                                  return { success: true, value: "fr" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["language"]);
                        })();
                        if (!parsed_language.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "modifiedAt")) {
                          return { success: false };
                        }
                        const parsed_modifiedAt = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "number") {
                              return { success: false };
                            }
                            const parsed = DateTime.fromMillis(value);
                            if (!parsed.isValid) {
                              return { success: false };
                            }
                            return { success: true, value: parsed };
                          })(value["modifiedAt"]);
                        })();
                        if (!parsed_modifiedAt.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "originalTitle")) {
                          return { success: false };
                        }
                        const parsed_originalTitle = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["originalTitle"]);
                        })();
                        if (!parsed_originalTitle.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "previewAvailable")) {
                          return { success: false };
                        }
                        const parsed_previewAvailable = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                            if (typeof value !== "boolean") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["previewAvailable"]);
                        })();
                        if (!parsed_previewAvailable.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "publicationDate")) {
                          return { success: false };
                        }
                        const parsed_publicationDate = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["publicationDate"]);
                        })();
                        if (!parsed_publicationDate.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "publisherInfo")) {
                          return { success: false };
                        }
                        const parsed_publisherInfo = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["publisherInfo"]);
                        })();
                        if (!parsed_publisherInfo.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "publisherName")) {
                          return { success: false };
                        }
                        const parsed_publisherName = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["publisherName"]);
                        })();
                        if (!parsed_publisherName.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "rating")) {
                          return { success: false };
                        }
                        const parsed_rating = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value === "number") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["rating"]);
                        })();
                        if (!parsed_rating.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "sourceType")) {
                          return { success: false };
                        }
                        const parsed_sourceType = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: SummarySourceType } => {
                            return ((value: unknown): { success: false } | { success: true; value: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "BOOK":
                                  return { success: true, value: "BOOK" };
                                case "VIDEO":
                                  return { success: true, value: "VIDEO" };
                                case "ARTICLE":
                                  return { success: true, value: "ARTICLE" };
                                case "CHAPTER":
                                  return { success: true, value: "CHAPTER" };
                                case "REPORT":
                                  return { success: true, value: "REPORT" };
                                case "PODCAST":
                                  return { success: true, value: "PODCAST" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["sourceType"]);
                        })();
                        if (!parsed_sourceType.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "subtitle")) {
                          return { success: false };
                        }
                        const parsed_subtitle = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["subtitle"]);
                        })();
                        if (!parsed_subtitle.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "summaryPages")) {
                          return { success: false };
                        }
                        const parsed_summaryPages = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: number } => {
                            if (typeof value === "number") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["summaryPages"]);
                        })();
                        if (!parsed_summaryPages.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "summaryWords")) {
                          return { success: false };
                        }
                        const parsed_summaryWords = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: number } => {
                            if (typeof value === "number") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["summaryWords"]);
                        })();
                        if (!parsed_summaryWords.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "tags")) {
                          return { success: false };
                        }
                        const parsed_tags = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
                            if (!isArray(value)) {
                              return { success: false };
                            }
                            const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value:
                                      | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                                      | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                                      | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                                      | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                                      | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                                      | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                                      | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                                      | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                                      | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                                  } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value:
                                        | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                                        | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                                        | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                                        | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                                        | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                                        | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                                        | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                                        | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                                        | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                                    } => {
                                  if (value == null) {
                                    return { success: false };
                                  }
                                  if (typeof value != "object") {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  if (typeof value["discriminator"] != "string") {
                                    return { success: false };
                                  }
                                  const discriminator = value["discriminator"];
                                  switch (discriminator) {
                                    case "dynamicString": {
                                      const parsedVariant = ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              exclude: boolean;
                                              source: TagSourceType;
                                              tagKind: TagKindDynamicString;
                                              value: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "exclude")) {
                                          return { success: false };
                                        }
                                        const parsed_exclude = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                            if (typeof value !== "boolean") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["exclude"]);
                                        })();
                                        if (!parsed_exclude.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "source")) {
                                          return { success: false };
                                        }
                                        const parsed_source = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "USER":
                                                  return { success: true, value: "USER" };
                                                case "AI":
                                                  return { success: true, value: "AI" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["source"]);
                                        })();
                                        if (!parsed_source.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "tagKind")) {
                                          return { success: false };
                                        }
                                        const parsed_tagKind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                                            return ((
                                              value: unknown,
                                            ):
                                              | { success: false }
                                              | {
                                                  success: true;
                                                  value: {
                                                    discriminator: KindDynamicString;
                                                    id: bigint;
                                                    kind: string;
                                                  };
                                                } => {
                                              if (typeof value !== "object") {
                                                return { success: false };
                                              }
                                              if (value === null) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "discriminator")) {
                                                return { success: false };
                                              }
                                              const parsed_discriminator = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "dynamicString":
                                                        return { success: true, value: "dynamicString" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["discriminator"]);
                                              })();
                                              if (!parsed_discriminator.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "id")) {
                                                return { success: false };
                                              }
                                              const parsed_id = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                  if (typeof value === "number") {
                                                    return { success: true, value: BigInt(value) };
                                                  }
                                                  if (typeof value === "bigint") {
                                                    return { success: true, value: value };
                                                  }
                                                  return { success: false };
                                                })(value["id"]);
                                              })();
                                              if (!parsed_id.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "kind")) {
                                                return { success: false };
                                              }
                                              const parsed_kind = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                                  if (typeof value !== "string") {
                                                    return { success: false };
                                                  }
                                                  return { success: true, value: value };
                                                })(value["kind"]);
                                              })();
                                              if (!parsed_kind.success) {
                                                return { success: false };
                                              }
                                              return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                            })(value);
                                          })(value["tagKind"]);
                                        })();
                                        if (!parsed_tagKind.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "value")) {
                                          return { success: false };
                                        }
                                        const parsed_value = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["value"]);
                                        })();
                                        if (!parsed_value.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                      })(value);
                                      if (!parsedVariant.success) {
                                        return { success: false };
                                      }
                                      return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                                    }
                                    case "dynamicBoolean": {
                                      const parsedVariant = ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              exclude: boolean;
                                              source: TagSourceType;
                                              tagKind: TagKindDynamicBoolean;
                                              value: boolean;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "exclude")) {
                                          return { success: false };
                                        }
                                        const parsed_exclude = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                            if (typeof value !== "boolean") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["exclude"]);
                                        })();
                                        if (!parsed_exclude.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "source")) {
                                          return { success: false };
                                        }
                                        const parsed_source = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "USER":
                                                  return { success: true, value: "USER" };
                                                case "AI":
                                                  return { success: true, value: "AI" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["source"]);
                                        })();
                                        if (!parsed_source.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "tagKind")) {
                                          return { success: false };
                                        }
                                        const parsed_tagKind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                                            return ((
                                              value: unknown,
                                            ):
                                              | { success: false }
                                              | {
                                                  success: true;
                                                  value: {
                                                    discriminator: KindDynamicBoolean;
                                                    id: bigint;
                                                    kind: string;
                                                  };
                                                } => {
                                              if (typeof value !== "object") {
                                                return { success: false };
                                              }
                                              if (value === null) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "discriminator")) {
                                                return { success: false };
                                              }
                                              const parsed_discriminator = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "dynamicBoolean":
                                                        return { success: true, value: "dynamicBoolean" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["discriminator"]);
                                              })();
                                              if (!parsed_discriminator.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "id")) {
                                                return { success: false };
                                              }
                                              const parsed_id = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                  if (typeof value === "number") {
                                                    return { success: true, value: BigInt(value) };
                                                  }
                                                  if (typeof value === "bigint") {
                                                    return { success: true, value: value };
                                                  }
                                                  return { success: false };
                                                })(value["id"]);
                                              })();
                                              if (!parsed_id.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "kind")) {
                                                return { success: false };
                                              }
                                              const parsed_kind = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                                  if (typeof value !== "string") {
                                                    return { success: false };
                                                  }
                                                  return { success: true, value: value };
                                                })(value["kind"]);
                                              })();
                                              if (!parsed_kind.success) {
                                                return { success: false };
                                              }
                                              return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                            })(value);
                                          })(value["tagKind"]);
                                        })();
                                        if (!parsed_tagKind.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "value")) {
                                          return { success: false };
                                        }
                                        const parsed_value = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                            if (typeof value !== "boolean") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["value"]);
                                        })();
                                        if (!parsed_value.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                      })(value);
                                      if (!parsedVariant.success) {
                                        return { success: false };
                                      }
                                      return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                                    }
                                    case "dynamicNumber": {
                                      const parsedVariant = ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              exclude: boolean;
                                              source: TagSourceType;
                                              tagKind: TagKindDynamicNumber;
                                              value: number;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "exclude")) {
                                          return { success: false };
                                        }
                                        const parsed_exclude = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                            if (typeof value !== "boolean") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["exclude"]);
                                        })();
                                        if (!parsed_exclude.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "source")) {
                                          return { success: false };
                                        }
                                        const parsed_source = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "USER":
                                                  return { success: true, value: "USER" };
                                                case "AI":
                                                  return { success: true, value: "AI" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["source"]);
                                        })();
                                        if (!parsed_source.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "tagKind")) {
                                          return { success: false };
                                        }
                                        const parsed_tagKind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                                            return ((
                                              value: unknown,
                                            ):
                                              | { success: false }
                                              | {
                                                  success: true;
                                                  value: {
                                                    discriminator: KindDynamicNumber;
                                                    id: bigint;
                                                    kind: string;
                                                  };
                                                } => {
                                              if (typeof value !== "object") {
                                                return { success: false };
                                              }
                                              if (value === null) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "discriminator")) {
                                                return { success: false };
                                              }
                                              const parsed_discriminator = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "dynamicNumber":
                                                        return { success: true, value: "dynamicNumber" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["discriminator"]);
                                              })();
                                              if (!parsed_discriminator.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "id")) {
                                                return { success: false };
                                              }
                                              const parsed_id = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                  if (typeof value === "number") {
                                                    return { success: true, value: BigInt(value) };
                                                  }
                                                  if (typeof value === "bigint") {
                                                    return { success: true, value: value };
                                                  }
                                                  return { success: false };
                                                })(value["id"]);
                                              })();
                                              if (!parsed_id.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "kind")) {
                                                return { success: false };
                                              }
                                              const parsed_kind = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                                  if (typeof value !== "string") {
                                                    return { success: false };
                                                  }
                                                  return { success: true, value: value };
                                                })(value["kind"]);
                                              })();
                                              if (!parsed_kind.success) {
                                                return { success: false };
                                              }
                                              return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                            })(value);
                                          })(value["tagKind"]);
                                        })();
                                        if (!parsed_tagKind.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "value")) {
                                          return { success: false };
                                        }
                                        const parsed_value = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: number } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["value"]);
                                        })();
                                        if (!parsed_value.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                      })(value);
                                      if (!parsedVariant.success) {
                                        return { success: false };
                                      }
                                      return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                                    }
                                    case "dynamicVoid": {
                                      const parsedVariant = ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              exclude: boolean;
                                              source: TagSourceType;
                                              tagKind: TagKindDynamicVoid;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "exclude")) {
                                          return { success: false };
                                        }
                                        const parsed_exclude = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                            if (typeof value !== "boolean") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["exclude"]);
                                        })();
                                        if (!parsed_exclude.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "source")) {
                                          return { success: false };
                                        }
                                        const parsed_source = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "USER":
                                                  return { success: true, value: "USER" };
                                                case "AI":
                                                  return { success: true, value: "AI" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["source"]);
                                        })();
                                        if (!parsed_source.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "tagKind")) {
                                          return { success: false };
                                        }
                                        const parsed_tagKind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                                            return ((
                                              value: unknown,
                                            ):
                                              | { success: false }
                                              | {
                                                  success: true;
                                                  value: {
                                                    discriminator: KindDynamicVoid;
                                                    id: bigint;
                                                    kind: string;
                                                  };
                                                } => {
                                              if (typeof value !== "object") {
                                                return { success: false };
                                              }
                                              if (value === null) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "discriminator")) {
                                                return { success: false };
                                              }
                                              const parsed_discriminator = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "dynamicVoid":
                                                        return { success: true, value: "dynamicVoid" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["discriminator"]);
                                              })();
                                              if (!parsed_discriminator.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "id")) {
                                                return { success: false };
                                              }
                                              const parsed_id = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                  if (typeof value === "number") {
                                                    return { success: true, value: BigInt(value) };
                                                  }
                                                  if (typeof value === "bigint") {
                                                    return { success: true, value: value };
                                                  }
                                                  return { success: false };
                                                })(value["id"]);
                                              })();
                                              if (!parsed_id.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "kind")) {
                                                return { success: false };
                                              }
                                              const parsed_kind = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                                  if (typeof value !== "string") {
                                                    return { success: false };
                                                  }
                                                  return { success: true, value: value };
                                                })(value["kind"]);
                                              })();
                                              if (!parsed_kind.success) {
                                                return { success: false };
                                              }
                                              return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                            })(value);
                                          })(value["tagKind"]);
                                        })();
                                        if (!parsed_tagKind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                                      })(value);
                                      if (!parsedVariant.success) {
                                        return { success: false };
                                      }
                                      return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                                    }
                                    case "channel": {
                                      const parsedVariant = ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              exclude: boolean;
                                              source: TagSourceType;
                                              tagKind: TagKindChannel;
                                              value: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "exclude")) {
                                          return { success: false };
                                        }
                                        const parsed_exclude = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                            if (typeof value !== "boolean") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["exclude"]);
                                        })();
                                        if (!parsed_exclude.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "source")) {
                                          return { success: false };
                                        }
                                        const parsed_source = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "USER":
                                                  return { success: true, value: "USER" };
                                                case "AI":
                                                  return { success: true, value: "AI" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["source"]);
                                        })();
                                        if (!parsed_source.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "tagKind")) {
                                          return { success: false };
                                        }
                                        const parsed_tagKind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                                            return ((
                                              value: unknown,
                                            ):
                                              | { success: false }
                                              | {
                                                  success: true;
                                                  value: {
                                                    discriminator: KindChannel;
                                                    id: bigint;
                                                    kind: KindChannel;
                                                  };
                                                } => {
                                              if (typeof value !== "object") {
                                                return { success: false };
                                              }
                                              if (value === null) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "discriminator")) {
                                                return { success: false };
                                              }
                                              const parsed_discriminator = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "channel":
                                                        return { success: true, value: "channel" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["discriminator"]);
                                              })();
                                              if (!parsed_discriminator.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "id")) {
                                                return { success: false };
                                              }
                                              const parsed_id = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                  if (typeof value === "number") {
                                                    return { success: true, value: BigInt(value) };
                                                  }
                                                  if (typeof value === "bigint") {
                                                    return { success: true, value: value };
                                                  }
                                                  return { success: false };
                                                })(value["id"]);
                                              })();
                                              if (!parsed_id.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "kind")) {
                                                return { success: false };
                                              }
                                              const parsed_kind = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "channel":
                                                        return { success: true, value: "channel" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["kind"]);
                                              })();
                                              if (!parsed_kind.success) {
                                                return { success: false };
                                              }
                                              return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                            })(value);
                                          })(value["tagKind"]);
                                        })();
                                        if (!parsed_tagKind.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "value")) {
                                          return { success: false };
                                        }
                                        const parsed_value = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["value"]);
                                        })();
                                        if (!parsed_value.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                      })(value);
                                      if (!parsedVariant.success) {
                                        return { success: false };
                                      }
                                      return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                                    }
                                    case "primary-skill": {
                                      const parsedVariant = ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              exclude: boolean;
                                              source: TagSourceType;
                                              tagKind: TagKindPrimarySkill;
                                              value: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "exclude")) {
                                          return { success: false };
                                        }
                                        const parsed_exclude = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                            if (typeof value !== "boolean") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["exclude"]);
                                        })();
                                        if (!parsed_exclude.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "source")) {
                                          return { success: false };
                                        }
                                        const parsed_source = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "USER":
                                                  return { success: true, value: "USER" };
                                                case "AI":
                                                  return { success: true, value: "AI" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["source"]);
                                        })();
                                        if (!parsed_source.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "tagKind")) {
                                          return { success: false };
                                        }
                                        const parsed_tagKind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                                            return ((
                                              value: unknown,
                                            ):
                                              | { success: false }
                                              | {
                                                  success: true;
                                                  value: {
                                                    discriminator: KindPrimarySkill;
                                                    id: bigint;
                                                    kind: KindPrimarySkill;
                                                  };
                                                } => {
                                              if (typeof value !== "object") {
                                                return { success: false };
                                              }
                                              if (value === null) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "discriminator")) {
                                                return { success: false };
                                              }
                                              const parsed_discriminator = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "primary-skill":
                                                        return { success: true, value: "primary-skill" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["discriminator"]);
                                              })();
                                              if (!parsed_discriminator.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "id")) {
                                                return { success: false };
                                              }
                                              const parsed_id = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                  if (typeof value === "number") {
                                                    return { success: true, value: BigInt(value) };
                                                  }
                                                  if (typeof value === "bigint") {
                                                    return { success: true, value: value };
                                                  }
                                                  return { success: false };
                                                })(value["id"]);
                                              })();
                                              if (!parsed_id.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "kind")) {
                                                return { success: false };
                                              }
                                              const parsed_kind = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "primary-skill":
                                                        return { success: true, value: "primary-skill" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["kind"]);
                                              })();
                                              if (!parsed_kind.success) {
                                                return { success: false };
                                              }
                                              return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                            })(value);
                                          })(value["tagKind"]);
                                        })();
                                        if (!parsed_tagKind.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "value")) {
                                          return { success: false };
                                        }
                                        const parsed_value = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["value"]);
                                        })();
                                        if (!parsed_value.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                      })(value);
                                      if (!parsedVariant.success) {
                                        return { success: false };
                                      }
                                      return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                                    }
                                    case "skill": {
                                      const parsedVariant = ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              exclude: boolean;
                                              source: TagSourceType;
                                              tagKind: TagKindSkill;
                                              value: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "exclude")) {
                                          return { success: false };
                                        }
                                        const parsed_exclude = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                            if (typeof value !== "boolean") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["exclude"]);
                                        })();
                                        if (!parsed_exclude.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "source")) {
                                          return { success: false };
                                        }
                                        const parsed_source = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "USER":
                                                  return { success: true, value: "USER" };
                                                case "AI":
                                                  return { success: true, value: "AI" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["source"]);
                                        })();
                                        if (!parsed_source.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "tagKind")) {
                                          return { success: false };
                                        }
                                        const parsed_tagKind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                                            return ((
                                              value: unknown,
                                            ):
                                              | { success: false }
                                              | {
                                                  success: true;
                                                  value: {
                                                    discriminator: KindSkill;
                                                    id: bigint;
                                                    kind: KindSkill;
                                                  };
                                                } => {
                                              if (typeof value !== "object") {
                                                return { success: false };
                                              }
                                              if (value === null) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "discriminator")) {
                                                return { success: false };
                                              }
                                              const parsed_discriminator = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "skill":
                                                        return { success: true, value: "skill" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["discriminator"]);
                                              })();
                                              if (!parsed_discriminator.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "id")) {
                                                return { success: false };
                                              }
                                              const parsed_id = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                  if (typeof value === "number") {
                                                    return { success: true, value: BigInt(value) };
                                                  }
                                                  if (typeof value === "bigint") {
                                                    return { success: true, value: value };
                                                  }
                                                  return { success: false };
                                                })(value["id"]);
                                              })();
                                              if (!parsed_id.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "kind")) {
                                                return { success: false };
                                              }
                                              const parsed_kind = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "skill":
                                                        return { success: true, value: "skill" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["kind"]);
                                              })();
                                              if (!parsed_kind.success) {
                                                return { success: false };
                                              }
                                              return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                            })(value);
                                          })(value["tagKind"]);
                                        })();
                                        if (!parsed_tagKind.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "value")) {
                                          return { success: false };
                                        }
                                        const parsed_value = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["value"]);
                                        })();
                                        if (!parsed_value.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                      })(value);
                                      if (!parsedVariant.success) {
                                        return { success: false };
                                      }
                                      return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                                    }
                                    case "key-quality": {
                                      const parsedVariant = ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              exclude: boolean;
                                              source: TagSourceType;
                                              tagKind: TagKindKeyQuality;
                                              value: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "exclude")) {
                                          return { success: false };
                                        }
                                        const parsed_exclude = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                            if (typeof value !== "boolean") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["exclude"]);
                                        })();
                                        if (!parsed_exclude.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "source")) {
                                          return { success: false };
                                        }
                                        const parsed_source = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "USER":
                                                  return { success: true, value: "USER" };
                                                case "AI":
                                                  return { success: true, value: "AI" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["source"]);
                                        })();
                                        if (!parsed_source.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "tagKind")) {
                                          return { success: false };
                                        }
                                        const parsed_tagKind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                                            return ((
                                              value: unknown,
                                            ):
                                              | { success: false }
                                              | {
                                                  success: true;
                                                  value: {
                                                    discriminator: KindKeyQuality;
                                                    id: bigint;
                                                    kind: KindKeyQuality;
                                                  };
                                                } => {
                                              if (typeof value !== "object") {
                                                return { success: false };
                                              }
                                              if (value === null) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "discriminator")) {
                                                return { success: false };
                                              }
                                              const parsed_discriminator = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "key-quality":
                                                        return { success: true, value: "key-quality" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["discriminator"]);
                                              })();
                                              if (!parsed_discriminator.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "id")) {
                                                return { success: false };
                                              }
                                              const parsed_id = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                  if (typeof value === "number") {
                                                    return { success: true, value: BigInt(value) };
                                                  }
                                                  if (typeof value === "bigint") {
                                                    return { success: true, value: value };
                                                  }
                                                  return { success: false };
                                                })(value["id"]);
                                              })();
                                              if (!parsed_id.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "kind")) {
                                                return { success: false };
                                              }
                                              const parsed_kind = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "key-quality":
                                                        return { success: true, value: "key-quality" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["kind"]);
                                              })();
                                              if (!parsed_kind.success) {
                                                return { success: false };
                                              }
                                              return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                            })(value);
                                          })(value["tagKind"]);
                                        })();
                                        if (!parsed_tagKind.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "value")) {
                                          return { success: false };
                                        }
                                        const parsed_value = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["value"]);
                                        })();
                                        if (!parsed_value.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                      })(value);
                                      if (!parsedVariant.success) {
                                        return { success: false };
                                      }
                                      return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                                    }
                                    case "classification": {
                                      const parsedVariant = ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              exclude: boolean;
                                              source: TagSourceType;
                                              tagKind: TagKindClassification;
                                              value: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "exclude")) {
                                          return { success: false };
                                        }
                                        const parsed_exclude = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                            if (typeof value !== "boolean") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["exclude"]);
                                        })();
                                        if (!parsed_exclude.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "source")) {
                                          return { success: false };
                                        }
                                        const parsed_source = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "USER":
                                                  return { success: true, value: "USER" };
                                                case "AI":
                                                  return { success: true, value: "AI" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["source"]);
                                        })();
                                        if (!parsed_source.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "tagKind")) {
                                          return { success: false };
                                        }
                                        const parsed_tagKind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                                            return ((
                                              value: unknown,
                                            ):
                                              | { success: false }
                                              | {
                                                  success: true;
                                                  value: {
                                                    discriminator: KindClassification;
                                                    id: bigint;
                                                    kind: KindClassification;
                                                  };
                                                } => {
                                              if (typeof value !== "object") {
                                                return { success: false };
                                              }
                                              if (value === null) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "discriminator")) {
                                                return { success: false };
                                              }
                                              const parsed_discriminator = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "classification":
                                                        return { success: true, value: "classification" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["discriminator"]);
                                              })();
                                              if (!parsed_discriminator.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "id")) {
                                                return { success: false };
                                              }
                                              const parsed_id = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                  if (typeof value === "number") {
                                                    return { success: true, value: BigInt(value) };
                                                  }
                                                  if (typeof value === "bigint") {
                                                    return { success: true, value: value };
                                                  }
                                                  return { success: false };
                                                })(value["id"]);
                                              })();
                                              if (!parsed_id.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "kind")) {
                                                return { success: false };
                                              }
                                              const parsed_kind = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "classification":
                                                        return { success: true, value: "classification" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["kind"]);
                                              })();
                                              if (!parsed_kind.success) {
                                                return { success: false };
                                              }
                                              return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                            })(value);
                                          })(value["tagKind"]);
                                        })();
                                        if (!parsed_tagKind.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "value")) {
                                          return { success: false };
                                        }
                                        const parsed_value = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["value"]);
                                        })();
                                        if (!parsed_value.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                      })(value);
                                      if (!parsedVariant.success) {
                                        return { success: false };
                                      }
                                      return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                                    }
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value);
                            });
                            if (parsed.find((value) => !value.success) !== undefined) {
                              return { success: false };
                            }
                            return {
                              success: true,
                              value: parsed.map((entry) => {
                                assert(entry.success == true);
                                return entry.value;
                              }),
                            };
                          })(value["tags"]);
                        })();
                        if (!parsed_tags.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "teaser")) {
                          return { success: false };
                        }
                        const parsed_teaser = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["teaser"]);
                        })();
                        if (!parsed_teaser.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "title")) {
                          return { success: false };
                        }
                        const parsed_title = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["title"]);
                        })();
                        if (!parsed_title.success) {
                          return { success: false };
                        }
                        return {
                          success: true,
                          value: {
                            activatedAt: parsed_activatedAt.value,
                            activationTime: parsed_activationTime.value,
                            active: parsed_active.value,
                            audioActivatedAt: parsed_audioActivatedAt.value,
                            audioActive: parsed_audioActive.value,
                            audioDuration: parsed_audioDuration.value,
                            authorInfo: parsed_authorInfo.value,
                            authors: parsed_authors.value,
                            backgroundColor: parsed_backgroundColor.value,
                            bestseller: parsed_bestseller.value,
                            businessClassic: parsed_businessClassic.value,
                            canonicalName: parsed_canonicalName.value,
                            contentItemId: parsed_contentItemId.value,
                            contentItemType: parsed_contentItemType.value,
                            countriesExcluded: parsed_countriesExcluded.value,
                            countriesIncluded: parsed_countriesIncluded.value,
                            coverModifiedAt: parsed_coverModifiedAt.value,
                            coverUri: parsed_coverUri.value,
                            dataId: parsed_dataId.value,
                            descriptionHtml: parsed_descriptionHtml.value,
                            documentType: parsed_documentType.value,
                            duration: parsed_duration.value,
                            imageBorder: parsed_imageBorder.value,
                            language: parsed_language.value,
                            modifiedAt: parsed_modifiedAt.value,
                            originalTitle: parsed_originalTitle.value,
                            previewAvailable: parsed_previewAvailable.value,
                            publicationDate: parsed_publicationDate.value,
                            publisherInfo: parsed_publisherInfo.value,
                            publisherName: parsed_publisherName.value,
                            rating: parsed_rating.value,
                            sourceType: parsed_sourceType.value,
                            subtitle: parsed_subtitle.value,
                            summaryPages: parsed_summaryPages.value,
                            summaryWords: parsed_summaryWords.value,
                            tags: parsed_tags.value,
                            teaser: parsed_teaser.value,
                            title: parsed_title.value,
                          },
                        };
                      })(value);
                    })(value["summary"]);
                  })();
                  if (!parsed_summary.success) {
                    return { success: false };
                  }
                  return { success: true, value: { relayState: parsed_relayState.value, summary: parsed_summary.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { kind: "SUMMARY" as const, ...parsedVariant.value } };
              }
              case "ACTIONABLE": {
                const parsedVariant = ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        actionable: ActionableMetadata;
                        relayState: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "actionable")) {
                    return { success: false };
                  }
                  const parsed_actionable = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: ActionableMetadata } => {
                      return ((
                        value: unknown,
                      ):
                        | { success: false }
                        | {
                            success: true;
                            value: {
                              actionableId: bigint;
                              activatedAt: DateTime<true> | null;
                              active: boolean;
                              coverUri: string;
                              description: string;
                              language: Language;
                              modifiedAt: DateTime<true>;
                              name: string;
                              tags: Tag[];
                              title: string;
                            };
                          } => {
                        if (typeof value !== "object") {
                          return { success: false };
                        }
                        if (value === null) {
                          return { success: false };
                        }
                        if (!hasProp(value, "actionableId")) {
                          return { success: false };
                        }
                        const parsed_actionableId = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                            if (typeof value === "number") {
                              return { success: true, value: BigInt(value) };
                            }
                            if (typeof value === "bigint") {
                              return { success: true, value: value };
                            }
                            return { success: false };
                          })(value["actionableId"]);
                        })();
                        if (!parsed_actionableId.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "activatedAt")) {
                          return { success: false };
                        }
                        const parsed_activatedAt = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: DateTime<true> | null } => {
                            if (value === null) {
                              return { success: true, value: null };
                            }
                            if (typeof value !== "number") {
                              return { success: false };
                            }
                            const parsed = DateTime.fromMillis(value);
                            if (!parsed.isValid) {
                              return { success: false };
                            }
                            return { success: true, value: parsed };
                          })(value["activatedAt"]);
                        })();
                        if (!parsed_activatedAt.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "active")) {
                          return { success: false };
                        }
                        const parsed_active = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                            if (typeof value !== "boolean") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["active"]);
                        })();
                        if (!parsed_active.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "coverUri")) {
                          return { success: false };
                        }
                        const parsed_coverUri = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["coverUri"]);
                        })();
                        if (!parsed_coverUri.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "description")) {
                          return { success: false };
                        }
                        const parsed_description = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["description"]);
                        })();
                        if (!parsed_description.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "language")) {
                          return { success: false };
                        }
                        const parsed_language = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: Language } => {
                            return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                              if (typeof value !== "string") {
                                return { success: false };
                              }
                              switch (value) {
                                case "en":
                                  return { success: true, value: "en" };
                                case "de":
                                  return { success: true, value: "de" };
                                case "es":
                                  return { success: true, value: "es" };
                                case "ru":
                                  return { success: true, value: "ru" };
                                case "zh":
                                  return { success: true, value: "zh" };
                                case "pt":
                                  return { success: true, value: "pt" };
                                case "fr":
                                  return { success: true, value: "fr" };
                                default:
                                  return { success: false };
                              }
                            })(value);
                          })(value["language"]);
                        })();
                        if (!parsed_language.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "modifiedAt")) {
                          return { success: false };
                        }
                        const parsed_modifiedAt = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                            if (typeof value !== "number") {
                              return { success: false };
                            }
                            const parsed = DateTime.fromMillis(value);
                            if (!parsed.isValid) {
                              return { success: false };
                            }
                            return { success: true, value: parsed };
                          })(value["modifiedAt"]);
                        })();
                        if (!parsed_modifiedAt.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "name")) {
                          return { success: false };
                        }
                        const parsed_name = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["name"]);
                        })();
                        if (!parsed_name.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "tags")) {
                          return { success: false };
                        }
                        const parsed_tags = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: Tag[] } => {
                            if (!isArray(value)) {
                              return { success: false };
                            }
                            const parsed = value.map((value: unknown): { success: false } | { success: true; value: Tag } => {
                              return ((
                                value: unknown,
                              ):
                                | { success: false }
                                | {
                                    success: true;
                                    value:
                                      | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                                      | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                                      | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                                      | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                                      | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                                      | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                                      | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                                      | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                                      | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                                  } => {
                                return ((
                                  value: unknown,
                                ):
                                  | { success: false }
                                  | {
                                      success: true;
                                      value:
                                        | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
                                        | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
                                        | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
                                        | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
                                        | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
                                        | { discriminator: "primary-skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindPrimarySkill; value: string }
                                        | { discriminator: "skill"; exclude: boolean; source: TagSourceType; tagKind: TagKindSkill; value: string }
                                        | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
                                        | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };
                                    } => {
                                  if (value == null) {
                                    return { success: false };
                                  }
                                  if (typeof value != "object") {
                                    return { success: false };
                                  }
                                  if (!hasProp(value, "discriminator")) {
                                    return { success: false };
                                  }
                                  if (typeof value["discriminator"] != "string") {
                                    return { success: false };
                                  }
                                  const discriminator = value["discriminator"];
                                  switch (discriminator) {
                                    case "dynamicString": {
                                      const parsedVariant = ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              exclude: boolean;
                                              source: TagSourceType;
                                              tagKind: TagKindDynamicString;
                                              value: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "exclude")) {
                                          return { success: false };
                                        }
                                        const parsed_exclude = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                            if (typeof value !== "boolean") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["exclude"]);
                                        })();
                                        if (!parsed_exclude.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "source")) {
                                          return { success: false };
                                        }
                                        const parsed_source = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "USER":
                                                  return { success: true, value: "USER" };
                                                case "AI":
                                                  return { success: true, value: "AI" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["source"]);
                                        })();
                                        if (!parsed_source.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "tagKind")) {
                                          return { success: false };
                                        }
                                        const parsed_tagKind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicString } => {
                                            return ((
                                              value: unknown,
                                            ):
                                              | { success: false }
                                              | {
                                                  success: true;
                                                  value: {
                                                    discriminator: KindDynamicString;
                                                    id: bigint;
                                                    kind: string;
                                                  };
                                                } => {
                                              if (typeof value !== "object") {
                                                return { success: false };
                                              }
                                              if (value === null) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "discriminator")) {
                                                return { success: false };
                                              }
                                              const parsed_discriminator = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindDynamicString } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "dynamicString" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "dynamicString":
                                                        return { success: true, value: "dynamicString" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["discriminator"]);
                                              })();
                                              if (!parsed_discriminator.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "id")) {
                                                return { success: false };
                                              }
                                              const parsed_id = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                  if (typeof value === "number") {
                                                    return { success: true, value: BigInt(value) };
                                                  }
                                                  if (typeof value === "bigint") {
                                                    return { success: true, value: value };
                                                  }
                                                  return { success: false };
                                                })(value["id"]);
                                              })();
                                              if (!parsed_id.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "kind")) {
                                                return { success: false };
                                              }
                                              const parsed_kind = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                                  if (typeof value !== "string") {
                                                    return { success: false };
                                                  }
                                                  return { success: true, value: value };
                                                })(value["kind"]);
                                              })();
                                              if (!parsed_kind.success) {
                                                return { success: false };
                                              }
                                              return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                            })(value);
                                          })(value["tagKind"]);
                                        })();
                                        if (!parsed_tagKind.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "value")) {
                                          return { success: false };
                                        }
                                        const parsed_value = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["value"]);
                                        })();
                                        if (!parsed_value.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                      })(value);
                                      if (!parsedVariant.success) {
                                        return { success: false };
                                      }
                                      return { success: true, value: { discriminator: "dynamicString" as const, ...parsedVariant.value } };
                                    }
                                    case "dynamicBoolean": {
                                      const parsedVariant = ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              exclude: boolean;
                                              source: TagSourceType;
                                              tagKind: TagKindDynamicBoolean;
                                              value: boolean;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "exclude")) {
                                          return { success: false };
                                        }
                                        const parsed_exclude = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                            if (typeof value !== "boolean") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["exclude"]);
                                        })();
                                        if (!parsed_exclude.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "source")) {
                                          return { success: false };
                                        }
                                        const parsed_source = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "USER":
                                                  return { success: true, value: "USER" };
                                                case "AI":
                                                  return { success: true, value: "AI" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["source"]);
                                        })();
                                        if (!parsed_source.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "tagKind")) {
                                          return { success: false };
                                        }
                                        const parsed_tagKind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicBoolean } => {
                                            return ((
                                              value: unknown,
                                            ):
                                              | { success: false }
                                              | {
                                                  success: true;
                                                  value: {
                                                    discriminator: KindDynamicBoolean;
                                                    id: bigint;
                                                    kind: string;
                                                  };
                                                } => {
                                              if (typeof value !== "object") {
                                                return { success: false };
                                              }
                                              if (value === null) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "discriminator")) {
                                                return { success: false };
                                              }
                                              const parsed_discriminator = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindDynamicBoolean } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "dynamicBoolean" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "dynamicBoolean":
                                                        return { success: true, value: "dynamicBoolean" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["discriminator"]);
                                              })();
                                              if (!parsed_discriminator.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "id")) {
                                                return { success: false };
                                              }
                                              const parsed_id = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                  if (typeof value === "number") {
                                                    return { success: true, value: BigInt(value) };
                                                  }
                                                  if (typeof value === "bigint") {
                                                    return { success: true, value: value };
                                                  }
                                                  return { success: false };
                                                })(value["id"]);
                                              })();
                                              if (!parsed_id.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "kind")) {
                                                return { success: false };
                                              }
                                              const parsed_kind = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                                  if (typeof value !== "string") {
                                                    return { success: false };
                                                  }
                                                  return { success: true, value: value };
                                                })(value["kind"]);
                                              })();
                                              if (!parsed_kind.success) {
                                                return { success: false };
                                              }
                                              return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                            })(value);
                                          })(value["tagKind"]);
                                        })();
                                        if (!parsed_tagKind.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "value")) {
                                          return { success: false };
                                        }
                                        const parsed_value = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                            if (typeof value !== "boolean") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["value"]);
                                        })();
                                        if (!parsed_value.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                      })(value);
                                      if (!parsedVariant.success) {
                                        return { success: false };
                                      }
                                      return { success: true, value: { discriminator: "dynamicBoolean" as const, ...parsedVariant.value } };
                                    }
                                    case "dynamicNumber": {
                                      const parsedVariant = ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              exclude: boolean;
                                              source: TagSourceType;
                                              tagKind: TagKindDynamicNumber;
                                              value: number;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "exclude")) {
                                          return { success: false };
                                        }
                                        const parsed_exclude = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                            if (typeof value !== "boolean") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["exclude"]);
                                        })();
                                        if (!parsed_exclude.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "source")) {
                                          return { success: false };
                                        }
                                        const parsed_source = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "USER":
                                                  return { success: true, value: "USER" };
                                                case "AI":
                                                  return { success: true, value: "AI" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["source"]);
                                        })();
                                        if (!parsed_source.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "tagKind")) {
                                          return { success: false };
                                        }
                                        const parsed_tagKind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicNumber } => {
                                            return ((
                                              value: unknown,
                                            ):
                                              | { success: false }
                                              | {
                                                  success: true;
                                                  value: {
                                                    discriminator: KindDynamicNumber;
                                                    id: bigint;
                                                    kind: string;
                                                  };
                                                } => {
                                              if (typeof value !== "object") {
                                                return { success: false };
                                              }
                                              if (value === null) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "discriminator")) {
                                                return { success: false };
                                              }
                                              const parsed_discriminator = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindDynamicNumber } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "dynamicNumber" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "dynamicNumber":
                                                        return { success: true, value: "dynamicNumber" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["discriminator"]);
                                              })();
                                              if (!parsed_discriminator.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "id")) {
                                                return { success: false };
                                              }
                                              const parsed_id = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                  if (typeof value === "number") {
                                                    return { success: true, value: BigInt(value) };
                                                  }
                                                  if (typeof value === "bigint") {
                                                    return { success: true, value: value };
                                                  }
                                                  return { success: false };
                                                })(value["id"]);
                                              })();
                                              if (!parsed_id.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "kind")) {
                                                return { success: false };
                                              }
                                              const parsed_kind = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                                  if (typeof value !== "string") {
                                                    return { success: false };
                                                  }
                                                  return { success: true, value: value };
                                                })(value["kind"]);
                                              })();
                                              if (!parsed_kind.success) {
                                                return { success: false };
                                              }
                                              return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                            })(value);
                                          })(value["tagKind"]);
                                        })();
                                        if (!parsed_tagKind.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "value")) {
                                          return { success: false };
                                        }
                                        const parsed_value = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: number } => {
                                            if (typeof value === "number") {
                                              return { success: true, value: value };
                                            }
                                            return { success: false };
                                          })(value["value"]);
                                        })();
                                        if (!parsed_value.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                      })(value);
                                      if (!parsedVariant.success) {
                                        return { success: false };
                                      }
                                      return { success: true, value: { discriminator: "dynamicNumber" as const, ...parsedVariant.value } };
                                    }
                                    case "dynamicVoid": {
                                      const parsedVariant = ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              exclude: boolean;
                                              source: TagSourceType;
                                              tagKind: TagKindDynamicVoid;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "exclude")) {
                                          return { success: false };
                                        }
                                        const parsed_exclude = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                            if (typeof value !== "boolean") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["exclude"]);
                                        })();
                                        if (!parsed_exclude.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "source")) {
                                          return { success: false };
                                        }
                                        const parsed_source = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "USER":
                                                  return { success: true, value: "USER" };
                                                case "AI":
                                                  return { success: true, value: "AI" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["source"]);
                                        })();
                                        if (!parsed_source.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "tagKind")) {
                                          return { success: false };
                                        }
                                        const parsed_tagKind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagKindDynamicVoid } => {
                                            return ((
                                              value: unknown,
                                            ):
                                              | { success: false }
                                              | {
                                                  success: true;
                                                  value: {
                                                    discriminator: KindDynamicVoid;
                                                    id: bigint;
                                                    kind: string;
                                                  };
                                                } => {
                                              if (typeof value !== "object") {
                                                return { success: false };
                                              }
                                              if (value === null) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "discriminator")) {
                                                return { success: false };
                                              }
                                              const parsed_discriminator = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindDynamicVoid } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "dynamicVoid" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "dynamicVoid":
                                                        return { success: true, value: "dynamicVoid" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["discriminator"]);
                                              })();
                                              if (!parsed_discriminator.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "id")) {
                                                return { success: false };
                                              }
                                              const parsed_id = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                  if (typeof value === "number") {
                                                    return { success: true, value: BigInt(value) };
                                                  }
                                                  if (typeof value === "bigint") {
                                                    return { success: true, value: value };
                                                  }
                                                  return { success: false };
                                                })(value["id"]);
                                              })();
                                              if (!parsed_id.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "kind")) {
                                                return { success: false };
                                              }
                                              const parsed_kind = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: string } => {
                                                  if (typeof value !== "string") {
                                                    return { success: false };
                                                  }
                                                  return { success: true, value: value };
                                                })(value["kind"]);
                                              })();
                                              if (!parsed_kind.success) {
                                                return { success: false };
                                              }
                                              return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                            })(value);
                                          })(value["tagKind"]);
                                        })();
                                        if (!parsed_tagKind.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value } };
                                      })(value);
                                      if (!parsedVariant.success) {
                                        return { success: false };
                                      }
                                      return { success: true, value: { discriminator: "dynamicVoid" as const, ...parsedVariant.value } };
                                    }
                                    case "channel": {
                                      const parsedVariant = ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              exclude: boolean;
                                              source: TagSourceType;
                                              tagKind: TagKindChannel;
                                              value: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "exclude")) {
                                          return { success: false };
                                        }
                                        const parsed_exclude = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                            if (typeof value !== "boolean") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["exclude"]);
                                        })();
                                        if (!parsed_exclude.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "source")) {
                                          return { success: false };
                                        }
                                        const parsed_source = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "USER":
                                                  return { success: true, value: "USER" };
                                                case "AI":
                                                  return { success: true, value: "AI" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["source"]);
                                        })();
                                        if (!parsed_source.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "tagKind")) {
                                          return { success: false };
                                        }
                                        const parsed_tagKind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagKindChannel } => {
                                            return ((
                                              value: unknown,
                                            ):
                                              | { success: false }
                                              | {
                                                  success: true;
                                                  value: {
                                                    discriminator: KindChannel;
                                                    id: bigint;
                                                    kind: KindChannel;
                                                  };
                                                } => {
                                              if (typeof value !== "object") {
                                                return { success: false };
                                              }
                                              if (value === null) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "discriminator")) {
                                                return { success: false };
                                              }
                                              const parsed_discriminator = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "channel":
                                                        return { success: true, value: "channel" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["discriminator"]);
                                              })();
                                              if (!parsed_discriminator.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "id")) {
                                                return { success: false };
                                              }
                                              const parsed_id = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                  if (typeof value === "number") {
                                                    return { success: true, value: BigInt(value) };
                                                  }
                                                  if (typeof value === "bigint") {
                                                    return { success: true, value: value };
                                                  }
                                                  return { success: false };
                                                })(value["id"]);
                                              })();
                                              if (!parsed_id.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "kind")) {
                                                return { success: false };
                                              }
                                              const parsed_kind = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindChannel } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "channel" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "channel":
                                                        return { success: true, value: "channel" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["kind"]);
                                              })();
                                              if (!parsed_kind.success) {
                                                return { success: false };
                                              }
                                              return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                            })(value);
                                          })(value["tagKind"]);
                                        })();
                                        if (!parsed_tagKind.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "value")) {
                                          return { success: false };
                                        }
                                        const parsed_value = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["value"]);
                                        })();
                                        if (!parsed_value.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                      })(value);
                                      if (!parsedVariant.success) {
                                        return { success: false };
                                      }
                                      return { success: true, value: { discriminator: "channel" as const, ...parsedVariant.value } };
                                    }
                                    case "primary-skill": {
                                      const parsedVariant = ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              exclude: boolean;
                                              source: TagSourceType;
                                              tagKind: TagKindPrimarySkill;
                                              value: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "exclude")) {
                                          return { success: false };
                                        }
                                        const parsed_exclude = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                            if (typeof value !== "boolean") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["exclude"]);
                                        })();
                                        if (!parsed_exclude.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "source")) {
                                          return { success: false };
                                        }
                                        const parsed_source = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "USER":
                                                  return { success: true, value: "USER" };
                                                case "AI":
                                                  return { success: true, value: "AI" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["source"]);
                                        })();
                                        if (!parsed_source.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "tagKind")) {
                                          return { success: false };
                                        }
                                        const parsed_tagKind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagKindPrimarySkill } => {
                                            return ((
                                              value: unknown,
                                            ):
                                              | { success: false }
                                              | {
                                                  success: true;
                                                  value: {
                                                    discriminator: KindPrimarySkill;
                                                    id: bigint;
                                                    kind: KindPrimarySkill;
                                                  };
                                                } => {
                                              if (typeof value !== "object") {
                                                return { success: false };
                                              }
                                              if (value === null) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "discriminator")) {
                                                return { success: false };
                                              }
                                              const parsed_discriminator = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "primary-skill":
                                                        return { success: true, value: "primary-skill" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["discriminator"]);
                                              })();
                                              if (!parsed_discriminator.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "id")) {
                                                return { success: false };
                                              }
                                              const parsed_id = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                  if (typeof value === "number") {
                                                    return { success: true, value: BigInt(value) };
                                                  }
                                                  if (typeof value === "bigint") {
                                                    return { success: true, value: value };
                                                  }
                                                  return { success: false };
                                                })(value["id"]);
                                              })();
                                              if (!parsed_id.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "kind")) {
                                                return { success: false };
                                              }
                                              const parsed_kind = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindPrimarySkill } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "primary-skill" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "primary-skill":
                                                        return { success: true, value: "primary-skill" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["kind"]);
                                              })();
                                              if (!parsed_kind.success) {
                                                return { success: false };
                                              }
                                              return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                            })(value);
                                          })(value["tagKind"]);
                                        })();
                                        if (!parsed_tagKind.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "value")) {
                                          return { success: false };
                                        }
                                        const parsed_value = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["value"]);
                                        })();
                                        if (!parsed_value.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                      })(value);
                                      if (!parsedVariant.success) {
                                        return { success: false };
                                      }
                                      return { success: true, value: { discriminator: "primary-skill" as const, ...parsedVariant.value } };
                                    }
                                    case "skill": {
                                      const parsedVariant = ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              exclude: boolean;
                                              source: TagSourceType;
                                              tagKind: TagKindSkill;
                                              value: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "exclude")) {
                                          return { success: false };
                                        }
                                        const parsed_exclude = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                            if (typeof value !== "boolean") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["exclude"]);
                                        })();
                                        if (!parsed_exclude.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "source")) {
                                          return { success: false };
                                        }
                                        const parsed_source = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "USER":
                                                  return { success: true, value: "USER" };
                                                case "AI":
                                                  return { success: true, value: "AI" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["source"]);
                                        })();
                                        if (!parsed_source.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "tagKind")) {
                                          return { success: false };
                                        }
                                        const parsed_tagKind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagKindSkill } => {
                                            return ((
                                              value: unknown,
                                            ):
                                              | { success: false }
                                              | {
                                                  success: true;
                                                  value: {
                                                    discriminator: KindSkill;
                                                    id: bigint;
                                                    kind: KindSkill;
                                                  };
                                                } => {
                                              if (typeof value !== "object") {
                                                return { success: false };
                                              }
                                              if (value === null) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "discriminator")) {
                                                return { success: false };
                                              }
                                              const parsed_discriminator = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "skill":
                                                        return { success: true, value: "skill" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["discriminator"]);
                                              })();
                                              if (!parsed_discriminator.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "id")) {
                                                return { success: false };
                                              }
                                              const parsed_id = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                  if (typeof value === "number") {
                                                    return { success: true, value: BigInt(value) };
                                                  }
                                                  if (typeof value === "bigint") {
                                                    return { success: true, value: value };
                                                  }
                                                  return { success: false };
                                                })(value["id"]);
                                              })();
                                              if (!parsed_id.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "kind")) {
                                                return { success: false };
                                              }
                                              const parsed_kind = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindSkill } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "skill" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "skill":
                                                        return { success: true, value: "skill" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["kind"]);
                                              })();
                                              if (!parsed_kind.success) {
                                                return { success: false };
                                              }
                                              return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                            })(value);
                                          })(value["tagKind"]);
                                        })();
                                        if (!parsed_tagKind.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "value")) {
                                          return { success: false };
                                        }
                                        const parsed_value = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["value"]);
                                        })();
                                        if (!parsed_value.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                      })(value);
                                      if (!parsedVariant.success) {
                                        return { success: false };
                                      }
                                      return { success: true, value: { discriminator: "skill" as const, ...parsedVariant.value } };
                                    }
                                    case "key-quality": {
                                      const parsedVariant = ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              exclude: boolean;
                                              source: TagSourceType;
                                              tagKind: TagKindKeyQuality;
                                              value: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "exclude")) {
                                          return { success: false };
                                        }
                                        const parsed_exclude = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                            if (typeof value !== "boolean") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["exclude"]);
                                        })();
                                        if (!parsed_exclude.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "source")) {
                                          return { success: false };
                                        }
                                        const parsed_source = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "USER":
                                                  return { success: true, value: "USER" };
                                                case "AI":
                                                  return { success: true, value: "AI" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["source"]);
                                        })();
                                        if (!parsed_source.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "tagKind")) {
                                          return { success: false };
                                        }
                                        const parsed_tagKind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagKindKeyQuality } => {
                                            return ((
                                              value: unknown,
                                            ):
                                              | { success: false }
                                              | {
                                                  success: true;
                                                  value: {
                                                    discriminator: KindKeyQuality;
                                                    id: bigint;
                                                    kind: KindKeyQuality;
                                                  };
                                                } => {
                                              if (typeof value !== "object") {
                                                return { success: false };
                                              }
                                              if (value === null) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "discriminator")) {
                                                return { success: false };
                                              }
                                              const parsed_discriminator = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "key-quality":
                                                        return { success: true, value: "key-quality" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["discriminator"]);
                                              })();
                                              if (!parsed_discriminator.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "id")) {
                                                return { success: false };
                                              }
                                              const parsed_id = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                  if (typeof value === "number") {
                                                    return { success: true, value: BigInt(value) };
                                                  }
                                                  if (typeof value === "bigint") {
                                                    return { success: true, value: value };
                                                  }
                                                  return { success: false };
                                                })(value["id"]);
                                              })();
                                              if (!parsed_id.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "kind")) {
                                                return { success: false };
                                              }
                                              const parsed_kind = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindKeyQuality } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "key-quality" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "key-quality":
                                                        return { success: true, value: "key-quality" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["kind"]);
                                              })();
                                              if (!parsed_kind.success) {
                                                return { success: false };
                                              }
                                              return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                            })(value);
                                          })(value["tagKind"]);
                                        })();
                                        if (!parsed_tagKind.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "value")) {
                                          return { success: false };
                                        }
                                        const parsed_value = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["value"]);
                                        })();
                                        if (!parsed_value.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                      })(value);
                                      if (!parsedVariant.success) {
                                        return { success: false };
                                      }
                                      return { success: true, value: { discriminator: "key-quality" as const, ...parsedVariant.value } };
                                    }
                                    case "classification": {
                                      const parsedVariant = ((
                                        value: unknown,
                                      ):
                                        | { success: false }
                                        | {
                                            success: true;
                                            value: {
                                              exclude: boolean;
                                              source: TagSourceType;
                                              tagKind: TagKindClassification;
                                              value: string;
                                            };
                                          } => {
                                        if (typeof value !== "object") {
                                          return { success: false };
                                        }
                                        if (value === null) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "exclude")) {
                                          return { success: false };
                                        }
                                        const parsed_exclude = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                                            if (typeof value !== "boolean") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["exclude"]);
                                        })();
                                        if (!parsed_exclude.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "source")) {
                                          return { success: false };
                                        }
                                        const parsed_source = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagSourceType } => {
                                            return ((value: unknown): { success: false } | { success: true; value: "USER" | "AI" } => {
                                              if (typeof value !== "string") {
                                                return { success: false };
                                              }
                                              switch (value) {
                                                case "USER":
                                                  return { success: true, value: "USER" };
                                                case "AI":
                                                  return { success: true, value: "AI" };
                                                default:
                                                  return { success: false };
                                              }
                                            })(value);
                                          })(value["source"]);
                                        })();
                                        if (!parsed_source.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "tagKind")) {
                                          return { success: false };
                                        }
                                        const parsed_tagKind = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: TagKindClassification } => {
                                            return ((
                                              value: unknown,
                                            ):
                                              | { success: false }
                                              | {
                                                  success: true;
                                                  value: {
                                                    discriminator: KindClassification;
                                                    id: bigint;
                                                    kind: KindClassification;
                                                  };
                                                } => {
                                              if (typeof value !== "object") {
                                                return { success: false };
                                              }
                                              if (value === null) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "discriminator")) {
                                                return { success: false };
                                              }
                                              const parsed_discriminator = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "classification":
                                                        return { success: true, value: "classification" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["discriminator"]);
                                              })();
                                              if (!parsed_discriminator.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "id")) {
                                                return { success: false };
                                              }
                                              const parsed_id = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: bigint } => {
                                                  if (typeof value === "number") {
                                                    return { success: true, value: BigInt(value) };
                                                  }
                                                  if (typeof value === "bigint") {
                                                    return { success: true, value: value };
                                                  }
                                                  return { success: false };
                                                })(value["id"]);
                                              })();
                                              if (!parsed_id.success) {
                                                return { success: false };
                                              }
                                              if (!hasProp(value, "kind")) {
                                                return { success: false };
                                              }
                                              const parsed_kind = (() => {
                                                return ((value: unknown): { success: false } | { success: true; value: KindClassification } => {
                                                  return ((value: unknown): { success: false } | { success: true; value: "classification" } => {
                                                    if (typeof value !== "string") {
                                                      return { success: false };
                                                    }
                                                    switch (value) {
                                                      case "classification":
                                                        return { success: true, value: "classification" };
                                                      default:
                                                        return { success: false };
                                                    }
                                                  })(value);
                                                })(value["kind"]);
                                              })();
                                              if (!parsed_kind.success) {
                                                return { success: false };
                                              }
                                              return { success: true, value: { discriminator: parsed_discriminator.value, id: parsed_id.value, kind: parsed_kind.value } };
                                            })(value);
                                          })(value["tagKind"]);
                                        })();
                                        if (!parsed_tagKind.success) {
                                          return { success: false };
                                        }
                                        if (!hasProp(value, "value")) {
                                          return { success: false };
                                        }
                                        const parsed_value = (() => {
                                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                                            if (typeof value !== "string") {
                                              return { success: false };
                                            }
                                            return { success: true, value: value };
                                          })(value["value"]);
                                        })();
                                        if (!parsed_value.success) {
                                          return { success: false };
                                        }
                                        return { success: true, value: { exclude: parsed_exclude.value, source: parsed_source.value, tagKind: parsed_tagKind.value, value: parsed_value.value } };
                                      })(value);
                                      if (!parsedVariant.success) {
                                        return { success: false };
                                      }
                                      return { success: true, value: { discriminator: "classification" as const, ...parsedVariant.value } };
                                    }
                                    default:
                                      return { success: false };
                                  }
                                })(value);
                              })(value);
                            });
                            if (parsed.find((value) => !value.success) !== undefined) {
                              return { success: false };
                            }
                            return {
                              success: true,
                              value: parsed.map((entry) => {
                                assert(entry.success == true);
                                return entry.value;
                              }),
                            };
                          })(value["tags"]);
                        })();
                        if (!parsed_tags.success) {
                          return { success: false };
                        }
                        if (!hasProp(value, "title")) {
                          return { success: false };
                        }
                        const parsed_title = (() => {
                          return ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value["title"]);
                        })();
                        if (!parsed_title.success) {
                          return { success: false };
                        }
                        return {
                          success: true,
                          value: {
                            actionableId: parsed_actionableId.value,
                            activatedAt: parsed_activatedAt.value,
                            active: parsed_active.value,
                            coverUri: parsed_coverUri.value,
                            description: parsed_description.value,
                            language: parsed_language.value,
                            modifiedAt: parsed_modifiedAt.value,
                            name: parsed_name.value,
                            tags: parsed_tags.value,
                            title: parsed_title.value,
                          },
                        };
                      })(value);
                    })(value["actionable"]);
                  })();
                  if (!parsed_actionable.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "relayState")) {
                    return { success: false };
                  }
                  const parsed_relayState = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["relayState"]);
                  })();
                  if (!parsed_relayState.success) {
                    return { success: false };
                  }
                  return { success: true, value: { actionable: parsed_actionable.value, relayState: parsed_relayState.value } };
                })(value);
                if (!parsedVariant.success) {
                  return { success: false };
                }
                return { success: true, value: { kind: "ACTIONABLE" as const, ...parsedVariant.value } };
              }
              default:
                return { success: false };
            }
          })(value);
        })(value);
      })(value["relayType"]);
    })();
    if (!parsed_relayType.success) {
      return { success: false };
    }
    if (!hasProp(value, "ssoRequest")) {
      return { success: false };
    }
    const parsed_ssoRequest = (() => {
      return ((value: unknown): { success: false } | { success: true; value: WarmupV2SsoRequest } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                corporateId: number;
                function: string | null;
                ssoUid: string;
                title: number | null;
                town: string | null;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "corporateId")) {
            return { success: false };
          }
          const parsed_corporateId = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number } => {
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["corporateId"]);
          })();
          if (!parsed_corporateId.success) {
            return { success: false };
          }
          if (!hasProp(value, "function")) {
            return { success: false };
          }
          const parsed_function = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["function"]);
          })();
          if (!parsed_function.success) {
            return { success: false };
          }
          if (!hasProp(value, "ssoUid")) {
            return { success: false };
          }
          const parsed_ssoUid = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["ssoUid"]);
          })();
          if (!parsed_ssoUid.success) {
            return { success: false };
          }
          if (!hasProp(value, "title")) {
            return { success: false };
          }
          const parsed_title = (() => {
            return ((value: unknown): { success: false } | { success: true; value: number | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value === "number") {
                return { success: true, value: value };
              }
              return { success: false };
            })(value["title"]);
          })();
          if (!parsed_title.success) {
            return { success: false };
          }
          if (!hasProp(value, "town")) {
            return { success: false };
          }
          const parsed_town = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["town"]);
          })();
          if (!parsed_town.success) {
            return { success: false };
          }
          return { success: true, value: { corporateId: parsed_corporateId.value, function: parsed_function.value, ssoUid: parsed_ssoUid.value, title: parsed_title.value, town: parsed_town.value } };
        })(value);
      })(value["ssoRequest"]);
    })();
    if (!parsed_ssoRequest.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        corporationName: parsed_corporationName.value,
        countryCode: parsed_countryCode.value,
        email: parsed_email.value,
        evrUuid: parsed_evrUuid.value,
        firstName: parsed_firstName.value,
        language: parsed_language.value,
        lastName: parsed_lastName.value,
        relayType: parsed_relayType.value,
        ssoRequest: parsed_ssoRequest.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// WishlistManagementData
export function serialize_WishlistManagementData(obj: WishlistManagementData): string {
  const serialized = ((value: { editorOptions: DropdownEntry[]; list: WishlistManagementItemList }): unknown => {
    return {
      editorOptions: ((value: DropdownEntry[]): unknown => {
        return value.map((value: { text: string; value: string | null }): unknown => {
          return {
            text: ((value: string): unknown => {
              return value;
            })(value["text"]),
            value: ((value: string | null): unknown => {
              if (value === null) {
                return null;
              }
              return value;
            })(value["value"]),
          };
        });
      })(value["editorOptions"]),
      list: ((value: { items: WishlistManagementItem[] }): unknown => {
        return {
          items: ((value: WishlistManagementItem[]): unknown => {
            return value.map(
              (value: {
                coverUri: string | null;
                dataId: number | null;
                description: string;
                detailInfo: string;
                isbn: string | null;
                krecId: number;
                language: Language;
                managingEditorCustomerId: number | null;
                rightsGranted: boolean;
                suggestedAt: DateTime<true>;
                tinyUrl: string | null;
                title: string;
              }): unknown => {
                return {
                  coverUri: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["coverUri"]),
                  dataId: ((value: number | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["dataId"]),
                  description: ((value: string): unknown => {
                    return value;
                  })(value["description"]),
                  detailInfo: ((value: string): unknown => {
                    return value;
                  })(value["detailInfo"]),
                  isbn: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["isbn"]),
                  krecId: ((value: number): unknown => {
                    return value;
                  })(value["krecId"]),
                  language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                    return value;
                  })(value["language"]),
                  managingEditorCustomerId: ((value: number | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["managingEditorCustomerId"]),
                  rightsGranted: ((value: boolean): unknown => {
                    return value;
                  })(value["rightsGranted"]),
                  suggestedAt: ((value: DateTime<true>): unknown => {
                    return value.toMillis();
                  })(value["suggestedAt"]),
                  tinyUrl: ((value: string | null): unknown => {
                    if (value === null) {
                      return null;
                    }
                    return value;
                  })(value["tinyUrl"]),
                  title: ((value: string): unknown => {
                    return value;
                  })(value["title"]),
                };
              },
            );
          })(value["items"]),
        };
      })(value["list"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_WishlistManagementData(str: string): WishlistManagementData | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          editorOptions: DropdownEntry[];
          list: WishlistManagementItemList;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "editorOptions")) {
      return { success: false };
    }
    const parsed_editorOptions = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DropdownEntry[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: DropdownEntry } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  text: string;
                  value: string | null;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "text")) {
              return { success: false };
            }
            const parsed_text = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["text"]);
            })();
            if (!parsed_text.success) {
              return { success: false };
            }
            if (!hasProp(value, "value")) {
              return { success: false };
            }
            const parsed_value = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["value"]);
            })();
            if (!parsed_value.success) {
              return { success: false };
            }
            return { success: true, value: { text: parsed_text.value, value: parsed_value.value } };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["editorOptions"]);
    })();
    if (!parsed_editorOptions.success) {
      return { success: false };
    }
    if (!hasProp(value, "list")) {
      return { success: false };
    }
    const parsed_list = (() => {
      return ((value: unknown): { success: false } | { success: true; value: WishlistManagementItemList } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                items: WishlistManagementItem[];
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "items")) {
            return { success: false };
          }
          const parsed_items = (() => {
            return ((value: unknown): { success: false } | { success: true; value: WishlistManagementItem[] } => {
              if (!isArray(value)) {
                return { success: false };
              }
              const parsed = value.map((value: unknown): { success: false } | { success: true; value: WishlistManagementItem } => {
                return ((
                  value: unknown,
                ):
                  | { success: false }
                  | {
                      success: true;
                      value: {
                        coverUri: string | null;
                        dataId: number | null;
                        description: string;
                        detailInfo: string;
                        isbn: string | null;
                        krecId: number;
                        language: Language;
                        managingEditorCustomerId: number | null;
                        rightsGranted: boolean;
                        suggestedAt: DateTime<true>;
                        tinyUrl: string | null;
                        title: string;
                      };
                    } => {
                  if (typeof value !== "object") {
                    return { success: false };
                  }
                  if (value === null) {
                    return { success: false };
                  }
                  if (!hasProp(value, "coverUri")) {
                    return { success: false };
                  }
                  const parsed_coverUri = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["coverUri"]);
                  })();
                  if (!parsed_coverUri.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "dataId")) {
                    return { success: false };
                  }
                  const parsed_dataId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["dataId"]);
                  })();
                  if (!parsed_dataId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "description")) {
                    return { success: false };
                  }
                  const parsed_description = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["description"]);
                  })();
                  if (!parsed_description.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "detailInfo")) {
                    return { success: false };
                  }
                  const parsed_detailInfo = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["detailInfo"]);
                  })();
                  if (!parsed_detailInfo.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "isbn")) {
                    return { success: false };
                  }
                  const parsed_isbn = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["isbn"]);
                  })();
                  if (!parsed_isbn.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "krecId")) {
                    return { success: false };
                  }
                  const parsed_krecId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number } => {
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["krecId"]);
                  })();
                  if (!parsed_krecId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "language")) {
                    return { success: false };
                  }
                  const parsed_language = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: Language } => {
                      return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                        if (typeof value !== "string") {
                          return { success: false };
                        }
                        switch (value) {
                          case "en":
                            return { success: true, value: "en" };
                          case "de":
                            return { success: true, value: "de" };
                          case "es":
                            return { success: true, value: "es" };
                          case "ru":
                            return { success: true, value: "ru" };
                          case "zh":
                            return { success: true, value: "zh" };
                          case "pt":
                            return { success: true, value: "pt" };
                          case "fr":
                            return { success: true, value: "fr" };
                          default:
                            return { success: false };
                        }
                      })(value);
                    })(value["language"]);
                  })();
                  if (!parsed_language.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "managingEditorCustomerId")) {
                    return { success: false };
                  }
                  const parsed_managingEditorCustomerId = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value === "number") {
                        return { success: true, value: value };
                      }
                      return { success: false };
                    })(value["managingEditorCustomerId"]);
                  })();
                  if (!parsed_managingEditorCustomerId.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "rightsGranted")) {
                    return { success: false };
                  }
                  const parsed_rightsGranted = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                      if (typeof value !== "boolean") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["rightsGranted"]);
                  })();
                  if (!parsed_rightsGranted.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "suggestedAt")) {
                    return { success: false };
                  }
                  const parsed_suggestedAt = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                      if (typeof value !== "number") {
                        return { success: false };
                      }
                      const parsed = DateTime.fromMillis(value);
                      if (!parsed.isValid) {
                        return { success: false };
                      }
                      return { success: true, value: parsed };
                    })(value["suggestedAt"]);
                  })();
                  if (!parsed_suggestedAt.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "tinyUrl")) {
                    return { success: false };
                  }
                  const parsed_tinyUrl = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                      if (value === null) {
                        return { success: true, value: null };
                      }
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["tinyUrl"]);
                  })();
                  if (!parsed_tinyUrl.success) {
                    return { success: false };
                  }
                  if (!hasProp(value, "title")) {
                    return { success: false };
                  }
                  const parsed_title = (() => {
                    return ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value["title"]);
                  })();
                  if (!parsed_title.success) {
                    return { success: false };
                  }
                  return {
                    success: true,
                    value: {
                      coverUri: parsed_coverUri.value,
                      dataId: parsed_dataId.value,
                      description: parsed_description.value,
                      detailInfo: parsed_detailInfo.value,
                      isbn: parsed_isbn.value,
                      krecId: parsed_krecId.value,
                      language: parsed_language.value,
                      managingEditorCustomerId: parsed_managingEditorCustomerId.value,
                      rightsGranted: parsed_rightsGranted.value,
                      suggestedAt: parsed_suggestedAt.value,
                      tinyUrl: parsed_tinyUrl.value,
                      title: parsed_title.value,
                    },
                  };
                })(value);
              });
              if (parsed.find((value) => !value.success) !== undefined) {
                return { success: false };
              }
              return {
                success: true,
                value: parsed.map((entry) => {
                  assert(entry.success == true);
                  return entry.value;
                }),
              };
            })(value["items"]);
          })();
          if (!parsed_items.success) {
            return { success: false };
          }
          return { success: true, value: { items: parsed_items.value } };
        })(value);
      })(value["list"]);
    })();
    if (!parsed_list.success) {
      return { success: false };
    }
    return { success: true, value: { editorOptions: parsed_editorOptions.value, list: parsed_list.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// WishlistManagementEditorUpdate
export function serialize_WishlistManagementEditorUpdate(obj: WishlistManagementEditorUpdate): string {
  const serialized = ((value: { managingEditorCustomerId: number | null }): unknown => {
    return {
      managingEditorCustomerId: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["managingEditorCustomerId"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_WishlistManagementEditorUpdate(str: string): WishlistManagementEditorUpdate | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          managingEditorCustomerId: number | null;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "managingEditorCustomerId")) {
      return { success: false };
    }
    const parsed_managingEditorCustomerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["managingEditorCustomerId"]);
    })();
    if (!parsed_managingEditorCustomerId.success) {
      return { success: false };
    }
    return { success: true, value: { managingEditorCustomerId: parsed_managingEditorCustomerId.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// WishlistManagementItem
export function serialize_WishlistManagementItem(obj: WishlistManagementItem): string {
  const serialized = ((value: {
    coverUri: string | null;
    dataId: number | null;
    description: string;
    detailInfo: string;
    isbn: string | null;
    krecId: number;
    language: Language;
    managingEditorCustomerId: number | null;
    rightsGranted: boolean;
    suggestedAt: DateTime<true>;
    tinyUrl: string | null;
    title: string;
  }): unknown => {
    return {
      coverUri: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["coverUri"]),
      dataId: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["dataId"]),
      description: ((value: string): unknown => {
        return value;
      })(value["description"]),
      detailInfo: ((value: string): unknown => {
        return value;
      })(value["detailInfo"]),
      isbn: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["isbn"]),
      krecId: ((value: number): unknown => {
        return value;
      })(value["krecId"]),
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
      managingEditorCustomerId: ((value: number | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["managingEditorCustomerId"]),
      rightsGranted: ((value: boolean): unknown => {
        return value;
      })(value["rightsGranted"]),
      suggestedAt: ((value: DateTime<true>): unknown => {
        return value.toMillis();
      })(value["suggestedAt"]),
      tinyUrl: ((value: string | null): unknown => {
        if (value === null) {
          return null;
        }
        return value;
      })(value["tinyUrl"]),
      title: ((value: string): unknown => {
        return value;
      })(value["title"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_WishlistManagementItem(str: string): WishlistManagementItem | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          coverUri: string | null;
          dataId: number | null;
          description: string;
          detailInfo: string;
          isbn: string | null;
          krecId: number;
          language: Language;
          managingEditorCustomerId: number | null;
          rightsGranted: boolean;
          suggestedAt: DateTime<true>;
          tinyUrl: string | null;
          title: string;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "coverUri")) {
      return { success: false };
    }
    const parsed_coverUri = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["coverUri"]);
    })();
    if (!parsed_coverUri.success) {
      return { success: false };
    }
    if (!hasProp(value, "dataId")) {
      return { success: false };
    }
    const parsed_dataId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["dataId"]);
    })();
    if (!parsed_dataId.success) {
      return { success: false };
    }
    if (!hasProp(value, "description")) {
      return { success: false };
    }
    const parsed_description = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["description"]);
    })();
    if (!parsed_description.success) {
      return { success: false };
    }
    if (!hasProp(value, "detailInfo")) {
      return { success: false };
    }
    const parsed_detailInfo = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["detailInfo"]);
    })();
    if (!parsed_detailInfo.success) {
      return { success: false };
    }
    if (!hasProp(value, "isbn")) {
      return { success: false };
    }
    const parsed_isbn = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["isbn"]);
    })();
    if (!parsed_isbn.success) {
      return { success: false };
    }
    if (!hasProp(value, "krecId")) {
      return { success: false };
    }
    const parsed_krecId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number } => {
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["krecId"]);
    })();
    if (!parsed_krecId.success) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language } => {
        return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "en":
              return { success: true, value: "en" };
            case "de":
              return { success: true, value: "de" };
            case "es":
              return { success: true, value: "es" };
            case "ru":
              return { success: true, value: "ru" };
            case "zh":
              return { success: true, value: "zh" };
            case "pt":
              return { success: true, value: "pt" };
            case "fr":
              return { success: true, value: "fr" };
            default:
              return { success: false };
          }
        })(value);
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    if (!hasProp(value, "managingEditorCustomerId")) {
      return { success: false };
    }
    const parsed_managingEditorCustomerId = (() => {
      return ((value: unknown): { success: false } | { success: true; value: number | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value === "number") {
          return { success: true, value: value };
        }
        return { success: false };
      })(value["managingEditorCustomerId"]);
    })();
    if (!parsed_managingEditorCustomerId.success) {
      return { success: false };
    }
    if (!hasProp(value, "rightsGranted")) {
      return { success: false };
    }
    const parsed_rightsGranted = (() => {
      return ((value: unknown): { success: false } | { success: true; value: boolean } => {
        if (typeof value !== "boolean") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["rightsGranted"]);
    })();
    if (!parsed_rightsGranted.success) {
      return { success: false };
    }
    if (!hasProp(value, "suggestedAt")) {
      return { success: false };
    }
    const parsed_suggestedAt = (() => {
      return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
        if (typeof value !== "number") {
          return { success: false };
        }
        const parsed = DateTime.fromMillis(value);
        if (!parsed.isValid) {
          return { success: false };
        }
        return { success: true, value: parsed };
      })(value["suggestedAt"]);
    })();
    if (!parsed_suggestedAt.success) {
      return { success: false };
    }
    if (!hasProp(value, "tinyUrl")) {
      return { success: false };
    }
    const parsed_tinyUrl = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string | null } => {
        if (value === null) {
          return { success: true, value: null };
        }
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["tinyUrl"]);
    })();
    if (!parsed_tinyUrl.success) {
      return { success: false };
    }
    if (!hasProp(value, "title")) {
      return { success: false };
    }
    const parsed_title = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["title"]);
    })();
    if (!parsed_title.success) {
      return { success: false };
    }
    return {
      success: true,
      value: {
        coverUri: parsed_coverUri.value,
        dataId: parsed_dataId.value,
        description: parsed_description.value,
        detailInfo: parsed_detailInfo.value,
        isbn: parsed_isbn.value,
        krecId: parsed_krecId.value,
        language: parsed_language.value,
        managingEditorCustomerId: parsed_managingEditorCustomerId.value,
        rightsGranted: parsed_rightsGranted.value,
        suggestedAt: parsed_suggestedAt.value,
        tinyUrl: parsed_tinyUrl.value,
        title: parsed_title.value,
      },
    };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// WishlistManagementItemList
export function serialize_WishlistManagementItemList(obj: WishlistManagementItemList): string {
  const serialized = ((value: { items: WishlistManagementItem[] }): unknown => {
    return {
      items: ((value: WishlistManagementItem[]): unknown => {
        return value.map(
          (value: {
            coverUri: string | null;
            dataId: number | null;
            description: string;
            detailInfo: string;
            isbn: string | null;
            krecId: number;
            language: Language;
            managingEditorCustomerId: number | null;
            rightsGranted: boolean;
            suggestedAt: DateTime<true>;
            tinyUrl: string | null;
            title: string;
          }): unknown => {
            return {
              coverUri: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["coverUri"]),
              dataId: ((value: number | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["dataId"]),
              description: ((value: string): unknown => {
                return value;
              })(value["description"]),
              detailInfo: ((value: string): unknown => {
                return value;
              })(value["detailInfo"]),
              isbn: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["isbn"]),
              krecId: ((value: number): unknown => {
                return value;
              })(value["krecId"]),
              language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
                return value;
              })(value["language"]),
              managingEditorCustomerId: ((value: number | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["managingEditorCustomerId"]),
              rightsGranted: ((value: boolean): unknown => {
                return value;
              })(value["rightsGranted"]),
              suggestedAt: ((value: DateTime<true>): unknown => {
                return value.toMillis();
              })(value["suggestedAt"]),
              tinyUrl: ((value: string | null): unknown => {
                if (value === null) {
                  return null;
                }
                return value;
              })(value["tinyUrl"]),
              title: ((value: string): unknown => {
                return value;
              })(value["title"]),
            };
          },
        );
      })(value["items"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_WishlistManagementItemList(str: string): WishlistManagementItemList | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          items: WishlistManagementItem[];
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "items")) {
      return { success: false };
    }
    const parsed_items = (() => {
      return ((value: unknown): { success: false } | { success: true; value: WishlistManagementItem[] } => {
        if (!isArray(value)) {
          return { success: false };
        }
        const parsed = value.map((value: unknown): { success: false } | { success: true; value: WishlistManagementItem } => {
          return ((
            value: unknown,
          ):
            | { success: false }
            | {
                success: true;
                value: {
                  coverUri: string | null;
                  dataId: number | null;
                  description: string;
                  detailInfo: string;
                  isbn: string | null;
                  krecId: number;
                  language: Language;
                  managingEditorCustomerId: number | null;
                  rightsGranted: boolean;
                  suggestedAt: DateTime<true>;
                  tinyUrl: string | null;
                  title: string;
                };
              } => {
            if (typeof value !== "object") {
              return { success: false };
            }
            if (value === null) {
              return { success: false };
            }
            if (!hasProp(value, "coverUri")) {
              return { success: false };
            }
            const parsed_coverUri = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["coverUri"]);
            })();
            if (!parsed_coverUri.success) {
              return { success: false };
            }
            if (!hasProp(value, "dataId")) {
              return { success: false };
            }
            const parsed_dataId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["dataId"]);
            })();
            if (!parsed_dataId.success) {
              return { success: false };
            }
            if (!hasProp(value, "description")) {
              return { success: false };
            }
            const parsed_description = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["description"]);
            })();
            if (!parsed_description.success) {
              return { success: false };
            }
            if (!hasProp(value, "detailInfo")) {
              return { success: false };
            }
            const parsed_detailInfo = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["detailInfo"]);
            })();
            if (!parsed_detailInfo.success) {
              return { success: false };
            }
            if (!hasProp(value, "isbn")) {
              return { success: false };
            }
            const parsed_isbn = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["isbn"]);
            })();
            if (!parsed_isbn.success) {
              return { success: false };
            }
            if (!hasProp(value, "krecId")) {
              return { success: false };
            }
            const parsed_krecId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number } => {
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["krecId"]);
            })();
            if (!parsed_krecId.success) {
              return { success: false };
            }
            if (!hasProp(value, "language")) {
              return { success: false };
            }
            const parsed_language = (() => {
              return ((value: unknown): { success: false } | { success: true; value: Language } => {
                return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
                  if (typeof value !== "string") {
                    return { success: false };
                  }
                  switch (value) {
                    case "en":
                      return { success: true, value: "en" };
                    case "de":
                      return { success: true, value: "de" };
                    case "es":
                      return { success: true, value: "es" };
                    case "ru":
                      return { success: true, value: "ru" };
                    case "zh":
                      return { success: true, value: "zh" };
                    case "pt":
                      return { success: true, value: "pt" };
                    case "fr":
                      return { success: true, value: "fr" };
                    default:
                      return { success: false };
                  }
                })(value);
              })(value["language"]);
            })();
            if (!parsed_language.success) {
              return { success: false };
            }
            if (!hasProp(value, "managingEditorCustomerId")) {
              return { success: false };
            }
            const parsed_managingEditorCustomerId = (() => {
              return ((value: unknown): { success: false } | { success: true; value: number | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value === "number") {
                  return { success: true, value: value };
                }
                return { success: false };
              })(value["managingEditorCustomerId"]);
            })();
            if (!parsed_managingEditorCustomerId.success) {
              return { success: false };
            }
            if (!hasProp(value, "rightsGranted")) {
              return { success: false };
            }
            const parsed_rightsGranted = (() => {
              return ((value: unknown): { success: false } | { success: true; value: boolean } => {
                if (typeof value !== "boolean") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["rightsGranted"]);
            })();
            if (!parsed_rightsGranted.success) {
              return { success: false };
            }
            if (!hasProp(value, "suggestedAt")) {
              return { success: false };
            }
            const parsed_suggestedAt = (() => {
              return ((value: unknown): { success: false } | { success: true; value: DateTime<true> } => {
                if (typeof value !== "number") {
                  return { success: false };
                }
                const parsed = DateTime.fromMillis(value);
                if (!parsed.isValid) {
                  return { success: false };
                }
                return { success: true, value: parsed };
              })(value["suggestedAt"]);
            })();
            if (!parsed_suggestedAt.success) {
              return { success: false };
            }
            if (!hasProp(value, "tinyUrl")) {
              return { success: false };
            }
            const parsed_tinyUrl = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string | null } => {
                if (value === null) {
                  return { success: true, value: null };
                }
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["tinyUrl"]);
            })();
            if (!parsed_tinyUrl.success) {
              return { success: false };
            }
            if (!hasProp(value, "title")) {
              return { success: false };
            }
            const parsed_title = (() => {
              return ((value: unknown): { success: false } | { success: true; value: string } => {
                if (typeof value !== "string") {
                  return { success: false };
                }
                return { success: true, value: value };
              })(value["title"]);
            })();
            if (!parsed_title.success) {
              return { success: false };
            }
            return {
              success: true,
              value: {
                coverUri: parsed_coverUri.value,
                dataId: parsed_dataId.value,
                description: parsed_description.value,
                detailInfo: parsed_detailInfo.value,
                isbn: parsed_isbn.value,
                krecId: parsed_krecId.value,
                language: parsed_language.value,
                managingEditorCustomerId: parsed_managingEditorCustomerId.value,
                rightsGranted: parsed_rightsGranted.value,
                suggestedAt: parsed_suggestedAt.value,
                tinyUrl: parsed_tinyUrl.value,
                title: parsed_title.value,
              },
            };
          })(value);
        });
        if (parsed.find((value) => !value.success) !== undefined) {
          return { success: false };
        }
        return {
          success: true,
          value: parsed.map((entry) => {
            assert(entry.success == true);
            return entry.value;
          }),
        };
      })(value["items"]);
    })();
    if (!parsed_items.success) {
      return { success: false };
    }
    return { success: true, value: { items: parsed_items.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// WishlistManagementLanguageUpdate
export function serialize_WishlistManagementLanguageUpdate(obj: WishlistManagementLanguageUpdate): string {
  const serialized = ((value: { language: Language }): unknown => {
    return {
      language: ((value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr"): unknown => {
        return value;
      })(value["language"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_WishlistManagementLanguageUpdate(str: string): WishlistManagementLanguageUpdate | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          language: Language;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "language")) {
      return { success: false };
    }
    const parsed_language = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Language } => {
        return ((value: unknown): { success: false } | { success: true; value: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr" } => {
          if (typeof value !== "string") {
            return { success: false };
          }
          switch (value) {
            case "en":
              return { success: true, value: "en" };
            case "de":
              return { success: true, value: "de" };
            case "es":
              return { success: true, value: "es" };
            case "ru":
              return { success: true, value: "ru" };
            case "zh":
              return { success: true, value: "zh" };
            case "pt":
              return { success: true, value: "pt" };
            case "fr":
              return { success: true, value: "fr" };
            default:
              return { success: false };
          }
        })(value);
      })(value["language"]);
    })();
    if (!parsed_language.success) {
      return { success: false };
    }
    return { success: true, value: { language: parsed_language.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// XApiActorId
export function serialize_XApiActorId(obj: XApiActorId): string {
  const serialized = ((value: "MBOX" | "ACCOUNT" | "OPENID"): unknown => {
    return value;
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_XApiActorId(str: string): XApiActorId | null {
  const parsed = ((value: unknown): { success: false } | { success: true; value: "MBOX" | "ACCOUNT" | "OPENID" } => {
    if (typeof value !== "string") {
      return { success: false };
    }
    switch (value) {
      case "MBOX":
        return { success: true, value: "MBOX" };
      case "ACCOUNT":
        return { success: true, value: "ACCOUNT" };
      case "OPENID":
        return { success: true, value: "OPENID" };
      default:
        return { success: false };
    }
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}

// XApiStatement
export function serialize_XApiStatement(obj: XApiStatement): string {
  const serialized = ((value: { actor: Actor; object: Activity; result: Result; timestamp: string; verb: Verb }): unknown => {
    return {
      actor: ((value: { account: Account | null; mbox: string | null; name: string; objectType: string; openid: string | null }): unknown => {
        return {
          account: ((
            value: {
              homePage: string;
              name: string;
            } | null,
          ): unknown => {
            if (value === null) {
              return null;
            }
            return {
              homePage: ((value: string): unknown => {
                return value;
              })(value["homePage"]),
              name: ((value: string): unknown => {
                return value;
              })(value["name"]),
            };
          })(value["account"]),
          mbox: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["mbox"]),
          name: ((value: string): unknown => {
            return value;
          })(value["name"]),
          objectType: ((value: string): unknown => {
            return value;
          })(value["objectType"]),
          openid: ((value: string | null): unknown => {
            if (value === null) {
              return null;
            }
            return value;
          })(value["openid"]),
        };
      })(value["actor"]),
      object: ((value: { definition: ActivityDefinition; id: string; objectType: string }): unknown => {
        return {
          definition: ((value: { description: { additionalProperties: Record<string, string> }; moreInfo: string; name: { additionalProperties: Record<string, string> }; type: string }): unknown => {
            return {
              description: ((value: { additionalProperties: Record<string, string> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string): unknown => {
                          return value;
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["description"]),
              moreInfo: ((value: string): unknown => {
                return value;
              })(value["moreInfo"]),
              name: ((value: { additionalProperties: Record<string, string> }): unknown => {
                const unordered_obj: Record<string, unknown> = {
                  ...Object.fromEntries(
                    Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                      return [
                        propertyKey,
                        ((value: string): unknown => {
                          return value;
                        })(propertyValue),
                      ];
                    }),
                  ),
                };
                return Object.keys(unordered_obj)
                  .sort()
                  .reduce(
                    (obj, key) => {
                      obj[key] = unordered_obj[key];
                      return obj;
                    },
                    {} as Record<string, unknown>,
                  );
              })(value["name"]),
              type: ((value: string): unknown => {
                return value;
              })(value["type"]),
            };
          })(value["definition"]),
          id: ((value: string): unknown => {
            return value;
          })(value["id"]),
          objectType: ((value: string): unknown => {
            return value;
          })(value["objectType"]),
        };
      })(value["object"]),
      result: ((value: { completion: boolean; duration: string }): unknown => {
        return {
          completion: ((value: boolean): unknown => {
            return value;
          })(value["completion"]),
          duration: ((value: string): unknown => {
            return value;
          })(value["duration"]),
        };
      })(value["result"]),
      timestamp: ((value: string): unknown => {
        return value;
      })(value["timestamp"]),
      verb: ((value: { display: { additionalProperties: Record<string, string> }; id: string }): unknown => {
        return {
          display: ((value: { additionalProperties: Record<string, string> }): unknown => {
            const unordered_obj: Record<string, unknown> = {
              ...Object.fromEntries(
                Object.entries(value.additionalProperties).map(([propertyKey, propertyValue]) => {
                  return [
                    propertyKey,
                    ((value: string): unknown => {
                      return value;
                    })(propertyValue),
                  ];
                }),
              ),
            };
            return Object.keys(unordered_obj)
              .sort()
              .reduce(
                (obj, key) => {
                  obj[key] = unordered_obj[key];
                  return obj;
                },
                {} as Record<string, unknown>,
              );
          })(value["display"]),
          id: ((value: string): unknown => {
            return value;
          })(value["id"]),
        };
      })(value["verb"]),
    };
  })(obj);
  return renderJsonWithNumbersAsBigint(serialized);
}
export function deserialize_XApiStatement(str: string): XApiStatement | null {
  const parsed = ((
    value: unknown,
  ):
    | { success: false }
    | {
        success: true;
        value: {
          actor: Actor;
          object: Activity;
          result: Result;
          timestamp: string;
          verb: Verb;
        };
      } => {
    if (typeof value !== "object") {
      return { success: false };
    }
    if (value === null) {
      return { success: false };
    }
    if (!hasProp(value, "actor")) {
      return { success: false };
    }
    const parsed_actor = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Actor } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                account: Account | null;
                mbox: string | null;
                name: string;
                objectType: string;
                openid: string | null;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "account")) {
            return { success: false };
          }
          const parsed_account = (() => {
            return ((value: unknown): { success: false } | { success: true; value: Account | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      homePage: string;
                      name: string;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "homePage")) {
                  return { success: false };
                }
                const parsed_homePage = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["homePage"]);
                })();
                if (!parsed_homePage.success) {
                  return { success: false };
                }
                if (!hasProp(value, "name")) {
                  return { success: false };
                }
                const parsed_name = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["name"]);
                })();
                if (!parsed_name.success) {
                  return { success: false };
                }
                return { success: true, value: { homePage: parsed_homePage.value, name: parsed_name.value } };
              })(value);
            })(value["account"]);
          })();
          if (!parsed_account.success) {
            return { success: false };
          }
          if (!hasProp(value, "mbox")) {
            return { success: false };
          }
          const parsed_mbox = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["mbox"]);
          })();
          if (!parsed_mbox.success) {
            return { success: false };
          }
          if (!hasProp(value, "name")) {
            return { success: false };
          }
          const parsed_name = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["name"]);
          })();
          if (!parsed_name.success) {
            return { success: false };
          }
          if (!hasProp(value, "objectType")) {
            return { success: false };
          }
          const parsed_objectType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["objectType"]);
          })();
          if (!parsed_objectType.success) {
            return { success: false };
          }
          if (!hasProp(value, "openid")) {
            return { success: false };
          }
          const parsed_openid = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string | null } => {
              if (value === null) {
                return { success: true, value: null };
              }
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["openid"]);
          })();
          if (!parsed_openid.success) {
            return { success: false };
          }
          return { success: true, value: { account: parsed_account.value, mbox: parsed_mbox.value, name: parsed_name.value, objectType: parsed_objectType.value, openid: parsed_openid.value } };
        })(value);
      })(value["actor"]);
    })();
    if (!parsed_actor.success) {
      return { success: false };
    }
    if (!hasProp(value, "object")) {
      return { success: false };
    }
    const parsed_object = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Activity } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                definition: ActivityDefinition;
                id: string;
                objectType: string;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "definition")) {
            return { success: false };
          }
          const parsed_definition = (() => {
            return ((value: unknown): { success: false } | { success: true; value: ActivityDefinition } => {
              return ((
                value: unknown,
              ):
                | { success: false }
                | {
                    success: true;
                    value: {
                      description: { additionalProperties: Record<string, string> };
                      moreInfo: string;
                      name: { additionalProperties: Record<string, string> };
                      type: string;
                    };
                  } => {
                if (typeof value !== "object") {
                  return { success: false };
                }
                if (value === null) {
                  return { success: false };
                }
                if (!hasProp(value, "description")) {
                  return { success: false };
                }
                const parsed_description = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
                      const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
                        return [
                          key,
                          ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value),
                        ];
                      });
                      if (parsed.find(([_, value]) => !value.success) !== undefined) {
                        return { success: false };
                      }
                      return {
                        success: true,
                        value: Object.fromEntries(
                          parsed.map(([key, value]) => {
                            assert(value.success);
                            return [key, value.value];
                          }),
                        ),
                      };
                    })();
                    if (!parsed_additionalProperties.success) {
                      return { success: false };
                    }
                    return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
                  })(value["description"]);
                })();
                if (!parsed_description.success) {
                  return { success: false };
                }
                if (!hasProp(value, "moreInfo")) {
                  return { success: false };
                }
                const parsed_moreInfo = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["moreInfo"]);
                })();
                if (!parsed_moreInfo.success) {
                  return { success: false };
                }
                if (!hasProp(value, "name")) {
                  return { success: false };
                }
                const parsed_name = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
                    if (typeof value !== "object") {
                      return { success: false };
                    }
                    if (value === null) {
                      return { success: false };
                    }
                    const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
                      const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
                        return [
                          key,
                          ((value: unknown): { success: false } | { success: true; value: string } => {
                            if (typeof value !== "string") {
                              return { success: false };
                            }
                            return { success: true, value: value };
                          })(value),
                        ];
                      });
                      if (parsed.find(([_, value]) => !value.success) !== undefined) {
                        return { success: false };
                      }
                      return {
                        success: true,
                        value: Object.fromEntries(
                          parsed.map(([key, value]) => {
                            assert(value.success);
                            return [key, value.value];
                          }),
                        ),
                      };
                    })();
                    if (!parsed_additionalProperties.success) {
                      return { success: false };
                    }
                    return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
                  })(value["name"]);
                })();
                if (!parsed_name.success) {
                  return { success: false };
                }
                if (!hasProp(value, "type")) {
                  return { success: false };
                }
                const parsed_type = (() => {
                  return ((value: unknown): { success: false } | { success: true; value: string } => {
                    if (typeof value !== "string") {
                      return { success: false };
                    }
                    return { success: true, value: value };
                  })(value["type"]);
                })();
                if (!parsed_type.success) {
                  return { success: false };
                }
                return { success: true, value: { description: parsed_description.value, moreInfo: parsed_moreInfo.value, name: parsed_name.value, type: parsed_type.value } };
              })(value);
            })(value["definition"]);
          })();
          if (!parsed_definition.success) {
            return { success: false };
          }
          if (!hasProp(value, "id")) {
            return { success: false };
          }
          const parsed_id = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["id"]);
          })();
          if (!parsed_id.success) {
            return { success: false };
          }
          if (!hasProp(value, "objectType")) {
            return { success: false };
          }
          const parsed_objectType = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["objectType"]);
          })();
          if (!parsed_objectType.success) {
            return { success: false };
          }
          return { success: true, value: { definition: parsed_definition.value, id: parsed_id.value, objectType: parsed_objectType.value } };
        })(value);
      })(value["object"]);
    })();
    if (!parsed_object.success) {
      return { success: false };
    }
    if (!hasProp(value, "result")) {
      return { success: false };
    }
    const parsed_result = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Result } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                completion: boolean;
                duration: string;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "completion")) {
            return { success: false };
          }
          const parsed_completion = (() => {
            return ((value: unknown): { success: false } | { success: true; value: boolean } => {
              if (typeof value !== "boolean") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["completion"]);
          })();
          if (!parsed_completion.success) {
            return { success: false };
          }
          if (!hasProp(value, "duration")) {
            return { success: false };
          }
          const parsed_duration = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["duration"]);
          })();
          if (!parsed_duration.success) {
            return { success: false };
          }
          return { success: true, value: { completion: parsed_completion.value, duration: parsed_duration.value } };
        })(value);
      })(value["result"]);
    })();
    if (!parsed_result.success) {
      return { success: false };
    }
    if (!hasProp(value, "timestamp")) {
      return { success: false };
    }
    const parsed_timestamp = (() => {
      return ((value: unknown): { success: false } | { success: true; value: string } => {
        if (typeof value !== "string") {
          return { success: false };
        }
        return { success: true, value: value };
      })(value["timestamp"]);
    })();
    if (!parsed_timestamp.success) {
      return { success: false };
    }
    if (!hasProp(value, "verb")) {
      return { success: false };
    }
    const parsed_verb = (() => {
      return ((value: unknown): { success: false } | { success: true; value: Verb } => {
        return ((
          value: unknown,
        ):
          | { success: false }
          | {
              success: true;
              value: {
                display: { additionalProperties: Record<string, string> };
                id: string;
              };
            } => {
          if (typeof value !== "object") {
            return { success: false };
          }
          if (value === null) {
            return { success: false };
          }
          if (!hasProp(value, "display")) {
            return { success: false };
          }
          const parsed_display = (() => {
            return ((value: unknown): { success: false } | { success: true; value: { additionalProperties: Record<string, string> } } => {
              if (typeof value !== "object") {
                return { success: false };
              }
              if (value === null) {
                return { success: false };
              }
              const parsed_additionalProperties = ((): { success: false } | { success: true; value: Record<string, string> } => {
                const parsed = Object.entries(value).map(([key, value]): [string, { success: false } | { success: true; value: string }] => {
                  return [
                    key,
                    ((value: unknown): { success: false } | { success: true; value: string } => {
                      if (typeof value !== "string") {
                        return { success: false };
                      }
                      return { success: true, value: value };
                    })(value),
                  ];
                });
                if (parsed.find(([_, value]) => !value.success) !== undefined) {
                  return { success: false };
                }
                return {
                  success: true,
                  value: Object.fromEntries(
                    parsed.map(([key, value]) => {
                      assert(value.success);
                      return [key, value.value];
                    }),
                  ),
                };
              })();
              if (!parsed_additionalProperties.success) {
                return { success: false };
              }
              return { success: true, value: { additionalProperties: parsed_additionalProperties.value } };
            })(value["display"]);
          })();
          if (!parsed_display.success) {
            return { success: false };
          }
          if (!hasProp(value, "id")) {
            return { success: false };
          }
          const parsed_id = (() => {
            return ((value: unknown): { success: false } | { success: true; value: string } => {
              if (typeof value !== "string") {
                return { success: false };
              }
              return { success: true, value: value };
            })(value["id"]);
          })();
          if (!parsed_id.success) {
            return { success: false };
          }
          return { success: true, value: { display: parsed_display.value, id: parsed_id.value } };
        })(value);
      })(value["verb"]);
    })();
    if (!parsed_verb.success) {
      return { success: false };
    }
    return { success: true, value: { actor: parsed_actor.value, object: parsed_object.value, result: parsed_result.value, timestamp: parsed_timestamp.value, verb: parsed_verb.value } };
  })(parseJsonWithNumbersAsBigint(str));
  if (parsed.success) {
    return parsed.value;
  } else {
    return null;
  }
}
