// @ts-ignore
import { gaApiCall } from "@utils/httpClient";
// @ts-ignore
import { hasProp, assert } from "@utils/assertion";
// @ts-ignore
import { renderJsonWithNumbersAsBigint, parseJsonWithNumbersAsBigint } from "@utils/json";
import * as schema from "@newgenerated/shared/schema";
import * as mapper from "@newgenerated/shared/mapper";
export async function getChannels(props: { includeInactive: boolean }): Promise<{ kind: "success"; data: schema.ChannelList } | { kind: "error"; message: string }> {
  let path = "/gaapi/admin/channels";
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append(
    "includeInactive",
    ((value) => {
      return ((value) => {
        const parsed_again = parseJsonWithNumbersAsBigint(value);
        assert(typeof parsed_again === "boolean");
        return parsed_again ? "true" : "false";
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.includeInactive),
  );
  if (urlSearchParams.size > 0) {
    path += "?";
    path += urlSearchParams.toString();
  }
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.ChannelList } => {
    const parsed = mapper.deserialize_ChannelList(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function getIxStatus(props: { body: schema.ContentIdList }): Promise<{ kind: "success"; data: schema.ContentIxStatusList } | { kind: "error"; message: string }> {
  const path = "/gaapi/content-ix-status";
  const response = await gaApiCall({
    path: path,
    method: "POST",
    requestBody: ((value) => {
      return mapper.serialize_ContentIdList(value);
    })(props.body),
  });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.ContentIxStatusList } => {
    const parsed = mapper.deserialize_ContentIxStatusList(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function getCustomPages(props: {
  corporateId: number;
  customPageAdminSearchForm: schema.CustomPageAdminSearchForm;
  pageRequest: schema.PageRequest;
}): Promise<{ kind: "success"; data: schema.PagedCustomPageEditListView } | { kind: "error"; message: string }> {
  let path = "/gaapi/corporations/{corporateId}/custom-pages";
  path = path.replace(
    "{corporateId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.corporateId),
  );
  const urlSearchParams = new URLSearchParams();
  (() => {
    const outerParsed = ((value) => {
      const outerRendered = ((value) => {
        return mapper.serialize_CustomPageAdminSearchForm(value);
      })(value);
      const outerParsed = parseJsonWithNumbersAsBigint(outerRendered);
      assert(outerParsed != null);
      assert(typeof outerParsed == "object");
      return outerParsed;
    })(props.customPageAdminSearchForm);
    assert(hasProp(outerParsed, "includeInactive"));
    urlSearchParams.append(
      "includeInactive",
      ((value) => {
        const parsed_again = parseJsonWithNumbersAsBigint(value);
        assert(typeof parsed_again === "boolean");
        return parsed_again ? "true" : "false";
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(outerParsed.includeInactive);
        })(),
      ),
    );
    assert(hasProp(outerParsed, "onlyHighlighted"));
    urlSearchParams.append(
      "onlyHighlighted",
      ((value) => {
        const parsed_again = parseJsonWithNumbersAsBigint(value);
        assert(typeof parsed_again === "boolean");
        return parsed_again ? "true" : "false";
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(outerParsed.onlyHighlighted);
        })(),
      ),
    );
    assert(hasProp(outerParsed, "searchTerm"));
    urlSearchParams.append(
      "searchTerm",
      ((value) => {
        const parsed_again = parseJsonWithNumbersAsBigint(value);
        assert(typeof parsed_again === "string");
        return parsed_again;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(outerParsed.searchTerm);
        })(),
      ),
    );
  })();
  (() => {
    const outerParsed = ((value) => {
      const outerRendered = ((value) => {
        return mapper.serialize_PageRequest(value);
      })(value);
      const outerParsed = parseJsonWithNumbersAsBigint(outerRendered);
      assert(outerParsed != null);
      assert(typeof outerParsed == "object");
      return outerParsed;
    })(props.pageRequest);
    assert(hasProp(outerParsed, "page"));
    urlSearchParams.append(
      "page",
      ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(outerParsed.page);
        })(),
      ),
    );
    assert(hasProp(outerParsed, "psize"));
    urlSearchParams.append(
      "psize",
      ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(outerParsed.psize);
        })(),
      ),
    );
    assert(hasProp(outerParsed, "sortBy"));
    if (outerParsed.sortBy != null) {
      urlSearchParams.append(
        "sortBy",
        ((value) => {
          const parsed_again = parseJsonWithNumbersAsBigint(value);
          assert(typeof parsed_again === "string");
          return parsed_again;
        })(
          (() => {
            return renderJsonWithNumbersAsBigint(outerParsed.sortBy);
          })(),
        ),
      );
    }
    assert(hasProp(outerParsed, "sortDesc"));
    urlSearchParams.append(
      "sortDesc",
      ((value) => {
        const parsed_again = parseJsonWithNumbersAsBigint(value);
        assert(typeof parsed_again === "boolean");
        return parsed_again ? "true" : "false";
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(outerParsed.sortDesc);
        })(),
      ),
    );
  })();
  if (urlSearchParams.size > 0) {
    path += "?";
    path += urlSearchParams.toString();
  }
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.PagedCustomPageEditListView } => {
    const parsed = mapper.deserialize_PagedCustomPageEditListView(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function getCustomPage(props: { corporateId: number; customPageId: bigint }): Promise<{ kind: "success"; data: schema.CustomPageEditView } | { kind: "error"; message: string }> {
  let path = "/gaapi/corporations/{corporateId}/custom-pages/{customPageId}";
  path = path.replace(
    "{corporateId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.corporateId),
  );
  path = path.replace(
    "{customPageId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.customPageId),
  );
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.CustomPageEditView } => {
    const parsed = mapper.deserialize_CustomPageEditView(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function archiveCustomPage(props: { corporateId: number; customPageId: bigint }): Promise<{ kind: "success" } | { kind: "error"; message: string }> {
  let path = "/gaapi/corporations/{corporateId}/custom-pages/{customPageId}/archive";
  path = path.replace(
    "{corporateId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.corporateId),
  );
  path = path.replace(
    "{customPageId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.customPageId),
  );
  const response = await gaApiCall({ path: path, method: "POST", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  return { kind: "success" };
}

export async function copyCustomPage(props: { body: schema.CustomPageCopyRequest; corporateId: number; customPageId: bigint }): Promise<{ kind: "success"; data: string } | { kind: "error"; message: string }> {
  let path = "/gaapi/corporations/{corporateId}/custom-pages/{customPageId}/copy";
  path = path.replace(
    "{corporateId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.corporateId),
  );
  path = path.replace(
    "{customPageId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.customPageId),
  );
  const response = await gaApiCall({
    path: path,
    method: "POST",
    requestBody: ((value) => {
      return mapper.serialize_CustomPageCopyRequest(value);
    })(props.body),
  });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: string } => {
    const data = parseJsonWithNumbersAsBigint(value);
    return ((value) => {
      if (typeof value !== "string") {
        return { success: false };
      }
      return { success: true, value: value };
    })(data);
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function searchCustomPages(props: {
  corporateId: number;
  pageRequest: schema.PageRequest;
  customPageAdminSearchForm: schema.CustomPageAdminSearchForm;
  isoCode: schema.Language;
}): Promise<{ kind: "success"; data: schema.PagedCustomPageListView } | { kind: "error"; message: string }> {
  let path = "/gaapi/corporations/{corporateId}/custom-pages/admin-search";
  path = path.replace(
    "{corporateId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.corporateId),
  );
  const urlSearchParams = new URLSearchParams();
  (() => {
    const outerParsed = ((value) => {
      const outerRendered = ((value) => {
        return mapper.serialize_PageRequest(value);
      })(value);
      const outerParsed = parseJsonWithNumbersAsBigint(outerRendered);
      assert(outerParsed != null);
      assert(typeof outerParsed == "object");
      return outerParsed;
    })(props.pageRequest);
    assert(hasProp(outerParsed, "page"));
    urlSearchParams.append(
      "page",
      ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(outerParsed.page);
        })(),
      ),
    );
    assert(hasProp(outerParsed, "psize"));
    urlSearchParams.append(
      "psize",
      ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(outerParsed.psize);
        })(),
      ),
    );
    assert(hasProp(outerParsed, "sortBy"));
    if (outerParsed.sortBy != null) {
      urlSearchParams.append(
        "sortBy",
        ((value) => {
          const parsed_again = parseJsonWithNumbersAsBigint(value);
          assert(typeof parsed_again === "string");
          return parsed_again;
        })(
          (() => {
            return renderJsonWithNumbersAsBigint(outerParsed.sortBy);
          })(),
        ),
      );
    }
    assert(hasProp(outerParsed, "sortDesc"));
    urlSearchParams.append(
      "sortDesc",
      ((value) => {
        const parsed_again = parseJsonWithNumbersAsBigint(value);
        assert(typeof parsed_again === "boolean");
        return parsed_again ? "true" : "false";
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(outerParsed.sortDesc);
        })(),
      ),
    );
  })();
  (() => {
    const outerParsed = ((value) => {
      const outerRendered = ((value) => {
        return mapper.serialize_CustomPageAdminSearchForm(value);
      })(value);
      const outerParsed = parseJsonWithNumbersAsBigint(outerRendered);
      assert(outerParsed != null);
      assert(typeof outerParsed == "object");
      return outerParsed;
    })(props.customPageAdminSearchForm);
    assert(hasProp(outerParsed, "includeInactive"));
    urlSearchParams.append(
      "includeInactive",
      ((value) => {
        const parsed_again = parseJsonWithNumbersAsBigint(value);
        assert(typeof parsed_again === "boolean");
        return parsed_again ? "true" : "false";
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(outerParsed.includeInactive);
        })(),
      ),
    );
    assert(hasProp(outerParsed, "onlyHighlighted"));
    urlSearchParams.append(
      "onlyHighlighted",
      ((value) => {
        const parsed_again = parseJsonWithNumbersAsBigint(value);
        assert(typeof parsed_again === "boolean");
        return parsed_again ? "true" : "false";
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(outerParsed.onlyHighlighted);
        })(),
      ),
    );
    assert(hasProp(outerParsed, "searchTerm"));
    urlSearchParams.append(
      "searchTerm",
      ((value) => {
        const parsed_again = parseJsonWithNumbersAsBigint(value);
        assert(typeof parsed_again === "string");
        return parsed_again;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(outerParsed.searchTerm);
        })(),
      ),
    );
  })();
  urlSearchParams.append(
    "isoCode",
    ((value) => {
      return ((value) => {
        const parsed_again = parseJsonWithNumbersAsBigint(value);
        assert(typeof parsed_again === "string");
        return parsed_again;
      })(
        (() => {
          return mapper.serialize_Language(value);
        })(),
      );
    })(props.isoCode),
  );
  if (urlSearchParams.size > 0) {
    path += "?";
    path += urlSearchParams.toString();
  }
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.PagedCustomPageListView } => {
    const parsed = mapper.deserialize_PagedCustomPageListView(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function getCustomPagesHighlighted(props: { corporateId: number }): Promise<{ kind: "success"; data: schema.CustomPageEditListViewArray } | { kind: "error"; message: string }> {
  let path = "/gaapi/corporations/{corporateId}/custom-pages/highlighted";
  path = path.replace(
    "{corporateId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.corporateId),
  );
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.CustomPageEditListViewArray } => {
    const parsed = mapper.deserialize_CustomPageEditListViewArray(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function saveCustomPagesHighlighted(props: { body: schema.CustomPageHighlightsForm; corporateId: number }): Promise<{ kind: "success"; data: schema.CustomPageEditListViewArray } | { kind: "error"; message: string }> {
  let path = "/gaapi/corporations/{corporateId}/custom-pages/highlighted";
  path = path.replace(
    "{corporateId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.corporateId),
  );
  const response = await gaApiCall({
    path: path,
    method: "PUT",
    requestBody: ((value) => {
      return mapper.serialize_CustomPageHighlightsForm(value);
    })(props.body),
  });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.CustomPageEditListViewArray } => {
    const parsed = mapper.deserialize_CustomPageEditListViewArray(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function getPortletContainer(props: { corporateId: number; isoCode: schema.Language }): Promise<{ kind: "success"; data: schema.PortletContainerAdminEditView } | { kind: "error"; message: string }> {
  let path = "/gaapi/corporations/{corporateId}/portlet-containers/start-page/languages/{isoCode}";
  path = path.replace(
    "{corporateId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.corporateId),
  );
  path = path.replace(
    "{isoCode}",
    ((value) => {
      return ((value) => {
        const parsed_again = parseJsonWithNumbersAsBigint(value);
        assert(typeof parsed_again === "string");
        return parsed_again;
      })(
        (() => {
          return mapper.serialize_Language(value);
        })(),
      );
    })(props.isoCode),
  );
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.PortletContainerAdminEditView } => {
    const parsed = mapper.deserialize_PortletContainerAdminEditView(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function savePortletContainer(props: {
  body: schema.PortletContainerAdminEditForm;
  corporateId: number;
  isoCode: schema.Language;
}): Promise<{ kind: "success"; data: schema.PortletContainerAdminEditView } | { kind: "error"; message: string }> {
  let path = "/gaapi/corporations/{corporateId}/portlet-containers/start-page/languages/{isoCode}";
  path = path.replace(
    "{corporateId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.corporateId),
  );
  path = path.replace(
    "{isoCode}",
    ((value) => {
      return ((value) => {
        const parsed_again = parseJsonWithNumbersAsBigint(value);
        assert(typeof parsed_again === "string");
        return parsed_again;
      })(
        (() => {
          return mapper.serialize_Language(value);
        })(),
      );
    })(props.isoCode),
  );
  const response = await gaApiCall({
    path: path,
    method: "POST",
    requestBody: ((value) => {
      return mapper.serialize_PortletContainerAdminEditForm(value);
    })(props.body),
  });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.PortletContainerAdminEditView } => {
    const parsed = mapper.deserialize_PortletContainerAdminEditView(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function deletePortletContainer(props: { corporateId: number; portletContainerId: number }): Promise<{ kind: "success" } | { kind: "error"; message: string }> {
  let path = "/gaapi/corporations/{corporateId}/portlet-containers/{portletContainerId}";
  path = path.replace(
    "{corporateId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.corporateId),
  );
  path = path.replace(
    "{portletContainerId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.portletContainerId),
  );
  const response = await gaApiCall({ path: path, method: "POST", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  return { kind: "success" };
}

export async function getMyListPortletView(props: { corporateId: number; portletId: number; isoCode: schema.Language }): Promise<{ kind: "success"; data: schema.MyListPortletView } | { kind: "error"; message: string }> {
  let path = "/gaapi/corporations/{corporateId}/portlets/mylist-{portletId}";
  path = path.replace(
    "{corporateId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.corporateId),
  );
  path = path.replace(
    "{portletId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.portletId),
  );
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append(
    "isoCode",
    ((value) => {
      return ((value) => {
        const parsed_again = parseJsonWithNumbersAsBigint(value);
        assert(typeof parsed_again === "string");
        return parsed_again;
      })(
        (() => {
          return mapper.serialize_Language(value);
        })(),
      );
    })(props.isoCode),
  );
  if (urlSearchParams.size > 0) {
    path += "?";
    path += urlSearchParams.toString();
  }
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.MyListPortletView } => {
    const parsed = mapper.deserialize_MyListPortletView(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function getAllSensitiveTags(props: { corporateId: number }): Promise<{ kind: "success"; data: schema.TagArray } | { kind: "error"; message: string }> {
  let path = "/gaapi/corporations/{corporateId}/sensitive-tags";
  path = path.replace(
    "{corporateId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.corporateId),
  );
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.TagArray } => {
    const parsed = mapper.deserialize_TagArray(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function saveSensitiveTags(props: { body: schema.TagArray; corporateId: number }): Promise<{ kind: "success" } | { kind: "error"; message: string }> {
  let path = "/gaapi/corporations/{corporateId}/sensitive-tags";
  path = path.replace(
    "{corporateId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.corporateId),
  );
  const response = await gaApiCall({
    path: path,
    method: "PUT",
    requestBody: ((value) => {
      return mapper.serialize_TagArray(value);
    })(props.body),
  });
  if (response.kind == "error") {
    return response;
  }
  return { kind: "success" };
}

export async function getCustomerComments(props: { customerId: number }): Promise<{ kind: "success"; data: schema.CustomerCommentForms } | { kind: "error"; message: string }> {
  let path = "/gaapi/customer-comments/{customerId}";
  path = path.replace(
    "{customerId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.customerId),
  );
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.CustomerCommentForms } => {
    const parsed = mapper.deserialize_CustomerCommentForms(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function createCustomerComment(props: { body: schema.CustomerCommentSaveRequest; customerId: number }): Promise<{ kind: "success" } | { kind: "error"; message: string }> {
  let path = "/gaapi/customer-comments/{customerId}";
  path = path.replace(
    "{customerId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.customerId),
  );
  const response = await gaApiCall({
    path: path,
    method: "POST",
    requestBody: ((value) => {
      return mapper.serialize_CustomerCommentSaveRequest(value);
    })(props.body),
  });
  if (response.kind == "error") {
    return response;
  }
  return { kind: "success" };
}

export async function getCustomerDetail(props: { customerId: number }): Promise<{ kind: "success"; data: schema.CustomerAdminDetailForm } | { kind: "error"; message: string }> {
  let path = "/gaapi/customer/{customerId}";
  path = path.replace(
    "{customerId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.customerId),
  );
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.CustomerAdminDetailForm } => {
    const parsed = mapper.deserialize_CustomerAdminDetailForm(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function updateCustomerDetails(props: { body: schema.CustomerAdminDetailForm; customerId: number }): Promise<{ kind: "success"; data: schema.CustomerAdminDetailForm } | { kind: "error"; message: string }> {
  let path = "/gaapi/customer/{customerId}";
  path = path.replace(
    "{customerId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.customerId),
  );
  const response = await gaApiCall({
    path: path,
    method: "PUT",
    requestBody: ((value) => {
      return mapper.serialize_CustomerAdminDetailForm(value);
    })(props.body),
  });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.CustomerAdminDetailForm } => {
    const parsed = mapper.deserialize_CustomerAdminDetailForm(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function getLeadPortals(props: { customerId: number }): Promise<{ kind: "success"; data: schema.CustomerAdminB2BPortalsForms } | { kind: "error"; message: string }> {
  let path = "/gaapi/customer/{customerId}/LeadPortals";
  path = path.replace(
    "{customerId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.customerId),
  );
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.CustomerAdminB2BPortalsForms } => {
    const parsed = mapper.deserialize_CustomerAdminB2BPortalsForms(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function getCustomerAccountDetails(props: { customerId: number }): Promise<{ kind: "success"; data: schema.CustomerAdminAccountDetailForm } | { kind: "error"; message: string }> {
  let path = "/gaapi/customer/{customerId}/accountdetails";
  path = path.replace(
    "{customerId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.customerId),
  );
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.CustomerAdminAccountDetailForm } => {
    const parsed = mapper.deserialize_CustomerAdminAccountDetailForm(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function getB2BPortals(props: { customerId: number }): Promise<{ kind: "success"; data: schema.CustomerAdminB2BPortalsForms } | { kind: "error"; message: string }> {
  let path = "/gaapi/customer/{customerId}/b2bportals";
  path = path.replace(
    "{customerId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.customerId),
  );
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.CustomerAdminB2BPortalsForms } => {
    const parsed = mapper.deserialize_CustomerAdminB2BPortalsForms(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function deactivateCorporateCustomer(props: {
  customerId: number;
  corporateId: number;
  portalType: schema.PortalType;
}): Promise<{ kind: "success"; data: schema.CustomerAdminB2BPortalsForms } | { kind: "error"; message: string }> {
  let path = "/gaapi/customer/{customerId}/b2bportals/{corporateId}/deactivate/{portalType}";
  path = path.replace(
    "{customerId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.customerId),
  );
  path = path.replace(
    "{corporateId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.corporateId),
  );
  path = path.replace(
    "{portalType}",
    ((value) => {
      return ((value) => {
        const parsed_again = parseJsonWithNumbersAsBigint(value);
        assert(typeof parsed_again === "string");
        return parsed_again;
      })(
        (() => {
          return mapper.serialize_PortalType(value);
        })(),
      );
    })(props.portalType),
  );
  const response = await gaApiCall({ path: path, method: "POST", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.CustomerAdminB2BPortalsForms } => {
    const parsed = mapper.deserialize_CustomerAdminB2BPortalsForms(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function getFreeAbstractCoupons(props: { customerId: number }): Promise<{ kind: "success"; data: schema.CustomerAdminFreeAbstractCoupons } | { kind: "error"; message: string }> {
  let path = "/gaapi/customer/{customerId}/freeAbstractCoupons";
  path = path.replace(
    "{customerId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.customerId),
  );
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.CustomerAdminFreeAbstractCoupons } => {
    const parsed = mapper.deserialize_CustomerAdminFreeAbstractCoupons(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function getFreeDownloads(props: { customerId: number }): Promise<{ kind: "success"; data: schema.CustomerAdminB2BPortalsForms } | { kind: "error"; message: string }> {
  let path = "/gaapi/customer/{customerId}/freedownloads";
  path = path.replace(
    "{customerId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.customerId),
  );
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.CustomerAdminB2BPortalsForms } => {
    const parsed = mapper.deserialize_CustomerAdminB2BPortalsForms(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function getMobileAppSettings(props: { customerId: number }): Promise<{ kind: "success"; data: schema.CustomerAdminMobileAppForm } | { kind: "error"; message: string }> {
  let path = "/gaapi/customer/{customerId}/mobile";
  path = path.replace(
    "{customerId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.customerId),
  );
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.CustomerAdminMobileAppForm } => {
    const parsed = mapper.deserialize_CustomerAdminMobileAppForm(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function setMobileAppLoginPreference(props: { body: schema.CustomerAdminMobileAppForm; customerId: number }): Promise<{ kind: "success"; data: schema.CustomerAdminMobileAppForm } | { kind: "error"; message: string }> {
  let path = "/gaapi/customer/{customerId}/mobile/loginPreference";
  path = path.replace(
    "{customerId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.customerId),
  );
  const response = await gaApiCall({
    path: path,
    method: "POST",
    requestBody: ((value) => {
      return mapper.serialize_CustomerAdminMobileAppForm(value);
    })(props.body),
  });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.CustomerAdminMobileAppForm } => {
    const parsed = mapper.deserialize_CustomerAdminMobileAppForm(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function revokeMobileAppToken(props: { customerId: number; token: string }): Promise<{ kind: "success"; data: schema.CustomerAdminMobileAppForm } | { kind: "error"; message: string }> {
  let path = "/gaapi/customer/{customerId}/mobile/token/{token}";
  path = path.replace(
    "{customerId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.customerId),
  );
  path = path.replace(
    "{token}",
    ((value) => {
      return ((value) => {
        const parsed_again = parseJsonWithNumbersAsBigint(value);
        assert(typeof parsed_again === "string");
        return parsed_again;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.token),
  );
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.CustomerAdminMobileAppForm } => {
    const parsed = mapper.deserialize_CustomerAdminMobileAppForm(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function resetLoginThrottler(props: { customerId: number }): Promise<{ kind: "success"; data: schema.CustomerAdminAccountDetailForm } | { kind: "error"; message: string }> {
  let path = "/gaapi/customer/{customerId}/resetLoginThrottler";
  path = path.replace(
    "{customerId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.customerId),
  );
  const response = await gaApiCall({ path: path, method: "POST", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.CustomerAdminAccountDetailForm } => {
    const parsed = mapper.deserialize_CustomerAdminAccountDetailForm(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function getB2CSubscriptions(props: { customerId: number }): Promise<{ kind: "success"; data: schema.CustomerAdminSubscriptionsForm } | { kind: "error"; message: string }> {
  let path = "/gaapi/customer/{customerId}/subscriptions";
  path = path.replace(
    "{customerId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.customerId),
  );
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.CustomerAdminSubscriptionsForm } => {
    const parsed = mapper.deserialize_CustomerAdminSubscriptionsForm(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function validateFormFile(props: { body: schema.DemoFormFile }): Promise<{ kind: "success"; data: schema.DemoFormFile } | { kind: "error"; message: string }> {
  const path = "/gaapi/demo-page/form/file";
  const response = await gaApiCall({
    path: path,
    method: "POST",
    requestBody: ((value) => {
      return mapper.serialize_DemoFormFile(value);
    })(props.body),
  });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.DemoFormFile } => {
    const parsed = mapper.deserialize_DemoFormFile(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function submitLeadForm(props: { body: schema.LeadForm }): Promise<{ kind: "success"; data: schema.LeadCreationResponse } | { kind: "error"; message: string }> {
  const path = "/gaapi/lead-form";
  const response = await gaApiCall({
    path: path,
    method: "POST",
    requestBody: ((value) => {
      return mapper.serialize_LeadForm(value);
    })(props.body),
  });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.LeadCreationResponse } => {
    const parsed = mapper.deserialize_LeadCreationResponse(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function applyAITag(props: { body: schema.ReferencedLearningItem }): Promise<{ kind: "success" } | { kind: "error"; message: string }> {
  const path = "/gaapi/learning-items/applyAiTags";
  const response = await gaApiCall({
    path: path,
    method: "POST",
    requestBody: ((value) => {
      return mapper.serialize_ReferencedLearningItem(value);
    })(props.body),
  });
  if (response.kind == "error") {
    return response;
  }
  return { kind: "success" };
}

export async function getNewestSummaries(props: { corporateId: number; daysBack: number; pageRequest: schema.PageRequest }): Promise<{ kind: "success"; data: schema.PagedSummaryListView } | { kind: "error"; message: string }> {
  let path = "/gaapi/portal-admin-overview/{corporateId}/summaries";
  path = path.replace(
    "{corporateId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.corporateId),
  );
  const urlSearchParams = new URLSearchParams();
  urlSearchParams.append(
    "daysBack",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.daysBack),
  );
  (() => {
    const outerParsed = ((value) => {
      const outerRendered = ((value) => {
        return mapper.serialize_PageRequest(value);
      })(value);
      const outerParsed = parseJsonWithNumbersAsBigint(outerRendered);
      assert(outerParsed != null);
      assert(typeof outerParsed == "object");
      return outerParsed;
    })(props.pageRequest);
    assert(hasProp(outerParsed, "page"));
    urlSearchParams.append(
      "page",
      ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(outerParsed.page);
        })(),
      ),
    );
    assert(hasProp(outerParsed, "psize"));
    urlSearchParams.append(
      "psize",
      ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(outerParsed.psize);
        })(),
      ),
    );
    assert(hasProp(outerParsed, "sortBy"));
    if (outerParsed.sortBy != null) {
      urlSearchParams.append(
        "sortBy",
        ((value) => {
          const parsed_again = parseJsonWithNumbersAsBigint(value);
          assert(typeof parsed_again === "string");
          return parsed_again;
        })(
          (() => {
            return renderJsonWithNumbersAsBigint(outerParsed.sortBy);
          })(),
        ),
      );
    }
    assert(hasProp(outerParsed, "sortDesc"));
    urlSearchParams.append(
      "sortDesc",
      ((value) => {
        const parsed_again = parseJsonWithNumbersAsBigint(value);
        assert(typeof parsed_again === "boolean");
        return parsed_again ? "true" : "false";
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(outerParsed.sortDesc);
        })(),
      ),
    );
  })();
  if (urlSearchParams.size > 0) {
    path += "?";
    path += urlSearchParams.toString();
  }
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.PagedSummaryListView } => {
    const parsed = mapper.deserialize_PagedSummaryListView(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function getReports(props: { corporateId: number }): Promise<{ kind: "success"; data: schema.PagedReportListView } | { kind: "error"; message: string }> {
  let path = "/gaapi/stats/b2b/{corporateId}/reports";
  path = path.replace(
    "{corporateId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.corporateId),
  );
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.PagedReportListView } => {
    const parsed = mapper.deserialize_PagedReportListView(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function getExportedReports(props: { corporateId: number }): Promise<{ kind: "success"; data: schema.PagedExportReportListView } | { kind: "error"; message: string }> {
  let path = "/gaapi/stats/b2b/{corporateId}/reports/exported";
  path = path.replace(
    "{corporateId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.corporateId),
  );
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.PagedExportReportListView } => {
    const parsed = mapper.deserialize_PagedExportReportListView(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function initB2bReport(props: { corporateId: number }): Promise<{ kind: "success"; data: schema.B2BStatsReportPageProps } | { kind: "error"; message: string }> {
  let path = "/gaapi/stats/b2b/{corporateId}/reports/init";
  path = path.replace(
    "{corporateId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.corporateId),
  );
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.B2BStatsReportPageProps } => {
    const parsed = mapper.deserialize_B2BStatsReportPageProps(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function getReport(props: { corporateId: number; reportId: number }): Promise<{ kind: "success"; data: schema.NewB2BReportForm } | { kind: "error"; message: string }> {
  let path = "/gaapi/stats/b2b/{corporateId}/reports/{reportId}";
  path = path.replace(
    "{corporateId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.corporateId),
  );
  path = path.replace(
    "{reportId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.reportId),
  );
  const response = await gaApiCall({ path: path, method: "GET", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.NewB2BReportForm } => {
    const parsed = mapper.deserialize_NewB2BReportForm(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function saveReport(props: { body: schema.NewB2BReportForm; corporateId: number; reportId: number }): Promise<{ kind: "success"; data: schema.B2BStatsReportSaveResponse } | { kind: "error"; message: string }> {
  let path = "/gaapi/stats/b2b/{corporateId}/reports/{reportId}";
  path = path.replace(
    "{corporateId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.corporateId),
  );
  path = path.replace(
    "{reportId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.reportId),
  );
  const response = await gaApiCall({
    path: path,
    method: "POST",
    requestBody: ((value) => {
      return mapper.serialize_NewB2BReportForm(value);
    })(props.body),
  });
  if (response.kind == "error") {
    return response;
  }
  const deserialized = ((value): { success: false } | { success: true; value: schema.B2BStatsReportSaveResponse } => {
    const parsed = mapper.deserialize_B2BStatsReportSaveResponse(value);
    if (parsed == null) {
      return { success: false };
    }
    return { success: true, value: parsed };
  })(response.jsonString);
  if (deserialized.success == false) {
    return { kind: "error", message: "could not parse response body" };
  }
  return { kind: "success", data: deserialized.value };
}

export async function deleteReport(props: { corporateId: number; reportId: number }): Promise<{ kind: "success" } | { kind: "error"; message: string }> {
  let path = "/gaapi/stats/b2b/{corporateId}/reports/{reportId}/delete";
  path = path.replace(
    "{corporateId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.corporateId),
  );
  path = path.replace(
    "{reportId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.reportId),
  );
  const response = await gaApiCall({ path: path, method: "POST", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  return { kind: "success" };
}

export async function runReport(props: { corporateId: number; reportId: number }): Promise<{ kind: "success" } | { kind: "error"; message: string }> {
  let path = "/gaapi/stats/b2b/{corporateId}/reports/{reportId}/run";
  path = path.replace(
    "{corporateId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.corporateId),
  );
  path = path.replace(
    "{reportId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.reportId),
  );
  const response = await gaApiCall({ path: path, method: "POST", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  return { kind: "success" };
}

export async function updateManagingEditor(props: { body: schema.WishlistManagementEditorUpdate; kRecId: number }): Promise<{ kind: "success" } | { kind: "error"; message: string }> {
  let path = "/gaapi/wishlist-management/{kRecId}/editor";
  path = path.replace(
    "{kRecId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.kRecId),
  );
  const response = await gaApiCall({
    path: path,
    method: "POST",
    requestBody: ((value) => {
      return mapper.serialize_WishlistManagementEditorUpdate(value);
    })(props.body),
  });
  if (response.kind == "error") {
    return response;
  }
  return { kind: "success" };
}

export async function updateLanguage(props: { body: schema.WishlistManagementLanguageUpdate; kRecId: number }): Promise<{ kind: "success" } | { kind: "error"; message: string }> {
  let path = "/gaapi/wishlist-management/{kRecId}/language";
  path = path.replace(
    "{kRecId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.kRecId),
  );
  const response = await gaApiCall({
    path: path,
    method: "POST",
    requestBody: ((value) => {
      return mapper.serialize_WishlistManagementLanguageUpdate(value);
    })(props.body),
  });
  if (response.kind == "error") {
    return response;
  }
  return { kind: "success" };
}

export async function markRejected(props: { kRecId: number }): Promise<{ kind: "success" } | { kind: "error"; message: string }> {
  let path = "/gaapi/wishlist-management/{kRecId}/reject";
  path = path.replace(
    "{kRecId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.kRecId),
  );
  const response = await gaApiCall({ path: path, method: "POST", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  return { kind: "success" };
}

export async function markToReview(props: { kRecId: number }): Promise<{ kind: "success" } | { kind: "error"; message: string }> {
  let path = "/gaapi/wishlist-management/{kRecId}/review";
  path = path.replace(
    "{kRecId}",
    ((value) => {
      return ((value) => {
        return value;
      })(
        (() => {
          return renderJsonWithNumbersAsBigint(
            (() => {
              return value;
            })(),
          );
        })(),
      );
    })(props.kRecId),
  );
  const response = await gaApiCall({ path: path, method: "POST", requestBody: null });
  if (response.kind == "error") {
    return response;
  }
  return { kind: "success" };
}
